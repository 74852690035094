import * as React from 'react';
import {
    dataSetDashboardDispatchSelectedLoadDate,
} from '../../../store/data/Actions';
import { DispatchCall, RootAction, IRootState, IAuthState } from '../../../@types/redux';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import { getState } from '../../../store/Index';
import { dataSetMaterialDispatch, dataSetTrip, dataSetAllMaterialRelatedData } from '../../../store/data/Functions';
import { uppercase, formatDateTimeToDateOnly, booleanToYesNo, formatDateTime, addObjectAttribute, removeObjectAttribute, formatMomentToDatePicker, compareDate } from '../../../services/appFunctionsService';
import { Typography, Divider, Icon, IconButton, List, TextField, Button } from '@mui/material';
import { DISPATCH_INSTRUCTION_STATUSSES, DATEPICKER_FORMAT_DEFAULT, VALID_DISPATCH_STATUS_DESTINATIONS, ADMIN_OVERRIDE_STATUSSES } from '../../../appConstants';
import { Draggable, Droppable, DragDropContext, DropResult, DroppableStateSnapshot, DragStart } from 'react-beautiful-dnd';
import posed from 'react-pose';
import CustomSelect from '../../../components/input/CustomSelect';
import { Form } from 'informed';
import jQuery from 'jquery';
import { IDropDownOptions } from '../../../@types/other';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import randomColor from 'randomcolor';
import lodash from 'lodash';
import { ICustomTableColumn } from '../../../components/table/CustomTable';
import { IMaterialDispatch } from '../../../@types/model/materialDispatch/materialDispatch';
import { ISite } from '../../../@types/model/masterData/site/site';
import { IMarket } from '../../../@types/model/masterData/market/market';
import { ITrip, Trip } from '../../../@types/model/dispatch/trip';
import { ISelectedDispatchStock } from '../../../@types/model/dispatch/selectedDispatchStock';
import { IMaterialDispatchLine } from '../../../@types/model/materialDispatch/materialDispatchLine';
import { ISetMaterialDispatchStatus } from '../../../@types/model/materialDispatch/setMaterialDispatchStatus';
import TripHttpService from '../../../services/http/trip/tripHttpService';
import MaterialDispatchHttpService from '../../../services/http/materialDispatch/materialDispatchHttpService';
import { ICarrier } from '../../../@types/model/masterData/carrier/carrier';
import Screen from '../../../components/Screen';
import TransactionFilter from '../../../components/filters/BasicTransactionScreenFilter';
import { IMaterialStock } from '../../../@types/model/materialStock/materialStock';
import FloatingActionButton from '../../../components/input/FloatingActionButton';
import { createSelector } from 'reselect';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikActions } from 'formik';
import { ITripFormValues, TripFormValues } from '../../../@types/model/dispatch/tripFormValues';
import TripForm from '../../dispatchInstructions/trip/form/TripForm';
import PillButton from '../../../components/input/PillButton';
import { CustomChangeEvent } from '../../../@types/helper';
import CustomTooltip from '../../../components/tooltip/tooltip';
import materialTheme, { getThemeMode } from '../../../styles/materialTheme';

const themeMode = getThemeMode(); // needed to apply hover effect to dispatch

interface IMaterialDispatchDashboardProps {
    dataSetDashboardDispatchSelectedLoadDate : DispatchCall<string>;
    dataSetDashboardDispatchSelectedMaterialDispatchId : DispatchCall<number | undefined>;
    materialDispatches : Array<IMaterialDispatch>;
    selectedLoadDate : string;
    sites : Array<ISite>;
    markets : Array<IMarket>;
    carriers : Array<ICarrier>;
    trips : Array<ITrip>;
    auth : IAuthState;
    selectedDispatchIndex ?: number;
    showOnlyMyDispatches : boolean;
    materialStocks : Array<IMaterialStock>;
}

interface IMaterialDispatchDashboardState {
    isLoading : boolean;
    dataFetched : boolean;
    rows : Array<IMaterialDispatch>;
    columns : Array<ICustomTableColumn>;
    showStatusBin : { [key : string] : boolean };
    collapseStatusColumn : { [key : string] : boolean };
    tripLoadDate : string;
    newTripDate : string;
    tripDriver ?: string;
    tripCarrierId ?: number;
    tripContainer ?: string;
    tripContainerTareWeight ?: number;
    showDispatchDeleteConfirmationPrompt : boolean;
    showTripDeleteConfirmationPrompt : boolean;
    deletingMaterialDispatch ?: IMaterialDispatch;
    editingTrip ?: ITrip;
    deletingTrip ?: ITrip;
    isAddingTrip : boolean;
    tripStatus : string;
    tripDescription ?: string;
    tripRegNr ?: string;
    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;
    selectableStockRows : Array<ISelectedDispatchStock>;
    newRequestedStatus ?: string;
    dispatchRequestedForMove ?: IMaterialDispatch;
    expanded : { [key : number] : boolean};
    overrideExport : boolean;
    requestSiteConfirmation ?: DropResult;
    filtersExpanded : boolean;
    tripDeleteReason : string;
}

const BinDiv = posed.div(
    {
        show : { opacity: 0, height: 0, borderWidth: 0 }, // FIXME: disabled show bin since it pops up unwantedly and isn't needed right now // opacity: 1, height: 200, borderWidth: 1,
        // transition: { duration: 100 } }, // TODO: fix height not assigned fast enough
        hide : { opacity: 0, height: 0, borderWidth: 0 },
        // transition: { duration: 300 } },
        /* show : { opacity: 0, height: 0, borderWidth: 0 },*/
    },
);

const StatusColumnDiv = posed.div(
    {
        expanded : { maxWidth: window.innerWidth },
        collapsed : { maxWidth: 40 },
    },
);

const DispatchExpansion = posed.div(
    {
        exp: {
            maxHeight: 2000,
            opacity: 1,
            paddingBottom: 5,
            transition: { duration: 160 },
        },
        col: {
            maxHeight: 0,
            opacity: 0,
            paddingBottom: 0,
            transition: { duration: 160 },
        },
    },
);

const getSiteShortDescription = (id ?: number) => {
    const state = getState();
    const sites = state.masterData.sites;
    const site = sites.find(x => x.id === id);
    return site && site.shortDescription ? uppercase(site.shortDescription) : '';
};

class MaterialDispatchDashboard extends React.Component<IMaterialDispatchDashboardProps, IMaterialDispatchDashboardState> {
    constructor(props : IMaterialDispatchDashboardProps) {
        super(props);

        this.state = {
            isLoading: false,
            dataFetched: false,
            showDispatchDeleteConfirmationPrompt: false,
            showTripDeleteConfirmationPrompt: false,
            selectedFromDate: moment().local().startOf('day'),
            selectedToDate: moment().local().endOf('day'),
            rows: [],
            columns: this.getColumns(),
            showStatusBin: {},
            collapseStatusColumn: { Trips: true },
            tripLoadDate: formatMomentToDatePicker(moment().utc().startOf('day')),
            newTripDate: formatMomentToDatePicker(moment().utc().startOf('day')),
            tripStatus: '',
            isAddingTrip: false,
            selectableStockRows: [],
            expanded: {},
            overrideExport: false,
            filtersExpanded: true,
            tripDeleteReason: '',
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        try {
            const res = await MaterialDispatchHttpService.getMaterialDispatchDashboardData(this.getDate('from'), this.getDate('to'), this.getDate('trip'));

            dataSetAllMaterialRelatedData(res.data);
            this.setState({ dataFetched: true, rows: this.filterData(), collapseStatusColumn: this.getEmptyColumns() }, () => this.setLoading(false));
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading inspection points.');
            this.setLoading(false);
        }
    };

    private getDate = (type : 'from' | 'to' | 'trip') => {
        switch (type) {
            case 'from':
                return this.state.selectedFromDate.startOf('day').utc().unix() * 1000;
            case 'to':
                return this.state.selectedToDate.endOf('day').utc().unix() * 1000;
            case 'trip':
                return moment(this.state.tripLoadDate, DATEPICKER_FORMAT_DEFAULT).utc().unix() * 1000;
        }
    };

    private getEmptyColumns = () => {
        const statusses = DISPATCH_INSTRUCTION_STATUSSES.filter(x => !this.props.materialDispatches.some(y => this.compareDate(moment(y.loadDate, DATEPICKER_FORMAT_DEFAULT), this.state.selectedFromDate, this.state.selectedToDate) && y.status === x && y.isActive));
        const returnObject : { [key : string] : boolean} = {};
        statusses.forEach(x => returnObject[x] = true);
        returnObject['Trips'] = true;
        return returnObject;
    };

    private expandNonEmptyColumns = () => {
        const currentCollapsed = { ...this.state.collapseStatusColumn };
        const nonEmpty = DISPATCH_INSTRUCTION_STATUSSES.filter(x => this.props.materialDispatches.some(y => this.compareDate(moment(y.loadDate, DATEPICKER_FORMAT_DEFAULT), this.state.selectedFromDate, this.state.selectedToDate) && y.status === x && y.isActive));
        const returnObject : { [key : string] : boolean} = {};
        lodash(currentCollapsed).forEach((x, key) => {
            if (!nonEmpty.some(y => y === key && x)) {
                returnObject[key] = true;
            }
        });
        return returnObject;
    };

    private getDispatchStockCount = (dispatchLines : Array<IMaterialDispatchLine>) => dispatchLines.length;

    private getColumns = () => {
        const columns : Array<ICustomTableColumn> = [
            { title: 'Id', field: 'id' },
            { title: 'Source Site', field: 'sourceSiteId', formatFunction: getSiteShortDescription },
            { title: 'Destination Site', field: 'destinationSiteId', formatFunction: getSiteShortDescription },
            { title: 'Load Date', field: 'loadDate', formatFunction: formatDateTimeToDateOnly, sortFunction: compareDate },
            { title: 'Pallets', field: 'dispatchLines', formatFunction: this.getDispatchStockCount },
            { title: 'Status', field: 'status' },
            { title: 'Transport', field: 'transport' },
            { title: 'Driver', field: 'driver' },
            { title: 'Trip', field: 'tripId' },
            { title: 'Is Printed?', field: 'isPrinted', formatFunction: booleanToYesNo, type: 'boolean' },
            { title: 'Created By', field: 'createdByName' },
            { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate },
            { title: 'Updated By', field: 'updatedByName' },
            { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate },
            { title: 'Active?', field: 'isActive', formatFunction: booleanToYesNo, type: 'boolean' },
        ];
        return columns;
    };

    private filterData = () => {
        const rows = [...this.props.materialDispatches];
        return rows;
    };

    private setLoading = (isLoading : boolean = false) => {
        this.setState({ isLoading });
    };

    private refreshData = async (noAnnounce ?: boolean) => {
        this.setLoading(true);
        try {
            const res = await MaterialDispatchHttpService.getMaterialDispatchDashboardData(this.getDate('from'), this.getDate('to'), this.getDate('trip'));

            dataSetAllMaterialRelatedData(res.data);
            this.setState({ rows: this.filterData() }, () => {
                this.setLoading(false);
                if (!noAnnounce) {
                    generalShowSuccessSnackbar('Data Refreshed');
                }
            });
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving dashboard data.');
            this.setLoading(false);
        }
    };

    private onDragStart = (initial : DragStart) => this.showBin(initial.source.droppableId);

    private onDragEnd = async (dropResult : DropResult) => {
        const dispatchId = dropResult.draggableId && Number(dropResult.draggableId);
        const newStatus = dropResult.destination && dropResult.destination.droppableId;
        const materialDispatch = this.props.materialDispatches.find(x => x.id === dispatchId);
        if (materialDispatch) {
            if (newStatus && newStatus.endsWith('_bin')) {
                this.deleteDispatch(materialDispatch);
                return;
            }
            this.hideBin(materialDispatch.status);
        }
        if (materialDispatch && newStatus && newStatus !== materialDispatch.status) {
            if (newStatus !== 'Draft' && (!materialDispatch.sourceSiteId || !materialDispatch.destinationSiteId)) {
                generalShowErrorSnackbar('This dispatch cannot be moved out of draft phase since a source or destination site is not supplied.');
                return;
            }
            if (!this.isDroppable(materialDispatch, true, newStatus)) {
                if (this.canAdminOverride(materialDispatch, newStatus)) {
                    this.setState({ newRequestedStatus: newStatus, dispatchRequestedForMove: materialDispatch });
                } else {
                    generalShowErrorSnackbar(`This dispatch cannot be moved to ${newStatus.toLowerCase()}.`);
                }
                return;
            }
            const oldDispatch = { ...materialDispatch };
            const newDispatch = { ...materialDispatch };
            newDispatch.status = newStatus;
            dataSetMaterialDispatch(newDispatch);
            this.setLoading(true);

            const data : ISetMaterialDispatchStatus = {
                materialDispatchId: oldDispatch.id,
                status: newStatus,
            };

            try {
                const res = await MaterialDispatchHttpService.setMaterialDispatchStatus(data);

                if (res && res.data) {
                    dataSetMaterialDispatch(res.data);
                    generalShowSuccessSnackbar(`Dispatch status successfully changed to ${newStatus.toLowerCase()}.`);
                    this.setLoading(false);
                } else {
                    generalShowErrorSnackbar('An error occurred updating the dispatch status.');
                    dataSetMaterialDispatch(oldDispatch);
                    this.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar(e?.data?.Message ? e?.data?.Message : 'An error occurred updating the dispatch status.');
                dataSetMaterialDispatch(oldDispatch);
                this.setLoading(false);
            }
        }
    };

    private onStatusOverriden = async (approved : boolean) => {
        const oldDispatch = this.state.dispatchRequestedForMove && { ...this.state.dispatchRequestedForMove };
        const newStatus = this.state.newRequestedStatus;
        this.setState({ dispatchRequestedForMove: undefined, newRequestedStatus: undefined });
        if (approved && oldDispatch && newStatus) {
            const newDispatch = { ...oldDispatch };
            newDispatch.status = newStatus;
            dataSetMaterialDispatch(newDispatch);
            newDispatch.updatedOn = undefined;
            this.setLoading(true);
            try {
                const res = await MaterialDispatchHttpService.addOrUpdateMaterialDispatch(newDispatch);

                if (res && res.data) {
                    dataSetMaterialDispatch(res.data);
                    generalShowSuccessSnackbar(`Dispatch status successfully changed to ${newStatus.toLowerCase()}.`);
                    this.setLoading(false);
                } else {
                    generalShowErrorSnackbar('An error occurred updating the dispatch status.');
                    dataSetMaterialDispatch(oldDispatch);
                    this.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar(e?.data?.Message ? e?.data?.Message : 'An error occurred updating the dispatch status.');
                dataSetMaterialDispatch(oldDispatch);
                this.setLoading(false);
            }
        }
    };

    private deleteDispatch = (deletingMaterialDispatch : IMaterialDispatch) => this.setState({ showDispatchDeleteConfirmationPrompt: true, deletingMaterialDispatch });

    private dispatchDeleteConfirmed = async () => {
        if (this.state.deletingMaterialDispatch) {
            const oldDispatch = { ...this.state.deletingMaterialDispatch };
            const newDispatch = { ...this.state.deletingMaterialDispatch };
            newDispatch.isActive = false;
            dataSetMaterialDispatch(newDispatch);
            newDispatch.updatedOn = undefined;
            this.hideBin(oldDispatch.status);
            this.setLoading(true);
            try {
                const res = await MaterialDispatchHttpService.deleteMaterialDispatch(newDispatch.id);

                if (res && res.data) {
                    dataSetMaterialDispatch(res.data);
                    generalShowSuccessSnackbar('Dispatch successfully deleted.');
                    this.setLoading(false);
                } else {
                    generalShowErrorSnackbar('An error occurred deleting the dispatch instruction.');
                    dataSetMaterialDispatch(oldDispatch);
                    this.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting the dispatch instruction.');
                dataSetMaterialDispatch(oldDispatch);
                this.setLoading(false);
            }
        }
        this.setState({ showDispatchDeleteConfirmationPrompt: false, deletingMaterialDispatch: undefined });
    };

    private dispatchDeleteDeclined = () => {
        const materialDispatch = this.state.deletingMaterialDispatch;
        if (materialDispatch) {
            this.hideBin(materialDispatch.status);
        }
        this.setState({ deletingMaterialDispatch: undefined, showDispatchDeleteConfirmationPrompt: false });
    };

    private editTrip = (editingTrip : ITrip) => this.setState({
        editingTrip,
        tripDescription: editingTrip.description,
        tripDriver: editingTrip.driver,
        tripContainer: editingTrip.container,
        tripContainerTareWeight: editingTrip.containerTareWeight,
        tripRegNr: editingTrip.registrationNumber,
        tripCarrierId: editingTrip.carrierId,
    });

    private deleteTrip = (deletingTrip : ITrip) => this.setState({ showTripDeleteConfirmationPrompt: true, deletingTrip });

    private tripDeleteConfirmed = async () => {
        if (this.state.deletingTrip) {
            const oldTrip = { ...this.state.deletingTrip };
            const newTrip = { ...this.state.deletingTrip };
            newTrip.isActive = false;
            dataSetTrip(newTrip);
            newTrip.updatedOn = undefined;
            this.setLoading(true);
            try {
                const res = await TripHttpService.addOrUpdateTrip(newTrip);

                if (res && res.data) {
                    dataSetTrip(res.data);
                    if (!res.data.isActive) {
                        this.props.materialDispatches.filter(x => x.tripId === res.data.id).forEach((x) => {
                            const newDispatch = { ...x };
                            newDispatch.tripId = undefined;
                            newDispatch.updatedByName = res.data.updatedByName;
                            newDispatch.updatedOn = res.data.updatedOn;
                            dataSetMaterialDispatch(newDispatch);
                        });
                    }
                    generalShowSuccessSnackbar('Trip successfully deleted.');
                    this.setLoading(false);
                } else {
                    generalShowErrorSnackbar('An error occurred deleting the trip.');
                    dataSetTrip(oldTrip);
                    this.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting the trip.');
                dataSetTrip(oldTrip);
                this.setLoading(false);
            }
        }
        this.setState({ showTripDeleteConfirmationPrompt: false, deletingTrip: undefined });

    };

    private OnTripDeleteReasonChange = (event : CustomChangeEvent) => {
        this.setState({ tripDeleteReason: event.currentTarget.value });
    };

    private addNewTrip = () => {
        this.setState({ isAddingTrip: true });
    };

    private addTripConfirmed = async (values : ITripFormValues) => {
        this.setLoading(true);
        try {
            const res = await TripHttpService.addOrUpdateTrip(new Trip(values));

            if (res && res.data) {
                dataSetTrip(res.data);
                this.setState({ tripLoadDate: this.state.newTripDate });
                generalShowSuccessSnackbar('Trip successfully added.');
                this.setLoading(false);
                this.tripDialogClosed();
            } else {
                generalShowErrorSnackbar('An error occurred adding the trip.');
                this.setLoading(false);
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred adding the trip.');
            this.setLoading(false);
        }
    };

    private editTripConfirmed = async (values : ITripFormValues) => {
        if (this.state.editingTrip) {
            this.setLoading(true);
            try {
                const res = await TripHttpService.addOrUpdateTrip(new Trip(values));

                if (res && res.data) {
                    dataSetTrip(res.data);
                    this.setState({ tripLoadDate: this.state.newTripDate });
                    generalShowSuccessSnackbar('Trip successfully updated.');
                    this.setLoading(false);
                    this.tripDialogClosed();
                } else {
                    generalShowErrorSnackbar('An error occurred updating the trip.');
                    this.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred adding the trip.');
                this.setLoading(false);
            }
        }
    };

    public onReset = async (formValues : ITripFormValues, formikActions : FormikActions<ITripFormValues>) => {
        formikActions.resetForm();
        this.tripDialogClosed();
    };

    private tripDialogClosed = () => {
        this.setState({ isAddingTrip: false, editingTrip: undefined, tripDriver: undefined, tripStatus: '', tripDescription: undefined, tripRegNr: undefined, tripContainer: undefined, tripContainerTareWeight: undefined, tripCarrierId: undefined });
    };

    private getDroppableStyle = (targetStatus : string, snapshot : DroppableStateSnapshot) => {
        const dispatchId = snapshot.draggingOverWith;
        const materialDispatch = this.props.materialDispatches.find(x => x.id === (dispatchId && Number(dispatchId)));
        if (materialDispatch && snapshot.isDraggingOver && !this.state.collapseStatusColumn[targetStatus]) {
            const currentStatus = materialDispatch.status;
            if (((!materialDispatch.sourceSiteId || !materialDispatch.destinationSiteId) && targetStatus !== 'Draft') || ((currentStatus !== targetStatus) && !VALID_DISPATCH_STATUS_DESTINATIONS[currentStatus].some(x => x === targetStatus))) {
                if (ADMIN_OVERRIDE_STATUSSES[currentStatus].some(x => x === targetStatus)) {
                    return { backgroundColor: 'darkorange' };
                }
                return { backgroundColor: '#ff6666', cursor: 'no-drop' };
            }
            return { backgroundColor: '#E6E6E6' };
        }
        return undefined;
    };

    private isDroppable = (materialDispatch : IMaterialDispatch, isDragging : boolean, targetStatus ?: string) => {
        if (isDragging) {
            const currentStatus = materialDispatch.status;
            return !(((!materialDispatch.sourceSiteId || !materialDispatch.destinationSiteId) && targetStatus !== 'Draft') || ((currentStatus !== targetStatus) && !VALID_DISPATCH_STATUS_DESTINATIONS[currentStatus].some(x => x === targetStatus)));
        }
        return true;
    };

    private canAdminOverride = (materialDispatch : IMaterialDispatch, newStatus : string) => {
        const currentStatus = materialDispatch.status;
        return ADMIN_OVERRIDE_STATUSSES[currentStatus].some(x => x === newStatus);
    };

    private showBin = (status : string) => {
        this.setState({ showStatusBin: addObjectAttribute(this.state.showStatusBin, status, true) });
    };

    private hideBin = (status : string) => {
        this.setState({ showStatusBin: removeObjectAttribute(this.state.showStatusBin, status) });
    };

    private collapseColumn = (status : string) => {
        this.setState({ collapseStatusColumn: addObjectAttribute(this.state.collapseStatusColumn, status, true) });
    };

    private expandColumn = (status : string) => {
        this.setState({ collapseStatusColumn: removeObjectAttribute(this.state.collapseStatusColumn, status) });
    };

    private tripOptions = (loadDate : string) => {
        if (!this.props.trips || !this.props.sites) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: 0, label: 'No Trip' });
        this.props.trips
            .filter(x => x.isActive && moment(x.loadDate, DATEPICKER_FORMAT_DEFAULT).format(DATEPICKER_FORMAT_DEFAULT).toString() ===
                moment(loadDate, DATEPICKER_FORMAT_DEFAULT).format(DATEPICKER_FORMAT_DEFAULT).toString())
            .forEach((x) => {
                returnValue.push({ value: x.id, label: '(' + x.id + ') ' + x.description });
            });
        return returnValue;
    };

    private setTripId = async (materialDispatch : IMaterialDispatch, tripId : number) => {
        const oldDispatch = { ...materialDispatch };
        const newDispatch = { ...materialDispatch };
        const trip = this.props.trips.find(x => x.id === tripId);
        newDispatch.tripId = tripId && tripId !== 0 ? tripId : undefined;
        if (trip) {
            newDispatch.driver = trip.driver;
            newDispatch.registrationNumber = trip.registrationNumber;
        }
        dataSetMaterialDispatch(newDispatch);
        newDispatch.updatedOn = undefined;
        this.setLoading(true);
        try {
            const res = await MaterialDispatchHttpService.addOrUpdateMaterialDispatch(newDispatch);

            if (res && res.data) {
                dataSetMaterialDispatch(res.data);
                generalShowSuccessSnackbar('Dispatch trip successfully changed.');
                this.setLoading(false);
            } else {
                generalShowErrorSnackbar('An error occurred updating the dispatch trip.');
                dataSetMaterialDispatch(oldDispatch);
                this.setLoading(false);
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating the dispatch trip.');
            dataSetMaterialDispatch(oldDispatch);
            this.setLoading(false);
        }

        dataSetMaterialDispatch(newDispatch);
    };

    private columnScrolled = (e : React.UIEvent<HTMLDivElement>, status : string) => {
        const scrollArea = document.getElementById(`${status}_scrollArea`);
        const scrolledValue = scrollArea && scrollArea.scrollTop;
        const fixedElements = jQuery('div[id*=dropdown]');
        fixedElements.toArray().forEach((x) => {
            x.style.marginTop = scrolledValue ? (0 - scrolledValue).toString() + 'px' : '';
        });
    };

    private handleDateRangeChange = (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => {
        const selectedFromDate = changedBy === 'start' ? moment.utc(start) : end < start ? moment.utc(end) : moment.utc(start);
        const selectedToDate = changedBy === 'end' ? moment.utc(end) : start > end ? moment.utc(start) : moment.utc(end);
        this.setState({ selectedFromDate, selectedToDate, rows: this.filterData() }, () => this.setState({ collapseStatusColumn: this.expandNonEmptyColumns() }));
    };

    private compareDate = (loadDate : moment.Moment, start : moment.Moment, end : moment.Moment) => loadDate.isBetween(start, end, 'day', '[]');

    private isExpanded = (id : number) => this.state.expanded[id];
    private toggleExpanded = (id : number, event : React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        this.setState(prevState => ({ expanded: addObjectAttribute(prevState.expanded, id, !prevState.expanded[id]) }));
    };

    private getMaterialStockDateCode = (materialStockId : number) => this.props.materialStocks.find(x => x.id === materialStockId)?.dateCode || '';

    private getCarrierName = (carrierId ?: number) => {
        const carrier = this.props.carriers.find(x => x.id === carrierId);
        return carrier?.name;
    };

    private getTrips = (props : IMaterialDispatchDashboardProps) => props.trips;
    private getTripLoadDate = (props : IMaterialDispatchDashboardProps, state : IMaterialDispatchDashboardState) => state.tripLoadDate;

    private getTripsFiltered = createSelector(
        [this.getTrips, this.getTripLoadDate],
        (trips : Array<ITrip>, tripLoadDate : string) => {
            if (!trips) return;

            return lodash.filter(trips, x => x.isActive && moment(x.loadDate, DATEPICKER_FORMAT_DEFAULT).format(DATEPICKER_FORMAT_DEFAULT).toString() ===
            moment(tripLoadDate, DATEPICKER_FORMAT_DEFAULT).format(DATEPICKER_FORMAT_DEFAULT).toString());
        },
    );

    public getSelectedTrip = (props : IMaterialDispatchDashboardProps, state : IMaterialDispatchDashboardState) => state.editingTrip;
    public getCarriers = (props : IMaterialDispatchDashboardProps) => props.carriers;

    public getInitialFormValues = createSelector(
        [this.getSelectedTrip, this.getCarriers],
        (trip, carriers) => {
            return new TripFormValues(trip, carriers);
        },
    );

    public render() {
        const initialValues = this.getInitialFormValues(this.props, this.state);
        const trips = this.getTripsFiltered(this.props, this.state);
        return (
            <Screen isPadded={false} isLoading={this.state.isLoading} isScrollable={false}>
                <div className={'fdc p10 mb20'}>
                    <TransactionFilter className='pr20' selectedFromDate={this.state.selectedFromDate} selectedToDate={this.state.selectedToDate} handleDateRangeChange={this.handleDateRangeChange} onApplyClick={this.refreshData}  />
                    <div className={'fdr h80vh'}>
                        <div className={'fdr flx1 hfill'}>
                            <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                                { DISPATCH_INSTRUCTION_STATUSSES.map((status) => {
                                    return <StatusColumnDiv style={{ backgroundColor: materialTheme.custom.panel.background }} pose={this.state.collapseStatusColumn[status] ? 'collapsed' : 'expanded'} className={'PaperBorder fdc flx1 hfill m5'} key={status}>
                                        <IconButton style={{ height: 40, width: 40, paddingTop: 8 }} onClick={() => this.expandColumn(status)} className={this.state.collapseStatusColumn[status] ? '' : 'dn'}><Icon>chevron_right</Icon></IconButton>
                                        <div className={this.state.collapseStatusColumn[status] ? 'flx1' : 'dn'}/>
                                        <Typography className={`pl10 pr10 aic fdr ${this.state.collapseStatusColumn[status] ? 'VerticalText' : 'bcp cw'}`} variant={'subtitle1'}>
                                            {status}
                                            <div className={'flx1'}/>
                                            <IconButton
                                                className={this.state.collapseStatusColumn[status] ? 'dn' : ''}
                                                onClick={() => this.collapseColumn(status)}
                                            >
                                                <Icon className={this.state.collapseStatusColumn[status] ? 'dn' : 'cw'}>chevron_left</Icon>
                                            </IconButton>
                                        </Typography>
                                        <div className={this.state.collapseStatusColumn[status] ? 'flx1' : 'dn'}/>
                                        {!this.state.collapseStatusColumn[status]  && <Droppable droppableId={status}>
                                            {(dropProvided, dropSnapshot) =>
                                                <div id={`${status}_scrollArea`} onScroll={e => this.columnScrolled(e, status)} className={`fdc flx1 oya oxh hidescroll ${this.state.collapseStatusColumn[status] ? 'dn' : ''}`} style={this.getDroppableStyle(status, dropSnapshot)} ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                                                    {dropProvided.placeholder}
                                                    {this.props.materialDispatches.filter(x =>
                                                        x.status === status
                                                    && x.isActive
                                                    && !!x.destinationSiteId
                                                    && !!x.sourceSiteId
                                                    && x.materialDispatchLines?.filter(y => y.isActive).length !== 0)
                                                        .map((materialDispatch, index) => {
                                                            const dashboardLines = materialDispatch.materialDispatchLines.filter(x => x.isActive);
                                                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                                            let allocatedAmount = 0;
                                                            dashboardLines.forEach((x) => {
                                                                allocatedAmount += x.amount;
                                                            });
                                                            return <Draggable index={index} draggableId={materialDispatch.id.toString()} key={`dispatch_${status}${materialDispatch.id.toString()}`}>
                                                                {(dragProvided, dragSnapshot) => <div
                                                                    ref={dragProvided.innerRef}
                                                                    onMouseDownCapture={() => this.showBin(materialDispatch.status)}
                                                                    onMouseUpCapture={() => this.hideBin(materialDispatch.status)}
                                                                    {...dragProvided.draggableProps}
                                                                    {...dragProvided.dragHandleProps}>
                                                                    <div className={`m5 PaperBorder fdc DispatchListItem${themeMode} ${this.isDroppable(materialDispatch, dragSnapshot.isDragging, dragSnapshot.draggingOver) ? '' : 'curnd'}`}>
                                                                        <div className={'fdr aic p5'}
                                                                            style={materialDispatch.tripId ? { backgroundColor: randomColor({
                                                                                hue: 'random',
                                                                                format: 'rgba',
                                                                                alpha: materialTheme.custom.randomColor.alphaValue,
                                                                                seed: materialDispatch.tripId * 1000000000 }) } : { backgroundColor: materialTheme.custom.panel.card.header }}
                                                                        >
                                                                            <Typography variant={'subtitle2'} className={'fdc wfill'} >
                                                                                <div className={'fdr'}>
                                                                                    <div className={'fdc'}>
                                                                                        <div className={'fs12 aic'}>#{materialDispatch.id}</div>
                                                                                        <div className={'fs12 aic'}>{formatDateTimeToDateOnly(materialDispatch.loadDate)}</div>
                                                                                    </div>
                                                                                    <div className={'flx1'}/>
                                                                                    <div className={'fdc aic'}>
                                                                                        <div className={'fs12 aic'}>{materialDispatch.materialDispatchCode}</div>
                                                                                        <div className={'aic'}>{getSiteShortDescription(materialDispatch.sourceSiteId) + ' - ' + getSiteShortDescription(materialDispatch.destinationSiteId)}</div>
                                                                                    </div>
                                                                                    <div className={'flx1'}/>
                                                                                </div>
                                                                            </Typography>
                                                                            <CustomTooltip title={'Close'}>
                                                                                <IconButton
                                                                                    className={'p0 h30 w30'}
                                                                                    onClick={() => this.deleteDispatch(materialDispatch)}
                                                                                    onMouseDownCapture={(e : React.MouseEvent<HTMLButtonElement>) => e.stopPropagation()} >
                                                                                    <Icon fontSize={'small'}>delete</Icon>
                                                                                </IconButton>
                                                                            </CustomTooltip>
                                                                        </div>
                                                                        <Divider className={'wfill mb5'} />
                                                                        <div className={'fdr aic'}>
                                                                            <div className={'flx1'}/>
                                                                            <IconButton className={'ml10 p0 h30 w30'} onClick={(event : React.MouseEvent<HTMLButtonElement>) => this.toggleExpanded(materialDispatch.id , event)}>
                                                                                <Icon fontSize={'small'}>{`${this.isExpanded(materialDispatch.id) ? 'expand_less' : 'expand_more'}`}</Icon>
                                                                            </IconButton>
                                                                        </div>
                                                                        <Divider className={'wfill mt5 mb5'} />
                                                                        { dashboardLines.map((instructionLine, lineIndex) => {
                                                                            return <DispatchExpansion pose={this.isExpanded(materialDispatch.id) ? 'exp' : 'col'} className={'fdc wfill'} key={`dispatch_line${index}_${lineIndex}`}>
                                                                                { this.isExpanded(materialDispatch.id) &&
                                                                            <div className={'fdr aic wfill hfill oya'}>
                                                                                <div>{this.getMaterialStockDateCode(instructionLine.sourceMaterialStockId)}</div>
                                                                                <div className={'flx1'} />
                                                                                <div className={'fdc'}>
                                                                                    <div className={'h30 w30 Round bcpd cs fdc fs12 aic jcc'}>
                                                                                        {instructionLine.amount}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                                }
                                                                                { (lineIndex < (dashboardLines.length - 1)) && <Divider className={'wfill mt5 mb5'} />}
                                                                            </DispatchExpansion>;
                                                                        })}
                                                                        <Divider className={`wfill mt5 mb5 ${this.isExpanded(materialDispatch.id) ? '' : 'dn'}`} />
                                                                        <Form className={'p5'}>
                                                                            <CustomSelect
                                                                                field={'tripId'}
                                                                                id={`trip_${materialDispatch.id}`}
                                                                                // onMouseDownCapture={(event : React.MouseEvent<HTMLDivElement | HTMLInputElement>) => { event.stopPropagation(); this.hideBin(status); }}
                                                                                label={'Trip'}
                                                                                className={'curd wfill'}
                                                                                value={materialDispatch.tripId ? materialDispatch.tripId : 0}
                                                                                initialValue={materialDispatch.tripId ? materialDispatch.tripId : 0}
                                                                                options={this.tripOptions(materialDispatch.loadDate)}
                                                                                onValueChange={(tripId : number) => this.setTripId(materialDispatch, tripId)}
                                                                            />
                                                                        </Form>
                                                                    </div>
                                                                </div>}
                                                            </Draggable>;
                                                        })}
                                                </div>}
                                        </Droppable>}
                                        <Droppable droppableId={status + '_bin'}>
                                            {dropProvided => <BinDiv className={'fdc aic jcc DashedBorder RoundedCorners'} pose={this.state.showStatusBin[status] ? 'show' : 'hide'} ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                                                <div className={'dn'}>{dropProvided.placeholder}</div>
                                                <Icon style={{ fontSize: 50 }} className={`cgray3 ${this.state.showStatusBin[status] ? '' : 'dn'}`}>delete</Icon>
                                            </BinDiv>}
                                        </Droppable>
                                    </StatusColumnDiv>;
                                })}
                            </DragDropContext>
                        </div>
                        {/* Trips Section */}
                        <div style={{ backgroundColor: materialTheme.custom.panel.background }} className={'PaperBorder w250 fdc hfill mr20 mt5 ml5 mb5'} key={'Trips'}>
                            <div className={'flx1'}/>
                            <Typography className={'mnh48 pl10 pr10 aic fdr bcp cw'} variant={'subtitle1'}>
                                {'Trips'}
                                <div className={'flx1'}/>
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={'Load Date'}
                                    format={DATEPICKER_FORMAT_DEFAULT}
                                    className={'m10'}
                                    value={moment(this.state.tripLoadDate)}
                                    onChange={(e : any) => {
                                        this.setState({
                                            tripLoadDate: moment(e, DATEPICKER_FORMAT_DEFAULT).format(DATEPICKER_FORMAT_DEFAULT).toString(),
                                            newTripDate: moment(e, DATEPICKER_FORMAT_DEFAULT).format(DATEPICKER_FORMAT_DEFAULT).toString(),
                                        });
                                    }}
                                />
                            </LocalizationProvider>
                            <List className={'oya hfill oxh'}>
                                {trips?.map(trip => <div key={'trip_' + trip.id}
                                    className={'PaperBorder fdc m5'}
                                    style={trip.id ? { backgroundColor: randomColor({
                                        hue: 'random',
                                        format: 'rgba',
                                        alpha: materialTheme.custom.randomColor.alphaValue,
                                        seed: trip.id * 1000000000 }) } : { backgroundColor: materialTheme.custom.panel.card.header }}
                                >
                                    <div className={'fdr aic'} >
                                        <div className={'fs12 aic pl10'}>#{trip.id}</div>
                                        <div className={'flx1'} />
                                        <IconButton className={'p0 h30 w30'} onClick={() => this.editTrip(trip)}><Icon fontSize={'small'}>edit</Icon></IconButton>
                                        <IconButton className={'p0 h30 w30'} onClick={() => this.deleteTrip(trip)}><Icon fontSize={'small'}>delete</Icon></IconButton>
                                    </div>
                                    <div className={'pt5'}>
                                        <Divider className={'wfill mt5 mb5 bcpd'} />
                                        <div className={'fdr'} >
                                            <div className={'pl10 pr10 fs12'}>Description: {trip.description}</div>
                                        </div>
                                        <div className={'fdr'} >
                                            <div className={'pl10 pr10 fs12'}>Driver: {trip.driver}</div>
                                        </div>
                                        <div className={'fdr'} >
                                            <div className={'pl10 pr10 fs12'}>Container: {trip.container}</div>
                                        </div>
                                        <div className={'fdr'} >
                                            <div className={'pl10 pr10 fs12'}>Container Tare Weight: {trip.containerTareWeight}</div>
                                        </div>
                                        <div className={'fdr'} >
                                            <div className={'pl10 pr10 fs12'}>Reg Nr: {trip.registrationNumber}</div>
                                        </div>
                                        <div className={'fdr mb5'} >
                                            <div className={'pl10 pr10 fs12'}>Carrier: {this.getCarrierName(trip.carrierId)}</div>
                                        </div>
                                    </div>
                                </div>)}
                            </List>
                            <div className={'fdr p10'}>
                                <div className={'flx1'} />
                                <FloatingActionButton
                                    color={'secondary'}
                                    size={'medium'}
                                    onClick={this.addNewTrip}
                                    disabled={this.state.isLoading}
                                />
                            </div>
                        </div>
                    </div>
                    <PackmanDialog
                        title={'Delete Trip'}
                        maxWidth={'lg'}
                        isInfo
                        isLoading={this.state.isLoading}
                        isOpen={!!this.state.showTripDeleteConfirmationPrompt}
                        onClose={() => this.setState({ showTripDeleteConfirmationPrompt: false, deletingTrip: undefined })}>
                        <div className={'fdc wfill hfill p10'}>
                            <Typography variant={'body1'}>Please give a reason for deleting this trip.</Typography>
                            <TextField
                                style={{ minWidth: 275 }}
                                name={'Delete Reason'}
                                label={'Delete Reason'}
                                className={'flx1'}
                                onChange={this.OnTripDeleteReasonChange}
                                disabled={this.state.isLoading}
                                value={this.state.tripDeleteReason}
                            />
                            <div className={'fdr pt10 pb10 wfill jcfe'}>
                                <Button
                                    className={'fwb h35'}
                                    variant='text'
                                    onClick={() => this.setState({ showTripDeleteConfirmationPrompt: false, deletingTrip: undefined })}>
                                        Cancel
                                </Button>
                                <PillButton
                                    disabled={this.state.isLoading && this.state.tripDeleteReason.length < 1}
                                    className={'ml15 pl20 pr20 h35'}
                                    text={'Submit'}
                                    color={'secondary'}
                                    onClick={this.tripDeleteConfirmed}
                                />
                            </div>
                        </div>
                    </PackmanDialog>
                    <ConfirmationPrompt title={'Delete Material Dispatch'} open={this.state.showDispatchDeleteConfirmationPrompt} message={'Are you sure you want to delete this material dispatch?'}
                        onOkClicked={this.dispatchDeleteConfirmed} onCancelClicked={this.dispatchDeleteDeclined}/>
                    <ConfirmationPrompt open={!!this.state.newRequestedStatus && !!this.state.dispatchRequestedForMove} message={`Are you sure you want to move this material dispatch to ${this.state.newRequestedStatus}?` }
                        onOkClicked={() => this.onStatusOverriden(true)} onCancelClicked={() => this.onStatusOverriden(false)} textColor={'cOrange'}/>
                    {/* Trip Edit Dialog */}
                    <PackmanDialog
                        title={'Trip'}
                        isEdit={!!this.state.editingTrip}
                        isLoading={this.state.isLoading}
                        isOpen={!!this.state.isAddingTrip || !!this.state.editingTrip}
                        onClose={this.tripDialogClosed}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={!!this.state.editingTrip ? this.editTripConfirmed : this.addTripConfirmed}
                            onReset={this.onReset}
                            enableReinitialize
                            validationSchema={TripFormValues.formSchema}
                            component={TripForm}/>
                    </PackmanDialog >
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        materialDispatches: state.data.materialDispatches,
        sites: state.masterData.sites,
        trips: state.data.trips,
        carriers: state.masterData.carriers,
        selectedLoadDate: state.data.dashboardDispatchSelectedLoadDate,
        showOnlyMyDispatches: state.data.dashboardShowOnlyMyDispatches,
        materialStocks: state.data.materialStocks,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    {
        dataSetDashboardDispatchSelectedLoadDate,
    },
    dispatcher,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MaterialDispatchDashboard);

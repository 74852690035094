import { ILot } from './lot';
import * as Yup from 'yup';
import { IOptionType } from '../../helper';
import { ICommodityState } from '../masterData/commodityState/commodityState';
import { ILotType } from '../masterData/lotType/lotType';

export interface ILotFormValues {
    commodityState ?: IOptionType;
    lotType ?: IOptionType;
}

export class LotFormValues implements ILotFormValues {
    public commodityState ?: IOptionType;
    public lotType ?: IOptionType;

    public constructor(
        lot ?: ILot,
        commodityStates ?: Array<ICommodityState>,
        lotTypes ?: Array<ILotType>,
    ) {
        this.initializeDefaultValues();
        if (lot) {
            this.commodityState = commodityStates && lot.commodityStateId ? {
                label: commodityStates.find(x => x.id === lot.commodityStateId)?.name ?? '',
                value: lot.commodityStateId,
            } : undefined;
            this.lotType = lotTypes && lot.lotTypeId ? {
                label: lotTypes.find(x => x.id === lot.lotTypeId)?.name ?? '',
                value: lot.lotTypeId,
            } : undefined;
        }
    }

    public initializeDefaultValues = () => {
        this.commodityState = undefined;
        this.lotType = undefined;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        commodityState: Yup.object().nullable(),
        lotType: Yup.object().nullable(),
    });
}

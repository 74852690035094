import * as React from 'react';
import { Link } from 'react-router-dom';
import materialTheme from '../../styles/materialTheme';


interface IPackmanMultiLinkProps {
    type : 'transactions' | 'masterdata';
    targetPage : 'stock' | 'dispatch' | 'compliance' | 'lot' | 'batch' | 'intake';
    ids ?: Array<number> | Array<string>;
    texts ?: Array<string>;
    isError ?: boolean;
    isWarning ?: boolean;
    color ?: 'red';
}

export const PackmanMultiLink = (props : IPackmanMultiLinkProps) => {

    const linkColor =
        (!!props.isError ? materialTheme.custom.packmanLink.error :
            (!!props.isWarning ? materialTheme.custom.packmanLink.error : materialTheme.custom.packmanLink.main));

    return <div>
        {props.texts ?
            props.texts?.map((x, i) => <div key={`packManLink_${x}_${i}`}>
                <Link
                    style={{ color: linkColor }}
                    // className={`packmanLink${props.color ?? ''}`}
                    to={`/${props.targetPage}/${props.type}/:${props.ids && props.ids[i]}`}>
                    {x}
                </Link>
                {i < ((props.texts?.length ?? 0) - 1) ? ', ' : ''}
            </div>)
            :
            ''}
    </div>;
};

import { IUnitOfMeasure } from './unitOfMeasure';
import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { IUnitOfMeasureType } from '../unitOfMeasureType/unitOfMeasureType';

export interface IUnitOfMeasureFormValues {
    id : number;
    guid : string;
    code : string;
    name : string;
    unitOfMeasureType ?: IOptionType;
    isActive : boolean;
}

export class UnitOfMeasureFormValues implements IUnitOfMeasureFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public name : string;
    public unitOfMeasureType ?: IOptionType;
    public isActive : boolean;

    public constructor(
        unitOfMeasure ?: IUnitOfMeasure,
        unitOfMeasureTypes ?: Array<IUnitOfMeasureType>,
    ) {
        this.initializeDefaultValues();
        if (unitOfMeasure) {
            this.id = unitOfMeasure.id;
            this.guid = unitOfMeasure.guid;
            this.code = unitOfMeasure.code;
            this.name = unitOfMeasure.name;
            this.unitOfMeasureType = unitOfMeasureTypes ? {
                label: unitOfMeasureTypes.find(x => x.id === unitOfMeasure.typeId)?.name ?? '',
                value: unitOfMeasure.typeId,
            } : undefined;
            this.isActive = unitOfMeasure.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.name = '';
        this.unitOfMeasureType = undefined;
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        unitOfMeasureType: Yup.object().nullable().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

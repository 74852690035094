import Fab from '@mui/material/Fab/Fab';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { SvgIconProps } from '@mui/material/SvgIcon';

interface IFloatingActionButtonProps {
    onClick ?: (event : React.MouseEvent<HTMLElement, MouseEvent>) => void;

    id ?: string;
    value ?: string | number | Array<string>;
    size ?: 'small' | 'medium' | 'large';
    color ?: 'inherit' | 'primary' | 'secondary' | 'default';
    className ?: string;
    children ?: React.ComponentType<SvgIconProps> | {};
    disabled ?: boolean;
}

export default class FloatingActionButton extends React.PureComponent<IFloatingActionButtonProps> {
    constructor(props : IFloatingActionButtonProps) {
        super(props);
    }

    public render() {
        const {
            className,
        } = this.props;
        const children = this.props.children ?? <AddIcon/>;

        return (
            <Fab
                id={this.props.id}
                value={this.props.value}
                color={this.props.color}
                disabled={this.props.disabled}
                size={this.props.size}
                onClick={this.props.onClick}
                className={`cpd ${this.props.size === 'small' ? 'pl5 pr5' : ''} ${this.props.disabled ? 'bcg3' : 'bca1'} ${className}`}>
                {children}
            </Fab>
        );
    }
}

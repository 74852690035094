import * as React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Collapse, Divider, Icon } from '@mui/material';

interface ISideMenuItemProps {
    icon : React.ReactElement<any>;
    title : string;
    className ?: string;
    paddingLeft : number;
    boldTitle ?: boolean;
}

interface ISideMenuItemState {
    open : boolean;
}

export default class SideMenuItem extends React.Component<ISideMenuItemProps, ISideMenuItemState> {

    constructor(props : ISideMenuItemProps) {
        super(props);

        this.state = {
            open: false,
        };

    }

    private handleClick = () => {
        this.setState({
            open: !this.state.open,
        });
    };

    public render() {
        return (
            <List className={this.props.className}>
                <ListItem button onClick={this.handleClick} className={'sideMenuButton wfill zi999 pl0 ml0 br5'} key={this.props.title}>
                    <div className={'bcg1 posa wfill hfill op30p br5'}/>
                    <ListItemIcon className={'zi999 pl20 pr20 '}>
                        {this.props.icon}
                    </ListItemIcon>
                    <ListItemText disableTypography className={`${this.props.boldTitle ? 'fw550' : ''} zi999`} primary={this.props.title} title={this.props.title} />
                    <Icon className={'zi999'}>{this.state.open ? 'expand_less' : 'expand_more'}</Icon>
                </ListItem>
                {!!this.state.open && <Divider />}
                <Collapse style={{ paddingLeft: this.props.paddingLeft }} className={this.props.className} in={this.state.open} timeout='auto' unmountOnExit>
                    <List disablePadding>
                        {this.props.children}
                    </List>
                </Collapse>
            </List>
        );
    }
}

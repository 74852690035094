import { IBaseModel } from '../../../model';
import { IFtpDetailFormValues } from './ftpDetailFormValues';

export interface IFtpDetail extends IBaseModel {
    name : string;
    address : string;
    port : number;
    username : string;
    password : string;
    path : string;
}

export class FtpDetail implements IFtpDetail {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public name : string;
    public address : string;
    public port : number;
    public username : string;
    public password : string;
    public path : string;

    public constructor(formValues : IFtpDetailFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.name = formValues.name;
        this.address = formValues.address;
        this.port = formValues.port;
        this.username = formValues.username;
        this.password = formValues.password;
        this.path = formValues.path;
    }
}

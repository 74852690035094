import { ILotType } from './lotType';
import * as Yup from 'yup';

export interface ILotTypeFormValues {
    id : number;
    guid : string;
    name : string;
    isSwappable : boolean;
    isActive : boolean;
}

export class LotTypeFormValues implements ILotTypeFormValues {
    public id : number;
    public guid : string;
    public name : string;
    public isSwappable : boolean;
    public isActive : boolean;

    public constructor(lotType ?: ILotType) {
        this.initializeDefaultValues();
        if (lotType) {
            this.id = lotType.id;
            this.guid = lotType.guid;
            this.name = lotType.name;
            this.isSwappable = lotType.isSwappable;
            this.isActive = lotType.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.name = '';
        this.isSwappable = false;
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        isSwappable: Yup.boolean().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

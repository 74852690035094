import { IMaterial } from './material';
import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { IPalletBaseType } from '../palletBaseType/palletBaseType';
import { IMaterialType } from '../materialType/materialType';
import { IPack } from '../pack/pack';
import { IUnitOfMeasure } from '../unitOfMeasure/unitOfMeasure';
import { IMaterialCost } from '../materialCost/materialCost';

export interface IMaterialFormValues {
    id : number;
    guid : string;
    code : string;
    name : string;
    palletBaseType ?: IOptionType;
    materialType ?: IOptionType;
    packs ?: Array<IOptionType>;
    materialCosts ?: Array<IMaterialCost>;
    materialCurrentCost ?: IMaterialCost;
    unitOfMeasure ?: IOptionType;
    isInnerPackaging : boolean;
    isActive : boolean;
}

export class MaterialFormValues implements IMaterialFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public name : string;
    public palletBaseType ?: IOptionType;
    public materialType ?: IOptionType;
    public unitOfMeasure ?: IOptionType;
    public packs ?: Array<IOptionType>;
    public materialCosts ?: Array<IMaterialCost>;
    public isInnerPackaging : boolean;
    public materialCurrentCost ?: IMaterialCost;
    public isActive : boolean;

    public constructor(
        material ?: IMaterial,
        palletBaseTypes ?: Array<IPalletBaseType>,
        materialTypes ?: Array<IMaterialType>,
        packs ?: Array<IPack>,
        unitOfMeasures ?: Array<IUnitOfMeasure>,
    ) {
        this.initializeDefaultValues();
        if (material) {
            this.id = material.id;
            this.guid = material.guid;
            this.code = material.code;
            this.name = material.name;

            this.palletBaseType = palletBaseTypes
                ?
                ((material.palletBaseTypeId)
                    ?
                    {
                        label: palletBaseTypes.find(x => x.id === material.palletBaseTypeId)?.name ?? '',
                        value: material.palletBaseTypeId ?? undefined,
                    }
                    : undefined)
                : undefined;

            this.materialType = materialTypes ? {
                label: materialTypes.find(x => x.id === material.materialTypeId)?.name ?? '',
                value: material.materialTypeId ?? null,
            } : undefined;

            this.packs = packs?.filter(x => material.packIds.some(y => y === x.id)).map(x => ({
                label: x.description,
                value: x.id,
            })) ?? [];

            this.unitOfMeasure = unitOfMeasures
                ?
                ((material.unitOfMeasureId)
                    ?
                    {
                        label: unitOfMeasures.find(x => x.id === material.unitOfMeasureId)?.name ?? '',
                        value: material.unitOfMeasureId ?? undefined,
                    }
                    : undefined)
                : undefined;

            this.materialCosts = material.materialCosts;
            this.materialCurrentCost = material.materialCosts?.find(x => !!x.isActive && !!x.currentlyActive);
            this.isInnerPackaging = material.isInnerPackaging;
            this.isActive = material.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.name = '';
        this.palletBaseType = undefined;
        this.materialType = undefined;
        this.unitOfMeasure = undefined;
        this.packs = [];
        this.isActive = true;
        this.materialCosts = [];
        this.materialCurrentCost = undefined;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        materialType: Yup.object().nullable().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

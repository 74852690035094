import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';

export default class FarmMasterDataSyncHttpService {
    public static getSyncStatus() : AxiosPromise<boolean> {
        return axios.get(`${PACKMAN_API_URL}PackmanFarmSync/GetSyncStatus`);
    }

    public static setSyncStatus() : AxiosPromise<boolean> {
        return axios.post(`${PACKMAN_API_URL}PackmanFarmSync/SetSyncStatus`);
    }

    public static triggerFullSync() : AxiosPromise<boolean> {
        return axios.get(`${PACKMAN_API_URL}PackmanFarmSync/FullSync`);
    }

    public static triggerLastUpdatedSync() : AxiosPromise<boolean> {
        return axios.get(`${PACKMAN_API_URL}PackmanFarmSync/LastUpdateSync`);
    }
}

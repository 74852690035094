import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IBarcodeRange } from '../../../@types/model/masterData/barcodeRange/barcodeRange';

export default class BarcodeRangeHttpService {
    public static getBarcodeRangeData() : AxiosPromise<Array<IBarcodeRange>> {
        return axios.get(`${PACKMAN_API_URL}BarcodeRange/GetAllWeb`);
    }

    public static addOrUpdateBarcodeRange(data : IBarcodeRange) : AxiosPromise<IBarcodeRange> {
        return axios.post(`${PACKMAN_API_URL}BarcodeRange/PostBarcodeRange`, data);
    }

    public static deleteBarcodeRange(barcodeRangeId : number) : AxiosPromise<IBarcodeRange> {
        return axios.delete(`${PACKMAN_API_URL}BarcodeRange/Delete`, {
            params : { barcodeRangeId },
        });
    }
}

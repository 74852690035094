import * as React from 'react';
import { Form, FormikProps } from 'formik';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import { FtpDetailFormValues } from '../../../../@types/model/masterData/ftpDetail/ftpDetailFormValues';

interface IFtpDetailFormProps {
    isLoading : boolean;
}

interface IFtpDetailFormState {
}

type FtpDetailFormPropsType = IFtpDetailFormProps & FormikProps<FtpDetailFormValues>;

export default class FtpDetailForm extends React.Component<FtpDetailFormPropsType, IFtpDetailFormState> {

    constructor(props : FtpDetailFormPropsType) {
        super(props);
    }

    public render() {
        return (
            <Form className={'p20 w300'}>
                <FormTextInput name={'name'} label={'Name'}/>
                <FormTextInput name={'address'} label={'Address'}/>
                <FormTextInput name={'port'} label={'Port'}/>
                <FormTextInput name={'username'} label={'Username'}/>
                <FormTextInput name={'password'} type={'password'} label={'Password'}/>
                <FormTextInput name={'path'} label={'Path'}/>
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

import axios, { AxiosPromise } from 'axios';
import { IPackLine } from '../../../@types/model/masterData/packLine/packLine';
import { PACKMAN_API_URL } from '../../../appConstants';

export default class PackLineHttpService {
    public static getPackLineData() : AxiosPromise<Array<IPackLine>> {
        return axios.get(`${PACKMAN_API_URL}PackLine/GetAllWeb`);
    }

    public static addOrUpdatePackLine(data : IPackLine) : AxiosPromise<IPackLine> {
        return axios.post(`${PACKMAN_API_URL}PackLine/PostPackLine`, data);
    }

    public static deletePackLine(packLineId : number) : AxiosPromise<IPackLine> {
        return axios.delete(`${PACKMAN_API_URL}PackLine/Delete`, {
            params : { packLineId },
        });
    }
}

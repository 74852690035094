import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../../appConstants';
import { ICoreFile } from '../../../../@types/model/user/coreFile';
import { IPOFileUpload } from '../../../../@types/model/dispatch/poFileUpload';
import { IPIFileUpload } from '../../../../@types/model/compliance/piFileUpload';

export default class FTPHttpService {
    // CVS export to Teraoka
    public static uploadDispatchCSV(data : { dispatchId : number }) : AxiosPromise<boolean> {
        return axios.post(`${PACKMAN_API_URL}Dispatch/UploadCSV`, data);
    }

    // export file to CORE
    public static uploadCoreFile(data : ICoreFile, dispatchId ?: number, complianceId ?: number) : AxiosPromise<boolean> {
        return axios.post(`${PACKMAN_API_URL}Dispatch/UploadCoreFile?dispatchId=${dispatchId}&complianceId=${complianceId}`, data);
    }

    // export PO file to CORE
    public static uploadPOFile(data : IPOFileUpload) : AxiosPromise<boolean> {
        return axios.post(`${PACKMAN_API_URL}Dispatch/UploadPOFile`, data);
    }

    // export PI file to CORE
    public static uploadPIFile(data : IPIFileUpload) : AxiosPromise<boolean> {
        return axios.post(`${PACKMAN_API_URL}Compliance/UploadPIFile`, data);
    }
}

import React from 'react';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import * as httpServices from '../../services/httpService';
import RefreshIcon from '@mui/icons-material/Refresh';
import { setLocalStorageSession } from '../../services/localStorageService';
import { generalShowSuccessSnackbar } from '../../store/general/Functions';
import PackmanDialog from './PackmanDialog';
import PillButton from '../input/PillButton';

interface IEmployeeNrDialogProps {
    open : boolean;
}

interface IEmployeeNrDialogState {
    isLoading : boolean;
    employeeNr : string;
}

class EmployeeNrDialog extends React.Component<IEmployeeNrDialogProps, IEmployeeNrDialogState> {

    constructor(props : IEmployeeNrDialogProps) {
        super(props);

        this.state = {
            isLoading: false,
            employeeNr: '',
        };
    }

    private handleChange = (result : string) => {
        this.setState({
            employeeNr: result.toLocaleUpperCase(),
        });
    };

    private handleRefresh = async () => {
        this.setState({
            isLoading: true,
        });

        const result = await httpServices.refreshSession();

        if (!!result) {
            setLocalStorageSession(result);
        }

        this.setState({
            isLoading: false,
        });
    };

    private handleClose = async () => {
        this.setState({
            isLoading: true,
        });

        const result = await httpServices.setEmployeeNr(this.state.employeeNr);

        if (!!result) {
            generalShowSuccessSnackbar('Employee Nr has been set successfully.');
            setLocalStorageSession(result);
        }

        this.setState({
            isLoading: false,
        });
    };

    public render() {
        const { isLoading, employeeNr } = this.state;
        return (
            <PackmanDialog
                isInfo
                isOpen={this.props.open}
                title='Enter Employee Number'>
                <div className='p20 fdc aic jcc'>
                    <div className={'fdr jcs aifs wfill'}>
                        We need your Employee Number to link you to the mobile app.
                    </div>
                    <div className={'fdr jcs aifs wfill'}>
                        <FormControl>
                            <TextField
                                required
                                error={!employeeNr}
                                autoFocus
                                margin='dense'
                                id='employeeNr'
                                label='Employee Number'
                                type='text'
                                fullWidth
                                value={employeeNr}
                                onChange={event => this.handleChange(event.target.value)}
                            />
                            {
                                !employeeNr &&
                                <FormHelperText error>Required</FormHelperText>
                            }
                        </FormControl>
                    </div>
                    <div className={'fdr aife jcfe wfill '}>
                        <Button color='primary' disabled={isLoading} onClick={this.handleRefresh}>
                            {!isLoading && <RefreshIcon style={{ marginRight: '10px' }}></RefreshIcon>}
                            {!!isLoading && <CircularProgress color='primary' size={24} style={{ marginRight: '10px' }}></CircularProgress>}
                                REFRESH
                        </Button>
                        <PillButton color={'secondary'} className={'ml15 h30 pt3 pl20 pr20 mb3'} type={'submit'} text={'SUBMIT'} onClick={this.handleClose} disabled={!!isLoading}/>
                    </div>
                </div>
            </PackmanDialog>);
    }
}

const mapStateToProps = () => {
    return {
    };
};

export default connect(
    mapStateToProps,
)(EmployeeNrDialog);

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IOrderHeader } from '../../../@types/model/order/orderHeader';

export default class OrderHttpService {
    public static getOrderHeaderData() : AxiosPromise<Array<IOrderHeader>> {
        return axios.get(`${PACKMAN_API_URL}Order/GetAllWeb`);
    }

    public static addOrUpdateOrderHeader(data : IOrderHeader) : AxiosPromise<IOrderHeader> {
        return axios.post(`${PACKMAN_API_URL}Order/PostOrder`, data);
    }
}

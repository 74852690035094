import { IBaseModel } from '../../../model';
import { IPrintServerFormValues } from './printServerFormValues';

export interface IPrintServer extends IBaseModel {
    siteId ?: number;
    name : string;
    macAddress : string;
    ipAddress : string;
    defaultScheme : string;
    primaryUrl : string;
    port : number;
    isMafrodaIntegrated : boolean;
    isOverrideFlag : boolean;
}

export class PrintServer implements IPrintServer {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public name : string;
    public macAddress : string;
    public ipAddress : string;
    public defaultScheme : string;
    public primaryUrl : string;
    public port : number;
    public siteId ?: number;
    public isMafrodaIntegrated : boolean;
    public isOverrideFlag : boolean;

    public constructor(formValues : IPrintServerFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.name = formValues.name;
        this.macAddress = formValues.macAddress;
        this.ipAddress = formValues.ipAddress;
        this.primaryUrl = formValues.primaryUrl;
        this.defaultScheme = formValues.defaultScheme;
        this.port = formValues.port;
        this.isMafrodaIntegrated = formValues.isMafrodaIntegrated;
        this.isOverrideFlag = formValues.isOverrideFlag;
        this.siteId = formValues.site ? Number(formValues.site?.value) : undefined;
        this.isActive = formValues.isActive;
    }
}

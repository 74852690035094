import { Button, Icon, Typography } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import * as React from 'react';
import {
    dataSetStocks,
} from '../../store/data/Actions';
import { connect } from 'react-redux';
import { CustomChangeEvent, IOptionType } from '../../@types/helper';
import { IDispatchInstruction } from '../../@types/model/dispatch/dispatchInstruction';
import { IDispatchView } from '../../@types/model/dispatch/dispatchView';
import { ISite } from '../../@types/model/masterData/site/site';
import { IStock } from '../../@types/model/stock/stock';
import { DispatchCall, IRootState, RootAction } from '../../@types/redux';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import PillButton from '../../components/input/PillButton';
import DispatchHttpService from '../../services/http/dispatch/dispatchHttpService';
import StockHttpService from '../../services/http/stock/stockHttpService';
import { dataSetDispatchCorrectionRelatedData, dataSetDispatchView, dataSetStock } from '../../store/data/Functions';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { Dispatch, bindActionCreators } from 'redux';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { ISize } from '../../@types/model/masterData/size/size';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { IDispatchDestinationSite } from '../../@types/model/dispatch/dispatchDestinationSite';
import moment from 'moment';
import CustomTooltip from '../../components/tooltip/tooltip';

interface IDispatchCorrectionDialogProps {
    setLoading : (isLoading ?: boolean) => void;
    closeDispatchCorrectionDialog : () => void;
    dataSetStocks : DispatchCall<Array<IStock>>;
    isLoading : boolean;
    hasDispatchCorrectionRight : boolean;
    dispatchInstructions : Array<IDispatchInstruction>;
    dispatchViews : Array<IDispatchView>;
    stocks : Array<IStock>;
    organizations : Array<IOrganization>;
    sites : Array<ISite>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    sizes : Array<ISize>;
    grades : Array<IGrade>;
}

interface IDispatchCorrectionDialogState {
    isDispatchCorrectionMoveReplaceDialogOpen : boolean;
    isDispatchCorrectionChangeDestinationDialogOpen : boolean;
    selectedStock ?: IOptionType;
    selectedDispatchForMoveOrReplace ?: IOptionType;

    isReplaceStockPopupOpen : boolean;
    dispatchOptions : Array<IDispatchView>;
    selectedDispatchToCorrect ?: IDispatchInstruction;
    replacementStockData : Array<IStock>;
    selectedReplacementStock ?: IOptionType;
    selectedDispatchForSwapAction ?: IOptionType;
    selectedDispatchLineStockForSwapAction ?: IOptionType;
    selectedDispatchForSwap ?: IDispatchInstruction;

    selectedDispatchForDestinationChange ?: IOptionType;
    selectedNewTargetOrganization ?: IOptionType;
    selectedNewDestinationSite ?: IOptionType;
}

class DispatchCorrectionDialog extends React.Component<IDispatchCorrectionDialogProps, IDispatchCorrectionDialogState> {
    constructor(props : IDispatchCorrectionDialogProps) {
        super(props);

        this.state = {
            isDispatchCorrectionMoveReplaceDialogOpen: false,
            isDispatchCorrectionChangeDestinationDialogOpen: false,

            isReplaceStockPopupOpen: false,
            dispatchOptions: [],
            replacementStockData: [],
        };
    }

    public componentDidMount = async () => {
    };

    private getOrganizations = (props : IDispatchCorrectionDialogProps) => props.organizations;
    private getSites = (props : IDispatchCorrectionDialogProps) => props.sites;
    private getDispatchViews = (props : IDispatchCorrectionDialogProps) => props.dispatchViews;
    private getDispatchInstructions = (props : IDispatchCorrectionDialogProps) => props.dispatchInstructions;
    private getStocks = (props : IDispatchCorrectionDialogProps) => props.stocks;

    private getSelectedStock = (props : IDispatchCorrectionDialogProps, state : IDispatchCorrectionDialogState) => state.selectedStock;
    private getSelectedDispatchForNewDestination = (props : IDispatchCorrectionDialogProps, state : IDispatchCorrectionDialogState) => state.selectedDispatchForDestinationChange;
    private getNewSelectedTargetOrganization = (props : IDispatchCorrectionDialogProps, state : IDispatchCorrectionDialogState) => state.selectedNewTargetOrganization;
    private getSelectedReplacementStock = (props : IDispatchCorrectionDialogProps, state : IDispatchCorrectionDialogState) => state.selectedReplacementStock;
    private getSelectedDispatchLineStockForSwapAction = (props : IDispatchCorrectionDialogProps, state : IDispatchCorrectionDialogState) => state.selectedDispatchLineStockForSwapAction;
    private getReplacementStockData = (props : IDispatchCorrectionDialogProps, state : IDispatchCorrectionDialogState) => state.replacementStockData;
    private getDispatchOptions = (props : IDispatchCorrectionDialogProps, state : IDispatchCorrectionDialogState) => state.dispatchOptions;
    private getDispatchForSwap = (props : IDispatchCorrectionDialogProps, state : IDispatchCorrectionDialogState) => state.selectedDispatchForSwap;

    private getMainStockLine = (stock : IStock) => {
        if (stock.stockLines.length > 0) {
            let mainStockLine = { ...stock.stockLines[0] };
            stock.stockLines.forEach((x) => {
                if ((mainStockLine.cartons < x.cartons) || ((mainStockLine.cartons === x.cartons) && (mainStockLine.id > x.id))) {
                    mainStockLine = x;
                }
            });

            return mainStockLine;
        }
    };

    private getSiteDescription = (id : number) => {
        const site = this.props.sites && this.props.sites.find(x => x.id === id);
        return site && site.description ? site.description : 'UNKNOWN';
    };

    private getOrganizationName = (id : number) => {
        const organization = this.props.organizations && this.props.organizations.find(x => x.id === id);
        return organization ? organization.name : 'UNKNOWN';
    };

    private getCommodityCode = (id : number) => this.props.commodities.find(x => x.id === id)?.code || '';
    private getVarietyCode = (id : number) =>  this.props.varieties.find(x => x.id === id)?.code || '';
    private getGradeCode = (gradeId : number) => this.props.grades.find(x => x.id === gradeId)?.code || '';
    private getPackCode = (packId : number) => this.props.packs.find(x => x.id === packId)?.code || '';
    private getSizeCode = (sizeId : number) => this.props.sizes.find(x => x.id === sizeId)?.code || '';

    private openDispatchCorrectionMoveReplaceDialog = async () => {
        this.setState({ isDispatchCorrectionMoveReplaceDialogOpen: true }, async () => {
            this.props.setLoading(true);
            // checks if indexedDB is available.
            const isIndexedDBAvailable = !!self.indexedDB ? true : false;

            try {
                const res = await DispatchHttpService.getDispatchCorrectionRelatedData(this.props.dispatchViews.filter(x => !!x.isActive).map(x => x.id), !isIndexedDBAvailable);
                dataSetDispatchCorrectionRelatedData(res.data);
            } catch (e) {
                generalShowErrorSnackbar('Failed to load correction related data');
            } finally {
                this.props.setLoading(false);
            }
        });
    };

    private closeDispatchCorrectionMoveReplaceDialog = () => {
        this.setState({ isDispatchCorrectionMoveReplaceDialogOpen: false, selectedStock: undefined, selectedDispatchForMoveOrReplace: undefined }, () => {
            this.props.closeDispatchCorrectionDialog();
        });
    };

    private openReplacementStockPopup = async () => {
        const dispatch = this.props.dispatchViews.find(x => x.id === this.state.selectedDispatchForMoveOrReplace?.value);
        if (!!dispatch) {
            this.setState({ isReplaceStockPopupOpen: true }, async () => {
                const originalStock = this.props.stocks.find(x => x.id === this.state.selectedStock?.value);
                if (originalStock) {
                    this.props.setLoading(true);
                    try {
                        const res = await StockHttpService.getDispatchLineReplacementStock(dispatch.id);
                        const res2 = await DispatchHttpService.getDispatch(dispatch.id);

                        if (!!this.props.hasDispatchCorrectionRight) {
                            const res3 = await DispatchHttpService.getDispatchesForSwap();
                            this.setState({ dispatchOptions: res3.data });
                        }

                        this.setState({ replacementStockData: res.data, selectedDispatchToCorrect: res2.data });
                    } catch (e) {
                        generalShowErrorSnackbar('Failed to load replacement stock data');
                        this.props.setLoading(false);
                    } finally {
                        this.props.setLoading(false);
                    }
                }
            });
        } else {
            generalShowErrorSnackbar('Please select a dispatch');
        }
    };

    private closeReplacementStockPopup = () => {
        this.setState({ isReplaceStockPopupOpen: false,
            selectedReplacementStock: undefined,
            selectedDispatchToCorrect: undefined,
            selectedDispatchForSwapAction: undefined,
            selectedDispatchLineStockForSwapAction: undefined });
    };

    private onReplacementStockChange = (e : CustomChangeEvent, selectedStock : IOptionType) => {
        this.setState({ selectedReplacementStock: selectedStock });
    };

    private onDispatchOptionChange = async (e : CustomChangeEvent, selectedDispatch : IOptionType) => {
        this.setState({ selectedDispatchForSwapAction: selectedDispatch }, async () => {
            if (!!selectedDispatch) {
                try {
                    this.props.setLoading(true);
                    const res = await DispatchHttpService.getDispatch(Number(selectedDispatch.value));
                    const res2 = await DispatchHttpService.getDispatchLineRelatedData(Number(selectedDispatch.value));

                    if (res && res2 && res.data && res2.data) {
                        res2.data.stocks.forEach((stock) => {
                            dataSetStock(stock);
                        });
                        this.setState({ selectedDispatchForSwap: res.data });
                    } else {
                        generalShowErrorSnackbar('Failed to retrieve selected dispatch data');
                    }
                } catch {
                    generalShowErrorSnackbar('Failed to retrieve selected dispatch data');
                } finally {
                    this.props.setLoading(false);
                }
            }
        });
    };

    private onDispatchLineStockChange = (e : CustomChangeEvent, selectedStock : IOptionType) => {
        this.setState({ selectedDispatchLineStockForSwapAction: selectedStock });
    };

    private onSwapDispatchLineSubmit = async () => {
        const oldStock = this.props.stocks.find(x => x.id === this.state.selectedStock?.value);
        const newStock = (!this.state.selectedDispatchForSwapAction) ? this.state.replacementStockData.find(x => x.id === this.state.selectedReplacementStock?.value) : this.props.stocks.find(x => x.id === this.state.selectedDispatchLineStockForSwapAction?.value);

        const dispatchLines = this.state.selectedDispatchToCorrect?.dispatchLines;

        const oldStockDispatchLine = dispatchLines?.find(x => x.currentStockId === oldStock?.id);

        if (oldStock && oldStockDispatchLine && newStock) {
            this.props.setLoading(true);
            try {
                const data = {
                    dispatchLineId: oldStockDispatchLine.id,
                    stockId: newStock.id,
                };

                const res = await DispatchHttpService.swapDispatchStock(data);

                if (res && res.data) {
                    res.data.forEach((x) => {
                        dataSetDispatchView(x);
                    });
                    generalShowSuccessSnackbar('Stock swapped successfully');
                    this.closeReplacementStockPopup();
                    this.closeDispatchCorrectionMoveReplaceDialog();
                }

            } catch (e) {
                generalShowErrorSnackbar('Error occured while swapping stock');
            } finally {
                this.props.setLoading(false);
            }
        }
    };

    private openDispatchCorrectionChangeDistinationDialog = async () => {
        this.setState({ isDispatchCorrectionChangeDestinationDialogOpen: true });
    };

    private closeDispatchCorrectionChangeDestinationDialog = () => {
        this.setState({ isDispatchCorrectionChangeDestinationDialogOpen: false, selectedDispatchForDestinationChange: undefined, selectedNewTargetOrganization: undefined, selectedNewDestinationSite: undefined }, () => {
            this.props.closeDispatchCorrectionDialog();
        });
    };

    private backDispatchCorrectionChangeDestinationDialog = () => {
        this.setState({ isDispatchCorrectionChangeDestinationDialogOpen: false });
    };

    private onDispatchCorrectionDispatchChange = async (e : CustomChangeEvent, selectedDispatch : IOptionType) => {
        this.setState({ selectedDispatchForMoveOrReplace: selectedDispatch }, async () => {
            const dispatch = this.props.dispatchViews.find(x => x.id === Number(selectedDispatch.value));
            if (!!dispatch) {
                try {
                    this.props.setLoading(true);
                    const res = await StockHttpService.getDispatchStocks(dispatch.id);

                    if (res && res.data) {
                        this.props.dataSetStocks(res.data);
                    }
                } catch (ex) {
                    generalShowErrorSnackbar('Failed to load related stock data');
                } finally {
                    this.props.setLoading(false);
                }
            }
        });
    };

    private onDispatchCorrectionStockChange = (e : CustomChangeEvent, selectedStock : IOptionType) => {
        this.setState({ selectedStock });
    };

    private onDispatchLineRevert = async () => {
        const dispatch = this.props.dispatchInstructions.find(x => x.id === this.state.selectedDispatchForMoveOrReplace?.value);
        const stock = this.props.stocks.find(x => x.id === this.state.selectedStock?.value);

        if (!!stock && !!dispatch) {
            this.props.setLoading(true);
            try {
                const data = {
                    dispatchId: dispatch.id,
                    stockId: stock.id,
                };

                const res = await DispatchHttpService.revertDispatchLine(data);

                if (res && res.data) {
                    dataSetDispatchView(res.data);
                    this.closeDispatchCorrectionMoveReplaceDialog();
                    generalShowSuccessSnackbar('Successfully reverted dispatch stock');
                }
            } catch (e) {
                generalShowErrorSnackbar('Error occured reverting selected dispatch stock');
            } finally {
                this.props.setLoading(false);
            }
        } else if (!dispatch) {
            generalShowErrorSnackbar('Please select a dispatch');
        } else {
            generalShowErrorSnackbar('Please select a stock');
        }
    };

    private onSelectedDisaptchForNewDestinationChange = (e : CustomChangeEvent, selectedDispatchForDestinationChange : IOptionType) => {
        const selectedDispatch = this.props.dispatchViews.find(x => x.id === selectedDispatchForDestinationChange?.value);

        if (!!selectedDispatch) {
            const targetOrganization = this.props.organizations.find(x => x.id === selectedDispatch?.targetOrganizationId);
            let targetOrgOption : IOptionType | undefined;

            if (targetOrganization) {
                targetOrgOption = { label: `(${targetOrganization?.code}) ${targetOrganization?.name}`, value: targetOrganization?.id };
            }

            this.setState({ selectedDispatchForDestinationChange, selectedNewTargetOrganization: targetOrgOption });
        } else {
            this.setState({ selectedDispatchForDestinationChange, selectedNewTargetOrganization: undefined, selectedNewDestinationSite: undefined });
        }
    };

    private onNewTargetOrganizationChange = (e : CustomChangeEvent, selectedNewTargetOrganization : IOptionType) => {
        this.setState({ selectedNewTargetOrganization, selectedNewDestinationSite: undefined });
    };

    private onNewDestinationSiteChange = (e : CustomChangeEvent, selectedNewDestinationSite : IOptionType) => {
        this.setState({ selectedNewDestinationSite });
    };

    private onDispatchDestinationSiteChangeSubmit = async () => {
        const selectedDispatch = this.props.dispatchViews.find(x => x.id === this.state.selectedDispatchForDestinationChange?.value);
        if (this.state.selectedDispatchForDestinationChange && selectedDispatch && this.state.selectedNewTargetOrganization && this.state.selectedNewDestinationSite) {
            if (selectedDispatch.targetOrganizationId === this.state.selectedNewTargetOrganization.value && selectedDispatch.destinationSiteId === this.state.selectedNewDestinationSite.value) {
                generalShowErrorSnackbar('New Selected Site matches selected dispatch destination site');
                return;
            }
            try {
                this.props.setLoading(true);
                const data : IDispatchDestinationSite = {
                    dispatchId: Number(this.state.selectedDispatchForDestinationChange.value),
                    newTargetOrganizationId: Number(this.state.selectedNewTargetOrganization.value),
                    newDestinationSiteId: Number(this.state.selectedNewDestinationSite.value),
                };
                const res = await DispatchHttpService.updateDispatchDestinationSite(data);

                if (res && res.data) {
                    dataSetDispatchView(res.data);
                    this.closeDispatchCorrectionChangeDestinationDialog();
                    generalShowSuccessSnackbar('Dispatch Updated Successfully!');
                }
            } catch (err) {
                generalShowErrorSnackbar(err?.data?.Message);
            } finally {
                this.props.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Selected target organization or destination site not found');
        }
    };

    private getDispatchCorrectionDispatchOptions = createSelector(
        [this.getDispatchInstructions],
        (dispatches : Array<IDispatchInstruction>) => {
            if (!dispatches) return [];

            return dispatches.filter(x => x.isActive && !!x.dispatchCode)?.map((x) => {
                return { label: x.dispatchCode ?? '', value: x.id };
            });
        },
    );

    private getDispatchCorrectionStockOptions = createSelector(
        [this.getStocks, this.getDispatchInstructions],
        (stockData : Array<IStock>, dispatches : Array<IDispatchInstruction>) => {
            if (!stockData) return [];

            return stockData.filter(x => x.isActive && dispatches.some(y => y.dispatchLines?.some(z => x.id === z.currentStockId)))?.map((x) => {
                return { label: x.barcode, value: x.id };
            });
        },
    );

    private getSelectedStockDetailsFdc = createSelector(
        [this.getStocks, this.getSelectedStock],
        (stocks, selectedStock) => {
            const stock = stocks.find(x => x.id === selectedStock?.value);
            if (stock) {
                const mainStockLine = this.getMainStockLine(stock);
                return (
                    <div className={'fdc pl10'}>
                        <div>{`Commodity: ${mainStockLine ? this.getCommodityCode(mainStockLine.commodityId) : ''}`}</div>
                        <div>{`Variety: ${mainStockLine ? this.getVarietyCode(mainStockLine.varietyId) : ''}`}</div>
                        <div>{`Pack: ${mainStockLine ? this.getPackCode(mainStockLine.packId) : ''}`}</div>
                        <div>{`Grade: ${mainStockLine ? this.getGradeCode(mainStockLine.gradeId) : ''}`}</div>
                        <div>{`Size: ${mainStockLine ? this.getSizeCode(mainStockLine.sizeId) : ''}`}</div>
                        <div>{`Total Cartons: ${stock.cartons}`}</div>
                        <div>{`Gross Weight(kg): ${stock.grossWeight.toFixed(3)}`}</div>
                    </div>
                );
            }
        },
    );

    private getSelectedStockDetailsFdr = createSelector(
        [this.getStocks, this.getSelectedStock],
        (stocks, selectedStock) => {
            const stock = stocks.find(x => x.id === selectedStock?.value);
            if (stock) {
                const mainStockLine = this.getMainStockLine(stock);
                return (
                    <div className={'fdr wfill'}>
                        <div className={'pr5'}>{`Commodity: ${mainStockLine ? this.getCommodityCode(mainStockLine.commodityId) : ''}`}</div>
                        <div className={'pr5'}>{`Variety: ${mainStockLine ? this.getVarietyCode(mainStockLine.varietyId) : ''}`}</div>
                        <div className={'pr5'}>{`Pack: ${mainStockLine ? this.getPackCode(mainStockLine.packId) : ''}`}</div>
                        <div className={'pr5'}>{`Grade: ${mainStockLine ? this.getGradeCode(mainStockLine.gradeId) : ''}`}</div>
                        <div className={'pr5'}>{`Size: ${mainStockLine ? this.getSizeCode(mainStockLine.sizeId) : ''}`}</div>
                        <div className={'pr5'}>{`Total Cartons: ${stock.cartons}`}</div>
                        <div className={'pr5'}>{`Gross Weight(kg): ${stock.grossWeight.toFixed(3)}`}</div>
                    </div>
                );
            }
        },
    );

    private getReplacementStockDetailsFdr = createSelector(
        [this.getStocks, this.getSelectedReplacementStock, this.getSelectedDispatchLineStockForSwapAction],
        (stocks, selectedReplacementStock, selectedStockFromOtherDispatch) => {
            const stock = stocks.find(x => selectedStockFromOtherDispatch ? x.id === selectedStockFromOtherDispatch?.value : x.id === selectedReplacementStock?.value);
            if (stock) {
                const mainStockLine = this.getMainStockLine(stock);
                return (
                    <div className={'fdr wfill'}>
                        <div className={'pr5'}>{`Commodity: ${mainStockLine ? this.getCommodityCode(mainStockLine.commodityId) : ''}`}</div>
                        <div className={'pr5'}>{`Variety: ${mainStockLine ? this.getVarietyCode(mainStockLine.varietyId) : ''}`}</div>
                        <div className={'pr5'}>{`Pack: ${mainStockLine ? this.getPackCode(mainStockLine.packId) : ''}`}</div>
                        <div className={'pr5'}>{`Grade: ${mainStockLine ? this.getGradeCode(mainStockLine.gradeId) : ''}`}</div>
                        <div className={'pr5'}>{`Size: ${mainStockLine ? this.getSizeCode(mainStockLine.sizeId) : ''}`}</div>
                        <div className={'pr5'}>{`Total Cartons: ${stock.cartons}`}</div>
                        <div className={'pr5'}>{`Gross Weight(kg): ${stock.grossWeight.toFixed(3)}`}</div>
                    </div>
                );
            }
        },
    );

    private getDispatchesForDestinationChangeOptions = createSelector(
        [this.getDispatchViews],
        (dispatches : Array<IDispatchView>) => {
            if (!dispatches) return [];
            return dispatches.filter(x => x.isActive && !!x.dispatchCode && x.dispatchCode !== '')?.map((x) => {
                return { label: x.dispatchCode ?? '', value: x.id };
            });
        },
    );

    private getNewTargetOrganizationOptions = createSelector(
        [this.getOrganizations],
        (organizations : Array<IOrganization>) => {
            if (!organizations) return [];

            return organizations.filter(x => x.isActive)?.map((x) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            });
        },
    );

    private getNewDestinationSiteOptions = createSelector(
        [this.getSites, this.getDispatchInstructions, this.getSelectedDispatchForNewDestination, this.getNewSelectedTargetOrganization],
        (sites : Array<ISite>, dispatches : Array<IDispatchInstruction>, selectedDispatchForDestinationChange, selectedNewTargetOrganization) => {
            if (!sites) return [];

            const selectedDispatch : IDispatchView | undefined = dispatches.find(x => x.id === selectedDispatchForDestinationChange?.value);

            return sites.filter(x => x.isActive
                && x.id !== selectedDispatch?.sourceSiteId
                && x.organizationIds.some(y => !!selectedNewTargetOrganization ? y === selectedNewTargetOrganization.value : y === selectedDispatch?.targetOrganizationId))?.map((x) => {
                return { label: `(${x.shortDescription}) ${x.description}`, value: x.id };
            });
        },
    );

    private closeDispatchCorrectionMoveReplaceDialogOpen = () => this.setState({ isDispatchCorrectionMoveReplaceDialogOpen: false });

    private getSelectedDispatch = createSelector(
        [this.getDispatchViews, this.getSelectedDispatchForNewDestination],
        (dispatches : Array<IDispatchView>, selectedDispatchForDestinationChange) => {
            const selectedDispatch = dispatches.find(x => x.id === selectedDispatchForDestinationChange?.value);

            return selectedDispatch;
        },
    );

    private getReplacementStockOptions = createSelector(
        [this.getStocks, this.getReplacementStockData, this.getSelectedStock],
        (stocks : Array<IStock>, replacementStockData : Array<IStock>, selectedStock : IOptionType) => {
            if (!replacementStockData) return [];

            const originalStock = stocks.find(x => x.id === selectedStock.value);

            return replacementStockData.filter(x => x.id !== originalStock?.id)
                .map((x) => {
                    return { label: x.barcode, value: x.id };
                });
        },
    );

    private getDispatchDropDownOptions = createSelector(
        [this.getDispatchOptions],
        (dispatches : Array<IDispatchView>) => {
            return dispatches.filter(x => x.isActive).sort((a : IDispatchView, b : IDispatchView) => moment(b.createdOn).diff(moment(a.createdOn))).map((x) => {
                return { label: x.dispatchCode ?? 'No Dispatch Code', value: x.id };
            });
        },
    );

    private getDispatchLineStockOptions = createSelector(
        [this.getDispatchForSwap, this.getStocks, this.getSelectedStock],
        (dispatch : IDispatchInstruction, stocks : Array<IStock>) => {
            const dispatchLineStockIds = dispatch?.dispatchLines?.filter(x => x.isActive)?.map(x => x.currentStockId);

            const currentStocks = stocks.filter(x => dispatchLineStockIds?.some(y => y === x.id));

            return currentStocks?.map((x) => {
                return { label: x.barcode, value: x.id };
            });
        },
    );

    public render() {
        return (
            <div>
                <div className={'fdc aic jcc p10 w400 h150 bcg2'}>
                    <PillButton
                        disabled={!!this.props.isLoading}
                        className={'pl10 pr10 mr10 h35 w200 reducedPillButtonShadow'}
                        text={'Move & Replace'}
                        color={'secondary'}
                        onClick={this.openDispatchCorrectionMoveReplaceDialog}
                    />
                    <div className={'h20'}/>
                    <PillButton
                        disabled={!!this.props.isLoading}
                        className={'pl10 pr10 mr10 h35 w200 reducedPillButtonShadow'}
                        text={'Change Destination'}
                        color={'secondary'}
                        onClick={this.openDispatchCorrectionChangeDistinationDialog}
                    />
                </div>
                { !!this.state.isDispatchCorrectionMoveReplaceDialogOpen &&
                    <PackmanDialog
                        title={'Dispatch Correction - Move & Replace'}
                        maxWidth={'sm'}
                        isInfo
                        isLoading={this.props.isLoading}
                        isOpen={this.state.isDispatchCorrectionMoveReplaceDialogOpen}
                        onClose={this.closeDispatchCorrectionMoveReplaceDialog}>
                        <div className={'fdc p10 mnh400 h400 bcg2'}>
                            <div className={'fdc jcc aic wfill'}>
                                <AutocompleteSelect
                                    className={'w350'}
                                    name={'dispatch'}
                                    label={'Dispatch'}
                                    placeholder={'Select A Dispatch...'}
                                    options={this.getDispatchCorrectionDispatchOptions(this.props) ?? []}
                                    onChange={this.onDispatchCorrectionDispatchChange}
                                    value={this.state.selectedDispatchForMoveOrReplace}
                                />
                                <AutocompleteSelect
                                    className={'w350'}
                                    name={'stock'}
                                    label={'Stock'}
                                    placeholder={'Select A Stock...'}
                                    options={this.getDispatchCorrectionStockOptions(this.props) ?? []}
                                    onChange={this.onDispatchCorrectionStockChange}
                                    value={this.state.selectedStock}
                                />
                            </div>
                            <div className={'fdc pl30 pt10 flx1 wfill'}>
                                {this.getSelectedStockDetailsFdc(this.props, this.state)}
                            </div>
                            <div className={'fdr aic jcc wfill pt10 pr10'}>
                                <CustomTooltip title={'Go Back'}>
                                    <PillButton
                                        disabled={!!this.props.isLoading}
                                        className={'pl20 pr20 h35'}
                                        text={'Go Back'}
                                        color={'secondary'}
                                        onClick={this.closeDispatchCorrectionMoveReplaceDialogOpen}
                                    />
                                </CustomTooltip>
                                <div className={'w10'}/>
                                <CustomTooltip title={(!this.state.selectedStock || !this.state.selectedDispatchForMoveOrReplace) ? 'Please select a dispatch and stock' : 'Revert Dispatch Line'}>
                                    <PillButton
                                        disabled={this.props.isLoading || !this.state.selectedStock || !this.state.selectedDispatchForMoveOrReplace}
                                        className={'pl20 pr20 h35'}
                                        text={'Reverse'}
                                        color={'secondary'}
                                        iconPosition={'start'}
                                        icon={<Icon className={'mr10'}>undo</Icon>}
                                        onClick={this.onDispatchLineRevert}
                                    />
                                </CustomTooltip>
                                <div className={'w10'}/>
                                <CustomTooltip title={(!this.state.selectedStock || !this.state.selectedDispatchForMoveOrReplace) ? 'Please select a dispatch and stock' : 'Swap stock with replacement stock'}>
                                    <PillButton
                                        disabled={this.props.isLoading || !this.state.selectedStock || !this.state.selectedDispatchForMoveOrReplace}
                                        className={'h35'}
                                        text={'Swap'}
                                        color={'secondary'}
                                        iconPosition={'start'}
                                        icon={<Icon className={'mr10'}>find_replace</Icon>}
                                        onClick={this.openReplacementStockPopup}
                                    />
                                </CustomTooltip>
                            </div>
                        </div>
                    </PackmanDialog >
                }
                { !!this.state.isDispatchCorrectionChangeDestinationDialogOpen &&
                    <PackmanDialog
                        title={'Dispatch Correction - Change Destination'}
                        maxWidth={'lg'}
                        isInfo
                        isLoading={this.props.isLoading}
                        isOpen={this.state.isDispatchCorrectionChangeDestinationDialogOpen}
                        onClose={this.closeDispatchCorrectionChangeDestinationDialog}>
                        <div className={'fdc p10 bcg2'}>
                            <div className={'fdr wfill flx1'}>
                                <div className={'fdc flx1'}>
                                    <AutocompleteSelect
                                        className={'w250'}
                                        name={'dispatch'}
                                        label={'Dispatch'}
                                        placeholder={'Select A Dispatch...'}
                                        options={this.getDispatchesForDestinationChangeOptions(this.props) ?? []}
                                        onChange={this.onSelectedDisaptchForNewDestinationChange}
                                        value={this.state.selectedDispatchForDestinationChange}
                                    />
                                    <div className={!!this.state.selectedDispatchForDestinationChange ? 'fdc pt10 pl10' : 'dn'}>
                                        <Typography variant={'body2'} className={'mb10'}>Target Organization: {this.getOrganizationName(this.getSelectedDispatch(this.props, this.state)?.targetOrganizationId ?? 0)}</Typography>
                                        <Typography variant={'body2'}>Destination Site: {this.getSiteDescription(this.getSelectedDispatch(this.props, this.state)?.destinationSiteId ?? 0)}</Typography>
                                    </div>
                                </div>
                                <div className={'w10'}/>
                                <div className={'fdc flx1 hfill'}>
                                    <AutocompleteSelect
                                        className={'w250'}
                                        name={'site'}
                                        label={'New Target Organization'}
                                        placeholder={'Select A organization...'}
                                        options={this.getNewTargetOrganizationOptions(this.props) ?? []}
                                        onChange={this.onNewTargetOrganizationChange}
                                        value={this.state.selectedNewTargetOrganization}
                                        disabled={!this.state.selectedDispatchForDestinationChange}
                                    />
                                    <Typography variant={'body2'} className={'fdr aic jcc mt10'}>AND / OR</Typography>
                                    <AutocompleteSelect
                                        className={'w250'}
                                        name={'site'}
                                        label={'New Destination Site'}
                                        placeholder={'Select A Site...'}
                                        options={this.getNewDestinationSiteOptions(this.props, this.state) ?? []}
                                        onChange={this.onNewDestinationSiteChange}
                                        value={this.state.selectedNewDestinationSite}
                                        disabled={!this.state.selectedDispatchForDestinationChange || !this.state.selectedNewTargetOrganization}
                                    />
                                </div>
                            </div>
                            <div className={'fdr aic jcfe wfill pt10 pr10'}>
                                <CustomTooltip title={'Go Back'}>
                                    <PillButton
                                        disabled={!!this.props.isLoading}
                                        className={'pl20 pr20 h35'}
                                        text={'Go Back'}
                                        color={'secondary'}
                                        onClick={this.backDispatchCorrectionChangeDestinationDialog}
                                    />
                                </CustomTooltip>
                                <div className={'w10'}/>
                                <Button
                                    className={'fwb h35'}
                                    variant='text'
                                    color='primary'
                                    onClick={this.closeDispatchCorrectionChangeDestinationDialog}>
                                    Cancel
                                </Button>
                                <div className={'w10'}/>
                                <PillButton
                                    disabled={this.props.isLoading || (!!this.state.selectedNewTargetOrganization && !this.state.selectedNewDestinationSite) || !this.state.selectedNewDestinationSite}
                                    className={'pl20 pr20 h35'}
                                    text={'Submit'}
                                    color={'secondary'}
                                    onClick={this.onDispatchDestinationSiteChangeSubmit}
                                />
                            </div>
                        </div>
                    </PackmanDialog >
                }
                { !!this.state.isReplaceStockPopupOpen &&
                    <PackmanDialog
                        title={'Replacement Stock'}
                        isInfo
                        maxWidth={'md'}
                        isLoading={this.props.isLoading}
                        isOpen={this.state.isReplaceStockPopupOpen}
                        onClose={this.closeReplacementStockPopup}>
                        <div className={'fdc p10 bcg2 aic jcc'}>
                            <Typography>Selected stock</Typography>
                            <div className={'fdr'}>{this.getSelectedStockDetailsFdr(this.props, this.state)}</div>
                            <Typography>Replacement stock</Typography>
                            <div className={'fdr'}>{this.getReplacementStockDetailsFdr(this.props, this.state)}</div>
                            <div className={'h20'}/>
                            <div className={'fdr wfill'}>
                                <CustomTooltip title={`${!!this.state.selectedDispatchForSwapAction ? 'Already in process of selecting stock from another dispatch' : ''}`}>
                                    <AutocompleteSelect
                                        className={'w350'}
                                        name={'replacementStock'}
                                        label={'Replacement Stock'}
                                        placeholder={'Select A Replacement Stock...'}
                                        options={this.getReplacementStockOptions(this.props, this.state) ?? []}
                                        onChange={this.onReplacementStockChange}
                                        value={this.state.selectedReplacementStock}
                                        disabled={!!this.state.selectedDispatchForSwapAction}
                                    />
                                </CustomTooltip>
                                <div className={`${!!this.props.hasDispatchCorrectionRight ? 'fdc' : 'dn'}`}>
                                    <AutocompleteSelect
                                        className={'w350'}
                                        name={'dispatch'}
                                        label={'Dispatch'}
                                        placeholder={'Select A Dispatch...'}
                                        options={this.getDispatchDropDownOptions(this.props, this.state) ?? []}
                                        onChange={this.onDispatchOptionChange}
                                        value={this.state.selectedDispatchForSwapAction}
                                        disabled={!!this.state.selectedReplacementStock}
                                    />
                                    <AutocompleteSelect
                                        className={'w350'}
                                        name={'dispatchLineStocks'}
                                        label={'Stock'}
                                        placeholder={'Select Stock...'}
                                        options={this.getDispatchLineStockOptions(this.props, this.state) ?? []}
                                        onChange={this.onDispatchLineStockChange}
                                        value={this.state.selectedDispatchLineStockForSwapAction}
                                        disabled={!!this.state.selectedReplacementStock}
                                    />
                                </div>
                            </div>
                            <div className={'h20'}/>
                            <div className={'fdr jcfe'}>
                                {!!this.props.hasDispatchCorrectionRight ?
                                    <PillButton
                                        disabled={!this.state.selectedReplacementStock && !this.state.selectedDispatchLineStockForSwapAction}
                                        className={'pl10 pr10 mr10 h35 w200 reducedPillButtonShadow'}
                                        text={'SUBMIT'}
                                        color={'secondary'}
                                        onClick={this.onSwapDispatchLineSubmit}
                                    />
                                    :
                                    <PillButton
                                        disabled={!this.state.selectedReplacementStock}
                                        className={'pl10 pr10 mr10 h35 w200 reducedPillButtonShadow'}
                                        text={'SUBMIT'}
                                        color={'secondary'}
                                        onClick={this.onSwapDispatchLineSubmit}
                                    />
                                }
                            </div>
                        </div>
                    </PackmanDialog >
                }
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        dispatchInstructions: state.data.dispatchInstructions,
        dispatchViews: state.data.dispatchViews,
        stocks: state.data.stocks,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        sizes: state.masterData.sizes,
        colours: state.masterData.colours,
        grades: state.masterData.grades,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    {
        dataSetStocks,
    },
    dispatcher,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DispatchCorrectionDialog);

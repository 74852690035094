import { IFarm } from './farm';
import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { IOrganization } from '../organization/organization';
import { IContactInfo } from '../contactInfo/contactInfo';
import { IAccreditation } from '../accreditation/accreditation';
import { IFtpDetail } from '../ftpDetail/ftpDetail';

export interface IFarmFormValues {
    id : number;
    guid : string;
    isActive : boolean;

    code : string;
    name : string;
    organizations : Array<IOptionType>;
    ftpDetails : Array<IOptionType>;
    contactInfos : Array<IOptionType>;
    globalGapNumber ?: string;
    address ?: string;
    telNumber ?: string;
    faxNumber ?: string;
    bankAccount ?: string;
    bankName ?: string;
    extendedDescription ?: string;
    vat ?: string;
    exportedCode ?: string;
    exportedDescription ?: string;
    accreditation ?: IOptionType;
}

export class FarmFormValues implements IFarmFormValues {
    public id : number;
    public guid : string;
    public isActive : boolean;

    public code : string;
    public name : string;
    public organizations : Array<IOptionType>;
    public ftpDetails : Array<IOptionType>;
    public contactInfos : Array<IOptionType>;
    public globalGapNumber ?: string;
    public address ?: string;
    public telNumber ?: string;
    public faxNumber ?: string;
    public bankAccount ?: string;
    public bankName ?: string;
    public extendedDescription ?: string;
    public vat ?: string;
    public exportedCode ?: string;
    public exportedDescription ?: string;
    public accreditation ?: IOptionType;

    public constructor(
        farm ?: IFarm,
        organizations ?: Array<IOrganization>,
        ftpDetails ?: Array<IFtpDetail>,
        contactInfos ?: Array<IContactInfo>,
        accreditations ?: Array<IAccreditation>,
    ) {
        this.initializeDefaultValues();
        if (farm) {
            this.id = farm.id;
            this.guid = farm.guid;
            this.code = farm.code;
            this.name = farm.name;
            this.isActive = farm.isActive;

            this.organizations = organizations?.filter(x => farm.organizationIds.some(y => y === x.id)).map(x => ({
                label: x.name,
                value: x.id,
            })) ?? [];

            this.ftpDetails = ftpDetails?.filter(x => farm.ftpDetailIds.some(y => y === x.id)).map(x => ({
                label: x.name,
                value: x.id,
            })) ?? [];

            this.contactInfos = contactInfos?.filter(x => farm.contactInfoIds.some(y => y === x.id)).map(x => ({
                label: x.name,
                value: x.id,
            })) ?? [];

            this.accreditation = accreditations ? {
                label: accreditations.find(x => x.id === farm.accreditationId)?.name ?? '',
                value: farm.accreditationId,
            } : undefined;

            this.globalGapNumber = farm.globalGapNumber;
            this.address = farm.address;
            this.telNumber = farm.telNumber;
            this.faxNumber = farm.faxNumber;
            this.bankAccount = farm.bankAccount;
            this.bankName = farm.bankName;
            this.extendedDescription = farm.extendedDescription;
            this.vat = farm.vat;
            this.exportedCode = farm.exportedCode;
            this.exportedDescription = farm.exportedDescription;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.name = '';
        this.isActive = true;

        this.organizations = [];
        this.ftpDetails = [];
        this.contactInfos = [];
        this.globalGapNumber = '';
        this.address = '';
        this.telNumber = '';
        this.faxNumber = '';
        this.bankAccount = '';
        this.bankName = '';
        this.extendedDescription = '';
        this.vat = '';
        this.exportedCode = '';
        this.exportedDescription = '';
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        organizations: Yup.array().of(Yup.object()).min(1, 'Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

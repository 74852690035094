import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState, RootAction } from '../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Typography } from '@mui/material';
import IntakeLineLayerUnitSummary from './IntakeLineLayerUnitSummary';
import { IIntakeLine } from '../../@types/model/intake/intakeLine';
import { IStock } from '../../@types/model/stock/stock';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import { IIntakeLineLayer } from '../../@types/model/intake/intakeLineLayer';
import CustomTable from '../../components/table/CustomTable';
import { addkg, formatDateTime, compareDate, booleanToYesNo } from '../../services/appFunctionsService';
import { ILot } from '../../@types/model/lot/lot';
import { ICommodityState } from '../../@types/model/masterData/commodityState/commodityState';
import { PackmanLink } from '../../components/link/packmanLink';
import { IProject } from '../../@types/model/masterData/project/project';

interface IIntakeLineLayerSummaryProps {
    line : IIntakeLine;
    setLoading : (loading : boolean) => void;
    refreshData : () => void;
    isLoading : boolean;
    stockData : Array<IStock>;
    packs : Array<IPack>;
    varieties : Array<IVariety>;
    orchards : Array<IOrchard>;
    projects : Array<IProject>;
    layers : Array<IIntakeLineLayer>;
    lots : Array<ILot>;
    commodityStates : Array<ICommodityState>;
}

interface IIntakeLineLayerSummaryState {
    showDialog : boolean;
    rows : Array<IIntakeLineLayer>;
}

class IntakeLineLayerSummary extends React.Component<IIntakeLineLayerSummaryProps, IIntakeLineLayerSummaryState> {
    constructor(props : IIntakeLineLayerSummaryProps) {
        super(props);

        this.state = {
            showDialog: false,
            rows: this.props.layers,
        };
    }

    private getOrchardDetail = (orchardId : number) => {
        const orchards = this.props.orchards;
        const orchard = orchards && orchards.find(x => x.id === orchardId);
        return orchard ? orchard.code + ' - ' + orchard.name : '';
    };

    private getProjectDetail = (projectId : number) => {
        const projects = this.props.projects;
        const project = projects && projects.find(x => x.id === projectId);
        return project ? project.number + ' - ' + project.description : '';
    };

    private getPackCode = (packId : number) => {
        const packs = this.props.packs;
        const pack = packs && packs.find(x => x.id === packId);
        return pack ? pack.code : '';
    };

    private getVarietyName = (varietyId : number) => {
        const varieties = this.props.varieties;
        const variety = varieties && varieties.find(x => x.id === varietyId);
        return variety ? variety.name : '';
    };

    private getLotCommodityState = (lotId : number) => {
        const lots = this.props.lots;
        const lot = lots && lots.find(x => x.id === lotId);
        return lot ? this.getCommodityStateName(lot.commodityStateId) : '';
    };

    private getCommodityStateName = (commodityStateId ?: number) => {
        const commodityStates = this.props.commodityStates;
        const commodityState = commodityStates && commodityStates.find(x => x.id === commodityStateId);
        return commodityState ? commodityState.name : '';
    };

    private formatLotIdLink = (lotId : number) => {
        return <PackmanLink
            type={'transactions'}
            targetPage={'lot'}
            id={Number(lotId)}
            text={lotId} />;
    };

    private formatLotNameLink = (lotName : number, row : IIntakeLineLayer) => {
        return <PackmanLink
            type={'transactions'}
            targetPage={'lot'}
            id={Number(row.lotId)}
            text={lotName} />;
    };

    public render() {
        return <div className={'fdc flx1 p10'}>
            <Typography className={'fdr bcp cw pt10 pb10 pl20 mt10 fs18 fw500'}>
                Layers
            </Typography>
            <div className={'fdr flx1 wfill'}>
                <CustomTable<IIntakeLineLayer>
                    enableSorting
                    enableTotalRow
                    fitWidthToPage
                    columns={[
                        { title: 'ID', field: 'id', enableSorting: true },
                        { title: 'Variety', field: 'varietyId', formatFunction: this.getVarietyName, enableSorting: true },
                        { title: 'Pack', field: 'packId', formatFunction: this.getPackCode, enableSorting: true },
                        { title: 'Orchard', field: 'orchardId', formatFunction: this.getOrchardDetail, enableSorting: true },
                        { title: 'Project', field: 'projectId', formatFunction: this.getProjectDetail, enableSorting: true },
                        { title: 'Gross Weight (kg)', field: 'grossWeight', formatFunction: addkg, enableSorting: true },
                        { title: 'Nett Weight (kg)', field: 'nettWeight', formatFunction: addkg, enableSorting: true },
                        { title: 'No. Units', field: 'noOfUnits', enableSorting: true },
                        { title: 'Lot ID', field: 'lotId', formatFunction: this.formatLotIdLink, enableSorting: true },
                        { title: 'Lot', field: 'lotName', formatFunction: this.formatLotNameLink, enableSorting: true },
                        { title: 'Commodity State', field: 'lotId', formatFunction: this.getLotCommodityState, enableSorting: true },
                        { title: 'Created By', field: 'createdByName', enableSorting: true },
                        { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
                        { title: 'Updated By', field: 'updatedByName', enableSorting: true },
                        { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
                        { title: 'Active?', field: 'isActive', formatFunction: booleanToYesNo, type: 'boolean', enableSorting: true },
                    ]}
                    rows={this.state.rows}
                    initialSortOrder={[{ columnName: 'id_Id', direction : 'desc' }]}
                    pageHeight={210}
                    enableDetailAccordion
                    isActive={(row : IIntakeLineLayer) => row.isActive}
                    detailAccordionComponent={(row : IIntakeLineLayer) =>
                        <IntakeLineLayerUnitSummary
                            setLoading={this.props.setLoading}
                            layer={row}
                            units={row.intakeLineLayerUnits}
                            refreshData={this.props.refreshData}
                            isLoading={this.props.isLoading}
                        />}
                />
            </div>
        </div>;
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        stockData: state.data.stocks,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        orchards: state.masterData.orchards,
        projects: state.masterData.projects,
        lots: state.data.lots,
        commodityStates: state.masterData.commodityStates,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IntakeLineLayerSummary);

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { ICommodityCondition } from '../../../@types/model/masterData/commodityCondition/commodityCondition';

export default class CommodityConditionHttpService {
    public static getCommodityConditionData() : AxiosPromise<Array<ICommodityCondition>> {
        return axios.get(`${PACKMAN_API_URL}CommodityCondition/GetAllWeb`);
    }

    public static addOrUpdateCommodityCondition(data : ICommodityCondition) : AxiosPromise<ICommodityCondition> {
        return axios.post(`${PACKMAN_API_URL}CommodityCondition/PostCommodityCondition`, data);
    }

    public static deleteCommodityCondition(conditionId : number) : AxiosPromise<ICommodityCondition> {
        return axios.delete(`${PACKMAN_API_URL}CommodityCondition/Delete`, {
            params : { conditionId },
        });
    }
}

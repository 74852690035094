import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { PACKMAN_API_URL } from '../appConstants';
import { generalShowErrorSnackbar } from '../store/general/Functions';
import { setLocalStorageSession } from './localStorageService';
import { IUserToken } from '../@types/model/user/userToken';
import { navPath } from '../store/nav/Actions';
import { dispatch } from '../store/Index';
import { authSetSession } from '../store/auth/Actions';

let requestInterceptorNum = 0;
let responseInterceptorNum = 0;
export const initializeInterceptor = (token : string) => {
    axios.interceptors.request.eject(requestInterceptorNum);
    axios.interceptors.response.eject(responseInterceptorNum);

    if (!!token) {
        requestInterceptorNum = axios.interceptors.request.use((config : AxiosRequestConfig) => {
            if (!config.headers['Authorization'] || config.headers['Authorization'] === '' || config.headers['Authorization'] === 'Bearer ') {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            return config;
        });

        responseInterceptorNum = axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                const ppecbError = String(error?.response?.config?.url).includes('ppecb.com'); // prevents logout when getting ppecb token fails
                if (!!error.response && error.response.status === 401) {
                    if (!ppecbError) { // prevents logout when getting ppecb token fails
                        initializeInterceptor('');
                        dispatch(authSetSession(null));
                        setLocalStorageSession(null);
                    }
                    generalShowErrorSnackbar(`Unauthorised. ${error.response.data}`);
                    return Promise.reject(error.response);
                } else if (!!error.response && error.response.status === 403) {
                    navPath('/noRights');
                    generalShowErrorSnackbar(`Insufficient rights. ${error.response.data}`);
                    return Promise.reject(error.response);
                }

                if (!error || !error.response) {
                    generalShowErrorSnackbar('Connection Error.');
                    return Promise.reject();
                }

                return Promise.reject(error.response);
            });
    }
};

export const googleAuth = (idToken ?: string) : AxiosPromise<IUserToken> => axios.get(`${PACKMAN_API_URL}Rights/Authorisation/GoogleAuth`, { params: { idToken } });
export const logout = () : AxiosPromise => axios.get(`${PACKMAN_API_URL}Rights/Authorisation/Logout`, { params: { } });

export const logoutUsers = (userIds : Array<number>) : AxiosPromise => {
    return axios.post(`${PACKMAN_API_URL}Rights/Authorisation/LogoutUsers`, userIds);
};

export const logInManual = (emailOrUsername : string, password : string) : AxiosPromise<IUserToken> => {
    return axios.post(`${PACKMAN_API_URL}Rights/Authorisation/LogIn`, {
        emailOrUsername,
        password,
    });
};

export async function setEmployeeNr(employeeNr : string) : Promise<IUserToken | null> {
    try {
        const result = await axios.post<IUserToken | null>(`${PACKMAN_API_URL}Rights/User/SetEmployeeNr`, {}, {
            params : {
                employeeNr,
            },
        });

        return result.data;
    } catch (ex) {
        generalShowErrorSnackbar(ex?.data?.Message ?? 'Error setting employee nr.');
        return null;
    }
}

export async function setPassword(password : string) : Promise<IUserToken | null> {
    return await updatePassword(undefined, password);
}

export async function updatePassword(oldPassword : string | null | undefined, newPassword : string) : Promise<IUserToken | null> {
    try {
        const result = await axios.post<IUserToken | null>(`${PACKMAN_API_URL}Rights/User/UpdatePassword`, {
            oldPassword,
            newPassword,
        });

        return result.data;
    } catch (ex) {
        if (!!ex && ex.status === 400) {
            generalShowErrorSnackbar(ex.data);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403)  {
            generalShowErrorSnackbar('Error setting password.');
        }
        return null;
    }
}

export async function refreshSession() : Promise<IUserToken | null> {
    try {
        const result = await axios.get<IUserToken | null>(`${PACKMAN_API_URL}Rights/Authorisation/GetSession`, {
            params : {
            },
        });

        return result.data;
    } catch (ex) {
        if (!!ex && ex.status === 400) {
            generalShowErrorSnackbar(ex.data);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403)  {
            generalShowErrorSnackbar('Error refreshing session');
        }
        return null;
    }
}

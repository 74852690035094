import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { BarcodeRangeFormValues } from '../../../../@types/model/masterData/barcodeRange/barcodeRangeFormValues';
import PillButton from '../../../../components/input/PillButton';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import { IOptionType } from '../../../../@types/helper';
import FormAutocompleteSelect from '../../../../components/input/form/FormAutoCompleteSelect';
import { IRootState } from '../../../../@types/redux';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { ISite } from '../../../../@types/model/masterData/site/site';

interface IBarcodeRangeFormProps {
    isLoading : boolean;
    siteOptions : Array<IOptionType>;
}

interface IBarcodeRangeFormState {
}

type BarcodeRangeFormPropsType = IBarcodeRangeFormProps & FormikProps<BarcodeRangeFormValues>;

class BarcodeRangeForm extends React.Component<BarcodeRangeFormPropsType, IBarcodeRangeFormState> {

    constructor(props : BarcodeRangeFormPropsType) {
        super(props);
    }

    public render() {
        return (
            <Form className={'p20 w300 wfill fdc aic'}>
                {this.props.values.isExport && <img height={150} src={`${ASSET_BASE}/assets/images/gs1.png`} />}
                <FormSingleToggleButton name={'isExport'} label={'Is Export'} />
                <FormTextInput className={'w500'} name={'name'} label={'Name'}/>
                {this.props.values.isExport && <FormTextInput className={'w500'} name={'indicatorDigit'} label={'Indicator Digit'}/>}
                <FormTextInput className={'w500'} name={'rangeStart'} label={'Range Start'}/>
                <FormTextInput className={'w500'} name={'rangeEnd'} label={'Range End'}/>
                {this.props.values.isExport && <FormTextInput className={'w500'} name={'importCode'} label={'Import Code'}/>}
                <FormAutocompleteSelect
                    name={'sites'}
                    label={'Sites'}
                    options={this.props.siteOptions}
                    isMulti
                    className={'w500'}
                />
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const getSites = (state : IRootState) => state.masterData.sites;

const getSiteOptions = createSelector(
    [getSites],
    (sites : Array<ISite>) => {
        return sites.filter(x => x.isActive).filter(x => x.isPackhouse).map(x => ({ label: `(${x.code}) ${x.shortDescription}`, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        siteOptions: getSiteOptions(state),
    };
};

export default connect(
    mapStateToProps,
)(BarcodeRangeForm);

import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IMaterialRelatedData } from '../../../@types/model/materialStock/materialRelatedData';

export default class MaterialRelatedDataHttpService {
    public static getAllMaterialRelatedData(fromDateUnix ?: number, toDateUnix ?: number, siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<IMaterialRelatedData> {
        return axios.get(`${PACKMAN_API_URL}MaterialStock/AllMaterialRelatedData`, {
            params: {
                fromDateUnix,
                toDateUnix,
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getAllMaterialRelatedMasterData() : AxiosPromise<IMaterialRelatedData> {
        return axios.get(`${PACKMAN_API_URL}MaterialStock/AllMaterialRelatedMasterData`);
    }
}

import { IBaseModel } from '../../../model';
import { ITruckTypeFormValues } from './truckTypeFormValues';

export interface ITruckType extends IBaseModel {
    code : string;
    description : string;
    defaultNumberOfPallets ?: number;
    maxPayload ?: number;
    weightOfTruck ?: number;
    tareWeight ?: number;
    percentageWarning ?: number;
}

export class TruckType implements ITruckType {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public description : string;
    public defaultNumberOfPallets ?: number;
    public maxPayload ?: number;
    public weightOfTruck ?: number;
    public tareWeight ?: number;
    public percentageWarning ?: number;

    public constructor(formValues : ITruckTypeFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.code = formValues.code;
        this.description = formValues.description;
        this.defaultNumberOfPallets = formValues.defaultNumberOfPallets;
        this.maxPayload = formValues.maxPayload;
        this.weightOfTruck = formValues.weightOfTruck;
        this.tareWeight = formValues.tareWeight;
        this.percentageWarning = formValues.percentageWarning;
    }
}

import * as React from 'react';
import { Button } from '@mui/material';
import CustomTable from '../../components/datagrid/CustomTable';
import { IStock } from '../../@types/model/stock/stock';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import PillButton from '../../components/input/PillButton';
import { IDispatchInstruction } from '../../@types/model/dispatch/dispatchInstruction';
import { createSelector } from 'reselect';
import { addArrayElement, compareDate, formatDateTimeToDateOnly, formatMomentToDatePicker } from '../../services/appFunctionsService';
import { getState } from '../../store/Index';
import { connect } from 'react-redux';
import { Form } from 'informed';
import CustomSelect from '../../components/input/CustomSelect';
import { IDropDownOptions } from '../../@types/other';
import CustomDatePicker from '../../components/input/CustomDatePicker';
import SingleToggleButton from '../../components/input/SingleToggleButton';
import validationService from '../../services/validationService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import DispatchHttpService from '../../services/http/dispatch/dispatchHttpService';
import { IDispatchView } from '../../@types/model/dispatch/dispatchView';
import { dataSetDispatchEditRelatedData } from '../../store/data/Functions';
import { DATEPICKER_FORMAT_DEFAULT } from '../../appConstants';
import moment from 'moment';
import { IRootState } from '../../@types/redux';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { ISize } from '../../@types/model/masterData/size/size';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { IMarket } from '../../@types/model/masterData/market/market';
import { IEditDispatch } from '../../@types/model/dispatch/editDispatch';
import { IStockViewWithCartons } from '../../@types/model/stock/stockViewWithCartons';
import { syncMasterData } from '../../services/masterDataSyncService';
import { uniq } from 'lodash';

interface IDispatchInstructionEditProps {
    selectedDispatchView : IDispatchView;
    isLoading : boolean;
    editDispatchCancelled ?: () => void;
    stockData : Array<IStock>;
    dispatches : Array<IDispatchInstruction>;
    setLoading : (isLoading ?: boolean) => void;
    refreshData : () => void;
    farms : Array<IFarm>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    grades : Array<IGrade>;
    sizes : Array<ISize>;
    colours : Array<IColour>;
    markets : Array<IMarket>;
    stockWithCartonsDataViews : Array<IStockViewWithCartons>;
}

const getMarketName = (id : number) => {
    const state = getState();
    return state.masterData.markets.find(x => x.id === id)?.name;
};

const getPalletBaseTypeCode = (id : number) => {
    const state = getState();
    return state.masterData.palletBaseTypes.find(x => x.id === id)?.code;
};

const getOrganizationName = (id : number) => {
    const state = getState();
    return state.masterData.organizations.find(x => x.id === id)?.name;
};

interface IDispatchInstructionEditState {
    editingDispatch ?: IDispatchInstruction;
    initialSelectedStockRows : Array<number>;
    selectedStockRows : Array<number>;
}

class DispatchInstructionEdit extends React.Component<IDispatchInstructionEditProps, IDispatchInstructionEditState> {

    constructor(props : IDispatchInstructionEditProps) {
        super(props);

        this.state = {
            initialSelectedStockRows: [],
            selectedStockRows: [],
        };
    }

    public componentDidMount = async () => {
        this.props.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }
        try {
            const res = await DispatchHttpService.getDispatchEditData(this.props.selectedDispatchView.id, this.props.selectedDispatchView.sourceSiteId ?? 0, !isIndexedDBAvailable);

            if (res && res.data) {
                const selectedDispatch = res.data.dispatch;
                const selectedStocks = uniq(selectedDispatch?.dispatchLines.filter(y => y.isActive).map(y => y.currentStockId));

                this.setState({ editingDispatch: selectedDispatch, initialSelectedStockRows: selectedStocks, selectedStockRows: selectedStocks });
                dataSetDispatchEditRelatedData(res.data);
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading dispatch edit form data.');
        } finally {
            this.props.setLoading(false);
        }
    };

    private editDispatchConfirmed = async () => {
        if (this.state.editingDispatch) {
            this.props.setLoading(true);
            try {
                const editedDispatch : IEditDispatch = {
                    id: this.state.editingDispatch.id,
                    loadDate: this.state.editingDispatch.loadDate,
                    isActive: this.state.editingDispatch.isActive,
                    targetOrganizationId: this.state.editingDispatch.targetOrganizationId,
                    stockIds: this.state.selectedStockRows.map(x => x),
                };

                const res = await DispatchHttpService.editDispatch(editedDispatch);

                if (res && res.data) {
                    this.props.refreshData();
                    generalShowSuccessSnackbar('Dispatch successfully updated.');
                } else {
                    generalShowErrorSnackbar('An error occurred updating the dispatch instruction.');
                }
            } catch (e) {
                this.props.setLoading(false);
                generalShowErrorSnackbar('An error occurred updating the dispatch instruction.');
            }
        }
        this.setState({ editingDispatch: undefined, selectedStockRows: [] });
        if (!!this.props.editDispatchCancelled) {
            this.props.editDispatchCancelled();
        }
    };

    private getStockViewData = (props : IDispatchInstructionEditProps) => props.stockWithCartonsDataViews;
    private getDispatches = (props : IDispatchInstructionEditProps) => props.dispatches;
    private getEditingDispatch = (props : IDispatchInstructionEditProps, state : IDispatchInstructionEditState) => state.editingDispatch;
    private getInitialSelectedStockRows = (props : IDispatchInstructionEditProps, state : IDispatchInstructionEditState) => state.initialSelectedStockRows;
    private getSelectedStockRows = (props : IDispatchInstructionEditProps, state : IDispatchInstructionEditState) => state.selectedStockRows;
    private isChecked = (id : number) => this.state.selectedStockRows.some(x => x === id);

    private filterStockRows = createSelector([this.getStockViewData, this.getDispatches, this.getEditingDispatch, this.getInitialSelectedStockRows, this.getSelectedStockRows],
        (stockViews, dispatches, editingDispatch, initialSelectedStockRows) => {
            const stocks : Array<IStockViewWithCartons> = [];

            if (editingDispatch) {
                const selectedDispatch = dispatches.find(x => x.id === editingDispatch?.id);
                if (selectedDispatch) {
                    const selectedStock = stockViews.filter(x => initialSelectedStockRows.some(y => y === x.id));
                    if (selectedStock) {
                        selectedStock.forEach(x => stocks.push(x));
                    }

                    stockViews
                        .filter(x => x.currentOrganizationId === editingDispatch?.organizationId && x.currentSiteId === editingDispatch.sourceSiteId)
                        .filter(x => dispatches.some(y => y.dispatchLines.every(z => z.isActive && z.currentStockId !== x.id)))
                        .forEach((x) => {
                            const index = stocks.findIndex(y => y.id === x.id);

                            if (index === -1) {
                                stocks.push(x);
                            }
                        });
                }
            }

            return stocks;
        },
    );

    private setEditingDispatchTargetOrganization = (event : React.ChangeEvent<HTMLInputElement>) => {
        const editingDispatch = this.state.editingDispatch && { ...this.state.editingDispatch };
        if (editingDispatch) {
            editingDispatch.targetOrganizationId = parseInt(event.target.value, 10);
            this.setState({ editingDispatch });
        }
    };

    private setEditingDispatchLoadDate = (loadDate : any) => {
        const editingDispatch = this.state.editingDispatch && { ...this.state.editingDispatch };
        if (editingDispatch) {
            editingDispatch.loadDate = formatMomentToDatePicker(moment(loadDate, DATEPICKER_FORMAT_DEFAULT));
            this.setState({ editingDispatch });
        }
    };

    private toggleEditingIsActive = () => {
        const editingDispatch = this.state.editingDispatch && { ...this.state.editingDispatch };
        if (editingDispatch) {
            editingDispatch.isActive = !editingDispatch.isActive;
            this.setState({ editingDispatch });
        }
    };

    private handleStockCheckboxChecked = (selectedStockRows : Array<number>) => this.setState({ selectedStockRows });

    private organizationOptions = () => {
        const state = getState();
        if (!state.masterData.organizations) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Organization', disabled: true });
        state.masterData.organizations.filter(x => x.isActive)
            .forEach(x => returnValue.push({ value: x.id, label: `(${x.code}) ${x.name}` }));
        return returnValue;

    };

    private checkCodes = (value : string) => {
        const stringList : Array<string> = value.split(', ');

        const filtered = stringList.filter(x => !x.endsWith('_inactive'));

        return filtered.join(', ');
    };

    private getTotalCartons = (id : number) => {
        const stock = this.props.stockWithCartonsDataViews.find(x => x.id === id);

        if (stock && stock?.totalCartons) {
            const cartons = stock?.totalCartons.split(', ');

            const newCartons : Array<string> = cartons.filter(x => x !== '0' && !x.endsWith('_inactive'));
            return newCartons.toString().replace(/,/g, ', ');
        } else {
            return '';
        }
    };

    private getTotalInners = (id : number) => {
        const stock = this.props.stockWithCartonsDataViews.find(x => x.id === id);

        if (stock && stock?.totalInners) {
            const inners = stock?.totalInners.split(', ');

            const newTotalInners : Array<string> = inners.filter(x => x !== '0' && !x.endsWith('_inactive'));
            return newTotalInners.toString().replace(/,/g, ', ');
        } else {
            return '';
        }
    };

    private getAgeInDays = (id : number,  row : IStockViewWithCartons) => row.packDate ? moment({ hours: 0 }).diff(row.packDate, 'days') : -1;

    private getStockTableColumns = () => [
        { title: 'Pack Date', field: 'packDate', formatFunction: formatDateTimeToDateOnly, sortFunction: compareDate, type: 'date', enableFiltering: true, enableSorting: true },
        { title: 'Barcode', field: 'barcode', enableFiltering: true, enableSorting: true },
        { title: 'Cartons', field: 'id', width: 120, formatFunction: this.getTotalCartons, enableFiltering: true, enableSorting: true },
        { title: 'Inners', field: 'id', width: 120, formatFunction: this.getTotalInners, enableFiltering: true, enableSorting: true },
        { title: 'Commodities', field: 'commodityCodes', width: 150, formatFunction: this.checkCodes, enableFiltering: true, enableSorting: true },
        { title: 'Varieties', field: 'varietyCodes', width: 150, formatFunction: this.checkCodes, enableFiltering: true, enableSorting: true },
        { title: 'Packs', field: 'packCodes', width: 150, formatFunction: this.checkCodes, enableFiltering: true, enableSorting: true },
        { title: 'Grades', field: 'gradeCodes', width: 110, formatFunction: this.checkCodes, enableFiltering: true, enableSorting: true },
        { title: 'Sizes', field: 'sizeCodes', width: 150, formatFunction: this.checkCodes, enableFiltering: true, enableSorting: true },
        { title: 'Colours', field: 'colourCodes', width: 110, formatFunction: this.checkCodes, enableFiltering: true, enableSorting: true },
        { title: 'Target Market', field: 'marketName', width: 160, formatFunction: getMarketName, enableFiltering: true, enableSorting: true },
        { title: 'Pallet Base Type', field: 'palletBaseTypeName', formatFunction: getPalletBaseTypeCode, width: 180, enableFiltering: true, enableSorting: true },
        { title: 'Gross Weight (kg)', field: 'grossWeight', width: 190, enableFiltering: true, enableSorting: true },
        { title: 'Age', field: 'id', width: 90, formatFunction: this.getAgeInDays, enableFiltering: true, enableSorting: true },
        { title: 'Farm(s)', field: 'farmCodes', formatFunction: this.checkCodes, enableFiltering: true, enableSorting: true },
        { title: 'Channel', field: 'channel', enableFiltering: true, enableSorting: true },
        { title: 'Current Organization', field: 'currentOrganizationId', formatFunction: getOrganizationName, enableFiltering: true, enableSorting: true },
        { title: 'Waybill', field: 'waybill', enableFiltering: true, enableSorting: true },
    ];

    private getFilterTypeColunmObject = () => {
        let channels : Array<string> = [];
        this.props.stockData.map(x => x.channel).forEach((x) => {
            const index = channels.findIndex(y => y === x);
            if (index === -1) {
                channels = addArrayElement(channels, x);
            }
        });

        return [
            { column: 'Farms', data: this.props.farms.map(x => x.code) },
            { column: 'Sizes', data: this.props.sizes.map(x => x.code) },
            { column: 'Commodities', data: this.props.commodities.map(x => x.code) },
            { column: 'Varieties', data: this.props.varieties.map(x => x.code) },
            { column: 'Colours', data: this.props.colours.map(x => x.code) },
            { column: 'Grades', data: this.props.grades.map(x => x.code) },
            { column: 'Packs', data: this.props.packs.map(x => x.code) },
            { column: 'Channel', data: channels ?? [] },
            { column: 'Target Market', data: this.props.markets.map(x => x.name) },
        ];
    };

    public render() {
        return <PackmanDialog
            title={'Edit Dispatch #' + this.props.selectedDispatchView?.id}
            isInfo
            isLoading={this.props.isLoading}
            fullScreen
            isOpen={!!this.props.selectedDispatchView}
            onClose={this.props.editDispatchCancelled}>
            <div className={'p20 hfill'}>
                <Form className={'jcc fdc aic wfill'}>
                    <div className={'fdr mb10 wfill'}>
                        <CustomSelect
                            color={'primary'}
                            className={'flx1 m5'}
                            options={this.organizationOptions()}
                            label={'Target Organization'}
                            initialValue={this.props.selectedDispatchView ? this.props.selectedDispatchView.targetOrganizationId : 0}
                            validate={validationService.required}
                            validateOnBlur
                            validateOnChange
                            validateOnMount
                            field={'targetOrganization'}
                            value={this.state.editingDispatch ? this.state.editingDispatch.targetOrganizationId : 0}
                            onChange={this.setEditingDispatchTargetOrganization}
                        />
                        <CustomDatePicker
                            color={'primary'}
                            className={'flx1 m5'}
                            type='date'
                            field='loadDate'
                            label={'Load Date'}
                            onChange={this.setEditingDispatchLoadDate}
                            initialValue={this.props.selectedDispatchView && this.props.selectedDispatchView.loadDate}
                        />
                        <div className={'flx1 p5'}>
                            <SingleToggleButton
                                label={'Active?'}
                                onChange={this.toggleEditingIsActive}
                                name=''
                                disabled
                                value={this.state.editingDispatch ? this.state.editingDispatch.isActive : false}
                            />
                        </div>
                    </div>
                    <div className={'fdr mb10 w50p wfill'}>
                    </div>
                </Form>
                <div className={'wfill'}>
                    <CustomTable<IStockViewWithCartons>
                        enableSorting
                        enableFiltering
                        enableSelection
                        onSelectChange={this.handleStockCheckboxChecked}
                        selectedRows={this.getSelectedStockRows(this.props, this.state)}
                        disableRowSelect={row => !row.isActive}
                        filterType={'MultiCheckbox'}
                        filterTypeColumn={this.getFilterTypeColunmObject()}
                        columns={this.getStockTableColumns()}
                        rows={this.filterStockRows(this.props, this.state)}
                        initialSortOrder={[{ columnName: 'barcode_Barcode', direction : 'desc' }]}
                        isActive={(row : IStockViewWithCartons) => row.isActive}
                        pageHeight={230}
                    />
                </div>
                <div className={'fdr aic jcfe wfill mt10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text'
                        color='primary'
                        onClick={this.props.editDispatchCancelled}>
                        Cancel
                    </Button>
                    <PillButton
                        disabled={this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Submit'}
                        color={'secondary'}
                        onClick={this.editDispatchConfirmed}
                    />
                </div>
            </div>
        </PackmanDialog >;
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        stockData: state.data.stocks,
        dispatches: state.data.dispatchInstructions,
        farms: state.masterData.farms,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        grades: state.masterData.grades,
        sizes: state.masterData.sizes,
        colours: state.masterData.colours,
        markets: state.masterData.markets,
        stockWithCartonsDataViews: state.data.stockWithCartonsDataViews,
    };
};

export default connect(
    mapStateToProps,
)(DispatchInstructionEdit);

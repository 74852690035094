import { User } from 'firebase';
import store from '../store/Index';
import { IUserSession } from '../@types/model/user/userSession';
import { ISite } from '../@types/model/masterData/site/site';
import { IFruitSpec } from '../@types/model/dispatch/fruitSpec';
import { IStockLine } from '../@types/model/stock/stockLine';
import { IMaterialDispatchDashboard } from '../@types/model/materialDispatch/materialDispatchDashboard';
import { IMaterialStock } from '../@types/model/materialStock/materialStock';

export function mapFirebaseUserToSession(user : User | null) : IUserSession | null {
    return user ? {
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        isAnonymous: user.isAnonymous,
        metadata: user.metadata,
        phoneNumber: user.phoneNumber,
        photoURL: user.photoURL,
        providerData: user.providerData,
        providerId: user.providerId,
        refreshToken: user.refreshToken,
        uid: user.uid,
    } : null;
}

export function getPackhouses() : Array<ISite> {
    const state = store.getState();
    const sites = state.masterData.sites;

    if (!sites) {
        return [];
    }

    return sites.filter(x => x.isPackhouse && x.isActive);
}

export function getSites() : Array<ISite> {
    const state = store.getState();
    const sites = state.masterData.sites;

    if (!sites) {
        return [];
    }

    return sites.filter(x => x.isActive);
}

export const isBeingDispatchedAndSubstitutable = (stockId : number) => {
    const state = store.getState();
    const isActive = state.data.stocks.find(x => x.isActive && x.cartons !== 0 && x.stockLines.some(y => y.isActive));
    return isActive && state.data.dispatchInstructions.some(y => y.isActive && y.status !== 'Closed' && y.status !== 'Received'
    && y.dispatchLines.some(x => x.currentStockId === stockId && x.isActive && x.isSubstitutable));
};

export const isBeingDispatched = (stockId : number) => {
    const state = store.getState();
    const isActive = !!state.data.stocks.find(x => x.isActive && x.cartons !== 0 && x.stockLines.some(y => y.isActive));
    return isActive && state.data.dispatchInstructions.some(y => y.isActive && y.status !== 'Closed' && y.status !== 'Received'
    && y.dispatchLines.some(x => x.currentStockId === stockId && x.isActive));
};

export const isSiteExternal = (siteId : number) => {
    const state = store.getState();
    return state.masterData.sites.find(x => x.isActive && x.id === siteId)?.isExternal;
};

export function mapFruitSpec() : Array<IFruitSpec> {
    const state = store.getState();
    const selectedSiteIds = state.data.selectedDashboardSiteIds;
    const sites = getPackhouses().filter(x => selectedSiteIds?.some(y => x.id === y));
    const commodities = state.masterData.commodities;
    const varieties = state.masterData.varieties;
    const organizations = state.masterData.organizations;
    const markets = state.masterData.markets;
    const packs = state.masterData.packs;
    const sizes = state.masterData.sizes;
    const grades = state.masterData.grades;
    const stocks = state.data.stocks;
    const colours = state.masterData.colours;
    const uniqueStockLines = getUniqueStockLines();

    const fruitspecs : Array<IFruitSpec> = [];
    uniqueStockLines.forEach((x, i) => {
        const stock = stocks.find(y => y.id === x.stockId);
        const commodityId = commodities.find(y => y.id === x.commodityId)?.id;
        const varietyId = varieties.find(y => y.id === x.varietyId)?.id;
        const organizationId = stock && organizations.find(y => y.id === stock.currentOrganizationId)?.id;
        const pack = packs.find(y => y.id === x.packId);
        const packId = pack?.id;
        const packCategoryId = pack?.categoryId;
        const sizeId = sizes.find(y => y.id === x.sizeId)?.id;
        const targetMarketId = stock && markets.find(y => y.id === stock.marketId)?.id;
        const gradeId = grades.find(y => y.id === x.gradeId)?.id;
        const colourId = colours.find(y => y.id === x.colourId)?.id;
        if (stock) {
            const isBeingDispatchedBool = isBeingDispatched(stock.id);
            const inStock = stock.status === 'In Stock' && !stock.isTemporary && !isSiteExternal(stock.currentSiteId);
            if (commodityId && varietyId && organizationId && packId && packCategoryId && sizeId && targetMarketId && gradeId && colourId && stock.isActive) {
                const idx = fruitspecs.findIndex(y =>
                    (y.organizationId === organizationId)
                    && (y.commodityId === commodityId)
                    && (y.varietyId === varietyId)
                    && (y.targetMarketId === targetMarketId)
                    && (y.packId === packId)
                    && (y.packCategoryId === packCategoryId)
                    && (y.sizeId === sizeId)
                    && (y.gradeId === gradeId)
                    && (y.colourId === colourId));
                if (idx === -1) {
                    fruitspecs.push({
                        id: i,
                        commodityId,
                        varietyId,
                        organizationId,
                        targetMarketId,
                        packId,
                        packCategoryId,
                        sizeId,
                        gradeId,
                        colourId,
                        totalStock: {
                            inStock: inStock ? 1 : 0,
                            dispatching: isBeingDispatchedBool ? 1 : 0,
                        },
                        stock : sites.filter(y => y.isActive && !y.isExternal).map((y) => {
                            return {
                                siteId: y.id,
                                stockAmount: (y.id === stock.currentSiteId) ? (inStock ? 1 : 0) : 0,
                                stockAmountDispatching: (y.id === stock.currentSiteId) ? (isBeingDispatchedBool ? 1 : 0) : 0,
                                stockLineIds: [x.id],
                                stockIds: [x.stockId],
                            };
                        }),
                    });
                } else {
                    const stockidx = fruitspecs[idx].stock.findIndex((y : {
                        siteId : number;
                        stockAmount : number;
                        stockLineIds : Array<number>;
                        stockIds : Array<number>;
                    }) => y.siteId === stock.currentSiteId);
                    if (stockidx !== -1) {
                        fruitspecs[idx].totalStock.inStock += inStock ? 1 : 0,
                        fruitspecs[idx].totalStock.dispatching += isBeingDispatchedBool ? 1 : 0;
                        fruitspecs[idx].stock[stockidx].stockAmount += inStock ? 1 : 0,
                        fruitspecs[idx].stock[stockidx].stockAmountDispatching += isBeingDispatchedBool ? 1 : 0;
                        fruitspecs[idx].stock[stockidx].stockLineIds.push(x.id);
                        fruitspecs[idx].stock[stockidx].stockIds.push(x.stockId);
                    }
                }
            }

        }
    });
    return fruitspecs;
}

export function mapMaterialDispatchDashboard() : Array<IMaterialDispatchDashboard> {
    const state = store.getState();
    const sites = getSites();
    const materialStocks = state.data.materialStocks;
    const materials = state.masterData.materials;
    const unitOfMeasures = state.masterData.unitOfMeasures;

    const result : Array<IMaterialDispatchDashboard> = [];
    materialStocks.forEach((x, i) => {
        const material = materials.find(y => y.id === x.materialId);
        const unitOfMeasure = unitOfMeasures.find(y => y.id === x.unitOfMeasureId);
        const existingIndex = result.findIndex(y =>
            (y.material?.id === material?.id)
            && (y.material?.id === material?.id)
            && (y.dateCode === x.dateCode));
        if (material && unitOfMeasure) {
            if (existingIndex === -1) {
                result.push({
                    isActive: x.isActive,
                    id: i,
                    material,
                    unitOfMeasure,
                    dateCode: x.dateCode,
                    totalAmounts: {
                        allocated: x.amountAllocated,
                        unallocated: x.amountUnAllocated,
                    },
                    stock : sites.map((y) => {
                        return {
                            site: y,
                            allocatedAmount: (y.id === x.siteId) ? (x.amountAllocated ? x.amountAllocated : 0) : 0,
                            unallocatedAmount: (y.id === x.siteId) ? (x.amountUnAllocated ? x.amountUnAllocated : 0) : 0,
                            materialStocks: [x],
                        };
                    }),
                });
            } else {
                const stockexistingIndex = result[existingIndex].stock.findIndex(
                    (y : {
                        site : ISite;
                        allocatedAmount : number;
                        unallocatedAmount : number;
                        materialStocks : Array<IMaterialStock>;
                    }) => y.site.id === x.siteId);

                if (stockexistingIndex !== -1) {
                    result[existingIndex].totalAmounts.allocated += x.amountAllocated,
                    result[existingIndex].totalAmounts.unallocated += x.amountUnAllocated;
                    result[existingIndex].stock[stockexistingIndex].allocatedAmount += x.amountAllocated,
                    result[existingIndex].stock[stockexistingIndex].unallocatedAmount += x.amountUnAllocated;
                    result[existingIndex].stock[stockexistingIndex].materialStocks.push(x);
                }
            }

        }
    });
    return result;
}

export function getUniqueStockLines() {
    const state = store.getState();
    const stockData = state.data.stocks;

    const uniqueStockLines : Array<IStockLine> = [];
    stockData.forEach(stock => stock.stockLines.filter(x => x.isActive).forEach((x) => {
        const index = uniqueStockLines.findIndex(y => y.stockId === x.stockId);
        if (index === -1) {
            uniqueStockLines.push(x);
        } else if ((uniqueStockLines[index].cartons < x.cartons) ||
            ((uniqueStockLines[index].cartons === x.cartons) && (uniqueStockLines[index].id > x.id))) {
            uniqueStockLines[index] = x;
        }
    }));

    return uniqueStockLines;
}

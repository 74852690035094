import * as React from 'react';
import { Form, FormikProps } from 'formik';
import Button from '@mui/material/Button';
import FormTextInput from '../../../components/input/form/FormTextInput';
import PillButton from '../../../components/input/PillButton';
import { ContactUsFormValues } from '../../../@types/model/home/contactUsFormValues';

interface IContactUsEditFormProps {
    isLoading : boolean;
}

interface IContactUsEditFormState {
}

type ContactUsEditFormPropsType = IContactUsEditFormProps & FormikProps<ContactUsFormValues>;

export default class ContactUsEditForm extends React.Component<ContactUsEditFormPropsType, IContactUsEditFormState> {

    constructor(props : ContactUsEditFormPropsType) {
        super(props);
    }

    public render() {
        return (
            <Form className={'p20 w600'}>
                <FormTextInput name={'name'} label={'Name'}/>
                <FormTextInput name={'email'} label={'Email'}/>
                <FormTextInput name={'moreInfo'} label={'More Info'}/>
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

import { IBaseModel } from '../../../model';
import { IMaterialCost } from '../materialCost/materialCost';
import { IMaterialFormValues } from './materialFormValues';

export interface IMaterial extends IBaseModel {
    name : string;
    code : string;
    isInnerPackaging : boolean;
    packIds : Array<number>;
    palletBaseTypeId ?: number;
    materialTypeId : number;
    unitOfMeasureId ?: number;
    materialCostIds : Array<number>;
    materialCosts : Array<IMaterialCost>;
}

export class Material implements IMaterial {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public name : string;
    public packIds : Array<number>;
    public isInnerPackaging : boolean;
    public palletBaseTypeId ?: number;
    public materialTypeId : number;
    public unitOfMeasureId ?: number;
    public materialCostIds : Array<number>;
    public materialCosts : Array<IMaterialCost>;

    public constructor(formValues : IMaterialFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;
        this.isInnerPackaging = formValues.isInnerPackaging;

        this.code = formValues.code;
        this.name = formValues.name;

        this.materialCosts = formValues.materialCosts ?? [];
        this.packIds = formValues.packs?.map(x => Number(x.value)) ?? [];

        this.palletBaseTypeId = Number(formValues.palletBaseType?.value) ?? undefined;

        this.materialTypeId = Number(formValues.materialType?.value) ?? 0;
        this.unitOfMeasureId = Number(formValues.unitOfMeasure?.value) ?? undefined;
    }
}

import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { VERSION } from '../../../version';
import { navPath } from '../../store/nav/Actions';
import Icon from '@mui/material/Icon';
import { useAppSelector, useAppDispatch } from '../../@types/redux';
import { Breadcrumbs, Button, Fab } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import OrganizationHttpService from '../../services/http/masterData/organizationHttpService';
import { generalShowErrorSnackbar, generalShowInfoSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { dataSetSelectedOrganizationIds, dataSetSelectedSiteIds, dataSetSelectedExporterOrganization } from '../../store/data/Actions';
import { dataSetMasterDataSyncIsLoading, dataSetOrganizations, dataSetSites } from '../../store/masterData/Actions';
import SiteHttpService from '../../services/http/masterData/siteHttpService';
import { setOrganizationLocalStorage, getOrganizationLocalStorage, getSiteLocalStorage, setSiteLocalStorage, getHomeUrlStorage, setExportOrganizationLocalStorage, getExportOrganizationLocalStorage, setHomeUrlStorage, getMasterDataLastSyncDateLocalStorage, setSelectedThemeLocalStorage, getSelectedThemeLocalStorage, clearLocalStorage } from '../../services/localStorageService';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import Screen from '../../components/Screen';
import CustomAutoSuggest from '../../components/input/CustomAutoSuggest';
import PillButton from '../../components/input/PillButton';
import { useClearCacheCtx } from 'react-clear-cache';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import { IOptionType } from '../../@types/helper';
import { authSignOutGoogle } from '../../store/auth/Functions';
import { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import CustomTooltip from '../../components/tooltip/tooltip';
import { syncMasterData } from '../../services/masterDataSyncService';
import { APP_THEMES, DATE_FORMAT_DEFAULT } from '../../appConstants';
import moment from 'moment';

const useLongPress = (onLongPress : () => void, onClick : () => void, ms : number, shouldPreventDefault = true) => {
    const [longPressTriggered, setLongPressTriggered] = useState(false);
    const timeout : any = useRef();
    const target : any = useRef();

    const start = useCallback(
        (event) => {
            if (shouldPreventDefault && event.target) {
                event.target.addEventListener('touchend', {
                    passive: false,
                });
                target.current = event.target;
            }
            timeout.current = setTimeout(() => {
                onLongPress();
                setLongPressTriggered(true);
            }, ms);
        }, [onLongPress, ms, shouldPreventDefault]);

    const clear = useCallback(
        (event, shouldTriggerClick = true) => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
            if (shouldTriggerClick && !longPressTriggered) {
                onClick();
            }
            setLongPressTriggered(false);
            if (shouldPreventDefault && target.current) {
                target.current.removeEventListener('touchend', preventDefault);
            }
        }, [onClick, longPressTriggered, shouldPreventDefault]);

    return {
        onMouseDown: (event : any) => start(event),
        onTouchStart: (event : any) => start(event),
        onMouseUp: (event : any) => clear(event),
        onMouseLeave: (event : any) => clear(event, false),
        onTouchEnd: (event : any) => clear(event),
    };
};

const isTouchEvent = (event : any) => {
    return 'touches' in event;
};

const preventDefault = (event : any) => {
    if (!isTouchEvent(event)) return;

    if (event.touches.length < 2 && event.preventDefault) {
        event.preventDefault();
    }
};

interface INavBarProps {
    toggleLeftDrawer ?: () => void;
}

const NavBar = (props : INavBarProps) => {

    const isLoggingIn = useAppSelector(x => x.auth.isLoggingIn);
    const isLoggedIn = useAppSelector(x => x.auth.isLoggedIn);
    const session = useAppSelector(x => x.auth.session);
    const currentUserOrgs = useAppSelector(x => x.auth.session?.user.organizationIds);
    const currentUserSites = useAppSelector(x => x.auth.session?.user.siteIds);
    const sites = useAppSelector(x => x.masterData.sites);
    const organizations = useAppSelector(x => x.masterData.organizations);
    const selectedOrganizationIds = useAppSelector(x => x.data.selectedOrganizationIds);
    const selectedSiteIds = useAppSelector(x => x.data.selectedSiteIds);
    const selectedExporterOrganization = useAppSelector(x => x.data.selectedExporterOrganization);
    const path = useLocation().pathname;
    const isMasterDataSyncRunning = useAppSelector(x => x.masterData.masterDataSyncIsLoading);

    const [showUserSettingsDialog, setShowUserSettingsDialog] = useState<boolean>(false);
    const [showLogoutLongPressPopup, setShowLogoutLongPressPopup] = useState<boolean>(false);
    const [getSelectedThemeOption, setSelectedThemeOption] = useState<IOptionType | undefined>(undefined);
    const dispatch = useAppDispatch();

    useEffect(()  => {
        if (!!session && (isLoggedIn || isLoggingIn)) {
            const getData = async () => {
                // checks if indexedDB is available.
                const isIndexedDBAvailable = !!self.indexedDB ? true : false;
                try {
                    if (!isIndexedDBAvailable) {
                        const orgResult = await OrganizationHttpService.getOrganizationData();
                        const siteResult = await SiteHttpService.getSiteData();
                        dispatch(dataSetOrganizations(orgResult.data));
                        dispatch(dataSetSites(siteResult.data));
                    }
                    if (session?.user?.organizationIds.length > 0) {
                        const localStorageOrgIds = getOrganizationLocalStorage();
                        const newSelectedOrgIds = localStorageOrgIds?.filter(x => session?.user?.organizationIds?.some(y => y === x));
                        setOrganizationLocalStorage(newSelectedOrgIds);
                        dispatch(dataSetSelectedOrganizationIds(newSelectedOrgIds));
                    } else {
                        setOrganizationLocalStorage([]);
                        dispatch(dataSetSelectedOrganizationIds([]));
                    }
                    if (session?.user?.siteIds.length > 0) {
                        const localStorageSiteIds = getSiteLocalStorage();
                        const newSelectedSiteIds = localStorageSiteIds?.filter(x => session?.user?.siteIds?.some(y => y === x));
                        setSiteLocalStorage(newSelectedSiteIds);
                        dispatch(dataSetSelectedSiteIds(newSelectedSiteIds));
                    } else {
                        setSiteLocalStorage([]);
                        dispatch(dataSetSelectedSiteIds([]));
                    }
                    dispatch(dataSetSelectedExporterOrganization(getExportOrganizationLocalStorage()));

                    const selectedTheme = getSelectedThemeLocalStorage();
                    const index = APP_THEMES.findIndex(x => x === selectedTheme);
                    if (index !== -1) {
                        setSelectedThemeOption({ label: selectedTheme, value: selectedTheme });
                    }
                } catch (e) {
                    generalShowErrorSnackbar('An error occurred while loading organizations.');
                }
            };
            getData();
        }
    }, [session, isLoggingIn, isLoggedIn]);

    const onOrganizationChange = (e : React.ChangeEvent<{}>, selectedOrganizationOptions : Array<IOptionType>, reason : AutocompleteChangeReason | 'selectAll' | 'deselectAll') => {
        if (reason === 'removeOption' && selectedOrganizationOptions.length === 0) {
            e.preventDefault();
            return;
        }
        const selected = reason === 'deselectAll' ? undefined : selectedOrganizationOptions;
        const selectedSites = reason === 'deselectAll' ? undefined : [];
        if (selected && selected?.length > 0) {
            setOrganizationLocalStorage(selected?.map(x => Number(x.value)));
            dispatch(dataSetSelectedOrganizationIds(selected?.map(x => Number(x.value))));
        } else {
            setOrganizationLocalStorage(selected?.map(x => Number(x.value)));
            setSiteLocalStorage(selectedSites);
            dispatch(dataSetSelectedOrganizationIds(selected?.map(x => Number(x.value))));
            dispatch(dataSetSelectedSiteIds(selectedSites));
        }
    };

    const onSiteChange = (e : React.ChangeEvent<{}>, selectedSiteOptions : Array<IOptionType>, reason : AutocompleteChangeReason | 'selectAll' | 'deselectAll') => {
        if (reason === 'removeOption' && selectedSiteOptions.length === 0) {
            e.preventDefault();
            return;
        }
        const selected = reason === 'deselectAll' ? undefined : selectedSiteOptions;
        setSiteLocalStorage(selected?.map(x => Number(x.value)));
        dispatch(dataSetSelectedSiteIds(selected?.map(x => Number(x.value))));
    };

    const onExportOrganizationChange = (orgId : any) => {
        const org = orgId === '0' ? undefined : organizations.find(x => x.id === Number(orgId));

        setExportOrganizationLocalStorage(org);

        dispatch(dataSetSelectedExporterOrganization(org));
    };

    const getOrganizationOptions = useMemo(() => {
        return organizations?.filter(x => x.isActive).filter(x => (session?.user?.organizationIds.some(y => x.id === y))).map((x) => {
            return { value: x.id, label: `${x.code} - ${x.name}` };
        }) ?? [];
    }, [organizations, session, currentUserOrgs]);

    const getPackhouseOptions = useMemo(() => {
        const packhouses = sites.filter(x => x.isPackhouse && x.isActive);
        return  packhouses.filter(x => (session?.user?.siteIds.some(y => x.id === y)))
            .filter(x => x.organizationIds?.some(y => selectedOrganizationIds?.some(z => y === z)))
            .map((x) => {
                return { value: x.id, label:  `${x.shortDescription} - ${x.description}` };
            }) ?? [];
    }, [sites, selectedOrganizationIds, session, currentUserSites]);

    const getSelectedOrganizationOptions = useMemo(() => {
        return getOrganizationOptions?.filter(x => selectedOrganizationIds?.some(y => y === x.value)) ?? [];
    }, [selectedOrganizationIds, getOrganizationOptions]);

    const getSelectedPackhouseOptions = useMemo(() => {
        return getPackhouseOptions?.filter(x => selectedSiteIds?.some(y => y === x.value)) ?? [];
    }, [selectedSiteIds, getPackhouseOptions]);

    const getExportOrganizationOptions = useMemo(() => {
        const exporters = organizations.filter(x => x.isActive && x.exporter !== null);
        if (!exporters) return [];

        return exporters.map((x) => {
            return { label: x.name, value: x.id };
        });
    }, [organizations]);

    const getBreadcrumbs = useMemo(() => {
        const rights = session?.user.rights ?? [];
        const right = rights.filter(x => x.isActive && !x.code.endsWith('_EDIT'))?.find(x => x.url === path);
        const breadcrumbs : Array<{ name : string; url : string }> = [];
        if (right) {
            let currentRight = right ? { ...right } : undefined;
            if (right) {
                breadcrumbs.push({ name: right.name, url: right.url });
            }
            while (currentRight?.breadcrumbParentId !== undefined) {
                const parent = rights.find(x => x.id === currentRight?.breadcrumbParentId);
                currentRight = parent ? { ...parent } : undefined;
                if (parent) {
                    breadcrumbs.push({ name: parent.name, url: parent.url });
                }
            }
        }
        return breadcrumbs.reverse().map((x) => {
            if (x.url) {
                return <Link color='inherit' to={x.url} key={'breadcrumb_' + x.url + '_' + x.name} className={'breadcrumbLink'}>{x.name}</Link>;
            } else {
                return <span color={'inherit'} key={'breadcrumb_' + x.url + '_' + x.name}>{x.name}</span>;
            }
        });
    }, [session, path]);

    const openUserSettingsDialog = () => {
        setShowUserSettingsDialog(true);
    };

    const closeUserSettingsDialog = () => {
        if (selectedOrganizationIds === undefined || selectedSiteIds === undefined) {
            return;
        }
        setShowUserSettingsDialog(false);
    };

    const resetHomeScreenAsDefault = () => {
        setHomeUrlStorage('/');
        navPath('/');
    };

    const updateVersion = async () => {
        await clearLocalStorage();
        emptyCacheStorage();
        window.location.reload();
    };

    const getOrganizationName = (id : number) => organizations.find(x => x.id === id)?.name;
    const getSiteDescription = (id : number) => sites.find(x => x.id === id)?.description;

    const getOrganizationHeading = useMemo(() => {
        if (!selectedOrganizationIds) return 'NO ORGANIZATION SELECTED';

        const allOrganizationCount = getOrganizationOptions.length;
        const selectedOrgCount = selectedOrganizationIds?.length;
        let availableOrgCount = session?.user?.organizationIds?.length ?? allOrganizationCount;

        if (availableOrgCount === 0) {
            availableOrgCount = allOrganizationCount;
        }

        switch (selectedOrgCount) {
            case 0:
                return 'NO ORGANIZATION SELECTED';
            case 1:
                return getOrganizationName(selectedOrganizationIds[0]);
            case availableOrgCount:
                return 'ALL ORGANIZATIONS';
            default:
                return 'MULTIPLE ORGANIZATIONS';
        }
    }, [organizations, selectedOrganizationIds, session]);

    const getSiteHeading = useMemo(() => {
        if (!selectedSiteIds) return 'NO SITE SELECTED';

        const allSiteCount = getPackhouseOptions.length;
        const selectedSiteCount = selectedSiteIds?.length;
        let availableSiteCount = session?.user?.siteIds?.length ?? allSiteCount;

        if (availableSiteCount === 0) {
            availableSiteCount = allSiteCount;
        }

        switch (selectedSiteCount) {
            case 0:
                return 'NO SITE SELECTED';
            case 1:
                return getSiteDescription(selectedSiteIds[0]);
            case availableSiteCount:
                return 'ALL SITES';
            default:
                return 'MULTIPLE SITES';
        }
    }, [sites, selectedSiteIds, session]);

    const getThemeOptions = useMemo(() => {
        return APP_THEMES.map((x) => {
            return { value: x, label: x === 'Dark' ? 'Dark (Beta)' : 'Light' };
        }) ?? [];
    }, [session]);

    const onSelectedThemeChange = (e : React.ChangeEvent<{}>, selectedTheme : IOptionType) => {
        setSelectedThemeOption(selectedTheme);
        setSelectedThemeLocalStorage(selectedTheme.value?.toString());

        // this is needed to refresh the site so that the new theme variables can be loaded
        window.location.reload();
    };

    const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

    const signOutGoogle = () => {
        authSignOutGoogle();
    };

    const onlogoutLongPress = () => {
        setShowLogoutLongPressPopup(true);
    };

    const onlogoutClick = () => {
        signOutGoogle();
    };

    const getMasterDataLastSyncDate = () => {
        const lastSyncDate : moment.Moment = getMasterDataLastSyncDateLocalStorage();

        return moment(lastSyncDate).format(DATE_FORMAT_DEFAULT);
    };

    const onMasterDataResyncClick = async () => {
        setShowUserSettingsDialog(false);
        try {
            dispatch(dataSetMasterDataSyncIsLoading(true));
            generalShowInfoSnackbar('Master data is being synced');

            await syncMasterData(true);

            generalShowSuccessSnackbar('Master data sync completed.');
            dispatch(dataSetMasterDataSyncIsLoading(false));
        } catch (e) {
            generalShowErrorSnackbar('Failed to sync master data');
        }
    };

    const logoutLongPress = useLongPress(onlogoutLongPress, onlogoutClick, 2000);

    return (
        <AppBar position='static' className={'noWrap'} style={{ zIndex: 1 }} elevation={0}>
            <Toolbar variant='navbar'>
                <div className={'flx1 aic fdr'}>
                    {
                        isLoggedIn && session &&
                        <CustomTooltip title={isMasterDataSyncRunning ? 'Master Data is being synced' : ''}>
                            <IconButton disabled={isMasterDataSyncRunning} color={'inherit'} className={'mr20'} onMouseEnter={props.toggleLeftDrawer} onClick={props.toggleLeftDrawer}>
                                <Icon className={'cw'}>menu</Icon>
                            </IconButton>
                        </CustomTooltip>
                    }
                    {!isMasterDataSyncRunning
                        ?
                        <Link to={getHomeUrlStorage()} className={'fdr aic curp mt5 mb5 mr40'} style={{ textDecoration: 'unset', color: 'unset' }}>
                            {<img height={42} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} className={'mr10 curp disableImageDrag'}/>}
                            <Typography color='inherit' variant='h6'>
                                    PACKMAN
                            </Typography>
                        </Link>
                        :
                        <div className={'fdr aic mt5 mb5 mr40'}>
                            {<img height={42} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} className={'mr10 curp disableImageDrag'}/>}
                            <Typography color='inherit' variant='h6'>
                                PACKMAN
                            </Typography>
                        </div>
                    }
                    <Breadcrumbs separator={'>'} className={'mt5 cs breadcrumbSubText'}>
                        {getBreadcrumbs}
                    </Breadcrumbs>
                </div>
                <div className={'flx1 aic fdr jcfe'}>
                    <div style={{ flexWrap: 'wrap' }} className={'flx1 aic'}>
                        {
                            isLoggedIn && session &&
                            <Typography className={'flx1 cs'} variant='subtitle1' color='inherit'>
                                {getOrganizationHeading}
                            </Typography>
                        }
                        <div className={'w10'}/>
                        {
                            isLoggedIn && session &&
                            <Typography className={'flx1 cs'} variant='subtitle1' color='inherit'>
                                {getSiteHeading}
                            </Typography>
                        }
                    </div>
                    {
                        isLoggedIn && session &&
                        <div className={'fdr aic pl10'}>
                            <Typography variant='h6' color='inherit'>{session.user.name}</Typography>
                            <CustomTooltip title={!isMasterDataSyncRunning ? 'Settings' : 'Master Data is being synced'}>
                                <IconButton disabled={isMasterDataSyncRunning} className={'ml5'} color={'inherit'} onClick={openUserSettingsDialog}>
                                    {
                                        session.user?.profilePictureURL ?
                                            <>
                                                <img src={session.user.profilePictureURL} className={'w48 h48 Round'}/>
                                                <Icon className={'cw posa post35 posl40'}>account_circle</Icon>
                                            </>
                                            :
                                            <Icon className={'cw'}>account_circle</Icon>
                                    }
                                </IconButton>
                            </CustomTooltip>
                        </div>
                    }
                    { !isLatestVersion && !isMasterDataSyncRunning && (
                        <Fab
                            color={'secondary'}
                            onClick={updateVersion}
                            className={'w100 h15 br20'}
                        >
                            <Icon className={'mr5'} fontSize={'small'}>
                                cloud_upload
                            </Icon>
                            Update
                        </Fab>
                    )}
                    <sub className={'ml10 pt5 pr10'}>V {VERSION.version}</sub>
                </div>
            </Toolbar>
            {/* User Settings Dialog */}
            {!!showUserSettingsDialog &&
                <PackmanDialog
                    title='User Settings'
                    isInfo
                    isCloseDisabled={selectedOrganizationIds === undefined || selectedSiteIds === undefined}
                    isOpen={showUserSettingsDialog}
                    onClose={closeUserSettingsDialog}>
                    <div className={'w300 flx1 fdc aic m20'}>
                        <AutocompleteSelect
                            name={'organizations'}
                            label={'Organizations'}
                            options={getOrganizationOptions}
                            value={getSelectedOrganizationOptions}
                            onChange={onOrganizationChange}
                            isMulti
                            disableClearable
                            className={'wfill pb10'}>
                        </AutocompleteSelect>
                        <AutocompleteSelect
                            name={'sites'}
                            label={'Sites'}
                            options={getPackhouseOptions}
                            value={getSelectedPackhouseOptions}
                            onChange={onSiteChange}
                            isMulti
                            disableClearable
                            className={'wfill pb10'}>
                        </AutocompleteSelect>
                        <CustomAutoSuggest
                            value={selectedExporterOrganization ? selectedExporterOrganization.id : ''}
                            hasInitialValue={!!selectedExporterOrganization}
                            className={'wfill mb10 pl10 pr10'}
                            label={'Exporter'}
                            field={'exportOrganization'}
                            options={getExportOrganizationOptions}
                            onChange={onExportOrganizationChange}
                            placeholder={'Select Exporter...'}
                        />
                        <div className={'flx1'}/>
                        <AutocompleteSelect
                            name={'theme'}
                            label={'Theme'}
                            options={getThemeOptions}
                            value={getSelectedThemeOption}
                            onChange={onSelectedThemeChange}
                            disableClearable
                            className={'wfill pb10'}>
                        </AutocompleteSelect>
                        <div className={'fdc mt10 mb10 aic jcc'}>
                            <Typography variant={'body2'}>{`Last sync date: ${getMasterDataLastSyncDate()}`}</Typography>
                            <CustomTooltip className='aic jcc' title={'Does a full resyncing of the master data'}>
                                <Button variant={'text'} onClick={onMasterDataResyncClick}>Resync Master Data</Button>
                            </CustomTooltip>
                        </div>
                        <div className={'mt10 mb10'}>
                            {getHomeUrlStorage() !== '/'
                                ?
                                <PillButton
                                    text={'Reset Home As Default'}
                                    className={'ml15 pl20 pr20 h35 cpd'}
                                    onClick={resetHomeScreenAsDefault}
                                    color={'secondary'}
                                    size={'small'}
                                ></PillButton>
                                :
                                <CustomTooltip title={'Home already set as default'}>
                                    <Button variant={'text'} disabled={true}>Reset Home As Default</Button>
                                </CustomTooltip>
                            }
                        </div>
                        <div className={'mt10 mb10'}>
                            <CustomTooltip title={'Logout'}>
                                <Button variant={'text'} {...logoutLongPress}>Logout</Button>
                            </CustomTooltip>
                        </div>
                    </div>
                </PackmanDialog >
            }
            {/* Justin's special logout long press popup */}
            {!!showLogoutLongPressPopup &&
                <PackmanDialog
                    title='In Memory of Justin'
                    isInfo={true}
                    isOpen={showLogoutLongPressPopup}
                    onClose={() => setShowLogoutLongPressPopup(false)}>
                    <Screen isPadded={false} isScrollable={false}>
                        <div className={'w300 fdc aic m20'}>
                            <Typography>{'This popup is in memory of the logout button request from Justin'}</Typography>
                        </div>
                    </Screen>
                </PackmanDialog >
            }
        </AppBar>
    );
};

export default NavBar;

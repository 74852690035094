import * as React from 'react';
import TextField from '@mui/material/TextField';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import { IOptionType, AutocompleteValueType } from '../../@types/helper';
import Autocomplete, { AutocompleteChangeReason, AutocompleteInputChangeReason, AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import { Chip, FilterOptionsState } from '@mui/material';
import { createSelector } from 'reselect';
import PillButton from './PillButton';

export interface IAutocompleteSelectProps {
    name : string;
    label : string;
    options : Array<IOptionType>;
    value : AutocompleteValueType;
    onChange : (e : React.ChangeEvent<{}> | undefined, v : AutocompleteValueType, reason : AutocompleteChangeReason | 'selectAll' | 'deselectAll') => void;
    onBlur ?: (e : React.FocusEvent<HTMLDivElement>) => void;
    onKeyDown ?: (e : React.KeyboardEvent<HTMLDivElement>) => void;
    className ?: string;
    placeholder ?: string;
    isMulti ?: boolean;
    disabled ?: boolean;
    formControlProps ?: FormControlProps;
    transparentBackground ?: boolean;
    hasError ?: boolean;
    clearOnBlur ?: boolean;
    blurOnSelect ?: boolean;
    disableClearable ?: boolean;
    errorMessage ?: string;
    filterOptions ?: (options : Array<IOptionType>, state : FilterOptionsState<IOptionType>) => Array<IOptionType>;
    freeSolo ?: boolean;
    textFieldInputRef ?: React.RefObject<HTMLInputElement>;
    autoFocus ?: boolean;
    isLoading ?: boolean;
    preventSelectAll ?: boolean;
    onInputChange ?: (event : React.ChangeEvent<{}>, value : any, reason : AutocompleteInputChangeReason) => void;
    errorMargin ?: boolean;
}

interface IAutocompleteSelectState {
    inputFocussed ?: boolean;
}

export default class AutocompleteSelect extends React.Component<IAutocompleteSelectProps, IAutocompleteSelectState> {

    constructor(props : IAutocompleteSelectProps) {
        super(props);
        this.state = {
            inputFocussed: false,
        };
    }

    private setInputFocussed = (inputFocussed : boolean) => this.setState({ inputFocussed });

    public isOptionEqualToValue = (option : IOptionType, value : IOptionType) => option?.value === value?.value;

    private getInputFocussed = (props : IAutocompleteSelectProps, state : IAutocompleteSelectState) => state.inputFocussed;
    private getValues = (props : IAutocompleteSelectProps) => props.value;
    private getOptions = (props : IAutocompleteSelectProps) => props.options;

    private allNotSelected = createSelector([this.getOptions, this.getValues], (options, values) => {
        return options.some(x => !values.some((y : IOptionType) => this.isOptionEqualToValue(x, y)));
    });

    private selectAllClicked = (e : React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (this.props.isMulti) {
            if (this.allNotSelected(this.props)) {
                this.props.onChange(undefined, this.props.options, 'selectAll');
            } else {
                this.props.onChange(undefined, [], 'deselectAll');
            }
        }
    };
    public getOptionLabel = (option : IOptionType) => option?.label ?? '';

    // hides the delete icon when only one option, used with disableClearable to make it proper unclearable
    public renderTags = (value : Array<IOptionType>, getTagProps : AutocompleteRenderGetTagProps) => {
        if (value.length === 1) {
            return value.map((option, index) => (
                <Chip
                    label={option.label}
                    {...getTagProps({ index })}
                    onDelete={undefined}
                />
            ));
        }

        return value.map((option, index) => (
            <Chip
                label={option.label}
                {...getTagProps({ index })}
            />
        ));
    };

    private getSelectAllClass = createSelector([this.getInputFocussed, this.getValues], (isInputFocussed, values) => {
        if (isInputFocussed || values.length > 0) {
            return 'SelectAllLabelButtonHasValue';
        }
        return 'SelectAllLabelButton';
    });

    public render() {
        const { transparentBackground = true } = this.props;
        return (
            <Autocomplete
                clearOnBlur={this.props.clearOnBlur}
                onKeyDown={this.props.onKeyDown}
                multiple={this.props.isMulti}
                id={this.props.name}
                options={this.props.options}
                defaultValue={[]}
                value={this.props.value ?? (this.props.isMulti ? [] : null)}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                disabled={this.props.disabled}
                filterOptions={this.props.filterOptions}
                freeSolo={this.props.freeSolo}
                className={`${this.props.className || ''} pl10 pr10 fdc ais jcfs`}
                getOptionLabel={this.getOptionLabel}
                isOptionEqualToValue={this.isOptionEqualToValue}
                noOptionsText={'No options available.'}
                size={'small'}
                filterSelectedOptions
                renderTags={this.props.disableClearable ? this.renderTags : undefined}
                disableClearable={this.props.disableClearable}
                blurOnSelect={this.props.blurOnSelect ?? true}
                autoHighlight={true}
                onInputChange={this.props.onInputChange}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={`${option.value} - ${option.label}`}>
                            {option.label}
                        </li>
                    );
                }}
                renderInput={params => (
                    <FormControl {...this.props.formControlProps} variant={'outlined'}>
                        <TextField
                            {...params}
                            className={`${transparentBackground ? '' : 'bcw'}`}
                            label={this.props.label}
                            margin={'dense'}
                            variant={'standard'}
                            inputRef={this.props.textFieldInputRef}
                            autoFocus={this.props.autoFocus}
                            placeholder={this.props.value ? '' : this.props.placeholder}
                            fullWidth
                            onFocus={() => {
                                this.setInputFocussed(true);
                            }}
                            onBlur={() => {
                                this.setInputFocussed(false);
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {this.props.isMulti && !this.props.preventSelectAll && this.props.options?.length > 1 &&
                                            <PillButton
                                                className={`${this.getSelectAllClass(this.props, this.state)} w120 h20`}
                                                onClick={this.selectAllClicked}
                                                text={this.allNotSelected(this.props) ? 'Select All' : 'Deselect All'}
                                                color={'secondary'} />
                                        }
                                        {this.props.isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                        <FormHelperText className={`mt0 ${this.props.errorMargin ? 'h20' : ''}`} error>
                            {this.props.hasError && this.props.errorMessage ? this.props.errorMessage : ''}
                        </FormHelperText>
                    </FormControl>
                )}
            />);
    }
}



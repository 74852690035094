import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { MaterialTypeFormValues } from '../../../../@types/model/masterData/materialType/materialTypeFormValues';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';

interface IMaterialTypeFormProps {
    isLoading : boolean;
}

interface IMaterialTypeFormState {
}

type MaterialTypeFormPropsType = IMaterialTypeFormProps & FormikProps<MaterialTypeFormValues>;

export default class MaterialTypeForm extends React.Component<MaterialTypeFormPropsType, IMaterialTypeFormState> {

    constructor(props : MaterialTypeFormPropsType) {
        super(props);
    }

    public render() {
        return (
            <Form className={'p20'}>
                <FormTextInput name={'name'} label={'Name'}/>
                <FormSingleToggleButton name={'isPackType'} label={'Is Pack Type'} />
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

import * as React from 'react';
import { Form, FormikProps, FieldArray } from 'formik';
import Button from '@mui/material/Button';
import { IntakeFormValues } from '../../../@types/model/intake/form/intakeFormValues';
import FormTextInput from '../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../components/input/form/FormSingleToggleButton';
import PillButton from '../../../components/input/PillButton';
import Screen from '../../../components/Screen';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IOptionType } from '../../../@types/helper';
import FormAutocompleteSelect from '../../../components/input/form/FormAutoCompleteSelect';
import IntakeLineForm from './IntakeLineForm';
import { IOrganization } from '../../../@types/model/masterData/organization/organization';
import { ICommodity } from '../../../@types/model/masterData/commodity/commodity';
import { IFarm } from '../../../@types/model/masterData/farm/farm';
import { ISite } from '../../../@types/model/masterData/site/site';

interface IIntakeFormProps {
    isLoading : boolean;
    organizationOptions : Array<IOptionType>;
    siteOptions : Array<IOptionType>;
    commodityOptions : Array<IOptionType>;
    farmOptions : Array<IOptionType>;
}

interface IIntakeFormState {
    isLoading : boolean;
}

type IntakeFormPropsType = IIntakeFormProps & FormikProps<IntakeFormValues>;

class IntakeForm extends React.Component<IntakeFormPropsType, IIntakeFormState> {

    constructor(props : IntakeFormPropsType) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    public render() {
        return (
            <Form  className={'hfill'}>
                <Screen actions={
                    <div className={'fdr ml10 ais jcfe pt10 pb10 pr20'}>
                        <Button
                            className={'fwb h35'}
                            variant='text' color='primary'
                            type={'reset'}>
                            Clear
                        </Button>
                        <PillButton
                            disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                            className={'ml15 pl20 pr20 h35'}
                            text={'Save'}
                            type={'submit'}
                            color={'secondary'}
                        />
                    </div>
                }>
                    <div className='fdc wfill'>
                        <div className='fdr aic'>
                            <FormAutocompleteSelect name={'organization'} label={'Organization'} options={this.props.organizationOptions} className='flx1' disabled/>
                            <FormAutocompleteSelect name={'site'} label={'Current Site'} options={this.props.siteOptions} className='flx1' disabled/>
                            <FormAutocompleteSelect name={'commodity'} label={'Commodity'} options={this.props.commodityOptions} className='flx1' disabled/>
                            <FormAutocompleteSelect name={'farm'} label={'Farm'} options={this.props.farmOptions} className='flx1' disabled/>
                            <FormTextInput name={'shipmentGuid'} label={'Shipment Reference'} className='flx1 pt5'/>
                            <FormSingleToggleButton name={'isActive'} label={'Is Active'} disabled/>
                        </div>
                        <FieldArray
                            name={'intakeLines'}
                            component={IntakeLineForm}
                        />
                    </div>
                </Screen>
            </Form>
        );
    }
}

const getOrganizations = (state : IRootState) => state.masterData.organizations;

const getOrganizationOptions = createSelector(
    [getOrganizations],
    (organizations : Array<IOrganization>) => {
        return organizations.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }));
    },
);

const getCommodities = (state : IRootState) => state.masterData.commodities;

const getCommodityOptions = createSelector(
    [getCommodities],
    (commodities : Array<ICommodity>) => {
        return commodities.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }));
    },
);

const getFarms = (state : IRootState) => state.masterData.farms;

const getFarmOptions = createSelector(
    [getFarms],
    (farms : Array<IFarm>) => {
        return farms.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }));
    },
);

const getSites = (state : IRootState) => state.masterData.sites;

const getSiteOptions = createSelector(
    [getSites],
    (sites : Array<ISite>) => {
        return sites.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.description}`, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        organizationOptions: getOrganizationOptions(state),
        siteOptions: getSiteOptions(state),
        commodityOptions: getCommodityOptions(state),
        farmOptions: getFarmOptions(state),
    };
};

export default connect(
    mapStateToProps,
)(IntakeForm);

import { IBaseModel } from '../../../model';
import { IMarketFormValues } from './marketFormValues';

export interface IMarket extends IBaseModel {
    regionId : number;
    channel : string;
    code : string;
    name : string;
}

export class Market implements IMarket {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public name : string;
    public channel : string;
    public regionId : number;

    public constructor(formValues : IMarketFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.code = formValues.code;
        this.name = formValues.name;
        this.channel = formValues.channel?.label[0] ?? '';
        this.regionId = Number(formValues.region?.value) ?? 0;
    }
}

import * as React from 'react';
import { Form, FormikProps } from 'formik';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import { IOptionType } from '../../../../@types/helper';
import FormAutocompleteSelect from '../../../../components/input/form/FormAutoCompleteSelect';
import { IRootState } from '../../../../@types/redux';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { PrinterFormValues } from '../../../../@types/model/masterData/printer/printerFormValues';
import { IPrintServer } from '../../../../@types/model/masterData/printServer/printServer';

interface IPrinterFormProps {
    isLoading : boolean;
    printServerOptions : Array<IOptionType>;
}

interface IPrinterFormState {
}

type PrinterFormPropsType = IPrinterFormProps & FormikProps<PrinterFormValues>;

class PrinterForm extends React.Component<PrinterFormPropsType, IPrinterFormState> {

    constructor(props : PrinterFormPropsType) {
        super(props);
    }

    public render() {
        return (
            <Form className={'p20'}>
                <FormTextInput name={'name'} label={'Name'}/>
                <FormTextInput name={'defualtPaper'} label={'Default Paper'}/>
                <FormTextInput name={'apiAddress'} label={'API Address'}/>
                <FormAutocompleteSelect name={'printServer'} label={'Print Server'} options={this.props.printServerOptions}/>
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const getAuth = (state : IRootState) => state.auth;
const getPrintServers = (state : IRootState) => state.masterData.printServers;

const getPrintServerOptions = createSelector(
    [getPrintServers, getAuth],
    (printServers : Array<IPrintServer>) => {
        return printServers.map(x => ({ label: `${x.name}`, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        printServerOptions: getPrintServerOptions(state),
    };
};

export default connect(
    mapStateToProps,
)(PrinterForm);

import { IBaseModel } from '../../model';
import { IDomainFormValues } from './domainFormValues';

export interface IDomain extends IBaseModel {
    domainName : string;
}
export class Domain implements IDomain {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public domainName : string;

    public constructor(formValues : IDomainFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.domainName = formValues.domainName;
    }
}

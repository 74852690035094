import * as React from 'react';
import { ListItem, Icon, IconButton, List, Switch, Card, Popper, Grow, Button, ClickAwayListener, TextField, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { DATE_FORMAT_DEFAULT_NO_TIME } from '../../appConstants';
import lodash from 'lodash';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall } from '../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import moment from 'moment';
import DateRange from '../../components/DateRange';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { generalShowSuccessSnackbar, generalShowErrorSnackbar } from '../../store/general/Functions';
import { dataSetSites, dataSetCommodities } from '../../store/masterData/Actions';
import { ISite } from '../../@types/model/masterData/site/site';
import { IIntake } from '../../@types/model/intake/intake';
import { IBatch } from '../../@types/model/batch/batch';
import { ILot } from '../../@types/model/lot/lot';
import { IDispatchInstruction } from '../../@types/model/dispatch/dispatchInstruction';
import { IStock } from '../../@types/model/stock/stock';
import { IOverviewRelatedData } from '../../@types/model/overview/overview';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import BatchHttpService from '../../services/http/batch/batchHttpService';
import LotHttpService from '../../services/http/lot/lotHttpService';
import OverviewRelatedDataHttpService from '../../services/http/general/overviewRelatedDataHttpService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWeight, faBoxOpen, faPallet, faCalendarCheck, faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import PillButton from '../../components/input/PillButton';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import { createSelector } from 'reselect';
import { IOptionType, CustomChangeEvent } from '../../@types/helper';
import CommodityHttpService from '../../services/http/masterData/commodityHttpService';
import { dataSetLot } from '../../store/data/Functions';
import ConfirmationPrompt from '../../components/dialog/ConfirmationPrompt';
import Screen from '../../components/Screen';
import CustomTooltip from '../../components/tooltip/tooltip';
import { syncMasterData } from '../../services/masterDataSyncService';
import materialTheme from '../../styles/materialTheme';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dateRangePicker = require('react-date-range');

interface IDateRange {
    selection : {
        startDate : Date;
        endDate : Date;
    };
}

interface IOverviewDashboardProps {
    isLoading : boolean;
    dataSetSites : DispatchCall<Array<ISite>>;
    selectedOrganizationIds : Array<number> ;
    selectedSiteIds : Array<number>;
    overviewRelatedData : IOverviewRelatedData;
    dataSetCommodities : DispatchCall<Array<ICommodity>>;
    commodityData : Array<ICommodity>;
    sites : Array<ISite>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    orchards : Array<IOrchard>;
    farms : Array<IFarm>;
}

interface IOverviewDashboardState {
    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;
    isLoading : boolean;
    expandedIntakeLineLayers : {[expanded : number] : number | undefined};
    intakes : Array<IIntake>;
    batches : Array<IBatch>;
    availableLots : Array<ILot>;
    batchLots : Array<ILot>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    orchards : Array<IOrchard>;
    packs : Array<IPack>;
    farms : Array<IFarm>;
    stocks : Array<IStock>;
    dispatches : Array<IDispatchInstruction>;
    showEnded : boolean;
    showFinalized : boolean;
    showTemporary : boolean;
    lotToFinalize ?: ILot;

    open : boolean;
    isFinalizeNormalLotDialogOpen : boolean;
    isFinalizeBatchLotDialogOpen : boolean;
    selectedCommodityOption ?: IOptionType;
    showRangePicker : boolean;

    onYesClick : () => void;
    onNoClick : () => void;
    message : string;
    showPrompt : boolean;
}

class OverviewDashboard extends React.Component<IOverviewDashboardProps, IOverviewDashboardState> {
    private anchorEl : any;

    constructor(props : IOverviewDashboardProps) {
        super(props);
        this.state = {
            isLoading: false,
            selectedFromDate: moment().local().startOf('day').subtract(1, 'days'),
            selectedToDate: moment().local().endOf('day'),
            expandedIntakeLineLayers: {},
            intakes : [],
            batches: [],
            availableLots: [],
            batchLots: [],
            stocks: [],
            dispatches: [],
            commodities: [],
            varieties: [],
            farms: [],
            orchards: [],
            packs: [],
            showEnded: false,
            showFinalized: false,
            showTemporary: false,

            open: false,
            isFinalizeNormalLotDialogOpen: false,
            isFinalizeBatchLotDialogOpen: false,
            showRangePicker: false,

            onYesClick: () => null,
            onNoClick: () => null,
            message: '',
            showPrompt: false,
        };
    }

    private getDate = (type : 'from' | 'to') => {
        switch (type) {
            case 'from':
                return this.state.selectedFromDate.startOf('day').utc().unix() * 1000;
            case 'to':
                return this.state.selectedToDate.endOf('day').utc().unix() * 1000;
        }
    };

    public componentDidMount = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        try {
            try {
                const res2 = await OverviewRelatedDataHttpService.getAllOverviewRelatedData(this.getDate('from'), this.getDate('to'), this.props.selectedSiteIds, !isIndexedDBAvailable);

                if (!isIndexedDBAvailable) {
                    const res3 = await CommodityHttpService.getCommodityData();

                    this.props.dataSetCommodities(res3.data);
                }

                this.setData(res2.data, isIndexedDBAvailable);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred retrieving overview data.');
                this.setLoading(false);
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading sites.');
            this.setLoading(false);
        }
    };

    public componentDidUpdate = async (prevProps : IOverviewDashboardProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            /* prop changes go here */
            if (nextProps.selectedSiteIds !== undefined && prevProps.selectedSiteIds !== nextProps.selectedSiteIds) {
                await this.refreshDataClick();
            }
        }
    };

    private toggleShowPicker = () => {
        this.setState({ showRangePicker: !this.state.showRangePicker });
    };

    private handleRangeChange = (ranges : IDateRange) => {
        const start = moment({ y: ranges.selection.startDate.getFullYear(), M: ranges.selection.startDate.getMonth(), d: ranges.selection.startDate.getDate() });
        const end = moment({ y: ranges.selection.endDate.getFullYear(), M: ranges.selection.endDate.getMonth(), d: ranges.selection.endDate.getDate() });
        this.handleDateRangeChange(start, end);
    };

    private handleDateRangeChange = (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => {
        const selectedFromDate = changedBy === 'start' ? moment(start).startOf('day') : end < start ? moment(end).startOf('day') : moment(start).startOf('day');
        const selectedToDate = changedBy === 'end' ? moment(end).endOf('day') : start > end ? moment(start).endOf('day') : moment(end).endOf('day');
        this.setState({ selectedFromDate, selectedToDate });
    };

    private onExpandChange = (isExpanded : boolean, intakeId : number, intakeLineLayerId : number) => {
        const expandedIntakeLineLayers = { ...this.state.expandedIntakeLineLayers };
        expandedIntakeLineLayers[intakeId] = isExpanded ? intakeLineLayerId : undefined;
        this.setState({ expandedIntakeLineLayers });
    };

    private setLoading = (isLoading : boolean = false) => this.setState({ isLoading });

    private getLot = (lotId ?: number) => this.state.availableLots.find(x => x.id === lotId) || this.state.batchLots.find(x => x.id === lotId);
    private getLotGrossWeight = (lotId ?: number) => this.getLot(lotId)?.grossWeight || 0;
    private getLotIsFinalized = (lotId ?: number) => this.getLot(lotId)?.isFinalized;

    private getCommodityCode = (commodityId ?: number) => this.state.commodities.find(x => x.id === commodityId)?.code || '';
    private getVarietyCode = (varietyId ?: number) => this.state.varieties.find(x => x.id === varietyId)?.code || '';
    private getOrchardCode = (orchardId ?: number) => this.state.orchards.find(x => x.id === orchardId)?.code || '';
    private getPackCode = (packId ?: number) => this.state.packs.find(x => x.id === packId)?.code || '';
    private getFarmCode = (farmId ?: number) => this.state.farms.find(x => x.id === farmId)?.code || '';

    private finalizeNormalLotButtonPressed = (lot : ILot) => this.setState({ lotToFinalize: lot, isFinalizeNormalLotDialogOpen: true });
    private finalizeBatchLotButtonPressed = (lot : ILot) => this.setState({ lotToFinalize: lot, isFinalizeBatchLotDialogOpen: true });
    private finalizeNormalLotNoClicked = () => this.setState({ lotToFinalize: undefined, isFinalizeNormalLotDialogOpen: false });
    private finalizeBatchLotNoClicked = () => this.setState({ lotToFinalize: undefined, isFinalizeBatchLotDialogOpen: false });

    private submitFinalizeBatchLot = async () => {
        if (this.state.lotToFinalize) {
            this.setLoading(true);
            try {
                const res = await LotHttpService.doLotAdjustment(this.state.lotToFinalize.id);

                if (res.data) {
                    res.data.map(x => dataSetLot(x));

                    this.setState({ lotToFinalize: undefined, isFinalizeBatchLotDialogOpen: false }, () => {
                        this.setLoading(false);
                    });
                    generalShowSuccessSnackbar('Lot finalized!');
                } else {
                    generalShowErrorSnackbar('An error occurred finalizing the lot.');
                    this.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred finalizing the lot.');
                this.setLoading(false);
            }
        }
    };

    private submitFinalizeNormalLot = async () => {
        if (this.state.lotToFinalize) {
            this.setLoading(true);
            try {
                const res = await LotHttpService.doLotAdjustment(this.state.lotToFinalize.id);

                if (res.data) {
                    res.data.map(x => dataSetLot(x));

                    this.setState({ lotToFinalize: undefined, isFinalizeNormalLotDialogOpen: false }, () => {
                        this.setLoading(false);
                    });
                    generalShowSuccessSnackbar('Lot finalized!');
                } else {
                    generalShowErrorSnackbar('An error occurred finalizing the lot.');
                    this.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred finalizing the lot.');
                this.setLoading(false);
            }
        }
    };

    private submitEndBatch = async (row : IBatch) => {
        if (row) {
            this.setLoading(true);
            try {
                await BatchHttpService.endBatch(row?.id);
                await this.refreshDataClick();

                this.closeConfirmationPrompt();

                generalShowSuccessSnackbar('Batch ended!');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred ending the batch.');
                this.setLoading(false);
            }
        }
    };

    private submitResumeBatch = async (row : IBatch) => {
        if (row) {
            this.setLoading(true);
            try {
                await BatchHttpService.resumeBatch(row?.id);
                await this.refreshDataClick();

                this.closeConfirmationPrompt();

                generalShowSuccessSnackbar('Batch resumed!');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred resuming the batch.');
                this.setLoading(false);
            }
        }
    };

    private renderIntake = (row : IIntake) => {
        return(
            <Card className={'mnh150 mxh300 wfill mt5 mb5 fdc oya'} elevation={3}>
                <div className={'fdr h30 pl2 wfill aic jcsb bcp cw'}>
                    #{row.id}
                    <div className={'flx1'}/>
                    <div className={'pr15'}>
                        <FontAwesomeIcon icon={faWeight} className={'cw mr5'} size={'sm'}/>
                        {row.intakeLines.map(x => x.grossWeight).reduce((a, b) => a + b, 0).toFixed(2)} kg
                        {row.shipmentGuid && ` | Ref:${row.shipmentGuid}`}
                    </div>
                </div>
                {row.intakeLines.map(x =>
                    x.intakeLineLayers.length > 1 ?
                        <div>
                            {x.intakeLineLayers.map((y) => {
                                const lot = this.getLot(y.lotId);
                                return <Accordion
                                    key={`${row.id}_${x.id}_${y.id}`}
                                    className={'fdc wfill'}
                                    expanded={this.state.expandedIntakeLineLayers[row.id] === y.id}
                                    onChange={(e, isExpanded) => this.onExpandChange(isExpanded, row.id, y.id)}
                                >
                                    <AccordionSummary className={'aic flx1'} expandIcon={<Icon className={'cw'}>expand_more</Icon>}>
                                        <div className={'flx1 fw500 cw'}>
                                            {y.lotName}
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails className={'fdc hfill wfill'}>
                                        <div className={'fdr wfill pb10'}>
                                            {lot?.commodityId ? this.getCommodityCode(lot?.commodityId) + ((lot?.orchardId || lot?.packId || lot?.farmId || lot?.varietyId) ? ' || ' : '') : ''}
                                            {lot?.varietyId ? this.getVarietyCode(lot?.varietyId) + ((lot?.orchardId || lot?.packId || lot?.farmId) ? ' || ' : '') : ''}
                                            {lot?.farmId ? this.getFarmCode(lot?.farmId) + ((lot?.orchardId || lot?.packId) ? ' || ' : '') : ''}
                                            {lot?.orchardId ? this.getOrchardCode(lot?.orchardId) + (lot?.packId ? ' || ' : '') : ''}
                                            {lot?.packId ? this.getPackCode(lot?.packId) : ''}
                                        </div>
                                        <div className={'fdr wfill'}>
                                            <FontAwesomeIcon icon={faWeight} className={'pt2 mr5'} size={'sm'}/>
                                            {`Intake: ${Number(y.grossWeight).toFixed(2)}kg`}
                                        </div>
                                        <div className={'fdr wfill'}>
                                            <FontAwesomeIcon icon={faWeight} className={'pt2 mr5'} size={'sm'}/>
                                            {`Current: ${Number(this.getLotGrossWeight(y.lotId)).toFixed(2)}kg`}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>;
                            })}
                        </div>
                        :
                        <div key={x.id} className={'fdc flx1 wfill'}>
                            <div className={'fdr pl10 pr5 h30 aic jcsb'}>
                                <div className={'flx1 fw500'}>
                                    {x.intakeLineLayers.map(y =>
                                        y.lotName,
                                    )}
                                </div>
                            </div>
                            {x.intakeLineLayers.map((y) => {
                                const lot = this.getLot(y.lotId);
                                return(
                                    <div className={'fdc hfill wfill pl10 pt10'}>
                                        <div className={'fdr wfill pb10'}>
                                            {lot?.commodityId ? this.getCommodityCode(lot?.commodityId) + ((lot?.orchardId || lot?.packId || lot?.farmId || lot?.varietyId) ? ' || ' : '') : ''}
                                            {lot?.varietyId ? this.getVarietyCode(lot?.varietyId) + ((lot?.orchardId || lot?.packId || lot?.farmId) ? ' || ' : '') : ''}
                                            {lot?.farmId ? this.getFarmCode(lot?.farmId) + ((lot?.orchardId || lot?.packId) ? ' || ' : '') : ''}
                                            {lot?.orchardId ? this.getOrchardCode(lot?.orchardId) + (lot?.packId ? ' || ' : '') : ''}
                                            {lot?.packId ? this.getPackCode(lot?.packId) : ''}
                                        </div>
                                        <div className={'fdr wfill'}>
                                            <FontAwesomeIcon icon={faWeight} className={'pt2 mr5'} size={'sm'}/>
                                            {`Intake: ${Number(y.grossWeight).toFixed(2)}kg`}
                                        </div>
                                        <div className={'fdr wfill'}>
                                            <FontAwesomeIcon icon={faWeight} className={'pt2 mr5'} size={'sm'}/>
                                            {`Current: ${Number(this.getLotGrossWeight(y.lotId)).toFixed(2)}kg`}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>)
                }
            </Card>
        );
    };

    private renderLot = (row : ILot) => <Card className={'mnh90 mt5 mb5 fdc'} elevation={3}>
        <div className={'fdr h30 pl10 pr8 aic bcp cw'}>
            {row.name}
            <div className={'flx1'}/>
            <FontAwesomeIcon icon={faWeight} className={'mr5'} size={'sm'}/>
            {Number(row.grossWeight).toFixed(2)} kg
        </div>
        <div className={'fdr aic p5 pr8'}>
            {row?.commodityId ? this.getCommodityCode(row?.commodityId) + ((row?.orchardId || row?.packId || row?.farmId || row?.varietyId) ? ' || ' : '') : ''}
            {row?.varietyId ? this.getVarietyCode(row?.varietyId) + ((row?.orchardId || row?.packId || row?.farmId) ? ' || ' : '') : ''}
            {row?.farmId ? this.getFarmCode(row?.farmId) + ((row?.orchardId || row?.packId) ? ' || ' : '') : ''}
            {row?.orchardId ? this.getOrchardCode(row?.orchardId) + (row?.packId ? ' || ' : '') : ''}
            {row?.packId ? this.getPackCode(row?.packId) : ''}
        </div>
        <div className={'fdr flx1'}>
            <div className={'flx1'}/>
            { !row.isFinalized && <CustomTooltip title={'Finalize Lot'}>
                <IconButton disabled={this.state.isLoading} className={'w30 h30 p0'} onClick={() => this.finalizeNormalLotButtonPressed(row)}>
                    <Icon>check_circle</Icon>
                </IconButton>
            </CustomTooltip>}
        </div>
    </Card>;

    private renderBatch = (row : IBatch) => {
        const batchLot = this.getLot(row?.lotId);
        return <Card className={'mnh90 mt5 mb5 fdc'} elevation={3}>
            <div className={'fdr h30 pl10 pr10 aic wfill bcp cw'}>
                Batch Lot - {row.batchCode}
            </div>
            <div className={'fdr h30 pl10 pr10 aic wfill fw500'}>
                {batchLot?.name}
            </div>
            <div className={'fdr wfill pl10 pr10 fw300'}>
                {batchLot?.commodityId ? this.getCommodityCode(batchLot?.commodityId) + ((batchLot?.orchardId || batchLot?.packId || batchLot?.farmId || batchLot?.varietyId) ? ' || ' : '') : ''}
                {batchLot?.varietyId ? this.getVarietyCode(batchLot?.varietyId) + ((batchLot?.orchardId || batchLot?.packId || batchLot?.farmId) ? ' || ' : '') : ''}
                {batchLot?.farmId ? this.getFarmCode(batchLot?.farmId) + ((batchLot?.orchardId || batchLot?.packId) ? ' || ' : '') : ''}
                {batchLot?.orchardId ? this.getOrchardCode(batchLot?.orchardId) + (batchLot?.packId ? ' || ' : '') : ''}
                {batchLot?.packId ? this.getPackCode(batchLot?.packId) : ''}
            </div>
            <div className={'fdr flx1 wfill'}>
                <div className={'flx1'}/>
                { !row?.endedOn && <CustomTooltip title={'End Batch'}>
                    <IconButton disabled={this.state.isLoading} className={'w30 h30 p0'} onClick={() => this.showConfirmationPrompt(() => this.submitEndBatch(row), this.closeConfirmationPrompt, 'Are you sure you want to end this batch?')}>
                        <FontAwesomeIcon icon={faCalendarCheck} className={'cred mr5'} size={'sm'}/>
                    </IconButton>
                </CustomTooltip>}
                { !!row?.endedOn && <CustomTooltip title={'Resume Batch'}>
                    <IconButton disabled={this.state.isLoading} className={'w30 h30 p0'} onClick={() => this.showConfirmationPrompt(() => this.submitResumeBatch(row), this.closeConfirmationPrompt, 'Are you sure you want to resume this batch?')}>
                        <FontAwesomeIcon icon={faCalendarPlus} className={'cpl mr5'} size={'sm'}/>
                    </IconButton>
                </CustomTooltip>}
                { batchLot && !batchLot?.isFinalized && <CustomTooltip title={'Finalize Lot'}>
                    <IconButton disabled={this.state.isLoading} className={'w30 h30 p0'} onClick={() => batchLot && this.finalizeBatchLotButtonPressed(batchLot)}>
                        <Icon>check_circle</Icon>
                    </IconButton>
                </CustomTooltip>}
            </div>
        </Card>;
    };

    private getStockStatusColour = (status : string) => {
        switch (status) {
            case 'Temp Building':
                return 'cOrange';
            case 'Dispatched':
                return 'cblue';
        }
    };

    private renderStock = (row : IStock) => <Card className={'mnh90 mt5 mb5 fdc'} elevation={3}>
        <div className={'fdr h30 pl10 pr5 aic jcsb bcp cw fw500'}>
            {!row.isTemporary ? row.barcode : row.tempBarcode}
            <div className={'flx1'}/>
            <div className={`${this.getStockStatusColour(row.status)}`}>{row.status}</div>
        </div>
        <div className={'fdr h50 pl10 aic'}>
            <FontAwesomeIcon icon={faWeight} className={'cgray3 mr5'} size={'sm'}/>
            {Number(row.grossWeight).toFixed(2)} kg
            <div className={'flx1'}/>
            <div className={'pr10'}>
                <FontAwesomeIcon icon={faBoxOpen} className={'cgray3 mr5'} size={'sm'}/>
                {row.cartons}
            </div>
        </div>
    </Card>;

    private renderDispatch = (row : IDispatchInstruction) => <ListItem className={'PaperBorder mt5 mb5 bcp cw'}>
        <div className={'fdr wfill jcc'}>
            <div className={'fw500'}>{row.dispatchCode}</div>
            <div className={'flx1'}/>
            <FontAwesomeIcon icon={faPallet} className={'pt2 mr5'} size={'sm'}/>
            {row.dispatchLines.filter(x => x.isActive).length}
        </div>
    </ListItem>;

    public getSites = (props : IOverviewDashboardProps) => props.sites;
    public getSelectedOrganizationIds = (props : IOverviewDashboardProps) => props.selectedOrganizationIds;
    public getSelectedSitesData = (props : IOverviewDashboardProps) => props.selectedSiteIds;
    public getIntakeData = (props : IOverviewDashboardProps, state : IOverviewDashboardState) => state.intakes;
    public getAvailableLotData = (props : IOverviewDashboardProps, state : IOverviewDashboardState) => state.availableLots;
    public getBatchData = (props : IOverviewDashboardProps, state : IOverviewDashboardState) => state.batches;
    public getStockData = (props : IOverviewDashboardProps, state : IOverviewDashboardState) => state.stocks;
    public getDispatchData = (props : IOverviewDashboardProps, state : IOverviewDashboardState) => state.dispatches;
    public getSelectedCommodityOption = (props : IOverviewDashboardProps, state : IOverviewDashboardState) => state.selectedCommodityOption;
    public getCommodityData = (props : IOverviewDashboardProps) => props.commodityData;
    public getSelectedFromDate = (props : IOverviewDashboardProps, state : IOverviewDashboardState) => state.selectedFromDate;
    public getSelectedToDate = (props : IOverviewDashboardProps, state : IOverviewDashboardState) => state.selectedToDate;

    public getCommodityOptions = createSelector(
        [this.getCommodityData],
        (commodities : Array<ICommodity>) => {
            if (!commodities) return [];
            return lodash.filter(commodities, x => x.isActive).map((x) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            });
        },
    );

    public getIntakes = createSelector(
        [this.getIntakeData, this.getSelectedCommodityOption, this.getSelectedSitesData],
        (intakes, selectedCommodityOption, selectedSiteIds) => intakes?.filter(x => selectedSiteIds?.some(y => y === x.siteId)
                && (selectedCommodityOption ? x.commodityId === selectedCommodityOption.value : x.commodityId))) ?? [];

    public getLots = createSelector(
        [this.getAvailableLotData, this.getSelectedCommodityOption, this.getSelectedSitesData],
        (lots, selectedCommodityOption, selectedSiteIds) => lots?.filter(x => this.state.showFinalized ? true : !x.isFinalized
                && selectedSiteIds?.some(y => y === x.siteId)
                && (selectedCommodityOption ? x.commodityId === selectedCommodityOption.value : x.commodityId))) ?? [];

    public getBatches = createSelector(
        [this.getBatchData, this.getSelectedCommodityOption, this.getSelectedSitesData],
        (batches, selectedCommodityOption, selectedSiteIds) => batches?.filter(x => this.state.showEnded ? true : x.endedOn === null
                && selectedSiteIds?.some(y => y === x.siteId)
                && (!selectedCommodityOption || x.commodityIds.some(a => a === selectedCommodityOption.value)))) ?? [];

    public getStocks = createSelector(
        [this.getStockData, this.getSelectedCommodityOption, this.getSelectedSitesData],
        (stocks, selectedCommodityOption, selectedSiteIds) => stocks?.filter(x => this.state.showTemporary ? true : !x.isTemporary
                && selectedSiteIds?.some(y => y === x.currentSiteId)
                && (selectedCommodityOption ? x.stockLines.some(a => a.commodityId === selectedCommodityOption.value) : x.stockLines))) ?? [];

    public getDispatchesInBound = createSelector(
        [this.getDispatchData, this.getSelectedCommodityOption, this.getSelectedSitesData],
        (dispatches, selectedCommodityOption, selectedSiteIds) => dispatches?.filter(x => selectedSiteIds?.some(y => y === x.destinationSiteId)
                && (selectedCommodityOption ? x.dispatchLines.some(a => a.commodityId === selectedCommodityOption.value) : x.dispatchLines))) ?? [];

    public getDispatches = createSelector(
        [this.getDispatchData, this.getSelectedCommodityOption, this.getSelectedSitesData],
        (dispatches, selectedCommodityOption, selectedSiteIds) => dispatches?.filter(x => selectedSiteIds?.some(y => (y === x.destinationSiteId) || (y === x.sourceSiteId))
                && (selectedCommodityOption ? x.dispatchLines.some(a => a.commodityId === selectedCommodityOption.value) : x.dispatchLines))) ?? [];

    private columns = () => {
        return {
            dispatchInbound: {
                title: 'Dispatches Inbound',
                data: this.getDispatchesInBound(this.props, this.state),
                renderElement: this.renderDispatch,
            },
            intake: {
                title: 'Intakes',
                data: this.getIntakes(this.props, this.state),
                renderElement: this.renderIntake,
            },
            lot: {
                title: 'Lots',
                data: this.getLots(this.props, this.state),
                renderElement: this.renderLot,
            },
            batch: {
                title: 'Batches',
                data: this.getBatches(this.props, this.state),
                renderElement: this.renderBatch,
            },
            stock: {
                title: 'Stock',
                data: this.getStocks(this.props, this.state),
                renderElement: this.renderStock,
            },
            dispatchOutbound: {
                title: 'Dispatches',
                data: this.getDispatches(this.props, this.state),
                renderElement: this.renderDispatch,
            },
        };
    };

    private renderColumn = (
        column :
        {
            title : string;
            data : Array<IIntake> | Array<ILot> | Array<IBatch> | Array<IStock> | Array<IDispatchInstruction>;
            renderElement : (row : IIntake | ILot | IBatch | IStock | IDispatchInstruction | string) => HTMLElement;
        }) => {
        return (
            <Card id={'overviewTab'} elevation={3} className={'flx1 m10 fdc'} style={ { height: 'calc(80vh)' }}>
                <div className={'wfill aic jcc fs18 pt10 pb5 bcp cw'}>{column.title}</div>
                <List className={'fdc hfill pb10 pl10 pr10 pt5 oa'}>
                    {lodash.map(column.data, column.renderElement)}
                </List>
            </Card>
        );
    };

    private setData = (data : IOverviewRelatedData, isIndexedDBAvailable : boolean) => {

        this.setState({
            intakes: data.intakes,
            batches: data.batches,
            availableLots: data.availableLots,
            batchLots: data.batchLots,
            stocks: data.stocks,
            dispatches: data.dispatches,
            commodities: isIndexedDBAvailable ? this.props.commodityData : data.commodities,
            varieties: isIndexedDBAvailable ? this.props.varieties : data.varieties,
            packs: isIndexedDBAvailable ? this.props.packs : data.packs,
            orchards: isIndexedDBAvailable ? this.props.orchards : data.orchards,
            farms: isIndexedDBAvailable ? this.props.farms : data.farms,
        });
    };

    private refreshDataClick = async (event ?: React.MouseEvent<HTMLButtonElement>, noAnnounce : boolean = true) => {
        event?.stopPropagation();
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        try {
            const res = await OverviewRelatedDataHttpService.getAllOverviewRelatedData(this.getDate('from'), this.getDate('to'), this.props.selectedSiteIds, !isIndexedDBAvailable);

            this.setData(res.data, isIndexedDBAvailable);
            this.setLoading(false);
            if (!noAnnounce) {
                generalShowSuccessSnackbar('Data Refreshed');
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving dashboard data.');
            this.setLoading(false);
        }
    };

    private toggleShowEnded = () => this.setState(prevState => ({ showEnded: !prevState.showEnded }));
    private toggleShowFinalized = () => this.setState(prevState => ({ showFinalized: !prevState.showFinalized }));
    private toggleShowTemporary = () => this.setState(prevState => ({ showTemporary: !prevState.showTemporary }));

    private handleClick = () => {
        this.setState({ open: !this.state.open });
    };

    private onCancelClick = (event : React.MouseEvent<HTMLElement>) => {
        if (this.anchorEl && this.anchorEl.contains(event.target)) {
            return;
        }
        this.setState({ open: false });
    };

    private handleClose = (event : MouseEvent | TouchEvent) => {
        if (this.anchorEl && this.anchorEl.contains(event.target)) {
            return;
        }

        event.preventDefault();
        this.setState({ open: false });
    };

    private onApplyClick = () => {
        this.refreshDataClick();

        this.setState({ open: false });
    };

    public closeFinalizeNormalLotDialog = () => {
        this.setState({
            isFinalizeNormalLotDialogOpen: false,
        });
    };

    public closeFinalizeBatchLotDialog = () => {
        this.setState({
            isFinalizeBatchLotDialogOpen: false,
        });
    };

    public onCommodityChange = (e : CustomChangeEvent, selectedCommodityOption : IOptionType) => {
        this.setState({
            selectedCommodityOption,
        });
    };

    private showConfirmationPrompt = (onYesClick : () => void, onNoClick : () => void, message : string) => {
        this.setState({ onYesClick, onNoClick, message }, () => {
            this.setState({ showPrompt: true });
        });
    };

    private closeConfirmationPrompt = () => {
        this.setState({ onYesClick: () => null, onNoClick: () => null, message: '', showPrompt: false });
    };

    private isSelectedDateRangeValid = createSelector(
        [this.getSelectedFromDate, this.getSelectedToDate],
        (selectedFromDate : moment.Moment, selectedToDate : moment.Moment) => {
            let days = 0;

            const fromDate = selectedFromDate.startOf('day');
            const toDate = selectedToDate.startOf('day');

            days = Math.round(moment.duration(toDate.diff(fromDate)).asDays());

            return (days > 365) ? true : false;
        },
    );

    public render() {
        const selectionRange = {
            startDate: this.state.selectedFromDate.toDate(),
            endDate: this.state.selectedToDate.toDate(),
            key: 'selection',
        };

        return (
            <Screen isLoading={this.state.isLoading} isPadded={false} isScrollable={false}>
                <div className={'fdc flx1 hfill disableSelect'}>
                    <div className={'fdr jcfe pt10'}>
                        <TextField
                            ref={(node) => { this.anchorEl = node; }}
                            className={'pr10 cblack w250'}
                            label={'Filter By Date'}
                            aria-haspopup='true'
                            name={'filter'}
                            value={this.state.selectedFromDate.local().format(DATE_FORMAT_DEFAULT_NO_TIME) + ' - ' + this.state.selectedToDate.local().format(DATE_FORMAT_DEFAULT_NO_TIME)}
                            variant={'standard'}
                            onClick={this.handleClick}
                            InputProps={{
                                endAdornment: (
                                    <Icon fontSize='small'>
                                        filter_list
                                    </Icon>
                                ),
                            }}
                        />
                        <Popper className={'zi999 pr10'} anchorEl={this.anchorEl} open={this.state.open} transition disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', marginTop: 8, backgroundColor: materialTheme.custom.basicTransactionDateFilter.background }}
                                >
                                    <div className={'zi1000 p10 filterShadow'}>
                                        <ClickAwayListener onClickAway={this.handleClose}>
                                            <div className={'fdc'}>
                                                <div className={'fdc aifs'}>
                                                    <div className={'pl10'}>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DateRange parentPickerFunction={this.toggleShowPicker} onChange={this.handleDateRangeChange} start={this.state.selectedFromDate} end={this.state.selectedToDate} positionLeft={true}/>
                                                        </LocalizationProvider>
                                                        {this.state.showRangePicker && <dateRangePicker.DateRangePicker
                                                            ranges={[selectionRange]}
                                                            onChange={this.handleRangeChange}
                                                            className={'bw1'}
                                                        />}
                                                    </div>

                                                    <AutocompleteSelect
                                                        className={'w200'}
                                                        name={'Commodity Options'}
                                                        label={'Commodity'}
                                                        options={this.getCommodityOptions(this.props)}
                                                        value={this.state.selectedCommodityOption}
                                                        onChange={this.onCommodityChange}
                                                    />

                                                    <div className={'fdr aic jcc m10'}>
                                                        Show Finalized
                                                        <Switch
                                                            color={'primary'}
                                                            checked={this.state.showFinalized}
                                                            onChange={this.toggleShowFinalized} />
                                                    </div>

                                                    <div className={'w30'}/>

                                                    <div className={'fdr aic jcc m10'}>
                                                        Show Ended
                                                        <Switch
                                                            color={'primary'}
                                                            checked={this.state.showEnded}
                                                            onChange={this.toggleShowEnded} />
                                                    </div>

                                                    <div className={'w30'}/>

                                                    <div className={'fdr aic jcc m10'}>
                                                        Show Temporary
                                                        <Switch
                                                            color={'primary'}
                                                            checked={this.state.showTemporary}
                                                            onChange={this.toggleShowTemporary} />
                                                    </div>
                                                </div>
                                                <div className={'fdr flx1 pt10'}>
                                                    <div className={'flx1'}/>
                                                    <Button
                                                        className={'fwb h35'}
                                                        variant='text'
                                                        onClick={this.onCancelClick}>
                                                        Cancel
                                                    </Button>
                                                    <CustomTooltip title={`${this.isSelectedDateRangeValid(this.props, this.state) ? 'Date range cannot be more then 365 days' : ''}`}>
                                                        <PillButton
                                                            text={'Apply'}
                                                            className={'ml15 pl20 pr20 h35 cpd'}
                                                            onClick={this.onApplyClick}
                                                            color={'secondary'}
                                                            size={'small'}
                                                            disabled={this.isSelectedDateRangeValid(this.props, this.state)}
                                                        >
                                                        </PillButton>
                                                    </CustomTooltip>
                                                </div>
                                            </div>
                                        </ClickAwayListener>
                                    </div>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                    <div className={'flx1 hfill pt5 pb5 fdr'}>
                        { lodash.map(this.columns(), this.renderColumn) }
                    </div>
                    <ConfirmationPrompt open={this.state.showPrompt} message={this.state.message}
                        onOkClicked={this.state.onYesClick} onCancelClicked={this.state.onNoClick}/>
                    <PackmanDialog
                        title='Confirmation'
                        isInfo={true}
                        isLoading={this.state.isLoading}
                        isOpen={this.state.isFinalizeNormalLotDialogOpen}
                        onClose={this.closeFinalizeNormalLotDialog}
                    >
                        {
                            (this.state.lotToFinalize?.nettWeight === 0 && this.state.lotToFinalize?.grossWeight === 0) ?
                                <div className={'fdc h90 jcsb'}>
                                    <Typography className={'pl25 pt5'}>Are you sure you want to finalize this lot?</Typography>
                                    <div className={'fdr jcfe pr30 pb10'}>
                                        <Button
                                            className={'fwb mr5 h35'}
                                            variant='text'
                                            onClick={this.finalizeNormalLotNoClicked}>
                                        Cancel
                                        </Button>
                                        <PillButton
                                            text={'Ok'}
                                            className={'pl30 pr30 h35 cpd'}
                                            onClick={this.submitFinalizeNormalLot}
                                            color={'secondary'}
                                            size={'small'}
                                        ></PillButton>
                                    </div>
                                </div>
                                :
                                <div>
                                    <Typography className={'pl25 pt5 pr15 pb10'}>Are you sure you want to make the following adjustments and finalize this lot?</Typography>
                                    {this.state.lotToFinalize && this.state.lotToFinalize?.grossWeight !== 0 &&
                                    <div className={'fdr pl25 pt5'}>
                                        Initial Gross Weight :
                                        {
                                            <div className={'fdr'}>
                                                <div className={'w20'}/>
                                                {`  + ${Math.abs(Number(this.state.lotToFinalize.lotLines.map(x => x.grossWeight).reverse().pop()))} kg`}
                                            </div>
                                        }
                                    </div>
                                    }
                                    {this.state.lotToFinalize && this.state.lotToFinalize?.nettWeight !== 0 &&
                                    <div className={'fdr pl25'}>
                                        Initial Nett Weight :
                                        {
                                            <div className={'fdr'}>
                                                <div className={'w20'}/>
                                                {`  + ${Math.abs(Number(this.state.lotToFinalize.lotLines.map(x => x.nettWeight).reverse().pop()))} kg`}
                                            </div>
                                        }
                                    </div>
                                    }
                                    {this.state.lotToFinalize && this.state.lotToFinalize?.grossWeight !== 0 &&
                                    <div className={'fdr pl25 pt5'}>
                                        Current Gross Weight :
                                        {
                                            this.state.lotToFinalize?.grossWeight > 0
                                                ?   <div className={'fdr'}>
                                                    <div className={'w20'}/>
                                                    {`  + ${Math.abs(this.state.lotToFinalize?.grossWeight)} kg`}
                                                </div>
                                                :   <div className={'fdr'}>
                                                    <div className={'w20'}/>
                                                    {`  - ${Math.abs(this.state.lotToFinalize?.grossWeight)} kg`}
                                                </div>
                                        }
                                    </div>
                                    }
                                    {this.state.lotToFinalize && this.state.lotToFinalize?.nettWeight !== 0 &&
                                    <div className={'fdr pl25'}>
                                        Current Nett Weight :
                                        {
                                            this.state.lotToFinalize?.grossWeight > 0
                                                ?   <div className={'fdr'}>
                                                    <div className={'w20'}/>
                                                    {`  + ${Math.abs(this.state.lotToFinalize?.nettWeight)} kg`}
                                                </div>
                                                :   <div className={'fdr'}>
                                                    <div className={'w20'}/>
                                                    {`  - ${Math.abs(this.state.lotToFinalize?.nettWeight)} kg`}
                                                </div>
                                        }
                                    </div>
                                    }
                                    { this.state.lotToFinalize && this.state.lotToFinalize?.grossWeight !== 0 &&
                                    <div className={'fdr pl25 pt5'}>
                                        Adjustment Gross Weight :
                                        {this.state.lotToFinalize?.grossWeight > 0 ?
                                            <div className={'fdr cred'}>
                                                <div className={'w20'}/>
                                                {`  - ${this.state.lotToFinalize?.grossWeight} kg`}
                                            </div>
                                            :
                                            <div className={'fdr cpl'}>
                                                <div className={'w20'}/>
                                                {`  + ${Math.abs(this.state.lotToFinalize?.grossWeight)} kg`}
                                            </div>
                                        }
                                    </div>
                                    }
                                    {this.state.lotToFinalize && this.state.lotToFinalize?.nettWeight !== 0 &&
                                    <div className={'fdr pl25'}>
                                        Adjustment Nett Weight :
                                        {this.state.lotToFinalize?.nettWeight > 0 ?
                                            <div className={'fdr cred'}>
                                                <div className={'w20'}/>
                                                {`- ${this.state.lotToFinalize?.nettWeight} kg`}
                                            </div>
                                            :
                                            <div className={'fdr cpl'}>
                                                <div className={'w20'}/>
                                                {`  + ${Math.abs(this.state.lotToFinalize?.nettWeight)} kg`}
                                            </div>
                                        }
                                    </div>
                                    }
                                    <div className={'fdr jcfe pr30 pb10'}>
                                        <Button
                                            className={'fwb mr5 h35'}
                                            variant='text'
                                            onClick={this.finalizeNormalLotNoClicked}>
                                        Cancel
                                        </Button>
                                        <PillButton
                                            text={'Ok'}
                                            className={'pl30 pr30 h35 cpd'}
                                            onClick={this.submitFinalizeNormalLot}
                                            color={'secondary'}
                                            size={'small'}
                                        ></PillButton>
                                    </div>
                                </div>
                        }
                    </PackmanDialog >
                    <PackmanDialog
                        title='Confirmation'
                        isInfo={true}
                        isLoading={this.state.isLoading}
                        isOpen={this.state.isFinalizeBatchLotDialogOpen}
                        onClose={this.closeFinalizeBatchLotDialog}
                    >
                        {
                            (this.state.lotToFinalize?.nettWeight === 0 && this.state.lotToFinalize?.grossWeight === 0) ?
                                <div className={'fdc h90 jcsb'}>
                                    <Typography className={'pl25 pt5'}>Are you sure you want to finalize this lot?</Typography>
                                    <div className={'fdr jcfe pr30 pb10'}>
                                        <Button
                                            className={'fwb mr5 h35'}
                                            variant='text'
                                            onClick={this.finalizeBatchLotNoClicked}>
                                        Cancel
                                        </Button>
                                        <PillButton
                                            text={'Ok'}
                                            className={'pl30 pr30 h35'}
                                            onClick={this.submitFinalizeBatchLot}
                                            color={'secondary'}
                                            size={'small'}
                                        ></PillButton>
                                    </div>
                                </div>
                                :
                                <div>
                                    <Typography className={'pl25 pt5 pr15 pb10'}>Are you sure you want to make the following adjustments and finalize this lot?</Typography>
                                    {this.state.lotToFinalize && this.state.lotToFinalize?.grossWeight !== 0 &&
                                    <div className={'fdr pl25 pt5'}>
                                        Current Gross Weight :
                                        {
                                            this.state.lotToFinalize?.grossWeight > 0
                                                ?   <div className={'fdr'}>
                                                    <div className={'w20'}/>
                                                    {`  + ${Math.abs(this.state.lotToFinalize?.grossWeight)} kg`}
                                                </div>
                                                :   <div className={'fdr'}>
                                                    <div className={'w20'}/>
                                                    {`  - ${Math.abs(this.state.lotToFinalize?.grossWeight)} kg`}
                                                </div>
                                        }
                                    </div>
                                    }
                                    {this.state.lotToFinalize && this.state.lotToFinalize?.nettWeight !== 0 &&
                                    <div className={'fdr pl25'}>
                                        Current Nett Weight :
                                        {
                                            this.state.lotToFinalize?.grossWeight > 0
                                                ?   <div className={'fdr'}>
                                                    <div className={'w20'}/>
                                                    {`  + ${Math.abs(this.state.lotToFinalize?.nettWeight)} kg`}
                                                </div>
                                                :   <div className={'fdr'}>
                                                    <div className={'w20'}/>
                                                    {`  - ${Math.abs(this.state.lotToFinalize?.nettWeight)} kg`}
                                                </div>
                                        }
                                    </div>
                                    }
                                    { this.state.lotToFinalize && this.state.lotToFinalize?.grossWeight !== 0 &&
                                    <div className={'fdr pl25 pt5'}>
                                        Adjustment Gross Weight :
                                        {this.state.lotToFinalize?.grossWeight > 0 ?
                                            <div className={'fdr cred'}>
                                                <div className={'w20'}/>
                                                {`  - ${this.state.lotToFinalize?.grossWeight} kg`}
                                            </div>
                                            :
                                            <div className={'fdr cpl'}>
                                                <div className={'w20'}/>
                                                {`  + ${Math.abs(this.state.lotToFinalize?.grossWeight)} kg`}
                                            </div>
                                        }
                                    </div>
                                    }
                                    {this.state.lotToFinalize && this.state.lotToFinalize?.nettWeight !== 0 &&
                                    <div className={'fdr pl25'}>
                                        Adjustment Nett Weight :
                                        {this.state.lotToFinalize?.nettWeight > 0 ?
                                            <div className={'fdr cred'}>
                                                <div className={'w20'}/>
                                                {`- ${this.state.lotToFinalize?.nettWeight} kg`}
                                            </div>
                                            :
                                            <div className={'fdr cpl'}>
                                                <div className={'w20'}/>
                                                {`  + ${Math.abs(this.state.lotToFinalize?.nettWeight)} kg`}
                                            </div>
                                        }
                                    </div>
                                    }
                                    <div className={'fdr jcfe pr30 pb10'}>
                                        <Button
                                            className={'fwb mr5 h35'}
                                            variant='text'
                                            onClick={this.finalizeBatchLotNoClicked}>
                                        Cancel
                                        </Button>
                                        <PillButton
                                            text={'Ok'}
                                            className={'pl30 pr30 h35'}
                                            onClick={this.submitFinalizeBatchLot}
                                            color={'secondary'}
                                            size={'small'}
                                        ></PillButton>
                                    </div>
                                </div>
                        }
                    </PackmanDialog >
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
        commodityData: state.masterData.commodities,
        sites: state.masterData.sites,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        orchards: state.masterData.orchards,
        farms: state.masterData.farms,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetCommodities, dataSetSites }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OverviewDashboard);

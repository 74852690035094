import { MasterDataAction, IMasterDataState } from '../../@types/redux';
import * as dataActions from './Actions';
import { getType } from 'typesafe-actions';

const initialState = {
    /* Master Data */
    masterDataSyncIsLoading: false,
    masterDataSyncData: undefined,
    selectedCommodity: undefined,

    organizations: [],
    commodities: [],
    commodityConditions: [],
    varieties: [],
    packs: [],
    packCategories: [],
    sizes: [],
    accreditations: [],
    colours: [],
    commodityStates: [],
    grades: [],
    orchards: [],
    farms: [],
    barcodeRanges: [],
    contactInfos: [],
    countries: [],
    markets: [],
    marks: [],
    lotTypes: [],
    regions: [],
    sites: [],
    storageUnits: [],
    ftpDetails: [],
    inspectionPoints: [],
    palletBaseTypes: [],
    carriers: [],
    seasons: [],
    inventories: [],
    departments: [],
    domains: [],
    agreementCodes: [],
    materials: [],
    materialTypes: [],
    unitOfMeasures: [],
    unitOfMeasureTypes: [],
    truckTypes: [],
    devices: [],
    projects: [],
    packLines: [],
    outlets: [],
    printers: [],
    printServers: [],
    reports: [],
    siteSettings: [],
    salesPoints: [],
};

const masterDataReducer = (state : IMasterDataState = initialState, action : MasterDataAction) : IMasterDataState => {
    switch (action.type) {
    /* Master Data */
        case getType(dataActions.dataSetOrganizations):
            return { ...state, organizations: action.payload };
        case getType(dataActions.dataSetCommodities):
            return { ...state, commodities: action.payload };
        case getType(dataActions.dataSetCommodityConditions):
            return { ...state, commodityConditions: action.payload };
        case getType(dataActions.dataSetVarieties):
            return { ...state, varieties: action.payload };
        case getType(dataActions.dataSetPacks):
            return { ...state, packs: action.payload };
        case getType(dataActions.dataSetPackCategories):
            return { ...state, packCategories: action.payload };
        case getType(dataActions.dataSetSizes):
            return { ...state, sizes: action.payload };
        case getType(dataActions.dataSetAccreditations):
            return { ...state, accreditations: action.payload };
        case getType(dataActions.dataSetColours):
            return { ...state, colours: action.payload };
        case getType(dataActions.dataSetGrades):
            return { ...state, grades: action.payload };
        case getType(dataActions.dataSetFtpDetails):
            return { ...state, ftpDetails: action.payload };
        case getType(dataActions.dataSetCommodityStates):
            return { ...state, commodityStates: action.payload };
        case getType(dataActions.dataSetOrchards):
            return { ...state, orchards: action.payload };
        case getType(dataActions.dataSetFarms):
            return { ...state, farms: action.payload };
        case getType(dataActions.dataSetBarcodeRanges):
            return { ...state, barcodeRanges: action.payload };
        case getType(dataActions.dataSetContactInfos):
            return { ...state, contactInfos: action.payload };
        case getType(dataActions.dataSetCountries):
            return { ...state, countries: action.payload };
        case getType(dataActions.dataSetMarkets):
            return { ...state, markets: action.payload };
        case getType(dataActions.dataSetMarks):
            return { ...state, marks: action.payload };
        case getType(dataActions.dataSetLotTypes):
            return { ...state, lotTypes: action.payload };
        case getType(dataActions.dataSetRegions):
            return { ...state, regions: action.payload };
        case getType(dataActions.dataSetSites):
            return { ...state, sites: action.payload };
        case getType(dataActions.dataSetStorageUnits):
            return { ...state, storageUnits: action.payload };
        case getType(dataActions.dataSetInspectionPoints):
            return { ...state, inspectionPoints: action.payload };
        case getType(dataActions.dataSetPalletBaseTypes):
            return { ...state, palletBaseTypes: action.payload };
        case getType(dataActions.dataSetCarriers):
            return { ...state, carriers: action.payload };
        case getType(dataActions.dataSetSeasons):
            return { ...state, seasons: action.payload };
        case getType(dataActions.dataSetInventories):
            return { ...state, inventories: action.payload };
        case getType(dataActions.dataSetDepartments):
            return { ...state, departments: action.payload };
        case getType(dataActions.dataSetDomains):
            return { ...state, domains: action.payload };
        case getType(dataActions.dataSetMaterials):
            return { ...state, materials: action.payload };
        case getType(dataActions.dataSetMaterialTypes):
            return { ...state, materialTypes: action.payload };
        case getType(dataActions.dataSetUnitOfMeasures):
            return { ...state, unitOfMeasures: action.payload };
        case getType(dataActions.dataSetUnitOfMeasureTypes):
            return { ...state, unitOfMeasureTypes: action.payload };
        case getType(dataActions.dataSetTruckTypes):
            return { ...state, truckTypes: action.payload };
        case getType(dataActions.dataSetDevices):
            return { ...state, devices: action.payload };
        case getType(dataActions.dataSetProjects):
            return { ...state, projects: action.payload };
        case getType(dataActions.dataSetPackLines):
            return { ...state, packLines: action.payload };
        case getType(dataActions.dataSetOutlets):
            return { ...state, outlets: action.payload };
        case getType(dataActions.dataSetPrinters):
            return { ...state, printers: action.payload };
        case getType(dataActions.dataSetPrintServers):
            return { ...state, printServers: action.payload };
        case getType(dataActions.dataSetReports):
            return { ...state, reports: action.payload };
        case getType(dataActions.dataSetSiteSettings):
            return { ...state, siteSettings: action.payload };
        case getType(dataActions.dataSetAgreementCodes):
            return { ...state, agreementCodes: action.payload };
        case getType(dataActions.dataSetSalesPoints):
            return { ...state, salesPoints: action.payload };

        case getType(dataActions.dataSetSelectedCommodity):
            return { ...state, selectedCommodity: action.payload };
        case getType(dataActions.dataSetMasterDataSyncIsLoading):
            return { ...state, masterDataSyncIsLoading: action.payload };
        case getType(dataActions.dataSetMasterDataSyncData):
            return { ...state,
                organizations: action.payload.organizations,
                sites: action.payload.sites,
                accreditations: action.payload.accreditations,
                agreementCodes: action.payload.agreementCodes,
                barcodeRanges: action.payload.barcodeRanges,
                carriers: action.payload.carriers,
                colours: action.payload.colours,
                commodities: action.payload.commodities,
                commodityConditions: action.payload.commodityConditions,
                commodityStates: action.payload.commodityStates,
                grades: action.payload.grades,
                orchards: action.payload.orchards,
                packs: action.payload.packs,
                seasons: action.payload.seasons,
                sizes: action.payload.sizes,
                varieties: action.payload.varieties,
                contactInfos: action.payload.contactInfos,
                countries: action.payload.countries,
                departments: action.payload.departments,
                devices: action.payload.devices,
                domains: action.payload.domains,
                farms: action.payload.farms,
                ftpDetails: action.payload.ftpDetails,
                inspectionPoints: action.payload.inspectionPoints,
                inventories: action.payload.inventories,
                lotTypes: action.payload.lotTypes,
                marks: action.payload.marks,
                markets: action.payload.markets,
                materials: action.payload.materials,
                materialTypes: action.payload.materialTypes,
                outlets: action.payload.outlets,
                packCategories: action.payload.packCategories,
                packLines: action.payload.packLines,
                palletBaseTypes: action.payload.palletBaseTypes,
                printers: action.payload.printers,
                projects: action.payload.projects,
                printServers: action.payload.printServers,
                salesPoints: action.payload.salesPoints,
                regions: action.payload.regions,
                reports: action.payload.reports,
                siteSettings: action.payload.siteSettings,
                storageUnits: action.payload.storageUnits,
                truckTypes: action.payload.truckTypes,
                unitOfMeasures: action.payload.unitOfMeasures,
                unitOfMeasureTypes: action.payload.unitOfMeasureTypes,
            };

        default:
            return state;
    }
};

export default masterDataReducer;

import { Typography } from '@mui/material';
import { floor } from 'lodash';
import * as React from 'react';
import { IPack } from '../../../../@types/model/masterData/pack/pack';

interface IPackSummaryProps {
    selectedPack : IPack;
}

interface IPackSummaryState {
}

export default class PackSummary extends React.Component<IPackSummaryProps, IPackSummaryState> {
    constructor(props : IPackSummaryProps) {
        super(props);
    }

    private getNumberOfObjects = (isBottom : boolean) => {
        let count = 0;
        const children = [];

        if (!this.props.selectedPack.noUnitsPerCarton) {
            return;
        }
        if (isBottom) {
            count = this.props.selectedPack.noUnitsPerCarton % 2 === 0 ? this.props.selectedPack.noUnitsPerCarton / 2 : this.props.selectedPack.noUnitsPerCarton / 2;
        } else {
            count = floor(this.props.selectedPack.noUnitsPerCarton / 2);
        }
        for (let i = 0; i < count; i++) {
            let value = this.props.selectedPack.unitGrossWeight ? this.props.selectedPack.unitGrossWeight * 100 : 0;
            if (value % 1) {
                value = parseFloat(value.toFixed(2));
            }

            children.push(
                <div className={'flx1 p5 ml5 mr5 jcc aic bcs br5 h30'}>
                </div>,
            );
        }
        return children;
    };

    public render() {
        return (
            <div className={'flx1 fdc ml10 mr10'}>
                <div className={'fdr hfill wfill jcc aic'}>
                    <div className={'fdr wfill aife jcfe pr10 posr'} >
                        <img src={`${ASSET_BASE}/assets/images/pallet_image.svg`} />
                        <div className={'posa post50 w427'}>
                            <div className={'fdr pl20 pr20'}>
                                {this.getNumberOfObjects(false)}
                            </div>
                        </div>
                        <div className={'posa post95 w427'}>
                            <div className={'fdr pl20 pr20'}>
                                {this.getNumberOfObjects(true)}
                            </div>
                        </div>
                    </div>
                    <div className={'w10'}/>
                    <div className={'fdr wfill pl10'}>
                        <div className={'fdc jcc aic'}>
                            <Typography>Total Cartons on pallet:</Typography>
                            <div className='h50 w50 brh jcc aic mt10 mb20 fw500 cw bcp'>{this.props.selectedPack.noCartons ? this.props.selectedPack.noCartons : 0}</div>
                            <Typography>Weight per inner (g):</Typography>
                            <div className='h50 w50 brh jcc aic mt10 mb20 fw500 bcs'>{this.props.selectedPack.unitGrossWeight ? (this.props.selectedPack.unitGrossWeight * 100).toFixed(2)  :  0}</div>
                        </div>
                        <div className={'fdc jcc aic'}>
                            <Typography>Total inners per carton:</Typography>
                            <div className='h50 w50 brh jcc aic mt10 mb20 fw500 bcs'>{this.props.selectedPack.noUnitsPerCarton ? this.props.selectedPack.noUnitsPerCarton : 0}</div>
                            <Typography>Total inners weight per carton (kg):</Typography>
                            <div className='h50 w50 brh jcc aic mt10 mb20 fw500 cw bcp'>{(this.props.selectedPack.unitGrossWeight && this.props.selectedPack.noUnitsPerCarton) ? (this.props.selectedPack.unitGrossWeight * this.props.selectedPack.noUnitsPerCarton).toFixed(2) : 0}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

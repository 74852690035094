import { IBaseModel } from '../../model';

export interface IContactUs extends IBaseModel {
    name : string;
    email : string;
    moreInfo : string;
}

export class ContactUs implements IContactUs {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public name : string;
    public email : string;
    public moreInfo : string;

    public constructor(formValues : IContactUs) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.name = formValues.name;
        this.email = formValues.email;
        this.moreInfo = formValues.moreInfo;
    }
}

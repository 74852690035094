import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { ReportFileFormValues } from '../../@types/model/reportFile/reportFileFormValues';
import PillButton from '../input/PillButton';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import lodash from 'lodash';
import FormAutocompleteSelect from '../input/form/FormAutoCompleteSelect';
import { IRootState } from '../../@types/redux';
import { IPrintServer } from '../../@types/model/masterData/printServer/printServer';
import { ISite } from '../../@types/model/masterData/site/site';
import { IReport } from '../../@types/model/masterData/report/report';
import { createSelector } from 'reselect';
import { ISiteSetting } from '../../@types/model/masterData/siteSetting/siteSetting';

interface IReportFileFormProps {
    isLoading : boolean;
    printServers : Array<IPrintServer>;
    sites : Array<ISite>;
    siteSettings : Array<ISiteSetting>;
    reports : Array<IReport>;
    selectedSiteIds : Array<number>;
    page : 'Dispatch' | 'Stock' | 'MaterialDispatch' | 'Compliance';
}

interface IReportFileFormState {
}

type ReportFileFormPropsType = IReportFileFormProps & FormikProps<ReportFileFormValues>;

class ReportFileForm extends React.Component<ReportFileFormPropsType, IReportFileFormState> {

    constructor(props : ReportFileFormPropsType) {
        super(props);
    }

    private getInitialSites = (props : ReportFileFormPropsType) => props.initialValues.site;
    private getInitialPrintServers = (props : ReportFileFormPropsType) => props.initialValues.printServer;
    private getInitialReports = (props : ReportFileFormPropsType) => props.initialValues.report;

    private getSelectedSite = (props : ReportFileFormPropsType) => props.values.site;
    private getSelectedPrintServer = (props : ReportFileFormPropsType) => props.values.printServer;
    private getSelectedReport = (props : ReportFileFormPropsType) => props.values.report;

    private getSelectedSiteIds = (props : ReportFileFormPropsType) => props.selectedSiteIds;
    private getSites = (props : ReportFileFormPropsType) => props.sites;
    private getSiteSettings = (props : ReportFileFormPropsType) => props.siteSettings;
    private getPrintServers = (props : ReportFileFormPropsType) => props.printServers;
    private getReports = (props : ReportFileFormPropsType) => props.reports;
    private getPage = (props : ReportFileFormPropsType) => props.page;

    private getSiteOptions = createSelector([this.getSites, this.getSelectedSiteIds], (sites, selectedSiteIds) => {
        return sites?.filter(x => selectedSiteIds.some(y => y === x.id)).map(x => ({
            label: `${x.code} - ${x.description}`,
            value: x.id,
        })) ?? [];
    });

    private getPrintServerOptions = createSelector([this.getPrintServers, this.getSelectedSite, this.getInitialPrintServers], (printServers, selectedSite) => {
        if (selectedSite) {
            const printServerIds = printServers.filter(x => x.siteId === selectedSite?.value).map(x => x.id);
            return printServers?.filter(x => x.defaultScheme === 'https' && printServerIds.some(y => y === x.id)).map(x => ({
                label: x.name,
                value: x.id,
            })) ?? [];
        }
        return printServers.map(x => ({
            label: x.name,
            value: x.id,
        })) ?? [];
    });

    private getReportOptions = createSelector([this.getReports, this.getSelectedPrintServer, this.getPage, this.getSelectedSite, this.getSiteSettings], (reports, selectedPrintServer, page, selectedSite, siteSettings) => {
        if (selectedPrintServer) {
            if (page === 'Dispatch' && selectedSite) {
                console.log(siteSettings.find(x => x.siteId === selectedSite.value));
            }
            const printServerIds = reports.filter(x => x.printServerId === selectedPrintServer?.value).map(x => x.id);
            return reports?.filter(x => printServerIds.some(y => y === x.id)).map(x => ({
                label: x.name,
                value: x.id,
            })) ?? [];
        }
        return reports.map(x => ({
            label: x.name,
            value: x.id,
        })) ?? [];
    });

    public render() {

        const hasErrors = lodash.toArray(this.props.errors)?.length !== 0;
        return (
            <Form className={'p20'}>
                <FormAutocompleteSelect
                    name={'site'}
                    label={'Site'}
                    options={this.getSiteOptions(this.props)}
                />
                <FormAutocompleteSelect
                    name={'printServer'}
                    label={'Print Server'}
                    options={this.getPrintServerOptions(this.props)}
                />
                <FormAutocompleteSelect
                    name={'report'}
                    label={'Report'}
                    options={this.getReportOptions(this.props)}
                />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Cancel
                    </Button>
                    <PillButton
                        disabled={this.props.isLoading || hasErrors || !this.props.isValid}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Download'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        sites: state.masterData.sites,
        printServers: state.masterData.printServers,
        reports: state.masterData.reports,
        siteSettings: state.masterData.siteSettings,
        selectedSiteIds: state.data.selectedSiteIds,
    };
};

export default connect(
    mapStateToProps,
)(ReportFileForm);

import { IRootState } from '../../../@types/redux';
import React from 'react';
import { connect } from 'react-redux';
import * as roleService from '../../../services/right/roleService';
import { Paper } from '@mui/material';
import { formatDateTime, addArrayElement, getIndexOfArrayElement, setArrayElement, compareDate, booleanToYesNo, getUserName } from '../../../services/appFunctionsService';
import RoleEditDialog from './RoleEditComponent';
import CustomTable from '../../../components/datagrid/CustomTable';
import { IRole } from '../../../@types/model/user/role';
import Screen from '../../../components/Screen';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { generalShowSuccessSnackbar } from '../../../store/general/Functions';

interface IRoleListProps {
}

interface IRoleListState {
    isLoading : boolean;

    roleList : Array<IRole>;

    editItem ?: IRole;
    deleteItem ?: IRole;

    isAdding : boolean;
}

class RoleList extends React.Component<IRoleListProps, IRoleListState> {
    constructor(props : IRoleListProps) {
        super(props);

        this.state = {
            isLoading: true,
            roleList: [],
            editItem: undefined,
            deleteItem: undefined,
            isAdding: false,
        };
    }

    public componentDidMount() {
        this.loadData();
    }

    private loadData() {
        roleService.getList(
        ).then((result) => {
            this.setState({
                roleList: result,
                isLoading: false,
            });
        });
    }

    private setEditItem = (item ?: IRole) => {
        this.setState({
            editItem: item,
        });
    };

    private setDeleteItem = (item ?: IRole) => {
        this.setState({
            deleteItem: item,
        });
    };

    private onDeleteYesClick = async () => {
        this.setState({ isLoading: true });

        const roleId = this.state.deleteItem?.id;

        if (roleId) {
            const res = await roleService.deleteRole(roleId);

            if (res) {
                this.onDeleteNoClick();

                await this.loadData();

                generalShowSuccessSnackbar('Role deleted successfully.');
            }
        }
    };

    private onDeleteNoClick = () => {
        this.setState({
            deleteItem: undefined,
        });
    };

    private onFormClose = () => this.setState({
        editItem: undefined,
        isAdding: false,
    });

    private onAdd = () => this.setState({ isAdding: true });

    private onItemSaved = (item : IRole) => {
        const index = getIndexOfArrayElement(this.state.roleList, item, 'id');

        let items = [];
        if (index > -1) {
            items = setArrayElement<IRole>(this.state.roleList, index, item);
        } else {
            items = addArrayElement<IRole>(this.state.roleList, item, 'end');
        }

        this.setState({
            roleList: items,
            editItem: undefined,
            isAdding: false,
        });
    };

    private refresh = () => {
        this.setState({
            isLoading: true,
        });

        this.loadData();
    };

    public render() {
        const { roleList, editItem, isAdding } = this.state;

        return (
            <Screen isPadded isScrollable={false} isLoading={this.state.isLoading}>
                <div className={'wfill hfill fdc'}>
                    <Paper className='hfill'>
                        <CustomTable<IRole>
                            enableAdding
                            addFunction={this.onAdd}
                            enableEditing={() => true}
                            editFunction={this.setEditItem}
                            enableSorting
                            enableFiltering
                            enablePagination
                            enableRefresh
                            refreshFunction={this.refresh}
                            enableDeleting={() => true}
                            deleteFunction={this.setDeleteItem}
                            fitWidthToPage
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', formatFunction: getUserName, enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', formatFunction: getUserName, enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={roleList}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IRole) => row.isActive}
                        />
                    </Paper>
                </div>
                <RoleEditDialog open={isAdding || !!editItem} role={editItem} onClose={this.onFormClose} onSaved={item => this.onItemSaved(item)} />
                <ConfirmationPrompt title={'Delete Role'} open={!!this.state.deleteItem} message={'Are you sure you want to delete this role?'}
                    onOkClicked={this.onDeleteYesClick} onCancelClicked={this.onDeleteNoClick}/>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
)(RoleList);

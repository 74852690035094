import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { MaterialFormValues } from '../../../../@types/model/masterData/material/materialFormValues';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import { IOptionType } from '../../../../@types/helper';
import FormAutocompleteSelect from '../../../../components/input/form/FormAutoCompleteSelect';
import { IRootState } from '../../../../@types/redux';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { IPalletBaseType } from '../../../../@types/model/masterData/palletBaseType/palletBaseType';
import { IMaterialType } from '../../../../@types/model/masterData/materialType/materialType';
import { IPack } from '../../../../@types/model/masterData/pack/pack';
import { IUnitOfMeasure } from '../../../../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import TextInput from '../../../../components/input/TextInput';
import PackmanDialog from '../../../../components/dialog/PackmanDialog';
import { IMaterialCost } from '../../../../@types/model/masterData/materialCost/materialCost';
import { addArrayElement } from '../../../../services/appFunctionsService';
import CustomTextInput from '../../../../components/input/CustomTextInput';
import CustomTooltip from '../../../../components/tooltip/tooltip';

interface IMaterialFormProps {
    isLoading : boolean;
    palletBaseTypeOptions : Array<IOptionType>;
    materialTypeOptions : Array<IOptionType>;
    packOptions : Array<IOptionType>;
    unitOfMeasureOptions : Array<IOptionType>;
}

interface IMaterialFormState {
    openMaterialCostDialog : boolean;
    newMaterialCostAmount : number;
}

type MaterialFormPropsType = IMaterialFormProps & FormikProps<MaterialFormValues>;

class MaterialForm extends React.Component<MaterialFormPropsType, IMaterialFormState> {

    constructor(props : MaterialFormPropsType) {
        super(props);

        this.state = {
            openMaterialCostDialog: false,
            newMaterialCostAmount: 0,
        };
    }

    private openMaterialCostDialog = () => {
        this.setState({ openMaterialCostDialog: true });
    };

    private closeMaterialCostDialog = () => {
        this.setState({ openMaterialCostDialog: false, newMaterialCostAmount: 0 });
    };

    private onNewMaterialCostAmountChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ newMaterialCostAmount:  Number(e.currentTarget.value) });
    };

    private saveNewMaterialCost = () => {
        const newMaterialCostAmount = this.state.newMaterialCostAmount;

        if (newMaterialCostAmount > -1) {
            const newMaterialCost : IMaterialCost = {
                id: 0,
                guid: '',
                materialId: this.props.values.id,
                amount: newMaterialCostAmount,
                currentlyActive: true,
                isActive: true,
            };

            const newMaterialCostList = addArrayElement(this.props.values.materialCosts ?? [], newMaterialCost);

            this.props.setFieldValue('materialCosts', newMaterialCostList);
            this.props.setFieldValue('materialCurrentCost', newMaterialCost);
            this.closeMaterialCostDialog();
        }
    };

    public render() {
        const newMaterialCostAmount = this.state.newMaterialCostAmount;
        const values = this.props.values;
        return (
            <Form className={'p20'}>
                <FormTextInput name={'code'} label={'Code'}/>
                <FormTextInput name={'name'} label={'Name'}/>
                <CustomTooltip title={!!values.packs && values.packs.length > 0 ? 'A Pack Type is already selected.' : ''}>
                    <FormAutocompleteSelect name={'palletBaseType'} label={'Pallet Base Type'} disabled={!!values.packs && values.packs.length > 0} options={this.props.palletBaseTypeOptions}/>
                </CustomTooltip>
                <FormAutocompleteSelect name={'materialType'} label={'Material Type'} options={this.props.materialTypeOptions}/>
                <CustomTooltip title={!!values.palletBaseType ? 'Pallet Base Type is already selected.' : ''}>
                    <FormAutocompleteSelect
                        name={'packs'}
                        label={'Pack'}
                        options={this.props.packOptions}
                        disabled={!!values.palletBaseType}
                        isMulti
                    />
                </CustomTooltip>
                <FormAutocompleteSelect name={'unitOfMeasure'} label={'Unit Of Measure'} options={this.props.unitOfMeasureOptions}/>
                <div className={'fdr'}>
                    <TextInput
                        label={'Current Cost (in Rand)'}
                        type={'number'}
                        value={this.props.values.materialCurrentCost?.amount ?? ''}
                        name={'materialCurrentCost.amount'}
                        className={'flx1'}
                        disabled={true}
                    />
                    <CustomTooltip title={'Add new material cost'}>
                        <PillButton
                            disabled={this.props.isLoading}
                            className={'pl20 h35 pb0 mb0'}
                            text={'Costing'}
                            color={'secondary'}
                            onClick={this.openMaterialCostDialog}
                        />
                    </CustomTooltip>
                </div>
                <FormSingleToggleButton name={'isInnerPackaging'} label={'Is Inner Packaging'} />
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <div className={'fdr ml10 ais pt10 pb10'}>
                    <div className={'flx1'}/>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
                {!!this.state.openMaterialCostDialog &&
                    <PackmanDialog
                        title='Material Cost'
                        isOpen={this.state.openMaterialCostDialog}
                        onClose={this.closeMaterialCostDialog}>
                        <div className={'fdc w500 p10'}>
                            <div className={'fdr'}>
                                <FormTextInput className={'flx1'} name={'materialCurrentCost.amount'} label={'Current Cost (in Rand)'} disabled={true}/>
                                <FormSingleToggleButton className={'flx1'} name={'materialCurrentCost.currentlyActive'} label={'Currently Active'} disabled={true}/>
                            </div>
                            <div className={'fdr pl10 pr10'}>
                                <CustomTextInput
                                    className={'flx1'}
                                    type={'number'}
                                    field={'amount'}
                                    label={'New Amount (in Rand)'}
                                    initialValue={newMaterialCostAmount}
                                    value={newMaterialCostAmount}
                                    onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.onNewMaterialCostAmountChange(e)}
                                />
                            </div>
                            <div className={'fdr ml10 ais pt10 pb10'}>
                                <div className={'flx1'}/>
                                <Button
                                    className={'fwb h35'}
                                    variant='text'
                                    color='primary'
                                    onClick={this.closeMaterialCostDialog}
                                >
                                        Clear
                                </Button>
                                <CustomTooltip title={this.state.newMaterialCostAmount < 0 ? 'Amount must be positive'  : ''}>
                                    <PillButton
                                        disabled={this.props.isLoading || this.state.newMaterialCostAmount < 0}
                                        className={'ml15 pl20 pr20 h35'}
                                        text={'Save'}
                                        color={'secondary'}
                                        onClick={this.saveNewMaterialCost}
                                    />
                                </CustomTooltip>
                            </div>
                        </div>
                    </PackmanDialog >
                }
            </Form>
        );
    }
}

const getPalletBaseTypes = (state : IRootState) => state.masterData.palletBaseTypes;

const getPalletBaseTypeOptions = createSelector(
    [getPalletBaseTypes],
    (palletBaseTypes : Array<IPalletBaseType>) => {
        return palletBaseTypes.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }));
    },
);

const getMaterialTypes = (state : IRootState) => state.masterData.materialTypes;

const getMaterialTypeOptions = createSelector(
    [getMaterialTypes],
    (materialTypes : Array<IMaterialType>) => {
        return materialTypes.filter(x => x.isActive).map(x => ({ label: x.name, value: x.id }));
    },
);

const getPacks = (state : IRootState) => state.masterData.packs;

const getPackOptions = createSelector(
    [getPacks],
    (packs : Array<IPack>) => {
        return packs.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.description}`, value: x.id }));
    },
);

const getUnitOfMeasures = (state : IRootState) => state.masterData.unitOfMeasures;

const getUnitOfMeasureOptions = createSelector(
    [getUnitOfMeasures],
    (unitOfMeasures : Array<IUnitOfMeasure>) => {
        return unitOfMeasures.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        palletBaseTypeOptions: getPalletBaseTypeOptions(state),
        materialTypeOptions: getMaterialTypeOptions(state),
        packOptions: getPackOptions(state),
        unitOfMeasureOptions: getUnitOfMeasureOptions(state),
    };
};

export default connect(
    mapStateToProps,
)(MaterialForm);

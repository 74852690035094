import * as React from 'react';
import { IRootState, IAuthState, DispatchCall, RootAction } from '../../../@types/redux';
import { connect } from 'react-redux';
import { Paper } from '@mui/material';
import { formatDateTime, compareDate, removeArrayElement } from '../../../services/appFunctionsService';
import CustomTable from '../../../components/datagrid/CustomTable';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import { dataSetTrip, dataSetTripRelatedData } from '../../../store/data/Functions';
import moment from 'moment';
import materialTheme from '../../../styles/materialTheme';
import { IOrganization } from '../../../@types/model/masterData/organization/organization';
import { ISite } from '../../../@types/model/masterData/site/site';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { createSelector } from 'reselect';
import Screen from '../../../components/Screen';
import TransactionFilter from '../../../components/filters/BasicTransactionScreenFilter';
import { ITrip, Trip } from '../../../@types/model/dispatch/trip';
import { ICarrier } from '../../../@types/model/masterData/carrier/carrier';
import TripHttpService from '../../../services/http/trip/tripHttpService';
import TripSummary from './TripSummary';
import { Formik, FormikActions } from 'formik';
import { ITripFormValues, TripFormValues } from '../../../@types/model/dispatch/tripFormValues';
import TripForm from './form/TripForm';
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_TIMESTAMP_FROM_API } from '../../../appConstants';
import { IDispatchInstruction } from '../../../@types/model/dispatch/dispatchInstruction';
import { IRight } from '../../../@types/model/user/right';
import { RouteComponentProps } from 'react-router';
import { ITemperatureUnit } from '../../../@types/model/dispatch/temperatureUnit';
import { ITruckType } from '../../../@types/model/masterData/truckType/truckType';
import { IStock } from '../../../@types/model/stock/stock';
import { dataSetStocks, dataSetDispatchInstructions } from '../../../store/data/Actions';
import { Dispatch, bindActionCreators } from 'redux';
import { IDispatchView } from '../../../@types/model/dispatch/dispatchView';
import DispatchHttpService from '../../../services/http/dispatch/dispatchHttpService';
import { navPath, navTrip } from '../../../store/nav/Actions';
import { syncMasterData } from '../../../services/masterDataSyncService';
import DeleteConfirmationDialog from '../../../components/dialog/DeleteConfirmationDialog';

interface ITripScreenProps extends RouteComponentProps {
    dataSetStocks : DispatchCall<Array<IStock>>;
    dataSetDispatchInstructions : DispatchCall<Array<IDispatchInstruction>>;
    trips : Array<ITrip>;
    organizations : Array<IOrganization>;
    carriers : Array<ICarrier>;
    auth : IAuthState;
    selectedOrganizationIds : Array<number> ;
    selectedSiteIds : Array<number> ;
    dispatches : Array<IDispatchInstruction>;
    dispatchViews : Array<IDispatchView>;
    sites : Array<ISite>;
    stocks : Array<IStock>;
    truckTypes : Array<ITruckType>;
}

interface ITripScreenState {
    isLoading : boolean;
    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;
    isShowingTripSummary : boolean;
    isDeleting : boolean;
    deleteItem ?: ITrip;
    isEditing : boolean;
    isAdding : boolean;
    selectedTrip ?: ITrip;
    isDialogOpen : boolean;
}

class TripScreen extends React.Component<ITripScreenProps, ITripScreenState> {
    constructor(props : ITripScreenProps) {
        super(props);

        this.state = {
            isLoading: false,
            selectedFromDate: moment().local().startOf('day').subtract(1, 'week'),
            selectedToDate: moment().local().endOf('day'),
            isShowingTripSummary: false,
            isDeleting: false,
            deleteItem: undefined,
            isEditing: false,
            isAdding: false,
            selectedTrip: undefined,
            isDialogOpen: false,
        };
    }

    private getDate = (type : 'from' | 'to') => {
        switch (type) {
            case 'from':
                return this.state.selectedFromDate.startOf('day').utc().unix() * 1000;
            case 'to':
                return this.state.selectedToDate.endOf('day').utc().unix() * 1000;
        }
    };

    public componentDidMount = async () => {
        this.setLoading(true);
        if (this.props.location.pathname?.includes(':')) {
            this.showSummaryDialog(Number(this.props.location.pathname.split(':')[1].split('/')[0]));
        } else {
            // checks if indexedDB is available.
            const isIndexedDBAvailable = !!self.indexedDB ? true : false;

            if (isIndexedDBAvailable) {
                await syncMasterData(false);
            }
            try {
                const res = await TripHttpService.getTripTransactionData(this.getDate('from'), this.getDate('to'), undefined, undefined, !isIndexedDBAvailable);

                dataSetTripRelatedData(res.data);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred retrieving Trip data.');
            } finally {
                this.setLoading(false);
            }
        }
    };

    public componentDidUpdate = (prevProps : ITripScreenProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            /* prop changes go here */
            if (prevProps.location.pathname?.includes(':') && !nextProps.location.pathname?.includes(':')) {
                this.closeSummaryDialog();
            }
            if (!prevProps.location.pathname?.includes(':') && nextProps.location.pathname?.includes(':')) {
                this.showSummaryDialog(Number(nextProps.location.pathname.split(':')[1].split('/')[0]));
            }
            if (prevProps.location.pathname?.includes(':') && nextProps.location.pathname?.includes(':')) {
                const prevId = prevProps.location.pathname.split(':')[1].split('/')[0];
                const nextId = nextProps.location.pathname.split(':')[1].split('/')[0];
                if (prevId !== nextId) {
                    this.showSummaryDialog(Number(nextId));
                }
            }
            if (nextProps.selectedSiteIds !== prevProps.selectedSiteIds) {
                this.refreshData();
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    private getCarrierCode = (id : number) => {
        const carriers = this.props.carriers;
        const carrier = carriers && carriers.find(x => x.id === id);
        return carrier ? carrier.code : '';
    };

    private getTruckTypeCode = (id : number) => {
        const truckTypes = this.props.truckTypes;
        const truckType = truckTypes && truckTypes.find(x => x.id === id);
        return truckType ? truckType.code : '';
    };

    private refreshData = async (noAnnounce ?: boolean) => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;
        try {
            const res = await TripHttpService.getTripTransactionData(this.getDate('from'), this.getDate('to'), undefined, undefined, !isIndexedDBAvailable);

            dataSetTripRelatedData(res.data);
            this.setLoading(false);
            if (!noAnnounce) {
                generalShowSuccessSnackbar('Data Refreshed');
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving stock related data.');
            this.setLoading(false);
        }
    };

    public showSummaryDialog = async (id : number) => {
        try {
            const res = await TripHttpService.getTrip(id);

            if (res && res.data) {
                this.setState({
                    isShowingTripSummary: true,
                    selectedTrip: res.data,
                }, async () => {
                    try {
                        this.setLoading(true);
                        const res2 = await DispatchHttpService.getDispatchesLinkedToTrip(res.data.id);

                        if (res2 && res2.data) {
                            this.props.dataSetStocks(res2.data.stocks);
                            this.props.dataSetDispatchInstructions(res2.data.dispatches);
                        }

                    } catch (ex) {
                        generalShowErrorSnackbar('Failed to load related trip related data');
                    } finally {
                        this.setLoading(false);
                    }
                });

            }

        } catch (ex) {
            generalShowErrorSnackbar('Failed to load related trip related data');
        } finally {
            this.setLoading(false);
        }
    };

    public closeSummaryDialog = () => {
        this.setState({
            isShowingTripSummary: false,
            selectedTrip: undefined,
        }, () => navPath(this.props.location?.pathname?.split('/:')[0]));
    };

    private addTrip = () => {
        this.setState({ isDialogOpen: true });
    };

    private editTrip = async (row : ITrip) => {
        this.setState({
            isDialogOpen: true,
            selectedTrip: row,
        }, async () => {
            try {
                this.setLoading(true);
                const res = await DispatchHttpService.getDispatchesLinkedToTrip(row.id);

                if (res && res.data) {
                    this.props.dataSetStocks(res.data.stocks);
                    this.props.dataSetDispatchInstructions(res.data.dispatches);
                }

            } catch (ex) {
                generalShowErrorSnackbar('Failed to load related trip related data');
            } finally {
                this.setLoading(false);
            }
        });
    };

    public onDialogClose = () => {
        this.setState({
            isDialogOpen: false,
            selectedTrip: undefined,
        });
    };

    private setDeleteItem = (item ?: ITrip) => {
        this.setState({
            deleteItem: item,
            isDeleting: true,
        });
    };

    public onSubmit = async (value : ITripFormValues) => {
        this.setLoading(true);
        try {
            const res = await TripHttpService.addOrUpdateTrip(new Trip(value));

            if (res.data) {
                dataSetTrip(res.data);
            }

            if (this.state.selectedTrip) {
                generalShowSuccessSnackbar('Trip updated successfully.');
            } else {
                generalShowSuccessSnackbar('Trip added successfully.');
            }

            this.onDialogClose();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating Trip data.');
        } finally {
            this.setLoading(false);
        }
    };

    private removeTrip = async (deleteReason : string) => {

        if (this.state.deleteItem) {
            const trip = { ...this.state.deleteItem };
            trip.isActive = false;

            if (deleteReason !== '' && deleteReason.length >= 200) {
                this.setLoading(true);

                try {
                    const res = await TripHttpService.tripDelete(trip.id, deleteReason);

                    if (res) {
                        if (res.data) {
                            dataSetTrip(res.data);
                        }

                        generalShowSuccessSnackbar('Trip deleted successfully.');
                    }
                } catch (e) {
                    generalShowErrorSnackbar('An error occurred deleting trip.');
                    trip.isActive = true;
                } finally {
                    this.closeDeleteConfirmationPopup();
                    this.setLoading(false);
                }
            } else {
                generalShowErrorSnackbar('Reason for deleting this trip must be at least 200 characters.');
            }
        }
    };

    private closeDeleteConfirmationPopup = () => this.setState({ isDeleting: false, deleteItem: undefined });

    public onReset = async (formValues : ITripFormValues, formikActions : FormikActions<ITripFormValues>) => {
        formikActions.resetForm();
        this.onDialogClose();
    };

    private handleDateRangeChange = (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => {
        const selectedFromDate = changedBy === 'start' ? moment(start).startOf('day') : end < start ? moment(end).startOf('day') : moment(start).startOf('day');
        const selectedToDate = changedBy === 'end' ? moment(end).endOf('day') : start > end ? moment(start).endOf('day') : moment(end).endOf('day');
        this.setState({ selectedFromDate, selectedToDate });
    };

    private formatTripLoadDate = (dateTime : moment.Moment) => {
        return dateTime ? moment.utc(dateTime, DATE_FORMAT_TIMESTAMP_FROM_API).format(DATE_FORMAT_DEFAULT) : '';
    };

    private getDispatchCode = (tripId : number) => {
        const dispatch = this.props.dispatchViews.filter(x => x.isActive && x.tripId === tripId).map(x => x.dispatchCode);

        return dispatch.join(', ');
    };

    private getTemperatureUnitDeviceNumbers = (temperatureUnits : Array<ITemperatureUnit>) => temperatureUnits.map(x => x.deviceNumber).toString().replace(/,/g, ', ');

    private getTripsData = (props : ITripScreenProps) => props.trips;
    private getDispatchInstructions = (props : ITripScreenProps) => props.dispatches;
    private getDispatchViews = (props : ITripScreenProps) => props.dispatchViews;
    private getSelectedOrganizationIds = (props : ITripScreenProps) => props.selectedOrganizationIds;
    private getSelectedSiteIds = (props : ITripScreenProps) => props.selectedSiteIds;

    private getTrips = createSelector(
        [this.getTripsData, this.getDispatchViews, this.getSelectedOrganizationIds, this.getSelectedSiteIds],
        (trips : Array<ITrip>, dispatchViews : Array<IDispatchView>, selectedOrganizationIds : Array<number>, selectedSiteIds : Array<number>) => {
            if (!trips) return [];

            const dispatchesWithTrips = dispatchViews.filter(x => !!x.tripId);

            const filteredDispatches = dispatchesWithTrips.filter(x => selectedOrganizationIds?.some(z => !!(z === x.organizationId || z === x.targetOrganizationId))
                                                            && selectedSiteIds?.some(z => !!(z === x.sourceSiteId || z === x.destinationSiteId)));

            const dispatchesToExclude = dispatchesWithTrips.filter(x => !filteredDispatches.some(y => y.id === x.id));

            let filteredTrips : Array<ITrip> = trips;

            dispatchesToExclude.forEach((dispatch) => {
                if (!!dispatch.tripId) {
                    const index = filteredTrips.findIndex(x => x.id === dispatch.tripId);
                    const tripLinkedToFilteredDispatchIndex = filteredDispatches.findIndex(x => x.tripId === dispatch.tripId);
                    if (index !== -1 && tripLinkedToFilteredDispatchIndex === -1) {
                        filteredTrips = removeArrayElement(filteredTrips, index);
                    }
                }
            });

            return filteredTrips;
        },
    );

    private getColumns = () => {
        const columns = [];
        columns.push(
            { title: 'Id', field: 'id', width: 100, enableFiltering: true, enableSorting: true },
            { title: 'Load Date', field: 'loadDate', formatFunction: this.formatTripLoadDate, sortFunction: compareDate, type: 'date', width: 170, enableFiltering: true, enableSorting: true },
            { title: 'Dispatch Code', field: 'id', formatFunction: this.getDispatchCode, width: 190, enableFiltering: true, enableSorting: true },
            { title: 'Carrier', field: 'carrierId', formatFunction: this.getCarrierCode, width: 150, enableFiltering: true, enableSorting: true },
            { title: 'Truck Type', field: 'truckTypeId', formatFunction: this.getTruckTypeCode, width: 150, enableFiltering: true, enableSorting: true },
            { title: 'Description', field: 'description', width: 150, enableFiltering: true, enableSorting: true },
            { title: 'Registration Number', field: 'registrationNumber', width: 220, enableFiltering: true, enableSorting: true },
            { title: 'Driver', field: 'driver', width: 150, enableFiltering: true, enableSorting: true },
            { title: 'Container', field: 'container', width: 150, enableFiltering: true, enableSorting: true },
            { title: 'Container Tare Weight', field: 'containerTareWeight', width: 180, enableFiltering: true, enableSorting: true },
            { title: 'Seal Number', field: 'sealNumber', width: 200, enableFiltering: true, enableSorting: true },
            { title: 'Fleet Number', field: 'fleetNumber', width: 200, enableFiltering: true, enableSorting: true },
            { title: 'Devices', field: 'temperatureUnits', formatFunction: this.getTemperatureUnitDeviceNumbers, width: 200, enableFiltering: true, enableSorting: true },
            { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, width: 180, enableFiltering: true, enableSorting: true },
            { title: 'Created By', field: 'createdByName', width: 200, enableFiltering: true, enableSorting: true },
            { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, width: 180, enableFiltering: true, enableSorting: true },
            { title: 'Updated By', field: 'updatedByName', width: 200, enableFiltering: true, enableSorting: true },
        );
        return columns;
    };

    public getSelectedTrip = (props : ITripScreenProps, state : ITripScreenState) => state.selectedTrip;
    public getCarriers = (props : ITripScreenProps) => props.carriers;
    public getStocks = (props : ITripScreenProps) => props.stocks;
    public getTruckTypes = (props : ITripScreenProps) => props.truckTypes;

    public getInitialFormValues = createSelector(
        [this.getSelectedTrip, this.getCarriers, this.getTruckTypes, this.getDispatchInstructions, this.getStocks],
        (trip, carriers, truckTypes, dispatches, stocks) => {
            return new TripFormValues(trip, carriers, truckTypes, undefined, undefined, dispatches, stocks);
        },
    );

    private getRights = (props : ITripScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : ITripScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isPadded={false} isLoading={this.state.isLoading} isScrollable={false}>
                <div className={'fdc hfill'}>
                    <TransactionFilter className={'mt10 pt10 mr20 mb10'} selectedFromDate={this.state.selectedFromDate} selectedToDate={this.state.selectedToDate} handleDateRangeChange={this.handleDateRangeChange} onApplyClick={this.refreshData} />
                    {/* Trip Table */}
                    <Paper className={'mr20 mb10 mt10 ml20'} style={{ height: 'calc(100% - 100px )' }}>
                        <CustomTable<ITrip>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.addTrip}
                            editFunction={this.editTrip}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(row : ITrip) => row.isActive && this.hasEditingRight(this.props)}
                            deleteColor={materialTheme.palette.primary.main}
                            deleteFunction={this.setDeleteItem}
                            enableDetails={true}
                            detailIcon={'info'}
                            detailTooltip={'Summary'}
                            detailFunction={row => navTrip(row.id)}
                            enableSorting
                            enableFiltering
                            enableClearFilterButton
                            enablePagination
                            enableRefresh
                            disableRefreshButton={this.state.isLoading}
                            refreshFunction={this.refreshData}
                            columns={this.getColumns()}
                            rows={this.getTrips(this.props)}
                            initialSortOrder={[{ columnName: 'loadDate_Load Date', direction : 'desc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={270}
                            isActive={(row : ITrip) => row.isActive}
                        />
                    </Paper>
                    {/* Edit Dialog */}
                    <PackmanDialog
                        title={'Trip'}
                        isEdit={!!this.state.selectedTrip}
                        isLoading={this.state.isLoading}
                        isOpen={this.state.isDialogOpen}
                        onClose={this.onDialogClose}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={this.onSubmit}
                            onReset={this.onReset}
                            enableReinitialize
                            validationSchema={TripFormValues.formSchema}
                            component={TripForm}/>
                    </PackmanDialog >
                    {/* Summary Dialog */}
                    <PackmanDialog
                        title='Trip Summary'
                        isInfo={true}
                        fullScreen={true}
                        isOpen={this.state.isShowingTripSummary}
                        onClose={this.closeSummaryDialog}>
                        {this.state.selectedTrip &&
                            <TripSummary
                                selectedTrip={this.state.selectedTrip}
                                isLoading={this.state.isLoading}
                            />
                        }
                    </PackmanDialog >
                    {/* Delete Dialog */}
                    {!!this.state.isDeleting &&
                        <DeleteConfirmationDialog
                            isLoading={this.state.isLoading}
                            onSubmit={this.removeTrip}
                            onclose={this.closeDeleteConfirmationPopup}
                            isOpen={!!this.state.isDeleting}
                            title={'Trip'}/>
                    }
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        organizations: state.masterData.organizations,
        trips: state.data.trips,
        carriers: state.masterData.carriers,
        auth: state.auth,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
        dispatches: state.data.dispatchInstructions,
        dispatchViews: state.data.dispatchViews,
        sites: state.masterData.sites,
        stocks: state.data.stocks,
        truckTypes: state.masterData.truckTypes,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    {
        dataSetStocks,
        dataSetDispatchInstructions,
    },
    dispatcher,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TripScreen);

import * as Yup from 'yup';
import { IOptionType } from '../../helper';

export interface IReportFileFormValues {
    report : IOptionType;
    site : IOptionType;
    printServer : IOptionType;
}

export class ReportFileFormValues implements IReportFileFormValues {
    public report : IOptionType;
    public site : IOptionType;
    public printServer : IOptionType;

    public constructor(
    ) {
        this.initializeDefaultValues();
    }

    public initializeDefaultValues = () => {
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
    });
}

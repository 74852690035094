import moment from 'moment';
import { DATEPICKER_FORMAT_DEFAULT } from '../../../../appConstants';
import { IBaseModel } from '../../../model';
import { ISeasonFormValues } from './seasonFormValue';

export interface ISeason extends IBaseModel {
    name : string;
    code : string;
    startDate : string;
    endDate : string;
    commodityId : number;
}

export class Season implements ISeason {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public name : string;
    public code : string;
    public startDate : string;
    public endDate : string;
    public commodityId : number;

    public constructor(formValues : ISeasonFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.name = formValues.name;
        this.code = formValues.code;
        this.startDate = moment(formValues.startDate).format(DATEPICKER_FORMAT_DEFAULT);
        this.endDate = formValues.endDate != null ? formValues.endDate?.format(DATEPICKER_FORMAT_DEFAULT) : '';
        this.commodityId = Number(formValues.commodity?.value) ?? 0;
    }
}

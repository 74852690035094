import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { IIntakeLine } from '../intakeLine';
import { IIntakeLineLayerFormValues, IntakeLineLayerFormValues } from './intakeLineLayerFormValues';
import { IIntakeLineLayer, IntakeLineLayer } from '../intakeLineLayer';
import { IPalletBaseType } from '../../masterData/palletBaseType/palletBaseType';
import { IVariety } from '../../masterData/variety/variety';
import { IOrchard } from '../../masterData/orchard/orchard';
import { IPack } from '../../masterData/pack/pack';
import { ILot } from '../../lot/lot';
import { IProject } from '../../masterData/project/project';

export interface IIntakeLineFormValues {
    id : number;
    guid : string;
    isActive : boolean;

    palletBaseType ?: IOptionType;
    grossWeight : number;
    nettWeight : number;
    intakePalletRef ?: string;
    intakeLineLayers : Array<IIntakeLineLayerFormValues>;
}

export class IntakeLineFormValues implements IIntakeLineFormValues {
    public id : number;
    public guid : string;
    public isActive : boolean;

    public palletBaseType ?: IOptionType;
    public grossWeight : number;
    public nettWeight : number;
    public intakePalletRef ?: string;
    public intakeLineLayers : Array<IIntakeLineLayerFormValues>;

    public constructor(
        intakeLine ?: IIntakeLine,
        palletBaseTypes ?: Array<IPalletBaseType>,
        varieties ?: Array<IVariety>,
        orchards ?: Array<IOrchard>,
        packs ?: Array<IPack>,
        lots ?: Array<ILot>,
        projects ?: Array<IProject>
    ) {
        this.initializeDefaultValues();
        if (intakeLine) {
            this.id = intakeLine.id;
            this.guid = intakeLine.guid;
            this.isActive = intakeLine.isActive;

            this.palletBaseType = palletBaseTypes ? {
                label: palletBaseTypes.find(x => x.id === intakeLine.palletBaseTypeId)?.name ?? '',
                value: intakeLine.palletBaseTypeId,
            } : undefined;

            this.grossWeight = intakeLine.grossWeight;
            this.nettWeight = intakeLine.nettWeight;
            this.intakePalletRef = intakeLine.intakePalletRef ?? '';

            this.intakeLineLayers = intakeLine.intakeLineLayers?.map((x : IIntakeLineLayer) => new IntakeLineLayerFormValues(x, varieties, orchards, packs, lots, projects));
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.isActive = true;

        this.palletBaseType = undefined;
        this.grossWeight = 0.0;
        this.nettWeight = 0.0;
        this.intakePalletRef = '';
        this.intakeLineLayers = [new IntakeLineLayer(new IntakeLineLayerFormValues())];
    };

    public static formSchema = Yup.object().shape({
        isActive: Yup.boolean().required('Required'),
        palletBaseType: Yup.object().nullable().required('Required'),
        grossWeight: Yup.number().positive('Gross Weight should be more than 0').required('Required'),
        nettWeight: Yup.number().min(0).required('Required'),
        intakeLineLayers: Yup.array().of(IntakeLineLayerFormValues.formSchema),
    });
}

import * as React from 'react';
import CustomTable from '../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetMarks } from '../../../store/masterData/Actions';
import { formatDateTime, upsertArrayElement } from '../../../services/appFunctionsService';
import { IMark, Mark } from '../../../@types/model/masterData/mark/mark';
import MarkHttpService from '../../../services/http/masterData/markHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikActions } from 'formik';
import { createSelector } from 'reselect';
import { MarkFormValues, IMarkFormValues } from '../../../@types/model/masterData/mark/markFormValues';
import MarkForm from './form/MarkForm';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { setMarksMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IMarkScreenProps extends RouteComponentProps {
    dataSetMarks : DispatchCall<Array<IMark>>;
    markData : Array<IMark>;
    auth : IAuthState;
}

interface IMarkScreenState {
    rows : Array<IMark>;
    isLoading : boolean;
    selectedMark ?: IMark;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingMark ?: IMark;
}

class MarkScreen extends React.Component<IMarkScreenProps, IMarkScreenState> {
    constructor(props : IMarkScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedMark: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);

            try {
                const res = await MarkHttpService.getMarkData();

                this.props.dataSetMarks(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading brands.');
                this.setLoading(false);
            }
        }
    };

    public editMark = (mark : IMark) => {
        this.setState({
            isDialogOpen: true,
            selectedMark: mark,
        });
        this.openDialog();
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedMark: undefined,
        });
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public onSubmit = async (values : IMarkFormValues) => {
        this.setLoading(true);

        try {
            const res = await MarkHttpService.addOrUpdateMark(new Mark(values));

            const newMarkList = upsertArrayElement(this.props.markData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetMarks(newMarkList);
            await setMarksMasterDataIndexedDB(newMarkList);

            if (this.state.selectedMark) {
                generalShowSuccessSnackbar('Brand updated successfully.');
            } else {
                generalShowSuccessSnackbar('Brand added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating Brand data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (mark : IMark) => {
        this.setState({ isDeletePopupOpen: true, deletingMark: mark });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingMark: undefined });
    };

    private removeMark = async () => {
        const newMark = this.state.deletingMark;
        if (newMark) {
            newMark.isActive = false;
            this.setLoading(true);

            try {
                const res = await MarkHttpService.deleteMark(newMark.id);

                const newMarkList = upsertArrayElement(this.props.markData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetMarks(newMarkList);
                await setMarksMasterDataIndexedDB(newMarkList);

                generalShowSuccessSnackbar('Brand updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting Brand.');
                newMark.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    public onReset = async (formValues : IMarkFormValues, formikActions : FormikActions<IMarkFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    public getSelectedMark = (props : IMarkScreenProps, state : IMarkScreenState) => state.selectedMark;

    public getInitialFormValues = createSelector(
        [this.getSelectedMark],
        (mark : IMark) => {
            return new MarkFormValues(mark);
        },
    );

    private getRights = (props : IMarkScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IMarkScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.markData ? this.props.markData : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Brand'
                    isEdit={!!this.state.selectedMark}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={MarkFormValues.formSchema}
                        component={MarkForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IMark>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editMark}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(mark : IMark) => mark.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Description', field: 'description', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IMark) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Brand'}
                    open={this.state.isDeletePopupOpen}
                    message={'Are you sure you want to delete this brand?'}
                    onOkClicked={this.removeMark}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        markData: state.masterData.marks,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetMarks }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MarkScreen);

import * as React from 'react';
import {
    dataSetDashboardDispatchSelectedLoadDate,
    dataSetDashboardDispatchSelectedDispatchInstructionId,
    dataSetDashboardDispatchInstructionLines,
    dataSetStocks,
    dataSetDispatchInstructions,
    dataSetDispatchViews,
} from '../../../store/data/Actions';
import { DispatchCall, RootAction, IRootState, IAuthState } from '../../../@types/redux';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CustomTable, { ICustomTableColumn } from '../../../components/datagrid/CustomTable';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import { dataSetCompliance, dataSetAllMaterialDispatchViewRelatedData, dataSetMaterialDispatchView } from '../../../store/data/Functions';
import {  formatDateTimeToDateOnly, formatDateTime, compareDate, addArrayElement } from '../../../services/appFunctionsService';
import { Checkbox, Paper, Card } from '@mui/material';
import { DISPATCH_INSTRUCTION_STATUSSES, DATE_FORMAT_DEFAULT_NO_TIME } from '../../../appConstants';
import moment from 'moment';
import { IDashboardDispatchInstructionLine } from '../../../@types/model/dispatch/dashboardDispatchInstructionLine';
import { IDispatchInstruction } from '../../../@types/model/dispatch/dispatchInstruction';
import { ISite } from '../../../@types/model/masterData/site/site';
import { IFarm } from '../../../@types/model/masterData/farm/farm';
import { IOrganization } from '../../../@types/model/masterData/organization/organization';
import { ICommodity } from '../../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../../@types/model/masterData/variety/variety';
import { IPack } from '../../../@types/model/masterData/pack/pack';
import { ISize } from '../../../@types/model/masterData/size/size';
import { IStock } from '../../../@types/model/stock/stock';
import { IColour } from '../../../@types/model/masterData/colour/colour';
import { IGrade } from '../../../@types/model/masterData/grade/grade';
import { ICompliance } from '../../../@types/model/compliance/compliance';
import { ISelectableDispatch } from '../../../@types/model/dispatch/selectableDispatch';
import ComplianceHttpService from '../../../services/http/compliance/complianceHttpService';
import PillButton from '../../../components/input/PillButton';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import DispatchSummary from './MaterialDispatchSummary';
import Screen from '../../../components/Screen';
import lodash from 'lodash';
import { CustomChangeEvent, IOptionType } from '../../../@types/helper';
import GoogleCloudStorageHttpService from '../../../services/googleCloudStorageService';
import { createSelector } from 'reselect';
import { IRight } from '../../../@types/model/user/right';
import { RouteComponentProps } from 'react-router';
import { IMaterialDispatchView } from '../../../@types/model/materialDispatch/materialDispatchView';
import { Trip } from '../../../@types/model/dispatch/trip';
import FileSaver from 'file-saver';
import TransactionFilter from '../../../components/filters/BasicTransactionScreenFilter';
import { navMaterialDispatch, navPath } from '../../../store/nav/Actions';
import { syncMasterData } from '../../../services/masterDataSyncService';
import DeleteConfirmationDialog from '../../../components/dialog/DeleteConfirmationDialog';
import Tooltip from '../../../components/tooltip/tooltip';
import MaterialDispatchHttpService from '../../../services/http/materialDispatch/materialDispatchHttpService';

const path = 'DispatchNotes/';

interface IDispatchInstructionTableProps extends RouteComponentProps {
    dataSetDashboardDispatchSelectedLoadDate : DispatchCall<string>;
    dataSetDashboardDispatchSelectedDispatchInstructionId : DispatchCall<number | undefined>;
    dataSetDashboardDispatchInstructionLines : DispatchCall<Array<IDashboardDispatchInstructionLine>>;
    dataSetStocks : DispatchCall<Array<IStock>>;
    dataSetDispatchInstructions : DispatchCall<Array<IDispatchInstruction>>;
    dataSetDispatchViews : DispatchCall<Array<IMaterialDispatchView>>;
    dispatchInstructions : Array<IDispatchInstruction>;
    materialDispatchViews : Array<IMaterialDispatchView>;
    selectedLoadDate : string;
    sites : Array<ISite>;
    farms : Array<IFarm>;
    organizations : Array<IOrganization>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    sizes : Array<ISize>;
    stockData : Array<IStock>;
    colours : Array<IColour>;
    grades : Array<IGrade>;
    compliances : Array<ICompliance>;
    auth : IAuthState;
    selectedDispatchIndex ?: number;
    showOnlyMyDispatches : boolean;
    selectedOrganizationIds : Array<number>;
    selectedSiteIds : Array<number>;
    selectedExporterOrganization ?: IOrganization;
    trips : Array<Trip>;
}

interface IDispatchInstructionTableState {
    isLoading : boolean;
    dataFetched : boolean;
    columns : Array<ICustomTableColumn>;
    editingDispatch ?: IMaterialDispatchView;
    deletingDispatch ?: IMaterialDispatchView;
    collapseDraftColumn : boolean;
    selectedCompliance ?: ICompliance;
    barcodeSearchValue ?: string;
    dispatchesFilteredByBarcode : Array<IMaterialDispatchView>;
    filterOpen : boolean;
    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;
    selectedDispatchId ?: number;
    showRangePicker : boolean;

    isDispatchFilesDownloadDialogOpen : boolean;
    isDispatchNotePopupOpen : boolean;
    dispatchFilesSourceSites ?: Array<any>;
    dispatchFiles ?: Array<any>;
    dispatchFilesCreatedDates : Array<string>;
    selectedDispatchSite ?: IOptionType;
    isDeleteDialogOpen : boolean;
    searchFilesText : string;
    selectedFilterDate : string | null;
    selectedDispatchNotesFolder : string;

    isDispatchCorrectionDialogOpen : boolean;
}

class DispatchInstructionTable extends React.Component<IDispatchInstructionTableProps, IDispatchInstructionTableState> {
    private anchorEl : any;

    constructor(props : IDispatchInstructionTableProps) {
        super(props);

        this.state = {
            isLoading: false,
            dataFetched: false,
            columns: this.getColumns(),
            collapseDraftColumn: true,
            filterOpen: false,
            selectedFromDate: moment().local().startOf('day').subtract(7, 'days'),
            selectedToDate: moment().local().endOf('day'),
            showRangePicker: false,
            dispatchesFilteredByBarcode: [],

            isDispatchFilesDownloadDialogOpen: false,
            dispatchFilesCreatedDates: [],
            isDispatchNotePopupOpen: false,
            isDeleteDialogOpen: false,
            searchFilesText: '',
            selectedFilterDate: null,
            selectedDispatchNotesFolder: '',

            isDispatchCorrectionDialogOpen: false,
        };
    }

    public componentDidMount = async () => {
        if (this.props.location.pathname?.includes(':')) {
            this.showSummary(Number(this.props.location.pathname.split(':')[1].split('/')[0]));
        } else if (!!this.props.selectedSiteIds && this.props.selectedSiteIds.length > 0) {
            this.setLoading(true);
            // checks if indexedDB is available.
            const isIndexedDBAvailable = !!self.indexedDB ? true : false;

            if (isIndexedDBAvailable) {
                await syncMasterData(false);
            }
            try {
                const res = await MaterialDispatchHttpService.getDispatchScreenData(this.getDate('from'), this.getDate('to'), undefined, undefined, this.props.selectedSiteIds, !isIndexedDBAvailable);

                if (this.props.selectedSiteIds?.length === 1) {
                    const selectedSite : IOptionType = { label: this.getSiteDescription(this.props.selectedSiteIds[0]) , value: this.props.selectedSiteIds[0]  };
                    this.setState({ selectedDispatchSite: selectedSite });
                    this.getDispatchFilesBySite(this.props.selectedSiteIds[0]);
                }

                dataSetAllMaterialDispatchViewRelatedData(res.data);
                this.setState({ dataFetched: true }, () => this.setLoading(false));
            } catch (e) {
                generalShowErrorSnackbar('An error occurred retrieving material dispatch related data.');
                this.setLoading(false);
            }
        }
    };

    public componentDidUpdate = (prevProps : IDispatchInstructionTableProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            /* prop changes go here */
            if (prevProps.location.pathname?.includes(':') && !nextProps.location.pathname?.includes(':')) {
                this.closeSummary();
            } else if (!prevProps.location.pathname?.includes(':') && nextProps.location.pathname?.includes(':')) {
                this.showSummary(Number(nextProps.location.pathname.split(':')[1].split('/')[0]));
            } else if (prevProps.location.pathname?.includes(':') && nextProps.location.pathname?.includes(':')) {
                const prevId = prevProps.location.pathname.split(':')[1].split('/')[0];
                const nextId = nextProps.location.pathname.split(':')[1].split('/')[0];
                if (prevId !== nextId) {
                    this.showSummary(Number(nextId));
                }
            }

            if ((prevProps.selectedSiteIds !== nextProps.selectedSiteIds)) {
                if (nextProps.selectedSiteIds?.length > 0) {
                    this.refreshData(true);
                    if (nextProps.selectedSiteIds?.length !== 1) {
                        this.setState({ selectedDispatchSite: undefined, dispatchFiles: undefined });
                    } else {
                        const selectedSite : IOptionType = { label: this.getSiteDescription(nextProps.selectedSiteIds[0]) , value: nextProps.selectedSiteIds[0]  };
                        this.setState({ selectedDispatchSite: selectedSite });
                        this.getDispatchFilesBySite(nextProps.selectedSiteIds[0]);
                    }
                }
            }
        }
    };

    private getSiteDetail = (id : number) => {
        const site = this.props.sites && this.props.sites.find(x => x.id === id);
        return site && site.shortDescription ? `(${site.code}) - ${site.description}` : 'UNK?';
    };

    private getSiteDescription = (id : number) => {
        const site = this.props.sites && this.props.sites.find(x => x.id === id);
        return site && site.description ? site.description : 'UNKNOWN';
    };

    private isCheckboxDisabled = (row : ISelectableDispatch) => {
        const selectedCompliance = this.state.selectedCompliance && { ...this.state.selectedCompliance };
        if (!selectedCompliance || !selectedCompliance.isActive) {
            return true;
        }

        if ((selectedCompliance.organizationId && selectedCompliance.organizationId !== row.organizationId) ||
            (selectedCompliance.siteId && selectedCompliance.siteId !== row.sourceSiteId)) {
            return true;
        }

        return this.props.compliances.some(x => x.isActive && x.complianceLines.some(y => row.dispatchLines.some(z => y.stockId === z.currentStockId && x.id !== selectedCompliance.id && y.isActive && z.isActive)));
    };

    private handleSelectCheckBox = async (row : ISelectableDispatch, isAdding : boolean) => {
        const selectedCompliance = this.state.selectedCompliance && { ...this.state.selectedCompliance };

        this.setLoading(true);

        if (row && selectedCompliance) {
            try {
                const res = await ComplianceHttpService.addOrRemoveDispatchOnCompliance({ dispatchId: row.id, complianceId: selectedCompliance.id, isAdding });

                if (res && res.data) {
                    dataSetCompliance(res.data);
                    this.setState({ selectedCompliance: res.data });
                    generalShowSuccessSnackbar(`Dispatch successfully ${isAdding ? 'added to' : 'removed from'} compliance.`);
                    this.setLoading(false);
                } else {
                    generalShowErrorSnackbar(`An error occurred ${isAdding ? 'adding the dispatch to' : 'removing the dispatch from'} the compliance.`);
                    this.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar(`An error occurred ${isAdding ? 'adding the dispatch to' : 'removing the dispatch from'} the compliance.`);
                this.setLoading(false);
            }
        }
    };

    private getDispatchTooltip = (row : ISelectableDispatch) => {
        const selectedCompliance = this.state.selectedCompliance && { ...this.state.selectedCompliance };
        if (!selectedCompliance || !selectedCompliance.isActive) {
            return 'No compliance selected';
        } else if (selectedCompliance.organizationId && selectedCompliance.organizationId !== row.organizationId) {
            return 'Dispatch Organization does not match selected compliance organization';
        } else if (selectedCompliance.siteId && selectedCompliance.siteId !== row.sourceSiteId) {
            return 'Dispatch source site does not match selected compliance site';
        } else {
            return 'Select for compliance';
        }
    };

    private getFleetNumber = (id : number, row : IMaterialDispatchView) => {
        const trip = this.props.trips.find(x => x.id === row.tripId);

        return trip && trip.fleetNumber ? trip.fleetNumber : '';
    };

    private getColumns = () => {
        const columns : Array<ICustomTableColumn> = [
            {
                title: 'Select',
                field: 'isSelected',
                width: 100,
                containerComponent: (row : ISelectableDispatch, value : boolean) => {
                    const disabled = this.isCheckboxDisabled(row);
                    return <Tooltip title={this.getDispatchTooltip(row)}>
                        <Checkbox
                            checked={!!this.state.selectedCompliance && value}
                            disabled={disabled}
                            onChange={(event, checked) => this.handleSelectCheckBox(row, checked)}
                        />
                    </Tooltip>;
                },
            },
            { title: 'Material Dispatch Code', field: 'materialDispatchCode', width: 150, enableFiltering: true, enableSorting: true },
            { title: 'Source Site', field: 'sourceSiteId', formatFunction: this.getSiteDetail, width: 110, enableFiltering: true, enableSorting: true },
            { title: 'Destination Site', field: 'destinationSiteId', formatFunction: this.getSiteDetail, enableFiltering: true, enableSorting: true },
            { title: 'Load Date', field: 'loadDate', formatFunction: formatDateTimeToDateOnly, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Status', field: 'status', enableFiltering: true, enableSorting: true },
            { title: 'Transport', field: 'transport', enableFiltering: true, enableSorting: true },
            { title: 'Driver', field: 'driver', enableFiltering: true, enableSorting: true },
            { title: 'Truck Number', field: 'id', formatFunction: this.getFleetNumber, enableFiltering: true, enableSorting: true },
            { title: 'Trip', field: 'tripId', enableFiltering: true, enableSorting: true },
            { title: 'Is Printed?', field: 'isPrinted', type: 'boolean', width: 150, enableFiltering: true, enableSorting: true },
            { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
            { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
            { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
        ];
        return columns;
    };

    private closeDeleteConfirmationPopup = () => this.setState({ isDeleteDialogOpen: false, deletingDispatch: undefined });

    private deleteDispatch = async (deleteReason : string) => {
        const deletingDispatch = this.state.deletingDispatch && { ...this.state.deletingDispatch };

        if (deletingDispatch) {
            const oldDispatch = deletingDispatch;

            if (deleteReason !== '' && deleteReason.length >= 200) {
                this.setLoading(true);
                try {
                    const res = await MaterialDispatchHttpService.dispatchDelete(deletingDispatch.id, deleteReason);
                    if (res && res.data) {
                        dataSetMaterialDispatchView(res.data);
                        generalShowSuccessSnackbar('Material Dispatch successfully deleted.');
                    } else {
                        generalShowErrorSnackbar('An error occurred deleting the material dispatch.');
                        dataSetMaterialDispatchView(oldDispatch);
                    }
                } catch (e) {
                    generalShowErrorSnackbar('An error occurred deleting the material dispatch.');
                    dataSetMaterialDispatchView(oldDispatch);
                } finally {
                    this.setLoading(false);
                    this.closeDeleteConfirmationPopup();
                }
            } else {
                generalShowErrorSnackbar('Reason for deleting this dispatch must be at least 200 characters.');
            }
        }
    };

    private editDispatchCancelled = () => {
        this.setState({ editingDispatch: undefined });
    };

    private setLoading = (isLoading : boolean = false) => {
        this.setState({ isLoading });
    };

    private getDate = (type : 'from' | 'to') => {
        switch (type) {
            case 'from':
                return this.state.selectedFromDate.startOf('day').utc().unix() * 1000;
            case 'to':
                return this.state.selectedToDate.endOf('day').utc().unix() * 1000;
        }
    };

    private refreshData = async (noAnnounce ?: boolean) => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;
        try {
            const res = await MaterialDispatchHttpService.getDispatchScreenData(this.getDate('from'), this.getDate('to'), undefined, undefined, this.props.selectedSiteIds, !isIndexedDBAvailable);

            dataSetAllMaterialDispatchViewRelatedData(res.data);
            this.setLoading(false);
            if (!noAnnounce) {
                generalShowSuccessSnackbar('Data Refreshed');
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving material dispatch related data.');
            this.setLoading(false);
        }
    };

    private getDispatchNoteSites = async () => {
        const dispatchFilesSourceSites = await GoogleCloudStorageHttpService.getListData(path);
        if (dispatchFilesSourceSites) {
            this.setState({ dispatchFilesSourceSites: dispatchFilesSourceSites.data });
        }
    };

    private getSites = (props : IDispatchInstructionTableProps) => props.sites;
    private getSelectedSites = (props : IDispatchInstructionTableProps) => props.selectedSiteIds;
    private getDispatchViews = (props : IDispatchInstructionTableProps) => props.materialDispatchViews;
    private getDispatchesFilteredByBarcode = (props : IDispatchInstructionTableProps, state : IDispatchInstructionTableState) => state.dispatchesFilteredByBarcode;

    private getDispatchRows = createSelector(
        [this.getDispatchViews, this.getDispatchesFilteredByBarcode, this.getSelectedSites],
        (materialDispatchViews : Array<IMaterialDispatchView>, dispatchesFilteredByBarcode : Array<IMaterialDispatchView>, selectedSiteIds : Array<number>) => {

            const dispatches = dispatchesFilteredByBarcode.length < 1 ? materialDispatchViews : dispatchesFilteredByBarcode;

            return dispatches
                .filter(x => selectedSiteIds?.some(y => y === x.sourceSiteId || y === x.destinationSiteId));
        },
    );

    private onFilterApplyClick = async () => {
        await this.refreshData(true);
        this.closeFilter();
    };

    private closeFilter = () => {
        this.setState({ filterOpen: false });
    };

    private toggleFilter = () => {
        this.setState({ filterOpen: !this.state.filterOpen });
    };

    private handleDateRangeChange = (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => {
        const selectedFromDate = changedBy === 'start' ? moment(start).startOf('day') : end < start ? moment(end).startOf('day') : moment(start).startOf('day');
        const selectedToDate = changedBy === 'end' ? moment(end).endOf('day') : start > end ? moment(start).endOf('day') : moment(end).endOf('day');
        this.setState({ selectedFromDate, selectedToDate });
    };

    private closeSummary = () => {
        this.setState({ selectedDispatchId: undefined },
            () => navPath(this.props.location?.pathname?.split('/:')[0]));
    };

    private showSummary = (selectedDispatchId : number) => {
        this.setState({ selectedDispatchId });
    };

    private editDispatch = async (row : IMaterialDispatchView) => {
        this.setState({ editingDispatch: row });
    };

    private openDispatchFilesDownloadDialog = async () => {
        this.setLoading(true);
        const dispatchFilesSourceSites = await GoogleCloudStorageHttpService.getListData(path);
        this.setLoading(false);
        if (this.props.selectedSiteIds?.length === 1) {
            const selectedSite = this.props.sites.find(x => x.id === this.props.selectedSiteIds[0]);
            const siteOptionType : IOptionType | undefined = selectedSite ? { label: selectedSite.description , value: selectedSite.id } : undefined;

            if (siteOptionType) {
                this.getDispatchFilesBySite(Number(siteOptionType.value));
            }

            this.setState({ isDispatchFilesDownloadDialogOpen: true, selectedDispatchSite: siteOptionType, dispatchFilesSourceSites: dispatchFilesSourceSites.data });
        } else {
            this.setState({ isDispatchFilesDownloadDialogOpen: true, selectedDispatchSite: undefined, dispatchFilesSourceSites: dispatchFilesSourceSites.data });
        }
    };

    private closeDispatchFilesDownloadDialog = () => {
        this.setState({ isDispatchFilesDownloadDialogOpen: false, selectedDispatchSite: undefined, dispatchFiles: undefined, selectedFilterDate: null });
    };

    public onDispatchFilesSiteChange = (e : CustomChangeEvent, selectedDispatchSite : IOptionType) => {
        this.setState({ selectedDispatchSite });
        this.getDispatchFilesBySite(Number(selectedDispatchSite.value));
    };

    private getDispatchFilesBySite = async (siteId ?: number) => {
        const site = this.props.sites.find(x => x.id === siteId);
        if (site) {
            this.setLoading(true);
            const folderPath = path + `${site.code + '_' + site.guid}/`;
            const dispatchFilesFromSite = await GoogleCloudStorageHttpService.getListData(folderPath);

            if (dispatchFilesFromSite) {
                const allDates = dispatchFilesFromSite.data?.map(x => moment(x.timeCreated).format(DATE_FORMAT_DEFAULT_NO_TIME));
                const dispatchFilesCreatedDates = lodash.uniq(allDates);

                this.setState({ dispatchFiles: dispatchFilesFromSite.data, dispatchFilesCreatedDates });
                this.setLoading(false);
            }
        }
    };

    private dispatchFilesSites = (props : IDispatchInstructionTableProps, state : IDispatchInstructionTableState) => state.dispatchFilesSourceSites;

    private getDispatchSourceSites = createSelector(
        [this.dispatchFilesSites, this.getSites, this.getSelectedSites],
        (dispatchFilesSourceSites : Array<any>, sites : Array<ISite>, selectedSiteIds : Array<number>) => {
            if (!dispatchFilesSourceSites && !sites) return [];

            return sites.filter(x => selectedSiteIds?.some(y => y === x.id) && dispatchFilesSourceSites?.some(y => this.getDispatchSourceSiteName(y.name) === `${x.code}_${x.guid}`) && x.isActive).map((x) => {
                return { label: x.description, value: x.id };
            });
        },
    );

    private getDispatchSourceSiteName = (dispatchNoteSiteName : string) => {
        return dispatchNoteSiteName.split('/')[1];
    };

    private downloadDispatchNote = async (fileName : string, selectedDispatchCode : string, contentType : string) => {
        const selectedSite = this.state.selectedDispatchSite;
        const site = this.props.sites.find(x => x.id === selectedSite?.value);
        if (site) {
            const filePath = path + `${site?.code + '_' + site?.guid}/${selectedDispatchCode}/`;
            try {
                this.setLoading(true);
                const res = await GoogleCloudStorageHttpService.downloadFile(filePath, fileName);

                if (res) {

                    const byteCharacters = atob(res.data);
                    const byteNumbers = lodash.map(byteCharacters, (x, i) => byteCharacters.charCodeAt(i));
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: contentType });
                    if (blob) {
                        FileSaver.saveAs(blob, fileName);
                        generalShowSuccessSnackbar('Dispatch note downloaded successfully.');
                        this.setLoading(false);
                    }
                }
            } catch (e) {
                generalShowErrorSnackbar('Error while downloading dispatch note.');
                this.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Selected dispatch source site not found!');
        }
    };

    private dispatchFiles = (props : IDispatchInstructionTableProps, state : IDispatchInstructionTableState) => state.dispatchFiles;
    private getSearchFilesText = (props : IDispatchInstructionTableProps, state : IDispatchInstructionTableState) => state.searchFilesText;
    private getSelectedFilterDate = (props : IDispatchInstructionTableProps, state : IDispatchInstructionTableState) => state.selectedFilterDate;
    private getSelectedDispatchNotesFolder = (props : IDispatchInstructionTableProps, state : IDispatchInstructionTableState) => state.selectedDispatchNotesFolder;

    private getDispatchFiles = createSelector(
        [this.dispatchFiles, this.getSearchFilesText, this.getSelectedFilterDate],
        (dispatchFiles : Array<any>, searchFilesText : string, selectedFilterDate : string | null) => {
            if (!dispatchFiles) return [];

            let newDispatchFilesList : Array<any> = [];

            dispatchFiles.filter(x => selectedFilterDate ? (moment(x.timeCreated).format(DATE_FORMAT_DEFAULT_NO_TIME) === selectedFilterDate) : (x.name.toLowerCase().includes(searchFilesText.toLowerCase())))
                .forEach((x) => {
                    const index = newDispatchFilesList.findIndex(y => y.name.split('/')[2] === x.name.split('/')[2]);
                    if (index === -1 && x.name.split('/')[2] !== '') {
                        newDispatchFilesList = addArrayElement(newDispatchFilesList, x);
                    }
                });

            return newDispatchFilesList.map(x => this.getDispatchNoteFolders(x));
        },
    );

    private getDispatchNoteFolders = (dispatchNote : any) => {
        const dispatchNoteName : string = dispatchNote.name;
        const dispatchCode = dispatchNoteName.split('/')[2];
        return (
            <Tooltip disableInteractive title={'Select Dispatch'}>
                <Card key={`Dispatch_Notes_Folder_For_${dispatchCode}`} style={{ minHeight: 40 }} className={'h40 w350 m5 aic jcc curp'} onClick={() => this.openDispatchNotePopup(dispatchCode)}>{dispatchCode}</Card>
            </Tooltip>
        );
    };

    private openDispatchNotePopup = (dispatchCode : string) => {
        this.setState({ isDispatchNotePopupOpen: true, selectedDispatchNotesFolder: dispatchCode });
    };

    private closeDispatchNotePopup = () => {
        this.setState({ isDispatchNotePopupOpen: false, selectedDispatchNotesFolder: '' });
    };

    private getDispatchNotes = createSelector(
        [this.dispatchFiles, this.getSelectedDispatchNotesFolder],
        (dispatchFiles : Array<any>, selectedDispatchNotesFolder : string) => {
            if (!dispatchFiles) return [];

            return dispatchFiles.filter(x => x.name.split('/')[2] === selectedDispatchNotesFolder)
                .map(x => this.getDispatchNotesToBeDownloaded(x));
        },
    );

    private getDispatchNotesToBeDownloaded = (dispatchNote : any) => {
        const dispatchNoteName : string = dispatchNote.name;
        const selectedDispatchCode = dispatchNoteName.split('/')[2];
        const name = dispatchNoteName.split('/')[3];
        return (
            <Tooltip disableInteractive key={`Dispatch_Note_${name}`} title={'Download Dispatch File?'}>
                <Card key={`Dispatch_Note_${name}`} style={{ maxHeight: 40, minHeight: 40 }} className={'h40 w350 m5 aic jcc curp'} onClick={() => this.downloadDispatchNote(name, selectedDispatchCode, dispatchNote.contentType)}>{name}</Card>
            </Tooltip>
        );
    };

    private getRights = (props : IDispatchInstructionTableProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IDispatchInstructionTableProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    private hasDispatchCorrectionRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code === 'DISPATCH_CORRECTION_ADMIN'));

    private onSearchFileTextChange = (event : CustomChangeEvent) => {
        this.setState({ searchFilesText: event.currentTarget.value });
    };

    public onFilterDateChange = (selectedDate : moment.Moment) => {
        this.setState({ selectedFilterDate: moment(selectedDate).format(DATE_FORMAT_DEFAULT_NO_TIME) });
    };

    private clearDatePickerInput = () => {
        this.setState({ selectedFilterDate: null });
    };

    private disableDay = (day : any) => {
        const datesSubmitted = this.state.dispatchFilesCreatedDates;

        return !(datesSubmitted.length > 0 && datesSubmitted?.some(x => x === moment(day).format(DATE_FORMAT_DEFAULT_NO_TIME)));
    };

    private openDispatchCorrectionDialog = async () => {
        this.setState({ isDispatchCorrectionDialogOpen: true });
    };

    private closeDispatchCorrectionDialog = () => {
        this.setState({ isDispatchCorrectionDialogOpen: false });
    };

    public render() {
        return (
            <Screen isPadded={false} isLoading={this.state.isLoading} isScrollable={false}>
                <div className={'fdc hfill'}>
                    <div className={'fdr mt10 mb10'}>
                        <Tooltip title={`${this.props.selectedSiteIds?.length < 1 ? 'Please select specific site' : ''}`}>
                            <PillButton
                                text={'Download Dispatch Files'}
                                color={'secondary'}
                                className={'w250 cpd fw500 mt10 ml20'}
                                onClick={() => this.openDispatchFilesDownloadDialog()}
                                disabled={ true }// this.props.selectedSiteIds?.length < 1}
                            />
                        </Tooltip>
                        <div className={'flx1'}/>
                        <TransactionFilter className={'pr20'} selectedFromDate={this.state.selectedFromDate} selectedToDate={this.state.selectedToDate} handleDateRangeChange={this.handleDateRangeChange} onApplyClick={this.onFilterApplyClick} />
                    </div>
                    <Paper className={'mr20 mt10 mb10 ml20 hfill oyh'}>
                        <CustomTable<IMaterialDispatchView>
                            enableSorting
                            enableFiltering
                            enableTotalRow
                            enableRefresh
                            enableCSVExport
                            csvExportPathname={this.props.location.pathname}
                            enableDetails={true}
                            // enableEditing={(row : IMaterialDispatchView) => row.status !== DISPATCH_INSTRUCTION_STATUSSES[5] && row.status !== DISPATCH_INSTRUCTION_STATUSSES[6] && this.hasEditingRight(this.props)}
                            enableDeleting={(row : IMaterialDispatchView) => row.isActive
                                && this.hasEditingRight(this.props)
                                && ((row.status !== DISPATCH_INSTRUCTION_STATUSSES[5] && row.status !== DISPATCH_INSTRUCTION_STATUSSES[6]) || this.hasDispatchCorrectionRight(this.props))}
                            detailFunction={(row : IMaterialDispatchView) => navMaterialDispatch(row.id)}
                            detailIcon={'info'}
                            detailTooltip={'Material Dispatch Summary'}
                            enablePagination
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={300}
                            disableRefreshButton={this.state.isLoading}
                            refreshFunction={this.refreshData}
                            editFunction={(row : IMaterialDispatchView) => this.editDispatch(row)}
                            deleteFunction={(row : IMaterialDispatchView) => this.setState({ isDeleteDialogOpen: true, deletingDispatch: row })}
                            columns={this.state.collapseDraftColumn ? this.state.columns.slice(1) : this.state.columns}
                            rows={this.getDispatchRows(this.props, this.state)}
                            initialSortOrder={[{ columnName: 'dispatchCode_Dispatch Code', direction: 'desc' }]}
                            isActive={(row : IMaterialDispatchView) => row.isActive}
                        />
                    </Paper>
                    <div className={'fdr wfill jcc mr20 pr10 pb20'}>
                        <div className={'flx1'}/>
                        <PillButton
                            className={'pl10 pr10 mr10 h35 w200 reducedPillButtonShadow'}
                            text={'DISPATCH CORRECTIONS'}
                            color={'secondary'}
                            disabled
                            onClick={this.openDispatchCorrectionDialog}
                        />
                    </div>
                    {/* Dispatch Edit Dialog */}
                    {/* { !!this.state.editingDispatch &&
                        <DispatchInstructionEdit
                            isLoading={this.state.isLoading}
                            setLoading={this.setLoading}
                            selectedDispatchView={this.state.editingDispatch}
                            editDispatchCancelled={this.editDispatchCancelled}
                            refreshData={() => this.refreshData(true)}
                        />
                    } */}
                    {!!this.state.isDeleteDialogOpen &&
                        <DeleteConfirmationDialog
                            isLoading={this.state.isLoading}
                            onSubmit={this.deleteDispatch}
                            onclose={this.closeDeleteConfirmationPopup}
                            isOpen={!!this.state.isDeleteDialogOpen}
                            title={'Dispatch Instruction'}/>
                    }
                    { !!this.state.selectedDispatchId &&
                        <PackmanDialog title={'Material Dispatch Summary'} isInfo isOpen={!!this.state.selectedDispatchId} onClose={this.closeSummary} fullScreen>
                            {this.state.selectedDispatchId && <DispatchSummary selectedDispatchId={this.state.selectedDispatchId} />}
                        </PackmanDialog>
                    }
                    {/* Dispatch Files Download Dialog */}
                    {/* { !!this.state.isDispatchFilesDownloadDialogOpen &&
                        <PackmanDialog
                            title={'Dispatch Files'}
                            isInfo
                            maxWidth={'md'}
                            fullWidth
                            isLoading={this.state.isLoading}
                            isOpen={this.state.isDispatchFilesDownloadDialogOpen}
                            onClose={this.closeDispatchFilesDownloadDialog}>
                            <Screen isPadded={false} isScrollable={false}>
                                <div className={'fdc p10'}>
                                    <div className='fdc'>
                                        <AutocompleteSelect
                                            name={'sites'}
                                            label={'Sites'}
                                            options={this.getDispatchSourceSites(this.props, this.state)}
                                            value={this.state.selectedDispatchSite}
                                            onChange={this.onDispatchFilesSiteChange}
                                            className={'mt5 mb5 w270'}
                                            disabled={this.props.selectedSiteIds?.length === 1}
                                        />
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                className={'w250 mt5 mb5 ml10 mr10'}
                                                label={'Date Submitted'}
                                                format={'DD/MM/YYYY'}
                                                disabled={this.state.isLoading || !this.state.selectedDispatchSite || !this.state.dispatchFiles || this.state.dispatchFiles?.length < 1}
                                                value={this.state.selectedFilterDate ? moment(this.state.selectedFilterDate, DATE_FORMAT_DEFAULT) : null}
                                                onChange={this.onFilterDateChange}
                                                disableFuture
                                                slotProps={{
                                                    textField: { variant: 'standard' },
                                                    actionBar: {
                                                        actions: ['clear'],
                                                        onClick: this.clearDatePickerInput,
                                                    },
                                                }}
                                                shouldDisableDate={this.disableDay}
                                            />
                                        </LocalizationProvider>
                                        <TextField
                                            className={'mt5 mb5 ml10 mr10 w250'}
                                            label={'Search'}
                                            variant={'standard'}
                                            placeholder={'Search...'}
                                            onChange={this.onSearchFileTextChange}
                                            value={this.state.searchFilesText}
                                            disabled={!this.state.selectedDispatchSite || this.state.isLoading}
                                        />
                                    </div>
                                    <div className='fdc'>
                                        <div style={{ maxWidth: 500, wordWrap: 'normal' }}>{'Please note: All dispatch reports here are copies of the ones printed from the Packhouse. If there is a change in Dispatch Note details, please ask the Packhouse to reprint the Dispatch and it will become available here.'}</div>
                                        <div style={{ maxHeight: 600, minHeight: 600 }} className={'fdc flx1 m5 oya'}>
                                            {this.state.selectedDispatchSite ?
                                                this.getDispatchFiles(this.props, this.state)
                                                :
                                                'No Site Selected'
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Screen>
                        </PackmanDialog >
                    } */}
                    {/* Dispatch Files popup */}
                    {/* { !!this.state.isDispatchNotePopupOpen &&
                        <PackmanDialog
                            title={'Download Dispatch Files'}
                            isInfo
                            isLoading={this.state.isLoading}
                            isOpen={this.state.isDispatchNotePopupOpen}
                            onClose={this.closeDispatchNotePopup}>
                            <Screen isPadded={false} isScrollable={false}>
                                <div style={{ maxHeight: 500, height: 500 }} className={'w400 aic fdc p10 oya'}>
                                    {(this.state.dispatchFiles && this.state.dispatchFiles?.filter(x => x.name.toLowerCase().includes(this.state.selectedDispatchNotesFolder.toLowerCase())).length > 0) &&
                                            this.getDispatchNotes(this.props, this.state)
                                    }
                                </div>
                            </Screen>
                        </PackmanDialog >
                    } */}
                    {/* { !!this.state.isDispatchCorrectionDialogOpen &&
                        <PackmanDialog
                            title={'Dispatch Correction'}
                            maxWidth={'lg'}
                            isInfo
                            isLoading={this.state.isLoading}
                            isOpen={this.state.isDispatchCorrectionDialogOpen}
                            onClose={this.closeDispatchCorrectionDialog}>
                            <DispatchCorrectionDialog
                                isLoading={this.state.isLoading}
                                setLoading={this.setLoading}
                                closeDispatchCorrectionDialog={this.closeDispatchCorrectionDialog}
                                hasDispatchCorrectionRight={this.hasDispatchCorrectionRight(this.props)}
                            />
                        </PackmanDialog >
                    } */}
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        dispatchInstructions: state.data.dispatchInstructions,
        materialDispatchViews: state.data.materialDispatchViews,
        organizations: state.masterData.organizations,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        sizes: state.masterData.sizes,
        colours: state.masterData.colours,
        grades: state.masterData.grades,
        sites: state.masterData.sites,
        farms: state.masterData.farms,
        compliances: state.data.compliances,
        stockData: state.data.stocks,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
        selectedLoadDate: state.data.dashboardDispatchSelectedLoadDate,
        selectedDispatchIndex: state.data.dashboardDispatchSelectedDispatchInstructionId,
        showOnlyMyDispatches: state.data.dashboardShowOnlyMyDispatches,
        selectedExporterOrganization: state.data.selectedExporterOrganization,
        trips: state.data.trips,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    {
        dataSetDashboardDispatchSelectedLoadDate,
        dataSetDashboardDispatchSelectedDispatchInstructionId,
        dataSetDashboardDispatchInstructionLines,
        dataSetStocks,
        dataSetDispatchInstructions,
        dataSetDispatchViews,
    },
    dispatcher,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DispatchInstructionTable);

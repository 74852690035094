import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IPalletBaseType } from '../../../@types/model/masterData/palletBaseType/palletBaseType';

export default class PalletBaseTypeHttpService {
    public static getPalletBaseTypeData() : AxiosPromise<Array<IPalletBaseType>> {
        return axios.get(`${PACKMAN_API_URL}PalletBaseType/GetAllWeb`);
    }

    public static addOrUpdatePalletBaseType(data : IPalletBaseType) : AxiosPromise<IPalletBaseType> {
        return axios.post(`${PACKMAN_API_URL}PalletBaseType/PostPalletBaseType`, data);
    }

    public static deletePalletBaseType(palletBaseTypeId : number) : AxiosPromise<IPalletBaseType> {
        return axios.delete(`${PACKMAN_API_URL}PalletBaseType/Delete`, {
            params : { palletBaseTypeId },
        });
    }
}

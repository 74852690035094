import * as React from 'react';
import { Map, Marker, Popup } from 'react-leaflet';
import { LatLng } from 'leaflet';
import Screen from '../../../components/Screen';
import { connect } from 'react-redux';
import { DispatchCall, IRootState, RootAction } from '../../../@types/redux';
import { ISite } from '../../../@types/model/masterData/site/site';
import StandardLayerControl from '../../../components/map/StandardLayerControl';
import { createSelector } from 'reselect';
import { IOrganization } from '../../../@types/model/masterData/organization/organization';
import SiteHttpService from '../../../services/http/masterData/siteHttpService';
import { generalShowErrorSnackbar } from '../../../store/general/Functions';
import InspectionPointHttpService from '../../../services/http/masterData/inspectionPointHttpService';
import { dataSetSites, dataSetInspectionPoints } from '../../../store/masterData/Actions';
import { IInspectionPoint } from '../../../@types/model/masterData/inspectionPoint/inspectionPoint';
import { bindActionCreators, Dispatch } from 'redux';
import { syncMasterData } from '../../../services/masterDataSyncService';

interface ISiteMapProps {
    dataSetSites : DispatchCall<Array<ISite>>;
    dataSetInspectionPoints : DispatchCall<Array<IInspectionPoint>>;
    sites : Array<ISite>;
    selectedOrganizationIds : Array<number>;
    selectedSiteIds : Array<number>;
    organizations : Array<IOrganization>;
}

interface ISiteMapState {
    isLoading : boolean;
    isSiteDetailPopupOpen : boolean;
}

class SiteMap extends React.Component<ISiteMapProps, ISiteMapState> {
    public mapRef : Map;

    public centerPosition = new LatLng(-28.4792625, 24.6727135);
    public mapZoom = 6;

    constructor(props : ISiteMapProps) {
        super(props);

        this.state = {
            isLoading: false,
            isSiteDetailPopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await SiteHttpService.getSiteData();

                this.props.dataSetSites(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading sites.');
                this.setLoading(false);
            }

            try {
                const res = await InspectionPointHttpService.getInspectionPointData();

                this.props.dataSetInspectionPoints(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading inpsection points.');
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public setMapRef = (ref : Map) => {
        this.mapRef = ref;
    };

    private getSites = (props : ISiteMapProps) => props.sites;
    private getSelectedOrganizationIds = (props : ISiteMapProps) => props.selectedOrganizationIds;
    private getSelectedSites = (props : ISiteMapProps) => props.selectedSiteIds;

    private getSitesLatLongs = createSelector(
        [this.getSites, this.getSelectedOrganizationIds, this.getSelectedSites],
        (sites, selectedOrganizationIds, selectedSiteIds) => {
            return sites.filter(x => x.isActive
                && x.latitude
                && x.longitude
                && x.organizationIds.some(y => selectedOrganizationIds?.some(z => z === y))
                && selectedSiteIds?.some(z => z === x.id)).map((x) => {
                return (
                    <Marker key={`site_id_#${x.id}`} title={`${x.code} - ${x.description}`} position={new LatLng(Number(x.latitude), Number(x.longitude))}>
                        <Popup>
                            <div className={'mb3'}>{`Title: ${x.code} - ${x.description}`}</div>
                            <div className={`${!x.shortDescription ? 'dn' : 'mb3'}`}>{`Short Description: ${x.shortDescription}`}</div>
                            <div className={`${!x.telNumber ? 'dn' : 'mb3'}`}>{`Tel: ${x.telNumber}`}</div>
                            <div className={`${!x.faxNumber ? 'dn' : 'mb3'}`}>{`Fax: ${x.faxNumber}`}</div>
                            <div className={`${!x.packhouseDateCode ? 'dn' : 'mb3'}`}>{`Date Code: ${x.packhouseDateCode}`}</div>
                            <div className={`${!x.address ? 'dn' : 'fdr mb3'}`}>
                                <div className={'pr5'}>{'Address:'}</div>
                                <div>{x.address}</div>
                            </div>
                            <div className={'fdr mb3'}>
                                <div className={'pr5'}>{'Packhouse?:'}</div>
                                <div>{x.isPackhouse ? 'Yes' : 'No'}</div>
                            </div>
                            <div className={'fdr mb3'}>
                                <div className={'pr5'}>{'Delivery Point?:'}</div>
                                <div>{x.isDeliveryPoint ? 'Yes' : 'No'}</div>
                            </div>
                            <div className={'fdr mb3'}>
                                <div className={'pr5'}>{'BRC Compliant?:'}</div>
                                <div>{x.isBRCCompliant ? 'Yes' : 'No'}</div>
                            </div>
                            <div className={'fdr mb3'}>
                                <div className={'pr5'}>{'External?:'}</div>
                                <div>{x.isExternal ? 'Yes' : 'No'}</div>
                            </div>
                        </Popup>
                    </Marker>
                );
            });
        },
    );

    public render() {
        return (
            <Screen isLoading={this.state.isLoading} isScrollable={false}>
                <div className='bcg1 PaperBorder p10 hfill wfill asc fdc aic jcc oln posr'>
                    <Map center={this.centerPosition} zoom={this.mapZoom} maxZoom={18} ref={this.setMapRef} className={'flx1 bcw'} preferCanvas>
                        <StandardLayerControl position={'topright'} />
                        {this.getSitesLatLongs(this.props)}
                    </Map>
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        sites: state.masterData.sites,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
        organizations: state.masterData.organizations,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetSites, dataSetInspectionPoints }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SiteMap);

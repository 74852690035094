import React from 'react';
import FormControl from '@mui/material/FormControl';
import { FormControlProps } from '@mui/material/FormControl/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { OutlinedInputProps } from '@mui/material/OutlinedInput/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import { CustomChangeEvent, CustomFocusEvent } from '../../@types/helper';
import Input from '@mui/material/Input';

export interface ITextInputProps {
    label : string | number;
    name : string;
    id ?: string;
    formControlProps ?: FormControlProps;
    inputProps ?: OutlinedInputProps;
    onChange ?: (e : CustomChangeEvent) => void;
    onBlur ?: (e : CustomFocusEvent) => void;
    className ?: string;
    type ?: string;
    contentEditable ?: boolean;
    disabled ?: boolean;
    error ?: string;
    placeholder ?: string;
    value : any; // string | number;
    selectOnFocus ?: boolean;
    startAdornment ?: React.ReactNode;
    endAdornment ?: React.ReactNode;
    multiline ?: boolean;
    rows ?: number;
    transparentBackground ?: boolean;
    autoFocus ?: boolean;
    errorMargin ?: boolean;
}

interface ITextInputState {
    labelWidth : number;
}

export default class TextInput extends React.PureComponent<ITextInputProps, ITextInputState> {
    public inputRef : HTMLInputElement;
    public labelRef : HTMLLabelElement;
    public inputProps : InputBaseComponentProps;

    constructor(props : ITextInputProps) {
        super(props);

        this.state = {
            labelWidth: 0,
        };

        this.inputProps = {
            onFocus: this.onFocus,
            ref: this.setInputRef,
        };
    }

    public setInputRef = (ref : HTMLInputElement) => {
        this.inputRef = ref;
    };

    public setLabelRef = (ref : HTMLLabelElement) => {
        this.labelRef = ref;

        if (ref) {
            this.setState({ labelWidth: ref.offsetWidth });
        }
    };

    public onChange = (e : CustomChangeEvent) => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    };

    public onBlur = (e : CustomFocusEvent) => {
        if (this.props.onBlur) this.props.onBlur(e);
    };

    public onFocus = () => {
        if (this.inputRef && this.props.selectOnFocus) {
            this.inputRef.select();
        }
    };

    public render() {
        const {
            formControlProps,
            inputProps,
            label,
            className,
            type,
            contentEditable,
            disabled,
            error,
            id,
            name,
            placeholder,
            value,
            multiline = false,
            rows = 1,
            transparentBackground = true,
            errorMargin,
        } = this.props;

        return (
            <div className={`pl10 pr10 fdc ais jcfs ${className || ''}`}>
                <FormControl  disabled={disabled} variant={'standard'} className={'flx1 m0'} {...formControlProps}>
                    {label && <InputLabel htmlFor={name} ref={this.setLabelRef}>{label}</InputLabel>}
                    <Input
                        error={!!error}
                        id={id ?? name}
                        type={type}
                        className={`${transparentBackground ? '' : 'bcw'}`}
                        multiline={multiline}
                        rows={rows}
                        disabled={disabled}
                        value={value}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        contentEditable={contentEditable}
                        fullWidth
                        placeholder={placeholder}
                        inputProps={this.inputProps}
                        {...inputProps}
                        startAdornment={this.props.startAdornment}
                        endAdornment={this.props.endAdornment}
                        autoFocus={this.props.autoFocus}
                        autoComplete={''}
                    />
                    <FormHelperText className={`mt4 ${errorMargin ? 'h20' : ''}`} error>{error || ''}</FormHelperText>
                </FormControl>
            </div>
        );
    }
}

import * as Yup from 'yup';
import { ICommodityCondition } from './commodityCondition';
import { IOptionType } from '../../../helper';
import { ICommodity } from '../commodity/commodity';

export interface ICommodityConditionFormValues {
    id : number;
    guid : string;
    isActive : boolean;

    condition : string;
    selectedCommodities : Array<IOptionType>;
    isMasterView : boolean;
}

export class CommodityConditionFormValues implements ICommodityConditionFormValues {
    public id : number;
    public guid : string;
    public isActive : boolean;

    public condition : string;
    public selectedCommodities : Array<IOptionType>;
    public isMasterView : boolean;

    public constructor(
        commodityCondition ?: ICommodityCondition,
        isMasterView ?: boolean,
        commodityData ?: Array<ICommodity>,
    ) {
        this.initializeDefaultValues();
        this.isMasterView = isMasterView ?? false;

        if (commodityCondition) {
            this.id = commodityCondition.id;
            this.guid = commodityCondition.guid;
            this.isActive = commodityCondition.isActive;

            this.condition = commodityCondition.condition;

            const selectedCommodities = commodityData?.filter(x => x.conditions?.some(y => y.conditionId === commodityCondition.id));
            this.selectedCommodities = selectedCommodities?.map(x => ({ label: `(${x.code}) - ${x.name}`, value: x.id })) ?? [];
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.isActive = true;
        this.selectedCommodities = [],
        this.condition = '';
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        condition: Yup.string().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

import * as React from 'react';
import CustomTable from '../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetCommodities, dataSetCommodityConditions } from '../../../store/masterData/Actions';
import { formatDateTime, upsertArrayElement } from '../../../services/appFunctionsService';
import { IOrganization } from '../../../@types/model/masterData/organization/organization';
import { ICommodity, Commodity } from '../../../@types/model/masterData/commodity/commodity';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import CommodityHttpService from '../../../services/http/masterData/commodityHttpService';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikActions } from 'formik';
import { CommodityFormValues, ICommodityFormValues } from '../../../@types/model/masterData/commodity/commodityFormValues';
import CommodityForm from './form/CommodityForm';
import { createSelector } from 'reselect';
import { ICommodityCondition } from '../../../@types/model/masterData/commodityCondition/commodityCondition';
import CommodityConditionHttpService from '../../../services/http/masterData/commodityConditionHttpService';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { setCommodityMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface ICommodityScreenProps extends RouteComponentProps {
    dataSetCommodities : DispatchCall<Array<ICommodity>>;
    dataSetCommodityConditions : DispatchCall<Array<ICommodityCondition>>;
    commodities : Array<ICommodity>;
    selectedOrganizationIds : Array<number>;
    organizations : Array<IOrganization>;
    commodityConditions : Array<ICommodityCondition>;
    auth : IAuthState;
}

interface ICommodityScreenState {
    rows : Array<ICommodity>;
    isLoading : boolean;
    selectedCommodity ?: ICommodity;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingCommodity ?: ICommodity;
}

class CommodityScreen extends React.Component<ICommodityScreenProps, ICommodityScreenState> {
    constructor(props : ICommodityScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedCommodity: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidUpdate = (prevProps : ICommodityScreenProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            /* prop changes go here */
            if ((prevProps.commodities !== nextProps.commodities)
                || (prevProps.selectedOrganizationIds !== nextProps.selectedOrganizationIds)) {
                this.setState({ rows: this.getRows() });
            }
        }
    };

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }
        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await CommodityHttpService.getCommodityData();
                const res2 = await CommodityConditionHttpService.getCommodityConditionData();

                this.props.dataSetCommodities(res.data);
                this.props.dataSetCommodityConditions(res2.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading commodities.');
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private formatOrganizations = (organizationIds : Array<number>) => this.props.organizations
        .filter(x => organizationIds.some(y => y === x.id))
        .map(x => x.code).toString().replace(/,/g, ', ');

    private getRows = () => {
        let rows : Array<ICommodity> = [];
        if (this.hasEditingRight(this.props)) {
            rows = this.props.commodities.filter(x => x.organizationIds.some(y => this.props.selectedOrganizationIds?.some(z => z === y)) || x.organizationIds.length < 1);
        } else {
            rows = this.props.commodities.filter(x => x.organizationIds.some(y => this.props.selectedOrganizationIds?.some(z => z === y)));
        }
        return rows;
    };

    public onSubmit = async (values : ICommodityFormValues) => {
        this.setLoading(true);

        try {
            const res = await CommodityHttpService.addOrUpdateCommodity(new Commodity(values));

            const newCommodityList = upsertArrayElement(this.props.commodities, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetCommodities(newCommodityList);
            await setCommodityMasterDataIndexedDB(newCommodityList);

            if (this.state.selectedCommodity) {
                generalShowSuccessSnackbar('Commodity updated successfully.');
            } else {
                generalShowSuccessSnackbar('Commodity added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating Commodity data.');
        } finally {
            this.setLoading(false);
        }
    };

    public onReset = async (formValues : ICommodityFormValues, formikActions : FormikActions<ICommodityFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    private openDeleteConfirmationPopup = (commodity : ICommodity) => {
        this.setState({ isDeletePopupOpen: true, deletingCommodity: commodity });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingCommodity: undefined });
    };

    private removeCommodity = async () => {
        const newCommodity = this.state.deletingCommodity;
        if (newCommodity) {
            newCommodity.isActive = false;
            this.setLoading(true);

            try {
                const res = await CommodityHttpService.deleteCommodity(newCommodity.id);

                const newCommodityList = upsertArrayElement(this.props.commodities, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetCommodities(newCommodityList);
                await setCommodityMasterDataIndexedDB(newCommodityList);

                generalShowSuccessSnackbar('Commodity updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting commodity.');
                newCommodity.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    public getSelectedCommodity = (props : ICommodityScreenProps, state : ICommodityScreenState) => state.selectedCommodity;
    public getOrganizations = (props : ICommodityScreenProps) => props.organizations;
    public getCommodityConditions = (props : ICommodityScreenProps) => props.commodityConditions;

    public getInitialFormValues = createSelector(
        [this.getSelectedCommodity, this.getOrganizations, this.getCommodityConditions],
        (commodity : ICommodity, organizations : Array<IOrganization>, commodityConditions : Array<ICommodityCondition>) => {
            return new CommodityFormValues(commodity, organizations, commodityConditions);
        },
    );

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedCommodity: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    private onCommodityEdit = (row : ICommodity) => {
        this.setState({
            isDialogOpen: true,
            selectedCommodity: row,
        });
    };

    private getRights = (props : ICommodityScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : ICommodityScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.getRows();
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Commodity'
                    isEdit={!!this.state.selectedCommodity}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}
                    fullScreen={true}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={CommodityFormValues.formSchema}
                        component={CommodityForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<ICommodity>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.onCommodityEdit}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(commodity : ICommodity) => commodity.isActive && this.hasEditingRight(this.props) && !commodity.isImported}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            fitWidthToPage
                            enableFiltering
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Export Code', field: 'exportCode', enableFiltering: true, enableSorting: true },
                                { title: 'Organizations', field: 'organizationIds', formatFunction: this.formatOrganizations, width: 200, enableFiltering: true, enableSorting: true },
                                { title: 'Stone Fruit?', field: 'isStoneFruit', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Phyto Compliant?', field: 'isPhytoCompliant', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Temperature', field: 'temperature', enableFiltering: true, enableSorting: true },
                                { title: 'Temperature Regime', field: 'temperatureRegime', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Imported?', field: 'isImported', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : ICommodity) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Commodity'}
                    open={this.state.isDeletePopupOpen}
                    message={'Are you sure you want to delete this commodity?'}
                    onOkClicked={this.removeCommodity}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        commodities: state.masterData.commodities,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        organizations: state.masterData.organizations,
        commodityConditions: state.masterData.commodityConditions,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetCommodities, dataSetCommodityConditions }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CommodityScreen);

import * as React from 'react';
import { Divider, IconButton, Icon } from '@mui/material';
import { connect } from 'react-redux';
import { IDashboardDispatchInstructionLine } from '../../@types/model/dispatch/dashboardDispatchInstructionLine';
import { IDispatchInstructionLine } from '../../@types/model/dispatch/dispatchInstructionLine';
import { getState } from '../../store/Index';
import { ISite } from '../../@types/model/masterData/site/site';
import { IAuthState, IRootState } from '../../@types/redux';
import PillButton from '../../components/input/PillButton';
import { generateBarcodesFromDispatch } from '../../services/compliance/documentService';
import { IDispatchInstruction } from '../../@types/model/dispatch/dispatchInstruction';
import { dataSetDispatchInfoData, dataSetDispatchInstruction, mapDashboardDispatchLines } from '../../store/data/Functions';
import DispatchHttpService from '../../services/http/dispatch/dispatchHttpService';
import { IDispatchView } from '../../@types/model/dispatch/dispatchView';
import { generalShowErrorSnackbar } from '../../store/general/Functions';
import { ITemperatureUnit } from '../../@types/model/dispatch/temperatureUnit';
import { ITrip } from '../../@types/model/dispatch/trip';
import { ICarrier } from '../../@types/model/masterData/carrier/carrier';
import GetAppIcon from '@mui/icons-material/GetApp';
import PopupOptionButton from '../../components/button/PopupOptionButton';
import FileSaver from 'file-saver';
import { PackmanLink } from '../../components/link/packmanLink';
import { createSelector } from 'reselect';
import CustomTooltip from '../../components/tooltip/tooltip';
import { syncMasterData } from '../../services/masterDataSyncService';
import { getIconLocation } from '../../services/iconHelperService';

interface IDispatchInstructionInfoPopupProps {
    editDispatchLine : (line : IDispatchInstructionLine) => void;
    status : string;
    sites : Array<ISite>;
    selectedDispatchView : IDispatchView;
    dashboardDispatchInstructionLines : Array<IDashboardDispatchInstructionLine>;
    setLoading : (isLoading ?: boolean) => void;
    trips : Array<ITrip>;
    carriers : Array<ICarrier>;
    auth : IAuthState;
}

interface IDispatchInstructionInfoPopupState {
    dispatchLines : Array<IDashboardDispatchInstructionLine>;
    selectedDispatch ?: IDispatchInstruction;
}

const getOrganizationCode = (id : number) => {
    const state = getState();
    return state.masterData.organizations.find(x => x.id === id)?.code;
};

const getCommodityCode = (id : number) => {
    const state = getState();
    return state.masterData.commodities.find(x => x.id === id)?.code;
};

const getVarietyCode = (id : number) => {
    const state = getState();
    return state.masterData.varieties.find(x => x.id === id)?.code;
};

const getMarketCode = (id : number) => {
    const state = getState();
    return state.masterData.markets.find(x => x.id === id)?.code;
};

const getPackCode = (id : number) => {
    const state = getState();
    return state.masterData.packs.find(x => x.id === id)?.code;
};

const getSizeCode = (id : number) => {
    const state = getState();
    return state.masterData.sizes.find(x => x.id === id)?.code;
};

const getColourCode = (id ?: number) => {
    const state = getState();
    return state.masterData.colours.find(x => x.id === id)?.code;
};

const getGradeCode = (id : number) => {
    const state = getState();
    return state.masterData.grades.find(x => x.id === id)?.code;
};

const getStockBarcode = (stockId : number) => {
    const state = getState();
    const stock = state.data.stocks.find(x => x.id === stockId);
    return stock?.barcode || '';
};

const getStockCartons = (stockId : number) => {
    const state = getState();
    const stock = state.data.stocks.find(x => x.id === stockId);
    return stock?.cartons || 0;
};

class DispatchInstructionInfoPopup extends React.Component<IDispatchInstructionInfoPopupProps, IDispatchInstructionInfoPopupState> {
    constructor(props : IDispatchInstructionInfoPopupProps) {
        super(props);

        this.state = {
            dispatchLines: [],
        };
    }

    public componentDidMount = async () => {
        try {
            this.props.setLoading(true);
            // checks if indexedDB is available.
            const isIndexedDBAvailable = !!self.indexedDB ? true : false;

            if (isIndexedDBAvailable) {
                await syncMasterData(false);
            }
            const res = await DispatchHttpService.getDispatchInfoData(this.props.selectedDispatchView.id, !isIndexedDBAvailable);

            if (res) {
                dataSetDispatchInfoData(res.data);
                const showInfoDispatch = res.data.dispatch;
                this.setState({ selectedDispatch: showInfoDispatch });
                this.props.setLoading(false);
            }
        } catch (e) {
            generalShowErrorSnackbar('Failed to load dispatch info data');
            this.props.setLoading(false);
        }
    };

    private generateBarcodeToPDF = () => {
        if (this.state.selectedDispatch) {
            const sites = this.props.sites;
            const siteCode = sites.find(x => x.id === this.state.selectedDispatch?.sourceSiteId)?.code;
            generateBarcodesFromDispatch(this.state.selectedDispatch, siteCode);
        }
    };

    private getRights = (props : IDispatchInstructionInfoPopupProps) => props.auth?.session?.user?.rights || [];

    private hasGenerateBarcodeRight = createSelector(
        [this.getRights],
        rights => rights.some(x => x.isActive && x.code === 'DISPATCH_GENERATE_BARCODES'));

    private getCarrierName = (carrierId ?: number) => {
        const carrier = this.props.carriers.find(x => x.id === carrierId);
        return carrier?.name;
    };

    private onDispatchCSVExport = async (exportedDispatch : IDispatchInstruction) => {
        try {
            this.props.setLoading(true);
            const csvResult = await DispatchHttpService.getTeraokaCsv(exportedDispatch.id);
            const res = await DispatchHttpService.setTeraokaDownloadedFlag({ dispatchId: exportedDispatch.id });

            if (csvResult && csvResult.data) {
                const blob = new Blob([csvResult.data.csvData], { type: 'text/csv;charset=utf-8;' });
                FileSaver.saveAs(blob, csvResult.data.fileName);

                if (res && res.data) {
                    dataSetDispatchInstruction(res.data);
                    this.props.setLoading(false);
                    return;
                } else {
                    generalShowErrorSnackbar('An error occurred setting the Teraoka CSV download flag.');
                    this.props.setLoading(false);
                    return;
                }
            } else {
                generalShowErrorSnackbar('An error occurred while fetching Teraoka CSV');
                this.props.setLoading(false);
                return;
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred downloading the Teraoka CSV.');
        }
    };

    private getDevices = (tempUnits : Array<ITemperatureUnit>) => tempUnits.filter(x => x.isActive).map(x => x.deviceNumber).toString().replace(/,/g, ', ');

    public render() {
        const trip = this.props.trips.find(x => x.id === this.props.selectedDispatchView.tripId);
        const dashboardLines = this.props.dashboardDispatchInstructionLines.filter(x => x.dispatchId === this.state.selectedDispatch?.id);
        if (!dashboardLines || dashboardLines.length === 0) {
            return <div className={'h200'}/>;
        }
        return (
            <div style={{ maxHeight: 700 }} className={'fdc hfill'}>
                {!!trip ?
                    <div className={`${this.props.status === 'Incoming' ? 'fdc' : 'dn'}`}>
                        <div className={'pl10 pr10 fs12 fw500'}>{`Trip: ${trip?.id}`}</div>
                        <div className={'fdr'} >
                            <div className={'pl10 pr10 fs12'}>Description:</div>
                            <div className={'pr10 fs12 fw500'}>{trip?.description}</div>
                        </div>
                        <div className={'fdr'} >
                            <div className={'pl10 pr10 fs12'}>Driver:</div>
                            <div className={'pr10 fs12 fw500'}>{trip?.driver}</div>
                        </div>
                        <div className={'fdr'} >
                            <div className={'pl10 pr10 fs12'}>Container:</div>
                            <div className={'pr10 fs12 fw500'}>{trip?.container}</div>
                        </div>
                        <div className={'fdr'} >
                            <div className={'pl10 pr10 fs12'}>Container Tare Weight:</div>
                            <div className={'pr10 fs12 fw500'}>{trip?.containerTareWeight}</div>
                        </div>
                        <div className={'fdr'} >
                            <div className={'pl10 pr10 fs12'}>Reg Nr:</div>
                            <div className={'pr10 fs12 fw500'}>{trip?.registrationNumber}</div>
                        </div>
                        <div className={'fdr'} >
                            <div className={'pl10 pr10 fs12'}>Carrier:</div>
                            <div className={'pr10 fs12 fw500'}>{this.getCarrierName(trip?.carrierId ?? 0)}</div>
                        </div>
                        <div className={'fdr mb5'} >
                            <div className={'pl10 pr10 fs12'}>Devices:</div>
                            <div className={'pr10 fs12 fw500'}>{this.getDevices(trip?.temperatureUnits ?? [])}</div>
                        </div>
                    </div>
                    :
                    <div className={`${this.props.status === 'Incoming' ? 'aic jcc p10' : 'dn'}`}>
                        No Trips Assigned
                    </div>
                }
                <PillButton
                    color={'secondary'}
                    className={`${this.props.status !== 'Incoming' ? 'm10 h30' : 'dn'}`}
                    text={'Generate Barcodes'}
                    disabled={!this.hasGenerateBarcodeRight(this.props)}
                    onClick={this.generateBarcodeToPDF}/>
                <div className={`${this.props.status === 'Incoming' ? 'm10 h30' : 'dn'}`}>
                    <PopupOptionButton
                        text={'DOWNLOAD TERAOKA CSV'}
                        icon={<GetAppIcon/>}
                        onClick={() => this.onDispatchCSVExport(this.state.selectedDispatch ?? {} as IDispatchInstruction)}
                    />
                </div>
                <Divider className={'wfill mt5 mb5'} />
                <div style={{ maxHeight: 700 }} className={'hfill oya oxh p10'}>
                    {dashboardLines.map((instructionLine, lineIndex) => {
                        return <div>
                            <div className={'fdr aic wfill'}>
                                <div className={'fdc'}>
                                    <div className={'fdr'}>
                                        {getOrganizationCode(instructionLine.organizationId)  + ' | '  + getCommodityCode(instructionLine.commodityId)}
                                    </div>
                                    <div className={'fdr'}>
                                        {getVarietyCode(instructionLine.varietyId) + ' | ' + getMarketCode(instructionLine.targetMarketId)}
                                    </div>
                                    <div className={'fdr'}>
                                        {getPackCode(instructionLine.packId) + ' | ' + getSizeCode(instructionLine.sizeId)}
                                    </div>
                                    <div className={'fdr'}>
                                        {getColourCode(instructionLine.colourId) + ' | '  + getGradeCode(instructionLine.gradeId)}
                                    </div>
                                </div>
                                <div className={'flx1'} />
                                <div className={'fdc'}>
                                    <div className={'h30 w30 Round bcpd cs fdc fs12 aic jcc'}>
                                        {instructionLine.dispatchLines.length}
                                    </div>
                                </div>
                            </div>
                            <Divider className={'wfill mt5 mb5'} />
                            {instructionLine.dispatchLines.map(x => <div className={'fdr'}>
                                <div className={'fdr flx1'}>
                                    <PackmanLink
                                        type={'transactions'}
                                        targetPage={'stock'}
                                        id={Number(x.currentStockId)}
                                        text={getStockBarcode(x.currentStockId)} />
                                    <div className='w5'/>
                                    {`(${getStockCartons(x.currentStockId)} ea)`}
                                </div>
                                <div className={`${x.identified ? 'pt5' : 'dn'}`}>
                                    <img height={25} src={`${getIconLocation()}/scanned.svg`} />
                                </div>
                                <CustomTooltip title={'Substitute this line'}>
                                    <IconButton className={`p0 h30 w30 ${(this.props.status !== 'Dispatched' && this.props.status !== 'Received' && this.props.status !== 'Incoming') ? '' : 'dn'}`}
                                        onClick={() => this.props.editDispatchLine(x)}>
                                        <Icon fontSize={'small'}>edit</Icon>
                                    </IconButton>
                                </CustomTooltip>
                            </div>)}
                            { (lineIndex < (dashboardLines.length - 1)) && <Divider className={'wfill mt5 mb5'} />}
                        </div>;
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        sites: state.masterData.sites,
        dashboardDispatchInstructionLines: mapDashboardDispatchLines(state),
        trips: state.data.trips,
        carriers: state.masterData.carriers,
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
)(DispatchInstructionInfoPopup);

import React, { useMemo } from 'react';
import { useAppSelector } from '../../@types/redux';
import { IOptionType } from '../../@types/helper';
import { setOrganizationLocalStorage, setSiteLocalStorage } from '../../services/localStorageService';
import { dataSetSelectedOrganizationIds, dataSetSelectedSiteIds } from '../../store/data/Actions';
import { dispatch } from '../../store/Index';
import AutocompleteSelect from '../input/AutoCompleteSelect';
import PillButton from '../input/PillButton';
import PackmanDialog from './PackmanDialog';
import { authSignOutGoogle } from '../../store/auth/Functions';
import CustomTooltip from '../tooltip/tooltip';

interface OrgSiteDialogProps {
    isOpen : boolean;
    onClose : () => void;
};

const OrgSiteDialog = (props : OrgSiteDialogProps) => {
    const { isOpen, onClose } = props;
    const currentUserOrgs = useAppSelector(x => x.auth.session?.user.organizationIds);
    const currentUserSites = useAppSelector(x => x.auth.session?.user.siteIds);
    const sites = useAppSelector(x => x.masterData.sites);
    const session = useAppSelector(x => x.auth.session);
    const organizations = useAppSelector(x => x.masterData.organizations);
    const selectedOrganizationIds = useAppSelector(x => x.data.selectedOrganizationIds);
    const selectedSiteIds = useAppSelector(x => x.data.selectedSiteIds);

    const onOrganizationChange = (e : React.ChangeEvent<{}>, selectedOrganizationOptions : Array<IOptionType>) => {
        if (selectedOrganizationOptions.length > 0) {
            setOrganizationLocalStorage(selectedOrganizationOptions.map(x => Number(x.value)));
            dispatch(dataSetSelectedOrganizationIds(selectedOrganizationOptions.map(x => Number(x.value))));
        } else {
            setOrganizationLocalStorage(selectedOrganizationOptions.map(x => Number(x.value)));
            setSiteLocalStorage([]);
            dispatch(dataSetSelectedOrganizationIds(selectedOrganizationOptions.map(x => Number(x.value))));
            dispatch(dataSetSelectedSiteIds([]));
        }
    };

    const onSiteChange = (e : React.ChangeEvent<{}>, selectedSiteOptions : Array<IOptionType>) => {
        setSiteLocalStorage(selectedSiteOptions.map(x => Number(x.value)));
        dispatch(dataSetSelectedSiteIds(selectedSiteOptions.map(x => Number(x.value))));
    };

    const signOutGoogle = () => {
        authSignOutGoogle();
    };

    const onlogoutClick = () => {
        signOutGoogle();
    };

    const getOrganizationOptions = useMemo(() => {
        return organizations?.filter(x => x.isActive).filter(x => (session?.user?.organizationIds.some(y => x.id === y))).map((x) => {
            return { value: x.id, label: x.name };
        }) ?? [];
    }, [organizations, session, currentUserOrgs]);

    const getPackhouseOptions = useMemo(() => {
        const packhouses = sites.filter(x => x.isPackhouse && x.isActive);
        return  packhouses.filter(x => (session?.user?.siteIds.some(y => x.id === y)))
            .filter(x => x.organizationIds?.some(y => selectedOrganizationIds?.some(z => y === z)))
            .map((x) => {
                return { value: x.id, label: x.description };
            }) ?? [];
    }, [sites, selectedOrganizationIds, session, currentUserSites]);

    const getSelectedOrganizationOptions = useMemo(() => {
        return getOrganizationOptions?.filter(x => selectedOrganizationIds?.some(y => y === x.value)) ?? [];
    }, [selectedOrganizationIds, getOrganizationOptions]);

    const getSelectedPackhouseOptions = useMemo(() => {
        return getPackhouseOptions?.filter(x => selectedSiteIds?.some(y => y === x.value)) ?? [];
    }, [selectedSiteIds, getPackhouseOptions]);

    return (
        <PackmanDialog title='Select Organization(s) and Site(s)' isInfo maxWidth={'sm'} fullWidth isOpen={isOpen}>
            <div className={'p20'}>
                <AutocompleteSelect
                    name={'organizations'}
                    label={'Organizations'}
                    options={getOrganizationOptions}
                    value={getSelectedOrganizationOptions}
                    onChange={onOrganizationChange}
                    isMulti={true}
                    className={'wfill pb10'}
                />
                <AutocompleteSelect
                    name={'sites'}
                    label={'Sites'}
                    options={getPackhouseOptions}
                    value={getSelectedPackhouseOptions}
                    onChange={onSiteChange}
                    isMulti={true}
                    className={'wfill pb10'}
                />
                <div className={'fdr mt20 aic jcc'}>
                    <CustomTooltip title={'Logout'}>
                        <PillButton className={'ml15 pl20 pr20 mr10 h35'} onClick={onlogoutClick} text={'Logout'}/>
                    </CustomTooltip>
                    <PillButton color={'secondary'} disabled={getSelectedOrganizationOptions.length <= 0 || getSelectedPackhouseOptions.length <= 0} className={'ml15 pl20 pr20 mr10 h35'} onClick={onClose} type='submit' text='Submit'/>
                </div>
            </div>
        </PackmanDialog>
    );
};

export default OrgSiteDialog;

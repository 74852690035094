import * as React from 'react';
import { dataSetLotViews, dataSetLots, dataSetStocks } from '../../store/data/Actions';
import { IRootState, IAuthState, DispatchCall, RootAction } from '../../@types/redux';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CustomTable from '../../components/datagrid/CustomTable';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { booleanToYesNo, formatDateTime, compareDate, addkg, formatDateTimeToDateOnly, addArrayElement, removeArrayElement } from '../../services/appFunctionsService';
import { Card, Typography, Button, TextField, IconButton, Icon, Table, TableRow, TableCell, Checkbox } from '@mui/material';
import LotSummary from './LotSummary';
import { dataSetLot, dataSetLotScreenData, dataSetLotSummaryData, dataSetLotView } from '../../store/data/Functions';
import materialTheme from '../../styles/materialTheme';
import { ILot } from '../../@types/model/lot/lot';
import { ILotType } from '../../@types/model/masterData/lotType/lotType';
import { ISite } from '../../@types/model/masterData/site/site';
import LotHttpService from '../../services/http/lot/lotHttpService';
import Screen from '../../components/Screen';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import { Formik, FormikActions } from 'formik';
import { LotFormValues, ILotFormValues } from '../../@types/model/lot/lotFormValues';
import LotForm from './form/LotForm';
import { createSelector } from 'reselect';
import PillButton from '../../components/input/PillButton';
import { ICommodityState } from '../../@types/model/masterData/commodityState/commodityState';
import { CONSTANT_LOT_TYPE_GUIDS, DATE_FORMAT_DEFAULT, DATE_FORMAT_TIMESTAMP_FROM_API } from '../../appConstants';
import moment from 'moment';
import TransactionFilter from '../../components/filters/BasicTransactionScreenFilter';
import { ILotEdit } from '../../@types/model/lot/lotEdit';
import { IRight } from '../../@types/model/user/right';
import { RouteComponentProps } from 'react-router';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import { CustomChangeEvent, IOptionType } from '../../@types/helper';
import { IStorageUnit } from '../../@types/model/masterData/storageUnit/storageUnit';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import ConfirmationPrompt from '../../components/dialog/ConfirmationPrompt';
import { ILotLine } from '../../@types/model/lot/lotLine';
import LotCorrectionDialog from './LotCorrectionDialog';
import LotTransferDialog from './LotTransferDialog';
import { ILotView } from '../../@types/model/lot/lotView';
import { ILotLineView } from '../../@types/model/lot/LotLineView';
import { IBarcodeTraceTableRow } from '../../@types/model/lot/barcodeTraceTableRow';
import { ListChildComponentProps, FixedSizeList as WindowList } from 'react-window';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { IBatch } from '../../@types/model/batch/batch';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import LotMassImportDialog from './LotMassImportDialog';
import { IStock } from '../../@types/model/stock/stock';
import { navLot, navPath } from '../../store/nav/Actions';
import { generateLotCards, renderQRCodes } from '../../services/lot/lotDocumentService';
import { syncMasterData } from '../../services/masterDataSyncService';
import CustomTooltip from '../../components/tooltip/tooltip';
import DeleteConfirmationDialog from '../../components/dialog/DeleteConfirmationDialog';

export const lotImportCSVHeaders = {
    lotName: 'LotName',
    lotTypeGuid: 'LotTypeGuid',
    organizationGuid: 'OrganizationGuid',
    siteGuid: 'SiteGuid',
    storageUnitGuid: 'StorageUnitGuid',
    commodityGuid: 'CommodityGuid',
    varietyGuid: 'VarietyGuid',
    farmGuid: 'FarmGuid',
    orchardGuid: 'OrchardGuid',
    batchGuid: 'BatchGuid',
    packGuid: 'PackGuid',
    commodityStateGuid: 'CommodityStateGuid',
    numberOfUnits: 'NumberOfUnits',
};

interface ILotScreenProps extends RouteComponentProps {
    dataSetLotViews : DispatchCall<Array<ILotView>>;
    dataSetLots : DispatchCall<Array<ILot>>;
    selectedLoadDate : string;
    auth : IAuthState;
    selectedDispatchIndex ?: number;
    showOnlyMyDispatches : boolean;
    selectedOrganizationIds ?: Array<number>;
    selectedSiteIds ?: Array<number>;
    storageUnits : Array<IStorageUnit>;
    lotViews : Array<ILotView>;
    lots : Array<ILot>;
    stocks : Array<IStock>;
    lotTypes : Array<ILotType>;
    farms : Array<IFarm>;
    sites : Array<ISite>;
    organizations : Array<IOrganization>;
    commodityStates : Array<ICommodityState>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    orchards : Array<IOrchard>;
    packs : Array<IPack>;
    batches : Array<IBatch>;
}

interface ILotScreenState {
    isLoading : boolean;
    dataFetched : boolean;
    lotToFinalize ?: ILot;
    showLotSummaryId ?: number;
    editLotView ?: ILotView;
    selectedLot ?: ILot;
    isDialogOpen : boolean;
    isFormDialogOpen : boolean;
    isFinalizeDialogOpen : boolean;
    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;
    lotToTransfer ?: ILotView;
    isTransferDialogOpen : boolean;
    isTransferFinalizeButtonClicked : boolean;
    selectedSourceLot ?: IOptionType;
    selectedDestinationLot ?: IOptionType;
    selectedSourceStorageUnit ?: IOptionType;
    selectedDestinationStorageUnit ?: IOptionType;
    selectedPack ?: IOptionType;
    lotTransferNoUnitsAmount ?: number;
    lotTransferSelectedTotalNoUnitsAmount ?: number;
    lotTransferGrossWeight ?: number;
    lotTransferSelectedTotalGrossWeight ?: number;
    selectedFilterStorageUnit ?: IOptionType;
    selectedFilterFarm ?: IOptionType;
    isLotCorrectionsDialogOpen : boolean;
    selectedLotToCorrect ?: IOptionType;
    selectedSimilarLot ?: IOptionType;
    isDeleting : boolean;
    deleteItem ?: ILotView;

    isTraceBarcodeDialogOpen : boolean;
    barcodeToTrace : string;
    barcodeTraceTableRows : Array<IBarcodeTraceTableRow>;

    checkedLotLines : Array<{lotLine : ILotLine; checked : boolean}>;

    onYesClick : () => void;
    onNoClick : () => void;
    message : string;
    showPrompt : boolean;

    lotLineViews : Array<ILotLineView>;

    isMassLotFinalizeDialogOpen : boolean;
    selectedSite ?: IOptionType;
    selectedLotType ?: IOptionType;
    checkedLotsToFinalize : Array<ILotView>;
    lotsAvailableToBeFinalized : Array<ILotView>;
    searchLotNameText : string;

    isLotImportDialogOpen : boolean;

    checkedLots : Array<ILotView>;
}

class LotScreen extends React.Component<ILotScreenProps, ILotScreenState> {
    constructor(props : ILotScreenProps) {
        super(props);

        this.state = {
            isLoading: false,
            dataFetched: false,
            editLotView: undefined,
            isDialogOpen: false,
            isFormDialogOpen: false,
            isFinalizeDialogOpen: false,
            isTransferDialogOpen: false,
            isTransferFinalizeButtonClicked: false,
            selectedFromDate: moment().local().startOf('day').subtract(7, 'days'),
            selectedToDate: moment().local().endOf('day'),
            isLotCorrectionsDialogOpen: false,
            isDeleting: false,
            deleteItem: undefined,
            checkedLotLines: [],
            isTraceBarcodeDialogOpen: false,
            barcodeToTrace: '',
            barcodeTraceTableRows: [],

            onYesClick: () => null,
            onNoClick: () => null,
            message: '',
            showPrompt: false,

            lotLineViews: [],

            isMassLotFinalizeDialogOpen: false,
            checkedLotsToFinalize: [],
            lotsAvailableToBeFinalized: [],
            searchLotNameText: '',

            isLotImportDialogOpen: false,

            checkedLots: [],
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        if (this.props.location.pathname?.includes(':')) {
            this.showSummaryDialog(Number(this.props.location.pathname.split(':')[1].split('/')[0]));
        } else {
            // checks if indexedDB is available.
            const isIndexedDBAvailable = !!self.indexedDB ? true : false;

            if (isIndexedDBAvailable) {
                await syncMasterData(false);
            }

            try {
                const res = await LotHttpService.getLotTransactionViewRelatedData(this.getDate('from'), this.getDate('to'), this.props.selectedSiteIds, !isIndexedDBAvailable);

                dataSetLotScreenData(res.data);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred retrieving lot data.');
            } finally {
                this.setLoading(false);
            }
        }
    };

    public componentDidUpdate = (prevProps : ILotScreenProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            /* prop changes go here */
            if (prevProps.location.pathname?.includes(':') && !nextProps.location.pathname?.includes(':')) {
                this.closeSummaryDialog();
            }
            if (!prevProps.location.pathname?.includes(':') && nextProps.location.pathname?.includes(':')) {
                this.showSummaryDialog(Number(nextProps.location.pathname.split(':')[1].split('/')[0]));
            }
            if (prevProps.location.pathname?.includes(':') && nextProps.location.pathname?.includes(':')) {
                const prevId = prevProps.location.pathname.split(':')[1].split('/')[0];
                const nextId = nextProps.location.pathname.split(':')[1].split('/')[0];
                if (prevId !== nextId) {
                    this.showSummaryDialog(Number(nextId));
                }
            }
            if (nextProps.selectedSiteIds !== undefined && prevProps.selectedSiteIds !== nextProps.selectedSiteIds) {
                this.refreshData();
            }
            if ((prevProps.lotViews !== nextProps.lotViews)) { // TODO: use a selector for this (or make this page a functional component)
                if (this.state.editLotView) {
                    const lot = nextProps.lotViews.find(x => x.id === this.state.editLotView?.id);
                    this.setState({ editLotView: lot });
                }
            }
        }
    };

    private getLotTypeName = (id : number) => this.props.lotTypes?.find(x => x.id === id)?.name ?? '';

    private getCommodityStateName = (id : number) => this.props.commodityStates?.find(x => x.id === id)?.name ?? '';

    private getDate = (type : 'from' | 'to') => {
        switch (type) {
            case 'from':
                return this.state.selectedFromDate.startOf('day').utc().unix() * 1000;
            case 'to':
                return this.state.selectedToDate.endOf('day').utc().unix() * 1000;
        }
    };

    private getLots = (props : ILotScreenProps) => props.lotViews ?? [];
    private getSelectedSites = (props : ILotScreenProps) => props.selectedSiteIds;
    private getSelectedFilterStorageUnit = (props : ILotScreenProps, state : ILotScreenState) => state.selectedFilterStorageUnit;
    private getSelectedFilterFarm = (props : ILotScreenProps, state : ILotScreenState) => state.selectedFilterFarm;

    private getRows = createSelector(
        [this.getLots, this.getSelectedSites, this.getSelectedFilterStorageUnit, this.getSelectedFilterFarm],
        (lots, selectedSiteIds, selectedFilterStorageUnit, selectedFilterFarm) => lots.filter(x => selectedSiteIds?.some(y => y === x.siteId)
                            && (selectedFilterStorageUnit ? selectedFilterStorageUnit.value === x.storageUnitId : true)
                            && (selectedFilterFarm ? selectedFilterFarm.value === x.farmId : true)));

    private setLoading = (isLoading : boolean = false) => {
        this.setState({ isLoading });
    };

    private refreshData = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        try {
            const res = await LotHttpService.getLotTransactionViewRelatedData(this.getDate('from'), this.getDate('to'), this.props.selectedSiteIds, !isIndexedDBAvailable);

            dataSetLotScreenData(res.data);
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving dashboard data.');
        } finally {
            this.setLoading(false);
        }
    };

    private submitFinalize = async () => {
        this.setLoading(true);
        if (this.state.lotToFinalize) {
            try {
                const res = await LotHttpService.doLotAdjustment(this.state.lotToFinalize.id);

                if (res.data) {
                    res.data.map(x => dataSetLot(x));
                    await this.refreshLot();

                    this.setState({ lotToFinalize: undefined, isFinalizeDialogOpen: false });
                    generalShowSuccessSnackbar('Lot finalized!');
                } else {
                    generalShowErrorSnackbar('An error occurred finalizing the lot.');
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred finalizing the lot.');
            } finally {
                this.setLoading(false);
            }
        }
    };

    private refreshLot = async () => {
        if (this.state.lotToFinalize) {
            try {
                const res = await LotHttpService.getLotViewById(this.state.lotToFinalize.id);

                if (res.data) {
                    dataSetLotView(res.data);
                } else {
                    generalShowErrorSnackbar('An error occurred retrieving lot data.');
                }
            } catch (error) {
                generalShowErrorSnackbar('An error occurred retrieving lot data.');
            }
        }
    };

    public showSummaryDialog = async (id : number) => {
        this.setState({
            isDialogOpen: true,
            showLotSummaryId: id,
        }, async () => {
            try {
                this.setLoading(true);
                // checks if indexedDB is available.
                const isIndexedDBAvailable = !!self.indexedDB ? true : false;

                if (isIndexedDBAvailable) {
                    await syncMasterData(false);
                }

                const res = await LotHttpService.getLotSummaryRelatedData(id, !isIndexedDBAvailable);

                if (res && res.data) {
                    this.setState({ lotLineViews: res.data.lotLineViews, selectedLot: res.data.lot });
                    dataSetLotSummaryData(res.data);
                }
            } catch (e) {
                generalShowErrorSnackbar('Error occured while loading lot summary data');
            } finally {
                this.setLoading(false);
            }
        });
    };

    public refreshLotSummaryData = async () => {
        if (this.state.showLotSummaryId) {
            try {
                this.setLoading(true);
                // checks if indexedDB is available.
                const isIndexedDBAvailable = !!self.indexedDB ? true : false;

                if (isIndexedDBAvailable) {
                    await syncMasterData(false);
                }

                const res = await LotHttpService.getLotSummaryRelatedData(this.state.showLotSummaryId, !isIndexedDBAvailable);

                if (res && res.data) {
                    this.setState({ lotLineViews: res.data.lotLineViews, selectedLot: res.data.lot });
                    dataSetLotSummaryData(res.data);
                }
            } catch (e) {
                generalShowErrorSnackbar('Error occured while loading lot summary data');
            } finally {
                this.setLoading(false);
            }
        }
    };

    public closeSummaryDialog = () => {
        this.setState({
            isDialogOpen: false,
            showLotSummaryId: undefined,
        }, () => navPath(this.props.location?.pathname?.split('/:')[0]));
    };

    private onLotEdit = async (row : ILotView) => {
        this.setState({
            isFormDialogOpen: true,
            editLotView: row,
        }, async () => {
            try {
                this.setLoading(true);
                const res = await LotHttpService.getLot(row.id);

                if (res && res.data) {
                    this.setState({ selectedLot: res.data });
                }
            } catch (e) {
                generalShowErrorSnackbar('Failed to load lot data for edit');
            } finally {
                this.setLoading(false);
            }
        });
    };

    public closeFormDialog = () => {
        this.setState({
            isFormDialogOpen: false,
            editLotView: undefined,
        });
    };

    private onLotFinalize = async (rowId ?: number) => {
        this.setLoading(true);
        if (!!rowId) {
            this.setState({
                isFinalizeDialogOpen: true,
            }, async () => {
                try {
                    const res = await LotHttpService.getLot(rowId);

                    if (res && res.data) {
                        this.setState({ lotToFinalize: res.data });
                    }
                } catch (e) {
                    generalShowErrorSnackbar('Error orrcured while loading lot finalization data');
                } finally {
                    this.setLoading(false);
                }
            });
        } else {
            generalShowErrorSnackbar('Selected lot could not be found.');
            this.setLoading(false);
        }
    };

    public closeFinalizeDialog = () => {
        this.setState({
            isFinalizeDialogOpen: false,
            lotToFinalize: undefined,
        });
    };

    private onLotTransfer = async (row ?: ILotView, transferFinalizeButtonClicked ?: boolean) => {
        this.setState({ isTransferDialogOpen: true, isTransferFinalizeButtonClicked: transferFinalizeButtonClicked ? true : false, lotToTransfer: row });
    };

    public closeLotTransferDialog = () => {
        this.setState({
            isTransferDialogOpen: false,
            lotToTransfer: undefined,
            isTransferFinalizeButtonClicked: false,
        });
    };

    public getSelectedLot = (props : ILotScreenProps, state : ILotScreenState) => state.selectedLot;
    public geteditLotView = (props : ILotScreenProps, state : ILotScreenState) => state.editLotView;
    public getAvailableLotsToFinalize = (props : ILotScreenProps, state : ILotScreenState) => state.lotsAvailableToBeFinalized;
    public getSelectedLotType = (props : ILotScreenProps, state : ILotScreenState) => state.selectedLotType;
    public getLotNameToSearch = (props : ILotScreenProps, state : ILotScreenState) => state.searchLotNameText;
    public getCommodityStates = (props : ILotScreenProps) => props.commodityStates;
    public getSelectedOrganizationIds = (props : ILotScreenProps) => props.selectedOrganizationIds;
    public getLotTypes = (props : ILotScreenProps) => props.lotTypes;
    public getSites = (props : ILotScreenProps) => props.sites;
    public getFarms = (props : ILotScreenProps) => props.farms;
    public getStorageUnits = (props : ILotScreenProps) => props.storageUnits;
    public getSelectedSiteIds = (props : ILotScreenProps) => props.selectedSiteIds;
    public getSelectedSite = (props : ILotScreenProps, state : ILotScreenState) => state.selectedSite;
    public getCheckedLots = (props : ILotScreenProps, state : ILotScreenState) => state.checkedLots;
    public getBatchLotBatches = (props : ILotScreenProps) => props.batches;

    public getInitialFormValues = createSelector(
        [this.getSelectedLot, this.getCommodityStates, this.getLotTypes],
        (lot : ILot, commodityStates ?: Array<ICommodityState>, lotTypes ?: Array<ILotType>) => {
            return new LotFormValues(lot, commodityStates, lotTypes);
        },
    );

    public onReset = async (formValues : ILotFormValues, formikActions : FormikActions<ILotFormValues>) => {
        formikActions.resetForm();
        this.closeFormDialog();
    };

    public onSubmit = async (values : ILotFormValues) => {
        this.setLoading(true);
        const lotView = this.geteditLotView(this.props, this.state);

        if (lotView) {
            lotView.commodityStateName = values.commodityState?.label ?? '';
            lotView.commodityStateId = Number(values.commodityState?.value);
            const data : ILotEdit = {
                lotId: lotView.id,
                commodityStateId: Number(values?.commodityState?.value),
                lotTypeId: Number(values?.lotType?.value),
            };

            try {
                const res = await LotHttpService.editLot(data);
                if (res.data) {
                    dataSetLotView(lotView);
                }

                if (this.state.editLotView) {
                    generalShowSuccessSnackbar('Lot updated successfully.');
                } else {
                    generalShowSuccessSnackbar('Lot added successfully.');
                }

                this.closeFormDialog();
            } catch (e) {
                generalShowErrorSnackbar('An error occurred updating lot data.');
            } finally {
                this.setLoading(false);
            }
        }
    };

    private getLotLotTypeGuid = (id : number) => {
        const lot = this.props.lotViews.find(x => x.id === id);
        const lotType = lot && this.props.lotTypes.find(x => x.id === lot.lotTypeId);
        return lotType ? lotType.guid : '';
    };

    private handleDateRangeChange = (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => {
        const selectedFromDate = changedBy === 'start' ? moment(start).startOf('day') : end < start ? moment(end).startOf('day') : moment(start).startOf('day');
        const selectedToDate = changedBy === 'end' ? moment(end).endOf('day') : start > end ? moment(start).endOf('day') : moment(end).endOf('day');
        this.setState({ selectedFromDate, selectedToDate });
    };

    private getFilterStorageUnitOptions = createSelector(
        [this.getStorageUnits, this.getSelectedSites],
        (storageUnits : Array<IStorageUnit>, selectedSiteIds : Array<number>) => {
            if (!storageUnits) return [];

            return storageUnits.filter(x => x.isActive && selectedSiteIds?.some(y => y === x.siteId)).map((x) => {
                return { label: `(${x.code}) - ${x.description}`, value: x.id };
            });
        },
    );

    private onFilterStorageUnitChange = (e : React.ChangeEvent<{}>, selectedFilterStorageUnit : IOptionType) => {
        this.setState({ selectedFilterStorageUnit });
    };

    private getFarmOptions = createSelector(
        [this.getFarms, this.getSelectedOrganizationIds],
        (farms : Array<IFarm>, selectedOrganizationIds : Array<number>) => {
            if (!farms) return [];

            return farms.filter(x => x.isActive && x.organizationIds.some(y => selectedOrganizationIds?.some(z => z === y))).map((x) => {
                return { label: `(${x.code}) - ${x.name}`, value: x.id };
            });
        },
    );

    private onFarmChange = (e : React.ChangeEvent<{}>, selectedFilterFarm : IOptionType) => {
        this.setState({ selectedFilterFarm  });
    };

    private uneditableLotTypes = (lotId : number) => CONSTANT_LOT_TYPE_GUIDS.filter((x, i) => [0, 1, 3].some(y => i === y)).some(x => this.getLotLotTypeGuid(lotId) === x);

    private onLotCorrectionDialogOpen = () => {
        this.setState({ isLotCorrectionsDialogOpen: true });
    };

    private onLotCorrectionDialogClose = () => {
        this.setState({ isLotCorrectionsDialogOpen: false });
    };

    private setDeleteItem = (item ?: ILotView) => {
        this.setState({
            deleteItem: item,
            isDeleting: true,
        });
    };

    private removeLot = async (deleteReason : string) => {
        const selectedLot = this.state.deleteItem;

        if (selectedLot) {
            if (deleteReason !== '' && deleteReason.length >= 200) {
                this.setLoading(true);

                try {
                    const res = await LotHttpService.deleteLot(selectedLot.id, deleteReason);
                    dataSetLotView(res.data);

                    generalShowSuccessSnackbar('Lot deleted successfully.');
                } catch (e) {
                    generalShowErrorSnackbar('An error occurred deleting the lot.');
                } finally {
                    this.closeDeleteConfirmationPopup();
                    this.setLoading(false);
                }
            } else {
                generalShowErrorSnackbar('Reason for deleting this Lot must be at least 200 characters.');
            }
        }
    };

    private closeDeleteConfirmationPopup = () => this.setState({ isDeleting: false, deleteItem: undefined });

    private onTraceBarcodeDialogOpen = () => {
        this.setState({ isTraceBarcodeDialogOpen: true });
    };

    private onTraceBarcodeDialogClose = () => {
        this.setState({ isTraceBarcodeDialogOpen: false, barcodeToTrace: '', barcodeTraceTableRows: [] });
    };

    private onBarcodeToTraceChange = (event : CustomChangeEvent) => {
        this.setState({ barcodeToTrace: event.target.value });
    };

    private onSearchLotNameChange = (event : CustomChangeEvent) => {
        this.setState({ searchLotNameText: event.target.value });
    };

    private onBarcodeTrace = async () => {
        this.setLoading(true);
        try {
            const res = await LotHttpService.traceUnitGuid(this.state.barcodeToTrace);

            if (res && res.data && res.data.length > 0) {
                this.setState({ barcodeTraceTableRows : res.data });
            } else {
                generalShowErrorSnackbar('Found nothing matching given barcode');
            }
        } catch (e) {
            generalShowErrorSnackbar('Error occured while performing barcode trace');
        } finally {
            this.setLoading(false);
        }
    };

    private getStorageUnitCode = (id : number) => {
        const storageUnits = this.props.storageUnits;
        const storageUnit = storageUnits.find(x => x.id === id);

        return storageUnit ? storageUnit.code : '';
    };

    private getBarcodeToTrace = (props : ILotScreenProps, state : ILotScreenState) => state.barcodeToTrace;
    private getTableRows = (props : ILotScreenProps, state : ILotScreenState) => state.barcodeTraceTableRows;

    private getBarcodeTableRows = createSelector(
        [this.getTableRows, this.getBarcodeToTrace],
        (barcodeTraceTableRows, barcodeToTrace) => {
            if (barcodeTraceTableRows.length < 1 && barcodeToTrace === '') return [];

            return barcodeTraceTableRows;
        },
    );

    private canLotBeDeleted = (row : ILotView) => {
        let result = false;
        if (this.hasAdminEditingRight(this.props) && row.isActive) {
            result = true;
        } else if (row.isActive && this.hasEditingRight(this.props)) {
            if (row.lotLineIds == null) {
                result = true;
            } else if (row.lotLineIds === 0) {
                result = true;
            } else if (!!row.lineIdsWithCorrespondingLotLineOrStockLine && row.lineIdsWithCorrespondingLotLineOrStockLine !== '') {
                result = true;
            }
        }
        return result;
    };

    private renderListElement = React.memo(({ data, index, style } : React.PropsWithChildren<ListChildComponentProps>) => {
        const row : IBarcodeTraceTableRow = data[index];
        if (!row) return <div />;
        return (
            <TableRow style={style} className={`${index % 2 === 0 ? 'bcTableRow2' : 'bcTableRow1'}`}>
                <TableCell className={'wfill aic h50 fs14 p0 fw500'}>
                    <div style={{ minWidth: 270, maxWidth: 270 }} className={'fdr w270'}>
                        {(row.isIncoming ? <div className={'cp'} style={{ paddingLeft: 3 }}>{`+ ${row.lotName}`}</div> :
                            <div className={'cred'} style={{ paddingLeft: 3 }}>{`- ${row.lotName}`}</div>)
                        }
                    </div>
                    <div style={{ minWidth: 100, maxWidth: 100 }} className={'w100 aic jcc bl1 hfill'}>{this.getStorageUnitCode(row.storageUnitId ?? 0)}</div>
                    <div style={{ minWidth: 250, maxWidth: 250 }} className={'w250 aic jcc bl1 hfill'}>{row.fromLotName}</div>
                    <div style={{ minWidth: 250, maxWidth: 250 }} className={'w250 aic jcc bl1 hfill'}>{row.toLotName}</div>
                    <div style={{ minWidth: 170, maxWidth: 170 }} className={'w170 aic jcc bl1 hfill'}>{row.fromStockBarcode}</div>
                    <div style={{ minWidth: 170, maxWidth: 170 }} className={'w170 aic jcc bl1 hfill'}>{row.toStockBarcode}</div>
                    <div style={{ minWidth: 100, maxWidth: 100 }} className={'w100 aic jcc bl1 hfill'}>{formatDateTime(row.createdOn ?? '')}</div>
                    <div style={{ minWidth: 50, maxWidth: 50 }} className={'w50 aic jcc bl1 hfill'}>{booleanToYesNo(row.isActive)}</div>
                </TableCell>
            </TableRow>
        );
    });

    private openMassLotFinalizeDialog = async () => {
        let site : ISite | undefined;
        const selectedSiteIds = this.props.selectedSiteIds;
        if (!!selectedSiteIds && selectedSiteIds?.length === 1) {
            site = this.props.sites.find(x => x.id === selectedSiteIds[0]);
        }

        if (!!site) {
            this.setState({ isMassLotFinalizeDialogOpen: true, selectedSite: { label: `${site?.shortDescription} ${site?.description}`, value: site?.id } });
            this.setLoading(true);
            try {
                const res = await LotHttpService.getLotsAvailableToFinalize(site?.id);

                if (res && res.data) {
                    this.setState({ lotsAvailableToBeFinalized: res.data });
                }
            } catch (e) {
                generalShowErrorSnackbar('Error occured while loading lots');
            } finally {
                this.setLoading(false);
            }
        } else {
            this.setState({ isMassLotFinalizeDialogOpen: true });
        }
    };

    private closeMassLotFinalizeDialog = () => {
        this.setState({
            isMassLotFinalizeDialogOpen: false,
            checkedLotsToFinalize: [],
            lotsAvailableToBeFinalized: [],
            selectedSite: undefined,
            selectedLotType: undefined,
            searchLotNameText: '',
        });
    };

    private getMassLotFinalizeTableRows = createSelector(
        [this.getAvailableLotsToFinalize, this.getLotNameToSearch, this.getSelectedLotType],
        (lotViews : Array<ILotView>, lotName : string, selectedLotType ?: IOptionType) => {
            return lotViews.filter(x => (!!selectedLotType ? x.lotTypeId === selectedLotType.value : true) && (lotName !== '' ? (!!x.name && x.name.toLowerCase().includes(lotName.toLowerCase())) : true));
        },
    );

    private getSiteOptions = createSelector(
        [this.getSites, this.getSelectedSiteIds],
        (sites : Array<ISite>, selectedSiteIds ?: Array<number>) => {
            return sites.filter(x => selectedSiteIds?.some(y => y === x.id) && x.isActive).map((x) => {
                return { label: `(${x.shortDescription}) ${x.description}`, value: x.id };
            });
        },
    );

    private getLotTypeOptions = createSelector(
        [this.getLotTypes],
        (lotTypes : Array<ILotType>) => {
            return lotTypes.filter(x => x.isActive).map((x) => {
                return { label: x.name, value: x.id };
            });
        },
    );

    private onSiteChange = async (e : React.ChangeEvent<{}>, selectedSite : IOptionType) => {
        this.setState({ selectedSite });
        if (!!selectedSite && selectedSite !== null) {
            this.setLoading(true);
            try {
                const res = await LotHttpService.getLotsAvailableToFinalize(Number(selectedSite.value));

                if (res && res.data) {
                    this.setState({ lotsAvailableToBeFinalized: res.data });
                }
            } catch {
                generalShowErrorSnackbar('Error occured while loading lots');
            } finally {
                this.setLoading(false);
            }
        } else {
            this.setState({ checkedLotsToFinalize: [], lotsAvailableToBeFinalized: [] });
        }
    };

    private onLotTypeChange = async (e : React.ChangeEvent<{}>, selectedLotType : IOptionType) => {
        this.setState({ selectedLotType });
    };

    private handleSelectAllLotsToFinalize = () => {
        const filteredLotsAvailableToBeFinalized = this.getMassLotFinalizeTableRows(this.props, this.state);
        const checkedLotsToFinalize = this.state.checkedLotsToFinalize;

        if  (filteredLotsAvailableToBeFinalized.length !== checkedLotsToFinalize.length) {
            filteredLotsAvailableToBeFinalized.forEach((batch) => {
                const index = checkedLotsToFinalize.findIndex(x => x.id === batch.id);

                if (index === -1) {
                    this.setState(prevState => ({ checkedLotsToFinalize: addArrayElement(prevState.checkedLotsToFinalize, batch) }));
                }
            });
        } else {
            this.setState({ checkedLotsToFinalize: [] });
        }
    };

    private handleLotToFinalizeCheckboxChecked = (lot : ILotView, selected : boolean) => {
        if (selected) {
            const index = this.state.checkedLotsToFinalize.findIndex(x => x.id === lot.id);

            if (index === -1) {
                this.setState(prevState => ({ checkedLotsToFinalize: addArrayElement(prevState.checkedLotsToFinalize, lot) }));
            }
        } else {
            const index = this.state.checkedLotsToFinalize.findIndex(x => x.id === lot.id);
            if (index !== -1) {
                this.setState(prevState => ({ checkedLotsToFinalize: removeArrayElement(prevState.checkedLotsToFinalize, index) }));
            }
        }
    };

    private submitMultipleLotsToFinalize = async () => {
        const selectedLots = this.state.checkedLotsToFinalize;
        if (selectedLots.length > 0) {
            try {
                this.setLoading(true);
                const res = await LotHttpService.doMultipleLotAdjustments(selectedLots.map(x => x.id));

                if (res && res.data) {
                    res.data.forEach((x) => {
                        dataSetLotView(x);
                    });
                    this.closeMassLotFinalizeDialog();
                    generalShowSuccessSnackbar('Selected lots have been finalized successfully!');
                }
            } catch (e) {
                generalShowErrorSnackbar(e.data.Message);
            } finally {
                this.setLoading(false);
            }
        }
    };

    private openLotImportDialog = async () => {
        this.setState({ isLotImportDialogOpen: true });
    };

    private closeLotImportDialog = () => {
        this.setState({ isLotImportDialogOpen: false });
    };

    private setCheckedLotIds = (checkedLotIds : Array<number>) => this.setState({ checkedLots: this.getRows(this.props, this.state).filter(x => checkedLotIds.some(y => y === x.id)) });

    private onLotCardGenerate = async () => {
        generateLotCards(this.state.checkedLots, () => this.setState({ checkedLots: [] }));
    };

    private getLotsToGenerateLotCards = createSelector(
        [this.getCheckedLots],
        (lots : Array<ILotView>) => {
            return lots;
        },
    );

    private getBatchLotBatch = (row : ILotView) => {
        const batch = this.props.batches.find(x => x.lotId === row.id);

        return batch;
    };

    private getRights = (props : ILotScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : ILotScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    private hasAdminEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_ADMIN_EDIT')));

    private hasMassFinalizeRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_MASS_FINALIZE')));

    private hasMassImportRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_MASS_IMPORT')));

    private hasFinalizeBatchLotRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_FINALIZE_BATCH_LOT')));

    public render() {
        const hasMassFinalizeRight = this.hasMassFinalizeRight(this.props);
        const hasMassImportRight = this.hasMassImportRight(this.props);
        const hasFinalizeBatchLotRight = this.hasFinalizeBatchLotRight(this.props);
        const initialValues = this.getInitialFormValues(this.props, this.state);
        const selectedLotsToGenerateLotCards = this.getLotsToGenerateLotCards(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading} isPadded={false} isScrollable={false}>
                {/* renderQRCodes is needed for generating lot cards */}
                {renderQRCodes(selectedLotsToGenerateLotCards)}
                <div className={'fdc flx1 hfill oh'}>
                    <div className={'fdr pt10 mt10 mr20 mb10 aic'}>
                        <PillButton
                            className={!!hasMassFinalizeRight ? 'ml20 h35 w200 reducedPillButtonShadow' : 'dn'}
                            text={'Finalize lots'}
                            color={'secondary'}
                            onClick={() => this.openMassLotFinalizeDialog()}
                        />
                        <PillButton
                            className={!!hasMassImportRight ? 'ml20 h35 w200 reducedPillButtonShadow' : 'dn'}
                            text={'Import New Lots'}
                            color={'secondary'}
                            onClick={() => this.openLotImportDialog()}
                        />
                        <div className={'flx1'}/>
                        <AutocompleteSelect
                            className={'w300 mb10'}
                            name={'storageUnits'}
                            label={'Select Storage Unit'}
                            options={this.getFilterStorageUnitOptions(this.props)}
                            onChange={this.onFilterStorageUnitChange}
                            value={this.state.selectedFilterStorageUnit}
                        />
                        <div className={'w10'}/>
                        <AutocompleteSelect
                            className={'w300 mb10'}
                            name={'farms'}
                            label={'Select Farm'}
                            options={this.getFarmOptions(this.props)}
                            onChange={this.onFarmChange}
                            value={this.state.selectedFilterFarm}
                        />
                        <div className={'w10'}/>
                        <TransactionFilter
                            className={'mb10'}
                            selectedFromDate={this.state.selectedFromDate}
                            selectedToDate={this.state.selectedToDate}
                            handleDateRangeChange={this.handleDateRangeChange}
                            onApplyClick={this.refreshData} />
                    </div>
                    {/* Delete Dialog */}
                    {!!this.state.isDeleting &&
                        <DeleteConfirmationDialog
                            isLoading={this.state.isLoading}
                            onSubmit={this.removeLot}
                            onclose={this.closeDeleteConfirmationPopup}
                            isOpen={!!this.state.isDeleting}
                            title={'Lot'}/>
                    }
                    {/* Info Dialog */}
                    <PackmanDialog
                        title='Lot Summary'
                        isInfo={true}
                        fullScreen={true}
                        isLoading={this.state.isLoading}
                        isOpen={this.state.isDialogOpen}
                        onClose={this.closeSummaryDialog}>
                        <div className={'flx1'}>
                            {
                                this.state.showLotSummaryId ?
                                    <LotSummary
                                        setLoading={this.setLoading}
                                        refreshData={this.refreshData}
                                        refreshLotSummaryData={this.refreshLotSummaryData}
                                        isLoading={this.state.isLoading}
                                        lot={this.state.selectedLot}
                                        lotLineViews={this.state.lotLineViews}
                                        hasEditingRight={this.hasEditingRight(this.props)}
                                    /> : <></>
                            }
                        </div>
                        {
                            this.state.selectedLot &&
                                (
                                    <div className={'fdr jcfe pr30 pb10'}>
                                        <Button
                                            className={'fwb mr5 h35'}
                                            variant='text'
                                            color='primary'
                                            onClick={this.closeFinalizeDialog}>
                                            Cancel
                                        </Button>
                                        <PillButton
                                            text={'Finalize'}
                                            className={'pl30 pr30 h35 cpd'}
                                            disabled={this.state.selectedLot?.isFinalized
                                                || this.getLotLotTypeGuid(this.state.selectedLot?.id ?? 0) === CONSTANT_LOT_TYPE_GUIDS[3]
                                                || this.state.selectedLot?.lotLines?.length < 1
                                                || this.state.selectedLot?.lotLines.every(x => !x.fromLotLineId && !x.fromStockLineId && !x.toLotLineId && !x.toStockLineId)
                                                || !hasFinalizeBatchLotRight}
                                            onClick={() => {
                                                if (this.state.selectedLot) {
                                                    this.onLotFinalize(this.state.selectedLot.id);
                                                }
                                            }}
                                            color={'secondary'}
                                            size={'small'}
                                        ></PillButton>
                                    </div>
                                )
                        }
                    </PackmanDialog>
                    {/* Finalize Dialog */}
                    <PackmanDialog
                        title='Finalize'
                        isInfo={true}
                        isLoading={this.state.isLoading}
                        isOpen={this.state.isFinalizeDialogOpen}
                        onClose={this.closeFinalizeDialog}>
                        {
                            <div>
                                <Typography className={'pl25 pt5 pr15 pb10'}>Are you sure you want to make the following adjustments and finalize this lot?</Typography>
                                {this.state.lotToFinalize && this.getLotLotTypeGuid(this.state.lotToFinalize.id) === CONSTANT_LOT_TYPE_GUIDS[2] &&
                                    <div className={'fdr pl25 pt5'}>
                                        Initial Gross Weight :
                                        {
                                            <div className={'fdr'}>
                                                <div className={'w20'}/>
                                                {`  + ${Math.abs(Number(this.state.lotToFinalize.lotLines.map(x => x.grossWeight).reverse().pop()))} kg`}
                                            </div>
                                        }
                                    </div>
                                }
                                {this.state.lotToFinalize && this.getLotLotTypeGuid(this.state.lotToFinalize.id) === CONSTANT_LOT_TYPE_GUIDS[2] &&
                                    <div className={'fdr pl25'}>
                                        Initial Nett Weight :
                                        {
                                            <div className={'fdr'}>
                                                <div className={'w20'}/>
                                                {`  + ${Math.abs(Number(this.state.lotToFinalize.lotLines.map(x => x.nettWeight).reverse().pop()))} kg`}
                                            </div>
                                        }
                                    </div>
                                }
                                {this.state.lotToFinalize &&
                                    <div className={'fdr pl25 pt5'}>
                                        Current Gross Weight :
                                        {
                                            this.state.lotToFinalize?.grossWeight > 0
                                                ?   <div className={'fdr'}>
                                                    <div className={'w20'}/>
                                                    {`  + ${Math.abs(this.state.lotToFinalize?.grossWeight)} kg`}
                                                </div>
                                                :   <div className={'fdr'}>
                                                    <div className={'w20'}/>
                                                    {`  - ${Math.abs(this.state.lotToFinalize?.grossWeight)} kg`}
                                                </div>
                                        }
                                    </div>
                                }
                                {this.state.lotToFinalize &&
                                    <div className={'fdr pl25'}>
                                        Current Nett Weight :
                                        {
                                            this.state.lotToFinalize?.grossWeight > 0
                                                ?   <div className={'fdr'}>
                                                    <div className={'w20'}/>
                                                    {`  + ${Math.abs(this.state.lotToFinalize?.nettWeight)} kg`}
                                                </div>
                                                :   <div className={'fdr'}>
                                                    <div className={'w20'}/>
                                                    {`  - ${Math.abs(this.state.lotToFinalize?.nettWeight)} kg`}
                                                </div>
                                        }
                                    </div>
                                }
                                { this.state.lotToFinalize &&
                                    <div className={'fdr pl25 pt5'}>
                                        Adjustment Gross Weight :
                                        {this.state.lotToFinalize?.grossWeight > 0 ?
                                            <div className={'fdr cred'}>
                                                <div className={'w20'}/>
                                                {`  - ${this.state.lotToFinalize?.grossWeight} kg`}
                                            </div>
                                            :
                                            <div className={'fdr cp'}>
                                                <div className={'w20'}/>
                                                {`  + ${Math.abs(this.state.lotToFinalize?.grossWeight)} kg`}
                                            </div>
                                        }
                                    </div>
                                }
                                {this.state.lotToFinalize &&
                                    <div className={'fdr pl25'}>
                                        Adjustment Nett Weight :
                                        {this.state.lotToFinalize?.nettWeight > 0 ?
                                            <div className={'fdr cred'}>
                                                <div className={'w20'}/>
                                                {`- ${this.state.lotToFinalize?.nettWeight} kg`}
                                            </div>
                                            :
                                            <div className={'fdr cp'}>
                                                <div className={'w20'}/>
                                                {`  + ${Math.abs(this.state.lotToFinalize?.nettWeight)} kg`}
                                            </div>
                                        }
                                    </div>
                                }
                                <div className={'fdr jcfe pr30 pb10'}>
                                    <Button
                                        className={'fwb mr5 h35'}
                                        variant='text'
                                        color='primary'
                                        onClick={this.closeFinalizeDialog}>
                                    Cancel
                                    </Button>
                                    <PillButton
                                        text={'Ok'}
                                        className={'pl30 pr30 h35 cpd'}
                                        onClick={this.submitFinalize}
                                        color={'secondary'}
                                        size={'small'}
                                    ></PillButton>
                                </div>
                            </div>
                        }
                    </PackmanDialog>
                    {/* Form Dialog */}
                    <PackmanDialog
                        title='Lot'
                        isEdit={!!this.state.editLotView}
                        isLoading={this.state.isLoading}
                        isOpen={this.state.isFormDialogOpen}
                        onClose={this.closeFormDialog}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={this.onSubmit}
                            onReset={this.onReset}
                            enableReinitialize
                            validationSchema={LotFormValues.formSchema}
                            component={LotForm} />
                    </PackmanDialog>
                    <Card className={'hfill mr20 mt10 mb10 ml20'}>
                        <CustomTable<ILotView>
                            fitWidthToPage
                            pageHeight={300}
                            enableSorting
                            enableFiltering
                            enablePagination
                            pageSizes={[50, 150, 250, 500, 1000]}
                            enableTotalRow
                            enableRefresh
                            enableDetails={true}
                            detailIcon={'info'}
                            detailTooltip={'Lot Summary'}
                            detailFunction={row => navLot(row.id)}
                            detailsColor={materialTheme.palette.primary.main}
                            disableRefreshButton={this.state.isLoading}
                            refreshFunction={this.refreshData}
                            isActive={(row : ILotView) => row.isActive}
                            enableEditing={(row : ILotView) => !this.uneditableLotTypes(row.id) && this.hasEditingRight(this.props) && row.isActive}
                            editFunction={this.onLotEdit}
                            enableDeleting={(row : ILotView) => !!this.canLotBeDeleted(row)}
                            deleteFunction={this.setDeleteItem}
                            enableSelection
                            autoRowHeight
                            onSelectChange={this.setCheckedLotIds}
                            disableRowSelect={row => !row.isActive}
                            columns={[
                                { title: 'Finalize', field: 'id', disableExport: true,
                                    containerComponent: (row : ILotView) => {
                                        return (
                                            ((this.getBatchLotBatch(row) && !this.getBatchLotBatch(row)?.endedOn)
                                            || (this.getBatchLotBatch(row) && this.getBatchLotBatch(row)?.endedOn && !hasFinalizeBatchLotRight)
                                            || row.isFinalized || this.getLotLotTypeGuid(row.id) === CONSTANT_LOT_TYPE_GUIDS[3]
                                            || !row.lotLineIds || row.lotLineIds === 0 || !row.lineIdsWithCorrespondingLotLineOrStockLine || row.lineIdsWithCorrespondingLotLineOrStockLine === '')
                                            || !row.isActive
                                                ?   <div></div>
                                                :   <div className={'wfill hfill jcc aic'}>
                                                    <PillButton
                                                        className={'pl10 pr10 h35 zi0 w100 reducedPillButtonShadow'}
                                                        text={'Finalize'}
                                                        type={'submit'}
                                                        color={'secondary'}
                                                        onClick={() => this.onLotFinalize(row.id)}
                                                    />
                                                </div>
                                        );
                                    },
                                },
                                { title: 'Transfer', field: 'id', disableExport: true,
                                    containerComponent: (row : ILotView) => {
                                        return (
                                            !row.isActive || this.getLotLotTypeGuid(row.id) !== CONSTANT_LOT_TYPE_GUIDS[2] && this.getLotLotTypeGuid(row.id) !== CONSTANT_LOT_TYPE_GUIDS[4]
                                                ?   <div></div>
                                                :
                                                <div className={'wfill hfill jcc aic'}>
                                                    <PillButton
                                                        className={'pl10 pr10 h35 zi0 w100 reducedPillButtonShadow'}
                                                        text={'Transfer'}
                                                        color={'secondary'}
                                                        onClick={() => this.onLotTransfer(row)}
                                                    />
                                                </div>
                                        );
                                    },
                                },
                                { title: 'ID', field: 'id', width: 85, enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', width: 300, enableFiltering: true, enableSorting: true },
                                { title: 'Type', field: 'lotTypeId', formatFunction: this.getLotTypeName, enableFiltering: true, enableSorting: true },
                                { title: 'Created', field: 'createdOn', formatFunction: formatDateTimeToDateOnly, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
                                { title: 'Gross Weight (kg)', field: 'grossWeight', formatFunction: addkg , enableFiltering: true, enableSorting: true },
                                { title: 'Nett Weight (kg)', field: 'nettWeight', formatFunction: addkg , enableFiltering: true, enableSorting: true },
                                { title: 'Commodity State', field: 'commodityStateId', formatFunction: this.getCommodityStateName, enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Finalized?', field: 'isFinalized', formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={this.getRows(this.props, this.state)}
                            initialSortOrder={[{ columnName: 'id_Id', direction : 'desc' }]}
                        />
                    </Card>
                    <div className={'fdr wfill jcc mr20 pr10 pl25 pb20'}>
                        <PillButton
                            className={'pl10 pr10 mr10 h35 w200 reducedPillButtonShadow'}
                            text={'Generate Lot Cards'}
                            color={'secondary'}
                            disabled={this.state.checkedLots.length < 1}
                            onClick={this.onLotCardGenerate}
                        />
                        <div className={'flx1'}/>
                        <PillButton
                            className={'pl10 pr10 mr10 h35 w200 reducedPillButtonShadow'}
                            text={'Trace Barcode'}
                            color={'secondary'}
                            onClick={this.onTraceBarcodeDialogOpen}
                        />
                        <PillButton
                            className={'pl10 pr10 mr10 h35 w200 reducedPillButtonShadow'}
                            text={'LOT CORRECTIONS'}
                            color={'secondary'}
                            onClick={this.onLotCorrectionDialogOpen}
                        />
                        <PillButton
                            className={'pl10 pr10 mr10 h35 w200 reducedPillButtonShadow'}
                            text={'LOT TRANSFER'}
                            color={'secondary'}
                            onClick={() => this.onLotTransfer(undefined, true)}
                        />
                    </div>
                    {/* lot transfer dialog */}
                    <PackmanDialog
                        title='Lot Transfer'
                        isInfo={true}
                        maxWidth={'md'}
                        fullWidth
                        isLoading={this.state.isLoading}
                        isOpen={this.state.isTransferDialogOpen}
                        onClose={this.closeLotTransferDialog}>
                        <LotTransferDialog
                            isLoading={this.state.isLoading}
                            selectedLotId={this.state.lotToTransfer?.id}
                            isTransferFinalizeButtonClicked={this.state.isTransferFinalizeButtonClicked}
                            refreshData={this.refreshData}
                            closeLotTransferDialog={this.closeLotTransferDialog}
                            setLoading={value => this.setLoading(value)}/>
                    </PackmanDialog>
                    {/* lot correction dialog */}
                    {!!this.state.isLotCorrectionsDialogOpen &&
                        <PackmanDialog
                            title='Lot Corrections'
                            isInfo={true}
                            maxWidth={'md'}
                            fullWidth
                            isLoading={this.state.isLoading}
                            isOpen={this.state.isLotCorrectionsDialogOpen}
                            onClose={this.onLotCorrectionDialogClose}>
                            <LotCorrectionDialog
                                isLoading={this.state.isLoading}
                                setLoading={value => this.setLoading(value)}
                                closeLotCorrectionDialog={this.onLotCorrectionDialogClose} />
                        </PackmanDialog>
                    }
                    {/* Trace Barcode dialog */}
                    {!!this.state.isTraceBarcodeDialogOpen &&
                        <PackmanDialog
                            title='Trace Barcode'
                            isInfo={true}
                            maxWidth={'xl'}
                            isLoading={this.state.isLoading}
                            isOpen={this.state.isTraceBarcodeDialogOpen}
                            onClose={this.onTraceBarcodeDialogClose}>
                            <div className={'fdc p10 aic jcc'}>
                                <div className={'fdr aic jcc'}>
                                    <TextField fullWidth className={'w250'} variant='standard' label={'Filter By Barcode'} value={this.state.barcodeToTrace} onChange={this.onBarcodeToTraceChange}/>
                                    <CustomTooltip title={(!this.state.barcodeToTrace || this.state.barcodeToTrace === '') ? 'Please enter barcode to trace' : 'Trace Barcode'}>
                                        <IconButton
                                            size={'medium'}
                                            className={'mt20 ml5 h20 w20'}
                                            onClick={this.onBarcodeTrace}
                                            disabled={!this.state.barcodeToTrace || this.state.barcodeToTrace === ''}>
                                            <Icon className={`${(!this.state.barcodeToTrace || this.state.barcodeToTrace === '') ? 'cgray2' : 'cpd'}`}>search</Icon>
                                        </IconButton>
                                    </CustomTooltip>
                                </div>
                                <Table className= {'PaperBorder mt10 mb10 pl20 pr20'}>
                                    {/* Headings */}
                                    <TableRow className={'fdr p0 bcTableHead'}>
                                        <TableCell className={'flx3 aic p0 bcTableHead h50 cw fs14'}>
                                            <div className={'w270 aic jcc'}>Lot Name</div>
                                            <div className={'w100 aic jcc'}>Storage Unit</div>
                                            <div className={'w250 aic jcc'}>From Lot</div>
                                            <div className={'w250 aic jcc'}>To Lot</div>
                                            <div className={'w170 aic jcc'}>From Stock</div>
                                            <div className={'w170 aic jcc'}>To Stock</div>
                                            <div className={'w100 aic jcc'}>Created</div>
                                            <div className={'w50 aic jcc'}>Active?</div>
                                        </TableCell>
                                    </TableRow>
                                    {/* body */}
                                    { this.getBarcodeTableRows(this.props, this.state) &&
                                            <WindowList
                                                itemCount={this.getBarcodeTableRows(this.props, this.state).length}
                                                width={1400}
                                                height={400}
                                                itemSize={50}
                                                itemData={this.getBarcodeTableRows(this.props, this.state)}
                                            >
                                                {this.renderListElement}
                                            </WindowList>
                                    }
                                </Table>
                            </div>
                        </PackmanDialog>
                    }
                    {/* Finalize Multiple lots dialog */}
                    <PackmanDialog
                        isOpen={!!this.state.isMassLotFinalizeDialogOpen}
                        isLoading={this.state.isLoading}
                        title={'Finalize Lots'}
                        isInfo={true}
                        maxWidth={'lg'}
                        onClose={this.closeMassLotFinalizeDialog} >
                        <div className={'p20 fdc'}>
                            <div className={'fdr aic'}>
                                <AutocompleteSelect
                                    className={'w300 mb10'}
                                    name={'sites'}
                                    label={'Site'}
                                    options={this.getSiteOptions(this.props)}
                                    onChange={this.onSiteChange}
                                    value={this.state.selectedSite}
                                />
                                <div className={'w10'}/>
                                <AutocompleteSelect
                                    className={'w300 mb10'}
                                    name={'lotTypes'}
                                    label={'Lot Type'}
                                    options={this.getLotTypeOptions(this.props)}
                                    onChange={this.onLotTypeChange}
                                    value={this.state.selectedLotType}
                                />
                                <div className={'flx1'}/>
                                <PillButton
                                    color={'secondary'}
                                    className={'h35'}
                                    text={`${this.state.checkedLotsToFinalize.length !== this.getMassLotFinalizeTableRows(this.props, this.state).length ? 'Select All' : 'Unselect All'}`}
                                    disabled={!!(this.state.lotsAvailableToBeFinalized.length < 1)}
                                    onClick={() => this.handleSelectAllLotsToFinalize()}/>
                            </div>
                            <div className={'fdr aic pl10 pb10'}>
                                <TextField fullWidth style={{ width: 280 }} label={'Search By Lot Name'} value={this.state.searchLotNameText} onChange={this.onSearchLotNameChange}/>
                            </div>
                            <Table className= {'PaperBorder w900'}>
                                {/* Headings */}
                                <TableRow className={'fdr bcTableHead'}>
                                    <TableCell className={'flx3 jcc aic p5 bcTableHead h23 cw fs14'}>
                                        <div style={{ width: 50, minWidth: 50 }} className={'aic jcc'}>Select</div>
                                        <div style={{ width: 85, maxWidth: 85 }} className={'aic jcc'}>Id</div>
                                        <div className={'flx2 aic jcc'}>Lot Name</div>
                                        <div className={'flx1 aic jcc'}>Created</div>
                                        <div className={'flx1 aic jcc'}>Active?</div>
                                    </TableCell>
                                </TableRow>
                                {/* body */}
                                <WindowList
                                    itemCount={this.getMassLotFinalizeTableRows(this.props, this.state).length}
                                    width={900}
                                    height={400}
                                    itemSize={30}
                                    itemData={this.getMassLotFinalizeTableRows(this.props, this.state)}
                                >
                                    {React.memo(({ data, index, style } : React.PropsWithChildren<ListChildComponentProps>) => {
                                        const lot : ILotView = data[index];
                                        if (!lot) return <div />;
                                        const isChecked : boolean = !!this.state.checkedLotsToFinalize.find(x => x.id === lot.id);
                                        return (
                                            <TableRow style={style} className={`${index % 2 === 0 ? 'bcTableRow2' : 'bcTableRow1'}`}>
                                                <TableCell className={'wfill jcc aic hfill fs14 p0 fw500'}>
                                                    <div style={{ width: 50, minWidth: 50 }} className={'aic jcc'}>
                                                        <Checkbox
                                                            className={'pt0 pb0 pr5 m0 cp'}
                                                            checked={isChecked}
                                                            onChange={(event, checked) => this.handleLotToFinalizeCheckboxChecked(lot, checked)}
                                                        />
                                                    </div>
                                                    <div style={{ width: 85, maxWidth: 85 }} className={'aic jcc bl1 hfill'}>{lot.id}</div>
                                                    <div className={'flx2 aic jcc bl1 hfill'}>{lot.name}</div>
                                                    <div className={'flx1 aic jcc bl1 hfill'}>{moment.utc(lot.createdOn, DATE_FORMAT_TIMESTAMP_FROM_API).local().format(DATE_FORMAT_DEFAULT)}</div>
                                                    <div className={'flx1 aic jcc bl1 hfill'}>{booleanToYesNo(lot.isActive)}</div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </WindowList>
                            </Table>
                            <div className={'fdr aife jcfe wfill mt10'}>
                                <Button className={'fwb h35'} variant='text' color='primary'
                                    onClick={this.closeMassLotFinalizeDialog}>
                                        Cancel
                                </Button>
                                <PillButton
                                    color={'secondary'}
                                    className={'ml15 pl20 pr20 h35'}
                                    text={'Submit'}
                                    disabled={!!(this.state.checkedLotsToFinalize.length < 1)}
                                    onClick={this.submitMultipleLotsToFinalize}/>
                            </div>
                        </div>
                    </PackmanDialog>
                    {/* Import lots dialog */}
                    <PackmanDialog
                        isOpen={!!this.state.isLotImportDialogOpen}
                        isLoading={this.state.isLoading}
                        title={'Import New lots'}
                        isInfo={true}
                        fullScreen
                        maxWidth={'xl'}
                        onClose={this.closeLotImportDialog}>
                        <LotMassImportDialog
                            isLoading={this.state.isLoading}
                            setLoading={value => this.setLoading(value)}
                            close={this.closeLotImportDialog}
                        />
                    </PackmanDialog>
                    <ConfirmationPrompt isLoading={this.state.isLoading} open={this.state.showPrompt} message={this.state.message}
                        onOkClicked={this.state.onYesClick} onCancelClicked={this.state.onNoClick}/>
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        lotViews: state.data.lotViews,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
        storageUnits: state.masterData.storageUnits,
        lotTypes: state.masterData.lotTypes,
        farms: state.masterData.farms,
        sites: state.masterData.sites,
        commodityStates: state.masterData.commodityStates,
        organizations: state.masterData.organizations,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        orchards: state.masterData.orchards,
        packs: state.masterData.packs,
        batches: state.data.batches,
        lots: state.data.lots,
        stocks: state.data.stocks,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetLotViews, dataSetLots, dataSetStocks }, dispatcher,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LotScreen);

import { Typography } from '@mui/material';
import * as React from 'react';
import { ReactElement } from 'react-markdown';
import Tooltip from '../tooltip/tooltip';


interface IPackmanLabelProps {
    label ?: string;
    value ?: string | number | React.Component<any> | ReactElement;
    className ?: string;
    maxWidth ?: string | number;
}

export const PackmanLabel = (props : IPackmanLabelProps) => {

    return (
        <div className={'flx1 fdc mxwfill'}>
            <Typography variant={'packmanLabel'}>{props.label}</Typography>
            { props.value ?
                <Tooltip title={props.value?.toString()}>
                    <Typography maxWidth={props.maxWidth} className={props.className ?? ''}>
                        {props.value}
                    </Typography>
                </Tooltip>
                :
                <div className={'h24'}/>
            }
        </div>
    );
};

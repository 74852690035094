import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { ISize } from '../../../@types/model/masterData/size/size';

export default class SizeHttpService {
    public static getSizeData() : AxiosPromise<Array<ISize>> {
        return axios.get(`${PACKMAN_API_URL}Size/GetAllWeb`);
    }

    public static addOrUpdateSize(data : ISize) : AxiosPromise<ISize> {
        return axios.post(`${PACKMAN_API_URL}Size/PostSize`, data);
    }

    public static deleteSize(sizeId : number) : AxiosPromise<ISize> {
        return axios.delete(`${PACKMAN_API_URL}Size/Delete`, {
            params : { sizeId },
        });
    }
}

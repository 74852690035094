import * as React from 'react';
import CustomTable from '../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetAccreditations, dataSetFarms } from '../../../store/masterData/Actions';
import { formatDateTime, upsertArrayElement } from '../../../services/appFunctionsService';
import { IOrganization } from '../../../@types/model/masterData/organization/organization';
import { IAccreditation, Accreditation } from '../../../@types/model/masterData/accreditation/accreditation';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import AccreditationHttpService from '../../../services/http/masterData/accreditationHttpService';
import Screen from '../../../components/Screen';
import AccreditationForm from './form/AccreditationForm';
import { Formik, FormikActions } from 'formik';
import { AccreditationFormValues, IAccreditationFormValues } from '../../../@types/model/masterData/accreditation/accreditationFormValues';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { createSelector } from 'reselect';
import { IOptionType } from '../../../@types/helper';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { IFarm } from '../../../@types/model/masterData/farm/farm';
import FarmHttpService from '../../../services/http/masterData/farmHttpService';
import { setAccreditationMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IAccreditationScreenProps extends RouteComponentProps {
    dataSetAccreditations : DispatchCall<Array<IAccreditation>>;
    dataSetFarms : DispatchCall<Array<IFarm>>;
    accreditations : Array<IAccreditation>;
    organizations : Array<IOrganization>;
    farms : Array<IFarm>;
    selectedOrganizationIds : Array<number>;
    auth : IAuthState;
}

interface IAccreditationScreenState {
    rows : Array<IAccreditation>;
    isLoading : boolean;
    selectedAccreditation ?: IAccreditation;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    channelOptions : Array<IOptionType>;
    isDeletePopupOpen : boolean;
    deletingAccreditation ?: IAccreditation;
}

class AccreditationScreen extends React.Component<IAccreditationScreenProps, IAccreditationScreenState> {
    constructor(props : IAccreditationScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedAccreditation: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
            channelOptions: [
                {
                    label: 'Local',
                    value: 0,
                },
                {
                    label: 'Retail',
                    value: 0,
                },
                {
                    label: 'Export',
                    value: 0,
                },
            ],
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);

            try {
                const res = await AccreditationHttpService.getAccreditationData();
                const res2 = await FarmHttpService.getFarmData();

                this.props.dataSetAccreditations(res.data);
                this.props.dataSetFarms(res2.data);

                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading accreditations.');
                this.setLoading(false);
            }
        }
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedAccreditation: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    public editAccreditation = (accreditation : IAccreditation) => {
        this.setState({
            isDialogOpen: true,
            selectedAccreditation: accreditation,
        });
        this.openDialog();
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private formatFarms = (farmIds : Array<number>) => this.props.farms?.filter(x => farmIds?.some(y => y === x.id))
        .map(x => x.name).toString().replace(/,/g, ', ');

    public onSubmit = async (values : IAccreditationFormValues) => {
        this.setLoading(true);

        try {
            const res = await AccreditationHttpService.addOrUpdateAccreditation(new Accreditation(values));

            const newAccreditationList = upsertArrayElement(this.props.accreditations, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetAccreditations(newAccreditationList);
            await setAccreditationMasterDataIndexedDB(newAccreditationList);

            if (this.state.selectedAccreditation) {
                generalShowSuccessSnackbar('Accreditation updated successfully.');
            } else {
                generalShowSuccessSnackbar('Accreditation added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating accreditation data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (accreditation : IAccreditation) => {
        this.setState({ isDeletePopupOpen: true, deletingAccreditation: accreditation });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingAccreditation: undefined });
    };

    private removeAccreditation = async () => {
        const newAccreditation = this.state.deletingAccreditation;
        if (newAccreditation) {
            newAccreditation.isActive = false;
            this.setLoading(true);

            try {
                const res = await AccreditationHttpService.deleteAccreditation(newAccreditation.id);

                const newAccreditationList = upsertArrayElement(this.props.accreditations, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetAccreditations(newAccreditationList);
                await setAccreditationMasterDataIndexedDB(newAccreditationList);

                generalShowSuccessSnackbar('Accreditation updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting accreditation.');
                newAccreditation.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private getRows = () => {
        return this.props.accreditations;
    };

    public onReset = async (formValues : IAccreditationFormValues, formikActions : FormikActions<IAccreditationFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    public getSelectedAccreditation = (props : IAccreditationScreenProps, state : IAccreditationScreenState) => state.selectedAccreditation;
    public getFarms = (props : IAccreditationScreenProps) => props.farms;

    public getInitialFormValues = createSelector(
        [this.getSelectedAccreditation, this.getFarms],
        (accreditation, farms) => {
            return new AccreditationFormValues(accreditation, farms);
        },
    );

    private getRights = (props : IAccreditationScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IAccreditationScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.getRows();
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Accreditation'
                    isEdit={!!this.state.selectedAccreditation}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={AccreditationFormValues.formSchema}
                        component={AccreditationForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IAccreditation>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editAccreditation}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(accreditation : IAccreditation) => accreditation.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Farms', field: 'farmIds', formatFunction: this.formatFarms, width: 200, enableFiltering: true, enableSorting: true },
                                { title: 'Issue Date', field: 'issueDate', type: 'date', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Expiration Date', field: 'expirationDate', type: 'date', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            fitWidthToPage
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IAccreditation) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Accreditation'}
                    open={this.state.isDeletePopupOpen}
                    message={'Are you sure you want to delete this accreditation?'}
                    onOkClicked={this.removeAccreditation}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        accreditations: state.masterData.accreditations,
        organizations: state.masterData.organizations,
        farms: state.masterData.farms,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetAccreditations, dataSetFarms }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccreditationScreen);

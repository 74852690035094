import * as React from 'react';
import CustomTable from '../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetSalesPoints, dataSetFarms } from '../../../store/masterData/Actions';
import { formatDateTime, upsertArrayElement } from '../../../services/appFunctionsService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikActions } from 'formik';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { ISalesPoint, SalesPoint } from '../../../@types/model/masterData/salesPoint/salesPoint';
import { ISalesPointFormValues, SalesPointFormValues } from '../../../@types/model/masterData/salesPoint/salesPointFormValues';
import SalesPointHttpService from '../../../services/http/masterData/salesPointHttpService';
import { IFarm } from '../../../@types/model/masterData/farm/farm';
import FarmHttpService from '../../../services/http/masterData/farmHttpService';
import SalesPointForm from './form/SalesPointForm';
import { setSalesPointMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface ISalesPointScreenProps extends RouteComponentProps {
    dataSetSalesPoints : DispatchCall<Array<ISalesPoint>>;
    dataSetFarms : DispatchCall<Array<IFarm>>;
    salesPointData : Array<ISalesPoint>;
    farms : Array<IFarm>;
    auth : IAuthState;
}

interface ISalesPointScreenState {
    rows : Array<ISalesPoint>;
    isLoading : boolean;
    selectedSalesPoint ?: ISalesPoint;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingSalesPoint ?: ISalesPoint;
}

class SalesPointScreen extends React.Component<ISalesPointScreenProps, ISalesPointScreenState> {
    constructor(props : ISalesPointScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedSalesPoint: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await SalesPointHttpService.getSalesPointData();
                const res2 = await FarmHttpService.getFarmData();

                this.props.dataSetSalesPoints(res.data);
                this.props.dataSetFarms(res2.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading sales points data.');
                this.setLoading(false);
            }
        }
    };

    public editSalesPoint = (salesPoint : ISalesPoint) => {
        this.setState({
            isDialogOpen: true,
            selectedSalesPoint: salesPoint,
        });
        this.openDialog();
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedSalesPoint: undefined,
        });
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private formatFarms = (farmIds : Array<number>) => {
        let string = this.props.farms
            .filter(x => farmIds.some(y => y === x.id))
            .map(x => x.code).toString().replace(/,/g, ', ');
        if (string.length > 85) {
            string = string.substr(0, 86) + '...';
        }
        return string;
    };

    public getSelectedSalesPoint = (props : ISalesPointScreenProps, state : ISalesPointScreenState) => state.selectedSalesPoint;
    public getFarms = (props : ISalesPointScreenProps) => props.farms;

    public getInitialFormValues = createSelector(
        [this.getSelectedSalesPoint, this.getFarms],
        (salesPoint : ISalesPoint, farms : Array<IFarm>) => {
            return new SalesPointFormValues(salesPoint, farms);
        },
    );

    public onReset = async (formValues : ISalesPointFormValues, formikActions : FormikActions<ISalesPointFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    public onSubmit = async (values : ISalesPointFormValues) => {
        this.setLoading(true);

        try {
            const res = await SalesPointHttpService.addOrUpdateSalesPoint(new SalesPoint(values));

            const newSalesPointList = upsertArrayElement(this.props.salesPointData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetSalesPoints(newSalesPointList);
            await setSalesPointMasterDataIndexedDB(newSalesPointList);

            if (this.state.selectedSalesPoint) {
                generalShowSuccessSnackbar('Sales point updated successfully.');
            } else {
                generalShowSuccessSnackbar('Sales point added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating sales point data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (salesPoint : ISalesPoint) => {
        this.setState({ isDeletePopupOpen: true, deletingSalesPoint: salesPoint });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingSalesPoint: undefined });
    };

    private removeSalesPoint = async () => {
        const newSalesPoint = this.state.deletingSalesPoint;
        if (newSalesPoint) {
            newSalesPoint.isActive = false;
            this.setLoading(true);

            try {
                const res = await SalesPointHttpService.deleteSalesPoint(newSalesPoint.id);

                const newSalesPointList = upsertArrayElement(this.props.salesPointData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetSalesPoints(newSalesPointList);
                await setSalesPointMasterDataIndexedDB(newSalesPointList);

                generalShowSuccessSnackbar('Sales point updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting sales point.');
                newSalesPoint.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private getRights = (props : ISalesPointScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : ISalesPointScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.salesPointData ? this.props.salesPointData : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Sales Point'
                    isEdit={!!this.state.selectedSalesPoint}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={SalesPointFormValues.formSchema}
                        component={SalesPointForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<ISalesPoint>
                            enableAdding={this.hasEditingRight(this.props)}
                            enableDeleting={(salesPoint : ISalesPoint) => salesPoint.isActive && this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editSalesPoint}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Farms', field: 'farmIds', formatFunction: this.formatFarms, width: 300, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : ISalesPoint) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Sales Point'}
                    open={this.state.isDeletePopupOpen}
                    message={'Are you sure you want to delete this sales point?'}
                    onOkClicked={this.removeSalesPoint}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        salesPointData: state.masterData.salesPoints,
        farms: state.masterData.farms,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetSalesPoints, dataSetFarms }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SalesPointScreen);

import * as React from 'react';
import { syncMasterData } from '../../../services/masterDataSyncService';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { connect } from 'react-redux';
import { IRootState, DispatchCall, RootAction } from '../../../@types/redux';
import PackScreen from './pack/PackScreen';
import SizeScreen from './size/SizeScreen';
import ColourScreen from './colour/ColourScreen';
import GradeScreen from './grade/GradeScreen';
import VarietyScreen from './variety/VarietyScreen';
import CommodityStateScreen from './commodityState/CommodityStateScreen';
import lodash from 'lodash';
import { IRouterMatch } from '../../../@types/router';
import { navPath } from '../../../store/nav/Actions';
import { dataSetCommodities, dataSetSelectedCommodity } from '../../../store/masterData/Actions';
import { Dispatch, bindActionCreators } from 'redux';
import { compareString, camelCase } from '../../../services/appFunctionsService';
import { ICommodity } from '../../../@types/model/masterData/commodity/commodity';
import CommodityHttpService from '../../../services/http/masterData/commodityHttpService';
import { generalShowErrorSnackbar } from '../../../store/general/Functions';
import TextInput from '../../../components/input/TextInput';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import OrchardScreen from './orchard/OrchardScreen';
import { RouteComponentProps } from 'react-router';
import Screen from '../../../components/Screen';
import SeasonScreen from './season/SeasonScreen';
import { getCommodityImage } from '../../../services/iconHelperService';
import materialTheme, { getThemeMode } from '../../../styles/materialTheme';

const themeMode = getThemeMode();

interface ICommoditySectionScreenProps extends RouteComponentProps {
    dataSetCommodities : DispatchCall<Array<ICommodity>>;
    dataSetSelectedCommodity : DispatchCall<ICommodity | undefined>;
    commodities : Array<ICommodity>;
    match : IRouterMatch;
    selectedOrganizationIds : Array<number>;
}

interface ICommoditySectionScreenState {
    isLoading : boolean;
    selectedCommodity ?: ICommodity;
    selectedTab : number;
    commoditySearchValue : string;
}

class CommoditySectionScreen extends React.Component<ICommoditySectionScreenProps, ICommoditySectionScreenState> {
    constructor(props : ICommoditySectionScreenProps) {
        super(props);

        this.state = {
            isLoading: false,
            selectedCommodity: undefined,
            selectedTab: this.getSelectedTab(),
            commoditySearchValue: '',
        };
    }

    private tabs = () => {
        const { match, history, location } = this.props;
        return({
            ['Variety'] : <VarietyScreen
                match={match}
                history={history}
                location={location}
                selectedCommodity={this.state ? this.state.selectedCommodity : undefined} />,
            ['Grade'] : <GradeScreen
                match={match}
                history={history}
                location={location}
                selectedCommodity={this.state ? this.state.selectedCommodity : undefined} />,
            ['Colour'] : <ColourScreen
                match={match}
                history={history}
                location={location}
                selectedCommodity={this.state ? this.state.selectedCommodity : undefined} />,
            ['Pack'] : <PackScreen
                match={match}
                history={history}
                location={location}
                selectedCommodity={this.state ? this.state.selectedCommodity : undefined} />,
            ['Size'] : <SizeScreen
                match={match}
                history={history}
                location={location}
                selectedCommodity={this.state ? this.state.selectedCommodity : undefined} />,
            ['Orchard'] : <OrchardScreen
                match={match}
                history={history}
                location={location}
                selectedCommodity={this.state ? this.state.selectedCommodity : undefined} />,
            ['Commodity State'] : <CommodityStateScreen
                match={match}
                history={history}
                location={location}
                selectedCommodity={this.state ? this.state.selectedCommodity : undefined} />,
            ['Season'] : <SeasonScreen
                match={match}
                history={history}
                location={location}
                selectedCommodity={this.state ? this.state.selectedCommodity : undefined} />,
        });
    };

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);

            try {
                const res = await CommodityHttpService.getCommodityData();

                this.props.dataSetCommodities(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading commodities.');
                this.setLoading(false);
            }
        }

        if (!this.props.match.params.section || this.props.match.params.section === 'Pack') {
            navPath('/masterData/management/commoditySection.pack');
        }
    };

    private getSelectedTab = () => {
        if (!this.props.match.params.section) {
            return 0;
        }
        const filteredTabNames =  Object.keys(this.tabs());
        const tabIndex = filteredTabNames.map((x, i) => this.props.match.params.section.split('.')[1].includes(camelCase(x)) ? i : undefined).filter(x => x !== undefined)[0];
        return tabIndex ? tabIndex : 0;
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    private onCommoditySelect = (commodityId : number) => {
        const newCommodity = this.state.selectedCommodity?.id === commodityId ? undefined : this.props.commodities.find(x => x.id === commodityId);
        this.setState({ selectedCommodity: newCommodity });
        this.props.dataSetSelectedCommodity(newCommodity);
    };

    public componentDidUpdate = (prevProps : ICommoditySectionScreenProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps && prevProps.match.params.section !== nextProps.match.params.section) {
            this.setState({ selectedTab: this.getSelectedTab() });
        }
    };

    private getRows = () => this.props.commodities.filter(x => x.isActive && x.organizationIds.some(y => this.props.selectedOrganizationIds?.some(z => z === y)));

    private getTabContents = (index : number) => lodash.values(this.tabs())[index];

    private handleChange = (event : React.ChangeEvent, selectedTab : number) => navPath(`/masterData/management/commoditySection.${camelCase(Object.keys(this.tabs())[selectedTab])}`);

    public render() {
        const commodities = this.getRows();
        const filteredTabNames =  Object.keys(this.tabs());
        if ((this.state.selectedTab && filteredTabNames && filteredTabNames.length < this.state.selectedTab)) {
            this.setState({ selectedTab: 0 });
        } else if (!filteredTabNames) {
            this.setState({ selectedTab: 0 });
        }

        return (
            <Screen isPadded={false} isScrollable={false} isLoading={this.state.isLoading}>
                <div className={'fdc hfill'}>
                    <div className={'fdr hfill'}>
                        <div className={'fdc flx1'}>
                            <div className={'posstick post0 zi1 bb1 cgray2'}>
                                <div className={'fdr p10'}>
                                    <TextInput
                                        name={'search-commodities'}
                                        label={'Search Commodities'}
                                        value={this.state.commoditySearchValue}
                                        onChange={(e) => {
                                            this.setState({ commoditySearchValue: e.target.value });
                                        }}
                                    />
                                </div>
                            </div>
                            <List component='nav' className={'hfill p5 oys'}>
                                {
                                    commodities.sort((x, y) => compareString(x.name, y.name)).map((element) => {
                                        const background = this.state.selectedCommodity?.id === element.id ? materialTheme.custom.commodityListItem.selected.background : '';
                                        const textColor = this.state.selectedCommodity?.id === element.id ? materialTheme.custom.commodityListItem.selected.text : materialTheme.custom.commodityListItem.default.text;
                                        return !!element && !!element.name && element.name.toUpperCase().includes(this.state.commoditySearchValue.toUpperCase()) &&
                                        <ListItem
                                            key={'commodity_' + element.id}
                                            style={{ backgroundColor: background, color: textColor }}
                                            button
                                            selected={this.state.selectedCommodity?.id === element.id}
                                            onClick={this.onCommoditySelect.bind(this, element.id)}>
                                            <img src={getCommodityImage(element.name)} height={30} width={30} className={'pr10' + (this.state.selectedCommodity?.id === element.id ? ' commodityListIconSelected' : '')} />{element.name}
                                        </ListItem>;
                                    })
                                }
                            </List>
                        </div>
                        <div className={'fdc flx5 oxh'}>
                            <TabContext value={this.state.selectedTab.toString()}>
                                <TabList onChange={this.handleChange} indicatorColor={themeMode === 'light' ? 'primary' : 'secondary'} textColor={themeMode === 'light' ? 'primary' : 'secondary'}>
                                    {!!filteredTabNames && filteredTabNames.map((tabName, index) => <Tab key={'tab' + tabName} label={`${tabName === 'Pack' ? 'Pack Configuration' : tabName}`} value={index.toString()} />)}
                                </TabList>
                                {!!filteredTabNames && filteredTabNames.map((tabName, index) => <TabPanel style={{ height: 'calc(100% - 48px)' }} key={'tabPanel' + tabName} value={index.toString()}>{this.getTabContents(index)}</TabPanel>)}
                            </TabContext>
                        </div>
                    </div>
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        packData: state.masterData.packs,
        commodities: state.masterData.commodities,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetCommodities, dataSetSelectedCommodity }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CommoditySectionScreen);

import * as React from 'react';
import { FormControl, FormHelperText, PropTypes, TextField } from '@mui/material';
import { asField, FieldContext, FieldProps, FormValues } from 'informed';
import { IFormValue } from '../../@types/other';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formatMomentToDatePicker } from '../../services/appFunctionsService';
import moment from 'moment';
import { DATEPICKER_FORMAT_DEFAULT } from '../../appConstants';

interface ICustomDatePickerProps extends FieldProps<IFormValue, FormValues> {
    onChange ?: (event : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => void;
    onBlur ?: (event : React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    initialValue ?: any;
    id ?: any;
    disabled ?: boolean;
    type ?: any;
    label ?: string;
    margin ?: PropTypes.Margin;
    className ?: string;
    field : string;
    forwardedRef ?: React.RefObject<HTMLInputElement>;
    style ?: React.CSSProperties;
    defaultValue ?: string;
}

class CustomDatePicker extends React.Component<ICustomDatePickerProps & FieldContext<IFormValue>> {

    constructor(props : ICustomDatePickerProps & FieldContext<IFormValue>) {
        super(props);
    }

    public render() {
        const { onChange, id, label, margin, className, required, fieldState, fieldApi, disabled } = this.props;

        const { value, error } = fieldState;
        const { setValue, setTouched } = fieldApi;
        return (
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <>
                    <FormControl margin={margin} className={className} required={required}>
                        <div className={'fdr'}>
                            <DatePicker
                                renderInput={inputProps => <TextField
                                    id={id}
                                    label={label}
                                    error={
                                        (!!required && !!!fieldApi.getValue()) ||
                                            !!error
                                    }
                                    {...inputProps}
                                />}
                                inputFormat='YYYY-MM-DD'
                                disabled={disabled}
                                className={'flx1'}
                                value={value && typeof(value) === 'string' ? moment(value, DATEPICKER_FORMAT_DEFAULT) : formatMomentToDatePicker(moment().utc().startOf('day'))}
                                onChange={(e) => {
                                    setValue(moment(e, DATEPICKER_FORMAT_DEFAULT).format(DATEPICKER_FORMAT_DEFAULT));
                                    setTouched(true);
                                    if (!!onChange) {
                                        onChange(e);
                                    }
                                }}
                            />
                        </div>
                        {
                            !!this.props.children && this.props.children
                        }
                        {
                            !!fieldApi && !!error ? (
                                <FormHelperText error>{error}</FormHelperText>
                            ) : null
                        }
                    </FormControl>
                </>
            </LocalizationProvider>
        );
    }
}

export default asField<ICustomDatePickerProps>(CustomDatePicker);

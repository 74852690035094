import * as Yup from 'yup';
import { IPackCategory } from './packCategory';

export interface IPackCategoryFormValues {
    id : number;
    guid : string;
    code : string;
    category : string;
    isActive : boolean;
}

export class PackCategoryFormValues implements IPackCategoryFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public category : string;
    public isActive : boolean;

    public constructor(packCategory ?: IPackCategory) {
        this.initializeDefaultValues();
        if (packCategory) {
            this.id = packCategory.id;
            this.guid = packCategory.guid;
            this.code = packCategory.code;
            this.category = packCategory.category;
            this.isActive = packCategory.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.category = '';
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required').typeError('Cannot be empty'),
        category: Yup.string().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

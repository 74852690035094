import { IBaseModel } from '../../../model';
import { IRegionFormValues } from './regionFormValues';

export interface IRegion extends IBaseModel {
    organizationIds : Array<number>;
    code : string;
    name : string;
}

export class Region implements IRegion {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public name : string;
    public organizationIds : Array<number>;

    public constructor(formValues : IRegionFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.code = formValues.code;
        this.name = formValues.name;
        this.organizationIds = formValues.organizations.map(x => Number(x.value));
    }
}

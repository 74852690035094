import { IBaseModel } from '../../../model';
import { ICommodityConditionFormValues } from './commodityConditionFormValues';

export interface ICommodityCondition extends IBaseModel {
    condition : string;
    commodityIds : Array<number>;
}

export class CommodityCondition implements ICommodityCondition {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public condition : string;
    public commodityIds : Array<number>;

    public constructor(formValues : ICommodityConditionFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.condition = formValues.condition;
        this.commodityIds = formValues.selectedCommodities.map(x => Number(x.value));
    }
}

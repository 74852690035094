import * as React from 'react';
import { IRootState, IAuthState } from '../../@types/redux';
import { connect } from 'react-redux';
import { Dialog, AppBar, Toolbar, Typography, IconButton, Icon, Accordion, AccordionSummary, AccordionDetails, CircularProgress } from '@mui/material';
import OrderPlanningForm from './OrderPlanningForm';
import CustomTable from '../../components/table/CustomTable';
import { formatDateTimeToDateOnly } from '../../services/appFunctionsService';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import jQuery from 'jquery';
import { IOrderRequest } from '../../@types/model/order/orderRequest';
import { IOrderHeader } from '../../@types/model/order/orderHeader';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { ISize } from '../../@types/model/masterData/size/size';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { ISite } from '../../@types/model/masterData/site/site';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { IOrderPlan } from '../../@types/model/order/orderPlan';

interface IOrderPlanningScreenProps {
    selectedOrderRequest ?: IOrderRequest;
    auth : IAuthState;
    orderHeaderData : Array<IOrderHeader>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    sizes : Array<ISize>;
    grades : Array<IGrade>;
    colours : Array<IColour>;
    sites : Array<ISite>;
    organizations : Array<IOrganization>;
    onClose : () => void;
}

interface IOrderPlanningScreenState {
    isLoading : boolean;
    dataFetched : boolean;
    isEditing : boolean;
    orderPlans : Array<IOrderPlan>;
    editingOrderPlan ?: IOrderPlan;
}

class OrderPlanningScreen extends React.Component<IOrderPlanningScreenProps, IOrderPlanningScreenState> {
    constructor(props : IOrderPlanningScreenProps) {
        super(props);

        this.state = {
            isLoading: false,
            isEditing: false,
            dataFetched: false,
            orderPlans: [],
        };
    }

    public componentDidUpdate = (prevProps : IOrderPlanningScreenProps) => {
        const nextProps = this.props;
        if (nextProps && nextProps.selectedOrderRequest && (!prevProps || !prevProps.selectedOrderRequest)) {
            this.setState({ orderPlans: this.getOrderPlans() });
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    private getOrderPlans = () => this.props.selectedOrderRequest ? this.props.selectedOrderRequest.orderPlannedLines : [];

    private getOrderHeader = () => this.props.orderHeaderData.find(x => x.id === (this.props.selectedOrderRequest && this.props.selectedOrderRequest.orderId));

    private getOrderNumber = () => {
        const order = this.getOrderHeader();
        return order ? order.orderNumber : '';
    };

    private onOrderPlanEdit = (row : IOrderPlan) => {
        this.setState({ isEditing : true, editingOrderPlan: row });
    };

    private onOrderPlanningEditFormClose = () => {
        this.setState({
            isEditing: false,
            editingOrderPlan: undefined,
        });
    };

    private getCommodityName = (commodityId : number) => {
        const commodities = this.props.commodities;
        const commodity = commodities && commodities.find(x => x.id === commodityId);
        return commodity ? commodity.name : '';
    };

    private getVarietyName = (varietyId : number) => {
        const varieties = this.props.varieties;
        const variety = varieties && varieties.find(x => x.id === varietyId);
        return variety ? variety.name : '';
    };

    private getPackDescription = (packId : number) => {
        const packs = this.props.packs;
        const pack = packs && packs.find(x => x.id === packId);
        return pack ? pack.code : '';
    };

    private getSizeName = (sizeId : number) => {
        const sizes = this.props.sizes;
        const size = sizes && sizes.find(x => x.id === sizeId);
        return size ? size.name : '';
    };

    private getGradeName = (gradeId : number) => {
        const grades = this.props.grades;
        const grade = grades && grades.find(x => x.id === gradeId);
        return grade ? grade.name : '';
    };

    private getColourName = (colourId : number) => {
        const colours = this.props.colours;
        const colour = colours && colours.find(x => x.id === colourId);
        return colour ? colour.name : '';
    };

    private getSiteDescription = (siteId : number) => {
        const sites = this.props.sites;
        const site = sites && sites.find(x => x.id === siteId);
        return site ? site.description : '';
    };

    private getOrganizationName = (orgId : number) => {
        if (orgId === 0) {
            return '';
        }
        const organization = this.props.organizations.find(x => x.id === orgId);
        return organization && organization.name ? organization.name : '';
    };

    private getColumns = () => [
        { title: 'Commodity', field: 'commodityId', formatFunction: this.getCommodityName },
        { title: 'Variety', field: 'varietyId', formatFunction: this.getVarietyName },
        { title: 'Pack', field: 'packId', formatFunction: this.getPackDescription },
        { title: 'Size', field: 'sizeId', formatFunction: this.getSizeName },
        { title: 'Grade', field: 'gradeId', formatFunction: this.getGradeName },
        { title: 'Colour', field: 'colourId', formatFunction: this.getColourName },
        { title: 'Source Site', field: 'siteSourceId', formatFunction: this.getSiteDescription },
        { title: 'Destination Site', field: 'siteDestinationId', formatFunction: this.getSiteDescription },
        { title: 'Planning Quantity', field: 'quantity' },
    ];

    private pageScrolled = () => {
        const scrollArea = document.getElementById('page_scrollArea');
        const scrolledValue = scrollArea && scrollArea.scrollTop;
        const fixedElements = jQuery('div[id*=dropdown]');
        fixedElements.toArray().forEach((x) => {
            x.style.marginTop = scrolledValue ? (0 - scrolledValue).toString() + 'px' : '';
        });
    };

    private close = () => {
        this.props.onClose();
    };

    public render() {
        const selectedOrder = this.getOrderHeader();
        const selectedOrderRequest = this.props.selectedOrderRequest;
        return <Dialog open={!!this.props.selectedOrderRequest} fullScreen>
            <AppBar>
                <Toolbar>
                    <Typography variant='h6' color='inherit' className={'flx1'}>
                        {'Order ' + this.getOrderNumber() + ' - Planning'}
                    </Typography>
                    <IconButton color='inherit' aria-label='Close' onClick={this.close} disabled={this.state.isLoading}>
                        <Icon>close</Icon>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Toolbar />
            {this.state.isLoading &&
                <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                    <div className={'posr aic jcc h50 w50'}>
                        <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                            <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                        </div>
                        <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                    </div>
                </div>
            }
            <div id={'page_scrollArea'} className={'fdc flx1 p20 oya'} onScroll={this.pageScrolled}>
                <div className={'fdc'}>
                    <Typography className={'wfill StockHeading1 bocp'} color='inherit' variant='h6'>
                        Order Header Info
                    </Typography>
                    <div className={'fdr'}>
                        <Typography className={'flx1 FormHeading2 bocp'} color='inherit' variant='h6'>
                            Order Number
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 FormHeading2 bocp'} color='inherit' variant='h6'>
                            Client
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 FormHeading2 bocp'} color='inherit' variant='h6'>
                            Supplier
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 FormHeading2 bocp'} color='inherit' variant='h6'>
                            Order Date
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 FormHeading2 bocp'} color='inherit' variant='h6'>
                            Comment
                        </Typography>
                    </div>
                    <div className={'fdr mb10'}>
                        <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                            {selectedOrder && selectedOrder.orderNumber}
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                            {selectedOrder && this.getOrganizationName(selectedOrder.clientId)}
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                            {selectedOrder && this.getOrganizationName(selectedOrder.supplierId)}
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                            {selectedOrder && selectedOrder.orderDate && formatDateTimeToDateOnly(selectedOrder.orderDate)}
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                            {selectedOrder && selectedOrder.comment}
                        </Typography>
                    </div>
                    <Typography className={'wfill StockHeading1 bocp'} color='inherit' variant='h6'>
                        Order Request Info
                    </Typography>
                    <div className={'fdr'}>
                        <Typography className={'flx1 FormHeading2 bocp'} color='inherit' variant='h6'>
                            Commodity
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 FormHeading2 bocp'} color='inherit' variant='h6'>
                            Variety
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 FormHeading2 bocp'} color='inherit' variant='h6'>
                            Pack
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 FormHeading2 bocp'} color='inherit' variant='h6'>
                            Size
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 FormHeading2 bocp'} color='inherit' variant='h6'>
                            Grade
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 FormHeading2 bocp'} color='inherit' variant='h6'>
                            Colour
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 FormHeading2 bocp'} color='inherit' variant='h6'>
                            Requested Quantity
                        </Typography>
                    </div>
                    <div className={'fdr mb10'}>
                        <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                            {selectedOrderRequest && this.getCommodityName(selectedOrderRequest.commodityId)}
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                            {selectedOrderRequest && this.getVarietyName(selectedOrderRequest.varietyId)}
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                            {selectedOrderRequest && this.getPackDescription(selectedOrderRequest.packId)}
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                            {selectedOrderRequest && this.getSizeName(selectedOrderRequest.sizeId)}
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                            {selectedOrderRequest && this.getGradeName(selectedOrderRequest.gradeId)}
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                            {selectedOrderRequest && this.getColourName(selectedOrderRequest.colourId)}
                        </Typography>
                        <span className={'w10'} />
                        <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                            {selectedOrderRequest && selectedOrderRequest.quantity}
                        </Typography>
                    </div>
                </div>
                <Typography className={'wfill StockHeading1 bocp'} color='inherit' variant='h6'>
                    Order Planning
                </Typography>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography>Capture Order Plan</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={'wfill'}>
                            <OrderPlanningForm
                                orderRequest={this.props.selectedOrderRequest}
                                isAdding
                                onClose={() => null}/>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <div className={'flx1 fdr pt20 pb20'}>
                    <CustomTable<IOrderPlan>
                        columns={this.getColumns()}
                        rows={this.getOrderPlans()}
                        fitWidthToPage
                        editFunction={this.onOrderPlanEdit}
                        enableEditing={() => true}
                    />
                </div>
            </div>
            <Dialog open={this.state.isEditing} fullScreen>
                <AppBar>
                    <Toolbar>
                        <Typography variant='h6' color='inherit' className={'flx1'}>
                            {'Order ' + this.getOrderNumber() + ' - Editing Order Plan'}
                        </Typography>
                        <IconButton color='inherit' aria-label='Close' onClick={this.onOrderPlanningEditFormClose} disabled={this.state.isLoading}>
                            <Icon>close</Icon>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Toolbar />
                <div className={'flx1 fdc p20'}>
                    <OrderPlanningForm
                        orderRequest={this.props.selectedOrderRequest}
                        selectedOrderPlan={this.state.editingOrderPlan}
                        isEditing
                        onClose={this.onOrderPlanningEditFormClose}/>
                </div>
            </Dialog>
        </Dialog>;
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth : state.auth,
        orderHeaderData: state.data.orderHeaders,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        sizes: state.masterData.sizes,
        grades: state.masterData.grades,
        colours: state.masterData.colours,
        sites: state.masterData.sites,
        organizations: state.masterData.organizations,
    };
};

export default connect(
    mapStateToProps,
)(OrderPlanningScreen);

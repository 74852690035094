import * as React from 'react';
import { Form, FormikProps, FieldProps } from 'formik';
import { PackFormValues } from '../../../../../@types/model/masterData/pack/packFormValues';
import PillButton from '../../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../../components/input/form/FormSingleToggleButton';
import { IRootState } from '../../../../../@types/redux';
import { connect } from 'react-redux';
import FormAutocompleteSelect from '../../../../../components/input/form/FormAutoCompleteSelect';
import { createSelector } from 'reselect';
import { IOptionType, CustomChangeEvent } from '../../../../../@types/helper';
import { ICommodity } from '../../../../../@types/model/masterData/commodity/commodity';
import { IPackCategory } from '../../../../../@types/model/masterData/pack/packCategory';
import { ISize } from '../../../../../@types/model/masterData/size/size';
import { Card, Icon, Typography } from '@mui/material';
import { floor } from 'lodash';
import TextInput from '../../../../../components/input/TextInput';
import PackmanDialog from '../../../../../components/dialog/PackmanDialog';
import AutocompleteSelect from '../../../../../components/input/AutoCompleteSelect';
import { IMaterial } from '../../../../../@types/model/masterData/material/material';
import { generalShowErrorSnackbar } from '../../../../../store/general/Functions';
import { IBillOfMaterials } from '../../../../../@types/model/masterData/pack/billOfMaterials';
import { addArrayElement, upsertArrayElement } from '../../../../../services/appFunctionsService';
import CustomTable from '../../../../../components/datagrid/CustomTable';
import uuidv1 from 'uuid';
import ConfirmationPrompt from '../../../../../components/dialog/ConfirmationPrompt';
import CustomTooltip from '../../../../../components/tooltip/tooltip';

interface IPackFormProps {
    isLoading : boolean;
    commodityOptions : Array<IOptionType>;
    categoryOptions : Array<IOptionType>;
    sizeOptions : Array<IOptionType>;
    materials : Array<IMaterial>;
}

interface IPackFormState {
    openBillOfMaterialsPopup : boolean;
    selectedMaterial ?: IOptionType;
    numberOfUnits : number;

    openDeleteConfirmation : boolean;
    billEntryToDelete ?: IBillOfMaterials;
}

type PackFormPropsType = IPackFormProps & FormikProps<PackFormValues>;

class PackForm extends React.Component<PackFormPropsType, IPackFormState> {

    constructor(props : PackFormPropsType) {
        super(props);

        this.state = {
            openBillOfMaterialsPopup: false,
            numberOfUnits: 0,

            openDeleteConfirmation: false,
        };
    }

    private balanceWeights = (e : CustomChangeEvent, fieldProps : FieldProps<PackFormValues>) => {
        this.props.setFieldValue(fieldProps.field.name, e.currentTarget.value);
        const values = { ...this.props.values };

        const hasInner = values.hasInnerPack;

        const inputNettWeight = Number(!hasInner ?
            (fieldProps.field.name === 'nettWeight' ? Number(e.currentTarget.value) : values.nettWeight)
            :
            (fieldProps.field.name === 'unitNettWeight' ? Number(e.currentTarget.value) : values.unitNettWeight));

        const packagingWeight = Number(fieldProps.field.name === 'packagingWeight' ? Number(e.currentTarget.value) : values.packagingWeight);
        const noUnitsPerCarton = Number(fieldProps.field.name === 'noUnitsPerCarton' ? Number(e.currentTarget.value) : values.noUnitsPerCarton);
        const unitPackagingWeight = Number(fieldProps.field.name === 'unitPackagingWeight' ? Number(e.currentTarget.value) : values.unitPackagingWeight);
        const unitGrossWeight = hasInner ? Number((inputNettWeight ?? 0) + (unitPackagingWeight ?? 0)) : undefined;
        const nettWeight = Number(hasInner ? ((inputNettWeight ?? 0) * (noUnitsPerCarton ?? 0)) : inputNettWeight);
        const grossWeight = Number((hasInner ? ((unitGrossWeight ?? 0) * (noUnitsPerCarton ?? 0)) : (nettWeight ?? 0)) + packagingWeight);
        this.props.setFieldValue('grossWeight', grossWeight.toFixed(3));
        if (this.props.values.hasInnerPack) {
            this.props.setFieldValue('unitGrossWeight', unitGrossWeight?.toFixed(3));
            this.props.setFieldValue('nettWeight', nettWeight.toFixed(3));
        }
    };

    private onHasInnerPackChange = (selected : boolean, fieldProps : FieldProps<PackFormValues>) => {
        if (!selected) {
            fieldProps.form.setFieldValue('unitGrossWeight', '');
            fieldProps.form.setFieldValue('unitNettWeight', '');
            fieldProps.form.setFieldValue('unitPackagingWeight', '');
            fieldProps.form.setFieldValue('noUnitsPerCarton', '');
        }
    };

    private onIsIntakePackChange = (selected : boolean) => {
        if (!selected) {
            this.props.setFieldValue('numberOfStackedUnits', '');
        }
    };

    private getNumberOfObjects = (isBottom : boolean) => {
        let count = 0;
        const children = [];

        if (!this.props.values.noUnitsPerCarton) {
            return;
        }
        if (isBottom) {
            count = this.props.values.noUnitsPerCarton % 2 === 0 ? this.props.values.noUnitsPerCarton / 2 : this.props.values.noUnitsPerCarton / 2;
        } else {
            count = floor(this.props.values.noUnitsPerCarton / 2);
        }
        for (let i = 0; i < count; i++) {
            let value = this.props.values.unitGrossWeight ? this.props.values.unitGrossWeight * 100 : 0;
            if (value % 1) {
                value = parseFloat(value.toFixed(2));
            }

            children.push(
                <div className={'flx1 p5 ml5 mr5 jcc aic bcs br5 h30'}>
                </div>,
            );
        }
        return children;
    };

    private openBillOfMaterialsPopup = () => {
        this.setState({ openBillOfMaterialsPopup: true });
    };

    private closeBillOfMaterialsPopup = () => {
        this.setState({ openBillOfMaterialsPopup: false, selectedMaterial: undefined, numberOfUnits: 0 });
    };

    private getPackBillOfMaterials = (props : PackFormPropsType) => props.values.billOfMaterials;
    private getMaterials = (props : PackFormPropsType) => props.materials;

    private getSelectedMaterial = (props : PackFormPropsType, state : IPackFormState) => state.selectedMaterial;
    private getNumberOfUnits = (props : PackFormPropsType, state : IPackFormState) => state.numberOfUnits;

    private getMaterialOptions = createSelector(
        [this.getMaterials],
        (materials : Array<IMaterial>) => {
            if (!materials) return [];

            return materials.filter(x => x.isActive).map((x) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            });
        },
    );

    private onSelectedMaterialChange = (e : React.ChangeEvent<{}>, selectedMaterial : IOptionType) => {
        this.setState({ selectedMaterial });
    };

    private onNumberOfUnitsChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ numberOfUnits:  Number(e.target.value) });
    };

    private getPricePerUnit = createSelector(
        [this.getMaterials, this.getSelectedMaterial],
        (materials : Array<IMaterial>, selectedMaterial ?: IOptionType) => {
            const material = materials.find(x => x.id === selectedMaterial?.value);
            const currentlyActiveMaterialCost = material?.materialCosts.find(x => x.isActive && x.currentlyActive);

            return currentlyActiveMaterialCost ? currentlyActiveMaterialCost.amount : 0;
        },
    );

    private addNewMaterial = () => {
        const selectedMaterial = this.props.materials.find(x => x.id === this.state.selectedMaterial?.value);
        if (!selectedMaterial) {
            generalShowErrorSnackbar('No Material selected');
            return;
        } else if (this.state.numberOfUnits < 1) {
            generalShowErrorSnackbar('Number of units must be more then 0');
            return;
        } else {
            const existingBillOfMaterialEntry = this.props.values.billOfMaterials?.find(x => x.isActive && x.materialId === selectedMaterial.id);

            if (!!existingBillOfMaterialEntry) {
                generalShowErrorSnackbar(`Selected Material already exists. Please delete material ${selectedMaterial.name} before adding a new one`);
                return;
            }

            const newMaterial : IBillOfMaterials = {
                id: 0,
                guid: uuidv1(),
                packId: this.props.values.id,
                materialId: selectedMaterial.id,
                units: this.state.numberOfUnits,
                unitOfMeasureId: selectedMaterial.unitOfMeasureId,
                pricePerUnit: this.getPricePerUnit(this.props, this.state),
                isActive: true,
            };

            const newBillOfMaterialsList = addArrayElement(this.props.values.billOfMaterials ?? [], newMaterial);

            this.props.setFieldValue('billOfMaterials', newBillOfMaterialsList);

            this.setState({ selectedMaterial: undefined, numberOfUnits: 0 });
        }
    };

    private getBillOfMaterialsTableRows = createSelector(
        [this.getPackBillOfMaterials],
        (billOfMaterials : Array<IBillOfMaterials>) => {
            return billOfMaterials.sort((a, b) => {
                if (a.id === 0) {
                    return 1;
                } else if (b.id === 0) {
                    return 1;
                } else if (a.id > b.id) {
                    return -1;
                } else if (a.id < b.id) {
                    return 1;
                } else {
                    return 0;
                }
            });
        },
    );

    private getMaterial = (materialId : number) => {
        const material = this.props.materials.find(x => x.id === materialId);

        return material ? `(${material.code}) ${material.name}` : '';
    };

    private getAddNewMaterialTooltipTitle = createSelector(
        [this.getNumberOfUnits, this.getSelectedMaterial],
        (numberOfUnits : number, selectedMaterial ?: IOptionType) => {
            let message = '';
            if (!selectedMaterial) {
                message = 'Please select a material.';
            } else if (numberOfUnits < 1) {
                message = 'Please give a number of units larger then 0';
            } else {
                message = '';
            }
            return message;
        },
    );

    private getTotalPrice = (rowGuid : string) => {
        const row = this.props.values.billOfMaterials.find(x => x.guid === rowGuid);

        let totalPrice = 0;
        if (row) {
            totalPrice = row?.units * row?.pricePerUnit;
        }

        return totalPrice;
    };

    private openDeletePopup = (row : IBillOfMaterials) => {
        this.setState({ openDeleteConfirmation: true, billEntryToDelete: row });
    };

    private closeDeletePopup = () => {
        this.setState({ openDeleteConfirmation: false });
    };

    private removeBillEntry = () => {
        const row = { ...this.state.billEntryToDelete };

        row.isActive = false;

        const newBillOfMaterialsList = upsertArrayElement(this.props.values.billOfMaterials ?? [], row, x => x.guid === row.guid);

        this.props.setFieldValue('billOfMaterials', newBillOfMaterialsList);
        this.closeDeletePopup();
    };

    public render() {
        const hasInner = this.props.values.hasInnerPack;
        const isIntakePack = this.props.values.isIntakePack;
        return (
            <Form className={'fdc p20 hfill'}>
                <div className={'fdr flx1'}>
                    <div className={'fdc flx1'}>
                        <FormTextInput className={'flx1'} name={'code'} label={'Code'} />
                        <FormTextInput className={'flx1'} name={'description'} label={'Description'}/>
                        <FormTextInput className={'flx1'} name={'noCartons'} label={`No.${hasInner ? ' Outer' : ''} Cartons`}/>
                        <FormTextInput className={'flx1'} name={'packagingWeight'} label={`${hasInner ? 'Outer ' : ''}Packaging Weight`} onChange={this.balanceWeights}/>
                        <FormTextInput className={'flx1'} name={'grossWeight'} label={`${hasInner ? 'Outer ' : ''}Gross Weight`} disabled/>
                        <FormTextInput className={'flx1'} name={'nettWeight'}
                            label={`${hasInner ? 'Product ' : ''}Nett Weight ${hasInner ? 'in an outer' : ''}`} disabled={hasInner} onChange={!hasInner ? this.balanceWeights : undefined}/>
                    </div>
                    <div className={'fdc flx1'}>
                        { <FormTextInput disabled={!hasInner} className={'flx1'} name={'noUnitsPerCarton'} label={'No. Inner Units'} onChange={this.balanceWeights}/> }
                        { <FormTextInput disabled={!hasInner} className={'flx1'} name={'unitPackagingWeight'} label={'Inner Packaging Weight'} onChange={this.balanceWeights}/> }
                        { <FormTextInput className={'flx1'} name={'unitGrossWeight'} label={'Inner Gross Weight'} disabled/> }
                        { <FormTextInput disabled={!hasInner} className={'flx1'} name={'unitNettWeight'} label={'Inner Nett Weight'} onChange={this.balanceWeights}/> }
                        <FormAutocompleteSelect className={'flx1'} name={'commodity'} label={'Commodity'} options={this.props.commodityOptions}/>
                        <FormAutocompleteSelect className={'flx1'} name={'category'} label={'Category'} options={this.props.categoryOptions}/>
                    </div>
                    <div className={'fdc flx1 aifs jcfs'}>
                        <FormAutocompleteSelect name={'sizes'} className={'wfill'} label={'Sizes'} options={this.props.sizeOptions} isMulti />
                        <div className={'fdr '}>
                            <FormSingleToggleButton name={'hasInnerPack'} label={'Has Inner Pack'} onChange={this.onHasInnerPackChange} />
                            <FormSingleToggleButton name={'isIntakePack'} label={'Is Intake Pack'} onChange={this.onIsIntakePackChange} />
                            <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                        </div>
                        <FormTextInput disabled={!isIntakePack} className={'flx1'} name={'numberOfStackedUnits'} label={'No. Stacked Units'}/>
                        <div className={'flx1'}/>
                        <div className={'fdr wfill mb10'}>
                            <div className={'flx1'}/>
                            <PillButton
                                disabled={this.props.isLoading}
                                className={'ml15 pl20 pr20 h35'}
                                text={'Bill Of Materials'}
                                color={'secondary'}
                                onClick={this.openBillOfMaterialsPopup}
                            />
                        </div>
                    </div>
                </div>
                <div className={'flx1 fdc ml10 mr10'}>
                    <div className={'fdr wfill bcg1'}>
                        <div className={'cp pt10 pb10 pl10 fw700'}>
                        Carton Preview
                        </div>
                    </div>
                    <div className={'fdr hfill wfill jcc aic'}>
                        <div className={'fdr wfill aife jcfe pr10 posr'} >
                            <img src={`${ASSET_BASE}/assets/images/pallet_image.svg`} />
                            <div className={'posa post50 w427'}>
                                <div className={'fdr pl20 pr20'}>
                                    {this.getNumberOfObjects(false)}
                                </div>
                            </div>
                            <div className={'posa post95 w427'}>
                                <div className={'fdr pl20 pr20'}>
                                    {this.getNumberOfObjects(true)}
                                </div>
                            </div>
                        </div>
                        <div className={'fdr wfill pl10'}>
                            <div className={'fdc jcc aic'}>
                                <Typography>Total Cartons on pallet:</Typography>
                                <div className='h50 w50 brh jcc aic mt10 mb20 fw500 cw bcp'>{this.props.values.noCartons ? this.props.values.noCartons : 0}</div>
                                <Typography>Weight per inner (g):</Typography>
                                <div className='h50 w50 brh jcc aic mt10 mb20 fw500 bcs'>{this.props.values.unitGrossWeight ? (this.props.values.unitGrossWeight * 100).toFixed(2)  :  0}</div>
                            </div>
                            <div className={'fdc jcc aic'}>
                                <Typography>Total inners per carton:</Typography>
                                <div className='h50 w50 brh jcc aic mt10 mb20 fw500 bcs'>{this.props.values.noUnitsPerCarton ? this.props.values.noUnitsPerCarton : 0}</div>
                                <Typography>Total inners weight per carton (kg):</Typography>
                                <div className='h50 w50 brh jcc aic mt10 mb20 fw500 cw bcp'>{(this.props.values.unitGrossWeight && this.props.values.noUnitsPerCarton) ? (this.props.values.unitGrossWeight * this.props.values.noUnitsPerCarton).toFixed(2) : 0}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
                {!!this.state.openBillOfMaterialsPopup &&
                    <PackmanDialog
                        title='Bill Of Material'
                        isInfo
                        maxWidth={'md'}
                        isOpen={this.state.openBillOfMaterialsPopup}
                        onClose={this.closeBillOfMaterialsPopup}>
                        <div className={'fdc w850 p20'}>
                            <div className={'fdr'}>
                                <AutocompleteSelect
                                    className={'flx1 pb5'}
                                    name={'material'}
                                    label={'Material'}
                                    options={this.getMaterialOptions(this.props)}
                                    onChange={this.onSelectedMaterialChange}
                                    value={this.state.selectedMaterial}
                                />
                                <TextInput
                                    label={'Units'}
                                    type={'number'}
                                    value={this.state.numberOfUnits}
                                    name={'units'}
                                    className={'flx1'}
                                    onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.onNumberOfUnitsChange(e)}
                                />
                                <TextInput
                                    label={'Price Per Unit (in Rand)'}
                                    type={'number'}
                                    value={this.getPricePerUnit(this.props, this.state)}
                                    name={'pricePerUnit'}
                                    className={'flx1'}
                                    disabled={true}
                                />
                                <CustomTooltip title={this.getAddNewMaterialTooltipTitle(this.props, this.state)}>
                                    <div className={'aic jcc'}>
                                        <PillButton
                                            text={''}
                                            icon={<Icon className={'cpd p0 m0'} fontSize={'large'}>
                                                        add_circle_outline
                                            </Icon>}
                                            color={'secondary'}
                                            size={'small'}
                                            className={'p0'}
                                            disabled={(!this.state.selectedMaterial || this.state.numberOfUnits < 1)}
                                            onClick={() => this.addNewMaterial()}/>
                                    </div>
                                </CustomTooltip>
                            </div>
                            <Card elevation={1} className={'aic fdc wfill oya'}>
                                <CustomTable<IBillOfMaterials>
                                    enableFiltering
                                    enableSorting
                                    enableDeleting={(row : IBillOfMaterials) => row.isActive}
                                    fitWidthToPage
                                    deleteFunction={(row : IBillOfMaterials) => this.openDeletePopup(row)}
                                    columns={[
                                        { title: 'Material', field: 'materialId', formatFunction: this.getMaterial, enableFiltering: true, enableSorting: true, width: 300 },
                                        { title: 'Number of Units', field: 'units' },
                                        { title: 'Price Per Unit (in Rand) ', field: 'pricePerUnit' },
                                        { title: ' Price (in Rand)', field: 'guid', formatFunction: this.getTotalPrice },
                                    ]}
                                    rows={this.getBillOfMaterialsTableRows(this.props)}
                                    pageHeight={200}
                                    isActive={(row : IBillOfMaterials) => row.isActive}
                                />
                            </Card>
                        </div>
                    </PackmanDialog >
                }
                <ConfirmationPrompt
                    title={'Delete Bill Entry'}
                    open={this.state.openDeleteConfirmation}
                    message={'Are you sure you want to delete this bill entry?'}
                    onOkClicked={this.removeBillEntry}
                    onCancelClicked={this.closeDeletePopup}
                />
            </Form>
        );
    }
}

const getCommodities = (state : IRootState) => state.masterData.commodities;

const getCommodityOptions = createSelector(
    [getCommodities],
    (commodities : Array<ICommodity>) => {
        return commodities.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }));
    },
);

const getCategories = (state : IRootState) => state.masterData.packCategories;

const getCategoryOptions = createSelector(
    [getCategories],
    (categories : Array<IPackCategory>) => {
        return categories.filter(x => x.isActive).map(x => ({ label: x.category, value: x.id }));
    },
);

const getSizes = (state : IRootState) => state.masterData.sizes;

const getSizeOptions = createSelector(
    [getSizes],
    (sizes : Array<ISize>) => {
        return sizes.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        commodityOptions: getCommodityOptions(state),
        categoryOptions: getCategoryOptions(state),
        sizeOptions: getSizeOptions(state),
        materials: state.masterData.materials,
    };
};

export default connect(
    mapStateToProps,
)(PackForm);

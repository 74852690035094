import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IOrganization } from '../../../@types/model/masterData/organization/organization';

export default class OrganizationHttpService {
    public static getOrganizationData() : AxiosPromise<Array<IOrganization>> {
        return axios.get(`${PACKMAN_API_URL}Organization/GetAllWeb`);
    }

    public static addOrUpdateOrganization(data : IOrganization) : AxiosPromise<IOrganization> {
        return axios.post(`${PACKMAN_API_URL}Organization/PostOrganization`, data);
    }

    public static deleteOrganization(organizationId : number) : AxiosPromise<IOrganization> {
        return axios.delete(`${PACKMAN_API_URL}Organization/Delete`, {
            params : { organizationId },
        });
    }
}

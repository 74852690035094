import * as React from 'react';
import { FormHelperText, Typography } from '@mui/material';
import PillButton from '../input/PillButton';
import Tooltip from '../tooltip/tooltip';
import { CustomChangeEvent } from '../../@types/helper';
import PackmanDialog from './PackmanDialog';
import TextInput from '../input/TextInput';

interface IDeleteConfirmationDialogProps {
    isLoading : boolean;
    onclose : () => void;
    onSubmit : (body : string) => Promise<void>;
    isOpen : boolean;
    title : string;
}

interface IDeleteConfirmationDialogState {
    body : string;
}

export default class DeleteConfirmationDialog  extends React.Component<IDeleteConfirmationDialogProps, IDeleteConfirmationDialogState> {

    constructor(props : IDeleteConfirmationDialogProps) {
        super(props);

        this.state = {
            body: '',
        };
    }

    private bodyChanged = (event : CustomChangeEvent) => {
        this.setState({
            body: event.currentTarget.value,
        });
    };

    public render() {
        return (
            <PackmanDialog
                title={`Delete ${this.props.title}`}
                maxWidth={'lg'}
                isInfo
                isLoading={this.props.isLoading}
                onClose={this.props.onclose}
                isOpen={this.props.isOpen}>
                <div className={'fdc wfill hfill p10'}>
                    <Typography variant={'body1'}>Please give a reason for deleting this {this.props.title}.</Typography>
                    <TextInput
                        multiline
                        rows={3}
                        name={'Delete Reason'}
                        label={'Reason'}
                        className={'flx1'}
                        disabled={this.props.isLoading}
                        onChange={this.bodyChanged}
                        value={this.state.body ?? ''}/>
                    <div className={'fdr jcfe'}>
                        <Typography>{`${this.state.body.length}/200`}</Typography>
                    </div>
                    { this.state.body.length < 200 ?
                        <FormHelperText className={'pl10'} error>{'Must be at least 200 characters.'}</FormHelperText>
                        : <div/>
                    }
                    <div className={'fdr pt20 pb10 wfill jcfe'}>
                        <Tooltip title={this.props.isLoading || this.state.body.length < 200 ? 'Must be at least 200 characters.' : ''}>
                            <PillButton
                                disabled={this.props.isLoading || this.state.body.length < 200}
                                className={'ml15 pl20 pr20 h35'}
                                text={'Submit'}
                                color={'secondary'}
                                onClick={() => this.props.onSubmit(this.state.body)}
                            />
                        </Tooltip>
                    </div>
                </div>
            </PackmanDialog>
        );
    }
}

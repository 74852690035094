import { IBaseModel } from '../../../model';
import { ISiteFormValues } from './siteFormValues';

export interface ISite extends IBaseModel {
    organizationIds : Array<number>;
    contactInfoIds : Array<number>;
    ftpDetailIds : Array<number>;
    code : string;
    description : string;
    shortDescription : string;
    isPackhouse : boolean;
    isDeliveryPoint : boolean;
    isExternal ?: boolean;
    handlesExport : boolean;
    isBRCCompliant ?: boolean;
    isSimple ?: boolean;
    canMixSalesPoint : boolean;
    inspectionPointId ?: number;
    latitude ?: number | string;
    longitude ?: number | string;
    exporterName ?: string;
    exporterEmail ?: string;
    globalGapNumber ?: string;
    address ?: string;
    telNumber ?: string;
    faxNumber ?: string;
    dispatchHeaderLogo ?: string;
    packhouseDateCode ?: string;
}

export class Site implements ISite {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public organizationIds : Array<number>;
    public ftpDetailIds : Array<number>;
    public contactInfoIds : Array<number>;
    public code : string;
    public description : string;
    public shortDescription : string;
    public type : string;
    public isPackhouse : boolean;
    public isDeliveryPoint : boolean;
    public isExternal ?: boolean;
    public handlesExport : boolean;
    public isBRCCompliant ?: boolean;
    public isSimple ?: boolean;
    public canMixSalesPoint : boolean;
    public inspectionPointId ?: number;
    public latitude ?: number | string;
    public longitude ?: number | string;
    public exporterName ?: string;
    public exporterEmail ?: string;
    public globalGapNumber ?: string;
    public address ?: string;
    public telNumber ?: string;
    public faxNumber ?: string;
    public dispatchHeaderLogo ?: string;
    public packhouseDateCode ?: string;

    public constructor(formValues : ISiteFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.organizationIds = formValues.organizations.map(x => Number(x.value));
        this.contactInfoIds = formValues.contactInfos.map(x => Number(x.value));
        this.ftpDetailIds = formValues.ftpDetails.map(x => Number(x.value));

        this.code = formValues.code;
        this.description = formValues.description;
        this.shortDescription = formValues.shortDescription;
        this.isPackhouse = formValues.isPackhouse;
        this.isDeliveryPoint = formValues.isDeliveryPoint;
        this.isExternal = formValues.isExternal;
        this.handlesExport = formValues.handlesExport;
        this.isBRCCompliant = formValues.isBRCCompliant;
        this.isSimple = formValues.isSimple;
        this.canMixSalesPoint = formValues.canMixSalesPoint;
        this.inspectionPointId = Number(formValues.inspectionPoint?.value) ?? undefined;
        this.latitude = formValues.latitude;
        this.longitude = formValues.longitude;
        this.exporterName = formValues.exporterName;
        this.exporterEmail = formValues.exporterEmail;
        this.globalGapNumber = formValues.globalGapNumber;
        this.address = formValues.address;
        this.telNumber = formValues.telNumber;
        this.faxNumber = formValues.faxNumber;
        this.dispatchHeaderLogo = formValues.dispatchHeaderLogo;
        this.packhouseDateCode = formValues.packhouseDateCode;
    }
}

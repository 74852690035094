import * as React from 'react';
import { Button } from '@mui/material';
import PillButton from '../input/PillButton';
import PackmanDialog from './PackmanDialog';

interface IConfirmationPromptProps {
    open : boolean;
    message : string | React.ReactElement;
    title ?: string;
    onOkClicked : () => void;
    onCancelClicked : () => void;
    disableYes ?: boolean;
    disableNo ?: boolean;
    isLoading ?: boolean;
    textColor ?: string;
}

export default class ConfirmationPrompt extends React.Component<IConfirmationPromptProps> {

    constructor(props : IConfirmationPromptProps) {
        super(props);

    }

    public render() {

        return (
            <PackmanDialog
                isInfo
                isOpen={this.props.open}
                title={this.props.title ? this.props.title : 'Confirm'}
                isLoading={this.props.isLoading}
                onClose={this.props.onCancelClicked} >
                <div className={'p20 fdc jcsb w500 h130'}>
                    <div className={'fdr aic jcc fs16'} style={ this.props.textColor ? { color: this.props.textColor } : undefined }>
                        {this.props.message}
                    </div>
                    <div className={'fdr aife jcfe wfill '}>
                        <Button className={'fwb h35'} variant='text' disabled={this.props.disableNo ? this.props.disableNo : false}
                            onClick={this.props.onCancelClicked}>
                            Cancel
                        </Button>
                        <PillButton color={'secondary'} className={'ml15 pl20 pr20 h35'} type={'submit'} text={'Ok'} onClick={this.props.onOkClicked} disabled={this.props.disableYes ? this.props.disableYes : false}/>
                    </div>
                </div>
            </PackmanDialog>
        );
    }
}

import { ICommodityState } from './commodityState';
import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { ICommodity } from '../commodity/commodity';

export interface ICommodityStateFormValues {
    id : number;
    guid : string;
    name : string;
    commodities : Array<IOptionType>;
    isActive : boolean;
}

export class CommodityStateFormValues implements ICommodityStateFormValues {
    public id : number;
    public guid : string;
    public name : string;
    public commodities : Array<IOptionType>;
    public isActive : boolean;

    public constructor(
        commodityState ?: ICommodityState,
        selectedCommodity ?: ICommodity,
        commodities ?: Array<ICommodity>,
    ) {
        this.initializeDefaultValues(selectedCommodity);
        if (commodityState) {
            this.id = commodityState.id;
            this.guid = commodityState.guid;
            this.name = commodityState.name;

            this.commodities = commodities?.filter(x => commodityState.commodityIds?.some(y => y === x.id))?.map(x => ({
                label: x.name,
                value: x.id,
            })) ?? [];

            this.isActive = commodityState.isActive;
        }
    }

    public initializeDefaultValues = (selectedCommodity ?: ICommodity) => {
        this.id = 0;
        this.guid = '';
        this.name = '';
        this.commodities = [];
        this.isActive = true;

        if (selectedCommodity) {
            this.commodities = [{
                label: selectedCommodity?.name ?? '',
                value: selectedCommodity?.id,
            }];
        }
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        commodities: Yup.array().of(Yup.object()).min(1, 'Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

import { IBaseModel } from '../../../model';
import { IOutletFormValues } from './outletFormValues';

export interface IOutlet extends IBaseModel {
    code : string;
    deviceMacAddress : string;
    packLineId : number;
    defaultPrintServerId ?: number;
    defaultPrinterId ?: number;
    defaultReportId ?: number;
    defaultExporterSiteId ?: number;
    defaultInventoryId ?: number;
}

export class Outlet implements IOutlet {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public deviceMacAddress : string;
    public packLineId : number;
    public defaultPrintServerId ?: number;
    public defaultPrinterId ?: number;
    public defaultReportId ?: number;
    public defaultExporterSiteId ?: number;
    public defaultInventoryId ?: number;

    public constructor(formValues : IOutletFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.code = formValues.code;
        this.deviceMacAddress = formValues.deviceMacAddress;
        this.packLineId = Number(formValues.packLine?.value);
        this.defaultPrintServerId = Number(formValues.defaultPrintServer?.value);
        this.defaultPrinterId = Number(formValues.defaultPrinter?.value);
        this.defaultReportId = Number(formValues.defaultReport?.value);
        this.defaultExporterSiteId = Number(formValues.defaultExporterSite?.value);
        this.defaultInventoryId = Number(formValues.defaultInventory?.value);
        this.isActive = formValues.isActive;
    }
}

import * as Yup from 'yup';
import { IDomain } from './domain';

export interface IDomainFormValues {
    id : number;
    guid : string;
    domainName : string;
    isActive : boolean;
}

export class DomainFormValues implements IDomainFormValues {
    public id : number;
    public guid : string;
    public domainName : string;
    public isActive : boolean;

    public constructor(domain ?: IDomain) {
        this.initializeDefaultValues();
        if (domain) {
            this.id = domain.id;
            this.guid = domain.guid;
            this.isActive = domain.isActive;

            this.domainName = domain.domainName;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.domainName = '';
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        domainName: Yup.string().matches(/^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/, 'Invalid Domain Name').required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

import * as React from 'react';
import { Link } from 'react-router-dom';
import materialTheme from '../../styles/materialTheme';


interface IPackmanLinkProps {
    type : 'transactions' | 'masterdata' | 'tripTransactions'; // TODO: make trips it's own category away from dispatches
    targetPage : 'stock' | 'dispatch' | 'compliance' | 'lot' | 'batch' | 'intake' | 'materialDispatch' | 'materialStock';
    id ?: number;
    text ?: string | number;
    isError ?: boolean;
    isWarning ?: boolean;
    color ?: 'green' | 'red';
}

export const PackmanLink = (props : IPackmanLinkProps) => {

    const linkColor =
                    (!!props.isError ? materialTheme.custom.packmanLink.error :
                        (!!props.isWarning ? materialTheme.custom.packmanLink.error : materialTheme.custom.packmanLink.main));

    return (
        <Link
            style={{ color: linkColor }}
            // className={`packmanLink${props.color ?? ''}`}
            to={`/${props.targetPage}/${props.type}/:${props.id}`}>
            {props.text}
        </Link>
    );
};

import { IMaterialType } from './materialType';
import * as Yup from 'yup';

export interface IMaterialTypeFormValues {
    id : number;
    guid : string;
    name : string;
    isPackType : boolean;
    isActive : boolean;
}

export class MaterialTypeFormValues implements IMaterialTypeFormValues {
    public id : number;
    public guid : string;
    public name : string;
    public isPackType : boolean;
    public isActive : boolean;

    public constructor(materialType ?: IMaterialType) {
        this.initializeDefaultValues();
        if (materialType) {
            this.id = materialType.id;
            this.guid = materialType.guid;
            this.name = materialType.name;
            this.isPackType = materialType.isPackType;
            this.isActive = materialType.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.name = '';
        this.isPackType = false;
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        isPackType: Yup.boolean().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

import axios from 'axios';
import { PACKMAN_API_URL } from '../../appConstants';
import { dispatch, getState } from '../../store/Index';
import { generalShowErrorSnackbar } from '../../store/general/Functions';
import { dataSetRoles } from '../../store/data/Actions';
import { IRole } from '../../@types/model/user/role';
import { dataSetRole } from '../../store/data/Functions';

const ROLE_API_PATH = `${PACKMAN_API_URL}Rights/Role/`;

export async function getList(name ?: string, code ?: string, isActive ?: boolean) : Promise<Array<IRole>> {
    try {
        const state = getState();

        if (!!state.data.rights.length) {
            return state.data.roles;
        }

        const result = await axios.get<Array<IRole>>(`${ROLE_API_PATH}GetList`, {
            params : {
                name,
                code,
                isActive,
            },
        });

        dispatch(dataSetRoles(result.data));
        return result.data;
    } catch (ex) {
        if (!!ex && ex.status === 400) {
            generalShowErrorSnackbar(ex.data);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403) {
            generalShowErrorSnackbar('Error getting roles');
        }
        return [];
    }
}

export async function getRole(roleId : number) : Promise<IRole> {
    try {
        const result = await axios.get<IRole>(`${ROLE_API_PATH}GetRole`, {
            params : {
                roleId,
            },
        });

        dataSetRole(result.data);
        return result.data;
    } catch (ex) {
        if (!!ex && ex.status === 400) {
            generalShowErrorSnackbar(ex.data);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403) {
            generalShowErrorSnackbar('Error getting roles');
        }
        return {} as IRole;
    }
}

export async function save(role : IRole) : Promise<IRole | null> {
    try {
        const result = await axios.post<IRole>(`${ROLE_API_PATH}Save`, role, {
            params : {
            },
        });

        return result.data;
    } catch (ex) {
        if (!!ex && ex.status === 400) {
            generalShowErrorSnackbar(ex.data);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403) {
            generalShowErrorSnackbar('Error creating role');
        }
        return null;
    }
}

export function getUserRole() : IRole | null {
    const state = getState();

    if (!!!state.auth.session) return null;
    if (!!!state.auth.session.user.role) return null;

    return state.auth.session.user.role;
}

export async function deleteRole(roleId : number) : Promise<boolean> {
    try {
        await axios.delete(`${ROLE_API_PATH}Delete`, {
            params : { roleId },
        });

        return true;
    } catch (ex) {
        if (!!ex && ex.status === 400 && typeof(ex.data) === 'string') {
            generalShowErrorSnackbar(ex.data);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403) {
            generalShowErrorSnackbar('Error deleting role');
        }
        return false;
    }
}

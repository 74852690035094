import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ILog } from '../../@types/model/logs/Log';
import { DispatchCall, IRootState, RootAction } from '../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetLogs } from '../../store/logs/Actions';
import { connect } from 'react-redux';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import LogHttpService from '../../services/http/general/logHttpService';
import moment from 'moment';
import Screen from '../../components/Screen';
import Card from '@mui/material/Card';
import CustomTable from '../../components/datagrid/CustomTable';
import { booleanToYesNo, compareDate, formatDateTime } from '../../services/appFunctionsService';
import TransactionFilter from '../../components/filters/BasicTransactionScreenFilter';
import { createSelector } from 'reselect';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import TextField from '@mui/material/TextField';
import PillButton from '../../components/input/PillButton';
import FarmMasterDataSyncHttpService from '../../services/http/general/farmMasterDataSyncHttpService';
import Typography from '@mui/material/Typography/Typography';
import CustomTooltip from '../../components/tooltip/tooltip';
import { BooleanFlag } from '../../components/label/BooleanFlag';

interface ILogScreenProps extends RouteComponentProps {
    dataSetLogs : DispatchCall<Array<ILog>>;
    logs : Array<ILog>;
}

interface ILogScreenState {
    isLoading : boolean;
    syncStatus : boolean;
    syncStatusCheckInterval : number;

    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;

    selectedErrorLog ?: ILog;
    interval ?: NodeJS.Timer;
}

class LogScreen extends React.Component<ILogScreenProps, ILogScreenState> {
    constructor(props : ILogScreenProps) {
        super(props);

        this.state = {
            isLoading: false,
            syncStatus: false,
            syncStatusCheckInterval: 10000, // miliseconds
            selectedFromDate: moment().local().startOf('day'),
            selectedToDate: moment().local().endOf('day'),
        };
    }

    public componentDidMount = async () => {
        try {
            this.setLoading(true);

            const res = await LogHttpService.getErrorLogs(this.getDate('from'), this.getDate('to'));
            const res2 = await FarmMasterDataSyncHttpService.getSyncStatus();

            this.props.dataSetLogs(res.data);
            this.setState({ syncStatus: res2.data });
        } catch (ex) {
            generalShowErrorSnackbar('Failed to load error logs');
        } finally {
            this.setLoading(false);
        }

        // Sets interval to check sync status every 10 seconds
        // const interval = setInterval(this.syncStatusCheck, this.state.syncStatusCheckInterval);
        // this.setState({ interval });
    };

    public componentWillUnmount() : void {
        // Needed to clear interval when component gets destroyed to prevent memory leaks
        if (this.state.interval) {
            clearInterval(this.state.interval);
            this.setState({ interval: undefined });
        }
    };

    private getDate = (type : 'from' | 'to') => {
        switch (type) {
            case 'from':
                return this.state.selectedFromDate.startOf('day').utc().unix() * 1000;
            case 'to':
                return this.state.selectedToDate.endOf('day').utc().unix() * 1000;
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private refreshData = async (noAnnounce ?: boolean) => {
        try {
            this.setLoading(true);

            const res = await LogHttpService.getErrorLogs(this.getDate('from'), this.getDate('to'));
            const res2 = await FarmMasterDataSyncHttpService.getSyncStatus();

            this.props.dataSetLogs(res.data);
            this.setState({ syncStatus: res2.data });
            if (!noAnnounce) {
                generalShowSuccessSnackbar('Error logs refreshed');
            }
        } catch (ex) {
            generalShowErrorSnackbar('Failed to load error logs');
        } finally {
            this.setLoading(false);
        }
    };

    private handleDateRangeChange = (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => {
        const selectedFromDate = changedBy === 'start' ? moment(start).startOf('day') : end < start ? moment(end).startOf('day') : moment(start).startOf('day');
        const selectedToDate = changedBy === 'end' ? moment(end).endOf('day') : start > end ? moment(start).endOf('day') : moment(end).endOf('day');
        this.setState({ selectedFromDate, selectedToDate });
    };

    private onApplyClick = async () => {
        await this.refreshData(true);
    };

    private getLogs = (props : ILogScreenProps) => props.logs;
    private getStatus = (props : ILogScreenProps, state : ILogScreenState) => state.syncStatus;

    private getRows = createSelector(
        [this.getLogs],
        (logs : Array<ILog>) => {
            return logs;
        },
    );

    private getSyncStatus = createSelector(
        [this.getStatus],
        (syncStatus : boolean) => {
            return syncStatus;
        },
    );

    private openStackTraceDialog = (selectedLog : ILog) => {
        this.setState({ selectedErrorLog: selectedLog });
    };

    private closeStackTraceDialog = () => {
        this.setState({ selectedErrorLog: undefined });
    };

    private syncStatusCheck = async () => {
        try {
            const res = await FarmMasterDataSyncHttpService.getSyncStatus();
            this.setState({ syncStatus: res.data });
        } catch (ex) {
            generalShowErrorSnackbar('Failed while trying to check sync status');
        }
    };

    private onSetSyncStatusClick = async () => {
        try {
            this.setLoading(true);

            const res = await FarmMasterDataSyncHttpService.setSyncStatus();
            this.setState({ syncStatus: res.data });
        } catch (ex) {
            if (!!ex && ex.status === 400) {
                generalShowErrorSnackbar(ex.data.Message ?? '');
            } else {
                generalShowErrorSnackbar('Failed to override sync status');
            }
        } finally {
            this.setLoading(false);
        }
    };

    private onFullSyncClick = async () => {
        try {
            this.setLoading(true);

            await FarmMasterDataSyncHttpService.triggerFullSync();
        } catch (ex) {
            if (!!ex && ex.status === 400) {
                generalShowErrorSnackbar(ex.data.Message ?? '');
            } else {
                generalShowErrorSnackbar('Failed to trigger full sync');
            }
        } finally {
            this.setLoading(false);
        }
    };

    private onLastUpdatedSyncClick = async () => {
        try {
            this.setLoading(true);

            await FarmMasterDataSyncHttpService.triggerLastUpdatedSync();
        } catch (ex) {
            if (!!ex && ex.status === 400) {
                generalShowErrorSnackbar(ex.data.Message ?? '');
            } else {
                generalShowErrorSnackbar('Failed to trigger last updated sync');
            }
        } finally {
            this.setLoading(false);
        }
    };

    public render() {
        return (
            <Screen isScrollable={false} isLoading={this.state.isLoading}>
                <div className={'fdc hfill'}>
                    <div className={'fdr jcsb aic'}>
                        <div className={'fdr'}>
                            <CustomTooltip
                                title={!this.state.syncStatus ? 'Sync status is already set to false' : 'This will change the sync status to false'}
                                children={
                                    <PillButton
                                        disabled={this.state.isLoading || !this.state.syncStatus}
                                        className={'ml15 pl20 pr20 h35'}
                                        text={'Override Sync Status'}
                                        color={'secondary'}
                                        onClick={this.onSetSyncStatusClick}
                                    />
                                }
                            />
                            <CustomTooltip
                                title={this.state.syncStatus ? 'Sync is currently running' : ''}
                                children={
                                    <PillButton
                                        disabled={this.state.isLoading || this.state.syncStatus}
                                        className={'ml15 pl20 pr20 h35'}
                                        text={'Full Sync'}
                                        color={'secondary'}
                                        onClick={this.onFullSyncClick}
                                    />
                                }
                            />
                            <CustomTooltip
                                title={this.state.syncStatus ? 'Sync is currently running' : ''}
                                children={
                                    <PillButton
                                        disabled={this.state.isLoading || this.state.syncStatus}
                                        className={'ml15 pl20 pr20 h35 mr20'}
                                        text={'Last Updated Sync'}
                                        color={'secondary'}
                                        onClick={this.onLastUpdatedSyncClick}
                                    />
                                }
                            />
                            <CustomTooltip
                                title={'The status gets refreshed every 10 seconds'}
                                className='fdr aic jcc'
                                children={
                                    <div className={'fdr aic flx1'}>
                                        <Typography className={'fs14 mr5'}>Is Sync Currently Running?</Typography>
                                        <BooleanFlag value={this.getSyncStatus(this.props, this.state)}/>
                                    </div>
                                }
                            />
                        </div>
                        <TransactionFilter
                            className={'pt10 mt10 mr20 mb10'}
                            selectedFromDate={this.state.selectedFromDate}
                            selectedToDate={this.state.selectedToDate}
                            handleDateRangeChange={this.handleDateRangeChange}
                            onApplyClick={this.onApplyClick} />
                    </div>
                    <Card className={'fdc'}>
                        <CustomTable<ILog>
                            enableDetails
                            detailFunction={this.openStackTraceDialog}
                            enableRefresh
                            refreshFunction={this.refreshData}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Message', field: 'message', enableFiltering: true, enableSorting: true },
                                { title: 'Exception Message', field: 'exceptionMessage', enableFiltering: true, enableSorting: true },
                                { title: 'Inner Exception Message', field: 'innerExceptionMessage', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={this.getRows(this.props)}
                            initialSortOrder={[{ columnName: 'createdOn_Created On', direction : 'desc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={300}
                            isActive={(row : ILog) => row.isActive}
                        />
                    </Card>
                    <PackmanDialog
                        title={'Error Log Stack Trace'}
                        isInfo
                        maxWidth={'lg'}
                        isLoading={this.state.isLoading}
                        isOpen={!!this.state.selectedErrorLog}
                        onClose={this.closeStackTraceDialog}>
                        <div className={'wfill hfill p10 mnw800'}>
                            <TextField
                                value={this.state.selectedErrorLog?.stackTrace ?? ''}
                                multiline
                                variant={'outlined'}
                                disabled
                                className={'wfill'}
                            />
                        </div>
                    </PackmanDialog >
                </div>
            </Screen>
        );
    }
};

const mapStateToProps = (state : IRootState) => {
    return {
        logs: state.log.logs,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetLogs }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LogScreen);

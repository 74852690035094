import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { OrchardFormValues } from '../../../../../@types/model/masterData/orchard/orchardFormValues';
import PillButton from '../../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../../components/input/form/FormSingleToggleButton';
import { IOptionType } from '../../../../../@types/helper';
import FormAutocompleteSelect from '../../../../../components/input/form/FormAutoCompleteSelect';
import { IRootState } from '../../../../../@types/redux';
import { createSelector } from 'reselect';
import { ICommodity } from '../../../../../@types/model/masterData/commodity/commodity';
import { connect } from 'react-redux';
import { IVariety } from '../../../../../@types/model/masterData/variety/variety';
import { IFarm } from '../../../../../@types/model/masterData/farm/farm';
import lodash from 'lodash';
import { IDepartment } from '../../../../../@types/model/masterData/department/department';
import { IProject } from '../../../../../@types/model/masterData/project/project';
import FormDateInput from '../../../../../components/input/form/FormDateInput';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import CustomTooltip from '../../../../../components/tooltip/tooltip';

interface IOrchardFormProps {
    isLoading : boolean;
    farms : Array<IFarm>;

    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    departments : Array<IDepartment>;
    projects : Array<IProject>;
    selectedOrganizationIds : Array<number>;
}

interface IOrchardFormState {
    selectedCommodityOption ?: IOptionType;
}

type OrchardFormPropsType = IOrchardFormProps & FormikProps<OrchardFormValues>;

class OrchardForm extends React.Component<OrchardFormPropsType, IOrchardFormState> {

    constructor(props : OrchardFormPropsType) {
        super(props);
        this.state = {};
    }

    public componentDidMount = () => {
        this.setState({ selectedCommodityOption: this.props.values.commodity });
    };

    public onCommodityChange = (selectedCommodityOption : IOptionType) => {
        this.setState({
            selectedCommodityOption,
        });
    };

    public getCommodities = (props : IOrchardFormProps) => props.commodities;
    public getFarms = (props : IOrchardFormProps) => props.farms;
    public getVarieties = (props : IOrchardFormProps) => props.varieties;
    public getDepartments = (props : IOrchardFormProps) => props.departments;
    public getProjects = (props : IOrchardFormProps) => props.projects;
    public getSelectedOrganizationIds = (props : IOrchardFormProps) => props.selectedOrganizationIds;
    public getSelectedCommodityOption = (props : IOrchardFormProps, state : IOrchardFormState) => state.selectedCommodityOption;

    public getCommodityOptions = createSelector(
        [this.getCommodities, this.getSelectedOrganizationIds],
        (commodities, selectedOrganizationIds) => {
            if (!commodities) return [];
            return lodash.filter(commodities, x => x.isActive && x.organizationIds.some(y => selectedOrganizationIds?.some(z => z === y))).map((x) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            });
        },
    );
    public getFarmOptions = createSelector(
        [this.getFarms, this.getSelectedOrganizationIds],
        (farms, selectedOrganizationIds) => {
            return farms.filter(x => x.isActive && x.organizationIds.some(y => selectedOrganizationIds?.some(z => z === y))).map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }));
        },
    );

    public getVarietyOptions = createSelector(
        [this.getVarieties, this.getSelectedCommodityOption],
        (varieties : Array<IVariety>, selectedCommodityOption : IOptionType) => {
            if (!varieties) return [];
            return lodash.filter(varieties, x => x.commodityId === selectedCommodityOption?.value && x.isActive).map((x) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            });
        },
    );

    public getDepartmentOptions = createSelector(
        [this.getDepartments],
        (departments : Array<IDepartment>) => {
            if (!departments) return [];
            return lodash.filter(departments, x => x.isActive).map((x) => {
                return { label: `(${x.code}) ${x.shortDescription}`, value: x.id };
            });
        },
    );

    public getProjectOptions = createSelector(
        [this.getProjects, this.getSelectedCommodityOption],
        (projects : Array<IProject>, selectedCommodityOption : IOptionType) => {
            if (!projects) return [];
            return lodash.filter(projects, x => x.isActive && x.commodityId === selectedCommodityOption?.value).map((x) => {
                return { label: x.number, value: x.id };
            });
        },
    );

    public render() {
        const isImported = this.props.values.isImported;
        const tooltipTitle = !!isImported ? 'Cannot change this field if orchard is imported' : '';
        return (
            <Form className={'p20 w500'}>
                <div className={'fdr'}>
                    <div className={'fdc flx1'}>
                        <CustomTooltip title={tooltipTitle}>
                            <FormTextInput name={'code'} label={'Code'} disabled={!!isImported}/>
                        </CustomTooltip>
                        <CustomTooltip title={tooltipTitle}>
                            <FormAutocompleteSelect name={'project'} label={'Project Number'} options={this.getProjectOptions(this.props, this.state)} disabled={!!isImported}/>
                        </CustomTooltip>
                        <FormTextInput name={'subBlock'} label={'Sub Block'}/>
                        <CustomTooltip title={tooltipTitle}>
                            <FormAutocompleteSelect name={'commodity'} label={'Commodity'} options={this.getCommodityOptions(this.props)} onChange={this.onCommodityChange} disabled={!!isImported}/>
                        </CustomTooltip>
                        <CustomTooltip title={tooltipTitle}>
                            <FormAutocompleteSelect name={'farm'} label={'Farm'} options={this.getFarmOptions(this.props)} disabled={!!isImported}/>
                        </CustomTooltip>
                        <CustomTooltip title={tooltipTitle}>
                            <FormAutocompleteSelect name={'department'} label={'Department'} options={this.getDepartmentOptions(this.props)} disabled={!!isImported}/>
                        </CustomTooltip>
                    </div>
                    <div className={'fdc flx1'}>
                        <CustomTooltip title={tooltipTitle}>
                            <FormTextInput name={'name'} label={'Name'} disabled={!!isImported}/>
                        </CustomTooltip>
                        <CustomTooltip title={tooltipTitle}>
                            <FormTextInput className={'pt5'} name={'landName'} label={'Land Name'} disabled={!!isImported}/>
                        </CustomTooltip>
                        <FormTextInput name={'complianceCode'} label={'Compliance Code'}/>
                        <CustomTooltip title={tooltipTitle}>
                            <FormAutocompleteSelect name={'defaultVariety'} label={'Default Variety'} options={this.getVarietyOptions(this.props, this.state)} disabled={!!isImported}/>
                        </CustomTooltip>
                        <CustomTooltip title={tooltipTitle}>
                            <FormTextInput className={'flx1 pt5'} name={'plantedHectares'} label={'Planted Hectares'} disabled={!!isImported}/>
                        </CustomTooltip>
                        <CustomTooltip title={tooltipTitle}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <FormDateInput name={'endedOn'} label={'Ended On'} variant={'standard'} className={'pt0 mt0'} disabled={!!isImported}/>
                            </LocalizationProvider>
                        </CustomTooltip>
                    </div>
                </div>
                <CustomTooltip title={tooltipTitle}>
                    <FormSingleToggleButton name={'isActive'} label={'Is Active'} disabled={!!isImported}/>
                </CustomTooltip>
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        departments: state.masterData.departments,
        projects: state.masterData.projects,
        farms: state.masterData.farms,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
    };
};

export default connect(
    mapStateToProps,
)(OrchardForm);

import { IGrade } from './grade';
import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { ICommodity } from '../commodity/commodity';

export interface IGradeFormValues {
    id : number;
    guid : string;
    code : string;
    name : string;
    commodities : Array<IOptionType>;
    isActive : boolean;
}

export class GradeFormValues implements IGradeFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public name : string;
    public commodities : Array<IOptionType>;
    public isActive : boolean;

    public constructor(
        grade ?: IGrade,
        selectedCommodity ?: ICommodity,
        commodities ?: Array<ICommodity>,
    ) {
        this.initializeDefaultValues(selectedCommodity);
        if (grade) {
            this.id = grade.id;
            this.guid = grade.guid;
            this.code = grade.code;
            this.name = grade.name;

            this.commodities = commodities?.filter(x => grade.commodityIds?.some(y => y === x.id))?.map(x => ({
                label: x.name,
                value: x.id,
            })) ?? [];

            this.isActive = grade.isActive;
        }
    }

    public initializeDefaultValues = (selectedCommodity ?: ICommodity) => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.name = '';
        this.commodities = [];
        this.isActive = true;

        if (selectedCommodity) {
            this.commodities = [{
                label: selectedCommodity?.name ?? '',
                value: selectedCommodity?.id,
            }];
        }
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        commodities: Yup.array().of(Yup.object()).min(1, 'Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../../appConstants';
import { IRecipe } from '../../../../@types/model/integration/recipe';
import { IRecipeTable } from '../../../../@types/model/integration/recipeTable';
import { IRecipeField } from '../../../../@types/model/integration/recipeField';

export default class RecipeHttpService {
    public static getRecipeData() : AxiosPromise<Array<IRecipe>> {
        return axios.get(`${PACKMAN_API_URL}Recipe`);
    }

    public static addOrUpdateRecipe(data : IRecipe) : AxiosPromise<IRecipe> {
        return axios.post(`${PACKMAN_API_URL}Recipe/PostRecipe`, data);
    }

    public static getTables() : AxiosPromise<Array<IRecipeTable>> {
        return axios.get(`${PACKMAN_API_URL}Recipe/Tables`);
    }

    public static getFields(tableName : string) : AxiosPromise<Array<IRecipeField>> {
        return axios.get(`${PACKMAN_API_URL}Recipe/Fields`, {
            params: {
                tableName,
            },
        });
    }

    public static testRecipe(recipeId : number, tableId : number) : AxiosPromise<string> {
        return axios.get(`${PACKMAN_API_URL}Recipe/Test`, {
            params: {
                recipeId,
                tableId,
            },
        });
    }
}

import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import * as httpServices from '../../services/httpService';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import { setLocalStorageSession } from '../../services/localStorageService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import PillButton from '../input/PillButton';
import PackmanDialog from './PackmanDialog';

interface IPasswordDialogProps {
    open : boolean;
}

interface IPasswordDialogState {
    isLoading : boolean;
    password : string;
    confirmPassword : string;
}

class PasswordDialog extends React.Component<IPasswordDialogProps, IPasswordDialogState> {

    constructor(props : IPasswordDialogProps) {
        super(props);

        this.state = {
            isLoading: false,
            password: '',
            confirmPassword: '',
        };
    }

    private handleChangePassword = (result : string) => {
        this.setState({
            password: result,
        });
    };

    private handleChangeConfirm = (result : string) => {
        this.setState({
            confirmPassword: result,
        });
    };

    private handleRefresh = async () => {
        this.setState({
            isLoading: true,
        });

        const result = await httpServices.refreshSession();

        if (!!result) {
            setLocalStorageSession(result);
        }

        this.setState({
            isLoading: false,
        });
    };

    private handleClose = async () => {

        if (this.state.password !== this.state.confirmPassword) {
            generalShowErrorSnackbar('Passwords do not match!');
            return;
        }

        this.setState({
            isLoading: true,
        });

        const result = await httpServices.setPassword(this.state.password);

        if (!!result) {
            generalShowSuccessSnackbar('Password has been set successfully.');
            setLocalStorageSession(result);
        }

        this.setState({
            isLoading: false,
        });
    };

    public render() {
        const { isLoading, password, confirmPassword } = this.state;

        return (
            <PackmanDialog
                isInfo
                isOpen={this.props.open}
                title='Create Password'>
                <div className='p20 fdc aic jcc'>
                    <div className={'fdr jcc aic wfill'}>
                    You need to create a password for your account.
                    </div>
                    <div className={'fdr jcs aifs wfill'}>
                        <FormControl>
                            <div className='fdr'>
                                <TextField
                                    className='mr5 flx1'
                                    required
                                    error={!password}
                                    autoFocus
                                    margin='dense'
                                    id='password'
                                    label='Password'
                                    type='password'
                                    value={password}
                                    onChange={event => this.handleChangePassword(event.target.value)}
                                />
                                <div className='fdc ml5 flx1'>
                                    <TextField
                                        required
                                        error={!confirmPassword}
                                        margin='dense'
                                        id='confirmPassword'
                                        label='Confirm Password'
                                        type='password'
                                        value={confirmPassword}
                                        onChange={event => this.handleChangeConfirm(event.target.value)}
                                    />
                                    {
                                        !password &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </div>
                            </div>
                        </FormControl>
                    </div>
                    <div className={'fdr aife jcfe wfill '}>
                        <Button color='primary' disabled={isLoading} onClick={this.handleRefresh}>
                            {!isLoading && <RefreshIcon style={{ marginRight: '10px' }}></RefreshIcon>}
                            {!!isLoading && <CircularProgress color='primary' size={24} style={{ marginRight: '10px' }}></CircularProgress>}
                                REFRESH
                        </Button>
                        <PillButton color={'secondary'} className={'ml15 h30 pt3 pl20 pr20 mb3'} type={'submit'} text={'SUBMIT'} onClick={this.handleClose} disabled={!!isLoading}/>
                    </div>
                </div>
            </PackmanDialog>);

        return (
            <div>
                <Dialog
                    open={this.props.open}
                    aria-labelledby='password-dialog-title'
                >
                    <DialogTitle id='password-dialog-title'>Create Password</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            You need to create a password for your account.
                        </DialogContentText>
                        <FormControl>
                            <TextField
                                required
                                error={!password}
                                autoFocus
                                margin='dense'
                                id='password'
                                label='Password'
                                type='password'
                                fullWidth
                                value={password}
                                onChange={event => this.handleChangePassword(event.target.value)}
                            />
                            <TextField
                                required
                                error={!confirmPassword}
                                margin='dense'
                                id='confirmPassword'
                                label='Confirm Password'
                                type='password'
                                fullWidth
                                value={confirmPassword}
                                onChange={event => this.handleChangeConfirm(event.target.value)}
                            />
                            {
                                !password &&
                                <FormHelperText error>Required</FormHelperText>
                            }
                        </FormControl>

                    </DialogContent>
                    <DialogActions>
                        <Button color='primary' disabled={isLoading} onClick={this.handleRefresh}>
                            {!isLoading && <RefreshIcon style={{ marginRight: '10px' }}></RefreshIcon>}
                            {!!isLoading && <CircularProgress color='primary' size={24} style={{ marginRight: '10px' }}></CircularProgress>}
                            REFRESH
                        </Button>
                        <Button color='primary' disabled={isLoading} onClick={this.handleClose}>
                            {!isLoading && <SaveIcon style={{ marginRight: '10px' }}></SaveIcon>}
                            {!!isLoading && <CircularProgress color='primary' size={24} style={{ marginRight: '10px' }}></CircularProgress>}
                            SUBMIT
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {
    };
};

export default connect(
    mapStateToProps,
)(PasswordDialog);

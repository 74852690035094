import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IVariety } from '../../../@types/model/masterData/variety/variety';

export default class VarietyHttpService {
    public static getVarietyData(onlyActive ?: boolean, ignoreIncludes ?: boolean) : AxiosPromise<Array<IVariety>> {
        return axios.get(`${PACKMAN_API_URL}Variety/GetAllWeb`, {
            params : {
                ignoreIncludes,
                onlyActive,
            },
        });
    }

    public static addOrUpdateVariety(data : IVariety) : AxiosPromise<IVariety> {
        return axios.post(`${PACKMAN_API_URL}Variety/PostVariety`, data);
    }

    public static deleteVariety(varietyId : number) : AxiosPromise<IVariety> {
        return axios.delete(`${PACKMAN_API_URL}Variety/Delete`, {
            params : { varietyId },
        });
    }
}

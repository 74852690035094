import * as React from 'react';
import { FieldArrayRenderProps, FieldArray } from 'formik';
import { createSelector } from 'reselect';
import { IntakeLineLayerUnitFormValues } from '../../../@types/model/intake/form/intakeLineLayerUnitFormValues';
import FormTextInput from '../../../components/input/form/FormTextInput';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { IVariety } from '../../../@types/model/masterData/variety/variety';
import { Accordion, AccordionSummary, Typography, AccordionDetails, Icon } from '@mui/material';
import FormSingleToggleButton from '../../../components/input/form/FormSingleToggleButton';
import { IPack } from '../../../@types/model/masterData/pack/pack';
import { ILot } from '../../../@types/model/lot/lot';
import { IOrchard } from '../../../@types/model/masterData/orchard/orchard';
import { IIntakeLineLayerUnit, IntakeLineLayerUnit } from '../../../@types/model/intake/intakeLineLayerUnit';
import { IOptionType } from '../../../@types/helper';

interface IIntakeLineLayerUnitFormProps {
    intakeLineIndex : number;
    intakeLineLayerIndex : number;
    updateWeight : (v : number, baseType ?: IOptionType) => void;
}

interface IIntakeLineLayerUnitFormState {
    expanded : Array<number>;
}

class IntakeLineLayerUnitForm extends React.Component<IIntakeLineLayerUnitFormProps, IIntakeLineLayerUnitFormState> {
    constructor(props : IIntakeLineLayerUnitFormProps) {
        super(props);
        this.state = {
            expanded: [],
        };
    }

    private toggleExpanded = (key : number) => {
        if (this.state.expanded.some(x => x === key)) {
            const array = [...this.state.expanded.filter(x => x !== key)];
            this.setState({ expanded: array });
        } else {
            this.setState({ expanded: [...this.state.expanded, key] });
        }

    };

    public removeLine = (renderProps : FieldArrayRenderProps, index : number) => {
        renderProps.form.setFieldValue(`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers.${this.props.intakeLineLayerIndex}.intakeLineLayerUnits.${index}.isActive`, false);
    };

    public render() {
        return (
            <FieldArray
                name={`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers.${this.props.intakeLineLayerIndex}.intakeLineLayerUnits`}
                render={(renderProps : FieldArrayRenderProps) => {
                    const intakeLineLayerUnits : Array<IntakeLineLayerUnit> = renderProps.form.values.intakeLines[this.props.intakeLineIndex].intakeLineLayers[this.props.intakeLineLayerIndex].intakeLineLayerUnits ?? [];
                    if (renderProps.form.values.intakeLines[this.props.intakeLineIndex].intakeLineLayers[this.props.intakeLineLayerIndex].intakeLineLayerUnits && renderProps.form.values.intakeLines[this.props.intakeLineIndex].intakeLineLayers[this.props.intakeLineLayerIndex].intakeLineLayerUnits.length === 0) {
                        renderProps.push(new IntakeLineLayerUnitFormValues());
                    }
                    return <div className={'fdc wfill aic asc jcc'}>
                        <>
                            {
                                intakeLineLayerUnits.map((intakeLineLayerUnit : IIntakeLineLayerUnit, index : number) => {
                                    return (
                                        <div className={' w90p pt10 pb10'}>
                                            <Accordion key={`${intakeLineLayerUnit.id}-${index}-intake-line-layer-unit-form`}>
                                                <AccordionSummary
                                                    expandIcon={<Icon className={'cw'}>expand_more</Icon>}
                                                    aria-controls='panel1a-content'
                                                    id='panel1a-header'
                                                    className='cw'
                                                    onClick={() => this.toggleExpanded(index)}
                                                >
                                                    <div className={'fdr wfill aic p0 m0'}>
                                                        <div className={'flx1'}>
                                                            <Typography className={'fw500 pl30'}>UNIT {index + 1}</Typography>
                                                        </div>
                                                        <div className='fdr aic pr30'>
                                                            <Typography className={'cw pr10 fs14'}>UNIT GROSS</Typography>
                                                            <div className={'cw bw1 br5 fs14'}>
                                                                <div className={'pl10 pr50'}>
                                                                    {intakeLineLayerUnit.unitGrossWeight} KG
                                                                </div>
                                                            </div>
                                                            <Typography className={'cw pr10 fs14 pl20'}>UNIT NETT</Typography>
                                                            <div className={'cw bw1 br5 fs14'}>
                                                                <div className={'pl10 pr50'}>
                                                                    {intakeLineLayerUnit.unitNettWeight} KG
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className='fdc wfill'>
                                                        <div className={'fdr aic'}>
                                                            <div className={'flx1 aic'}>
                                                                <FormTextInput
                                                                    name={`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers.${this.props.intakeLineLayerIndex}.intakeLineLayerUnits.${index}.unitGuid`}
                                                                    label={'Unit Guid'}
                                                                    className={'flx1 pt5'}
                                                                    disabled/>
                                                                <FormTextInput
                                                                    name={`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers.${this.props.intakeLineLayerIndex}.intakeLineLayerUnits.${index}.noOfUnits`}
                                                                    label={'No. Units'}
                                                                    onChange={() => this.props.updateWeight(this.props.intakeLineIndex)}
                                                                    className='flx1 pt5 pt5'
                                                                    disabled/>
                                                                <FormTextInput
                                                                    disabled={true}
                                                                    name={`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers.${this.props.intakeLineLayerIndex}.intakeLineLayerUnits.${index}.grossWeight`} label={'Gross Weight'} className='flx1 pt5'/>
                                                                <FormTextInput
                                                                    disabled={true}
                                                                    name={`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers.${this.props.intakeLineLayerIndex}.intakeLineLayerUnits.${index}.nettWeight`}
                                                                    label={'Nett Weight'}
                                                                    className='flx1 pt5'/>
                                                                <FormSingleToggleButton
                                                                    name={`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers.${this.props.intakeLineLayerIndex}.intakeLineLayerUnits.${index}.isActive`}
                                                                    label={'Is Active'}
                                                                    disabled/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    );
                                })
                            }
                        </>
                    </div>;
                }}
            />
        );
    }
}

const getVarieties = (state : IRootState) => state.masterData.varieties;

const getVarietyOptions = createSelector(
    [getVarieties],
    (varieties : Array<IVariety>) => {
        return varieties.map(x => ({ label: x.name, value: x.id }));
    },
);

const getPacks = (state : IRootState) => state.masterData.packs;

const getPackOptions = createSelector(
    [getPacks],
    (packs : Array<IPack>) => {
        return packs.map(x => ({ label: x.description, value: x.id }));
    },
);

const getLots = (state : IRootState) => state.data.lots;

const getLotOptions = createSelector(
    [getLots],
    (lots : Array<ILot>) => {
        return lots.map(x => ({ label: x.name, value: x.id }));
    },
);

const getOrchards = (state : IRootState) => state.masterData.orchards;

const getOrchardOptions = createSelector(
    [getOrchards],
    (orchards : Array<IOrchard>) => {
        return orchards.map(x => ({ label: x.name, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        varietyOptions: getVarietyOptions(state),
        packOptions: getPackOptions(state),
        lotOptions: getLotOptions(state),
        orchardOptions: getOrchardOptions(state),
    };
};

export default connect(
    mapStateToProps,
)(IntakeLineLayerUnitForm);

import * as Yup from 'yup';
import uuidv1 from 'uuid';
import { IOptionType } from '../../helper';
import { ITrip } from './trip';
import { ICarrier } from '../masterData/carrier/carrier';
import moment from 'moment';
import { DATEPICKER_FORMAT_DEFAULT } from '../../../appConstants';
import { ITemperatureUnit } from './temperatureUnit';
import { ITruckType } from '../masterData/truckType/truckType';
import { IDispatchInstruction } from './dispatchInstruction';
import { IStock } from '../stock/stock';
import { sum, uniq } from 'lodash';
import { IDispatchView } from './dispatchView';
import isContainer from 'is-container'; // Validates a container number according to ISO 6346

export interface ITripFormValues {
    id : number;
    guid : string;
    isActive : boolean;

    description ?: string;
    loadDate : moment.Moment;
    driver ?: string;
    container ?: string;
    containerTareWeight ?: number;
    containerGrossWeight ?: number | string;
    sealNumber ?: string;
    registrationNumber ?: string;
    carrier ?: IOptionType;
    temperatureUnits : Array<ITemperatureUnit>;
    truckType ?: IOptionType;
    fleetNumber ?: string;
}

export class TripFormValues implements ITripFormValues {
    public id : number;
    public guid : string;
    public isActive : boolean;

    public description ?: string;
    public loadDate : moment.Moment;
    public driver ?: string;
    public container ?: string;
    public containerTareWeight ?: number;
    public containerGrossWeight ?: number | string;
    public sealNumber ?: string;
    public registrationNumber ?: string;
    public carrier ?: IOptionType;
    public temperatureUnits : Array<ITemperatureUnit>;
    public truckType ?: IOptionType;
    public fleetNumber ?: string;

    public constructor(
        trip ?: ITrip | null,
        carriers ?: Array<ICarrier>,
        truckTypes ?: Array<ITruckType>,
        tripLoadDate ?: string,
        dispatchView ?: Array<IDispatchView>,
        dispatches ?: Array<IDispatchInstruction>,
        stocks ?: Array<IStock>,
    ) {

        let containerGrossWeight : string | number | undefined;
        if (dispatchView) {
            const stockWeight = sum(dispatchView?.map(x => x.palletsTotalWeight));
            containerGrossWeight = trip?.containerTareWeight ? ((trip?.containerTareWeight ?? 0) + stockWeight) : '';
        } else {
            const stockIds : Array<number> = [];
            dispatches?.filter(x => x.isActive).forEach(x => x.dispatchLines.filter(y => y.isActive).forEach(y => stockIds.push(y.currentStockId)));
            const stockWeight = sum(stocks?.filter(x => x.isActive && uniq(stockIds).some(y => y === x.id)).map(x => x.grossWeight));
            containerGrossWeight = trip?.containerTareWeight ? ((trip?.containerTareWeight ?? 0) + stockWeight) : '';
        }
        this.initializeDefaultValues(tripLoadDate, containerGrossWeight);
        if (trip) {
            this.id = trip.id;
            this.guid = trip.guid;
            this.isActive = trip.isActive;

            this.description = trip.description;
            this.loadDate = moment(trip.loadDate, DATEPICKER_FORMAT_DEFAULT);
            this.driver = trip.driver;
            this.container = trip.container;
            this.containerTareWeight = trip.containerTareWeight;
            this.containerGrossWeight = containerGrossWeight;
            this.sealNumber = trip.sealNumber;
            this.registrationNumber = trip.registrationNumber;
            this.temperatureUnits = trip.temperatureUnits;
            this.fleetNumber = trip.fleetNumber;

            this.carrier = carriers
                ?
                ((trip.carrierId)
                    ?
                    {
                        label: carriers.find(x => x.id === trip.carrierId)?.name ?? '',
                        value: trip.carrierId ?? undefined,
                    }
                    : undefined)
                : undefined;

            this.truckType = truckTypes
                ?
                ((trip.truckTypeId)
                    ?
                    {
                        label: truckTypes.find(x => x.id === trip.truckTypeId)?.code ?? '',
                        value: trip.truckTypeId ?? undefined,
                    }
                    : undefined)
                : undefined;
        }
    }

    public initializeDefaultValues = (tripLoadDate ?: string, containerGrossWeight ?: number | string) => {
        this.id = 0;
        this.guid = uuidv1();
        this.isActive = true;

        this.description = '';
        this.loadDate = tripLoadDate ? moment(tripLoadDate) : moment();
        this.driver = '';
        this.container = '';
        this.containerTareWeight = undefined;
        this.containerGrossWeight = containerGrossWeight;
        this.sealNumber = '';
        this.registrationNumber = '';
        this.carrier = undefined;
        this.temperatureUnits = [];
        this.truckType = undefined;
        this.fleetNumber = '';
    };

    public static formSchema = Yup.object().shape({
        isActive: Yup.boolean(),

        description: Yup.string().required('Required'),
        loadDate: Yup.date(),
        driver: Yup.string().nullable(),
        registrationNumber: Yup.string().nullable(),
        container: Yup.string().nullable()
            .length(11, 'Container Number length must be 11 characters.')
            .matches(RegExp('^[A-Z]{3}'), 'First three characters must be uppercase letters, see info button.')
            .matches(RegExp('^[A-Z]{3}[U,J,Z]{1}'), 'Forth character can only be U, J or Z, see info button.')
            .matches(RegExp('[0-9]{7}$'), 'Last 7 characters must be numbers, see info button.')
            .test('is-container', 'Invalid container number, see info button.', x => !x || (isContainer(x))),
        containerTareWeight: Yup.number().nullable().positive('Tare weight must be a positive number'),
        sealNumber: Yup.string().nullable(),
        carrier: Yup.object().nullable(),
        truckType: Yup.object().nullable(),
        fleetNumber: Yup.string().nullable(),
    });
}

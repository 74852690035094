import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IOrderRelatedData } from '../../../@types/model/order/orderRelatedData';

export default class OrderRelatedDataHttpService {
    public static getAllOrderRelatedData(loadMasterData ?: boolean) : AxiosPromise<IOrderRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Order/AllOrderRelatedData`, {
            params: { loadMasterData },
        });
    }
}

import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { IRecipe } from '../recipe';
import { IRecipeLineFormValues, RecipeLineFormValues } from './recipeLineFormValues';

export interface IRecipeFormValues {
    id : number;
    guid : string;
    name : string;
    startingTable ?: IOptionType;
    recipeLines : Array<IRecipeLineFormValues>;
    isActive : boolean;

    fieldName ?: IOptionType; // used to add recipeLines
    callRecipe ?: IOptionType; // used to add recipeLines
    collectionField ?: IOptionType; // used to add recipeLines
    collectionMethod ?: IOptionType; // used to add recipeLines
}

export class RecipeFormValues implements IRecipeFormValues {
    public id : number;
    public guid : string;
    public name : string;
    public startingTable ?: IOptionType;
    public recipeLines : Array<IRecipeLineFormValues>;
    public isActive : boolean;


    public fieldName ?: IOptionType; // used to add recipeLines
    public callRecipe ?: IOptionType; // used to add recipeLines
    public collectionField ?: IOptionType; // used to add recipeLines
    public collectionMethod ?: IOptionType; // used to add recipeLines

    public constructor(recipe ?: IRecipe, tables ?: Array<IOptionType>) {
        this.initializeDefaultValues();
        if (recipe) {
            this.id = recipe.id;
            this.guid = recipe.guid;
            this.name = recipe.name;
            this.startingTable = tables?.find(x => x.value === recipe.startingTable);
            this.recipeLines = recipe.recipeLines.map(x => new RecipeLineFormValues(x));
            this.isActive = recipe.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.name = '';
        this.recipeLines = [];
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
    });
}

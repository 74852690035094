import * as React from 'react';
import IconButton from '@mui/material/IconButton/IconButton';
import Icon from '@mui/material/Icon/Icon';
import Popper from '@mui/material/Popper/Popper';
import Grow from '@mui/material/Grow/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControl from '@mui/material/FormControl/FormControl';
import { Button, Checkbox, Tooltip, Typography } from '@mui/material';
import PillButton from '../input/PillButton';
import './customTableStyles.scss';
import { addArrayElement, removeArrayElement } from '../../services/appFunctionsService';
import materialTheme from '../../styles/materialTheme';

interface ICustomTableMultiCheckboxFilterProps {
    field : string;
    data : Array<string>;
    setOpen : (open : boolean, field : string) => void;
    onFilterChanged : (columnField : string, filterValue ?: Array<string>) => void;
}

interface ICustomTableMultiCheckboxFilterState {
    open : boolean;

    filterValue : Array<{value : string; checked : boolean}>;
    oldFilterValue : Array<{value : string; checked : boolean}>;
}

export default class CustomTableMultiCheckboxFilter extends React.Component<ICustomTableMultiCheckboxFilterProps, ICustomTableMultiCheckboxFilterState> {
    private anchorEl : any;

    constructor(props : ICustomTableMultiCheckboxFilterProps) {
        super(props);

        this.state = {
            open: false,
            filterValue: [],
            oldFilterValue: [],
        };
    }

    public componentDidUpdate = (prevProps : ICustomTableMultiCheckboxFilterProps, prevState : ICustomTableMultiCheckboxFilterState) => {
        if (!prevState.open && this.state.open) {
            const element = document.getElementById(`filter-${this.props.field}-id`);
            if (!!element) element.focus();
        }
        if (prevState.open !== this.state.open) {
            this.props.setOpen(this.state.open, this.props.field);
        }
        if (!prevState.open && this.state.open) {
            this.setState({ oldFilterValue: prevState.filterValue });
        }
    };

    private handleClick = () => {
        this.setState({ open: !this.state.open });
    };

    private handleClose = (event : MouseEvent | TouchEvent) => {
        if (this.anchorEl && this.anchorEl.contains(event.target)) {
            return;
        }

        event.preventDefault();

        this.props.onFilterChanged(this.props.field, this.state.oldFilterValue.length > 0 ? this.state.oldFilterValue.map(x => x.value) : []);
        this.setState({ open: false, filterValue: this.state.oldFilterValue });
    };

    private submitForm = async (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.setState({ open: false });

        this.props.onFilterChanged(this.props.field, this.state.filterValue.length > 0 ? this.state.filterValue.map(x => x.value) : []);
    };

    private handleCheckboxChecked = (fieldValue : string, selected : boolean) => {
        if (selected) {
            const index = this.state.filterValue.findIndex(x => x.value === fieldValue);
            const value = {
                value: fieldValue,
                checked: selected,
            };
            if (index === -1) {
                this.setState(prevState => ({ filterValue: addArrayElement(prevState.filterValue, value) }));
            }
        } else {
            const index = this.state.filterValue.findIndex(x => x.value === fieldValue);
            if (index !== -1) {
                this.setState(prevState => ({ filterValue: removeArrayElement(prevState.filterValue, index) }));
            }
        }
    };

    private handleShowMixedCheckboxChecked = (fieldValue : string, selected : boolean) => {
        if (selected) {
            const index = this.state.filterValue.findIndex(x => x.value === fieldValue);
            const value = {
                value: fieldValue,
                checked: selected,
            };
            if (index === -1) {
                this.setState(prevState => ({ filterValue: addArrayElement(prevState.filterValue, value) }));
            }
        } else {
            const index = this.state.filterValue.findIndex(x => x.value === fieldValue);
            if (index !== -1) {
                this.setState(prevState => ({ filterValue: removeArrayElement(prevState.filterValue, index) }));
            }
        }
    };

    public render = () => {
        const { open } = this.state;

        return <>
            <IconButton
                aria-owns={`filter-menu-${this.props.field}`}
                aria-haspopup='true'
                onClick={this.handleClick}
                ref={(node) => { this.anchorEl = node; }}
                color={'inherit'}>
                <Icon fontSize='small'>
                    filter_list
                </Icon>
            </IconButton>
            <Popper anchorEl={this.anchorEl} open={open} transition disablePortal style={{ zIndex: 1000 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', marginTop: 8 }}
                    >
                        <div id={`filter-menu-${this.props.field}`} className={'bcw p10 filterShadow'}>
                            <ClickAwayListener onClickAway={this.handleClose}>
                                <div className={'fdc tcFilterBlack'}>
                                    <div className={'fdr pl10 jcsb'}>
                                        <div>{'Filter By: '}</div>
                                        <Tooltip disableInteractive title={`${!!this.state.filterValue.find(x => x.value === 'ShowMixed')?.checked ? 'Show Mixed is already checked' : ''}`}>
                                            <div className={'aic m0'}>
                                                <Typography variant={'caption'}>{'Show Mixed'}</Typography>
                                                <Checkbox
                                                    className={'p0 m0'}
                                                    size={'small'}
                                                    style={{ color: materialTheme.palette.primary.main }}
                                                    checked={this.state.filterValue.find(x => x.value === 'ShowMixed')?.checked}
                                                    onChange={(event, checked) => this.handleShowMixedCheckboxChecked('ShowMixed', checked)}
                                                />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <form autoComplete='off' onSubmit={this.submitForm}>
                                        <FormControl>
                                            <div style={{ maxHeight: 110 }} className='fdc oya'>
                                                {
                                                    this.props.data.map((data) => {
                                                        return (
                                                            <div className={'aic pb2 m0'}>
                                                                <Checkbox
                                                                    className={'pt0 pb0 pr5 m0'}
                                                                    size={'small'}
                                                                    style={{ color: materialTheme.palette.primary.main }}
                                                                    checked={this.state.filterValue.find(x => x.value === data)?.checked}
                                                                    onChange={(event, checked) => this.handleCheckboxChecked(data, checked)}
                                                                    disabled={!!this.state.filterValue.find(x => x.value === 'ShowMixed')?.checked}
                                                                />
                                                                <Typography variant={'caption'}>{data}</Typography>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                            <div className={'fdr flx1 pt10'}>
                                                <div className={'flx1'}/>
                                                <Button
                                                    className={'fwb h35'}
                                                    variant='text'
                                                    color='primary'
                                                    type={'reset'}
                                                    onClick={this.handleClose}>
                                                    Cancel
                                                </Button>
                                                <PillButton
                                                    className={'ml15 pl20 pr20 h35'}
                                                    text={'Apply'}
                                                    color={'secondary'}
                                                    type={'submit'}
                                                />
                                            </div>
                                        </FormControl>
                                    </form>
                                </div>
                            </ClickAwayListener>
                        </div>
                    </Grow>
                )}
            </Popper>
        </>;
    };
}

import { IVariety } from './variety';
import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { ICommodity } from '../commodity/commodity';

export interface IVarietyFormValues {
    id : number;
    guid : string;
    code : string;
    name : string;
    commodity ?: IOptionType;
    isActive : boolean;
    isImported ?: boolean;
}

export class VarietyFormValues implements IVarietyFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public name : string;
    public commodity ?: IOptionType;
    public isActive : boolean;
    public isImported ?: boolean;

    public constructor(
        variety ?: IVariety,
        commodities ?: Array<ICommodity>,
        selectedCommodity ?: ICommodity,
    ) {
        this.initializeDefaultValues(selectedCommodity);
        if (variety) {
            this.id = variety.id;
            this.guid = variety.guid;
            this.code = variety.code;
            this.name = variety.name;
            this.isImported = variety.isImported;

            this.commodity = commodities ? {
                label: commodities.find(x => x.id === variety.commodityId)?.name ?? '',
                value: variety.commodityId,
            } : undefined;

            this.isActive = variety.isActive;
        }
    }

    public initializeDefaultValues = (selectedCommodity ?: ICommodity) => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.name = '';
        this.commodity = undefined;
        this.isActive = true;
        this.isImported = false;

        if (selectedCommodity) {
            this.commodity = {
                label: selectedCommodity?.name ?? '',
                value: selectedCommodity?.id,
            };
        }
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        commodity: Yup.object().nullable().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

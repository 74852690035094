import * as React from 'react';
import CustomTable from '../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetFarms, dataSetContactInfos, dataSetAccreditations, dataSetFtpDetails } from '../../../store/masterData/Actions';
import { formatDateTime, upsertArrayElement } from '../../../services/appFunctionsService';
import { IOrganization } from '../../../@types/model/masterData/organization/organization';
import { IFarm, Farm } from '../../../@types/model/masterData/farm/farm';
import FarmHttpService from '../../../services/http/masterData/farmHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import { Formik, FormikActions } from 'formik';
import FarmForm from './form/FarmForm';
import { FarmFormValues, IFarmFormValues } from '../../../@types/model/masterData/farm/farmFormValues';
import { createSelector } from 'reselect';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { IContactInfo } from '../../../@types/model/masterData/contactInfo/contactInfo';
import ContactInfoHttpService from '../../../services/http/masterData/contactInfoHttpService';
import { IAccreditation } from '../../../@types/model/masterData/accreditation/accreditation';
import AccreditationHttpService from '../../../services/http/masterData/accreditationHttpService';
import { setFarmMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';
import { IFtpDetail } from '../../../@types/model/masterData/ftpDetail/ftpDetail';
import FtpDetailHttpService from '../../../services/http/masterData/ftpDetailHttpService';

interface IFarmScreenProps extends RouteComponentProps {
    dataSetFarms : DispatchCall<Array<IFarm>>;
    dataSetContactInfos : DispatchCall<Array<IContactInfo>>;
    dataSetAccreditations : DispatchCall<Array<IAccreditation>>;
    dataSetFtpDetails : DispatchCall<Array<IFtpDetail>>;
    farms : Array<IFarm>;
    accreditations : Array<IAccreditation>;
    selectedOrganizationIds : Array<number>;
    organizations : Array<IOrganization>;
    ftpDetails : Array<IFtpDetail>;
    contactInfos : Array<IContactInfo>;
    auth : IAuthState;
}

interface IFarmScreenState {
    rows : Array<IFarm>;
    isLoading : boolean;
    selectedFarm ?: IFarm;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingFarm ?: IFarm;
}

class FarmScreen extends React.Component<IFarmScreenProps, IFarmScreenState> {
    constructor(props : IFarmScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedFarm: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await FarmHttpService.getFarmData(this.props.selectedOrganizationIds);
                const res2 = await ContactInfoHttpService.getContactInfoData();
                const res3 = await AccreditationHttpService.getAccreditationData();
                const res4 = await FtpDetailHttpService.getFtpDetailData();

                this.props.dataSetFarms(res.data);
                this.props.dataSetContactInfos(res2.data);
                this.props.dataSetAccreditations(res3.data);
                this.props.dataSetFtpDetails(res4.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading farms.');
                this.setLoading(false);
            }
        }
    };

    public componentDidUpdate = async (prevProps : IFarmScreenProps) => {

        const nextProps = this.props;
        if (prevProps.selectedOrganizationIds !== nextProps.selectedOrganizationIds) {
            this.setLoading(true);
            try {
                const res = await FarmHttpService.getFarmData(this.props.selectedOrganizationIds);
                const res2 = await ContactInfoHttpService.getContactInfoData();
                const res3 = await AccreditationHttpService.getAccreditationData();
                const res4 = await FtpDetailHttpService.getFtpDetailData();

                this.props.dataSetFarms(res.data);
                this.props.dataSetContactInfos(res2.data);
                this.props.dataSetAccreditations(res3.data);
                this.props.dataSetFtpDetails(res4.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading farms.');
                this.setLoading(false);
            }
        }
    };

    private onFarmEdit = (row : IFarm) => {
        this.setState({
            isDialogOpen: true,
            selectedFarm: row,
        });
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private formatOrganizations = (organizationIds : Array<number>) => this.props.organizations
        .filter(x => organizationIds.some(y => y === x.id))
        .map(x => x.code).toString().replace(/,/g, ', ');

    private formatFtpDetails = (ftpDetailIds : Array<number>) => this.props.ftpDetails
        .filter(x => ftpDetailIds?.some(y => y === x.id))
        .map(x => x.name).toString().replace(/,/g, ', ');

    private formatContactInfos = (contactInfoIds : Array<number>) => this.props.contactInfos
        .filter(x => contactInfoIds.some(y => y === x.id))
        .map(x => x.name).toString().replace(/,/g, ', ');

    private getRows = () => this.props.farms.filter(x => x.organizationIds.some(y => this.props.selectedOrganizationIds?.some(z => z === y)));

    public onSubmit = async (values : IFarmFormValues) => {
        this.setLoading(true);

        try {
            const res = await FarmHttpService.addOrUpdateFarm(new Farm(values));

            const newFarmList = upsertArrayElement(this.props.farms, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetFarms(newFarmList);
            await setFarmMasterDataIndexedDB(newFarmList);

            if (this.state.selectedFarm) {
                generalShowSuccessSnackbar('Farm updated successfully.');
            } else {
                generalShowSuccessSnackbar('Farm added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating farm data.');
        } finally {
            this.setLoading(false);
        }
    };

    public onReset = async (formValues : IFarmFormValues, formikActions : FormikActions<IFarmFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    private openDeleteConfirmationPopup = (farm : IFarm) => {
        this.setState({ isDeletePopupOpen: true, deletingFarm: farm });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingFarm: undefined });
    };

    private removeFarm = async () => {
        const newFarm = this.state.deletingFarm;
        if (newFarm) {
            newFarm.isActive = false;
            this.setLoading(true);

            try {
                const res = await FarmHttpService.deleteFarm(newFarm.id);

                const newFarmList = upsertArrayElement(this.props.farms, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetFarms(newFarmList);
                await setFarmMasterDataIndexedDB(newFarmList);

                generalShowSuccessSnackbar('Farm updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting farm.');
                newFarm.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    public getSelectedFarm = (props : IFarmScreenProps, state : IFarmScreenState) => state.selectedFarm;
    public getOrganizations = (props : IFarmScreenProps) => props.organizations;
    public getFtpDetails = (props : IFarmScreenProps) => props.ftpDetails;
    public getContactInfos = (props : IFarmScreenProps) => props.contactInfos;
    public getAccreditations = (props : IFarmScreenProps) => props.accreditations;

    public getInitialFormValues = createSelector(
        [this.getSelectedFarm, this.getOrganizations, this.getFtpDetails, this.getContactInfos, this.getAccreditations],
        (farm, organizations, ftpDetails, contactInfos, accreditations) => {
            return new FarmFormValues(farm, organizations, ftpDetails, contactInfos, accreditations);
        },
    );

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedFarm: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    private getAccreditationName = (accreditationId : number) => {
        const accreditation = this.props.accreditations?.find(x => x.id === accreditationId);
        return accreditation?.name ?? '';
    };

    private getRights = (props : IFarmScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IFarmScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.getRows();
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Farm'
                    isEdit={!!this.state.selectedFarm}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}
                    fullScreen={true}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={FarmFormValues.formSchema}
                        component={FarmForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IFarm>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.onFarmEdit}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(farm : IFarm) => farm.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Global Gap Number', field: 'globalGapNumber', enableFiltering: true, enableSorting: true },
                                { title: 'Address', field: 'address', enableFiltering: true, enableSorting: true },
                                { title: 'Tel Number', field: 'telNumber', enableFiltering: true, enableSorting: true },
                                { title: 'Fax Number', field: 'faxNumber', enableFiltering: true, enableSorting: true },
                                { title: 'Bank Account', field: 'bankAccount', enableFiltering: true, enableSorting: true },
                                { title: 'Bank Name', field: 'bankName', enableFiltering: true, enableSorting: true },
                                { title: 'VAT Number', field: 'vat', enableFiltering: true, enableSorting: true },
                                { title: 'ftpAddress', field: 'ftpAddress', enableFiltering: true, enableSorting: true },
                                { title: 'ftpPort', field: 'ftpPort', enableFiltering: true, enableSorting: true },
                                { title: 'ftpPath', field: 'ftpPath', enableFiltering: true, enableSorting: true },
                                { title: 'Extended Desc', field: 'extendedDescription', enableFiltering: true, enableSorting: true },
                                { title: 'Exported Code', field: 'exportedCode', enableFiltering: true, enableSorting: true },
                                { title: 'Exported Desc', field: 'exportedDescription', enableFiltering: true, enableSorting: true },
                                { title: 'Accreditation', field: 'accreditationId', formatFunction: this.getAccreditationName, enableFiltering: true, enableSorting: true },
                                { title: 'Organizations', field: 'organizationIds', formatFunction: this.formatOrganizations, width: 200, enableFiltering: true, enableSorting: true },
                                { title: 'FtpDetails', field: 'ftpDetailIds', formatFunction: this.formatFtpDetails, width: 200, enableFiltering: true, enableSorting: true },
                                { title: 'Contact Info', field: 'contactInfoIds', formatFunction: this.formatContactInfos, width: 200, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IFarm) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Farm'}
                    open={this.state.isDeletePopupOpen}
                    message={'Are you sure you want to delete this farm?'}
                    onOkClicked={this.removeFarm}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        farms: state.masterData.farms,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        organizations: state.masterData.organizations,
        ftpDetails: state.masterData.ftpDetails,
        contactInfos: state.masterData.contactInfos,
        accreditations: state.masterData.accreditations,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetFarms, dataSetContactInfos, dataSetAccreditations, dataSetFtpDetails }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FarmScreen);

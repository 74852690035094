import * as React from 'react';
import { Form, FormikProps } from 'formik';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import { AgreementCodeFormValues } from '../../../../@types/model/masterData/agreementCode/agreementCodeFormValues';
import FormAutocompleteSelect from '../../../../components/input/form/FormAutoCompleteSelect';
import { connect } from 'react-redux';
import { IRootState } from '../../../../@types/redux';
import { createSelector } from 'reselect';
import { IMarket } from '../../../../@types/model/masterData/market/market';
import { IOptionType } from '../../../../@types/helper';

interface IAgreementCodeFormProps {
    isLoading : boolean;
    marketOptions : Array<IOptionType>;
}

interface IAgreementCodeFormState {
}

type AgreementCodeFormPropsType = IAgreementCodeFormProps & FormikProps<AgreementCodeFormValues>;

class AgreementCodeForm extends React.Component<AgreementCodeFormPropsType, IAgreementCodeFormState> {

    constructor(props : AgreementCodeFormPropsType) {
        super(props);
    }

    public render() {
        return (
            <Form className={'p20'}>
                <FormAutocompleteSelect name={'targetMarket'} label={'Target Market'} options={this.props.marketOptions}/>
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const getMarkets = (state : IRootState) => state.masterData.markets;

const getMarketOptions = createSelector(
    [getMarkets],
    (markets : Array<IMarket>) => {
        return markets.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        marketOptions: getMarketOptions(state),
    };
};

export default connect(
    mapStateToProps,
)(AgreementCodeForm);

import * as React from 'react';
import Slide from '@mui/material/Slide/Slide';
import { TransitionProps } from '@mui/material/transitions';

const slideUpDialogTransition = React.forwardRef((
    props : TransitionProps & { children ?: React.ReactElement },
    ref : React.Ref<unknown>,
) => {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default slideUpDialogTransition;

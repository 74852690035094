import * as React from 'react';
import { connect } from 'react-redux';
import { DispatchCall, IRootState, RootAction } from '../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Typography, TableCell, TableRow, Table, Checkbox } from '@mui/material';
import { addArrayElement, booleanToYesNo, compareDate, formatDateTime, removeArrayElement } from '../../services/appFunctionsService';
import CustomTable from '../../components/table/CustomTable';
import { ISite } from '../../@types/model/masterData/site/site';
import Screen from '../../components/Screen';
import { IMaterialStock } from '../../@types/model/materialStock/materialStock';
import { IMaterialStockLine } from '../../@types/model/materialStock/materialStockLine';
import { IMaterial } from '../../@types/model/masterData/material/material';
import { IUnitOfMeasure } from '../../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import PillButton from '../../components/input/PillButton';
import ConfirmationPrompt from '../../components/dialog/ConfirmationPrompt';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import MaterialStockHttpService from '../../services/http/material/materialStockHttpService';
import { IStock } from '../../@types/model/stock/stock';
import { IMaterialDispatch } from '../../@types/model/materialDispatch/materialDispatch';
import { IDispatchInstruction } from '../../@types/model/dispatch/dispatchInstruction';
import { dataSetMaterialStock, dataSetMaterialStockSummaryRelatedData } from '../../store/data/Functions';
import { dataSetStocks } from '../../store/data/Actions';
import { IMaterialStockView } from '../../@types/model/materialStock/materialStockView';
import { PackmanLink } from '../../components/link/packmanLink';
import { PackmanLabel } from '../../components/label/PackmanLabel';
import CustomTooltip from '../../components/tooltip/tooltip';
import { syncMasterData } from '../../services/masterDataSyncService';
import { BooleanFlag } from '../../components/label/BooleanFlag';
import materialTheme from '../../styles/materialTheme';

interface IMaterialStockSummaryProps {
    selectedMaterialStockView : IMaterialStockView;
    dataSetStocks : DispatchCall<Array<IStock>>;
    sites : Array<ISite>;
    materials : Array<IMaterial>;
    unitOfMeasures : Array<IUnitOfMeasure>;
    materialStocks : Array<IMaterialStock>;
    isLoading : boolean;
    setLoading : (loading : boolean) => void;
    refreshData : () => void;
    stocks : Array<IStock>;
    materialDispatches : Array<IMaterialDispatch>;
    dispatches : Array<IDispatchInstruction>;
}

interface IMaterialStockSummaryState {
    materialStock ?: IMaterialStock;
    showRevertScrappedMaterialStockLineDialog : boolean;
    showRevertMultiLineDialog : boolean;
    showRevertMaterialStockLineDialog : boolean;
    editingMaterialStockLine ?: IMaterialStockLine;
    revertMaterialStockLines : Array<number>;
}

class MaterialStockSummary extends React.Component<IMaterialStockSummaryProps, IMaterialStockSummaryState> {
    constructor(props : IMaterialStockSummaryProps) {
        super(props);

        this.state = {
            showRevertScrappedMaterialStockLineDialog: false,
            showRevertMultiLineDialog: false,
            showRevertMaterialStockLineDialog: false,
            revertMaterialStockLines: [],
        };
    }

    public componentDidMount = async () => {
        if (this.props.selectedMaterialStockView) {
            this.props.setLoading(true);
            // checks if indexedDB is available.
            const isIndexedDBAvailable = !!self.indexedDB ? true : false;

            if (isIndexedDBAvailable) {
                await syncMasterData(false);
            }
            try {
                const res = await MaterialStockHttpService.getMaterialStockSummaryRelatedData(this.props.selectedMaterialStockView.id, !isIndexedDBAvailable);

                if (res && res.data) {
                    dataSetMaterialStockSummaryRelatedData(res.data);
                }
                this.setState({ materialStock: res.data.materialStock });
            } catch (e) {
                generalShowErrorSnackbar('An error occurred retrieving material stock summary data');
            } finally {
                this.props.setLoading(false);
            }
        }
    };

    public componentDidUpdate = async (prevProps : IMaterialStockSummaryProps) => {
        const nextProps = this.props;
        if (nextProps && prevProps && (prevProps.selectedMaterialStockView !== nextProps.selectedMaterialStockView)) {
            if (nextProps.selectedMaterialStockView !== null) {
                // checks if indexedDB is available.
                const isIndexedDBAvailable = !!self.indexedDB ? true : false;
                this.props.setLoading(true);
                try {
                    const res = await MaterialStockHttpService.getMaterialStockSummaryRelatedData(this.props.selectedMaterialStockView.id, !isIndexedDBAvailable);

                    if (res && res.data) {
                        dataSetMaterialStockSummaryRelatedData(res.data);
                    }
                    this.setState({ materialStock: res.data.materialStock });
                    this.props.setLoading(false);
                } catch (e) {
                    generalShowErrorSnackbar('An error occurred retrieving stock data');
                    this.props.setLoading(false);
                }

            }
        }
    };

    private getSiteDescription = (id : number) => {
        const rows = this.props.sites;
        const obj = rows && rows.find(x => x.id === id);
        return obj ? obj.description : '';
    };

    private onRevertMaterialStockLinesClick = () => {
        this.setState({ showRevertMultiLineDialog: true });
    };

    private onRevertMaterialStockLinesCancel = () => {
        this.setState({ showRevertMultiLineDialog: false, revertMaterialStockLines: [] });
    };

    private submitRevertMultipleLines = async () => {
        const materialStockLineIds = [...this.state.revertMaterialStockLines];
        if (materialStockLineIds.length !== 0) {
            this.props.setLoading(true);
            try {
                const res = await MaterialStockHttpService.revertMaterialStockLines(materialStockLineIds);

                if (res && res.data) {
                    this.setState({ revertMaterialStockLines : [], showRevertMultiLineDialog: false });
                    res.data.forEach((x) => {
                        if (x.id === this.props.selectedMaterialStockView.id) {
                            this.setState({ materialStock: x });
                        }

                        dataSetMaterialStock(x);
                    });
                }
                this.props.setLoading(false);
                generalShowSuccessSnackbar('Material stock lines reverted successfully');
            } catch (e) {
                this.setState({ revertMaterialStockLines : [], showRevertMultiLineDialog: false });
                generalShowErrorSnackbar('An error occurred reverting the material stock lines');
                this.props.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Material Stock Lines was not specified');
        }
    };

    private noData = (id : number) => {
        if (id !== null) {
            return id;
        } else {
            return '';
        }
    };

    private getStockBarcode = (id : number) => {
        const stock = this.props.stocks.find(x => x.id === id);

        return stock ? <PackmanLink
            type={'transactions'}
            targetPage={'stock'}
            id={Number(id)}
            text={stock.barcode} /> : '';
    };

    private getMaterialDispatchCode = (id : number) => {
        const materialDispatch = this.props.materialDispatches.find(x => x.id === id);

        return materialDispatch ? <PackmanLink
            type={'transactions'}
            targetPage={'materialDispatch'}
            id={Number(id)}
            text={materialDispatch.materialDispatchCode} /> : '';
    };

    private getDispatchCode = (id : number) => {
        const dispatch = this.props.dispatches.find(x => x.id === id);

        return dispatch ? <PackmanLink
            type={'transactions'}
            targetPage={'dispatch'}
            id={Number(id)}
            text={dispatch.dispatchCode} /> : '';
    };

    private isChecked = (id : number) => this.state.revertMaterialStockLines.some(x => x === id);

    private handleRevertCheckboxChecked = (id : number) => {
        const index = this.state.revertMaterialStockLines.findIndex(x => x === id);
        if (index === -1) {
            this.setState(prevState => ({ revertMaterialStockLines: addArrayElement(prevState.revertMaterialStockLines, id, 'end') }));
        } else {
            this.setState(prevState => ({ revertMaterialStockLines: removeArrayElement(prevState.revertMaterialStockLines, index) }));
        }
    };

    private getColumns = () => [
        {
            title: 'Select', field: 'revert', width: 70,
            containerComponent: (row : IMaterialStockLine) => {
                return (
                    <CustomTooltip title={`${!row.isActive ? 'Line is inactive' : (row.isDispatched ? 'Line is already dispatched' : row.isAllocation ? 'Line is already allocated' : '') }`}>
                        <Checkbox
                            disabled={!row.isActive || row.isDispatched || row.isAllocation}
                            checked={this.isChecked(row.id)}
                            onChange={() => this.handleRevertCheckboxChecked(row.id)}
                        />
                    </CustomTooltip>
                );
            },
        },
        { title: 'Id', field: 'id', enableSorting: true },
        { title: 'Amount', field: 'amount', enableSorting: true,
            containerComponent: (row : IMaterialStockLine, value : any) => <div className={'fdr'}>
                {(value === '0' ? '0' : row.isIncoming ? <div className={'cpl'} style={{ paddingLeft: 3 }}>{`+${value}`}</div> :
                    <div className={'cred'} style={{ paddingLeft: 3 }}>{`-${value}`}</div>)}
            </div> },
        { title: 'Stock', field: 'stockId', formatFunction: this.getStockBarcode, width: 170, enableSorting: true },
        { title: 'Dispatch', field: 'dispatchId', formatFunction: this.getDispatchCode, enableSorting: true },
        { title: 'Material Dispatch', field: 'materialDispatchId', formatFunction: this.getMaterialDispatchCode, enableSorting: true },
        { title: 'Temp Stock Unit', field: 'tempStockUnitId', formatFunction: this.noData, enableSorting: true },
        { title: 'From MaterialStock Line', field: 'fromMaterialStockLineId', formatFunction: this.noData, enableSorting: true },
        { title: 'To MaterialStock Line', field: 'toMaterialStockLineId', formatFunction: this.noData, enableSorting: true },
        { title: 'Incoming?', field: 'isIncoming', formatFunction: booleanToYesNo, type: 'boolean', enableSorting: true },
        { title: 'Allocated?', field: 'isAllocation', formatFunction: booleanToYesNo, type: 'boolean', enableSorting: true },
        { title: 'Dispatched?', field: 'isDispatched', formatFunction: booleanToYesNo, type: 'boolean', enableSorting: true },
        { title: 'Scrapped?', field: 'isScrapped', formatFunction: booleanToYesNo, type: 'boolean', enableSorting: true },
        { title: 'Scrap Reversed?', field: 'isScrapReversed', formatFunction: booleanToYesNo, type: 'boolean', enableSorting: true },
        { title: 'Stock Take?', field: 'isStockTake', formatFunction: booleanToYesNo, type: 'boolean', enableSorting: true },
        { title: 'Submitted On', field: 'submittedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
        { title: 'Created By', field: 'createdByName', enableSorting: true },
        { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
        { title: 'Updated By', field: 'updatedByName', enableSorting: true },
        { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
        { title: 'Active?', field: 'isActive', formatFunction: booleanToYesNo, type: 'boolean', enableSorting: true },
    ];

    private renderSummaryTable = () => {
        return (
            <div className={'fdr flx1 pt10'}>
                <Table style={{ backgroundColor: materialTheme.custom.table.background }} className= {'PaperBorder'}>
                    {/* Headings */}
                    <TableRow className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0'}>
                            {' '}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic p5 lotSummaryDarkBlue h27 fw500'}>
                            {'INCOMING'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic p5 lotSummaryDarkGreen h27 fw500'}>
                            {'OUTGOING'}
                        </TableCell>
                    </TableRow>
                    <TableRow className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0'}>
                            {' '}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic p5 lotSummaryLightBlue h23 fs14'}>
                            <div className={'flx1 aic jcc'}>Amount</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic p5 lotSummaryLightGreen h23 fs14'}>
                            <div className={'flx1 aic jcc'}>Amount</div>
                        </TableCell>
                    </TableRow>
                    {/* Calculated Values */}
                    <TableRow style={{ backgroundColor: materialTheme.custom.table.row1.default }} className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0 fw500'}>
                            {'Total'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0 fw500'}>
                            <div className={'flx1 aic jcc hfill'}>{this.calculateTotalIncoming()}</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0 fw500'}>
                            <div className={'flx1 aic jcc hfill'}>{this.calculateTotalOutGoing()}</div>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: materialTheme.custom.table.row2.default }} className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0 fw500'}>
                            {'Allocated'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0'}>
                            <div className={'flx1 aic jcc hfill'}>{this.calculateTotal('Allocated', true)}</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0'}>
                            <div className={'flx1 aic jcc hfill'}>{this.calculateTotal('Allocated', false)}</div>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: materialTheme.custom.table.row1.default }} className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0 fw500'}>
                            {'Dispatched'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0 fw500'}>
                            <div className={'flx1 aic jcc hfill'}>{this.calculateTotal('Dispatched', true)}</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0 fw500'}>
                            <div className={'flx1 aic jcc hfill'}>{this.calculateTotal('Dispatched', false)}</div>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: materialTheme.custom.table.row2.default }} className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0 fw500'}>
                            {'Scrapped'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0'}>
                            <div className={'flx1 aic jcc hfill'}>{this.calculateTotal('Scrapped', true)}</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0'}>
                            <div className={'flx1 aic jcc hfill'}>{this.calculateTotal('Scrapped', false)}</div>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: materialTheme.custom.table.row1.default }} className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0 fw500'}>
                            {'Scrap Reversed'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0 fw500'}>
                            <div className={'flx1 aic jcc hfill'}>{this.calculateTotal('ScrapReversed', true)}</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0 fw500'}>
                            <div className={'flx1 aic jcc hfill'}>{this.calculateTotal('ScrapReversed', false)}</div>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: materialTheme.custom.table.row2.default }} className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0 fw500'}>
                            {'Stock Take'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0'}>
                            <div className={'flx1 aic jcc hfill'}>{this.calculateTotal('StockTake', true)}</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0'}>
                            <div className={'flx1 aic jcc hfill'}>{this.calculateTotal('StockTake', false)}</div>
                        </TableCell>
                    </TableRow>
                </Table>
            </div>
        );
    };

    private calculateTotalIncoming = () => {
        let total = 0;
        const materialStockLines = this.state.materialStock?.materialStockLines;

        materialStockLines?.filter(x => x.isActive && x.isIncoming).forEach(x => total += x.amount);

        return total;
    };

    private calculateTotalOutGoing = () => {
        let total = 0;
        const materialStockLines = this.state.materialStock?.materialStockLines;

        materialStockLines?.filter(x => x.isActive && !x.isIncoming && (x.isAllocation || x.isDispatched || x.isStockTake || x.isScrapped || x.isScrapReversed)).forEach(x => total += x.amount);

        return total;
    };

    private calculateTotal = (type : 'Allocated' | 'Dispatched' | 'Scrapped' | 'ScrapReversed' | 'StockTake', incoming : boolean) => {
        let total = 0;
        const materialStockLines = this.state.materialStock?.materialStockLines.filter(x => x.isActive);

        switch (type) {
            case 'Allocated':
                materialStockLines?.filter(x => x.isActive && (x.isIncoming === incoming) && x.isAllocation).forEach(x => total += x.amount);
                break;
            case 'Dispatched':
                materialStockLines?.filter(x => x.isActive && (x.isIncoming === incoming) && x.isDispatched).forEach(x => total += x.amount);
                break;
            case 'Scrapped':
                materialStockLines?.filter(x => x.isActive && (x.isIncoming === incoming) && x.isScrapped).forEach(x => total += x.amount);
                break;
            case 'ScrapReversed':
                materialStockLines?.filter(x => x.isActive && (x.isIncoming === incoming) && x.isScrapReversed).forEach(x => total += x.amount);
                break;
            case 'StockTake':
                materialStockLines?.filter(x => x.isActive && (x.isIncoming === incoming) && x.isStockTake).forEach(x => total += x.amount);
                break;
        }

        return total;
    };

    private getMaterialStockLines = (props : IMaterialStockSummaryProps, state : IMaterialStockSummaryState) => state.materialStock?.materialStockLines;

    public render() {
        return (
            <Screen isPadded={false} isScrollable={false} isLoading={this.props.isLoading}>
                <div className={'fdc hfill'}>
                    <div className={'fdc pl10 pr10 pt10'}>
                        <div className={'fdr aic pt10'}>
                            <PackmanLabel
                                label={'Material'}
                                value={this.props.selectedMaterialStockView.materialCode}
                            />
                            <PackmanLabel
                                label={'Site'}
                                value={this.getSiteDescription(this.props.selectedMaterialStockView.siteId ?? 0)}
                            />
                            <PackmanLabel
                                label={'Date Code'}
                                value={this.props.selectedMaterialStockView.dateCode ?? ''}
                            />
                            <PackmanLabel
                                label={'Amount Allocated'}
                                value={this.props.selectedMaterialStockView.amountAllocated ?? ''}
                            />
                            <PackmanLabel
                                label={'Amount Unallocated'}
                                value={this.props.selectedMaterialStockView.amountUnAllocated ?? ''}
                            />
                            <PackmanLabel
                                label={'Amount Scrapped'}
                                value={this.props.selectedMaterialStockView.amountScrapped ?? ''}
                            />
                        </div>
                        <div className={'fdr aic pt10'}>
                            <div className={'flx1'}>
                                <PackmanLabel
                                    label={'Amount On-Hand'}
                                    value={this.props.selectedMaterialStockView.amountOnHand ?? ''}
                                />
                                <PackmanLabel
                                    label={'Amount Total'}
                                    value={this.props.selectedMaterialStockView.amountTotal ?? ''}
                                />
                                <PackmanLabel
                                    label={'Unit of Measure'}
                                    value={this.props.selectedMaterialStockView.unitOfMeasureCode}
                                />
                            </div>
                            <div className={'flx1'}>
                                <PackmanLabel
                                    label={'Material'}
                                    value={`(${this.props.selectedMaterialStockView.materialCode}) ${this.props.selectedMaterialStockView.materialName}`}
                                />
                                <div className={'flx1 pr10'}></div>
                            </div>
                        </div>
                        <div className={'fdr aic pt10 mb10'}>
                            <div className={'flx1'}>
                                <PackmanLabel
                                    label={'Created By'}
                                    value={this.props.selectedMaterialStockView.createdByName ?? ''}
                                />
                                <PackmanLabel
                                    label={'Created On'}
                                    value={this.props.selectedMaterialStockView?.createdOn ?? ''}
                                />
                                <PackmanLabel
                                    label={'Updated By'}
                                    value={this.props.selectedMaterialStockView?.updatedByName ?? ''}
                                />
                            </div>
                            <div className={'flx1'}>
                                <PackmanLabel
                                    label={'Updated On'}
                                    value={this.props.selectedMaterialStockView?.updatedOn ?? ''}
                                />
                                <div className={'flx1 pr10'}></div>
                            </div>
                        </div>
                        <div className={'fdr aic pt10'}>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Finalized?</Typography>
                                <BooleanFlag value={this.props.selectedMaterialStockView?.isFinalized ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Auto Finalized?</Typography>
                                <BooleanFlag value={this.props.selectedMaterialStockView?.isAutoFinalized ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Active?</Typography>
                                <BooleanFlag value={this.props.selectedMaterialStockView?.isActive ?? false}/>
                            </div>
                            <div className={'flx3'}></div>
                        </div>
                    </div>
                    <div className={'flx1 fdc p20'}>
                        <div className={'pt20 pb20'}>
                            <CustomTable<IMaterialStockLine>
                                columns={this.getColumns()}
                                rows={this.getMaterialStockLines(this.props, this.state) ?? []}
                                fitWidthToPage
                                enableSorting
                                initialSortOrder={[{ columnName: 'id_Id', direction : 'asc' }]}
                                isActive={(row : IMaterialStockLine) => row.isActive}
                                pageHeight={600}
                            />
                            {(this.props.selectedMaterialStockView && !this.props.selectedMaterialStockView.isFinalized && this.props.selectedMaterialStockView.isActive) &&
                                <div className={'flx1 pt20 pr20'}>
                                    <div className={'flx1'} />
                                    <PillButton
                                        className={'pl10 pr10 h35 w300 reducedPillButtonShadow'}
                                        text={'Revert Selected Material Stock Lines'}
                                        disabled={this.props.isLoading || this.state.revertMaterialStockLines.length === 0}
                                        type={'submit'}
                                        color={'secondary'}
                                        onClick={() => this.onRevertMaterialStockLinesClick()}
                                    />
                                </div>
                            }
                            {this.renderSummaryTable()}
                        </div>
                    </div>
                    <ConfirmationPrompt isLoading={this.props.isLoading} open={this.state.showRevertMultiLineDialog} message={'Are you sure you want to revert this material stock lines?' }
                        onOkClicked={this.submitRevertMultipleLines} onCancelClicked={this.onRevertMaterialStockLinesCancel}/>
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        sites: state.masterData.sites,
        materials: state.masterData.materials,
        unitOfMeasures: state.masterData.unitOfMeasures,
        materialStocks: state.data.materialStocks,
        stocks: state.data.stocks,
        materialDispatches: state.data.materialDispatches,
        dispatches: state.data.dispatchInstructions,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetStocks }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MaterialStockSummary);

import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Moment } from 'moment';
import { TextField } from '@mui/material';

interface IDateInputProps {
    label : string;
    name : string;
    onDateChange : (date : Moment) => void;
    onBlur ?: () => void;
    className ?: string;
    disabled ?: boolean;
    error ?: string;
    value : Moment | null;
    variant ?: 'standard' | 'outlined' | 'filled';
    format ?: string;
}

export default class DateInput extends React.Component<IDateInputProps> {
    constructor(props : IDateInputProps) {
        super(props);
    }

    public onChange = (date : Moment) => {
        this.props.onDateChange(date);
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    };

    public render() {
        const {
            label,
            className,
            disabled,
            name,
            error,
            value,
            variant = 'outlined',
            format = 'YYYY-MM-DD',
        } = this.props;

        return (
            <div className={`fdc ais jcfs ${className || ''}`}>
                <DatePicker
                    renderInput={inputProps => <TextField
                        margin={'dense'}
                        variant={variant}
                        id={name}
                        error={!!error}
                        {...inputProps}/>}
                    disabled={disabled}
                    value={value}
                    onChange={this.onChange}
                    label={label}
                    format={format}
                />
            </div>
        );
    }
}

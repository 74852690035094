import * as React from 'react';
import { Map, Marker, Popup } from 'react-leaflet';
import { Icon, LatLng } from 'leaflet';
import Screen from '../../components/Screen';
import { connect } from 'react-redux';
import { DispatchCall, IRootState, RootAction } from '../../@types/redux';
import { ISite } from '../../@types/model/masterData/site/site';
import StandardLayerControl from '../../components/map/StandardLayerControl';
import { createSelector } from 'reselect';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import SiteHttpService from '../../services/http/masterData/siteHttpService';
import { generalShowErrorSnackbar } from '../../store/general/Functions';
import { dataSetSites, dataSetStorageUnits } from '../../store/masterData/Actions';
import { bindActionCreators, Dispatch } from 'redux';
import StorageUnitHttpService from '../../services/http/masterData/storageUnitHttpService';
import { IStorageUnit } from '../../@types/model/masterData/storageUnit/storageUnit';
import { Card, Table, TableCell, TableRow } from '@mui/material';
import CustomTable from '../../components/datagrid/CustomTable';
import moment from 'moment';
import TransactionFilter from '../../components/filters/BasicTransactionScreenFilter';
import { ILot } from '../../@types/model/lot/lot';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import { addArrayElement, setArrayElement } from '../../services/appFunctionsService';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import { IOptionType } from '../../@types/helper';
import PillButton from '../../components/input/PillButton';
import { dataSetAllStorageUnitDashboardData } from '../../store/masterData/Functions';
import { syncMasterData } from '../../services/masterDataSyncService';

interface IStorageUnitDashboardProps {
    dataSetStorageUnits : DispatchCall<Array<IStorageUnit>>;
    dataSetSites : DispatchCall<Array<ISite>>;
    storageUnitData : Array<IStorageUnit>;
    sites : Array<ISite>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    orchards : Array<IOrchard>;
    selectedOrganizationIds : Array<number>;
    selectedSiteIds : Array<number>;
    organizations : Array<IOrganization>;
    lots : Array<ILot>;
}

interface IStorageUnitDashboardState {
    isLoading : boolean;
    rows : Array<{ storageUnitId ?: number; siteId : number; commodityId ?: number; varietyId ?: number; orchardId ?: number; lotIds : Array<number>; totalGrossWeight : number; totalNettWeight : number}>;
    isSiteDetailPopupOpen : boolean;
    selectedSite ?: ISite;
    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;
    selectedStorageUnit ?: IStorageUnit;
    showMap : boolean;
    selectedStorageUnitLocation ?: IStorageUnit;
    selectedFilterStorageUnit ?: IOptionType;
}

class StorageUnitDashboard extends React.Component<IStorageUnitDashboardProps, IStorageUnitDashboardState> {
    public mapRef : Map;

    public initialCenterPosition = new LatLng(-28.4792625, 24.6727135);
    public initialMapZoomLevel = 6;
    public selectedStorageUnitLocationMapZoomLevel = 8;

    constructor(props : IStorageUnitDashboardProps) {
        super(props);

        this.state = {
            isLoading: false,
            rows: [],
            isSiteDetailPopupOpen: false,
            selectedFromDate: moment().local().startOf('day').subtract(7, 'days'),
            selectedToDate: moment().local().endOf('day'),
            showMap: false,
        };
    }

    public componentDidMount = async () => {
        if (!this.props.selectedSiteIds) return;

        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        this.setLoading(true);
        try {
            let siteData : Array<ISite> = [];
            if (!isIndexedDBAvailable) {
                const res2 = await SiteHttpService.getSiteData();
                siteData = res2.data;
                this.props.dataSetSites(siteData);
            }

            if (siteData) {
                const res3 = await StorageUnitHttpService.getDashboardData(this.getDate('from'), this.getDate('to'), this.props.selectedSiteIds, !isIndexedDBAvailable);

                if (res3.data) {
                    dataSetAllStorageUnitDashboardData(res3.data);
                }
            }

            const filteredRows = this.getFilteredRows(this.props, this.state);
            this.setState({ rows: filteredRows });
            this.setLoading(false);
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading storage unit data.');
            this.setLoading(false);
        }
    };

    public componentDidUpdate = (prevProps : IStorageUnitDashboardProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            /* prop changes go here */
            if (nextProps.selectedSiteIds !== undefined && prevProps.selectedSiteIds !== nextProps.selectedSiteIds) {
                this.refreshData();
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private refreshData = async () => {
        this.setLoading(true);
        try {
            const res2 = await SiteHttpService.getSiteData();

            const siteData = res2.data;
            if (siteData) {
                const res3 = await StorageUnitHttpService.getDashboardData(this.getDate('from'), this.getDate('to'), this.props.selectedSiteIds);

                if (res3.data) {
                    dataSetAllStorageUnitDashboardData(res3.data);
                }
                this.props.dataSetSites(siteData);
            }
            const filteredRows = this.getFilteredRows(this.props, this.state);
            this.setState({ rows: filteredRows });
            this.setLoading(false);
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while refreshing data.');
            this.setLoading(false);
        }
    };

    private getDate = (type : 'from' | 'to') => {
        switch (type) {
            case 'from':
                return this.state.selectedFromDate.startOf('day').utc().unix() * 1000;
            case 'to':
                return this.state.selectedToDate.endOf('day').utc().unix() * 1000;
        }
    };

    public setMapRef = (ref : Map) => {
        this.mapRef = ref;
    };

    private getSiteDescription = (siteId : number) => {
        const sites = this.props.sites;
        const site = sites.find(x => x.id === siteId);

        return site ? site?.description : '';
    };

    private getSiteCodeAndDescription = (siteId : number) => {
        const sites = this.props.sites;
        const site = sites.find(x => x.id === siteId);

        return site ? site.code + ' - ' + site?.description : '';
    };

    private getStorageUnitDescription = (storageUnitId : number) => {
        const storageUnits = this.props.storageUnitData;
        const storageUnit = storageUnits.find(x => x.id === storageUnitId);

        return storageUnit ? storageUnit?.description : '';
    };

    private getCommodityName = (commodityId : number) => {
        const commodities = this.props.commodities;
        const commodity = commodities?.find(x => x.id === commodityId);

        return commodity ? commodity.name : '';
    };

    private getVarietyName = (varietyId : number) => {
        const varieties = this.props.varieties;
        const variety = varieties?.find(x => x.id === varietyId);

        return variety ? variety.name : '';
    };

    private getOrchardName = (orchardId : number) => {
        const orchards = this.props.orchards;
        const orchard = orchards?.find(x => x.id === orchardId);

        return orchard ? orchard.name : '';
    };

    private getSites = (props : IStorageUnitDashboardProps) => props.sites;
    private getStorageUnits = (props : IStorageUnitDashboardProps) => props.storageUnitData;
    private getLotData = (props : IStorageUnitDashboardProps) => props.lots;
    private getSelectedSites = (props : IStorageUnitDashboardProps) => props.selectedSiteIds;
    private getSelectedStorageUnit = (props : IStorageUnitDashboardProps, state : IStorageUnitDashboardState) => state.selectedStorageUnit;
    private getSelectedStorageUnitLocation = (props : IStorageUnitDashboardProps, state : IStorageUnitDashboardState) => state.selectedStorageUnitLocation;

    private getSiteLocations = createSelector(
        [this.getSites, this.getSelectedSites],
        (sites : Array<ISite>, selectedSiteIds : Array<number>) => {
            return sites.filter(x => x.isActive
                && x.isPackhouse
                && selectedSiteIds?.some(y => y === x.id)).map((x) => {
                return { site: x, location: new LatLng(Number(x.latitude), Number(x.longitude)) };
            });
        },
    );

    private getStorageUnitLocations = createSelector(
        [this.getStorageUnits, this.getSelectedSites],
        (storageUnitData : Array<IStorageUnit>, selectedSiteIds : Array<number>) => {
            return storageUnitData.filter(x => x.isActive
                && selectedSiteIds?.some(y => y === x.siteId)).map((x) => {
                return { storageUnit: x, location: new LatLng(Number(x.latitude), Number(x.longitude)) };
            });
        },
    );

    private getFilteredRows = createSelector(
        [this.getLotData, this.getSelectedStorageUnit, this.getSelectedStorageUnitLocation, this.getSelectedSites],
        (lots : Array<ILot>, selectedStorageUnit : IStorageUnit, selectedStorageUnitLocation : IStorageUnit, selectedSiteIds : Array<number>) => {
            if (!lots) return [];
            let storageUnits : Array<{ storageUnitId ?: number; siteId : number; commodityId ?: number; varietyId ?: number; orchardId ?: number; lotIds : Array<number>; totalGrossWeight : number; totalNettWeight : number}> = [];

            lots?.filter(x => x.isActive && !!x.storageUnitId && selectedSiteIds?.some(y => y === x.siteId)).forEach((x) => {
                const index = storageUnits.findIndex(y => x.commodityId === y.commodityId && x.varietyId === y.varietyId && x.orchardId === y.orchardId && x.storageUnitId === y.storageUnitId && x.siteId === y.siteId);
                if (index === -1) {
                    const data = {
                        id : index,
                        storageUnitId: x.storageUnitId,
                        commodityId: x.commodityId,
                        varietyId: x.varietyId,
                        orchardId: x.orchardId,
                        totalGrossWeight: x.grossWeight,
                        totalNettWeight: x.nettWeight,
                        lotIds: [x.id],
                        siteId: x.siteId,
                    };
                    storageUnits = addArrayElement(storageUnits, data, 'end');
                } else {
                    const storageUnit = { ...storageUnits[index] };
                    storageUnit.totalGrossWeight = storageUnit.totalGrossWeight + x.grossWeight;
                    storageUnit.totalNettWeight = storageUnit.totalNettWeight + x.nettWeight;
                    storageUnit.lotIds = addArrayElement(storageUnit.lotIds, x.id, 'end');

                    storageUnits = setArrayElement(storageUnits, index, storageUnit);
                }
            });

            return storageUnits.filter(x => selectedStorageUnit ? selectedStorageUnit.id === x.storageUnitId : selectedStorageUnitLocation ? selectedStorageUnitLocation.id === x.storageUnitId : true);
        },
    );

    private getLotsNotAtStorageUnits = createSelector(
        [this.getLotData, this.getSelectedSites],
        (lots : Array<ILot>, selectedSiteIds : Array<number>) => {
            if (!lots) return [];
            let storageUnits : Array<{ storageUnitId ?: number; siteId : number; commodityId ?: number; varietyId ?: number; orchardId ?: number; lotIds : Array<number>; totalGrossWeight : number; totalNettWeight : number}> = [];

            lots?.filter(x => x.isActive && !x.storageUnitId && selectedSiteIds?.some(y => y === x.siteId)).forEach((x) => {
                const index = storageUnits.findIndex(y => x.commodityId === y.commodityId && x.varietyId === y.varietyId && x.orchardId === y.orchardId && x.siteId === y.siteId);
                if (index === -1) {
                    const data = {
                        commodityId: x.commodityId,
                        varietyId: x.varietyId,
                        orchardId: x.orchardId,
                        totalGrossWeight: x.grossWeight,
                        totalNettWeight: x.nettWeight,
                        lotIds: [x.id],
                        siteId: x.siteId,
                    };
                    storageUnits = addArrayElement(storageUnits, data, 'end');
                } else {
                    const storageUnit = { ...storageUnits[index] };
                    storageUnit.totalGrossWeight = storageUnit.totalGrossWeight + x.grossWeight;
                    storageUnit.totalNettWeight = storageUnit.totalNettWeight + x.nettWeight;
                    storageUnit.lotIds = addArrayElement(storageUnit.lotIds, x.id, 'end');

                    storageUnits = setArrayElement(storageUnits, index, storageUnit);
                }
            });

            return storageUnits;
        },
    );

    private handleDateRangeChange = (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => {
        const selectedFromDate = changedBy === 'start' ? moment(start).startOf('day') : end < start ? moment(end).startOf('day') : moment(start).startOf('day');
        const selectedToDate = changedBy === 'end' ? moment(end).endOf('day') : start > end ? moment(start).endOf('day') : moment(end).endOf('day');
        this.setState({ selectedFromDate, selectedToDate });
    };

    private onMarkerClick = (id : number) => {
        const selectedStorageUnit = this.props.storageUnitData.find(x => x.id === id);
        if (selectedStorageUnit) {
            this.setState({ selectedStorageUnit });
        }
    };

    private onMarkerPopupClose = () => {
        this.setState({ selectedStorageUnit: undefined });
    };

    private getCommodityCode = (commodityId : number) => {
        const commodities = this.props.commodities;

        const commodity = commodities?.find(x => x.id === commodityId);

        return commodity ? commodity.code : '';
    };

    private getVarietyCode = (varietyId : number) => {
        const varieties = this.props.varieties;

        const variety = varieties?.find(x => x.id === varietyId);

        return variety ? variety.code : '';
    };

    private getOrchardCode = (orchardId : number) => {
        const orchards = this.props.orchards;

        const orchard = orchards?.find(x => x.id === orchardId);

        return orchard ? orchard.code : '';
    };

    private isStorageUnitTippable = (id : number) => {
        const storageUnits = this.props.storageUnitData;
        const storageUnit = storageUnits.find(x => x.id === id);

        if (storageUnit) {
            if (storageUnit.isTippable) {
                return 'Yes';
            } else {
                return 'No';
            }
        }
    };

    private toggleShowMap = (value : boolean) => this.setState({ showMap: value });

    private onRowClick = (row : { storageUnitId ?: number; commodityId ?: number; varietyId ?: number; orchardId ?: number; lotIds : Array<number>; totalGrossWeight : number; totalNettWeight : number}) => {
        const storageUnits = this.props.storageUnitData;

        const selectedStorageUnit = storageUnits.find(x => x.id === row.storageUnitId);

        if (selectedStorageUnit) {
            this.setState({ selectedStorageUnitLocation: selectedStorageUnit, selectedStorageUnit }, () => {
                const filteredRows = this.getFilteredRows(this.props, this.state);
                this.setState({ rows: filteredRows });
            });
            this.toggleShowMap(true);
        }
    };

    private getFilterStorageUnitOptions = createSelector(
        [this.getStorageUnits, this.getSelectedSites],
        (storageUnits : Array<IStorageUnit>, selectedSiteIds : Array<number>) => {
            if (!storageUnits) return [];

            return storageUnits.filter(x => x.isActive && selectedSiteIds?.some(y => y === x.siteId)).map((x) => {
                return { label: `(${x.code}) - ${x.description}`, value: x.id };
            });
        },
    );

    private onFilterStorageUnitChange = (e : React.ChangeEvent<{}>, selectedFilterStorageUnit : IOptionType) => {
        const storageUnits = this.props.storageUnitData;
        const selectedStorageUnit = selectedFilterStorageUnit ? storageUnits.find(x => x.id === selectedFilterStorageUnit.value) : undefined;
        this.setState({ selectedFilterStorageUnit, selectedStorageUnit, selectedStorageUnitLocation: selectedStorageUnit }, () => {
            const filteredRows = this.getFilteredRows(this.props, this.state);
            this.setState({ rows: filteredRows });
        });
    };

    private showAllMapMarkers = () => {
        this.setState({ selectedStorageUnit: undefined, selectedStorageUnitLocation: undefined, selectedFilterStorageUnit: undefined }, () => {
            const filteredRows = this.getFilteredRows(this.props, this.state);
            this.setState({ rows: filteredRows });
        });
    };

    public render() {
        const rows = this.state.rows;
        const selectedStorageUnitLocation = new LatLng(Number(this.state.selectedStorageUnitLocation?.latitude ?? 0), Number(this.state.selectedStorageUnitLocation?.longitude ?? 0));
        return (
            <Screen isLoading={this.state.isLoading} isPadded={false} isScrollable={false}>
                <div className={'fdr wfill jcfe aic mb5 mr10 pl10 pr10'}>
                    <PillButton
                        disabled={this.state.isLoading || (!this.state.selectedStorageUnit && !this.state.selectedStorageUnitLocation)}
                        className={`${this.state.showMap ? 'h35 cpd' : 'dn'}`}
                        text={'Show all markers'}
                        color={'secondary'}
                        onClick={this.showAllMapMarkers}
                    />
                    <div className={'flx1'}/>
                    <AutocompleteSelect
                        className={'w300 mb10'}
                        name={'storageUnits'}
                        label={'Select Storage Unit'}
                        options={this.getFilterStorageUnitOptions(this.props)}
                        onChange={this.onFilterStorageUnitChange}
                        value={this.state.selectedFilterStorageUnit}
                    />
                    <div className={'w10'}/>
                    <TransactionFilter className={'mt0 p0 mb10'} selectedFromDate={this.state.selectedFromDate} selectedToDate={this.state.selectedToDate} handleDateRangeChange={this.handleDateRangeChange} onApplyClick={this.refreshData} />
                    <div className={'w20'}/>
                    <div className='aic jcc h90'>
                        <ToggleButtonGroup exclusive value={this.state.showMap}>
                            <ToggleButton onClick={() => this.toggleShowMap(false)} className={`ToggleButtonLeft${!this.state.showMap ? 'Selected' : ' bcw'}`} value={false}>TABLE</ToggleButton>
                            <ToggleButton onClick={() => this.toggleShowMap(true)} className={`ToggleButtonRight${this.state.showMap ? 'Selected' : ' bcw'}`} value={true}>MAP</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
                <div className={'fdc pl10 pr10 hfill pb20 oxh'}>
                    {!this.state.showMap &&
                        <Card className={'fdc flx1 wfill hfill'}>
                            <CustomTable<{ storageUnitId ?: number; commodityId ?: number; varietyId ?: number; orchardId ?: number; lotIds : Array<number>; totalGrossWeight : number; totalNettWeight : number}>
                                enableDetails={true}
                                detailIcon={<FontAwesomeIcon icon={faMapMarkedAlt} className={'cpd'} size={'sm'}/>}
                                detailFunction={this.onRowClick}
                                detailTooltip={'Show storage unit on map'}
                                enableSorting
                                enableFiltering
                                fitWidthToPage
                                enablePagination
                                columns={[
                                    { title: 'Site', field: 'siteId', formatFunction: this.getSiteDescription, enableFiltering: true, enableSorting: true },
                                    { title: 'Storage Unit', field: 'storageUnitId', formatFunction: this.getStorageUnitDescription, enableFiltering: true, enableSorting: true },
                                    { title: 'Commodity', field: 'commodityId', formatFunction: this.getCommodityName, enableFiltering: true, enableSorting: true },
                                    { title: 'Variety', field: 'varietyId', formatFunction: this.getVarietyName, enableFiltering: true, enableSorting: true },
                                    { title: 'Orchard', field: 'orchardId', formatFunction: this.getOrchardName, enableFiltering: true, enableSorting: true },
                                    { title: 'Total Nett Weight (kg)', field: 'totalNettWeight', enableFiltering: true, enableSorting: true },
                                    { title: 'Total Gross Weight (kg)', field: 'totalGrossWeight', enableFiltering: true, enableSorting: true },
                                ]}
                                rows={this.state.rows}
                                initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                                pageSizes={[50, 150, 250, 500, 1000]}
                                pageHeight={300}
                            />
                        </Card>
                    }
                    {this.state.showMap &&
                        <div style={{ height: 780, minHeight: 780 }} className='bcg1 PaperBorder wfill asc fdc aic jcc oln posr'>
                            <Map
                                style={{ height: 780, minHeight: 780 }}
                                center={this.state.selectedStorageUnitLocation ? selectedStorageUnitLocation : this.initialCenterPosition}
                                zoom={this.state.selectedStorageUnitLocation ? this.selectedStorageUnitLocationMapZoomLevel : this.initialMapZoomLevel}
                                maxZoom={18}
                                ref={this.setMapRef}
                                className={'flx1 bcw wfill'}
                                preferCanvas>
                                <StandardLayerControl position={'topleft'}/>
                                {/* Storage Unit Markers */}
                                {!!this.state.selectedStorageUnitLocation &&
                                    <Marker key={'selectedStorageUnitLocation_marker'} title={`Storage unit: ${this.state.selectedStorageUnitLocation.code} - ${this.state.selectedStorageUnitLocation.description}`}
                                        position={selectedStorageUnitLocation}
                                        onclick={() => this.onMarkerClick(this.state.selectedStorageUnitLocation?.id ?? 0)}
                                        icon={new Icon({
                                            iconUrl: `${ASSET_BASE}/assets/icons/mapMarkers/green.svg`,
                                            iconSize: [30, 30],
                                            iconAnchor: [15, 30],
                                            popupAnchor: [0, 0],
                                            shadowSize: [15, 20],
                                            shadowAnchor: [7, 20],
                                        })}>
                                        <Popup id={'selectedStorageUnitLocation_popup'} className={'w750'} onClose={this.onMarkerPopupClose}>
                                            <div style={{ width: 700, maxHeight: 300 }} className={'fdr oya'}>
                                                <Table className= {'PaperBorder'}>
                                                    {/* Headings */}
                                                    <TableRow className={'fdr lotSummaryDarkBlue'}>
                                                        <TableCell className={'flx1 jcc aic p0 h23 cw'}>
                                                            <div className={'fdr flx1 pr10 pl10 jcsb'}>
                                                                <div>{this.getStorageUnitDescription(this.state.selectedStorageUnitLocation.id)}</div>
                                                                <div>{`Tippable: ${this.isStorageUnitTippable(this.state.selectedStorageUnitLocation.id)}`}</div>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className={'fdr bbw1 bocw'}>
                                                        <TableCell className={'flx1 jcc aic p0 lotSummaryLightBlue'}>
                                                            <div className={'flx1 aic jcc cw'}>Commodity</div>
                                                        </TableCell>
                                                        <TableCell className={'flx1 jcc aic p0 lotSummaryLightBlue'}>
                                                            <div className={'flx1 aic jcc cw'}>Variety</div>
                                                        </TableCell>
                                                        <TableCell className={'flx1 jcc aic p0 lotSummaryLightBlue'}>
                                                            <div className={'flx1 aic jcc cw'}>Orchard</div>
                                                        </TableCell>
                                                        <TableCell className={'flx1 jcc aic p0 lotSummaryLightBlue'}>
                                                            <div className={'flx1 aic jcc cw'}>Total Nett Weight</div>
                                                        </TableCell>
                                                        <TableCell className={'flx1 jcc aic p0 lotSummaryLightBlue'}>
                                                            <div className={'flx1 aic jcc cw'}>Total Gross Weight</div>
                                                        </TableCell>
                                                    </TableRow>
                                                    {/* Body */}
                                                    {rows.length < 1 &&
                                                        <TableRow className={'fdr bbw1 bocw'}>
                                                            <TableCell className={'flx1 jcc aic p0 fw500'}>
                                                                <div className={'flx1 aic jcc hfill'}>{'No Data'}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {rows.length > 0 && rows.map(y =>
                                                        <TableRow className={'fdr bbw1 bocw'}>
                                                            <TableCell className={`flx1 jcc aic p0 fw500 bl1 ${rows.findIndex(z => z.commodityId === y.commodityId && z.varietyId === y.varietyId && z.orchardId === y.orchardId && z.storageUnitId === y.storageUnitId && z.siteId === y.siteId) % 2 === 0 ? 'lotSummaryLightestGrey' : 'lotSummaryLightGrey'}`}>
                                                                <div className={'flx1 aic jcc hfill'}>{this.getCommodityCode(y.commodityId ?? 0)}</div>
                                                            </TableCell>
                                                            <TableCell className={`flx1 jcc aic p0 fw500 bl1 ${rows.findIndex(z => z.commodityId === y.commodityId && z.varietyId === y.varietyId && z.orchardId === y.orchardId && z.storageUnitId === y.storageUnitId && z.siteId === y.siteId) % 2 === 0 ? 'lotSummaryLightestGrey' : 'lotSummaryLightGrey'}`}>
                                                                <div className={'flx1 aic jcc hfill'}>{this.getVarietyCode(y.varietyId ?? 0)}</div>
                                                            </TableCell>
                                                            <TableCell className={`flx1 jcc aic p0 fw500 bl1 ${rows.findIndex(z => z.commodityId === y.commodityId && z.varietyId === y.varietyId && z.orchardId === y.orchardId && z.storageUnitId === y.storageUnitId && z.siteId === y.siteId) % 2 === 0 ? 'lotSummaryLightestGrey' : 'lotSummaryLightGrey'}`}>
                                                                <div className={'flx1 aic jcc hfill'}>{this.getOrchardCode(y.orchardId ?? 0)}</div>
                                                            </TableCell>
                                                            <TableCell className={`flx1 jcc aic p0 fw500 w200 bl1 ${rows.findIndex(z => z.commodityId === y.commodityId && z.varietyId === y.varietyId && z.orchardId === y.orchardId && z.storageUnitId === y.storageUnitId && z.siteId === y.siteId) % 2 === 0 ? 'lotSummaryLightestGrey' : 'lotSummaryLightGrey'}`}>
                                                                <div className={'flx1 aic jcc hfill'}>{y.totalNettWeight}</div>
                                                            </TableCell>
                                                            <TableCell className={`flx1 jcc aic p0 fw500 w200 bl1 ${rows.findIndex(z => z.commodityId === y.commodityId && z.varietyId === y.varietyId && z.orchardId === y.orchardId && z.storageUnitId === y.storageUnitId && z.siteId === y.siteId) % 2 === 0 ? 'lotSummaryLightestGrey' : 'lotSummaryLightGrey'}`}>
                                                                <div className={'flx1 aic jcc hfill'}>{y.totalGrossWeight}</div>
                                                            </TableCell>
                                                        </TableRow>,
                                                    )}
                                                </Table>
                                            </div>
                                        </Popup>
                                    </Marker>
                                }
                                {!this.state.selectedStorageUnitLocation &&
                                    this.getStorageUnitLocations(this.props).map(x =>
                                        <Marker title={`Storage unit: ${x.storageUnit.code} - ${x.storageUnit.description}`} position={x.location} onclick={() => this.onMarkerClick(x.storageUnit.id)}
                                            icon={new Icon({
                                                iconUrl: `${ASSET_BASE}/assets/icons/mapMarkers/green.svg`,
                                                iconSize: [30, 30],
                                                iconAnchor: [15, 30],
                                                popupAnchor: [0, 0],
                                                shadowSize: [15, 20],
                                                shadowAnchor: [7, 20],
                                            })}>
                                            <Popup keepInView className={'w750'} onClose={this.onMarkerPopupClose}>
                                                <div style={{ width: 650, maxHeight: 300 }} className={'fdr oya'}>
                                                    <Table className= {'PaperBorder'}>
                                                        {/* Headings */}
                                                        <TableRow className={'fdr lotSummaryDarkBlue'}>
                                                            <TableCell className={'flx1 jcc aic p0 h23 cw'}>
                                                                <div className={'fdr flx1 pr10 pl10 jcsb'}>
                                                                    <div>{this.getStorageUnitDescription(x.storageUnit.id)}</div>
                                                                    <div>{`Tippable: ${this.isStorageUnitTippable(x.storageUnit.id)}`}</div>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow className={'fdr bbw1 bocw'}>
                                                            <TableCell className={'flx1 jcc aic p0 lotSummaryLightBlue'}>
                                                                <div className={'flx1 aic jcc cw'}>Commodity</div>
                                                            </TableCell>
                                                            <TableCell className={'flx1 jcc aic p0 lotSummaryLightBlue'}>
                                                                <div className={'flx1 aic jcc cw'}>Variety</div>
                                                            </TableCell>
                                                            <TableCell className={'flx1 jcc aic p0 lotSummaryLightBlue'}>
                                                                <div className={'flx1 aic jcc cw'}>Orchard</div>
                                                            </TableCell>
                                                            <TableCell className={'flx1 jcc aic p0 lotSummaryLightBlue'}>
                                                                <div className={'flx1 aic jcc cw'}>Total Nett Weight</div>
                                                            </TableCell>
                                                            <TableCell className={'flx1 jcc aic p0 lotSummaryLightBlue'}>
                                                                <div className={'flx1 aic jcc cw'}>Total Gross Weight</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        {/* Body */}
                                                        {rows.length < 1 &&
                                                            <TableRow className={'fdr bbw1 bocw'}>
                                                                <TableCell className={'flx1 jcc aic p0 fw500'}>
                                                                    <div className={'flx1 aic jcc hfill'}>{'No Data'}</div>
                                                                </TableCell>
                                                            </TableRow>
                                                        }
                                                        {rows.length > 0 && rows.map(y =>
                                                            <TableRow className={'fdr bbw1 bocw'}>
                                                                <TableCell className={`flx1 jcc aic p0 fw500 bl1 ${rows.findIndex(z => z.commodityId === y.commodityId && z.varietyId === y.varietyId && z.orchardId === y.orchardId && z.storageUnitId === y.storageUnitId && z.siteId === y.siteId) % 2 === 0 ? 'lotSummaryLightestGrey' : 'lotSummaryLightGrey'}`}>
                                                                    <div className={'flx1 aic jcc hfill'}>{this.getCommodityCode(y.commodityId ?? 0)}</div>
                                                                </TableCell>
                                                                <TableCell className={`flx1 jcc aic p0 fw500 bl1 ${rows.findIndex(z => z.commodityId === y.commodityId && z.varietyId === y.varietyId && z.orchardId === y.orchardId && z.storageUnitId === y.storageUnitId && z.siteId === y.siteId) % 2 === 0 ? 'lotSummaryLightestGrey' : 'lotSummaryLightGrey'}`}>
                                                                    <div className={'flx1 aic jcc hfill'}>{this.getVarietyCode(y.varietyId ?? 0)}</div>
                                                                </TableCell>
                                                                <TableCell className={`flx1 jcc aic p0 fw500 bl1 ${rows.findIndex(z => z.commodityId === y.commodityId && z.varietyId === y.varietyId && z.orchardId === y.orchardId && z.storageUnitId === y.storageUnitId && z.siteId === y.siteId) % 2 === 0 ? 'lotSummaryLightestGrey' : 'lotSummaryLightGrey'}`}>
                                                                    <div className={'flx1 aic jcc hfill'}>{this.getOrchardCode(y.orchardId ?? 0)}</div>
                                                                </TableCell>
                                                                <TableCell className={`flx1 jcc aic p0 fw500 w200 bl1 ${rows.findIndex(z => z.commodityId === y.commodityId && z.varietyId === y.varietyId && z.orchardId === y.orchardId && z.storageUnitId === y.storageUnitId && z.siteId === y.siteId) % 2 === 0 ? 'lotSummaryLightestGrey' : 'lotSummaryLightGrey'}`}>
                                                                    <div className={'flx1 aic jcc hfill'}>{y.totalNettWeight}</div>
                                                                </TableCell>
                                                                <TableCell className={`flx1 jcc aic p0 fw500 w200 bl1 ${rows.findIndex(z => z.commodityId === y.commodityId && z.varietyId === y.varietyId && z.orchardId === y.orchardId && z.storageUnitId === y.storageUnitId && z.siteId === y.siteId) % 2 === 0 ? 'lotSummaryLightestGrey' : 'lotSummaryLightGrey'}`}>
                                                                    <div className={'flx1 aic jcc hfill'}>{y.totalGrossWeight}</div>
                                                                </TableCell>
                                                            </TableRow>,
                                                        )}
                                                    </Table>
                                                </div>
                                            </Popup>
                                        </Marker>,
                                    )}
                                {/* Site Markers */}
                                {!this.state.selectedStorageUnitLocation && this.getSiteLocations(this.props).map(x =>
                                    <Marker  title={`Packhouse: ${x.site.code} - ${x.site.description}`} position={x.location}>
                                        <Popup className={'w750'}>
                                            <div style={{ width: 650, maxHeight: 300 }} className={'fdr oya'}>
                                                <Table className= {'PaperBorder'}>
                                                    {/* Headings */}
                                                    <TableRow className={'fdr lotSummaryDarkBlue'}>
                                                        <TableCell className={'flx1 jcc aic p0 h23 cw'}>
                                                            <div className={'fdr'}>
                                                                <div>{this.getSiteCodeAndDescription(x.site.id)}</div>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className={'fdr bbw1 bocw'}>
                                                        <TableCell className={'flx1 jcc aic p0 lotSummaryLightBlue'}>
                                                            <div className={'flx1 aic jcc cw'}>Commodity</div>
                                                        </TableCell>
                                                        <TableCell className={'flx1 jcc aic p0 lotSummaryLightBlue'}>
                                                            <div className={'flx1 aic jcc cw'}>Variety</div>
                                                        </TableCell>
                                                        <TableCell className={'flx1 jcc aic p0 lotSummaryLightBlue'}>
                                                            <div className={'flx1 aic jcc cw'}>Orchard</div>
                                                        </TableCell>
                                                        <TableCell className={'flx1 jcc aic p0 lotSummaryLightBlue'}>
                                                            <div className={'flx1 aic jcc cw'}>Total Nett Weight</div>
                                                        </TableCell>
                                                        <TableCell className={'flx1 jcc aic p0 lotSummaryLightBlue'}>
                                                            <div className={'flx1 aic jcc cw'}>Total Gross Weight</div>
                                                        </TableCell>
                                                    </TableRow>
                                                    {/* Body */}
                                                    {this.getLotsNotAtStorageUnits(this.props).filter(y => x.site.id === y.siteId).length < 1 &&
                                                        <TableRow className={'fdr bbw1 bocw'}>
                                                            <TableCell className={'flx1 jcc aic p0 fw500'}>
                                                                <div className={'flx1 aic jcc hfill'}>{'No Data'}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {this.getLotsNotAtStorageUnits(this.props).length > 0 && this.getLotsNotAtStorageUnits(this.props).filter(y => x.site.id === y.siteId).map(y =>
                                                        <TableRow className={'fdr bbw1 bocw'}>
                                                            <TableCell className={`flx1 jcc aic p0 fw500 bl1 ${rows.findIndex(z => z.commodityId === y.commodityId && z.varietyId === y.varietyId && z.orchardId === y.orchardId && z.storageUnitId === y.storageUnitId && z.siteId === y.siteId) % 2 === 0 ? 'lotSummaryLightestGrey' : 'lotSummaryLightGrey'}`}>
                                                                <div className={'flx1 aic jcc hfill'}>{this.getCommodityCode(y.commodityId ?? 0)}</div>
                                                            </TableCell>
                                                            <TableCell className={`flx1 jcc aic p0 fw500 bl1 ${rows.findIndex(z => z.commodityId === y.commodityId && z.varietyId === y.varietyId && z.orchardId === y.orchardId && z.storageUnitId === y.storageUnitId && z.siteId === y.siteId) % 2 === 0 ? 'lotSummaryLightestGrey' : 'lotSummaryLightGrey'}`}>
                                                                <div className={'flx1 aic jcc hfill'}>{this.getVarietyCode(y.varietyId ?? 0)}</div>
                                                            </TableCell>
                                                            <TableCell className={`flx1 jcc aic p0 fw500 bl1 ${rows.findIndex(z => z.commodityId === y.commodityId && z.varietyId === y.varietyId && z.orchardId === y.orchardId && z.storageUnitId === y.storageUnitId && z.siteId === y.siteId) % 2 === 0 ? 'lotSummaryLightestGrey' : 'lotSummaryLightGrey'}`}>
                                                                <div className={'flx1 aic jcc hfill'}>{this.getOrchardCode(y.orchardId ?? 0)}</div>
                                                            </TableCell>
                                                            <TableCell className={`flx1 jcc aic p0 fw500 w200 bl1 ${rows.findIndex(z => z.commodityId === y.commodityId && z.varietyId === y.varietyId && z.orchardId === y.orchardId && z.storageUnitId === y.storageUnitId && z.siteId === y.siteId) % 2 === 0 ? 'lotSummaryLightestGrey' : 'lotSummaryLightGrey'}`}>
                                                                <div className={'flx1 aic jcc hfill'}>{y.totalNettWeight}</div>
                                                            </TableCell>
                                                            <TableCell className={`flx1 jcc aic p0 fw500 w200 bl1 ${rows.findIndex(z => z.commodityId === y.commodityId && z.varietyId === y.varietyId && z.orchardId === y.orchardId && z.storageUnitId === y.storageUnitId && z.siteId === y.siteId) % 2 === 0 ? 'lotSummaryLightestGrey' : 'lotSummaryLightGrey'}`}>
                                                                <div className={'flx1 aic jcc hfill'}>{y.totalGrossWeight}</div>
                                                            </TableCell>
                                                        </TableRow>,
                                                    )}
                                                </Table>
                                            </div>
                                        </Popup>
                                    </Marker>,
                                )}
                            </Map>
                            <div style={{ right: 40, top: 680 }} className={'fdc posa p10 bcg1 zi999'}>
                                <div>{'Markers:'}</div>
                                <div className={'fdr flx1 pl5 pr5 jcsb aic'}>
                                    <div className={'h10 w10 bcp'}></div>
                                    <div className={'w10'}/>
                                    <div>Storage Unit</div>
                                </div>
                                <div className={'fdr flx1 pl5 pr5 jcsb aic'}>
                                    <div className={'h10 w10 bcb'}></div>
                                    <div className={'w10'}/>
                                    <div>Packhouse</div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        sites: state.masterData.sites,
        storageUnitData: state.masterData.storageUnits,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        orchards: state.masterData.orchards,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
        organizations: state.masterData.organizations,
        lots: state.data.lots,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetSites, dataSetStorageUnits }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StorageUnitDashboard);

import * as React from 'react';
import { Form, FormikProps } from 'formik';
import Button from '@mui/material/Button';
import { InfoBoardDataFormValues } from '../../../@types/model/home/infoBoardDataFormValues';
import FormTextInput from '../../../components/input/form/FormTextInput';
import PillButton from '../../../components/input/PillButton';
import { Icon, IconButton } from '@mui/material';
import FormSingleToggleButton from '../../../components/input/form/FormSingleToggleButton';
import CustomTooltip from '../../../components/tooltip/tooltip';

interface IInfoBoardEditFormProps {
    isLoading : boolean;
}

interface IInfoBoardEditFormState {
    isBoldTextClicked : boolean;
}

type InfoBoardEditFormPropsType = IInfoBoardEditFormProps & FormikProps<InfoBoardDataFormValues>;

export default class InfoBoardEditForm extends React.Component<InfoBoardEditFormPropsType, IInfoBoardEditFormState> {
    constructor(props : InfoBoardEditFormPropsType) {
        super(props);

        this.state = {
            isBoldTextClicked: false,
        };
    }

    private addBulletPoint = () => {
        const currentBody = this.props.values.body;
        const newBody = currentBody + '- ';
        this.props.setFieldValue('body', newBody);
    };

    private boldText = () => {
        const currentBody = this.props.values.body;
        const newBody = currentBody + '**';
        this.setState({ isBoldTextClicked: !this.state.isBoldTextClicked });
        this.props.setFieldValue('body', newBody);
    };

    public render() {
        return (
            <Form className={'p20 w600'}>
                <FormTextInput name={'heading'} label={'Heading'}/>
                <div className={'h10'}/>
                <div className={'fdr aic jcfe mr10'}>
                    <CustomTooltip title={'Add Bullet Point'}>
                        <IconButton size={'small'} onClick={this.addBulletPoint}><Icon>format_list_bulleted</Icon></IconButton>
                    </CustomTooltip>
                    <CustomTooltip title={'Bold Text'}>
                        <IconButton className={`${this.state.isBoldTextClicked ? 'bcg2' : ''}`} size={'small'} onClick={this.boldText}><Icon>format_bold</Icon></IconButton>
                    </CustomTooltip>
                </div>
                <FormTextInput multiline rows={10} name={'body'} label={'Body'}/>
                <div className={'fdr ml10 ais pt10 pb10 wfill'}>
                    <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                    <div className={'flx1'} />
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

import axios, { AxiosPromise } from 'axios';
import { IPrinter } from '../../../@types/model/masterData/printer/printer';
import { PACKMAN_API_URL } from '../../../appConstants';

export default class PrinterHttpService {
    public static getPrinterData() : AxiosPromise<Array<IPrinter>> {
        return axios.get(`${PACKMAN_API_URL}Printer/GetAllWeb`);
    }

    public static addOrUpdatePrinter(data : IPrinter) : AxiosPromise<IPrinter> {
        return axios.post(`${PACKMAN_API_URL}Printer/PostPrinter`, data);
    }

    public static deletePrinter(printerId : number) : AxiosPromise<IPrinter> {
        return axios.delete(`${PACKMAN_API_URL}Printer/Delete`, {
            params : { printerId },
        });
    }
}

import { IBaseModel } from '../../../model';
import { IDepartmentFormValues } from './departmentFormValues';

export interface IDepartment extends IBaseModel {
    code : string;
    shortDescription : string;
    description : string;
    isImported ?: boolean;
}

export class Department implements IDepartment {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public shortDescription : string;
    public description : string;
    public isImported ?: boolean;

    public constructor(formValues : IDepartmentFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.code = formValues.code;
        this.shortDescription = formValues.shortDescription;
        this.description = formValues.description;
        this.isImported = formValues.isImported;
    }
}

import { IBaseModel } from '../../../model';
import { ICountryFormValues } from './countryFormValues';

export interface ICountry extends IBaseModel {
    code : string;
    name : string;
    regionId : number;
}

export class Country implements ICountry {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public name : string;
    public regionId : number;

    public constructor(formValues : ICountryFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.code = formValues.code;
        this.name = formValues.name;
        this.regionId = Number(formValues.region?.value) ?? 0;
        this.isActive = formValues.isActive;
    }
}

import { IPalletBaseType } from './palletBaseType';
import * as Yup from 'yup';

export interface IPalletBaseTypeFormValues {
    id : number;
    guid : string;
    code : string;
    name : string;
    defaultWeight : number;
    isActive : boolean;
}

export class PalletBaseTypeFormValues implements IPalletBaseTypeFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public name : string;
    public defaultWeight : number;
    public isActive : boolean;

    public constructor(palletBaseType ?: IPalletBaseType) {
        this.initializeDefaultValues();
        if (palletBaseType) {
            this.id = palletBaseType.id;
            this.guid = palletBaseType.guid;
            this.code = palletBaseType.code;
            this.name = palletBaseType.name;
            this.defaultWeight = palletBaseType.defaultWeight;
            this.isActive = palletBaseType.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.name = '';
        this.defaultWeight = 0.0;
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        defaultWeight: Yup.number().min(0).required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

import { DATEPICKER_FORMAT_DEFAULT } from '../../../../appConstants';
import { IBaseModel } from '../../../model';
import { IOrchardFormValues } from './orchardFormValues';

export interface IOrchard extends IBaseModel {
    code : string;
    name : string;
    landName : string;
    subBlock : string;
    complianceCode ?: string;
    farmId : number;
    commodityId : number;
    defaultVarietyId : number;
    departmentId ?: number;
    projectId ?: number;
    plantedHectares ?: number;
    isImported ?: boolean;
    endedOn ?: string;
}

export class Orchard implements IOrchard {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public name : string;
    public landName : string;
    public subBlock : string;
    public complianceCode ?: string;
    public commodityId : number;
    public defaultVarietyId : number;
    public departmentId ?: number;
    public projectId ?: number;
    public farmId : number;
    public plantedHectares ?: number;
    public isImported ?: boolean;
    public endedOn ?: string;

    public constructor(formValues : IOrchardFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.code = formValues.code;
        this.name = formValues.name;
        this.commodityId = Number(formValues.commodity?.value) ?? 0;
        this.defaultVarietyId = Number(formValues.defaultVariety?.value) ?? 0;
        this.departmentId = Number(formValues.department?.value) ?? 0;
        this.farmId = Number(formValues.farm?.value) ?? 0;
        this.landName = formValues.landName;
        this.subBlock = formValues.subBlock;
        this.complianceCode = formValues.complianceCode;
        this.plantedHectares = formValues.plantedHectares;
        this.isActive = formValues.isActive;
        this.projectId = Number(formValues.project?.value);
        this.isImported = formValues.isImported;
        this.endedOn = formValues.endedOn != null ? formValues.endedOn?.format(DATEPICKER_FORMAT_DEFAULT) : '';
    }
}

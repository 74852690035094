import * as React from 'react';
import CustomTable from '../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetMaterialTypes } from '../../../store/masterData/Actions';
import { formatDateTime, upsertArrayElement } from '../../../services/appFunctionsService';
import { IMaterialType, MaterialType } from '../../../@types/model/masterData/materialType/materialType';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import MaterialTypeHttpService from '../../../services/http/masterData/materialTypeHttpService';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { MaterialTypeFormValues, IMaterialTypeFormValues } from '../../../@types/model/masterData/materialType/materialTypeFormValues';
import MaterialTypeForm from './form/MaterialTypeForm';
import { FormikActions, Formik } from 'formik';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { setMaterialTypesMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IMaterialTypeScreenProps extends RouteComponentProps {
    dataSetMaterialTypes : DispatchCall<Array<IMaterialType>>;
    materialTypeData : Array<IMaterialType>;
    auth : IAuthState;
}

interface IMaterialTypeScreenState {
    rows : Array<IMaterialType>;
    isLoading : boolean;
    selectedMaterialType ?: IMaterialType;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingMaterialType ?: IMaterialType;
}

class MaterialTypeScreen extends React.Component<IMaterialTypeScreenProps, IMaterialTypeScreenState> {
    constructor(props : IMaterialTypeScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedMaterialType: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await MaterialTypeHttpService.getMaterialTypeData();

                this.props.dataSetMaterialTypes(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading material types.');
                this.setLoading(false);
            }
        }
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedMaterialType: undefined,
        });
    };

    public onSubmit = async (values : IMaterialTypeFormValues) => {
        this.setLoading(true);

        try {
            const res = await MaterialTypeHttpService.addOrUpdateMaterialType(new MaterialType(values));

            const newMaterialTypeList = upsertArrayElement(this.props.materialTypeData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetMaterialTypes(newMaterialTypeList);
            await setMaterialTypesMasterDataIndexedDB(newMaterialTypeList);

            if (this.state.selectedMaterialType) {
                generalShowSuccessSnackbar('Material Type updated successfully.');
            } else {
                generalShowSuccessSnackbar('Material Type added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating Material Type data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (materialType : IMaterialType) => {
        this.setState({ isDeletePopupOpen: true, deletingMaterialType: materialType });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingMaterialType: undefined });
    };

    private removeMaterialType = async () => {
        const newMaterialType = this.state.deletingMaterialType;
        if (newMaterialType) {
            newMaterialType.isActive = false;
            this.setLoading(true);

            try {
                const res = await MaterialTypeHttpService.deleteMaterialType(newMaterialType.id);

                const newMaterialTypeList = upsertArrayElement(this.props.materialTypeData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetMaterialTypes(newMaterialTypeList);
                await setMaterialTypesMasterDataIndexedDB(newMaterialTypeList);

                generalShowSuccessSnackbar('Material Type updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting Material Type.');
                newMaterialType.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    public onReset = async (formValues : IMaterialTypeFormValues, formikActions : FormikActions<IMaterialTypeFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    public editMaterialType = (materialType : IMaterialType) => {
        this.setState({
            isDialogOpen: true,
            selectedMaterialType: materialType,
        });
        this.openDialog();
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public getSelectedMaterialType = (props : IMaterialTypeScreenProps, state : IMaterialTypeScreenState) => state.selectedMaterialType;

    public getInitialFormValues = createSelector(
        [this.getSelectedMaterialType],
        (materialType : IMaterialType) => {
            return new MaterialTypeFormValues(materialType);
        },
    );

    private getRights = (props : IMaterialTypeScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IMaterialTypeScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.materialTypeData ? this.props.materialTypeData : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Material Type'
                    isEdit={!!this.state.selectedMaterialType}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={MaterialTypeFormValues.formSchema}
                        component={MaterialTypeForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IMaterialType>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editMaterialType}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(materialType : IMaterialType) => materialType.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Pack Type?', field: 'isPackType', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IMaterialType) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Material Type'}
                    open={this.state.isDeletePopupOpen}
                    message={'Are you sure you want to delete this material type?'}
                    onOkClicked={this.removeMaterialType}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        materialTypeData: state.masterData.materialTypes,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetMaterialTypes }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MaterialTypeScreen);

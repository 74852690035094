import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IMasterDataSyncData } from '../../../@types/model/masterData/sync/MasterDataSyncData';

export default class MasterDataSyncHttpService {
    public static getAllMasterData(lastUpdateUnix ?: number) : AxiosPromise<IMasterDataSyncData> {
        return axios.get(`${PACKMAN_API_URL}Data/Web`, {
            params: {
                lastUpdateUnix,
            },
        });
    }
}

import * as React from 'react';
import { FormControl, InputLabel, FormHelperText, PropTypes } from '@mui/material';
import Input from '@mui/material/Input';
import { asField, FieldContext, FieldProps, FormValues } from 'informed';
import { IFormValue } from '../../@types/other';

export interface ICustomTextInputProps extends FieldProps<IFormValue, FormValues> {
    onChange ?: (event : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onBlur ?: (event : React.FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>) => void;
    initialValue : any;
    id ?: any;
    disabled ?: boolean;
    type ?: any;
    label ?: string;
    margin ?: PropTypes.Margin;
    className ?: string;
    field : string;
    forwardedRef ?: React.RefObject<HTMLInputElement>;
    style ?: React.CSSProperties;
    defaultValue ?: string;
    forceUppercase ?: boolean;
}

class CustomTextInput extends React.Component<ICustomTextInputProps & FieldContext<IFormValue>> {

    constructor(props : ICustomTextInputProps & FieldContext<IFormValue>) {
        super(props);
    }

    public render() {
        const { onChange, onBlur,
            id, label, margin, className, type, required, fieldState, fieldApi, disabled, forceUppercase } = this.props;

        const { value, error } = fieldState;
        const { setValue, setTouched } = fieldApi;

        return (
            <>
                <FormControl margin={margin} className={className} required={required}>
                    {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
                    <Input error={
                        (!!required && !!!fieldApi.getValue()) ||
                        !!error
                    }
                    id={id}
                    type={type}
                    disabled={disabled}
                    value={!value && value !== 0 ? '' : value}
                    onChange={(e) => {

                        if (forceUppercase) {
                            setValue(e.target.value.toUpperCase());
                        } else {
                            setValue(e.target.value);
                        }
                        if (!!onChange) {
                            onChange(e);
                        }
                    }}
                    onBlur={(e) => {
                        setTouched(true);
                        if (!!onBlur) {
                            onBlur(e);
                        }
                    }}
                    ></Input>
                    {
                        !!this.props.children && this.props.children
                    }
                    {
                        !!fieldApi && !!error ? (
                            <FormHelperText error>{error}</FormHelperText>
                        ) : null
                    }

                </FormControl>
            </>
        );
    }
}

export default asField<ICustomTextInputProps>(CustomTextInput);

import { IBarcodeRange } from './barcodeRange';
import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { ISite } from '../site/site';
import { TestContext } from 'yup';

export interface IBarcodeRangeFormValues {
    id : number;
    guid : string;
    name : string;
    indicatorDigit ?: number;
    rangeStart : number;
    rangeEnd : number;
    isExport : boolean;
    latest ?: number;
    numRemaining ?: number;
    importCode ?: number;
    sites : Array<IOptionType>;
    isActive : boolean;
}
export class BarcodeRangeFormValues implements IBarcodeRangeFormValues {
    public id : number;
    public guid : string;
    public name : string;
    public indicatorDigit ?: number;
    public rangeStart : number;
    public rangeEnd : number;
    public isExport : boolean;
    public latest ?: number;
    public numRemaining ?: number;
    public importCode ?: number;
    public sites : Array<IOptionType>;
    public isActive : boolean;
    public parent : { rangeEnd : number };

    public constructor(
        barcodeRange : IBarcodeRange | undefined,
        sites : Array<ISite> | undefined,
    ) {
        this.initializeDefaultValues();
        if (barcodeRange) {
            this.id = barcodeRange.id;
            this.guid = barcodeRange.guid;
            this.name = barcodeRange.name;
            this.indicatorDigit = barcodeRange.indicatorDigit;
            this.rangeStart = barcodeRange.rangeStart;
            this.rangeEnd = barcodeRange.rangeEnd;
            this.isExport = barcodeRange.isExport;
            this.latest = barcodeRange.latest;
            this.numRemaining = barcodeRange.numRemaining;
            this.importCode = barcodeRange.importCode;

            this.sites = sites?.filter(x => barcodeRange.siteIds?.some(y => y === x.id))?.map(x => ({
                label: `${x.code} - ${x.description}`,
                value: x.id,
            })) ?? [];

            this.isActive = barcodeRange.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.name = '';
        this.importCode = undefined;
        this.rangeStart = 1;
        this.rangeEnd = 999999;
        this.isExport = false;
        this.sites = [];
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        importCode: Yup.number().when(['isExport'], {
            is: true,
            then: (schema : Yup.NumberSchema<number>) => schema.required('Required').test('len', 'The combination of range and import code cannot be more than 16 characters long', (value : number | undefined, context : TestContext) => {
                return (value?.toString()?.length ?? 0) <= (16 - (context.parent.rangeEnd?.toString()?.length ?? 0));
            }),
            otherwise: (schema : Yup.NumberSchema<number | null | undefined>) => schema.oneOf([null], 'Value must be null if not export'),
        }),
        indicatorDigit: Yup.number().when(['isExport'], {
            is: true,
            then: (schema : Yup.NumberSchema<number>) => schema.required('Required').oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9], 'Invalid value or value too long'),
            otherwise: (schema : Yup.NumberSchema<number | null | undefined>) => schema.nullable().oneOf([null, undefined], 'Value must be null if not export'),
        }),
        sites: Yup.array().when(['isExport'], {
            is: true,
            then: schema => schema.min(1, 'Required'),
        }),
        rangeStart: Yup.number().lessThan(Yup.ref('rangeEnd'), 'Must be less than range end').required('Required').positive('Value must be greater than 0'),
        rangeEnd: Yup.number().when(['isExport'], {
            is: true,
            then: (schema : Yup.NumberSchema<number>) => schema.moreThan(Yup.ref('rangeStart'), 'Must be greater than range start').required('Required').test('len', 'The combination of range and import code cannot be more than 16 characters long', (value : number | undefined, context : TestContext) => {
                return (value?.toString()?.length ?? 0) <= (16 - (context.parent.importCode?.toString()?.length ?? 0));
            }),
            otherwise: (schema : Yup.NumberSchema<number>) => schema.moreThan(Yup.ref('rangeStart'), 'Must be greater than range start').required('Required'),
        }),
        isActive: Yup.boolean().required('Required'),
    });
}

import * as React from 'react';
import CustomTable from '../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { DispatchCall, IAuthState, IRootState, RootAction } from '../../../@types/redux';
import { dataSetPackLines, dataSetSites } from '../../../store/masterData/Actions';
import { bindActionCreators, Dispatch } from 'redux';
import { formatDateTime, upsertArrayElement } from '../../../services/appFunctionsService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import { connect } from 'react-redux';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikActions } from 'formik';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { IPackLine, PackLine } from '../../../@types/model/masterData/packLine/packLine';
import { ISite } from '../../../@types/model/masterData/site/site';
import PackLineHttpService from '../../../services/http/masterData/packLineHttpService';
import SiteHttpService from '../../../services/http/masterData/siteHttpService';
import { IPackLineFormValues, PackLineFormValues } from '../../../@types/model/masterData/packLine/packLineFormValues';
import PackLineForm from './form/PackLineForm';
import { setPackLineMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IPackLineScreenProps extends RouteComponentProps {
    dataSetPackLines : DispatchCall<Array<IPackLine>>;
    dataSetSites : DispatchCall<Array<ISite>>;
    packLines : Array<IPackLine>;
    sites : Array<ISite>;
    auth : IAuthState;
}

interface IPackLineScreenState {
    rows : Array<IPackLine>;
    isLoading : boolean;
    selectedPackLine ?: IPackLine;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingPackLine ?: IPackLine;
}

class PackLineScreen extends React.Component<IPackLineScreenProps, IPackLineScreenState> {
    constructor(props : IPackLineScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedPackLine: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await PackLineHttpService.getPackLineData();

                const packLineData = res.data;
                if (packLineData) {
                    const res2 = await SiteHttpService.getSiteData();

                    const sites = res2.data;
                    this.props.dataSetSites(sites);
                    this.props.dataSetPackLines(packLineData);
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading pack lines.');
            } finally {
                this.setLoading(false);
            }
        }
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedPackLine: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    public editPackLine = (selectedPackLine : IPackLine) => {
        this.setState({
            isDialogOpen: true,
            selectedPackLine,
        });
        this.openDialog();
    };

    public onSubmit = async (values : IPackLineFormValues) => {
        this.setLoading(true);

        try {
            const res = await PackLineHttpService.addOrUpdatePackLine(new PackLine(values));

            const newPackLineList = upsertArrayElement(this.props.packLines, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetPackLines(newPackLineList);
            await setPackLineMasterDataIndexedDB(newPackLineList);

            if (this.state.selectedPackLine) {
                generalShowSuccessSnackbar('Pack line updated successfully.');
            } else {
                generalShowSuccessSnackbar('Pack line added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating pack line data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (packLine : IPackLine) => {
        this.setState({ isDeletePopupOpen: true, deletingPackLine: packLine });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingPackLine: undefined });
    };

    private removePackLine = async () => {
        const newPackLine = this.state.deletingPackLine;
        if (newPackLine) {
            newPackLine.isActive = false;
            this.setLoading(true);

            try {
                const res = await PackLineHttpService.deletePackLine(newPackLine.id);

                const newPackLineList = upsertArrayElement(this.props.packLines, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetPackLines(newPackLineList);
                await setPackLineMasterDataIndexedDB(newPackLineList);

                generalShowSuccessSnackbar('Pack line updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting pack line.');
                newPackLine.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public onReset = async (formValues : IPackLineFormValues, formikActions : FormikActions<IPackLineFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    public getSelectedPackLine = (props : IPackLineScreenProps, state : IPackLineScreenState) => state.selectedPackLine;

    public getInitialFormValues = createSelector(
        [this.getSelectedPackLine],
        (packLine : IPackLine) => {
            return new PackLineFormValues(packLine, this.props.sites);
        },
    );

    private getSiteShortDescription = (siteId : number) => {
        const site = this.props.sites?.find(x => x.id === siteId);
        return site?.shortDescription ?? '';
    };

    private getRights = (props : IPackLineScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IPackLineScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.packLines ? this.props.packLines : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Pack Line'
                    maxWidth={'xs'}
                    fullWidth
                    isEdit={!!this.state.selectedPackLine}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={PackLineFormValues.formSchema}
                        component={PackLineForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IPackLine>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editPackLine}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(packLine : IPackLine) => packLine.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Site', field: 'siteId', formatFunction: this.getSiteShortDescription, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IPackLine) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Pack Line'}
                    open={this.state.isDeletePopupOpen}
                    isLoading={this.state.isLoading}
                    message={'Are you sure you want to delete this pack line?'}
                    onOkClicked={this.removePackLine}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        packLines: state.masterData.packLines,
        sites: state.masterData.sites,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetPackLines, dataSetSites }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PackLineScreen);

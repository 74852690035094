import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { CustomChangeEvent } from '../../../@types/helper';
import { IUserListView } from '../../../@types/model/user/userListView';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import PillButton from '../../../components/input/PillButton';
import { addArrayElement, removeArrayElement } from '../../../services/appFunctionsService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import FileSelector from '../../../components/input/FileSelector';
import * as userService from '../../../services/right/userService';
import PapaParse from 'papaparse';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { IDomain } from '../../../@types/model/user/domain';
import { CSVLink } from 'react-csv';
import CustomToggleButton from '../../../components/input/CustomToggleButton';

interface IUserAddComponentProps {
    userList : Array<IUserListView>;
    onClose : () => void;
    isLoading : boolean;
    setIsLoading : (loading : boolean) => void;
    setNewUserList : (userList : Array<IUserListView>) => void;
    domains : Array<IDomain>;
}

interface IUserAddComponentState {
    isLoading : boolean;
    newUserName : string;
    newUserEmail : string;
    newUserEmployeeNr : string;
    newUserPassword : string;
    newUserRequiresAuth : boolean;

    newUsersFromCSV : Array<{ name : string; email : string; password : string; employeeNr : string; requiresAuth : boolean }>;

    isUserCVSImportDialogOpen : boolean;
    selectedFiles : Array<File>;

    onYesClick : () => void;
    onNoClick : () => void;
    message : string;
    showPrompt : boolean;

    isEmailNotMatchingDialogOpen : boolean;
    isIncorrectEmailFormatDialogOpen : boolean;

    emailsNotMatchingDomains : Array<string>;
    emailsWithIncorrectFormat : Array<string>;
}

export default class UserAddComponent extends React.Component<IUserAddComponentProps, IUserAddComponentState> {

    constructor(props : IUserAddComponentProps) {
        super(props);

        this.state = {
            isLoading: true,

            isUserCVSImportDialogOpen: false,
            selectedFiles: [],
            newUsersFromCSV: [],
            newUserName: '',
            newUserEmail: '',
            newUserEmployeeNr: '',
            newUserPassword: '',
            newUserRequiresAuth: true,

            onYesClick: () => null,
            onNoClick: () => null,
            message: '',
            showPrompt: false,

            isEmailNotMatchingDialogOpen: false,
            isIncorrectEmailFormatDialogOpen: false,
            emailsNotMatchingDomains: [],
            emailsWithIncorrectFormat: [],
        };
    }

    private closeAddUserDialog = () => {
        this.setState({ newUserName: '', newUserEmail: '', newUserPassword: '', newUserEmployeeNr: '', newUserRequiresAuth: true, newUsersFromCSV: [], selectedFiles: [] });
        this.props.onClose();
    };

    private onNameChange = (event : CustomChangeEvent) => {
        this.setState({ newUserName: event.target.value });
    };

    private onEmailChange = (event : CustomChangeEvent) => {
        this.setState({ newUserEmail: event.target.value });
    };

    private onEmployeeNrChange = (event : CustomChangeEvent) => {
        this.setState({ newUserEmployeeNr: event.target.value.toUpperCase() });
    };

    private onPasswordChange = (event : CustomChangeEvent) => {
        this.setState({ newUserPassword: event.target.value });
    };

    private onNewUserSubmitClick = async () => {
        const userName = this.state.newUserName;
        const userEmail = this.state.newUserEmail;
        const userPassword = this.state.newUserPassword;
        const userEmployeeNr = this.state.newUserEmployeeNr;
        const userRequiresAuth = this.state.newUserRequiresAuth;
        let usersFromCSV = this.state.newUsersFromCSV;

        if (userName && !userEmail && !userEmployeeNr) {
            generalShowErrorSnackbar('Either email or employee number is required. Both fields are empty');
            return;
        }

        if (!userName && (userEmail || userEmployeeNr) && usersFromCSV?.length < 1) {
            generalShowErrorSnackbar('Name is required!');
            return;
        }

        if (userName && (userEmail || userEmployeeNr) && usersFromCSV?.length > 0) {
            this.showConfirmationPrompt(this.onAddNewUsersYesClick, this.onAddNewUsersNoClick, 'Do you want to add the user info entered to the users list imported from the CSV file?');
        } else if (userName && (userEmail || userEmployeeNr) && usersFromCSV?.length < 1) {
            if (userEmail) {
                if (this.validateEmail(userEmail)) {
                    // if (!this.props.domains.some(x => x.domainName === userEmail.split('@')[1])) { /* removed domain check from here for now */
                    //     if (userEmployeeNr && this.props.userList.find(x => x.isActive && x.employeeNr === userEmployeeNr)) {
                    //         generalShowErrorSnackbar('Employee number already exists!');
                    //     }
                    //     generalShowErrorSnackbar('Email does not match any of our domains.');
                    //     return;
                    // } else {
                    if (this.props.userList.find(x => x.isActive && x.email === userEmail)) {
                        generalShowErrorSnackbar('Email already exists!');
                        return;
                    }
                    // }
                } else {
                    if (userEmployeeNr && this.props.userList.find(x => x.isActive && x.employeeNr === userEmployeeNr)) {
                        generalShowErrorSnackbar('Employee number already exists!');
                    }
                    generalShowErrorSnackbar('Invalid Email Format!');
                    return;
                }
            }
            if (userEmployeeNr) {
                if (this.props.userList.find(x => x.isActive && x.employeeNr === userEmployeeNr)) {
                    generalShowErrorSnackbar('Employee number already exists!');
                    return;
                }
            }

            this.props.setIsLoading(true);
            const data = {
                name: userName,
                email: userEmail,
                password: userPassword,
                employeeNr: userEmployeeNr,
                requiresAuth: userRequiresAuth,
            };

            usersFromCSV = addArrayElement(usersFromCSV, data);

            try {
                await userService.addUsers(usersFromCSV).then((result) => {
                    if (!!result && result?.length > 0) {
                        this.props.setNewUserList(result);
                        this.props.setIsLoading(false);
                        this.closeAddUserDialog();
                        generalShowSuccessSnackbar('Users Added successfully!');
                    } else {
                        generalShowErrorSnackbar('No users were added!');
                        this.props.setIsLoading(false);
                    }
                });
            } catch(e) {
                generalShowErrorSnackbar('An error occurred adding users!');
            }

        } else if (!userName && !userEmail && !userEmployeeNr && usersFromCSV?.length > 0) {
            this.props.setIsLoading(true);
            try {
                await userService.addUsers(usersFromCSV).then((result) => {
                    if (!!result && result?.length > 0) {
                        this.props.setNewUserList(result);
                        this.props.setIsLoading(false);
                        this.closeAddUserDialog();
                        generalShowSuccessSnackbar('Users Added successfully!');
                    } else {
                        generalShowErrorSnackbar('No users were added!');
                        this.props.setIsLoading(false);
                    }
                });
            } catch(e) {
                generalShowErrorSnackbar('An error occurred adding users!');
            }
        }
    };

    private openUserCSVImportDialog = () => {
        this.setState({ isUserCVSImportDialogOpen: true });
    };

    private closeUserCSVImportDialog = () => {
        this.setState({ isUserCVSImportDialogOpen: false, selectedFiles: [] });
    };

    private showConfirmationPrompt = (onYesClick : () => void, onNoClick : () => void, message : string) => {
        this.setState({ onYesClick, onNoClick, message }, () => {
            this.setState({ showPrompt: true });
        });
    };

    private closeConfirmationPrompt = () => {
        this.setState({ onYesClick: () => null, onNoClick: () => null, message: '', showPrompt: false });
    };

    private validateEmail = (email : string) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    private onUserCSVImport = () => {
        // const domains = this.props.domains;
        const files = this.state.selectedFiles;
        let results : Array<{ name : string; email : string; password : string; employeeNr : string; requiresAuth : boolean }> = [];
        let emailsNotMatchingDomains : Array<{ name : string; email : string; password : string; employeeNr : string; requiresAuth : boolean }> = [];
        let emailsWithIncorrectFormat : Array<{ name : string; email : string; password : string; employeeNr : string; requiresAuth : boolean }> = [];

        let usersWithNoName : Array<{ name : string; email : string; password : string; employeeNr : string; requiresAuth : boolean }> = [];
        let usersWithNoEmployeeNrOrEmail : Array<{ name : string; email : string; password : string; employeeNr : string; requiresAuth : boolean }> = [];
        let duplicateUsers : Array<{ name : string; email : string; password : string; employeeNr : string; requiresAuth : boolean }> = [];
        let existingUsers : Array<{ name : string; email : string; password : string; employeeNr : string; requiresAuth : boolean }> = [];
        if (files?.length > 0) {
            files.forEach((file) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const data = reader.result;
                    const csvData = PapaParse.parse(
                        // @ts-ignore
                        data,
                    );
                    if (typeof data === 'string') {
                        const nameIndex = csvData.data[0].findIndex((x : any) => x.toLowerCase() === 'Name'.toLowerCase());
                        const emailIndex = csvData.data[0].findIndex((x : any) => x.toLowerCase() === 'Email'.toLowerCase());
                        const passwordIndex = csvData.data[0].findIndex((x : any) => x.toLowerCase() === 'Password'.toLowerCase());
                        const employeeNrIndex = csvData.data[0].findIndex((x : any) => x.toLowerCase() === 'EmployeeNumber'.toLowerCase());
                        const requiresAuthIndex = csvData.data[0].findIndex((x : any) => x.toLowerCase() === 'RequiresAuth'.toLowerCase());

                        // TODO: this needs to be made more readable
                        if (nameIndex !== -1 && emailIndex !== -1 && passwordIndex !== -1 && employeeNrIndex !== -1 && requiresAuthIndex !== -1) {
                            csvData.data.forEach((x : any) => {
                                if (x[nameIndex] && (x[emailIndex] || x[employeeNrIndex])) {
                                    if (x[nameIndex].toLowerCase() !== 'Name'.toLowerCase() && x[emailIndex].toLowerCase() !== 'Email'.toLowerCase() && x[passwordIndex].toLowerCase() !== 'Password'.toLowerCase() && x[employeeNrIndex].toLowerCase() !== 'EmployeeNumber'.toLowerCase() && x[requiresAuthIndex] !== 'RequiresAuth'.toLowerCase()) {
                                        if (results.every(y => y.employeeNr !== x[employeeNrIndex]) && results.every(y => y.email !== x[emailIndex])) {
                                            if (this.props.userList.filter(y => y.isActive).every(y => y.employeeNr !== x[employeeNrIndex]) && this.props.userList.filter(y => y.isActive).every(y => y.email !== x[emailIndex])) {
                                                if (x[emailIndex]) {
                                                    if (this.validateEmail(x[emailIndex])) {
                                                        // if (domains.some(y => y.domainName === x[emailIndex].split('@')[1])) { /* Removed domain check from here for now */
                                                        const userData = {
                                                            name: x[nameIndex],
                                                            email: x[emailIndex],
                                                            password: x[passwordIndex],
                                                            employeeNr: x[employeeNrIndex],
                                                            requiresAuth: x[requiresAuthIndex],
                                                        };

                                                        results = addArrayElement(results, userData);
                                                        // } else {
                                                        //     if (emailsNotMatchingDomains.every(y => y.email !== x[emailIndex])) {
                                                        //         const userData = {
                                                        //             name: x[nameIndex],
                                                        //             email: x[emailIndex],
                                                        //             password: x[passwordIndex],
                                                        //             employeeNr: x[employeeNrIndex],
                                                        //             requiresAuth: x[requiresAuthIndex],
                                                        //         };
                                                        //         emailsNotMatchingDomains = addArrayElement(emailsNotMatchingDomains, userData);
                                                        //     }
                                                        // }
                                                    } else {
                                                        if (emailsWithIncorrectFormat.every(y => y.email !== x)) {
                                                            const userData = {
                                                                name: x[nameIndex],
                                                                email: x[emailIndex],
                                                                password: x[passwordIndex],
                                                                employeeNr: x[employeeNrIndex],
                                                                requiresAuth: x[requiresAuthIndex],
                                                            };
                                                            emailsWithIncorrectFormat = addArrayElement(emailsWithIncorrectFormat, userData);
                                                        }
                                                    }
                                                } else {
                                                    const userData = {
                                                        name: x[nameIndex],
                                                        email: x[emailIndex],
                                                        password: x[passwordIndex],
                                                        employeeNr: x[employeeNrIndex],
                                                        requiresAuth: x[requiresAuthIndex],
                                                    };

                                                    results = addArrayElement(results, userData);
                                                }
                                            } else {
                                                const userData = {
                                                    name: x[nameIndex],
                                                    email: x[emailIndex],
                                                    password: x[passwordIndex],
                                                    employeeNr: x[employeeNrIndex],
                                                    requiresAuth: x[requiresAuthIndex],
                                                };
                                                existingUsers = addArrayElement(existingUsers, userData);
                                            }
                                        } else {
                                            const userData = {
                                                name: x[nameIndex],
                                                email: x[emailIndex],
                                                password: x[passwordIndex],
                                                employeeNr: x[employeeNrIndex],
                                                requiresAuth: x[requiresAuthIndex],
                                            };
                                            duplicateUsers = addArrayElement(duplicateUsers, userData);
                                        }
                                    }
                                } else if (x[nameIndex] || x[employeeNrIndex] || x[emailIndex] || x[passwordIndex]) {
                                    const userData = {
                                        name: x[nameIndex],
                                        email: x[emailIndex],
                                        password: x[passwordIndex],
                                        employeeNr: x[employeeNrIndex],
                                        requiresAuth: x[requiresAuthIndex],
                                    };
                                    if (!x[nameIndex] && !x[employeeNrIndex] && !x[emailIndex]) {
                                        usersWithNoName = addArrayElement(usersWithNoName, userData);
                                        usersWithNoEmployeeNrOrEmail = addArrayElement(usersWithNoEmployeeNrOrEmail, userData);
                                    } else if (x[nameIndex] && !x[employeeNrIndex] && !x[emailIndex]) {
                                        usersWithNoEmployeeNrOrEmail = addArrayElement(usersWithNoEmployeeNrOrEmail, userData);
                                    } else if (!x[nameIndex] && (x[employeeNrIndex] || x[emailIndex])) {
                                        usersWithNoName = addArrayElement(usersWithNoName, userData);
                                    }
                                }
                            });
                            if (emailsNotMatchingDomains?.length < 1 && emailsWithIncorrectFormat?.length < 1 && usersWithNoName?.length < 1 && usersWithNoEmployeeNrOrEmail?.length < 1 && duplicateUsers?.length < 1 && existingUsers?.length < 1) {
                                this.setState({ newUsersFromCSV: results, isUserCVSImportDialogOpen: false });
                            } else {
                                if (emailsNotMatchingDomains?.length > 0) {
                                    generalShowErrorSnackbar(`There are ${emailsNotMatchingDomains?.length} ${emailsNotMatchingDomains?.length < 2 ? ' user' : ' users'} that you are trying to import are not correct. "${emailsNotMatchingDomains[0].email}" doesn't match our domains. Please correct before importing again.`);
                                }
                                if (emailsWithIncorrectFormat?.length > 0) {
                                    generalShowErrorSnackbar(`There are ${emailsWithIncorrectFormat?.length} ${emailsWithIncorrectFormat?.length < 2 ? ' user' : ' users'} that you are trying to import are not correct. "${emailsWithIncorrectFormat[0].email}" format is incorrect. Please correct before importing again.`);
                                }
                                if (usersWithNoName?.length > 0) {
                                    generalShowErrorSnackbar(`There are ${usersWithNoName?.length} ${usersWithNoName?.length < 2 ? ' user' : ' users'} that you are trying to import are not correct. "${!usersWithNoName[0].employeeNr ? usersWithNoName[0].email : usersWithNoName[0].employeeNr}" user does not have a name. Please correct before importing again.`);
                                }
                                if (usersWithNoEmployeeNrOrEmail?.length > 0) {
                                    generalShowErrorSnackbar(`There are ${usersWithNoEmployeeNrOrEmail?.length} ${usersWithNoEmployeeNrOrEmail?.length < 2 ? ' user' : ' users'} that you are trying to import are not correct. "${usersWithNoEmployeeNrOrEmail.map(x => x.name)[0]}" does not have an email or employee number. Please correct before importing again.`);
                                }
                                if (duplicateUsers?.length > 0) {
                                    generalShowErrorSnackbar(`There are ${duplicateUsers?.length} ${duplicateUsers?.length < 2 ? ' user' : ' users'} that you are trying to import are not correct. "${!duplicateUsers[0].employeeNr ? duplicateUsers[0].email : duplicateUsers[0].employeeNr}" user apprears more than once (duplicate). Please correct before importing the file again`);
                                }
                                if (existingUsers?.length > 0) {
                                    generalShowErrorSnackbar(`There are ${existingUsers?.length} ${existingUsers?.length < 2 ? 'user' : 'users'} that you are trying to import are not correct. "${!existingUsers[0].employeeNr ? existingUsers[0].email : existingUsers[0].employeeNr}" user already exists Please remove them before importing the file again`);
                                }
                                this.setState({ selectedFiles: [] });
                            }
                        } else {
                            generalShowErrorSnackbar('Headers does not match required headers!');
                        }
                    }
                };
                reader.readAsText(file);
            });
        }
    };

    private onAddNewUsersYesClick = async () => {
        const userName = this.state.newUserName;
        const userEmail = this.state.newUserEmail;
        const userPassword = this.state.newUserPassword;
        const userEmployeeNr = this.state.newUserEmployeeNr;
        const userRequiresAuth = this.state.newUserRequiresAuth;
        let usersFromCSV : Array<{ name : string; email : string; password : string; employeeNr : string; requiresAuth : boolean }> = [];

        if (userName && (userEmail || userEmployeeNr)) {

            if (userEmail) {
                const emailExists = this.props.userList.find(x => x.isActive && x.email === userEmail);
                if (emailExists) {
                    generalShowErrorSnackbar('Manually entered email already exists!');
                    return;
                } else {
                    if (this.validateEmail(userEmail)) {
                        if (!this.props.domains.some(x => x.domainName === userEmail.split('@')[1])) {
                            if (userEmployeeNr && this.props.userList.find(x => x.isActive && x.employeeNr === userEmployeeNr)) {
                                generalShowErrorSnackbar('Manually entered employee number already exists!');
                            }
                            generalShowErrorSnackbar('Manually entered email does not match any of our domains.');
                            return;
                        }
                    } else {
                        if (userEmployeeNr && this.props.userList.find(x => x.isActive && x.employeeNr === userEmployeeNr)) {
                            generalShowErrorSnackbar('Manually entered employee number already exists!');
                        }
                        generalShowErrorSnackbar('Manually entered email format is invalid!');
                        return;
                    }
                }
            }
            if (userEmployeeNr) {
                if (this.props.userList.find(x => x.isActive && x.employeeNr === userEmployeeNr)) {
                    generalShowErrorSnackbar('Manually entered employee number already exists!');
                    return;
                }
            }

            this.props.setIsLoading(true);
            const data = {
                name: userName,
                email: userEmail,
                password: userPassword,
                employeeNr: userEmployeeNr,
                requiresAuth: userRequiresAuth,
            };

            const importedUsers = this.state.newUsersFromCSV.every(x => x.name !== userName && x.email !== userEmail && x.password !== userPassword && x.employeeNr !== userEmployeeNr);

            if (importedUsers) {
                usersFromCSV = addArrayElement(this.state.newUsersFromCSV, data);
            }

            await userService.addUsers(usersFromCSV).then((result) => {
                if (!!result && result?.length > 0) {
                    this.props.setNewUserList(result);
                    this.props.setIsLoading(false);
                    this.closeConfirmationPrompt();
                    this.closeAddUserDialog();
                    generalShowSuccessSnackbar('Users Added successfully!');
                } else {
                    this.props.setIsLoading(false);
                    this.closeConfirmationPrompt();
                    this.closeAddUserDialog();
                }
            });
        }
    };

    private onAddNewUsersNoClick = async () => {
        this.setState({ newUserName: '', newUserEmail: '', newUserPassword: '', newUserEmployeeNr: '', newUserRequiresAuth: true });
        this.closeConfirmationPrompt();
    };

    private handleFileInputChange = (files : Array<File>) => {
        files.forEach(x => this.setState({ selectedFiles: addArrayElement(this.state.selectedFiles, x, 'end') }));
    };

    private onFileRemove = (file : File) => {
        const index = this.state.selectedFiles?.findIndex(x => x.name === file.name);
        if (index > -1) {
            this.setState({ selectedFiles: removeArrayElement(this.state.selectedFiles, index) });
        }
    };

    private exportUserImportTemplate = () => {
        const returnData : Array<Array<string>> = [];
        returnData.push([
            'Name',
            'Email',
            'Password',
            'EmployeeNumber',
            'RequiresAuth',
        ]);
        return returnData;
    };

    public render() {
        return (
            <div className={'fdc hfill p20'}>
                <div className={'fdr flx1 aic mb10'}>
                    <TextField
                        className={'flx1'}
                        label={'Name'}
                        name={'userName'}
                        value={this.state.newUserName}
                        onChange={this.onNameChange}
                    />
                    <div className={'w10'}/>
                    <TextField
                        className={'flx1'}
                        label={'Email'}
                        name={'userEmail'}
                        value={this.state.newUserEmail}
                        onChange={this.onEmailChange}
                    />
                </div>
                <div className={'fdr flx1 aic mb10'}>
                    <TextField
                        className={'flx1'}
                        label={'Employee Number'}
                        name={'userEmployeeNr'}
                        value={this.state.newUserEmployeeNr}
                        onChange={this.onEmployeeNrChange}
                    />
                    <div className={'w10'}/>
                    <TextField
                        className={'flx1'}
                        label={'Password'}
                        name={'password'}
                        type={'password'}
                        value={this.state.newUserPassword}
                        onChange={this.onPasswordChange}
                    />
                </div>
                <div className={'fdr flx1 aic mb10'}>
                    <div className={'aic p5 mb10 pr20'}>
                        <CustomToggleButton
                            field={'requiresAuth'}
                            label={'Needs Auth'}
                            initialValue={this.state.newUserRequiresAuth}
                            onChange={(event : React.FormEvent<HTMLButtonElement | HTMLInputElement | HTMLAnchorElement>, selected ?: boolean) => this.setState({ newUserRequiresAuth: selected ?? false })}
                            className={'w170'}/>
                    </div>
                    <div className='flx1'></div>
                </div>
                <div className={'fdr jcsb mt10'}>
                    <div className={'fdr aic'}>
                        <PillButton
                            text={'Import CSV'}
                            className={'pl30 pr30 h35'}
                            onClick={this.openUserCSVImportDialog}
                            disabled={this.props.isLoading}
                            color={'secondary'}
                            size={'small'}
                        ></PillButton>
                        <div className={'w10'}/>
                        <Typography>{`No. valid users imported: ${this.state.newUsersFromCSV?.length}`}</Typography>
                    </div>
                    <div className={'fdr'}>
                        <Button
                            className={'fwb mr5 h35'}
                            variant='text'
                            disabled={this.props.isLoading}
                            onClick={this.closeAddUserDialog}>
                            Cancel
                        </Button>
                        <div className={'w10'}/>
                        <PillButton
                            text={'Submit'}
                            className={'pl30 pr30 h35'}
                            onClick={this.onNewUserSubmitClick}
                            disabled={this.props.isLoading}
                            color={'secondary'}
                            size={'small'}
                        ></PillButton>
                    </div>
                </div>
                {/* User CSV Import Dialog */}
                {this.state.isUserCVSImportDialogOpen &&
                    <PackmanDialog
                        title={'Import CSV'}
                        isInfo
                        isLoading={this.props.isLoading}
                        isOpen={this.state.isUserCVSImportDialogOpen}
                        onClose={this.closeUserCSVImportDialog}>
                        <Screen isPadded={false} isScrollable={false}>
                            <div style={{ height: 550 }} className={'w500 aic fdc p20'}>
                                <Typography>Required Headers: Name, Email, Password, EmployeeNumber, RequiresAuth</Typography>
                                <div style={{ height: 400 }} className={'wfill'}>
                                    <FileSelector
                                        accept={'.csv, text/csv'}
                                        files={this.state.selectedFiles ? this.state.selectedFiles : []}
                                        disabled={this.props.isLoading}
                                        onFileSelected={this.handleFileInputChange}
                                        onFileRemoved={this.onFileRemove}
                                    />
                                </div>
                                <div className={'flx1'}/>
                                <div className={'fdr wfill pt10 pb10'}>
                                    <CSVLink data={this.exportUserImportTemplate()} filename={'Employee_Import_Template.csv'} style={{ textDecoration: 'unset' }}>
                                        <div>
                                            <PillButton
                                                className={'ml15 pl20 pr20 h35'}
                                                text={'Download Template'}
                                                color={'secondary'}
                                                onClick={() => null}
                                            />
                                        </div>
                                    </CSVLink>
                                    <div className={'flx1'}/>
                                    <Button
                                        className={'fwb h35'}
                                        variant='text'
                                        onClick={this.closeUserCSVImportDialog}>
                                            Cancel
                                    </Button>
                                    <PillButton
                                        disabled={this.state.selectedFiles?.length === 0 || this.props.isLoading}
                                        className={'ml15 pl20 pr20 h35'}
                                        text={'Import'}
                                        color={'secondary'}
                                        onClick={this.onUserCSVImport}
                                    />
                                </div>
                            </div>
                        </Screen>
                    </PackmanDialog>
                }
                <ConfirmationPrompt open={this.state.showPrompt} message={this.state.message}
                    onOkClicked={this.state.onYesClick} onCancelClicked={this.state.onNoClick}/>
            </div>
        );
    }
}

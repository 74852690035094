import * as Yup from 'yup';
import { IRight } from './right';
import { IOptionType } from '../../helper';

export interface IRightFormValues {
    id : number;
    guid : string;
    isActive : boolean;

    parent ?: IOptionType | null;
    breadcrumbParent ?: IOptionType | null;
    code : string;
    name : string;
    url : string;
    isMenu : boolean;
    isMobile : boolean;
    isPage : boolean;
    isOnNavDrawer : boolean;
    pageOrder ?: number;
    sectionOrder ?: number;
    reportURL ?: string;
}

export class RightFormValues implements IRightFormValues {
    public id : number;
    public guid : string;
    public isActive : boolean;

    public parent ?: IOptionType | null;
    public breadcrumbParent ?: IOptionType | null;
    public code : string;
    public name : string;
    public url : string;
    public isMenu : boolean;
    public isMobile : boolean;
    public isPage : boolean;
    public isOnNavDrawer : boolean;
    public pageOrder ?: number;
    public sectionOrder ?: number;
    public reportURL ?: string;

    public constructor(
        right ?: IRight | null,
        parents ?: Array<IRight> | null,
    ) {
        this.initializeDefaultValues();
        if (right) {
            this.id = right.id;
            this.guid = right.guid;
            this.code = right.code;
            this.name = right.name;
            this.isActive = right.isActive;

            this.parent = parents
                ?
                ((right.parentId)
                    ?
                    {
                        label: parents.find(x => x.id === right.parentId)?.name ?? '',
                        value: right.parentId ?? null,
                    }
                    : null)
                : null;

            this.breadcrumbParent = parents
                ?
                ((right.breadcrumbParentId)
                    ?
                    {
                        label: parents.find(x => x.id === right.breadcrumbParentId)?.name ?? '',
                        value: right.breadcrumbParentId ?? null,
                    }
                    : null)
                : null;

            this.url = right.url;
            this.isMenu = right.isMenu;
            this.isMobile = right.isMobile;
            this.isPage = right.isPage;
            this.isOnNavDrawer = right.isOnNavDrawer;
            this.pageOrder = right.pageOrder;
            this.sectionOrder = right.sectionOrder;
            this.reportURL = right.reportURL;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.name = '';
        this.isActive = true;

        this.parent = undefined;
        this.breadcrumbParent = undefined;
        this.url = '';
        this.isMenu = true;
        this.isMobile = true;
        this.isPage = true;
        this.isOnNavDrawer = true;
        this.pageOrder = undefined;
        this.sectionOrder = undefined;
        this.reportURL = '';
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

import * as React from 'react';
import CustomTable from '../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetDevices } from '../../../store/masterData/Actions';
import { formatDateTime, upsertArrayElement } from '../../../services/appFunctionsService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikActions } from 'formik';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { Device, IDevice } from '../../../@types/model/masterData/device/device';
import DeviceHttpService from '../../../services/http/masterData/deviceHttpService';
import { DeviceFormValues, IDeviceFormValues } from '../../../@types/model/masterData/device/deviceFormValues';
import DeviceForm from './form/DeviceForm';
import { setDeviceMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IDeviceScreenProps extends RouteComponentProps {
    dataSetDevices : DispatchCall<Array<IDevice>>;
    deviceData : Array<IDevice>;
    auth : IAuthState;
}

interface IDeviceScreenState {
    rows : Array<IDevice>;
    isLoading : boolean;
    selectedDevice ?: IDevice;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingDevice ?: IDevice;
}

class DeviceScreen extends React.Component<IDeviceScreenProps, IDeviceScreenState> {
    constructor(props : IDeviceScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedDevice: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await DeviceHttpService.getDeviceData();

                this.props.dataSetDevices(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading devices.');
                this.setLoading(false);
            }
        }
    };

    public editDevice = (device : IDevice) => {
        this.setState({
            isDialogOpen: true,
            selectedDevice: device,
        });
        this.openDialog();
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedDevice: undefined,
        });
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public getSelectedDevice = (props : IDeviceScreenProps, state : IDeviceScreenState) => state.selectedDevice;

    public getInitialFormValues = createSelector(
        [this.getSelectedDevice],
        (device : IDevice) => {
            return new DeviceFormValues(device);
        },
    );

    public onReset = async (formValues : IDeviceFormValues, formikActions : FormikActions<IDeviceFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    public onSubmit = async (values : IDeviceFormValues) => {
        this.setLoading(true);

        try {
            const res = await DeviceHttpService.addOrUpdateDevice(new Device(values));

            const newDeviceList = upsertArrayElement(this.props.deviceData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetDevices(newDeviceList);
            await setDeviceMasterDataIndexedDB(newDeviceList);

            if (this.state.selectedDevice) {
                generalShowSuccessSnackbar('Device updated successfully.');
            } else {
                generalShowSuccessSnackbar('Device added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating device data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (device : IDevice) => {
        this.setState({ isDeletePopupOpen: true, deletingDevice: device });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingDevice: undefined });
    };

    private removeDevice = async () => {
        const newDevice = this.state.deletingDevice;
        if (newDevice) {
            newDevice.isActive = false;
            this.setLoading(true);

            try {
                const res = await DeviceHttpService.deleteDevice(newDevice.id);

                const newDeviceList = upsertArrayElement(this.props.deviceData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetDevices(newDeviceList);
                await setDeviceMasterDataIndexedDB(newDeviceList);

                generalShowSuccessSnackbar('Device updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting device.');
                newDevice.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private getRights = (props : IDeviceScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IDeviceScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.deviceData ? this.props.deviceData : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Device'
                    isEdit={!!this.state.selectedDevice}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={DeviceFormValues.formSchema}
                        component={DeviceForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IDevice>
                            enableAdding={this.hasEditingRight(this.props)}
                            enableDeleting={(device : IDevice) => device.isActive && this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editDevice}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Serial Number', field: 'serialNumber', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IDevice) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Device'}
                    open={this.state.isDeletePopupOpen}
                    message={'Are you sure you want to delete this device?'}
                    onOkClicked={this.removeDevice}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        deviceData: state.masterData.devices,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetDevices }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DeviceScreen);

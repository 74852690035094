import * as Yup from 'yup';
import { IRecipeLine } from '../recipeLine';

export interface IRecipeLineFormValues {
    id : number;
    guid : string;
    order : number;
    tableName ?: string;
    fieldName ?: string;
    fieldType : string;
    collectionMethod ?: string;
    collectionField ?: string;
    recipeId : number;
    callRecipeId ?: number;
    isActive : boolean;
}

export class RecipeLineFormValues implements IRecipeLineFormValues {
    public id : number;
    public guid : string;
    public order : number;
    public tableName ?: string;
    public fieldName ?: string;
    public fieldType : string;
    public collectionMethod ?: string;
    public collectionField ?: string;
    public recipeId : number;
    public callRecipeId ?: number;
    public isActive : boolean;

    public constructor(recipeLine ?: IRecipeLine) {
        this.initializeDefaultValues();
        if (recipeLine) {
            this.id = recipeLine.id;
            this.guid = recipeLine.guid;
            this.order = recipeLine.order;
            this.tableName = recipeLine.tableName;
            this.fieldName = recipeLine.fieldName;
            this.fieldType = recipeLine.fieldType;
            this.collectionField = recipeLine.collectionField;
            this.collectionMethod = recipeLine.collectionMethod;
            this.recipeId = recipeLine.recipeId;
            this.callRecipeId = recipeLine.callRecipeId;
            this.isActive = recipeLine.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.order = 0;
        this.tableName = '';
        this.fieldName = '';
        this.recipeId = 0;
        this.callRecipeId = 0;
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        tableName: Yup.string().required('Required'),
        order: Yup.number().required('Required').positive(),
        recipeId: Yup.number().required('Required'),
    });
}

import { combineReducers } from 'redux';
import { createHashHistory } from 'history';
import general from './general/Reducer';
import auth from './auth/Reducer';
import data from './data/Reducer';
import masterData from './masterData/Reducer';
import { configureStore } from '@reduxjs/toolkit';
import log from './logs/Reducer';

export const history = createHashHistory();

export const rootReducer = combineReducers({
    general,
    auth,
    data,
    masterData,
    log,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: [],
});

export default store;
export const dispatch = store.dispatch;
export const getState = store.getState;

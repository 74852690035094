import { ICoreFile } from '../user/coreFile';
import { IEmailFormValues } from './emailFormValues';

export interface IEmail {
    toAddresses : Array<string>;
    ccAddresses : Array<string>;
    files : Array<ICoreFile>;
    subject : string;
    body : string;
}

export class Email implements IEmail {
    public toAddresses : Array<string>;
    public ccAddresses : Array<string>;
    public files : Array<ICoreFile>;
    public subject : string;
    public body : string;

    public constructor(formValues : IEmailFormValues, files : Array<ICoreFile>) {

        this.toAddresses = formValues.toAddresses.map(x => x.label);
        this.ccAddresses = formValues.ccAddresses.map(x => x.label);
        this.files = files;

        this.subject = formValues.subject;
        this.body = formValues.body;
    }
}

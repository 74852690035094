import * as React from 'react';
import { FieldArrayRenderProps, FieldArray } from 'formik';
import { createSelector } from 'reselect';
import { IIntakeLineLayerFormValues, IntakeLineLayerFormValues } from '../../../@types/model/intake/form/intakeLineLayerFormValues';
import FormAutocompleteSelect from '../../../components/input/form/FormAutoCompleteSelect';
import FormTextInput from '../../../components/input/form/FormTextInput';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { IVariety } from '../../../@types/model/masterData/variety/variety';
import { IOptionType } from '../../../@types/helper';
import { Typography } from '@mui/material';
import FormSingleToggleButton from '../../../components/input/form/FormSingleToggleButton';
import { IPack } from '../../../@types/model/masterData/pack/pack';
import { IOrchard } from '../../../@types/model/masterData/orchard/orchard';
import IntakeLineLayerUnitForm from './IntakeLineLayerUnitForm';
import { IIntakeLineLayer } from '../../../@types/model/intake/intakeLineLayer';
import { IIntakeFormValues } from '../../../@types/model/intake/form/intakeFormValues';
import { IProject } from '../../../@types/model/masterData/project/project';

{/* TODO: Need a better way of doing this */}
interface IIntakeLineLayerFormProps {
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    orchards : Array<IOrchard>;
    projects : Array<IProject>;
    intakeLineIndex : number;
    updateWeight : (v : number, baseType ?: IOptionType) => void;
    selectedIntake : IIntakeFormValues;
}

interface IIntakeLineLayerFormState {
    selectedProjectId ?: number;
}

class IntakeLineLayerForm extends React.Component<IIntakeLineLayerFormProps, IIntakeLineLayerFormState> {
    constructor(props : IIntakeLineLayerFormProps) {
        super(props);
    }

    public removeLine = (renderProps : FieldArrayRenderProps, index : number) => {
        const selectedLine = renderProps.form.values.intakeLines[this.props.intakeLineIndex];
        const layers = selectedLine && selectedLine.intakeLineLayers;

        renderProps.form.setFieldValue(`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers.${index}.isActive`, false);
        layers[index].intakeLineLayerUnits.forEach((y : IIntakeLineLayerFormValues, unitIndex : number) => {
            renderProps.form.setFieldValue(`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers.${index}.intakeLineLayerUnits.${unitIndex}.isActive`, false);
        });
    };

    public onVarietyChange = (index : number, renderProps : FieldArrayRenderProps) => {
        renderProps.form.setFieldValue(`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers.${index}.orchard`, undefined);
    };

    public onProjectChange = (index : number, renderProps : FieldArrayRenderProps, option : IOptionType) => {
        const orchards = this.getOrchardOptions(this.props)
            .filter(x => x.defaultVarietyId === this.props.selectedIntake.intakeLines[this.props.intakeLineIndex].intakeLineLayers[index].variety?.value)
            .filter(x => x.projectId === this.props.selectedIntake.intakeLines[this.props.intakeLineIndex].intakeLineLayers[index].project?.value);
        if (!orchards.some(x => x.projectId === option.value)) {
            renderProps.form.setFieldValue(`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers.${index}.orchard`, undefined);
        }
    };

    public onOrchardChange = (index : number, renderProps : FieldArrayRenderProps, option : IOptionType) => {
        const projectId = this.props.orchards.find(x => x.id === option.value)?.projectId;
        const project = this.getProjectOptions(this.props)
            .map(x => ({ label: `(${x.number}) ${x.description}`, value: x.id }))
            .find(x => x.value === projectId);
        renderProps.form.setFieldValue(`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers.${index}.project`, project);
    };

    private getVarieties = (props : IIntakeLineLayerFormProps) => props.varieties;
    private getOrchards = (props : IIntakeLineLayerFormProps) => props.orchards;
    private getProjects = (props : IIntakeLineLayerFormProps) => props.projects;
    private getPacks = (props : IIntakeLineLayerFormProps) => props.packs;
    private getSelectedIntake = (props : IIntakeLineLayerFormProps) => props.selectedIntake;

    private getVarietyOptions = createSelector(
        [this.getVarieties, this.getSelectedIntake],
        (varieties : Array<IVariety>, selectedIntake : IIntakeFormValues) => {
            if (!varieties) return [];

            return varieties.filter(x => x.isActive && x.commodityId === selectedIntake.commodity?.value).map((x) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            });
        },
    );

    private getOrchardOptions = createSelector(
        [this.getOrchards, this.getSelectedIntake],
        (orchards : Array<IOrchard>, selectedIntake : IIntakeFormValues) => {
            if (!orchards) return [];

            return orchards.filter(x => x.isActive && x.farmId === selectedIntake.farm?.value);
        },
    );

    private getProjectOptions = createSelector(
        [this.getProjects, this.getOrchards, this.getSelectedIntake],
        (projects : Array<IProject>, orchards : Array<IOrchard>, selectedIntake : IIntakeFormValues) => {
            if (!projects) return [];

            const orchardProjectIds = orchards.filter(x => x.isActive && x.farmId === selectedIntake.farm?.value).map(x => x.projectId);

            return projects.filter(x => x.isActive && x.commodityId === selectedIntake.commodity?.value && ((orchardProjectIds.length === 0) || (orchardProjectIds.some(y => y === x.id)))).filter(x => x.isActive);
        },
    );

    private getPackOptions = createSelector(
        [this.getPacks, this.getSelectedIntake],
        (packs : Array<IPack>, selectedIntake : IIntakeFormValues) => {
            if (!packs) return [];

            return packs.filter(x => x.isActive && x.commodityId === selectedIntake.commodity?.value).map((x) => {
                return { label: `(${x.code}) ${x.description}`, value: x.id };
            });
        },
    );

    public render() {
        const selectedIntake = this.props.selectedIntake;
        return (
            <FieldArray
                name={`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers`}
                render={(renderProps : FieldArrayRenderProps) => {
                    const intakeLineLayers = renderProps.form.values.intakeLines[this.props.intakeLineIndex]?.intakeLineLayers ?? [];
                    if (renderProps.form.values.intakeLines[this.props.intakeLineIndex].intakeLineLayers && renderProps.form.values.intakeLines[this.props.intakeLineIndex].intakeLineLayers.length === 0) {
                        renderProps.push(new IntakeLineLayerFormValues());
                    }
                    return (
                        <>
                            {intakeLineLayers.map((intakeLineLayer : IIntakeLineLayer, index : number) => {
                                return (
                                    <div key={`${intakeLineLayer.id}-${index}-intake-line-layer-form`}>
                                        <div className='fdc wfill aic'>
                                            <div className='h60 fdr wfill bcp'>
                                                <Typography className='cw flx1 pl60 pt10 pb10 aic fw500'>LAYER {index + 1}</Typography>
                                                <div className='fdr aic pr69'>
                                                    <Typography className={'cw pr10 fs14'}>GROSS</Typography>
                                                    <div className={'cw bw1 br5 fs14'}>
                                                        <div className={'pl10 pr50 pt5 pb5'}>
                                                            {intakeLineLayer.grossWeight} KG
                                                        </div>
                                                    </div>
                                                    <Typography className={'cw pr10 fs14 pl20'}>NETT</Typography>
                                                    <div className={'cw bw1 br5 fs14'}>
                                                        <div className={'pl10 pr50 pt5 pb5'}>
                                                            {intakeLineLayer.nettWeight} KG
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='fdr wfill'>
                                                <div className='fdr pl10 pr10 wfill'>
                                                    <FormAutocompleteSelect
                                                        name={`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers.${index}.variety`}
                                                        label={'Variety'}
                                                        options={this.getVarietyOptions(this.props)}
                                                        onChange={() => this.onVarietyChange(index, renderProps)}
                                                        className={'flx1'} />
                                                    <FormAutocompleteSelect
                                                        name={`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers.${index}.project`}
                                                        label={'Project'}
                                                        onChange={(option : IOptionType) => this.onProjectChange(index, renderProps, option)}
                                                        options={this.getProjectOptions(this.props).map(x => ({ label: `(${x.number}) ${x.description}`, value: x.id }))}
                                                        className='flx1' />
                                                    <FormAutocompleteSelect
                                                        name={`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers.${index}.orchard`}
                                                        label={'Orchard'}
                                                        onChange={(option : IOptionType) => this.onOrchardChange(index, renderProps, option)}
                                                        options={this.getOrchardOptions(this.props)
                                                            .filter(x => x.defaultVarietyId === selectedIntake.intakeLines[this.props.intakeLineIndex].intakeLineLayers[index].variety?.value)
                                                            .filter(x => !!selectedIntake.intakeLines[this.props.intakeLineIndex].intakeLineLayers[index].project?.value ? x.projectId === selectedIntake.intakeLines[this.props.intakeLineIndex].intakeLineLayers[index].project?.value : true)
                                                            .map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }))}
                                                        className='flx1' />
                                                    <FormAutocompleteSelect
                                                        name={`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers.${index}.pack`}
                                                        label={'Pack'}
                                                        onChange={() => this.props.updateWeight(this.props.intakeLineIndex)}
                                                        options={this.getPackOptions(this.props)} className={'flx1'}
                                                        disabled/>
                                                    <FormTextInput
                                                        disabled
                                                        name={`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers.${index}.lotName`}
                                                        label={'Lot'}
                                                        className={'flx1 pt5'} />
                                                    <FormTextInput
                                                        disabled={true}
                                                        name={`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers.${index}.noOfUnits`}
                                                        label={'No. Units'}
                                                        className='flx1 pt5' />
                                                    <FormSingleToggleButton
                                                        name={`intakeLines.${this.props.intakeLineIndex}.intakeLineLayers.${index}.isActive`}
                                                        label={'Is Active'}
                                                        disabled/>
                                                </div>
                                            </div>
                                            <IntakeLineLayerUnitForm
                                                intakeLineIndex={this.props.intakeLineIndex}
                                                intakeLineLayerIndex={index}
                                                updateWeight={this.props.updateWeight}
                                            />
                                        </div>
                                    </div>
                                );
                            })
                            }
                        </>
                    );
                }}
            />
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        orchards: state.masterData.orchards,
        projects: state.masterData.projects,
    };
};

export default connect(
    mapStateToProps,
)(IntakeLineLayerForm);

import React from 'react';
import ReactDOM from 'react-dom';
import { DATE_FORMAT_DEFAULT } from '../../../appConstants';
import { IDocumentProps } from '../../../@types/other';
import { generalShowErrorSnackbar } from '../../../store/general/Functions';
import { getState } from '../../../store/Index';
import { addArrayElement } from '../../appFunctionsService';
import {  PDFDownloadLink, Document, Page, View, Image, Text, StyleSheet, Font } from '@react-pdf/renderer';
import moment from 'moment';
import Code39 from '../../../fonts/code39.ttf';
import QRCode from 'qrcode.react';
import FileSaver from 'file-saver';
import { IStorageUnit } from '../../../@types/model/masterData/storageUnit/storageUnit';
import { orderBy } from 'lodash';

const styles = StyleSheet.create({
    section: {
        margin: 10,
        padding: 10,
        flex: 1,
    },
    page: {
        paddingTop: 25,
        paddingBottom: 65,
        marginBottom: 65,
    },
    pageNumber: {
        fontSize: 11,
        position: 'absolute',
        bottom: 35,
        right: 25,
    },
    pageMargin: {
        marginLeft: 25,
        marginRight: 25,
        flex: 1,
    },
    tableRow: {
        borderBottom: '1pt solid black',
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 8,
        flexDirection: 'row',
    },
    findingSheetColumn: {
        fontSize: 8,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        paddingTop: 5,
        borderRight: '1pt solid black',
        alignItems: 'center',
    },
    fdc: { flexDirection: 'column' },
    fdr: { flexDirection: 'row' },
    verticalText: {
        transform: 'rotate(90deg)',
        width: 80,
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    barcode: {
        fontFamily: 'code39',
        fontSize: 20,
        transform: 'scaleY(2)',
        top: 10,
    },
    barcodeLabel: {
        fontSize: 9,
        letterSpacing: 2.5,
        top: 20,
    },
    qrCode: {
        width: '100%',
        height: '100%',
    },
    fs6: { fontSize: 6 },
    fs8: { fontSize: 8 },
    fs10: { fontSize: 10 },
    fs11: { fontSize: 11 },
    fs12: { fontSize: 12 },
    fs13: { fontSize: 13 },
    fs14: { fontSize: 14 },
    fs15: { fontSize: 15 },
    bold: { fontWeight: 'bold' },
    pt5: { paddingTop: 5 },
    pt10: { paddingTop: 10 },
    pt35: { paddingTop: 35 },
    pt50: { paddingTop: 50 },
    pb5: { paddingBottom: 5 },
    pb10: { paddingBottom: 10 },
    pb35: { paddingBottom: 35 },
    pb50: { paddingBottom: 50 },
    pl2: { paddingLeft: 2 },
    pl4: { paddingLeft: 4 },
    pl5: { paddingLeft: 5 },
    pl20: { paddingLeft: 20 },
    pl40: { paddingLeft: 40 },
    pr2: { paddingRight: 2 },
    pr20: { paddingRight: 20 },
    pr80: { paddingRight: 80 },
    pr100: { paddingRight: 100 },
    mt5: { marginTop: 5 },
    mt10: { marginTop: 10 },
    mt15: { marginTop: 15 },
    mt20: { marginTop: 20 },
    mt35: { marginTop: 35 },
    mb5: { marginBottom: 5 },
    mb10: { marginBottom: 10 },
    mb20: { marginBottom: 20 },
    mb35: { marginBottom: 35 },
    mb50: { marginBottom: 50 },
    flx1: { flex: 1 },
    blw1: { borderLeft: '1pt solid black' },
    brw1: { borderRight: '1pt solid black' },
    btw1: { borderTop: '1pt solid black' },
    bbw1: { borderBottom: '1pt solid black' },
    jcc: { justifyContent: 'center' },
    ail: { alignItems: 'left' },
    aic: { alignItems: 'center' },
    aife: { alignItems: 'flex-end' },
    tac: { textAlign: 'center' },
    posa: { position: 'absolute' },
    w50: { width: 50 },
    w100: { width: 100 },
    w130: { width: 130 },
    w140: { width: 140 },
    w175: { width: 175 },
    w180: { width: 180 },
    w190: { width: 190 },
    w200: { width: 200 },
    w280: { width: 280 },
    h40: { height: 40 },
    h80: { height: 80 },
    h90: { height: 90 },
    h100: { height: 100 },
    h130: { height: 130 },
    h150: { height: 150 },
    h160: { height: 160 },
    h170: { height: 170 },
    h180: { height: 180 },
    h185: { height: 185 },
    h190: { height: 190 },
    h200: { height: 200 },
    h205: { height: 205 },
    h210: { height: 210 },
    h220: { height: 220 },
    h230: { height: 230 },
    h240: { height: 240 },
    h250: { height: 250 },
    h260: { height: 260 },
    t5: { top: 5 },
    certCol1: {
        left: 0,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 1,
        position: 'absolute',
    },
    certCol2: {
        left: 35,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 2,
        position: 'absolute',
    },
    certCol3: {
        left: 70,
        width: 30,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 3,
        position: 'absolute',
    },
    certCol4: {
        left: 100,
        width: 25,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 4,
        position: 'absolute',
    },
    certCol5: {
        left: 125,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 5,
        position: 'absolute',
    },
    certCol6: {
        left: 160,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 6,
        position: 'absolute',
    },
    certCol7: {
        left: 195,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 7,
        position: 'absolute',
    },
    certCol8: {
        left: 230,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 8,
        position: 'absolute',
    },
    certCol9: {
        left: 265,
        width: 25,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 9,
        position: 'absolute',
    },
    certCol10: {
        left: 290,
        width: 30,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 10,
        position: 'absolute',
    },
    certCol11: {
        left: 320,
        width: 70,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 11,
        position: 'absolute',
    },
    certCol12: {
        left: 390,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 12,
        position: 'absolute',
    },
    certCol13: {
        left: 425,
        width: 85,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 13,
        position: 'absolute',
    },
    certCol14: {
        left: 510,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 14,
        position: 'absolute',
    },
});

Font.register({
    family: 'code39',
    src: Code39,
});

// #region getters
const getSite = (id ?: number) => {
    const state = getState();
    return state.masterData.sites.find(x => x.id === id);
};

export const generateStorageUnitCards = async (storageUnits : Array<IStorageUnit>, callback ?: () => void) => buildStorageUnitCardsPDF(storageUnitCardsPDF, 'Storage Unit Cards.pdf', storageUnits, callback);

export const buildStorageUnitCardsPDF = (document : any, fileName : string, storageUnits : Array<IStorageUnit>, callback ?: () => void) => {
    const doc : () => React.ReactElement<IDocumentProps> = () => document(storageUnits);

    createAndDownloadPDF(
        doc,
        fileName,
        'pdf-creator-link',
        // tslint:disable-next-line: no-empty
        callback ? callback : () => {},
    );
};

const createAndDownloadPDF = (pdfContent : () => React.ReactElement<IDocumentProps>, filename : string, divId : string, callback : () => void) => {
    setTimeout(
        () => {
            const link = (
                <div id={ divId }>
                    <PDFDownloadLink document={ pdfContent() } fileName={ filename }>{
                        ({ blob, loading }) => {
                            if (!loading) {
                                setTimeout(
                                    () => {
                                        if (blob) {
                                            FileSaver.saveAs(blob, filename);
                                        } else {
                                            generalShowErrorSnackbar('Could not download file.');
                                        }
                                    },
                                    1);
                            }
                        }}</PDFDownloadLink>
                </div>
            );
            const elem = document.createElement('div');
            const doc = document.getElementById('root');
            if (doc) {
                doc.appendChild(elem);
                ReactDOM.render(link, elem);
                setTimeout(
                    () => {
                        elem.remove();
                        callback();
                    },
                    1);
            }
        },
        1);
};

const storageUnitCardsPDF = (storageUnits : Array<IStorageUnit>) => {
    return <Document>
        <Page size='A4' style={styles.page}>
            <View style={styles.pageMargin}>
                <View style={styles.fdr}>
                    <Image style={{ height: 42 }} source={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`}/>
                    <Text style={[styles.fs13, styles.bold, styles.mt15, styles.pl20]}>PACKMAN</Text>
                    <View style={styles.flx1} />
                </View>
                <View style={[styles.fdr, styles.pb10]} fixed>
                    <View style={styles.flx1} />
                    <Text style={styles.fs8} fixed render={({ pageNumber, totalPages }) => (
                        `PAGE ${pageNumber} OF ${totalPages}`
                    )}> </Text>
                </View>
                <View style={[styles.fdr, styles.aic, styles.pt5, styles.pb5]}>
                    <Text style={styles.fs13}>{`SITE : ${getSite(storageUnits[0].siteId)?.shortDescription} - ${getSite(storageUnits[0].siteId)?.description}`}</Text>
                    <View style={styles.flx1} />
                    <Text style={styles.fs8}>{`Date: ${moment().local().format(DATE_FORMAT_DEFAULT)}`}</Text>
                </View>
                <View style={[styles.fdr, styles.bbw1]}>
                </View>
                <View style={[styles.fdr]}>
                    <Text style={[styles.fs10, styles.pb10, styles.pt10, styles.w280]}>{'QR CODES'}</Text>
                </View>
                {storageUnitCards(storageUnits ?? [])}
            </View>
        </Page>
    </Document>;
};

const storageUnitCards = (storageUnits : Array<IStorageUnit>) => {
    let qrCodes : any = [];
    orderBy(storageUnits,x => x.code).forEach((storageUnit) => {
        qrCodes = addArrayElement(qrCodes,
            <View key={storageUnit.guid} wrap={false} style={[styles.fdr, styles.h190, styles.mb20, styles.bbw1, styles.btw1, styles.brw1, styles.blw1]}>
                <View style={[styles.flx1, styles.fdc, styles.pb5, styles.aic, styles.jcc]}>
                    {<Image style={[styles.qrCode, styles.h130, { width: 130 }]} src={renderQRCode(storageUnit)}/>}
                    {<Text style={[styles.bold, styles.pt5, styles.fs10]}>{storageUnit.code}</Text>}
                </View>
            </View>, 'end');
    });
    return qrCodes;
};

const renderQRCode = (storageUnit : IStorageUnit) => {
    const renderedQRCode = document.getElementById(`${storageUnit.id}_qr_code`) as HTMLCanvasElement;

    return renderedQRCode.toDataURL();
};

const getStorageUnitQRCode = (storageUnit : IStorageUnit) => {
    return 'SU|' + storageUnit.guid;
};

export const renderQRCodes = (storageUnits : Array<IStorageUnit>) => {
    return storageUnits.map((x) => {
        return (
            <div key={x.guid} style={{ display: 'none' }}>
                <QRCode
                    id={`${x.id}_qr_code`}
                    value={getStorageUnitQRCode(x)}
                    bgColor={'#ffffff'}
                    fgColor={'#000000'}
                    level={'H'}
                />
            </div>
        );
    });
};

import * as React from 'react';
import moment, { Moment } from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LocalizationProvider, PickersShortcutsItem } from '@mui/x-date-pickers';
import { LicenseInfo, DateRange, StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import {
    DateRangePickerDay as MuiDateRangePickerDay,
    DateRangePickerDayProps,
} from '@mui/x-date-pickers-pro/DateRangePickerDay';
import materialTheme from '../styles/materialTheme';
import styled from '@mui/styles/styled';


LicenseInfo.setLicenseKey(
    MUIXLICENCE,
);

const DateRangePickerDay = styled(MuiDateRangePickerDay)(
    ({
        isHighlighting,
        outsideCurrentMonth,
        isEndOfHighlighting,
        isStartOfHighlighting,
    }) => ({
        ...(!outsideCurrentMonth &&
            (isStartOfHighlighting || isEndOfHighlighting || isHighlighting) && {
            backgroundColor: materialTheme.custom.basicTransactionDateFilter.days.selected.background,
            color: materialTheme.custom.basicTransactionDateFilter.days.selected.text,
        }),
    }),
) as React.ComponentType<DateRangePickerDayProps<any>>;


interface IDateRangeProps {
    onChange : (start : Moment | null, end : Moment | null, changedBy ?: 'start' | 'end') => void;
    start : Moment;
    end : Moment;
    parentPickerFunction ?: () => void;
    positionLeft ?: boolean;
    className ?: string;
}

interface IDateRangeState {
}

const shortcutsItems : Array<PickersShortcutsItem<DateRange<Moment>>> = [
    {
        label: 'Today',
        getValue: () => {
            return [moment().startOf('day'), moment().endOf('day')];
        },
    },
    {
        label: 'Yesterday',
        getValue: () => {
            return [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')];
        },
    },
    {
        label: 'This Week',
        getValue: () => {
            return [moment().startOf('week'), moment().endOf('week')];
        },
    },
    {
        label: 'Last Week',
        getValue: () => {
            return [moment().subtract(7, 'day').startOf('week'), moment().subtract(7, 'day').endOf('week')];
        },
    },
    {
        label: 'Last 7 Days',
        getValue: () => {
            return [moment().subtract(7, 'day'), moment()];
        },
    },
    {
        label: 'This Month',
        getValue: () => {
            return [moment().startOf('month'), moment().endOf('month')];
        },
    },
    {
        label: 'Last Month',
        getValue: () => {
            return [moment().startOf('month').subtract(1, 'day').startOf('month'), moment().startOf('month').subtract(1, 'day')];
        },
    },
    {
        label: 'This Year',
        getValue: () => {
            return [moment().startOf('year'), moment().endOf('year')];
        },
    },
    {
        label: 'Last Year',
        getValue: () => {
            return [moment().startOf('year').subtract(1, 'day').startOf('year'), moment().startOf('year').subtract(1, 'day')];
        },
    },
    { label: 'Reset', getValue: () => [null, null] },
];

export default class CustomDateRange extends React.Component<IDateRangeProps, IDateRangeState> {

    constructor(props : IDateRangeProps) {
        super(props);
    }

    private handleRangeChange = (ranges : DateRange<Moment>) => {
        const startdate = ranges[0];
        const enddate = ranges[1];
        this.props.onChange(startdate, enddate);
    };

    private formatDayOfWeek = (day : string) => {
        // this is 100% a hackfix for a very strange issue where the day of the week is out by one on the calendar, if this ever gets resolved remove this
        const correctDayIndex = moment.weekdays().findIndex(x => x.includes(day));
        return correctDayIndex < 6 ? moment.weekdays()[correctDayIndex + 1].substring(0, 3) : moment.weekdays()[0].substring(0, 3);
    };

    public render() {
        const selectionRange = {
            startDate: this.props.start,
            endDate: this.props.end,
            key: 'selection',
        };

        return (
            <div className={'fdr flx1'.concat(this.props.className ? this.props.className : '')}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-GB'}>
                    <StaticDateRangePicker
                        calendars={2}
                        dayOfWeekFormatter={this.formatDayOfWeek}
                        value={[selectionRange.startDate, selectionRange.endDate]}
                        onChange={this.handleRangeChange}
                        slots={{ day: DateRangePickerDay }}
                        slotProps={{
                            shortcuts: {
                                items: shortcutsItems,
                            },
                            // textField: { variant: 'standard' },
                            actionBar: { actions: [] },
                            layout: {
                                sx: {
                                    bgcolor: materialTheme.custom.basicTransactionDateFilter.background,
                                },
                            },
                        }}
                    />
                </LocalizationProvider>
            </div>
        );
    }
}

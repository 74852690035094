import { IBaseModel } from '../../model';
import { IIntakeLineLayer, IntakeLineLayer } from './intakeLineLayer';
import { IIntakeLineFormValues } from './form/intakeLineFormValues';

export interface IIntakeLine extends IBaseModel {
    intakeId : number;
    palletBaseTypeId : number;
    grossWeight : number;
    nettWeight : number;
    intakePalletRef ?: string;
    intakeLineLayers : Array<IIntakeLineLayer>;
}

export class IntakeLine implements IIntakeLine {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public intakeId : number;
    public palletBaseTypeId : number;
    public grossWeight : number;
    public nettWeight : number;
    public intakePalletRef ?: string;

    public intakeLineLayers : Array<IIntakeLineLayer>;

    public constructor(formValues : IIntakeLineFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.palletBaseTypeId = Number(formValues.palletBaseType?.value) ?? 0;
        this.grossWeight = formValues.grossWeight;
        this.nettWeight = formValues.nettWeight;
        this.intakePalletRef = formValues.intakePalletRef;

        this.intakeLineLayers = formValues.intakeLineLayers.map(x => new IntakeLineLayer(x));
    }

}

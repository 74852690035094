import { enqueueSnackbar } from './Actions';
import { dispatch } from '../Index';

export async function generalShowErrorSnackbar(message : string) {
    dispatch(enqueueSnackbar({
        message,
        options: {
            variant: 'error',
        },
    }));
}

export async function generalShowSuccessSnackbar(message : string) {
    dispatch(enqueueSnackbar({
        message,
        options: {
            variant: 'success',
        },
    }));
}

export async function generalShowWarningSnackbar(message : string) {
    dispatch(enqueueSnackbar({
        message,
        options: {
            variant: 'warning',
        },
    }));
}

export async function generalShowInfoSnackbar(message : string) {
    dispatch(enqueueSnackbar({
        message,
        options: {
            variant: 'info',
        },
    }));
}

import { IBaseModel } from '../../../model';
import { IColourFormValues } from './colourFormValues';

export interface IColour extends IBaseModel {
    commodityIds ?: Array<number>;
    code : string;
    name : string;
}

export class Colour implements IColour {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public name : string;
    public commodityIds ?: Array<number> | undefined;

    public constructor(formValues : IColourFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.code = formValues.code;
        this.name = formValues.name;

        this.commodityIds = formValues.commodities.map(x => Number(x.value)) ?? undefined;
    }
}

import { IBaseModel } from '../../../model';
import { IPrinterFormValues } from './printerFormValues';

export interface IPrinter extends IBaseModel {
    printServerId : number;
    name : string;
    defaultPaper : string;
    apiAddress : string;
}

export class Printer implements IPrinter {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public name : string;
    public defaultPaper : string;
    public apiAddress : string;
    public printServerId : number;

    public constructor(formValues : IPrinterFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.name = formValues.name;
        this.defaultPaper = formValues.defaultPaper;
        this.apiAddress = formValues.apiAddress;
        this.printServerId = Number(formValues.printServer?.value);
        this.isActive = formValues.isActive;
    }
}

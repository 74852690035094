import * as React from 'react';
import CustomTable from '../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { formatDateTime, upsertArrayElement } from '../../../services/appFunctionsService';
import { dataSetAllMaterialRelatedData } from '../../../store/data/Functions';
import { IMaterial, Material } from '../../../@types/model/masterData/material/material';
import { IPalletBaseType } from '../../../@types/model/masterData/palletBaseType/palletBaseType';
import { IMaterialType } from '../../../@types/model/masterData/materialType/materialType';
import { IPack } from '../../../@types/model/masterData/pack/pack';
import MaterialRelatedDataHttpService from '../../../services/http/masterData/materialRelatedDataHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikActions } from 'formik';
import { IMaterialFormValues, MaterialFormValues } from '../../../@types/model/masterData/material/materialFormValues';
import { createSelector } from 'reselect';
import MaterialHttpService from '../../../services/http/masterData/materialHttpService';
import MaterialForm from './form/MaterialForm';
import { dataSetMaterials } from '../../../store/masterData/Actions';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { IUnitOfMeasure } from '../../../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import { setMaterialsMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IMaterialScreenProps extends RouteComponentProps {
    materials : Array<IMaterial>;
    palletBaseTypes : Array<IPalletBaseType>;
    materialTypes : Array<IMaterialType>;
    unitOfMeasures : Array<IUnitOfMeasure>;
    dataSetMaterials : DispatchCall<Array<IMaterial>>;
    packs : Array<IPack>;
    auth : IAuthState;
}

interface IMaterialScreenState {
    rows : Array<IMaterial>;
    isLoading : boolean;
    selectedMaterial ?: IMaterial;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingMaterial ?: IMaterial;
}

class MaterialScreen extends React.Component<IMaterialScreenProps, IMaterialScreenState> {
    constructor(props : IMaterialScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedMaterial: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await MaterialRelatedDataHttpService.getAllMaterialRelatedMasterData();

                dataSetAllMaterialRelatedData(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading material related data.');
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private formatPacks = (packIds : Array<number>) => this.props.packs
        .filter(x => packIds.some(y => y === x.id))
        .map(x => x.code).toString().replace(/,/g, ', ');

    public onReset = async (formValues : IMaterialFormValues, formikActions : FormikActions<IMaterialFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    public getSelectedMaterial = (props : IMaterialScreenProps, state : IMaterialScreenState) => state.selectedMaterial;
    public getPacks = (props : IMaterialScreenProps) => props.packs;
    public getPalletBaseTypes = (props : IMaterialScreenProps) => props.palletBaseTypes;
    public getMaterialTypes = (props : IMaterialScreenProps) => props.materialTypes;
    public getUnitOfMeasures = (props : IMaterialScreenProps) => props.unitOfMeasures;

    public getInitialFormValues = createSelector(
        [this.getSelectedMaterial, this.getPalletBaseTypes, this.getMaterialTypes, this.getPacks, this.getUnitOfMeasures],
        (material : IMaterial, palletBaseTypes : Array<IPalletBaseType>, materialTypes : Array<IMaterialType>, packs : Array<IPack>, unitOfMeasures : Array<IUnitOfMeasure>) => {
            return new MaterialFormValues(material, palletBaseTypes, materialTypes, packs, unitOfMeasures);
        },
    );

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedMaterial: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    public editMaterial = (material : IMaterial) => {
        this.setState({
            isDialogOpen: true,
            selectedMaterial: material,
        });
        this.openDialog();
    };

    public onSubmit = async (values : IMaterialFormValues) => {
        if (values.packs && values.packs?.length > 0 && !!values.palletBaseType) {
            generalShowErrorSnackbar('Either pallet base type OR pack can be selected. Cannot select both.');
            return;
        }

        this.setLoading(true);
        try {
            const res = await MaterialHttpService.addOrUpdateMaterial(new Material(values));

            const newMaterialList = upsertArrayElement(this.props.materials, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetMaterials(newMaterialList);
            await setMaterialsMasterDataIndexedDB(newMaterialList);

            if (this.state.selectedMaterial) {
                generalShowSuccessSnackbar('Material updated successfully.');
            } else {
                generalShowSuccessSnackbar('Material added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating material data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (material : IMaterial) => {
        this.setState({ isDeletePopupOpen: true, deletingMaterial: material });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingMaterial: undefined });
    };

    private removeMaterial = async () => {
        const newMaterial = this.state.deletingMaterial;
        if (newMaterial) {
            newMaterial.isActive = false;
            this.setLoading(true);

            try {
                const res = await MaterialHttpService.deleteMaterial(newMaterial.id);

                const newMaterialList = upsertArrayElement(this.props.materials, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetMaterials(newMaterialList);
                await setMaterialsMasterDataIndexedDB(newMaterialList);

                generalShowSuccessSnackbar('Material updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting material.');
                newMaterial.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private getPalletBaseTypeName = (palletBaseTypeId : number) => {
        const palletBaseType = this.props.palletBaseTypes?.find(x => x.id === palletBaseTypeId);
        return palletBaseType?.name ?? '';
    };

    private getMaterialTypeName = (materialTypeId : number) => {
        const materialType = this.props.materialTypes?.find(x => x.id === materialTypeId);
        return materialType?.name ?? '';
    };

    private getUnitOfMeasureName = (unitOfMeasureId : number) => {
        const unitOfMeasure = this.props.unitOfMeasures?.find(x => x.id === unitOfMeasureId);
        return unitOfMeasure?.name ?? '';
    };

    private getRights = (props : IMaterialScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IMaterialScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.materials ? this.props.materials : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Material'
                    isEdit={!!this.state.selectedMaterial}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={MaterialFormValues.formSchema}
                        component={MaterialForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IMaterial>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editMaterial}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(material : IMaterial) => material.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Material Type', field: 'materialTypeId', formatFunction: this.getMaterialTypeName, enableFiltering: true, enableSorting: true },
                                { title: 'Pallet Base Type', field: 'palletBaseTypeId', formatFunction: this.getPalletBaseTypeName, enableFiltering: true, enableSorting: true },
                                { title: 'Packs', field: 'packIds', formatFunction: this.formatPacks, enableFiltering: true, enableSorting: true },
                                { title: 'Unit Of Measure', field: 'unitOfMeasureId', formatFunction: this.getUnitOfMeasureName, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IMaterial) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Material'}
                    open={this.state.isDeletePopupOpen}
                    message={'Are you sure you want to delete this material?'}
                    onOkClicked={this.removeMaterial}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        materials: state.masterData.materials,
        palletBaseTypes: state.masterData.palletBaseTypes,
        materialTypes: state.masterData.materialTypes,
        unitOfMeasures: state.masterData.unitOfMeasures,
        packs: state.masterData.packs,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetMaterials }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MaterialScreen);

import * as Yup from 'yup';
import { IIntakeLineLayer } from '../intakeLineLayer';
import { IOptionType } from '../../../helper';
import { IIntakeLineLayerUnitFormValues, IntakeLineLayerUnitFormValues } from './intakeLineLayerUnitFormValues';
import { IIntakeLineLayerUnit } from '../intakeLineLayerUnit';
import { IVariety } from '../../masterData/variety/variety';
import { IOrchard } from '../../masterData/orchard/orchard';
import { IPack } from '../../masterData/pack/pack';
import { ILot } from '../../lot/lot';
import { IProject } from '../../masterData/project/project';

export interface IIntakeLineLayerFormValues {
    id : number;
    guid : string;
    isActive : boolean;

    variety ?: IOptionType;
    pack ?: IOptionType;
    lot ?: IOptionType;
    orchard ?: IOptionType;
    project ?: IOptionType;
    grossWeight : number;
    nettWeight : number;
    noOfUnits : number;
    lotName ?: string;

    intakeLineLayerUnits : Array<IIntakeLineLayerUnitFormValues>;
}

export class IntakeLineLayerFormValues implements IIntakeLineLayerFormValues {
    public id : number;
    public guid : string;
    public isActive : boolean;

    public variety ?: IOptionType;
    public pack ?: IOptionType;
    public lot ?: IOptionType;
    public orchard ?: IOptionType;
    public project ?: IOptionType;
    public grossWeight : number;
    public nettWeight : number;
    public noOfUnits : number;
    public lotName ?: string;

    public intakeLineLayerUnits : Array<IIntakeLineLayerUnitFormValues>;

    public constructor(
        intakeLineLayer ?: IIntakeLineLayer,
        varieties ?: Array<IVariety>,
        orchards ?: Array<IOrchard>,
        packs ?: Array<IPack>,
        lots ?: Array<ILot>,
        projects ?: Array<IProject>,
    ) {
        this.initializeDefaultValues();

        const selectedVariety = varieties?.find(x => x.id === intakeLineLayer?.varietyId);
        const selectedPack = packs?.find(x => x.id === intakeLineLayer?.packId);
        const selectedOrchard = orchards?.find(x => x.id === intakeLineLayer?.orchardId);
        const selectedProject = projects?.find(x => x.id === intakeLineLayer?.projectId);

        if (intakeLineLayer) {
            this.id = intakeLineLayer.id;
            this.guid = intakeLineLayer.guid;
            this.isActive = intakeLineLayer.isActive;

            this.variety = varieties ? {
                label: selectedVariety ? `(${selectedVariety?.code}) ${selectedVariety?.name}` : '',
                value: intakeLineLayer.varietyId,
            } : undefined;

            this.pack = packs ? {
                label: selectedPack ? `(${selectedPack?.code}) ${selectedPack?.description}` : '',
                value: intakeLineLayer.packId,
            } : undefined;

            this.lot = lots ? {
                label: lots.find(x => x.id === intakeLineLayer.lotId)?.name ?? '',
                value: intakeLineLayer.lotId,
            } : undefined;

            this.orchard = orchards && intakeLineLayer.orchardId ? {
                label: selectedOrchard ? `(${selectedOrchard?.code}) ${selectedOrchard?.name}` : '',
                value: intakeLineLayer.orchardId,
            } : undefined;

            this.project = projects && intakeLineLayer.projectId ? {
                label: selectedProject ? `(${selectedProject?.number}) ${selectedProject?.description}` : '',
                value: intakeLineLayer.projectId,
            } : undefined;

            this.grossWeight = intakeLineLayer.grossWeight;
            this.nettWeight = intakeLineLayer.nettWeight;
            this.noOfUnits = intakeLineLayer.noOfUnits;
            this.lotName = intakeLineLayer.lotName;

            this.intakeLineLayerUnits = intakeLineLayer.intakeLineLayerUnits?.map((x : IIntakeLineLayerUnit) => new IntakeLineLayerUnitFormValues(x));
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.isActive = true;

        this.variety = undefined;
        this.pack = undefined;
        this.lot = undefined;
        this.orchard = undefined;
        this.project = undefined;
        this.grossWeight = 0.0;
        this.nettWeight = 0.0;
        this.noOfUnits = 0;
        this.lotName = '';

        this.intakeLineLayerUnits = [];
    };

    public static formSchema = Yup.object().shape({
        isActive: Yup.boolean().required('Required'),
        variety: Yup.object().nullable().required('Required'),
        pack: Yup.object().nullable().required('Required'),
        grossWeight: Yup.number().required('Required'),
        nettWeight: Yup.number().required('Required'),
        noOfUnits: Yup.number().required('Required'),
        lotName: Yup.string().required('Required'),
        intakeLineLayerUnits: Yup.array().of(IntakeLineLayerUnitFormValues.formSchema),
    });
}

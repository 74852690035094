import { IBaseModel } from '../../../model';
import { IUnitOfMeasureFormValues } from './unitOfMeasureFormValues';

export interface IUnitOfMeasure extends IBaseModel {
    code : string;
    name : string;
    typeId : number;
}

export class UnitOfMeasure implements IUnitOfMeasure {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public name : string;
    public typeId : number;

    public constructor(formValues : IUnitOfMeasureFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.code = formValues.code;
        this.name = formValues.name;
        this.typeId = Number(formValues.unitOfMeasureType?.value) ?? 0;
    }
}

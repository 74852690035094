import * as React from 'react';
import { Map } from 'react-leaflet';
import Screen from '../../../components/Screen';
import L from 'leaflet';
import 'leaflet-routing-machine';
import { connect } from 'react-redux';
import { DispatchCall, IRootState, RootAction } from '../../../@types/redux';
import { ISite } from '../../../@types/model/masterData/site/site';
import { createSelector } from 'reselect';
import { IOrganization } from '../../../@types/model/masterData/organization/organization';
import { generalShowErrorSnackbar } from '../../../store/general/Functions';
import { bindActionCreators, Dispatch } from 'redux';
import TripHttpService from '../../../services/http/trip/tripHttpService';
import CarrierHttpService from '../../../services/http/masterData/carrierHttpService';
import DispatchHttpService from '../../../services/http/dispatch/dispatchHttpService';
import { ITrip } from '../../../@types/model/dispatch/trip';
import { ICarrier } from '../../../@types/model/masterData/carrier/carrier';
import { IDispatchInstruction } from '../../../@types/model/dispatch/dispatchInstruction';
import { dataSetTrips, dataSetDispatchInstructions } from '../../../store/data/Actions';
import { dataSetCarriers } from '../../../store/masterData/Actions';
import { addArrayElement } from '../../../services/appFunctionsService';
import TransactionFilter from '../../../components/filters/BasicTransactionScreenFilter';
import moment from 'moment';
import TripDashboardGoogleMapComponent from '../../../components/map/TripDashboardGoogleMapComponent';
import { DATEPICKER_FORMAT_DEFAULT } from '../../../appConstants';
import PillButton from '../../../components/input/PillButton';
import { syncMasterData } from '../../../services/masterDataSyncService';

interface ITripDashboardProps {
    dataSetTrips : DispatchCall<Array<ITrip>>;
    dataSetCarriers : DispatchCall<Array<ICarrier>>;
    dataSetDispatchInstructions : DispatchCall<Array<IDispatchInstruction>>;
    sites : Array<ISite>;
    selectedOrganizationIds : Array<number>;
    selectedSiteIds : Array<number>;
    organizations : Array<IOrganization>;
    trips : Array<ITrip>;
    carriers : Array<ICarrier>;
    dispatches : Array<IDispatchInstruction>;
}

interface ITripDashboardState {
    isLoading : boolean;
    isSiteDetailPopupOpen : boolean;
    selectedSite ?: ISite;
    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;
    filteredTrips : Array<ITrip>;
    tripRoutes : Array<{
        tripId : number;
        tripDirection ?: google.maps.DirectionsResult;
        startLocation : google.maps.LatLng;
        endLocation : google.maps.LatLng;
        sourceSite : ISite;
        destinationSite : ISite; }>;
}

class TripDashboard extends React.Component<ITripDashboardProps, ITripDashboardState> {
    public mapRef : Map;

    public centerPosition = new L.LatLng(-28.4792625, 24.6727135);
    public mapZoom = 6;

    constructor(props : ITripDashboardProps) {
        super(props);

        this.state = {
            isLoading: false,
            isSiteDetailPopupOpen: false,
            selectedFromDate: moment().local().startOf('day'),
            selectedToDate: moment().local().endOf('day'),
            filteredTrips: [],
            tripRoutes: [],
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }
        try {
            const res = await TripHttpService.getTripData();
            const res3 = await DispatchHttpService.getDispatchHeaderData();

            if (!isIndexedDBAvailable) {
                const res2 = await CarrierHttpService.getCarrierData();
                this.props.dataSetCarriers(res2.data);
            }

            this.props.dataSetTrips(res.data);
            this.props.dataSetDispatchInstructions(res3.data);
            this.setLoading(false);
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading sites.');
            this.setLoading(false);
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public setMapRef = (ref : Map) => {

        this.mapRef = ref;
    };

    private handleDateRangeChange = (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => {
        const selectedFromDate = changedBy === 'start' ? moment(start).startOf('day') : end < start ? moment(end).startOf('day') : moment(start).startOf('day');
        const selectedToDate = changedBy === 'end' ? moment(end).endOf('day') : start > end ? moment(start).endOf('day') : moment(end).endOf('day');
        this.setState({ selectedFromDate, selectedToDate });
    };

    private getSites = (props : ITripDashboardProps) => props.sites;
    private getSelectedOrganizationIds = (props : ITripDashboardProps) => props.selectedOrganizationIds;
    private getSelectedSites = (props : ITripDashboardProps) => props.selectedSiteIds;

    private getSitesLatLongs = createSelector(
        [this.getSites, this.getSelectedOrganizationIds, this.getSelectedSites],
        (sites : Array<ISite>, selectedOrganizationIds : Array<number>, selectedSiteIds : Array<number>) => {
            if (!sites) return [];
            return sites.filter(x => x.isActive
                && x.latitude
                && x.longitude
                && x.organizationIds.some(y => selectedOrganizationIds?.some(z => z === y))
                && selectedSiteIds?.some(y => y === x.id)).map((x) => {
                return { site: x, position: new L.LatLng(Number(x.latitude), Number(x.longitude)) };
            });
        },
    );

    private filterLines = () => {
        this.setLoading(true);
        const trips = this.props.trips;

        const filteredTrips = trips.filter(x => x.isActive && (moment(x.loadDate, DATEPICKER_FORMAT_DEFAULT).format(DATEPICKER_FORMAT_DEFAULT) >= this.state.selectedFromDate.format(DATEPICKER_FORMAT_DEFAULT) && moment(x.loadDate, DATEPICKER_FORMAT_DEFAULT).format(DATEPICKER_FORMAT_DEFAULT) <= this.state.selectedToDate.format(DATEPICKER_FORMAT_DEFAULT)));

        this.setState({ filteredTrips });

        let origin = new google.maps.LatLng(-23.591645, 30.099205);
        let destination = new google.maps.LatLng(-23.591645, 30.099205);

        let sourceAndDestinationSites : Array<{
            origin : google.maps.LatLng;
            destination : google.maps.LatLng;
            sourceSite : ISite;
            destinationSite : ISite;
            tripId : number;
        }> = [];

        this.props.dispatches.filter(x => x.isActive && filteredTrips.some(y => y.id === x.tripId)).forEach((x) => {
            origin = new google.maps.LatLng(Number(this.props.sites.find(z => z.id === x.sourceSiteId)?.latitude), Number(this.props.sites.find(z => z.id === x.sourceSiteId)?.longitude));
            destination = new google.maps.LatLng(Number(this.props.sites.find(z => z.id === x.destinationSiteId)?.latitude), Number(this.props.sites.find(z => z.id === x.destinationSiteId)?.longitude));
            const startSite = this.props.sites.find(z => z.id === x.sourceSiteId);
            const endSite = this.props.sites.find(z => z.id === x.destinationSiteId);

            sourceAndDestinationSites = addArrayElement(sourceAndDestinationSites, { origin, destination, tripId: x.tripId ?? 0, sourceSite: startSite ?? {} as ISite, destinationSite: endSite ?? {} as ISite });
        });

        sourceAndDestinationSites.forEach(async (x) => {
            new google.maps.DirectionsService().route({
                origin: x.origin,
                destination: x.destination,
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
            }, async (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    const tripRoute = {
                        tripId: x.tripId,
                        tripDirection: result ?? undefined,
                        startLocation: origin,
                        endLocation: destination,
                        sourceSite: x.sourceSite,
                        destinationSite: x.destinationSite,

                    };
                    this.setState({ tripRoutes: addArrayElement(this.state.tripRoutes, tripRoute) });
                }
            });
        });

        this.setLoading(false);
    };

    private onTripsClear = () => {
        this.setState({ tripRoutes: [], selectedFromDate: moment().local().startOf('day'), selectedToDate: moment().local().endOf('day') });
    };

    public render() {
        return (
            <Screen isLoading={this.state.isLoading} isScrollable={false}>
                <div className={'fdr jcsb aic'}>
                    <PillButton
                        className={'pl10 pr10 mr10 h35 w200 reducedPillButtonShadow'}
                        text={'Clear Trips'}
                        color={'secondary'}
                        onClick={() => this.onTripsClear()}
                    />
                    <TransactionFilter className={'mt10 pt10 mr15 mb10'} selectedFromDate={this.state.selectedFromDate} selectedToDate={this.state.selectedToDate} handleDateRangeChange={this.handleDateRangeChange} onApplyClick={this.filterLines} />
                </div>
                <div className='PaperBorder p5 hfill wfill asc fdc aic jcc oln posr'>
                    <TripDashboardGoogleMapComponent
                        mapCenter={this.centerPosition}
                        directions={this.state.tripRoutes}
                        sites={this.getSitesLatLongs(this.props)}
                        containerElement={<div className={'p10 hfill wfill'}/>}
                        mapElement={<div className={'p10 wfill hfill'}/>}
                    />
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        sites: state.masterData.sites,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
        organizations: state.masterData.organizations,
        trips: state.data.trips,
        carriers: state.masterData.carriers,
        dispatches: state.data.dispatchInstructions,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetTrips, dataSetCarriers, dataSetDispatchInstructions }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TripDashboard);

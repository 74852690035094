import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { ILot } from '../../../@types/model/lot/lot';
import { ILotEdit } from '../../../@types/model/lot/lotEdit';
import { ILotRelatedData } from '../../../@types/model/lot/lotRelatedData';
import { ILotTransfer } from '../../../@types/model/lot/lotTransfer';
import { ILotLine } from '../../../@types/model/lot/lotLine';
import { ILotWaste } from '../../../@types/model/lot/lotWaste';
import { ILotCorrection } from '../../../@types/model/lot/lotCorrection';
import qs from 'qs';
import { ILotLineEdit } from '../../../@types/model/lot/lotLineEdit';
import { ILotViewRelatedData } from '../../../@types/model/lot/lotViewRelatedData';
import { ILotView } from '../../../@types/model/lot/lotView';
import { ILotSummaryRelatedData } from '../../../@types/model/lot/lotSummaryRelatedData';
import { ICreateLot } from '../../../@types/model/lot/createLot';
import { ILotImportRelatedData } from '../../../@types/model/lot/lotImportRelatedData';
import { IBarcodeTraceTableRow } from '../../../@types/model/lot/barcodeTraceTableRow';

export default class LotHttpService {
    public static getLotData() : AxiosPromise<Array<ILot>> {
        return axios.get(`${PACKMAN_API_URL}Lot`);
    }

    public static getLotImportRelatedData(loadMasterData ?: boolean) : AxiosPromise<ILotImportRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Lot/GetImportRelatedData`, {
            params: {
                loadMasterData,
            },
        });
    }

    public static getLot(lotId : number) : AxiosPromise<ILot> {
        return axios.get(`${PACKMAN_API_URL}Lot/GetLot`, {
            params: {
                lotId,
            },
        });
    }

    public static getLotViewById(lotId : number) : AxiosPromise<ILotView> {
        return axios.get(`${PACKMAN_API_URL}Lot/GetLotViewById`, {
            params: {
                lotId,
            },
        });
    }

    public static getLotSummaryRelatedData(lotId : number, loadMasterData ?: boolean) : AxiosPromise<ILotSummaryRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Lot/GetSummaryRelatedData`, {
            params: {
                lotId,
                loadMasterData,
            },
        });
    }

    public static traceUnitGuid(unitGuid : string) : AxiosPromise<Array<IBarcodeTraceTableRow>> {
        return axios.get(`${PACKMAN_API_URL}Lot/TraceUnitGuid`, {
            params: {
                unitGuid,
            },
        });
    }

    public static getLotCorrectionData(siteIds ?: Array<number>) : AxiosPromise<Array<ILot>> {
        return axios.get(`${PACKMAN_API_URL}Lot/GetLotCorrectionData`, {
            params: {
                siteIds,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getLotTransferRelatedData(lotId ?: number, organizationIds ?: Array<number>, siteIds ?: Array<number>) : AxiosPromise<Array<ILot>> {
        return axios.get(`${PACKMAN_API_URL}Lot/GetLotTransferRelatedData`, {
            params: {
                lotId,
                organizationIds,
                siteIds,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getLotsAvailableToFinalize(siteId : number) : AxiosPromise<Array<ILotView>> {
        return axios.get(`${PACKMAN_API_URL}Lot/GetAvailableLotsToFinalize`, {
            params: {
                siteId,
            },
        });
    }

    public static editLot(data : ILotEdit) : AxiosPromise<ILot> {
        return axios.post(`${PACKMAN_API_URL}Lot/EditLotWeb`, data);
    }

    public static doLotTransfer(data : ILotTransfer) : AxiosPromise<Array<ILotView>> {
        return axios.post(`${PACKMAN_API_URL}Lot/TransferUnitsWeb`, data);
    }

    public static sendLotToWaste(data : ILotWaste) : AxiosPromise<ILotLine> {
        return axios.post(`${PACKMAN_API_URL}Lot/TipToWasteWebTransaction`, data);
    }

    public static doLotAdjustment(lotId : number) : AxiosPromise<Array<ILot>> {
        return axios.post(`${PACKMAN_API_URL}Lot/DoAdjustmentWeb?lotId=` + lotId);
    }

    public static doMultipleLotAdjustments(lotIds : Array<number>) : AxiosPromise<Array<ILotView>> {
        return axios.post(`${PACKMAN_API_URL}Lot/FinalizeMultipleLots`, lotIds);
    }

    public static upsertNewLots(data : Array<ICreateLot>) : AxiosPromise<Array<ILotView>> {
        return axios.post(`${PACKMAN_API_URL}Lot/UpsertWebNewLots`, data);
    }

    // TODO: Make a model for this
    public static changeLotLineWeight(data : { lotLineId : number; newGrossWeight : number }) : AxiosPromise<Array<ILot>> {
        return axios.post(`${PACKMAN_API_URL}LotLine/ChangeLotLineWeight`, data);
    }

    public static editLotLine(data : ILotLineEdit) : AxiosPromise<Array<ILot>> {
        return axios.post(`${PACKMAN_API_URL}LotLine/EditLotLine`, data);
    }

    public static editBatchLotLine(data : ILotLineEdit) : AxiosPromise<Array<ILot>> {
        return axios.post(`${PACKMAN_API_URL}LotLine/EditBatchLotLine`, data);
    }

    public static revertLotLines(lines : Array<number>) : AxiosPromise<Array<ILot>> {
        return axios.post(`${PACKMAN_API_URL}LotLine/RevertLotLinesWebTransaction`, lines.map((id) => {
            return { id };
        }));
    }

    public static getAllLotRelatedData(loadMasterData ?: boolean) : AxiosPromise<ILotRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Lot/AllLotRelatedData`, {
            params : { loadMasterData },
        });
    }

    public static getLotLineLotData(lotLineId : number) : AxiosPromise<ILot> {
        return axios.get(`${PACKMAN_API_URL}Lot/GetLotLineLot`, {
            params : { lotLineId },
        });
    }

    public static getLotTransactionData(fromDateUnix ?: number, toDateUnix ?: number, siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<ILotRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Lot/WebTransactions`, {
            params: {
                fromDateUnix,
                toDateUnix,
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getLotTransactionViewRelatedData(fromDateUnix ?: number, toDateUnix ?: number, siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<ILotViewRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Lot/WebTransactionViews`, {
            params: {
                fromDateUnix,
                toDateUnix,
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static lotCorrection(data : ILotCorrection) : AxiosPromise<Array<ILotView>> {
        return axios.post(`${PACKMAN_API_URL}Lot/LotCorrectionWeb`, data);
    }

    public static deleteLot(lotId : number, deleteReason : string) : AxiosPromise<ILotView> {
        return axios.delete(`${PACKMAN_API_URL}Lot/Delete`, {
            params : {
                lotId,
                deleteReason,
            },
        });
    }
}

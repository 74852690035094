import { Icon } from '@mui/material';
import React from 'react';

interface IPopupOptionButtonProps {
    text : string;
    onClick : () => void;
    icon ?: React.ReactNode;
    disabled ?: boolean;
    isImage ?: boolean;
    imgSource ?: string;
    widthClass ?: string;
}

export default class PopupOptionButton extends React.PureComponent<IPopupOptionButtonProps> {
    constructor(props : IPopupOptionButtonProps) {
        super(props);
    }

    public render() {
        const { text, onClick, icon, disabled, isImage, imgSource } = this.props;
        return (
            <div className={`${this.props.widthClass ?? 'w300'} ${!disabled ? ' ButtonMainDiv curp bcs' : 'bcg3 ButtonMainDivDisabled'} aic mb15 fdr jcsb `} onClick={!disabled ? onClick : undefined}>
                <div className={`ButtonSecondary${disabled ? 'Disabled' : ''}Div aic jcc fdr ml5`}>
                    {isImage && imgSource ?
                        <img className={`${disabled ? 'cpd' : 'cs'}`} height={20} width={20} src={imgSource} />
                        :
                        <Icon className={`${disabled ? 'cpd' : 'cs'}`}>{icon}</Icon>
                    }
                </div>
                <div className={'cpd fw500 jcc aic pr10'}>{text}</div>
                <div className={'w10'}/>
            </div>
        );
    }
}

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IRegion } from '../../../@types/model/masterData/region/region';

export default class RegionHttpService {
    public static getRegionData() : AxiosPromise<Array<IRegion>> {
        return axios.get(`${PACKMAN_API_URL}Region/GetAllWeb`);
    }

    public static addOrUpdateRegion(data : IRegion) : AxiosPromise<IRegion> {
        return axios.post(`${PACKMAN_API_URL}Region/PostRegion`, data);
    }

    public static deleteRegion(regionId : number) : AxiosPromise<IRegion> {
        return axios.delete(`${PACKMAN_API_URL}Region/Delete`, {
            params : { regionId },
        });
    }
}

import React from 'react';
import Dropzone from 'react-dropzone';
import Typography from '@mui/material/Typography';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Icon, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface IFileSelectorProps {
    files : Array<File>;
    onFileSelected ?: (files : Array<File>) => void;
    onFileRemoved ?: (file : File) => void;
    disabled ?: boolean;
    accept : string;
    className ?: string;
}

interface IFileSelectorState {
}

export default class FileSelector extends React.Component<IFileSelectorProps, IFileSelectorState> {
    constructor(props : IFileSelectorProps) {
        super(props);
        this.state = {};
    }

    private onFileDrop = (acceptedFiles : Array<File>) => {
        const files = acceptedFiles;
        const disabled = this.props.disabled;

        if (!!this.props.onFileSelected && !disabled) {
            this.props.onFileSelected(files);
        }
    };

    private onRemoveFile = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        const file = this.props.files.slice().find(x => x.name === event.currentTarget.value);

        if (!!file && !!this.props.onFileRemoved) {
            this.props.onFileRemoved(file);
        }
    };

    public render = () => {
        const { disabled, accept, files, className } = this.props;
        return (
            <div className={`fdc bw1 hfill ${className}`}>
                <Dropzone onDrop={this.onFileDrop} accept={accept}>
                    {({ getRootProps, getInputProps }) => (
                        <section className={'fdc hfill wfill'}>
                            <div className={'fdc hfill wfill'}>
                                {
                                    <div className={'fdc hfill p20 bw1d wfill'} {...getRootProps()}>
                                        <div className={'fdc flx1 hfill oya wfill'}>
                                            {
                                                files.map(n => (
                                                    <div key={n.name} className={'fdr aic fww pr15'}>
                                                        <InsertDriveFileIcon className='cp pr5' />
                                                        <Typography style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }} className={'flx1 oxh'} variant={'body2'}>
                                                            {n.name}
                                                        </Typography>
                                                        <IconButton size='small' value={n.name} onClick={this.onRemoveFile}>
                                                            <DeleteIcon className='cpr' />
                                                        </IconButton>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <input disabled={disabled} multiple={false} {...getInputProps()} />
                                        <div className={'fdc flx1 aic wfill'}>
                                            <Icon fontSize={'large'} className={`fw500 ${disabled ? 'cgray2' : 'cg1 curp'}`}>publish</Icon>
                                            <Typography className={`fdr fs18 fw500 ${disabled ? 'cgray2' : 'cg1 curp'}`} variant={'body2'}>
                                                Drag &amp; Drop or Browse
                                            </Typography>
                                        </div>
                                    </div>
                                }
                            </div>
                        </section>
                    )}
                </Dropzone>
            </div>
        );
    };
}

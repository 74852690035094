import * as React from 'react';
import { RootAction, IRootState } from '../../@types/redux';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Dialog, List, ListItem, ListItemText, IconButton, Icon } from '@mui/material';
import { formatDateTimeToDateOnly, booleanToYesNo, uppercase } from '../../services/appFunctionsService';
import CustomTable from '../../components/table/CustomTable';
import { IOrderHeader } from '../../@types/model/order/orderHeader';
import { IStock } from '../../@types/model/stock/stock';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ISite } from '../../@types/model/masterData/site/site';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { ISize } from '../../@types/model/masterData/size/size';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { IOrderRequest } from '../../@types/model/order/orderRequest';
import { IOrderPlan } from '../../@types/model/order/orderPlan';
import { IOpenOrder } from '../../@types/model/order/openOrder';

interface IOrderViewerProps {
    open : boolean;
    orderHeaders : Array<IOrderHeader>;
    currentStockValues : IStock;
    organizations : Array<IOrganization>;
    sites : Array<ISite>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    sizes : Array<ISize>;
    grades : Array<IGrade>;
    colours : Array<IColour>;
    onClose : () => void;
    onSubmit : (orderId : number) => void;
}

interface IOrderViewerState {
    selectedOrder : number;
}

class OrderViewer extends React.Component<IOrderViewerProps, IOrderViewerState> {
    constructor(props : IOrderViewerProps) {
        super(props);

        this.state = {
            selectedOrder: 0,
        };
    }

    private sameFruitSpec = (orderRequest : IOrderRequest, orderPlan : IOrderPlan) => {
        return orderRequest.commodityId === orderPlan.commodityId &&
            orderRequest.varietyId === orderPlan.varietyId &&
            orderRequest.packId === orderPlan.packId &&
            orderRequest.sizeId === orderPlan.sizeId &&
            orderRequest.gradeId === orderPlan.gradeId &&
            orderRequest.colourId === orderPlan.colourId;
    };

    private setSelectedOrder = (i : number) => {
        this.setState({ selectedOrder: i });
    };

    private getOrderData = () => {
        const stockItem = this.props.currentStockValues;
        const returnValue : Array<IOpenOrder> = [];

        const validOrders = this.props.orderHeaders.filter(x => x.isActive && x.supplierId === stockItem.originalOrganizationId && formatDateTimeToDateOnly(x.orderDate) === formatDateTimeToDateOnly(stockItem.packDate)
            && x.orderRequestLines.some(y => y.isActive && y.orderPlannedLines.some(z => z.isActive && z.siteSourceId === stockItem.currentSiteId)));

        validOrders.forEach(header => header.orderRequestLines.forEach(request => request.orderPlannedLines
            .filter(z => z.isActive && z.siteSourceId === stockItem.currentSiteId).forEach((plan) => {
                returnValue.push({
                    orderHeaderId: header.id,
                    orderRequestId: request.id,
                    orderPlanId: header.id,
                    orderNumber: header.orderNumber,
                    orderDate: header.orderDate,
                    clientId: header.clientId,
                    supplierId: header.supplierId,
                    status: header.status,
                    comment: header.comment,
                    siteSourceId: plan.siteSourceId,
                    siteDestinationId: plan.siteDestinationId,
                    commodityId: plan.commodityId,
                    varietyId: plan.varietyId,
                    packId: plan.packId,
                    sizeId: plan.sizeId,
                    gradeId: plan.gradeId,
                    colourId: plan.colourId,
                    requestedQuantity: this.sameFruitSpec(request, plan) ? request.quantity : 0,
                    plannedQuantity: plan.quantity,
                    actualQuantity: 0, // TODO: orders at stock?
                    isNew: plan.isNew,
                });
            })));
        return returnValue;
    };

    private uniqueOrderIds = () => {
        const orderData = this.getOrderData();
        const returnValue : Array<number> = [];
        orderData.forEach((x) => {
            if (!returnValue.find(y => x.orderHeaderId === y)) {
                returnValue.push(x.orderHeaderId);
            }
        });
        return returnValue;
    };

    private getCommodityName = (commId : number) => {
        if (commId === 0) {
            return '';
        }
        const comm = this.props.commodities.find(x => x.id === commId);
        return comm && comm.name ? uppercase(comm.name) : 'UNKNOWN VALUE';
    };

    private getVarietyName = (varId : number) => {
        if (varId === 0) {
            return '';
        }
        const variety = this.props.varieties.find(x => x.id === varId);
        return variety && variety.name ? uppercase(variety.name) : 'UNKNOWN VALUE';
    };

    private getPackDescription = (packId : number) => {
        if (packId === 0) {
            return '';
        }
        const pack = this.props.packs.find(x => x.id === packId);
        return pack && pack.description ? uppercase(pack.description) : 'UNKNOWN VALUE';
    };

    private getSizeName = (sizeId : number) => {
        if (sizeId === 0) {
            return '';
        }
        const size = this.props.sizes.find(x => x.id === sizeId);
        return size && size.name ? uppercase(size.name) : 'UNKNOWN VALUE';
    };

    private getGradeName = (gradeId : number) => {
        if (gradeId === 0) {
            return '';
        }
        const grade = this.props.grades.find(x => x.id === gradeId);
        return grade && grade.name ? uppercase(grade.name) : 'UNKNOWN VALUE';
    };

    private getColourName = (colourId : number) => {
        if (colourId === 0) {
            return '';
        }
        const colour = this.props.colours.find(x => x.id === colourId);
        return colour && colour.name ? uppercase(colour.name) : 'UNKNOWN VALUE';
    };

    private getColumns = () => {
        return [
            { title: 'Commodity', field: 'commodityId', formatFunction: this.getCommodityName },
            { title: 'Variety', field: 'varietyId', formatFunction: this.getVarietyName },
            { title: 'Pack', field: 'packId', formatFunction: this.getPackDescription },
            { title: 'Size', field: 'sizeId', formatFunction: this.getSizeName },
            { title: 'Grade', field: 'gradeId', formatFunction: this.getGradeName },
            { title: 'Colour', field: 'colourId', formatFunction: this.getColourName },
            { title: 'Requested Qty', field: 'requestedQuantity' },
            { title: 'Planned Qty', field: 'plannedQuantity' },
            { title: 'Actual Qty', field: 'actualQuantity' },
            { title: 'Status', field: 'status' },
            { title: 'Comment', field: 'comment' },
            { title: 'New?', field: 'isNew', formatFunction: booleanToYesNo, type: 'boolean' },
        ];
    };

    private submitOrderSelection = () => this.props.onSubmit(this.state.selectedOrder);

    private close = () => this.props.onClose();

    private getOrderNumber = (orderId : number) => {
        const order = this.props.orderHeaders.find(x => x.id === orderId);
        return order && order.orderNumber;
    };

    public render() {
        const orderData = this.getOrderData();
        const uniqueOrderIds = this.uniqueOrderIds();
        const selectedOrderData = orderData.filter(x => x.orderHeaderId === this.state.selectedOrder);
        return <Dialog open={this.props.open} maxWidth={'lg'}>
            <div className={'wfill fdc h400'}>
                <div className={'bcpd h40 wfill  cw jcc aic'}>
                    Select Order
                    <div className={'posa wfill fdr'}>
                        <div className={'flx1'} />
                        <IconButton className={'cw hfill'} onClick={this.submitOrderSelection}>
                            <Icon>done</Icon>
                        </IconButton>
                        <IconButton className={'cw hfill'} onClick={this.close}>
                            <Icon>close</Icon>
                        </IconButton>

                    </div>
                </div>
                <div className={'fdr flx1'}>
                    <List component='nav' className={'mnw200'}>
                        { uniqueOrderIds.map((x) => {
                            return <ListItem key={'orderViewer_list_' + x} selected={this.state.selectedOrder === x} button onClick={() => this.setSelectedOrder(x)}>
                                <ListItemText primary={this.getOrderNumber(x)} />
                            </ListItem>;
                        })
                        }
                    </List>
                    <div className={'fdc'}>
                        <CustomTable<IOpenOrder>
                            rows={selectedOrderData}
                            columns={this.getColumns()}
                        />
                    </div>
                </div>
            </div>
        </Dialog>;
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        orderHeaders: state.data.orderHeaders,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        sizes: state.masterData.sizes,
        grades: state.masterData.grades,
        colours: state.masterData.colours,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    {},
    dispatcher,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OrderViewer);

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { ICarrier } from '../../../@types/model/masterData/carrier/carrier';

export default class CarrierHttpService {
    public static getCarrierData() : AxiosPromise<Array<ICarrier>> {
        return axios.get(`${PACKMAN_API_URL}Carrier/GetAllWeb`);
    }

    public static addOrUpdateCarrier(data : ICarrier) : AxiosPromise<ICarrier> {
        return axios.post(`${PACKMAN_API_URL}Carrier/PostCarrier`, data);
    }

    public static deleteCarrier(carrierId : number) : AxiosPromise<ICarrier> {
        return axios.delete(`${PACKMAN_API_URL}Carrier/Delete`, {
            params : { carrierId },
        });
    }
}

import * as React from 'react';
import { Typography, IconButton, Icon, Button } from '@mui/material';
import { IRootState, IAuthState } from '../../@types/redux';
import { connect } from 'react-redux';
import CustomTextField from '../../components/input/CustomTextField';
import { Form } from 'informed';
import { isEmptyObject } from '../../services/appFunctionsService';
import { generalShowErrorSnackbar } from '../../store/general/Functions';
import CustomAutoSuggest from '../../components/input/CustomAutoSuggest';
import { IFormApi } from '../../@types/other';
import CustomCheckbox from '../../components/input/CustomCheckbox';
import validationFunctions from '../../services/validationService';
import uuidv1 from 'uuid';
import { IStockLine } from '../../@types/model/stock/stockLine';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { IStock } from '../../@types/model/stock/stock';
import { ISite } from '../../@types/model/masterData/site/site';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { IMarket } from '../../@types/model/masterData/market/market';
import { IRegion } from '../../@types/model/masterData/region/region';
import { ICountry } from '../../@types/model/masterData/country/country';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import { IBatch } from '../../@types/model/batch/batch';
import { IPalletBaseType } from '../../@types/model/masterData/palletBaseType/palletBaseType';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { ISize } from '../../@types/model/masterData/size/size';
import moment from 'moment';
import { IProject } from '../../@types/model/masterData/project/project';

interface IStockLineFormProps {
    selectedStockLine ?: IStockLine;
    selectedOrganization ?: number;
    selectedSite ?: number;
    organizations : Array<IOrganization>;
    stocks : Array<IStock>;
    sites : Array<ISite>;
    farms : Array<IFarm>;
    markets : Array<IMarket>;
    regions : Array<IRegion>;
    countries : Array<ICountry>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    grades : Array<IGrade>;
    colours : Array<IColour>;
    orchards : Array<IOrchard>;
    sizes : Array<ISize>;
    batches : Array<IBatch>;
    projects : Array<IProject>;
    palletBaseTypes : Array<IPalletBaseType>;
    isAdding ?: boolean;
    onClose : () => void;
    isEditing ?: boolean;
    selectedStock : number;
    currentStockLines : Array<IStockLine>;
    currentMarketId ?: number;
    addStockLine : (stockline : IStockLine) => void;
    auth : IAuthState;
    isClearingStockLines : boolean;
    stockLinesCleared : () => void;
    clearEditStockLine : () => void;

    setSelectedCommodityId : (commodityId : number) => void;
    selectedCommodityId : number;

}
interface IStockLineFormState {
    initialLoadingOfStockLine : boolean;
    selectedBatch ?: IBatch;
    selectedCommodity : number;
    selectedVariety ?: number;
    selectedFarm ?: number;
    selectedPack ?: IPack;
    selectedSize ?: number;
    selectedGrade ?: number;
    selectedOrchard ?: number;
    clearCommodities : boolean;
    clearVarieties : boolean;
    clearFarms : boolean;
    clearOrchards : boolean;
    clearProjects : boolean;
    clearGrades : boolean;
    clearColours : boolean;
    newCommodityValue ?: number;
    newVarietyValue ?: number;
    newFarmValue ?: number;
    newOrchardValue ?: number;
    newProjectValue ?: number;
    newGradeValue ?: number;
    newColourValue ?: number;
    noCartonsSelected : number;
    clearBatch : boolean;
    totalInners ?: number;
    isOpening : boolean;
    isSelectedCommodityPhytoCompliant : boolean;
}

class StockLineForm extends React.Component<IStockLineFormProps, IStockLineFormState> {
    private formApi : IFormApi<IStockLine>;
    private batchRef : React.RefObject<HTMLInputElement>;
    private orchardRef : React.RefObject<HTMLInputElement>;
    constructor(props : IStockLineFormProps) {
        super(props);

        this.state = {
            initialLoadingOfStockLine: true,
            selectedBatch: undefined,
            selectedCommodity: 0,
            clearBatch: false,
            clearCommodities: false,
            clearVarieties: false,
            clearFarms: false,
            clearOrchards: false,
            clearProjects: false,
            clearGrades: false,
            clearColours: false,
            noCartonsSelected: 0,
            isOpening: true,
            isSelectedCommodityPhytoCompliant: false,
        };

        this.batchRef = React.createRef();
        this.orchardRef = React.createRef();

    }

    public componentDidMount = () => {
        if (this.props.selectedStockLine) {
            this.formApi.setValues(this.props.selectedStockLine);
            this.formApi.setValue('commodityId', this.props.selectedCommodityId);
            this.setState({
                initialLoadingOfStockLine: true,
                selectedCommodity: this.props.selectedStockLine.commodityId ? this.props.selectedStockLine.commodityId : this.props.selectedCommodityId,
                selectedPack: this.props.selectedStockLine.pack ? this.props.selectedStockLine.pack : undefined,
                noCartonsSelected: this.props.selectedStockLine?.cartons ?? 0,
                totalInners: this.props.selectedStockLine?.totalInners ?? 0,
            });
        }
    };

    public componentDidUpdate = (prevProps : IStockLineFormProps, prevState : IStockLineFormState) => {
        const nextProps = this.props;
        const nextState = this.state;
        if (nextProps && nextProps.selectedStockLine && prevProps && prevProps.selectedStockLine && this.formApi) {
            if (JSON.stringify({ ...nextProps.selectedStockLine }) !== JSON.stringify({ ...prevProps.selectedStockLine })) {
                this.formApi.setValues(nextProps.selectedStockLine);
            }
        }

        if (nextProps.isClearingStockLines && !prevProps.isClearingStockLines) {
            this.clearStockLineInputFields();
            this.props.stockLinesCleared();
        }

        if (prevState && nextState && prevState.selectedCommodity !== nextState.selectedCommodity) {
            const commodity = nextProps.commodities.find(x => x.id === nextState.selectedCommodity);

            if (commodity && commodity.isPhytoCompliant) {
                this.setState({ isSelectedCommodityPhytoCompliant: true });
            } else {
                this.setState({ isSelectedCommodityPhytoCompliant: false });
            }
        }
    };

    private isLocalPallet = (marketId ?: number) => {
        if (!this.props.markets) {
            return false;
        }
        const market = this.props.markets.find(x => x.id === marketId);
        return market && market.channel !== 'E' ? true : false;
    };

    private disableCommAndVar = () => {
        const firstStockLine = this.props.currentStockLines[0];
        return !this.isLocalPallet(this.props.currentMarketId) && !!firstStockLine && !isEmptyObject(firstStockLine);
    };

    private getSelectedStockLine = (props : IStockLineFormProps) => props.selectedStockLine;
    private getBatches = (props : IStockLineFormProps) => props.batches;
    private getCommodities = (props : IStockLineFormProps) => props.commodities;
    private getVarieties = (props : IStockLineFormProps) => props.varieties;
    private getFarms = (props : IStockLineFormProps) => props.farms;
    private getOrchards = (props : IStockLineFormProps) => props.orchards;
    private getPacks = (props : IStockLineFormProps) => props.packs;
    private getSizes = (props : IStockLineFormProps) => props.sizes;
    private getGrades = (props : IStockLineFormProps) => props.grades;
    private getColours = (props : IStockLineFormProps) => props.colours;
    private getProjects = (props : IStockLineFormProps) => props.projects;

    private getSelectedOrganizationIds = (props : IStockLineFormProps) => props.selectedOrganization;
    private getSelectedSites = (props : IStockLineFormProps) => props.selectedSite;
    private getSelectedBatch = (props : IStockLineFormProps, state : IStockLineFormState) => state.selectedBatch;
    private getSelectedCommodity = (props : IStockLineFormProps, state : IStockLineFormState) => state.selectedCommodity;
    private getSelectedFarm = (props : IStockLineFormProps, state : IStockLineFormState) => state.selectedFarm;
    private getSelectedPack = (props : IStockLineFormProps, state : IStockLineFormState) => state.selectedPack;
    private getSelectedOrchard = (props : IStockLineFormProps, state : IStockLineFormState) => state.selectedOrchard;

    private getCurrentMarket = (props : IStockLineFormProps) => props.currentMarketId;
    private getCurrentStockLines = (props : IStockLineFormProps) => props.currentStockLines;

    private getClearBatch = (props : IStockLineFormProps, state : IStockLineFormState) => state.clearBatch;
    private getClearCommodities = (props : IStockLineFormProps, state : IStockLineFormState) => state.clearCommodities;
    private getClearVarieties = (props : IStockLineFormProps, state : IStockLineFormState) => state.clearBatch;
    private getClearFarms = (props : IStockLineFormProps, state : IStockLineFormState) => state.clearFarms;
    private getClearOrchards = (props : IStockLineFormProps, state : IStockLineFormState) => state.clearOrchards;
    private getClearGrades = (props : IStockLineFormProps, state : IStockLineFormState) => state.clearGrades;
    private getClearColours = (props : IStockLineFormProps, state : IStockLineFormState) => state.clearColours;
    private getClearProjects = (props : IStockLineFormProps, state : IStockLineFormState) => state.clearProjects;

    private getNewProjectValue = (props : IStockLineFormProps, state : IStockLineFormState) => state.newProjectValue;

    private batchOptions = createSelector(
        [this.getBatches, this.getClearBatch, this.getSelectedOrganizationIds, this.getSelectedSites],
        (batches : Array<IBatch>, clearBatch : boolean, selectedOrganization : number, selectedSite : number) => {
            if (!batches) return [];

            if (clearBatch) {
                this.setState({
                    clearBatch: false,
                });
                return [];
            }

            return lodash.filter(batches, x => x.isActive
                && this.getEndedBool(x.endedOn?.toString()) === 'No'
                && x.organizationId === selectedOrganization
                && (x.siteId === selectedSite)).sort((a : IBatch, b : IBatch) => moment(b.createdOn).diff(moment(a.createdOn))).map((x : IBatch) => {
                return { value: x.id, label: x.batchCode };
            });
        },
    );

    private getEndedBool = (endedOn ?: string) => !!endedOn ? 'Yes' : 'No';

    private getCommodityOptions = createSelector(
        [this.getCommodities, this.getSelectedOrganizationIds, this.getSelectedBatch, this.getClearCommodities, this.getCurrentMarket, this.getCurrentStockLines],
        (commodities : Array<ICommodity>, selectedOrganization : number, selectedBatch : IBatch, clearCommodities : boolean, currentMarketId : number, currentStockLines : Array<IStockLine>) => {
            if (!commodities) return [];

            if (clearCommodities) {
                this.setState({
                    newCommodityValue: undefined,
                    clearCommodities: false,
                });
                return [];
            }

            const firstStockLine = currentStockLines[0];
            const stockLineCount = this.props.currentStockLines.length;
            const isLocal = this.isLocalPallet(currentMarketId);
            if (firstStockLine && !isLocal && stockLineCount >= 1) {
                return commodities.filter(x => x.id === firstStockLine.commodityId).map((x) => {
                    return { value: x.id, label: `(${x.code}) ${x.name}` };
                });
            }

            return commodities.filter(x => x.organizationIds?.some(y => y === selectedOrganization)
                                        && (selectedBatch !== undefined ? (selectedBatch.commodityIds.length !== 1 ? (selectedBatch.commodityIds.length > 1 ? selectedBatch?.commodityIds.some(a => a === x.id) : true) : selectedBatch?.commodityIds.some(a => a === x.id)) : true)
                                        && x.isActive).map((x) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            });
        },
    );

    private getVarietyOptions = createSelector(
        [this.getVarieties, this.getSelectedBatch, this.getClearVarieties, this.getSelectedOrganizationIds, this.getSelectedCommodity, this.getCurrentMarket],
        (varieties : Array<IVariety>, selectedBatch : IBatch, clearVarieties : boolean, selectedOrganization : number, selectedCommodity : number, currentMarketId : number) => {
            if (!varieties || !selectedOrganization || !selectedCommodity || !currentMarketId) return [];

            if (clearVarieties) {
                this.setState({
                    selectedVariety: 0,
                    clearVarieties: false,
                });
                return [];
            }

            const firstStockLine = this.props.currentStockLines[0];
            const stockLineCount = this.props.currentStockLines.length;
            const isLocal = this.isLocalPallet(this.props.currentMarketId);
            if (firstStockLine && !isEmptyObject(firstStockLine) && !isLocal && stockLineCount >= 1) {
                return varieties.filter(x => x.id === firstStockLine.varietyId).map((x) => {
                    return { label: `(${x.code}) ${x.name}`, value: x.id };
                });
            }

            return varieties.filter(x => x.commodityId === selectedCommodity
                && (selectedBatch !== undefined ? (selectedBatch.varietyIds.length !== 1 ? (selectedBatch.varietyIds.length > 1 ? selectedBatch?.varietyIds.some(a => a === x.id) : true) :  selectedBatch?.varietyIds.some(y => y === x.id)) : true)
                && x.isActive).map((x) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            });
        },
    );

    private getFarmOptions = createSelector(
        [this.getFarms, this.getSelectedBatch, this.getClearFarms, this.getSelectedOrganizationIds],
        (farms : Array<IFarm>, selectedBatch : IBatch, clearFarms : boolean, selectedOrganization : number) => {
            if (!farms || !selectedOrganization) return [];

            if (clearFarms) {
                this.setState({
                    selectedFarm: 0,
                    newFarmValue: 0,
                    clearFarms: false,
                });
                return [];
            }

            return farms.filter(x => x.organizationIds?.some(y => y === selectedOrganization)
                                    && (selectedBatch !== undefined ? (selectedBatch.farmIds.length !== 1 ? (selectedBatch.farmIds.length > 1 ? selectedBatch?.farmIds.some(a => a === x.id) : true) : selectedBatch?.farmIds.some(a => a === x.id)) : true)
                                    && x.isActive).map((x) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            });
        },
    );

    private getOrchardOptions = createSelector(
        [this.getOrchards, this.getSelectedBatch, this.getClearOrchards, this.getSelectedOrganizationIds, this.getSelectedCommodity, this.getSelectedFarm, this.getSelectedOrchard],
        (orchards : Array<IOrchard>, selectedBatch : IBatch, clearOrchards : boolean, selectedOrganization : number, selectedCommodity : number, selectedFarm : number) => {
            if (!orchards || !selectedOrganization || !selectedCommodity || !selectedFarm) return [];

            if (clearOrchards) {
                this.setState({
                    selectedOrchard: 0,
                    clearOrchards: false,
                });
                return [];
            }

            return orchards.filter(x => x.commodityId === selectedCommodity
                && x.farmId === selectedFarm
                && (selectedBatch !== undefined ? (selectedBatch.orchardIds.length !== 1 ? (selectedBatch.orchardIds.length > 1 ? selectedBatch?.orchardIds.some(a => a === x.id) : true) : selectedBatch?.orchardIds.some(y => y === x.id)) : true) && x.isActive).map((x) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            });
        },
    );

    private getPackOptions = createSelector(
        [this.getPacks, this.getSelectedOrganizationIds, this.getSelectedCommodity],
        (packs : Array<IPack>, selectedOrganization : number, selectedCommodity : number) => {
            if (!packs || !selectedOrganization || !selectedCommodity) return [];

            return lodash.filter(packs, x => x.isActive && x.commodityId === selectedCommodity).map((x) => {
                return { label: `(${x.code}) ${x.description}`, value: x.id };
            });
        },
    );

    private getSizeOptions = createSelector(
        [this.getSizes, this.getSelectedPack, this.getSelectedOrganizationIds, this.getSelectedCommodity],
        (sizes : Array<ISize>, selectedPack : IPack, selectedOrganization : number, selectedCommodity : number) => {
            if (!sizes || !selectedPack || (selectedPack && !selectedPack.sizeIds) || !selectedOrganization || !selectedCommodity) return [];

            return lodash.filter(sizes, x => x.isActive && selectedPack.sizeIds.some(a => a === x.id)).map((x) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            });
        },
    );

    private getGradeOptions = createSelector(
        [this.getGrades, this.getSelectedOrganizationIds, this.getSelectedCommodity, this.getClearGrades],
        (grades : Array<IGrade>, selectedOrganization : number, selectedCommodity : number, clearGrades : boolean) => {
            if (!grades || !selectedOrganization || !selectedCommodity) return [];

            if (clearGrades) {
                this.setState({
                    newGradeValue: undefined,
                    clearGrades: false,
                });
                return [];
            }

            return lodash.filter(grades, x => x.isActive && x.commodityIds?.some(y => y === selectedCommodity)).map((x) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            }).sort((a, b) => {
                if (a.label > b.label) {
                    return 1;
                } else if (a.label < b.label) {
                    return -1;
                } else {
                    return 0;
                }
            });
        },
    );

    private getColourOptions = createSelector(
        [this.getColours, this.getSelectedOrganizationIds, this.getSelectedCommodity, this.getClearColours],
        (colours : Array<IColour>, selectedOrganization : number, selectedCommodity : number, clearColours : boolean) => {
            if (!colours || !selectedOrganization || !selectedCommodity) return [];

            if (clearColours) {
                this.setState({
                    newColourValue: undefined,
                    clearColours: false,
                });
                return [];
            }

            return lodash.filter(colours, x => x.isActive && x.commodityIds?.some(y => y === selectedCommodity)).map((x : IColour) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            });
        },
    );

    private getProjectOptions = createSelector(
        [this.getProjects, this.getSelectedOrganizationIds, this.getSelectedCommodity, this.getClearProjects],
        (projects : Array<IProject>, selectedOrganization : number, selectedCommodity : number, clearProjects : boolean) => {
            if (!projects || !selectedOrganization || !selectedCommodity) return [];

            if (clearProjects) {
                this.setState({
                    newProjectValue: undefined,
                    clearProjects: false,
                });
                return [];
            }

            return lodash.filter(projects, x => x.isActive && x.commodityId === selectedCommodity).map((x : IProject) => {
                return { label: x.number, value: x.id };
            });
        },
    );

    private onSelectedBatchChange = (selectedBatchId : any) => {
        const selectedBatch = this.props.batches.find(x => x.id === selectedBatchId);

        if (selectedBatch) {
            this.setState({ selectedBatch }, () => {
                if (selectedBatch.commodityIds?.length === 1) {
                    this.setState({ newCommodityValue: selectedBatch.commodityIds[0] });
                }
                if (selectedBatch.varietyIds?.length === 1) {
                    this.setState({ newVarietyValue: selectedBatch.varietyIds[0] });
                }
                if (selectedBatch.farmIds?.length === 1) {
                    this.setState({ newFarmValue: selectedBatch.farmIds[0] });
                }
                if (selectedBatch.orchardIds?.length === 1) {
                    this.setState({ newOrchardValue: selectedBatch.orchardIds[0] });
                }
            });
        } else {
            this.setState({
                selectedBatch: undefined,
                clearCommodities: true,
                clearVarieties: true,
                clearFarms: true,
                clearOrchards: true,
            });
        }
    };

    private onCommodityChange = (commodityId : number) => {
        const selectedCommodity = this.props.commodities.find(x => x.id === commodityId);
        const grades = this.props.grades.filter(x => x.commodityIds.some(y => y === commodityId) && x.isActive);
        const colours = this.props.colours.filter(x => x.commodityIds?.some(y => y === commodityId) && x.isActive);

        if (selectedCommodity) {
            this.setState({ selectedCommodity: selectedCommodity.id }, () => {
                if (grades.length === 1) {
                    const grade = grades[0];
                    this.setState({ newGradeValue: grade.id });
                }
                if (colours.length === 1) {
                    const colour = colours[0];
                    this.setState({ newColourValue: colour.id });
                }
            });
        } else {
            this.setState({
                clearGrades: true,
                clearColours: true,
            });
        }
    };

    private onSizeChange = (sizeId : any) => {
        const size = this.props.sizes.find(x => x.id === sizeId);

        this.setState({ selectedSize: size ? size.id : undefined });
    };

    private getCommodity = () => {
        const batchCommodity = this.props.commodities.find(x => this.state.selectedBatch?.commodityIds.some(y => y === x.id));

        return batchCommodity ? batchCommodity.id : 0;
    };

    private getVariety = () => {
        const batchVarieties = this.props.varieties.find(x => this.state.selectedBatch?.varietyIds.some(y => y === x.id));

        return batchVarieties ? batchVarieties.id : 0;
    };

    private getFarm = () => {
        const batchFarms = this.props.farms.find(x => this.state.selectedBatch?.farmIds.some(y => y === x.id));

        return batchFarms ? batchFarms.id : 0;
    };

    private getOrchard = () => {
        const batchOrchards = this.props.orchards.find(x => this.state.selectedBatch?.orchardIds.some(y => y === x.id));

        return batchOrchards ? batchOrchards.id : 0;
    };

    private getGrade = () => {
        const grades = this.props.grades.filter(x => x.commodityIds.some(y => y === this.state.selectedCommodity));
        const grade = grades[0];

        return grades && grades.length === 1 ? grade.id : 0;
    };

    private getColour = () => {
        const colours = this.props.colours.filter(x => x.commodityIds?.some(y => y === this.state.selectedCommodity));
        const colour = colours[0];

        return colours && colours.length === 1 ? colour.id : 0;
    };

    private submit = () => {
        const values = this.formApi.getState().values;
        const touched = this.formApi.getState().touched;
        const valid = !this.formApi.getState().invalid;

        const existingStockLine = this.props.selectedStockLine;

        let valuesTouched : boolean = false;

        if (isEmptyObject(touched)) {
            if (this.props.isEditing && this.props.selectedStockLine) {
                if (this.props.selectedStockLine.batchId !== values.batchId
                    || this.props.selectedStockLine.commodityId !== values.commodityId
                    || this.props.selectedStockLine.varietyId !== values.varietyId
                    || this.props.selectedStockLine.farmId !== values.farmId
                    || this.props.selectedStockLine.packId !== values.packId
                    || this.props.selectedStockLine.sizeId !== values.sizeId
                    || this.props.selectedStockLine.gradeId !== values.gradeId
                    || this.props.selectedStockLine.colourId !== values.colourId
                    || this.props.selectedStockLine.orchardId !== values.orchardId
                    || this.props.selectedStockLine.projectId !== values.projectId) {
                    valuesTouched = true;
                } else {
                    generalShowErrorSnackbar('Nothing to update!');
                    return;
                }
            } else if (this.props.isAdding) {
                generalShowErrorSnackbar('Nothing to add!');
                return;
            }
        } else {
            valuesTouched = true;
        }

        if (!valid) {
            generalShowErrorSnackbar('Check form validation!');
            return;
        }

        if (!!valuesTouched) {
            const newRowModel : IStockLine = { ...values };

            if (newRowModel.cartons <= 0) {
                generalShowErrorSnackbar('Stock line cartons cannot be negative or zero!');
                return;
            }
            const stockLinePack = this.props.packs.find(x => x.id === newRowModel.packId);
            if (newRowModel.isActive && stockLinePack?.hasInnerPack && (!newRowModel.totalInners || newRowModel.totalInners <= 0)) {
                generalShowErrorSnackbar('Inners cannot be negative or empty. Please type in cartons.');
                return;
            }

            if (this.props.isEditing && existingStockLine) {
                newRowModel.id = existingStockLine ? existingStockLine.id : 0;
                newRowModel.stockId = existingStockLine ? existingStockLine.stockId : 0;
                newRowModel.guid = existingStockLine ? existingStockLine.guid : uuidv1();
                newRowModel.totalInners = this.state.totalInners;
            }

            if (this.props.isAdding) {
                newRowModel.guid = uuidv1();
                newRowModel.id = 0;
                newRowModel.stockId = this.props.selectedStock ? this.props.selectedStock : 0;
                newRowModel.isActive = true;
                newRowModel.totalInners = this.state.totalInners;
            }

            this.batchRef.current?.focus();

            this.props.addStockLine(newRowModel);
            this.clearStockLineInputFields();
        }
    };

    private clearStockLineInputFields = () => {
        this.props.setSelectedCommodityId(this.state.selectedCommodity);
        if (this.props.isAdding) {
            this.setState({
                totalInners: undefined,
                noCartonsSelected: this.state.selectedPack?.noCartons ? this.state.selectedPack?.noCartons : 0,
            });
        } else {
            this.setState({
                selectedBatch: undefined,
                selectedCommodity: 0,
                selectedFarm: 0,
                selectedVariety: 0,
                selectedOrchard: undefined,
                selectedPack: undefined,
                clearCommodities: true,
                clearVarieties: true,
                clearFarms: true,
                clearBatch: true,
                totalInners: undefined,
                noCartonsSelected: 0,
            });
            this.formApi.reset();
        }
        this.formApi.validate();
        if (this.props.selectedStockLine) {
            this.props.clearEditStockLine();
        }
    };

    private setFormApi = (formApi : IFormApi<IStockLine>) => {
        this.formApi = formApi;
    };

    private setSelectedPack = (packId : number) => {
        const pack = this.props.packs.find(x => x.id === packId);
        if (pack) {
            this.setState({ selectedPack: pack }, () => {
                const opening = this.state.isOpening;
                if (this.props.isEditing && this.state.isOpening) {
                    this.setState({ isOpening: !opening });
                } else {
                    this.setState({
                        noCartonsSelected: (this.props.selectedStockLine && this.props.selectedStockLine.packId === pack.id) ? this.props.selectedStockLine.cartons : pack.noCartons,
                        totalInners: (this.props.selectedStockLine && this.props.selectedStockLine.packId === pack.id)
                            ? this.props.selectedStockLine.totalInners
                            : (pack.hasInnerPack && pack.noUnitsPerCarton ? (pack.noCartons * pack.noUnitsPerCarton) : 0),
                        initialLoadingOfStockLine: false,
                    });
                }
            });
        } else {
            this.setState({
                selectedPack: undefined,
                noCartonsSelected: 0,
                totalInners: undefined,
                initialLoadingOfStockLine: false,
            });
        }
    };

    private onCartonsChange = (newValue : any) => {
        this.setState({
            noCartonsSelected: !!this.state.initialLoadingOfStockLine ? this.props.selectedStockLine?.cartons ?? 0 : Number(newValue),
            totalInners: this.state.selectedPack && this.state.selectedPack?.noUnitsPerCarton ? (this.state.selectedPack?.noUnitsPerCarton * Number(newValue)) : 0,
        });
    };

    private onTotalInnersChange = (newValue : any) => {
        this.setState({
            totalInners: Number(newValue),
        });
    };

    private getFarmDropDownValues = createSelector(
        [this.getSelectedStockLine, this.getSelectedFarm, this.getSelectedBatch],
        (selectedStockLine : IStockLine, selectedFarm : number, selectedBatch : IBatch) => {
            if (selectedStockLine && selectedStockLine.farmId !== 0) {
                return selectedStockLine.farmId;
            } else if (selectedBatch !== undefined && selectedBatch.farmIds?.length === 1) {
                return this.getFarm();
            } else if (selectedFarm !== undefined) {
                return selectedFarm;
            } else {
                return undefined;
            }
        },
    );

    private onOrchardChange = (orchardId : number) => {
        const selectedOrchard = this.props.orchards.find(x => x.id === orchardId);

        if (selectedOrchard) {
            const project = this.props.projects.find(x => x.id === selectedOrchard.projectId && x.isActive);
            this.setState({ selectedOrchard: selectedOrchard.id }, () => {
                this.setState({ newProjectValue: project?.id });
            });
        } else {
            this.setState({
                clearProjects: true,
            });
        }
    };

    private getUpdatedProjectValue = createSelector(
        [this.getNewProjectValue],
        (newProjectValue : number) => {
            return newProjectValue;
        },
    );

    public render() {
        const selectedCommodity = this.props.commodities.find(x => x.id === this.state.selectedCommodity);
        const selectedSite = this.props.sites.find(x => x.id === this.props.selectedSite);
        return <div className={'fdc'}>
            <div>
                <Form getApi={this.setFormApi}>
                    {!this.props.isAdding &&
                        <div className={'fdr flx1 pl20 pr20 aic jcc'}>
                            {'Stock Line #' + (this.props.selectedStockLine && this.props.selectedStockLine.sequence)}
                            <div className={'flx1'} />
                            <div className={this.props.isAdding ? 'dn' : 'bw1 pr10'}>
                                <CustomCheckbox
                                    field={'isActive'}
                                    tabIndex={-1}
                                    initialValue={this.props.selectedStockLine && !this.props.selectedStockLine.isActive ? false : true}
                                    label={'Active?'}
                                />
                            </div>
                        </div>
                    }
                    <div className={'fdr flx1 p10'}>
                        <div className={'fdc flx1'}>
                            <div className={'fdr mb10'}>
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <CustomAutoSuggest
                                        forwardedRef={this.batchRef}
                                        initialValue={this.props.selectedStockLine && this.props.selectedStockLine.batchId !== 0 ? this.props.selectedStockLine.batchId : ''}
                                        hasInitialValue={!!(this.props.selectedStockLine && this.props.selectedStockLine.batchId)}
                                        className={'flx1'}
                                        field={'batchId'}
                                        label={'Batch'}
                                        placeholder={'Select Batch...'}
                                        clearValue={this.state.clearBatch}
                                        resetClearValue={() => this.setState({ clearBatch: false })}
                                        options={this.batchOptions(this.props, this.state)}
                                        onChange={this.onSelectedBatchChange}
                                        value={this.props.selectedStockLine && this.props.selectedStockLine.batchId !== undefined ? this.props.selectedStockLine.batchId : ''}
                                        disabled={!!selectedSite && selectedSite.isSimple}
                                    />
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <CustomAutoSuggest
                                        initialValue={this.props.selectedStockLine &&  this.props.selectedStockLine.commodityId !== 0
                                            ? this.props.selectedStockLine.commodityId
                                            : this.state.selectedBatch !== undefined && this.state.selectedBatch.commodityIds?.length === 1
                                                ? this.getCommodity()
                                                : this.state.selectedCommodity > 0
                                                    ? this.state.selectedCommodity
                                                    : undefined}
                                        hasInitialValue={!!this.state.selectedBatch || (this.state.selectedCommodity > 0) || !!(this.props.selectedStockLine && this.props.selectedStockLine.commodityId)}
                                        className={'flx1'}
                                        field={'commodityId'}
                                        label={'Commodity'}
                                        id={`stockline_${this.props.selectedStockLine && this.props.selectedStockLine.sequence ? this.props.selectedStockLine.sequence : 'adding'}_commodityId`}
                                        clearValue={this.state.clearCommodities}
                                        resetClearValue={() => this.setState({ clearCommodities: false })}
                                        updateValue={this.state.newCommodityValue}
                                        clearUpdateValue={() => this.setState({ newCommodityValue: undefined })}
                                        options={this.getCommodityOptions(this.props, this.state)}
                                        validate={validationFunctions.required}
                                        onValueChange={newValue => newValue ? this.onCommodityChange(Number(newValue)) : this.setState({ selectedCommodity: 0 }) }
                                        validateOnMount
                                        validateOnChange
                                        validateOnBlur
                                        value={this.props.selectedStockLine &&  this.props.selectedStockLine.commodityId !== 0
                                            ? this.props.selectedStockLine.commodityId
                                            : this.state.selectedBatch !== undefined && this.state.selectedBatch.commodityIds?.length === 1
                                                ? this.getCommodity()
                                                : this.state.selectedCommodity > 0
                                                    ? this.state.selectedCommodity
                                                    : undefined}
                                    />
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <CustomAutoSuggest
                                        initialValue={this.props.selectedStockLine &&  this.props.selectedStockLine.varietyId !== 0
                                            ? this.props.selectedStockLine.varietyId
                                            : this.state.selectedBatch !== undefined && this.state.selectedBatch.varietyIds?.length === 1 ? this.getVariety() : undefined}
                                        hasInitialValue={!!this.state.selectedBatch || !!this.state.selectedVariety || !!(this.props.selectedStockLine && this.props.selectedStockLine.varietyId)}
                                        className={'flx1'}
                                        field={'varietyId'}
                                        label={'Variety'}
                                        id={`stockline_${this.props.selectedStockLine && this.props.selectedStockLine.sequence ? this.props.selectedStockLine.sequence : 'adding'}_varietyId`}
                                        updateValue={this.state.newVarietyValue}
                                        clearUpdateValue={() => this.setState({ newVarietyValue: undefined })}
                                        options={this.getVarietyOptions(this.props, this.state)}
                                        onValueChange={newValue => this.setState({ selectedVariety: newValue ? Number(newValue) : 0 })}
                                        validate={validationFunctions.required}
                                        validateOnMount
                                        validateOnChange
                                        validateOnBlur
                                        value={this.props.selectedStockLine &&  this.props.selectedStockLine.varietyId !== 0
                                            ? this.props.selectedStockLine.varietyId
                                            : this.state.selectedBatch !== undefined && this.state.selectedBatch.varietyIds?.length === 1
                                                ? this.getVariety()
                                                : this.state.selectedVariety !== undefined
                                                    ? this.state.selectedVariety
                                                    : undefined}
                                    />
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <CustomAutoSuggest
                                        initialValue={this.props.selectedStockLine && this.props.selectedStockLine.farmId !== 0
                                            ? this.props.selectedStockLine.farmId
                                            : this.state.selectedBatch !== undefined && this.state.selectedBatch.farmIds?.length === 1 ? this.getFarm() : undefined}
                                        hasInitialValue={!!this.state.selectedBatch || !!this.state.selectedFarm || !!(this.props.selectedStockLine && this.props.selectedStockLine.farmId)}
                                        className={'flx1'}
                                        field={'farmId'}
                                        label={'Farm'}
                                        updateValue={this.state.newFarmValue}
                                        clearUpdateValue={() => this.setState({ newFarmValue: undefined })}
                                        options={this.getFarmOptions(this.props, this.state)}
                                        onValueChange={newValue => this.setState({ selectedFarm: newValue ? Number(newValue) : 0 })}
                                        placeholder={'Select Farm...'}
                                        validate={validationFunctions.required}
                                        validateOnMount
                                        validateOnChange
                                        validateOnBlur
                                        value={this.getFarmDropDownValues(this.props, this.state)}
                                    />
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <CustomAutoSuggest
                                        initialValue={this.props.selectedStockLine &&  this.props.selectedStockLine.packId !== 0 ? this.props.selectedStockLine.packId : ''}
                                        hasInitialValue={!!(this.props.selectedStockLine && this.props.selectedStockLine.packId) || !!this.state.selectedPack}
                                        className={'flx1'}
                                        field={'packId'}
                                        label={'Pack'}
                                        id={`stockline_${this.props.selectedStockLine && this.props.selectedStockLine.sequence ? this.props.selectedStockLine.sequence : 'adding'}_packId`}
                                        options={this.getPackOptions(this.props, this.state)}
                                        onValueChange={this.setSelectedPack}
                                        validate={validationFunctions.required}
                                        validateOnMount
                                        validateOnChange
                                        validateOnBlur
                                        value={this.props.selectedStockLine && this.props.selectedStockLine.packId !== 0
                                            ? this.props.selectedStockLine.packId
                                            : this.state.selectedPack ? this.state.selectedPack.id : ''}
                                    />
                                </Typography>
                            </div>
                            <div className={'fdr mb10'}>
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <CustomAutoSuggest
                                        hasInitialValue={!!(this.props.selectedStockLine && this.props.selectedStockLine.sizeId) || !!this.state.selectedSize}
                                        value={this.props.selectedStockLine &&  this.props.selectedStockLine.sizeId !== 0
                                            ? this.props.selectedStockLine.sizeId
                                            : this.state.selectedSize !== undefined ? this.state.selectedSize : ''}
                                        className={'flx1'}
                                        field={'sizeId'}
                                        label={'Size'}
                                        id={`stockline_${this.props.selectedStockLine && this.props.selectedStockLine.sequence ? this.props.selectedStockLine.sequence : 'adding'}_sizeId`}
                                        options={this.getSizeOptions(this.props, this.state)}
                                        onChange={this.onSizeChange}
                                        validate={validationFunctions.required}
                                        validateOnMount
                                        validateOnChange
                                        validateOnBlur
                                    />
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <CustomAutoSuggest
                                        value={this.props.selectedStockLine &&  this.props.selectedStockLine.gradeId !== 0 ? this.props.selectedStockLine.gradeId : this.state.selectedCommodity ? this.getGrade() : ''}
                                        hasInitialValue={!!(this.props.selectedStockLine && this.props.selectedStockLine.gradeId) || !!this.state.selectedCommodity}
                                        className={'flx1'}
                                        field={'gradeId'}
                                        label={'Grade'}
                                        id={`stockline_${this.props.selectedStockLine && this.props.selectedStockLine.sequence ? this.props.selectedStockLine.sequence : 'adding'}_gradeId`}
                                        updateValue={this.state.newGradeValue}
                                        clearUpdateValue={() => this.setState({ newGradeValue: undefined })}
                                        options={this.getGradeOptions(this.props, this.state)}
                                        onValueChange={newValue => this.setState({ newGradeValue: newValue ? Number(newValue) : 0 })}
                                        validate={validationFunctions.required}
                                        validateOnMount
                                        validateOnChange
                                        validateOnBlur
                                    />
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <CustomAutoSuggest
                                        value={this.props.selectedStockLine &&  this.props.selectedStockLine.colourId !== 0 ? this.props.selectedStockLine.colourId : this.state.selectedCommodity ? this.getColour() : ''}
                                        hasInitialValue={!!(this.props.selectedStockLine && this.props.selectedStockLine.colourId) || !!this.state.selectedCommodity}
                                        className={'flx1'}
                                        field={'colourId'}
                                        label={'Colour'}
                                        id={`stockline_${this.props.selectedStockLine && this.props.selectedStockLine.sequence ? this.props.selectedStockLine.sequence : 'adding'}_colourId`}
                                        updateValue={this.state.newColourValue}
                                        clearUpdateValue={() => this.setState({ newColourValue: undefined })}
                                        options={this.getColourOptions(this.props, this.state)}
                                        onValueChange={newValue => this.setState({ newColourValue: newValue ? Number(newValue) : 0 })}
                                        validate={validationFunctions.required}
                                        validateOnMount
                                        validateOnChange
                                        validateOnBlur
                                    />
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    {(selectedCommodity && selectedCommodity.isPhytoCompliant) &&
                                        <CustomAutoSuggest
                                            forwardedRef={this.orchardRef}
                                            value={this.props.selectedStockLine && this.props.selectedStockLine.orchardId !== 0
                                                ? this.props.selectedStockLine.orchardId
                                                : this.state.selectedBatch !== undefined && this.state.selectedBatch.orchardIds?.length === 1 ? this.getOrchard() : undefined}
                                            hasInitialValue={!!this.state.selectedBatch || !!(this.props.selectedStockLine && this.props.selectedStockLine.orchardId)}
                                            className={'flx1'}
                                            field={'orchardId'}
                                            label={'Orchard'}
                                            id={`stockline_${this.props.selectedStockLine && this.props.selectedStockLine.sequence ? this.props.selectedStockLine.sequence : 'adding'}_orchardId`}
                                            updateValue={this.state.newOrchardValue}
                                            clearUpdateValue={() => this.setState({ newOrchardValue: undefined })}
                                            options={this.getOrchardOptions(this.props, this.state)}
                                            validate={validationFunctions.required}
                                            validateOnMount
                                            validateOnChange
                                            validateOnBlur
                                        />
                                    }
                                    {(!selectedCommodity || !selectedCommodity.isPhytoCompliant) &&
                                        <CustomAutoSuggest
                                            forwardedRef={this.orchardRef}
                                            value={this.props.selectedStockLine && this.props.selectedStockLine.orchardId !== 0
                                                ? this.props.selectedStockLine.orchardId
                                                : this.state.selectedBatch !== undefined && this.state.selectedBatch.orchardIds?.length === 1 ? this.getOrchard() : undefined}
                                            hasInitialValue={!!this.state.selectedBatch || !!(this.props.selectedStockLine && this.props.selectedStockLine.orchardId)}
                                            className={'flx1'}
                                            field={'orchardId'}
                                            label={'Orchard'}
                                            id={`stockline_${this.props.selectedStockLine && this.props.selectedStockLine.sequence ? this.props.selectedStockLine.sequence : 'adding'}_orchardId`}
                                            updateValue={this.state.newOrchardValue}
                                            clearUpdateValue={() => this.setState({ newOrchardValue: undefined })}
                                            options={this.getOrchardOptions(this.props, this.state)}
                                            onValueChange={newValue => this.onOrchardChange(Number(newValue))}
                                        />
                                    }
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <CustomAutoSuggest
                                        value={this.props.selectedStockLine &&  this.props.selectedStockLine.projectId !== 0 ? this.props.selectedStockLine.projectId : ''}
                                        hasInitialValue={!!(this.props.selectedStockLine && this.props.selectedStockLine.projectId)}
                                        className={'flx1'}
                                        field={'projectId'}
                                        label={'Project'}
                                        id={`stockline_${this.props.selectedStockLine && this.props.selectedStockLine.sequence ? this.props.selectedStockLine.sequence : 'adding'}_projectId`}
                                        updateValue={this.getUpdatedProjectValue(this.props, this.state)}
                                        clearUpdateValue={() => this.setState({ newProjectValue: undefined })}
                                        options={this.getProjectOptions(this.props, this.state)}
                                    />
                                </Typography>
                            </div>
                            <div className={'fdr mb10'}>
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <CustomTextField
                                        className={'flx1'}
                                        type={'number'}
                                        field={'cartons'}
                                        label={'Cartons'}
                                        value={this.state.noCartonsSelected}
                                        onChange={this.onCartonsChange}
                                        validate={validationFunctions.required}
                                        validateOnMount
                                        validateOnChange
                                        validateOnBlur
                                    />
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <CustomTextField
                                        className={'flx1'}
                                        type={'number'}
                                        field={'totalInners'}
                                        label={'Total Inners'}
                                        value={this.state.totalInners}
                                        onChange={this.onTotalInnersChange}
                                        disabled={!this.state.selectedPack?.hasInnerPack}
                                    />
                                </Typography>
                                <div className={'flx3'}/>
                                <div className={'w120'}/>
                            </div>
                        </div>
                    </div>
                    <div className={'fdr p20'}><div className={'flx1'}/>
                        {this.props.isEditing &&
                        <Button
                            className={'fwb h35 pt20 pr20'}
                            onClick={this.clearStockLineInputFields}
                            variant='text'>
                            Clear
                        </Button>
                        }
                        <IconButton className={'h50 bcp'} onClick={this.submit}>
                            <Icon className={'cw'}>{ this.props.isAdding ? 'add' : 'done'}</Icon>
                        </IconButton>
                    </div>
                </Form>
            </div>
        </div>;
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        organizations: state.masterData.organizations,
        stocks: state.data.stocks,
        sites: state.masterData.sites,
        farms: state.masterData.farms,
        markets: state.masterData.markets,
        regions: state.masterData.regions,
        countries: state.masterData.countries,
        batches: state.data.batches,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        sizes: state.masterData.sizes,
        grades: state.masterData.grades,
        colours: state.masterData.colours,
        orchards: state.masterData.orchards,
        palletBaseTypes: state.masterData.palletBaseTypes,
        auth : state.auth,
        projects: state.masterData.projects,
    };
};

export default connect(
    mapStateToProps,
)(StockLineForm);

import { IBaseModel } from '../../model';
import { IIntakeLineLayerUnit, IntakeLineLayerUnit } from './intakeLineLayerUnit';
import { IIntakeLineLayerFormValues } from './form/intakeLineLayerFormValues';

export interface IIntakeLineLayer extends IBaseModel {
    intakeLineId : number;
    varietyId : number;
    packId : number;
    orchardId ?: number;
    projectId ?: number;
    grossWeight : number;
    nettWeight : number;
    noOfUnits : number;
    lotId : number;
    lotName ?: string;
    intakeLineLayerUnits : Array<IIntakeLineLayerUnit>;
}

export class IntakeLineLayer implements IIntakeLineLayer {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public intakeLineId : number;
    public varietyId : number;
    public packId : number;
    public orchardId ?: number;
    public projectId ?: number;
    public grossWeight : number;
    public nettWeight : number;
    public noOfUnits : number;
    public lotId : number;
    public lotName ?: string;

    public intakeLineLayerUnits : Array<IIntakeLineLayerUnit>;

    public constructor(formValues : IIntakeLineLayerFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.varietyId = Number(formValues.variety?.value) ?? 0;
        this.packId = Number(formValues.pack?.value) ?? 0;
        this.orchardId = Number(formValues.orchard?.value);
        this.projectId = Number(formValues.project?.value);
        this.grossWeight = formValues.grossWeight;
        this.nettWeight = formValues.nettWeight;
        this.lotId = Number(formValues.lot?.value) ?? 0;
        this.lotName = formValues.lotName;
        this.noOfUnits = formValues.noOfUnits;

        this.intakeLineLayerUnits = formValues.intakeLineLayerUnits.map(x => new IntakeLineLayerUnit(x));
    }

}

import * as React from 'react';
import { Form, FormikProps } from 'formik';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import { IOptionType } from '../../../../@types/helper';
import FormAutocompleteSelect from '../../../../components/input/form/FormAutoCompleteSelect';
import { IAuthState, IRootState } from '../../../../@types/redux';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { PrintServerFormValues } from '../../../../@types/model/masterData/printServer/printServerFormValues';
import { ISite } from '../../../../@types/model/masterData/site/site';

interface IPrintServerFormProps {
    isLoading : boolean;
    siteOptions : Array<IOptionType>;
}

interface IPrintServerFormState {
}

type PrintServerFormPropsType = IPrintServerFormProps & FormikProps<PrintServerFormValues>;

class PrintServerForm extends React.Component<PrintServerFormPropsType, IPrintServerFormState> {

    constructor(props : PrintServerFormPropsType) {
        super(props);
    }

    public render() {
        return (
            <Form className={'p20'}>
                <FormTextInput name={'name'} label={'Name'}/>
                <FormTextInput name={'macAddress'} label={'MAC Address'}/>
                <FormTextInput name={'ipAddress'} label={'IP Address'}/>
                <FormTextInput name={'primaryUrl'} label={'Primary URL'}/>
                <FormTextInput name={'defaultScheme'} label={'Default Scheme'}/>
                <FormTextInput name={'port'} label={'Port'}/>
                <FormAutocompleteSelect name={'site'} label={'Site'} options={this.props.siteOptions}/>
                <FormSingleToggleButton name={'isMafrodaIntegrated'} label={'Is Mafroda Integrated'} />
                <FormSingleToggleButton name={'isOverrideFlag'} label={'Override Flag'} />
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const getAuth = (state : IRootState) => state.auth;
const getSites = (state : IRootState) => state.masterData.sites;

const getSiteOptions = createSelector(
    [getSites, getAuth],
    (sites : Array<ISite>, auth : IAuthState) => {
        return sites.filter(x => x.isActive && auth.session?.user.siteIds.some(y => y === x.id)).map(x => ({ label: `(${x.code})  ${x.description}`, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        siteOptions: getSiteOptions(state),
    };
};

export default connect(
    mapStateToProps,
)(PrintServerForm);

import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState, RootAction } from '../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Typography } from '@mui/material';
import IntakeLineLayerSummary from './IntakeLineLayerSummary';
import { IIntake } from '../../@types/model/intake/intake';
import { IStock } from '../../@types/model/stock/stock';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import { IPalletBaseType } from '../../@types/model/masterData/palletBaseType/palletBaseType';
import { IIntakeLine } from '../../@types/model/intake/intakeLine';
import CustomTable from '../../components/table/CustomTable';
import { booleanToYesNo, addkg, formatDateTime, compareDate } from '../../services/appFunctionsService';

interface IIntakeLineSummaryProps {
    intake : IIntake;
    setLoading : (loading : boolean) => void;
    refreshData : () => void;
    isLoading : boolean;
    stockData : Array<IStock>;
    packs : Array<IPack>;
    orchards : Array<IOrchard>;
    palletBaseTypes : Array<IPalletBaseType>;
    lines : Array<IIntakeLine>;
}

interface IIntakeLineSummaryState {
    showDialog : boolean;
    rows : Array<IIntakeLine>;
}

class IntakeLineSummary extends React.Component<IIntakeLineSummaryProps, IIntakeLineSummaryState> {
    constructor(props : IIntakeLineSummaryProps) {
        super(props);

        this.state = {
            showDialog: false,
            rows: this.props.lines,
        };
    }

    private getPalletBaseTypeName = (palletBaseTypeId : number) => {
        const palletBaseTypes = this.props.palletBaseTypes;
        const palletBaseType = palletBaseTypes && palletBaseTypes.find(x => x.id === palletBaseTypeId);
        return palletBaseType ? palletBaseType.name : '';
    };

    public render() {
        return <div className={'fdc flx1 p10'}>
            <Typography className={'fdr bcp cw pt10 pb10 pl20 mt10 fs18 fw500'}>
                Lines
            </Typography>
            <div className={'fdr flx1 wfill'}>
                <CustomTable<IIntakeLine>
                    enableSorting
                    enableTotalRow
                    fitWidthToPage
                    columns={[
                        { title: 'ID', field: 'id', enableSorting: true },
                        { title: 'Pallet Base Type', field: 'palletBaseTypeId', formatFunction: this.getPalletBaseTypeName, enableSorting: true },
                        { title: 'Gross Weight (kg)', field: 'grossWeight', formatFunction: addkg , enableSorting: true },
                        { title: 'Nett Weight (kg)', field: 'nettWeight', formatFunction: addkg , enableSorting: true },
                        { title: 'Created By', field: 'createdByName', enableSorting: true },
                        { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
                        { title: 'Updated By', field: 'updatedByName', enableSorting: true },
                        { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
                        { title: 'Active?', field: 'isActive', formatFunction: booleanToYesNo, type: 'boolean', enableSorting: true },
                    ]}
                    rows={this.state.rows}
                    initialSortOrder={[{ columnName: 'id_Id', direction : 'desc' }]}
                    pageHeight={210}
                    enableDetailAccordion={() => true}
                    isActive={(row : IIntakeLine) => row.isActive}
                    detailAccordionComponent={(row : IIntakeLine) =>
                        <IntakeLineLayerSummary
                            setLoading={this.props.setLoading}
                            line={row}
                            layers={row.intakeLineLayers}
                            refreshData={this.props.refreshData}
                            isLoading={this.props.isLoading}
                        />}
                />
            </div>
        </div>;
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        lots: state.data.lots,
        lotTypes: state.masterData.lotTypes,
        stockData: state.data.stocks,
        packs: state.masterData.packs,
        orchards: state.masterData.orchards,
        palletBaseTypes: state.masterData.palletBaseTypes,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IntakeLineSummary);

import { Button, ClickAwayListener, Grow, Icon, Popper, TextField } from '@mui/material';
import DateRange from '../DateRange';
import moment from 'moment';
import React from 'react';
import { DATE_FORMAT_DEFAULT_NO_TIME } from '../../appConstants';
import PillButton from '../input/PillButton';
import { createSelector } from 'reselect';
import CustomTooltip from '../tooltip/tooltip';
import materialTheme from '../../styles/materialTheme';

interface IFilterProps {
    handleDateRangeChange : (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => void;
    onApplyClick : () => void;
    closeButtonTitle ?: string;
    applyButtonTitle ?: string;
    enableCloseButton ?: boolean;
    enableApplyButton ?: boolean;
    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;
    className ?: string;
}

interface IFilterState {
    open : boolean;
}

export default class TransactionFilter extends React.Component<IFilterProps, IFilterState> {
    private anchorEl : any;

    constructor(props : IFilterProps) {
        super(props);

        this.state = {
            open: false,
        };
    }

    private handleClick = () => {
        this.setState({ open: !this.state.open });
    };

    private handleClose = (event : MouseEvent | TouchEvent) => {
        if (this.anchorEl && this.anchorEl.contains(event.target)) {
            return;
        }

        event.preventDefault();
        this.setState({ open: false });
    };

    private onCancelClick = (event : React.MouseEvent<HTMLElement>) => {
        if (this.anchorEl && this.anchorEl.contains(event.target)) {
            return;
        }
        this.setState({ open: false });
    };

    private getSelectedFromDate = (props : IFilterProps) => props.selectedFromDate;
    private getSelectedToDate = (props : IFilterProps) => props.selectedToDate;

    private isSelectedDateRangeValid = createSelector(
        [this.getSelectedFromDate, this.getSelectedToDate],
        (selectedFromDate : moment.Moment, selectedToDate : moment.Moment) => {
            let days = 0;

            const fromDate = selectedFromDate.startOf('day');
            const toDate = selectedToDate.startOf('day');

            days = Math.round(moment.duration(toDate.diff(fromDate)).asDays());

            return (days > 365) ? true : false;
        },
    );

    public render() {
        return <div className={`fdr jcfe ${this.props.className ? this.props.className : ''}`}>
            <TextField
                ref={(node) => { this.anchorEl = node; }}
                className={'w250'}
                label={'Filter By Date'}
                aria-haspopup='true'
                name={'filter'}
                value={this.props.selectedFromDate !== null ? this.props.selectedFromDate.local().format(DATE_FORMAT_DEFAULT_NO_TIME) + ' - ' + (this.props.selectedToDate !== null ? this.props.selectedToDate.local().format(DATE_FORMAT_DEFAULT_NO_TIME) : '') : ''}
                variant={'standard'}
                onClick={this.handleClick}
                InputProps={{
                    endAdornment: (
                        <Icon fontSize='small'>
                        filter_list
                        </Icon>
                    ),
                }}
            />
            <Popper style={{ zIndex: 1999 }} className={'pr10'} anchorEl={this.anchorEl} open={this.state.open} transition>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', marginTop: 8, backgroundColor: materialTheme.custom.basicTransactionDateFilter.background }}
                    >
                        <div className={'zi1000 p10 filterShadow'}>
                            <form>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <div className={'fdc'}>
                                        <div className='fdc jsr aife asr'>
                                            <DateRange
                                                className={'jsr jcfe aife asr'}
                                                onChange={this.props.handleDateRangeChange}
                                                start={this.props.selectedFromDate}
                                                end={this.props.selectedToDate}
                                                positionLeft={true}/>
                                            <div className={'w30'}/>
                                        </div>
                                        <div className={'fdr flx1 pt10'}>
                                            <div className={'flx1'}/>
                                            <Button
                                                className={this.props.enableCloseButton === false ? 'dn' : 'fwb h35'}
                                                onClick={this.onCancelClick}>
                                                {!!this.props.closeButtonTitle ? this.props.closeButtonTitle : 'Cancel'}
                                            </Button>
                                            <CustomTooltip title={`${this.isSelectedDateRangeValid(this.props) ? 'Date range cannot be more then 365 days' : ''}`}>
                                                <PillButton
                                                    disabled={this.isSelectedDateRangeValid(this.props)}
                                                    text={!!this.props.applyButtonTitle ? this.props.applyButtonTitle : 'Apply'}
                                                    className={this.props.enableApplyButton === false ? 'dn' : 'ml15 pl20 pr20 h35 cpd'}
                                                    onClick={() => {
                                                        this.props.onApplyClick();
                                                        this.setState({ open : false });
                                                    }}
                                                    color={'secondary'}
                                                    size={'small'}
                                                >
                                                </PillButton>
                                            </CustomTooltip>
                                        </div>
                                    </div>
                                </ClickAwayListener>
                            </form>
                        </div>
                    </Grow>
                )}
            </Popper>
        </div>;
    }
}

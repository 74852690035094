import { DATEPICKER_FORMAT_DEFAULT } from '../../../../appConstants';
import { IBaseModel } from '../../../model';
import { IAccreditationFormValues } from './accreditationFormValues';
import moment from 'moment';

export interface IAccreditation extends IBaseModel {
    code : string;
    name : string;
    issueDate : string;
    expirationDate : string;
    farmIds ?: Array<number>;
}

export class Accreditation implements IAccreditation {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public name : string;
    public issueDate : string;
    public expirationDate : string;
    public farmIds ?: Array<number> | undefined;

    public constructor(formValues : IAccreditationFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.code = formValues.code;
        this.name = formValues.name;
        this.issueDate = moment(formValues.issueDate).format(DATEPICKER_FORMAT_DEFAULT);
        this.expirationDate = moment(formValues.expirationDate).format(DATEPICKER_FORMAT_DEFAULT);
        this.farmIds = formValues.farms.map(x => Number(x.value)) ?? undefined;
    }
}

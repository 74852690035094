import * as React from 'react';
import CustomTable from '../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetLotTypes } from '../../../store/masterData/Actions';
import { formatDateTime, upsertArrayElement } from '../../../services/appFunctionsService';
import { CONSTANT_LOT_TYPE_GUIDS } from '../../../appConstants';
import { ILotType, LotType } from '../../../@types/model/masterData/lotType/lotType';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import LotTypeHttpService from '../../../services/http/masterData/lotTypeHttpService';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikActions } from 'formik';
import { LotTypeFormValues, ILotTypeFormValues } from '../../../@types/model/masterData/lotType/lotTypeFormValues';
import LotTypeForm from './form/LotTypeForm';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { setLotTypeMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface ILotTypeScreenProps extends RouteComponentProps {
    dataSetLotTypes : DispatchCall<Array<ILotType>>;
    lotTypeData : Array<ILotType>;
    auth : IAuthState;
}

interface ILotTypeScreenState {
    rows : Array<ILotType>;
    isLoading : boolean;
    selectedLotType ?: ILotType;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingLotType ?: ILotType;
}

class LotTypeScreen extends React.Component<ILotTypeScreenProps, ILotTypeScreenState> {
    constructor(props : ILotTypeScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedLotType: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);

            try {
                const res = await LotTypeHttpService.getLotTypeData();

                this.props.dataSetLotTypes(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading lot types.');
                this.setLoading(false);
            }
        }
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedLotType: undefined,
        });
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public onSubmit = async (values : ILotTypeFormValues) => {
        this.setLoading(true);

        try {
            const res = await LotTypeHttpService.addOrUpdateLotType(new LotType(values));

            const newLotTypeList = upsertArrayElement(this.props.lotTypeData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetLotTypes(newLotTypeList);
            await setLotTypeMasterDataIndexedDB(newLotTypeList);

            if (this.state.selectedLotType) {
                generalShowSuccessSnackbar('Lot Type updated successfully.');
            } else {
                generalShowSuccessSnackbar('Lot Type added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating Lot Type data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (lotType : ILotType) => {
        this.setState({ isDeletePopupOpen: true, deletingLotType: lotType });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingLotType: undefined });
    };

    private removeLotType = async () => {
        const newLotType = this.state.deletingLotType;
        if (newLotType) {
            newLotType.isActive = false;
            this.setLoading(true);

            try {
                const res = await LotTypeHttpService.deleteLotType(newLotType.id);

                const newLotTypeList = upsertArrayElement(this.props.lotTypeData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetLotTypes(newLotTypeList);
                await setLotTypeMasterDataIndexedDB(newLotTypeList);

                generalShowSuccessSnackbar('Lot Type updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting Lot Type.');
                newLotType.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    public onReset = async (formValues : ILotTypeFormValues, formikActions : FormikActions<ILotTypeFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    public editLotType = (lotType : ILotType) => {
        this.setState({
            isDialogOpen: true,
            selectedLotType: lotType,
        });
        this.openDialog();
    };

    private isEditable = (lotType : ILotType) => !CONSTANT_LOT_TYPE_GUIDS.some(x => x === lotType.guid);

    public getSelectedLotType = (props : ILotTypeScreenProps, state : ILotTypeScreenState) => state.selectedLotType;

    public getInitialFormValues = createSelector(
        [this.getSelectedLotType],
        (lotType : ILotType) => {
            return new LotTypeFormValues(lotType);
        },
    );

    private getRights = (props : ILotTypeScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : ILotTypeScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.lotTypeData ? this.props.lotTypeData : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Lot Type'
                    isEdit={!!this.state.selectedLotType}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={LotTypeFormValues.formSchema}
                        component={LotTypeForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<ILotType>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editLotType}
                            enableEditing={(lotType : ILotType) => this.isEditable(lotType) && this.hasEditingRight(this.props)}
                            enableDeleting={(lotType : ILotType) => lotType.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Swappable?', field: 'isSwappable', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : ILotType) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Lot Type'}
                    open={this.state.isDeletePopupOpen}
                    message={'Are you sure you want to delete this lot type?'}
                    onOkClicked={this.removeLotType}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        lotTypeData: state.masterData.lotTypes,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetLotTypes }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LotTypeScreen);

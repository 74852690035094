import { IBaseModel } from '../../../model';
import { IMaterialTypeFormValues } from './materialTypeFormValues';

export interface IMaterialType extends IBaseModel {
    name : string;
    isPackType : boolean;
}

export class MaterialType implements IMaterialType {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public name : string;
    public isPackType : boolean;

    public constructor(formValues : IMaterialTypeFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.name = formValues.name;
        this.isPackType = formValues.isPackType;
    }
}

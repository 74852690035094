import { IBaseModel } from '../../../model';
import { IVarietyFormValues } from './varietyFormValues';

export interface IVariety extends IBaseModel {
    commodityId : number;
    code : string;
    name : string;
    isImported ?: boolean;
}

export class Variety implements IVariety {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public name : string;
    public commodityId : number;
    public isImported ?: boolean;

    public constructor(formValues : IVarietyFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.code = formValues.code;
        this.name = formValues.name;
        this.commodityId = Number(formValues.commodity?.value) ?? 0;
        this.isActive = formValues.isActive;
        this.isImported = formValues.isImported;
    }
}

import { getState, dispatch } from '../Index';
import {
    dataSetStocks,
    dataSetOrderHeaders,
    dataSetDispatchInstructions,
    dataSetTrips,
    dataSetCompliances,
    dataSetLots,
    dataSetBatches,
    dataSetIntakes,
    dataSetMaterialDispatches,
    dataSetMaterialStocks,
    dataSetTempStockUnits,
    dataSetStockDataViews,
    dataSetDispatchViews,
    dataSetMultiDispatchStockViews,
    dataSetRoles,
    dataSetStockTomatoReportViews,
    dataSetBatchViews,
    dataSetLotViews,
    dataSetIntakeViews,
    dataSetFAQs,
    dataSetMaterialStockViews,
    dataSetStockWithCartonsDataViews,
    dataSetMaterialDispatchViews,
} from './Actions';
import { addArrayElement, setArrayElement } from '../../services/appFunctionsService';
import { IStock } from '../../@types/model/stock/stock';
import { IStockRelatedData } from '../../@types/model/stock/stockRelatedData';
import { IOrderHeader } from '../../@types/model/order/orderHeader';
import { IOrderRelatedData } from '../../@types/model/order/orderRelatedData';
import { IDispatchInstruction } from '../../@types/model/dispatch/dispatchInstruction';
import { IDashboardDispatchInstructionLine } from '../../@types/model/dispatch/dashboardDispatchInstructionLine';
import { ITrip } from '../../@types/model/dispatch/trip';
import { ICompliance } from '../../@types/model/compliance/compliance';
import { ILot } from '../../@types/model/lot/lot';
import { ILotRelatedData } from '../../@types/model/lot/lotRelatedData';
import { IIntake } from '../../@types/model/intake/intake';
import { IIntakeRelatedData } from '../../@types/model/intake/intakeRelatedData';
import { IBatch } from '../../@types/model/batch/batch';
import { IMaterialStock } from '../../@types/model/materialStock/materialStock';
import { IMaterialDispatch } from '../../@types/model/materialDispatch/materialDispatch';
import { ITempStockUnit } from '../../@types/model/stock/tempStockUnit';
import { IMaterialRelatedData } from '../../@types/model/materialStock/materialRelatedData';
import { IRootState } from '../../@types/redux';
import { createSelector } from 'reselect';
import { ISaleLineRelatedData } from '../../@types/model/sales/saleLineRelatedData';
import { IStockViewRelatedData } from '../../@types/model/stock/stockViewRelatedData';
import { IStockEditFormRelatedData } from '../../@types/model/stock/StockEditFormRelatedData';
import { IStockView } from '../../@types/model/stock/stockView';
import { dataSetCarriers, dataSetColours, dataSetCommodities, dataSetCommodityConditions, dataSetCommodityStates, dataSetContactInfos, dataSetCountries, dataSetFarms, dataSetGrades, dataSetInspectionPoints, dataSetInventories, dataSetLotTypes, dataSetMarkets, dataSetMarks, dataSetMaterials, dataSetMaterialTypes, dataSetOrchards, dataSetPackCategories, dataSetPacks, dataSetPalletBaseTypes, dataSetRegions, dataSetSeasons, dataSetSizes, dataSetStorageUnits, dataSetUnitOfMeasures, dataSetUnitOfMeasureTypes, dataSetVarieties, dataSetTruckTypes, dataSetProjects, dataSetAccreditations, dataSetAgreementCodes, dataSetSalesPoints } from '../masterData/Actions';
import { IDispatchViewRelatedData } from '../../@types/model/dispatch/dispatchViewRelatedData';
import { IDispatchView } from '../../@types/model/dispatch/dispatchView';
import { IDispatchSummaryRelatedData } from '../../@types/model/dispatch/dispatchSummaryRelatedData';
import { IDispatchEditRelatedData } from '../../@types/model/dispatch/dispatchEditRelatedData';
import { IDispatchDashboardInfo } from '../../@types/model/dispatch/dispatchDashboardInfo';
import { IMultiDispatchWizardRelatedData } from '../../@types/model/dispatch/multiDispatchWizardRelatedData';
import { IRole } from '../../@types/model/user/role';
import { IDispatchCorrectionRelatedData } from '../../@types/model/dispatch/dispatchCorrectionRelatedData';
import { IStockTomatoReportRelatedData } from '../../@types/model/stock/stockTomatoReportRelatedData';
import { IBatchView } from '../../@types/model/batch/batchView';
import { IBatchSummaryRelatedData } from '../../@types/model/batch/batchSummaryRelatedData';
import { dataSetCommodity, dataSetDepartment, dataSetOrchard, dataSetPack, dataSetVariety } from '../masterData/Functions';
import { IBatchViewRelatedData } from '../../@types/model/batch/batchViewRelatedData';
import { ILotView } from '../../@types/model/lot/lotView';
import { ILotViewRelatedData } from '../../@types/model/lot/lotViewRelatedData';
import { ILotSummaryRelatedData } from '../../@types/model/lot/lotSummaryRelatedData';
import { IIntakeView } from '../../@types/model/intake/intakeView';
import { IIntakeViewRelatedData } from '../../@types/model/intake/intakeViewRelatedData';
import { IIntakeSummaryRelatedData } from '../../@types/model/intake/intakeSummaryRelatedData';
import { ILotImportRelatedData } from '../../@types/model/lot/lotImportRelatedData';
import { IFaq } from '../../@types/model/frequentlyAskedQuestions/faq';
import { ITripRelatedData } from '../../@types/model/dispatch/tripRelatedData';
import { IMaterialStockView } from '../../@types/model/materialStock/materialStockView';
import { IMaterialStockSummaryRelatedData } from '../../@types/model/materialStock/materialStockSummaryRelatedData';
import { IBatchReportRelatedData } from '../../@types/model/batch/batchReportRelatedData';
import { IStockViewWithCartons } from '../../@types/model/stock/stockViewWithCartons';
import { IPOFileRelatedData } from '../../@types/model/integration/fileImport/poFileRelatedData';
import { ICombineStockRelatedData } from '../../@types/model/stock/combineStockRelatedData';
import { IMaterialDispatchViewRelatedData } from '../../@types/model/materialDispatch/materialDispatchViewRelatedData';
import { IMaterialDispatchView } from '../../@types/model/materialDispatch/materialDispatchView';
import { IMaterialDispatchSummaryRelatedData } from '../../@types/model/materialDispatch/materialDispatchSummaryRelatedData';

/* Stock Functions */

export function dataSetStock(value : IStock) {
    const state = getState();
    const position = state.data.stocks.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetStocks(setArrayElement(state.data.stocks, position, { ...value })));
    } else {
        dispatch(dataSetStocks(addArrayElement(state.data.stocks, { ...value })));
    }
}

export function dataSetStockView(value : IStockView) {
    const state = getState();
    const position = state.data.stockDataViews.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetStockDataViews(setArrayElement(state.data.stockDataViews, position, { ...value })));
    } else {
        dispatch(dataSetStockDataViews(addArrayElement(state.data.stockDataViews, { ...value })));
    }
}

export function dataSetStockViewWithCartons(value : IStockViewWithCartons) {
    const state = getState();
    const position = state.data.stockWithCartonsDataViews.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetStockWithCartonsDataViews(setArrayElement(state.data.stockWithCartonsDataViews, position, { ...value })));
    } else {
        dispatch(dataSetStockWithCartonsDataViews(addArrayElement(state.data.stockWithCartonsDataViews, { ...value })));
    }
}

export function dataSetAllStockRelatedData(data : IStockRelatedData) {
    dispatch(dataSetStocks(data?.stocks ? [...data.stocks] : []));
    dispatch(dataSetOrderHeaders(data?.orders ? [...data.orders] : []));
    dispatch(dataSetDispatchInstructions(data?.dispatches ? [...data.dispatches] : []));
    dispatch(dataSetTrips(data?.trips ? [...data.trips] : []));
    dispatch(dataSetCompliances(data?.compliances ? [...data.compliances] : []));
    dispatch(dataSetBatches(data?.batches ? [...data.batches] : []));

    if (!self.indexedDB) {
        dispatch(dataSetStorageUnits(data?.storageUnits ? [...data.storageUnits] : []));
        dispatch(dataSetInspectionPoints(data?.inspectionPoints ? [...data.inspectionPoints] : []));
        dispatch(dataSetFarms(data?.farms ? [...data.farms] : []));
        dispatch(dataSetMarkets(data?.markets ? [...data.markets] : []));
        dispatch(dataSetMarks(data?.marks ? [...data.marks] : []));
        dispatch(dataSetAccreditations(data?.accreditations ? [...data.accreditations] : []));
        dispatch(dataSetRegions(data?.regions ? [...data.regions] : []));
        dispatch(dataSetCountries(data?.countries ? [...data.countries] : []));
        dispatch(dataSetCommodities(data?.commodities ? [...data.commodities] : []));
        dispatch(dataSetCommodityConditions(data?.commodityConditions ? [...data.commodityConditions] : []));
        dispatch(dataSetVarieties(data?.varieties ? [...data.varieties] : []));
        dispatch(dataSetPacks(data?.packs ? [...data.packs] : []));
        dispatch(dataSetPackCategories(data?.packCategories ? [...data.packCategories] : []));
        dispatch(dataSetSizes(data?.sizes ? [...data.sizes] : []));
        dispatch(dataSetGrades(data?.grades ? [...data.grades] : []));
        dispatch(dataSetColours(data?.colours ? [...data.colours] : []));
        dispatch(dataSetOrchards(data?.orchards ? [...data.orchards] : []));
        dispatch(dataSetPalletBaseTypes(data?.palletBaseTypes ? [...data.palletBaseTypes] : []));
        dispatch(dataSetCarriers(data?.carriers ? [...data.carriers] : []));
        dispatch(dataSetContactInfos(data?.contactInfos ? [...data.contactInfos] : []));
        dispatch(dataSetInventories(data?.inventories ? [...data.inventories] : []));
        dispatch(dataSetTruckTypes(data?.truckTypes ? [...data.truckTypes] : []));
        dispatch(dataSetAgreementCodes(data?.agreementCodes ? [...data.agreementCodes] : []));
    }
}

export function dataSetAllStockViewRelatedData(data : IStockViewRelatedData) {
    dispatch(dataSetStockDataViews(data?.stocks ? [...data.stocks] : []));
    dispatch(dataSetMaterialStocks(data?.materialStocks ? [...data.materialStocks] : []));

    if (!self.indexedDB) {
        dispatch(dataSetMarkets(data?.markets ? [...data.markets] : []));
        dispatch(dataSetMarks(data?.marks ? [...data.marks] : []));
        dispatch(dataSetRegions(data?.regions ? [...data.regions] : []));
        dispatch(dataSetStorageUnits(data?.storageUnits ? [...data.storageUnits] : []));
        dispatch(dataSetCountries(data?.countries ? [...data.countries] : []));
        dispatch(dataSetPalletBaseTypes(data?.palletBaseTypes ? [...data.palletBaseTypes] : []));
        dispatch(dataSetInventories(data?.inventories ? [...data.inventories] : []));
        dispatch(dataSetMaterials(data?.materials ? [...data.materials] : []));
        dispatch(dataSetProjects(data?.projects ? [...data.projects] : []));
    }
}

export function dataSetAllStockTomatoReportRelatedData(data : IStockTomatoReportRelatedData) {
    dispatch(dataSetStockTomatoReportViews(data?.stocks ? [...data.stocks] : []));

    if (!self.indexedDB) {
        dispatch(dataSetMarkets(data?.markets ? [...data.markets] : []));
        dispatch(dataSetCommodities(data?.commodities ? [...data.commodities] : []));
        dispatch(dataSetVarieties(data?.varieties ? [...data.varieties] : []));
        dispatch(dataSetFarms(data?.farms ? [...data.farms] : []));
        dispatch(dataSetGrades(data?.grades ? [...data.grades] : []));
        dispatch(dataSetColours(data?.colours ? [...data.colours] : []));
        dispatch(dataSetPacks(data?.packs ? [...data.packs] : []));
        dispatch(dataSetSizes(data?.sizes ? [...data.sizes] : []));
    }
}

export function dataSetStockEditFormRelatedData(data : IStockEditFormRelatedData) {
    dispatch(dataSetBatches(data?.batches ? [...data.batches] : []));

    if (!self.indexedDB) {
        dispatch(dataSetFarms(data?.farms ? [...data.farms] : []));
        dispatch(dataSetCommodities(data?.commodities ? [...data.commodities] : []));
        dispatch(dataSetVarieties(data?.varieties ? [...data.varieties] : []));
        dispatch(dataSetPacks(data?.packs ? [...data.packs] : []));
        dispatch(dataSetSizes(data?.sizes ? [...data.sizes] : []));
        dispatch(dataSetGrades(data?.grades ? [...data.grades] : []));
        dispatch(dataSetColours(data?.colours ? [...data.colours] : []));
        dispatch(dataSetOrchards(data?.orchards ? [...data.orchards] : []));
    }
}

export function dataSetCombineStockRelatedData(data : ICombineStockRelatedData) {
    if (!self.indexedDB) {
        dispatch(dataSetPacks(data?.packs ? [...data.packs] : []));
        dispatch(dataSetSizes(data?.sizes ? [...data.sizes] : []));
        dispatch(dataSetGrades(data?.grades ? [...data.grades] : []));
        dispatch(dataSetColours(data?.colours ? [...data.colours] : []));
        dispatch(dataSetSalesPoints(data?.salesPoints ? [...data.salesPoints] : []));
    }
}

/* Order Functions */

export function dataSetOrderHeader(value : IOrderHeader) {
    const state = getState();
    const position = state.data.orderHeaders.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetOrderHeaders(setArrayElement(state.data.orderHeaders, position, { ...value })));
    } else {
        dispatch(dataSetOrderHeaders(addArrayElement(state.data.orderHeaders, { ...value })));
    }
}

export function dataSetAllOrderRelatedData(data : IOrderRelatedData) {
    dispatch(dataSetOrderHeaders(data?.orders ? [...data.orders] : []));

    if (!self.indexedDB) {
        dispatch(dataSetCommodities(data?.commodities ? [...data.commodities] : []));
        dispatch(dataSetVarieties(data?.varieties ? [...data.varieties] : []));
        dispatch(dataSetPacks(data?.packs ? [...data.packs] : []));
        dispatch(dataSetSizes(data?.sizes ? [...data.sizes] : []));
        dispatch(dataSetGrades(data?.grades ? [...data.grades] : []));
        dispatch(dataSetColours(data?.colours ? [...data.colours] : []));
    }
}

/* Dispatch Instruction Functions */

export function dataSetDispatchInstruction(value : IDispatchInstruction) {
    const state = getState();
    const position = state.data.dispatchInstructions.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetDispatchInstructions(setArrayElement(state.data.dispatchInstructions, position, { ...value })));
    } else {
        dispatch(dataSetDispatchInstructions(addArrayElement(state.data.dispatchInstructions, { ...value })));
    }
}

export function dataSetDispatchView(value : IDispatchView) {
    const state = getState();
    const position = state.data.dispatchViews.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetDispatchViews(setArrayElement(state.data.dispatchViews, position, { ...value })));
    } else {
        dispatch(dataSetDispatchViews(addArrayElement(state.data.dispatchViews, { ...value })));
    }
}

export function dataSetMaterialDispatchView(value : IMaterialDispatchView) {
    const state = getState();
    const position = state.data.materialDispatchViews.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetMaterialDispatchViews(setArrayElement(state.data.materialDispatchViews, position, { ...value })));
    } else {
        dispatch(dataSetMaterialDispatchViews(addArrayElement(state.data.materialDispatchViews, { ...value })));
    }
}

export function dataSetAllDispatchViewRelatedData(data : IDispatchViewRelatedData) {
    dispatch(dataSetDispatchViews(data?.dispatches ? [...data.dispatches] : []));
    dispatch(dataSetStocks(data?.stocks ? [...data.stocks] : []));
    dispatch(dataSetTrips(data?.trips ? [...data.trips] : []));

    if (!self.indexedDB) {
        dispatch(dataSetInspectionPoints(data?.inspectionPoints ? [...data.inspectionPoints] : []));
        dispatch(dataSetMarkets(data?.markets ? [...data.markets] : []));
        dispatch(dataSetMarks(data?.marks ? [...data.marks] : []));
        dispatch(dataSetInspectionPoints(data?.inspectionPoints ? [...data.inspectionPoints] : []));
        dispatch(dataSetRegions(data?.regions ? [...data.regions] : []));
        dispatch(dataSetCountries(data?.countries ? [...data.countries] : []));
        dispatch(dataSetPalletBaseTypes(data?.palletBaseTypes ? [...data.palletBaseTypes] : []));
        dispatch(dataSetInventories(data?.inventories ? [...data.inventories] : []));
        dispatch(dataSetContactInfos(data?.contactInfos ? [...data.contactInfos] : []));
        dispatch(dataSetTruckTypes(data?.truckTypes ? [...data.truckTypes] : []));
        dispatch(dataSetCarriers(data?.carriers ? [...data.carriers] : []));
    }
}

export function dataSetAllMaterialDispatchViewRelatedData(data : IMaterialDispatchViewRelatedData) {
    dispatch(dataSetMaterialDispatchViews(data?.materialDispatches ? [...data.materialDispatches] : []));
    dispatch(dataSetStocks(data?.stocks ? [...data.stocks] : []));
    dispatch(dataSetTrips(data?.trips ? [...data.trips] : []));

    if (!self.indexedDB) {
        dispatch(dataSetInspectionPoints(data?.inspectionPoints ? [...data.inspectionPoints] : []));
        dispatch(dataSetMarkets(data?.markets ? [...data.markets] : []));
        dispatch(dataSetMarks(data?.marks ? [...data.marks] : []));
        dispatch(dataSetInspectionPoints(data?.inspectionPoints ? [...data.inspectionPoints] : []));
        dispatch(dataSetRegions(data?.regions ? [...data.regions] : []));
        dispatch(dataSetCountries(data?.countries ? [...data.countries] : []));
        dispatch(dataSetPalletBaseTypes(data?.palletBaseTypes ? [...data.palletBaseTypes] : []));
        dispatch(dataSetInventories(data?.inventories ? [...data.inventories] : []));
        dispatch(dataSetContactInfos(data?.contactInfos ? [...data.contactInfos] : []));
        dispatch(dataSetTruckTypes(data?.truckTypes ? [...data.truckTypes] : []));
        dispatch(dataSetCarriers(data?.carriers ? [...data.carriers] : []));
    }
}

export function dataSetAllDispatchDashboardViewRelatedData(data : IDispatchViewRelatedData) {
    dispatch(dataSetDispatchViews(data?.dispatches ? [...data.dispatches] : []));
    dispatch(dataSetStocks(data?.stocks ? [...data.stocks] : []));
    dispatch(dataSetCompliances(data?.compliances ? [...data.compliances] : []));
    dispatch(dataSetTrips(data?.trips ? [...data.trips] : []));

    if (!self.indexedDB) {
        dispatch(dataSetMarkets(data?.markets ? [...data.markets] : []));
        dispatch(dataSetMarks(data?.marks ? [...data.marks] : []));
        dispatch(dataSetInspectionPoints(data?.inspectionPoints ? [...data.inspectionPoints] : []));
        dispatch(dataSetRegions(data?.regions ? [...data.regions] : []));
        dispatch(dataSetCountries(data?.countries ? [...data.countries] : []));
        dispatch(dataSetPalletBaseTypes(data?.palletBaseTypes ? [...data.palletBaseTypes] : []));
        dispatch(dataSetTruckTypes(data?.truckTypes ? [...data.truckTypes] : []));
        dispatch(dataSetCarriers(data?.carriers ? [...data.carriers] : []));
    }
}

export function dataClearAllDispatchViewRelatedData() {
    dispatch(dataSetDispatchViews([]));
    dispatch(dataSetStocks([]));
    dispatch(dataSetTrips([]));

    if (!self.indexedDB) {
        dispatch(dataSetInspectionPoints([]));
        dispatch(dataSetMarkets([]));
        dispatch(dataSetMarks([]));
        dispatch(dataSetRegions([]));
        dispatch(dataSetCountries([]));
        dispatch(dataSetPalletBaseTypes([]));
        dispatch(dataSetInventories([]));
        dispatch(dataSetContactInfos([]));
    }
}

export function dataSetDispatchSummaryRelatedData(data : IDispatchSummaryRelatedData) {
    dataSetDispatchInstruction(data?.dispatch);
    dispatch(dataSetStocks(data?.stocks ? [...data.stocks] : []));
    dispatch(dataSetCompliances(data?.compliances ? [...data.compliances] : []));

    if (!self.indexedDB) {
        dispatch(dataSetFarms(data?.farms ? [...data.farms] : []));
        dispatch(dataSetContactInfos(data?.contactInfos ? [...data.contactInfos] : []));
    }
}

export function dataSetMaterialDispatchSummaryRelatedData(data : IMaterialDispatchSummaryRelatedData) {
    dataSetMaterialDispatch(data?.materialDispatch);
    dispatch(dataSetMaterialStocks(data?.materialStocks ? [...data.materialStocks] : []));

    if (!self.indexedDB) {
        dispatch(dataSetContactInfos(data?.contactInfos ? [...data.contactInfos] : []));
    }
}

export function dataSetDispatchEditRelatedData(data : IDispatchEditRelatedData) {
    dataSetDispatchInstruction(data?.dispatch);
    dispatch(dataSetStockWithCartonsDataViews(data?.stocks ? [...data.stocks] : []));

    if (!self.indexedDB) {
        dispatch(dataSetCommodities(data?.commodities ? [...data.commodities] : []));
        dispatch(dataSetVarieties(data?.varieties ? [...data.varieties] : []));
        dispatch(dataSetPacks(data?.packs ? [...data.packs] : []));
        dispatch(dataSetSizes(data?.sizes ? [...data.sizes] : []));
        dispatch(dataSetGrades(data?.grades ? [...data.grades] : []));
        dispatch(dataSetColours(data?.colours ? [...data.colours] : []));
    }
}

export function dataSetDispatchInfoData(data : IDispatchDashboardInfo) {
    dataSetDispatchInstruction(data?.dispatch);
    dispatch(dataSetStocks(data?.stocks ? [...data.stocks] : []));

    if (!self.indexedDB) {
        dispatch(dataSetCommodities(data?.commodities ? [...data.commodities] : []));
        dispatch(dataSetVarieties(data?.varieties ? [...data.varieties] : []));
        dispatch(dataSetPacks(data?.packs ? [...data.packs] : []));
        dispatch(dataSetSizes(data?.sizes ? [...data.sizes] : []));
        dispatch(dataSetGrades(data?.grades ? [...data.grades] : []));
        dispatch(dataSetColours(data?.colours ? [...data.colours] : []));
    }
}

export function dataSetDispatchCorrectionRelatedData(data : IDispatchCorrectionRelatedData) {
    dispatch(dataSetDispatchInstructions(data?.dispatches ? [...data.dispatches] : []));

    if (!self.indexedDB) {
        dispatch(dataSetCommodities(data?.commodities ? [...data.commodities] : []));
        dispatch(dataSetVarieties(data?.varieties ? [...data.varieties] : []));
        dispatch(dataSetPacks(data?.packs ? [...data.packs] : []));
        dispatch(dataSetSizes(data?.sizes ? [...data.sizes] : []));
        dispatch(dataSetGrades(data?.grades ? [...data.grades] : []));
        dispatch(dataSetColours(data?.colours ? [...data.colours] : []));
    }
}

export function dataSetMultiDispatchWizardData(data : IMultiDispatchWizardRelatedData) {
    dispatch(dataSetMultiDispatchStockViews(data?.stocks ? [...data.stocks] : []));

    if (!self.indexedDB) {
        dispatch(dataSetPacks(data?.packs ? [...data.packs] : []));
        dispatch(dataSetSizes(data?.sizes ? [...data.sizes] : []));
        dispatch(dataSetGrades(data?.grades ? [...data.grades] : []));
        dispatch(dataSetCommodities(data?.commodities ? [...data.commodities] : []));
        dispatch(dataSetVarieties(data?.varieties ? [...data.varieties] : []));
        dispatch(dataSetColours(data?.colours ? [...data.colours] : []));
        dispatch(dataSetCarriers(data?.carriers ? [...data.carriers] : []));
        dispatch(dataSetTruckTypes(data?.truckTypes ? [...data.truckTypes] : []));
        dispatch(dataSetMarkets(data?.markets ? [...data.markets] : []));
        dispatch(dataSetRegions(data?.regions ? [...data.regions] : []));
        dispatch(dataSetFarms(data?.farms ? [...data.farms] : []));
        dispatch(dataSetSalesPoints(data?.farms ? [...data.salesPoints] : []));
    }
}
export function dataSetDispatchWizardMasterDataData(data : IStockRelatedData) {
    if (!self.indexedDB) {
        dispatch(dataSetFarms(data?.farms ? [...data.farms] : []));
        dispatch(dataSetMarkets(data?.markets ? [...data.markets] : []));
        dispatch(dataSetMarks(data?.marks ? [...data.marks] : []));
        dispatch(dataSetRegions(data?.regions ? [...data.regions] : []));
        dispatch(dataSetCountries(data?.countries ? [...data.countries] : []));
        dispatch(dataSetCommodities(data?.commodities ? [...data.commodities] : []));
        dispatch(dataSetCommodityConditions(data?.commodityConditions ? [...data.commodityConditions] : []));
        dispatch(dataSetVarieties(data?.varieties ? [...data.varieties] : []));
        dispatch(dataSetPacks(data?.packs ? [...data.packs] : []));
        dispatch(dataSetSizes(data?.sizes ? [...data.sizes] : []));
        dispatch(dataSetStorageUnits(data?.storageUnits ? [...data.storageUnits] : []));
        dispatch(dataSetGrades(data?.grades ? [...data.grades] : []));
        dispatch(dataSetColours(data?.colours ? [...data.colours] : []));
        dispatch(dataSetPalletBaseTypes(data?.palletBaseTypes ? [...data.palletBaseTypes] : []));
        dispatch(dataSetCarriers(data?.carriers ? [...data.carriers] : []));
        dispatch(dataSetTruckTypes(data?.truckTypes ? [...data.truckTypes] : []));
    }
}
export function dataSetDispatchWizardStockData(data : IStockRelatedData) {
    dispatch(dataSetStocks(data?.stocks ? [...data.stocks] : []));
    dispatch(dataSetCompliances(data?.compliances ? [...data.compliances] : []));
    dispatch(dataSetTrips(data?.trips ? [...data.trips] : []));
    dispatch(dataSetBatches(data?.batches ? [...data.batches] : []));

    if (!self.indexedDB) {
        dispatch(dataSetOrchards(data?.orchards ? [...data.orchards] : []));
    }
}
export function dataSetDispatchWizardFinalStepData(data : IStockRelatedData) {
    dispatch(dataSetDispatchInstructions(data?.dispatches ? [...data.dispatches] : []));
    dispatch(dataSetCompliances(data?.compliances ? [...data.compliances] : []));
}

export const getDispatchInstructions = (state : IRootState) => state.data.dispatchInstructions ?? [];

export const getShowOnlyMyDispatches = (state : IRootState) => state.data.dashboardShowOnlyMyDispatches;
export const getUserId = (state : IRootState) => state.auth.session?.userId;

export const mapDashboardDispatchLines = createSelector([getDispatchInstructions, getShowOnlyMyDispatches, getUserId],
    (dispatchInstructions : Array<IDispatchInstruction>, dashboardShowOnlyMyDispatches : boolean, userId ?: number) => {

        let dashboardDispatchInstructionLines : Array<IDashboardDispatchInstructionLine> = [];
        dispatchInstructions.filter(x => x.isActive).forEach((x) => {
            x.dispatchLines.filter(y => y.isActive && (dashboardShowOnlyMyDispatches ? y.updatedBy === userId : true)).forEach((y) => {
                const index = dashboardDispatchInstructionLines.findIndex(z => z.organizationId === y.organizationId && z.commodityId === y.commodityId && z.varietyId === y.varietyId &&
                    z.packId === y.packId && z.sizeId === y.sizeId && z.colourId === y.colourId && z.gradeId === y.gradeId && z.dispatchId === x.id);
                if (index !== -1) {
                    const instructionLine = dashboardDispatchInstructionLines[index];
                    instructionLine.dispatchLines.push(y);
                    dashboardDispatchInstructionLines = setArrayElement(dashboardDispatchInstructionLines, index, instructionLine);
                } else {
                    dashboardDispatchInstructionLines.push({
                        dispatchId: x.id,
                        organizationId: y.organizationId,
                        commodityId: y.commodityId,
                        varietyId: y.varietyId,
                        packId: y.packId,
                        sizeId: y.sizeId,
                        gradeId: y.gradeId,
                        targetMarketId: y.targetMarketId,
                        colourId: y.colourId,
                        dispatchLines: [y],
                    });
                }
            });
        });
        return dashboardDispatchInstructionLines;
    });

export function dataSetAllPOFileRelatedData(data : IPOFileRelatedData) {
    if (!self.indexedDB) {
        dispatch(dataSetFarms(data?.farms ? [...data.farms] : []));
        dispatch(dataSetMarkets(data?.markets ? [...data.markets] : []));
        dispatch(dataSetMarks(data?.marks ? [...data.marks] : []));
        dispatch(dataSetRegions(data?.regions ? [...data.regions] : []));
        dispatch(dataSetCountries(data?.countries ? [...data.countries] : []));
        dispatch(dataSetCommodities(data?.commodities ? [...data.commodities] : []));
        dispatch(dataSetVarieties(data?.varieties ? [...data.varieties] : []));
        dispatch(dataSetPacks(data?.packs ? [...data.packs] : []));
        dispatch(dataSetSizes(data?.sizes ? [...data.sizes] : []));
        dispatch(dataSetGrades(data?.grades ? [...data.grades] : []));
        dispatch(dataSetColours(data?.colours ? [...data.colours] : []));
        dispatch(dataSetOrchards(data?.orchards ? [...data.orchards] : []));
        dispatch(dataSetPalletBaseTypes(data?.palletBaseTypes ? [...data.palletBaseTypes] : []));
        dispatch(dataSetInventories(data?.inventories ? [...data.inventories] : []));
    }
}

/* Trip Functions */

export function dataSetTrip(value : ITrip) {
    const state = getState();
    const position = state.data.trips.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetTrips(setArrayElement(state.data.trips, position, { ...value })));
    } else {
        dispatch(dataSetTrips(addArrayElement(state.data.trips, { ...value })));
    }
}

export function dataSetTripRelatedData(data : ITripRelatedData) {
    dispatch(dataSetDispatchViews(data?.dispatchViews ? [...data.dispatchViews] : []));
    dispatch(dataSetTrips(data?.trips ? [...data.trips] : []));

    if (!self.indexedDB) {
        dispatch(dataSetCarriers(data?.carriers ? [...data.carriers] : []));
        dispatch(dataSetTruckTypes(data?.truckTypes ? [...data.truckTypes] : []));
    }
}

/* Compliance Functions */

export function dataSetCompliance(value : ICompliance) {
    const state = getState();
    const position = state.data.compliances.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetCompliances(setArrayElement(state.data.compliances, position, { ...value })));
    } else {
        dispatch(dataSetCompliances(addArrayElement(state.data.compliances, { ...value })));
    }
}

/* Lot Functions */

export function dataSetLot(value : ILot) {
    const state = getState();
    const position = state.data.lots.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetLots(setArrayElement(state.data.lots, position, { ...value })));
    } else {
        dispatch(dataSetLots(addArrayElement(state.data.lots, { ...value })));
    }
}

export function dataSetLotView(value : ILotView) {
    const state = getState();
    const position = state.data.lotViews.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetLotViews(setArrayElement(state.data.lotViews, position, { ...value })));
    } else {
        dispatch(dataSetLotViews(addArrayElement(state.data.lotViews, { ...value })));
    }
}

export function dataSetLotScreenData(data : ILotViewRelatedData) {
    dispatch(dataSetLotViews(data?.lotViews ? [...data.lotViews] : []));
    dispatch(dataSetBatches(data?.batches ? [...data.batches] : []));

    if (!self.indexedDB) {
        dispatch(dataSetFarms(data?.farms ? [...data.farms] : []));
        dispatch(dataSetStorageUnits(data?.storageUnits ? [...data.storageUnits] : []));
        dispatch(dataSetLotTypes(data?.lotTypes ? [...data.lotTypes] : []));
        dispatch(dataSetCommodityStates(data?.commodityStates ? [...data.commodityStates] : []));
        dispatch(dataSetPacks(data?.packs ? [...data.packs] : []));
    }
}

export function dataSetLotImportRelatedData(data : ILotImportRelatedData) {
    if (!self.indexedDB) {
        dispatch(dataSetCommodities(data?.commodities ? [...data.commodities] : []));
        dispatch(dataSetVarieties(data?.varieties ? [...data.varieties] : []));
        dispatch(dataSetBatches(data?.batches ? [...data.batches] : []));
        dispatch(dataSetOrchards(data?.orchards ? [...data.orchards] : []));
    }
}

export function dataSetLotSummaryData(data : ILotSummaryRelatedData) {
    dataSetLot(data?.lot);
    if (!!data.batch) {
        dataSetBatch(data?.batch);
    }

    if (!self.indexedDB) {
        if (!!data.commodity) {
            dataSetCommodity(data?.commodity);
        }
        if (!!data.variety) {
            dataSetVariety(data?.variety);
        }
        if (!!data.orchard) {
            dataSetOrchard(data?.orchard);
        }
        if (!!data.department) {
            dataSetDepartment(data?.department);
        }
        if (!!data.pack) {
            dataSetPack(data?.pack);
        }
    }
}

export function dataSetAllLotRelatedData(data : ILotRelatedData) {
    dispatch(dataSetLots(data?.lots ? [...data.lots] : []));
    dispatch(dataSetStocks(data?.stocks ? [...data.stocks] : []));
    dispatch(dataSetBatches(data?.batches ? [...data.batches] : []));

    if (!self.indexedDB) {
        dispatch(dataSetStorageUnits(data?.storageUnits ? [...data.storageUnits] : []));
        dispatch(dataSetFarms(data?.farms ? [...data.farms] : []));
        dispatch(dataSetCommodities(data?.commodities ? [...data.commodities] : []));
        dispatch(dataSetCommodityStates(data?.commodityStates ? [...data.commodityStates] : []));
        dispatch(dataSetVarieties(data?.varieties ? [...data.varieties] : []));
        dispatch(dataSetPacks(data?.packs ? [...data.packs] : []));
        dispatch(dataSetOrchards(data?.orchards ? [...data.orchards] : []));
        dispatch(dataSetPalletBaseTypes(data?.palletBaseTypes ? [...data.palletBaseTypes] : []));
        dispatch(dataSetLotTypes(data?.lotTypes ? [...data.lotTypes] : []));
    }
}

/* Intake Functions */

export function dataSetIntake(value : IIntake) {
    const state = getState();
    const position = state.data.intakes.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetIntakes(setArrayElement(state.data.intakes, position, { ...value })));
    } else {
        dispatch(dataSetIntakes(addArrayElement(state.data.intakes, { ...value })));
    }
}

export function dataSetIntakeView(value : IIntakeView) {
    const state = getState();
    const position = state.data.intakeViews.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetIntakeViews(setArrayElement(state.data.intakeViews, position, { ...value })));
    } else {
        dispatch(dataSetIntakeViews(addArrayElement(state.data.intakeViews, { ...value })));
    }
}

export function dataSetAllIntakeRelatedData(data : IIntakeRelatedData) {
    dispatch(dataSetBatches(data?.batches ? [...data.batches] : []));
    dispatch(dataSetLots(data?.lots ? [...data.lots] : []));
    dispatch(dataSetIntakes(data?.intakes ? [...data.intakes] : []));
    dispatch(dataSetStocks(data?.stocks ? [...data.stocks] : []));

    if (!self.indexedDB) {
        dispatch(dataSetStorageUnits(data?.storageUnits ? [...data.storageUnits] : []));
        dispatch(dataSetFarms(data?.farms ? [...data.farms] : []));
        dispatch(dataSetCommodities(data?.commodities ? [...data.commodities] : []));
        dispatch(dataSetVarieties(data?.varieties ? [...data.varieties] : []));
        dispatch(dataSetPacks(data?.packs ? [...data.packs] : []));
        dispatch(dataSetOrchards(data?.orchards ? [...data.orchards] : []));
        dispatch(dataSetPalletBaseTypes(data?.palletBaseTypes ? [...data.palletBaseTypes] : []));
        dispatch(dataSetLotTypes(data?.lotTypes ? [...data.lotTypes] : []));
    }
}

export function dataSetIntakeViewRelatedData(data : IIntakeViewRelatedData) {
    dispatch(dataSetIntakeViews(data?.intakeViews ? [...data.intakeViews] : []));

    if (!self.indexedDB) {
        dispatch(dataSetFarms(data?.farms ? [...data.farms] : []));
        dispatch(dataSetCommodities(data?.commodities ? [...data.commodities] : []));
        dispatch(dataSetPacks(data?.packs ? [...data.packs] : []));
        dispatch(dataSetPalletBaseTypes(data?.palletBaseTypes ? [...data.palletBaseTypes] : []));
        dispatch(dataSetLotTypes(data?.lotTypes ? [...data.lotTypes] : []));
        dispatch(dataSetCommodityStates(data?.commodityStates ? [...data.commodityStates] : []));
    }
}

export function dataSetIntakeSummaryRelatedData(data : IIntakeSummaryRelatedData) {
    dataSetIntake(data.intake);
    dispatch(dataSetLots(data?.lots ? [...data.lots] : []));

    if (!self.indexedDB) {
        dispatch(dataSetStorageUnits(data?.storageUnits ? [...data.storageUnits] : []));
        dispatch(dataSetVarieties(data?.varieties ? [...data.varieties] : []));
        dispatch(dataSetOrchards(data?.orchards ? [...data.orchards] : []));
    }
}

/* Batch Functions */

export function dataSetBatch(value : IBatch) {
    const state = getState();
    const position = state.data.batches.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetBatches(setArrayElement(state.data.batches, position, { ...value })));
    } else {
        dispatch(dataSetBatches(addArrayElement(state.data.batches, { ...value })));
    }
}

export function dataSetBatchView(value : IBatchView) {
    const state = getState();
    const position = state.data.batchViews.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetBatchViews(setArrayElement(state.data.batchViews, position, { ...value })));
    } else {
        dispatch(dataSetBatchViews(addArrayElement(state.data.batchViews, { ...value })));
    }
}

export function dataSetBatchScreenData(data : IBatchViewRelatedData) {
    dispatch(dataSetBatchViews(data?.batchViews ? [...data.batchViews] : []));
    dispatch(dataSetLots(data?.lots ? [...data.lots] : []));

    if (!self.indexedDB) {
        dispatch(dataSetFarms(data?.farms ? [...data.farms] : []));
        dispatch(dataSetCommodities(data?.commodities ? [...data.commodities] : []));
        dispatch(dataSetVarieties(data?.varieties ? [...data.varieties] : []));
        dispatch(dataSetLotTypes(data?.lotTypes ? [...data.lotTypes] : []));
        dispatch(dataSetCommodityStates(data?.commodityStates ? [...data.commodityStates] : []));
    }
}

export function dataSetBatchSummaryPopupData(data : IBatchSummaryRelatedData) {
    dataSetBatch(data?.batch);
    dispatch(dataSetLots(data?.lots ? [...data.lots] : []));

    if (!self.indexedDB) {
        dispatch(dataSetLotTypes(data?.lotTypes ? [...data.lotTypes] : []));
        dispatch(dataSetOrchards(data?.orchards ? [...data.orchards] : []));
    }
}

export function dataSetBatchReportRelatedData(data : IBatchReportRelatedData) {
    dispatch(dataSetStocks(data?.stocks ? [...data.stocks] : []));

    if (!self.indexedDB) {
        dispatch(dataSetPacks(data?.packs ? [...data.packs] : []));
    }
}

/* Material Functions */

export function dataSetMaterialStock(value : IMaterialStock) {
    const state = getState();
    const position = state.data.materialStocks.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetMaterialStocks(setArrayElement(state.data.materialStocks, position, { ...value })));
    } else {
        dispatch(dataSetMaterialStocks(addArrayElement(state.data.materialStocks, { ...value })));
    }
}

export function dataSetMaterialStockView(value : IMaterialStockView) {
    const state = getState();
    const position = state.data.materialStockViews.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetMaterialStockViews(setArrayElement(state.data.materialStockViews, position, { ...value })));
    } else {
        dispatch(dataSetMaterialStockViews(addArrayElement(state.data.materialStockViews, { ...value })));
    }
}

export function dataSetMaterialDispatch(value : IMaterialDispatch) {
    const state = getState();
    const position = state.data.materialDispatches.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetMaterialDispatches(setArrayElement(state.data.materialDispatches, position, { ...value })));
    } else {
        dispatch(dataSetMaterialDispatches(addArrayElement(state.data.materialDispatches, { ...value })));
    }
}

export function dataSetTempStockUnit(value : ITempStockUnit) {
    const state = getState();
    const position = state.data.tempStockUnits.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetTempStockUnits(setArrayElement(state.data.tempStockUnits, position, { ...value })));
    } else {
        dispatch(dataSetTempStockUnits(addArrayElement(state.data.tempStockUnits, { ...value })));
    }
}

export function dataSetMaterialStockSummaryRelatedData(data : IMaterialStockSummaryRelatedData) {
    dataSetMaterialStock(data.materialStock);
    dispatch(dataSetStocks(data ? data.stocks : []));
    dispatch(dataSetDispatchInstructions(data ? data.dispatches : []));
    dispatch(dataSetMaterialDispatches(data ? data.materialDispatches : []));

    if (!self.indexedDB) {
        dispatch(dataSetMaterials(data ? data.materials : []));
        dispatch(dataSetMaterialTypes(data ? data.materialTypes : []));
        dispatch(dataSetUnitOfMeasures(data ? data.unitOfMeasures : []));
        dispatch(dataSetUnitOfMeasureTypes(data ? data.unitOfMeasureTypes : []));
    }
}

export function dataSetAllMaterialRelatedData(data : IMaterialRelatedData) {
    dispatch(dataSetMaterialDispatches(data ? data.materialDispatches : []));
    dispatch(dataSetMaterialStocks(data ? data.materialStocks : []));
    dispatch(dataSetStocks(data ? data.stocks : []));
    dispatch(dataSetTempStockUnits(data ? data.tempStockUnits : []));
    dispatch(dataSetTrips(data ? data.trips : []));
    dispatch(dataSetDispatchInstructions(data ? data.dispatches : []));

    if (!self.indexedDB) {
        dispatch(dataSetMaterials(data ? data.materials : []));
        dispatch(dataSetMaterialTypes(data ? data.materialTypes : []));
        dispatch(dataSetUnitOfMeasures(data ? data.unitOfMeasures : []));
        dispatch(dataSetUnitOfMeasureTypes(data ? data.unitOfMeasureTypes : []));
        dispatch(dataSetPacks(data ? data.packs : []));
        dispatch(dataSetPalletBaseTypes(data ? data.palletBaseTypes : []));
        dispatch(dataSetCarriers(data ? data.carriers : []));
        dispatch(dataSetTruckTypes(data ? data.truckTypes : []));
    }
}

/* Sale Functions */

export function dataSetSaleLineRelatedData(data : ISaleLineRelatedData) {
    dispatch(dataSetStocks(data?.stocks ? [...data.stocks] : []));
    if (!self.indexedDB) {
        dispatch(dataSetSeasons(data ? data.seasons : []));
        dispatch(dataSetFarms(data ? data.farms : []));
        dispatch(dataSetMarkets(data ? data.markets : []));
        dispatch(dataSetMarks(data ? data.marks : []));
        dispatch(dataSetGrades(data ? data.grades : []));
        dispatch(dataSetCommodities(data ? data.commodities : []));
        dispatch(dataSetVarieties(data ? data.varieties : []));
        dispatch(dataSetCountries(data ? data.countries : []));
        dispatch(dataSetPacks(data ? data.packs : []));
        dispatch(dataSetInventories(data ? data.inventories : []));
        dispatch(dataSetRegions(data ? data.regions : []));
    }
}

/* Roles */

export function dataSetRole(value : IRole) {
    const state = getState();
    const position = state.data.roles.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetRoles(setArrayElement(state.data.roles, position, { ...value })));
    } else {
        dispatch(dataSetRoles(addArrayElement(state.data.roles, { ...value })));
    }
}

/* Frequently Asked Question */

export function dataSetFaq(value : IFaq) {
    const state = getState();
    const position = state.data.frequentlyAskedQuestions.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetFAQs(setArrayElement(state.data.frequentlyAskedQuestions, position, { ...value })));
    } else {
        dispatch(dataSetFAQs(addArrayElement(state.data.frequentlyAskedQuestions, { ...value })));
    }
}

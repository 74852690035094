import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IMaterial } from '../../../@types/model/masterData/material/material';

export default class MaterialHttpService {
    public static getMaterialData() : AxiosPromise<Array<IMaterial>> {
        return axios.get(`${PACKMAN_API_URL}Material/GetAllWeb`);
    }

    public static addOrUpdateMaterial(data : IMaterial) : AxiosPromise<IMaterial> {
        return axios.post(`${PACKMAN_API_URL}Material/PostMaterial`, data);
    }

    public static deleteMaterial(materialId : number) : AxiosPromise<IMaterial> {
        return axios.delete(`${PACKMAN_API_URL}Material/Delete`, {
            params : { materialId },
        });
    }
}

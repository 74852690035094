import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IMaterialType } from '../../../@types/model/masterData/materialType/materialType';

export default class MaterialTypeHttpService {
    public static getMaterialTypeData() : AxiosPromise<Array<IMaterialType>> {
        return axios.get(`${PACKMAN_API_URL}MaterialType/GetAllWeb`);
    }

    public static addOrUpdateMaterialType(data : IMaterialType) : AxiosPromise<IMaterialType> {
        return axios.post(`${PACKMAN_API_URL}MaterialType/PostMaterialType`, data);
    }

    public static deleteMaterialType(materialTypeId : number) : AxiosPromise<IMaterialType> {
        return axios.delete(`${PACKMAN_API_URL}MaterialType/Delete`, {
            params : { materialTypeId },
        });
    }
}

import { DATE_FORMAT_TIMESTAMP_FROM_API } from '../../../../appConstants';
import { IBaseModel } from '../../../model';
import { IProjectFormValues } from './projectFormValues';

export interface IProject extends IBaseModel {
    commodityId : number;
    number : string;
    description : string;
    isImported ?: boolean;
    endedOn ?: string;
}

export class Project implements IProject {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public number : string;
    public description : string;
    public commodityId : number;
    public endedOn ?: string;
    public isImported ?: boolean;

    public constructor(formValues : IProjectFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.number = formValues.number;
        this.description = formValues.description;
        this.commodityId = Number(formValues.commodity?.value) ?? 0;
        this.isActive = formValues.isActive;
        this.isImported = formValues.isImported;
        this.endedOn = formValues.endedOn != null ? formValues.endedOn?.utc().format(DATE_FORMAT_TIMESTAMP_FROM_API) : '';
    }
}

import * as React from 'react';
import { connect } from 'react-redux';
import { IAuthState, IRootState, RootAction } from '../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Typography, Button, Tooltip, Card } from '@mui/material';
import { formatDateTime, compareDate, removeArrayElement, addArrayElement, getCSVStockLineSummary, setArrayElement, formatDateTimeToDateOnly } from '../../services/appFunctionsService';
import CustomTable, { ICustomTableColumn } from '../../components/datagrid/CustomTable';
import { IStock } from '../../@types/model/stock/stock';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ISite } from '../../@types/model/masterData/site/site';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import Screen from '../../components/Screen';
import PillButton from '../../components/input/PillButton';
import PopupOptionButton from '../../components/button/PopupOptionButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import EmailIcon from '@mui/icons-material/Email';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import FTPHttpService from '../../services/http/integration/ftp/ftpHttpService';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import FileSelector from '../../components/input/FileSelector';
import { IGoogleCloudStorage } from '../../@types/model/googleCloudStorage/googleCloudStorage';
import GoogleCloudStorageHttpService from '../../services/googleCloudStorageService';
import ConfirmationPrompt from '../../components/dialog/ConfirmationPrompt';
import DispatchHttpService from '../../services/http/dispatch/dispatchHttpService';
import { createSelector } from 'reselect';
import { dataSetDispatchSummaryRelatedData, dataSetDispatchInstruction } from '../../store/data/Functions';
import lodash, { flatten, uniq } from 'lodash';
import { IStockLine } from '../../@types/model/stock/stockLine';
import { generateBarcodesFromDispatch } from '../../services/compliance/documentService';
import { Formik, FormikActions } from 'formik';
import { EmailFormValues, IEmailFormValues } from '../../@types/model/email/emailFormValues';
import { VERSION } from '../../../version';
import EmailForm from '../../components/email/EmailForm';
import { IContactInfo } from '../../@types/model/masterData/contactInfo/contactInfo';
import { ICompliance } from '../../@types/model/compliance/compliance';
import { IPOFileUpload } from '../../@types/model/dispatch/poFileUpload';
import { IPOFileEmail } from '../../@types/model/dispatch/poFileEmail';
import { Email } from '../../@types/model/email/email';
import EmailHttpService from '../../services/http/integration/email/emailHttpService';
import FileSaver from 'file-saver';
import { RouteComponentProps, withRouter } from 'react-router';
import { PackmanLink } from '../../components/link/packmanLink';
import { PackmanLabel } from '../../components/label/PackmanLabel';
import { CropFree } from '@mui/icons-material';
import { IDispatchLineView } from '../../@types/model/dispatch/dispatchLineView';
import { IDispatchInstruction } from '../../@types/model/dispatch/dispatchInstruction';
import CustomTooltip from '../../components/tooltip/tooltip';
import { syncMasterData } from '../../services/masterDataSyncService';
import { BooleanFlag } from '../../components/label/BooleanFlag';
import PrintServerReportHttpService from '../../services/http/printServerReport/printServerReportHttpService';
import { IPrintServer } from '../../@types/model/masterData/printServer/printServer';
import { IReport } from '../../@types/model/masterData/report/report';
import { IReportFileFormValues, ReportFileFormValues } from '../../@types/model/reportFile/reportFileFormValues';
import ReportFileForm from '../../components/reportFile/ReportFileForm';

const path = 'DispatchNotes/';

interface IDispatchInstructionSummaryProps extends RouteComponentProps {
    selectedDispatchId : number;
    organizations : Array<IOrganization>;
    sites : Array<ISite>;
    printServers : Array<IPrintServer>;
    reports : Array<IReport>;
    selectedSiteIds : Array<number>;
    farms : Array<IFarm>;
    contactInfos : Array<IContactInfo>;
    auth : IAuthState;
    complainces : Array<ICompliance>;
    stockData : Array<IStock>;
}

interface IDispatchInstructionSummaryState {
    isLoading : boolean;
    selectedDispatch ?: IDispatchInstruction;
    dispatchLineViews : Array<IDispatchLineView>;
    stockData : Array<IStock>;

    onYesClick : () => void;
    onNoClick : () => void;
    message : string;
    showPrompt : boolean;
    openDispatchFileUploadDialog : boolean;
    openDispatchFileDownloadDialog : boolean;
    dispatchDownloadFiles ?: Array<any>;
    selectedFiles : Array<File>;
    setLineAsSubstitutable : boolean;
    selectedDispatchLineView ?: IDispatchLineView;
    isEmailFormOpen : boolean;
    isDownloadReportFormOpen : boolean;

    isPOFileOrchardConfirmDialogOpen : boolean;
    poFileAction : string;
    includeOrchardsOnPOFile : boolean;
}

class DispatchInstructionSummary extends React.Component<IDispatchInstructionSummaryProps, IDispatchInstructionSummaryState> {
    constructor(props : IDispatchInstructionSummaryProps) {
        super(props);

        this.state = {
            isLoading: false,
            dispatchLineViews: [],
            stockData: [],

            onYesClick: () => null,
            onNoClick: () => null,
            message: '',
            showPrompt: false,

            openDispatchFileUploadDialog: false,
            openDispatchFileDownloadDialog: false,
            selectedFiles: [],
            setLineAsSubstitutable: false,
            isEmailFormOpen: false,
            isDownloadReportFormOpen: false,

            isPOFileOrchardConfirmDialogOpen: false,
            poFileAction: '',
            includeOrchardsOnPOFile: false,
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }
        try {
            const res = await DispatchHttpService.getDispatchSummaryData(this.props.selectedDispatchId, !isIndexedDBAvailable);

            if (res) {
                dataSetDispatchSummaryRelatedData(res.data);
                this.setState({ selectedDispatch: res.data.dispatch, dispatchLineViews: res.data.dispatchLineViews, stockData: res.data.stocks, isLoading: false });
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading dispatch summary data.');
        } finally {
            this.setLoading(false);
        }
    };

    private getSiteDescription = (siteId : number) => {
        const sites = this.props.sites;
        const site = sites && sites.find(x => x.id === siteId);
        return site ? site.description : '';
    };

    public onEmailReset = async (formValues : IEmailFormValues, formikActions : FormikActions<IEmailFormValues>) => {
        formikActions.resetForm();
        this.closeEmailForm();
    };

    public onReportFileReset = async (formValues : IReportFileFormValues, formikActions : FormikActions<IReportFileFormValues>) => {
        formikActions.resetForm();
        this.closeDownloadReportPopup();
    };

    private onEmailSubmit = async (value : IEmailFormValues) => {
        if (this.state.selectedDispatch) {
            try {
                const data : IPOFileEmail = {
                    dispatchId: this.state.selectedDispatch.id,
                    versionNumber: VERSION.version,
                    emailData: new Email(value, []),
                    includeOrchards: this.state.includeOrchardsOnPOFile,
                };

                const res = await EmailHttpService.emailPOFile(data);

                if (res) {
                    generalShowSuccessSnackbar('PO file successfully emailed');
                }
                this.closeEmailForm();
                const dispatch = { ...this.state.selectedDispatch };
                dispatch.poEmailed = true;
                this.setState({ selectedDispatch: dispatch });
                dataSetDispatchInstruction(dispatch);
                this.closeEmailForm();
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while emailing the PO file.');
            } finally {
                this.setLoading(false);
            }
        }
    };

    private getOrganizationName = (orgId : number) => {
        const organizations = this.props.organizations;
        const organization = organizations && organizations.find(x => x.id === orgId);
        return organization ? organization.name : '';
    };

    private getOrganizationCode = (orgId : number) => {
        const org = this.props.organizations.find(x => x.id === orgId);
        return org ? org.code : '';
    };

    private getStockBarcode = (row : IDispatchLineView) => {
        const stock = this.state.stockData.find(x => x.id === row.currentStockId);
        return stock ? <PackmanLink
            type={'transactions'}
            targetPage={'stock'}
            id={stock.id}
            text={stock.barcode} /> : '';
    };

    private getStockFarm = (stockId : number, row : IDispatchLineView) => {
        const stock = this.state.stockData.find(x => x.id === row.currentStockId);
        if (stock) {
            const mainStockLine  = this.getMainStockLine(stock);
            if (mainStockLine) {
                return this.getFarmName(mainStockLine.farmId);
            } else {
                return '';
            }
        }
    };

    private getCartons = (id : number, row : IDispatchLineView) => {
        const stock = this.state.stockData.find(x => x.id === row.currentStockId);
        const stockLines = stock?.stockLines;
        return this.getCartonsFromStockLines(stockLines ? stockLines : []);
    };

    private getCartonsFromStockLines = (stockLines : Array<IStockLine>) => getCSVStockLineSummary(stockLines.filter(x => x.isActive && x.cartons > 0))
        .map(x => x.cartons).toString().replace(/,/g, ', ');

    private getInners = (id : number, row : IDispatchLineView) => {
        const stock = this.state.stockData.find(x => x.id === row.currentStockId);
        const stockLines = stock?.stockLines;
        return this.getTotalInnersFromStockLines(stockLines ? stockLines : []);
    };

    private getTotalInnersFromStockLines = (stockLines : Array<IStockLine>) => getCSVStockLineSummary(stockLines.filter(x => x.isActive && x.totalInners && x.totalInners > 0))
        .map(x => x.totalInners).toString().replace(/,/g, ', ');

    private getFarmName = (farmId : number) => {
        const farms = this.props.farms;
        const farm = farms && farms.find(x => x.id === farmId);
        return farm ? farm.name : '';
    };

    private setLoading = (isLoading : boolean = false) => {
        this.setState({ isLoading });
    };

    private getMainStockLine = (stock : IStock) => {
        if (stock.stockLines.length > 0) {
            let mainStockLine = { ...stock.stockLines[0] };
            stock.stockLines.forEach((x) => {
                if ((mainStockLine.cartons < x.cartons) || ((mainStockLine.cartons === x.cartons) && (mainStockLine.id > x.id))) {
                    mainStockLine = x;
                }
            });

            return mainStockLine;
        }
    };

    private getDispatch = (props : IDispatchInstructionSummaryProps, state : IDispatchInstructionSummaryState) => state.selectedDispatch;
    private getDispatchLineViews = (props : IDispatchInstructionSummaryProps, state : IDispatchInstructionSummaryState) => state.dispatchLineViews;
    private getUserEmail = (props : IDispatchInstructionSummaryProps) => props.auth.session?.user?.email;
    private getSites = (props : IDispatchInstructionSummaryProps) => props.sites;
    private getFarms = (props : IDispatchInstructionSummaryProps) => props.farms;
    private getStocks = (props : IDispatchInstructionSummaryProps, state : IDispatchInstructionSummaryState) => state.stockData;
    private getContactInfos = (props : IDispatchInstructionSummaryProps) => props.contactInfos;

    private generateEmail = createSelector([this.getDispatch, this.getUserEmail, this.getSites, this.getContactInfos, this.getFarms, this.getStocks], (dispatch, userEmail, sites, contactInfos, farms, stocks) => {
        const toAddresses = contactInfos.filter(x =>
            farms.find(y => uniq(flatten(stocks.map(z => z.stockLines.map(a => a.farmId)))).some(z => z === y.id))?.contactInfoIds?.some(y => y === x.id) && !!x.email && x.isActive)?.map(x => x.email ?? '');

        const ccAddresses : Array<string> = !!userEmail ? [userEmail] : [];

        const sourceSite = sites.find(x => x.id === dispatch?.sourceSiteId);
        const destinationSite = sites.find(x => x.id === dispatch?.destinationSiteId);

        const subject = `Packman v${VERSION.version} - PO File - ${dispatch?.dispatchCode} (${sourceSite?.shortDescription} - ${destinationSite?.shortDescription})`;

        const body = `Please find attached the PO FIle for dispatch ${dispatch?.dispatchCode}`;

        const files : Array<string> = ['PO File'];

        return new EmailFormValues(subject, body, toAddresses, ccAddresses, files);
    });

    private generateReportForm = () => new ReportFileFormValues();

    private onPOFileOrchardConfirmClick = async (action : 'Upload' | 'Download' | 'Email') => {
        this.setState({ isPOFileOrchardConfirmDialogOpen: true, poFileAction: action });
    };

    private closePOFileOrchardConfirmDialog = async () => {
        this.setState({ isPOFileOrchardConfirmDialogOpen: false, poFileAction: '' });
    };

    private onPOFileOrchardConfirmYesClick = async () => {
        const poFileActionType = this.state.poFileAction;

        if (poFileActionType !== '') {
            if (poFileActionType === 'Upload') {
                this.onUploadPOFileYesClick(true);
            } else if (poFileActionType === 'Download') {
                this.onPOFileDownloadClick(true);
            } else if (poFileActionType === 'Email') {
                this.setState({ includeOrchardsOnPOFile: true });
                this.closePOFileOrchardConfirmDialog();
                this.showEmailForm();
            }

            this.closePOFileOrchardConfirmDialog();
        }
    };

    private onPOFileOrchardConfirmNoClick = async () => {
        const poFileActionType = this.state.poFileAction;

        if (poFileActionType !== '') {
            if (poFileActionType === 'Upload') {
                this.onUploadPOFileYesClick(false);
            } else if (poFileActionType === 'Download') {
                this.onPOFileDownloadClick(false);
            } else if (poFileActionType === 'Email') {
                this.setState({ includeOrchardsOnPOFile: false });
                this.closePOFileOrchardConfirmDialog();
                this.showEmailForm();
            }

            this.closePOFileOrchardConfirmDialog();
        }
    };

    private onPOFileDownloadClick = async (includeOrchards : boolean) => {
        const dispatch = this.state.selectedDispatch;
        if (dispatch) {
            this.setState({ isLoading: true });
            try {
                const res = await DispatchHttpService.getPOFileDataForDownload(dispatch.id, includeOrchards);

                if (res && res.data) {
                    const fileBlob = new Blob([res.data.fileContent], { type: 'text/plain' });
                    FileSaver.saveAs(fileBlob, res.data.fileName);
                }
            } catch (ex) {
                generalShowErrorSnackbar('Failed to download PO file');
            } finally {
                this.setState({ isLoading: false });
            }
        }
    };

    private showConfirmationPrompt = (onYesClick : () => void, onNoClick : () => void, message : string) => {
        this.setState({ onYesClick, onNoClick, message }, () => {
            this.setState({ showPrompt: true });
        });
    };

    private closeConfirmationPrompt = () => {
        this.setState({ onYesClick: () => null, onNoClick: () => null, message: '', showPrompt: false });
    };

    private onUploadPOFileYesClick = async (includeOrchards : boolean) => {
        const selectedDispatch = this.state.selectedDispatch;
        // const dispatchLineStockIds : Array<number> = selectedDispatch?.dispatchLines?.filter(x => x.isActive).map(x => x.currentStockId) ?? [];
        // const dispatchStocks  = this.props.stockData.filter(x => x.isActive && dispatchLineStockIds.some(y => y === x.id));

        // if ((this.props.farms.find(y => uniq(flatten(dispatchStocks?.map(z => z?.stockLines?.map(a => a.farmId)))).some(z => z === y.id))?.ftpDetailIds?.length ?? 0) < 1) {
        //     generalShowErrorSnackbar('No farms on this dispatch have ftp details');
        //     return;
        // }
        if (!!selectedDispatch) {
            this.setLoading(true);

            try {
                const data : IPOFileUpload = {
                    dispatchId: selectedDispatch.id,
                    versionNumber: VERSION.version,
                    includeOrchards,
                };
                const res = await FTPHttpService.uploadPOFile(data);

                if (res && res.data) {
                    if (!this.state.selectedDispatch) {
                        generalShowErrorSnackbar('Failed to upload PO file');
                        return;
                    }

                    const updatedDispatch : IDispatchInstruction = {
                        ...this.state.selectedDispatch,
                        poUploaded: true,
                    };
                    dataSetDispatchInstruction(updatedDispatch);
                    this.setState({ selectedDispatch: updatedDispatch });

                    generalShowSuccessSnackbar('PO file successfully uploaded');
                }
            } catch (ex) {
                generalShowErrorSnackbar('Failed to upload PO file');
            } finally {
                this.closeConfirmationPrompt();
                this.setLoading(false);
            }
        }
    };

    private showEmailForm = () => {
        this.setState({ isEmailFormOpen: true });
    };

    private closeEmailForm = () => {
        this.setState({ isEmailFormOpen: false });
    };

    private showDownloadReportPopup = async () => {
        this.setState({ isDownloadReportFormOpen: true });
    };

    private downloadReportFile = async (values : IReportFileFormValues) => {
        this.setLoading(true);
        if (this.state.selectedDispatch && values.printServer && values.site && values.report) {
            try {
                const res = await DispatchHttpService.getDispatchAppView(this.state.selectedDispatch.guid);

                const printServer = this.props.printServers.find(x => x.id === values.printServer?.value);
                const report = this.props.reports.find(x => x.id === values.report?.value);

                if (printServer && res && report) {
                    const res2 = await PrintServerReportHttpService.getDispatchReportPreview(printServer, res.data, this.state.selectedDispatch.guid, report.guid);
                    if (res2 && res2.data) {
                        const data = Buffer.from(res2.data).toString('base64');
                        const base64String = 'data:document/pdf;base64,' + data; // Base64 string
                        const byteString = atob(base64String.split(',')[1]);

                        // separate out the mime component
                        const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];
                        // write the bytes of the string to an ArrayBuffer
                        const ab = new ArrayBuffer(byteString.length);
                        // create a view into the buffer
                        const ia = new Uint8Array(ab);
                        // set the bytes of the buffer to the correct values
                        for (let i = 0; i < byteString.length; i++) {
                            ia[i] = byteString.charCodeAt(i);
                        }

                        const blob = new Blob([ab], { type: mimeString }); // Create a BLOB object

                        saveAs(blob, 'Dispatch Report.pdf');
                    }
                }

            } catch (e) {
                generalShowErrorSnackbar('Downloading dispatch report failed!');
            } finally {
                this.setLoading(false);
            }
        }
    };

    private closeDownloadReportPopup = () => {
        this.setState({ isDownloadReportFormOpen: false });
    };

    private submitDispatchCSVUpload = async (id : number) => {
        const oldDispatch = this.state.selectedDispatch;
        this.setLoading(true);
        try {
            const res = await FTPHttpService.uploadDispatchCSV({ dispatchId: id });
            if (oldDispatch) {
                const updatedDispatch : IDispatchInstruction = {
                    ...oldDispatch,
                    teraokaUploaded: true,
                };
                dataSetDispatchInstruction(updatedDispatch);
                this.setState({ selectedDispatch: updatedDispatch });
            }

            if (res && res.data) {
                generalShowSuccessSnackbar('CSV successfully uploaded.');
            } else {
                generalShowErrorSnackbar('CSV upload failed!');
            }
        } catch (e) {
            generalShowErrorSnackbar('CSV upload failed!');
        } finally {
            this.setLoading(false);
        }
    };

    private onUploadTeraokaCSVYesClick = async () => {
        this.setLoading(true);
        await this.submitDispatchCSVUpload(this.state.selectedDispatch?.id ?? 0);

        this.setLoading(false);
        this.closeConfirmationPrompt();
    };

    private openDispatchFileUploadDialog = () => {
        this.setState({ openDispatchFileUploadDialog: true });
    };

    private closeDispatchFileUploadDialog = () => {
        this.setState({ openDispatchFileUploadDialog: false, selectedFiles: [] });
    };

    private openDispatchFileDownloadDialog = () => {
        this.setState({ openDispatchFileDownloadDialog: true }, () => {
            if (this.state.selectedDispatch && this.state.selectedDispatch?.sourceSiteId) {
                this.getDispatchFilesToBeDownloaded(this.state.selectedDispatch?.sourceSiteId);
            }
        });
    };

    private closeDispatchFileDownloadDialog = () => {
        this.setState({ openDispatchFileDownloadDialog: false, dispatchDownloadFiles: [] });
    };

    private getDispatchFilesToBeDownloaded = async (siteId : number) => {
        const site = this.props.sites.find(x => x.id === siteId);
        if (site) {
            this.setLoading(true);
            const folderPath = path + `${site.code + '_' + site.guid}/${this.state.selectedDispatch?.dispatchCode}/`;
            const dispatchAvailableDownloadFiles = await GoogleCloudStorageHttpService.getListData(folderPath);

            if (dispatchAvailableDownloadFiles) {
                this.setState({ dispatchDownloadFiles: dispatchAvailableDownloadFiles.data });
                this.setLoading(false);
            }
        }
    };

    private dispatchFiles = (props : IDispatchInstructionSummaryProps, state : IDispatchInstructionSummaryState) => state.dispatchDownloadFiles;

    private getDispatchFiles = createSelector(
        [this.dispatchFiles],
        (dispatchFiles : Array<any>) => {
            if (!dispatchFiles) return [];

            return dispatchFiles.map(x => this.getDispatchNote(x));
        },
    );

    private getDispatchNote = (dispatchNote : any) => {
        const dispatchNoteName : string = dispatchNote.name;
        const name = dispatchNoteName.split('/')[3];
        return (
            <Tooltip disableInteractive title={'Download Dispatch File?'}>
                <Card style={{ minHeight: 40, maxHeight: 40 }} className={'h40 w350 m5 aic jcc curp'} onClick={() => this.downloadDispatchNote(name, dispatchNote.contentType)}>{name}</Card>
            </Tooltip>
        );
    };

    private downloadDispatchNote = async (fileName : string, contentType : string) => {
        const selectedDispatchSourceSiteId = this.state.selectedDispatch?.sourceSiteId;
        const site = this.props.sites.find(x => x.id === selectedDispatchSourceSiteId);
        const filePath = path + `${site?.code + '_' + site?.guid}/${this.state.selectedDispatch?.dispatchCode}/`;
        if (site) {
            try {
                this.setLoading(true);
                const res = await GoogleCloudStorageHttpService.downloadFile(filePath, fileName);

                if (res) {

                    const byteCharacters = atob(res.data);
                    const byteNumbers = lodash.map(byteCharacters, (x, i) => byteCharacters.charCodeAt(i));
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: contentType });
                    if (blob) {
                        FileSaver.saveAs(blob, fileName);
                        generalShowSuccessSnackbar('Dispatch note downloaded successfully.');
                        this.setLoading(false);
                    }
                }
            } catch (e) {
                generalShowErrorSnackbar('Error while downloading dispatch note.');
                this.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Selected dispatch source site not found!');
        }
    };

    private onFileRemove = (file : File) => {
        const index = this.state.selectedFiles?.findIndex(x => x.name === file.name);
        if (index > -1) {
            this.setState({ selectedFiles: removeArrayElement(this.state.selectedFiles, index) });
        }
    };

    private handleFileInputChange = (files : Array<File>) => {
        files.forEach(x => this.setState({ selectedFiles: addArrayElement(this.state.selectedFiles, x, 'end') }));
    };

    private getSiteCodeAndGuid = (id ?: number) => {
        const sites = this.props.sites;
        const site = sites.find(x => x.id === id);
        if (site) {
            return site.code + '_' + site.guid;
        }
    };

    private onDispatchFilesUpload = async () => {
        const files = this.state.selectedFiles;
        const selectedDispatch = this.state.selectedDispatch;
        const data : Array<IGoogleCloudStorage> = [];
        if (files.length > 0 && selectedDispatch) {
            this.setLoading(true);
            files.forEach((file) => {
                const reader = new FileReader();
                reader.onload = async () => {
                    const fileStr = reader.result;
                    if (typeof fileStr === 'string') {
                        const fileString = fileStr.substr(5);
                        const contentType = fileString.split(';')[0];
                        const startFromPos = fileString.split(',')[0].length;
                        const fileBase64 = fileString.substr((startFromPos + 1));

                        const fileToBeUploaded : IGoogleCloudStorage = {
                            fileName: file?.name,
                            destinationPath: `DispatchNotes/${this.getSiteCodeAndGuid(selectedDispatch?.sourceSiteId)}/${selectedDispatch.dispatchCode}/`,
                            contentType,
                            fileBase64,
                        };

                        data.push(fileToBeUploaded);

                        if (data.length === files.length) {
                            try {
                                const res = await GoogleCloudStorageHttpService.uploadFile(data);
                                if (res) {
                                    this.closeDispatchFileUploadDialog();
                                    generalShowSuccessSnackbar('File uploaded successfully.');
                                    this.setLoading(false);
                                }
                            } catch (e) {
                                this.closeDispatchFileUploadDialog();
                                generalShowErrorSnackbar('Error while uploading file.');
                                this.setLoading(false);
                            }
                        }
                    }
                };
                reader.readAsDataURL(file);
            });
        }
    };

    private onSetLineSubstitutableClick = (row : IDispatchLineView) => {
        this.setState({ selectedDispatchLineView: row, setLineAsSubstitutable: true });
    };

    private onSubstituteCancel = () => {
        this.setState({ setLineAsSubstitutable: false });
    };

    private onSubstituteSubmit = async () => {
        const selectedDispatchLine = this.state.selectedDispatchLineView && { ...this.state.selectedDispatchLineView };
        if (selectedDispatchLine) {
            this.setLoading(true);
            try {
                const res = await DispatchHttpService.setDispatchLineAsSubstitutable(selectedDispatchLine.id);

                if (res && res.data) {
                    let updatedDispatchLineViewList : Array<IDispatchLineView> = this.state.dispatchLineViews;
                    const index = updatedDispatchLineViewList.findIndex(x => x.id === res.data.id);

                    if (index !== -1) {
                        updatedDispatchLineViewList = setArrayElement(updatedDispatchLineViewList, index, res.data);
                    }
                    this.setState({ dispatchLineViews: updatedDispatchLineViewList, isLoading: false, setLineAsSubstitutable: false });
                    generalShowSuccessSnackbar('Successfully updated dispatch line');
                }
            } catch (e) {
                generalShowErrorSnackbar('Error occured while updating dispatch line');
                this.setLoading(false);
            }
        }
    };

    private getColumns = () => {
        let columns : Array<ICustomTableColumn> = [
            { title: 'Barcode', field: 'id', containerComponent: this.getStockBarcode, width: 170, enableSorting: true },
            { title: 'Organization', field: 'organizationId', formatFunction: this.getOrganizationCode, enableSorting: true },
            { title: 'Commodity', field: 'commodityCode', enableSorting: true },
            { title: 'Variety', field: 'varietyCode', enableSorting: true },
            { title: 'Farm', field: 'id', formatFunction: this.getStockFarm, enableSorting: true },
            { title: 'Pack', field: 'packCode', enableSorting: true },
            { title: 'Size', field: 'sizeCode', enableSorting: true },
            { title: 'Grade', field: 'gradeCode', enableSorting: true },
            { title: 'Colour', field: 'colourCode', enableSorting: true },
            { title: 'Cartons', field: 'id', formatFunction: this.getCartons, width: 150, enableSorting: true },
            { title: 'Inners', field: 'id', formatFunction: this.getInners, width: 150, enableSorting: true },
            { title: 'Sent Gross Weight (kg)', field: 'sentGrossWeight', enableSorting: true },
            { title: 'Received Gross Weight (kg)', field: 'receivedGrossWeight', enableSorting: true },
            { title: 'Substitutable?', field: 'isSubstitutable', type: 'boolean', enableSorting: true },
            { title: 'Imported From PO?', field: 'isImportedFromPO', type: 'boolean', enableFiltering: true, enableSorting: true },
            { title: 'Created By', field: 'createdByName', enableSorting: true },
            { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
            { title: 'Updated By', field: 'updatedByName', enableSorting: true },
            { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
            { title: 'Active?', field: 'isActive', type: 'boolean', enableSorting: true },
        ];
        if (!['Dispatched', 'Received', undefined].some(x => x === this.state.selectedDispatch?.status)) {
            columns = addArrayElement(columns, {
                title: '',
                field: 'id',
                width: 230,
                containerComponent: (row : IDispatchLineView) => {
                    return (
                        <div className={'wfill hfill jcc'}>
                            {(!['Dispatched', 'Received'].some(x => x === this.state.selectedDispatch?.status) && !row.isSubstitutable && row.isActive) &&
                                <PillButton
                                    className={'pl10 pr10 h40 w200 reducedPillButtonShadow'}
                                    text={'Set As Substitutable'}
                                    type={'submit'}
                                    color={'secondary'}
                                    onClick={() => this.onSetLineSubstitutableClick(row)}
                                />
                            }
                        </div>
                    );
                },
            }, 'start');
        }
        return columns;
    };

    private getRows = createSelector(
        [this.getDispatchLineViews],
        (dispatchLineViews) => {
            return dispatchLineViews ?? [];
        },
    );

    private getSelectedDispatch = (props : IDispatchInstructionSummaryProps, state : IDispatchInstructionSummaryState) => state.selectedDispatch;
    private getIsLoading = (props : IDispatchInstructionSummaryProps, state : IDispatchInstructionSummaryState) => state.isLoading;
    private getRights = (props : IDispatchInstructionSummaryProps) => props.auth?.session?.user?.rights || [];

    private getIsButtonDisabled = createSelector(
        [this.getSelectedDispatch, this.getIsLoading],
        (selectedDispatch : IDispatchInstruction, isLoading : boolean) => {
            if (isLoading
                || selectedDispatch?.status === 'Draft'
                || selectedDispatch?.status === 'Planned'
                || selectedDispatch?.status === 'Confirmed'
                || selectedDispatch?.status === 'Instructed'
                || selectedDispatch?.status === 'Staged') {
                return true;
            } else {
                return false;
            }
        },
    );

    private hasUploadTeraokaRight = createSelector(
        [this.getRights],
        rights => rights.some(x => x.isActive && x.code === 'DISPATCH_UPLOAD_TERAOKA'));

    private onDispatchCSVExport = async () => {
        const selectedDispatch = this.state.selectedDispatch;
        if (selectedDispatch) {
            try {
                this.setLoading(true);
                const csvResult = await DispatchHttpService.getTeraokaCsv(selectedDispatch.id);
                const res2 = await DispatchHttpService.setTeraokaDownloadedFlag({ dispatchId: selectedDispatch.id });

                if (csvResult && csvResult.data) {
                    const blob = new Blob([csvResult.data.csvData], { type: 'text/csv;charset=utf-8;' });
                    FileSaver.saveAs(blob, csvResult.data.fileName);
                }
                if (res2 && res2.data) {
                    dataSetDispatchInstruction(res2.data);
                    this.setState({ selectedDispatch: res2.data });
                    this.setLoading(false);
                    return;
                } else {
                    generalShowErrorSnackbar('An error occurred setting the Teraoka CSV download flag.');
                    this.setLoading(false);
                    return;
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred trying to download the teraoka csv file.');
            } finally {
                this.setLoading(false);

            }
        }
    };

    private generateBarcodeToPDF = async () => {
        const sites = this.props.sites;
        const siteCode = sites.find(x => x.id === this.state.selectedDispatch?.sourceSiteId)?.code;

        try {
            const res = await DispatchHttpService.getDispatch(this.state.selectedDispatch?.id);

            if (res && res.data) {
                generateBarcodesFromDispatch(res.data, siteCode);
            } else {
                generalShowErrorSnackbar('Failed to load dispatch data for barcode pdf generation');
            }
        } catch (ex) {
            generalShowErrorSnackbar('Failed to load dispatch data for barcode pdf generation');
        }
    };

    private hasGenerateBarcodeRight = createSelector(
        [this.getRights],
        rights => rights.some(x => x.isActive && x.code === 'DISPATCH_GENERATE_BARCODES'));

    public render() {
        return (
            <Screen isPadded={false} isScrollable={false} isLoading={this.state.isLoading}>
                <div className={'fdc wfill hfill'}>
                    <div className={'fdc pl10 pr10 pt10'}>
                        <div className={'fdr aic pt10'}>
                            <PackmanLabel
                                label={'Current Organization'}
                                value={this.state.selectedDispatch?.organizationId ? this.getOrganizationName(this.state.selectedDispatch?.organizationId) : ''}
                            />
                            <PackmanLabel
                                label={'Target Organization'}
                                value={this.state.selectedDispatch?.targetOrganizationId ? this.getOrganizationName(this.state.selectedDispatch?.targetOrganizationId) : ''}
                            />
                            <PackmanLabel
                                label={'Source Site'}
                                value={this.state.selectedDispatch?.sourceSiteId ? this.getSiteDescription(this.state.selectedDispatch?.sourceSiteId) : ''}
                            />
                            <PackmanLabel
                                label={'Destination Site'}
                                value={this.state.selectedDispatch?.destinationSiteId ? this.getSiteDescription(this.state.selectedDispatch?.destinationSiteId) : ''}
                            />
                            <PackmanLabel
                                label={'Dispatch Code'}
                                value={this.state.selectedDispatch?.dispatchCode ?? ''}
                            />
                            <PackmanLabel
                                label={'Trip Id'}
                                value={this.state.selectedDispatch?.tripId ? <PackmanLink
                                    type={'tripTransactions'}
                                    targetPage={'dispatch'}
                                    id={this.state.selectedDispatch?.tripId}
                                    text={this.state.selectedDispatch?.tripId} /> : ''}
                            />
                            <PackmanLabel
                                label={'Driver'}
                                value={this.state.selectedDispatch?.driver ?? ''}
                            />
                        </div>
                        <div className={'fdr aic pt10'}>
                            <PackmanLabel
                                label={'Status'}
                                value={this.state.selectedDispatch?.status ?? ''}
                            />
                            <PackmanLabel
                                label={'Registration Number'}
                                value={this.state.selectedDispatch?.registrationNumber ?? ''}
                            />
                            <PackmanLabel
                                label={'Load Date'}
                                value={formatDateTimeToDateOnly(this.state.selectedDispatch?.loadDate ?? '')}
                            />
                            <PackmanLabel
                                label={'Created By'}
                                value={this.state.selectedDispatch?.createdByName ?? ''}
                            />
                            <PackmanLabel
                                label={'Created On'}
                                value={formatDateTime(this.state.selectedDispatch?.createdOn ?? '')}
                            />
                            <PackmanLabel
                                label={'Updated By'}
                                value={this.state.selectedDispatch?.updatedByName ?? ''}
                            />
                            <PackmanLabel
                                label={'Updated On'}
                                value={formatDateTime(this.state.selectedDispatch?.updatedOn ?? '')}
                            />
                        </div>
                        <div className={'fdr aic pt10'}>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>PO Uploaded?</Typography>
                                <BooleanFlag value={this.state.selectedDispatch?.poUploaded ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>PO Downloaded?</Typography>
                                <BooleanFlag value={this.state.selectedDispatch?.poDownloaded ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>PO Emailed?</Typography>
                                <BooleanFlag value={this.state.selectedDispatch?.poEmailed ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Teraoka Uploaded?</Typography>
                                <BooleanFlag value={this.state.selectedDispatch?.teraokaUploaded ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Teraoka Downloaded?</Typography>
                                <BooleanFlag value={this.state.selectedDispatch?.teraokaDownloaded ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Finalized?</Typography>
                                <BooleanFlag value={!!this.state.selectedDispatch?.finalizedSignature ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Printed?</Typography>
                                <BooleanFlag value={this.state.selectedDispatch?.isPrinted ?? false}/>
                            </div>
                        </div>
                        <div className={'fdr pt10 aifs'}>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Manual Override?</Typography>
                                <BooleanFlag value={this.state.selectedDispatch?.manualOverride ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Status Changed On Web?</Typography>
                                <BooleanFlag value={this.state.selectedDispatch?.webStatusChange ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Imported From PO?</Typography>
                                <BooleanFlag value={this.state.selectedDispatch?.isImportedFromPO ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Active?</Typography>
                                <BooleanFlag value={this.state.selectedDispatch?.isActive ?? false}/>
                            </div>
                            <div className={'fdr aic flx2'} style={{ maxWidth: 'calc(100% * 2/7)' }}>
                                {!this.state.selectedDispatch?.isActive ? <PackmanLabel
                                    label={'Delete Reason'}
                                    maxWidth={'100%'}
                                    className={'wrap2line mxwfill oh'}
                                    value={this.state.selectedDispatch?.deleteReason ?? ''}
                                /> : <></>}
                            </div>
                            <div className='flx1' />
                        </div>
                        <div className={'fdr aic pt10 pl10'}>
                            <div/>
                            <div className='bcg1 PaperBorder p10 h250 w400 asc fdc jcc oln'>
                                <div className={'h200'}>
                                    <img className={'hi wi'} src={this.state.selectedDispatch?.finalizedSignature} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'wfill flx1 fdc p20'}>
                        <CustomTable<IDispatchLineView>
                            columns={this.getColumns()}
                            rows={this.getRows(this.props, this.state)}
                            enableSorting
                            initialSortOrder={[{ columnName: 'sequence_Sequence', direction : 'asc' }]}
                            isActive={(row : IDispatchLineView) => row.isActive}
                            pageHeight={450}
                        />
                    </div>
                    <div style={{ flexWrap: 'wrap' }} className={'fdr p10 wfill hfill'}>
                        <PopupOptionButton
                            text={'DOWNLOAD DISPATCH FILES'}
                            icon={<CloudDownloadIcon/>}
                            onClick={() => this.openDispatchFileDownloadDialog()}
                        />
                        <div className={'w10'}/>
                        <CustomTooltip title={this.getIsButtonDisabled(this.props, this.state) ? 'Dispatch status needs to be dispatched or received to use this action' : ''}>
                            <PopupOptionButton
                                text={'DOWNLOAD PO FILE'}
                                icon={<GetAppIcon/>}
                                disabled={this.getIsButtonDisabled(this.props, this.state)}
                                onClick={() => this.onPOFileOrchardConfirmClick('Download')}
                            />
                        </CustomTooltip>
                        <div className={'w10'}></div>
                        <CustomTooltip title={this.getIsButtonDisabled(this.props, this.state) ? 'Dispatch status needs to be dispatched or received to use this action' : ''}>
                            <PopupOptionButton
                                text={'UPLOAD PO FILE'}
                                icon={<CloudUploadIcon/>}
                                disabled={this.getIsButtonDisabled(this.props, this.state)}
                                onClick={() => this.showConfirmationPrompt(() => this.onPOFileOrchardConfirmClick('Upload'), this.closeConfirmationPrompt, 'Are you sure you want to upload the PO file?')}
                            />
                        </CustomTooltip>
                        <div className={'w10'}></div>
                        <CustomTooltip title={this.getIsButtonDisabled(this.props, this.state) ? 'Dispatch status needs to be dispatched or received to use this action' :
                            (!this.hasUploadTeraokaRight(this.props) ? 'You don\'t have the required rights for this' : '')}>
                            <PopupOptionButton
                                text={'DOWNLOAD TERAOKA CSV'}
                                icon={<GetAppIcon/>}
                                disabled={this.getIsButtonDisabled(this.props, this.state) || !this.hasUploadTeraokaRight(this.props)}
                                onClick={this.onDispatchCSVExport}
                            />
                        </CustomTooltip>
                        <div className={'w10'}/>
                        <CustomTooltip title={this.getIsButtonDisabled(this.props, this.state) ? 'Dispatch status needs to be dispatched or received to use this action' :
                            (!this.hasUploadTeraokaRight(this.props) ? 'You don\'t have the required rights for this' : '') }>
                            <PopupOptionButton
                                text={'UPLOAD TERAOKA CSV'}
                                icon={<CloudUploadIcon/>}
                                disabled={this.getIsButtonDisabled(this.props, this.state) || !this.hasUploadTeraokaRight(this.props)}
                                onClick={() => this.showConfirmationPrompt(this.state.selectedDispatch?.teraokaUploaded
                                    ? () => this.showConfirmationPrompt(this.onUploadTeraokaCSVYesClick, this.closeConfirmationPrompt, 'The CSV file is already uploaded to Teraoka. Are you sure you want to upload it again?')
                                    : this.onUploadTeraokaCSVYesClick, this.closeConfirmationPrompt, 'Are you sure you want to upload the CSV file to Teraoka?')}
                            />
                        </CustomTooltip>
                        <div className={'w10'}/>
                        <CustomTooltip title={this.getIsButtonDisabled(this.props, this.state) ? 'Dispatch status needs to be dispatched or received to use this action' : ''}>
                            <PopupOptionButton
                                text={'UPLOAD DISPATCH FILES'}
                                icon={<CloudUploadIcon/>}
                                disabled={this.getIsButtonDisabled(this.props, this.state)}
                                onClick={() => this.openDispatchFileUploadDialog()}
                            />
                        </CustomTooltip>
                        <div className={'w10'}/>
                        <CustomTooltip title={this.getIsButtonDisabled(this.props, this.state) ? 'Dispatch status needs to be dispatched or received to use this action' : ''}>
                            <PopupOptionButton
                                text={'EMAIL PO FILE'}
                                icon={<EmailIcon/>}
                                disabled={this.getIsButtonDisabled(this.props, this.state)}
                                onClick={() => this.onPOFileOrchardConfirmClick('Email')}
                            />
                        </CustomTooltip>
                        <div className={'w10'}/>
                        <div>
                            <PopupOptionButton
                                text={'GENERATE BARCODES'}
                                icon={<CropFree/>}
                                disabled={!this.hasGenerateBarcodeRight(this.props)}
                                onClick={this.generateBarcodeToPDF}
                            />
                        </div>
                        <div className={'w10'}/>
                        <div>
                            <PopupOptionButton
                                text={'DOWNLOAD REPORT FILE'}
                                icon={<GetAppIcon/>}
                                // disabled={this.getIsButtonDisabled(this.props, this.state)}
                                onClick={this.showDownloadReportPopup}
                            />
                        </div>
                        <div className={'flx1'}></div>
                    </div>
                    {!!this.state.isDownloadReportFormOpen &&
                        <PackmanDialog
                            title='Download Report File'
                            isInfo
                            isLoading={this.state.isLoading}
                            isOpen={this.state.isDownloadReportFormOpen}
                            onClose={this.closeDownloadReportPopup}>
                            <Formik
                                initialValues={this.generateReportForm()}
                                isInitialValid={ReportFileFormValues.formSchema.isValidSync(this.generateReportForm())}
                                onSubmit={this.downloadReportFile}
                                onReset={this.onReportFileReset}
                                enableReinitialize
                                validationSchema={ReportFileFormValues.formSchema}
                                component={ReportFileForm} />
                        </PackmanDialog >}
                    {!!this.state.isEmailFormOpen &&
                        <PackmanDialog
                            title='Email PO File'
                            isInfo
                            isLoading={this.state.isLoading}
                            isOpen={this.state.isEmailFormOpen}
                            onClose={this.closeEmailForm}>
                            <Formik
                                initialValues={this.generateEmail(this.props, this.state)}
                                isInitialValid={EmailFormValues.formSchema.isValidSync(this.generateEmail(this.props, this.state))}
                                onSubmit={this.onEmailSubmit}
                                onReset={this.onEmailReset}
                                enableReinitialize
                                validationSchema={EmailFormValues.formSchema}
                                component={EmailForm} />
                        </PackmanDialog >}
                    {/* Dispatch Files Upload Dialog */}
                    <PackmanDialog
                        title={'Upload Dispatch files'}
                        isInfo
                        isLoading={this.state.isLoading}
                        isOpen={this.state.openDispatchFileUploadDialog}
                        onClose={this.closeDispatchFileUploadDialog}>
                        <Screen isPadded={false} isScrollable={false}>
                            <div style={{ height: 500 }} className={'w400 aic fdc p20'}>
                                <div style={{ height: 400 }} className={'wfill'}>
                                    <FileSelector
                                        accept={'image/*,application/pdf'}
                                        files={this.state.selectedFiles ? this.state.selectedFiles : []}
                                        disabled={this.state.isLoading}
                                        onFileSelected={this.handleFileInputChange}
                                        onFileRemoved={this.onFileRemove}
                                    />
                                </div>
                                <div className={'flx1'}/>
                                <div className={'fdr pt10 pb10'}>
                                    <Button
                                        className={'fwb h35'}
                                        variant='text'
                                        color='primary'
                                        onClick={this.closeDispatchFileUploadDialog}>
                                            Cancel
                                    </Button>
                                    <PillButton
                                        disabled={this.state.selectedFiles.length === 0}
                                        className={'ml15 pl20 pr20 h35'}
                                        text={'Upload File'}
                                        color={'secondary'}
                                        onClick={this.onDispatchFilesUpload}
                                    />
                                </div>
                            </div>
                        </Screen>
                    </PackmanDialog >
                    {/* Dispatch Files Download Dialog */}
                    <PackmanDialog
                        title={'Download Dispatch files'}
                        isInfo
                        isLoading={this.state.isLoading}
                        isOpen={this.state.openDispatchFileDownloadDialog}
                        onClose={this.closeDispatchFileDownloadDialog}>
                        <Screen isPadded={false} isScrollable={false}>
                            <div style={{ maxHeight: 500, height: 500 }} className={'w400 aic fdc p20 oya'}>
                                {(this.state.dispatchDownloadFiles && this.state.dispatchDownloadFiles?.length > 0) ?
                                    this.getDispatchFiles(this.props, this.state)
                                    :
                                    'No files available'
                                }
                            </div>
                        </Screen>
                    </PackmanDialog >
                    <PackmanDialog
                        title={'PO File Orchard Confirm'}
                        isInfo
                        maxWidth={'lg'}
                        isLoading={this.state.isLoading}
                        isOpen={this.state.isPOFileOrchardConfirmDialogOpen}
                        onClose={this.closePOFileOrchardConfirmDialog}>
                        <div className={'w500 aic fdc p20 oya'}>
                            <Typography className={'mb10'}>Should orchards be included?</Typography>
                            <div className={'fdc wfill jcfs'}>
                                <Typography>Note: This is not recommended when dispatching to CORE.</Typography>
                            </div>
                            <div className={'fdr aife jcfe wfill '}>
                                <Button className={'fwb h35'} variant='text' color='primary'
                                    onClick={this.onPOFileOrchardConfirmNoClick}>
                                    No
                                </Button>
                                <PillButton
                                    color={'secondary'}
                                    className={'ml15 pl20 pr20 h35'}
                                    text={'Yes'}
                                    onClick={this.onPOFileOrchardConfirmYesClick}
                                />
                            </div>
                        </div>
                    </PackmanDialog >
                    <ConfirmationPrompt disableYes={this.state.isLoading} disableNo={this.state.isLoading} isLoading={this.state.isLoading} open={this.state.showPrompt} message={this.state.message}
                        onOkClicked={this.state.onYesClick} onCancelClicked={this.state.onNoClick}/>
                    <ConfirmationPrompt isLoading={this.state.isLoading} open={this.state.setLineAsSubstitutable} message={'Are you sure you want to make this dispatch line substitutable' }
                        onOkClicked={this.onSubstituteSubmit} onCancelClicked={this.onSubstituteCancel}/>
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        complainces: state.data.compliances,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
        farms: state.masterData.farms,
        printServers: state.masterData.printServers,
        reports: state.masterData.reports,
        contactInfos: state.masterData.contactInfos,
        compliances: state.data.compliances,
        stockData: state.data.stocks,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { }, dispatcher);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(DispatchInstructionSummary));

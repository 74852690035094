import { getThemeMode } from '../styles/materialTheme';


/**
 * Returns icon location based on selected theme.
 */
export const getIconLocation = () => {

    const themeMode = getThemeMode();

    if (themeMode === 'light') {
        return `${ASSET_BASE}/assets/icons`;
    }
    if (themeMode === 'dark') {
        return `${ASSET_BASE}/assets/icons/darkTheme`;
    }
};

export const getCommodityImage = (name : string) => {
    const iconsLocation = getIconLocation();
    switch (name) {
        case 'ALMONDS':
            return `${iconsLocation}/almonds.svg`;
        case 'APPLES':
            return `${iconsLocation}/apples.svg`;
        case 'APRICOTS':
            return `${iconsLocation}/apricots.svg`;
        case 'AVOCADOS':
            return `${iconsLocation}/avocados.svg`;
        case 'BACHIO COCKTAILS':
        case 'BLUEBERRIES':
            return `${iconsLocation}/blueberries.svg`;
        case 'BRINJAL':
            return `${iconsLocation}/brinjal.svg`;
        case 'CACTUS PRICKLEY PEAR':
            return `${iconsLocation}/prickly_pear.svg`;
        case 'CHERRIES':
            return `${iconsLocation}/cherries.svg`;
        case 'DATES':
            return `${iconsLocation}/dates.svg`;
        case 'DRAGON FRUIT':
            return `${iconsLocation}/dragon_fruit.svg`;
        case 'FAVO COCKTAIL':
        case 'GARLIC':
            return `${iconsLocation}/garlic.svg`;
        case 'GRAPEFRUIT':
            return `${iconsLocation}/grapefruits.svg`;
        case 'KIWI':
            return `${iconsLocation}/kiwi.svg`;
        case 'LEMONS':
            return `${iconsLocation}/citrus.svg`;
        case 'LITCHIES':
            return `${iconsLocation}/litchies.svg`;
        case 'LONG SHELF LIFE':
        case 'MACADAMIA NUTS':
            return `${iconsLocation}/macadamia.svg`;
        case 'MANGOES':
            return `${iconsLocation}/mangoes.svg`;
        case 'NECTARINES':
            return `${iconsLocation}/peaches.svg`;
        case 'ONIONS':
            return `${iconsLocation}/onion.svg`;
        case 'ORGANIC ROMANITA':
        case 'ORGANIC SANTé':
        case 'PEACHES':
            return `${iconsLocation}/peaches.svg`;
        case 'PEARS':
            return `${iconsLocation}/pears.svg`;
        case 'PLUMS':
            return `${iconsLocation}/plums.svg`;
        case 'PUMMELOS':
            return `${iconsLocation}/pomelos.svg`;
        case 'RIGOLETTO COCKTAILS':
        case 'ROOI UIE':
        case 'SOFT CITRUS':
            return `${iconsLocation}/citrus.svg`;
        case 'SPANISH SANTé':
            return `${iconsLocation}/tomato.svg`;
        case 'TAMATIES ROMANITA':
        case 'TAMATIES ROND':
        case 'TOMATO':
            return `${iconsLocation}/tomatoes.svg`;
        case 'TOMATO JUICE':
        case 'UIE PIEKELS':
    }
};

import * as Yup from 'yup';
import { IOptionType } from '../../helper';

export interface IEmailFormValues {
    toAddresses : Array<IOptionType>;
    ccAddresses : Array<IOptionType>;
    files : Array<IOptionType>;
    subject : string;
    body : string;
}

export class EmailFormValues implements IEmailFormValues {
    public toAddresses : Array<IOptionType>;
    public ccAddresses : Array<IOptionType>;
    public files : Array<IOptionType>;

    public subject : string;
    public body : string;

    public constructor(
        subject : string,
        body : string,
        toAddresses ?: Array<string>,
        ccAddresses ?: Array<string>,
        files ?: Array<string>,
    ) {
        this.initializeDefaultValues();
        this.toAddresses = toAddresses?.map((x, i) => ({
            label: x,
            value: i,
        })) ?? [];

        this.ccAddresses = ccAddresses?.map((x, i) => ({
            label: x,
            value: i,
        })) ?? [];

        this.files = files?.map((x, i) => ({
            label: x,
            value: i,
        })) ?? [];

        this.subject = subject;
        this.body = body;
    }

    public initializeDefaultValues = () => {
        this.toAddresses = [];
        this.ccAddresses = [];
        this.files = [];
        this.subject = '';
        this.body = '';
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        subject: Yup.string().required('Required'),
        body: Yup.string().required('Required'),
        toAddresses: Yup.array().min(1, 'To Email field must have at least 1 item').required('Required'),
    });
}

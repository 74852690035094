import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IMaterialStock } from '../../../@types/model/materialStock/materialStock';
import { IScrapMaterialStock } from '../../../@types/model/materialStock/scrapMaterialStock';
import { ICreateMaterialStock } from '../../../@types/model/materialStock/createMaterialStock';
import qs from 'qs';
import { IMaterialStockView } from '../../../@types/model/materialStock/materialStockView';
import { IMaterialStockSummaryRelatedData } from '../../../@types/model/materialStock/materialStockSummaryRelatedData';

export default class MaterialStockHttpService {
    public static getMaterialStockData() : AxiosPromise<Array<IMaterialStock>> {
        return axios.get(`${PACKMAN_API_URL}MaterialStock/GetAllWeb`);
    }

    public static getMaterialStockTransactionViews(fromDateUnix ?: number, toDateUnix ?: number, siteIds ?: Array<number>) : AxiosPromise<Array<IMaterialStockView>> {
        return axios.get(`${PACKMAN_API_URL}MaterialStock/GetMaterialStockWebTransActionViews`, {
            params: {
                fromDateUnix,
                toDateUnix,
                siteIds,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getMaterialStockView(id : number) : AxiosPromise<IMaterialStockView> {
        return axios.get(`${PACKMAN_API_URL}MaterialStock/GetMaterialStockView`, {
            params: {
                id,
            },
        });
    }

    public static getMaterialStocksAvailableToFinalize(siteId : number) : AxiosPromise<Array<IMaterialStockView>> {
        return axios.get(`${PACKMAN_API_URL}MaterialStock/GetAvailableMaterialStocksToFinalize`, {
            params: {
                siteId,
            },
        });
    }

    public static getMaterialStockSummaryRelatedData(materialStockId : number, loadMasterData ?: boolean) : AxiosPromise<IMaterialStockSummaryRelatedData> {
        return axios.get(`${PACKMAN_API_URL}MaterialStock/GetSummaryRelatedData`, {
            params: {
                materialStockId,
                loadMasterData,
            },
        });
    }

    public static finalizeMaterialStock(materialStockGuid : string) : AxiosPromise<IMaterialStockView> {
        return axios.post(`${PACKMAN_API_URL}MaterialStock/FinalizeMaterialStockWeb`, { materialStockGuid });
    }

    public static finalizeMutlipleMaterialStocks(materialStockGuids : Array<string>) : AxiosPromise<Array<IMaterialStockView>> {
        return axios.post(`${PACKMAN_API_URL}MaterialStock/FinalizeMultipleMaterialStocksWeb`, materialStockGuids);
    }

    public static materialStockIntake(data : ICreateMaterialStock) : AxiosPromise<Array<IMaterialStockView>> {
        return axios.post(`${PACKMAN_API_URL}MaterialStock/CreateMaterialStocksWebTransaction`, [data]);
    }

    public static materialStockTake(materialStockGuid : string, amount : number) : AxiosPromise<IMaterialStock> {
        return axios.post(`${PACKMAN_API_URL}MaterialStock/SetMaterialStockAmount`, { materialStockGuid, amount });
    }

    public static materialStockScrapped(data : IScrapMaterialStock) : AxiosPromise<IMaterialStockView> {
        return axios.post(`${PACKMAN_API_URL}MaterialStock/ScrapMaterialStockWebTransaction`, data);
    }

    public static revertScrapMaterialStockLine(id : number) : AxiosPromise<IMaterialStock> {
        return axios.post(`${PACKMAN_API_URL}MaterialStockLine/RevertScrapMaterialStockLineWebTransaction`, { id });
    }

    public static revertStockTake(id : number) : AxiosPromise<IMaterialStock> {
        return axios.post(`${PACKMAN_API_URL}MaterialStockLine/RevertStockTakeWebTransaction`, { id });
    }

    public static revertMaterialStockLines(lines : Array<number>) : AxiosPromise<Array<IMaterialStock>> {
        return axios.post(`${PACKMAN_API_URL}MaterialStockLine/RevertMaterialStockLinesWebTransaction`, lines.map((id) => {
            return { id };
        }));
    }

    public static reActivateMaterialStock(materialStockId : number) : AxiosPromise<IMaterialStockView> {
        return axios.post(`${PACKMAN_API_URL}MaterialStock/ReActivate`, { materialStockId });
    }

    public static deleteMaterialStock(materialStockId : number, deleteReason : string) : AxiosPromise<IMaterialStockView> {
        return axios.delete(`${PACKMAN_API_URL}MaterialStock/Delete`, {
            params : {
                materialStockId,
                deleteReason,
            },
        });
    }
}

import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { IInventory } from '../inventory/inventory';
import { IPackLine } from '../packLine/packLine';
import { IPrinter } from '../printer/printer';
import { IPrintServer } from '../printServer/printServer';
import { IReport } from '../report/report';
import { ISite } from '../site/site';
import { IOutlet } from './outlet';

export interface IOutletFormValues {
    id : number;
    guid : string;
    code : string;
    deviceMacAddress : string;
    packLine ?: IOptionType;
    defaultPrintServer ?: IOptionType;
    defaultPrinter ?: IOptionType;
    defaultReport ?: IOptionType;
    defaultExporterSite ?: IOptionType;
    defaultInventory ?: IOptionType;
    isActive : boolean;
}

export class OutletFormValues implements IOutletFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public deviceMacAddress : string;
    public packLine ?: IOptionType;
    public defaultPrintServer ?: IOptionType;
    public defaultPrinter ?: IOptionType;
    public defaultReport ?: IOptionType;
    public defaultExporterSite ?: IOptionType;
    public defaultInventory ?: IOptionType;
    public isActive : boolean;

    public constructor(
        outlet ?: IOutlet,
        packLines ?: Array<IPackLine>,
        printServers ?: Array<IPrintServer>,
        printers ?: Array<IPrinter>,
        reports ?: Array<IReport>,
        sites ?: Array<ISite>,
        inventories ?: Array<IInventory>,
    ) {
        this.initializeDefaultValues();
        if (outlet) {
            this.id = outlet.id;
            this.guid = outlet.guid;
            this.code = outlet.code;
            this.deviceMacAddress = outlet.deviceMacAddress;

            this.packLine = packLines ? {
                label: packLines.find(x => x.id === outlet.packLineId) ? `(${packLines.find(x => x.id === outlet.packLineId)?.code}) ${packLines.find(x => x.id === outlet.packLineId)?.name}` : '',
                value: outlet.packLineId,
            } : undefined;

            this.defaultPrintServer = printServers
                ?
                ((outlet.defaultPrintServerId)
                    ?
                    {
                        label: printServers.find(x => x.id === outlet.defaultPrintServerId)?.name ?? '',
                        value: outlet.defaultPrintServerId,
                    }
                    : undefined)
                : undefined;

            this.defaultPrinter = printers
                ?
                ((outlet.defaultPrinterId)
                    ?
                    {
                        label: printers.find(x => x.id === outlet.defaultPrinterId)?.name ?? '',
                        value: outlet.defaultPrinterId,
                    }
                    : undefined)
                : undefined;

            this.defaultReport = reports
                ?
                ((outlet.defaultReportId)
                    ?
                    {
                        label: reports.find(x => x.id === outlet.defaultReportId)?.name ?? '',
                        value: outlet.defaultReportId,
                    }
                    : undefined)
                : undefined;

            this.defaultExporterSite = sites
                ?
                ((outlet.defaultExporterSiteId)
                    ?
                    {
                        label: `(${sites.find(x => x.id === outlet.defaultExporterSiteId)?.code}) ${sites.find(x => x.id === outlet.defaultExporterSiteId)?.description}` ?? '',
                        value: outlet.defaultExporterSiteId,
                    }
                    : undefined)
                : undefined;

            this.defaultInventory = inventories
                ?
                ((outlet.defaultInventoryId)
                    ?
                    {
                        label: `(${inventories.find(x => x.id === outlet.defaultInventoryId)?.code}) ${inventories.find(x => x.id === outlet.defaultInventoryId)?.description}` ?? '',
                        value: outlet.defaultInventoryId,
                    }
                    : undefined)
                : undefined;

            this.isActive = outlet.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.deviceMacAddress = '';
        this.packLine = undefined;
        this.defaultPrintServer = undefined;
        this.defaultPrinter = undefined;
        this.defaultReport = undefined;
        this.defaultExporterSite = undefined;
        this.defaultInventory = undefined;
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        packLine: Yup.object().nullable().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

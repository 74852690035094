import axios, { AxiosPromise } from 'axios';
import { ISalesPoint } from '../../../@types/model/masterData/salesPoint/salesPoint';
import { PACKMAN_API_URL } from '../../../appConstants';

export default class SalesPointHttpService {
    public static getSalesPointData() : AxiosPromise<Array<ISalesPoint>> {
        return axios.get(`${PACKMAN_API_URL}SalesPoint/GetAllWeb`);
    }

    public static addOrUpdateSalesPoint(data : ISalesPoint) : AxiosPromise<ISalesPoint> {
        return axios.post(`${PACKMAN_API_URL}SalesPoint/PostSalesPoint`, data);
    }

    public static deleteSalesPoint(salesPointId : number) : AxiosPromise<ISalesPoint> {
        return axios.delete(`${PACKMAN_API_URL}SalesPoint/Delete`, {
            params : { salesPointId },
        });
    }
}

import Axios from 'axios';
import { IFreshServiceTicket } from '../../../@types/model/freshService/freshServiceTicket';
import { freshServiceConfig } from '../../../appConstants';

export const createTicket = async (data : IFreshServiceTicket) => {
    return Axios.post(freshServiceConfig.Address,
        data,
        { headers : { Authorization: `Basic ${Buffer.from(freshServiceConfig.apiKey).toString('base64')}`, 'Content-Type' : 'application/json'  }  },
    );
};

import { IBaseModel } from '../../../model';
import { IBillOfMaterials } from './billOfMaterials';
import { IPackFormValues } from './packFormValues';

export interface IPack extends IBaseModel {
    code : string;
    description : string;
    noCartons : number;
    nettWeight : number;
    packagingWeight : number;
    grossWeight : number;
    isIntakePack : boolean;
    hasInnerPack : boolean;
    noUnitsPerCarton ?: number;
    numberOfStackedUnits ?: number;
    unitNettWeight ?: number;
    unitPackagingWeight ?: number;
    unitGrossWeight ?: number;

    sizeIds : Array<number>;
    commodityId : number;
    categoryId : number;

    billOfMaterials : Array<IBillOfMaterials>;
}

export class Pack implements IPack {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public description : string;
    public noCartons : number;
    public nettWeight : number;
    public packagingWeight : number;
    public grossWeight : number;
    public isIntakePack : boolean;
    public hasInnerPack : boolean;
    public noUnitsPerCarton ?: number;
    public numberOfStackedUnits ?: number;
    public unitNettWeight ?: number;
    public unitPackagingWeight ?: number;
    public unitGrossWeight ?: number;

    public sizeIds : Array<number>;
    public commodityId : number;
    public categoryId : number;

    public billOfMaterials : Array<IBillOfMaterials>;

    public constructor(formValues : IPackFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.code = formValues.code;
        this.description = formValues.description;
        this.noCartons = formValues.noCartons;
        this.nettWeight = formValues.nettWeight;
        this.packagingWeight = formValues.packagingWeight;
        this.grossWeight = formValues.grossWeight;
        this.isIntakePack = formValues.isIntakePack;
        this.hasInnerPack = formValues.hasInnerPack;
        this.noUnitsPerCarton = formValues.noUnitsPerCarton;
        this.numberOfStackedUnits = formValues.numberOfStackedUnits;
        this.unitNettWeight = formValues.unitNettWeight;
        this.unitPackagingWeight = formValues.unitPackagingWeight;
        this.unitGrossWeight = formValues.unitGrossWeight;

        this.sizeIds = formValues.sizes?.map(x => Number(x.value)) ?? [];
        this.commodityId = Number(formValues.commodity?.value) ?? 0;
        this.categoryId = Number(formValues.category?.value) ?? 0;
        this.billOfMaterials = formValues.billOfMaterials;
    }
}

import * as React from 'react';
import { Typography, IconButton, Icon, Paper, List } from '@mui/material';
import { IRootState, IAuthState } from '../../@types/redux';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { connect } from 'react-redux';
import { setArrayElement, addArrayElement, removeArrayElement, uppercase } from '../../services/appFunctionsService';
import { dataSetCompliance, dataSetStock } from '../../store/data/Functions';
import uuidv1 from 'uuid';
import { ICompliance } from '../../@types/model/compliance/compliance';
import { IStock } from '../../@types/model/stock/stock';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { ISize } from '../../@types/model/masterData/size/size';
import { ISite } from '../../@types/model/masterData/site/site';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { IComplianceLine } from '../../@types/model/compliance/complianceLine';
import ComplianceHttpService from '../../services/http/compliance/complianceHttpService';
import StockHttpService from '../../services/http/stock/stockHttpService';
import PillButton from '../../components/input/PillButton';
import { IStockLine } from '../../@types/model/stock/stockLine';
import Screen from '../../components/Screen';
import { getThemeMode } from '../../styles/materialTheme';

const themeMode = getThemeMode();

interface IComplianceManualInspectionProps {
    open : boolean;
    compliance ?: ICompliance;
    onClose : () => void;
    stockData : Array<IStock>;
    organizations : Array<IOrganization>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    grades : Array<IGrade>;
    colours : Array<IColour>;
    packs : Array<IPack>;
    sizes : Array<ISize>;
    sites : Array<ISite>;
    farms : Array<IFarm>;
    auth : IAuthState;
    refreshData : (noAnnounce ?: boolean) => void;
    selectedSiteIds : Array<number> ;
}

interface IComplianceManualInspectionState {
    currentCompliance ?: ICompliance;
    isLoading : boolean;
    stockToReplace ?: IStock;
    removedStock : Array<number>;
    replacedStock : Array<{ original : number; replacement : number}>;
    uniqueStockLines : Array<IStockLine>;
    compliancesLinkedToStocks : Array<ICompliance>;
}

class ComplianceManualInspection extends React.Component<IComplianceManualInspectionProps, IComplianceManualInspectionState> {
    constructor(props : IComplianceManualInspectionProps) {
        super(props);

        this.state = {
            isLoading: false,
            removedStock: [],
            replacedStock: [],
            uniqueStockLines: [],
            compliancesLinkedToStocks: [],
        };
    }

    public componentDidMount = async () => {
        if (this.props.compliance) {
            this.setLoading(true);
            try {
                const stockIds = this.props.compliance.complianceLines.filter(x => x.isActive && x.isRejected && !x.isReplaced).map(x => x.stockId);

                const res = await StockHttpService.getReplacementStock(stockIds);

                if (res && res.data) {
                    this.getUniqueStockLines(res.data.stocks);
                    this.setState({ compliancesLinkedToStocks: res.data.compliances });
                    res.data.stocks.forEach((stock) => {
                        dataSetStock(stock);
                    });
                }
            } catch (e) {
                generalShowErrorSnackbar('Error accured while trying to load replacement stock data');
            } finally {
                this.setLoading(false);
            }

            this.setState({ currentCompliance: this.props.compliance });
        }
    };

    private getUniqueStockLines = (stocks : Array<IStock>) => {
        const compliance = this.props.compliance;
        const complianceLines = compliance?.complianceLines;

        let stockList : Array<IStock> = [...stocks];

        complianceLines?.forEach((x) => {
            const complianceStock = this.getStock(x.stockId);

            if (complianceStock) {
                stockList = addArrayElement(stockList, complianceStock, 'end');
            }
        });

        const uniqueStockLines : Array<IStockLine> = [];
        stockList.forEach(stock => stock.stockLines.filter(x => x.isActive).forEach((x) => {
            const index = uniqueStockLines.findIndex(y => y.stockId === x.stockId);
            if (index === -1) {
                uniqueStockLines.push(x);
            } else if ((uniqueStockLines[index].cartons < x.cartons) ||
                ((uniqueStockLines[index].cartons === x.cartons) && (uniqueStockLines[index].id > x.id))) {
                uniqueStockLines[index] = x;
            }
        }));

        this.setState({ uniqueStockLines });
    };

    private onClose = () => {
        this.setState({
            currentCompliance: undefined,
        });
        this.props.onClose();
    };

    private setLoading = (isLoading : boolean = false) => {
        this.setState({ isLoading });
    };

    private getBarcode = (stockId : number) => {
        const stock = this.props.stockData.find(x => x.id === stockId);
        return stock && stock.barcode;
    };

    private submit = async () => {
        if (!this.allStockReplaced()) {
            generalShowErrorSnackbar('Please replace or remove all stock on the compliance first.');
            return;
        }
        this.setLoading(true);
        const oldCompliance = this.props.compliance && { ...this.props.compliance };
        const newCompliance = this.state.currentCompliance && { ...this.state.currentCompliance };
        if (newCompliance && oldCompliance) {
            this.state.replacedStock.forEach((x) => {
                const lineIndex = newCompliance.complianceLines.findIndex(y => y.stockId === x.original && y.isActive);
                if (lineIndex !== -1) {
                    const line = newCompliance.complianceLines[lineIndex];
                    line.isReplaced = true;
                    line.updatedOn = undefined;
                    newCompliance.complianceLines = setArrayElement(newCompliance.complianceLines, lineIndex, line);
                }
                const newComplianceLine : IComplianceLine = {
                    id: 0,
                    guid: uuidv1(),
                    stockId: x.replacement,
                    isInspected: false,
                    isRejected: false,
                    isReplaced: false,
                    complianceId: newCompliance.id,
                    isActive: true,
                };
                newCompliance.complianceLines.push(newComplianceLine);
            });
            this.state.removedStock.forEach((x) => {
                const lineIndex = newCompliance.complianceLines.findIndex(y => y.stockId === x && y.isActive);
                if (lineIndex !== -1) {
                    const line = newCompliance.complianceLines[lineIndex];
                    line.isReplaced = true;
                    line.updatedOn = undefined;
                    newCompliance.complianceLines = setArrayElement(newCompliance.complianceLines, lineIndex, line);
                }
            });
            newCompliance.updatedOn = undefined;
            newCompliance.status = 'Planned';

            try {
                const res = await ComplianceHttpService.addOrUpdateCompliance(newCompliance);

                if (res && res.data) {
                    dataSetCompliance(res.data);
                    this.props.refreshData(true);
                    this.setLoading(false);
                    generalShowSuccessSnackbar('Compliance stock replacement successful.');
                    this.onClose();
                } else {
                    generalShowErrorSnackbar('An error occurred replacing the compliance stock.');
                    if (oldCompliance) dataSetCompliance(oldCompliance);
                    this.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred replacing the compliance stock.');
                if (oldCompliance) dataSetCompliance(oldCompliance);
                this.setLoading(false);
            }
        }
    };

    private getStock = (stockId : number) => this.props.stockData.find(x => x.id === stockId);
    private isRemoved = (stockId : number) => this.state.removedStock.some(x => x === stockId);
    private isBeingReplaced = (stockId : number) => !!this.state.stockToReplace && (this.state.stockToReplace.id === stockId);
    private isReplaced = (stockId : number) => this.state.replacedStock.some(x => x.original === stockId);

    private allStockReplaced = () => {
        const compliance = this.state.currentCompliance;
        if (compliance) {
            let returnValue = true;
            compliance.complianceLines.filter(x => x.isActive && x.isRejected && !x.isReplaced).forEach((x) => {
                if (!this.state.removedStock.some(y => y === x.stockId) && !this.state.replacedStock.some(y => y.original === x.stockId)) {
                    returnValue = false;
                }
            });
            return returnValue;
        }
        return false;
    };

    private removeStock = (stockId : number, event : React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        let replacedStock = [...this.state.replacedStock];
        const index = replacedStock.findIndex(x => x.original === stockId);
        if (index !== -1) {
            replacedStock = removeArrayElement(replacedStock, index);
        }
        this.setState(prevState => ({
            removedStock: addArrayElement(prevState.removedStock, stockId, 'end'),
            stockToReplace: this.isBeingReplaced(stockId) ? undefined : prevState.stockToReplace,
            replacedStock,
        }));
    };

    private restoreStock = (stockId : number, event : React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        const index = this.state.removedStock.findIndex(x => x === stockId);
        if (index !== -1) {
            this.setState(prevState => ({ removedStock: removeArrayElement(prevState.removedStock, index) }));
        }
    };

    private getReplacement = (stockId : number) => {
        const replaced = this.state.replacedStock.find(x => x.original === stockId);
        return replaced && replaced.replacement;
    };

    private isSelectedReplacement = (stockId : number) => {
        const stockToReplace = this.state.stockToReplace;
        if (stockToReplace) {
            return this.state.replacedStock.find(x => x.replacement === stockId);
        }
    };

    private getMainStockLine = (stock : IStock) => this.state.uniqueStockLines.find(x => x.stockId === stock.id);

    private findReplacements = () => {
        const selectedStock = this.state.stockToReplace;
        if (selectedStock) {
            const originalMainStockLine = this.getMainStockLine(selectedStock);
            if (originalMainStockLine) {
                return this.props.stockData.filter(x => (!!this.getMainStockLine(x))
                            && (this.isStockOnCompliance(x.id))
                            && (originalMainStockLine.commodityId !== this.getMainStockLine(x)?.commodityId
                                || originalMainStockLine.varietyId !== this.getMainStockLine(x)?.varietyId
                                || originalMainStockLine.farmId !== this.getMainStockLine(x)?.farmId)).map((x) => {
                    return { stock: x, mainStockLine: this.getMainStockLine(x) };
                });
            }
        }
        return [];
    };

    private isStockOnCompliance = (stockId : number) => {
        let complianceLineLinkedToStock : IComplianceLine | undefined;
        this.state.compliancesLinkedToStocks.forEach((compliance) => {
            compliance.complianceLines.forEach((line) => {
                if (line.isActive && line.stockId === stockId) {
                    complianceLineLinkedToStock = line;
                }
            });
        });

        let result = false;

        if (!!complianceLineLinkedToStock) {
            result = false;
        } else {
            result = true;
        }
        return result;
    };

    private toggleIsBeingReplaced = (stockId : number) => this.setState(prevState => ({ stockToReplace: prevState.stockToReplace && (prevState.stockToReplace.id  === stockId) ? undefined : this.getStock(stockId) }));

    private handleReplace = (stockId : number) => {
        const stockToReplace = this.state.stockToReplace;
        let replacedStock = [...this.state.replacedStock];
        if (stockToReplace) {
            const index = replacedStock.findIndex(x => x.original === stockToReplace.id);
            const addNew = index === -1 || replacedStock[index].replacement !== stockId;
            if (index !== -1) {
                replacedStock = removeArrayElement(replacedStock, index);
            }
            if (addNew) {
                replacedStock = addArrayElement(replacedStock, { original : stockToReplace.id, replacement: stockId }, 'end');
            }
            this.setState({ replacedStock });
        }
    };

    private getOrganizationName = (orgId : number) => {
        if (orgId === 0) {
            return '';
        }
        const organization = this.props.organizations.find(x => x.id === orgId);
        return organization && organization.name ? uppercase(organization.name) : 'UNKNOWN VALUE';
    };

    private getSiteDescription = (siteId : number) => {
        if (siteId === 0) {
            return '';
        }
        const site = this.props.sites.find(x => x.id === siteId);
        return site && site.description ? uppercase(site.description) : 'UNKNOWN VALUE';
    };

    private getCommodityName = (commId : number) => {
        if (commId === 0) {
            return '';
        }
        const comm = this.props.commodities.find(x => x.id === commId);
        return comm && comm.name ? uppercase(comm.name) : 'UNKNOWN VALUE';
    };

    private getVarietyName = (varId : number) => {
        if (varId === 0) {
            return '';
        }
        const variety = this.props.varieties.find(x => x.id === varId);
        return variety && variety.name ? uppercase(variety.name) : 'UNKNOWN VALUE';
    };

    private getPackCode = (packId : number) => {
        if (packId === 0) {
            return '';
        }
        const pack = this.props.packs.find(x => x.id === packId);
        return pack && pack.code ? uppercase(pack.code) : 'UNK';
    };

    private getSizeCode = (sizeId : number) => {
        if (sizeId === 0) {
            return '';
        }
        const size = this.props.sizes.find(x => x.id === sizeId);
        return size && size.code ? uppercase(size.code) : 'UNK';
    };

    private getGradeCode = (gradeId : number) => {
        if (gradeId === 0) {
            return '';
        }
        const grade = this.props.grades.find(x => x.id === gradeId);
        return grade && grade.code ? uppercase(grade.code) : 'UNK';
    };

    private getColourCode = (colourId ?: number) => {
        if (colourId === 0) {
            return '';
        }
        const colour = this.props.colours.find(x => x.id === colourId);
        return colour && colour.code ? uppercase(colour.code) : 'UNK';
    };

    private getFarmName = (farmId : number) => {
        if (farmId === 0) {
            return '';
        }
        const farm = this.props.farms.find(x => x.id === farmId);
        return farm && farm.name ? uppercase(farm.name) : 'UNKNOWN VALUE';
    };

    public render() {
        const compliance = this.state.currentCompliance;
        return <Screen isPadded={false} isScrollable={false} isLoading={this.state.isLoading}>
            <div className={'fdr pl20 pr20 pt20'}>
                <Typography className={'flx1 p8 mb10 fw400 fs16 txtUC bcp cw'} color='inherit' variant='h6'>
                        Organization
                </Typography>
                <span className={'w10'} />
                <Typography className={'flx1 p8 mb10 fw400 fs16 txtUC bcp cw'} color='inherit' variant='h6'>
                        Site
                </Typography>
                <span className={'w10'} />
                <Typography className={'flx1 p8 mb10 fw400 fs16 txtUC bcp cw'} color='inherit' variant='h6'>
                        Commodity
                </Typography>
                <span className={'w10'} />
                <Typography className={'flx1 p8 mb10 fw400 fs16 txtUC bcp cw'} color='inherit' variant='h6'>
                        Variety
                </Typography>
                <span className={'w10'} />
                <Typography className={'flx1 p8 mb10 fw400 fs16 txtUC bcp cw'} color='inherit' variant='h6'>
                        Farm
                </Typography>
            </div>
            <div className={'fdr pl20 pr20'}>
                <Typography className={'flx1 ml10 mr10'} color='inherit' variant='subtitle1'>
                    {compliance && this.getOrganizationName(compliance.organizationId)}
                </Typography>
                <span className={'w10'} />
                <Typography className={'flx1 ml10 mr10'} color='inherit' variant='subtitle1'>
                    {compliance && compliance.siteId && this.getSiteDescription(compliance.siteId)}
                </Typography>
                <span className={'w10'} />
                <Typography className={'flx1 ml10 mr10'} color='inherit' variant='subtitle1'>
                    {compliance && compliance.commodityId && this.getCommodityName(compliance.commodityId)}
                </Typography>
                <span className={'w10'} />
                <div className={'flx1'}/> {/* placeholder */}
                <span className={'w10'} />
                <div className={'flx1'}/> {/* placeholder */}
            </div>
            <div style={ { height: 'calc(80vh)' }} className={'fdr wfill pt20 pb20'}>
                <Paper className={'fdc hfill w400 ml20 mr20'}>
                    <div className={'wfill h50 bcr'}>
                        <Typography className={'pl10 pr10 pt10 aic fdr cw'} variant={'h6'}>
                            {'Rejected Stock'}
                        </Typography>
                    </div>
                    <List>
                        { compliance && compliance.complianceLines.filter(x => x.isActive && x.isRejected && !x.isReplaced).map((x) => {
                            const removed = this.isRemoved(x.stockId);
                            const replacedWith = this.getReplacement(x.stockId);
                            const selected = this.isBeingReplaced(x.stockId);
                            const stock = this.getStock(x.stockId);
                            const replacedWithStock = replacedWith && this.getStock(replacedWith);
                            const mainStockLine = stock && this.getMainStockLine(stock);
                            const replacedWithMainStockLine = replacedWithStock && this.getMainStockLine(replacedWithStock);
                            return <div onClick={() => removed ? null : this.toggleIsBeingReplaced(x.stockId)} className={`m5 p5 PaperBorder fdr curp aic DispatchListItem${themeMode}${selected ? 'Selected' : ''}`}>
                                <div className={(removed || replacedWith) ? 'fdc' : ''}>
                                    { removed ? 'Removed:' : replacedWith ? 'Replaced' : null}
                                    <div className={(removed || replacedWith) ? 'cred fdr' : ''}>
                                        {this.getBarcode(x.stockId)}
                                    </div>
                                    <div className={(removed || replacedWith) ? 'cred fdr' : ''}>
                                        {`${mainStockLine && `${this.getPackCode(mainStockLine.packId)} || ${this.getSizeCode(mainStockLine.sizeId)} || ${this.getGradeCode(mainStockLine.gradeId)} || ${this.getColourCode(mainStockLine.colourId)}`}`}
                                    </div>
                                    { replacedWith ? 'with' : null}
                                    {
                                        replacedWith &&
                                            <div className={'cpl fdc'}>
                                                {`(${this.getBarcode(replacedWith)})`}
                                                <div className={'fdr'}>
                                                    {`${replacedWithMainStockLine && `(${this.getPackCode(replacedWithMainStockLine.packId)} || ${this.getSizeCode(replacedWithMainStockLine.sizeId)} || ${this.getGradeCode(replacedWithMainStockLine.gradeId)} || ${this.getColourCode(replacedWithMainStockLine.colourId)}`})`}
                                                </div>
                                            </div>
                                    }
                                </div>
                                <div className={'flx1'}/>
                                {
                                    removed ?
                                        <IconButton onClick={(event : React.MouseEvent<HTMLButtonElement>) => this.restoreStock(x.stockId, event)}>
                                            <Icon>restore</Icon>
                                        </IconButton>
                                        :
                                        <IconButton className={`${!!this.isReplaced(x.stockId) ? 'dn' : ''}`} onClick={(event : React.MouseEvent<HTMLButtonElement>) => this.removeStock(x.stockId, event)}>
                                            <Icon>cancel</Icon>
                                        </IconButton>
                                }
                            </div>;
                        })}
                    </List>
                </Paper>
                <Paper style={ { height: 'calc(80vh)' }} className={'fdc hfill ml20 w400'}>
                    <div className={'wfill h50 bco'}>
                        <Typography className={'pl10 pr10 pt10 aic fdr cw'} variant={'h6'}>
                            {'Replacement Stock'}
                        </Typography>
                    </div>
                    <div className={'oya wfill hfill'}>
                        {this.findReplacements().length < 1 ?
                            <div className={'aic jcc wfill hfill'}>{this.state.stockToReplace ? 'No pallets available to replace selected one' : 'Please select a pallet to replace' }</div>
                            :
                            this.findReplacements().map((x) => {
                                const stockToReplace = this.state.stockToReplace;
                                return (
                                    <div style={!!this.state.replacedStock.find(y => y.original !== stockToReplace?.id && y.replacement === x.stock.id) ? { pointerEvents: 'none', opacity: '0.7' } : {}}
                                        onClick={() => this.handleReplace(x.stock.id)}
                                        className={`w350 ml5 mr5 mb10 p5 PaperBorder fdc curp jcc DispatchListItem${themeMode}${this.isSelectedReplacement(x.stock.id) ? 'Selected' : ''}`}>
                                        {this.getBarcode(x.stock.id)}
                                        <div>
                                            {`${x.mainStockLine && `${this.getPackCode(x.mainStockLine.packId)} || ${this.getSizeCode(x.mainStockLine.sizeId)} || ${this.getGradeCode(x.mainStockLine.gradeId)} || ${this.getColourCode(x.mainStockLine.colourId)}`}`}
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </Paper>
                <div className={'fdc hfill flx1 mr20'}>
                    <div className={'flx1'}/>
                    <div className={'fdr jcfe'}>
                        {
                            <PillButton
                                disabled={this.state.isLoading || (this.state.removedStock.length < 1 && this.state.replacedStock.length < 1)}
                                className={'w100 h35 aic jcc'}
                                text={'SAVE'}
                                color={'secondary'}
                                onClick={this.submit}
                            />
                        }
                    </div>
                </div>
            </div>
        </Screen>;
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        stockData: state.data.stocks,
        organizations: state.masterData.organizations,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        grades: state.masterData.grades,
        colours: state.masterData.colours,
        packs: state.masterData.packs,
        sizes: state.masterData.sizes,
        sites: state.masterData.sites,
        farms: state.masterData.farms,
        auth: state.auth,
        selectedSiteIds: state.data.selectedSiteIds,
    };
};

export default connect(
    mapStateToProps,
)(ComplianceManualInspection);

import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { StorageUnitFormValues } from '../../../../@types/model/masterData/storageUnit/storageUnitFormValues';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import { IOptionType } from '../../../../@types/helper';
import FormAutocompleteSelect from '../../../../components/input/form/FormAutoCompleteSelect';
import { IRootState } from '../../../../@types/redux';
import { createSelector } from 'reselect';
import { ISite } from '../../../../@types/model/masterData/site/site';
import { connect } from 'react-redux';
import L, { LatLng, LeafletMouseEvent } from 'leaflet';
import { Map, Marker } from 'react-leaflet';
import StandardLayerControl from '../../../../components/map/StandardLayerControl';
import { getMapIcon } from '../../../../appConstants';
import { IStorageUnit } from '../../../../@types/model/masterData/storageUnit/storageUnit';

interface IStorageUnitFormProps {
    isLoading : boolean;
    siteOptions : Array<IOptionType>;
    sites : Array<ISite>;
    storageUnitData : Array<IStorageUnit>;
}

interface IStorageUnitFormState {
    lat ?: number;
    long ?: number;
    iconGreen ?: L.Icon;
    iconRed ?: L.Icon;
    selectedSite ?: ISite;
    siteStorageUnits : Array<IStorageUnit>;
}

type StorageUnitFormPropsType = IStorageUnitFormProps & FormikProps<StorageUnitFormValues>;

class StorageUnitForm extends React.Component<StorageUnitFormPropsType, IStorageUnitFormState> {
    public mapRef : Map;

    public centerPosition = new LatLng(-28.4792625, 24.6727135);
    public mapZoom = 6;

    constructor(props : StorageUnitFormPropsType) {
        super(props);

        this.state = {
            siteStorageUnits: [],
            iconGreen: new L.Icon({
                iconUrl: getMapIcon('marker_green'),
                iconSize:     [42, 42],
                iconAnchor:   [21, 42],
            }),
            iconRed: new L.Icon({
                iconUrl: getMapIcon('marker_red'),
                iconSize:     [42, 42],
                iconAnchor:   [21, 42],
            }),
        };
    }

    componentDidMount() : void {
        if (this.props.values.site) {
            const site = this.props.sites.find(x => x.id === this.props.values.site?.value);
            this.updateSite(site);
        }
    }

    public setMapRef = (ref : Map) => {
        this.mapRef = ref;
    };

    private setNewLatLong = (e : LeafletMouseEvent) => {
        this.mapZoom = 18;
        this.centerPosition = e.latlng;
        this.setState({
            lat: e.latlng.lat,
            long: e.latlng.lng,
        });
    };

    private saveNewUnitPos = (event : React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        this.props.setFieldValue('latitude', this.state.lat);
        this.props.setFieldValue('longitude', this.state.long);
    };

    public onSiteChange = (site : IOptionType) => {
        const newSite = this.props.sites.find(x => x.id === site.value);
        this.updateSite(newSite);
    };

    public updateSite = (newSite : ISite | undefined) => {
        if (newSite) {
            this.mapZoom = 18;
            this.centerPosition = new LatLng(Number(newSite.latitude), Number(newSite.longitude));

            this.setState({
                selectedSite: newSite,
                siteStorageUnits: this.props.storageUnitData.filter(x => x.siteId === newSite.id),
            });
        }
    };

    public getSiteUnits = (state : IStorageUnitFormState) => state.siteStorageUnits;

    public renderUnitsLinkedToSite = createSelector(
        [this.getSiteUnits],
        (units : Array<IStorageUnit>) => {
            const children = units.map(x => x.id !== this.props.values.id &&
                <Marker icon={this.state.iconGreen} title={x.description ?? ''} position={new LatLng(Number(x.latitude), Number(x.longitude))}/>
            );

            return children;
        },
    );

    public render() {
        const lat = this.state.selectedSite?.latitude ?? -23.5792;
        const long = this.state.selectedSite?.longitude ?? 30.1424;
        const sitePos = new LatLng(Number(lat), Number(long));

        const unitLat = this.state.lat ?? this.props.values.latitude ?? lat;
        const unitLong = this.state.long ?? this.props.values.longitude ?? long;
        const storageUnitPos = new LatLng(Number(unitLat), Number(unitLong));

        return (
            <Form className={'p20'}>
                <FormTextInput name={'code'} label={'Code'}/>
                <FormTextInput name={'description'} label={'Description'}/>
                <FormTextInput name={'latitude'} label={'Latitude'}/>
                <FormTextInput name={'longitude'} label={'Longitude'}/>
                <FormAutocompleteSelect name={'site'} label={'Site'} options={this.props.siteOptions} onChange={this.onSiteChange}/>
                <FormSingleToggleButton name={'isTippable'} label={'Is Tippable'} />
                <FormSingleToggleButton name={'hasRawStock'} label={'Has Raw Stock'} />
                <FormSingleToggleButton name={'hasPackedStock'} label={'Has Packed Stock'} />
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <div className='bcg1 PaperBorder mt10 p10 h600 wfill asc fdc aic jcc oln posr'>
                    <Map center={this.centerPosition} onclick={this.setNewLatLong} zoom={this.mapZoom} maxZoom={18} ref={this.setMapRef} className={'flx1 bcw'} preferCanvas>
                        <StandardLayerControl position={'topright'} />
                        <Marker icon={
                            !this.state.lat && !this.state.long ?
                                this.state.iconGreen : this.props.values.latitude === this.state.lat && this.props.values.longitude === this.state.long ?
                                    this.state.iconGreen : this.state.iconRed
                        }
                        title={this.props.values.description ?? ''}
                        position={storageUnitPos}
                        opacity={unitLat === lat && unitLong === long && !this.props.values.latitude ? 0 : 1}/>
                        <Marker title={this.state.selectedSite?.description ?? 'ZZ2 Head Office'} position={sitePos}/>
                        {this.renderUnitsLinkedToSite(this.state)}
                    </Map>
                    <PillButton
                        className={'posa mt ml15 pl20 pr20 h35 zi999 post550 posr40'}
                        text={'Set new storage unit location'}
                        color={'secondary'}
                        disabled={!this.state.lat || !this.state.long || (this.props.values.latitude === this.state.lat && this.props.values.longitude === this.state.long)}
                        onClick={this.saveNewUnitPos}
                    />
                </div>
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const getSites = (state : IRootState) => state.masterData.sites.filter(x => state.auth.session?.user?.siteIds.some(y => x.id === y));

const getSiteOptions = createSelector(
    [getSites],
    (sites : Array<ISite>) => {
        return sites.filter(x => x.isActive).map(x => ({ label: `${x.code} - ${x.description}`, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        siteOptions: getSiteOptions(state),
        sites: getSites(state),
        storageUnitData: state.masterData.storageUnits,
    };
};

export default connect(
    mapStateToProps,
)(StorageUnitForm);

import { IFreshServiceTicketFormValues } from './freshServiceFormValues';

export enum Priorities {
    NA = 0,
    LOW,
    MEDIUM,
    HIGH,
    URGENT,
}

export enum SourceType {
    NA = 0,
    EMAIL,
    PORTAL,
    PHONE,
    CHAT,
    FEEDBACK_WIDGET,
    YAMMER,
    AWS_CLOUDWATCH,
    PAGERDUTY,
    WALKUP,
    SLACK,
}

export enum Status {
    NA = 1,
    OPEN,
    PENDING,
    RESOLVED,
    CLOSED,
}

export interface IFreshServiceTicket {
    name : string;
    requester_id : number;
    email : string;
    phone : string;
    subject : string;
    type : string;
    status : number;
    priority : number;
    description : string;
    responder_id : number;
    attachments : Array<File>;
    cc_emails : Array<string>;
    due_by : Date;
    email_config_id : number;
    fr_due_by : Date;
    groupId : number;
    source : number;
    tags : Array<number>;
    department_id : number;
    category : string;
    sub_category : string;
    item_category : string;
    urgency : number;
    impact : number;
}

export class FreshServiceTicket implements IFreshServiceTicket {
    public name : string;
    // tslint:disable-next-line: variable-name
    public requester_id : number;
    public email : string;
    public phone : string;
    public subject : string;
    public type : string;
    public status : number;
    public priority : number;
    public description : string;
    // tslint:disable-next-line: variable-name
    public responder_id : number;
    // tslint:disable-next-line: variable-name
    public due_by : Date;
    // tslint:disable-next-line: variable-name
    public email_config_id : number;
    // tslint:disable-next-line: variable-name
    public fr_due_by : Date;
    public groupId : number;
    public source : number;

    // tslint:disable-next-line: variable-name
    public department_id : number;
    public category : string;
    // tslint:disable-next-line: variable-name
    public sub_category : string;
    // tslint:disable-next-line: variable-name
    public item_category : string;
    public urgency : number;
    public impact : number;

    public attachments : Array<File>;
    // tslint:disable-next-line: variable-name
    public cc_emails : Array<string>;
    public tags : Array<number>;

    public constructor(values : IFreshServiceTicketFormValues) {
        this.name = values.name;
        this.email = values.email;
        this.subject = values.subject;
        this.description = values.description;
        this.cc_emails = ['it-software-dev-support@zz2.co.za'];
        this.responder_id = 13001528548;
        this.status = values.status ? values.status : Status.OPEN;
        this.priority = values.priority ? values.priority : Priorities.LOW;
    }
}

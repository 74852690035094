import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IColour } from '../../../@types/model/masterData/colour/colour';

export default class ColourHttpService {
    public static getColourData() : AxiosPromise<Array<IColour>> {
        return axios.get(`${PACKMAN_API_URL}Colour/GetAllWeb`);
    }

    public static addOrUpdateColour(data : IColour) : AxiosPromise<IColour> {
        return axios.post(`${PACKMAN_API_URL}Colour/PostColour`, data);
    }

    public static deleteColour(colourId : number) : AxiosPromise<IColour> {
        return axios.delete(`${PACKMAN_API_URL}Colour/Delete`, {
            params : { colourId },
        });
    }
}

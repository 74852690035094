import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { ISite } from '../site/site';
import { IPrintServer } from './printServer';

export interface IPrintServerFormValues {
    id : number;
    guid : string;
    name : string;
    macAddress : string;
    ipAddress : string;
    defaultScheme : string;
    primaryUrl : string;
    port : number;
    site ?: IOptionType;
    isMafrodaIntegrated : boolean;
    isOverrideFlag : boolean;
    isActive : boolean;
}

export class PrintServerFormValues implements IPrintServerFormValues {
    public id : number;
    public guid : string;
    public name : string;
    public macAddress : string;
    public ipAddress : string;
    public primaryUrl : string;
    public defaultScheme : string;
    public port : number;
    public site ?: IOptionType;
    public isMafrodaIntegrated : boolean;
    public isOverrideFlag : boolean;
    public isActive : boolean;

    public constructor(
        printServer ?: IPrintServer,
        sites ?: Array<ISite>,
    ) {
        const site = sites?.find(x => x.id === printServer?.siteId);
        this.initializeDefaultValues();
        if (printServer) {
            this.id = printServer.id;
            this.guid = printServer.guid;
            this.name = printServer.name;
            this.macAddress = printServer.macAddress;
            this.ipAddress = printServer.ipAddress;
            this.primaryUrl = printServer.primaryUrl;
            this.defaultScheme = printServer.defaultScheme;
            this.port = printServer.port;
            this.isMafrodaIntegrated = printServer.isMafrodaIntegrated;
            this.isOverrideFlag = printServer.isOverrideFlag;

            this.site = site ? {
                label: `(${site.code}) ${site.description}`,
                value: site.id,
            } : undefined;

            this.isActive = printServer.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.name = '';
        this.site = undefined;
        this.isMafrodaIntegrated = false;
        this.isOverrideFlag = false;
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        macAddress: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        site: Yup.object().nullable().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

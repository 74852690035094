import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { EmailFormValues } from '../../@types/model/email/emailFormValues';
import PillButton from '../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../components/input/form/FormTextInput';
import { connect } from 'react-redux';
import lodash from 'lodash';
import FormAutocompleteSelect from '../../components/input/form/FormAutoCompleteSelect';

interface IEmailFormProps {
    isLoading : boolean;
}

interface IEmailFormState {
}

type EmailFormPropsType = IEmailFormProps & FormikProps<EmailFormValues>;

class EmailForm extends React.Component<EmailFormPropsType, IEmailFormState> {

    constructor(props : EmailFormPropsType) {
        super(props);
    }

    public render() {
        const { toAddresses, ccAddresses, files } = this.props.initialValues;
        const hasErrors = lodash.toArray(this.props.errors)?.length !== 0;
        return (
            <Form className={'p20'}>
                <FormTextInput name={'subject'} label={'Subject'}/>
                <FormAutocompleteSelect
                    name={'toAddresses'}
                    label={'To'}
                    options={toAddresses}
                    isMulti
                />
                <FormAutocompleteSelect
                    name={'ccAddresses'}
                    label={'CC'}
                    options={ccAddresses}
                    isMulti
                />
                <FormTextInput name={'body'} label={'Body'} multiline rows={4}/>
                <FormAutocompleteSelect
                    name={'files'}
                    label={'Attachments'}
                    options={files}
                    isMulti
                    disabled
                />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={this.props.isLoading || hasErrors || !this.props.isValid}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Send'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const mapStateToProps = () => {
    return {
    };
};

export default connect(
    mapStateToProps,
)(EmailForm);

import { IBaseModel } from '../../model';

export interface IRecipeLine extends IBaseModel {
    order : number;
    tableName ?: string;
    fieldName ?: string;
    fieldType : string;
    collectionMethod ?: string;
    collectionField ?: string;
    recipeId : number;
    callRecipeId ?: number;
}

export class RecipeLine implements IRecipeLine {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public order : number;
    public tableName ?: string;
    public fieldName ?: string;
    public fieldType : string;
    public collectionMethod ?: string;
    public collectionField ?: string;
    public recipeId : number;
    public callRecipeId ?: number;

    public constructor(formValues : IRecipeLine) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.order = formValues.order;
        this.tableName = formValues.tableName;
        this.fieldName = formValues.fieldName;
        this.fieldType = formValues.fieldType;
        this.collectionField = formValues.collectionField;
        this.collectionMethod = formValues.collectionMethod;
        this.recipeId = formValues.recipeId;
        this.callRecipeId = formValues.callRecipeId;
    }
}

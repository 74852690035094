import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { ILotType } from '../../../@types/model/masterData/lotType/lotType';

export default class LotTypeHttpService {
    public static getLotTypeData() : AxiosPromise<Array<ILotType>> {
        return axios.get(`${PACKMAN_API_URL}LotType/GetAllWeb`);
    }

    public static addOrUpdateLotType(data : ILotType) : AxiosPromise<ILotType> {
        return axios.post(`${PACKMAN_API_URL}LotType/PostLotType`, data);
    }

    public static deleteLotType(lotTypeId : number) : AxiosPromise<ILotType> {
        return axios.delete(`${PACKMAN_API_URL}LotType/Delete`, {
            params : { lotTypeId },
        });
    }
}

import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { FaqFormValues } from '../../../@types/model/frequentlyAskedQuestions/faqFormValues';
import PillButton from '../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../components/input/form/FormSingleToggleButton';
import { Icon, IconButton } from '@mui/material';
import CustomTooltip from '../../../components/tooltip/tooltip';

interface IFrequentlyAskedQuestionsFormProps {
    isLoading : boolean;
}

interface IFrequentlyAskedQuestionsFormState {
    isBoldTextClicked : boolean;
}

type FrequentlyAskedQuestionsFormPropsType = IFrequentlyAskedQuestionsFormProps & FormikProps<FaqFormValues>;

export default class FrequentlyAskedQuestionsForm extends React.Component<FrequentlyAskedQuestionsFormPropsType, IFrequentlyAskedQuestionsFormState> {

    constructor(props : FrequentlyAskedQuestionsFormPropsType) {
        super(props);

        this.state = {
            isBoldTextClicked: false,
        };
    }

    private addBulletPoint = () => {
        const currentDescription = this.props.values.description;
        const newDescription = currentDescription + '- ';
        this.props.setFieldValue('description', newDescription);
    };

    private boldText = () => {
        const currentDescription = this.props.values.description;
        const newDescription = currentDescription + '**';
        this.setState({ isBoldTextClicked: !this.state.isBoldTextClicked });
        this.props.setFieldValue('description', newDescription);
    };

    public render() {
        return (
            <Form className={'p20 w500'}>
                <FormTextInput name={'title'} label={'Title'}/>
                <div className={'fdr aic jcfe mr10'}>
                    <CustomTooltip title={'Add Bullet Point'}>
                        <IconButton size={'small'} onClick={this.addBulletPoint}><Icon>format_list_bulleted</Icon></IconButton>
                    </CustomTooltip>
                    <CustomTooltip title={'Bold Text'}>
                        <IconButton className={`${this.state.isBoldTextClicked ? 'bcg2' : ''}`} size={'small'} onClick={this.boldText}><Icon>format_bold</Icon></IconButton>
                    </CustomTooltip>
                </div>
                <FormTextInput name={'description'} multiline rows={5} label={'Description'}/>
                <FormTextInput name={'url'} label={'URL'}/>
                <FormSingleToggleButton name={'isIFrame'} label={'Is IFrame'} />
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <FormSingleToggleButton name={'isStarred'} label={'Is Starred'} />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IStorageUnit } from '../../../@types/model/masterData/storageUnit/storageUnit';
import qs from 'qs';
import { IStorageUnitDashboard } from '../../../@types/model/masterData/storageUnit/storageUnitDashboardData';

export default class StorageUnitHttpService {
    public static getStorageUnitData() : AxiosPromise<Array<IStorageUnit>> {
        return axios.get(`${PACKMAN_API_URL}StorageUnit/GetAllWeb`);
    }

    public static getDashboardData(fromDateUnix ?: number, toDateUnix ?: number, siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<IStorageUnitDashboard> {
        return axios.get(`${PACKMAN_API_URL}StorageUnit/DashboardData`, {
            params: {
                fromDateUnix,
                toDateUnix,
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static addOrUpdateStorageUnit(data : IStorageUnit) : AxiosPromise<IStorageUnit> {
        return axios.post(`${PACKMAN_API_URL}StorageUnit/PostStorageUnit`, data);
    }

    public static deleteStorageUnit(storageUnitId : number) : AxiosPromise<IStorageUnit> {
        return axios.delete(`${PACKMAN_API_URL}StorageUnit/Delete`, {
            params : { storageUnitId },
        });
    }
}

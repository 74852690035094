import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { OrganizationFormValues } from '../../../../@types/model/masterData/organization/organizationFormValues';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import { IOrganization } from '../../../../@types/model/masterData/organization/organization';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { IOptionType } from '../../../../@types/helper';
import FormAutocompleteSelect from '../../../../components/input/form/FormAutoCompleteSelect';
import { createSelector } from 'reselect';
import { IContactInfo } from '../../../../@types/model/masterData/contactInfo/contactInfo';

interface IOrganizationFormProps {
    isLoading : boolean;
    organizationToEdit ?: IOrganization;
    contactInfoOptions : Array<IOptionType>;
}

interface IOrganizationFormState {
}

type OrganizationFormPropsType = IOrganizationFormProps & FormikProps<OrganizationFormValues>;

class OrganizationForm extends React.Component<OrganizationFormPropsType, IOrganizationFormState> {

    constructor(props : OrganizationFormPropsType) {
        super(props);
    }

    public render() {
        return (
            <Form className={'p20'}>
                <FormTextInput name={'code'} label={'Code'}/>
                <FormTextInput name={'name'} label={'Name'}/>
                <FormTextInput name={'exporter'} label={'Exporter Code'}/>
                <FormTextInput name={'waybillPrefix'} label={'Waybill Prefix'} disabled={this.props.organizationToEdit && !!this.props.organizationToEdit?.waybillPrefix}/>
                <FormTextInput name={'nextWaybillNumber'} label={'Next Waybill Nr'} disabled={this.props.organizationToEdit && !!this.props.organizationToEdit?.nextWaybillNumber}/>
                <FormTextInput name={'waybillRangeEnd'} label={'Waybill Range End'} disabled={this.props.organizationToEdit && !!this.props.organizationToEdit?.waybillRangeEnd}/>
                <FormAutocompleteSelect
                    name={'contactInfos'}
                    label={'ContactInfos'}
                    options={this.props.contactInfoOptions}
                    isMulti
                />
                <FormSingleToggleButton name={'isSupplier'} label={'Is Supplier'} />
                <FormSingleToggleButton name={'isClient'} label={'Is Client'} />
                <FormSingleToggleButton name={'canIntake'} label={'Can Intake'} />
                <FormSingleToggleButton name={'requiresProject'} label={'Requires Project'} />
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const getContactInfos = (state : IRootState) => state.masterData.contactInfos;

const getContactInfoOptions = createSelector(
    [getContactInfos],
    (contactInfos : Array<IContactInfo>) => {
        return contactInfos.filter(x => x.isActive).map(x => ({ label: `${x.name}`, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        organizationToEdit: state.data.organizationToEdit,
        contactInfoOptions: getContactInfoOptions(state),
    };
};

export default connect(
    mapStateToProps,
)(OrganizationForm);

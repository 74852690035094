import 'firebase/auth';
import { createAction } from 'typesafe-actions';
import { IUserToken } from '../../@types/model/user/userToken';
import { IConsignmentTokenResponse } from '../../@types/model/compliance/titan-TUR/consignmentTokenResponse';
import { ITURTokenResponse } from '../../@types/model/compliance/titan-TUR/turTokenResponse';

export const authSetSession = createAction('AUTH_SET_SESSION', resolve => (session ?: IUserToken | null) => resolve(session));
export const authSetLoggedIn = createAction('AUTH_SET_LOGGED_IN', resolve => (loggedIn : boolean) => resolve(loggedIn));
export const authSetLoggingIn = createAction('AUTH_SET_LOGGING_IN', resolve => (loggingIn : boolean) => resolve(loggingIn));
export const authSetToken = createAction('AUTH_SET_TOKEN', resolve => (token : string) => resolve(token));
export const authSetPPECBToken = createAction('AUTH_SET_PPECB_TOKEN', resolve => (token : IConsignmentTokenResponse) => resolve(token));
export const authSetTURToken = createAction('AUTH_SET_TUR_TOKEN', resolve => (token : ITURTokenResponse) => resolve(token));

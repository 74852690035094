import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { SiteFormValues } from '../../../../@types/model/masterData/site/siteFormValues';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import FormAutocompleteSelect from '../../../../components/input/form/FormAutoCompleteSelect';
import { createSelector } from 'reselect';
import { IOrganization } from '../../../../@types/model/masterData/organization/organization';
import { IOptionType } from '../../../../@types/helper';
import { IInspectionPoint } from '../../../../@types/model/masterData/inspectionPoint/inspectionPoint';
import FormImageSelector from '../../../../components/input/form/FormImageSelector';
import StandardLayerControl from '../../../../components/map/StandardLayerControl';
import { Map, Marker, Popup } from 'react-leaflet';
import { LatLng, LeafletMouseEvent } from 'leaflet';
import { IContactInfo } from '../../../../@types/model/masterData/contactInfo/contactInfo';
import { IFtpDetail } from '../../../../@types/model/masterData/ftpDetail/ftpDetail';

interface ISiteFormProps {
    isLoading : boolean;
    organizationOptions : Array<IOptionType>;
    inspectionPointOptions : Array<IOptionType>;
    contactInfoOptions : Array<IOptionType>;
    ftpDetailOptions : Array<IOptionType>;
}

interface ISiteFormState {
    currentImage : string;
    currentPos ?: LatLng;
    lat ?: number;
    long ?: number;
}

type SiteFormPropsType = ISiteFormProps & FormikProps<SiteFormValues>;

class SiteForm extends React.Component<SiteFormPropsType, ISiteFormState> {
    public mapRef : Map;

    public mapZoom = 10;

    constructor(props : SiteFormPropsType) {
        super(props);

        this.state = {
            currentImage: '',
        };
    }

    public setMapRef = (ref : Map) => {
        this.mapRef = ref;
    };

    private setNewLatLong = (e : LeafletMouseEvent) => {
        this.setState({
            currentPos: e.latlng,
            lat: e.latlng.lat,
            long: e.latlng.lng,
        });
    };

    private saveNewSitePos = (event : React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        this.props.setFieldValue('latitude', this.state.lat);
        this.props.setFieldValue('longitude', this.state.long);
    };

    public render() {
        const lat = this.props.values?.latitude && this.props.values.latitude !== '-' ? this.props.values?.latitude : -23.5792;
        const long = this.props.values?.longitude && this.props.values?.longitude !== '-' ? this.props.values?.longitude : 30.1424;
        const initialPosition = new LatLng(Number(lat), Number(long));
        return (
            <Form className={'p20'}>
                <FormTextInput name={'code'} label={'Code'}/>
                <FormTextInput name={'shortDescription'} label={'Short Description'}/>
                <FormTextInput name={'description'} label={'Description'}/>
                <FormTextInput name={'latitude'} label={'Latitude'}/>
                <FormTextInput name={'longitude'} label={'Longitude'}/>
                <FormTextInput name={'exporterName'} label={'Exporter Name'}/>
                <FormTextInput name={'exporterEmail'} label={'Exporter Email'}/>
                <FormTextInput name={'globalGapNumber'} label={'Global Gap Number'}/>
                <FormTextInput multiline rows={5} name={'address'} label={'Address'}/>
                <FormTextInput name={'telNumber'} label={'Telephone Number'}/>
                <FormTextInput name={'faxNumber'} label={'Fax Number'}/>
                <FormTextInput name={'packhouseDateCode'} label={'Packhouse Date Code'}/>
                <FormAutocompleteSelect name={'inspectionPoint'} label={'Inspection Point'} options={this.props.inspectionPointOptions}/>
                <FormAutocompleteSelect
                    name={'organizations'}
                    label={'Organizations'}
                    options={this.props.organizationOptions}
                    isMulti
                />
                <FormAutocompleteSelect
                    name={'contactInfos'}
                    label={'Contact Info'}
                    options={this.props.contactInfoOptions}
                    isMulti
                />
                <FormAutocompleteSelect
                    name={'ftpDetails'}
                    label={'FTP Details'}
                    options={this.props.ftpDetailOptions?? []}
                    isMulti
                />
                <FormImageSelector name={'dispatchHeaderLogo'}/>
                <div className='bcg1 PaperBorder mt10 p10 h600 wfill asc fdc aic jcc oln posr'>
                    <Map center={initialPosition} onclick={this.setNewLatLong} zoom={this.mapZoom} maxZoom={18} ref={this.setMapRef} className={'flx1 bcw'} preferCanvas>
                        <StandardLayerControl position={'topright'} />
                        <Marker title={this.props.values.description ? this.props.values.description : 'ZZ2 Head Office'} position={this.state.currentPos ? this.state.currentPos : initialPosition}>
                            <Popup>{this.props.values.description ? this.props.values.description : 'ZZ2 Head Office'}</Popup>
                        </Marker>
                    </Map>
                    <PillButton
                        className={'posa mt ml15 pl20 pr20 h35 zi999 post550 posr40'}
                        text={'Set new site position'}
                        color={'secondary'}
                        disabled={this.state.lat === undefined && this.state.long === undefined }
                        onClick={this.saveNewSitePos}
                    />
                </div>
                <FormSingleToggleButton name={'isPackhouse'} label={'Is Packhouse'} />
                <FormSingleToggleButton name={'isBRCCompliant'} label={'Is BRC Compliant'} />
                <FormSingleToggleButton name={'isSimple'} label={'Is Simple'} />
                <FormSingleToggleButton name={'isDeliveryPoint'} label={'Is Delivery Point'} />
                <FormSingleToggleButton name={'isExternal'} label={'Is External'} />
                <FormSingleToggleButton name={'handlesExport'} label={'Handles Export'} />
                <FormSingleToggleButton name={'canMixSalesPoint'} label={'Can Mix Sales Point'} />
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const getInspectionPoints = (state : IRootState) => state.masterData.inspectionPoints;

const getInspectionPointOptions = createSelector(
    [getInspectionPoints],
    (inspectionPoints : Array<IInspectionPoint>) => {
        return inspectionPoints.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id })) ?? [];
    },
);

const getOrganizations = (state : IRootState) => state.masterData.organizations;

const getOrganizationOptions = createSelector(
    [getOrganizations],
    (organizations : Array<IOrganization>) => {
        return organizations.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id })) ?? [];
    },
);

const getContactInfos = (state : IRootState) => state.masterData.contactInfos;

const getContactInfoOptions = createSelector(
    [getContactInfos],
    (contactInfos : Array<IContactInfo>) => {
        return contactInfos.filter(x => x.isActive).map(x => ({ label: `${x.name}`, value: x.id })) ?? [];
    },
);

const getFtpDetails = (state : IRootState) => state.masterData.ftpDetails;

const getFtpDetailOptions = createSelector(
    [getFtpDetails],
    (ftpDetails : Array<IFtpDetail>) => {
        return ftpDetails.filter(x => x.isActive).map(x => ({ label: `${x.name}`, value: x.id })) ?? [];
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        organizationOptions: getOrganizationOptions(state),
        inspectionPointOptions: getInspectionPointOptions(state),
        contactInfoOptions: getContactInfoOptions(state),
        ftpDetailOptions: getFtpDetailOptions(state),
    };
};

export default connect(
    mapStateToProps,
)(SiteForm);

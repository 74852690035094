import * as React from 'react';
import { IOptionType } from '../../@types/helper';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import LotHttpService from '../../services/http/lot/lotHttpService';
import { IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import { ILotMassImportRow } from '../../@types/model/lot/LotMassImportRow';
import { ILotView } from '../../@types/model/lot/lotView';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import { Button, Tooltip, Table, TableRow, TableCell, IconButton, Icon } from '@mui/material';
import { ListChildComponentProps, FixedSizeList as WindowList } from 'react-window';
import FileSelector from '../../components/input/FileSelector';
import { CSVLink } from 'react-csv';
import PapaParse, { ParseConfig } from 'papaparse';
import PillButton from '../../components/input/PillButton';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { ICommodityState } from '../../@types/model/masterData/commodityState/commodityState';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IStorageUnit } from '../../@types/model/masterData/storageUnit/storageUnit';
import { ILotType } from '../../@types/model/masterData/lotType/lotType';
import { ISite } from '../../@types/model/masterData/site/site';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { createSelector } from 'reselect';
import { addArrayElement, removeArrayElement, upsertArrayElement } from '../../services/appFunctionsService';
import { dataSetLotImportRelatedData, dataSetLotView } from '../../store/data/Functions';
import { generateLotCards, renderQRCodes } from '../../services/lot/lotDocumentService';
import { ICreateLot } from '../../@types/model/lot/createLot';
import { syncMasterData } from '../../services/masterDataSyncService';
import CustomTooltip from '../../components/tooltip/tooltip';

export const lotImportCSVHeaders = {
    lotName: 'LotName',
    lotTypeGuid: 'LotTypeGuid',
    organizationGuid: 'OrganizationGuid',
    siteGuid: 'SiteGuid',
    storageUnitGuid: 'StorageUnitGuid',
    commodityGuid: 'CommodityGuid',
    varietyGuid: 'VarietyGuid',
    farmGuid: 'FarmGuid',
    orchardGuid: 'OrchardGuid',
    batchGuid: 'BatchGuid',
    packGuid: 'PackGuid',
    commodityStateGuid: 'CommodityStateGuid',
    numberOfUnits: 'NumberOfUnits',
};

interface ILotMassImportDialogProps {
    isLoading : boolean;
    setLoading : (isLoading : boolean) => void;
    close : () => void;
    selectedOrganizationIds ?: Array<number>;
    selectedSiteIds ?: Array<number>;
    storageUnits : Array<IStorageUnit>;
    lotViews : Array<ILotView>;
    lotTypes : Array<ILotType>;
    farms : Array<IFarm>;
    sites : Array<ISite>;
    organizations : Array<IOrganization>;
    commodityStates : Array<ICommodityState>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    orchards : Array<IOrchard>;
    packs : Array<IPack>;
}

interface ILotMassImportDialogState {
    isLotImportDialogOpen : boolean;
    selectedFiles : Array<File>;
    importedLots : Array<ILotMassImportRow>;
    selectedOrganization ?: IOptionType;
    selectedSite ?: IOptionType;
    selectedLotType ?: IOptionType;
    selectedMassLotImportStorageUnit ?: IOptionType;
    selectedMassLotImportCommodity ?: IOptionType;
    selectedMassLotImportVariety ?: IOptionType;
    selectedMassLotImportFarm ?: IOptionType;
    selectedMassLotImportOrchard ?: IOptionType;
    selectedMassLotImportPack ?: IOptionType;
    selectedMassLotImportCommodityState ?: IOptionType;
    isLotCardGenerateButtonEnabled : boolean;
    newlyCreatedLots : Array<ILotView>;
}

class LotMassImportDialog extends React.Component<ILotMassImportDialogProps, ILotMassImportDialogState> {

    constructor(props : ILotMassImportDialogProps) {
        super(props);

        this.state = {
            isLotImportDialogOpen: false,
            selectedFiles: [],
            importedLots: [],
            isLotCardGenerateButtonEnabled: false,
            newlyCreatedLots: [],
        };
    }

    public componentDidMount = async () => {
        this.props.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        const organizationOptions = this.getMassLotImportOrganizationOptions(this.props);
        if (organizationOptions.length === 1) {
            this.setState({ selectedOrganization: organizationOptions[0] }, () => {
                const siteOptions = this.getMassLotImportSiteOptions(this.props, this.state);
                if (siteOptions.length === 1) {
                    this.setState({ selectedSite: siteOptions[0] });
                }
            });
        }
        try {
            const res = await LotHttpService.getLotImportRelatedData(!isIndexedDBAvailable);

            if (res && res.data) {
                dataSetLotImportRelatedData(res.data);
            }
        } catch (e) {
            generalShowErrorSnackbar('Error occured while loading data for importing');
        } finally {
            this.props.setLoading(false);
        }
    };

    private submitImportedLots = async () => {
        if (this.state.importedLots.length > 0) {
            let lotsToBeImported : Array<ICreateLot> = [];

            this.props.setLoading(true);
            try {
                this.state.importedLots.forEach((x) => {
                    const newLotObject : ICreateLot = {
                        lotName: x.lotName,
                        orgId: Number(this.state.selectedOrganization?.value),
                        siteId: Number(this.state.selectedSite?.value),
                        lotTypeId: Number(this.state.selectedLotType?.value),
                        storageUnitId: Number(x.storageUnitOption?.value),
                        commId: Number(x.commOption?.value),
                        varId: Number(x.varOption?.value),
                        farmId: Number(x.farmOption?.value),
                        orchardId: Number(x.orchardOption?.value),
                        packId: Number(x.packOption?.value),
                        commodityStateId: Number(x.commodityStateOption?.value),
                        numberOfUnits: x.numberOfUnits,
                    };

                    lotsToBeImported = addArrayElement(lotsToBeImported, newLotObject);
                });

                const res = await LotHttpService.upsertNewLots(lotsToBeImported);

                if (res && res.data) {
                    res.data.forEach(x => dataSetLotView(x));
                    this.setState({ newlyCreatedLots: res.data, isLotCardGenerateButtonEnabled: true });
                }
                generalShowSuccessSnackbar('Lots created successfully!');
            } catch (e) {
                generalShowErrorSnackbar(e.data.Message);
            } finally {
                this.props.setLoading(false);
            }
        }
    };

    private closeLotImportDialog = () => {
        this.setState({
            isLotImportDialogOpen: false,
            selectedFiles: [],
            importedLots: [],
            selectedOrganization: undefined,
            selectedSite: undefined,
            selectedLotType: undefined,
            selectedMassLotImportStorageUnit: undefined,
            selectedMassLotImportCommodity: undefined,
            selectedMassLotImportVariety: undefined,
            selectedMassLotImportFarm: undefined,
            selectedMassLotImportOrchard: undefined,
            selectedMassLotImportPack: undefined,
            selectedMassLotImportCommodityState: undefined,
        });
        this.props.close();
    };

    public getCommodityStates = (props : ILotMassImportDialogProps) => props.commodityStates;
    public getSelectedOrganizationIds = (props : ILotMassImportDialogProps) => props.selectedOrganizationIds;
    public getLotTypes = (props : ILotMassImportDialogProps) => props.lotTypes;
    public getSites = (props : ILotMassImportDialogProps) => props.sites;
    public getOrganizations = (props : ILotMassImportDialogProps) => props.organizations;
    public getCommodities = (props : ILotMassImportDialogProps) => props.commodities;
    public getVarieties = (props : ILotMassImportDialogProps) => props.varieties;
    public getFarms = (props : ILotMassImportDialogProps) => props.farms;
    public getPacks = (props : ILotMassImportDialogProps) => props.packs;
    public getOrchards = (props : ILotMassImportDialogProps) => props.orchards;
    public getStorageUnits = (props : ILotMassImportDialogProps) => props.storageUnits;
    public getSelectedSiteIds = (props : ILotMassImportDialogProps) => props.selectedSiteIds;
    public getSelectedSite = (props : ILotMassImportDialogProps, state : ILotMassImportDialogState) => state.selectedSite;
    public getSelectedOrganization = (props : ILotMassImportDialogProps, state : ILotMassImportDialogState) => state.selectedOrganization;
    public getImportedLots = (props : ILotMassImportDialogProps, state : ILotMassImportDialogState) => state.importedLots;
    public getSelectedMassLotImportCommodity = (props : ILotMassImportDialogProps, state : ILotMassImportDialogState) => state.selectedMassLotImportCommodity;
    public getSelectedMassLotImportFarm = (props : ILotMassImportDialogProps, state : ILotMassImportDialogState) => state.selectedMassLotImportFarm;
    public getNewlyCreatedLots = (props : ILotMassImportDialogProps, state : ILotMassImportDialogState) => state.newlyCreatedLots;

    private getMassLotImportOrganizationOptions = createSelector(
        [this.getOrganizations, this.getSelectedOrganizationIds],
        (organizations : Array<IOrganization>, selectedOrganizationIds ?: Array<number>) => {
            return organizations.filter(x => selectedOrganizationIds?.some(y => y === x.id) && x.isActive).map((x) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            });
        },
    );

    private getMassLotImportSiteOptions = createSelector(
        [this.getSites, this.getSelectedSiteIds, this.getSelectedOrganization],
        (sites : Array<ISite>, selectedSiteIds ?: Array<number>, selectedOrganization ?: IOptionType) => {
            return sites.filter(x => selectedSiteIds?.some(y => y === x.id) && (!!selectedOrganization ? x.organizationIds.some(y => y === selectedOrganization.value) : true) && x.isActive).map((x) => {
                return { label: `(${x.shortDescription}) ${x.description}`, value: x.id };
            });
        },
    );

    private getLotTypeOptions = createSelector(
        [this.getLotTypes],
        (lotTypes : Array<ILotType>) => {
            return lotTypes.filter(x => x.isActive).map((x) => {
                return { label: x.name, value: x.id };
            });
        },
    );

    private getMassLotImportStorageUnitOptions = createSelector(
        [this.getStorageUnits, this.getSelectedSite],
        (storageUnits : Array<IStorageUnit>, selectedSite ?: IOptionType) => {
            return storageUnits.filter(x => x.isActive && (!!selectedSite ? x.siteId === selectedSite.value : false)).map((x) => {
                return { label: `(${x.code}) ${x.description}`, value: x.id };
            });
        },
    );

    private getMassLotImportCommodityOptions = createSelector(
        [this.getCommodities, this.getSelectedOrganization],
        (commodites : Array<ICommodity>, selectedOrganization ?: IOptionType) => {
            return commodites.filter(x => x.isActive && (!!selectedOrganization ? x.organizationIds.some(y => y === selectedOrganization.value) : true)).map((x) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            });
        },
    );

    private getMassLotImportVarietyOptions = (row : ILotMassImportRow) => {
        const varieties = this.props.varieties;

        return varieties.filter(x => x.isActive && x.commodityId === row.commOption?.value).map((x) => {
            return { label: `(${x.code}) ${x.name}`, value: x.id };
        });
    };

    private getSelectAllMassLotImportVarietyOptions = createSelector(
        [this.getVarieties, this.getSelectedMassLotImportCommodity],
        (varieties : Array<IVariety>, selectedMassLotImportCommodity ?: IOptionType) => {
            return varieties.filter(x => x.isActive && x.commodityId === selectedMassLotImportCommodity?.value).map((x) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            });
        },
    );

    private getSelectAllMassLotImportFarmOptions = createSelector(
        [this.getFarms, this.getSelectedOrganization],
        (farms : Array<IFarm>, selectedOrganization ?: IOptionType) => {
            return farms.filter(x => x.isActive && x.organizationIds.some(y => y === selectedOrganization?.value)).map((x) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            });
        },
    );

    private getMassLotImportFarmOptions = createSelector(
        [this.getFarms, this.getSelectedOrganization],
        (farms : Array<IFarm>, selectedOrganization ?: IOptionType) => {
            return farms.filter(x => x.isActive && x.organizationIds.some(y => y === selectedOrganization?.value)).map((x) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            });
        },
    );

    private getSelectAllMassLotImportOrchardOptions = createSelector(
        [this.getOrchards, this.getSelectedMassLotImportCommodity, this.getSelectedMassLotImportFarm],
        (orchards : Array<IOrchard>, selectedMassLotImportCommodity ?: IOptionType, selectedMassLotImportFarm ?: IOptionType) => {
            return orchards.filter(x => x.isActive && x.commodityId === selectedMassLotImportCommodity?.value && x.farmId === selectedMassLotImportFarm?.value).map((x) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            });
        },
    );

    private getMassLotImportOrchardOptions = (row : ILotMassImportRow) => {
        const orchards = this.props.orchards;

        return orchards.filter(x => x.isActive && x.commodityId === row.commOption?.value && x.farmId === row.farmOption?.value).map((x) => {
            return { label: `(${x.code}) ${x.name}`, value: x.id };
        });
    };

    private getSelectAllMassLotImportPackOptions = createSelector(
        [this.getPacks, this.getSelectedMassLotImportCommodity],
        (packs : Array<IPack>, selectedMassLotImportCommodity ?: IOptionType) => {
            return packs.filter(x => x.isActive && x.commodityId === selectedMassLotImportCommodity?.value).map((x) => {
                return { label: `(${x.code}) ${x.description}`, value: x.id };
            });
        },
    );

    private getMassLotImportPackOptions = (row : ILotMassImportRow) => {
        const packs = this.props.packs;

        return packs.filter(x => x.isActive && x.commodityId === row.commOption?.value).map((x) => {
            return { label: `(${x.code}) ${x.description}`, value: x.id };
        });
    };

    private getSelectAllMassLotImportCommodityStateOptions = createSelector(
        [this.getCommodityStates, this.getSelectedMassLotImportCommodity],
        (commodityState : Array<ICommodityState>, selectedMassLotImportCommodity ?: IOptionType) => {
            return commodityState.filter(x => x.isActive && x.commodityIds.some(y => y === selectedMassLotImportCommodity?.value)).map((x) => {
                return { label: x.name, value: x.id };
            });
        },
    );

    private getMassLotImportCommodityStateOptions = (row : ILotMassImportRow) => {
        const commodityStates = this.props.commodityStates;

        return commodityStates.filter(x => x.isActive && x.commodityIds.some(y => y === row.commOption?.value)).map((x) => {
            return { label: x.name, value: x.id };
        });
    };

    private getImportedLotRows = createSelector(
        [this.getImportedLots],
        (importedLots : Array<ILotMassImportRow>) => {
            return importedLots;
        },
    );

    private onLotMassImportOrganizationChange = async (e : React.ChangeEvent<{}>, selectedOrganization : IOptionType) => {
        let newList : Array<ILotMassImportRow> = [];

        this.state.importedLots.forEach((x) => {
            const newLotObject : ILotMassImportRow = {
                ...x,
                commOption: undefined,
                farmOption: undefined,
                varOption: undefined,
                storageUnitOption: undefined,
                orchardOption: undefined,
                packOption: undefined,
                commodityStateOption: undefined,
            };
            newList = addArrayElement(newList, newLotObject, 'end');
        });

        this.setState({
            selectedOrganization,
            selectedSite: undefined,
            selectedLotType: undefined,
            selectedMassLotImportCommodity: undefined,
            selectedMassLotImportFarm: undefined,
            selectedMassLotImportStorageUnit: undefined,
            selectedMassLotImportVariety: undefined,
            selectedMassLotImportOrchard: undefined,
            selectedMassLotImportPack: undefined,
            selectedMassLotImportCommodityState: undefined,
            importedLots: newList,
        });
    };

    private onLotMassImportSiteChange = async (e : React.ChangeEvent<{}>, selectedSite : IOptionType) => {
        let newList : Array<ILotMassImportRow> = [];

        this.state.importedLots.forEach((x) => {
            const newLotObject : ILotMassImportRow = {
                ...x,
                storageUnitOption: undefined,
            };
            newList = addArrayElement(newList, newLotObject, 'end');
        });

        this.setState({ selectedSite, selectedMassLotImportStorageUnit: undefined, importedLots: newList });
    };

    private onLotTypeChange = async (e : React.ChangeEvent<{}>, selectedLotType : IOptionType) => {
        this.setState({ selectedLotType });
    };

    private onSelectAllMassLotImportStorageUnitChange = async (e : React.ChangeEvent<{}>, selectedStorageUnit : IOptionType) => {
        let newList : Array<ILotMassImportRow> = [];

        this.state.importedLots.forEach((x) => {
            const newLotObject : ILotMassImportRow = {
                ...x,
                storageUnitOption: selectedStorageUnit,
            };
            newList = addArrayElement(newList, newLotObject, 'end');
        });

        this.setState({ importedLots: newList, selectedMassLotImportStorageUnit: selectedStorageUnit });
    };

    private onSelectAllMassLotImportCommodityChange = async (e : React.ChangeEvent<{}>, selectedCommodity : IOptionType) => {
        let newList : Array<ILotMassImportRow> = [];

        this.state.importedLots.forEach((x) => {
            const newLotObject : ILotMassImportRow = {
                ...x,
                commOption: selectedCommodity,
            };
            newList = addArrayElement(newList, newLotObject, 'end');
        });
        this.setState({
            importedLots: newList,
            selectedMassLotImportCommodity: selectedCommodity,
            selectedMassLotImportVariety: undefined,
            selectedMassLotImportOrchard: undefined,
            selectedMassLotImportPack: undefined,
            selectedMassLotImportCommodityState: undefined,
        });
    };

    private onSelectAllMassLotImportVarietyChange = async (e : React.ChangeEvent<{}>, selectedVariety : IOptionType) => {
        let newList : Array<ILotMassImportRow> = [];

        this.state.importedLots.forEach((x) => {
            const newLotObject : ILotMassImportRow = {
                ...x,
                varOption: selectedVariety,
            };
            newList = addArrayElement(newList, newLotObject, 'end');
        });
        this.setState({ importedLots: newList, selectedMassLotImportVariety: selectedVariety });
    };

    private onSelectAllMassLotImportFarmChange = async (e : React.ChangeEvent<{}>, selectedFarm : IOptionType) => {
        let newList : Array<ILotMassImportRow> = [];

        this.state.importedLots.forEach((x) => {
            const newLotObject : ILotMassImportRow = {
                ...x,
                farmOption: selectedFarm,
            };
            newList = addArrayElement(newList, newLotObject, 'end');
        });
        this.setState({ importedLots: newList, selectedMassLotImportFarm: selectedFarm });
    };

    private onSelectAllMassLotImportOrchardChange = async (e : React.ChangeEvent<{}>, selectedOrchard : IOptionType) => {
        let newList : Array<ILotMassImportRow> = [];

        this.state.importedLots.forEach((x) => {
            const newLotObject : ILotMassImportRow = {
                ...x,
                orchardOption: selectedOrchard,
            };
            newList = addArrayElement(newList, newLotObject, 'end');
        });
        this.setState({ importedLots: newList, selectedMassLotImportOrchard: selectedOrchard });
    };

    private onSelectAllMassLotImportPackChange = async (e : React.ChangeEvent<{}>, selectedPack : IOptionType) => {
        let newList : Array<ILotMassImportRow> = [];

        this.state.importedLots.forEach((x) => {
            const newLotObject : ILotMassImportRow = {
                ...x,
                packOption: selectedPack,
            };
            newList = addArrayElement(newList, newLotObject, 'end');
        });
        this.setState({ importedLots: newList, selectedMassLotImportPack: selectedPack });
    };

    private onSelectAllMassLotImportCommodityStateChange = async (e : React.ChangeEvent<{}>, selectedCommodityState : IOptionType) => {
        let newList : Array<ILotMassImportRow> = [];

        this.state.importedLots.forEach((x) => {
            const newLotObject : ILotMassImportRow = {
                ...x,
                commodityStateOption: selectedCommodityState,
            };
            newList = addArrayElement(newList, newLotObject, 'end');
        });
        this.setState({ importedLots: newList, selectedMassLotImportCommodityState: selectedCommodityState });
    };

    private onMassLotImportStorageUnitChange = async (e ?: React.ChangeEvent<{}>, row ?: ILotMassImportRow, selectedStorageUnit ?: IOptionType) => {
        const importedLots : Array<ILotMassImportRow> = this.state.importedLots;
        const importedLot = this.state.importedLots.find(x => x.lotName === row?.lotName);
        if (importedLot) {
            importedLot.storageUnitOption = selectedStorageUnit;
            const newList = upsertArrayElement(importedLots, importedLot, x => x.lotName === importedLot.lotName);
            this.setState({ importedLots: newList ?? this.state.importedLots });
        }
    };

    private onMassLotImportCommodityChange = async (e ?: React.ChangeEvent<{}>, row ?: ILotMassImportRow, selectedCommodity ?: IOptionType) => {
        const importedLot = this.state.importedLots.find(x => x.lotName === row?.lotName);
        if (importedLot) {
            importedLot.commOption = selectedCommodity;
            importedLot.varOption = undefined;
            importedLot.orchardOption = undefined;
            importedLot.packOption = undefined;
            importedLot.commodityStateOption = undefined;
            this.setState(prevState => ({ importedLots: upsertArrayElement(prevState.importedLots, importedLot, x => x.lotName === importedLot.lotName) ?? this.state.importedLots }));
        }
    };

    private onMassLotImportVarietyChange = async (e ?: React.ChangeEvent<{}>, row ?: ILotMassImportRow, selectedVariety ?: IOptionType) => {
        const importedLots : Array<ILotMassImportRow> = this.state.importedLots;
        const importedLot = this.state.importedLots.find(x => x.lotName === row?.lotName);
        if (importedLot) {
            importedLot.varOption = selectedVariety;
            const newList = upsertArrayElement(importedLots, importedLot, x => x.lotName === importedLot.lotName);
            this.setState({ importedLots: newList ?? this.state.importedLots });
        }
    };

    private onMassLotImportFarmChange = async (e ?: React.ChangeEvent<{}>, row ?: ILotMassImportRow, selectedFarm ?: IOptionType) => {
        const importedLots : Array<ILotMassImportRow> = this.state.importedLots;
        const importedLot = this.state.importedLots.find(x => x.lotName === row?.lotName);
        if (importedLot) {
            importedLot.farmOption = selectedFarm;
            const newList = upsertArrayElement(importedLots, importedLot, x => x.lotName === importedLot.lotName);
            this.setState({ importedLots: newList ?? this.state.importedLots });
        }
    };

    private onMassLotImportOrchardChange = async (e ?: React.ChangeEvent<{}>, row ?: ILotMassImportRow, selectedOrchard ?: IOptionType) => {
        const importedLots : Array<ILotMassImportRow> = this.state.importedLots;
        const importedLot = this.state.importedLots.find(x => x.lotName === row?.lotName);
        if (importedLot) {
            importedLot.orchardOption = selectedOrchard;
            const newList = upsertArrayElement(importedLots, importedLot, x => x.lotName === importedLot.lotName);
            this.setState({ importedLots: newList ?? this.state.importedLots });
        }
    };

    private onMassLotImportPackChange = async (e ?: React.ChangeEvent<{}>, row ?: ILotMassImportRow, selectedPack ?: IOptionType) => {
        const importedLots : Array<ILotMassImportRow> = this.state.importedLots;
        const importedLot = this.state.importedLots.find(x => x.lotName === row?.lotName);
        if (importedLot) {
            importedLot.packOption = selectedPack;
            const newList = upsertArrayElement(importedLots, importedLot, x => x.lotName === importedLot.lotName);
            this.setState({ importedLots: newList ?? this.state.importedLots });
        }
    };

    private onMassLotImportCommodityStateChange = async (e ?: React.ChangeEvent<{}>, row ?: ILotMassImportRow, selectedCommodityState ?: IOptionType) => {
        const importedLots : Array<ILotMassImportRow> = this.state.importedLots;
        const importedLot = this.state.importedLots.find(x => x.lotName === row?.lotName);
        if (importedLot) {
            importedLot.commodityStateOption = selectedCommodityState;
            const newList = upsertArrayElement(importedLots, importedLot, x => x.lotName === importedLot.lotName);
            this.setState({ importedLots: newList ?? this.state.importedLots });
        }
    };

    private getNewCreatedLotsAfterImport = createSelector(
        [this.getNewlyCreatedLots],
        (lots : Array<ILotView>) => {
            return lots;
        },
    );

    private getImportLotFileSelectorTooltipTitle = () => {
        let result : string = '';
        if (!this.state.selectedOrganization) {
            result = 'Please select an organization.';
        } else if (!this.state.selectedSite) {
            result = 'Please select a site.';
        } else if (!this.state.selectedLotType) {
            result = 'Please select a lot type.';
        }

        return result;
    };

    private onFileRemove = (file : File) => {
        const index = this.state.selectedFiles?.findIndex(x => x.name === file.name);
        if (index > -1) {
            this.setState({
                selectedFiles: removeArrayElement(this.state.selectedFiles, index),
                importedLots: [],
                selectedMassLotImportStorageUnit: undefined,
                selectedMassLotImportCommodity: undefined,
                selectedMassLotImportVariety: undefined,
                selectedMassLotImportFarm: undefined,
                selectedMassLotImportOrchard: undefined,
                selectedMassLotImportPack: undefined,
                selectedMassLotImportCommodityState: undefined,
            });
        }
    };

    private exportLotsImportTemplate = () => {
        const returnData : Array<Array<string>> = [];
        returnData.push([
            lotImportCSVHeaders.lotName,
            lotImportCSVHeaders.numberOfUnits,
        ]);
        return returnData;
    };

    private handleFileInputChange = (files : Array<File>) => {
        files.forEach(x => this.setState({ selectedFiles: addArrayElement(this.state.selectedFiles, x, 'end') }));
        this.getFileData(files);
    };

    private getFileData = (files : Array<File>) => {
        let newLots : Array<ICreateLot> = [];
        let duplicateLotNames : Array<ICreateLot> = [];
        let lotNamesWithPoles : Array<ICreateLot> = [];
        if (files?.length > 0) {
            files.forEach((file) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const data = reader.result;

                    const parserOptions : ParseConfig = {
                        delimiter: ',',
                    };
                    const csvData = PapaParse.parse(
                        // @ts-ignore
                        data,
                        Object.assign(parserOptions),
                    );
                    if (typeof data === 'string') {
                        const lotNameIndex = csvData.data[0].findIndex((x : any) => x.toLowerCase() === lotImportCSVHeaders.lotName.toLowerCase());
                        const numberOfUnitsIndex = csvData.data[0].findIndex((x : any) => x.toLowerCase() === lotImportCSVHeaders.numberOfUnits.toLowerCase());

                        csvData.data.forEach((x : any, index : number) => {
                            if (!!x[lotNameIndex] && x[lotNameIndex] !== '' && index > 0) {
                                const newLot : ILotMassImportRow = {
                                    lotName: x[lotNameIndex],
                                    numberOfUnits: x[numberOfUnitsIndex] === '' ? 0 : x[numberOfUnitsIndex],
                                };

                                const duplicateLotIndex = newLots.findIndex(y => y.lotName === newLot.lotName);

                                if (duplicateLotIndex > -1) {
                                    duplicateLotNames = addArrayElement(duplicateLotNames, newLot);
                                }

                                const lotNameCharList : Array<string> = x[lotNameIndex].split('');
                                if (lotNameCharList.some(y => y === '|')) {
                                    lotNamesWithPoles = addArrayElement(lotNamesWithPoles, newLot);
                                }

                                newLots = addArrayElement(newLots, newLot);
                            }
                        });

                        if (duplicateLotNames.length > 0) {
                            generalShowErrorSnackbar(`There are ${duplicateLotNames?.length} duplicate ${duplicateLotNames?.length < 2 ? ' lot' : ' lots'} found. "${duplicateLotNames[0].lotName}" has duplicates. Please correct before importing again.`);
                            this.setState({
                                selectedFiles: [],
                                importedLots: [],
                                selectedMassLotImportStorageUnit: undefined,
                                selectedMassLotImportCommodity: undefined,
                                selectedMassLotImportVariety: undefined,
                                selectedMassLotImportFarm: undefined,
                                selectedMassLotImportOrchard: undefined,
                                selectedMassLotImportPack: undefined,
                                selectedMassLotImportCommodityState: undefined,
                            });
                        }
                        if (lotNamesWithPoles.length > 0) {
                            generalShowErrorSnackbar(`Lot names cannot contain poles. ${lotNamesWithPoles?.length} lot ${lotNamesWithPoles?.length < 2 ? ' name' : ' names'} found containing poles. Please remove the poles before importing again.`);
                            this.setState({
                                selectedFiles: [],
                                importedLots: [],
                                selectedMassLotImportStorageUnit: undefined,
                                selectedMassLotImportCommodity: undefined,
                                selectedMassLotImportVariety: undefined,
                                selectedMassLotImportFarm: undefined,
                                selectedMassLotImportOrchard: undefined,
                                selectedMassLotImportPack: undefined,
                                selectedMassLotImportCommodityState: undefined,
                            });
                        }
                        if (duplicateLotNames.length < 1 && lotNamesWithPoles.length < 1) {
                            this.setState({ importedLots: newLots.reverse() });
                        }

                    }
                };
                reader.readAsText(file);
            });
        }
    };

    private onLotCardsGenerate = () => {
        if (this.state.newlyCreatedLots.length > 0) {
            generateLotCards(this.state.newlyCreatedLots);
        }
    };

    private removeImportedLot = (rowIndex : number) => {
        this.setState(prevState => ({ importedLots: removeArrayElement(prevState.importedLots, rowIndex) }));
    };

    private renderListElement = React.memo(({ data, index, style } : React.PropsWithChildren<ListChildComponentProps>) => {
        const importedLot : ILotMassImportRow = data[index];
        if (!importedLot) return <div />;
        return (
            <TableRow style={style} className={`${index % 2 === 0 ? 'bcTableRow2' : 'bcTableRow1'}`}>
                <TableCell className={'wfill jcc aic hfill fs14 p0 fw500'}>
                    <div style={{ overflowWrap: 'break-word' }} className={'w150 bl1 hfill'}>{importedLot.lotName}</div>
                    <div className={'flx1 aic jcc bl1 hfill'}>
                        <AutocompleteSelect
                            className={'wfill'}
                            name={'importLotStorageUnit'}
                            label={'Storage Unit'}
                            options={this.getMassLotImportStorageUnitOptions(this.props, this.state)}
                            onChange={(e, selectedStorageUnit : IOptionType) => this.onMassLotImportStorageUnitChange(e, importedLot, selectedStorageUnit)}
                            value={importedLot.storageUnitOption}
                        />
                    </div>
                    <div className={'flx1 aic jcc bl1 hfill'}>
                        <AutocompleteSelect
                            className={'wfill'}
                            name={'importLotCommodity'}
                            label={'Commodity'}
                            options={this.getMassLotImportCommodityOptions(this.props, this.state)}
                            onChange={(e, selectedCommodity : IOptionType) => this.onMassLotImportCommodityChange(e, importedLot, selectedCommodity)}
                            value={importedLot.commOption}
                        />
                    </div>
                    <div className={'flx1 aic jcc bl1 hfill'}>
                        <AutocompleteSelect
                            className={'wfill'}
                            name={'importLotVariety'}
                            label={'Variety'}
                            options={this.getMassLotImportVarietyOptions(importedLot)}
                            onChange={(e, selectedVariety : IOptionType) => this.onMassLotImportVarietyChange(e, importedLot, selectedVariety)}
                            value={importedLot.varOption}
                        />
                    </div>
                    <div className={'flx1 aic jcc bl1 hfill'}>
                        <AutocompleteSelect
                            className={'wfill'}
                            name={'importLotFarm'}
                            label={'Farm'}
                            options={this.getMassLotImportFarmOptions(this.props, this.state)}
                            onChange={(e, selectedFarm : IOptionType) => this.onMassLotImportFarmChange(e, importedLot, selectedFarm)}
                            value={importedLot.farmOption}
                        />
                    </div>
                    <div className={'flx1 aic jcc bl1 hfill'}>
                        <AutocompleteSelect
                            className={'wfill'}
                            name={'importLotOrchard'}
                            label={'Orchard'}
                            options={this.getMassLotImportOrchardOptions(importedLot)}
                            onChange={(e, selectedOrchard : IOptionType) => this.onMassLotImportOrchardChange(e, importedLot, selectedOrchard)}
                            value={importedLot.orchardOption}
                        />
                    </div>
                    <div className={'flx1 aic jcc bl1 hfill'}>
                        <AutocompleteSelect
                            className={'wfill'}
                            name={'importLotPack'}
                            label={'Pack'}
                            options={this.getMassLotImportPackOptions(importedLot)}
                            onChange={(e, selectedPack : IOptionType) => this.onMassLotImportPackChange(e, importedLot, selectedPack)}
                            value={importedLot.packOption}
                        />
                    </div>
                    <div className={'flx1 aic jcc bl1 hfill'}>
                        <AutocompleteSelect
                            className={'wfill'}
                            name={'importLotCommodityState'}
                            label={'CommodityState'}
                            options={this.getMassLotImportCommodityStateOptions(importedLot)}
                            onChange={(e, selectedCommodityState : IOptionType) => this.onMassLotImportCommodityStateChange(e, importedLot, selectedCommodityState)}
                            value={importedLot.commodityStateOption}
                        />
                    </div>
                    <div className={'w75 aic jcc bl1 hfill'}>{importedLot.numberOfUnits}</div>
                    <div className={'w50 aic jcc bl1 hfill'}>
                        <CustomTooltip title={'Delete'}>
                            <IconButton value={index} className={'w48 mnw48 cpd'} onClick={() => this.removeImportedLot(index)}>
                                <Icon>delete</Icon>
                            </IconButton>
                        </CustomTooltip>
                    </div>
                </TableCell>
            </TableRow>
        );
    });

    public render() {
        return (
            <div className={'p20 fdc'}>
                <div className={'fdr aic'}>
                    <AutocompleteSelect
                        className={'w300 mb10'}
                        name={'organization'}
                        label={'Organization'}
                        options={this.getMassLotImportOrganizationOptions(this.props)}
                        onChange={this.onLotMassImportOrganizationChange}
                        value={this.state.selectedOrganization}
                    />
                    <div className={'w10'}/>
                    <AutocompleteSelect
                        className={'w300 mb10'}
                        name={'site'}
                        label={'Site'}
                        options={this.getMassLotImportSiteOptions(this.props, this.state)}
                        onChange={this.onLotMassImportSiteChange}
                        value={this.state.selectedSite}
                        disabled={!this.state.selectedOrganization}
                    />
                    <div className={'w10'}/>
                    <AutocompleteSelect
                        className={'w300 mb10'}
                        name={'lotTypes'}
                        label={'Lot Type'}
                        options={this.getLotTypeOptions(this.props)}
                        onChange={this.onLotTypeChange}
                        value={this.state.selectedLotType}
                    />
                </div>
                <Table style={{ width: 1800 }} className= {'PaperBorder mb20'}>
                    {/* Headings */}
                    <TableRow className={'fdr'}>
                        <TableCell className={'flx3 jcc aic p5 h40 fs14 mb10'}>
                            <div className={'w150 aic jcc'}></div>
                            <div className={'flx1 aic jcc p5'}>
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'selectAllStorageUnit'}
                                    label={'Select All'}
                                    options={this.getMassLotImportStorageUnitOptions(this.props, this.state)}
                                    onChange={this.onSelectAllMassLotImportStorageUnitChange}
                                    value={this.state.selectedMassLotImportStorageUnit}
                                    disabled={!this.state.selectedOrganization || !this.state.selectedSite || !this.state.selectedLotType || this.getImportedLotRows(this.props, this.state).length < 1}
                                />
                            </div>
                            <div className={'flx1 aic jcc p5'}>
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'selectAllCommodity'}
                                    label={'Select All'}
                                    options={this.getMassLotImportCommodityOptions(this.props, this.state)}
                                    onChange={this.onSelectAllMassLotImportCommodityChange}
                                    value={this.state.selectedMassLotImportCommodity}
                                    disabled={!this.state.selectedOrganization || !this.state.selectedSite || !this.state.selectedLotType || this.getImportedLotRows(this.props, this.state).length < 1}
                                />
                            </div>
                            <div className={'flx1 aic jcc p5'}>
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'selectAllVariety'}
                                    label={'Select All'}
                                    options={this.getSelectAllMassLotImportVarietyOptions(this.props, this.state)}
                                    onChange={this.onSelectAllMassLotImportVarietyChange}
                                    value={this.state.selectedMassLotImportVariety}
                                    disabled={!this.state.selectedOrganization || !this.state.selectedSite || !this.state.selectedLotType || this.getImportedLotRows(this.props, this.state).length < 1}
                                />
                            </div>
                            <div className={'flx1 aic jcc p5'}>
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'importLotFarm'}
                                    label={'Select All'}
                                    options={this.getSelectAllMassLotImportFarmOptions(this.props, this.state)}
                                    onChange={this.onSelectAllMassLotImportFarmChange}
                                    value={this.state.selectedMassLotImportFarm}
                                    disabled={!this.state.selectedOrganization || !this.state.selectedSite || !this.state.selectedLotType || this.getImportedLotRows(this.props, this.state).length < 1}
                                />
                            </div>
                            <div className={'flx1 aic jcc p5'}>
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'importLotOrchard'}
                                    label={'Select All'}
                                    options={this.getSelectAllMassLotImportOrchardOptions(this.props, this.state)}
                                    onChange={this.onSelectAllMassLotImportOrchardChange}
                                    value={this.state.selectedMassLotImportOrchard}
                                    disabled={!this.state.selectedOrganization || !this.state.selectedSite || !this.state.selectedLotType || this.getImportedLotRows(this.props, this.state).length < 1}
                                />
                            </div>
                            <div className={'flx1 aic jcc p5'}>
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'importLotPack'}
                                    label={'Select All'}
                                    options={this.getSelectAllMassLotImportPackOptions(this.props, this.state)}
                                    onChange={this.onSelectAllMassLotImportPackChange}
                                    value={this.state.selectedMassLotImportPack}
                                    disabled={!this.state.selectedOrganization || !this.state.selectedSite || !this.state.selectedLotType || this.getImportedLotRows(this.props, this.state).length < 1}
                                />
                            </div>
                            <div className={'flx1 aic jcc p5'}>
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'importLotCommodityState'}
                                    label={'Select All'}
                                    options={this.getSelectAllMassLotImportCommodityStateOptions(this.props, this.state)}
                                    onChange={this.onSelectAllMassLotImportCommodityStateChange}
                                    value={this.state.selectedMassLotImportCommodityState}
                                    disabled={!this.state.selectedOrganization || !this.state.selectedSite || !this.state.selectedLotType || this.getImportedLotRows(this.props, this.state).length < 1}
                                />
                            </div>
                            <div className={'w75 aic jcc'}></div>
                        </TableCell>
                    </TableRow>
                    <TableRow className={'fdr bcTableHead'}>
                        <TableCell className={'flx3 jcc aic p5 bcTableHead h40 cw fs14'}>
                            <div className={'w150 aic jcc'}>Lot Name</div>
                            <div className={'flx1 aic jcc'}>Storage Unit</div>
                            <div className={'flx1 aic jcc'}>Commodity</div>
                            <div className={'flx1 aic jcc'}>Variety</div>
                            <div className={'flx1 aic jcc'}>Farm</div>
                            <div className={'flx1 aic jcc'}>Orchard</div>
                            <div className={'flx1 aic jcc'}>Pack</div>
                            <div className={'flx1 aic jcc'}>Commodity State</div>
                            <div className={'w75 aic jcc'}>No. Of Units</div>
                            <div className={'w50 aic jcc'}/>
                        </TableCell>
                    </TableRow>
                    {/* body */}
                    <WindowList
                        itemCount={this.getImportedLotRows(this.props, this.state).length}
                        width={1800}
                        height={300}
                        itemSize={50}
                        itemData={this.getImportedLotRows(this.props, this.state)}
                    >
                        {this.renderListElement}
                    </WindowList>
                </Table>
                <Tooltip disableInteractive title={this.getImportLotFileSelectorTooltipTitle()}>
                    <div className={'fdr aic mb10 flx1'}>
                        <FileSelector
                            accept={'.csv, text/csv'}
                            files={this.state.selectedFiles ? this.state.selectedFiles : []}
                            disabled={this.props.isLoading || this.state.selectedFiles.length >= 1 || !this.state.selectedOrganization || !this.state.selectedSite || !this.state.selectedLotType}
                            onFileSelected={this.handleFileInputChange}
                            onFileRemoved={this.onFileRemove}
                            className={'wfill hfill'}
                        />
                    </div>
                </Tooltip>
                <div className={'fdr aife wfill mt10'}>
                    <CSVLink data={this.exportLotsImportTemplate()} filename={'Lots_Import_Template.csv'} style={{ textDecoration: 'unset' }}>
                        <div>
                            <PillButton
                                color={'secondary'}
                                className={'h35'}
                                text={'Download Template'}
                                onClick={() => null}/>
                        </div>
                    </CSVLink>
                    {renderQRCodes(this.getNewCreatedLotsAfterImport(this.props, this.state))}
                    <div className={'w10'}/>
                    <PillButton
                        color={'secondary'}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Generate Lot Cards'}
                        disabled={!this.state.isLotCardGenerateButtonEnabled}
                        onClick={this.onLotCardsGenerate}/>
                    <div className={'flx1'}/>
                    <Button className={'fwb h35'} variant='text' color='primary'
                        onClick={this.closeLotImportDialog}>
                        Cancel
                    </Button>
                    <PillButton
                        color={'secondary'}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Create'}
                        disabled={!!(this.state.importedLots.length < 1) || !this.state.selectedOrganization || !this.state.selectedSite || !this.state.selectedLotType}
                        onClick={this.submitImportedLots}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        lotViews: state.data.lotViews,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
        storageUnits: state.masterData.storageUnits,
        lotTypes: state.masterData.lotTypes,
        farms: state.masterData.farms,
        sites: state.masterData.sites,
        commodityStates: state.masterData.commodityStates,
        organizations: state.masterData.organizations,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        orchards: state.masterData.orchards,
        packs: state.masterData.packs,
    };
};

export default connect(
    mapStateToProps,
)(LotMassImportDialog);

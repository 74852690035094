import * as React from 'react';
import Fab from '@mui/material/Fab/Fab';

enum FontSize {
    Small = 14,
    Medium = 14,
    Large = 15,
}

interface IPillButtonProps {
    text : string;
    onClick ?: (event : React.MouseEvent<HTMLElement, MouseEvent>) => void;

    id ?: string;
    value ?: string | number | Array<string>;
    size ?: 'small' | 'medium' | 'large';
    color ?: 'inherit' | 'primary' | 'secondary' | 'default';
    type ?: 'submit' | 'reset';
    iconPosition ?: 'start' | 'end';
    className ?: string;
    disabled ?: boolean;
    icon ?: React.ReactNode;
    fontSize ?: number;
}

export default class PillButton extends React.PureComponent<IPillButtonProps> {
    constructor(props : IPillButtonProps) {
        super(props);
    }

    public getFontSize = () => {
        if (this.props.fontSize) {
            return this.props.fontSize;
        }

        if (this.props.size === 'small') {
            return FontSize.Small;
        }
        if (this.props.size === 'medium') {
            return FontSize.Medium;
        }
        if (this.props.size === 'large') {
            return FontSize.Large;
        }

        return FontSize.Medium;
    };

    public render() {
        const {
            className,
            size = 'medium',
            type,
        } = this.props;

        return (
            <Fab
                id={this.props.id}
                value={this.props.value}
                variant='extended'
                type={type}
                color={this.props.color}
                disabled={this.props.disabled}
                size={size}
                onClick={this.props.onClick}
                className={`fdr ${this.props.iconPosition ? '' : 'aic'} cpd ${this.props.size === 'small' ? 'pl5 pr5' : ''} ${className}`}
            >
                {(this.props.icon && this.props.iconPosition && this.props.iconPosition === 'start') && <span className={'aic jcc'}>{this.props.icon}</span>}
                <div className={this.props.iconPosition ? 'flx1' : 'dn'}/>
                <span className={`fs${this.getFontSize()}`}>{this.props.text?.toUpperCase()}</span>
                <div className={this.props.iconPosition ? 'flx1' : 'dn'}/>
                {(this.props.icon && (!this.props.iconPosition || this.props.iconPosition === 'end')) && <span className={'aic jcc'}>{this.props.icon}</span>}
            </Fab>
        );
    }
}

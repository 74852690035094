import { ICarrier } from './carrier';
import * as Yup from 'yup';

export interface ICarrierFormValues {
    id : number;
    guid : string;
    code : string;
    name : string;
    isActive : boolean;
}

export class CarrierFormValues implements ICarrierFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public name : string;
    public isActive : boolean;

    public constructor(carrier ?: ICarrier) {
        this.initializeDefaultValues();
        if (carrier) {
            this.id = carrier.id;
            this.guid = carrier.guid;
            this.code = carrier.code;
            this.name = carrier.name;
            this.isActive = carrier.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.name = '';
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

import axios, { AxiosPromise } from 'axios';
import { IContactUs } from '../../../@types/model/home/contactUs';
import { IInfoBoardData } from '../../../@types/model/home/infoBoardData';
import { PACKMAN_API_URL } from '../../../appConstants';

export default class HomeHttpService {
    public static getInfoBoardData() : AxiosPromise<Array<IInfoBoardData>> {
        return axios.get(`${PACKMAN_API_URL}Home/InfoBoardData`);
    }

    public static addOrUpdateInfoBoardData(data : IInfoBoardData) : AxiosPromise<IInfoBoardData> {
        return axios.post(`${PACKMAN_API_URL}Home/InfoBoardData/PostInfoBoardData`, data);
    }

    public static getContactUsData() : AxiosPromise<Array<IContactUs>> {
        return axios.get(`${PACKMAN_API_URL}Home/ContactUsData`);
    }

    public static addOrUpdateContactUsData(data : IContactUs) : AxiosPromise<IContactUs> {
        return axios.post(`${PACKMAN_API_URL}Home/ContactUsData/PostContactUsData`, data);
    }
}

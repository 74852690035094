import React from 'react';
import PapaParse, { ParseConfig } from 'papaparse';
import { Button, Typography } from '@mui/material';
import { IBlobFile } from '../../@types/other';

interface ICSVReaderProps {
    cssClass ?: string;
    cssInputClass ?: string;
    label ?: string;
    onFileLoaded : (csvData : Array<any>, filename : string) => void;
    onError ?: () => void;
    inputId ?: string;
    inputStyle ?: React.CSSProperties;
    parserOptions ?: ParseConfig;
}

const CSVReader : (props : ICSVReaderProps) => JSX.Element = ({
    onFileLoaded,
    onError,
    parserOptions = {},
}) => {
    const handleChangeFile = (e : { target : { files : Array<IBlobFile> }}) => {
        const reader = new FileReader();
        const filename = e.target.files[0].name;
        // @ts-ignore
        reader.onload = (event : { target : { result : string } | null}) => {
            const csvData = PapaParse.parse(
                // @ts-ignore
                event.target.result,
                Object.assign(parserOptions, {
                    error: onError,
                }),
            );
            onFileLoaded(csvData.data, filename);
        };
        reader.readAsText(e.target.files[0]);
    };

    return (
        <div className={'fdr jcc aic wfill p5'}>
            <input
                className={'dn'}
                type='file'
                id={'csv-reader-input-field'}
                accept='.csv, text/csv'
                // @ts-ignore
                onChange={e => handleChangeFile(e)}
            />
            <label className='wfill' htmlFor={'csv-reader-input-field'} >
                <Button component='span' variant='contained' color='primary' className='bcp wfill p5'>
                    <Typography className='fw500 cw m5'>IMPORT COMPLIANCE (206)</Typography>
                </Button>
            </label>
        </div>
    );
};

export default CSVReader;

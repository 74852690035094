import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import Icon from '@mui/material/Icon';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';

const toggleButtonStyle : React.CSSProperties = { textTransform: 'none' }; // TODO:ensure this is correct changed to react.css rather than material as it was throwing an error

export interface ISingleToggleButtonProps {
    label : string;
    name : string;
    onChange : (selected : boolean) => void;
    className ?: string;
    disabled ?: boolean;
    value : boolean;
}

interface ISingleToggleButtonState {
}

class SingleToggleButton extends React.PureComponent<ISingleToggleButtonProps, ISingleToggleButtonState> {

    constructor(props : ISingleToggleButtonProps) {
        super(props);
    }

    public onChange = () => {
        this.props.onChange(!this.props.value);
    };

    public render() {
        return (
            <FormControl>
                <ToggleButton
                    id={this.props.name}
                    value={this.props.value}
                    style={toggleButtonStyle}
                    disabled={this.props.disabled}
                    selected={this.props.value}
                    onChange={this.onChange}
                    className={`br30 p3 m10 h36 bw2 ${this.props.value ? 'bcp cw bogreen1' : ''} ${this.props.className}`}>
                    <div className={'w30 aic mr5'}>
                        {
                            this.props.value ? (
                                <Icon style={{ width: 27, height: 27 }} className={'brh bw2 cw mr10'}>
                                    done
                                </Icon>
                            ) : (
                                <Icon style={{ width: 27, height: 27 }} className={'brh bw2 mr10'}>
                                    close
                                </Icon>
                            )
                        }
                    </div>
                    <Typography className={'flx1 jcc'}>{this.props.label}</Typography>
                    <span className={'w30'}/>
                </ToggleButton>
            </FormControl>
        );
    }
}

export default SingleToggleButton;

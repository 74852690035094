import { IBaseModel } from '../../../model';
import { IReportFormValues } from './reportFormValues';

export interface IReport extends IBaseModel {
    printServerId : number;
    name : string;
    canLivePalletize : boolean;
}

export class Report implements IReport {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public name : string;
    public printServerId : number;
    public canLivePalletize : boolean;

    public constructor(formValues : IReportFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.name = formValues.name;
        this.printServerId = Number(formValues.printServer?.value);
        this.canLivePalletize = formValues.canLivePalletize;
        this.isActive = formValues.isActive;
    }
}

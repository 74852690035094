import { Tooltip } from '@mui/material';
import React, { ReactNode } from 'react';

interface CustomTooltipProps {
    children : ReactNode;
    title : string;
    placement ?: 'bottom' | 'top' | 'left' | 'right';
    arrow ?: boolean;
    enterDelay ?: number;
    leaveDelay ?: number;
    className ?: string;
    disableInteractive ?: boolean;
}

const CustomTooltip = ({
    children,
    title,
    placement = 'top',
    arrow = true,
    disableInteractive = true,
    enterDelay = 0,
    leaveDelay = 0,
    className = '',
} : CustomTooltipProps) => {
    return (
        <Tooltip
            disableInteractive={disableInteractive}
            title={title}
            placement={placement}
            arrow={arrow}
            enterDelay={enterDelay}
            leaveDelay={leaveDelay}
        >
            <div className={className}>{children}</div>
        </Tooltip>
    );
};

export default CustomTooltip;

import * as React from 'react';
import { Form, FormikProps } from 'formik';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import { IOptionType } from '../../../../@types/helper';
import FormAutocompleteSelect from '../../../../components/input/form/FormAutoCompleteSelect';
import { IRootState } from '../../../../@types/redux';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { ProjectFormValues } from '../../../../@types/model/masterData/project/projectFormValues';
import { ICommodity } from '../../../../@types/model/masterData/commodity/commodity';
import CustomTooltip from '../../../../components/tooltip/tooltip';
import { LocalizationProvider } from '@mui/x-date-pickers';
import FormDateInput from '../../../../components/input/form/FormDateInput';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

interface IProjectFormProps {
    isLoading : boolean;
    commodityOptions : Array<IOptionType>;
}

interface IProjectFormState {
}

type ProjectFormPropsType = IProjectFormProps & FormikProps<ProjectFormValues>;

class ProjectForm extends React.Component<ProjectFormPropsType, IProjectFormState> {

    constructor(props : ProjectFormPropsType) {
        super(props);
    }

    public render() {
        const selectedProject = this.props.values;
        return (
            <Form className={'p20 w300'}>
                <CustomTooltip title={!!selectedProject.isImported ? 'Cannot change this field if project is imported' : ''}>
                    <FormTextInput name={'number'} label={'Number'} disabled={!!selectedProject.isImported}/>
                </CustomTooltip>
                <CustomTooltip title={!!selectedProject.isImported ? 'Cannot change this field if project is imported' : ''}>
                    <FormTextInput name={'description'} label={'Description'} disabled={!!selectedProject.isImported}/>
                </CustomTooltip>
                <CustomTooltip title={!!selectedProject.isImported ? 'Cannot change this field if project is imported' : ''}>
                    <FormAutocompleteSelect name={'commodity'} label={'Commodity'} options={this.props.commodityOptions} disabled={!!selectedProject.isImported}/>
                </CustomTooltip>
                <CustomTooltip title={!!selectedProject.isImported ? 'Cannot change this field if project is imported' : ''}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <FormDateInput name={'endedOn'} label={'Ended On'} variant={'standard'} className={'pt0 mt0'} disabled={!!selectedProject.isImported}/>
                    </LocalizationProvider>
                </CustomTooltip>
                <CustomTooltip title={!!selectedProject.isImported ? 'Cannot change this field if project is imported' : ''}>
                    <FormSingleToggleButton name={'isActive'} label={'Is Active'} disabled={!!selectedProject.isImported}/>
                </CustomTooltip>
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const getCommodities = (state : IRootState) => state.masterData.commodities;
const getSelectedOrganizationIds = (state : IRootState) => state.data.selectedOrganizationIds;

const getCommoditiesOptions = createSelector(
    [getCommodities, getSelectedOrganizationIds],
    (commodities : Array<ICommodity>) => {
        return commodities.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        commodityOptions: getCommoditiesOptions(state),
    };
};

export default connect(
    mapStateToProps,
)(ProjectForm);

import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { CountryFormValues } from '../../../../@types/model/masterData/country/countryFormValues';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import { IOptionType } from '../../../../@types/helper';
import FormAutocompleteSelect from '../../../../components/input/form/FormAutoCompleteSelect';
import { IRootState } from '../../../../@types/redux';
import { createSelector } from 'reselect';
import { IRegion } from '../../../../@types/model/masterData/region/region';
import { connect } from 'react-redux';

interface ICountryFormProps {
    isLoading : boolean;
    regionOptions : Array<IOptionType>;
}

interface ICountryFormState {
}

type CountryFormPropsType = ICountryFormProps & FormikProps<CountryFormValues>;

class CountryForm extends React.Component<CountryFormPropsType, ICountryFormState> {

    constructor(props : CountryFormPropsType) {
        super(props);
    }

    public render() {
        return (
            <Form className={'p20 w300'}>
                <FormTextInput name={'code'} label={'Code'}/>
                <FormTextInput name={'name'} label={'Name'}/>
                <FormAutocompleteSelect name={'region'} label={'Region'} options={this.props.regionOptions}/>
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const getRegions = (state : IRootState) => state.masterData.regions;
const getSelectedOrganizationIds = (state : IRootState) => state.data.selectedOrganizationIds;

const getRegionOptions = createSelector(
    [getRegions, getSelectedOrganizationIds],
    (regions : Array<IRegion>, selectedOrganizationIds : Array<number>) => {
        return regions.filter(x => x.isActive && selectedOrganizationIds?.some(y => x.organizationIds.some(z => z === y))).map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        regionOptions: getRegionOptions(state),
    };
};

export default connect(
    mapStateToProps,
)(CountryForm);

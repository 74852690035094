import { getHomeUrlStorage } from '../../services/localStorageService';
import { history } from '../Index';

const push = history.push;
const goBack = history.goBack;

export const navMasterData = () => push('/masterData/management');
export const navStockDashboard = () => push('/stock/dashboard');
export const navOverviewDashboard = () => push('/overview/dashboard');
export const navStock = (id ?: number) => push(`/stock/transactions${id ? `/:${id}` : ''}`);
export const navCompliance = (id ?: number) => push(`/compliance/transactions${id ? `/:${id}` : ''}`);
export const navInspection = () => push('/compliance/inspection');
export const navComplianceDashboard = () => push('/compliance/dashboard');
export const navTestReport = () => push('/report/test');
export const navOrder = () => push('/order/transactions');
export const navLot = (id ?: number) => push(`/lot/transactions${id ? `/:${id}` : ''}`);
export const navTrip = (id ?: number) => push(`/dispatch/tripTransactions${id ? `/:${id}` : ''}`); // TODO: make this trip/transactions
export const navBatch = (id ?: number) => push(`/batch/transactions${id ? `/:${id}` : ''}`);
export const navIntake = (id ?: number) => push(`/intake/transactions${id ? `/:${id}` : ''}`);
export const navMaterialStock = (id ?: number) => push(`/materialStock/transactions${id ? `/:${id}` : ''}`);
export const navMaterial = () => push('/material');
export const navDispatchInstruction = (id ?: number) => push(`/dispatch/transactions${id ? `/:${id}` : ''}`);
export const navMaterialStockDashboard = () => push('/materialStockDashboard');
export const navMaterialDispatchDashboard = () => push('/materialDispatch/dashboard');
export const navMaterialDispatch = (id ?: number) => push(`/materialDispatch/transactions${id ? `/:${id}` : ''}`);
export const navDispatchInstructionDashboard = () => push('/dispatch/dashboard');
export const navRightsUser = () => push('/right/user');
export const navRightsMobileUser = () => push('/right/mobile/user');
export const navRightsRole = () => push('/right/role');
export const navHome = () => push(getHomeUrlStorage());
export const navPath = (pathname : string) => push(pathname);
export const navBack = () => goBack();

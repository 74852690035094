import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../@types/redux';
import { IStock } from '../../@types/model/stock/stock';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ISite } from '../../@types/model/masterData/site/site';
import { IMarket } from '../../@types/model/masterData/market/market';
import { IMark } from '../../@types/model/masterData/mark/mark';
import { IRegion } from '../../@types/model/masterData/region/region';
import { ICountry } from '../../@types/model/masterData/country/country';
import { IInventory } from '../../@types/model/masterData/inventory/inventory';
import StockHttpService from '../../services/http/stock/stockHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { dataSetCombineStockRelatedData, dataSetStockView } from '../../store/data/Functions';
import PillButton from '../../components/input/PillButton';
import { ListChildComponentProps, FixedSizeList as WindowList } from 'react-window';
import { Button, Checkbox, Table, TableCell, TableRow, Typography } from '@mui/material';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { addArrayElement, formatMomentToDatePicker, removeArrayElement, setArrayElement } from '../../services/appFunctionsService';
import { PackmanLabel } from '../../components/label/PackmanLabel';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { ISize } from '../../@types/model/masterData/size/size';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { IPalletBaseType } from '../../@types/model/masterData/palletBaseType/palletBaseType';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import { IStockLine } from '../../@types/model/stock/stockLine';
import { ICombineStock } from '../../@types/model/stock/combineStock';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import { CustomChangeEvent, IOptionType } from '../../@types/helper';
import { IStorageUnit } from '../../@types/model/masterData/storageUnit/storageUnit';
import { ICombineStockUpsert } from '../../@types/model/stock/combineStockUpsert';
import uuidv1 from 'uuid';
import moment from 'moment';
import { IStockView } from '../../@types/model/stock/stockView';
import { IMaterialsOnStock } from '../../@types/model/stock/materialsOnStock';
import { ISalesPoint } from '../../@types/model/masterData/salesPoint/salesPoint';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { syncMasterData } from '../../services/masterDataSyncService';
import CustomTooltip from '../../components/tooltip/tooltip';

interface ICombineStockDialogProps {
    setLoading : (loading : boolean) => void;
    closeDialog : () => void;
    selectedSiteIds : Array<number> ;
    isLoading : boolean;
    organizations : Array<IOrganization>;
    sites : Array<ISite>;
    markets : Array<IMarket>;
    marks : Array<IMark>;
    regions : Array<IRegion>;
    countries : Array<ICountry>;
    inventories : Array<IInventory>;
    packs : Array<IPack>;
    sizes : Array<ISize>;
    grades : Array<IGrade>;
    colours : Array<IColour>;
    palletBaseTypes : Array<IPalletBaseType>;
    storageUnits : Array<IStorageUnit>;
    salesPoints : Array<ISalesPoint>;
}

interface ICombineStockDialogState {
    stocks : Array<IStock>;
    selectedStocks : Array<IStock>;
    newStock ?: ICombineStockUpsert;

    isCombineStockWarningOpen : boolean;
    isCreateNewStockWarningOpen : boolean;
    isWarningPopupOpen : boolean;
    selectedStockNotMatching ?: IStock;

    isResultPopupOpen : boolean;
    resultStockList : Array<IStockView>;

    selectedOriginalOrganizationOption ?: IOptionType;
    selectedOriginalSiteOption ?: IOptionType;
    selectedCurrentOrganizationOption ?: IOptionType;
    selectedCurrentSiteOption ?: IOptionType;
    selectedOriginalStorageUnitOption ?: IOptionType;
    selectedCurrentStorageUnitOption ?: IOptionType;
    selectedRegionOption ?: IOptionType;
    selectedCountryOption ?: IOptionType;
    selectedMarketOption ?: IOptionType;
    selectedBrandOption ?: IOptionType;
    selectedPalletBaseTypeOption ?: IOptionType;
    selectedInventoryOption ?: IOptionType;
}

class CombineStockDialog extends React.Component<ICombineStockDialogProps, ICombineStockDialogState> {
    constructor(props : ICombineStockDialogProps) {
        super(props);

        this.state = {
            stocks: [],
            selectedStocks: [],
            isCombineStockWarningOpen: false,
            isCreateNewStockWarningOpen: false,
            isWarningPopupOpen: false,
            isResultPopupOpen: false,
            resultStockList: [],
        };
    }

    public componentDidMount = async () => {
        if (this.props.selectedSiteIds && this.props.selectedSiteIds.length > 0) {
            this.props.setLoading(true);
            // checks if indexedDB is available.
            const isIndexedDBAvailable = !!self.indexedDB ? true : false;

            if (isIndexedDBAvailable) {
                await syncMasterData(false);
            }
            try {
                const res = await StockHttpService.getCombineStockRelatedData(this.props.selectedSiteIds, !isIndexedDBAvailable);

                if (res && res.data) {
                    const updatedStocks = this.mapMaterialStockLinesToStockMaterialList(res.data.stocks);
                    this.setState({ stocks: updatedStocks });
                    dataSetCombineStockRelatedData(res.data);
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading stock lines.');
            } finally {
                this.props.setLoading(false);
            }
        }
    };

    private onCreateNewStockSubmit = async () => {
        const newStockValues = this.state.newStock;
        const selectedStocks = this.state.selectedStocks;
        if (newStockValues && selectedStocks.length > 1) {
            this.props.setLoading(true);
            try {
                const newStockToBeCreated : ICombineStockUpsert = {
                    ...newStockValues,
                    id: 0,
                    guid: '',
                    barcode: '',
                    tempBarcode: '',
                    createdIn: 'Web App',
                    createdBy: undefined,
                    createdByName: undefined,
                    createdOn: undefined,
                    updatedBy: undefined,
                    updatedByName: undefined,
                    updatedOn: undefined,
                    packDate: formatMomentToDatePicker(moment().utc().startOf('day')),
                    stockLines: newStockValues.stockLines.filter(x => x.isActive).map((stockLine) => {
                        return {
                            ...stockLine,
                            id: 0,
                            guid: uuidv1(),
                        };
                    }),
                };

                const data : ICombineStock = {
                    newStock: newStockToBeCreated,
                    originalStockIds: selectedStocks.map(x => x.id),
                };
                const res = await StockHttpService.combineStockIntoNewStock(data);

                if (res && res.data) {
                    generalShowSuccessSnackbar('Stocks was successfully combined');
                    res.data.forEach((stock) => {
                        dataSetStockView(stock);
                    });
                    this.setState({
                        selectedStocks: [],
                        newStock: undefined,
                        isCreateNewStockWarningOpen: false,
                        selectedOriginalOrganizationOption: undefined,
                        selectedOriginalSiteOption: undefined,
                        selectedCurrentOrganizationOption: undefined,
                        selectedCurrentSiteOption: undefined,
                        selectedOriginalStorageUnitOption: undefined,
                        selectedCurrentStorageUnitOption: undefined,
                        selectedRegionOption: undefined,
                        selectedCountryOption: undefined,
                        selectedMarketOption: undefined,
                        selectedBrandOption: undefined,
                        selectedPalletBaseTypeOption: undefined,
                        selectedInventoryOption: undefined,
                        resultStockList: res.data,
                        isResultPopupOpen: true,
                    });
                }
            } catch (ex) {
                if (!!ex && ex.status === 400) {
                    generalShowErrorSnackbar(ex.data.Message);
                } else {
                    generalShowErrorSnackbar('Failed to create new stock.');
                }
            } finally {
                this.props.setLoading(false);
            }
        }
    };

    private onCombineStockSubmitConfirmed = async () => {
        const newStockValues = this.state.newStock;
        const selectedStocks = this.state.selectedStocks;
        if (newStockValues && selectedStocks.length > 1) {
            this.props.setLoading(true);
            try {
                const newStockToBeCreated : ICombineStockUpsert = {
                    ...newStockValues,
                    stockLines: newStockValues.stockLines.filter(x => x.isActive).map((stockLine) => {
                        return {
                            ...stockLine,
                            id: stockLine.stockId === newStockValues.id ? stockLine.id : 0,
                            guid:  stockLine.stockId === newStockValues.id ? stockLine.guid : uuidv1(),
                        };
                    }),
                };

                const data : ICombineStock = {
                    newStock: newStockToBeCreated,
                    originalStockIds: selectedStocks.filter(x => x.id !== newStockValues.id).map(x => x.id),
                };
                const res = await StockHttpService.mergeStocks(data);

                if (res && res.data) {
                    generalShowSuccessSnackbar('Stocks was successfully combined');
                    res.data.forEach((stock) => {
                        dataSetStockView(stock);
                    });
                    this.setState({
                        selectedStocks: [],
                        newStock: undefined,
                        isCombineStockWarningOpen: false,
                        selectedOriginalOrganizationOption: undefined,
                        selectedOriginalSiteOption: undefined,
                        selectedCurrentOrganizationOption: undefined,
                        selectedCurrentSiteOption: undefined,
                        selectedOriginalStorageUnitOption: undefined,
                        selectedCurrentStorageUnitOption: undefined,
                        selectedRegionOption: undefined,
                        selectedCountryOption: undefined,
                        selectedMarketOption: undefined,
                        selectedBrandOption: undefined,
                        selectedPalletBaseTypeOption: undefined,
                        selectedInventoryOption: undefined,
                    });
                    this.props.closeDialog();
                }
            } catch (ex) {
                if (!!ex && ex.status === 400) {
                    generalShowErrorSnackbar(ex.data.Message);
                } else {
                    generalShowErrorSnackbar('Failed to combine selected stocks.');
                }
            } finally {
                this.props.setLoading(false);
            }
        }
    };

    private openCreateNewStockWarning = () => {
        this.setState({ isCreateNewStockWarningOpen: true });
    };

    private openCombineStockWarning = () => {
        this.setState({ isCombineStockWarningOpen: true });
    };

    private cancelCombineStockWarningPopup = () => {
        this.setState({ isCombineStockWarningOpen: false });
    };

    private cancelCreateNewStockWarningPopup = () => {
        this.setState({ isCreateNewStockWarningOpen: false });
    };

    private closeResultDialog = () => {
        this.setState({ isResultPopupOpen: false });
        this.props.closeDialog();
    };

    private getOrganizations = (props : ICombineStockDialogProps) => props.organizations;
    private getSites = (props : ICombineStockDialogProps) => props.sites;
    private getBrands = (props : ICombineStockDialogProps) => props.marks;
    private getMarkets = (props : ICombineStockDialogProps) => props.markets;
    private getCountries = (props : ICombineStockDialogProps) => props.countries;
    private getRegions = (props : ICombineStockDialogProps) => props.regions;
    private getPalletBaseTypes = (props : ICombineStockDialogProps) => props.palletBaseTypes;
    private getInventories = (props : ICombineStockDialogProps) => props.inventories;
    private getStorageUnits = (props : ICombineStockDialogProps) => props.storageUnits;

    private getStocks = (props : ICombineStockDialogProps, state : ICombineStockDialogState) => state.stocks;
    private getSelectedStocks = (props : ICombineStockDialogProps, state : ICombineStockDialogState) => state.selectedStocks;
    private getNewStockValues = (props : ICombineStockDialogProps, state : ICombineStockDialogState) => state.newStock;
    private getSelectedStockNotMatching = (props : ICombineStockDialogProps, state : ICombineStockDialogState) => state.selectedStockNotMatching;
    private getResultStockList = (props : ICombineStockDialogProps, state : ICombineStockDialogState) => state.resultStockList;
    private getSelectedOriginalOrganizationOption = (props : ICombineStockDialogProps, state : ICombineStockDialogState) => state.selectedOriginalOrganizationOption;
    private getSelectedCurrentOrganizationOption = (props : ICombineStockDialogProps, state : ICombineStockDialogState) => state.selectedCurrentOrganizationOption;
    private getSelectedRegionOption = (props : ICombineStockDialogProps, state : ICombineStockDialogState) => state.selectedRegionOption;

    private getOrganizationCodeAndName = (orgId : number) => {
        const organizations = this.props.organizations;
        const organization = organizations && organizations.find(x => x.id === orgId);
        return organization ? `(${organization.code}) - ${organization.name}` : '';
    };

    private getSiteCodeAndDescription = (siteId : number) => {
        const sites = this.props.sites;
        const site = sites && sites.find(x => x.id === siteId);
        return site ? `(${site.code}) - ${site.description}` : '';
    };

    private getMarketCodeAndName = (marketId : number) => {
        const markets = this.props.markets;
        const market = markets && markets.find(x => x.id === marketId);
        return market ? `(${market.code}) - ${market.name}` : '';
    };

    private getCountryCodeAndName = (countryId : number) => {
        const countries = this.props.countries;
        const country = countries && countries.find(x => x.id === countryId);
        return country ? `(${country.code}) - ${country.name}` : '';
    };

    private getPalletBaseTypeCodeAndName = (palletBaseTypeId : number) => {
        const palletBaseTypes = this.props.palletBaseTypes;
        const palletBaseType = palletBaseTypes && palletBaseTypes.find(x => x.id === palletBaseTypeId);
        return palletBaseType ? `(${palletBaseType.code}) - ${palletBaseType.name}` : '';
    };

    private getInventoryCodeAndName = (inventoryId : number) => {
        const inventories = this.props.inventories;
        const inventory = inventories && inventories.find(x => x.id === inventoryId);
        return inventory ? `(${inventory.code}) - ${inventory.description}` : '';
    };

    private getStorageUnitCodeAndName = (storageUnitId : number) => {
        const storageUntis = this.props.storageUnits;
        const storageUnit = storageUntis && storageUntis.find(x => x.id === storageUnitId);
        return storageUnit ? `(${storageUnit.code}) - ${storageUnit.description}` : '';
    };

    private getMarkCodeAndDescription = (markId : number) => {
        const marks = this.props.marks;
        const mark = marks && marks.find(x => x.id === markId);
        return mark ? `(${mark.code}) - ${mark.description}` : '';
    };

    private getGradeCodeAndName = (rowId : number) => {
        const grade = this.props.grades.find(x => x.id === rowId);
        return grade ? `(${grade.code}) - ${grade.name}` : '';
    };

    private getSizeCodeAndName = (rowId : number) => {
        const size = this.props.sizes.find(x => x.id === rowId);
        return size ? `(${size.code}) - ${size.name}` : '';
    };

    private getColourCodeAndName = (rowId : number) => {
        const colours = this.props.colours.find(x => x.id === rowId);
        return colours ? `(${colours.code}) - ${colours.name}` : '';
    };

    private getPackCodeAndDescription = (rowId : number) => {
        const packs = this.props.packs.find(x => x.id === rowId);
        return packs ? `(${packs.code}) - ${packs.description}` : '';
    };

    private totalEstGrossWeight = (stock : ICombineStockUpsert) => {
        let total = 0;
        if (stock && stock.palletBaseTypeId) {
            const palletBaseType = this.props.palletBaseTypes.find(x => x.id === stock.palletBaseTypeId);
            if (palletBaseType && palletBaseType.defaultWeight) {
                total += Number(palletBaseType.defaultWeight);
            }
        }
        stock.stockLines.forEach(x => total += this.getStocklineEstGrossWeight(x));
        return total;
    };

    private totalEstNettWeight = (stock : ICombineStockUpsert) => {
        let total = 0;
        stock.stockLines.forEach(x => total += this.getStocklineEstNettWeight(x));
        return total;
    };

    private getStocklineEstGrossWeight = (stockline : IStockLine) => {
        if (!stockline || !stockline.packId || !stockline.cartons) {
            return 0;
        }
        const pack = this.props.packs.find(x => x.id === stockline.packId);
        if (!pack) {
            return 0;
        }

        return Number(pack.grossWeight) * Number(stockline.cartons);
    };

    private getStocklineEstNettWeight = (stockline : IStockLine) => {
        if (!stockline || !stockline.packId || !stockline.cartons) {
            return 0;
        }
        const pack = this.props.packs.find(x => x.id === stockline.packId);
        if (!pack) {
            return 0;
        }

        return Number(pack.nettWeight) * Number(stockline.cartons);
    };

    private closeWarningDialog = () => {
        this.setState({
            isWarningPopupOpen: false,
            selectedStockNotMatching: undefined,
            selectedOriginalOrganizationOption: undefined,
            selectedOriginalSiteOption: undefined,
            selectedCurrentOrganizationOption: undefined,
            selectedCurrentSiteOption: undefined,
            selectedOriginalStorageUnitOption: undefined,
            selectedCurrentStorageUnitOption: undefined,
            selectedRegionOption: undefined,
            selectedCountryOption: undefined,
            selectedMarketOption: undefined,
            selectedBrandOption: undefined,
            selectedPalletBaseTypeOption: undefined,
            selectedInventoryOption: undefined,
        });
    };

    private mapMaterialStockLinesToStockMaterialList = (stocks : Array<IStock>) => {
        const updatedStocks = stocks.map((stock) => {
            const lines = stock.materialStockLines.filter(x => x.isActive);

            const incomingLines = lines.filter(x => x.isIncoming);
            const allocatedLines = lines.filter(x => x.isAllocation && !x.isIncoming);

            const linesGroupedByMaterialStock = lodash.groupBy(allocatedLines, x => x.materialStockId);

            if (lines.length > 0) {
                const materialOnStocks : Array<IMaterialsOnStock> = lodash.map(linesGroupedByMaterialStock, (materialStockLines) => {
                    const firstLine = materialStockLines[0];

                    const incomingAmounts = incomingLines.filter(y => y.materialStockId === firstLine.materialStockId).map(x => x.amount);
                    const incomingAmount = lodash.sum(incomingAmounts);

                    const allocatedAmount = lodash.sumBy(materialStockLines, x => x.amount);

                    const amount = allocatedAmount - incomingAmount;

                    return { amount, materialStockId: firstLine.materialStockId };
                });

                return {
                    ...stock,
                    materials: materialOnStocks,
                };
            } else {
                return stock;
            }
        });

        return updatedStocks;
    };

    private onWarningContinueClick = () => {
        const newStock = this.state.newStock;
        const selectedStockNotMatching = this.state.selectedStockNotMatching;
        if (newStock && selectedStockNotMatching) {
            const newChannel = this.props.markets.find(x => x.id === this.state.selectedMarketOption?.value)?.channel ?? newStock.channel;
            let updatedStockValues : ICombineStockUpsert = {
                ...newStock,
                originalOrganizationId: Number(this.state.selectedOriginalOrganizationOption?.value ?? 0),
                originalSiteId: Number(this.state.selectedOriginalSiteOption?.value ?? 0),
                currentOrganizationId: Number(this.state.selectedCurrentOrganizationOption?.value ?? 0),
                currentSiteId: Number(this.state.selectedCurrentSiteOption?.value ?? 0),
                originalStorageUnitId: this.state.selectedOriginalStorageUnitOption ? Number(this.state.selectedOriginalStorageUnitOption?.value) : undefined,
                currentStorageUnitId: this.state.selectedCurrentStorageUnitOption ? Number(this.state.selectedCurrentStorageUnitOption?.value) : undefined,
                regionId: Number(this.state.selectedRegionOption?.value ?? 0),
                countryId: Number(this.state.selectedCountryOption?.value ?? 0),
                marketId: Number(this.state.selectedMarketOption?.value ?? 0),
                markId: Number(this.state.selectedBrandOption?.value ?? 0),
                inventoryId: this.state.selectedInventoryOption ? Number(this.state.selectedInventoryOption?.value) : undefined,
                palletBaseTypeId: Number(this.state.selectedPalletBaseTypeOption?.value ?? 0),
                channel: newChannel,
            };

            selectedStockNotMatching.stockLines.filter(x => x.isActive).forEach((newLine) => {
                const exsitingLine = updatedStockValues.stockLines.filter(x => x.isActive).find(x =>
                    x.commodityId === newLine.commodityId
                    && x.varietyId === newLine.varietyId
                    && x.farmId === newLine.farmId
                    && x.packId === newLine.packId
                    && x.gradeId === newLine.gradeId
                    && x.sizeId === newLine.sizeId
                    && x.colourId === newLine.colourId
                    && x.orchardId === newLine.orchardId
                    && x.batchId === newLine.batchId);

                const exsitingLineIndex = updatedStockValues.stockLines.filter(x => x.isActive).findIndex(x =>
                    x.commodityId === newLine.commodityId
                    && x.varietyId === newLine.varietyId
                    && x.farmId === newLine.farmId
                    && x.packId === newLine.packId
                    && x.gradeId === newLine.gradeId
                    && x.sizeId === newLine.sizeId
                    && x.colourId === newLine.colourId
                    && x.orchardId === newLine.orchardId
                    && x.batchId === newLine.batchId);

                if (exsitingLine) {
                    let updatedStockLine : IStockLine = {
                        ...exsitingLine,
                        cartons: exsitingLine.cartons + newLine.cartons,
                        totalInners: (exsitingLine?.totalInners ?? 0) + (newLine?.totalInners ?? 0),
                    };

                    updatedStockLine.estimatedGrossWeight = this.getStocklineEstGrossWeight(updatedStockLine);

                    updatedStockValues.stockLines = setArrayElement(updatedStockValues.stockLines, exsitingLineIndex, updatedStockLine);
                } else {
                    let newStockLine : IStockLine = {
                        ...newLine,
                        id: 0,
                        sequence: updatedStockValues.stockLines.length + 1,
                    };

                    updatedStockValues.stockLines = addArrayElement(updatedStockValues.stockLines, newStockLine);
                }
            });

            if (selectedStockNotMatching.materials.length > 0) {
                selectedStockNotMatching.materials.forEach((material) => {
                    const matchingMaterialStockIndex = updatedStockValues.materials.findIndex(x => x.materialStockId === material.materialStockId);

                    if (matchingMaterialStockIndex === -1) {
                        updatedStockValues.materials = addArrayElement(updatedStockValues.materials, material);
                    } else {
                        const updatedMaterial : IMaterialsOnStock = {
                            materialStockId: material.materialStockId,
                            amount: updatedStockValues.materials[matchingMaterialStockIndex].amount + material.amount,
                        };
                        updatedStockValues.materials = setArrayElement(updatedStockValues.materials, matchingMaterialStockIndex, updatedMaterial);
                    }
                });
            }

            const activeStockLines : Array<IStockLine> = updatedStockValues.stockLines.filter(x => x.isActive);

            updatedStockValues.cartons = lodash.sumBy(activeStockLines, x => x.cartons);
            updatedStockValues.grossWeight = updatedStockValues.grossWeight + selectedStockNotMatching.grossWeight;
            updatedStockValues.estimatedGrossWeight = this.totalEstGrossWeight(updatedStockValues);
            updatedStockValues.estimatedNettWeight = this.totalEstNettWeight(updatedStockValues);

            updatedStockValues.stockLines = updatedStockValues.stockLines.map((line) => {
                const updatedStockLine : IStockLine = {
                    ...line,
                    palletSize: 1 / activeStockLines.length,
                };

                if (line.isActive) {
                    return updatedStockLine;
                } else {
                    return line;
                }
            });

            let updatedSelectedList = [...this.state.selectedStocks];
            const index = updatedSelectedList.findIndex(x => x.id === selectedStockNotMatching.id);


            if (index === -1) {
                updatedSelectedList = addArrayElement(updatedSelectedList, selectedStockNotMatching);
            }

            this.setState({
                newStock: updatedStockValues,
                selectedStocks: updatedSelectedList,
                selectedStockNotMatching: undefined,
                isWarningPopupOpen: false,
                selectedOriginalOrganizationOption: undefined,
                selectedOriginalSiteOption: undefined,
                selectedCurrentOrganizationOption: undefined,
                selectedCurrentSiteOption: undefined,
                selectedOriginalStorageUnitOption: undefined,
                selectedCurrentStorageUnitOption: undefined,
                selectedRegionOption: undefined,
                selectedCountryOption: undefined,
                selectedMarketOption: undefined,
                selectedBrandOption: undefined,
                selectedPalletBaseTypeOption: undefined,
                selectedInventoryOption: undefined,
            });
        }
    };

    private allDropdownsHaveValues = () => {
        if (!this.state.selectedOriginalOrganizationOption
            || !this.state.selectedOriginalSiteOption
            || !this.state.selectedCurrentOrganizationOption
            || !this.state.selectedCurrentSiteOption
            || !this.state.selectedRegionOption
            || !this.state.selectedCountryOption
            || !this.state.selectedMarketOption
            || !this.state.selectedBrandOption
            || !this.state.selectedPalletBaseTypeOption) {
            return false;
        } else {
            return true;
        }
    };

    private openMismatchingStockWarningPopup = (selectedStockNotMatching : IStock) => {
        const newStock = this.state.newStock;

        const newStockOriginalOrg = this.props.organizations.find(x => x.id === newStock?.originalOrganizationId);
        const newStockOriginalSite = this.props.sites.find(x => x.id === newStock?.originalSiteId);
        const newStockCurrentOrg = this.props.organizations.find(x => x.id === newStock?.currentOrganizationId);
        const newStockCurrentSite = this.props.sites.find(x => x.id === newStock?.currentSiteId);
        const newStockRegion = this.props.regions.find(x => x.id === newStock?.regionId);
        const newStockCountry = this.props.countries.find(x => x.id === newStock?.countryId);
        const newStockMarket = this.props.markets.find(x => x.id === newStock?.marketId);
        const newStockMark = this.props.marks.find(x => x.id === newStock?.markId);
        const newStockPalletBaseType = this.props.palletBaseTypes.find(x => x.id === newStock?.palletBaseTypeId);
        const newStockOriginalStorageUnit = this.props.storageUnits.find(x => x.id === newStock?.originalStorageUnitId);
        const newStockCurrentStorageUnit = this.props.storageUnits.find(x => x.id === newStock?.currentStorageUnitId);
        const newStockInventory = this.props.inventories.find(x => x.id === newStock?.inventoryId);

        const newStockOriginalOrgOption : IOptionType = { label: `(${newStockOriginalOrg?.code}) - ${newStockOriginalOrg?.name}`, value: newStockOriginalOrg?.id ?? 0 };
        const newStockOriginalSiteOption : IOptionType = { label: `(${newStockOriginalSite?.code}) - ${newStockOriginalSite?.shortDescription}`, value: newStockOriginalSite?.id ?? 0 };
        const newStockCurrentOrgOption : IOptionType = { label: `(${newStockCurrentOrg?.code}) - ${newStockCurrentOrg?.name}`, value: newStockCurrentOrg?.id ?? 0 };
        const newStockCurrentSiteOption : IOptionType = { label: `(${newStockCurrentSite?.code}) - ${newStockCurrentSite?.shortDescription}`, value: newStockCurrentSite?.id ?? 0 };
        const newStockRegionOption : IOptionType = { label: `(${newStockRegion?.code}) - ${newStockRegion?.name}`, value: newStockRegion?.id ?? 0 };
        const newStockCountryOption : IOptionType = { label: `(${newStockCountry?.code}) - ${newStockCountry?.name}`, value: newStockCountry?.id ?? 0 };
        const newStockMarketOption : IOptionType = { label: `(${newStockMarket?.code}) - ${newStockMarket?.name}`, value: newStockMarket?.id ?? 0 };
        const newStockMarkOption : IOptionType = { label: `(${newStockMark?.code}) - ${newStockMark?.description}`, value: newStockMark?.id ?? 0 };
        const newStockPalletBaseTypeOption : IOptionType = { label: `(${newStockPalletBaseType?.code}) - ${newStockPalletBaseType?.name}`, value: newStockPalletBaseType?.id ?? 0 };
        const newStockOriginalStorageUnitOption : IOptionType = { label: `(${newStockOriginalStorageUnit?.code}) - ${newStockOriginalStorageUnit?.description}`, value: newStockOriginalStorageUnit?.id ?? 0 };
        const newStockCurrentStorageUnitOption : IOptionType = { label: `(${newStockCurrentStorageUnit?.code}) - ${newStockCurrentStorageUnit?.description}`, value: newStockCurrentStorageUnit?.id ?? 0 };
        const newStockInventoryOption : IOptionType = { label: `(${newStockInventory?.code}) - ${newStockInventory?.description}`, value: newStockInventory?.id ?? 0 };

        this.setState({
            isWarningPopupOpen: true,
            selectedStockNotMatching,
            selectedOriginalOrganizationOption: (newStock?.originalOrganizationId === selectedStockNotMatching.originalOrganizationId) && newStockOriginalOrg ? newStockOriginalOrgOption : undefined,
            selectedOriginalSiteOption: (newStock?.originalSiteId === selectedStockNotMatching.originalSiteId) && newStockOriginalSite ? newStockOriginalSiteOption : undefined,
            selectedCurrentOrganizationOption: (newStock?.currentOrganizationId === selectedStockNotMatching.currentOrganizationId) && newStockCurrentOrg ? newStockCurrentOrgOption : undefined,
            selectedCurrentSiteOption: (newStock?.currentSiteId === selectedStockNotMatching.currentSiteId) && newStockCurrentSite ? newStockCurrentSiteOption : undefined,
            selectedOriginalStorageUnitOption: (newStock?.originalStorageUnitId === selectedStockNotMatching.originalStorageUnitId) && newStockOriginalStorageUnit ? newStockOriginalStorageUnitOption : undefined,
            selectedCurrentStorageUnitOption: (newStock?.currentStorageUnitId === selectedStockNotMatching.currentStorageUnitId) && newStockCurrentStorageUnit ? newStockCurrentStorageUnitOption : undefined,
            selectedRegionOption: (newStock?.regionId === selectedStockNotMatching.regionId) && newStockRegion ? newStockRegionOption : undefined,
            selectedCountryOption: (newStock?.countryId === selectedStockNotMatching.countryId) && newStockCountry ? newStockCountryOption : undefined,
            selectedMarketOption: (newStock?.marketId === selectedStockNotMatching.marketId) && newStockMarket ? newStockMarketOption : undefined,
            selectedBrandOption: (newStock?.markId === selectedStockNotMatching.markId) && newStockMark ? newStockMarkOption : undefined,
            selectedPalletBaseTypeOption: (newStock?.palletBaseTypeId === selectedStockNotMatching.palletBaseTypeId) && newStockPalletBaseType ? newStockPalletBaseTypeOption : undefined,
            selectedInventoryOption: (newStock?.inventoryId === selectedStockNotMatching.inventoryId) && newStockInventory ? newStockInventoryOption : undefined,
        });
    };

    private handleCheckboxChecked = (stock : IStock, checked : boolean) => {
        const newStock = this.state.newStock;
        if (checked
            && newStock
            && (newStock.currentOrganizationId === stock.currentOrganizationId)
            && (newStock.currentSiteId === stock.currentSiteId)
            && (newStock.originalOrganizationId === stock.originalOrganizationId)
            && (newStock.originalSiteId === stock.originalSiteId)
            && (newStock.regionId === stock.regionId)
            && (newStock.countryId === stock.countryId)
            && (newStock.marketId === stock.marketId)
            && (newStock.markId === stock.markId)
            && (newStock.palletBaseTypeId === stock.palletBaseTypeId)
            && (newStock.channel === stock.channel)) { // updates newStock if with new selected stock while keeping newStock header values

            const newStockCurrentSite = this.props.sites.find(x => x.id === newStock.currentSiteId);

            if (!newStockCurrentSite?.canMixSalesPoint) {
                const firstStockLine = stock.stockLines.filter(x => x.isActive)[0];
                const firstStockLineSalesPoint = this.props.salesPoints.find(x => x.farmIds?.some(y => y === firstStockLine?.farmId));

                const hasMixedSalesPoints : boolean = stock.stockLines
                    .filter(x => x.isActive && x.id !== firstStockLine.id)
                    .some(x => !firstStockLineSalesPoint?.farmIds?.find(y => y === x.farmId));

                if (!!hasMixedSalesPoints) {
                    generalShowErrorSnackbar('The new stock current site does not allow the mixing of sales points.');
                    return;
                }
            }

            let updatedStockValues : ICombineStockUpsert = {
                ...newStock,
            };

            stock.stockLines.filter(x => x.isActive).forEach((newLine) => {
                const exsitingLine = updatedStockValues.stockLines.filter(x => x.isActive).find(x =>
                    x.commodityId === newLine.commodityId
                    && x.varietyId === newLine.varietyId
                    && x.farmId === newLine.farmId
                    && x.packId === newLine.packId
                    && x.gradeId === newLine.gradeId
                    && x.sizeId === newLine.sizeId
                    && x.colourId === newLine.colourId
                    && x.orchardId === newLine.orchardId
                    && x.batchId === newLine.batchId);

                const exsitingLineIndex = updatedStockValues.stockLines.filter(x => x.isActive).findIndex(x =>
                    x.commodityId === newLine.commodityId
                    && x.varietyId === newLine.varietyId
                    && x.farmId === newLine.farmId
                    && x.packId === newLine.packId
                    && x.gradeId === newLine.gradeId
                    && x.sizeId === newLine.sizeId
                    && x.colourId === newLine.colourId
                    && x.orchardId === newLine.orchardId
                    && x.batchId === newLine.batchId);

                if (exsitingLine) {
                    let updatedStockLine : IStockLine = {
                        ...exsitingLine,
                        cartons: exsitingLine.cartons + newLine.cartons,
                        totalInners: (exsitingLine?.totalInners ?? 0) + (newLine?.totalInners ?? 0),
                    };

                    updatedStockLine.estimatedGrossWeight = this.getStocklineEstGrossWeight(updatedStockLine);

                    updatedStockValues.stockLines = setArrayElement(updatedStockValues.stockLines, exsitingLineIndex, updatedStockLine);
                } else {
                    let newStockLine : IStockLine = {
                        ...newLine,
                        id: 0,
                        guid: uuidv1(),
                        sequence: updatedStockValues.stockLines.length + 1,
                    };

                    updatedStockValues.stockLines = addArrayElement(updatedStockValues.stockLines, newStockLine);
                }
            });

            const activeStockLines : Array<IStockLine> = updatedStockValues.stockLines.filter(x => x.isActive);

            updatedStockValues.cartons = lodash.sumBy(activeStockLines, x => x.cartons);
            updatedStockValues.grossWeight = updatedStockValues.grossWeight + stock.grossWeight;
            updatedStockValues.estimatedGrossWeight = this.totalEstGrossWeight(updatedStockValues);
            updatedStockValues.estimatedNettWeight = this.totalEstNettWeight(updatedStockValues);

            updatedStockValues.stockLines = updatedStockValues.stockLines.map((line) => {
                const updatedStockLine : IStockLine = {
                    ...line,
                    palletSize: 1 / activeStockLines.length,
                };

                if (line.isActive) {
                    return updatedStockLine;
                } else {
                    return line;
                }
            });

            if (stock.materials.length > 0) {
                stock.materials.forEach((material) => {
                    const matchingMaterialStockIndex = updatedStockValues.materials.findIndex(x => x.materialStockId === material.materialStockId);

                    if (matchingMaterialStockIndex === -1) {
                        updatedStockValues.materials = addArrayElement(updatedStockValues.materials, material);
                    } else {
                        const updatedMaterial : IMaterialsOnStock = {
                            materialStockId: material.materialStockId,
                            amount: updatedStockValues.materials[matchingMaterialStockIndex].amount + material.amount,
                        };
                        updatedStockValues.materials = setArrayElement(updatedStockValues.materials, matchingMaterialStockIndex, updatedMaterial);
                    }
                });
            }

            let updatedSelectedList = [...this.state.selectedStocks];
            const index = updatedSelectedList.findIndex(x => x.id === stock.id);

            if (index === -1) {
                updatedSelectedList = addArrayElement(updatedSelectedList, stock);
            }

            this.setState({
                newStock: updatedStockValues,
                selectedStocks: updatedSelectedList,
            });
        } else if (checked
            && newStock
            && ((newStock.currentOrganizationId !== stock.currentOrganizationId)
            || (newStock.currentSiteId !== stock.currentSiteId)
            || (newStock.originalOrganizationId !== stock.originalOrganizationId)
            || (newStock.originalSiteId !== stock.originalSiteId)
            || (newStock.regionId !== stock.regionId)
            || (newStock.countryId !== stock.countryId)
            || (newStock.marketId !== stock.marketId)
            || (newStock.markId !== stock.markId)
            || (newStock.palletBaseTypeId !== stock.palletBaseTypeId)
            || (newStock.channel !== stock.channel))) { // check if there are any mismatches with the stock header values

            const newStockCurrentSite = this.props.sites.find(x => x.id === newStock.currentSiteId);

            if (!newStockCurrentSite?.canMixSalesPoint) {
                const firstStockLine = stock.stockLines.filter(x => x.isActive)[0];
                const firstStockLineSalesPoint = this.props.salesPoints.find(x => x.farmIds?.some(y => y === firstStockLine?.farmId));

                const hasMixedSalesPoints : boolean = stock.stockLines
                    .filter(x => x.isActive && x.id !== firstStockLine.id)
                    .some(x => !firstStockLineSalesPoint?.farmIds?.find(y => y === x.farmId));

                if (!!hasMixedSalesPoints) {
                    generalShowErrorSnackbar('The new stock current site does not allow the mixing of sales points.');
                    return;
                }
            }

            this.openMismatchingStockWarningPopup(stock);
        } else if (checked && !newStock) { // Add to list if newStock is undefined
            const index = this.state.selectedStocks.findIndex(x => x.id === stock.id);

            if (index === -1) {
                if (this.state.selectedStocks.length < 1) {
                    this.setState(prevState => ({ selectedStocks: addArrayElement(prevState.selectedStocks, stock), newStock: stock }));
                } else {
                    this.setState(prevState => ({ selectedStocks: addArrayElement(prevState.selectedStocks, stock) }));
                }
            }
        } else { // removed selected stock from selectedStock list
            if (newStock && stock.guid !== newStock.guid) {
                let updatedStockValues : ICombineStockUpsert = {
                    ...newStock,
                };

                stock.stockLines.filter(x => x.isActive).forEach((newLine) => {
                    const exsitingLineIndex = updatedStockValues.stockLines.filter(x => x.isActive).findIndex(x =>
                        x.commodityId === newLine.commodityId
                        && x.varietyId === newLine.varietyId
                        && x.farmId === newLine.farmId
                        && x.packId === newLine.packId
                        && x.gradeId === newLine.gradeId
                        && x.sizeId === newLine.sizeId
                        && x.colourId === newLine.colourId
                        && x.orchardId === newLine.orchardId
                        && x.batchId === newLine.batchId);

                    if (exsitingLineIndex !== -1) {
                        let updatedStockLine : IStockLine = {
                            ...updatedStockValues.stockLines[exsitingLineIndex],
                            cartons: updatedStockValues.stockLines[exsitingLineIndex].cartons - newLine.cartons,
                            totalInners: (updatedStockValues.stockLines[exsitingLineIndex]?.totalInners ?? 0) - (newLine?.totalInners ?? 0),
                        };

                        updatedStockLine.estimatedGrossWeight = this.getStocklineEstGrossWeight(updatedStockLine);

                        if (updatedStockLine.cartons === 0) {
                            updatedStockValues.stockLines = removeArrayElement(updatedStockValues.stockLines, exsitingLineIndex);
                        } else {
                            updatedStockValues.stockLines = setArrayElement(updatedStockValues.stockLines, exsitingLineIndex, updatedStockLine);
                        }
                    }
                });

                const activeStockLines : Array<IStockLine> = updatedStockValues.stockLines.filter(x => x.isActive);

                updatedStockValues.stockLines = updatedStockValues.stockLines.map((line) => {
                    const updatedStockLine : IStockLine = {
                        ...line,
                        palletSize: 1 / activeStockLines.length,
                    };

                    if (line.isActive) {
                        return updatedStockLine;
                    } else {
                        return line;
                    }
                });

                if (stock.materials.length > 0) {
                    stock.materials.forEach((material) => {
                        const matchingMaterialStockIndex = updatedStockValues.materials.findIndex(x => x.materialStockId === material.materialStockId);

                        if (matchingMaterialStockIndex !== -1) {
                            const updatedMaterial : IMaterialsOnStock = {
                                materialStockId: material.materialStockId,
                                amount: updatedStockValues.materials[matchingMaterialStockIndex].amount - material.amount,
                            };

                            if (updatedMaterial.amount === 0) {
                                updatedStockValues.materials = removeArrayElement(updatedStockValues.materials, matchingMaterialStockIndex);
                            } else {
                                updatedStockValues.materials = setArrayElement(updatedStockValues.materials, matchingMaterialStockIndex, updatedMaterial);
                            }
                        }
                    });
                }

                const index = this.state.selectedStocks.findIndex(x => x.id === stock.id);
                if (index !== -1) {
                    if (this.state.selectedStocks.length === 1) {
                        this.setState(prevState => ({ selectedStocks: removeArrayElement(prevState.selectedStocks, index), newStock: undefined }));
                    } else {
                        this.setState(prevState => ({ selectedStocks: removeArrayElement(prevState.selectedStocks, index), newStock: updatedStockValues }));
                    }
                }
            } else {
                const index = this.state.selectedStocks.findIndex(x => x.id === stock.id);
                if (index !== -1) {
                    if (this.state.selectedStocks.length === 1) {
                        this.setState(prevState => ({ selectedStocks: removeArrayElement(prevState.selectedStocks, index), newStock: undefined }));
                    } else {
                        this.setState(prevState => ({ selectedStocks: removeArrayElement(prevState.selectedStocks, index) }));
                    }
                }
            }
        }
    };

    private onSelectedOriginalOrganizationOptionChange = async (e : CustomChangeEvent, selectedOption : IOptionType) => {
        this.setState({ selectedOriginalOrganizationOption: selectedOption });
    };

    private onSelectedCurrentOrganizationOptionChange = async (e : CustomChangeEvent, selectedOption : IOptionType) => {
        this.setState({ selectedCurrentOrganizationOption: selectedOption });
    };

    private onSelectedOriginalSiteOptionChange = async (e : CustomChangeEvent, selectedOption : IOptionType) => {
        this.setState({ selectedOriginalSiteOption: selectedOption });
    };

    private onSelectedCurrentSiteOptionChange = async (e : CustomChangeEvent, selectedOption : IOptionType) => {
        this.setState({ selectedCurrentSiteOption: selectedOption });
    };

    private onSelectedPalletBaseTypeOptionChange = async (e : CustomChangeEvent, selectedOption : IOptionType) => {
        this.setState({ selectedPalletBaseTypeOption: selectedOption });
    };

    private onSelectedRegionOptionChange = async (e : CustomChangeEvent, selectedOption : IOptionType) => {
        this.setState({ selectedRegionOption: selectedOption });
    };

    private onSelectedCountryOptionChange = async (e : CustomChangeEvent, selectedOption : IOptionType) => {
        this.setState({ selectedCountryOption: selectedOption });
    };

    private onSelectedMarketOptionChange = async (e : CustomChangeEvent, selectedOption : IOptionType) => {
        this.setState({ selectedMarketOption: selectedOption });
    };

    private onSelectedMarkOptionChange = async (e : CustomChangeEvent, selectedOption : IOptionType) => {
        this.setState({ selectedBrandOption: selectedOption });
    };

    private onSelectedInventoryOptionChange = async (e : CustomChangeEvent, selectedOption : IOptionType) => {
        this.setState({ selectedInventoryOption: selectedOption });
    };

    private onSelectedOriginalStorageUnitOptionChange = async (e : CustomChangeEvent, selectedOption : IOptionType) => {
        this.setState({ selectedOriginalStorageUnitOption: selectedOption });
    };

    private onSelectedCurrentStorageUnitOptionChange = async (e : CustomChangeEvent, selectedOption : IOptionType) => {
        this.setState({ selectedCurrentStorageUnitOption: selectedOption });
    };

    private getOriginalOrganizationOptions = createSelector(
        [this.getOrganizations, this.getNewStockValues, this.getSelectedStockNotMatching],
        (organizations, newStock ?: IStock, selectedStockNotMatching ?: IStock) => {
            const newStockOriginalOrg = organizations.find(x => x.id === newStock?.originalOrganizationId);
            const selectedStockOriginalOrg = organizations.find(x => x.id === selectedStockNotMatching?.originalOrganizationId);

            if (!newStockOriginalOrg && !selectedStockOriginalOrg) return [];

            const newStockOriginalOrgOption : IOptionType = { label: `(${newStockOriginalOrg?.code}) - ${newStockOriginalOrg?.name}`, value: newStockOriginalOrg?.id ?? 0 };
            const selectedStockOriginalOrgOption : IOptionType = { label: `(${selectedStockOriginalOrg?.code}) - ${selectedStockOriginalOrg?.name}`, value: selectedStockOriginalOrg?.id ?? 0 };

            const options : Array<IOptionType> = [newStockOriginalOrgOption, selectedStockOriginalOrgOption];

            return options;
        },
    );

    private getCurrentOrganizationOptions = createSelector(
        [this.getOrganizations, this.getNewStockValues, this.getSelectedStockNotMatching],
        (organizations, newStock ?: IStock, selectedStockNotMatching ?: IStock) => {
            const newStockCurrentOrg = organizations.find(x => x.id === newStock?.currentOrganizationId);
            const selectedStockCurrentOrg = organizations.find(x => x.id === selectedStockNotMatching?.currentOrganizationId);

            if (!newStockCurrentOrg && !selectedStockCurrentOrg) return [];

            const newStockCurrentOrgOption : IOptionType = { label: `(${newStockCurrentOrg?.code}) - ${newStockCurrentOrg?.name}`, value: newStockCurrentOrg?.id ?? 0 };
            const selectedStockCurrentOrgOption : IOptionType = { label: `(${selectedStockCurrentOrg?.code}) - ${selectedStockCurrentOrg?.name}`, value: selectedStockCurrentOrg?.id ?? 0 };

            const options : Array<IOptionType> = [newStockCurrentOrgOption, selectedStockCurrentOrgOption];

            return options;
        },
    );

    private getOriginalSiteOptions = createSelector(
        [this.getSites, this.getSelectedOriginalOrganizationOption, this.getNewStockValues, this.getSelectedStockNotMatching],
        (sites, selectedOriginalOrganizationOption ?: IOptionType, newStock ?: IStock, selectedStockNotMatching ?: IStock) => {
            const availableSiteOptions = sites.filter(x =>
                (x.id === newStock?.originalSiteId || x.id === selectedStockNotMatching?.originalSiteId)
                && (!selectedOriginalOrganizationOption || x?.organizationIds.some(y => y === selectedOriginalOrganizationOption?.value)))
                .map((site) => {
                    return { label: `(${site.code}) - ${site.shortDescription}`, value: site.id ?? 0 };
                });

            return availableSiteOptions;
        },
    );

    private getCurrentSiteOptions = createSelector(
        [this.getSites, this.getSelectedCurrentOrganizationOption, this.getNewStockValues, this.getSelectedStockNotMatching],
        (sites, selectedCurrentOrganizationOption ?: IOptionType, newStock ?: IStock, selectedStockNotMatching ?: IStock) => {
            const availableSiteOptions = sites.filter(x =>
                (x.id === newStock?.currentSiteId || x.id === selectedStockNotMatching?.currentSiteId)
                && (!selectedCurrentOrganizationOption || x?.organizationIds.some(y => y === selectedCurrentOrganizationOption?.value)))
                .map((site) => {
                    return { label: `(${site.code}) - ${site.shortDescription}`, value: site.id ?? 0 };
                });

            return availableSiteOptions;
        },
    );

    private getRegionOptions = createSelector(
        [this.getRegions, this.getSelectedCurrentOrganizationOption, this.getNewStockValues, this.getSelectedStockNotMatching],
        (regions : Array<IRegion>, selectedCurrentOrganizationOption ?: IOptionType, newStock ?: IStock, selectedStockNotMatching ?: IStock) => {
            const availableRegionOptions = regions.filter(x =>
                (x.id === newStock?.regionId || x.id === selectedStockNotMatching?.regionId))
                .map((x) => {
                    return { label: `(${x.code}) - ${x.name}`, value: x.id ?? 0 };
                });

            return availableRegionOptions;
        },
    );

    private getCountryOptions = createSelector(
        [this.getCountries, this.getSelectedRegionOption, this.getNewStockValues, this.getSelectedStockNotMatching],
        (countries : Array<ICountry>, selectedRegionOption ?: IOptionType, newStock ?: IStock, selectedStockNotMatching ?: IStock) => {
            const availableCountryOptions = countries.filter(x =>
                (x.id === newStock?.countryId || x.id === selectedStockNotMatching?.countryId)
                && (!selectedRegionOption || x.regionId === selectedRegionOption?.value))
                .map((x) => {
                    return { label: `(${x.code}) - ${x.name}`, value: x.id ?? 0 };
                });

            return availableCountryOptions;
        },
    );

    private getMarketOptions = createSelector(
        [this.getMarkets, this.getSelectedRegionOption, this.getNewStockValues, this.getSelectedStockNotMatching],
        (markets : Array<IMarket>, selectedRegionOption ?: IOptionType, newStock ?: IStock, selectedStockNotMatching ?: IStock) => {
            const availableMarketOptions = markets.filter(x =>
                (x.id === newStock?.marketId || x.id === selectedStockNotMatching?.marketId)
                && (!selectedRegionOption || x.regionId === selectedRegionOption?.value))
                .map((x) => {
                    return { label: `(${x.code}) - ${x.name}`, value: x.id ?? 0 };
                });

            return availableMarketOptions;
        },
    );

    private getPalletBaseTypeOptions = createSelector(
        [this.getPalletBaseTypes, this.getNewStockValues, this.getSelectedStockNotMatching],
        (palletBaseTypes : Array<IPalletBaseType>, newStock ?: IStock, selectedStockNotMatching ?: IStock) => {
            const availablePalletBaseTypesOptions = palletBaseTypes.filter(x =>
                (x.id === newStock?.palletBaseTypeId || x.id === selectedStockNotMatching?.palletBaseTypeId))
                .map((x) => {
                    return { label: `(${x.code}) - ${x.name}`, value: x.id ?? 0 };
                });

            return availablePalletBaseTypesOptions;
        },
    );

    private getMarkOptions = createSelector(
        [this.getBrands, this.getNewStockValues, this.getSelectedStockNotMatching],
        (marks : Array<IMark>, newStock ?: IStock, selectedStockNotMatching ?: IStock) => {
            const availableOptions = marks.filter(x =>
                (x.id === newStock?.markId || x.id === selectedStockNotMatching?.markId))
                .map((x) => {
                    return { label: `(${x.code}) - ${x.description}`, value: x.id ?? 0 };
                });

            return availableOptions;
        },
    );

    private getInventoryOptions = createSelector(
        [this.getInventories, this.getNewStockValues, this.getSelectedStockNotMatching],
        (inventories : Array<IInventory>, newStock ?: IStock, selectedStockNotMatching ?: IStock) => {
            const availableOptions = inventories.filter(x =>
                (x.id === newStock?.inventoryId || x.id === selectedStockNotMatching?.inventoryId))
                .map((x) => {
                    return { label: `(${x.code}) - ${x.description}`, value: x.id ?? 0 };
                });

            return availableOptions;
        },
    );

    private getOriginalStorageUnitOptions = createSelector(
        [this.getStorageUnits, this.getNewStockValues, this.getSelectedStockNotMatching],
        (storageUnits : Array<IStorageUnit>, newStock ?: IStock, selectedStockNotMatching ?: IStock) => {
            const availableOptions = storageUnits.filter(x =>
                (x.id === newStock?.originalStorageUnitId || x.id === selectedStockNotMatching?.originalStorageUnitId))
                .map((x) => {
                    return { label: `(${x.code}) - ${x.description}`, value: x.id ?? 0 };
                });

            return availableOptions;
        },
    );

    private getCurrentStorageUnitOptions = createSelector(
        [this.getStorageUnits, this.getNewStockValues, this.getSelectedStockNotMatching],
        (storageUnits : Array<IStorageUnit>, newStock ?: IStock, selectedStockNotMatching ?: IStock) => {
            const availableOptions = storageUnits.filter(x =>
                (x.id === newStock?.currentStorageUnitId || x.id === selectedStockNotMatching?.currentStorageUnitId))
                .map((x) => {
                    return { label: `(${x.code}) - ${x.description}`, value: x.id ?? 0 };
                });

            return availableOptions;
        },
    );

    private renderNewStockValues = createSelector(
        [this.getNewStockValues],
        (newStockValues ?: IStock) => {
            if (!newStockValues) return;

            return (
                <div className={'fdc flx1 w600 p10 jcfe'}>
                    <div className={'fdr jcfe'}>
                        <PackmanLabel label={'Original Organization'} value={this.getOrganizationCodeAndName(newStockValues?.originalOrganizationId ?? 0)} className={'flx1 mr5'}/>
                        <PackmanLabel label={'Original Site'} value={this.getSiteCodeAndDescription(newStockValues?.originalSiteId ?? 0)} className={'flx1 mr5'}/>
                        <PackmanLabel label={'Current Organization'} value={this.getOrganizationCodeAndName(newStockValues?.currentOrganizationId ?? 0)} className={'flx1 mr5'}/>
                        <PackmanLabel label={'Current Site'} value={this.getSiteCodeAndDescription(newStockValues?.currentSiteId ?? 0)} className={'flx1'}/>
                    </div>
                    <div className={'fdr jcfe'}>
                        <PackmanLabel label={'Market'} value={this.getMarketCodeAndName(newStockValues?.marketId ?? 0)} className={'flx1 mr5'}/>
                        <PackmanLabel label={'Country'} value={this.getCountryCodeAndName(newStockValues?.countryId ?? 0)} className={'flx1 mr5'}/>
                        <PackmanLabel label={'Pallet Base Type'} value={this.getPalletBaseTypeCodeAndName(newStockValues?.palletBaseTypeId ?? 0)} className={'flx1 mr5'}/>
                        <PackmanLabel label={'Brand'} value={this.getMarkCodeAndDescription(newStockValues?.markId ?? 0)} className={'flx1 mr5'}/>
                    </div>
                    <div className={'fdr jcfe'}>
                        <PackmanLabel label={'Channel'} value={newStockValues.channel} className={'flx1'}/>
                        <PackmanLabel label={'Gross Weight'} value={newStockValues.grossWeight.toFixed(3)} className={'flx1'}/>
                    </div>
                    <Typography className={'mt10'} variant={'h6'}>Stock Lines</Typography>
                    <div className={'fdc mxh200 flx1 oya'}>
                        {newStockValues.stockLines.map((stockLine) => {
                            return (
                                <div className={'fdr flx1 jcfe'}>
                                    <PackmanLabel label={'Pack'} value={this.getPackCodeAndDescription(stockLine.packId)} className={'flx1 mr5'}/>
                                    <PackmanLabel label={'Size'} value={this.getSizeCodeAndName(stockLine.sizeId)} className={'flx1 mr5'}/>
                                    <PackmanLabel label={'Grade'} value={this.getGradeCodeAndName(stockLine.gradeId)} className={'flx1 mr5'}/>
                                    <PackmanLabel label={'Colour'} value={this.getColourCodeAndName(stockLine.colourId ?? 0)} className={'flx1 mr5'}/>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        },
    );

    private renderAvailableStockTable = createSelector(
        [this.getStocks, this.getSelectedStocks],
        (stocks : Array<IStock>, selectedStocks : Array<IStock>) => {
            return (
                <WindowList
                    itemCount={stocks.length}
                    width={600}
                    height={400}
                    itemSize={40}
                    itemData={stocks}
                >
                    {React.memo(({ data, index, style } : React.PropsWithChildren<ListChildComponentProps>) => {
                        const stock : IStock = data[index];
                        if (!stock) return <div />;
                        const activeStockLines = stock.stockLines.filter(x => x.isActive);
                        const totalInners = activeStockLines.filter(x => !!x.totalInners).map(x => x.totalInners as number);
                        const stockTotalCartons = lodash.sumBy(activeStockLines, x => x.cartons);
                        const stockTotalInners = lodash.sum(totalInners);
                        const isChecked : boolean = !!selectedStocks.find(x => x.id === stock.id);

                        const newStockChannel = this.state.newStock?.channel;

                        let disabled : boolean = false;

                        if ((isChecked && this.state.newStock?.id === stock.id && selectedStocks.length > 1) || (newStockChannel && newStockChannel === 'L' && stock.channel === 'E')) {
                            disabled = true;
                        }

                        let tooltipTitle = '';

                        if ((isChecked && this.state.newStock?.id === stock.id && selectedStocks.length > 1)) {
                            tooltipTitle = 'First stock selected to combine into or create stock new from.';
                        } else if (newStockChannel && newStockChannel === 'L' && stock.channel === 'E') {
                            tooltipTitle = 'Cannot combine Export into Local. Only from Local into Export is allowed.';
                        }

                        return (
                            <TableRow style={style} className={`${index % 2 === 0 ? 'bcTableRow2' : 'bcTableRow1'}`}>
                                <TableCell className={'wfill jcc aic h33 fs14 p0 fw500'}>
                                    <CustomTooltip title={tooltipTitle}>
                                        <div className={'h60 aic jcc'}>
                                            <Checkbox
                                                className={'pt0 pb0 pr5 m0'}
                                                checked={isChecked}
                                                disabled={disabled}
                                                onChange={(event, checked) => this.handleCheckboxChecked(stock, checked)}
                                            />
                                        </div>
                                    </CustomTooltip>
                                    <div className={'w200 aic jcc hfill'}>{stock.barcode}</div>
                                    <div className={'w100 aic jcc bl1 hfill'}>{stockTotalCartons}</div>
                                    <div className={'w100 aic jcc bl1 hfill'}>{stockTotalInners}</div>
                                    <div className={'w100 aic jcc bl1 hfill'}>{stock.grossWeight.toFixed(3)}</div>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </WindowList>
            );
        },
    );

    private renderUpdatedStocks = createSelector(
        [this.getResultStockList],
        (stocks : Array<IStockView>) => {
            if (stocks.length > 0) {
                const resultStock = stocks.find(x => x.status !== 'Broken Down');

                const brokenDownStocks = stocks.filter(x => x.status === 'Broken Down');

                return (
                    <div className={'fdc flx1'}>
                        <Typography variant='h6'>Stocks selected:</Typography>
                        {brokenDownStocks.map((stock) => {
                            return (
                                <div className={'mt5 pl10'}>{stock.barcode}</div>
                            );
                        })}
                        <Typography variant='h6'>Combined into New Stock:</Typography>
                        <div className={'mt5 pl10'}>{resultStock?.barcode}</div>
                    </div>
                );
            }
        },
    );

    public render() {
        const newStockValues = this.getNewStockValues(this.props, this.state);
        return (
            <div className={'fdc p10'}>
                <div className={'fdr flx1'}>
                    <div className={'flx1 w600'}>
                        <Table className= {'PaperBorder'}>
                            {/* Headings */}
                            <TableRow className={'fdr bcTableHead'}>
                                <TableCell className={'flx3 jcc aic p5 bcTableHead h40 cw fs14'}>
                                    <div className={'h60 aic jcc'}>Select</div>
                                    <div className={'w200 aic jcc'}>Barcode</div>
                                    <div className={'w100 aic jcc'}>Total Cartons</div>
                                    <div className={'w100 aic jcc'}>Total Inners</div>
                                    <div className={'w100 aic jcc'}>Gross Weight (kg)</div>
                                </TableCell>
                            </TableRow>
                            {/* body */}
                            {this.renderAvailableStockTable(this.props, this.state)}
                        </Table>
                    </div>
                    <div className={'fdr aic jcc'}>
                        <TrendingFlatIcon color={'primary'} fontSize={'inherit'} style={{ minHeight: 80, height: 80, maxHeight: 80 }} className={'w50'}/>
                    </div>
                    {!!newStockValues
                        ? this.renderNewStockValues(this.props, this.state)
                        : <div className={'fdc flx1 w600'}></div>
                    }
                </div>
                <div className={'fdr flx1 jcfe'}>
                    <PillButton
                        className={'ml15 pl20 pr20 h35 mr20'}
                        text={'Create New Stock'}
                        color={'secondary'}
                        disabled={this.state.selectedStocks.length < 2 || this.props.isLoading}
                        onClick={this.openCreateNewStockWarning}
                    />
                    <PillButton
                        className={'ml15 pl20 pr20 h35'}
                        text={'Combine Into Selected stock'}
                        color={'secondary'}
                        disabled={this.state.selectedStocks.length < 2 || this.props.isLoading}
                        onClick={this.openCombineStockWarning}
                    />
                </div>
                <PackmanDialog
                    title={'Warning'}
                    maxWidth={'lg'}
                    isWarning
                    isInfo
                    isLoading={this.props.isLoading}
                    isOpen={!!this.state.isWarningPopupOpen}
                    onClose={this.closeWarningDialog}>
                    <div className={'fdc mnw1000 hfill p10'}>
                        <Typography variant={'body1'}>Not all stock header values matches the new stock header values.</Typography>
                        <Typography variant={'body1'}>Please select your prefered options in the drop downs.</Typography>
                        <Typography className={`${this.state.newStock?.channel !== this.state.selectedStockNotMatching?.channel ? 'mt20 mb20' : 'dn'}`} variant={'body1'}>The channel differs from the result stock. Please indicate whether you want it to be Export or Local by selecting the corresponding market.</Typography>
                        <div className={'fdc flx1'}>
                            <div className={'fdr wfill mb5'}>
                                <AutocompleteSelect
                                    className={'flx1'}
                                    name={'originalOrganization'}
                                    label={'Original Organization'}
                                    options={this.getOriginalOrganizationOptions(this.props, this.state)}
                                    onChange={this.onSelectedOriginalOrganizationOptionChange}
                                    value={this.state.selectedOriginalOrganizationOption}
                                />
                                <AutocompleteSelect
                                    className={'flx1'}
                                    name={'originalSite'}
                                    label={'Original Site'}
                                    options={this.getOriginalSiteOptions(this.props, this.state)}
                                    onChange={this.onSelectedOriginalSiteOptionChange}
                                    value={this.state.selectedOriginalSiteOption}
                                />
                                <AutocompleteSelect
                                    className={'flx1'}
                                    name={'CurrentOrganization'}
                                    label={'Current Organization'}
                                    options={this.getCurrentOrganizationOptions(this.props, this.state)}
                                    onChange={this.onSelectedCurrentOrganizationOptionChange}
                                    value={this.state.selectedCurrentOrganizationOption}
                                />
                                <AutocompleteSelect
                                    className={'flx1'}
                                    name={'currentSite'}
                                    label={'Current Site'}
                                    options={this.getCurrentSiteOptions(this.props, this.state)}
                                    onChange={this.onSelectedCurrentSiteOptionChange}
                                    value={this.state.selectedCurrentSiteOption}
                                />
                            </div>
                            <div className={'fdr wfill mb5'}>
                                <AutocompleteSelect
                                    className={'flx1'}
                                    name={'palletBaseType'}
                                    label={'Pallet Base Type'}
                                    options={this.getPalletBaseTypeOptions(this.props, this.state)}
                                    onChange={this.onSelectedPalletBaseTypeOptionChange}
                                    value={this.state.selectedPalletBaseTypeOption}
                                />
                                <AutocompleteSelect
                                    className={'flx1'}
                                    name={'Region'}
                                    label={'region'}
                                    options={this.getRegionOptions(this.props, this.state)}
                                    onChange={this.onSelectedRegionOptionChange}
                                    value={this.state.selectedRegionOption}
                                />
                                <AutocompleteSelect
                                    className={'flx1'}
                                    name={'country'}
                                    label={'Country'}
                                    options={this.getCountryOptions(this.props, this.state)}
                                    onChange={this.onSelectedCountryOptionChange}
                                    value={this.state.selectedCountryOption}
                                />
                                <AutocompleteSelect
                                    className={'flx1'}
                                    name={'market'}
                                    label={'Market'}
                                    options={this.getMarketOptions(this.props, this.state)}
                                    onChange={this.onSelectedMarketOptionChange}
                                    value={this.state.selectedMarketOption}
                                />
                            </div>
                            <div className={'fdr wfill mb5'}>
                                <AutocompleteSelect
                                    className={'flx1'}
                                    name={'brand'}
                                    label={'Brand'}
                                    options={this.getMarkOptions(this.props, this.state)}
                                    onChange={this.onSelectedMarkOptionChange}
                                    value={this.state.selectedBrandOption}
                                />
                                <AutocompleteSelect
                                    className={'flx1'}
                                    name={'inventory'}
                                    label={'Inventory'}
                                    options={this.getInventoryOptions(this.props, this.state)}
                                    onChange={this.onSelectedInventoryOptionChange}
                                    value={this.state.selectedInventoryOption}
                                />
                                <AutocompleteSelect
                                    className={'flx1'}
                                    name={'originalStorageUnit'}
                                    label={'Original Storage Unit'}
                                    options={this.getOriginalStorageUnitOptions(this.props, this.state)}
                                    onChange={this.onSelectedOriginalStorageUnitOptionChange}
                                    value={this.state.selectedOriginalStorageUnitOption}
                                />
                                <AutocompleteSelect
                                    className={'flx1'}
                                    name={'currentStorageUnit'}
                                    label={'Current Storage Unit'}
                                    options={this.getCurrentStorageUnitOptions(this.props, this.state)}
                                    onChange={this.onSelectedCurrentStorageUnitOptionChange}
                                    value={this.state.selectedCurrentStorageUnitOption}
                                />
                            </div>
                        </div>
                        <div className={'fdr pt10 pb10 wfill jcfe'}>
                            <Button
                                className={'fwb h35'}
                                variant='text'
                                color='primary'
                                onClick={this.closeWarningDialog}>
                                    Cancel
                            </Button>
                            <PillButton
                                disabled={this.props.isLoading || !this.allDropdownsHaveValues()}
                                className={'ml15 pl20 pr20 h35'}
                                text={'Continue'}
                                color={'secondary'}
                                onClick={this.onWarningContinueClick}
                            />
                        </div>
                    </div>
                </PackmanDialog>
                <PackmanDialog
                    title={'WARNING! Creating New Stock'}
                    isInfo
                    isError
                    isLoading={this.props.isLoading}
                    isOpen={this.state.isCreateNewStockWarningOpen}
                    onClose={this.cancelCreateNewStockWarningPopup}>
                    <div className={'fdc p20'}>
                        <div className={'fdr aic jcc pb5 fs16'}>{'You are about to create a new stock from the selected stocks.'}</div>
                        <div className={'fdr aic jcc pb5 fwb fs16'}>{'This action can not be undone.'}</div>
                        <div className={'fdr ml10 ais jcfe mt20'}>
                            <Button
                                className={'fwb h35'}
                                variant='text'
                                color='primary'
                                onClick={this.cancelCreateNewStockWarningPopup}
                            >
                                Cancel
                            </Button>
                            <PillButton
                                className={'ml15 pl20 pr20 h35'}
                                text={'Submit'}
                                color={'secondary'}
                                onClick={this.onCreateNewStockSubmit}
                            />
                        </div>
                    </div>
                </PackmanDialog>
                <PackmanDialog
                    title={'WARNING! Combining Stock'}
                    isInfo
                    isError
                    isLoading={this.props.isLoading}
                    isOpen={this.state.isCombineStockWarningOpen}
                    onClose={this.cancelCombineStockWarningPopup}>
                    <div className={'fdc p20'}>
                        <div className={'fdr aic jcc pb5 fs16'}>{'Are you sure you want to combine these stocks.'}</div>
                        <div className={'fdr aic jcc pb5 fwb fs16'}>{'This action can not be undone.'}</div>
                        <div className={'fdr ml10 ais jcfe mt20'}>
                            <Button
                                className={'fwb h35'}
                                variant='text'
                                color='primary'
                                onClick={this.cancelCombineStockWarningPopup}
                            >
                                Cancel
                            </Button>
                            <PillButton
                                className={'ml15 pl20 pr20 h35'}
                                text={'Submit'}
                                color={'secondary'}
                                onClick={this.onCombineStockSubmitConfirmed}
                            />
                        </div>
                    </div>
                </PackmanDialog>
                <PackmanDialog
                    title={'Result'}
                    isInfo
                    isLoading={this.props.isLoading}
                    isOpen={this.state.isResultPopupOpen}
                    onClose={this.closeResultDialog}>
                    <div className={'fdc p20'}>
                        {this.renderUpdatedStocks(this.props, this.state)}
                        <div className={'fdr ml10 ais jcfe mt20'}>
                            <PillButton
                                className={'ml15 pl20 pr20 h35'}
                                text={'Done'}
                                color={'secondary'}
                                onClick={this.closeResultDialog}
                            />
                        </div>
                    </div>
                </PackmanDialog>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        selectedSiteIds: state.data.selectedSiteIds,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
        markets: state.masterData.markets,
        marks: state.masterData.marks,
        regions: state.masterData.regions,
        countries: state.masterData.countries,
        palletBaseTypes: state.masterData.palletBaseTypes,
        inventories: state.masterData.inventories,
        packs: state.masterData.packs,
        sizes: state.masterData.sizes,
        grades: state.masterData.grades,
        colours: state.masterData.colours,
        storageUnits: state.masterData.storageUnits,
        salesPoints: state.masterData.salesPoints,
    };
};

export default connect(
    mapStateToProps,
)(CombineStockDialog);

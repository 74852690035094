import { IOrganization } from './organization';
import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { IContactInfo } from '../contactInfo/contactInfo';

export interface IOrganizationFormValues {
    id : number;
    guid : string;
    isActive : boolean;

    contactInfos : Array<IOptionType>;
    code : string;
    name : string;
    isSupplier : boolean;
    isClient : boolean;
    canIntake : boolean;
    requiresProject : boolean;
    exporter ?: string;
    billingParty ?: string;
    waybillPrefix ?: string;
    nextWaybillNumber ?: number;
    waybillRangeEnd ?: number;
}

export class OrganizationFormValues implements IOrganizationFormValues {
    public id : number;
    public guid : string;
    public isActive : boolean;

    public contactInfos : Array<IOptionType>;

    public code : string;
    public name : string;
    public isSupplier : boolean;
    public isClient : boolean;
    public canIntake : boolean;
    public requiresProject : boolean;
    public exporter ?: string;
    public billingParty ?: string;
    public waybillPrefix ?: string;
    public nextWaybillNumber ?: number;
    public waybillRangeEnd ?: number;

    public constructor(
        organization ?: IOrganization,
        contactInfos ?: Array<IContactInfo>,
    ) {
        this.initializeDefaultValues();
        if (organization) {
            this.id = organization.id;
            this.guid = organization.guid;

            this.contactInfos = contactInfos?.filter(x => organization.contactInfoIds.some(y => y === x.id)).map(x => ({
                label: x.name,
                value: x.id,
            })) ?? [];

            this.code = organization.code;
            this.name = organization.name;
            this.isSupplier = organization.isSupplier;
            this.isClient = organization.isClient;
            this.canIntake = organization.canIntake;
            this.requiresProject = organization.requiresProject;
            this.exporter = organization.exporter;
            this.billingParty = organization.billingParty;
            this.waybillPrefix = organization.waybillPrefix;
            this.nextWaybillNumber = organization.nextWaybillNumber;
            this.waybillRangeEnd = organization.waybillRangeEnd;
            this.isActive = organization.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.isActive = true;

        this.contactInfos = [];
        this.code = '';
        this.name = '';
        this.isSupplier = false;
        this.isClient = false;
        this.canIntake = false;
        this.requiresProject = false;
        this.exporter = undefined;
        this.billingParty = undefined;
        this.waybillPrefix = undefined;
        this.nextWaybillNumber = undefined;
        this.waybillRangeEnd = undefined;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        isSupplier: Yup.boolean().required('Required'),
        isClient: Yup.boolean().required('Required'),
        canIntake: Yup.boolean().required('Required'),
        requiresProject: Yup.boolean().required('Required'),
        isActive: Yup.boolean().required('Required'),
        waybillPrefix: Yup.string().max(1).nullable(),
    });
}

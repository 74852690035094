import * as React from 'react';
import { IconButton, Icon, Popper, Grow, ClickAwayListener, FormControl, InputAdornment, Button, Checkbox, Typography } from '@mui/material';
import PillButton from '../input/PillButton';
import './customTableStyles.scss';
import TextInput from '../../components/input/TextInput';
import CustomTooltip from '../tooltip/tooltip';
import materialTheme from '../../styles/materialTheme';

interface ICustomTableFilterProps {
    field : string;
    setOpen : (open : boolean, field : string) => void;
    onFilterChanged : (columnField : string, filterValue ?: string, showEmpty ?: boolean, showFilled ?: boolean) => void;
    enableAdditionalFiltering ?: boolean;
}

interface ICustomTableFilterState {
    open : boolean;

    filterValue : string;
    showEmpty : boolean;
    showFilled : boolean;

    prevShowEmpty : boolean;
    prevShowFilled : boolean;
}

export default class CustomTableFilter extends React.Component<ICustomTableFilterProps, ICustomTableFilterState> {
    private anchorEl : any;

    constructor(props : ICustomTableFilterProps) {
        super(props);

        this.state = {
            open: false,
            showEmpty: false,
            showFilled: false,
            prevShowEmpty: false,
            prevShowFilled: false,
            filterValue: '',
        };
    }

    public componentDidUpdate = (prevProps : ICustomTableFilterProps, prevState : ICustomTableFilterState) => {
        if (!prevState.open && this.state.open) {
            const element = document.getElementById(`filter-${this.props.field}-id`);
            this.setState({ prevShowEmpty: prevState.showEmpty, prevShowFilled: prevState.showFilled });
            if (!!element) element.focus();
        }
        if (prevState.open !== this.state.open) {
            this.props.setOpen(this.state.open, this.props.field);
        }
    };

    private handleClick = () => {
        this.setState({ open: !this.state.open });
    };

    private handleClose = (event : MouseEvent | TouchEvent) => {
        if (this.anchorEl && this.anchorEl.contains(event.target)) {
            return;
        }

        event.preventDefault();

        this.props.onFilterChanged(this.props.field, this.state.filterValue, this.state.prevShowEmpty, this.state.prevShowFilled);
        this.setState({ open: false, showEmpty: this.state.prevShowEmpty, showFilled: this.state.prevShowFilled });
    };

    private filterValueChanged = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            filterValue: event.target.value,
        });
    };

    private submitForm = async (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.setState({ open: false });

        this.props.onFilterChanged(this.props.field, this.state.filterValue, this.state.showEmpty, this.state.showFilled);
    };

    private onClearClicked = () => {
        this.setState({
            filterValue: '',
            open: false,
        });

        this.props.onFilterChanged(this.props.field);
    };

    private handleShowEmptyCheckboxChecked = (selected : boolean) => {
        if (!this.state.showFilled) {
            this.setState({ showEmpty: selected });
        } else {
            this.setState({ showEmpty: selected, showFilled: false });
        }
    };

    private handleShowFilledCheckboxChecked = (selected : boolean) => {
        if (!this.state.showEmpty) {
            this.setState({ showFilled: selected });
        } else {
            this.setState({ showFilled: selected, showEmpty: false });
        }
    };

    public render = () => {
        const { open, filterValue } = this.state;

        return <>
            <IconButton
                aria-owns={`filter-menu-${this.props.field}`}
                aria-haspopup='true'
                onClick={this.handleClick}
                ref={(node) => { this.anchorEl = node; }}
                color={'inherit'}>
                <Icon fontSize='small'>
                    filter_list
                </Icon>
            </IconButton>
            <Popper anchorEl={this.anchorEl} open={open} transition style={{ zIndex: 1000 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', marginTop: 8 }}
                    >
                        <div id={`filter-menu-${this.props.field}`} className={'bcw p10 filterShadow'}>
                            <ClickAwayListener onClickAway={this.handleClose}>
                                <div className={'fdc tcFilterBlack'}>
                                    <div className={'fdr pl10'}>
                                        Filter By:
                                    </div>
                                    <form autoComplete='off' onSubmit={this.submitForm}>
                                        <FormControl>
                                            <div className='fdr aic'>
                                                <TextInput
                                                    id={`filter-${this.props.field}-id`}
                                                    autoFocus
                                                    placeholder='Filter'
                                                    label={''}
                                                    name={'filter'}
                                                    value={filterValue}
                                                    onChange={this.filterValueChanged}
                                                    endAdornment={<InputAdornment position='end'>
                                                        { filterValue !== '' &&
                                                        <CustomTooltip title='Clear'>
                                                            <Icon aria-label='clear' onClick={this.onClearClicked}>clear</Icon>
                                                        </CustomTooltip>}
                                                    </InputAdornment>}
                                                />
                                            </div>
                                            <div className={`${!!this.props.enableAdditionalFiltering ? 'fdc pl10' : 'dn'}`}>
                                                <div className={'fdr aic'}>
                                                    <Typography variant={'caption'}>{'Show Empty'}</Typography>
                                                    <div className={'w10'}/>
                                                    <Checkbox
                                                        className={'p0 m0'}
                                                        size={'small'}
                                                        style={{ color: materialTheme.palette.primary.main }}
                                                        checked={this.state.showEmpty}
                                                        onChange={(event, checked) => this.handleShowEmptyCheckboxChecked(checked)}
                                                    />
                                                </div>
                                                <div className={'h5'}/>
                                                <div className={'fdr aic'}>
                                                    <Typography variant={'caption'}>{'Show Filled'}</Typography>
                                                    <div className={'w15'}/>
                                                    <Checkbox
                                                        className={'p0 m0'}
                                                        size={'small'}
                                                        style={{ color: materialTheme.palette.primary.main }}
                                                        checked={this.state.showFilled}
                                                        onChange={(event, checked) => this.handleShowFilledCheckboxChecked(checked)}
                                                    />
                                                </div>
                                            </div>
                                            <div className={'fdr flx1 pt10'}>
                                                <div className={'flx1'}/>
                                                <Button
                                                    className={'fwb h35'}
                                                    variant='text'
                                                    color='primary'
                                                    type={'reset'}
                                                    onClick={this.handleClose}>
                                                    Cancel
                                                </Button>
                                                <PillButton
                                                    className={'ml15 pl20 pr20 h35'}
                                                    text={'Apply'}
                                                    color={'secondary'}
                                                    type={'submit'}
                                                />
                                            </div>
                                        </FormControl>
                                    </form>
                                </div>
                            </ClickAwayListener>
                        </div>
                    </Grow>
                )}
            </Popper>
        </>;
    };
}

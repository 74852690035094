import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IOverviewRelatedData } from '../../../@types/model/overview/overview';
import qs from 'qs';

export default class OverviewRelatedDataHttpService {
    public static getAllOverviewRelatedData(fromDateUnix ?: number, toDateUnix ?: number, siteIds ?: Array<number>, showFinalized ?: boolean, showEnded ?: boolean, loadMasterData ?: boolean) : AxiosPromise<IOverviewRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Data/OverviewRelatedData`, {
            params: {
                fromDateUnix,
                toDateUnix,
                siteIds,
                showFinalized,
                showEnded,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }
}

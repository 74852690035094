import React from 'react';
import { asField, FieldContext, FormValue, FieldProps, FormValues } from 'informed';
import { FormControl, FormHelperText, MenuList, Paper, TextField, InputAdornment, Icon } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { IDropDownOptions } from '../../@types/other';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import Autosuggest from 'react-autosuggest';

const autoSuggestTheme = {
    container: {
        position: 'relative',
    },
    containerOpen: {},
    suggestionsContainer: { display : 'none' },
    suggestionsContainerOpen: {
        display: 'block',
        position: 'absolute',
        width: '100%',
        // backgroundColor: '#fff',
        fontWeight: 300,
        fontSize: '16px',
        borderRadius: 5,
        zIndex: 1001,
        boxSizing: 'border-box',
        border: '1px solid grey',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    suggestion: {
        cursor: 'pointer',
    },
    suggestionFirst: {},
    // suggestionHighlighted: {
    //     backgroundColor: '#ddd',
    // },
    sectionContainer: {},
    sectionContainerFirst: {},
    sectionTitle: {},
} as Autosuggest.Theme;

interface ICustomAutoSuggestProps extends FieldProps<FormValue, FormValues> {
    onChange ?: (value : any) => void;
    onBlur ?: (event : React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    id ?: any;
    ref ?: any;
    disabled ?: boolean;
    label ?: string;
    margin ?: 'none' | 'dense' | 'normal' | undefined;
    field : string;
    className ?: string;
    hasInitialValue ?: boolean; // FIXME: this.props.initialValue === undefined?? hence this hackfix
    required ?: boolean;
    forwardedRef ?: any;
    style ?: React.CSSProperties;
    options : Array<IDropDownOptions>;
    updateValue ?: any;
    clearUpdateValue ?: () => void;
    placeholder ?: string;
    tabIndex ?: number;
    dontUpdateOnBlur ?: boolean;
    clearValue ?: boolean;
    resetClearValue ?: () => void;
    enableDropDownIcon ?: boolean;
}

interface ICustomAutoSuggestState {
    label : string;
    suggestions : Array<IDropDownOptions>;
    initialValueWasSet : boolean;
    selectedSuggestion ?: IDropDownOptions;
    valueCleared : boolean;
}

const escapeRegexCharacters = (str : string) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

const getSuggestionValue = (suggestion : IDropDownOptions) => {
    return suggestion.value;
};

class CustomAutoSuggest extends React.Component<ICustomAutoSuggestProps & FieldContext<FormValue>, ICustomAutoSuggestState> {
    private innerRef : Autosuggest<IDropDownOptions>;
    constructor(props : ICustomAutoSuggestProps & FieldContext<FormValue>) {
        super(props);

        this.state = {
            label: '',
            suggestions: [],
            initialValueWasSet: false,
            valueCleared: false,
        };
    }

    public setValue = (newValue : any) => {
        const option = this.props.options.find(x => !x.disabled && x.value === newValue);
        if (option) {
            this.setState({ label: option && option.label ? option.label : '', selectedSuggestion: option }, () => this.props.fieldApi.setValue(newValue));
        } else {
            this.setState({ label: '', selectedSuggestion: undefined }, () => this.props.fieldApi.setValue(''));
        }
    };

    private onChange = (event : any, { newValue, method } : any) => {
        if (method === 'down' || method === 'up' || method === 'enter' || method === 'click') {
            const option = this.props.options.find(x => !x.disabled && x.value === newValue);
            if (option) {
                this.setState({ label: option && option.label ? option.label : '', selectedSuggestion: option }, () => this.props.fieldApi.setValue(newValue));
            } else {
                this.setState({ label: '', selectedSuggestion: undefined }, () => this.props.fieldApi.setValue(''));
            }
        } else {
            if (!newValue && this.innerRef) {
                // @ts-ignore
                this.innerRef.resetHighlightedSuggestion();
            }
            this.setState({ label: newValue, selectedSuggestion: undefined }, () => this.props.fieldApi.setValue(''));
        }
        if (this.props.onChange) {
            this.props.onChange(newValue);
        }
    };

    private renderSuggestion = (suggestion : IDropDownOptions, { query, isHighlighted } : { query : string; isHighlighted : boolean}) => {
        const matches = match(suggestion.label, query);
        const parts = parse(suggestion.label, matches);

        return (
            <MenuItem selected={isHighlighted} component='div'>
                <div>
                    {parts.map((part, index) =>
                        part.highlight ? (
                            <span key={String(index)} style={{ fontWeight: 500 }}>
                                {part.text}
                            </span>
                        ) : (
                            <strong key={String(index)} style={{ fontWeight: 300 }}>
                                {part.text}
                            </strong>
                        ),
                    )}
                </div>
            </MenuItem>
        );
    };

    private getSuggestions = (value : string) => {
        const escapedValue = value && typeof value === 'string' ? escapeRegexCharacters(value.trim()) : '';
        const options = [...this.props.options];

        if (escapedValue === '') {
            return options.filter(option => !option.disabled);
        }

        const regex = new RegExp(escapedValue, 'i');

        return options.filter(option => !option.disabled && regex.test(option.label));
    };

    private onSuggestionsFetchRequested = ({ value } : { value : string }) => {
        this.setState({
            suggestions: this.getSuggestions(value),
        });
    };

    private onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    private renderInputComponent = (inputProps : any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { classes, inputRef = () => null, ref, ...other } = inputProps;
        return (
            <TextField
                fullWidth
                id={this.props.id ? this.props.id + '_input' : undefined}
                label={this.props.label}
                autoFocus={this.props.autoFocus}
                className={'curp posr zi0'}
                variant={'standard'}
                InputProps={{
                    placeholder: !this.props.label && inputProps.placeholder,
                    ...other,
                }}
                inputRef={this.props.forwardedRef}
                {...other}
            />
        );
    };

    public componentDidMount = () => {
        if (this.props.value && this.props.hasInitialValue) {
            const option = this.props.options.find(x => !x.disabled && x.value === this.props.value);
            if (option && option.label.toLowerCase() !== this.state.label.toLowerCase()) {
                this.setState({ initialValueWasSet: true }, () => this.setValue(option.value));
            }
        }
    };

    public componentDidUpdate = (prevProps : ICustomAutoSuggestProps & FieldContext<FormValue>) => {
        const nextProps = this.props;
        if (nextProps && nextProps.value && nextProps.hasInitialValue) {
            const option = nextProps.options.find(x => !x.disabled && x.value === nextProps.value);
            if (!this.state.valueCleared) {
                if (option && option.label.toLowerCase() !== this.state.label.toLowerCase()) {
                    this.setState({ initialValueWasSet: true, valueCleared: false }, () => this.setValue(option.value));
                }
            }
        }
        if (prevProps && nextProps) {
            if ((prevProps.options && prevProps.options.length > 0) && (nextProps.options && nextProps.options.length === 0)) {
                this.setState({ label: '', selectedSuggestion: undefined }, () => nextProps.fieldApi.setValue(''));
            }
            if (nextProps.updateValue && prevProps.updateValue !== nextProps.updateValue) {
                this.setValue(nextProps.updateValue);
            }
            if (nextProps.options && prevProps.options && nextProps.options.length > prevProps.options.length && nextProps.updateValue) {
                this.setValue(nextProps.updateValue);
                if (nextProps.clearUpdateValue) {
                    nextProps.clearUpdateValue();
                }
            }
            if (nextProps.clearValue === true && !prevProps.clearValue) {
                this.setValue(undefined);
                if (nextProps.resetClearValue) {
                    nextProps.resetClearValue();
                }
            }
        }
    };

    private onSuggestionHighlighted = ({ suggestion } : { suggestion : IDropDownOptions}) => {
        const scrollArea = document.getElementById(`suggestion_scrollArea_${this.props.id}`);
        if (suggestion && scrollArea) {
            const index = this.state.suggestions.findIndex(x => x.value === suggestion.value);
            if (index !== -1) {
                const suggestionElement = document.getElementById(`react-autowhatever-${this.props.id}--item-${index}`);
                if (suggestionElement) {
                    const itemOffsetRelativeToContainer = suggestionElement.offsetParent === scrollArea ? suggestionElement.offsetTop : suggestionElement.offsetTop - scrollArea.offsetTop;
                    let scrollTop = scrollArea.scrollTop;
                    if (itemOffsetRelativeToContainer < scrollTop) {
                        scrollTop = itemOffsetRelativeToContainer;
                    } else if (itemOffsetRelativeToContainer + suggestionElement.offsetHeight > scrollTop + scrollArea.offsetHeight) {
                        scrollTop = itemOffsetRelativeToContainer + suggestionElement.offsetHeight - scrollArea.offsetHeight;
                    }

                    if (scrollTop !== scrollArea.scrollTop) {
                        scrollArea.scrollTop = scrollTop;
                    }
                }
            }
        }
    };
    private setRef = (ref : Autosuggest<IDropDownOptions>) => {
        this.innerRef = ref;
    };

    public render() {
        const { onBlur,
            id, margin, className, required, fieldState, fieldApi, disabled } = this.props;
        const { error } = fieldState;
        const { setValue, setTouched } = fieldApi;

        const inputProps = {
            placeholder: this.props.placeholder,
            value: this.state.label,
            onChange: this.onChange,
            disabled,
            error: (!!required && !!!fieldApi.getValue()) || !!error,
            onBlur: (event : any, { highlightedSuggestion } : {highlightedSuggestion : IDropDownOptions}) => {
                setTouched(true);
                if (onBlur) {
                    onBlur(event);
                }
                if (!this.props.dontUpdateOnBlur) {
                    if (highlightedSuggestion) {
                        this.setState({
                            label: highlightedSuggestion.label,
                        });
                        setValue(highlightedSuggestion.value);
                    } else if (this.state.selectedSuggestion) {
                        this.setState({
                            label: this.state.selectedSuggestion.label,
                        });
                        setValue(this.state.selectedSuggestion.value);
                    } else {
                        this.setState({
                            label: '',
                        });
                        setValue('');
                    }
                }
            },
            endAdornment: !disabled && <InputAdornment position='end'>
                { this.state.label !== '' &&
                    <Icon aria-label='clear' onClick={() => {
                        this.props.fieldApi.setValue('');
                        this.setState({ label: '', selectedSuggestion: undefined, valueCleared: true });
                        if (this.props.onChange) {
                            this.props.onChange('');
                        }
                    }}>
                        clear
                    </Icon>
                }
                { this.props.enableDropDownIcon &&
                    <Icon>
                        arrow_drop_down
                    </Icon>
                }
            </InputAdornment>,
        };

        return (
            <>
                <FormControl margin={margin} className={className} required={required}>
                    <Autosuggest
                        id={id}
                        suggestions={this.state.suggestions}
                        ref={this.setRef}
                        highlightFirstSuggestion={!!this.state.label}
                        renderInputComponent={this.renderInputComponent}
                        focusInputOnSuggestionClick={false}
                        shouldRenderSuggestions={() => true}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSuggestionValue={getSuggestionValue}
                        onSuggestionHighlighted={this.onSuggestionHighlighted}
                        renderSuggestion={this.renderSuggestion}
                        renderSuggestionsContainer={({ children, containerProps }) => {
                            return <div className={'posa zi999'} id={id && id + '_dropdown'} ref={containerProps.ref}><Paper className={'wfill'}>
                                <MenuList className={'oys mxh300 mnw200 posr wfill'} id={`suggestion_scrollArea_${id}`} key={containerProps.key}
                                    style={containerProps.style}>
                                    {children}
                                </MenuList>
                            </Paper></div>;
                        }}
                        inputProps={inputProps}
                        theme={autoSuggestTheme} />
                    {
                        fieldState.error ? (
                            <FormHelperText error>{fieldState.error}</FormHelperText>
                        ) : null
                    }

                </FormControl>
            </>
        );
    }
}

export default asField<ICustomAutoSuggestProps>(CustomAutoSuggest);

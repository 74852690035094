import * as React from 'react';
import { DispatchCall, IAuthState, IRootState, RootAction } from '../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Screen from '../../components/Screen';
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, Icon, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { CustomChangeEvent } from '../../@types/helper';
import ReactPlayer from 'react-player';
import { createSelector } from 'reselect';
import { Faq, IFaq } from '../../@types/model/frequentlyAskedQuestions/faq';
import PillButton from '../../components/input/PillButton';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import { Formik, FormikActions } from 'formik';
import { FaqFormValues, IFaqFormValues } from '../../@types/model/frequentlyAskedQuestions/faqFormValues';
import FrequentlyAskedQuestionsForm from './form/FrequentlyAskedQuestionsForm';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import FrequentlyAskedQuestionsHttpService from '../../services/http/frequentlyAskedQuestions/frequentlyAskedQuestionsHttpService';
import { addArrayElement, compareBool, removeArrayElement, upsertArrayElement } from '../../services/appFunctionsService';
import { dataSetFAQs } from '../../store/data/Actions';
import { IRight } from '../../@types/model/user/right';
import { RouteComponentProps } from 'react-router';
import GoogleCloudStorageHttpService from '../../services/googleCloudStorageService';
import { IGoogleCloudStorage } from '../../@types/model/googleCloudStorage/googleCloudStorage';
import lodash from 'lodash';
import posed from 'react-pose';
import ConfirmationPrompt from '../../components/dialog/ConfirmationPrompt';
import FileSelector from '../../components/input/FileSelector';
import FloatingActionButton from '../../components/input/FloatingActionButton';
import ReactMarkdown from 'react-markdown';
import { dataSetFaq } from '../../store/data/Functions';
import FileSaver from 'file-saver';
import CustomTooltip from '../../components/tooltip/tooltip';
import materialTheme from '../../styles/materialTheme';

const path = 'TrainingContent/';

const StatusColumnDiv = posed.div(
    {
        expanded : { maxWidth: 400 },
        collapsed : { maxWidth: 40 },
    },
);

interface IFAQScreenProps extends RouteComponentProps {
    auth : IAuthState;
    dataSetFAQs : DispatchCall<Array<IFaq>>;
    faqData : Array<IFaq>;
}

interface IFAQScreenState {
    isLoading : boolean;
    searchText : string;
    searchManualsText : string;
    isExpanded : boolean;
    faqId ?: number;
    isDialogOpen : boolean;
    selectedFAQ ?: IFaq;
    openManualUploadDialog : boolean;
    selectedFiles : Array<File>;
    manualDocsList ?: Array<any>;
    collapseStatusColumn : boolean;
    isDeleteManualConfirmationPromptOpen : boolean;
    isFaqStarredConfirmationPromptOpen : boolean;
    faqToStar ?: IFaq;
    manualName ?: string;
    refresh : boolean;
}

class FAQScreen extends React.Component<IFAQScreenProps, IFAQScreenState> {
    constructor(props : IFAQScreenProps) {
        super(props);

        this.state = {
            isLoading: false,
            searchText: '',
            searchManualsText: '',
            isExpanded: false,
            isDialogOpen: false,
            openManualUploadDialog: false,
            collapseStatusColumn: false,
            isDeleteManualConfirmationPromptOpen: false,
            isFaqStarredConfirmationPromptOpen: false,
            refresh: false,
            selectedFiles: [],
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        try {
            const res = await FrequentlyAskedQuestionsHttpService.getFaqData();
            const manualList = await GoogleCloudStorageHttpService.getListData(path);

            if (manualList) {
                this.setState({ manualDocsList: manualList.data });
            }

            this.props.dataSetFAQs(res.data);
            this.setLoading(false);
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading frequently asked questions data.');
            this.setLoading(false);
        }
    };

    private refreshManualsList = async () => {
        try {
            const newManualList = await GoogleCloudStorageHttpService.getListData(path);
            this.setState({ manualDocsList: newManualList.data });
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading manuals.');
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private onSearchChange = (event : CustomChangeEvent) => {
        this.setState({ searchText: event.currentTarget.value });
    };

    private onSearchManualsTextChange = (event : CustomChangeEvent) => {
        this.setState({ searchManualsText: event.currentTarget.value });
    };

    private toggleExpand = (id : number) => {
        if (id === this.state.faqId) {
            this.setState({ isExpanded: !this.state.isExpanded }, () => {
                if (this.state.isExpanded) {
                    this.setState({ faqId: id });
                } else {
                    this.setState({ faqId: undefined });
                }
            });
        } else {
            this.setState({ faqId: undefined, isExpanded: false }, () => {
                this.setState({ isExpanded: !this.state.isExpanded }, () => {
                    if (this.state.isExpanded) {
                        this.setState({ faqId: id });
                    } else {
                        this.setState({ faqId: undefined });
                    }
                });
            });
        }
    };

    private getFAQAccordions = (faq : IFaq) => {
        return (
            <Accordion
                key={`faq_#${faq.id}`}
                className={'fdc wfill mb10'}
                expanded={faq.id === this.state.faqId}
                onChange={() => this.toggleExpand(faq.id)}
                style={faq.isActive ? {} : { backgroundColor: 'transparent' }}
            >
                <Tooltip disableInteractive title={`${faq.isActive ? '' : 'This FAQ is inactive'}`}>
                    <AccordionSummary
                        className={`${faq.isActive ? '' : 'op70p'} flx1`}
                        expandIcon={<Icon className={'cw'}>expand_more</Icon>}
                    >
                        <div className={'fdr aic'}>
                            {this.hasEditingRight(this.props) &&
                                    <IconButton disabled={!faq.isActive} size={'medium'} onClick={e => this.starFaq(e, faq)}>
                                        <Icon className={'cw'}>{faq.isStarred ? 'star' : 'star_border'}</Icon>
                                    </IconButton>
                            }
                            {this.hasEditingRight(this.props) &&
                                    <IconButton size={'medium'} onClick={e => this.editFAQ(e, faq)}>
                                        <Icon className={'cw'}>edit</Icon>
                                    </IconButton>
                            }
                            <div className={'flx1 fw500'}>
                                {faq.title}
                            </div>
                        </div>
                    </AccordionSummary>
                </Tooltip>
                <AccordionDetails className={'fdc hfill wfill'}>
                    <ReactMarkdown className={'mb20 wfill'} children={faq.description} ></ReactMarkdown>
                    <div className={`${(!faq.url || faq.url === '') ? 'dn' : ''}`}>
                        {
                            this.getVideo(faq)
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    };

    private getVideo = (faq : IFaq) => {
        if (faq.isIFrame) {
            return (
                <div className='bcg1 PaperBorder p10 hfill wfill oln'>
                    <iframe src={faq.url} width='100%' height='480'></iframe>
                </div>
            );
        } else {
            return (
                <div className='bcg1 PaperBorder p10 hfill wfill oln'>
                    <ReactPlayer width={'100%'} url={faq.url} controls={true}/>
                </div>
            );
        }
    };

    private getRights = (props : IFAQScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IFAQScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    private getFaqData = (props : IFAQScreenProps) => props.faqData;
    private getSearchText = (props : IFAQScreenProps, state : IFAQScreenState) => state.searchText;

    private getFilteredFAQs = createSelector(
        [this.getFaqData, this.getSearchText],
        (faqData : Array<IFaq>, searchText : string) => {
            if (!faqData) return [];

            return faqData.filter(x =>
                (x.title.toLowerCase().includes(searchText.toLowerCase()) || x.description.toLowerCase().includes(searchText.toLowerCase()))
                && (this.hasEditingRight(this.props) ? true : x.isActive))
                .sort((a : IFaq, b : IFaq) => compareBool(b.isStarred, a.isStarred));
        },
    );

    private addFAQ = () => {
        this.setState({ isDialogOpen: true });
    };

    private editFAQ = (event : React.MouseEvent<HTMLElement>, selectedFAQ : IFaq) => {
        event.stopPropagation();
        this.setState({ isDialogOpen: true, selectedFAQ });
    };

    private starFaq = (event : React.MouseEvent<HTMLElement>, selectedFaq : IFaq) => {
        event.stopPropagation();
        this.setState({ isFaqStarredConfirmationPromptOpen: true, faqToStar: selectedFaq });
    };

    public onEditDialogClose = () => {
        this.setState({
            isDialogOpen: false,
            selectedFAQ: undefined,
        });
    };

    public onSubmit = async (values : IFaqFormValues) => {
        this.setLoading(true);

        try {
            const res = await FrequentlyAskedQuestionsHttpService.addOrUpdateFaq(new Faq(values));

            const newFaqList = upsertArrayElement(this.props.faqData, res.data, x => x.id === res.data.id, 'end') ?? [];
            this.props.dataSetFAQs(newFaqList);

            if (this.state.selectedFAQ) {
                generalShowSuccessSnackbar('Frequently asked question updated successfully.');
            } else {
                generalShowSuccessSnackbar('Frequently asked question added successfully.');
            }

            this.onEditDialogClose();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating frequently asked question data.');
            this.onEditDialogClose();
        } finally {
            this.setLoading(false);
        }
    };

    public onReset = async (formValues : IFaqFormValues, formikActions : FormikActions<IFaqFormValues>) => {
        formikActions.resetForm();
        this.onEditDialogClose();
    };

    public getSelectedFAQ = (props : IFAQScreenProps, state : IFAQScreenState) => state.selectedFAQ;

    public getInitialFormValues = createSelector(
        [this.getSelectedFAQ],
        (faq : IFaq) => {
            return new FaqFormValues(faq);
        },
    );

    private openManualUploadDialog = () => {
        this.setState({ openManualUploadDialog: true });
    };

    private closeManualUploadDialog = () => {
        this.setState({ openManualUploadDialog: false, selectedFiles: [] }, () => {
            this.refreshManualsList();
        });
    };

    private handleFileInputChange = (files : Array<File>) => {
        files.forEach(x => this.setState({ selectedFiles: addArrayElement(this.state.selectedFiles, x, 'end') }));
    };

    private onFileUpload = async () => {
        const files = this.state.selectedFiles;
        const data : Array<IGoogleCloudStorage> = [];
        if (files.length > 0) {
            this.setLoading(true);
            files.forEach((file) => {
                const reader = new FileReader();
                reader.onload = async () => {
                    const fileStr = reader.result;
                    if (typeof fileStr === 'string') {
                        const fileString = fileStr.substr(5);
                        const contentType = fileString.split(';')[0];
                        const startFromPos = fileString.split(',')[0].length;
                        const fileBase64 = fileString.substr((startFromPos + 1));

                        const fileToBeUploaded : IGoogleCloudStorage = {
                            fileName: file?.name,
                            destinationPath: 'TrainingContent/',
                            contentType,
                            fileBase64,
                        };

                        data.push(fileToBeUploaded);

                        if (data.length === files.length) {
                            try {
                                await GoogleCloudStorageHttpService.uploadFile(data);
                                this.closeManualUploadDialog();
                                generalShowSuccessSnackbar('Files uploaded successfully.');
                                this.setLoading(false);
                            } catch (e) {
                                this.closeManualUploadDialog();
                                generalShowErrorSnackbar('Error occured while uploading files.');
                                this.setLoading(false);
                            }
                        }
                    }
                };
                reader.readAsDataURL(file);
            });
        }
    };

    private downloadManual = async (fileName : string, contentType : string) => {
        try {
            this.setLoading(true);
            const res = await GoogleCloudStorageHttpService.downloadFile(path, fileName);

            if (res) {

                const byteCharacters = atob(res.data);
                const byteNumbers = lodash.map(byteCharacters, (x, i) => byteCharacters.charCodeAt(i));
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: contentType });
                if (blob) {
                    FileSaver.saveAs(blob, fileName);
                    generalShowSuccessSnackbar('File downloaded successfully.');
                    this.setLoading(false);
                }
            }
        } catch (e) {
            generalShowErrorSnackbar('Error while downloading file.');
            this.setLoading(false);
        }
    };

    private getManual = (manual : any) => {
        const manualName : string = manual.name;
        const name = manualName.split('/')[1];
        return (
            <Card key={`manual_${name}`} className={'fdr h50 w350 m5 bcp'}>
                <CustomTooltip title={'Download Manual'}>
                    <IconButton size={'small'} onClick={() => this.downloadManual(name, manual.contentType)}>
                        <Icon className={'cw'}>get_app</Icon>
                    </IconButton>
                </CustomTooltip>
                {this.hasEditingRight(this.props) &&
                    <CustomTooltip title={'Delete Manual'}>
                        <IconButton size={'small'} onClick={() => this.toggleDeleteConfirmationPrompt(name)}>
                            <Icon className={'cw'}>delete</Icon>
                        </IconButton>
                    </CustomTooltip>
                }
                <div className={'flx1 aic ml3 cw'}>{name}</div>
            </Card>
        );
    };

    private toggleColumnExpand = () => {
        this.setState({ collapseStatusColumn: !this.state.collapseStatusColumn });
    };

    private getManualDocList = (props : IFAQScreenProps, state : IFAQScreenState) => state.manualDocsList;
    private getSearchManualsText = (props : IFAQScreenProps, state : IFAQScreenState) => state.searchManualsText;

    private getManualsList = createSelector(
        [this.getManualDocList, this.getSearchManualsText],
        (manuals : Array<any>, searchManualsText : string) => {
            if (!manuals) return [];

            return manuals.filter(x => x.name !== path && (x.name.toLowerCase().includes(searchManualsText.toLowerCase())));
        },
    );

    private toggleDeleteConfirmationPrompt = (fileName : string) => {
        this.setState({
            isDeleteManualConfirmationPromptOpen: !this.state.isDeleteManualConfirmationPromptOpen,
            manualName: fileName,
        });
    };

    private onDeleteManualConfirm = async () => {
        const fileName = this.state.manualName;
        if (fileName) {
            this.setLoading(true);
            try {
                await GoogleCloudStorageHttpService.deleteFile(path, fileName);
                const manualsList = await GoogleCloudStorageHttpService.getListData(path);
                if (manualsList) {
                    this.setState({ manualDocsList: manualsList.data });
                }
                this.onDeleteManualCancel();
                generalShowSuccessSnackbar('File deleted successfully.');
                this.setLoading(false);
            } catch (e) {
                this.onDeleteManualCancel();
                generalShowErrorSnackbar('Error while deleting file.');
                this.setLoading(false);
            }
        }
    };

    private onDeleteManualCancel = () => {
        this.setState({
            isDeleteManualConfirmationPromptOpen: !this.state.isDeleteManualConfirmationPromptOpen,
            manualName: undefined,
        });
    };

    private onStarFaqConfirm = async () => {
        if (!!this.state.faqToStar) {
            const updatedFaq : IFaq = { ...this.state.faqToStar };

            if (!this.state.faqToStar.isStarred) {
                updatedFaq.isStarred = true;
            } else {
                updatedFaq.isStarred = false;
            }

            this.setLoading(true);
            try {
                const res = await FrequentlyAskedQuestionsHttpService.addOrUpdateFaq(updatedFaq);

                if (res && res.data) {
                    dataSetFaq(res.data);
                    generalShowSuccessSnackbar('FAQ successfully starred.');
                }
            } catch (e) {
                generalShowErrorSnackbar('Error while attempting to star selected FAQ.');
            } finally {
                this.onStarFaqCancel();
                this.setLoading(false);
            }
        }
    };

    private onStarFaqCancel = () => {
        this.setState({ isFaqStarredConfirmationPromptOpen: !this.state.isFaqStarredConfirmationPromptOpen, faqToStar: undefined });
    };

    private onFileRemove = (file : File) => {
        const index = this.state.selectedFiles?.findIndex(x => x.name === file.name);
        if (index > -1) {
            this.setState({ selectedFiles: removeArrayElement(this.state.selectedFiles, index) });
        }
    };

    public render() {
        const faqData = this.getFilteredFAQs(this.props, this.state);
        const manuals = this.getManualsList(this.props, this.state);
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isPadded={false} isLoading={this.state.isLoading} isScrollable={false}>
                <div className={'fdr jcsb'} style={{ height: 'calc(85vh)' }}>
                    <div className={'hfill mt20 ml20'}>
                        <StatusColumnDiv
                            style={{ backgroundColor: materialTheme.custom.panel.background }}
                            pose={!this.state.collapseStatusColumn ? 'collapsed' : 'expanded'}
                            elevation={3}
                            className={'fdc flx1 hfill m5 bw1 bocpl'}
                            key={'Manuals'}
                        >
                            <IconButton style={{ height: 40, width: 40, paddingTop: 8 }} onClick={this.toggleColumnExpand} className={!this.state.collapseStatusColumn ? '' : 'dn'}><Icon>chevron_right</Icon></IconButton>
                            <div className={!this.state.collapseStatusColumn ? 'flx1' : 'dn'}/>
                            <Typography className={`pl10 pr10 aic fdr ${!this.state.collapseStatusColumn ? 'VerticalText' : 'bcp cw'}`} variant={'subtitle1'}>
                                {'Manuals'}
                                <div className={'flx1'}/>
                                <IconButton
                                    className={!this.state.collapseStatusColumn ? 'dn' : 'cw'}
                                    onClick={this.toggleColumnExpand}
                                >
                                    <Icon className={'cw'}>chevron_left</Icon>
                                </IconButton>
                            </Typography>
                            <div className={!this.state.collapseStatusColumn ? 'flx1' : 'dn'}/>
                            <div className={'p10 fdc aic'}>
                                {this.state.collapseStatusColumn  &&
                                    <TextField
                                        className={'w250 m5'}
                                        label={'Search'}
                                        placeholder={'Search...'}
                                        onChange={this.onSearchManualsTextChange}
                                        value={this.state.searchManualsText}
                                    />
                                }
                                <div style={{ maxHeight: 600 }} className={'oya'}>
                                    {this.state.collapseStatusColumn  &&
                                        manuals?.map(x => this.getManual(x))
                                    }
                                </div>
                                <div className={'flx1'} />
                                <div className={`${(this.state.collapseStatusColumn && this.hasEditingRight(this.props)) ? 'fdr p10 wfill' : 'dn'}`}>
                                    <div className={'flx1'} />
                                    <CustomTooltip title={'Add Manual'}>
                                        <FloatingActionButton
                                            color={'secondary'}
                                            className={'cpd'}
                                            size={'medium'}
                                            onClick={this.openManualUploadDialog}
                                            disabled={this.state?.isLoading}
                                        />
                                    </CustomTooltip>
                                </div>
                            </div>
                        </StatusColumnDiv>
                    </div>
                    <div className={'fdr flx1'}>
                        <div className={'hfill wfill fdc aic oya'}>
                            <TextField
                                className={'w400 m5'}
                                label={'Search'}
                                placeholder={'Search...'}
                                onChange={this.onSearchChange}
                                value={this.state.searchText}
                            />
                            <div className={'p20 w800'}>
                                {faqData.map(x => this.getFAQAccordions(x))}
                            </div>
                        </div>
                    </div>
                    <div className={`${this.state.collapseStatusColumn ? 'w250' : ''}`}>
                        {this.hasEditingRight(this.props) &&
                            <div className={'fdr posa posr70 posb50 zi1'}>
                                <PillButton
                                    text={'Add FAQ'}
                                    color={'secondary'}
                                    className={'flx1 cpd fw500'}
                                    onClick={() => this.addFAQ()}
                                />
                            </div>
                        }
                    </div>
                    {/* Edit Dialog */}
                    <PackmanDialog
                        title={'FAQ'}
                        maxWidth={'md'}
                        isEdit={!!this.state.selectedFAQ}
                        isLoading={this.state.isLoading}
                        isOpen={this.state.isDialogOpen}
                        onClose={this.onEditDialogClose}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={this.onSubmit}
                            onReset={this.onReset}
                            enableReinitialize
                            validationSchema={FaqFormValues.formSchema}
                            component={FrequentlyAskedQuestionsForm}/>
                    </PackmanDialog >
                    {/* Manual Document Upload Dialog */}
                    <PackmanDialog
                        title={'Upload Manual(s)'}
                        isInfo
                        isLoading={this.state.isLoading}
                        isOpen={this.state.openManualUploadDialog}
                        onClose={this.closeManualUploadDialog}>
                        <Screen isPadded={false} isScrollable={false}>
                            <div style={{ height: 500 }} className={'w400 aic fdc p20'}>
                                <div style={{ height: 400 }} className={'wfill'}>
                                    <FileSelector
                                        accept={'image/*,application/pdf'}
                                        files={this.state.selectedFiles ? this.state.selectedFiles : []}
                                        disabled={this.state.isLoading}
                                        onFileSelected={this.handleFileInputChange}
                                        onFileRemoved={this.onFileRemove}
                                    />
                                </div>
                                <div className={'flx1'}/>
                                <div className={'fdr pt10 pb10'}>
                                    <Button
                                        className={'fwb h35'}
                                        variant='text'
                                        color='primary'
                                        onClick={this.closeManualUploadDialog}>
                                            Cancel
                                    </Button>
                                    <PillButton
                                        disabled={this.state.selectedFiles?.length === 0}
                                        className={'ml15 pl20 pr20 h35'}
                                        text={'Upload File'}
                                        color={'secondary'}
                                        onClick={this.onFileUpload}
                                    />
                                </div>
                            </div>
                        </Screen>
                    </PackmanDialog>
                    <ConfirmationPrompt
                        title={'Delete Manual'}
                        open={this.state.isDeleteManualConfirmationPromptOpen}
                        message={'Are you sure you want to delete this manual?' }
                        onOkClicked={this.onDeleteManualConfirm} onCancelClicked={this.onDeleteManualCancel}/>
                    <ConfirmationPrompt
                        title={(!!this.state.faqToStar && !this.state.faqToStar.isStarred) ? 'Star FAQ' : 'Unstar Faq'}
                        open={this.state.isFaqStarredConfirmationPromptOpen}
                        message={(!!this.state.faqToStar && !this.state.faqToStar.isStarred) ? 'Are you sure you want to star this FAQ?' : 'Are you sure you want to unstar this FAQ?'}
                        onOkClicked={this.onStarFaqConfirm} onCancelClicked={this.onStarFaqCancel}/>
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        faqData: state.data.frequentlyAskedQuestions,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetFAQs }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FAQScreen);

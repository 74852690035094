import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IMarket } from '../../../@types/model/masterData/market/market';

export default class MarketHttpService {
    public static getMarketData() : AxiosPromise<Array<IMarket>> {
        return axios.get(`${PACKMAN_API_URL}Market/GetAllWeb`);
    }

    public static addOrUpdateMarket(data : IMarket) : AxiosPromise<IMarket> {
        return axios.post(`${PACKMAN_API_URL}Market/PostMarket`, data);
    }

    public static deleteMarket(marketId : number) : AxiosPromise<IMarket> {
        return axios.delete(`${PACKMAN_API_URL}Market/Delete`, {
            params : { marketId },
        });
    }
}

import lodash from 'lodash';
import { dispatch, getState } from '../../store/Index';
import { generalShowErrorSnackbar } from '../../store/general/Functions';
import axios from 'axios';
import { PACKMAN_API_URL } from '../../appConstants';
import { dataSetRights } from '../../store/data/Actions';
import { IRight } from '../../@types/model/user/right';
import { IMappedRight } from '../../@types/model/user/mappedRight';
import { setArrayElement, addArrayElement } from '../appFunctionsService';

const RIGHT_API_PATH = `${PACKMAN_API_URL}Rights/Right/`;

export async function getList(name ?: string, code ?: string, isActive ?: boolean) : Promise<Array<IRight>> {
    try {
        const result = await axios.get<Array<IRight>>(`${RIGHT_API_PATH}GetList`, {
            params : {
                name,
                code,
                isActive,
            },
        });

        dispatch(dataSetRights(result.data));

        return result.data;
    } catch (ex) {
        if (!!ex && ex.status === 400) {
            generalShowErrorSnackbar(ex.data);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403) {
            generalShowErrorSnackbar('Error getting rights');
        }
        return [];
    }
}

export async function addOrUpdateRights(data : IRight) : Promise<IRight | null> {
    try {
        const state = getState();

        const result = await axios.post<IRight>(`${PACKMAN_API_URL}Rights/Right/PostRight`, data);

        const position = state.data.rights.findIndex(x => x.id === result.data.id);
        if (position !== -1) {
            dispatch(dataSetRights(setArrayElement(state.data.rights, position, result.data)));
        } else {
            dispatch(dataSetRights(addArrayElement(state.data.rights, result.data)));
        }

        return result.data;
    } catch (ex) {
        if (!!ex && ex.status === 400) {
            generalShowErrorSnackbar(ex.data);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403) {
            generalShowErrorSnackbar('An error occured while saving the right.');
        }
        return null;
    }
}

export function mapRightsToMappedRights(rights : Array<IRight>) : Array<IMappedRight> {
    const result : Array<IMappedRight> = [];

    rights.forEach((n) => {
        if (!!!n.parentId) {
            result.push({
                id: n.id,
                code: n.code,
                name: n.name,
                url: n.url,
                isMobile: n.isMobile,
                isMenu: n.isActive, // TODO: confirm this?
                children: mapRightChildrenToMappedRights(rights, n.id),
            });
        }
    });

    return result;
}

function mapRightChildrenToMappedRights(rights : Array<IRight>, parentId ?: number) : Array<IMappedRight> {
    const result : Array<IMappedRight> = [];

    const children = lodash.filter(rights, n => n.parentId === parentId);

    children.forEach((n) => {
        result.push({
            id: n.id,
            code: n.code,
            name: n.name,
            url: n.url,
            isMobile: n.isMobile,
            isMenu: n.isActive, // TODO: confirm this?
            children: mapRightChildrenToMappedRights(rights, n.id),
        });
    });

    return result;
}

export function hasMenuRight(url : string) : boolean {
    const state = getState();

    if (!state.auth.session) return false;

    if (!state.auth.session.user.rights || !state.auth.session.user.rights.length) return false;

    const { rights } = state.auth.session.user;
    return rights.some(n => n.url === url);
}

import * as React from 'react';
import { FormHelperText, PropTypes, Icon, Typography, FormControl } from '@mui/material';
import { asField, FieldContext, FieldProps, FormValues } from 'informed';
import ToggleButton from '@mui/material/ToggleButton';

interface ICustomToggleButtonProps extends FieldProps<boolean, FormValues> {
    onChange ?: (event : React.FormEvent<HTMLAnchorElement | HTMLInputElement | HTMLButtonElement> | undefined, selected ?: boolean) => void;
    onBlur ?: (event : React.FocusEvent<HTMLElement>) => void;
    initialValue : any;
    id ?: any;
    disabled ?: boolean;
    type ?: any;
    label ?: string;
    margin ?: PropTypes.Margin;
    className ?: string;
    field : string;
    forwardedRef ?: React.RefObject<HTMLInputElement>;
    style ?: React.CSSProperties;
    defaultValue ?: string;
    forceUppercase ?: boolean;
    value ?: boolean;
}

interface ICustomToggleButtonState {
    event ?: React.FormEvent<HTMLInputElement | HTMLAnchorElement | HTMLButtonElement>;
}

class CustomToggleButton extends React.Component<ICustomToggleButtonProps & FieldContext<boolean>, ICustomToggleButtonState> {

    constructor(props : ICustomToggleButtonProps & FieldContext<boolean>) {
        super(props);
    }

    private onClose = (approved : boolean) => {
        if (approved) {
            this.props.fieldApi.setValue(!this.props.fieldState.value);
            if (this.props.onChange) {
                this.props.onChange(this.state.event, !this.props.fieldState.value);
            }
        } else {
            this.props.fieldApi.setValue(this.props.fieldState.value);
            if (this.props.onChange) {
                this.props.onChange(this.state.event, this.props.fieldState.value);
            }
        }
    };

    public render() {
        const { onChange, onBlur, margin, className, required, fieldState, fieldApi } = this.props;

        const { value, error } = fieldState;
        const { setValue, setTouched } = fieldApi;

        return (
            <>
                <FormControl margin={margin} className={className} required={required}>
                    <ToggleButton
                        id={this.props.name}
                        value={!!value}
                        disabled={this.props.disabled}
                        selected={!!value}
                        onChange={(e) => {
                            setValue(!value);
                            if (onChange) {
                                onChange(e, !value);
                            }
                        }}
                        onBlur={(e) => {
                            setTouched(true);
                            if (!!onBlur) {
                                onBlur(e);
                            }
                        }}
                        className={`NoTextTransform br30 p3 m10 h36 bw2 ${value ? 'bcp cw bogreen1' : ''} ${this.props.className}`}>
                        <div className={'w30 aic mr5'}>
                            {
                                value ? (
                                    <Icon style={{ width: 27, height: 27 }} className={'brh bw2 cw mr10'}>
                                        done
                                    </Icon>
                                ) : (
                                    <Icon style={{ width: 27, height: 27 }} className={'brh bw2 mr10'}>
                                        close
                                    </Icon>
                                )
                            }
                        </div>
                        <Typography className={'flx1 jcc'}>{this.props.label}</Typography>
                        <span className={'w30'}/>
                    </ToggleButton>
                </FormControl>
                {
                    !!fieldApi && !!error ? (
                        <FormHelperText error>{error}</FormHelperText>
                    ) : null
                }
            </>
        );
    }
}

export default asField<ICustomToggleButtonProps>(CustomToggleButton);

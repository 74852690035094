import { IInspectionPoint } from './inspectionPoint';
import * as Yup from 'yup';

export interface IInspectionPointFormValues {
    id : number;
    guid : string;
    code : string;
    name : string;
    bookingRefNr ?: string;
    isActive : boolean;
}

export class InspectionPointFormValues implements IInspectionPointFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public name : string;
    public bookingRefNr ?: string;
    public isActive : boolean;

    public constructor(inspectionPoint ?: IInspectionPoint) {
        this.initializeDefaultValues();
        if (inspectionPoint) {
            this.id = inspectionPoint.id;
            this.guid = inspectionPoint.guid;
            this.code = inspectionPoint.code;
            this.name = inspectionPoint.name;
            this.bookingRefNr = inspectionPoint.bookingRefNr;
            this.isActive = inspectionPoint.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.name = '';
        this.bookingRefNr = '';
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

import * as React from 'react';
import { DispatchCall, IAuthState, IRootState, RootAction } from '../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography, TextField } from '@mui/material';
import Screen from '../../components/Screen';
import IconButton from '@mui/material/IconButton';
import { sortBy } from 'lodash';
import { CustomChangeEvent, CustomMouseEvent } from '../../@types/helper';
import Paper from '@mui/material/Paper';
import ButtonBase from '@mui/material/ButtonBase';
import * as navActions from '../../store/nav/Actions';
import { Link } from 'react-router-dom';
import { createSelector } from 'reselect';

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

interface IMasterDataManagementProps {
    pushUrl : DispatchCall<string>;
    auth : IAuthState;
}

interface IMasterDataManagementState {
    selectedLetter ?: string;
    searchFilter : string;
}

class MasterDataManagement extends React.Component<IMasterDataManagementProps, IMasterDataManagementState> {
    constructor(props : IMasterDataManagementProps) {
        super(props);

        this.state = {
            searchFilter: '',
        };
    }

    private getRights = (props : IMasterDataManagementProps) => props.auth.session?.user?.rights;

    private masterDataScreens = createSelector([this.getRights], rights => sortBy(rights?.filter(x => x.url?.startsWith('/masterData/') && !x.code.endsWith('_EDIT'))
        .map((x) => {
            return {
                label: x.name,
                url: x.url,
                disabled: !x.isActive,
            };
        }) ?? [], x => x.label));

    public onLetterClick = (e : CustomMouseEvent) => {
        let selectedLetter : string | undefined = e.currentTarget.value;

        if (this.state.selectedLetter === selectedLetter) {
            selectedLetter = undefined;
        }

        this.setState({ selectedLetter });
    };

    public onSearchFilterChange = (e : CustomChangeEvent) => {
        this.setSearchFilter(e.currentTarget.value);
    };

    public resetSearchFilter = () => {
        this.setSearchFilter('');
    };

    public setSearchFilter = (searchFilter : string) => {
        this.setState({ searchFilter });
    };

    public render() {
        const filteredMasterDataScreens = this.masterDataScreens(this.props).filter((x) => {
            return x.label.toLowerCase().includes(this.state.searchFilter.toLowerCase())
                && (this.state.selectedLetter === undefined || x.label.startsWith(this.state.selectedLetter));
        });
        const activeAlphabet = alphabet.filter(letter => this.masterDataScreens(this.props).some(x => x.label.startsWith(letter)));
        const activeAlphabetFiltered = activeAlphabet.filter(letter => filteredMasterDataScreens.some(x => x.label.startsWith(letter)));

        return (
            <Screen isScrollable>
                <div className={'fdr jcc aic'}>
                    {
                        alphabet.map((letter) => {
                            const disabled = !activeAlphabet.some(x => x === letter);

                            return (
                                <IconButton
                                    key={`letter-${letter}`}
                                    value={letter}
                                    onClick={this.onLetterClick}
                                    className={`w40 h40 ${this.state.selectedLetter === letter ? 'bcp cw' : ''}`}
                                    disabled={disabled}>
                                    <Typography>{letter}</Typography>
                                </IconButton>
                            );
                        })
                    }
                </div>
                <div className={'fdr jcc aic'}>
                    <TextField
                        label={'Search'}
                        name={'search'}
                        className={'w700'}
                        onChange={this.onSearchFilterChange}
                        value={this.state.searchFilter}
                    />
                </div>
                <div className={'w700 fdc asc pb30'}>
                    {
                        activeAlphabetFiltered.map((letter) => {
                            const screensForLetter = this.masterDataScreens(this.props).filter(x => x.label.startsWith(letter)
                                && x.label.toLowerCase().includes(this.state.searchFilter.toLowerCase()));

                            return (
                                <Paper key={`card-${letter}`} className={'jcfs aifs mt10 mb10 p20 pb10 pt10'}>
                                    <div className={'fdc aifs'}>
                                        <Typography variant={'h5'}>{letter}</Typography>
                                        {
                                            screensForLetter.filter(x => x.label !== 'Management').map((screen) => {
                                                return (
                                                    <Link to={screen.url} style={{ textDecoration: 'unset', color: 'unset' }}>
                                                        <ButtonBase
                                                            disabled={screen.disabled}
                                                            value={screen.url}
                                                            className={'ml20 br5 p10 pt0 pb0'}>
                                                            <Typography
                                                                key={`nav-${screen.url}-${screen.label}`}
                                                                color={!screen.disabled ? 'textPrimary' : 'textSecondary'}
                                                                variant={'subtitle1'}>
                                                                {screen.label}
                                                            </Typography>
                                                        </ButtonBase>
                                                    </Link>
                                                );
                                            })
                                        }
                                    </div>
                                </Paper>
                            );
                        })
                    }
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => bindActionCreators(
    {
        pushUrl: navActions.navPath,
    },
    dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MasterDataManagement);

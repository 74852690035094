import axios, { AxiosPromise } from 'axios';
import { IProject } from '../../../@types/model/masterData/project/project';
import { PACKMAN_API_URL } from '../../../appConstants';

export default class ProjectHttpService {
    public static getProjectData() : AxiosPromise<Array<IProject>> {
        return axios.get(`${PACKMAN_API_URL}Project/GetAllWeb`);
    }

    public static addOrUpdateProject(data : IProject) : AxiosPromise<IProject> {
        return axios.post(`${PACKMAN_API_URL}Project/PostProject`, data);
    }

    public static deleteProject(projectId : number) : AxiosPromise<IProject> {
        return axios.delete(`${PACKMAN_API_URL}Project/Delete`, {
            params : { projectId },
        });
    }
}

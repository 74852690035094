import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { AccreditationFormValues } from '../../../../@types/model/masterData/accreditation/accreditationFormValues';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormDateInput from '../../../../components/input/form/FormDateInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import { IOptionType } from '../../../../@types/helper';
import FormAutocompleteSelect from '../../../../components/input/form/FormAutoCompleteSelect';
import { IRootState } from '../../../../@types/redux';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

interface IAccreditationFormProps {
    isLoading : boolean;
    farmOptions : Array<IOptionType>;
}

interface IAccreditationFormState {
}

type AccreditationFormPropsType = IAccreditationFormProps & FormikProps<AccreditationFormValues>;

class AccreditationForm extends React.Component<AccreditationFormPropsType, IAccreditationFormState> {

    constructor(props : AccreditationFormPropsType) {
        super(props);
    }

    public render() {
        return (
            <Form className={'p20 w300'}>
                <FormTextInput name={'code'} label={'Code'}/>
                <FormTextInput name={'name'} label={'Name'}/>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <FormDateInput name={'issueDate'} label={'IssueDate'} variant={'standard'} className={'pt0 mt0 pb16'}/>
                    <FormDateInput name={'expitationDate'} label={'ExpirationDate'} variant={'standard'} className={'pt0 mt0 pb16'}/>
                </LocalizationProvider>
                <FormAutocompleteSelect
                    name={'farms'}
                    label={'Farms'}
                    options={this.props.farmOptions}
                    isMulti
                />
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const getFarms = (state : IRootState) => state.masterData.farms;

const getFarmOptions = createSelector(
    [getFarms],
    (farms) => {
        return farms
            .filter(x => x.isActive)
            .map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        farmOptions: getFarmOptions(state),
    };
};

export default connect(
    mapStateToProps,
)(AccreditationForm);

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { ICommodity } from '../../../@types/model/masterData/commodity/commodity';

export default class CommodityHttpService {
    public static getCommodityData(onlyActive ?: boolean, ignoreIncludes ?: boolean) : AxiosPromise<Array<ICommodity>> {
        return axios.get(`${PACKMAN_API_URL}Commodity/GetAllWeb`, {
            params : {
                ignoreIncludes,
                onlyActive,
            },
        });
    }

    public static addOrUpdateCommodity(data : ICommodity) : AxiosPromise<ICommodity> {
        return axios.post(`${PACKMAN_API_URL}Commodity/PostCommodity`, data);
    }

    public static deleteCommodity(commodityId : number) : AxiosPromise<ICommodity> {
        return axios.delete(`${PACKMAN_API_URL}Commodity/Delete`, {
            params : { commodityId },
        });
    }
}

import { IMark } from './mark';
import * as Yup from 'yup';

export interface IMarkFormValues {
    id : number;
    guid : string;
    code : string;
    description : string;
    isActive : boolean;
}

export class MarkFormValues implements IMarkFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public description : string;
    public isActive : boolean;

    public constructor(mark ?: IMark) {
        this.initializeDefaultValues();
        if (mark) {
            this.id = mark.id;
            this.guid = mark.guid;
            this.code = mark.code;
            this.description = mark.description;
            this.isActive = mark.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.description = '';
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

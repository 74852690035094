import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { IPrintServer } from '../printServer/printServer';
import { IReport } from './report';

export interface IReportFormValues {
    id : number;
    guid : string;
    name : string;
    printServer ?: IOptionType;
    canLivePalletize : boolean;
    isActive : boolean;
}

export class ReportFormValues implements IReportFormValues {
    public id : number;
    public guid : string;
    public name : string;
    public printServer ?: IOptionType;
    public canLivePalletize : boolean;
    public isActive : boolean;

    public constructor(
        report ?: IReport,
        printServers ?: Array<IPrintServer>,
    ) {
        this.initializeDefaultValues();
        if (report) {
            this.id = report.id;
            this.guid = report.guid;
            this.name = report.name;

            this.printServer = printServers ? {
                label: !!printServers.find(x => x.id === report.printServerId) ? `(${printServers.find(x => x.id === report.printServerId)?.name})` : '',
                value: report.printServerId,
            } : undefined;

            this.canLivePalletize = report.canLivePalletize;
            this.isActive = report.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.name = '';
        this.printServer = undefined;
        this.canLivePalletize = true;
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        printServer: Yup.object().nullable().required('Required'),
        canLivePalletize: Yup.boolean().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

import React from 'react';
import { asField, FieldContext, FormValue, FieldProps, FormValues } from 'informed';
import { FormControl, FormHelperText, PropTypes, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { IDropDownOptions } from '../../@types/other';

interface ICustomSelectProps extends FieldProps<FormValue, FormValues> {
    onChange ?: (event : React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => void;
    onBlur ?: (event : React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onMouseDown ?: (event : React.MouseEvent<HTMLInputElement | HTMLDivElement>) => void;
    onMouseDownCapture ?: (event : React.MouseEvent<HTMLInputElement | HTMLDivElement>) => void;
    onClick ?: (event : React.MouseEvent<HTMLInputElement | HTMLDivElement>) => void;
    initialValue : any;
    id ?: any;
    disabled ?: boolean;
    label ?: string;
    margin ?: PropTypes.Margin;
    field : string;
    className ?: string;
    required ?: boolean;
    forwardedRef ?: React.RefObject<HTMLInputElement>;
    style ?: React.CSSProperties;
    options : Array<IDropDownOptions>;
}

class CustomSelect extends React.Component<ICustomSelectProps & FieldContext<FormValue>> {
    constructor(props : ICustomSelectProps & FieldContext<FormValue>) {
        super(props);
    }

    public render() {
        const { onChange, onBlur,
            id, margin, className, required, fieldState, fieldApi, options, initialValue, disabled, onMouseDown, onMouseDownCapture, onClick } = this.props;
        const { value } = fieldState;
        const { setValue, setTouched } = fieldApi;

        /* ensures initialValue gets assigned initially */
        let newvalue;
        value !== undefined ? newvalue = value : initialValue ? newvalue = initialValue : newvalue = '';

        return (
            <>
                <FormControl margin={margin} className={className} required={required}>
                    <TextField
                        id={id}
                        select
                        defaultValue={initialValue}
                        value={newvalue}
                        label={this.props.label}
                        className={'curd p0'}
                        disabled={disabled}
                        onChange={(e) => {
                            setValue(e.target.value);
                            if (onChange) {
                                onChange(e);
                            }
                        }}
                        onBlur={(e) => {
                            setTouched(true);
                            if (onBlur) {
                                onBlur(e);
                            }
                        }}
                        onMouseDown={onMouseDown}
                        onMouseDownCapture={onMouseDownCapture}
                        onClick={onClick}
                    >
                        {options.length && options.map((element, index) => {
                            return <MenuItem key={index} value={element.value} disabled={element.disabled}>{element.label}</MenuItem>;
                        })}
                    </TextField>
                    {
                        fieldState.error ? (
                            <FormHelperText error>{fieldState.error}</FormHelperText>
                        ) : null
                    }

                </FormControl>
            </>
        );
    }
}

export default asField<ICustomSelectProps>(CustomSelect);

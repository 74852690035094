import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IFarm } from '../../../@types/model/masterData/farm/farm';
import qs from 'qs';

export default class FarmHttpService {
    public static getFarmData(organizationIds ?: Array<number>, onlyActive ?: boolean, ignoreIncludes ?: boolean) : AxiosPromise<Array<IFarm>> {
        return axios.get(`${PACKMAN_API_URL}Farm/GetAllWeb`, {
            params: {
                onlyActive,
                ignoreIncludes,
                organizationIds,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static addOrUpdateFarm(data : IFarm) : AxiosPromise<IFarm> {
        return axios.post(`${PACKMAN_API_URL}Farm/PostFarm`, data);
    }

    public static deleteFarm(farmId : number) : AxiosPromise<IFarm> {
        return axios.delete(`${PACKMAN_API_URL}Farm/Delete`, {
            params : { farmId },
        });
    }
}

import * as React from 'react';
import { Card, Table, TableRow, TableCell, Typography, TableHead } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, IAuthState } from '../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import Screen from '../../components/Screen';
import CustomTable from '../../components/table/CustomTable';
import StockHttpService from '../../services/http/stock/stockHttpService';
import moment from 'moment';
import { generalShowErrorSnackbar } from '../../store/general/Functions';
import { dataSetAllStockTomatoReportRelatedData } from '../../store/data/Functions';
import { addArrayElement, compareDate, formatDateTimeToDateOnly } from '../../services/appFunctionsService';
import { ISite } from '../../@types/model/masterData/site/site';
import { IMarket } from '../../@types/model/masterData/market/market';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import TransactionFilter from '../../components/filters/BasicTransactionScreenFilter';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import { IOptionType } from '../../@types/helper';
import { CSVLink } from 'react-csv';
import { DATEPICKER_FORMAT_DEFAULT } from '../../appConstants';
import PopupOptionButton from '../../components/button/PopupOptionButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import { IStockLine } from '../../@types/model/stock/stockLine';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { ISize } from '../../@types/model/masterData/size/size';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { IStock } from '../../@types/model/stock/stock';
import { IStockTomatoReportView } from '../../@types/model/stock/StockTomatoReportView';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { syncMasterData } from '../../services/masterDataSyncService';
import CustomTooltip from '../../components/tooltip/tooltip';
import { getThemeMode } from '../../styles/materialTheme';

const themeMode = getThemeMode();

interface IStockTomatoReportScreenProps extends RouteComponentProps {
    auth : IAuthState;
    selectedSiteIds : Array<number>;
    stocks : Array<IStockTomatoReportView>;
    sites : Array<ISite>;
    markets : Array<IMarket>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    farms : Array<IFarm>;
    grades : Array<IGrade>;
    colours : Array<IColour>;
    sizes : Array<ISize>;
    packs : Array<IPack>;
}

interface IStockTomatoReportScreenState {
    isLoading : boolean;
    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;
    selectedSite ?: IOptionType;
    selectedCommodity ?: IOptionType;
    selectedVariety ?: IOptionType;
    selectedFarm ?: IOptionType;
    selectedMarket ?: IOptionType;
    selectedBarcode ?: IOptionType;
    selectedDispatchCode ?: IOptionType;
    selectedTab : number;
}

class StockTomatoReportScreen extends React.Component<IStockTomatoReportScreenProps, IStockTomatoReportScreenState> {
    constructor(props : IStockTomatoReportScreenProps) {
        super(props);

        this.state = {
            isLoading: false,
            selectedFromDate: moment().local().startOf('day').subtract(7, 'days'),
            selectedToDate: moment().local().endOf('day'),
            selectedTab: 0,
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        try {
            const res = await StockHttpService.getStockTomatoReportRelatedData(this.getDate('from'), this.getDate('to'), this.props.selectedSiteIds, !isIndexedDBAvailable);
            dataSetAllStockTomatoReportRelatedData(res.data);
            this.setLoading(false);
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving stock report data.');
            this.setLoading(false);
        }
    };

    public componentDidUpdate = (prevProps : IStockTomatoReportScreenProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            if (nextProps.selectedSiteIds !== undefined && prevProps.selectedSiteIds !== nextProps.selectedSiteIds) {
                this.refreshData();
            }
        }
    };

    private refreshData = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }
        try {
            const res = await StockHttpService.getStockTomatoReportRelatedData(this.getDate('from'), this.getDate('to'), this.props.selectedSiteIds, !isIndexedDBAvailable);
            dataSetAllStockTomatoReportRelatedData(res.data);
            this.setLoading(false);
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving stock report data.');
            this.setLoading(false);
        } finally {
            this.setLoading(false);
        }
    };

    private onApplyClick = async () => {
        await this.refreshData();
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private getDate = (type : 'from' | 'to') => {
        switch (type) {
            case 'from':
                return this.state.selectedFromDate.startOf('day').utc().unix() * 1000;
            case 'to':
                return this.state.selectedToDate.endOf('day').utc().unix() * 1000;
        }
    };

    private handleDateRangeChange = (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => {
        const selectedFromDate = changedBy === 'start' ? moment(start).startOf('day') : end < start ? moment(end).startOf('day') : moment(start).startOf('day');
        const selectedToDate = changedBy === 'end' ? moment(end).endOf('day') : start > end ? moment(start).endOf('day') : moment(end).endOf('day');
        this.setState({ selectedFromDate, selectedToDate });
    };

    private getMainStockLine = (stock : IStock) => {
        if (stock.stockLines.length > 0) {
            let mainStockLine = { ...stock.stockLines[0] };
            stock.stockLines.forEach((x) => {
                if ((mainStockLine.cartons < x.cartons) || ((mainStockLine.cartons === x.cartons) && (mainStockLine.id > x.id))) {
                    mainStockLine = x;
                }
            });

            return mainStockLine;
        }
    };

    private getSiteDescription = (siteId : number) => {
        const sites = this.props.sites;
        const site = sites && sites.find(x => x.id === siteId);
        return site ? site.description : '';
    };

    private getCommodityCode = (mainStockLine : IStockLine) => {
        const commodity = this.props.commodities.find(x => x.id === mainStockLine.commodityId);

        return commodity ? commodity.code : '';
    };

    private getVarietyCode = (mainStockLine : IStockLine) => {
        const variety = this.props.varieties.find(x => x.id === mainStockLine.varietyId);

        return variety ? variety.code : '';
    };

    private getFarmCode = (mainStockLine : IStockLine) => {
        const farm = this.props.farms.find(x => x.id === mainStockLine.farmId);

        return farm ? farm.code : '';
    };

    private getGradeCode = (mainStockLine : IStockLine) => {
        const grade = this.props.grades.find(x => x.id === mainStockLine.gradeId);

        return grade ? grade.code : '';
    };

    private getColourCode = (mainStockLine : IStockLine) => {
        const colour = this.props.colours.find(x => x.id === mainStockLine.colourId);

        return colour ? colour.code : '';
    };

    private getPackCode = (mainStockLine : IStockLine) => {
        const pack = this.props.packs.find(x => x.id === mainStockLine.packId);

        return pack ? pack.code : '';
    };

    private getSizeCode = (mainStockLine : IStockLine) => {
        const Size = this.props.sizes.find(x => x.id === mainStockLine.sizeId);

        return Size ? Size.code : '';
    };

    private getMarketCode = (marketId : number) => {
        const market = this.props.markets.find(x => x.id === marketId);

        return market ? market.code : '';
    };

    private getMainStockLinePalletSize = (mainStockLine : IStockLine) => {
        return mainStockLine.palletSize ?? '';
    };

    private getTotalCartonsOrInners = (id : number) => {
        const stock = this.props.stocks.find(x => x.id === id);
        let newTotalInners : number = 0;
        if (stock && stock?.totalInners) {
            const inners = stock?.totalInners.split(', ');

            inners.forEach((x) => {
                if (x !== '0') {
                    newTotalInners = newTotalInners + Number(x);
                }
            });
        }

        if (newTotalInners === 0) {
            return stock?.cartons;
        } else {
            return newTotalInners;
        }
    };

    private getAgeInDays = (createdOn ?: string) => createdOn ? moment({ hours: 0 }).diff(createdOn, 'days') : -1;

    private getStocks = (props : IStockTomatoReportScreenProps) => props.stocks;
    private getSites = (props : IStockTomatoReportScreenProps) => props.sites;
    private getCommodities = (props : IStockTomatoReportScreenProps) => props.commodities;
    private getVarieties = (props : IStockTomatoReportScreenProps) => props.varieties;
    private getFarms = (props : IStockTomatoReportScreenProps) => props.farms;
    private getMarkets = (props : IStockTomatoReportScreenProps) => props.markets;
    private getPacks = (props : IStockTomatoReportScreenProps) => props.packs;
    private getSizes = (props : IStockTomatoReportScreenProps) => props.sizes;
    private getGrades = (props : IStockTomatoReportScreenProps) => props.grades;
    private getColours = (props : IStockTomatoReportScreenProps) => props.colours;
    private getSelectedSiteIds = (props : IStockTomatoReportScreenProps) => props.selectedSiteIds;

    private getSelectedSiteOption = (props : IStockTomatoReportScreenProps, state : IStockTomatoReportScreenState) => state.selectedSite;
    private getSelectedCommodityOption = (props : IStockTomatoReportScreenProps, state : IStockTomatoReportScreenState) => state.selectedCommodity;
    private getSelectedVarietyOption = (props : IStockTomatoReportScreenProps, state : IStockTomatoReportScreenState) => state.selectedVariety;
    private getSelectedFarmOption = (props : IStockTomatoReportScreenProps, state : IStockTomatoReportScreenState) => state.selectedFarm;
    private getSelectedMarketOption = (props : IStockTomatoReportScreenProps, state : IStockTomatoReportScreenState) => state.selectedMarket;
    private getSelectedBarcodeOption = (props : IStockTomatoReportScreenProps, state : IStockTomatoReportScreenState) => state.selectedBarcode;
    private getSelectedDispatchOption = (props : IStockTomatoReportScreenProps, state : IStockTomatoReportScreenState) => state.selectedDispatchCode;

    private getFilteredStockRows = createSelector(
        [this.getStocks, this.getSelectedSiteOption, this.getSelectedCommodityOption, this.getSelectedVarietyOption, this.getSelectedFarmOption, this.getSelectedMarketOption, this.getSelectedBarcodeOption, this.getSelectedDispatchOption],
        (stocks, selectedSite, selectedCommodity, selectedVariety, selectedFarm, selectedMarket, selectedBarcode, selectedDispatchCode) => {
            return stocks.filter(x => !!x.isActive && (!selectedSite || x.currentSiteId === selectedSite?.value)
                            && (!selectedCommodity || x.mainStockLine?.commodityId === selectedCommodity.value)
                            && (!selectedVariety || x.mainStockLine?.varietyId === selectedVariety.value)
                            && (!selectedFarm || x.mainStockLine?.farmId === selectedFarm.value)
                            && (!selectedMarket || x.marketId === selectedMarket.value)
                            && (!selectedBarcode || x.barcode === selectedBarcode.label)
                            && (!selectedDispatchCode || x?.dispatchCode === selectedDispatchCode.label));
        },
    );

    private getSiteOptions = createSelector(
        [this.getSites, this.getSelectedSiteIds],
        (sites, selectedSiteIds) => {
            return sites?.filter(x => x.isActive && selectedSiteIds?.some(y => y === x.id)).map((x) => {
                return { label: `${x.code} - ${x.shortDescription}`, value: x.id };
            });
        },
    );

    private getCommodityOptions = createSelector(
        [this.getCommodities],
        (commodities) => {
            return commodities?.filter(x => x.isActive).map((x) => {
                return { label: `${x.code} - ${x.name}`, value: x.id };
            });
        },
    );

    private getVarietyOptions = createSelector(
        [this.getVarieties, this.getSelectedCommodityOption],
        (varieties, selectedCommodity) => {
            return varieties?.filter(x => x.isActive && (!selectedCommodity || x.commodityId === selectedCommodity.value)).map((x) => {
                return { label: `${x.code} - ${x.name}`, value: x.id };
            });
        },
    );

    private getFarmOptions = createSelector(
        [this.getFarms],
        (farms) => {
            return farms?.filter(x => x.isActive).map((x) => {
                return { label: `${x.code} - ${x.name}`, value: x.id };
            });
        },
    );

    private getMarketOptions = createSelector(
        [this.getMarkets],
        (markets) => {
            return markets?.filter(x => x.isActive).map((x) => {
                return { label: `${x.code} - ${x.name}`, value: x.id };
            });
        },
    );

    private getBarcodeOptions = createSelector(
        [this.getStocks],
        (stocks) => {
            return stocks?.filter(x => x.isActive).map((x) => {
                return { label: x.barcode, value: x.id };
            });
        },
    );

    private getDispatchCodeOptions = createSelector(
        [this.getStocks],
        (stocks) => {
            return stocks?.filter(x => x.isActive && !!x.dispatchCode && x.dispatchCode !== '').map((x) => {
                return { label: x.dispatchCode, value: x.id };
            });
        },
    );

    private onSiteChange = (e : React.ChangeEvent<{}>, selectedSite : IOptionType) => {
        this.setState({ selectedSite });
    };

    private onCommodityChange = (e : React.ChangeEvent<{}>, selectedCommodity : IOptionType) => {
        this.setState({ selectedCommodity });
    };

    private onVarietyChange = (e : React.ChangeEvent<{}>, selectedVariety : IOptionType) => {
        this.setState({ selectedVariety });
    };

    private onFarmChange = (e : React.ChangeEvent<{}>, selectedFarm : IOptionType) => {
        this.setState({ selectedFarm });
    };

    private onMarketChange = (e : React.ChangeEvent<{}>, selectedMarket : IOptionType) => {
        this.setState({ selectedMarket });
    };

    private onBarcodeChange = (e : React.ChangeEvent<{}>, selectedBarcode : IOptionType) => {
        this.setState({ selectedBarcode });
    };

    private onDispatchCodeChange = (e : React.ChangeEvent<{}>, selectedDispatchCode : IOptionType) => {
        this.setState({ selectedDispatchCode });
    };

    private exportCSV = () => {
        const filteredStock = this.props.stocks;
        const returnData : Array<Array<string>> = [];
        returnData.push([
            'pack Date',
            'Site',
            'Grade',
            'Pack Code',
            'Variety',
            'Size',
            'Colour',
            'Dispatch Code',
            'Barcode',
            'Target Market',
            'Age',
            'Cartons/Inners',
            'Pallet Size',
            'Gross (kg)',
            'Est Nett (kg)',
        ]);

        if (filteredStock.length < 1) {
            return [];
        }

        filteredStock.forEach((stock) => {
            const cartonsOrInners = this.getTotalCartonsOrInners(stock.id) ?? 0;
            returnData.push([
                stock.packDate, // Pack Date
                this.getSiteDescription(stock.currentSiteId), // Site short description
                this.getGradeCode(stock.mainStockLine), // main stock line grade code
                this.getPackCode(stock.mainStockLine), // main stock line pack code
                this.getVarietyCode(stock.mainStockLine), // main stock line variety code
                this.getSizeCode(stock.mainStockLine), // main stock line size code
                this.getColourCode(stock.mainStockLine), // main stock line colour code
                stock ? stock.dispatchCode : '', // Dispatch code
                stock.barcode ?? '', // Barcode
                this.getMarketCode(stock.marketId), // Market code
                this.getAgeInDays(stock.createdOn).toString(), // Age in days
                cartonsOrInners.toString(), // Cartons/Inners
                this.getMainStockLinePalletSize(stock.mainStockLine).toString(), // Pallet Size
                stock.grossWeight.toFixed(3).toString(), // Gross (kg)
                stock.estimatedNettWeight.toFixed(3).toString(), // Est Nett (kg)
            ]);
        });
        return returnData;
    };

    public handleTabChange = (event : React.ChangeEvent<{}>, selectedTab : number) => {
        this.setState({ selectedTab });
    };

    private renderSummaryTable = createSelector(
        [this.getFilteredStockRows, this.getSites, this.getColours, this.getPacks, this.getSizes, this.getGrades, this.getSelectedSiteIds, this.getSelectedSiteOption],
        (filteredRows : Array<IStockTomatoReportView>,
         sites : Array<ISite>,
         colours : Array<IColour>, packs : Array<IPack>, sizes : Array<ISize>, grades : Array<IGrade>, selectedSiteIds : Array<number>, selectedSiteOption) => {
            let newFilteredRows : Array<{ packId : number; gradeId : number; sizeId : number; stocks : Array<IStockTomatoReportView> }> = [];
            filteredRows.forEach((stock) => {
                const index = newFilteredRows.findIndex(x => x.stocks.some(y => y.mainStockLine.packId === stock.mainStockLine.packId)
                                                            && x.stocks.some(y => y.mainStockLine.sizeId === stock.mainStockLine.sizeId)
                                                            && x.stocks.some(y => y.mainStockLine.gradeId === stock.mainStockLine.gradeId)
                                                            && x.stocks.some(y => y.mainStockLine.colourId === stock.mainStockLine.colourId));

                if (index === -1) {
                    const newRow : { packId : number; gradeId : number; sizeId : number; colourId : number; stocks : Array<IStockTomatoReportView> } = {
                        packId: stock.mainStockLine.packId,
                        gradeId: stock.mainStockLine.gradeId,
                        sizeId: stock.mainStockLine.sizeId,
                        colourId: stock.mainStockLine.colourId ?? 0,
                        stocks: [stock],
                    };
                    newFilteredRows = addArrayElement(newFilteredRows, newRow);
                } else {
                    const row = { ...newFilteredRows[index] };
                    row.stocks = addArrayElement(row.stocks, stock);
                }
            });

            const newRowsFilteredByPackGradeSizeColourCode = newFilteredRows.filter(x => selectedSiteIds?.some(y => x.stocks.some(z => z.currentSiteId === y))).map((x) => {
                return { id: x.stocks[0].id,
                    packCode: this.getPackCode(x.stocks[0].mainStockLine),
                    gradeCode: this.getGradeCode(x.stocks[0].mainStockLine),
                    sizeCode: this.getSizeCode(x.stocks[0].mainStockLine),
                    colourCode: this.getColourCode(x.stocks[0].mainStockLine) };
            });

            return (
                <Table className= {'PaperBorder'}>
                    {/* Body */}
                    <div>
                        {
                            newRowsFilteredByPackGradeSizeColourCode.map((x) => {
                                const row = filteredRows.find(y => y.id === x.id);
                                if (row) {
                                    const packCode = this.getPackCode(row.mainStockLine);
                                    const sizeCode = this.getSizeCode(row.mainStockLine);
                                    const gradeCode = this.getGradeCode(row.mainStockLine);
                                    const colourCode = this.getColourCode(row.mainStockLine);
                                    return (
                                        <TableRow className={'fdr wfill h50'}>
                                            <TableCell className={'w150 h50 fdc jcc aic p0 m0 fw500 bl1 br1 bb1 cgray3'}>
                                                <div>{`${packCode}, ${gradeCode}, ${sizeCode}, ${colourCode}`}</div>
                                            </TableCell>
                                            {
                                                sites.filter(site => site.isActive && (!!selectedSiteOption ? site.id === selectedSiteOption?.value : selectedSiteIds?.length < 1 || selectedSiteIds?.some(y => y === site.id))).map((site) => {
                                                    const stocksAtSite = filteredRows.filter(z => z.currentSiteId === site.id);
                                                    const stocks = stocksAtSite.filter(stock => row?.mainStockLine.packId === stock.mainStockLine.packId
                                                                                                    && row?.mainStockLine.sizeId === stock.mainStockLine.sizeId
                                                                                                    && row?.mainStockLine.gradeId === stock.mainStockLine.gradeId
                                                                                                    && row?.mainStockLine.colourId === stock.mainStockLine.colourId);
                                                    return (
                                                        <TableCell className={'flx1 h50 fdc jcc aic p0 m0 fw500 br1 bl1 bb1 cgray3'}>
                                                            <div className={'fdr aic jcc'}>
                                                                {stocks.length === 0 ? '-' : stocks.length}
                                                            </div>
                                                        </TableCell>
                                                    );
                                                })
                                            }
                                        </TableRow>
                                    );
                                }
                            })
                        }
                    </div>
                </Table>
            );
        },
    );

    public render() {
        return (
            <Screen isLoading={this.state.isLoading} isScrollable={false} isPadded={false}>
                <div className={'fdc hfill p20'}>
                    <div className={'fdr wfill aic'}>
                        <AutocompleteSelect
                            className={'w250 pt0 mt0 mb5'}
                            name={'packhouse'}
                            label={'Packhouse'}
                            options={this.getSiteOptions(this.props)}
                            onChange={this.onSiteChange}
                            value={this.state.selectedSite}
                        />
                        <AutocompleteSelect
                            className={'w250 pt0 mt0 mb5'}
                            name={'farm'}
                            label={'Farm'}
                            options={this.getFarmOptions(this.props)}
                            onChange={this.onFarmChange}
                            value={this.state.selectedFarm}
                        />
                        <AutocompleteSelect
                            className={'w250 pt0 mt0 mb5'}
                            name={'commodity'}
                            label={'Commodity'}
                            options={this.getCommodityOptions(this.props)}
                            onChange={this.onCommodityChange}
                            value={this.state.selectedCommodity}
                        />
                        <AutocompleteSelect
                            className={'w250 pt0 mt0 mb5'}
                            name={'variety'}
                            label={'Variety'}
                            options={this.getVarietyOptions(this.props, this.state)}
                            onChange={this.onVarietyChange}
                            value={this.state.selectedVariety}
                        />
                        <div className={'flx1'}/>
                        <TransactionFilter
                            className={'pt10 mt10 mr20 mb15'}
                            selectedFromDate={this.state.selectedFromDate}
                            selectedToDate={this.state.selectedToDate}
                            handleDateRangeChange={this.handleDateRangeChange}
                            onApplyClick={this.onApplyClick}
                        />
                    </div>
                    <div className={'fdr wfill aic'}>
                        <AutocompleteSelect
                            className={'w250 pt0 mt0 mb5'}
                            name={'barcode'}
                            label={'Barcode'}
                            options={this.getBarcodeOptions(this.props)}
                            onChange={this.onBarcodeChange}
                            value={this.state.selectedBarcode}
                        />
                        <AutocompleteSelect
                            className={'w250 pt0 mt0 mb5'}
                            name={'market'}
                            label={'Market'}
                            options={this.getMarketOptions(this.props)}
                            onChange={this.onMarketChange}
                            value={this.state.selectedMarket}
                        />
                        <AutocompleteSelect
                            className={'w250 pt0 mt0 mb5'}
                            name={'dispatchCode'}
                            label={'Dispatch Code'}
                            options={this.getDispatchCodeOptions(this.props)}
                            onChange={this.onDispatchCodeChange}
                            value={this.state.selectedDispatchCode}
                        />
                        <div className={'flx1'}/>
                        {this.getFilteredStockRows(this.props, this.state).length < 1 ?
                            <CustomTooltip title={'There is no stocks to export to csv.'}>
                                <PopupOptionButton
                                    text={'DOWNLOAD REPORT CSV'}
                                    icon={<GetAppIcon/>}
                                    disabled={true}
                                    onClick={() => null}
                                />
                            </CustomTooltip>
                            :
                            <CSVLink data={this.exportCSV()} filename={'Tomato Report ' + moment(moment.now()).local().format(DATEPICKER_FORMAT_DEFAULT) + '.csv'} style={{ textDecoration: 'unset' }}>
                                <div>
                                    <PopupOptionButton
                                        text={'DOWNLOAD REPORT CSV'}
                                        icon={<GetAppIcon/>}
                                        onClick={() => null}
                                    />
                                </div>
                            </CSVLink>
                        }
                    </div>
                    <Tabs
                        value={this.state.selectedTab}
                        indicatorColor={themeMode === 'light' ? 'primary' : 'secondary'}
                        textColor={themeMode === 'light' ? 'primary' : 'secondary'}
                        onChange={this.handleTabChange}
                    >
                        <Tab className={'flx1 w250 fwb'} label='Summary'/>
                        <Tab className={'flx1 w250 fwb'} label='Detail'/>
                    </Tabs>
                    {this.state.selectedTab === 0 &&
                    <TableHead className={'fdc posstick'}>
                        <TableRow className={'fdr h30 zi999 posstick bcpd bb1 cgray3'}>
                            <TableCell className={'w150 fdc jcc aic p0 fw500 bl1 br1 bb1 cgray3'}>
                                {' '}
                            </TableCell>
                            {
                                this.props.sites.filter(x => x.isActive && (!!this.state.selectedSite ? x.id === this.state.selectedSite?.value : this.props.selectedSiteIds?.length < 1 || this.props.selectedSiteIds?.some(y => y === x.id))).map((x) => {
                                    return (
                                        <TableCell className={'flx1 fdc jcc aic p0 fw500 cw bl1 br1 bb1 cgray3'}>{x.shortDescription}</TableCell>
                                    );
                                })
                            }
                        </TableRow>
                        <TableRow className={'fdr h50 zi999 posstick bcpd bb1 cgray3'}>
                            <TableCell className={'w150 fdc jcc aic p0 fw500 cw bl1 br1 bb1 cgray3'}>
                                <div>{'Specification'}</div>
                                <Typography variant={'caption'}>{'Pack, Grade, Size, Colour'}</Typography>
                            </TableCell>
                            {
                                this.props.sites.filter(x => x.isActive && (!!this.state.selectedSite ? x.id === this.state.selectedSite?.value : this.props.selectedSiteIds?.length < 1 || this.props.selectedSiteIds?.some(y => y === x.id))).map(() => {
                                    return (
                                        <TableCell className={'flx1 fdc jcc aic p0 fw500 cw bl1 br1 bb1 cgray3'}>{'Total'}</TableCell>
                                    );
                                })
                            }
                        </TableRow>
                    </TableHead>
                    }
                    {this.state.selectedTab === 0 &&
                        <Card className={'fdc oya'}>
                            {this.renderSummaryTable(this.props, this.state)}
                        </Card>
                    }
                    {this.state.selectedTab === 1 &&
                        <Card className={'fdc'}>
                            <CustomTable<IStockTomatoReportView>
                                enableFiltering
                                enablePagination
                                fitWidthToPage
                                columns={[
                                    { title: 'Pack Date', field: 'packDate', width: 100, formatFunction: formatDateTimeToDateOnly, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
                                    { title: 'Current Site', field: 'currentSiteId', formatFunction: this.getSiteDescription, enableFiltering: true, enableSorting: true },
                                    { title: 'Grade', field: 'mainStockLine', width: 80, formatFunction: this.getGradeCode, enableFiltering: true, enableSorting: true },
                                    { title: 'Pack Code', field: 'mainStockLine', width: 80, formatFunction: this.getPackCode, enableFiltering: true, enableSorting: true },
                                    { title: 'Variety', field: 'mainStockLine', width: 80, formatFunction: this.getVarietyCode, enableFiltering: true, enableSorting: true },
                                    { title: 'Size', field: 'mainStockLine', width: 80, formatFunction: this.getSizeCode, enableFiltering: true, enableSorting: true },
                                    { title: 'Colour', field: 'mainStockLine', width: 80, formatFunction: this.getColourCode, enableFiltering: true, enableSorting: true },
                                    { title: 'Dispatch Code', field: 'dispatchCode', width: 200, enableFiltering: true, enableSorting: true },
                                    { title: 'Barcode', field: 'barcode', width: 200, enableFiltering: true, enableSorting: true },
                                    { title: 'Target Market', field: 'marketName', enableFiltering: true, enableSorting: true },
                                    { title: 'Age', field: 'createdOn', width: 100, formatFunction: this.getAgeInDays, enableFiltering: true, enableSorting: true },
                                    { title: 'Cartons/Inners', field: 'id', width: 110, formatFunction: this.getTotalCartonsOrInners, enableFiltering: true, enableSorting: true },
                                    { title: 'Pallet Size', field: 'mainStockLine', width: 80, formatFunction: this.getMainStockLinePalletSize, enableFiltering: true, enableSorting: true },
                                    { title: 'Gross Weight (kg)', field: 'grossWeight', enableFiltering: true, enableSorting: true },
                                    { title: 'Estimated Nett Weight (kg)', field: 'estimatedNettWeight', enableFiltering: true, enableSorting: true },
                                ]}
                                rows={this.getFilteredStockRows(this.props, this.state)}
                                initialSortOrder={[{ columnName: 'packDate_Pack Date', direction : 'desc' }]}
                                pageSizes={[50, 150, 250, 500, 1000]}
                                isActive={(row : IStockTomatoReportView) => row.isActive}
                                pageHeight={190}
                            />
                        </Card>
                    }
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        stocks: state.data.stockTomatoReportViews,
        selectedSiteIds: state.data.selectedSiteIds,
        sites: state.masterData.sites,
        markets: state.masterData.markets,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        farms: state.masterData.farms,
        grades: state.masterData.grades,
        colours: state.masterData.colours,
        sizes: state.masterData.sizes,
        packs: state.masterData.packs,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    {  }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StockTomatoReportScreen);


export interface IConsignmentCSVJson {
    [key : string] : string | number | undefined;
    EDI_PARTNER_ID : string;
    EDI_FILE_VERSION : number;
    FILE_CREATION_DATE : number;
    FILE_CREATION_TIME : string;
    REVISION : number;
    TRAN_TYPE : number;
    CONS_NO : string;
    BOOKING_REFERENCE : string;
    BILLING_PARTY : string;
    EXPORTER : string;
    UPN ?: string;
    PHC : string;
    SSCC : string; // has ' at the beginning that needs to be removed from 202 and added to 206
    SEQ_NO_SSCC : number;
    SSCC_REV : number;
    CARTON_ID ?: string | number;
    SSCC_REF ?: string | number;
    PUC : string;
    ORCHARD ?: string;
    COMMODITY : string;
    VARIETY : string;
    CLASS : number | string;
    SIZE_COUNT : number | string;
    PACK : string;
    PACKAGE_CATEGORY : string;
    PACK_DATE : number;
    PRE_PACK_TREATMENT ?: string;
    INSP_SAMPLE_WEIGHT : number;
    NETT_WEIGHT_PACK : number;
    GROSS_WEIGHT_PACK : number;
    UNIT_TYPE : string;
    CARTONS : number;
    PLT_QTY : number;
    TARG_REGION : string;
    TARG_COUNTRY : string; // currently null, but must have value now
    PHYTO_IND : string;
    PROTOCOL_EXCEPTION_IND ?: string;
    INSP_STATUS : string;
    INSP_RESULT ?: string;
    REJECT_REASON1 ?: string;
    REJECT_REASON2 ?: string;
    REJECT_REASON3 ?: string;
    INSP_POINT : number;
    INSPECTOR ?: string | number;
    INSP_DATE ?: string | number;
    INSP_TIME ?: string | number;
}

export class ConsignmentCSVJson implements IConsignmentCSVJson {
    [key : string] : string | number | undefined;
    public EDI_PARTNER_ID : string;
    public EDI_FILE_VERSION : number;
    public FILE_CREATION_DATE : number;
    public FILE_CREATION_TIME : string;
    public REVISION : number;
    public TRAN_TYPE : number;
    public CONS_NO : string;
    public BOOKING_REFERENCE : string;
    public BILLING_PARTY : string;
    public EXPORTER : string;
    public UPN ?: string;
    public PHC : string;
    public SSCC : string; // has ' at the beginning that needs to be removed from 202 and added to 206
    public SEQ_NO_SSCC : number;
    public SSCC_REV : number;
    public CARTON_ID ?: string | number;
    public SSCC_REF ?: string | number;
    public PUC : string;
    public ORCHARD ?: string;
    public COMMODITY : string;
    public VARIETY : string;
    public CLASS : number | string;
    public SIZE_COUNT : number | string;
    public PACK : string;
    public PACKAGE_CATEGORY : string;
    public PACK_DATE : number;
    public PRE_PACK_TREATMENT ?: string;
    public INSP_SAMPLE_WEIGHT : number;
    public NETT_WEIGHT_PACK : number;
    public GROSS_WEIGHT_PACK : number;
    public UNIT_TYPE : string;
    public CARTONS : number;
    public PLT_QTY : number;
    public TARG_REGION : string;
    public TARG_COUNTRY : string; // currently null, but must have value now
    public PHYTO_IND : string;
    public PROTOCOL_EXCEPTION_IND ?: string;
    public INSP_STATUS : string;
    public INSP_RESULT ?: string;
    public REJECT_REASON1 ?: string;
    public REJECT_REASON2 ?: string;
    public REJECT_REASON3 ?: string;
    public INSP_POINT : number;
    public INSPECTOR ?: string | number;
    public INSP_DATE ?: string | number;
    public INSP_TIME ?: string | number;
    public constructor(values : Array<string>) {
        this.EDI_PARTNER_ID = values[0];
        this.EDI_FILE_VERSION = Number(values[1]);
        this.FILE_CREATION_DATE = Number(values[2]);
        this.FILE_CREATION_TIME = values[3];
        this.REVISION = Number(values[4]);
        this.TRAN_TYPE = Number(values[5]);
        this.CONS_NO = values[6];
        this.BOOKING_REFERENCE = values[7];
        this.BILLING_PARTY = values[8];
        this.EXPORTER = values[9];
        this.UPN = values[10];
        this.PHC = values[11];
        this.SSCC = values[12]; // has ' at the beginning that needs to be removed from 202 and added to 206
        this.SEQ_NO_SSCC = Number(values[13]);
        this.SSCC_REV = Number(values[14]);
        this.CARTON_ID = values[15];
        this.SSCC_REF = values[16];
        this.PUC = values[17];
        this.ORCHARD = values[18];
        this.COMMODITY = values[19];
        this.VARIETY = values[20];
        this.CLASS = values[21];
        this.SIZE_COUNT = values[22];
        this.PACK = values[23];
        this.PACK_DATE = Number(values[24]);
        this.PRE_PACK_TREATMENT = values[25];
        this.INSP_SAMPLE_WEIGHT = Number(values[26]);
        this.NETT_WEIGHT_PACK = Number(values[27]);
        this.GROSS_WEIGHT_PACK = Number(values[28]);
        this.UNIT_TYPE = values[29];
        this.CARTONS = Number(values[30]);
        this.PLT_QTY = Number(values[31]);
        this.TARG_REGION = values[32];
        this.TARG_COUNTRY = values[33]; // currently null, but must have value now
        this.PHYTO_IND = values[34];
        this.PROTOCOL_EXCEPTION_IND = values[35];
        this.INSP_STATUS = values[36];
        this.INSP_RESULT = values[37];
        this.REJECT_REASON1 = values[38];
        this.REJECT_REASON2 = values[39];
        this.REJECT_REASON3 = values[40];
        this.INSP_POINT = Number(values[41]);
        this.INSPECTOR = values[42];
        this.INSP_DATE = values[43];
        this.INSP_TIME = values[44];
        this.PACKAGE_CATEGORY = values[45];
    }
}

import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { ISite } from '../site/site';
import { IPackLine } from './packLine';

export interface IPackLineFormValues {
    id : number;
    guid : string;
    code : string;
    name : string;
    site ?: IOptionType;
    isActive : boolean;
}

export class PackLineFormValues implements IPackLineFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public name : string;
    public site ?: IOptionType;
    public isActive : boolean;

    public constructor(
        packLine ?: IPackLine,
        sites ?: Array<ISite>,
    ) {
        this.initializeDefaultValues();
        if (packLine) {
            this.id = packLine.id;
            this.guid = packLine.guid;
            this.code = packLine.code;
            this.name = packLine.name;

            this.site = sites ? {
                label: !!sites.find(x => x.id === packLine.siteId) ? `(${sites.find(x => x.id === packLine.siteId)?.code}) ${sites.find(x => x.id === packLine.siteId)?.description}` : '',
                value: packLine.siteId,
            } : undefined;

            this.isActive = packLine.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.name = '';
        this.site = undefined;
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        site: Yup.object().nullable().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

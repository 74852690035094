import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState, RootAction } from '../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Typography } from '@mui/material';
import { IIntakeLineLayer } from '../../@types/model/intake/intakeLineLayer';
import { IStock } from '../../@types/model/stock/stock';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IIntakeLineLayerUnit } from '../../@types/model/intake/intakeLineLayerUnit';
import CustomTable from '../../components/table/CustomTable';
import { addkg, formatDateTime, compareDate, booleanToYesNo } from '../../services/appFunctionsService';

interface IIntakeLineLayerUnitSummaryProps {
    layer : IIntakeLineLayer;
    setLoading : (loading : boolean) => void;
    refreshData : () => void;
    isLoading : boolean;
    stockData : Array<IStock>;
    packs : Array<IPack>;
    varieties : Array<IVariety>;
    units : Array<IIntakeLineLayerUnit>;
}

interface IIntakeLineLayerUnitSummaryState {
    showDialog : boolean;
    rows : Array<IIntakeLineLayerUnit>;
}

class IntakeLineLayerUnitSummary extends React.Component<IIntakeLineLayerUnitSummaryProps, IIntakeLineLayerUnitSummaryState> {
    constructor(props : IIntakeLineLayerUnitSummaryProps) {
        super(props);

        this.state = {
            showDialog: false,
            rows: this.props.units,
        };
    }

    public render() {
        return <div className={'fdc flx1 p10'}>
            <Typography className={'fdr bcp cw pt10 pb10 pl20 mt10 fs18 fw500'} variant='subtitle2'>
                Units
            </Typography>
            <div className={'fdr wfill'}>
                <CustomTable<IIntakeLineLayerUnit>
                    enableSorting
                    enableTotalRow
                    fitWidthToPage
                    columns={[
                        { title: 'ID', field: 'id', enableSorting: true },
                        { title: 'Unit Guid', field: 'unitGuid', enableSorting: true },
                        { title: 'No. Units', field: 'noOfUnits', enableSorting: true },
                        { title: 'Gross Weight (kg)', field: 'grossWeight', formatFunction: addkg, enableSorting: true },
                        { title: 'Nett Weight (kg)', field: 'nettWeight', formatFunction: addkg, enableSorting: true },
                        { title: 'Unit Gross Weight (kg)', field: 'unitGrossWeight', formatFunction: addkg, enableSorting: true },
                        { title: 'Unit Nett Weight (kg)', field: 'unitNettWeight', formatFunction: addkg, enableSorting: true },
                        { title: 'Created By', field: 'createdByName', enableSorting: true },
                        { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
                        { title: 'Updated By', field: 'updatedByName', enableSorting: true },
                        { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
                        { title: 'Active?', field: 'isActive', formatFunction: booleanToYesNo, type: 'boolean', enableSorting: true },
                    ]}
                    rows={this.state.rows}
                    initialSortOrder={[{ columnName: 'id_Id', direction : 'desc' }]}
                    pageHeight={50}
                    isActive={(row : IIntakeLineLayerUnit) => row.isActive}
                />
            </div>
        </div>;
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        stockData: state.data.stocks,
        packs: state.masterData.packs,
        varieties: state.masterData.varieties,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IntakeLineLayerUnitSummary);

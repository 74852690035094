import * as React from 'react';
import CustomTable from '../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetCommodityConditions, dataSetCommodities } from '../../../store/masterData/Actions';
import { formatDateTime, upsertArrayElement } from '../../../services/appFunctionsService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikActions } from 'formik';
import { CommodityConditionFormValues, ICommodityConditionFormValues } from '../../../@types/model/masterData/commodityCondition/commodityConditionFormValues';
import { createSelector } from 'reselect';
import { CommodityCondition, ICommodityCondition } from '../../../@types/model/masterData/commodityCondition/commodityCondition';
import CommodityConditionHttpService from '../../../services/http/masterData/commodityConditionHttpService';
import CommodityConditionForm from './form/CommodityConditionForm';
import { ICommodity } from '../../../@types/model/masterData/commodity/commodity';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import { setCommodityConditionMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface ICommodityConditionScreenProps extends RouteComponentProps {
    dataSetCommodityConditions : DispatchCall<Array<ICommodityCondition>>;
    dataSetCommodities : DispatchCall<Array<ICommodity>>;
    commodityConditionData : Array<ICommodityCondition>;
    commodities : Array<ICommodity>;
    auth : IAuthState;
}

interface ICommodityConditionScreenState {
    rows : Array<ICommodityCondition>;
    isLoading : boolean;
    selectedCommodityCondition ?: ICommodityCondition;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingCommodityCondition ?: ICommodityCondition;
}

class CommodityConditionScreen extends React.Component<ICommodityConditionScreenProps, ICommodityConditionScreenState> {
    constructor(props : ICommodityConditionScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedCommodityCondition: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidUpdate = (prevProps : ICommodityConditionScreenProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            /* prop changes go here */
            if ((prevProps.commodityConditionData !== nextProps.commodityConditionData)) {
                this.setState({ rows: this.getRows() });
            }
        }
    };

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await CommodityConditionHttpService.getCommodityConditionData();

                const commodityConditionData = res.data;
                if (commodityConditionData) {
                    this.props.dataSetCommodityConditions(commodityConditionData);
                } else {
                    this.props.dataSetCommodityConditions([]);
                }

                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading commodity conditions.');
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private getRows = () => this.props.commodityConditionData;

    public onSubmit = async (values : ICommodityConditionFormValues) => {
        this.setLoading(true);

        try {
            const res = await CommodityConditionHttpService.addOrUpdateCommodityCondition(new CommodityCondition(values));

            const newCommodityConditionList = upsertArrayElement(this.props.commodityConditionData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetCommodityConditions(newCommodityConditionList);
            await setCommodityConditionMasterDataIndexedDB(newCommodityConditionList);

            if (this.state.selectedCommodityCondition) {
                generalShowSuccessSnackbar('Commodity Condition updated successfully.');
            } else {
                generalShowSuccessSnackbar('Commodity Condition added successfully.');
            }

            this.closeDialog();

        } catch (e) {
            if (e.status === 400) {
                generalShowErrorSnackbar(e.data?.Message);
            } else {
                generalShowErrorSnackbar('An error occurred updating Commodity Condition data.');
            }
        } finally {
            this.setLoading(false);
        }
    };

    public onReset = async (formValues : ICommodityConditionFormValues, formikActions : FormikActions<ICommodityConditionFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    private openDeleteConfirmationPopup = (commodityCondition : ICommodityCondition) => {
        this.setState({ isDeletePopupOpen: true, deletingCommodityCondition: commodityCondition });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingCommodityCondition: undefined });
    };

    private removeCommodityCondition = async () => {
        const newCommodityCondition = this.state.deletingCommodityCondition;
        if (newCommodityCondition) {
            newCommodityCondition.isActive = false;
            this.setLoading(true);

            try {
                const res = await CommodityConditionHttpService.deleteCommodityCondition(newCommodityCondition.id);

                const newCommodityConditionList = upsertArrayElement(this.props.commodityConditionData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetCommodityConditions(newCommodityConditionList);
                await setCommodityConditionMasterDataIndexedDB(newCommodityConditionList);

                generalShowSuccessSnackbar('Commodity Condition updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting Commodity Condition.');
                newCommodityCondition.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private getRights = (props : ICommodityConditionScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : ICommodityConditionScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public getSelectedCommodityCondition = (props : ICommodityConditionScreenProps, state : ICommodityConditionScreenState) => state.selectedCommodityCondition;
    public getCommodities = (props : ICommodityConditionScreenProps) => props.commodities;
    public getCommodityConditionData = (props : ICommodityConditionScreenProps) => props.commodityConditionData;

    public getInitialFormValues = createSelector(
        [this.getSelectedCommodityCondition, this.getCommodities, this.getCommodityConditionData],
        (commodityCondition : ICommodityCondition, commodityData : Array<ICommodity>) => {
            return new CommodityConditionFormValues(commodityCondition, true, commodityData);
        },
    );

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedCommodityCondition: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    private onCommodityConditionEdit = (row : ICommodityCondition) => {
        this.setState({
            isDialogOpen: true,
            selectedCommodityCondition: row,
        });
    };

    public render() {
        const rows = this.getRows();
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Commodity Condition'
                    isEdit={!!this.state.selectedCommodityCondition}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={CommodityConditionFormValues.formSchema}
                        component={CommodityConditionForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<ICommodityCondition>
                            addFunction={this.openDialog}
                            editFunction={this.onCommodityConditionEdit}
                            enableAdding={this.hasEditingRight(this.props)}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(commodityCondition : ICommodityCondition) => commodityCondition.isActive}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            fitWidthToPage
                            enableFiltering
                            enablePagination
                            columns={[
                                { title: 'Condition', field: 'condition', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : ICommodityCondition) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Commodity Condition'}
                    open={this.state.isDeletePopupOpen}
                    message={'Are you sure you want to delete this commodity condition?'}
                    onOkClicked={this.removeCommodityCondition}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        commodityConditionData: state.masterData.commodityConditions,
        auth: state.auth,
        commodities: state.masterData.commodities,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetCommodityConditions, dataSetCommodities }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CommodityConditionScreen);

import moment from 'moment';
import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { ICommodity } from '../commodity/commodity';
import { IProject } from './project';
import { DATE_FORMAT_TIMESTAMP_FROM_API } from '../../../../appConstants';

export interface IProjectFormValues {
    id : number;
    guid : string;
    number : string;
    description : string;
    commodity ?: IOptionType;
    isActive : boolean;
    isImported ?: boolean;
    endedOn ?: moment.Moment | null;
}

export class ProjectFormValues implements IProjectFormValues {
    public id : number;
    public guid : string;
    public number : string;
    public description : string;
    public commodity ?: IOptionType;
    public isActive : boolean;
    public isImported ?: boolean;
    public endedOn ?: moment.Moment | null;

    public constructor(
        project ?: IProject,
        commodities ?: Array<ICommodity>,
    ) {
        this.initializeDefaultValues();
        if (project) {
            this.id = project.id;
            this.guid = project.guid;
            this.number = project.number;
            this.description = project.description;
            this.endedOn = !!project.endedOn ? moment(project.endedOn, DATE_FORMAT_TIMESTAMP_FROM_API) : null;

            this.commodity = commodities ? {
                label: commodities.find(x => x.id === project.commodityId)?.name ?? '',
                value: project.commodityId,
            } : undefined;

            this.isActive = project.isActive;
            this.isImported = project.isImported;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.number = '';
        this.description = '';
        this.commodity = undefined;
        this.isActive = true;
        this.isImported = false;
        this.endedOn = null;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        number: Yup.string().required('Required'),
        commodity: Yup.object().nullable().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

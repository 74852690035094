import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IDispatchInstruction } from '../../../@types/model/dispatch/dispatchInstruction';
import { IAddOrRemoveDispatchStock } from '../../../@types/model/dispatch/addOrRemoveDispatchStock';
import { ISetDispatchStatus } from '../../../@types/model/dispatch/setDispatchStatus';
import { ICreateInstructedDispatch } from '../../../@types/model/dispatch/createInstructedDispatch';
import { ISubstituteStock } from '../../../@types/model/dispatch/substituteStock';
import { IStockRelatedData } from '../../../@types/model/stock/stockRelatedData';
import qs from 'qs';
import { IDispatchViewRelatedData } from '../../../@types/model/dispatch/dispatchViewRelatedData';
import { IDispatchSummaryRelatedData } from '../../../@types/model/dispatch/dispatchSummaryRelatedData';
import { IDispatchView } from '../../../@types/model/dispatch/dispatchView';
import { IDispatchDashboardInfo } from '../../../@types/model/dispatch/dispatchDashboardInfo';
import { IDispatchEditRelatedData } from '../../../@types/model/dispatch/dispatchEditRelatedData';
import { IMultiDispatchWizardRelatedData } from '../../../@types/model/dispatch/multiDispatchWizardRelatedData';
import { IMultiDispatchWizardFinalSubmit } from '../../../@types/model/dispatch/multiDispatchWizardFinalSubmit';
import { IDispatchDrafts } from '../../../@types/model/dispatch/dispatchDrafts';
import { IMultiDispatchStockView } from '../../../@types/model/stock/multiDispatchStockView';
import { IDispatchDraftRelatedData } from '../../../@types/model/dispatch/dispatchDraftRelatedData';
import { IDispatchLineRelatedData } from '../../../@types/model/dispatch/dispatchLineRelatedData';
import { IDispatchCorrectionRelatedData } from '../../../@types/model/dispatch/dispatchCorrectionRelatedData';
import { IDispatchRelatedData } from '../../../@types/model/dispatch/dispatchRelatedData';
import { IDispatchDestinationSite } from '../../../@types/model/dispatch/dispatchDestinationSite';
import { ISetDispatchTrip } from '../../../@types/model/dispatch/setDispatchTrip';
import { IEditDispatch } from '../../../@types/model/dispatch/editDispatch';
import { ITrip } from '../../../@types/model/dispatch/trip';
import { ITeraokaCsvData } from '../../../@types/model/integration/teraokaCsvData';
import { VERSION } from '../../../../version';
import { IDispatchLineView } from '../../../@types/model/dispatch/dispatchLineView';
import { IDispatchInstructionAppView } from '../../../@types/model/dispatch/dispatchInstructionAppView';

export default class DispatchHttpService {
    public static getDispatchHeaderData() : AxiosPromise<Array<IDispatchInstruction>> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/GetAllWeb`);
    }

    public static getDispatchAppView(dispatchGuid : string) : AxiosPromise<IDispatchInstructionAppView> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/GetAppDispatch`, {
            params: {
                dispatchGuid,
            },
        });
    }

    public static getDispatchesForSwap() : AxiosPromise<Array<IDispatchView>> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/GetDispatchesForSwap`);
    }

    public static getDispatchesLinkedToTrip(tripId : number) : AxiosPromise<IDispatchRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/GetDispatchesLinkedToTrip`, {
            params: {
                tripId,
            },
        });
    }

    public static getDispatchCorrectionRelatedData(dispatchIds : Array<number>, loadMasterData ?: boolean) : AxiosPromise<IDispatchCorrectionRelatedData> {
        return axios.post(`${PACKMAN_API_URL}Dispatch/GetDispatchCorrectionRelatedData`, { dispatchIds, loadMasterData });
    }

    public static addOrUpdateDispatchHeader(data : IDispatchInstruction) : AxiosPromise<IDispatchInstruction> {
        return axios.post(`${PACKMAN_API_URL}Dispatch/PostDispatch`, data);
    }

    public static editDispatch(data : IEditDispatch) : AxiosPromise<IDispatchInstruction> {
        return axios.post(`${PACKMAN_API_URL}Dispatch/EditDispatch`, data);
    }

    public static getDispatchSignature(id : number) : AxiosPromise<string> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/GetDispatchSignature/${id}`);
    }

    public static addOrRemoveDispatchStock(data : IAddOrRemoveDispatchStock) : AxiosPromise<IDispatchInstruction> {
        return axios.post(`${PACKMAN_API_URL}Dispatch/AddOrRemoveStock`, data);
    }

    public static setDispatchStatus(data : ISetDispatchStatus) : AxiosPromise<IDispatchView> {
        return axios.post(`${PACKMAN_API_URL}Dispatch/SetDispatchStatus`, data);
    }

    public static setDispatchTrip(data : ISetDispatchTrip) : AxiosPromise<IDispatchView> {
        return axios.post(`${PACKMAN_API_URL}Dispatch/SetDispatchTrip`, data);
    }

    public static createMultipleInstructedDispatches(data : IMultiDispatchWizardFinalSubmit) : AxiosPromise<Array<IDispatchInstruction>> {
        return axios.post(`${PACKMAN_API_URL}Dispatch/MultiDispatchWizardSave`, data);
    }

    public static createInstructedDispatch(data : ICreateInstructedDispatch) : AxiosPromise<IDispatchInstruction> {
        return axios.post(`${PACKMAN_API_URL}Dispatch/CreateInstructedDispatch`, data);
    }

    public static createDispatchDrafts(data : IDispatchDrafts) : AxiosPromise<Array<IDispatchInstruction>> {
        return axios.post(`${PACKMAN_API_URL}Dispatch/CreateDispatchDrafts`, data);
    }

    public static getDispatchViewData(dispatchId : number) : AxiosPromise<IDispatchView> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/GetDispatchView`, {
            params: {
                dispatchId,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getDispatchScreenData(fromDateUnix ?: number, toDateUnix ?: number, tripLoadDateUnix ?: number, barcode ?: string, siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<IDispatchViewRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/TransactionView`, {
            params: {
                fromDateUnix,
                toDateUnix,
                tripLoadDateUnix,
                barcode,
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getDispatchDashboardViewData(fromDateUnix ?: number, toDateUnix ?: number, tripLoadDateUnix ?: number, barcode ?: string, siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<IDispatchViewRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/DashboardView`, {
            params: {
                fromDateUnix,
                toDateUnix,
                tripLoadDateUnix,
                barcode,
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getDispatchSummaryData(dispatchId ?: number, loadMasterData ?: boolean) : AxiosPromise<IDispatchSummaryRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/SummaryRelatedData`, {
            params: {
                dispatchId,
                loadMasterData,
            },
        });
    }

    public static getDispatchByBarcode(barcode ?: string) : AxiosPromise<Array<IDispatchView>> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/DispatchByBarcode`, {
            params: {
                barcode,
            },
        });
    }

    public static getDispatchInfoData(dispatchId ?: number, loadMasterData ?: boolean) : AxiosPromise<IDispatchDashboardInfo> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/DispatchInfoData`, {
            params: {
                dispatchId,
                loadMasterData,
            },
        });
    }

    public static getDispatchEditData(dispatchId : number, siteId : number, loadMasterData ?: boolean) : AxiosPromise<IDispatchEditRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/DispatchEditData`, {
            params: {
                dispatchId,
                siteId,
                loadMasterData,
            },
        });
    }

    public static getDispatch(dispatchId ?: number) : AxiosPromise<IDispatchInstruction> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/GetDispatch`, {
            params: {
                dispatchId,
            },
        });
    }

    public static getDispatchSubstitutionData() : AxiosPromise<Array<IDispatchInstruction>> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/GetDispatchLineSubstitutionData`);
    }

    public static getMultiDispatchWizardLocalRelatedData(showMixed : boolean, siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<IMultiDispatchWizardRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/GetMultiDispatchWizardDataLocal`, {
            params: {
                showMixed,
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getMultiDispatchWizardExportRelatedData(showMixed : boolean, siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<IMultiDispatchWizardRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/GetMultiDispatchWizardDataExport`, {
            params: {
                showMixed,
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static refreshMultiDispatchWizardExportData(showMixed : boolean, marketIds ?: Array<number>, packId ?: number, sizeId ?: number, gradeId ?: number, commodityId ?: number, varietyId ?: number, siteIds ?: Array<number>, complianceId ?: number, storageUnitId ?: number) : AxiosPromise<Array<IMultiDispatchStockView>> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/RefreshMultiDispatchWizardDataExport`, {
            params: {
                showMixed,
                marketIds,
                packId,
                sizeId,
                gradeId,
                commodityId,
                varietyId,
                siteIds,
                complianceId,
                storageUnitId,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static refreshMultiDispatchWizardLocalData(showMixed : boolean, marketIds ?: Array<number>, packId ?: number, sizeId ?: number, gradeId ?: number, commodityId ?: number, varietyId ?: number, siteIds ?: Array<number>, storageUnitId ?: number) : AxiosPromise<Array<IMultiDispatchStockView>> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/RefreshMultiDispatchWizardDataLocal`, {
            params: {
                showMixed,
                marketIds,
                packId,
                sizeId,
                gradeId,
                commodityId,
                varietyId,
                siteIds,
                storageUnitId,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getDispatchDrafts(siteIds ?: Array<number>) : AxiosPromise<IDispatchDraftRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/GetDispatchDrafts`, {
            params: {
                siteIds,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getDispatchDashboardData(fromDateUnix ?: number, toDateUnix ?: number, tripLoadDateUnix ?: number, barcode ?: string, siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<IStockRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/Dashboard`, {
            params: {
                fromDateUnix,
                toDateUnix,
                tripLoadDateUnix,
                barcode,
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getDispatchWizardMasterData() : AxiosPromise<IStockRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/WizardMasterData`);
    }

    public static getDispatchWizardStockData(fromDateUnix ?: number, toDateUnix ?: number, tripLoadDateUnix ?: number, sourceSiteId ?: number, storageUnitId ?: number, loadMasterData ?: boolean) : AxiosPromise<IStockRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/WizardStockData`, {
            params: {
                fromDateUnix,
                toDateUnix,
                tripLoadDateUnix,
                sourceSiteId,
                storageUnitId,
                loadMasterData,
            },
        });
    }

    public static getDispatchWizardTrips(loadDateUnix ?: number) : AxiosPromise<Array<ITrip>> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/WizardTrips`, {
            params: {
                loadDateUnix,
            },
        });
    }

    public static getDispatchWizardFinalStepRelatedData(stockIds : Array<number>, tripIds : Array<number>) : AxiosPromise<IStockRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/WizardFinalStepRelatedData`, {
            params: {
                stockIds,
                tripIds,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getPOFileDataForDownload(dispatchId : number, includeOrchards : boolean) : AxiosPromise<{ fileName : string; fileContent : string }> {
        const version = VERSION.version;
        return axios.get(`${PACKMAN_API_URL}Dispatch/GetPOFileDataForDownload`, {
            params: {
                dispatchId,
                version,
                includeOrchards,
            },
        });
    }

    public static setPODownloadedFlag(data : { dispatchId : number }) : AxiosPromise<IDispatchInstruction> {
        return axios.post(`${PACKMAN_API_URL}Dispatch/SetPODownloaded`, data);
    }

    public static getTeraokaCsv(dispatchId : number) : AxiosPromise<ITeraokaCsvData> {
        return axios.get(`${PACKMAN_API_URL}Dispatch/GetTeraokaCSV`, {
            params: {
                dispatchId,
            },
        });
    }

    public static setTeraokaDownloadedFlag(data : { dispatchId : number }) : AxiosPromise<IDispatchInstruction> {
        return axios.post(`${PACKMAN_API_URL}Dispatch/SetTeraokaDownloaded`, data);
    }

    public static updateDispatchDestinationSite(data : IDispatchDestinationSite) : AxiosPromise<IDispatchView> {
        return axios.post(`${PACKMAN_API_URL}Dispatch/ChangeDestination`, data);
    }

    public static dispatchDelete = (dispatchId : number, deleteReason : string) : AxiosPromise<IDispatchView> => {
        return axios.delete(`${PACKMAN_API_URL}Dispatch/Delete`, {
            params : { dispatchId, deleteReason },
        });
    };

    public static deleteDispatchDraft = (dispatchId : number, deleteReason : string) : AxiosPromise<IDispatchInstruction> => {
        return axios.delete(`${PACKMAN_API_URL}Dispatch/DeleteDispatchDraft`, {
            params : { dispatchId, deleteReason },
        });
    };

    // Dispatch Line
    public static getDispatchLineRelatedData(dispatchId ?: number) : AxiosPromise<IDispatchLineRelatedData> {
        return axios.get(`${PACKMAN_API_URL}DispatchLine/GetDispatchLineRelatedData`, {
            params: {
                dispatchId,
            },
        });
    }

    public static setDispatchLineAsSubstitutable(dispatchLineId : number) : AxiosPromise<IDispatchLineView> {
        const data = { dispatchLineId };
        return axios.post(`${PACKMAN_API_URL}DispatchLine/SetAsSubstitutable`, data);
    }

    public static substituteStock(data : ISubstituteStock) : AxiosPromise<IDispatchView> {
        return axios.post(`${PACKMAN_API_URL}DispatchLine/SubstituteStock`, data);
    }

    public static revertDispatchLine(data : { dispatchId : number; stockId : number }) : AxiosPromise<IDispatchView> {
        return axios.post(`${PACKMAN_API_URL}DispatchLine/RevertDispatchLineTransaction`, data);
    }

    public static removeStockFromDispatch(data : { dispatchId : number; stockId : number }) : AxiosPromise<IDispatchInstruction> {
        return axios.post(`${PACKMAN_API_URL}DispatchLine/RemoveStockFromDispatch`, data);
    }

    public static swapDispatchStock(data : { dispatchLineId : number; stockId : number }) : AxiosPromise<Array<IDispatchView>> {
        return axios.post(`${PACKMAN_API_URL}DispatchLine/SwapOutDispatchLineTransaction`, data);
    }
}

import * as React from 'react';
import CustomTable from '../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetMarkets, dataSetRegions } from '../../../store/masterData/Actions';
import { formatDateTime, upsertArrayElement } from '../../../services/appFunctionsService';
import { IOrganization } from '../../../@types/model/masterData/organization/organization';
import { IMarket, Market } from '../../../@types/model/masterData/market/market';
import { IRegion } from '../../../@types/model/masterData/region/region';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import MarketHttpService from '../../../services/http/masterData/marketHttpService';
import RegionHttpService from '../../../services/http/masterData/regionHttpService';
import Screen from '../../../components/Screen';
import MarketForm from './form/MarketForm';
import { Formik, FormikActions } from 'formik';
import { MarketFormValues, IMarketFormValues } from '../../../@types/model/masterData/market/marketFormValues';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { createSelector } from 'reselect';
import { IOptionType } from '../../../@types/helper';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { setMarketsMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IMarketScreenProps extends RouteComponentProps {
    dataSetMarkets : DispatchCall<Array<IMarket>>;
    dataSetRegions : DispatchCall<Array<IRegion>>;
    markets : Array<IMarket>;
    regions : Array<IRegion>;
    organizations : Array<IOrganization>;
    selectedOrganizationIds : Array<number>;
    auth : IAuthState;
}

interface IMarketScreenState {
    rows : Array<IMarket>;
    isLoading : boolean;
    selectedMarket ?: IMarket;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    channelOptions : Array<IOptionType>;
    isDeletePopupOpen : boolean;
    deletingMarket ?: IMarket;
}

class MarketScreen extends React.Component<IMarketScreenProps, IMarketScreenState> {
    constructor(props : IMarketScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedMarket: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
            channelOptions: [
                {
                    label: 'Local',
                    value: 0,
                },
                {
                    label: 'Retail',
                    value: 0,
                },
                {
                    label: 'Export',
                    value: 0,
                },
            ],
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);

            try {
                const res = await MarketHttpService.getMarketData();

                const markets = res.data;
                if (markets) {
                    const res2 = await RegionHttpService.getRegionData();

                    const regions = res2.data;
                    this.props.dataSetRegions(regions);
                    this.props.dataSetMarkets(markets);
                } else {
                    this.props.dataSetMarkets([]);
                }

                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading markets.');
                this.setLoading(false);
            }
        }
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedMarket: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    public editMarket = (market : IMarket) => {
        this.setState({
            isDialogOpen: true,
            selectedMarket: market,
        });
        this.openDialog();
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public onSubmit = async (values : IMarketFormValues) => {
        this.setLoading(true);

        try {
            const res = await MarketHttpService.addOrUpdateMarket(new Market(values));

            const newMarketList = upsertArrayElement(this.props.markets, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetMarkets(newMarketList);
            await setMarketsMasterDataIndexedDB(newMarketList);

            if (this.state.selectedMarket) {
                generalShowSuccessSnackbar('Market updated successfully.');
            } else {
                generalShowSuccessSnackbar('Market added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating market data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (market : IMarket) => {
        this.setState({ isDeletePopupOpen: true, deletingMarket: market });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingMarket: undefined });
    };

    private removeMarket = async () => {
        const newMarket = this.state.deletingMarket;
        if (newMarket) {
            newMarket.isActive = false;
            this.setLoading(true);

            try {
                const res = await MarketHttpService.deleteMarket(newMarket.id);

                const newMarketList = upsertArrayElement(this.props.markets, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetMarkets(newMarketList);
                await setMarketsMasterDataIndexedDB(newMarketList);

                generalShowSuccessSnackbar('Market updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting market.');
                newMarket.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private getRows = () => {
        return this.props.markets.filter((x) => {
            const region = this.props.regions.find(y => y.id === x.regionId && y.isActive);
            return region && region.organizationIds.some(y => this.props.selectedOrganizationIds?.some(z => z === y));
        });
    };

    public onReset = async (formValues : IMarketFormValues, formikActions : FormikActions<IMarketFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    public getSelectedMarket = (props : IMarketScreenProps, state : IMarketScreenState) => state.selectedMarket;
    public getRegions = (props : IMarketScreenProps) => props.regions;
    public getChannelOptions = (props : IMarketScreenProps, state : IMarketScreenState) => state.channelOptions;

    public getInitialFormValues = createSelector(
        [this.getSelectedMarket, this.getRegions, this.getChannelOptions],
        (market : IMarket, regions ?: Array<IRegion>, channelOptions ?: Array<IOptionType>) => {
            return new MarketFormValues(market, regions, channelOptions);
        },
    );

    private getRegionName = (regionId : number) => this.props.regions?.find(x => x.id === regionId)?.name ?? '';
    private getRegionOrgIds = (regionId : number) => this.props.regions?.find(x => x.id === regionId)?.organizationIds ?? [];
    private getRegionOrganizations = (regionId : number) => this.props.organizations
        .filter(x => this.getRegionOrgIds(regionId).some(y => y === x.id))
        .map(x => x.code)?.toString().replace(/,/g, ', ') || '';

    private getRights = (props : IMarketScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IMarketScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.getRows();
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Market'
                    isEdit={!!this.state.selectedMarket}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={MarketFormValues.formSchema}
                        component={MarketForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IMarket>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editMarket}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(market : IMarket) => market.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Region', field: 'regionId', formatFunction: this.getRegionName, enableFiltering: true, enableSorting: true },
                                { title: 'Channel', field: 'channel', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            fitWidthToPage
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IMarket) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Market'}
                    open={this.state.isDeletePopupOpen}
                    message={'Are you sure you want to delete this market?'}
                    onOkClicked={this.removeMarket}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        markets: state.masterData.markets,
        regions: state.masterData.regions,
        organizations: state.masterData.organizations,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetMarkets, dataSetRegions }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MarketScreen);

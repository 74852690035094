import * as React from 'react';
import CustomTable from '../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetOrganizationToEdit } from '../../../store/data/Actions';
import { dataSetOrganizations, dataSetContactInfos } from '../../../store/masterData/Actions';
import { formatDateTime, upsertArrayElement } from '../../../services/appFunctionsService';
import { IOrganization, Organization } from '../../../@types/model/masterData/organization/organization';
import OrganizationHttpService from '../../../services/http/masterData/organizationHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikActions } from 'formik';
import { OrganizationFormValues, IOrganizationFormValues } from '../../../@types/model/masterData/organization/organizationFormValues';
import { createSelector } from 'reselect';
import OrganizationForm from './form/OrganizationForm';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { IContactInfo } from '../../../@types/model/masterData/contactInfo/contactInfo';
import ContactInfoHttpService from '../../../services/http/masterData/contactInfoHttpService';
import { setOrganizationMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IOrganizationScreenProps extends RouteComponentProps {
    dataSetOrganizations : DispatchCall<Array<IOrganization>>;
    dataSetContactInfos : DispatchCall<Array<IContactInfo>>;
    dataSetOrganizationToEdit : DispatchCall<IOrganization | undefined>;
    organizationData : Array<IOrganization>;
    contactInfos : Array<IContactInfo>;
    auth : IAuthState;
}

interface IOrganizationScreenState {
    rows : Array<IOrganization>;
    isLoading : boolean;
    selectedOrganization ?: IOrganization;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingOrganization ?: IOrganization;
}

class OrganizationScreen extends React.Component<IOrganizationScreenProps, IOrganizationScreenState> {
    constructor(props : IOrganizationScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedOrganization: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await OrganizationHttpService.getOrganizationData();
                const res2 = await ContactInfoHttpService.getContactInfoData();
                this.props.dataSetOrganizations(res.data);
                this.props.dataSetContactInfos(res2.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading organizations.');
            }
        }
    };

    public editOrganization = (organization : IOrganization) => {
        this.setState({
            isDialogOpen: true,
            selectedOrganization: organization,
        });
        this.props.dataSetOrganizationToEdit(organization);
        this.openDialog();
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedOrganization: undefined,
        });
        this.props.dataSetOrganizationToEdit(undefined);
    };

    public onReset = async (formValues : IOrganizationFormValues, formikActions : FormikActions<IOrganizationFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public onSubmit = async (values : IOrganizationFormValues) => {
        this.setLoading(true);

        try {
            const res = await OrganizationHttpService.addOrUpdateOrganization(new Organization(values));

            const newOrganizationList = upsertArrayElement(this.props.organizationData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetOrganizations(newOrganizationList);
            await setOrganizationMasterDataIndexedDB(newOrganizationList);

            if (this.state.selectedOrganization) {
                generalShowSuccessSnackbar('Organization updated successfully.');
            } else {
                generalShowSuccessSnackbar('Organization added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating Organization data.');
        } finally {
            this.setLoading(false);
        }
    };

    private formatContactInfos = (contactInfoIds : Array<number>) => this.props.contactInfos
        .filter(x => contactInfoIds.some(y => y === x.id))
        .map(x => x.name).toString().replace(/,/g, ', ');

    private openDeleteConfirmationPopup = (organization : IOrganization) => {
        this.setState({ isDeletePopupOpen: true, deletingOrganization: organization });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingOrganization: undefined });
    };

    private removeOrganization = async () => {
        const newOrganization = this.state.deletingOrganization;
        if (newOrganization) {
            newOrganization.isActive = false;
            this.setLoading(true);

            try {
                const res = await OrganizationHttpService.deleteOrganization(newOrganization.id);

                const newOrganizationList = upsertArrayElement(this.props.organizationData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetOrganizations(newOrganizationList);
                await setOrganizationMasterDataIndexedDB(newOrganizationList);

                generalShowSuccessSnackbar('Organization updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting Organization.');
                newOrganization.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    public getSelectedOrganizationIds = (props : IOrganizationScreenProps, state : IOrganizationScreenState) => state.selectedOrganization;
    public getContactInfos = (props : IOrganizationScreenProps) => props.contactInfos;

    public getInitialFormValues = createSelector(
        [this.getSelectedOrganizationIds, this.getContactInfos],
        (organization : IOrganization, contactInfos : Array<IContactInfo>) => {
            return new OrganizationFormValues(organization, contactInfos);
        },
    );

    private getRights = (props : IOrganizationScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IOrganizationScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.organizationData ? this.props.organizationData : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Organization'
                    isEdit={!!this.state.selectedOrganization}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={OrganizationFormValues.formSchema}
                        component={OrganizationForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IOrganization>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editOrganization}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(organization : IOrganization) => organization.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Supplier?', field: 'isSupplier', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Client?', field: 'isClient', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Can Intake?', field: 'canIntake', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Requires Project?', field: 'requiresProject', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Exporter Code', field: 'exporter', enableFiltering: true, enableSorting: true },
                                { title: 'Billing Party', field: 'billingParty', enableFiltering: true, enableSorting: true },
                                { title: 'Waybill Prefix', field: 'waybillPrefix', enableFiltering: true, enableSorting: true },
                                { title: 'Next Waybill Nr', field: 'nextWaybillNumber', enableFiltering: true, enableSorting: true },
                                { title: 'Waybill Range End', field: 'waybillRangeEnd', enableFiltering: true, enableSorting: true },
                                { title: 'ContactInfos', field: 'contactInfoIds', formatFunction: this.formatContactInfos, width: 200, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IOrganization) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Organization'}
                    open={this.state.isDeletePopupOpen}
                    message={'Are you sure you want to delete this organization?'}
                    onOkClicked={this.removeOrganization}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        organizationData: state.masterData.organizations,
        contactInfos: state.masterData.contactInfos,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetOrganizations, dataSetOrganizationToEdit, dataSetContactInfos }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OrganizationScreen);

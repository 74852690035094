import { IBaseModel } from '../../model';

export interface IFaq extends IBaseModel {
    title : string;
    description : string;
    url : string;
    isIFrame : boolean;
    isStarred : boolean;
}

export class Faq implements IFaq {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public title : string;
    public description : string;
    public url : string;
    public isIFrame : boolean;
    public isStarred : boolean;

    public constructor(formValues : IFaq) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.title = formValues.title;
        this.description = formValues.description;
        this.url = formValues.url;
        this.isIFrame = formValues.isIFrame;
        this.isStarred = formValues.isStarred;
    }
}

import * as React from 'react';
import { navPath } from '../../store/nav/Actions';
import { formatDateTime, upsertArrayElement } from '../../services/appFunctionsService';
import lodash from 'lodash';
import Screen from '../../components/Screen';
import { Accordion, AccordionDetails, AccordionSummary, Card, Checkbox, Icon, IconButton, Paper, Switch, Typography } from '@mui/material';
import PillButton from '../../components/input/PillButton';
import { IFaq } from '../../@types/model/frequentlyAskedQuestions/faq';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import { dataSetFAQs, dataSetInfoBoardData, dataSetContactUsData } from '../../store/data/Actions';
import FrequentlyAskedQuestionsHttpService from '../../services/http/frequentlyAskedQuestions/frequentlyAskedQuestionsHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactPlayer from 'react-player';
import { VERSION } from '../../../version';
import ReactMarkdown from 'react-markdown';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import HomeHttpService from '../../services/http/home/homeHttpService';
import { IInfoBoardData, InfoBoardData } from '../../@types/model/home/infoBoardData';
import { IInfoBoardDataFormValues, InfoBoardDataFormValues } from '../../@types/model/home/infoBoardDataFormValues';
import { Formik, FormikActions } from 'formik';
import InfoBoardEditForm from './form/InfoBoardEditForm';
import { ContactUsFormValues, IContactUsFormValues } from '../../@types/model/home/contactUsFormValues';
import { ContactUs, IContactUs } from '../../@types/model/home/contactUs';
import ContactUsEditForm from './form/ContactUsEditForm';
import TicketCreationPopup from '../../components/TicketCreationPopup';
import { useLocation } from 'react-router-dom';
import { DATE_FORMAT_DEFAULT } from '../../appConstants';
import ConfirmationPrompt from '../../components/dialog/ConfirmationPrompt';
import CustomTooltip from '../../components/tooltip/tooltip';

const HomeScreen = () => {
    const isLoggingIn = useAppSelector(x => x.auth.isLoggingIn);
    const isLoggedIn = useAppSelector(x => x.auth.isLoggedIn);
    const session = useAppSelector(x => x.auth.session);
    const rights = useAppSelector(x => x.auth.session?.user.rights);
    const faqData = useAppSelector(x => x.data.frequentlyAskedQuestions);
    const infoBoardData = useAppSelector(x => x.data.infoBoardData);
    const contactUsData = useAppSelector(x => x.data.contactUsData);
    const path = useLocation().pathname;

    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
    const [isInfoBoardEditDialogOpen, setIsInfoBoardEditDialogOpen] = React.useState<boolean>(false);
    const [isInfoBoardDeletePopupOpen, setIsInfoBoardDeletePopupOpen] = React.useState<boolean>(false);
    const [isContactUsDialogOpen, setIsContactUsDialogOpen] = React.useState<boolean>(false);
    const [isTickerCreationPopupOpen, setIsTickerCreationPopupOpen] = React.useState<boolean>(false);
    const [showInactiveInfoBoardHeadings, setShowInactiveInfoBoardHeadings] = React.useState<boolean>(false);

    const [faqId, setFaqId] = React.useState<number | undefined>(undefined);
    const [selectedInfoBoardItem, setSelectedInfoBoardItem] = React.useState<IInfoBoardData | undefined>(undefined);
    const [selectedContactUsItem, setSelectedContactUsItem] = React.useState<IContactUs | undefined>(undefined);

    const dispatch = useAppDispatch();

    React.useEffect(()  => {
        if (!!session && (isLoggedIn || isLoggingIn)) {
            const getData = async () => {
                setLoading(true);
                await Promise.all([
                    loadFaqs(),
                    loadInfoBoardData(),
                    loadContactUsData(),
                ]);
                setLoading(false);
            };
            getData();
        }
    }, [session, isLoggingIn, isLoggedIn]);

    const loadFaqs = async () => {
        try {
            const res = await FrequentlyAskedQuestionsHttpService.getFaqData();

            dispatch(dataSetFAQs(res.data));
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading frequently asked questions data.');
        }
    };

    const loadInfoBoardData = async () => {
        try {
            const res = await HomeHttpService.getInfoBoardData();

            dispatch(dataSetInfoBoardData(res.data));
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading info board data.');
        }
    };

    const loadContactUsData = async () => {
        try {
            const res = await HomeHttpService.getContactUsData();

            dispatch(dataSetContactUsData(res.data));
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading contact us data.');
        }
    };

    const getInfoBoardInitialFormValues = React.useMemo(() => {
        return new InfoBoardDataFormValues(selectedInfoBoardItem);
    }, [selectedInfoBoardItem]);

    const getContactUsInitialFormValues = React.useMemo(() => {
        return new ContactUsFormValues(selectedContactUsItem);
    }, [selectedContactUsItem]);

    const onInfoBoardReset = async (formValues : IInfoBoardDataFormValues, formikActions : FormikActions<IInfoBoardDataFormValues>) => {
        formikActions.resetForm();
        onInfoBoardEditClose();
    };

    const onContactUsReset = async (formValues : IContactUsFormValues, formikActions : FormikActions<IContactUsFormValues>) => {
        formikActions.resetForm();
        onContactUsDialogClose();
    };

    const onInfoBoardSubmit = async (values : IInfoBoardDataFormValues) => {
        setLoading(true);

        try {
            const res = await HomeHttpService.addOrUpdateInfoBoardData(new InfoBoardData(values));

            const newInfoBoardDataList = upsertArrayElement(infoBoardData, res.data, x => x.id === res.data.id) ?? [];
            dispatch(dataSetInfoBoardData(newInfoBoardDataList));

            if (selectedInfoBoardItem) {
                generalShowSuccessSnackbar('Info board data updated successfully.');
            } else {
                generalShowSuccessSnackbar('Info board data added successfully.');
            }

            onInfoBoardEditClose();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating info board data data.');
        } finally {
            setSelectedInfoBoardItem(undefined);
            setLoading(false);
        }
    };

    const onContactUsSubmit = async (values : IContactUsFormValues) => {
        setLoading(true);

        try {
            const res = await HomeHttpService.addOrUpdateContactUsData(new ContactUs(values));

            const newContactUsDataList = upsertArrayElement(contactUsData, res.data, x => x.id === res.data.id) ?? [];
            dispatch(dataSetContactUsData(newContactUsDataList));

            if (selectedInfoBoardItem) {
                generalShowSuccessSnackbar('Contact Us data updated successfully.');
            } else {
                generalShowSuccessSnackbar('Contact Us data added successfully.');
            }

            onContactUsDialogClose();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating contact us data data.');
        } finally {
            setLoading(false);
        }
    };

    const onInfoBoardSectionDelete = async () => {
        if (selectedInfoBoardItem) {
            setLoading(true);

            const newInfoBoardItem = { ...selectedInfoBoardItem };

            newInfoBoardItem.isActive = false;

            try {
                const res = await HomeHttpService.addOrUpdateInfoBoardData(newInfoBoardItem);

                const newInfoBoardDataList = upsertArrayElement(infoBoardData, res.data, x => x.id === newInfoBoardItem.id) ?? [];
                dispatch(dataSetInfoBoardData(newInfoBoardDataList));

                generalShowSuccessSnackbar('Info board section deleted successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting info board section.');
            } finally {
                setSelectedInfoBoardItem(undefined);
                closeDeletePopup();
                setLoading(false);
            }
        }
    };

    const getFilteredFAQs = React.useMemo(() => {
        if (!faqData) return [];

        return faqData.filter(x => x.isActive).sort((a : IFaq, b : IFaq) => moment(b.updatedOn).diff(moment(a.updatedOn))).slice(0, 3);
    }, [faqData]);

    const toggleExpand = (id : number) => {
        let expanded : boolean = isExpanded;
        if (id === faqId) {
            setIsExpanded(!isExpanded);
            expanded = !isExpanded;
            if (expanded) {
                setFaqId(id);
            } else {
                setFaqId(undefined);
            }
        } else {
            setIsExpanded(!!isExpanded ? true : !isExpanded);
            expanded = !!isExpanded ? true : !isExpanded;
            if (expanded) {
                setFaqId(id);
            } else {
                setFaqId(undefined);
            }
        }
    };

    const getFAQAccordions = (faq : IFaq) => {
        return (
            <Accordion
                key={`faq_#${faq.id}`}
                className={'fdc wfill mb10 br5'}
                expanded={faq.id === faqId}
                onChange={() => toggleExpand(faq.id)}
                style={faq.isActive ? {} : { backgroundColor: 'transparent' }}
            >
                <AccordionSummary className={`flx1 br5 ${faq.id === faqId ? 'bcp cw' : 'bcw cp'}`} expandIcon={<ExpandMoreIcon className={`${faq.id === faqId ? 'cw' : 'cp'}`} />}>
                    <div className={'fdr aic'}>
                        <div className={'flx1 fw500'}>
                            {faq.title}
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={'fdc oya wfill br5 bcw'}>
                    <ReactMarkdown className={'mb20 wfill cblack'} children={faq.description} ></ReactMarkdown>
                    <div className={`${(!faq.url || faq.url === '') ? 'dn' : 'bcg1 PaperBorder p10 hfill wfill oln'}`}>
                        {faq.isIFrame ?
                            <iframe src={faq.url} width='100%' height='480'></iframe>
                            :
                            <ReactPlayer width={'100%'} height={400} url={faq.url} controls={true}/>
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    };

    const hanleCheckboxChecked = (item : IInfoBoardData) => {
        if (item.id === selectedInfoBoardItem?.id) {
            setSelectedInfoBoardItem(undefined);
        } else {
            setSelectedInfoBoardItem(item);
        }
    };

    const getLatestNews = (item : IInfoBoardData) => {
        return (
            <div className={`fdc wfill ${item.body !== '' ? '' : 'dn'}`}>
                <div className={`${item.isActive ? 'bcp cw' : 'bcg2 cp'} wfill h48 aic br5`}>
                    <CustomTooltip title={`${selectedInfoBoardItem && (item.id !== selectedInfoBoardItem?.id) ? 'There is already a section selected' : ''}`}>
                        <div className={`${hasEditingRight ? '' : 'dn'}`}>
                            <Checkbox
                                disabled={(selectedInfoBoardItem && (item.id !== selectedInfoBoardItem?.id))}
                                checked={item.id === selectedInfoBoardItem?.id}
                                onChange={() => hanleCheckboxChecked(item)}
                            />
                        </div>
                    </CustomTooltip>
                    <Typography variant={'h6'} className={'ml10'}>{item.heading}</Typography>
                    <div className={'flx1'}/>
                    <Typography variant={'subtitle1'} className={'ml10'}>{formatDateTime(item.updatedOn ?? '')}</Typography>
                    <div className={'w10'}/>
                    <Typography variant={'subtitle1'} className={'mr10 cred'}>{item.isActive ? '' : 'Inactive'}</Typography>
                </div>
                <div className={'wfill hfill fdc'}>
                    <ReactMarkdown className='cblack' children={item.body} ></ReactMarkdown>
                </div>
            </div>
        );
    };

    const onInfoBoardEditOpen = () => {
        setIsInfoBoardEditDialogOpen(true);
    };

    const onInfoBoardEditClose = () => {
        setIsInfoBoardEditDialogOpen(false);
    };

    const onInfoBoardDelete = () => {
        setIsInfoBoardDeletePopupOpen(true);
    };

    const closeDeletePopup = () => {
        setIsInfoBoardDeletePopupOpen(false);
    };

    const getFilteredInfoBoardData = React.useMemo(() => {
        if (!infoBoardData) return [];
        return infoBoardData?.filter(x => showInactiveInfoBoardHeadings ? !x.isActive : x.isActive)?.sort((a : IInfoBoardData, b : IInfoBoardData) => moment(b.updatedOn).diff(moment(a.updatedOn)));
    }, [infoBoardData, showInactiveInfoBoardHeadings]);

    const onContactUsDialogOpen = (contact : IContactUs) => {
        setIsContactUsDialogOpen(true);
        setSelectedContactUsItem(contact);
    };

    const onContactUsDialogClose = () => {
        setIsContactUsDialogOpen(false);
        setSelectedContactUsItem(undefined);
    };

    const openTickerCreationPopup = () => {
        setIsTickerCreationPopupOpen(true);
    };

    const closeTickerCreationPopup = () => {
        setIsTickerCreationPopupOpen(false);
    };

    const toggleInactiveInfoBoarditems = () => {
        setShowInactiveInfoBoardHeadings(!showInactiveInfoBoardHeadings);
        setSelectedInfoBoardItem(undefined);
    };

    const hasEditingRight = React.useMemo(() => {
        return rights?.some(x => x.isActive && (x.url === path) && x.code.endsWith('_EDIT'));
    }, [rights, path]);

    const infoBoardInitialValues = getInfoBoardInitialFormValues;
    const contactUsInitialValues = getContactUsInitialFormValues;
    const activeInfoBoardHeadings = infoBoardData.filter(x => x.isActive);
    return (
        <Screen isPadded={false} isScrollable={false} isLoading={isLoading}>
            <TicketCreationPopup isOpen={isTickerCreationPopupOpen} onClose={closeTickerCreationPopup}  />
            <div className={'fdr hfill wfill p30'}>
                <div className={'fdc flx1'}>
                    <div className={'fdr jcsb mt10 mb10'}>
                        <Typography variant={'homeHeader'}>FAQ's</Typography>
                        <PillButton
                            className={'mb10 mr10 h35 cpd'}
                            text={'GO TO THE FAQ\'S'}
                            color={'secondary'}
                            onClick={() => navPath('/faq')}
                        />
                    </div>
                    <div className={'wfill'}>
                        {lodash.map(getFilteredFAQs, x => getFAQAccordions(x))}
                    </div>
                    <div className={'flx1'} />
                    <div className={'fdr jcfs aic mt10 mb10'}>
                        <Typography variant={'homeHeader'}>Information</Typography>
                    </div>
                    <div className={'fdr'}>
                        <Card className={'fdc flx1 aic jcc bco'}>
                            <Typography variant={'subtitle1'} className={'cpd fw500'}>Current Version</Typography>
                            <Typography variant={'subtitle1'} className={'cpd fw500'}>{`Last Updated: ${moment(VERSION.versionDateTime).format(DATE_FORMAT_DEFAULT)}`}</Typography>
                            <Typography variant={'h2'} className={'cpd fw700'}>{VERSION.version}</Typography>
                        </Card>
                        <div className={'w10'}/>
                        <Card className={'fdc p10 flx1 bcw'}>
                            <Typography variant={'h6'} className={'cp'}>Please contact us for assistance on anything:</Typography>
                            {contactUsData.map((x) => {
                                return (
                                    <div className={'fdr'}>
                                        <div className={`${hasEditingRight ? '' : 'dn'}`}>
                                            <CustomTooltip title={'Edit'}>
                                                <IconButton className={'cgray4'} size={'small'} onClick={() => onContactUsDialogOpen(x)}><Icon>edit</Icon></IconButton>
                                            </CustomTooltip>
                                        </div>
                                        <Typography className={'cblack'}>{x.email + ' / ' + (x.moreInfo ?? '')}</Typography>
                                    </div>
                                );
                            })}
                        </Card>
                    </div>
                    <div className={'fdr mt10 mb10'}>
                        <PillButton
                            className={'mb10 mr10 h35 cpd'}
                            text={'HAVE A SUGGESTION?'}
                            color={'secondary'}
                            onClick={openTickerCreationPopup}
                        />
                        <PillButton
                            className={'mb10 mr10 h35 cw'}
                            text={'REQUEST ACCESS'}
                            color={'primary'}
                            onClick={openTickerCreationPopup}
                        />
                    </div>
                </div>
                <div className={'w30'} />
                <div className={'fdc flx1'}>
                    <div className={'fdr aic mt10 mb10'}>
                        <Typography variant={'homeHeader'}>What's New?</Typography>
                        <div className={'flx1'} />
                        <div className={`${hasEditingRight ? 'fdr' : 'dn'}`}>
                            <div className={'fdr aic jcc'}>
                                Show Inactive Headings
                                <Switch
                                    color={'primary'}
                                    checked={showInactiveInfoBoardHeadings}
                                    onChange={toggleInactiveInfoBoarditems} />
                            </div>
                            <CustomTooltip title={`${selectedInfoBoardItem ? (showInactiveInfoBoardHeadings ? 'Heading is already inactive' : (activeInfoBoardHeadings?.length < 2 ? 'Cannot delete if there is only one active heading' : 'Delete selected heading')) : (showInactiveInfoBoardHeadings ? 'You are viewing headings that is already inactive' : 'Select which heading to delete') }`}>
                                <IconButton onClick={onInfoBoardDelete} disabled={!selectedInfoBoardItem || (activeInfoBoardHeadings?.length < 2) || showInactiveInfoBoardHeadings}><Icon>delete</Icon></IconButton>
                            </CustomTooltip>
                            <CustomTooltip title={`${selectedInfoBoardItem ? 'Edit heading' : 'Select which heading to edit' }`}>
                                <IconButton onClick={onInfoBoardEditOpen} disabled={!selectedInfoBoardItem}><Icon>edit</Icon></IconButton>
                            </CustomTooltip>
                            <CustomTooltip title={'Add new heading'}>
                                <IconButton onClick={onInfoBoardEditOpen}><Icon>add</Icon></IconButton>
                            </CustomTooltip>
                        </div>
                    </div>
                    <Paper className={'fdc wfill flx1 oya p10 bcw'}>
                        {lodash.map(getFilteredInfoBoardData, item => getLatestNews(item))}
                    </Paper>
                </div>
            </div>
            <PackmanDialog
                title={`${!selectedInfoBoardItem ? 'Add to info board' : 'Packman Info Board'}`}
                maxWidth={'lg'}
                isInfo={!selectedInfoBoardItem}
                isEdit={!!selectedInfoBoardItem}
                isOpen={isInfoBoardEditDialogOpen}
                isLoading={isLoading}
                onClose={onInfoBoardEditClose}>
                <Formik
                    initialValues={infoBoardInitialValues}
                    onSubmit={onInfoBoardSubmit}
                    onReset={onInfoBoardReset}
                    enableReinitialize
                    validationSchema={InfoBoardDataFormValues.formSchema}
                    component={InfoBoardEditForm} />
            </PackmanDialog >
            <PackmanDialog
                title={`${!selectedContactUsItem ? 'Add to contact us' : 'Contact Us'}`}
                maxWidth={'lg'}
                isInfo={!selectedContactUsItem}
                isEdit={!!selectedContactUsItem}
                isOpen={isContactUsDialogOpen}
                isLoading={isLoading}
                onClose={onContactUsDialogClose}>
                <Formik
                    initialValues={contactUsInitialValues}
                    onSubmit={onContactUsSubmit}
                    onReset={onContactUsReset}
                    enableReinitialize
                    validationSchema={ContactUsFormValues.formSchema}
                    component={ContactUsEditForm}/>
            </PackmanDialog >
            <ConfirmationPrompt
                open={isInfoBoardDeletePopupOpen}
                isLoading={isLoading}
                title={'Delete Info Section'}
                message={'Are you sure you want to delete this info section?'}
                onOkClicked={onInfoBoardSectionDelete}
                onCancelClicked={closeDeletePopup}
            />
        </Screen>
    );
};

export default HomeScreen;

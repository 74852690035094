import * as React from 'react';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IOrchard, Orchard } from '../../../../@types/model/masterData/orchard/orchard';
import { IFarm } from '../../../../@types/model/masterData/farm/farm';
import { IVariety } from '../../../../@types/model/masterData/variety/variety';
import { ICommodity } from '../../../../@types/model/masterData/commodity/commodity';
import OrchardHttpService from '../../../../services/http/masterData/orchardHttpService';
import FarmHttpService from '../../../../services/http/masterData/farmHttpService';
import VarietyHttpService from '../../../../services/http/masterData/varietyHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../../store/general/Functions';
import { FormikActions, Formik } from 'formik';
import { upsertArrayElement, formatDateTime } from '../../../../services/appFunctionsService';
import { createSelector } from 'reselect';
import PackmanDialog from '../../../../components/dialog/PackmanDialog';
import CustomTable from '../../../../components/datagrid/CustomTable';
import { dataSetOrchards, dataSetVarieties, dataSetFarms, dataSetCommodities, dataSetDepartments, dataSetProjects } from '../../../../store/masterData/Actions';
import { IOrchardFormValues, OrchardFormValues } from '../../../../@types/model/masterData/orchard/orchardFormValues';
import OrchardForm from './form/OrchardForm';
import { IOptionType } from '../../../../@types/helper';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../../@types/model/user/right';
import { IDepartment } from '../../../../@types/model/masterData/department/department';
import DepartmentHttpService from '../../../../services/http/masterData/departmentHttpService';
import ConfirmationPrompt from '../../../../components/dialog/ConfirmationPrompt';
import { IProject } from '../../../../@types/model/masterData/project/project';
import ProjectHttpService from '../../../../services/http/masterData/projectHttpService';
import { getOrganizationLocalStorage } from '../../../../services/localStorageService';
import moment from 'moment';
import { setOrchardMasterDataIndexedDB, syncMasterData } from '../../../../services/masterDataSyncService';

interface IOrchardScreenProps extends RouteComponentProps {
    dataSetOrchards : DispatchCall<Array<IOrchard>>;
    dataSetFarms : DispatchCall<Array<IFarm>>;
    dataSetVarieties : DispatchCall<Array<IVariety>>;
    dataSetCommodities : DispatchCall<Array<ICommodity>>;
    dataSetDepartments : DispatchCall<Array<IDepartment>>;
    dataSetProjects : DispatchCall<Array<IProject>>;
    orchardData : Array<IOrchard>;
    commodities ?: Array<ICommodity>;
    varieties : Array<IVariety>;
    farms : Array<IFarm>;
    departments : Array<IDepartment>;
    commodityOptions : Array<IOptionType>;
    selectedOrganizationIds : Array<number>;
    selectedCommodity ?: ICommodity;
    auth : IAuthState;
    projects : Array<IProject>;
}

interface IOrchardScreenState {
    rows : Array<IOrchard>;
    isLoading : boolean;
    selectedOrchard ?: IOrchard;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingOrchard ?: IOrchard;
}

class OrchardScreen extends React.Component<IOrchardScreenProps, IOrchardScreenState> {
    constructor(props : IOrchardScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedOrchard: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);

            try {
                const selectedOrganizationIds = getOrganizationLocalStorage();
                const res = await OrchardHttpService.getOrchardData(selectedOrganizationIds);

                const orchardData = res.data;
                if (orchardData) {
                    const res2 = await FarmHttpService.getFarmData(selectedOrganizationIds);
                    const farms = res2.data;
                    this.props.dataSetFarms(farms);

                    const res3 = await VarietyHttpService.getVarietyData();
                    const varieties = res3.data;
                    this.props.dataSetVarieties(varieties);

                    const res4 = await DepartmentHttpService.getDepartmentData();
                    const departments = res4.data;
                    this.props.dataSetDepartments(departments);

                    const res5 = await ProjectHttpService.getProjectData();
                    const projects = res5.data;
                    this.props.dataSetProjects(projects);

                    this.props.dataSetOrchards(orchardData);
                } else {
                    this.props.dataSetOrchards([]);
                }
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading orchards.');
                this.setLoading(false);
            }
        }
    };

    public componentDidUpdate = async (prevProps : IOrchardScreenProps) => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        const nextProps = this.props;
        if (!isIndexedDBAvailable && prevProps.selectedOrganizationIds !== nextProps.selectedOrganizationIds) {
            this.setLoading(true);
            try {
                const res = await OrchardHttpService.getOrchardData(this.props.selectedOrganizationIds);
                const orchardData = res.data;
                if (orchardData) {

                    const res2 = await FarmHttpService.getFarmData(this.props.selectedOrganizationIds);
                    const farms = res2.data;
                    this.props.dataSetFarms(farms);

                    this.props.dataSetOrchards(orchardData);
                } else {
                    this.props.dataSetOrchards([]);
                }
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading orchards.');
                this.setLoading(false);
            }
        }
    };

    public editOrchard = (orchard : IOrchard) => {
        this.setState({
            isDialogOpen: true,
            selectedOrchard: orchard,
        });
        this.openDialog();
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedOrchard: undefined,
        });
    };

    public onReset = async (formValues : IOrchardFormValues, formikActions : FormikActions<IOrchardFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    private getCommodityName = (id : number) => {
        const value = this.props.commodities?.find(x => x.id === id);
        return value && value.name;
    };

    private getVarietyName = (id : number) => {
        const value = this.props.varieties.find(x => x.id === id);
        return value && value.name;
    };

    private getFarmName = (id : number) => {
        const value = this.props.farms.find(x => x.id === id);
        return value && value.name;
    };

    private getDepartmentShortDescription = (id : number) => {
        const value = this.props.departments.find(x => x.id === id);
        return value && value.shortDescription;
    };

    private getProjectNumber = (id : number) => {
        const project = this.props.projects.find(x => x.id === id);

        return project ? project.number : '';
    };

    public onSubmit = async (values : IOrchardFormValues) => {
        this.setLoading(true);

        try {
            const res = await OrchardHttpService.addOrUpdateOrchard(new Orchard(values));

            const newOrchardList = upsertArrayElement(this.props.orchardData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetOrchards(newOrchardList);
            await setOrchardMasterDataIndexedDB(newOrchardList);

            if (this.state.selectedOrchard) {
                generalShowSuccessSnackbar('Orchard updated successfully.');
            } else {
                generalShowSuccessSnackbar('Orchard added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating Orchard data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (orchard : IOrchard) => {
        this.setState({ isDeletePopupOpen: true, deletingOrchard: orchard });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingOrchard: undefined });
    };

    private removeOrchard = async () => {
        const newOrchard = this.state.deletingOrchard;
        if (newOrchard) {
            newOrchard.isActive = false;
            this.setLoading(true);

            try {
                const res = await OrchardHttpService.deleteOrchard(newOrchard.id);

                const newOrchardList = upsertArrayElement(this.props.orchardData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetOrchards(newOrchardList);
                await setOrchardMasterDataIndexedDB(newOrchardList);

                generalShowSuccessSnackbar('Orchard updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting Orchard.');
                newOrchard.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    public getSelectedOrchard = (props : IOrchardScreenProps, state : IOrchardScreenState) => state.selectedOrchard;
    public getCommodities = (props : IOrchardScreenProps) => props.commodities;
    public getVarieties = (props : IOrchardScreenProps) => props.varieties;
    public getFarms = (props : IOrchardScreenProps) => props.farms;
    public getDepartments = (props : IOrchardScreenProps) => props.departments;
    public getProjects = (props : IOrchardScreenProps) => props.projects;
    public getSelectedCommodity = (props : IOrchardScreenProps) => props.selectedCommodity;

    public getInitialFormValues = createSelector(
        [this.getSelectedOrchard, this.getCommodities, this.getVarieties, this.getFarms, this.getDepartments, this.getProjects, this.getSelectedCommodity],
        (orchard : IOrchard, commodities ?: Array<ICommodity> , varieties ?: Array<IVariety>, farms ?: Array<IFarm>, departments ?: Array<IDepartment>, projects ?: Array<IProject>, selectedCommodity ?: ICommodity) => {
            return new OrchardFormValues(orchard, commodities, varieties, farms, departments, projects, selectedCommodity);
        },
    );

    private getRights = (props : IOrchardScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IOrchardScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.orchardData ? this.props.orchardData.filter(x => x.commodityId === this.props.selectedCommodity?.id) : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <>
                <PackmanDialog
                    title='Orchard'
                    isEdit={!!this.state.selectedOrchard}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={!!this.state.selectedOrchard?.isImported ? OrchardFormValues.isImportedformSchema : OrchardFormValues.formSchema}
                        component={OrchardForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IOrchard>
                            enableAdding={!!this.props.selectedCommodity && this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editOrchard}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(orchard : IOrchard) => orchard.isActive && !orchard.isImported && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code' , enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Project Number', field: 'projectId', formatFunction: this.getProjectNumber, enableFiltering: true, enableSorting: true },
                                { title: 'Land Name', field: 'landName', enableFiltering: true, enableSorting: true },
                                { title: 'Sub Block', field: 'subBlock', enableFiltering: true, enableSorting: true },
                                { title: 'Compliance Code', field: 'complianceCode', enableFiltering: true, enableSorting: true },
                                { title: 'Commodity', field: 'commodityId', formatFunction: this.getCommodityName, enableFiltering: true, enableSorting: true },
                                { title: 'Default Variety', field: 'defaultVarietyId', formatFunction: this.getVarietyName, enableFiltering: true, enableSorting: true },
                                { title: 'Farm', field: 'farmId', formatFunction: this.getFarmName, enableFiltering: true, enableSorting: true },
                                { title: 'Department', field: 'departmentId', formatFunction: this.getDepartmentShortDescription, enableFiltering: true, enableSorting: true },
                                { title: 'Planted Hectares', field: 'plantedHectares', enableFiltering: true, enableSorting: true },
                                { title: 'Ended On', field: 'endedOn', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Imported?', field: 'isImported', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction : 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={340}
                            isActive={(row : IOrchard) => row.isActive}
                            warning={(row : IOrchard) => !!row.endedOn && moment(row.endedOn).isBefore(moment().startOf('day'))}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Orchard'}
                    open={this.state.isDeletePopupOpen}
                    message={'Are you sure you want to delete this orchard?'}
                    onOkClicked={this.removeOrchard}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </>
        );
    }
}

const getCommodities = (state : IRootState) => state.masterData.commodities.filter((x : ICommodity) => x.organizationIds?.some(y => state.data.selectedOrganizationIds?.some(z => z === y)));

const getCommodityOptions = createSelector(
    [getCommodities],
    (commodities : Array<ICommodity>) => {
        return commodities.map(x => ({ label: x.name, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        orchardData: state.masterData.orchards,
        varieties: state.masterData.varieties,
        farms: state.masterData.farms,
        commodities: state.masterData.commodities,
        departments: state.masterData.departments,
        projects: state.masterData.projects,
        commodityOptions: getCommodityOptions(state),
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        auth: state.auth,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetOrchards, dataSetVarieties, dataSetFarms, dataSetCommodities, dataSetDepartments, dataSetProjects }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OrchardScreen);

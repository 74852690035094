import { LatLng } from 'leaflet';
import React, { PureComponent } from 'react';
import { GoogleMap, DirectionsRenderer, Marker, withGoogleMap, InfoWindow } from 'react-google-maps';
import { ISite } from '../../@types/model/masterData/site/site';

interface ITripDashboardGoogleMapComponentProps {
    directions ?: Array<{
        tripId : number;
        tripDirection ?: google.maps.DirectionsResult;
        startLocation : google.maps.LatLng;
        endLocation : google.maps.LatLng;
        sourceSite : ISite;
        destinationSite : ISite; }>;
    cleared ?: boolean;
    sites : Array<{
        site : ISite;
        position : LatLng;
    }>;
    mapCenter : LatLng;
}

interface ITripDashboardGoogleMapComponentState {
    showMarker : boolean;
    openMarkerInfo : boolean;
    siteId ?: number;
    directionsRendererOptions : google.maps.DirectionsRendererOptions;
    tripId ?: number;
    showTripInfo : boolean;
}

class TripDashboardGoogleMapComponent extends PureComponent<ITripDashboardGoogleMapComponentProps, ITripDashboardGoogleMapComponentState> {

    constructor(props : ITripDashboardGoogleMapComponentProps) {
        super(props);
        this.state = {
            showMarker : false,
            openMarkerInfo: false,
            directionsRendererOptions: {
                suppressMarkers: true,
            },
            showTripInfo: false,
        };
    }

    public componentDidUpdate = (prevProps : ITripDashboardGoogleMapComponentProps) => {
        if (prevProps.cleared !== this.props.cleared) {
            this.setState({
                showMarker: false,
            });
        }
    };

    private toggleMarkerInfo = (id : number) => {
        if (id === this.state.siteId) {
            this.setState({ openMarkerInfo: !this.state.openMarkerInfo }, () => {
                if (this.state.openMarkerInfo) {
                    this.setState({ siteId: id });
                } else {
                    this.setState({ siteId: undefined });
                }
            });
        } else {
            this.setState({ siteId: undefined, openMarkerInfo: false }, () => {
                this.setState({ openMarkerInfo: !this.state.openMarkerInfo }, () => {
                    if (this.state.openMarkerInfo) {
                        this.setState({ siteId: id });
                    } else {
                        this.setState({ siteId: undefined });
                    }
                });
            });
        }
    };

    private toggleTripInfo = (id : number) => {
        if (id === this.state.tripId) {
            this.setState({ showTripInfo: !this.state.showTripInfo }, () => {
                if (this.state.showTripInfo) {
                    this.setState({ tripId: id });
                } else {
                    this.setState({ tripId: undefined });
                }
            });
        } else {
            this.setState({ tripId: undefined, showTripInfo: false }, () => {
                this.setState({ showTripInfo: !this.state.showTripInfo }, () => {
                    if (this.state.showTripInfo) {
                        this.setState({ tripId: id });
                    } else {
                        this.setState({ tripId: undefined });
                    }
                });
            });
        }
    };

    private getMarkerInfo = (site : { site : ISite; position : LatLng }) => {
        return (
            <InfoWindow key={`site_#${site.site.id}_info_window`} onCloseClick={() => this.toggleMarkerInfo(site.site.id)}>
                <div className={'p10'}>
                    <div className={'mb3 fw500 fs16'}>{`Title: ${site.site.code} - ${site.site.description}`}</div>
                    <div className={`${!site.site.shortDescription ? 'dn' : 'mb3'}`}>{`Short Description: ${site.site.shortDescription}`}</div>
                    <div className={`${!site.site.telNumber ? 'dn' : 'mb3'}`}>{`Tel: ${site.site.telNumber}`}</div>
                    <div className={`${!site.site.faxNumber ? 'dn' : 'mb3'}`}>{`Fax: ${site.site.faxNumber}`}</div>
                    <div className={`${!site.site.packhouseDateCode ? 'dn' : 'mb3'}`}>{`Date Code: ${site.site.packhouseDateCode}`}</div>
                    <div className={`${!site.site.address ? 'dn' : 'fdr mb3'}`}>
                        <div className={'pr5'}>{'Address:'}</div>
                        <div>{site.site.address}</div>
                    </div>
                    <div className={'fdr mb3'}>
                        <div className={'pr5'}>{'Packhouse?:'}</div>
                        <div>{site.site.isPackhouse ? 'Yes' : 'No'}</div>
                    </div>
                    <div className={'fdr mb3'}>
                        <div className={'pr5'}>{'Delivery Point?:'}</div>
                        <div>{site.site.isDeliveryPoint ? 'Yes' : 'No'}</div>
                    </div>
                    <div className={'fdr mb3'}>
                        <div className={'pr5'}>{'BRC Compliant?:'}</div>
                        <div>{site.site.isBRCCompliant ? 'Yes' : 'No'}</div>
                    </div>
                    <div className={'fdr mb3'}>
                        <div className={'pr5'}>{'External?:'}</div>
                        <div>{site.site.isExternal ? 'Yes' : 'No'}</div>
                    </div>
                </div>
            </InfoWindow>
        );
    };

    private getHalfwayPoint = (trip : { startLocation : google.maps.LatLng; endLocation : google.maps.LatLng; tripDirection ?: google.maps.DirectionsResult }) => {
        const stuff = Math.floor((trip.tripDirection?.routes[0].overview_path.length ?? 0) / 2) - 1;
        return trip.tripDirection?.routes[0].overview_path[stuff];
    };

    public render() {
        return (
            <GoogleMap
                defaultZoom={6}
                center={this.props.mapCenter}
            >
                {
                    this.props.directions && this.props.directions.map((trip) => {
                        return (
                            <div onClick={() => this.toggleTripInfo(trip.tripId)}>
                                <DirectionsRenderer directions={trip.tripDirection} options={this.state.directionsRendererOptions}/>
                                {
                                    <InfoWindow position={this.getHalfwayPoint(trip)} zIndex={1000} onCloseClick={() => this.toggleTripInfo(trip.tripId)}>
                                        <div className={'p10'}>
                                            <div className={'mb3 fw500 fs16'}>{`Trip: #${trip.tripId}`}</div>
                                            <div className={'mb3'}>{`Origin Site: ${trip.sourceSite.shortDescription} - ${trip.sourceSite.description}`}</div>
                                            <div className={'mb3'}>{`Destination Site: ${trip.destinationSite.shortDescription} - ${trip.destinationSite.description}`}</div>
                                        </div>
                                    </InfoWindow>
                                }
                            </div>
                        );
                    })
                }
                {this.props.sites.map(x =>
                    <Marker clickable={true} title={`${x.site.code} - ${x.site.description}`} position={x.position} onClick={() => this.toggleMarkerInfo(x.site.id)}>
                        {this.state.openMarkerInfo && x.site.id === this.state.siteId &&
                            this.getMarkerInfo(x)
                        }
                    </Marker>,
                )}
            </GoogleMap>
        );
    }
}

export default withGoogleMap(TripDashboardGoogleMapComponent);

import { IBaseModel } from '../../../model';
import { IUnitOfMeasureTypeFormValues } from './unitOfMeasureTypeFormValues';

export interface IUnitOfMeasureType extends IBaseModel {
    name : string;
}

export class UnitOfMeasureType implements IUnitOfMeasureType {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public name : string;

    public constructor(formValues : IUnitOfMeasureTypeFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.name = formValues.name;
    }
}

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IContactInfo } from '../../../@types/model/masterData/contactInfo/contactInfo';

export default class ContactInfoHttpService {
    public static getContactInfoData() : AxiosPromise<Array<IContactInfo>> {
        return axios.get(`${PACKMAN_API_URL}ContactInfo/GetAllWeb`);
    }

    public static addOrUpdateContactInfo(data : IContactInfo) : AxiosPromise<IContactInfo> {
        return axios.post(`${PACKMAN_API_URL}ContactInfo/PostContactInfo`, data);
    }

    public static deleteContactInfo(contactInfoId : number) : AxiosPromise<IContactInfo> {
        return axios.delete(`${PACKMAN_API_URL}ContactInfo/Delete`, {
            params : { contactInfoId },
        });
    }
}

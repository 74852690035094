import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { LotFormValues } from '../../../@types/model/lot/lotFormValues';
import PillButton from '../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormAutocompleteSelect from '../../../components/input/form/FormAutoCompleteSelect';
import { connect } from 'react-redux';
import { IOptionType } from '../../../@types/helper';
import { IRootState } from '../../../@types/redux';
import { createSelector } from 'reselect';
import { ICommodityState } from '../../../@types/model/masterData/commodityState/commodityState';
import { ILotType } from '../../../@types/model/masterData/lotType/lotType';

interface ILotFormProps {
    isLoading : boolean;
    commodityStateOptions : Array<IOptionType>;
    lotTypeOptions : Array<IOptionType>;
    lotTypes : Array<ILotType>;
}

interface ILotFormState {
}

type LotFormPropsType = ILotFormProps & FormikProps<LotFormValues>;

class LotForm extends React.Component<LotFormPropsType, ILotFormState> {

    constructor(props : LotFormPropsType) {
        super(props);
    }

    private getLotTypeIsSwappable = (id ?: number) => this.props.lotTypes.find(x => x.id === id)?.isSwappable;

    public render() {
        const { initialValues } = this.props;
        return (
            <Form className={'p20 w600'}>
                <FormAutocompleteSelect className={'w300'} name={'commodityState'} label={'Commodity State'} options={this.props.commodityStateOptions} />
                <FormAutocompleteSelect className={'w300'} name={'lotType'} label={'Lot Type'} options={this.props.lotTypeOptions.filter(x => this.getLotTypeIsSwappable(Number(x.value)))} disabled={!this.getLotTypeIsSwappable(Number(initialValues.lotType?.value))} />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const getCommodityStates = (state : IRootState) => state.masterData.commodityStates;

const getCommodityStateOptions = createSelector(
    [getCommodityStates],
    (commodityStates : Array<ICommodityState>) => {
        return commodityStates.map(x => ({ label: x.name, value: x.id }));
    },
);

const getLotTypes = (state : IRootState) => state.masterData.lotTypes;

const getLotTypeOptions = createSelector(
    [getLotTypes],
    (lotTypes : Array<ILotType>) => {
        return lotTypes.map(x => ({ label: x.name, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        commodityStateOptions: getCommodityStateOptions(state),
        lotTypeOptions: getLotTypeOptions(state),
        lotTypes: getLotTypes(state),
    };
};

export default connect(
    mapStateToProps,
)(LotForm);

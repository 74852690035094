import { IBaseModel } from '../../model';
import { IRecipeFormValues } from './form/recipeFormValues';
import { IRecipeLine, RecipeLine } from './recipeLine';

export interface IRecipe extends IBaseModel {
    name : string;
    startingTable ?: string;
    recipeLines : Array<IRecipeLine>;
}

export class Recipe implements IRecipe {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public name : string;
    public startingTable ?: string;
    public recipeLines : Array<IRecipeLine>;

    public constructor(formValues : IRecipeFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.name = formValues.name;
        this.startingTable = formValues.startingTable?.value.toString();
        this.recipeLines = formValues.recipeLines.map(x => new RecipeLine(x));
    }
}

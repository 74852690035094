import { ISize } from './size';
import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { IPack } from '../pack/pack';

export interface ISizeFormValues {
    id : number;
    guid : string;
    code : string;
    name : string;
    packs : Array<IOptionType>;
    isActive : boolean;
}

export class SizeFormValues implements ISizeFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public name : string;
    public packs : Array<IOptionType>;
    public isActive : boolean;

    public constructor(size ?: ISize, packs ?: Array<IPack>) {
        this.initializeDefaultValues();
        if (size) {
            this.id = size.id;
            this.guid = size.guid;
            this.code = size.code;
            this.name = size.name;
            this.isActive = size.isActive;

            this.packs = packs?.filter(x => size.packIds?.some(y => y === x.id)).map(x => ({
                label: x.description,
                value: x.id,
            })) ?? [];
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.name = '';
        this.packs = [];
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

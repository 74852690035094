import * as React from 'react';
import { FormHelperText, PropTypes, Checkbox, FormControlLabel } from '@mui/material';
import { asField, FieldContext, FormValue, FieldProps, FormValues } from 'informed';
import materialTheme from '../../styles/materialTheme';

interface ICustomCheckboxProps extends FieldProps<FormValue, FormValues> {
    onChange ?: (event : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onBlur ?: (event : React.FocusEvent<HTMLElement>) => void;
    initialValue : any;
    id ?: any;
    disabled ?: boolean;
    type ?: any;
    label ?: string;
    margin ?: PropTypes.Margin;
    className ?: string;
    field : string;
    forwardedRef ?: React.RefObject<HTMLInputElement>;
    style ?: React.CSSProperties;
    defaultValue ?: string;
    forceUppercase ?: boolean;
}

class CustomCheckbox extends React.Component<ICustomCheckboxProps & FieldContext<FormValue>> {

    constructor(props : ICustomCheckboxProps & FieldContext<FormValue>) {
        super(props);
    }

    public render() {
        const { onChange, onBlur, forwardedRef,
            id, label, fieldState, fieldApi, tabIndex } = this.props;

        const { value, error } = fieldState;
        const { setValue, setTouched } = fieldApi;

        return (
            <>
                <FormControlLabel
                    labelPlacement={'start'}
                    control={
                        <Checkbox
                            style={ !!value ? { color: materialTheme.palette.primary.main } : {}}
                            id={id}
                            tabIndex={tabIndex}
                            inputRef={forwardedRef}
                            checked={!!value}
                            onChange={(e, checked) => {
                                setValue(checked);
                                if (onChange) {
                                    onChange(e);
                                }
                            }}
                            onBlur={(e) => {
                                setTouched(true);
                                if (!!onBlur) {
                                    onBlur(e);
                                }
                            }}
                        />
                    }
                    label={label}
                />
                {
                    !!fieldApi && !!error ? (
                        <FormHelperText error>{error}</FormHelperText>
                    ) : null
                }
            </>
        );
    }
}

export default asField<ICustomCheckboxProps>(CustomCheckbox);

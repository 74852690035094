import * as React from 'react';
import { IRootState, IDataState } from '../../@types/redux';
import { connect } from 'react-redux';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import { IRole } from '../../@types/model/user/role';

interface IRoleDropdownProps {
    required ?: boolean;

    data : IDataState;

    role ?: IRole;

    onSelected : (role : IRole) => void;
}

interface IRoleDropdownState {
}

class RoleDropdown extends React.Component<IRoleDropdownProps, IRoleDropdownState> {

    constructor(props : IRoleDropdownProps) {
        super(props);
    }

    private onChanged = (event : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
        const result = this.props.data.roles.find(n => n.id === Number(event.target.value));

        if (!!result) this.props.onSelected(result);
    };

    public render() {
        const { data, role } = this.props;
        return (
            <div>
                <FormControl style={{ marginTop: '16px' }} >
                    <InputLabel htmlFor='action'>Role</InputLabel>
                    <Select className={'DropDown mb12'}
                        inputProps={{
                            id: 'action',
                        }}
                        style={{ width: '250px' }}
                        error={!!!role && !!this.props.required}
                        value={!!!role ? '' : `${role.id}`}
                        onChange={this.onChanged}
                    >
                        {!!data.roles && data.roles.map((n) => {
                            return (
                                <MenuItem key={`${n.id}_role_drop`} value={n.id} >
                                    <span>{n.name}</span>
                                </MenuItem>);
                        })}
                    </Select>
                    {
                        !!!role && this.props.required && (
                            <FormHelperText error>Required</FormHelperText>
                        )
                    }
                </FormControl>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        data: state.data,
    };
};

export default connect(
    mapStateToProps,
)(RoleDropdown);

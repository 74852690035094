import axios, { AxiosPromise } from 'axios';
import { IDepartment } from '../../../@types/model/masterData/department/department';
import { PACKMAN_API_URL } from '../../../appConstants';

export default class DepartmentHttpService {
    public static getDepartmentData() : AxiosPromise<Array<IDepartment>> {
        return axios.get(`${PACKMAN_API_URL}Department/GetAllWeb`);
    }

    public static addOrUpdateDepartment(data : IDepartment) : AxiosPromise<IDepartment> {
        return axios.post(`${PACKMAN_API_URL}Department/PostDepartment`, data);
    }

    public static deleteDepartment(departmentId : number) : AxiosPromise<IDepartment> {
        return axios.delete(`${PACKMAN_API_URL}Department/Delete`, {
            params : { departmentId },
        });
    }
}

import axios, { AxiosPromise } from 'axios';
import { IFtpDetail } from '../../../@types/model/masterData/ftpDetail/ftpDetail';
import { PACKMAN_API_URL } from '../../../appConstants';

export default class FtpDetailHttpService {
    public static getFtpDetailData() : AxiosPromise<Array<IFtpDetail>> {
        return axios.get(`${PACKMAN_API_URL}FtpDetail/GetAllWeb`);
    }

    public static addOrUpdateFtpDetail(data : IFtpDetail) : AxiosPromise<IFtpDetail> {
        return axios.post(`${PACKMAN_API_URL}FtpDetail/PostFtpDetail`, data);
    }

    public static deleteFtpDetail(ftpDetailId : number) : AxiosPromise<IFtpDetail> {
        return axios.delete(`${PACKMAN_API_URL}FtpDetail/Delete`, {
            params : { ftpDetailId },
        });
    }
}

import { IContactInfo } from './contactInfo';
import * as Yup from 'yup';

export interface IContactInfoFormValues {
    id : number;
    guid : string;
    isActive : boolean;

    name : string;
    address ?: string;
    telNumber ?: string;
    faxNumber ?: string;
    email ?: string;
}

export class ContactInfoFormValues implements IContactInfoFormValues {
    public id : number;
    public guid : string;
    public isActive : boolean;

    public name : string;
    public address ?: string;
    public telNumber ?: string;
    public faxNumber ?: string;
    public email ?: string;

    public constructor(
        contactInfo ?: IContactInfo,
    ) {
        this.initializeDefaultValues();
        if (contactInfo) {
            this.id = contactInfo.id;
            this.guid = contactInfo.guid;
            this.isActive = contactInfo.isActive;

            this.name = contactInfo.name;
            this.address = contactInfo.address;
            this.telNumber = contactInfo.telNumber;
            this.faxNumber = contactInfo.faxNumber;
            this.email = contactInfo.email;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.isActive = true;

        this.name = '';
        this.address = '';
        this.telNumber = '';
        this.faxNumber = '';
        this.email = '';
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        isActive: Yup.boolean().required('Required'),
        name: Yup.string().required('Required'),
    });
}

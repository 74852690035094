import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { ICountry } from '../../../@types/model/masterData/country/country';

export default class CountryHttpService {
    public static getCountryData() : AxiosPromise<Array<ICountry>> {
        return axios.get(`${PACKMAN_API_URL}Country/GetAllWeb`);
    }

    public static addOrUpdateCountry(data : ICountry) : AxiosPromise<ICountry> {
        return axios.post(`${PACKMAN_API_URL}Country/PostCountry`, data);
    }

    public static deleteCountry(countryId : number) : AxiosPromise<ICountry> {
        return axios.delete(`${PACKMAN_API_URL}Country/Delete`, {
            params : { countryId },
        });
    }
}

import { createAction } from 'typesafe-actions';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IPackCategory } from '../../@types/model/masterData/pack/packCategory';
import { IStock } from '../../@types/model/stock/stock';
import { IStockLine } from '../../@types/model/stock/stockLine';
import { ITempStockUnit } from '../../@types/model/stock/tempStockUnit';
import { IDispatchInstruction } from '../../@types/model/dispatch/dispatchInstruction';
import { IDashboardDispatchInstructionLine } from '../../@types/model/dispatch/dashboardDispatchInstructionLine';
import { ITrip } from '../../@types/model/dispatch/trip';
import { IOrderHeader } from '../../@types/model/order/orderHeader';
import { ICompliance } from '../../@types/model/compliance/compliance';
import { IRight } from '../../@types/model/user/right';
import { IRole } from '../../@types/model/user/role';
import { ILot } from '../../@types/model/lot/lot';
import { IIntake } from '../../@types/model/intake/intake';
import { IBatch } from '../../@types/model/batch/batch';
import { IMaterialStock } from '../../@types/model/materialStock/materialStock';
import { IMaterialDispatch } from '../../@types/model/materialDispatch/materialDispatch';
import { IUniqueCodeObject } from '../../@types/model';
import { IFaq } from '../../@types/model/frequentlyAskedQuestions/faq';
import { IInventory } from '../../@types/model/masterData/inventory/inventory';
import { IInfoBoardData } from '../../@types/model/home/infoBoardData';
import { IContactUs } from '../../@types/model/home/contactUs';
import { ISaleLine } from '../../@types/model/sales/saleLine';
import { ISale } from '../../@types/model/sales/sale';
import { ISaleLineView } from '../../@types/model/sales/saleLineView';
import { IStockView } from '../../@types/model/stock/stockView';
import { IDispatchView } from '../../@types/model/dispatch/dispatchView';
import { IMultiDispatchStockView } from '../../@types/model/stock/multiDispatchStockView';
import { IStockTomatoReportView } from '../../@types/model/stock/StockTomatoReportView';
import { IBatchView } from '../../@types/model/batch/batchView';
import { ILotView } from '../../@types/model/lot/lotView';
import { IIntakeView } from '../../@types/model/intake/intakeView';
import { IRecipe } from '../../@types/model/integration/recipe';
import { IOptionType } from '../../@types/helper';
import { IMaterialStockView } from '../../@types/model/materialStock/materialStockView';
import { IStockViewWithCartons } from '../../@types/model/stock/stockViewWithCartons';
import { IMaterialDispatchView } from '../../@types/model/materialDispatch/materialDispatchView';

/* Global */
export const dataSetSelectedOrganizationIds = createAction('SET_SELECTED_ORGANIZATIONS', resolve => (data ?: Array<number>) => resolve(data));
export const dataSetSelectedSiteIds = createAction('SET_SELECTED_SITES', resolve => (data ?: Array<number>) => resolve(data));
export const dataSetSelectedExporterOrganization = createAction('SET_SELECTED_EXPORTER_ORGANIZATION', resolve => (data : IOrganization | undefined) => resolve(data));
export const dataSetSelectedInventory = createAction('SET_SELECTED_INVENTORY', resolve => (data : IInventory | undefined) => resolve(data));
export const dataSetOrganizationToEdit = createAction('SET_ORGANIZATION_TO_EDIT', resolve => (data : IOrganization | undefined) => resolve(data));

/* Stock */
export const dataSetStocks = createAction('SET_STOCKS', resolve => (data : Array<IStock>) => resolve(data ? data : []));
export const dataSetStockDataViews = createAction('SET_STOCKS_VIEWS', resolve => (data : Array<IStockView>) => resolve(data ? data : []));
export const dataSetStockWithCartonsDataViews = createAction('SET_STOCKS_WITH_CARTONS_VIEWS', resolve => (data : Array<IStockViewWithCartons>) => resolve(data ? data : []));
export const dataSetStockTomatoReportViews = createAction('SET_STOCKS_TOMATO_REPORT_VIEWS', resolve => (data : Array<IStockTomatoReportView>) => resolve(data ? data : []));
export const dataSetStockLines = createAction('SET_STOCKLINES', resolve => (data : Array<IStockLine>) => resolve(data ? data : []));
export const dataSetTempStockUnits = createAction('SET_TEMP_STOCK_UNITS', resolve => (data : Array<ITempStockUnit>) => resolve(data ? data : []));
export const dataSetMultiDispatchStockViews = createAction('SET_MULTI_DISPATCH_STOCKS_VIEWS', resolve => (data : Array<IMultiDispatchStockView>) => resolve(data ? data : []));

/* Dispatch */
export const dataSetDispatchInstructions = createAction('SET_DISPATCH_INSTRUCTIONS', resolve => (data : Array<IDispatchInstruction>) => resolve(data ? data : []));
export const dataSetDispatchViews = createAction('SET_DISPATCH_VIEWS', resolve => (data : Array<IDispatchView>) => resolve(data ? data : []));
export const dataSetDashboardDispatchInstructionLines = createAction('SET_DASHBOARD_DISPATCH_INSTRUCTION_LINES', resolve => (data : Array<IDashboardDispatchInstructionLine>) => resolve(data ? data : []));
export const dataSetDispatchInstructionStocks = createAction('SET_DISPATCH_INSTRUCTION_STOCKS', resolve => (data : Array<IStock>) => resolve(data ? data : []));

/* Trip */
export const dataSetTrips = createAction('SET_TRIPS', resolve => (data : Array<ITrip>) => resolve(data ? data : []));
export const dataSetEditingTrip = createAction('SET_EDITING_TRIP', resolve => (data : boolean) => resolve(data));

/* Stock / Dispatch Dashboards */
export const dataSetDashboardShowOnlyMyDispatches = createAction('SET_DASHBOARD_SHOW_ONLY_MY_DISPATCHES', resolve => (data : boolean) => resolve(data));
export const dataSetDashboardDispatchSelectedLoadDate = createAction('SET_DASHBOARD_DISPATCH_SELECTED_LOAD_DATE', resolve => (data : string) => resolve(data ? data : ''));
export const dataSetDashboardDispatchSelectedDispatchInstructionId = createAction('SET_DASHBOARD_DISPATCH_SELECTED_DISPATCH_INSTRUCTION_INDEX', resolve => (data ?: number) => resolve(data));
export const dataSetStockDashboardSelectedSiteIds = createAction('SET_STOCK_DASHBOARD_SELECTED_SITE_IDS', resolve => (data : Array<number>) => resolve(data ? data : []));
export const dataSetStockDashboardSelectedOrganizations = createAction('SET_STOCK_DASHBOARD_SELECTED_ORGANIZATIONS', resolve => (data : Array<number>) => resolve(data ? data : []));
export const dataSetStockDashboardSelectedCommodities = createAction('SET_STOCK_DASHBOARD_SELECTED_COMMODITIES', resolve => (data : Array<ICommodity>) => resolve(data ? data : []));
export const dataSetStockDashboardSelectedVarieties = createAction('SET_STOCK_DASHBOARD_SELECTED_VARIETIES', resolve => (data : Array<IUniqueCodeObject>) => resolve(data ? data : []));
export const dataSetStockDashboardSelectedGrades = createAction('SET_STOCK_DASHBOARD_SELECTED_GRADES', resolve => (data : Array<IUniqueCodeObject>) => resolve(data ? data : []));
export const dataSetStockDashboardSelectedPackCategories = createAction('SET_STOCK_DASHBOARD_SELECTED_PACK_CATEGORIES', resolve => (data : Array<IPackCategory>) => resolve(data ? data : []));
export const dataSetStockDashboardSelectedColours = createAction('SET_STOCK_DASHBOARD_SELECTED_COLOURS', resolve => (data : Array<IUniqueCodeObject>) => resolve(data ? data : []));

/* Order */
export const dataSetOrderHeaders = createAction('SET_ORDER_HEADERS', resolve => (data : Array<IOrderHeader>) => resolve(data ? data : []));

/* Compliance */
export const dataSetCompliances = createAction('SET_COMPLIANCES', resolve => (data : Array<ICompliance>) => resolve(data ? data : []));

/* Rights */
export const dataSetRights = createAction('SET_RIGHTS', resolve => (rights : Array<IRight>) => resolve(rights));
export const dataSetRoles = createAction('SET_ROLES', resolve => (roles : Array<IRole>) => resolve(roles));

/* Lot */
export const dataSetLots = createAction('SET_LOTS', resolve => (data : Array<ILot>) => resolve(data ? data : []));
export const dataSetLotViews = createAction('SET_LOT_VIEWS', resolve => (data : Array<ILotView>) => resolve(data ? data : []));

/* Intake */
export const dataSetIntakes = createAction('SET_INTAKES', resolve => (data : Array<IIntake>) => resolve(data ? data : []));
export const dataSetIntakeViews = createAction('SET_INTAKE_VIEWS', resolve => (data : Array<IIntakeView>) => resolve(data ? data : []));

/* Batches */
export const dataSetBatches = createAction('SET_BATCHES', resolve => (data : Array<IBatch>) => resolve(data ? data : []));
export const dataSetBatchViews = createAction('SET_BATCH_VIEWS', resolve => (data : Array<IBatchView>) => resolve(data ? data : []));

/* Material */
export const dataSetMaterialStocks = createAction('SET_MATERIAL_STOCKS', resolve => (data : Array<IMaterialStock>) => resolve(data ? data : []));
export const dataSetMaterialStockViews = createAction('SET_MATERIAL_STOCK_VIEWS', resolve => (data : Array<IMaterialStockView>) => resolve(data ? data : []));
export const dataSetMaterialDispatches = createAction('SET_MATERIAL_DISPATCHES', resolve => (data : Array<IMaterialDispatch>) => resolve(data ? data : []));
export const dataSetMaterialDispatchViews = createAction('SET_MATERIAL_DISPATCH_VIEWS', resolve => (data : Array<IMaterialDispatchView>) => resolve(data ? data : []));
export const dataSetDashboardSelectedMaterialDispatchId = createAction('SET_DASHBOARD_SELECTED_MATERIAL_DISPATCH_ID', resolve => (data ?: number) => resolve(data));

/* Frequently Asked Question (FAQ) */
export const dataSetFAQs = createAction('SET_FAQS', resolve => (data : Array<IFaq>) => resolve(data ? data : []));

/* Home */
export const dataSetInfoBoardData = createAction('SET_INFO_BOARD_DATA', resolve => (data : Array<IInfoBoardData>) => resolve(data ? data : []));
export const dataSetContactUsData = createAction('SET_CONTACT_US_DATA', resolve => (data : Array<IContactUs>) => resolve(data ? data : []));

/* Sales */
export const dataSetSales = createAction('SET_SALES', resolve => (data : Array<ISale>) => resolve(data ? data : []));
export const dataSetSaleLines = createAction('SET_SALE_LINES', resolve => (data : Array<ISaleLine>) => resolve(data ? data : []));
export const dataSetSaleLineViewData = createAction('SET_SALE_LINE_VIEW_DATA', resolve => (data : Array<ISaleLineView>) => resolve(data ? data : []));

/* Integrations */
export const dataSetRecipes = createAction('SET_RECIPES', resolve => (data : Array<IRecipe>) => resolve(data ? data : []));
export const dataSetRecipeTables = createAction('SET_RECIPE_TABLES', resolve => (data : Array<IOptionType>) => resolve(data ? data : []));

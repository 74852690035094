import { Icon } from '@mui/material';
import React from 'react';

interface IPopupActionButtonProps {
    text : string;
    onClick ?: () => void;
    icon ?: React.ReactNode;
    isImage ?: boolean;
    imgSource ?: string;
    disabled ?: boolean;
    styleButton ?: string;
    styleText ?: string;
}

export default class PopupActionButton extends React.PureComponent<IPopupActionButtonProps> {
    constructor(props : IPopupActionButtonProps) {
        super(props);
    }

    public render() {
        const { text, onClick, icon, disabled, styleButton, styleText, isImage, imgSource } = this.props;
        return (
            <div className={`${styleButton ? styleButton : ''} ${!disabled ? ' ActionButtonMainDiv curp' : 'bcg2 ActionButtonMainDivDisabled'} aic mb15 fdc p10`} onClick={!disabled ? onClick : undefined}>
                <div className={`ActionButtonIcon${disabled ? 'Disabled' : ''}Div aic jcc fdr`}>
                    {isImage && imgSource ?
                        <img className={'mr5 cs'} height={30} width={30} src={imgSource} />
                        :
                        <Icon className={'cp'}>{icon}</Icon>
                    }
                </div>
                <div className={`${styleText ? styleText : ''} ActionButtonTextDiv cs fw500 p10`}>{text}</div>
            </div>
        );
    }
}

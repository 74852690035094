import * as Yup from 'yup';
import { IFaq } from './faq';

export interface IFaqFormValues {
    id : number;
    guid : string;
    title : string;
    description : string;
    url : string;
    isIFrame : boolean;
    isStarred : boolean;
    isActive : boolean;
}

export class FaqFormValues implements IFaqFormValues {
    public id : number;
    public guid : string;
    public title : string;
    public description : string;
    public url : string;
    public isIFrame : boolean;
    public isStarred : boolean;
    public isActive : boolean;

    public constructor(frequentlyAskedQuestion ?: IFaq) {
        this.initializeDefaultValues();
        if (frequentlyAskedQuestion) {
            this.id = frequentlyAskedQuestion.id;
            this.guid = frequentlyAskedQuestion.guid;
            this.title = frequentlyAskedQuestion.title;
            this.description = frequentlyAskedQuestion.description;
            this.url = frequentlyAskedQuestion.url;
            this.isIFrame = frequentlyAskedQuestion.isIFrame;
            this.isStarred = frequentlyAskedQuestion.isStarred;
            this.isActive = frequentlyAskedQuestion.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.title = '';
        this.description = '';
        this.url = '';
        this.isIFrame = false,
        this.isStarred = false,
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        title: Yup.string().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

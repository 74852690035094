import * as Yup from 'yup';
import { IIntakeLineLayerUnit } from '../intakeLineLayerUnit';

export interface IIntakeLineLayerUnitFormValues {
    id : number;
    guid : string;
    isActive : boolean;

    unitGuid ?: string;
    grossWeight : number;
    nettWeight : number;
    unitGrossWeight : number;
    unitNettWeight : number;
    noOfUnits : number;
}

export class IntakeLineLayerUnitFormValues implements IIntakeLineLayerUnitFormValues {
    public id : number;
    public guid : string;
    public isActive : boolean;

    public unitGuid ?: string;
    public grossWeight : number;
    public nettWeight : number;
    public unitGrossWeight : number;
    public unitNettWeight : number;
    public noOfUnits : number;

    public constructor(intakeLineLayerUnit ?: IIntakeLineLayerUnit) {
        this.initializeDefaultValues();
        if (intakeLineLayerUnit) {
            this.id = intakeLineLayerUnit.id;
            this.guid = intakeLineLayerUnit.guid;
            this.isActive = intakeLineLayerUnit.isActive;

            this.unitGuid = intakeLineLayerUnit.unitGuid;
            this.grossWeight = intakeLineLayerUnit.grossWeight;
            this.nettWeight = intakeLineLayerUnit.nettWeight;
            this.unitGrossWeight = intakeLineLayerUnit.unitGrossWeight;
            this.unitNettWeight = intakeLineLayerUnit.unitNettWeight;
            this.noOfUnits = intakeLineLayerUnit.noOfUnits;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.isActive = true;

        this.unitGuid = undefined;
        this.grossWeight = 0.0;
        this.nettWeight = 0.0;
        this.unitGrossWeight = 0.0;
        this.unitNettWeight = 0.0;
        this.noOfUnits = 0;
    };

    public static formSchema = Yup.object().shape({
        isActive: Yup.boolean().required('Required'),
        grossWeight: Yup.number().positive('Gross Weight should be more than 0').required('Required'),
        nettWeight: Yup.number().positive('Nett Weight should be more than 0').required('Required'),
        unitGrossWeight: Yup.number().min(0).required('Required'),
        unitNettWeight: Yup.number().min(0).required('Required'),
        noOfUnits: Yup.number().positive('No. Units should be more than 0').required('Required'),
    });
}

import React, { SyntheticEvent, ChangeEvent } from 'react';
import { Dialog, Slide, AppBar, DialogContent, Toolbar, IconButton, Typography, CircularProgress, FormControl, TextField, Icon } from '@mui/material';

import * as roleService from '../../../services/right/roleService';
import { IRootState, IDataState } from '../../../@types/redux';
import { connect } from 'react-redux';
import RightsSelect from '../../../components/right/RightsSelectComponent';
import { addArrayElement, removeArrayElement, setArrayElement } from '../../../services/appFunctionsService';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { IRole } from '../../../@types/model/user/role';
import { RIGHT_GRANTS } from '../../../appConstants';
import { forEachRight } from 'lodash';
import { IRightGrant } from '../../../@types/model/user/rightGrant';
import CustomToggleButton from '../../../components/input/CustomToggleButton';

interface IRoleEditDialogProps {
    onClose : (event : SyntheticEvent<{}, Event>) => void;
    onSaved : (entry : IRole) => void;

    open : boolean;

    role ?: IRole;

    data : IDataState;
}

interface IRoleEditDialogState {
    isLoading : boolean;
    selectedRights : Array<IRightGrant>;

    code : string;
    name : string;
    isActive : boolean;
    isMobile : boolean;

    disablePillButton : boolean;
}

class RoleEditDialog extends React.Component<IRoleEditDialogProps, IRoleEditDialogState> {

    constructor(props : IRoleEditDialogProps) {
        super(props);

        this.state = {
            isLoading: false,
            code: !!!props.role ? '' : props.role.code,
            name: !!!props.role ? '' : props.role.name,
            selectedRights : [],
            isActive: true,
            isMobile: false,
            disablePillButton: false,
        };
    }

    public componentDidUpdate = (prevProps : IRoleEditDialogProps) => {
        if (prevProps.role !== this.props.role) {
            this.setInitialValues(this.props.role);

            if (this.props.role) {
                roleService.getRole(this.props.role?.id)
                    .then((result) => {
                        this.setState({
                            selectedRights: result.roleRights?.map((x) => {
                                return { rightId: x.rightId, grant: x.rightGrant };
                            }),
                            isLoading: false,
                        });
                    });
            }
        }

    };

    public setInitialValues = (role ?: IRole) => {
        this.setState({
            code: role?.code ?? '',
            name: role?.name ?? '',
            isActive: role?.isActive ?? true,
        });
    };

    public transition = (props : any) => {
        return <Slide direction='up' {...props} />;
    };

    public close = (event : SyntheticEvent<{}, Event>, save : boolean) => {
        if (save) {
            this.setState({
                isLoading: true,
            });
            this.save().then((result) => {
                this.setState({
                    isLoading: false,
                });

                if (!!result) {
                    this.setState({ name: '', code: '', isActive: false, selectedRights: [], disablePillButton: false, isMobile: false });
                    this.props.onSaved(result);
                }
            });
        } else {
            this.setState({ name: '', code: '', isActive: false, selectedRights: [], disablePillButton: false, isMobile: false });
            this.props.onClose(event);
        }
    };

    public save = () => {
        if (!!!this.state.code) return Promise.resolve(null);
        if (!!!this.state.name) return Promise.resolve(null);

        const role = Object.assign({}, this.props.role);

        role.code = this.state.code;
        role.name = this.state.name;
        role.isActive = this.state.isActive;

        role.roleRights = [...this.state.selectedRights].map((rightGrant) => {
            return {
                rightId: rightGrant.rightId,
                roleId: role.id,
                isActive: true,
                rightGrant: rightGrant.grant,
            };
        });

        role.rights = undefined;

        return roleService.save(role);
    };

    private getRightsGranted = (grant : number) => {
        let remainingGrant = grant;
        const grants : Array<string> = [];
        forEachRight(RIGHT_GRANTS, (x, i) => {
            if ((remainingGrant - Number(i)) >= 0) {
                remainingGrant -= (Number(i));
                grants.push(x);
            }
        });

        return grants;
    };

    private hasGrant = (granted : number, grant : string) => this.getRightsGranted(granted).some(y => y === grant);

    private onRightCheckBoxChecked = (rightId : number, checked : boolean, grant : number) => {
        let selectedRights = [...this.state.selectedRights];

        const index = selectedRights.findIndex(x => x.rightId === rightId);

        if (index !== -1) {
            const granted = selectedRights[index]?.grant ?? 0;

            const alreadyGranted = this.hasGrant(granted, RIGHT_GRANTS[grant]);

            if (!alreadyGranted && checked) {
                selectedRights = setArrayElement(selectedRights, index, { rightId, grant: granted + grant });
            } else if (!checked && alreadyGranted) {
                selectedRights = setArrayElement(selectedRights, index, { rightId, grant: granted - grant });
            }
        }

        this.setState({
            selectedRights,
        });
    };

    private codeChanged = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            code: event.target.value,
        });
    };

    private nameChanged = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            name: event.target.value,
        });
    };

    private isActiveChanged = (event : React.FormEvent<HTMLButtonElement | HTMLInputElement | HTMLAnchorElement>, selected ?: boolean) => {
        this.setState({
            isActive: selected ?? true,
        });
    };

    private submitForm = async (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.close(event, true);
    };

    private onRightSelected = (id : number, checked : boolean) => {
        let selectedRights = [...this.state.selectedRights];

        const index = selectedRights.findIndex(x => x.rightId === id);

        if (checked && index === -1) {
            selectedRights = addArrayElement(selectedRights, { rightId: id, grant: 0 }, 'end');
        } else if (checked && index !== -1) {
            selectedRights = setArrayElement(selectedRights, index, { rightId: id, grant: 0 });
        } else if (!checked && index !== -1) {
            selectedRights = removeArrayElement(selectedRights, index);
        }

        this.setState({
            selectedRights,
        });
    };

    private setPillButtonDisabled = (disablePillButton : boolean = false) => {
        this.setState({ disablePillButton });
    };

    private toggleMobile = (value : boolean) => this.setState({ isMobile: value });

    public render() {
        const { isLoading, code, name, isActive, selectedRights } = this.state;
        const { open, role, onClose } = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    aria-labelledby='role-edit-dialog-title'
                    aria-describedby='role-edit-dialog-description'
                    TransitionComponent={this.transition}
                    transitionDuration={500}
                    fullScreen
                    onClose={onClose}
                >
                    <AppBar className='posr'>
                        <Toolbar>
                            <Typography variant='h6' color='inherit' className='flx1'>
                                {!!!role ? 'Add Role' : 'Edit Role'}
                            </Typography>
                            <IconButton color='inherit' aria-label='Submit' onClick={(event : React.MouseEvent<HTMLButtonElement>) => this.close(event, true)} disabled={this.state.isLoading}>
                                <Icon>done</Icon>
                            </IconButton>
                            <IconButton color='inherit' aria-label='Close' onClick={(event : React.MouseEvent<HTMLButtonElement>) => this.close(event, false)} disabled={this.state.isLoading}>
                                <Icon>close</Icon>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }}>
                        {
                            isLoading &&
                                <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                                    <div className={'posr aic jcc h50 w50'}>
                                        <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                                            <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                                        </div>
                                        <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                                    </div>
                                </div>
                        }
                        {
                            !isLoading &&
                            <div className={'fdc ais'}>
                                <form autoComplete='off' onSubmit={event => this.submitForm(event)} style={{ paddingLeft: '25px' }}>
                                    <div style={{ paddingTop: '15px' }}>
                                        <div className={'fdr aifs'}>
                                            <div className={'flx1'}>
                                                <div className={'aic p5 mb10 pr20'}>
                                                    <FormControl>
                                                        <TextField
                                                            autoComplete='off'
                                                            id='code'
                                                            label='Code'
                                                            value={code}
                                                            onChange={this.codeChanged}
                                                            margin='normal'
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div className={'aic p5 mb10 pr20'}>
                                                    <FormControl>
                                                        <TextField
                                                            autoComplete='off'
                                                            id='name'
                                                            label='Name'
                                                            value={name}
                                                            onChange={this.nameChanged}
                                                            margin='normal'
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div className={'aic p5 mb10 pr20'}>
                                                    <CustomToggleButton
                                                        disabled={!role}
                                                        field={'isActive'}
                                                        label={'Is Active'}
                                                        initialValue={isActive}
                                                        value={isActive}
                                                        onChange={this.isActiveChanged}
                                                        className={'w150'}/>
                                                </div>
                                            </div>
                                            <div className='flx1 aic jcc h90'>
                                                <ToggleButtonGroup exclusive value={this.state.isMobile}>
                                                    <ToggleButton onClick={() => this.toggleMobile(false)} className={`ToggleButtonLeft${!this.state.isMobile ? 'Selected' : ''}`} value={false}>Web</ToggleButton>
                                                    <ToggleButton onClick={() => this.toggleMobile(true)} className={`ToggleButtonRight${this.state.isMobile ? 'Selected' : ''}`} value={true}>Mobile</ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                            <div className={'flx1'}/>
                                        </div>
                                    </div>
                                </form>
                                <RightsSelect selectedRights={selectedRights} onSelected={this.onRightSelected} onCheckBoxChecked={this.onRightCheckBoxChecked} disablePillButton={this.state.isLoading} setPillButtonDisable={this.setPillButtonDisabled} isMobile={this.state.isMobile} />
                            </div>
                        }
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        data: state.data,
    };
};

export default connect(
    mapStateToProps,
)(RoleEditDialog);

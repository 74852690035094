import * as React from 'react';
import CustomTable from '../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetPackCategories } from '../../../store/masterData/Actions';
import { formatDateTime, upsertArrayElement } from '../../../services/appFunctionsService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikActions } from 'formik';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import { IPackCategory, PackCategory } from '../../../@types/model/masterData/pack/packCategory';
import PackCategoryHttpService from '../../../services/http/masterData/packCategoryHttpService';
import { IPackCategoryFormValues, PackCategoryFormValues } from '../../../@types/model/masterData/pack/packCategoryFormValues';
import PackCategoryForm from './form/PackCategoryForm';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { setPackCategoryMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IPackCategoryScreenProps extends RouteComponentProps {
    dataSetPackCategories : DispatchCall<Array<IPackCategory>>;
    packCategoryData : Array<IPackCategory>;
    auth : IAuthState;
}

interface IPackCategoryScreenState {
    rows : Array<IPackCategory>;
    isLoading : boolean;
    selectedPackCategory ?: IPackCategory;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingPackCategory ?: IPackCategory;
}

class PackCategoryScreen extends React.Component<IPackCategoryScreenProps, IPackCategoryScreenState> {
    constructor(props : IPackCategoryScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedPackCategory: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await PackCategoryHttpService.getPackCategoryData();

                this.props.dataSetPackCategories(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading pack categories.');
                this.setLoading(false);
            }
        }
    };

    public editPackConfigCategory = (packCategory : IPackCategory) => {
        this.setState({
            isDialogOpen: true,
            selectedPackCategory: packCategory,
        });
        this.openDialog();
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedPackCategory: undefined,
        });
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public getSelectedPackCategory = (props : IPackCategoryScreenProps, state : IPackCategoryScreenState) => state.selectedPackCategory;

    public getInitialFormValues = createSelector(
        [this.getSelectedPackCategory],
        (packCategory : IPackCategory) => {
            return new PackCategoryFormValues(packCategory);
        },
    );

    public onReset = async (formValues : IPackCategoryFormValues, formikActions : FormikActions<IPackCategoryFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    public onSubmit = async (values : IPackCategoryFormValues) => {
        this.setLoading(true);

        try {
            const res = await PackCategoryHttpService.addOrUpdatePackCategory(new PackCategory(values));

            const newPackCategoryList = upsertArrayElement(this.props.packCategoryData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetPackCategories(newPackCategoryList);
            await setPackCategoryMasterDataIndexedDB(newPackCategoryList);

            if (this.state.selectedPackCategory) {
                generalShowSuccessSnackbar('Pack Category updated successfully.');
            } else {
                generalShowSuccessSnackbar('Pack Category added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating pack category data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (packCategory : IPackCategory) => {
        this.setState({ isDeletePopupOpen: true, deletingPackCategory: packCategory });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingPackCategory: undefined });
    };

    private removePackConfigCategory = async () => {
        const newPackCategory = this.state.deletingPackCategory;
        if (newPackCategory) {
            newPackCategory.isActive = false;
            this.setLoading(true);

            try {
                const res = await PackCategoryHttpService.deletePackCategory(newPackCategory.id);

                const newPackCategoryList = upsertArrayElement(this.props.packCategoryData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetPackCategories(newPackCategoryList);
                await setPackCategoryMasterDataIndexedDB(newPackCategoryList);

                generalShowSuccessSnackbar('Pack Category updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting pack category.');
                newPackCategory.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private getRights = (props : IPackCategoryScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IPackCategoryScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.packCategoryData ? this.props.packCategoryData : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Pack Category'
                    isEdit={!!this.state.selectedPackCategory}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={PackCategoryFormValues.formSchema}
                        component={PackCategoryForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IPackCategory>
                            enableAdding={this.hasEditingRight(this.props)}
                            enableDeleting={(row : IPackCategory) => row.isActive && this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editPackConfigCategory}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Category', field: 'category', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IPackCategory) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Pack Category'}
                    open={this.state.isDeletePopupOpen}
                    message={'Are you sure you want to delete this pack category?'}
                    onOkClicked={this.removePackConfigCategory}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        packCategoryData: state.masterData.packCategories,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetPackCategories }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PackCategoryScreen);

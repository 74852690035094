import { IDevice } from './device';
import * as Yup from 'yup';

export interface IDeviceFormValues {
    id : number;
    guid : string;
    serialNumber : string;
    name : string;
    isActive : boolean;
}

export class DeviceFormValues implements IDeviceFormValues {
    public id : number;
    public guid : string;
    public serialNumber : string;
    public name : string;
    public isActive : boolean;

    public constructor(device ?: IDevice) {
        this.initializeDefaultValues();
        if (device) {
            this.id = device.id;
            this.guid = device.guid;
            this.name = device.name;
            this.serialNumber = device.serialNumber;
            this.isActive = device.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.name = '';
        this.serialNumber = '';
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        name: Yup.string().required('Required').typeError('Cannot be empty'),
        serialNumber: Yup.string().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

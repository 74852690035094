import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { ISite } from '../../../@types/model/masterData/site/site';

export default class SiteHttpService {
    public static getSiteData() : AxiosPromise<Array<ISite>> {
        return axios.get(`${PACKMAN_API_URL}Site/GetAllWeb`);
    }

    public static addOrUpdateSite(data : ISite) : AxiosPromise<ISite> {
        return axios.post(`${PACKMAN_API_URL}Site/PostSite`, data);
    }

    public static deleteSite(siteId : number) : AxiosPromise<ISite> {
        return axios.delete(`${PACKMAN_API_URL}Site/Delete`, {
            params : { siteId },
        });
    }
}

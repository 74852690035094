import * as React from 'react';
import { Typography, Paper, Checkbox, CircularProgress } from '@mui/material';
import { IRootState, IAuthState } from '../../@types/redux';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { connect } from 'react-redux';
import CustomTable from '../../components/datagrid/CustomTable';
import { addArrayElement, isEmptyObject, removeArrayElement, setArrayElement } from '../../services/appFunctionsService';
import moment from 'moment';
import { DATEPICKER_FORMAT_DEFAULT, DATE_FORMAT_CSV } from '../../appConstants';
import { dataSetCompliance } from '../../store/data/Functions';
import CustomTextInput from '../../components/input/CustomTextInput';
import validationFunctions from '../../services/validationService';
import { Form } from 'informed';
import { IFormApi } from '../../@types/other';
import { ICompliance } from '../../@types/model/compliance/compliance';
import { IStock } from '../../@types/model/stock/stock';
import { IComplianceLine } from '../../@types/model/compliance/complianceLine';
import ComplianceHttpService from '../../services/http/compliance/complianceHttpService';
import PillButton from '../../components/input/PillButton';
import { ISite } from '../../@types/model/masterData/site/site';
import { IInspectionPoint } from '../../@types/model/masterData/inspectionPoint/inspectionPoint';
import { IComplianceStatus } from '../../@types/model/compliance/complianceStatus';
import { IComplianceLineInspection } from '../../@types/model/compliance/complianceLineInspection';
import { PackmanLink } from '../../components/link/packmanLink';
import { GridCellEditStopParams, GridCellEditStopReasons, MuiEvent } from '@mui/x-data-grid-pro';

interface IComplianceManualInspectionProps {
    open : boolean;
    compliance ?: ICompliance;
    onClose : (printPdf ?: boolean, newCompliance ?: ICompliance) => void;
    stockData : Array<IStock>;
    sites : Array<ISite>;
    inspectionPoints : Array<IInspectionPoint>;
    auth : IAuthState;
    refreshData : (noAnnounce ?: boolean) => void;
}

interface IComplianceManualInspectionState {
    currentCompliance ?: ICompliance;
    isLoading : boolean;
    selectedStock : Array<number>;
}

class ComplianceManualInspection extends React.Component<IComplianceManualInspectionProps, IComplianceManualInspectionState> {
    private formApi : IFormApi<{ inspector : string }>;
    constructor(props : IComplianceManualInspectionProps) {
        super(props);

        this.state = {
            isLoading: false,
            selectedStock: [],
        };
    }

    private setFormApi = (formApi : IFormApi<{ inspector : string }>) => {
        this.formApi = formApi;
    };

    public componentDidMount() {
        if (this.props.compliance) {
            const currentCompliance = { ...this.props.compliance };
            currentCompliance.upn = this.generateUPN(currentCompliance);
            this.setState({ currentCompliance });
        }
    }

    public componentDidUpdate = (prevProps : IComplianceManualInspectionProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps && prevProps.compliance !== nextProps.compliance) {
            this.setState({ currentCompliance: nextProps.compliance });
        }
    };

    private onClose = (printPdf ?: boolean, newCompliance ?: ICompliance) => {
        this.setState({
            currentCompliance: undefined,
            selectedStock: [],
        });
        this.props.onClose(printPdf, newCompliance);
    };

    private onInspectorIdChange = (event : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
        const inspectorId = event.target.value;
        const currentCompliance = this.state.currentCompliance;
        if (currentCompliance) {
            currentCompliance.inspectorId = inspectorId;
            this.setState({ currentCompliance });
        }
    };

    private onUpnChange = (event : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
        const upn = event.target.value;
        const currentCompliance = this.state.currentCompliance;
        if (currentCompliance) {
            currentCompliance.upn = upn;
            this.setState({ currentCompliance });
        }
    };

    private setLoading = (isLoading : boolean = false) => {
        this.setState({ isLoading });
    };

    private getStockBarcode = (row : IComplianceLine, stock : IStock) => {
        return stock ? <PackmanLink
            type={'transactions'}
            targetPage={'stock'}
            id={stock.id}
            text={stock.barcode} /> : '';
    };

    private generateUPN = (compliance : ICompliance) => {
        const inspectionPoint = this.getInspectionPointCode(compliance.siteId);
        return inspectionPoint + compliance.waybill + moment().utc().format(DATE_FORMAT_CSV);
    };

    private getInspectionPointCode = (siteId ?: number) => {
        const site = this.props.sites.find(x => x.id === siteId);
        return this.props.inspectionPoints.find(x => x.id === site?.inspectionPointId)?.code ?? '';
    };

    private submit = async () => {
        const valid = !this.formApi.getState().invalid;
        if (valid) {
            this.setLoading(true);
            const oldCompliance = this.props.compliance && { ...this.props.compliance };
            const newCompliance = this.state.currentCompliance && { ...this.state.currentCompliance };
            if (newCompliance && oldCompliance) {
                const updatedNewComplianceLines : Array<IComplianceLineInspection> = newCompliance.complianceLines.filter(x => x.isActive).map((line) => {
                    return {
                        id: line.id,
                        reason1: line.rejectReason1,
                        reason2: line.rejectReason2,
                        reason3: line.rejectReason3,
                        isRejected: line.isRejected,

                    };
                });

                const complianceStatus : IComplianceStatus = {
                    id: newCompliance.id,
                    status: this.allApproved() ? 'Approved' : 'Partially Rejected',
                    upn: this.generateUPN(newCompliance),
                    isManualInspection: true,
                    isInspected: true,
                    inspectorId: newCompliance.inspectorId ? Number(newCompliance.inspectorId) : undefined,
                    lines: updatedNewComplianceLines,
                };
                newCompliance.complianceLines.filter(x => x.isActive).forEach((line) => {
                    line.inspectedOn = (this.allApproved() || (line.isRejected && !line.isReplaced)) ? moment().utc().format(DATEPICKER_FORMAT_DEFAULT) : undefined;
                    line.isInspected =  (this.allApproved() || (line.isRejected && !line.isReplaced)) ? true : false;
                    line.updatedOn = undefined;
                });
                newCompliance.upn = newCompliance.upn;
                newCompliance.isManualInspection = true;
                newCompliance.updatedOn = undefined;
                newCompliance.status = this.allApproved() ? 'Approved' : 'Partially Rejected';
                try {
                    const res = await ComplianceHttpService.setComplianceStatus(complianceStatus);

                    if (res && res.data) {
                        dataSetCompliance(newCompliance);
                        this.props.refreshData(true);
                        this.setLoading(false);
                        generalShowSuccessSnackbar('Compliance successfully manually inspected.');
                        this.onClose(true, newCompliance);
                    } else {
                        generalShowErrorSnackbar('An error occurred manually inspecting the compliance.');
                        if (oldCompliance) dataSetCompliance(oldCompliance);
                        this.setLoading(false);
                    }
                } catch (e) {
                    generalShowErrorSnackbar('An error occurred manually inspecting the compliance.');
                    if (oldCompliance) dataSetCompliance(oldCompliance);
                    this.setLoading(false);
                }
            }
        } else {
            generalShowErrorSnackbar('No inspector code provided.');
        }
    };

    private toggleRejected = (row : IComplianceLine, checked : boolean) => {
        const compliance = this.state.currentCompliance && { ...this.state.currentCompliance };
        const selectedStock = this.state.selectedStock && [...this.state.selectedStock];
        if (compliance) {
            const index = compliance.complianceLines.findIndex(x => x.id === row.id);
            if (index !== -1) {
                row.isRejected = checked;
                compliance.complianceLines = setArrayElement(compliance.complianceLines, index, row);
                this.setState({ currentCompliance: compliance });
            }

            const selectedStockIndex = selectedStock.findIndex(x => x === row.id);
            if (checked) {
                if (selectedStockIndex === -1) {
                    this.setState(prevState => ({ selectedStock: addArrayElement(prevState.selectedStock, row.id) }));
                }
            } else {
                if (selectedStockIndex !== -1) {
                    this.setState(prevState => ({ selectedStock: removeArrayElement(prevState.selectedStock, selectedStockIndex) }));
                }
            }
        }
    };

    private onRejectReasonChange = (params : GridCellEditStopParams, event : MuiEvent) => {
        const compliance = this.state.currentCompliance && { ...this.state.currentCompliance };
        if (params.reason === GridCellEditStopReasons.cellFocusOut) {
            event.defaultMuiPrevented = true;
        }
        if (compliance) {
            const index = compliance.complianceLines.findIndex(x => x.id === params.id);
            if (index !== -1) {
                const row = compliance.complianceLines[index];
                switch (params.field) {
                    case 'rejectReason1':
                        row.rejectReason1 = ((event as Event).target as HTMLTextAreaElement)?.value ?? '';
                        break;
                    case 'rejectReason2':
                        row.rejectReason2 = ((event as Event).target as HTMLTextAreaElement)?.value ?? '';
                        break;
                    case 'rejectReason3':
                        row.rejectReason3 = ((event as Event).target as HTMLTextAreaElement)?.value ?? '';
                        break;
                }
                compliance.complianceLines = setArrayElement(compliance.complianceLines, index, row);
                this.setState({ currentCompliance: compliance });
            }
        }

    };

    private allApproved = () => this.state.currentCompliance && !this.state.currentCompliance.complianceLines.some(x => x.isRejected && !x.isReplaced && x.isActive);

    private approvedCount = () => this.state.currentCompliance ? this.state.currentCompliance.complianceLines.filter(x => !x.isRejected && x.isActive).length : 0;
    private rejectedCount = () => this.state.currentCompliance ? this.state.currentCompliance.complianceLines.filter(x => x.isRejected && !x.isReplaced && x.isActive).length : 0;
    private prevRejectedCount = () => this.state.currentCompliance ? this.state.currentCompliance.complianceLines.filter(x => x.isRejected && x.isReplaced && x.isActive).length : 0;

    public render() {
        const compliance = { ...this.state.currentCompliance };
        const touched = this.formApi?.getState().touched;
        const valid = !this.formApi?.getState().invalid;
        return <div className={'fdc hfill'}>
            {this.state.isLoading &&
                <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                    <div className={'posr aic jcc h50 w50'}>
                        <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                            <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                        </div>
                        <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                    </div>
                </div>
            }
            <Form getApi={this.setFormApi}>
                <div className={'fdr m20'}>
                    <div className={'m10 fdr'}>
                        <CustomTextInput
                            type='text'
                            label={'UPN'}
                            field='upn'
                            className={'w250'}
                            validate={validationFunctions.required}
                            validateOnMount
                            validateOnChange
                            validateOnBlur
                            initialValue={this.generateUPN(this.props.compliance ?? {} as ICompliance)}
                            onChange={this.onUpnChange}
                        />
                        <div className={'w10'}/>
                        <CustomTextInput
                            type='text'
                            label={'Inspector Code'}
                            field='inspector'
                            validate={validationFunctions.required}
                            validateOnMount
                            validateOnChange
                            validateOnBlur
                            initialValue={''}
                            onChange={this.onInspectorIdChange}
                        />
                    </div>
                    <div className={'flx1'} />
                    <div className={'fdr p10 aic jcc'}>
                        <div className={'fdc aic jcc'}>
                            <Typography className={'fw500 cpl'}>Approved</Typography>
                            <Typography className={'fs37 fw700 cpl'}>{this.approvedCount()}</Typography>
                        </div>
                        <div className={'w50'}/>
                        <div className={'fdc aic jcc'}>
                            <Typography className={'fw500 cred'}>Rejected</Typography>
                            <Typography className={'fs37 fw700 cred'}>{this.rejectedCount()}</Typography>
                        </div>
                        <div className={'w50'}/>
                        <div className={'fdc aic jcc'}>
                            <Typography className={'fw500 cOrange'}>Previously Rejected</Typography>
                            <Typography className={'fs37 fw700 cOrange'}>{this.prevRejectedCount()}</Typography>
                        </div>
                    </div>
                </div>
            </Form>
            <Paper className={'fdr hfill m20 mxh500'}>
                <CustomTable<IComplianceLine>
                    rows={compliance?.complianceLines?.filter(x => x.isActive && !x.isReplaced) ?? []}
                    fitWidthToPage
                    isCellEditable={params => params.row.isRejected}
                    onCellEditStop={this.onRejectReasonChange}
                    columns={[
                        { field: 'stock', title: 'Barcode', containerComponent: this.getStockBarcode },
                        {
                            field: 'isRejected',
                            title: 'Rejected',
                            containerComponent: (row : IComplianceLine, value : boolean) => <Checkbox
                                checked={value ? value : false}
                                onChange={(e, checked) => this.toggleRejected(row, checked)}
                            />,
                        },
                        {
                            field: 'rejectReason1',
                            title: 'Reject Reason 1',
                            editable: true,
                            // containerComponent: (row : IComplianceLine, value : string) => <TextField
                            //     value={row.isRejected && value ? value : ''}
                            //     onChange={e => this.onRejectReasonChange(e, row, 1)}
                            //     disabled={!row.isRejected}
                            // />,
                        },
                        {
                            field: 'rejectReason2',
                            title: 'Reject Reason 2',
                            editable: true,
                            // containerComponent: (row : IComplianceLine, value : string) => <TextField
                            //     value={row.isRejected && value ? value : ''}
                            //     onChange={e => this.onRejectReasonChange(e, row, 2)}
                            //     disabled={!row.isRejected}
                            // />,
                        },
                        {
                            field: 'rejectReason3',
                            title: 'Reject Reason 3',
                            editable: true,
                            // containerComponent: (row : IComplianceLine, value : string) => <TextField
                            //     value={row.isRejected && value ? value : ''}
                            //     onChange={e => this.onRejectReasonChange(e, row, 3)}
                            //     disabled={!row.isRejected}
                            // />,
                        },
                    ]}
                />
            </Paper>
            <div className={'fdr jcfe mr20'}>
                <PillButton
                    disabled={this.state.isLoading || this.state.selectedStock.length > 1 || !valid}
                    className={'mb10 mr10 h35 cpd'}
                    text={'SUBMIT'}
                    color={'secondary'}
                    onClick={this.submit}
                />
                <PillButton
                    disabled={this.state.isLoading || this.state.selectedStock.length < 1 || isEmptyObject(touched) || !valid}
                    className={'mb10 mr10 h35 cpd'}
                    text={'REJECT'}
                    color={'secondary'}
                    onClick={this.submit}
                />
            </div>
        </div>;
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        stockData: state.data.stocks,
        sites: state.masterData.sites,
        inspectionPoints: state.masterData.inspectionPoints,
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
)(ComplianceManualInspection);

import * as React from 'react';
import {
    dataSetMaterialStockViews,
} from '../../store/data/Actions';
import { RootAction, IRootState, IAuthState, DispatchCall } from '../../@types/redux';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CustomTable, { ICustomTableColumn } from '../../components/datagrid/CustomTable';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { formatDateTime, compareDate, addArrayElement, removeArrayElement } from '../../services/appFunctionsService';
import { Button, Checkbox, Table, TableCell, TableRow, TextField } from '@mui/material';
import { dataSetMaterialStockView } from '../../store/data/Functions';
import ConfirmationPrompt from '../../components/dialog/ConfirmationPrompt';
import { ISite } from '../../@types/model/masterData/site/site';
import { IMaterial } from '../../@types/model/masterData/material/material';
import { IUnitOfMeasure } from '../../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import PillButton from '../../components/input/PillButton';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import MaterialStockSummary from './MaterialStockSummary';
import MaterialStockHttpService from '../../services/http/material/materialStockHttpService';
import TransactionFilter from '../../components/filters/BasicTransactionScreenFilter';
import moment from 'moment';
import Screen from '../../components/Screen';
import { IScrapMaterialStock } from '../../@types/model/materialStock/scrapMaterialStock';
import CustomToggleButton from '../../components/input/CustomToggleButton';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import { createSelector } from 'reselect';
import { CustomChangeEvent, IOptionType } from '../../@types/helper';
import { ICreateMaterialStock } from '../../@types/model/materialStock/createMaterialStock';
import { IMaterialStockView } from '../../@types/model/materialStock/materialStockView';
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_TIMESTAMP_FROM_API } from '../../appConstants';
import { dataSetMaterials, dataSetUnitOfMeasures } from '../../store/masterData/Actions';
import MaterialHttpService from '../../services/http/masterData/materialHttpService';
import UnitOfMeasureHttpService from '../../services/http/masterData/unitOfMeasureHttpService';
import { ListChildComponentProps, FixedSizeList as WindowList } from 'react-window';
import { RouteComponentProps } from 'react-router';
import { navMaterialStock, navPath } from '../../store/nav/Actions';
import { syncMasterData } from '../../services/masterDataSyncService';
import CustomTooltip from '../../components/tooltip/tooltip';
import DeleteConfirmationDialog from '../../components/dialog/DeleteConfirmationDialog';

interface IMaterialStockTableProps extends RouteComponentProps {
    materialStockViews : Array<IMaterialStockView>;
    selectedLoadDate : string;
    sites : Array<ISite>;
    auth : IAuthState;
    dataSetMaterialStockViews : DispatchCall<Array<IMaterialStockView>>;
    dataSetMaterials : DispatchCall<Array<IMaterial>>;
    dataSetUnitOfMeasures : DispatchCall<Array<IUnitOfMeasure>>;
    selectedDispatchIndex ?: number;
    showOnlyMyDispatches : boolean;
    materials : Array<IMaterial>;
    unitOfMeasures : Array<IUnitOfMeasure>;
    selectedSiteIds : Array<number> ;
}

interface IMaterialStockTableState {
    isLoading : boolean;
    dataFetched : boolean;
    rows : Array<IMaterialStockView>;
    columns : Array<ICustomTableColumn>;
    materialStockViewToFinalize ?: IMaterialStockView;
    selectedMaterialStockView ?: IMaterialStockView;
    edittingMaterialStockView ?: IMaterialStockView;
    scrapMaterialStockView ?: IMaterialStockView;
    materialStockAmount ?: number;
    isMaterialStockActive : boolean;
    isStockTakeDialogOpen : boolean;
    isStockIntakeDialogOpen : boolean;
    isStockScrapDialogOpen : boolean;
    isDeleting : boolean;
    deleteItem ?: IMaterialStockView;
    isMaterialStockAmountAllocated : boolean;
    selectedSite ?: IOptionType;
    selectedMaterial ?: IOptionType;
    selectedUnitOfMeasure ?: IOptionType;
    dateCode : string;
    isMassFinalizePopupOpen : boolean;
    checkedMaterialStocksToFinalize : Array<IMaterialStockView>;
    searchedDateCode : string;
    availableMaterialStocksToFinalize : Array<IMaterialStockView>;

    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;
}

class MaterialStockTable extends React.Component<IMaterialStockTableProps, IMaterialStockTableState> {
    constructor(props : IMaterialStockTableProps) {
        super(props);

        this.state = {
            isLoading: false,
            dataFetched: false,
            rows: [],
            columns: this.getColumns(),
            isMaterialStockActive: true,
            isStockTakeDialogOpen: false,
            isStockIntakeDialogOpen: false,
            isStockScrapDialogOpen: false,
            isDeleting: false,
            deleteItem: undefined,
            isMaterialStockAmountAllocated: false,
            dateCode: '',
            isMassFinalizePopupOpen: false,
            checkedMaterialStocksToFinalize: [],
            searchedDateCode: '',
            availableMaterialStocksToFinalize: [],

            selectedFromDate: moment().local().startOf('day').subtract(7, 'days'),
            selectedToDate: moment().local().endOf('day'),
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        if (this.props.location.pathname?.includes(':')) {
            this.openSummaryDialog(Number(this.props.location.pathname.split(':')[1].split('/')[0]));
        } else {
            // checks if indexedDB is available.
            const isIndexedDBAvailable = !!self.indexedDB ? true : false;

            if (isIndexedDBAvailable) {
                await syncMasterData(false);
            }

            try {
                const res = await MaterialStockHttpService.getMaterialStockTransactionViews(this.getDate('from'), this.getDate('to'), this.props.selectedSiteIds);

                if (!isIndexedDBAvailable) {
                    const res2 = await MaterialHttpService.getMaterialData();
                    const res3 = await UnitOfMeasureHttpService.getUnitOfMeasureData();

                    this.props.dataSetMaterials(res2.data);
                    this.props.dataSetUnitOfMeasures(res3.data);
                }

                this.props.dataSetMaterialStockViews(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading material stock data.');
                this.setLoading(false);
            }
        }
    };

    public componentDidUpdate = (prevProps : IMaterialStockTableProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            if (prevProps.location.pathname?.includes(':') && !nextProps.location.pathname?.includes(':')) {
                this.closeSummaryDialog();
            }
            if (!prevProps.location.pathname?.includes(':') && nextProps.location.pathname?.includes(':')) {
                this.openSummaryDialog(Number(nextProps.location.pathname.split(':')[1].split('/')[0]));
            }
            if (prevProps.location.pathname?.includes(':') && nextProps.location.pathname?.includes(':')) {
                const prevId = prevProps.location.pathname.split(':')[1].split('/')[0];
                const nextId = nextProps.location.pathname.split(':')[1].split('/')[0];
                if (prevId !== nextId) {
                    this.openSummaryDialog(Number(nextId));
                }
            }
            if (prevProps.materialStockViews !== nextProps.materialStockViews) {
                this.setState({ rows: this.filterData() });
            }
            if (prevProps.selectedSiteIds !== undefined && nextProps.selectedSiteIds !== undefined && prevProps.selectedSiteIds !== nextProps.selectedSiteIds) {
                this.refreshData();
            }
        }
    };

    private getSiteDescription = (id : number) => {
        const rows = this.props.sites;
        const obj = rows && rows.find(x => x.id === id);
        return obj ? obj.description : '';
    };

    private getMaterialCodeName = (materialStockid : number) => {
        const materialStockView = this.props.materialStockViews.find(x => x.id === materialStockid);

        return materialStockView ? `(${materialStockView.materialCode}) ${materialStockView.materialName}` : '';
    };

    private openStockScrapDialog = (materialStockView : IMaterialStockView) => {
        this.setState({ scrapMaterialStockView : materialStockView, isStockScrapDialogOpen: true });
    };

    private closeStockScrapDialog = () => {
        this.setState({ scrapMaterialStockView : undefined, materialStockAmount : undefined, isStockScrapDialogOpen: false });
    };

    private onStockScrapSubmit = async () => {
        const guid = this.state.scrapMaterialStockView?.guid;
        const amount = this.state.materialStockAmount;
        const allocated = this.state.isMaterialStockAmountAllocated;

        if (guid && amount) {

            const data : IScrapMaterialStock = {
                materialStockGuid: guid,
                scrapAmount: amount,
                isAllocated: allocated,
            };

            this.setLoading(true);
            try {
                const res = await MaterialStockHttpService.materialStockScrapped(data);

                dataSetMaterialStockView(res.data);
                this.closeStockScrapDialog();
                this.setLoading(false);
                generalShowSuccessSnackbar('Material stock scrapped successfully');
            } catch (e) {
                this.closeStockScrapDialog();
                generalShowErrorSnackbar('An error occurred scrapping material stock');
                this.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Material stock was not specified');
        }
    };

    private getColumns = () => {
        const columns : Array<ICustomTableColumn> = [
            { title: 'Finalize', field: 'id',
                containerComponent: (row : IMaterialStockView) => {
                    return (
                        row.isFinalized || !row.isActive
                            ?
                            <div className={'mnh48'}></div>
                            :
                            <div className={'wfill hfill jcc aic'}>
                                <PillButton
                                    className={'pl10 pr10 h35 zi0 w100 reducedPillButtonShadow'}
                                    text={'Finalize'}
                                    type={'submit'}
                                    color={'secondary'}
                                    onClick={() => this.finalizeMaterialStockButtonPressed(row)}
                                />
                            </div>
                    );
                },
            },
            { title: 'Scrap', field: 'id',
                containerComponent: (row : IMaterialStockView) => {
                    return (
                        row.amountOnHand === 0 || !row.isActive
                            ?
                            <div className={'mnh48'}></div>
                            :
                            <div className={'wfill hfill jcc aic'}>
                                <PillButton
                                    className={'pl10 pr10 h35 zi0 w100 reducedPillButtonShadow'}
                                    text={'Scrap'}
                                    type={'submit'}
                                    color={'secondary'}
                                    onClick={() => this.openStockScrapDialog(row)}
                                />
                            </div>
                    );
                },
            },
            { title: 'Material', field: 'id', formatFunction: this.getMaterialCodeName, width: 200, enableFiltering: true, enableSorting: true },
            { title: 'Site', field: 'siteId', formatFunction: this.getSiteDescription, enableFiltering: true, enableSorting: true },
            { title: 'Date Code', field: 'dateCode', enableFiltering: true, enableSorting: true },
            { title: 'Amount Intook', field: 'amountIntook', enableFiltering: true, enableSorting: true },
            { title: 'Amount Allocated', field: 'amountAllocated', enableFiltering: true, enableSorting: true },
            { title: 'Amount Unallocated', field: 'amountUnAllocated', enableFiltering: true, enableSorting: true },
            { title: 'Amount Scrapped', field: 'amountScrapped', enableFiltering: true, enableSorting: true },
            { title: 'Amount On-Hand', field: 'amountOnHand', enableFiltering: true, enableSorting: true },
            { title: 'Amount Total', field: 'amountTotal', enableFiltering: true, enableSorting: true },
            { title: 'Unit of Measure', field: 'unitOfMeasureCode', enableFiltering: true, enableSorting: true },
            { title: 'Finalized?', field: 'isFinalized', type: 'boolean', enableFiltering: true, enableSorting: true },
            { title: 'Auto Finalized?', field: 'isAutoFinalized', type: 'boolean', enableFiltering: true, enableSorting: true },
            { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
            { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
            { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
        ];
        return columns;
    };

    private filterData = () => {
        const rows = [...this.props.materialStockViews];
        return rows;
    };

    private setLoading = (isLoading : boolean = false) => this.setState({ isLoading });

    private refreshData = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;
        try {
            const res = await MaterialStockHttpService.getMaterialStockTransactionViews(this.getDate('from'), this.getDate('to'), this.props.selectedSiteIds);

            if (!isIndexedDBAvailable) {
                const res2 = await MaterialHttpService.getMaterialData();
                const res3 = await UnitOfMeasureHttpService.getUnitOfMeasureData();

                this.props.dataSetMaterials(res2.data);
                this.props.dataSetUnitOfMeasures(res3.data);
            }

            this.props.dataSetMaterialStockViews(res.data);
            this.setState({ rows: this.filterData() }, () => {
                this.setLoading(false);
            });
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading material stocks.');
            this.setLoading(false);
        }
    };

    private getDate = (type : 'from' | 'to') => {
        switch (type) {
            case 'from':
                return this.state.selectedFromDate.startOf('day').utc().unix() * 1000;
            case 'to':
                return this.state.selectedToDate.endOf('day').utc().unix() * 1000;
        }
    };

    private finalizeMaterialStockButtonPressed = (materialStockViewToFinalize : IMaterialStockView) => this.setState({ materialStockViewToFinalize });

    private submitFinalize = async () => {
        this.setLoading(true);
        const materialStockViewToFinalize = this.state.materialStockViewToFinalize;
        if (materialStockViewToFinalize) {

            try {
                const res = await MaterialStockHttpService.finalizeMaterialStock(materialStockViewToFinalize.guid);

                dataSetMaterialStockView(res.data);

                this.setState({ materialStockViewToFinalize: undefined });
                this.setLoading(false);
                generalShowSuccessSnackbar('Material stock finalized!');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred finalizing the material stock.');
                this.setLoading(false);
            }
        }
    };

    private noClicked = () => this.setState({ materialStockViewToFinalize: undefined });

    private openSummaryDialog = async (id : number) => {

        try {
            const res = await MaterialStockHttpService.getMaterialStockView(id);
            if (res) {
                this.setState({ selectedMaterialStockView: res.data });
            }

        } catch (e) {
            if (e.data && e.data !== '') {
                generalShowErrorSnackbar(e.data);
            } else {
                generalShowErrorSnackbar('Error occured while attempting to show batch summary');
            }
        }
    };

    public closeSummaryDialog = () => {
        this.setState({
            selectedMaterialStockView: undefined,
        }, () => navPath(this.props.location?.pathname?.split('/:')[0]));
    };

    private handleDateRangeChange = (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => {
        const selectedFromDate = changedBy === 'start' ? moment(start).startOf('day') : end < start ? moment(end).startOf('day') : moment(start).startOf('day');
        const selectedToDate = changedBy === 'end' ? moment(end).endOf('day') : start > end ? moment(start).endOf('day') : moment(end).endOf('day');
        this.setState({ selectedFromDate, selectedToDate });
    };

    private onApplyClick = () => {
        this.setState({ isLoading: true }, () => {
            this.refreshData();
        });

        this.setState({
            isLoading: false,
        });
    };

    private openEditDialog = (edittingMaterialStockView : IMaterialStockView) => {
        this.setState({ isStockTakeDialogOpen: true, edittingMaterialStockView, isMaterialStockActive: edittingMaterialStockView.isActive });
    };

    private closeEditDialog = () => {
        this.setState({ edittingMaterialStockView : undefined, materialStockAmount : undefined, isStockTakeDialogOpen: false, isMaterialStockActive: true });
    };

    private reActivateMaterialStock = async () => {
        if (this.state.edittingMaterialStockView) {
            this.setLoading(true);
            try {
                const res = await MaterialStockHttpService.reActivateMaterialStock(this.state.edittingMaterialStockView.id);

                if (res && res.data) {
                    this.setState({ edittingMaterialStockView : undefined, materialStockAmount : undefined, isStockTakeDialogOpen: false, isMaterialStockActive: true });
                    dataSetMaterialStockView(res.data);
                }
                this.setLoading(false);
                generalShowSuccessSnackbar('Material stock updated successfully');
            } catch (e) {
                this.setState({ edittingMaterialStockView : undefined, materialStockAmount : undefined, isStockTakeDialogOpen: false });
                generalShowErrorSnackbar('An error occurred updating material stock');
                this.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Material stock was not specified');
        }
    };

    private submitEdit = async () => {
        const guid = this.state.edittingMaterialStockView?.guid;
        const amount = this.state.materialStockAmount;

        if (guid && amount) {
            this.setLoading(true);
            try {
                const res = await MaterialStockHttpService.materialStockTake(guid, amount);

                if (res && res.data) {
                    this.setState({ edittingMaterialStockView : undefined, materialStockAmount : undefined, isStockTakeDialogOpen: false, isMaterialStockActive: true });
                    this.refreshData();
                }
                this.setLoading(false);
                generalShowSuccessSnackbar('Material stock updated successfully');
            } catch (e) {
                this.setState({ edittingMaterialStockView : undefined, materialStockAmount : undefined, isStockTakeDialogOpen: false });
                generalShowErrorSnackbar('An error occurred updating material stock');
                this.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Material stock was not specified');
        }
    };

    private openMaterialStockAddDialog = () => {
        const selectedSite = this.props.selectedSiteIds?.length === 1 ? this.getSite(this.props.selectedSiteIds[0]) : undefined;

        const site = !!selectedSite ? { label: `(${selectedSite.shortDescription}) ` + selectedSite.description, value: selectedSite.id } : undefined;
        this.setState({ isStockIntakeDialogOpen: true, selectedSite: site });
    };

    private closeMaterialStockAddDialog = () => {
        this.setState({ isStockIntakeDialogOpen: false, selectedSite: undefined, selectedMaterial: undefined, selectedUnitOfMeasure: undefined, materialStockAmount: undefined, dateCode: '' });
    };

    private onMaterialStockCreate = async () => {
        const amount = this.state.materialStockAmount;
        const siteGuid = this.props.sites.find(x => x.id === this.state.selectedSite?.value)?.guid;
        const materialGuid = this.props.materials.find(x => x.id === this.state.selectedMaterial?.value)?.guid;
        const unitOfMeasureGuid = this.props.unitOfMeasures.find(x => x.id === this.state.selectedUnitOfMeasure?.value)?.guid;

        if (siteGuid && materialGuid && unitOfMeasureGuid && amount) {

            const data : ICreateMaterialStock = {
                guid: '',
                siteGuid,
                materialGuid,
                unitOfMeasureGuid,
                amount,
                dateCode: this.state.dateCode,
                submittedOn: moment().local().startOf('day'),
            };

            this.setLoading(true);
            try {
                const res = await MaterialStockHttpService.materialStockIntake(data);

                if (res && res.data) {
                    res.data.forEach((x) => {
                        dataSetMaterialStockView(x);
                    });
                    this.closeMaterialStockAddDialog();
                }
                this.setLoading(false);
                generalShowSuccessSnackbar('Material stock created successfully');
            } catch (e) {
                this.closeMaterialStockAddDialog();
                generalShowErrorSnackbar('An error occurred creating material stock');
                this.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Material stock was not specified');
        }
    };

    private setDeleteItem = (item ?: IMaterialStockView) => {
        this.setState({
            deleteItem: item,
            isDeleting: true,
        });
    };

    private onMaterialStockDelete = async (deleteReason : string) => {
        const materialStock = this.state.deleteItem;

        if (materialStock) {
            if (deleteReason !== '' && deleteReason.length >= 200) {
                this.setLoading(true);

                try {
                    const res = await MaterialStockHttpService.deleteMaterialStock(materialStock.id, deleteReason);

                    if (res && res.data) {
                        dataSetMaterialStockView(res.data);
                    }
                    generalShowSuccessSnackbar('Material stock deleted successfully');
                } catch (e) {
                    if (e.status === 400) {
                        generalShowErrorSnackbar(e.data?.Message);
                    } else {
                        generalShowErrorSnackbar('An error occurred deleting material stock');
                    }
                } finally {
                    this.setLoading(false);
                    this.closeDeleteConfirmationPopup();
                }
            } else {
                generalShowErrorSnackbar('Reason for deleting this material stock must be at least 200 characters.');
            }
        }
    };

    private closeDeleteConfirmationPopup = () => this.setState({ isDeleting: false, deleteItem: undefined });

    private getSelectedSiteIds = (props : IMaterialStockTableProps) => props.selectedSiteIds;
    private getSites = (props : IMaterialStockTableProps) => props.sites;
    private getUnitOfMeasures = (props : IMaterialStockTableProps) => props.unitOfMeasures;
    private getMaterials = (props : IMaterialStockTableProps) => props.materials;
    private getAvailableMaterialStocksToFinalize = (props : IMaterialStockTableProps, state : IMaterialStockTableState) => state.availableMaterialStocksToFinalize;
    private getSearchedDateCode = (props : IMaterialStockTableProps, state : IMaterialStockTableState) => state.searchedDateCode;
    private getSelectedSite = (props : IMaterialStockTableProps, state : IMaterialStockTableState) => state.selectedSite;

    private getMassMaterialStockFinalizeTableRows = createSelector(
        [this.getAvailableMaterialStocksToFinalize, this.getSearchedDateCode, this.getSelectedSite],
        (availableMaterialStocksToFinalize : Array<IMaterialStockView>, searchedDateCode : string) => {
            return availableMaterialStocksToFinalize.filter(x => (searchedDateCode !== '' ? x.dateCode.toLowerCase().includes(searchedDateCode.toLowerCase()) : true));
        },
    );

    private getSiteOptions = createSelector(
        [this.getSites],
        (sites : Array<ISite>) => {
            if (!sites) return [];

            return sites.filter(x => x.isActive).map((x) => {
                return { label: `(${x.shortDescription}) ` + x.description, value: x.id };
            });
        },
    );

    private getMaterialOptions = createSelector(
        [this.getMaterials],
        (materials : Array<IMaterial>) => {
            if (!materials) return [];

            return materials.filter(x => x.isActive).map((x) => {
                return { label: `(${x.code}) ` + x.name, value: x.id };
            });
        },
    );

    private getUnitOfMeasureOptions = createSelector(
        [this.getUnitOfMeasures],
        (unitOfMeasures : Array<IUnitOfMeasure>) => {
            if (!unitOfMeasures) return [];

            return unitOfMeasures.filter(x => x.isActive).map((x) => {
                return { label: `(${x.code}) ` + x.name, value: x.id };
            });
        },
    );

    private onMassFinalizeSiteChange = async (e : React.ChangeEvent<{}>, selectedSite : IOptionType) => {
        this.setState({ selectedSite });
        if (!!selectedSite && selectedSite !== null) {
            this.setLoading(true);
            try {
                const res = await MaterialStockHttpService.getMaterialStocksAvailableToFinalize(Number(selectedSite.value));

                if (res && res.data) {
                    this.setState({ availableMaterialStocksToFinalize: res.data });
                }
            } catch {
                generalShowErrorSnackbar('Error occured while loading material stocks');
            } finally {
                this.setLoading(false);
            }
        } else {
            this.setState({ checkedMaterialStocksToFinalize: [], availableMaterialStocksToFinalize: [] });
        }
    };

    private onSiteChange = (e : CustomChangeEvent, selectedSite : IOptionType) => {
        this.setState({ selectedSite });
    };

    private onMaterialChange = (e : CustomChangeEvent, selectedMaterial : IOptionType) => {
        this.setState({ selectedMaterial });
    };

    private onUnitOfMeasureChange = (e : CustomChangeEvent, selectedUnitOfMeasure : IOptionType) => {
        this.setState({ selectedUnitOfMeasure });
    };

    private getSelectedSites = (props : IMaterialStockTableProps, state : IMaterialStockTableState) => state.selectedSite;

    private hasError = createSelector(
        [this.getSelectedSites],
        (selectedSite : IOptionType) => {
            if (selectedSite === undefined) {
                return true;
            } else {
                return false;
            }
        },
    );

    private getRows = (props : IMaterialStockTableProps, state : IMaterialStockTableState) => state.rows;

    private getTableRows = createSelector(
        [this.getRows],
        (rows) => {
            return rows;
        },
    );

    private getSite = (id : number) => this.props.sites.find(x => x.id === id);

    private addButtonTooltip = createSelector(
        [this.getSelectedSiteIds],
        (selectedSiteIds) => {
            let message = '';
            if (selectedSiteIds?.length === 1) {
                message = '';
            } else if (selectedSiteIds?.length > 1) {
                message = 'Please select only one site';
            } else {
                message = 'No Site Selected';
            }
            return message;
        },
    );

    private openMassMaterialStockFinalizeDialog = async () => {
        let site : ISite | undefined;
        const selectedSiteIds = this.props.selectedSiteIds;
        if (!!selectedSiteIds && selectedSiteIds?.length === 1) {
            site = this.props.sites.find(x => x.id === selectedSiteIds[0]);
        }

        if (!!site) {
            this.setState({ isMassFinalizePopupOpen: true, selectedSite: { label: `${site?.shortDescription} ${site?.description}`, value: site?.id } });
            this.setLoading(true);
            try {
                const res = await MaterialStockHttpService.getMaterialStocksAvailableToFinalize(site?.id);

                if (res && res.data) {
                    this.setState({ availableMaterialStocksToFinalize: res.data });
                }
            } catch (e) {
                generalShowErrorSnackbar('Error occured while loading material stocks');
            } finally {
                this.setLoading(false);
            }
        } else {
            this.setState({ isMassFinalizePopupOpen: true });
        }
    };

    private closeMassMaterialStockFinalizeDialog = () => {
        this.setState({
            isMassFinalizePopupOpen: false,
            checkedMaterialStocksToFinalize: [],
            availableMaterialStocksToFinalize: [],
            selectedSite: undefined,
            searchedDateCode: '',
        });
    };

    private handleSelectAllMaterialStocksToFinalize = () => {
        const filteredMaterialStocksAvailableToBeFinalized = this.getMassMaterialStockFinalizeTableRows(this.props, this.state);
        const checkedMaterialStocksToFinalize = this.state.checkedMaterialStocksToFinalize;

        if  (filteredMaterialStocksAvailableToBeFinalized.length !== checkedMaterialStocksToFinalize.length) {
            filteredMaterialStocksAvailableToBeFinalized.forEach((batch) => {
                const index = checkedMaterialStocksToFinalize.findIndex(x => x.id === batch.id);

                if (index === -1) {
                    this.setState(prevState => ({ checkedMaterialStocksToFinalize: addArrayElement(prevState.checkedMaterialStocksToFinalize, batch) }));
                }
            });
        } else {
            this.setState({ checkedMaterialStocksToFinalize: [] });
        }
    };

    private onSearchDateCodeChange = (event : CustomChangeEvent) => {
        this.setState({ searchedDateCode: event.target.value });
    };

    private handleCheckboxChecked = (materialStockView : IMaterialStockView, selected : boolean) => {
        if (selected) {
            const index = this.state.checkedMaterialStocksToFinalize.findIndex(x => x.id === materialStockView.id);

            if (index === -1) {
                this.setState(prevState => ({ checkedMaterialStocksToFinalize: addArrayElement(prevState.checkedMaterialStocksToFinalize, materialStockView) }));
            }
        } else {
            const index = this.state.checkedMaterialStocksToFinalize.findIndex(x => x.id === materialStockView.id);
            if (index !== -1) {
                this.setState(prevState => ({ checkedMaterialStocksToFinalize: removeArrayElement(prevState.checkedMaterialStocksToFinalize, index) }));
            }
        }
    };

    private submitMassMaterialStockFinalize = async () => {
        const selectedMaterialStocks = this.state.checkedMaterialStocksToFinalize;
        if (selectedMaterialStocks.length > 0) {
            try {
                this.setLoading(true);
                const res = await MaterialStockHttpService.finalizeMutlipleMaterialStocks(selectedMaterialStocks.map(x => x.guid));

                if (res && res.data) {
                    res.data.forEach((x) => {
                        dataSetMaterialStockView(x);
                    });
                    this.closeMassMaterialStockFinalizeDialog();
                    generalShowSuccessSnackbar('Selected lots have been finalized successfully!');
                }
            } catch (e) {
                generalShowErrorSnackbar(e.data);
            } finally {
                this.setLoading(false);
            }
        }
    };

    public render() {
        const selectedSite = this.props.selectedSiteIds?.length === 1 ? this.getSite(this.props.selectedSiteIds[0]) : undefined;
        return (
            <Screen isPadded={false} isScrollable={false} isLoading={this.state.isLoading}>
                <div className={'fdc hfill pl20 pr20'}>
                    <div className={'fdr mb5 aic'}>
                        <PillButton
                            className={'ml20 h40 p5 w200 reducedPillButtonShadow'}
                            text={'Finalize Material Stocks'}
                            color={'secondary'}
                            onClick={() => this.openMassMaterialStockFinalizeDialog()}
                        />
                        <div className={'flx1'}></div>
                        <TransactionFilter className={'pt5 mb5'} selectedFromDate={this.state.selectedFromDate} selectedToDate={this.state.selectedToDate} handleDateRangeChange={this.handleDateRangeChange} onApplyClick={this.onApplyClick} />
                    </div>
                    <CustomTable<IMaterialStockView>
                        pageHeight={250}
                        enableSorting
                        enableFiltering
                        enablePagination
                        pageSizes={[50, 150, 250, 500, 1000]}
                        enableTotalRow
                        enableRefresh
                        enableAdding
                        enableDeleting={(materialStock : IMaterialStockView) => materialStock.isActive && materialStock.amountAllocated < 1 && materialStock.amountScrapped < 1}
                        deleteFunction={this.setDeleteItem}
                        disableAddButton={!selectedSite}
                        addTooltip={this.addButtonTooltip(this.props)}
                        addFunction={this.openMaterialStockAddDialog}
                        enableEditing={(row : IMaterialStockView) => !row.isFinalized}
                        editFunction={this.openEditDialog}
                        editTooltip={'Stock Take'}
                        enableDetails
                        detailIcon={'info'}
                        detailTooltip={'Material Stock Summary'}
                        detailFunction={row => navMaterialStock(row.id)}
                        disableRefreshButton={this.state.isLoading}
                        refreshFunction={this.refreshData}
                        columns={this.state.columns}
                        rows={this.getTableRows(this.props, this.state)}
                        initialSortOrder={[{ columnName: 'id_Id', direction : 'desc' }]}
                        isActive={(row : IMaterialStockView) => row.isActive}
                    />
                    {/* Material Stock Intake Dialog */}
                    <PackmanDialog
                        title={'Material Stock Intake'}
                        isInfo
                        isLoading={this.state.isLoading}
                        isOpen={this.state.isStockIntakeDialogOpen}
                        onClose={this.closeMaterialStockAddDialog}>
                        <div className={'fdc p20'}>
                            <div className={'fdc aic jcc pb5'}>
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'site'}
                                    label={'Site'}
                                    onChange={this.onSiteChange}
                                    options={this.getSiteOptions(this.props)}
                                    value={selectedSite ? { label: `(${selectedSite.shortDescription}) ` + selectedSite.description, value: selectedSite.id } : this.state.selectedSite}
                                    disabled
                                />
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'material'}
                                    label={'Material'}
                                    onChange={this.onMaterialChange}
                                    options={this.getMaterialOptions(this.props)}
                                    value={this.state.selectedMaterial}
                                />
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'unitOfMeasure'}
                                    label={'Unit Of Measure'}
                                    onChange={this.onUnitOfMeasureChange}
                                    options={this.getUnitOfMeasureOptions(this.props)}
                                    value={this.state.selectedUnitOfMeasure}
                                />
                                <TextField
                                    style={{ width: 227 }}
                                    value={this.state.dateCode}
                                    label={'Date Code'}
                                    onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.setState({ dateCode: e.target.value })}
                                />
                                <TextField
                                    style={{ width: 227 }}
                                    value={this.state.materialStockAmount}
                                    label={'Amount'}
                                    type={'number'}
                                    onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.setState({ materialStockAmount: Number(e.target.value) })}
                                />
                            </div>
                            <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                                <Button
                                    className={'fwb h35'}
                                    onClick={this.closeMaterialStockAddDialog}
                                >
                                    Clear
                                </Button>
                                <PillButton
                                    className={'ml15 pl20 pr20 h35'}
                                    disabled={this.state.materialStockAmount === undefined}
                                    text={'Save'}
                                    color={'secondary'}
                                    onClick={this.onMaterialStockCreate}
                                />
                            </div>
                        </div>
                    </PackmanDialog>
                    {/* Material Stock Take Dialog */}
                    <PackmanDialog
                        title={`Material Stock - (${this.state.edittingMaterialStockView?.id})`}
                        isInfo
                        isLoading={this.state.isLoading}
                        isOpen={this.state.isStockTakeDialogOpen}
                        onClose={this.closeEditDialog}>
                        <div className={'fdc p20'}>
                            <div className={this.state.edittingMaterialStockView?.isActive ? 'fdr aic jcc pb5' : 'dn'}>{'Stock Take'}</div>
                            <div className={this.state.edittingMaterialStockView?.isActive ? 'fdr aic jcc pb5' : 'dn'}>{`Current Unallocated Amount: ${this.state.edittingMaterialStockView?.amountUnAllocated} \n`}</div>
                            <div className={this.state.edittingMaterialStockView?.isActive ? 'fdr aic jcc pb5' : 'dn'}>
                                <CustomTooltip title={!this.state.edittingMaterialStockView?.isActive ? 'Cannot edit. Material stock is inactive' : ''}>
                                    <TextField
                                        value={this.state.materialStockAmount}
                                        label={'New Amount'}
                                        type={'number'}
                                        disabled={!this.state.edittingMaterialStockView?.isActive}
                                        onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.setState({ materialStockAmount: Number(e.target.value) })}
                                    />
                                </CustomTooltip>
                            </div>
                            <div className={this.state.edittingMaterialStockView?.isActive ? 'dn' : 'fdc aic jcc pb5'}>
                                <CustomToggleButton
                                    field={'isActive'}
                                    initialValue={this.state.isMaterialStockActive}
                                    value={this.state.isMaterialStockActive}
                                    label={'Is Active?'}
                                    disabled={!!this.state.edittingMaterialStockView?.isActive}
                                    onChange={(event : React.FormEvent<HTMLButtonElement | HTMLInputElement | HTMLAnchorElement>, selected ?: boolean) => this.setState({ isMaterialStockActive: selected ?? this.state.isMaterialStockActive })}
                                />
                            </div>
                            <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                                <Button
                                    className={'fwb h35'}
                                    variant='text'
                                    onClick={this.closeEditDialog}
                                >
                                    Clear
                                </Button>
                                {this.state.edittingMaterialStockView?.isActive
                                    ?
                                    <PillButton
                                        className={'ml15 pl20 pr20 h35'}
                                        disabled={this.state.materialStockAmount === undefined}
                                        text={'Save'}
                                        color={'secondary'}
                                        onClick={this.submitEdit}
                                    />
                                    :
                                    <PillButton
                                        className={'ml15 pl20 pr20 h35'}
                                        disabled={this.state.edittingMaterialStockView?.isActive === this.state.isMaterialStockActive}
                                        text={'Save'}
                                        color={'secondary'}
                                        onClick={this.reActivateMaterialStock}
                                    />

                                }
                            </div>
                        </div>
                    </PackmanDialog>
                    {/* Material Stock Scrap Dialog */}
                    <PackmanDialog
                        title={`Scrap Material Stock - (${this.state.scrapMaterialStockView?.id})`}
                        isInfo
                        isLoading={this.state.isLoading}
                        isOpen={this.state.isStockScrapDialogOpen}
                        onClose={this.closeStockScrapDialog}>
                        <div className={'fdc p20'}>
                            <div className={'fdr aic jcc pb5'}>{'Amount to be scrapped:'}</div>
                            <div className={'fdc aic jcc pb5'}>
                                <TextField
                                    value={this.state.materialStockAmount}
                                    label={'Amount'}
                                    type={'number'}
                                    onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.setState({ materialStockAmount: Number(e.target.value) })}
                                />
                                <CustomToggleButton
                                    field={'isAllocated'}
                                    initialValue={false}
                                    label={'Is Allocated?'}
                                    onChange={(event : React.FormEvent<HTMLButtonElement | HTMLInputElement | HTMLAnchorElement>, selected ?: boolean) => this.setState({ isMaterialStockAmountAllocated: selected ?? false })}
                                />
                            </div>
                            <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                                <Button
                                    className={'fwb h35'}
                                    variant='text'
                                    onClick={this.closeStockScrapDialog}
                                >
                                    Clear
                                </Button>
                                <PillButton
                                    className={'ml15 pl20 pr20 h35'}
                                    disabled={this.state.materialStockAmount === undefined}
                                    text={'Save'}
                                    color={'secondary'}
                                    onClick={this.onStockScrapSubmit}
                                />
                            </div>
                        </div>
                    </PackmanDialog>
                    {/* Summary Dialog */}
                    <PackmanDialog
                        title='Material Stock Summary'
                        isInfo={true}
                        fullScreen={true}
                        isOpen={!!this.state.selectedMaterialStockView}
                        onClose={this.closeSummaryDialog}>
                        {this.state.selectedMaterialStockView &&
                            <MaterialStockSummary
                                selectedMaterialStockView={this.state.selectedMaterialStockView}
                                isLoading={this.state.isLoading}
                                setLoading={this.setLoading}
                                refreshData={this.refreshData}
                            />
                        }
                    </PackmanDialog >
                    {/* Finalize Multiple Material Stocks dialog */}
                    <PackmanDialog
                        isOpen={!!this.state.isMassFinalizePopupOpen}
                        isLoading={this.state.isLoading}
                        title={'Finalize Material Stocks'}
                        isInfo={true}
                        maxWidth={'md'}
                        onClose={this.closeMassMaterialStockFinalizeDialog} >
                        <div className={'p20 fdc'}>
                            <div className={'fdr aic'}>
                                <AutocompleteSelect
                                    className={'w300 mb10'}
                                    name={'sites'}
                                    label={'Site'}
                                    options={this.getSiteOptions(this.props)}
                                    onChange={this.onMassFinalizeSiteChange}
                                    value={this.state.selectedSite}
                                />
                                <div className={'w10'}/>
                                <TextField fullWidth className={'w280 mb10'} label={'Search By Date Code'} value={this.state.searchedDateCode} onChange={this.onSearchDateCodeChange}/>
                                <div className={'flx1'}/>
                                <PillButton
                                    color={'secondary'}
                                    className={'h35'}
                                    text={`${this.state.checkedMaterialStocksToFinalize.length !== this.getMassMaterialStockFinalizeTableRows(this.props, this.state).length ? 'Select All' : 'Unselect All'}`}
                                    disabled={!!(this.state.availableMaterialStocksToFinalize.length < 1)}
                                    onClick={() => this.handleSelectAllMaterialStocksToFinalize()}/>
                            </div>
                            <Table className= {'PaperBorder w900'}>
                                {/* Headings */}
                                <TableRow className={'fdr bcTableHead'}>
                                    <TableCell className={'flx3 jcc aic p5 bcTableHead h23 cw fs14'}>
                                        <div style={{ width: 50, minWidth: 50 }} className={'aic jcc'}>Select</div>
                                        <div style={{ width: 85, maxWidth: 85 }} className={'aic jcc'}>Id</div>
                                        <div className={'flx2 aic jcc'}>Date Code</div>
                                        <div className={'flx2 aic jcc'}>Amount Unallocated</div>
                                        <div className={'flx1 aic jcc'}>Created</div>
                                    </TableCell>
                                </TableRow>
                                {/* body */}
                                <WindowList
                                    itemCount={this.getMassMaterialStockFinalizeTableRows(this.props, this.state).length}
                                    width={900}
                                    height={400}
                                    itemSize={30}
                                    itemData={this.getMassMaterialStockFinalizeTableRows(this.props, this.state)}
                                >
                                    {React.memo(({ data, index, style } : React.PropsWithChildren<ListChildComponentProps>) => {
                                        const materialStockView : IMaterialStockView = data[index];
                                        if (!materialStockView) return <div />;
                                        const isChecked : boolean = !!this.state.checkedMaterialStocksToFinalize.find(x => x.id === materialStockView.id);

                                        const stuff : React.CSSProperties = {
                                            ...style,
                                            backgroundColor: '',
                                        };
                                        return (
                                            <TableRow style={stuff} className={`${index % 2 === 0 ? 'bcTableRow2' : 'bcTableRow1'}`}>
                                                <TableCell className={'wfill jcc aic hfill fs14 p0 fw500'}>
                                                    <div style={{ width: 50, minWidth: 50 }} className={'aic jcc'}>
                                                        <Checkbox
                                                            className={'pt0 pb0 pr5 m0'}
                                                            checked={isChecked}
                                                            onChange={(event, checked) => this.handleCheckboxChecked(materialStockView, checked)}
                                                        />
                                                    </div>
                                                    <div style={{ width: 85, maxWidth: 85 }} className={'aic jcc bl1 hfill'}>{materialStockView.id}</div>
                                                    <div className={'flx2 aic jcc bl1 hfill'}>{materialStockView.dateCode}</div>
                                                    <div className={'flx2 aic jcc bl1 hfill'}>{materialStockView.amountUnAllocated}</div>
                                                    <div className={'flx1 aic jcc bl1 hfill'}>{moment.utc(materialStockView.createdOn, DATE_FORMAT_TIMESTAMP_FROM_API).local().format(DATE_FORMAT_DEFAULT)}</div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </WindowList>
                            </Table>
                            <div className={'fdr aife jcfe wfill mt10'}>
                                <Button className={'fwb h35'} variant='text'
                                    onClick={this.closeMassMaterialStockFinalizeDialog}>
                                        Cancel
                                </Button>
                                <PillButton
                                    color={'secondary'}
                                    className={'ml15 pl20 pr20 h35'}
                                    text={'Submit'}
                                    disabled={!!(this.state.checkedMaterialStocksToFinalize.length < 1)}
                                    onClick={this.submitMassMaterialStockFinalize}/>
                            </div>
                        </div>
                    </PackmanDialog>
                    <ConfirmationPrompt
                        disableYes={this.state.isLoading}
                        disableNo={this.state.isLoading}
                        open={!!this.state.materialStockViewToFinalize}
                        onOkClicked={this.submitFinalize}
                        onCancelClicked={this.noClicked}
                        message={'Are you sure you want to finalize this material stock?'}
                    />
                </div>
                {/* Delete Dialog */}
                {!!this.state.isDeleting &&
                    <DeleteConfirmationDialog
                        isLoading={this.state.isLoading}
                        onSubmit={this.onMaterialStockDelete}
                        onclose={this.closeDeleteConfirmationPopup}
                        isOpen={!!this.state.isDeleting}
                        title={'Material Stock'}/>
                }
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        materialStockViews: state.data.materialStockViews,
        materials: state.masterData.materials,
        sites: state.masterData.sites,
        unitOfMeasures: state.masterData.unitOfMeasures,
        selectedSiteIds: state.data.selectedSiteIds,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    {
        dataSetMaterialStockViews,
        dataSetMaterials,
        dataSetUnitOfMeasures,
    },
    dispatcher,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MaterialStockTable);

import { IRootState, IAuthState } from '../../@types/redux';
import React from 'react';
import { connect } from 'react-redux';
import { Stepper, StepLabel, Step, Card, AppBar, Toolbar, Typography, Button, Checkbox, Divider, TextField, AutocompleteChangeReason, AutocompleteInputChangeReason } from '@mui/material';
import { ISite } from '../../@types/model/masterData/site/site';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { IStock } from '../../@types/model/stock/stock';
import { IDispatchInstruction } from '../../@types/model/dispatch/dispatchInstruction';
import { ICompliance } from '../../@types/model/compliance/compliance';
import { Form } from 'formik';
import PillButton from '../../components/input/PillButton';
import CustomTable, { ICustomTableColumn } from '../../components/datagrid/CustomTable';
import { formatDateTimeToDateOnly, compareDate, formatDateTime, addArrayElement } from '../../services/appFunctionsService';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { dataSetAllStockRelatedData, dataSetCompliance } from '../../store/data/Functions';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar, generalShowWarningSnackbar } from '../../store/general/Functions';
import { ISelectableDispatch } from '../../@types/model/dispatch/selectableDispatch';
import { IDispatchInstructionLine } from '../../@types/model/dispatch/dispatchInstructionLine';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import ComplianceHttpService from '../../services/http/compliance/complianceHttpService';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { exportFindingSheet, exportCompliance, get202FileName, onComplianceExport, onFileLoaded } from '../../services/compliance/documentService';
import { CSVLink } from 'react-csv';
import { ConsignmentCSVJson, IConsignmentCSVJson } from '../../@types/model/compliance/titan-TUR/consignmentCSVJson';
import { getConsignmentToken, getTURToken } from '../../services/inspectionService';
import lodash, { uniq } from 'lodash';
import { Link } from 'react-router-dom';
import { createSelector } from 'reselect';
import Screen from '../../components/Screen';
import PopupOptionButton from '../../components/button/PopupOptionButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import GetAppIcon from '@mui/icons-material/GetApp';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PopupActionButton from '../../components/button/PopupActionButton';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { ISize } from '../../@types/model/masterData/size/size';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { IRegion } from '../../@types/model/masterData/region/region';
import { ICountry } from '../../@types/model/masterData/country/country';
import { IPalletBaseType } from '../../@types/model/masterData/palletBaseType/palletBaseType';
import { IMarket } from '../../@types/model/masterData/market/market';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import { ICreateWizardCompliance } from '../../@types/model/compliance/createWizardcompliance';
import AutoCompleteSelect from '../../components/input/AutoCompleteSelect';
import { IOptionType } from '../../@types/helper';
import CustomTooltip from '../../components/tooltip/tooltip';
import { syncMasterData } from '../../services/masterDataSyncService';
import { getIconLocation } from '../../services/iconHelperService';

enum pages {
    start = 1,
    stock,
    preview,
}

interface IComplianceWizardProps {
    auth : IAuthState;
    organizations : Array<IOrganization>;
    sites : Array<ISite>;
    selectedOrganizationIds : Array<number>;
    selectedSiteIds : Array<number>;
    selectedCompliance ?: ICompliance;
    selectedExporterOrganization ?: IOrganization;
    stocks : Array<IStock>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    farms : Array<IFarm>;
    packs : Array<IPack>;
    sizes : Array<ISize>;
    grades : Array<IGrade>;
    colours : Array<IColour>;
    regions : Array<IRegion>;
    countries : Array<ICountry>;
    palletBaseTypes : Array<IPalletBaseType>;
    markets : Array<IMarket>;
    dispatchInstructions : Array<IDispatchInstruction>;
    orchards : Array<IOrchard>;
}

interface IComplianceWizardState {
    isLoading : boolean;
    isDispatches : boolean;
    completeCompliance ?: ICompliance;
    currentPage : pages;
    selectedStockIds : Array<number>;
    selectedDispatchInstructionIds : Array<number>;
    selectedOrganization ?: IOrganization;
    selectedSite ?: ISite;
    selectedCommodity ?: ICommodity;
    selectedCountry ?: ICountry;
    isMessageLoading : boolean;
    message : string;
    isMessageOpen : boolean;
    searchValues : Array<IOptionType>;
}

class ComplianceWizard extends React.PureComponent<IComplianceWizardProps, IComplianceWizardState> {

    private searchBoxRef : React.RefObject<HTMLInputElement>;

    constructor(props : IComplianceWizardProps) {
        super(props);
        this.state = this.initialState;
        this.searchBoxRef = React.createRef();
    }

    private initialState = {
        isLoading: false,
        isDispatches: false,
        completeCompliance: undefined,
        currentPage: pages.start,
        selectedStockIds: [],
        selectedDispatchInstructionIds: [],
        selectedSite: undefined,
        selectedCommodity: undefined,
        selectedOrganization: undefined,
        message: '',
        isMessageOpen: false,
        isMessageLoading: false,
        searchValues: [],
    };

    public componentDidMount = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }
        try {
            if (!this.props.auth.ppecbToken?.token) {
                await getConsignmentToken();
            }
            if (!this.props.auth.turToken?.access_token) {
                await getTURToken();
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving consignment data.');
            this.setLoading(false);
        }

        if (this.props.selectedCompliance) {
            const row = this.props.selectedCompliance;
            const stocks : Array<IStock> = [];

            row.complianceLines.forEach((element) => {
                const stock = this.props.stocks.find(x => x.id === element.stockId);
                if (stock) {
                    stocks.push(stock);
                } else {
                    generalShowWarningSnackbar('Stock data could not be found!');
                }
            });

            const res = await ComplianceHttpService.getComplianceWizardStockData(undefined, undefined, this.props.selectedSiteIds, !isIndexedDBAvailable);

            stocks.forEach((stock) => {
                res.data.stocks = addArrayElement(res.data.stocks, stock);
            });

            dataSetAllStockRelatedData(res.data);

            this.setState({
                isDispatches: false, currentPage: 2,
                selectedOrganization: this.props.organizations.find(x => x.id === row.organizationId),
                selectedSite: this.props.sites.find(x => x.id === row.siteId),
                selectedCommodity: this.props.commodities.find(x => x.id === row.commodityId),
                selectedStockIds: stocks.map(x => x.id),
            });
        }

        this.setLoading(false);
    };

    public componentDidUpdate = (prevProps : IComplianceWizardProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            /* prop changes go here */
            if ((this.state.currentPage === pages.stock) && (prevProps.selectedSiteIds !== nextProps.selectedSiteIds)) {
                this.refreshData();
            }
        }
    };

    private setLoading = (value : boolean) => {
        this.setState({ isLoading: value });
    };

    private refreshData = async () => {
        if (this.state.isDispatches) {
            await this.refreshDispatchData();
        } else {
            await this.refreshStockData();
        }
    };

    private refreshStockData = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;
        try {
            if (this.props.selectedCompliance) {
                const row = this.props.selectedCompliance;
                const stocks : Array<IStock> = [];

                row.complianceLines.forEach((element) => {
                    const stock = this.props.stocks.find(x => x.id === element.stockId);
                    if (stock) {
                        stocks.push(stock);
                    } else {
                        generalShowWarningSnackbar('Stock data could not be found!');
                    }
                });

                const res = await ComplianceHttpService.getComplianceWizardStockData(undefined, undefined, this.props.selectedSiteIds, !isIndexedDBAvailable);

                stocks.forEach((stock) => {
                    res.data.stocks = addArrayElement(res.data.stocks, stock);
                });

                dataSetAllStockRelatedData(res.data);
            } else {
                const res = await ComplianceHttpService.getComplianceWizardStockData(undefined, undefined, this.props.selectedSiteIds, !isIndexedDBAvailable);
                dataSetAllStockRelatedData(res.data);
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving compliance wizard stock data.');
        } finally {
            this.setLoading(false);
        }
    };

    private refreshDispatchData = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;
        try {
            const res = await ComplianceHttpService.getComplianceWizardDispatchData(undefined, undefined, this.props.selectedSiteIds, !isIndexedDBAvailable);
            dataSetAllStockRelatedData(res.data);
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving compliance wizard dispatch data.');
        } finally {
            this.setLoading(false);
        }
    };

    private renderStartPage = () => {
        return <Form className={'wfill jcc aic'}>
            <Card className={'w60p'}>
                <div className='fdc p20'>
                    <Typography color='inherit' className={'fw500 fs18'} variant='body1'>{'Please Select An Option'}</Typography>
                    <div className='fdr jcc aic pt20 pb10'>
                        <PillButton onClick={this.onDispatchCreateClick} className={'mr20 flx1 fw500 reducedPillButtonShadow'} color='secondary' text={'CREATE COMPLIANCE FROM DISPATCH'} />
                        <PillButton onClick={this.onStockCreateClick} className={'flx1 fw500 reducedPillButtonShadow'} color='secondary' text={'CREATE COMPLIANCE FROM STOCK'} />
                    </div>
                </div>
            </Card>
        </Form>;
    };

    private getDispatches = (props : IComplianceWizardProps) => props.dispatchInstructions ?? [];
    private getSelectedDispatchIds = (props : IComplianceWizardProps, state : IComplianceWizardState) => state.selectedDispatchInstructionIds;
    private getStocks = (props : IComplianceWizardProps) => props.stocks ?? [];
    private getSelectedStockIds = (props : IComplianceWizardProps, state : IComplianceWizardState) => state.selectedStockIds;
    private getIsLoading = (props : IComplianceWizardProps, state : IComplianceWizardState) => state.isLoading;
    private getSearchValues = (props : IComplianceWizardProps, state : IComplianceWizardState) => state.searchValues;

    private getSelectableDispatches = createSelector([this.getDispatches, this.getSelectedDispatchIds],
        (dispatches, selectedDispatchIds) : Array<ISelectableDispatch> => dispatches.map((x) => {
            return {
                ...x,
                isSelected : selectedDispatchIds.some(y => y === x.id),
            };
        }));

    private getSeletedStock = createSelector([this.getStocks, this.getSelectedStockIds],
        (stocks, selectedStockIds) => stocks.filter(x => selectedStockIds.some(y => y === x.id)));

    private renderDispatchPage = () => {
        return <div className={'wfill'} style={{ height: 'calc(100% - 83px)' }}>
            <div className={'fdr wfill hfill'}>
                <div className={'fdc wfill hfill'}>
                    <div className={'fdr hfill pb20 mxh500 selectTextOnly hfill'}>
                        <CustomTable<ISelectableDispatch>
                            enableSorting
                            enableFiltering
                            enableTotalRow
                            enableRefresh
                            enablePagination
                            pageSizes={[50, 150, 250, 500, 1000]}
                            disableRefreshButton={this.state.isLoading}
                            refreshFunction={this.refreshDispatchData}
                            columns={this.getDispatchTableColumns()}
                            rows={this.getSelectableDispatches(this.props, this.state)}
                            initialSortOrder={[{ columnName: 'dispatchCode_Dispatch Code', direction: 'desc' }]}
                            isActive={(row : ISelectableDispatch) => row.isActive}
                            pageHeight={350}
                        />
                    </div>
                    <div className={'fdr aife jcfe pr10'}>
                        <Button
                            className={'fwb h35'}
                            variant='text'
                            color='primary'
                            onClick={this.previousPage}>
                            BACK
                        </Button>
                        <PillButton
                            text={'NEXT'}
                            disabled={this.state.selectedDispatchInstructionIds.length <= 0}
                            className={'ml15 pl20 pr20 h35 cpd'}
                            onClick={this.nextPage}
                            color={'secondary'}
                            size={'small'}
                        >
                        </PillButton>
                    </div>
                </div>
            </div>
        </div>;
    };

    private setSearchValues = (e : React.ChangeEvent, searchValues : Array<IOptionType>, reason : AutocompleteChangeReason) => {
        if (reason === 'removeOption') {
            this.setState({ searchValues });
        } else {
            e.preventDefault();
        }
    };

    private onSearchInputChange = (e : React.ChangeEvent, inputValue : string, reason : AutocompleteInputChangeReason) => {
        if (reason === 'clear') {
            this.setState({ searchValues: [] });
        } else {
            const separator =  /\\r\n|\n\r|\n|\r|\t|,| /;
            if(inputValue.match(separator)) {
                const newValues = inputValue.split(separator).filter(x => x.length > 0);
                this.setState(prevState => ({
                    searchValues: uniq(prevState.searchValues.map(x => x.label).concat(newValues)).map((x) => { return { value: x, label: x };}),
                }));
            }
        }
    };

    private searchBarOnKeyDown = (e : React.KeyboardEvent<HTMLDivElement>) => {
        const inputValue = (e.target as HTMLTextAreaElement).value;
        if (e.key === 'Tab') {
            e.preventDefault();
            const newValues = inputValue.split('\t').filter(x => x.length > 0);
            this.setState(prevState => ({
                searchValues: uniq(prevState.searchValues.map(x => x.label).concat(newValues)).map((x) => { return { value: x, label: x };}),
            }));
            // if (this.searchBoxRef && this.searchBoxRef.value) {
            //     this.searchBoxRef.value = '';
            // }
        }
        if (e.key === 'Enter') {
            e.preventDefault();
            const newValues = inputValue.split('\r\n').filter(x => x.length > 0);
            this.setState(prevState => ({
                searchValues: uniq(prevState.searchValues.map(x => x.label).concat(newValues)).map((x) => { return { value: x, label: x };}),
            }));
        }
    };

    private onSearchBlur = (e : React.FocusEvent<HTMLDivElement>) => {
        const inputValue = ((e.target as unknown) as HTMLTextAreaElement).value;
        const separator =  /\\r\n|\n\r|\n|\r|\t|,| /;
        const newValues = inputValue.split(separator).filter(x => x.length > 0);
        this.setState(prevState => ({
            searchValues: uniq(prevState.searchValues.map(x => x.label).concat(newValues)).map((x) => { return { value: x, label: x };}),
        }));
    };

    private getStockRows = createSelector([this.getStocks, this.getSearchValues],
        (stocks, searchValues) => stocks.filter(x =>
            lodash(x).some((z) => {
                if(typeof z === 'string') {
                    return (searchValues.length === 0) || searchValues.some((y) => {
                        return (z as string)?.toString()?.toLowerCase()?.includes(y.value?.toString()?.toLowerCase());
                    });
                }
                return false;
            }))
    );

    private renderPalletPage = () => {
        return <div className='hfill pb30'>
            {/* Stock Table */}
            <div className={'fdc wfill mb10'} style={{ height: 'calc(100% - 50px)' }}>
                <div className='ml20 mt10 mb10'>
                    <AutoCompleteSelect
                        name={'search'}
                        label={'Search'}
                        freeSolo
                        onKeyDown={this.searchBarOnKeyDown}
                        onInputChange={this.onSearchInputChange}
                        onBlur={this.onSearchBlur}
                        isMulti
                        textFieldInputRef={this.searchBoxRef}
                        options={this.state.searchValues}
                        onChange={this.setSearchValues}
                        value={this.state.searchValues}
                        disabled={this.state.isLoading}
                        blurOnSelect={true}
                        clearOnBlur={true}
                    />
                </div>
                <div className={'fdc mr10 mt10 ml20 selectTextOnly'} style={{ height: 'calc(100% - 100px)' }}>
                    <CustomTable<IStock>
                        enableSorting
                        enableFiltering
                        enableClearFilterButton
                        enablePagination
                        enableRefresh
                        disableRefreshButton={this.state.isLoading}
                        refreshFunction={this.refreshStockData}
                        columns={this.getStockTableColumns(this.props, this.state)}
                        rows={this.getStockRows(this.props, this.state)}
                        initialSortOrder={[{ columnName: 'createdOn_Created On', direction: 'desc' }]}
                        pageSizes={[50, 150, 250, 500, 1000]}
                        pageHeight={350}
                        warning={row => !this.isStockLineOrchardsCompliant(row)}
                        isActive={(row : IStock) => row.isActive}

                    />
                </div>
            </div>
            <div className={'fdr aife jcfe pr10'}>
                {!this.props.selectedCompliance && <Button
                    className={'fwb h35'}
                    variant='text'
                    color='primary'
                    onClick={this.previousPage}>
                    BACK
                </Button>}
                <PillButton
                    text={'NEXT'}
                    disabled={this.state.selectedStockIds.length <= 0 || this.state.isLoading}
                    className={'ml15 pl20 pr20 h35 cpd'}
                    onClick={this.nextPage}
                    color={'secondary'}
                    size={'small'}
                >
                </PillButton>
            </div>

        </div>;
    };

    private getStockBarcode = (stockId : number) => this.props.stocks.find(x => x.id === stockId)?.barcode || '';
    private getStockCartons = (stockId : number) => this.props.stocks.find(x => x.id === stockId)?.cartons || 0;

    private renderPreviewPage = () => { // TODO:preview then create compliance
        return <div>
            <Card>
                <AppBar position='static' elevation={0}>
                    <Toolbar variant='dense' className={'fdr'}>
                        <div className={'flx1'}>
                            <Typography color='inherit' variant='h6'>SUMMARY</Typography>
                        </div>
                    </Toolbar>
                </AppBar>
                <div className={'p20 fdc mxh400 oys'}>
                    <div className={'fs18 fw550 pt5 pb5'} >Stock Barcodes:</div>
                    {this.getSeletedStock(this.props, this.state).map((stock) => {
                        return <div className={'fs16 fw500 pt5 pb5'}>{`${this.getStockBarcode(stock.id)} (${this.getStockCartons(stock.id)})`}</div>;
                    })}
                </div>
            </Card>
            <div className={'fdr aife jcfe pr10 mt10 mb10'}>
                <Button
                    className={'fwb h35'}
                    variant='text'
                    color='primary'
                    onClick={this.previousPage}>
                    Back
                </Button>
                <PillButton
                    text={'SAVE AS DRAFT'}
                    disabled={this.state.selectedStockIds.length <= 0 || this.state.completeCompliance !== undefined}
                    className={'ml15 pl20 pr20 h35 cpd'}
                    onClick={() => this.addCompliance(true)}
                    color={'secondary'}
                    size={'small'}
                >
                </PillButton>
                <PillButton
                    text={'CREATE COMPLIANCE'}
                    disabled={this.state.selectedStockIds.length <= 0 || this.state.completeCompliance !== undefined}
                    className={'ml15 pl20 pr20 h35 cpd'}
                    onClick={() => this.addCompliance(false)}
                    color={'secondary'}
                    size={'small'}
                >
                </PillButton>
            </div>
        </div>;
    };

    private renderConfirmationPopup = () => {
        const compliance = this.state.completeCompliance;
        if (compliance !== undefined) {
            return <PackmanDialog isOpen={compliance !== undefined} isInfo title={'CONTINUE'}>
                <div className={'fdc w600 hfill p10'}>
                    <div className={'fs18 fw550 pb16 aic jcc'}>{'Compliance '} <div className={'pl5 pr5 fs18 fw700'}>{compliance.waybill}</div> {' created, please select an option'}</div>
                    <div className={'fdc aic'}>
                        <PopupOptionButton
                            text={'DOWNLOAD FINDING SHEET'}
                            icon={<CloudUploadIcon/>}
                            disabled={this.state.isLoading}
                            onClick={this.downloadFindingSheet}/>
                        <CSVLink data={exportCompliance(compliance)} filename={get202FileName(compliance)} style={{ textDecoration: 'unset' }}>
                            <PopupOptionButton
                                text={'DOWNLOAD 202'}
                                icon={<GetAppIcon/>}
                                disabled={this.state.isLoading}
                                onClick={() => { this.onComplianceExport(compliance); }}/>
                        </CSVLink>
                        <PopupOptionButton
                            text={'SUBMIT TO PPECB'}
                            disabled={this.state.isLoading}
                            onClick={this.submitToPPECB}
                            isImage
                            imgSource={`${getIconLocation()}/send.svg`}/>
                    </div>
                    <Divider className={'pl20 pr20 mt10 mb10'}/>
                    <div className={'fdr jcsb'}>
                        <Link to='/' className={'pl15 mt10 mb10 pr15 w120 h150 mr15'} style={{ textDecoration: 'unset', color: 'unset' }} >
                            <PopupActionButton
                                text={'GO TO HOME'}
                                icon={<HomeIcon/>}
                                disabled={this.state.isLoading}
                                styleButton={'w120 h150'}
                                styleText={'jcc'}/>
                        </Link>
                        <Link to='/compliance/dashboard' className={'pl15 pr15 mt10 mb10 w120 h150 mr15'} style={{ textDecoration: 'unset', color: 'unset' }}>
                            <PopupActionButton
                                text={'GO TO DASHBOARD'}
                                icon={<DashboardIcon/>}
                                disabled={this.state.isLoading}
                                styleButton={'w120 h150'}/>
                        </Link>
                        <PopupActionButton
                            text={'CREATE ANOTHER COMPLIANCE'}
                            icon={<AddIcon/>}
                            disabled={this.state.isLoading}
                            onClick={this.createAnotherCompliance}
                            styleButton={'pl15 pr15 mt10 mb10 w120 mr10 ml20 h150'}/>
                    </div>
                </div>
            </PackmanDialog>;
        } else {
            return <div></div>;
        }
    };

    private renderPage = () => {
        switch (this.state.currentPage) {
            case 1:
                return this.renderStartPage();
            case 2:
                if (this.state.isDispatches) {
                    return this.renderDispatchPage();
                } else {
                    return this.renderPalletPage();
                }
            case 3:
                return this.renderPreviewPage();
        }
    };

    private renderDialog = () => {
        if (this.state.completeCompliance) {
            return this.renderConfirmationPopup();
        }
    };

    private addCompliance = async (draft : boolean) => {
        if (!this.state.selectedOrganization || !this.state.selectedSite || !this.state.selectedCommodity) {
            return;
        }

        if (this.state.selectedCommodity) {
            const selectedStocks = this.props.stocks.filter(x => this.state.selectedStockIds.some(y => y === x.id));
            const stocksWithNoOrchards = selectedStocks?.filter(x => x.stockLines.some(y => y.isActive && y.orchardId === null));

            if (stocksWithNoOrchards && stocksWithNoOrchards?.length > 0) {
                generalShowErrorSnackbar('Some of the lines on the selected stocks does not have orchards.');
                return;
            }
        }

        const newCompliance : ICreateWizardCompliance = {
            status: draft ? 'Draft' : 'Planned',
            stockIds: [...this.state.selectedStockIds],
        };

        this.setLoading(true);
        try {
            const res = await ComplianceHttpService.createWizardCompliance(newCompliance);

            if (res && res.data) {
                dataSetCompliance(res.data);
                generalShowSuccessSnackbar('Compliance successfully added.');
                this.setState({ completeCompliance: res.data });
            } else {
                generalShowErrorSnackbar('An error occurred adding the compliance.');
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred adding the compliance.');

        } finally {
            this.setLoading(false);
        }
    };

    private downloadFindingSheet = () => {
        if (this.state.completeCompliance !== undefined) {
            const sites = this.props.sites;
            const siteCode = sites.find(x => x.id === this.state.completeCompliance?.siteId)?.code;
            exportFindingSheet(this.state.completeCompliance, siteCode);
        }
    };

    private onComplianceExport = async (exportedCompliance : ICompliance) => {
        if (exportedCompliance) {
            const oldCompliance = { ...exportedCompliance };
            const newCompliance = { ...exportedCompliance };
            newCompliance.status = 'Inspecting';
            dataSetCompliance(newCompliance);
            newCompliance.updatedOn = undefined;
            this.setLoading(true);
            try {
                const res = await ComplianceHttpService.addOrUpdateCompliance(newCompliance);

                if (res && res.data) {
                    dataSetCompliance(res.data);
                    generalShowSuccessSnackbar('Compliance status successfully exported.');
                    this.setLoading(false);
                } else {
                    generalShowErrorSnackbar('An error occurred exporting the compliance.');
                    dataSetCompliance(oldCompliance);
                    this.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred exporting the compliance.');
                dataSetCompliance(oldCompliance);
                this.setLoading(false);
            }
        }
    };

    private csvObjToString = (data : Array<IConsignmentCSVJson>) => {
        let string = '';
        const firstObject = data[0];
        if (!firstObject) {
            return '';
        }
        const keys = lodash.keys(firstObject);
        string += keys.toString() + '\n';

        data.forEach((x) => {
            keys.forEach((y, i) => string += (x[y] ? x[y] : '') + ((i === keys.length - 1) ? '' : ','));
            string += '\n';
        });
        return string;
    };

    private submitToPPECB = () => {
        const compliance = this.state.completeCompliance;
        if (compliance) {
            this.openMessagePopup();
            const exportingCompliance = exportCompliance(compliance);
            const csvArr = exportingCompliance.filter((x, i) => i > 0).map(x => new ConsignmentCSVJson(x));
            const csvString = this.csvObjToString(csvArr);
            const fileName = get202FileName(compliance) ?? '';
            const file = new Blob([csvString]);
            onFileLoaded(csvArr, file, fileName, false, this.setMessageIsLoading, this.setMessage);
            onComplianceExport(compliance);
        }
    };

    private createAnotherCompliance = () => {
        this.setState(this.initialState);
    };

    private getOrganizationName = (orgId : number) => {
        const organizations = this.props.organizations;
        const organization = organizations && organizations.find(x => x.id === orgId);
        return organization ? organization.name : '';
    };

    private getCommoditiesFromStockLines = (id : number, row : IStock) => lodash.uniq(row.stockLines.filter(x => x.isActive && x.cartons > 0)
        .map(x => this.getCommodityCode(x.commodityId))).toString().replace(/,/g, ', ');

    private getCommodityCode = (commodityId : number) => this.props.commodities.find(x => x.id === commodityId)?.code || '';

    private getVarietiesFromStockLines = (id : number, row : IStock) => lodash.uniq(row.stockLines.filter(x => x.isActive && x.cartons > 0)
        .map(x => this.getVarietyCode(x.varietyId))).toString().replace(/,/g, ', ');

    private getVarietyCode = (varietyId : number) => this.props.varieties.find(x => x.id === varietyId)?.code || '';

    private getColoursFromStockLines = (id : number, row : IStock) => lodash.uniq(row.stockLines.filter(x => x.isActive && x.cartons > 0)
        .map(x => this.getColourCode(x.colourId))).toString().replace(/,/g, ', ');

    private getColourCode = (colourId ?: number) => this.props.colours.find(x => !!colourId && (x.id === colourId))?.code || '';

    private getGradesFromStockLines = (id : number, row : IStock) => lodash.uniq(row.stockLines.filter(x => x.isActive && x.cartons > 0)
        .map(x => this.getGradeCode(x.gradeId))).toString().replace(/,/g, ', ');

    private getGradeCode = (gradeId : number) => this.props.grades.find(x => x.id === gradeId)?.code || '';

    private getPacksFromStockLines = (id : number, row : IStock) => lodash.uniq(row.stockLines.filter(x => x.isActive && x.cartons > 0)
        .map(x => this.getPackCode(x.packId))).toString().replace(/,/g, ', ');

    private getPackCode = (packId : number) => this.props.packs.find(x => x.id === packId)?.code || '';

    private getSizesFromStockLines = (id : number, row : IStock) => lodash.uniq(row.stockLines.filter(x => x.isActive && x.cartons > 0)
        .map(x => this.getSizeCode(x.sizeId))).toString().replace(/,/g, ', ');

    private getSizeCode = (sizeId : number) => this.props.sizes.find(x => x.id === sizeId)?.code || '';

    private getRegionName = (regionId : number) => {
        const regions = this.props.regions;
        const region = regions && regions.find(x => x.id === regionId);
        return region ? region.name : '';
    };

    private getCountryName = (countryId : number) => {
        const countries = this.props.countries;
        const country = countries && countries.find(x => x.id === countryId);
        return country ? country.name : '';
    };

    private getPalletBaseTypeName = (palletBaseTypeId : number) => {
        const palletBaseTypes = this.props.palletBaseTypes;
        const palletBaseType = palletBaseTypes && palletBaseTypes.find(x => x.id === palletBaseTypeId);
        return palletBaseType ? palletBaseType.name : '';
    };

    private getMarketName = (marketId : number) => {
        const markets = this.props.markets;
        const market = markets && markets.find(x => x.id === marketId);
        return market ? market.name : '';
    };

    private getFarmCode = (farmId : number) => this.props.farms.find(x => x.id === farmId)?.code || '';

    private getSiteShortDescription = (id : number) => {
        const site = this.props.sites && this.props.sites.find(x => x.id === id);
        return site && site.shortDescription ? site.shortDescription : 'UNK?';
    };

    private getFarmString = (id : number, row : IStock) => {
        const returnArr : Array<string> = [];
        row.stockLines.forEach((x) => {
            const code = this.getFarmCode(x.farmId);
            if (!returnArr.some(y => y === code)) {
                returnArr.push(code);
            }
        });
        return returnArr.toString();
    };

    private handleStockCheckboxChecked = (row : IStock) => {
        if (!this.isChecked(row.id)) {
            this.setState(prevState => ({ selectedStockIds: [...prevState.selectedStockIds, row.id] }));
            if (!this.state.selectedOrganization && !this.state.selectedSite && !this.state.selectedCommodity) {
                this.setState({
                    selectedOrganization: this.props.organizations.find(x => x.id === row.currentOrganizationId),
                    selectedSite: this.props.sites.find(x => x.id === row.currentSiteId),
                    selectedCommodity: this.props.commodities.find(x => x.id === row.stockLines.filter(y => y.isActive)[0].commodityId),
                    selectedCountry: this.props.countries.find(x => x.id === row.countryId),
                });
            }
        } else {
            this.setState(prevState => ({ selectedStockIds: [...prevState.selectedStockIds.filter(x => x !== row.id)] }), () => {
                if ((this.state.selectedOrganization || this.state.selectedSite || this.state.selectedCommodity) && this.state.selectedStockIds.length === 0) {
                    this.setState({
                        selectedOrganization: undefined,
                        selectedSite: undefined,
                        selectedCommodity: undefined,
                        selectedCountry: undefined,
                    });
                }
            });
        }
    };

    private isStockCheckBoxDisabled = (row : IStock, dispatchScreen ?: boolean) => {
        if ((row.status !== 'In Stock') && !dispatchScreen) {
            return true;
        }
        if (row.stockLines.filter(x => x.isActive).length <= 0
            || !row.stockLines.filter(x => x.isActive)[0].commodityId
            || !row.stockLines.filter(x => x.isActive)[0].varietyId
            || !row.stockLines.filter(x => x.isActive)[0].farmId
            || uniq(row.stockLines.filter(x => x.isActive).map(x => x.commodityId)).length > 1) {
            return true;
        }
        if (!(this.state.selectedOrganization && this.state.selectedSite && this.state.selectedCommodity && this.state.selectedCountry)) {
            return false;
        }
        return !(row.currentOrganizationId === this.state.selectedOrganization.id
            && row.currentSiteId === this.state.selectedSite.id
            && row.countryId === this.state.selectedCountry.id
            && row.stockLines.filter(x => x.isActive)[0]?.commodityId === this.state.selectedCommodity.id);
    };

    private isStockLineOrchardsCompliant = (stock : IStock) => {
        const stockLines = stock.stockLines;
        const orchardIds = uniq(stockLines.map(x => x.isActive && x.orchardId));
        const orchards = this.props.orchards.filter(x => orchardIds.some(y => y === x.id));

        if ((orchards.length > 0) && !orchardIds.some(x => (x === undefined) || (x === null)) && orchards.every(x => !!x.complianceCode)) {
            return true;
        } else {
            return false;
        }
    };

    private stockHasMixedPacks = (stock : IStock) => {
        const stockLines = stock.stockLines;
        const firstStockLine = stockLines.filter(x => x.isActive)[0];
        return stockLines.filter(x => x.isActive).some(x => x.packId !== firstStockLine.packId);
    };

    private getStockRowTooltip = (row : IStock) => {
        if (row.stockLines.filter(x => x.isActive).length <= 0 || !row.stockLines.filter(x => x.isActive)[0].commodityId || !row.stockLines.filter(x => x.isActive)[0].varietyId || !row.stockLines.filter(x => x.isActive)[0].farmId) {
            return 'Stock has invalid stock lines';
        } else if (this.state.selectedOrganization && row.currentOrganizationId !== this.state.selectedOrganization.id) {
            return 'Stock is from a different organization to the already selected stock';
        } else if (this.state.selectedSite && row.currentSiteId !== this.state.selectedSite.id) {
            return 'Stock is from a different site to the already selected stock';
        } else if (this.state.selectedCommodity && row.stockLines.filter(x => x.isActive)[0].commodityId !== this.state.selectedCommodity.id) {
            return 'Stock is a different commodity to the already selected stock';
        } else if (this.stockHasMixedPacks(row)) {
            return 'Stock cannot have mixed packs on compliance';
        } else if (!this.isStockLineOrchardsCompliant(row)) {
            return 'Some orchards on this stock does not have a compliance code';
        } else if (uniq(row.stockLines.filter(x => x.isActive).map(x => x.commodityId)).length > 1) {
            return 'Stock has more than one commodity';
        } else if (row.status !== 'In Stock') {
            return 'Stock status must be "In Stock" before it can be added to a compliance';
        } else if (!!this.state.selectedCountry && row.countryId !== this.state.selectedCountry?.id) {
            return 'Cannot mix countries on a compliance';
        } else {
            return 'Select Stock';
        }
    };

    private isChecked = (id : number) => {
        return this.state.selectedStockIds.some(x => x === id);
    };

    private handleSelectAllCheckBox = (checked : boolean) => {
        let selectedStockIds : Array<number> = [];
        if (checked) {
            this.getStockRows(this.props, this.state).forEach((x) => {
                const disabled = this.isStockCheckBoxDisabled(x);
                const index = selectedStockIds.findIndex(y => y === x.id);

                if (!disabled && index === -1) {
                    selectedStockIds = addArrayElement(selectedStockIds, x.id);
                }
            });

            this.setState({ selectedStockIds });
        }

        this.setState({ selectedStockIds });
    };

    private getStockTableColumns = createSelector([this.getStockRows, this.getSelectedStockIds, this.getIsLoading], (stocks, selectedStocks, isLoading) => {
        return [
            /*
                TODO: Kept the old select checkboxes on this one for now since the salespoint checking works a little different, ideally we want
                to move this over to the datagrid's select but that isn't currently returning the selected items in the way we want.
            */
            {
                title: 'Select', field: 'id',  width: 100,
                titleContainerComponent: (value : string) => {
                    const rows = stocks.filter(x => x.isActive && !this.isStockCheckBoxDisabled(x));
                    return <div className={'fdr aic'}>
                        <Checkbox
                            className={'p0 mr10'}
                            indeterminate={(selectedStocks.length !== 0) && (selectedStocks.length !== rows.length)}
                            disabled={isLoading || (selectedStocks.length === 0) || (rows.length === 0)}
                            checked={selectedStocks.length === rows.length}
                            onChange={(event, checked) => this.handleSelectAllCheckBox(checked)}
                            onClick={e => e.stopPropagation()}
                        />
                        {value}
                    </div>;
                },
                containerComponent: (row : IStock) => {
                    return <CustomTooltip  title={this.getStockRowTooltip(row)}>
                        <Checkbox
                            disabled={(this.isStockCheckBoxDisabled(row) || !this.isStockLineOrchardsCompliant(row) || this.stockHasMixedPacks(row))}
                            checked={this.isChecked(row.id)}
                            onChange={() => this.handleStockCheckboxChecked(row)}
                        />
                    </CustomTooltip>;
                },
            },
            { title: 'Pack Date', field: 'packDate', width: 140, formatFunction: formatDateTimeToDateOnly, sortFunction: compareDate, type: 'date', enableFiltering: true, enableSorting: true },
            { title: 'Barcode', field: 'barcode', width: 170, enableFiltering: true, enableSorting: true },
            { title: 'Status', field: 'status', enableFiltering: true, enableSorting: true },
            { title: 'Original Organization', field: 'originalOrganizationId', formatFunction: this.getOrganizationName, enableFiltering: true, enableSorting: true },
            { title: 'Current Organization', field: 'currentOrganizationId', formatFunction: this.getOrganizationName, enableFiltering: true, enableSorting: true },
            { title: 'Pallet Base Type', field: 'palletBaseTypeId', formatFunction: this.getPalletBaseTypeName, enableFiltering: true, enableSorting: true },
            { title: 'Current Site', field: 'currentSiteId', formatFunction: this.getSiteShortDescription, enableFiltering: true, enableSorting: true },
            { title: 'Target Market', field: 'marketId', formatFunction: this.getMarketName, enableFiltering: true, enableSorting: true },
            { title: 'Farm(s)', field: 'id', formatFunction: this.getFarmString, enableFiltering: true, enableSorting: true },
            { title: 'Commodities', field: 'id', width: 150, formatFunction: this.getCommoditiesFromStockLines, enableFiltering: true, enableSorting: true },
            { title: 'Varieties', field: 'id', width: 150, formatFunction: this.getVarietiesFromStockLines, enableFiltering: true, enableSorting: true },
            { title: 'Colours', field: 'id', width: 110, formatFunction: this.getColoursFromStockLines, enableFiltering: true, enableSorting: true },
            { title: 'Grades', field: 'id', width: 110, formatFunction: this.getGradesFromStockLines, enableFiltering: true, enableSorting: true },
            { title: 'Packs', field: 'id', width: 150, formatFunction: this.getPacksFromStockLines, enableFiltering: true, enableSorting: true },
            { title: 'Sizes', field: 'id', width: 150, formatFunction: this.getSizesFromStockLines, enableFiltering: true, enableSorting: true },
            { title: 'Pallet Base Type', field: 'palletBaseTypeId', formatFunction: this.getPalletBaseTypeName, enableFiltering: true, enableSorting: true },
            { title: 'Target Region', field: 'regionId', formatFunction: this.getRegionName, enableFiltering: true, enableSorting: true },
            { title: 'Target Country', field: 'countryId', formatFunction: this.getCountryName, enableFiltering: true, enableSorting: true },
        ];
    });

    private getDispatchStockCount = (dispatchLines : Array<IDispatchInstructionLine>) => dispatchLines.length;

    private handleDispatchCheckboxChecked = (row : ISelectableDispatch) => {
        if (!this.isDispatchChecked(row.id)) {
            this.setState(prevState => ({ selectedDispatchInstructionIds: [...prevState.selectedDispatchInstructionIds, row.id] }));
            if (!this.state.selectedOrganization && !this.state.selectedSite && !this.state.selectedCommodity) {
                const stock = this.props.stocks.find(x => x.id === row.dispatchLines[0]?.currentStockId);
                this.setState({
                    selectedOrganization: this.props.organizations.find(x => x.id === stock?.currentOrganizationId),
                    selectedSite: this.props.sites.find(x => x.id === row.sourceSiteId),
                    selectedCommodity: this.props.commodities.find(x => x.id === row.dispatchLines[0].commodityId),
                });
            }
        } else {
            this.setState(prevState => ({ selectedDispatchInstructionIds: [...prevState.selectedDispatchInstructionIds.filter(x => x !== row.id)] }), () => {
                if ((this.state.selectedOrganization || this.state.selectedSite || this.state.selectedCommodity) && this.state.selectedDispatchInstructionIds.length === 0) {
                    this.setState({
                        selectedOrganization: undefined,
                        selectedSite: undefined,
                        selectedCommodity: undefined,
                    });
                }
            },
            );
        }
    };

    private isDispatchChecked = (id : number) => {
        return this.state.selectedDispatchInstructionIds.some(x => x === id);
    };

    private getStock = (id : number) => this.props.stocks.find(x => x.id === id);

    private isDispatchCheckboxDisabled = (row : IDispatchInstruction) => {
        if (row.dispatchLines.filter(x => x.isActive).length === 0 || row.dispatchLines.filter(x => x.isActive).some((x) => {
            const stock = this.getStock(x.currentStockId);
            return !stock || this.isStockCheckBoxDisabled(stock, true);
        })) {
            return true;
        }
        if (!(this.state.selectedOrganization && this.state.selectedSite && this.state.selectedCommodity)) {
            return false;
        }
        const firstStock = this.getStock(row.dispatchLines[0]?.currentStockId);
        return !(firstStock?.currentOrganizationId === this.state.selectedOrganization.id
            && row.sourceSiteId === this.state.selectedSite.id
            && row.dispatchLines[0].commodityId === this.state.selectedCommodity.id);
    };

    private getDispatchTooltip = (row : IDispatchInstruction) => {
        if (this.state.selectedDispatchInstructionIds.length > 0 && !this.state.selectedDispatchInstructionIds.some(x => x === row.id)) {
            return 'Only one dispatch may be selected';
        } else if (row.dispatchLines.filter(x => x.isActive).length === 0) {
            return 'This dispatch has no active dispatch lines';
        } else if (row.dispatchLines.filter(x => x.isActive).some((x) => {
            const stock = this.getStock(x.currentStockId);
            return !stock || this.isStockCheckBoxDisabled(stock, true);
        })) {
            return 'Some of the stock on this dispatch does not meet compliance requirements';
        } else {
            return 'Select dispatch';
        }
    };

    private getDispatchTableColumns = () => {
        const columns : Array<ICustomTableColumn> = [
            /*
                TODO: Kept the old select checkboxes on this one for now since the salespoint checking works a little different, ideally we want
                to move this over to the datagrid's select but that isn't currently returning the selected items in the way we want.
            */
            {
                title: 'Select',
                field: 'isSelected',
                width: 70,
                containerComponent: (row : ISelectableDispatch) => {
                    return <CustomTooltip title={this.getDispatchTooltip(row)}>
                        <Checkbox
                            disabled={this.isDispatchCheckboxDisabled(row)}
                            checked={this.isDispatchChecked(row.id)}
                            onChange={() => this.handleDispatchCheckboxChecked(row)}
                        />
                    </CustomTooltip>;
                },
            },
            { title: 'Dispatch Code', field: 'dispatchCode', width: 150, enableFiltering: true, enableSorting: true },
            { title: 'Source Site', field: 'sourceSiteId', formatFunction: this.getSiteShortDescription, width: 110, enableFiltering: true, enableSorting: true },
            { title: 'Destination Site', field: 'destinationSiteId', formatFunction: this.getSiteShortDescription, width: 110, enableFiltering: true, enableSorting: true },
            { title: 'Load Date', field: 'loadDate', formatFunction: formatDateTimeToDateOnly, sortFunction: compareDate, type: 'date', width: 110, enableFiltering: true, enableSorting: true },
            { title: 'Pallets', field: 'dispatchLines', formatFunction: this.getDispatchStockCount, width: 110, enableFiltering: true, enableSorting: true },
            { title: 'Status', field: 'status', width: 110, enableFiltering: true, enableSorting: true },
            { title: 'Transport', field: 'transport', width: 110, enableFiltering: true, enableSorting: true },
            { title: 'Driver', field: 'driver', width: 110, enableFiltering: true, enableSorting: true },
            { title: 'Trip', field: 'tripId', width: 110, enableFiltering: true, enableSorting: true },
            { title: 'Is Printed?', field: 'isPrinted', type: 'boolean', enableFiltering: true, enableSorting: true },
            { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
            { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
            { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
        ];
        return columns;
    };

    private onDispatchCreateClick = () => {
        this.setState({ isDispatches: true }, this.nextPage);
    };

    private onStockCreateClick = () => {
        this.setState({ isDispatches: false }, this.nextPage);
    };

    private previousPage = () => {
        this.setState(prevState => ({ currentPage: prevState.currentPage - 1 }),
            () => {
                if (this.state.currentPage === pages.start) {
                    this.setState(this.initialState);
                }
            },
        );
    };

    private getSelectedDispatches = createSelector([this.getDispatches, this.getSelectedDispatchIds],
        (dispatches, selectedDispatchIds) => dispatches.filter(x => selectedDispatchIds.some(y => y === x.id)));

    private nextPage = () => {
        const dispatch = this.state.isDispatches;
        this.setState(prevState => ({ currentPage: prevState.currentPage + 1, isDispatches: dispatch }),
            () => {
                if (this.state.currentPage === pages.stock) {
                    {
                        this.refreshData();
                    }
                } else if (this.state.currentPage === pages.preview && this.state.isDispatches) {
                    const stocks : Array<number> = [];
                    this.getSelectedDispatches(this.props, this.state).filter(x => x.isActive).forEach((ownedDispatch) => {
                        ownedDispatch.dispatchLines.filter(x => x.isActive).forEach((dispatchLine) => {
                            stocks.push(dispatchLine.currentStockId);
                        });
                    });
                    this.setState({ selectedStockIds: stocks });
                }
            },
        );
    };

    public openMessagePopup = () => this.setState({ isMessageOpen: true });
    public closeMessagePopup = () => this.setState({ isMessageOpen: false, message: '' });
    public setMessage = (message : string) => this.setState({ message });
    public setMessageIsLoading = (isMessageLoading : boolean) => this.setState({ isMessageLoading });

    private steps = [
        'Select Compliance Option',
        'Select Row Item',
        'Create Compliance',
    ];

    public render() {
        const { message, isMessageLoading: isMessageLoading, isMessageOpen } = this.state;
        return (
            <Screen isPadded={false} isScrollable={false} isLoading={this.state.isLoading}>
                <div className={'fdc oyh disableSelect hfill posr p20'}>
                    <Stepper activeStep={this.state.currentPage - 1} className={'pb20'}>
                        {
                            this.steps.map(label => <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>)
                        }
                    </Stepper>
                    {this.renderPage()}
                    {this.renderDialog()}
                    <PackmanDialog
                        isOpen={isMessageOpen}
                        onClose={this.closeMessagePopup}
                        isLoading={isMessageLoading} isInfo title='Response' >
                        <TextField
                            value={message}
                            multiline
                            variant={'outlined'}
                            disabled
                            className={'mnw400 mxh800 m5'}
                        />
                    </PackmanDialog>
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
        selectedExporterOrganization: state.data.selectedExporterOrganization,
        stocks: state.data.stocks,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        farms: state.masterData.farms,
        packs: state.masterData.packs,
        sizes: state.masterData.sizes,
        grades: state.masterData.grades,
        colours: state.masterData.colours,
        regions: state.masterData.regions,
        countries: state.masterData.countries,
        palletBaseTypes: state.masterData.palletBaseTypes,
        markets: state.masterData.markets,
        dispatchInstructions: state.data.dispatchInstructions,
        orchards: state.masterData.orchards,
    };
};

export default connect(
    mapStateToProps,
)(ComplianceWizard);

import * as React from 'react';
import { IRootState, IAuthState } from '../../@types/redux';
import { connect } from 'react-redux';
import { Card, Icon, IconButton, Paper, TextField, Tooltip } from '@mui/material';
import { formatDateTime, compareDate, addArrayElement } from '../../services/appFunctionsService';
import CustomTable from '../../components/datagrid/CustomTable';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { dataSetAllStockRelatedData, dataSetCompliance, dataSetStock } from '../../store/data/Functions';
import moment from 'moment';
import materialTheme from '../../styles/materialTheme';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { IStock } from '../../@types/model/stock/stock';
import { ISite } from '../../@types/model/masterData/site/site';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { IMarket } from '../../@types/model/masterData/market/market';
import { IRegion } from '../../@types/model/masterData/region/region';
import { ICountry } from '../../@types/model/masterData/country/country';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { ISize } from '../../@types/model/masterData/size/size';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import { ICompliance } from '../../@types/model/compliance/compliance';
import { IPalletBaseType } from '../../@types/model/masterData/palletBaseType/palletBaseType';
import { IPackCategory } from '../../@types/model/masterData/pack/packCategory';
import { IUniqueCodeObject } from '../../@types/model';
import ComplianceHttpService from '../../services/http/compliance/complianceHttpService';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import ComplianceSummary from './ComplianceSummary';
import { createSelector } from 'reselect';
import Screen from '../../components/Screen';
import ComplianceWizard from './ComplianceWizard';
import TransactionFilter from '../../components/filters/BasicTransactionScreenFilter';
import { CustomChangeEvent, IOptionType } from '../../@types/helper';
import GoogleCloudStorageHttpService from '../../services/googleCloudStorageService';
import lodash from 'lodash';
import PillButton from '../../components/input/PillButton';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../@types/model/user/right';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT_NO_TIME } from '../../appConstants';
import { getConsignmentToken, getTURToken } from '../../services/inspectionService';
import ConsignmentHttpService from '../../services/http/consignment/consignmentHttpService';
import { IConsignmentStorage } from '../../@types/model/compliance/titan-TUR/consignmentStorage';
import FileSaver from 'file-saver';
import { navCompliance, navPath } from '../../store/nav/Actions';
import CustomTooltip from '../../components/tooltip/tooltip';
import { syncMasterData } from '../../services/masterDataSyncService';
import DeleteConfirmationDialog from '../../components/dialog/DeleteConfirmationDialog';

const path = 'InspectionCertificates/';

interface IComplianceTransactionScreenProps extends RouteComponentProps {
    organizations : Array<IOrganization>;
    stocks : Array<IStock>;
    sites : Array<ISite>;
    farms : Array<IFarm>;
    markets : Array<IMarket>;
    regions : Array<IRegion>;
    countries : Array<ICountry>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    sizes : Array<ISize>;
    colours : Array<IColour>;
    grades : Array<IGrade>;
    orchards : Array<IOrchard>;
    auth : IAuthState;
    compliances : Array<ICompliance>;
    palletBaseTypes : Array<IPalletBaseType>;
    selectedCommodities : Array<ICommodity>;
    selectedVarieties : Array<IUniqueCodeObject>;
    selectedPackCategories : Array<IPackCategory>;
    selectedColours : Array<IUniqueCodeObject>;
    selectedGrades : Array<IUniqueCodeObject>;
    selectedDashboardSiteIds : Array<number>;
    selectedOrganizationIds : Array<number> ;
    selectedSiteIds : Array<number> ;
    selectedExporterOrganization ?: IOrganization;
}

interface IComplianceTransactionScreenState {
    isLoading : boolean;
    rows : Array<ICompliance>;
    consignments : Array<IConsignmentStorage>;
    selectedComplianceId ?: number;
    selectedComplianceWaybill ?: string;
    editingCompliance ?: ICompliance;
    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;
    isShowingComplianceSummary : boolean;
    isDeleting : boolean;
    deleteItem ?: ICompliance;
    isAdding : boolean;

    isComplianceFilesDownloadDialogOpen : boolean;
    isComplianceFilesPopupOpen : boolean;
    complianceFilesSites ?: Array<any>;
    complianceFiles ?: Array<any>;
    complianceFilesCreatedDates : Array<string>;
    selectedComplianceSite ?: IOptionType;
    searchFilesText : string;
    selectedFilterDate : string | null;
    selectedComplianceFolder : string;
    barcodeSearchValue ?: string;
}

class ComplianceTransactionScreen extends React.Component<IComplianceTransactionScreenProps, IComplianceTransactionScreenState> {
    constructor(props : IComplianceTransactionScreenProps) {
        super(props);

        this.state = {
            isLoading: false,
            rows: [],
            selectedFromDate: moment().local().subtract(3, 'days').startOf('day'),
            selectedToDate: moment().local().endOf('day'),
            isShowingComplianceSummary: false,
            isDeleting: false,
            deleteItem: undefined,
            isAdding: false,
            searchFilesText: '',
            consignments: [],
            selectedFilterDate: null,
            selectedComplianceFolder: '',

            isComplianceFilesDownloadDialogOpen: false,
            isComplianceFilesPopupOpen: false,
            complianceFilesCreatedDates: [],
        };
    }

    public componentDidMount = async () => {
        if (this.props.location.pathname?.includes(':')) {
            this.showSummaryDialog(Number(this.props.location.pathname.split(':')[1].split('/')[0]));
        } else {
            this.setLoading(true);
            // checks if indexedDB is available.
            const isIndexedDBAvailable = !!self.indexedDB ? true : false;

            if (isIndexedDBAvailable) {
                await syncMasterData(false);
            }

            if (this.props.selectedSiteIds?.length === 1) {
                const selectedSite : IOptionType = { label: this.getSiteDescription(this.props.selectedSiteIds[0]) , value: this.props.selectedSiteIds[0]  };
                this.setState({ selectedComplianceSite: selectedSite });
                this.getComplianceFilesBySite(this.props.selectedSiteIds[0]);
            }

            try {
                const res = await ComplianceHttpService.getComplianceDashboardData(this.getDate('from'), this.getDate('to'), undefined, this.props.selectedSiteIds, !isIndexedDBAvailable);

                const complianceFilesSites = await GoogleCloudStorageHttpService.getListData(path);

                if (complianceFilesSites) {
                    this.setState({ complianceFilesSites: complianceFilesSites.data });
                }
                dataSetAllStockRelatedData(res.data);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred retrieving compliance data.');
                this.setLoading(false);
            }

            try {
                if (!this.props.auth.ppecbToken?.token) {
                    await getConsignmentToken();
                }
                if (!this.props.auth.turToken?.access_token) {
                    await getTURToken();
                }
                const res = await ConsignmentHttpService.getConsignmentData();

                this.setState({ consignments: res?.data || [] }, this.setLoading);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred retrieving consignment data.');
                this.setLoading(false);
            }
        }
    };

    public componentDidUpdate = (prevProps : IComplianceTransactionScreenProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            /* prop changes go here */
            if (prevProps.location.pathname?.includes(':') && !nextProps.location.pathname?.includes(':')) {
                this.closeSummaryDialog();
            }
            if (!prevProps.location.pathname?.includes(':') && nextProps.location.pathname?.includes(':')) {
                this.showSummaryDialog(Number(nextProps.location.pathname.split(':')[1].split('/')[0]));
            }
            if (prevProps.location.pathname?.includes(':') && nextProps.location.pathname?.includes(':')) {
                const prevId = prevProps.location.pathname.split(':')[1].split('/')[0];
                const nextId = nextProps.location.pathname.split(':')[1].split('/')[0];
                if (prevId !== nextId) {
                    this.showSummaryDialog(Number(nextId));
                }
            }
            if (nextProps.selectedSiteIds !== undefined && prevProps.selectedSiteIds !== nextProps.selectedSiteIds) {
                this.refreshData();
                if (nextProps.selectedSiteIds?.length !== 1) {
                    this.setState({ selectedComplianceSite: undefined, complianceFiles: undefined });
                } else {
                    const selectedSite : IOptionType = { label: this.getSiteDescription(nextProps.selectedSiteIds[0]) , value: nextProps.selectedSiteIds[0]  };
                    this.setState({ selectedComplianceSite: selectedSite });
                    this.getComplianceFilesBySite(nextProps.selectedSiteIds[0]);
                }
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    private getDate = (type : 'from' | 'to') => {
        switch (type) {
            case 'from':
                return this.state.selectedFromDate.startOf('day').utc().unix() * 1000;
            case 'to':
                return this.state.selectedToDate.endOf('day').utc().unix() * 1000;
        }
    };

    private getOrganizationCode = (id : number) => {
        const organizations = this.props.organizations;
        const organization = organizations && organizations.find(x => x.id === id);
        return organization ? organization.code : '';
    };

    private getComplianceLineFarmCodes = (id : number, compliance : ICompliance) => {
        const stocks = this.props.stocks.filter(x => compliance?.complianceLines.some(y => x.id === y.stockId));
        return lodash.uniq(lodash.flatten(stocks.map(x => x.stockLines.filter(y => y.isActive)))
            .map(x => this.getFarmCode(x.farmId))).toString().replace(/,/g, ', ');
    };

    private getComplianceLineVarietyCodes = (id : number, compliance : ICompliance) => {
        const stocks = this.props.stocks.filter(x => compliance?.complianceLines.some(y => x.id === y.stockId));
        return lodash.uniq(lodash.flatten(stocks.map(x => x.stockLines.filter(y => y.isActive)))
            .map(x => this.getVarietyCode(x.varietyId))).toString().replace(/,/g, ', ');
    };

    private getTotalPallets = (id : number, compliance : ICompliance) => compliance?.complianceLines.filter(x => x.isActive).length;

    private getFarmCode = (farmId : number) => {
        const farms = this.props.farms;
        const farm = farms && farms.find(x => x.id === farmId);
        return farm ? farm.code : '';
    };

    private getCommodityCode = (id : number) => {
        const commodities = this.props.commodities;
        const commodity = commodities && commodities.find(x => x.id === id);
        return commodity ? commodity.code : '';
    };

    private getVarietyCode = (id : number) => {
        const varieties = this.props.varieties;
        const variety = varieties && varieties.find(x => x.id === id);
        return variety ? variety.code : '';
    };

    private refreshData = async (noAnnounce ?: boolean) => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;
        try {
            const res = await ComplianceHttpService.getComplianceDashboardData(this.getDate('from'), this.getDate('to'), this.state.barcodeSearchValue, this.props.selectedSiteIds, !isIndexedDBAvailable);

            dataSetAllStockRelatedData(res.data);
            this.setLoading(false);
            if (!noAnnounce) {
                generalShowSuccessSnackbar('Data Refreshed');
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving stock related data.');
            this.setLoading(false);
        }
    };

    public setSelectedComplianceWaybill = (waybill : string) => {
        this.setState({ selectedComplianceWaybill: waybill });
    };

    public showSummaryDialog = (id : number) => {
        this.setState({
            isShowingComplianceSummary: true,
            selectedComplianceId: id,
        });
    };

    public closeSummaryDialog = () => {
        this.setState({
            isShowingComplianceSummary: false,
            selectedComplianceId: undefined,
        }, () => navPath(this.props.location?.pathname?.split('/:')[0]));
    };

    private getSiteDescription = (id : number) => {
        const site = this.props.sites && this.props.sites.find(x => x.id === id);
        return site && site.description ? site.description : 'UNKNOWN';
    };

    private getSites = (props : IComplianceTransactionScreenProps) => props.sites;
    private getCompliances = (props : IComplianceTransactionScreenProps) => props.compliances;
    private getExporterOrganization = (props : IComplianceTransactionScreenProps) => props.selectedExporterOrganization;
    private getSelectedOrganizationIds = (props : IComplianceTransactionScreenProps) => props.selectedSiteIds;
    private getSelectedSiteIds = (props : IComplianceTransactionScreenProps) => props.selectedSiteIds;
    private getStocks = (props : IComplianceTransactionScreenProps) => props.stocks;
    private getBarcodeSearchValue = (props : IComplianceTransactionScreenProps, state : IComplianceTransactionScreenState) => state.barcodeSearchValue;

    private getRows = createSelector(
        [this.getCompliances, this.getSites, this.getStocks, this.getBarcodeSearchValue, this.getExporterOrganization, this.getSelectedOrganizationIds, this.getSelectedSiteIds],
        (compliances, sites, stocks, barcodeSearchValue, exporterOrganization, selectedOrganizationIds, selectedSiteIds) => {
            const filteredAllSites = sites.filter(x => exporterOrganization
                ? x.organizationIds.some(y => y === exporterOrganization.id)
                : x.organizationIds?.some(y => selectedOrganizationIds?.some(z => z === y)));

            const stock = barcodeSearchValue ? stocks.find(x => x.barcode.includes(barcodeSearchValue)) : undefined;

            return compliances.filter(x => (exporterOrganization ? (x.organizationId === exporterOrganization?.id) : true)
                    && (selectedSiteIds?.some(y => y === x.siteId) || filteredAllSites.some(y => y.id === x.siteId))
                    && (!stock || x.complianceLines.some(y => y.isActive && y.stockId === stock?.id)))
                .map((x) => {
                    return {
                        ...x,
                        organizationId: x.organizationId,
                        commodityId: x.commodityId,
                        status: x.status,
                        waybill: x.waybill,
                        upn: x.upn,
                        inspectorId: x.inspectorId,
                    };
                });
        },
    );

    private addCompliance = () => {
        this.setState({
            isAdding: true,
        });
    };

    public closeAddComplianceDialog = () => {
        this.setState({
            isAdding: false,
        });
        this.refreshData();
    };

    private editCompliance = (row : ICompliance) => {
        this.setState({
            editingCompliance: row,
        });
    };

    public closeEditDialog = () => {
        this.setState({
            editingCompliance: undefined,
        });
        this.refreshData();
    };

    private setDeleteItem = (item ?: ICompliance) => {
        this.setState({
            deleteItem: item,
            isDeleting: true,
        });
    };

    private removeCompliance = async (deleteReason : string) => {
        const newCompliance = this.state.deleteItem;

        if (newCompliance) {
            newCompliance.isActive = false;

            if (deleteReason !== '' && deleteReason.length >= 200) {
                this.setLoading(true);
                try {
                    const res = await ComplianceHttpService.deleteCompliance(newCompliance.id, deleteReason);

                    if (res && res.data) {
                        newCompliance.complianceLines.forEach((x) => {
                            if (x.stock) {
                                dataSetStock({ ...x.stock });
                            }
                            x.stock = undefined;
                        });
                        dataSetCompliance(res.data);
                        if (res.data.id === (this.state.selectedComplianceId)) {
                            this.setState({ selectedComplianceId: undefined });
                        }
                        generalShowSuccessSnackbar('Compliance successfully removed.');
                        this.setLoading(false);
                    } else {
                        generalShowErrorSnackbar('An error occurred removing the compliance.');
                        this.setLoading(false);
                    }
                } catch (e) {
                    generalShowErrorSnackbar('An error occurred removing the compliance.');
                    newCompliance.isActive = true;
                } finally {
                    this.closeDeleteConfirmationPopup();
                    this.setLoading(false);
                }
            } else {
                generalShowErrorSnackbar('Reason for deleting this compliance must be at least 200 characters.');
            }
        }
    };

    private closeDeleteConfirmationPopup = () => this.setState({ isDeleting: false, deleteItem: undefined });

    private handleDateRangeChange = (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => {
        const selectedFromDate = changedBy === 'start' ? moment(start).startOf('day') : end < start ? moment(end).startOf('day') : moment(start).startOf('day');
        const selectedToDate = changedBy === 'end' ? moment(end).endOf('day') : start > end ? moment(start).endOf('day') : moment(end).endOf('day');
        this.setState({ selectedFromDate, selectedToDate });
    };

    private getColumns = () => {
        const columns = [];
        columns.push(
            { title: 'Organization', field: 'organizationId', formatFunction: this.getOrganizationCode, enableFiltering: true, enableSorting: true },
            { title: 'Commodity', field: 'commodityId', formatFunction: this.getCommodityCode, enableFiltering: true, enableSorting: true },
            { title: 'Varieties', field: 'id', formatFunction: this.getComplianceLineVarietyCodes, width: 200, enableFiltering: true, enableSorting: true },
            { title: 'Farms', field: 'id', formatFunction: this.getComplianceLineFarmCodes, width: 200, enableFiltering: true, enableSorting: true },
            { title: 'Status', field: 'status', enableFiltering: true, enableSorting: true },
            { title: 'Waybill', field: 'waybill', enableFiltering: true, enableSorting: true },
            { title: 'E-Lot Key', field: 'eLotKey', enableFiltering: true, enableSorting: true },
            { title: 'Inspection Message Id', field: 'inspectionMessageId', enableFiltering: true, enableSorting: true },
            { title: 'No. Pallets', field: 'id', type: 'number', formatFunction: this.getTotalPallets, enableFiltering: true, enableSorting: true },
            { title: 'UPN', field: 'upn', width: 200, enableFiltering: true, enableSorting: true },
            { title: 'Inspector ID', field: 'inspectorId', enableFiltering: true, enableSorting: true },
            { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
            { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
        );
        return columns;
    };

    private openInspectionCertificateDownloadDialog = () => {
        if (this.props.selectedSiteIds?.length === 1) {
            const selectedSite = this.props.sites.find(x => x.id === this.props.selectedSiteIds[0]);
            const siteOptionType : IOptionType | undefined = selectedSite ? { label: selectedSite.description , value: selectedSite.id } : undefined;

            if (siteOptionType) {
                this.getComplianceFilesBySite(Number(siteOptionType.value));
            }

            this.setState({ isComplianceFilesDownloadDialogOpen: true, selectedComplianceSite: siteOptionType });
        } else {
            this.setState({ isComplianceFilesDownloadDialogOpen: true, selectedComplianceSite: undefined });
        }
        this.setState({ isComplianceFilesDownloadDialogOpen: true });
    };

    private closeInspectionCertificateDownloadDialog = () => {
        this.setState({ isComplianceFilesDownloadDialogOpen: false, selectedComplianceSite: undefined, complianceFiles: undefined, selectedFilterDate: null });
    };

    public onInspectionCertificateSiteChange = (e : React.ChangeEvent<{}>, selectedComplianceSite : IOptionType) => {
        this.setState({ selectedComplianceSite });
        this.getComplianceFilesBySite(Number(selectedComplianceSite.value));
    };

    private getComplianceFilesBySite = async (siteId : number) => {
        const site = this.props.sites.find(x => x.id === siteId);
        if (site) {
            this.setLoading(true);
            const folderPath = path + `${site.code + '_' + site.guid}/`;
            const complianceFilesFromSite = await GoogleCloudStorageHttpService.getListData(folderPath);

            if (complianceFilesFromSite) {
                const allDates = complianceFilesFromSite.data?.map(x => moment(x.timeCreated).format(DATE_FORMAT_DEFAULT_NO_TIME));
                const complianceFilesCreatedDates = lodash.uniq(allDates);

                this.setState({ complianceFiles: complianceFilesFromSite.data, complianceFilesCreatedDates });
                this.setLoading(false);
            }
        }
    };

    private getComplianceFilesSites = (props : IComplianceTransactionScreenProps, state : IComplianceTransactionScreenState) => state.complianceFilesSites;

    private getComplianceFilesSitesOptions = createSelector(
        [this.getComplianceFilesSites, this.getSites],
        (complianceFilesSites : Array<any>, sites : Array<ISite>) => {
            if (!complianceFilesSites && !sites) return [];

            return sites.filter(x => complianceFilesSites?.some(y => this.getComplianceFilesSiteName(y.name) === `${x.code}_${x.guid}`) && x.isActive).map((x) => {
                return { label: x.description, value: x.id };
            });
        },
    );

    private getComplianceFilesSiteName = (inspectionCertificateSiteName : string) => {
        return inspectionCertificateSiteName.split('/')[1];
    };

    private downloadComplianceFile = async (fileName : string, selectedComplianceWaybill : string, contentType : string) => {
        const selectedSite = this.state.selectedComplianceSite;
        const site = this.props.sites.find(x => x.id === selectedSite?.value);
        const filePath = path + `${site?.code + '_' + site?.guid}/${selectedComplianceWaybill}/`;
        if (site) {
            try {
                this.setLoading(true);
                const res = await GoogleCloudStorageHttpService.downloadFile(filePath, fileName);

                if (res) {

                    const byteCharacters = atob(res.data);
                    const byteNumbers = lodash.map(byteCharacters, (x, i) => byteCharacters.charCodeAt(i));
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: contentType });
                    if (blob) {
                        FileSaver.saveAs(blob, fileName);
                        generalShowSuccessSnackbar('Compliance file downloaded successfully.');
                        this.setLoading(false);
                    }
                }
            } catch (e) {
                generalShowErrorSnackbar('Error while downloading compliance file.');
                this.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Selected compliance site not found!');
        }
    };

    private getComplianceFiles = (props : IComplianceTransactionScreenProps, state : IComplianceTransactionScreenState) => state.complianceFiles;
    private getSearchFilesText = (props : IComplianceTransactionScreenProps, state : IComplianceTransactionScreenState) => state.searchFilesText;
    private getSelectedFilterDate = (props : IComplianceTransactionScreenProps, state : IComplianceTransactionScreenState) => state.selectedFilterDate;
    private getSelectedComplianceFolder = (props : IComplianceTransactionScreenProps, state : IComplianceTransactionScreenState) => state.selectedComplianceFolder;

    private getComplianceFilesList = createSelector(
        [this.getComplianceFiles, this.getSearchFilesText, this.getSelectedFilterDate],
        (complianceFiles : Array<any>, searchFilesText : string, selectedFilterDate : string) => {
            if (!complianceFiles) return [];

            let newComplianceFilesList : Array<any> = [];

            complianceFiles.filter(x => selectedFilterDate ? moment(x.timeCreated).format(DATE_FORMAT_DEFAULT_NO_TIME) === selectedFilterDate : (x.name.toLowerCase().includes(searchFilesText.toLowerCase())))
                .forEach((x) => {
                    const index = newComplianceFilesList.findIndex(y => y.name.split('/')[2] === x.name.split('/')[2]);
                    if (index === -1) {
                        newComplianceFilesList = addArrayElement(newComplianceFilesList, x);
                    }
                });

            return newComplianceFilesList.map(x => this.getComplianceInspectionCertificateFolders(x));
        },
    );

    private getComplianceInspectionCertificateFolders = (complianceFiles : any) => {
        const dispatchNoteName : string = complianceFiles.name;
        const complianceWaybill = dispatchNoteName.split('/')[2];
        return (
            <CustomTooltip title={'Open Compliance Folder?'}>
                <Card style={{ minHeight: 40 }} className={'h40 w200 m5 aic jcc curp'} onClick={() => this.openComplianceInspectionCertificatePopup(complianceWaybill)}>{complianceWaybill}</Card>
            </CustomTooltip>
        );
    };

    private openComplianceInspectionCertificatePopup = (complianceWaybill : string) => {
        this.setState({ isComplianceFilesPopupOpen: true, selectedComplianceFolder: complianceWaybill });
    };

    private closeComplianceInspectionCertificatePopup = () => {
        this.setState({ isComplianceFilesPopupOpen: false, selectedComplianceFolder: '' });
    };

    private getInspectionCertificates = createSelector(
        [this.getComplianceFiles, this.getSelectedComplianceFolder],
        (complianceFiles : Array<any>, selectedComplianceFolder : string) => {
            if (!complianceFiles) return [];

            return complianceFiles.filter(x => x.name.toLowerCase().includes(selectedComplianceFolder.toLowerCase()))
                .map(x => this.getInspectionCertificateToBeDownloaded(x));
        },
    );

    private getInspectionCertificateToBeDownloaded = (inspectionCertificate : any) => {
        const inspectionCertificateName : string = inspectionCertificate.name;
        const selectedComplianceWaybill = inspectionCertificateName.split('/')[2];
        const name = inspectionCertificateName.split('/')[3];
        return (
            <CustomTooltip title={'Download Compliance File?'}>
                <Card style={{ maxHeight: 40, minHeight: 40 }} className={'h40 w350 m5 aic jcc curp'} onClick={() => this.downloadComplianceFile(name, selectedComplianceWaybill, inspectionCertificate.contentType)}>{name}</Card>
            </CustomTooltip>
        );
    };

    private getRights = (props : IComplianceTransactionScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IComplianceTransactionScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    private onSearchFileTextChange = (event : CustomChangeEvent) => {
        this.setState({ searchFilesText: event.currentTarget.value });
    };

    public onFilterDateChange = (selectedDate : moment.Moment) => {
        this.setState({ selectedFilterDate: moment(selectedDate).format(DATE_FORMAT_DEFAULT_NO_TIME) });
    };

    private clearDatePickerInput = () => {
        this.setState({ selectedFilterDate: null });
    };

    private disableDay = (day : any) => {
        const datesSubmitted = this.state.complianceFilesCreatedDates;

        return !(datesSubmitted.length > 0 && datesSubmitted?.some(x => x === moment(day).format(DATE_FORMAT_DEFAULT_NO_TIME)));
    };

    private barcodeSearchValueChanged = (event : CustomChangeEvent) => {
        this.setState({ barcodeSearchValue: event.target.value });
    };

    public render() {
        const { consignments, selectedComplianceId, selectedComplianceSite, editingCompliance } = this.state;
        return (
            <Screen isPadded={false} isLoading={this.state.isLoading} isScrollable={false}>
                <div className={'fdc hfill'}>
                    <div className={'fdr'}>
                        <CustomTooltip title={`${this.props.selectedSiteIds?.length < 1 ? 'Please select specific site' : ''}`}>
                            <PillButton
                                text={'Download Compliance Files'}
                                color={'secondary'}
                                className={'w250 cpd fw500 mt20 ml20'}
                                onClick={() => this.openInspectionCertificateDownloadDialog()}
                                disabled={this.props.selectedSiteIds?.length < 1}
                            />
                        </CustomTooltip>
                        <div className={'flx1'}/>
                        <TextField fullWidth className={'mt15 mr10 w250'} variant='standard' label={'Filter By Barcode'} value={this.state.barcodeSearchValue} onChange={this.barcodeSearchValueChanged}/>
                        <Tooltip disableInteractive title={'Search remote for barcode'}>
                            <IconButton className={'mt30 mr10 h20 w20'} onClick={() => this.refreshData(true)}><Icon>search</Icon></IconButton>
                        </Tooltip>
                        <div className={'w10'}/>
                        <TransactionFilter className={'pt5 mt10 mr10 mb10'} selectedFromDate={this.state.selectedFromDate} selectedToDate={this.state.selectedToDate} handleDateRangeChange={this.handleDateRangeChange} onApplyClick={this.refreshData} />
                    </div>
                    {/* Compliance Stock Table */}
                    <Paper className={'mr20 mt10 mb10 ml20'} style={{ height: 'calc(100% - 93px)' } }>
                        <CustomTable<ICompliance>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.addCompliance}
                            editFunction={this.editCompliance}
                            enableEditing={(row : ICompliance) => this.hasEditingRight(this.props) && row.isActive}
                            disableEditButton={(row : ICompliance) => !['Draft', 'Planned'].some(x => row.status === x) }
                            editTooltip={(row : ICompliance) => !['Draft', 'Planned'].some(x => row.status === x) ? 'Compliance status must be Draft or Planned' : undefined}
                            enableDeleting={(row : ICompliance) => row.isActive && row.complianceLines.some(x => x.isActive ? !x.isInspected : true) && this.hasEditingRight(this.props)}
                            deleteColor={materialTheme.palette.primary.main}
                            deleteFunction={this.setDeleteItem}
                            enableDetails={true}
                            detailIcon={'info'}
                            detailTooltip={'Compliance Summary'}
                            detailFunction={row => navCompliance(row.id)}
                            enableSorting
                            enableFiltering
                            enableClearFilterButton
                            enablePagination
                            enableRefresh
                            disableRefreshButton={this.state.isLoading}
                            refreshFunction={this.refreshData}
                            columns={this.getColumns()}
                            rows={this.getRows(this.props, this.state)}
                            initialSortOrder={[{ columnName: 'createdOn_Created On', direction : 'desc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={250}
                            isActive={(row : ICompliance) => row.isActive}
                        />
                    </Paper>
                    {/* Summary Dialog */}
                    <PackmanDialog
                        title={`Compliance Summary - ${this.state.selectedComplianceWaybill}`}
                        isInfo={true}
                        fullScreen={true}
                        isOpen={this.state.isShowingComplianceSummary}
                        onClose={this.closeSummaryDialog}>
                        {selectedComplianceId &&
                            <ComplianceSummary
                                setWaybill={this.setSelectedComplianceWaybill}
                                consignments={consignments}
                                selectedComplianceId={selectedComplianceId}
                                refreshData={this.refreshData}
                            />
                        }
                    </PackmanDialog >
                    <PackmanDialog
                        title='Compliance Wizard'
                        isInfo={true}
                        fullScreen={true}
                        isOpen={this.state.isAdding}
                        onClose={this.closeAddComplianceDialog}>
                        <ComplianceWizard/>
                    </PackmanDialog >
                    <PackmanDialog
                        title='Compliance Wizard'
                        isInfo={true}
                        fullScreen={true}
                        isOpen={!!editingCompliance}
                        onClose={this.closeEditDialog}>
                        {editingCompliance &&
                            <ComplianceWizard
                                selectedCompliance={editingCompliance}
                            />
                        }
                    </PackmanDialog >
                    {/* Compliance Files Download Dialog */}
                    <PackmanDialog
                        title={'Download Compliance Files'}
                        isInfo
                        isLoading={this.state.isLoading}
                        isOpen={this.state.isComplianceFilesDownloadDialogOpen}
                        onClose={this.closeInspectionCertificateDownloadDialog}>
                        <Screen isPadded={false} isScrollable={false}>
                            <div className={'h600 aic fdc p20'}>
                                <AutocompleteSelect
                                    name={'sites'}
                                    label={'Sites'}
                                    options={this.getComplianceFilesSitesOptions(this.props, this.state)}
                                    value={selectedComplianceSite}
                                    onChange={this.onInspectionCertificateSiteChange}
                                    className={'m5 w270'}
                                    disabled={this.props.selectedSiteIds?.length === 1}
                                />
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        className={'w250 m5'}
                                        label={'Date Submitted'}
                                        format={'DD/MM/YYYY'}
                                        disabled={this.state.isLoading || !this.state.selectedComplianceSite || !this.state.complianceFiles || this.state.complianceFiles?.length < 1}
                                        value={this.state.selectedFilterDate ? moment(this.state.selectedFilterDate, DATE_FORMAT_DEFAULT) : null}
                                        onChange={this.onFilterDateChange}
                                        disableFuture
                                        shouldDisableDate={this.disableDay}
                                        slotProps={{
                                            textField: { variant: 'standard' },
                                            actionBar: {
                                                actions: ['clear'],
                                                onClick: this.clearDatePickerInput,
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                                <TextField
                                    className={'w250 m5'}
                                    label={'Search'}
                                    placeholder={'Search...'}
                                    variant={'standard'}
                                    onChange={this.onSearchFileTextChange}
                                    value={this.state.searchFilesText}
                                    disabled={!this.state.selectedComplianceSite || this.state.isLoading}
                                />
                                <div style={{ maxHeight: 300 }} className={'fdc flx1 m5 oya'}>
                                    {selectedComplianceSite ?
                                        this.getComplianceFilesList(this.props, this.state)
                                        :
                                        'No Site Selected'
                                    }
                                </div>
                            </div>
                        </Screen>
                    </PackmanDialog >
                    {/* Compliance Files Popup */}
                    <PackmanDialog
                        title={'Download Compliance Files'}
                        isInfo
                        isLoading={this.state.isLoading}
                        isOpen={this.state.isComplianceFilesPopupOpen}
                        onClose={this.closeComplianceInspectionCertificatePopup}>
                        <Screen isPadded={false} isScrollable={false}>
                            <div style={{ maxHeight: 500, height: 500 }} className={'w400 aic fdc p10 oya'}>
                                {(this.state.complianceFiles && this.state.complianceFiles?.filter(x => x.name.toLowerCase().includes(this.state.selectedComplianceFolder.toLowerCase())).length > 0) &&
                                        this.getInspectionCertificates(this.props, this.state)
                                }
                            </div>
                        </Screen>
                    </PackmanDialog >
                    {!!this.state.isDeleting &&
                        <DeleteConfirmationDialog
                            isLoading={this.state.isLoading}
                            onSubmit={this.removeCompliance}
                            onclose={this.closeDeleteConfirmationPopup}
                            isOpen={!!this.state.isDeleting}
                            title={'Compliance'}/>
                    }
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        organizations: state.masterData.organizations,
        compliances: state.data.compliances,
        stocks: state.data.stocks,
        sites: state.masterData.sites,
        farms: state.masterData.farms,
        markets: state.masterData.markets,
        regions: state.masterData.regions,
        countries: state.masterData.countries,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        sizes: state.masterData.sizes,
        colours: state.masterData.colours,
        grades: state.masterData.grades,
        orchards: state.masterData.orchards,
        auth: state.auth,
        selectedCommodities: state.data.selectedDashboardCommodities,
        selectedVarieties: state.data.selectedDashboardVarieties,
        selectedPackCategories: state.data.selectedDashboardPackCategories,
        selectedColours: state.data.selectedDashboardColours,
        selectedGrades: state.data.selectedDashboardGrades,
        selectedDashboardSiteIds: state.data.selectedDashboardSiteIds,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
        selectedExporterOrganization: state.data.selectedExporterOrganization,
    };
};

export default connect(
    mapStateToProps,
)(ComplianceTransactionScreen);

import { Button, InputLabel } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { createSelector } from 'reselect';
import { IAuthState, IRootState } from '../@types/redux';
import PackmanDialog from './dialog/PackmanDialog';
import FormTextInput from './input/form/FormTextInput';
import PillButton from './input/PillButton';
import { IFreshServiceTicketFormValues, FreshServiceTicketFormValues } from '../@types/model/freshService/freshServiceFormValues';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { FreshServiceTicket } from '../@types/model/freshService/freshServiceTicket';
import { VERSION } from '../../version';
import { createTicket } from '../services/http/freshService/freshService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../store/general/Functions';

interface ITicketCreationPopupProps extends RouteComponentProps {
    isOpen : boolean;
    onClose : () => void;
    auth : IAuthState;
    status ?: number;
    priority ?: number;

}

class TicketCreationPopup extends React.PureComponent<ITicketCreationPopupProps> {
    constructor(props : ITicketCreationPopupProps) {
        super(props);
    }

    public send = async (values : IFreshServiceTicketFormValues) => {
        const ticket = new FreshServiceTicket(values);
        try {
            const res = await createTicket(ticket);

            if (res && res.data) {
                generalShowSuccessSnackbar('Ticket successfully created.');
                this.props.onClose();
            } else {
                generalShowErrorSnackbar('An error occurred creating the ticket.');
            }

        } catch (e) {
            generalShowErrorSnackbar('An error occurred submitting the ticket.');
        }
    };

    public getUser = (props : ITicketCreationPopupProps) => props.auth.session?.user;
    public currentLocation = (props : ITicketCreationPopupProps) =>  props.location.pathname;
    public getStatus = (props : ITicketCreationPopupProps) => props.status;
    public getPriority = (props : ITicketCreationPopupProps) => props.priority;

    public getInitialValues = createSelector(([this.getUser, this.currentLocation, this.getStatus, this.getPriority]), (user, location, status, priority) => {
        const regex = new RegExp('/', 'g');
        return new FreshServiceTicketFormValues(user ? user.name : '', user ? user.email : '', ('Packman Website' +
        (location === '/' ? '/Home' : location)
         + '/' + VERSION.version).replace(regex, ' - '), undefined, status, priority);
    });

    public render() {
        const { isOpen, onClose: close } = this.props;
        const initialValues = this.getInitialValues(this.props);
        return (
            <div>
                <PackmanDialog isOpen={isOpen} onClose={close} title='ZZ2FreshService Support Ticket'>
                    <Formik initialValues={initialValues} onSubmit={this.send}>
                        {({
                            handleChange,
                            handleSubmit,
                            handleReset,
                        }) => (<form id='ticketForm' onSubmit={handleSubmit} onReset={handleReset}>
                            <div className='fdr wfill'>
                                <FormTextInput disabled className='flx1 mt10' label='Subject' name='subject' onChange={handleChange}></FormTextInput>
                            </div>
                            <InputLabel className='pl10 pb10'>Description</InputLabel>
                            <div className='fdr wfill'>
                                <textarea className='flx1 ml10 mr10 mb10 h150' form='ticketForm' name='description' onChange={handleChange}></textarea>
                            </div>
                            <div className='fdr wfill jcfe aife mb10'>
                                <Button className={'fwb h35 '} onClick={close} type='button' variant='text' title='Cancel'>Cancel</Button>
                                <PillButton color={'secondary'} className={'ml15 pl20 pr20 mr10 h35'} type='submit' text='Submit'/>
                            </div>
                        </form>)}
                    </Formik>
                </PackmanDialog>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

export default withRouter(connect(
    mapStateToProps,
)(TicketCreationPopup));

import React from 'react';
import { getHomeUrlStorage, setHomeUrlStorage } from '../../services/localStorageService';
import PillButton from './PillButton';
import { Button } from '@mui/material';
import { withRouter, RouteComponentProps } from 'react-router';

export interface ISetHomeButtonProps extends RouteComponentProps {
}

interface ISetHomeButtonState {
    isSet : boolean;
}

class SetHomeButton extends React.PureComponent<ISetHomeButtonProps, ISetHomeButtonState> {

    constructor(props : ISetHomeButtonProps) {
        super(props);
        this.state = {
            isSet : false,
        };
    }

    public checkPage = () => {
        if ((getHomeUrlStorage() === this.props.location.pathname) && !this.state.isSet) {
            this.setState({ isSet: true });
        } else if ((getHomeUrlStorage() !== this.props.location.pathname) && this.state.isSet) {
            this.setState({ isSet: false });
        }
    };

    public render() {
        const pathName = this.props.location?.pathname;
        this.checkPage();

        if (pathName.includes('dashboard')) {
            if (this.state.isSet) {
                return (<Button disabled={true}>Set As Default</Button>);
            } else {
                return (<PillButton color={'secondary'} className={'ml15 pl10 pr10 h35'} type={'submit'} text={'Set As Default'} onClick={
                    (event) => {
                        event.stopPropagation();
                        setHomeUrlStorage(pathName);
                        this.setState({ isSet: true });
                    }
                }/>);
            }
        } else if (pathName.includes('home')) {
            if (getHomeUrlStorage() === '/') {
                return (<Button disabled={true}>Set As Default</Button>);
            } else {
                return (<PillButton color={'secondary'} className={'ml15 pl10 pr10 h35'} type={'submit'} text={'Set As Default'} onClick={
                    (event) => {
                        event.stopPropagation();
                        setHomeUrlStorage('/') ;
                        this.setState({ isSet: true });
                    }
                }/>);
            }
        } else {
            return <div></div>;
        }

    }
}

export default withRouter(SetHomeButton);

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IAccreditation } from '../../../@types/model/masterData/accreditation/accreditation';

export default class AccreditationHttpService {
    public static getAccreditationData() : AxiosPromise<Array<IAccreditation>> {
        return axios.get(`${PACKMAN_API_URL}Accreditation/GetAllWeb`);
    }

    public static addOrUpdateAccreditation(data : IAccreditation) : AxiosPromise<IAccreditation> {
        return axios.post(`${PACKMAN_API_URL}Accreditation/PostAccreditation`, data);
    }

    public static deleteAccreditation(accreditationId : number) : AxiosPromise<IAccreditation> {
        return axios.delete(`${PACKMAN_API_URL}Accreditation/Delete`, {
            params : { accreditationId },
        });
    }
}

import axios, { AxiosPromise } from 'axios';
import { ISale } from '../../../@types/model/sales/sale';
import { ISaleLineRelatedData } from '../../../@types/model/sales/saleLineRelatedData';
import { ISaleLineView } from '../../../@types/model/sales/saleLineView';
import { ISaleUpsert } from '../../../@types/model/sales/saleUpsert';
import { PACKMAN_API_URL } from '../../../appConstants';

export default class SaleHttpService {
    public static getSaleData() : AxiosPromise<Array<ISale>> {
        return axios.get(`${PACKMAN_API_URL}Sale`);
    }

    public static addSale(saleImport : ISaleUpsert) : AxiosPromise<ISale> {
        return axios.post(`${PACKMAN_API_URL}Sale/PostSale`, saleImport);
    }

    public static revertSaleImport(saleId : number) : AxiosPromise<ISale> {
        const sale = {
            saleId,
        };
        return axios.post(`${PACKMAN_API_URL}Sale/RevertSaleImport`, sale);
    }

    public static changeSaleImportFileName(saleId : number, newFileName : string) : AxiosPromise<ISale> {
        const sale = {
            saleId,
            newFileName,
        };
        return axios.post(`${PACKMAN_API_URL}Sale/UpdateSaleFileName`, sale);
    }

    public static getSaleLines(saleId : number) : AxiosPromise<Array<ISaleLineView>> {
        return axios.get(`${PACKMAN_API_URL}SaleLine`, {
            params: {
                saleId,
            },
        });
    }

    public static getSaleLineRelatedData(loadMasterData ?: boolean) : AxiosPromise<ISaleLineRelatedData> {
        return axios.get(`${PACKMAN_API_URL}SaleLine/SaleLineRelatedData`, {
            params: { loadMasterData },
        });
    }
}

import * as Yup from 'yup';
import { ITruckType } from './truckType';

export interface ITruckTypeFormValues {
    id : number;
    guid : string;
    code : string;
    description : string;
    defaultNumberOfPallets ?: number;
    maxPayload ?: number;
    weightOfTruck ?: number;
    tareWeight ?: number;
    percentageWarning ?: number;
    isActive : boolean;
}

export class TruckTypeFormValues implements ITruckTypeFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public description : string;
    public defaultNumberOfPallets ?: number;
    public maxPayload ?: number;
    public weightOfTruck ?: number;
    public tareWeight ?: number;
    public percentageWarning ?: number;
    public isActive : boolean;

    public constructor(truckType ?: ITruckType) {
        this.initializeDefaultValues();
        if (truckType) {
            this.id = truckType.id;
            this.guid = truckType.guid;
            this.code = truckType.code;
            this.description = truckType.description;
            this.defaultNumberOfPallets = truckType.defaultNumberOfPallets;
            this.maxPayload = truckType.maxPayload;
            this.weightOfTruck = truckType.weightOfTruck;
            this.tareWeight = truckType.tareWeight;
            this.percentageWarning = truckType.percentageWarning;
            this.isActive = truckType.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.description = '';
        this.defaultNumberOfPallets = undefined;
        this.maxPayload = undefined;
        this.weightOfTruck = undefined;
        this.tareWeight = undefined;
        this.percentageWarning = undefined;
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        defaultNumberOfPallets: Yup.number().typeError('Value must be numeric').min(0, 'Value can not be negative').nullable(),
        maxPayload: Yup.number().typeError('Value must be numeric').min(0, 'Value can not be negative').nullable(),
        weightOfTruck: Yup.number().typeError('Value must be numeric').min(0, 'Value can not be negative').nullable(),
        tareWeight: Yup.number().typeError('Value must be numeric').min(0, 'Value can not be negative').nullable(),
        percentageWarning: Yup.number().typeError('Value must be numeric').min(0, 'Value can not be negative').nullable(),
        isActive: Yup.boolean().required('Required'),
    });
}

import * as React from 'react';
import { Form, FormikProps } from 'formik';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import { IOptionType } from '../../../../@types/helper';
import FormAutocompleteSelect from '../../../../components/input/form/FormAutoCompleteSelect';
import { IAuthState, IRootState } from '../../../../@types/redux';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { OutletFormValues } from '../../../../@types/model/masterData/outlet/outletFormValues';
import { IPackLine } from '../../../../@types/model/masterData/packLine/packLine';
import { IPrintServer } from '../../../../@types/model/masterData/printServer/printServer';
import { IPrinter } from '../../../../@types/model/masterData/printer/printer';
import { IReport } from '../../../../@types/model/masterData/report/report';
import { ISite } from '../../../../@types/model/masterData/site/site';
import { IInventory } from '../../../../@types/model/masterData/inventory/inventory';

interface IOutletFormProps {
    isLoading : boolean;
    packLineOptions : Array<IOptionType>;
    packLines : Array<IPackLine>;
    printServers : Array<IPrintServer>;
    printers : Array<IPrinter>;
    reports : Array<IReport>;
    siteOptions : Array<IOptionType>;
    inventoryOptions : Array<IOptionType>;
}

interface IOutletFormState {
}

type OutletFormPropsType = IOutletFormProps & FormikProps<OutletFormValues>;

class OutletForm extends React.Component<OutletFormPropsType, IOutletFormState> {

    constructor(props : OutletFormPropsType) {
        super(props);
    }

    private onPackLineChange = () => {
        this.props.setFieldValue('defaultPrintServer', undefined);
        this.props.setFieldValue('defaultPrinter', undefined);
        this.props.setFieldValue('defaultReport', undefined);
    };

    private onPrintServerChange = () => {
        this.props.setFieldValue('defaultPrinter', undefined);
        this.props.setFieldValue('defaultReport', undefined);
    };

    private getPackLines = (props : OutletFormPropsType) => props.packLines;
    private getPrintServers = (props : OutletFormPropsType) => props.printServers;
    private getPrinters = (props : OutletFormPropsType) => props.printers;
    private getReports = (props : OutletFormPropsType) => props.reports;

    private getSelectedPackLine = (props : OutletFormPropsType) => props.values.packLine;
    private getSelectedPrintServer = (props : OutletFormPropsType) => props.values.defaultPrintServer;

    private getPrintServerOptions = createSelector(
        [this.getPrintServers, this.getPackLines, this.getSelectedPackLine],
        (printServers : Array<IPrintServer>, packLines : Array<IPackLine>, selectedPackLine ?: IOptionType) => {
            const packLine = packLines.find(x => x.id === selectedPackLine?.value);
            return printServers.filter(x => x.isActive && x.siteId === packLine?.siteId).map((x) => {
                return { label: x.name, value: x.id };
            });
        },
    );

    private getPrinterOptions = createSelector(
        [this.getPrinters, this.getSelectedPrintServer],
        (printers : Array<IPrinter>, selectedPrintServer ?: IOptionType) => {
            return printers.filter(x => x.isActive && x.printServerId === selectedPrintServer?.value).map((x) => {
                return { label: x.name, value: x.id };
            });
        },
    );

    private getReportOptions = createSelector(
        [this.getReports, this.getSelectedPrintServer],
        (reports : Array<IReport>, selectedPrintServer ?: IOptionType) => {
            return reports.filter(x => x.isActive && x.printServerId === selectedPrintServer?.value).map((x) => {
                return { label: x.name, value: x.id };
            });
        },
    );

    public render() {
        return (
            <Form className={'p20'}>
                <FormTextInput name={'code'} label={'Code'}/>
                <FormTextInput name={'deviceMacAddress'} label={'Device MAC Address'}/>
                <FormAutocompleteSelect name={'packLine'} label={'Pack Line'} onChange={this.onPackLineChange} options={this.props.packLineOptions}/>
                <FormAutocompleteSelect name={'defaultPrintServer'} label={'Default Print Server'} onChange={this.onPrintServerChange}  options={this.getPrintServerOptions(this.props)}/>
                <FormAutocompleteSelect name={'defaultPrinter'} label={'Default Printer'} options={this.getPrinterOptions(this.props)}/>
                <FormAutocompleteSelect name={'defaultReport'} label={'Default Report'} options={this.getReportOptions(this.props)}/>
                <FormAutocompleteSelect name={'defaultExporterSite'} label={'Default Exporter Site'} options={this.props.siteOptions}/>
                <FormAutocompleteSelect name={'defaultInventory'} label={'Default Inventory'} options={this.props.inventoryOptions}/>
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const getAuth = (state : IRootState) => state.auth;
const getPackLines = (state : IRootState) => state.masterData.packLines;
const getSites = (state : IRootState) => state.masterData.sites;
const getInventories = (state : IRootState) => state.masterData.inventories;

const getPackLineOptions = createSelector(
    [getPackLines, getAuth],
    (packLines : Array<IPackLine>, auth : IAuthState) => {
        return packLines.filter(x => x.isActive && auth.session?.user?.siteIds.some(y => y === x.siteId)).map(x => ({ label: `(${x.code})  ${x.name}`, value: x.id }));
    },
);

const getSiteOptions = createSelector(
    [getSites, getAuth],
    (sites : Array<ISite>, auth : IAuthState) => {
        return sites.filter(x => x.isActive && x.organizationIds.some(y => auth.session?.user.organizationIds.some(z => z === y))).map((x) => {
            return { label: `(${x.code}) ${x.description}`, value: x.id };
        });
    },
);

const getInventoryOptions = createSelector(
    [getInventories],
    (inventories : Array<IInventory>) => {
        return inventories.filter(x => x.isActive).map((x) => {
            return { label: `(${x.code}) ${x.description}`, value: x.id };
        });
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        packLineOptions: getPackLineOptions(state),
        packLines : state.masterData.packLines,
        printServers: state.masterData.printServers,
        printers: state.masterData.printers,
        reports: state.masterData.reports,
        siteOptions: getSiteOptions(state),
        inventoryOptions: getInventoryOptions(state),
    };
};

export default connect(
    mapStateToProps,
)(OutletForm);

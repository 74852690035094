import { IAuthState, AuthAction } from '../../@types/redux';
import { getType } from 'typesafe-actions';
import * as authActions from './Actions';

const initialAuthState = {
    isLoggedIn: false,
    session: undefined,
    token: '',
    isLoggingIn: false,
    ppecbToken: undefined,
    turToken: undefined,
};

export default function authReducer(state : IAuthState = initialAuthState, action : AuthAction) : IAuthState {
    switch (action.type) {
        case getType(authActions.authSetSession):
            return { ...state, session: action.payload };
        case getType(authActions.authSetLoggedIn):
            return { ...state, isLoggedIn: action.payload };
        case getType(authActions.authSetLoggingIn):
            return { ...state, isLoggingIn: action.payload };
        case getType(authActions.authSetToken):
            return { ...state, token: action.payload };
        case getType(authActions.authSetPPECBToken):
            return { ...state, ppecbToken: action.payload };
        case getType(authActions.authSetTURToken):
            return { ...state, turToken: action.payload };
        default:
            return state;
    }
}

import { Divider, Icon, IconButton, Typography } from '@mui/material';
import React from 'react';
import posed from 'react-pose';
import { createSelector } from 'reselect';
import materialTheme from '../../styles/materialTheme';

export enum colours {
    green = 0,
    orange = 1,
}

interface IComplianceHorizontalAccordionProps {
    title : string;
    titleColour ?: colours;
    isOpen : boolean;
    setIsOpen : (status : string, isOpen : boolean) => void;
}

const Accordion = posed.div(
    {
        exp: {
            maxWidth: window.innerWidth / 3,
            maxHeight: 2000,
            opacity: 1,
            transition: { duration: 160 },
        },
        col: {
            maxWidth: 48,
            maxHeight: 2000,
            opacity: 1,
            transition: { duration: 160 },
        },
    },
);


// Note: only for use on the compliance dashboard
export default class ComplianceHorizontalAccordion extends React.PureComponent<IComplianceHorizontalAccordionProps> {
    constructor(props : IComplianceHorizontalAccordionProps) {
        super(props);
    }

    private getSelectedColor = (props : IComplianceHorizontalAccordionProps) => props.titleColour;
    private getBackgroundColorClass = createSelector((this.getSelectedColor), (colour) => {
        switch (colour) {
            case 0:
                return 'bcp';
            case 1:
                return 'bco';
            default:
                return '';
        }
    });

    private toggleIsOpen = () => this.props.setIsOpen(this.props.title, !this.props.isOpen);

    public render() {
        const { title, titleColour, isOpen } = this.props;
        const titleBColour = this.getBackgroundColorClass(this.props);

        return <Accordion pose={isOpen ? 'exp' : 'col'} style={{ backgroundColor: materialTheme.custom.panel.background }} className={'PaperBorder fdc flx1 hfill wfill m5'}>
            <div className={isOpen ? 'dn' : 'fdc hfill wfill jcc'}>
                <div className ={titleBColour ?? ''}>
                    <IconButton onClick={this.toggleIsOpen} className={titleColour !== undefined ? 'cw' : ''}>
                        <Icon className={titleColour !== undefined ? 'cw' : ''}>chevron_right</Icon>
                    </IconButton>
                </div>
                <div className={'flx1'} />
                <Typography className={'aic VerticalText wfill'} variant={'subtitle1'}>
                    <div className={'flx1'} />
                    {title}
                </Typography>
                <div className={'flx1 p10'} />
            </div>
            <div className={isOpen ? `${titleBColour} pr5` : 'dn'} >
                <Typography className={`aic ${isOpen ? `pl10 pr10 mr10 wfill ${titleColour !== undefined ? 'cw' : ''} ` : 'VerticalText wfill mr10'}`} variant={'subtitle1'}>
                    {title}
                    <div className={'flx1'} />
                    <IconButton
                        className={titleColour !== undefined ? 'cw' : ''}
                        onClick={this.toggleIsOpen}
                    >
                        <Icon className={titleColour !== undefined ? 'cw' : ''}>chevron_left</Icon>
                    </IconButton>
                </Typography>
            </div>
            <Divider className={isOpen ? 'mb5 cpl' : 'dn'} />
            <div className={isOpen ? 'fdc hfill m5 oyh' : 'dn'}>
                {this.props.children}
            </div>
        </Accordion>;
    }
}

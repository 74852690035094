import { IBaseModel } from '../../../model';
import { IStorageUnitFormValues } from './storageUnitFormValues';

export interface IStorageUnit extends IBaseModel {
    code : string;
    description : string;
    isTippable : boolean;
    hasRawStock : boolean;
    hasPackedStock : boolean;
    siteId : number;
    latitude ?: number | string;
    longitude ?: number | string;
}

export class StorageUnit implements IStorageUnit {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public description : string;
    public isTippable : boolean;
    public hasRawStock : boolean;
    public hasPackedStock : boolean;
    public siteId : number;
    public latitude ?: number | string;
    public longitude ?: number | string;

    public constructor(formValues : IStorageUnitFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.code = formValues.code;
        this.description = formValues.description;
        this.isTippable = formValues.isTippable;
        this.hasRawStock = formValues.hasRawStock;
        this.hasPackedStock = formValues.hasPackedStock;
        this.siteId = Number(formValues.site?.value) ?? 0;
        this.latitude = formValues.latitude;
        this.longitude = formValues.longitude;
    }
}

import axios, { AxiosPromise } from 'axios';
import { IReport } from '../../../@types/model/masterData/report/report';
import { PACKMAN_API_URL } from '../../../appConstants';

export default class ReportHttpService {
    public static getReportData() : AxiosPromise<Array<IReport>> {
        return axios.get(`${PACKMAN_API_URL}Report/GetAllWeb`);
    }

    public static addOrUpdateReport(data : IReport) : AxiosPromise<IReport> {
        return axios.post(`${PACKMAN_API_URL}Report/PostReport`, data);
    }

    public static deleteReport(reportId : number) : AxiosPromise<IReport> {
        return axios.delete(`${PACKMAN_API_URL}Report/Delete`, {
            params : { reportId },
        });
    }
}

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IMaterialDispatch } from '../../../@types/model/materialDispatch/materialDispatch';
import { ISetMaterialDispatchStatus } from '../../../@types/model/materialDispatch/setMaterialDispatchStatus';
import { ICreateMaterialDispatch } from '../../../@types/model/materialDispatch/createMaterialDispatch';
import { IMaterialRelatedData } from '../../../@types/model/materialStock/materialRelatedData';
import { IMaterialDispatchViewRelatedData } from '../../../@types/model/materialDispatch/materialDispatchViewRelatedData';
import qs from 'qs';
import { IMaterialDispatchView } from '../../../@types/model/materialDispatch/materialDispatchView';
import { IMaterialDispatchSummaryRelatedData } from '../../../@types/model/materialDispatch/materialDispatchSummaryRelatedData';

export default class MaterialDispatchHttpService {
    public static addOrUpdateMaterialDispatch(data : IMaterialDispatch) : AxiosPromise<IMaterialDispatch> {
        return axios.post(`${PACKMAN_API_URL}MaterialDispatch/PostMaterialDispatch`, data);
    }

    public static deleteMaterialDispatch(materialDispatchId : number) : AxiosPromise<IMaterialDispatch> {
        return axios.delete(`${PACKMAN_API_URL}MaterialDispatch/Delete`, {
            params : { materialDispatchId },
        });
    }

    public static getMaterialDispatch(dispatchId ?: number) : AxiosPromise<IMaterialDispatch> {
        return axios.get(`${PACKMAN_API_URL}MaterialDispatch/GetMaterialDispatch`, {
            params: {
                dispatchId,
            },
        });
    }

    public static createInstructedMaterialDispatch(data : ICreateMaterialDispatch) : AxiosPromise<IMaterialDispatch> {
        return axios.post(`${PACKMAN_API_URL}MaterialDispatch/CreateInstructedMaterialDispatch`, data);
    }

    public static getMaterialDispatchSummaryData(dispatchId ?: number, loadMasterData ?: boolean) : AxiosPromise<IMaterialDispatchSummaryRelatedData> {
        return axios.get(`${PACKMAN_API_URL}MaterialDispatch/SummaryRelatedData`, {
            params: {
                dispatchId,
                loadMasterData,
            },
        });
    }

    public static getDispatchScreenData(fromDateUnix ?: number, toDateUnix ?: number, tripLoadDateUnix ?: number, barcode ?: string, siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<IMaterialDispatchViewRelatedData> {
        return axios.get(`${PACKMAN_API_URL}MaterialDispatch/TransactionView`, {
            params: {
                fromDateUnix,
                toDateUnix,
                tripLoadDateUnix,
                barcode,
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static dispatchDelete = (materialDispatchId : number, deleteReason : string) : AxiosPromise<IMaterialDispatchView> => {
        return axios.delete(`${PACKMAN_API_URL}MaterialDispatch/Delete`, {
            params : { materialDispatchId, deleteReason },
        });
    };

    public static getMaterialDispatchDashboardData(fromDateUnix ?: number, toDateUnix ?: number, tripLoadDateUnix ?: number, loadMasterData ?: boolean) : AxiosPromise<IMaterialRelatedData> {
        return axios.get(`${PACKMAN_API_URL}MaterialDispatch/Dashboard`, {
            params: {
                fromDateUnix,
                toDateUnix,
                tripLoadDateUnix,
                loadMasterData,
            },
        });
    }

    public static getMaterialDispatchWizardData(fromDateUnix ?: number, toDateUnix ?: number, tripLoadDateUnix ?: number, sourceSiteId ?: number, loadMasterData ?: boolean) : AxiosPromise<IMaterialRelatedData> {
        return axios.get(`${PACKMAN_API_URL}MaterialDispatch/Wizard`, {
            params: {
                fromDateUnix,
                toDateUnix,
                tripLoadDateUnix,
                sourceSiteId,
                loadMasterData,
            },
        });
    }

    public static setMaterialDispatchStatus(data : ISetMaterialDispatchStatus) : AxiosPromise<IMaterialDispatch> {
        return axios.post(`${PACKMAN_API_URL}MaterialDispatch/SetMaterialDispatchStatus`, data);
    }
}

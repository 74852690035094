import * as React from 'react';
import { connect } from 'react-redux';
import { IAuthState, IRootState, RootAction } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Typography, Tooltip, Card } from '@mui/material';
import { formatDateTime, compareDate, removeArrayElement, addArrayElement, formatDateTimeToDateOnly } from '../../../services/appFunctionsService';
import CustomTable, { ICustomTableColumn } from '../../../components/datagrid/CustomTable';
import { IOrganization } from '../../../@types/model/masterData/organization/organization';
import { ISite } from '../../../@types/model/masterData/site/site';
import { IFarm } from '../../../@types/model/masterData/farm/farm';
import Screen from '../../../components/Screen';
import PopupOptionButton from '../../../components/button/PopupOptionButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import { IGoogleCloudStorage } from '../../../@types/model/googleCloudStorage/googleCloudStorage';
import GoogleCloudStorageHttpService from '../../../services/googleCloudStorageService';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { createSelector } from 'reselect';
import { dataSetMaterialDispatchSummaryRelatedData } from '../../../store/data/Functions';
import lodash from 'lodash';
import { IContactInfo } from '../../../@types/model/masterData/contactInfo/contactInfo';
import { ICompliance } from '../../../@types/model/compliance/compliance';
import FileSaver from 'file-saver';
import { RouteComponentProps, withRouter } from 'react-router';
import { PackmanLink } from '../../../components/link/packmanLink';
import { PackmanLabel } from '../../../components/label/PackmanLabel';
import CustomTooltip from '../../../components/tooltip/tooltip';
import { syncMasterData } from '../../../services/masterDataSyncService';
import { BooleanFlag } from '../../../components/label/BooleanFlag';
import MaterialDispatchHttpService from '../../../services/http/materialDispatch/materialDispatchHttpService';
import { IMaterialDispatch } from '../../../@types/model/materialDispatch/materialDispatch';
import { IMaterialDispatchLineView } from '../../../@types/model/materialDispatch/materialDispatchLineView';
import { IMaterialStock } from '../../../@types/model/materialStock/materialStock';
import { CropFree } from '@mui/icons-material';
import { generateBarcodesFromMaterialDispatch } from '../../../services/compliance/documentService';

const path = 'DispatchNotes/';

interface IMaterialDispatchSummaryProps extends RouteComponentProps {
    selectedDispatchId : number;
    organizations : Array<IOrganization>;
    sites : Array<ISite>;
    farms : Array<IFarm>;
    contactInfos : Array<IContactInfo>;
    auth : IAuthState;
    complainces : Array<ICompliance>;
}

interface IMaterialDispatchSummaryState {
    isLoading : boolean;
    selectedDispatch ?: IMaterialDispatch;
    dispatchLineViews : Array<IMaterialDispatchLineView>;
    stockData : Array<IMaterialStock>;

    onYesClick : () => void;
    onNoClick : () => void;
    message : string;
    showPrompt : boolean;
    openDispatchFileUploadDialog : boolean;
    openDispatchFileDownloadDialog : boolean;
    dispatchDownloadFiles ?: Array<any>;
    selectedFiles : Array<File>;
    setLineAsSubstitutable : boolean;
    selectedDispatchLineView ?: IMaterialDispatchLineView;
    isEmailFormOpen : boolean;

    isPOFileOrchardConfirmDialogOpen : boolean;
    poFileAction : string;
    includeOrchardsOnPOFile : boolean;
}

class DispatchInstructionSummary extends React.Component<IMaterialDispatchSummaryProps, IMaterialDispatchSummaryState> {
    constructor(props : IMaterialDispatchSummaryProps) {
        super(props);

        this.state = {
            isLoading: false,
            dispatchLineViews: [],
            stockData: [],

            onYesClick: () => null,
            onNoClick: () => null,
            message: '',
            showPrompt: false,

            openDispatchFileUploadDialog: false,
            openDispatchFileDownloadDialog: false,
            selectedFiles: [],
            setLineAsSubstitutable: false,
            isEmailFormOpen: false,

            isPOFileOrchardConfirmDialogOpen: false,
            poFileAction: '',
            includeOrchardsOnPOFile: false,
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }
        try {
            const res = await MaterialDispatchHttpService.getMaterialDispatchSummaryData(this.props.selectedDispatchId, !isIndexedDBAvailable);

            if (res) {
                dataSetMaterialDispatchSummaryRelatedData(res.data);
                this.setState({ selectedDispatch: res.data.materialDispatch, dispatchLineViews: res.data.materialDispatchLineViews, stockData: res.data.materialStocks, isLoading: false });
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading dispatch summary data.');
        } finally {
            this.setLoading(false);
        }
    };

    private getSiteDescription = (siteId : number) => {
        const sites = this.props.sites;
        const site = sites && sites.find(x => x.id === siteId);
        return site ? site.description : '';
    };

    private setLoading = (isLoading : boolean = false) => {
        this.setState({ isLoading });
    };

    private getDispatchLineViews = (props : IMaterialDispatchSummaryProps, state : IMaterialDispatchSummaryState) => state.dispatchLineViews;

    private showConfirmationPrompt = (onYesClick : () => void, onNoClick : () => void, message : string) => {
        this.setState({ onYesClick, onNoClick, message }, () => {
            this.setState({ showPrompt: true });
        });
    };

    private closeConfirmationPrompt = () => {
        this.setState({ onYesClick: () => null, onNoClick: () => null, message: '', showPrompt: false });
    };

    private openDispatchFileUploadDialog = () => {
        this.setState({ openDispatchFileUploadDialog: true });
    };

    private closeDispatchFileUploadDialog = () => {
        this.setState({ openDispatchFileUploadDialog: false, selectedFiles: [] });
    };

    private openDispatchFileDownloadDialog = () => {
        this.setState({ openDispatchFileDownloadDialog: true }, () => {
            if (this.state.selectedDispatch && this.state.selectedDispatch?.sourceSiteId) {
                this.getDispatchFilesToBeDownloaded(this.state.selectedDispatch?.sourceSiteId);
            }
        });
    };

    private closeDispatchFileDownloadDialog = () => {
        this.setState({ openDispatchFileDownloadDialog: false, dispatchDownloadFiles: [] });
    };

    private getDispatchFilesToBeDownloaded = async (siteId : number) => {
        const site = this.props.sites.find(x => x.id === siteId);
        if (site) {
            this.setLoading(true);
            const folderPath = path + `${site.code + '_' + site.guid}/${this.state.selectedDispatch?.materialDispatchCode}/`;
            const dispatchAvailableDownloadFiles = await GoogleCloudStorageHttpService.getListData(folderPath);

            if (dispatchAvailableDownloadFiles) {
                this.setState({ dispatchDownloadFiles: dispatchAvailableDownloadFiles.data });
                this.setLoading(false);
            }
        }
    };

    private dispatchFiles = (props : IMaterialDispatchSummaryProps, state : IMaterialDispatchSummaryState) => state.dispatchDownloadFiles;

    private getDispatchFiles = createSelector(
        [this.dispatchFiles],
        (dispatchFiles : Array<any>) => {
            if (!dispatchFiles) return [];

            return dispatchFiles.map(x => this.getDispatchNote(x));
        },
    );

    private getDispatchNote = (dispatchNote : any) => {
        const dispatchNoteName : string = dispatchNote.name;
        const name = dispatchNoteName.split('/')[3];
        return (
            <Tooltip disableInteractive title={'Download Dispatch File?'}>
                <Card style={{ minHeight: 40, maxHeight: 40 }} className={'h40 w350 m5 aic jcc curp'} onClick={() => this.downloadDispatchNote(name, dispatchNote.contentType)}>{name}</Card>
            </Tooltip>
        );
    };

    private downloadDispatchNote = async (fileName : string, contentType : string) => {
        const selectedDispatchSourceSiteId = this.state.selectedDispatch?.sourceSiteId;
        const site = this.props.sites.find(x => x.id === selectedDispatchSourceSiteId);
        const filePath = path + `${site?.code + '_' + site?.guid}/${this.state.selectedDispatch?.materialDispatchCode}/`;
        if (site) {
            try {
                this.setLoading(true);
                const res = await GoogleCloudStorageHttpService.downloadFile(filePath, fileName);

                if (res) {

                    const byteCharacters = atob(res.data);
                    const byteNumbers = lodash.map(byteCharacters, (x, i) => byteCharacters.charCodeAt(i));
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: contentType });
                    if (blob) {
                        FileSaver.saveAs(blob, fileName);
                        generalShowSuccessSnackbar('Dispatch note downloaded successfully.');
                        this.setLoading(false);
                    }
                }
            } catch (e) {
                generalShowErrorSnackbar('Error while downloading dispatch note.');
                this.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Selected dispatch source site not found!');
        }
    };

    private onFileRemove = (file : File) => {
        const index = this.state.selectedFiles?.findIndex(x => x.name === file.name);
        if (index > -1) {
            this.setState({ selectedFiles: removeArrayElement(this.state.selectedFiles, index) });
        }
    };

    private handleFileInputChange = (files : Array<File>) => {
        files.forEach(x => this.setState({ selectedFiles: addArrayElement(this.state.selectedFiles, x, 'end') }));
    };

    private getSiteCodeAndGuid = (id ?: number) => {
        const sites = this.props.sites;
        const site = sites.find(x => x.id === id);
        if (site) {
            return site.code + '_' + site.guid;
        }
    };

    private onDispatchFilesUpload = async () => {
        const files = this.state.selectedFiles;
        const selectedDispatch = this.state.selectedDispatch;
        const data : Array<IGoogleCloudStorage> = [];
        if (files.length > 0 && selectedDispatch) {
            this.setLoading(true);
            files.forEach((file) => {
                const reader = new FileReader();
                reader.onload = async () => {
                    const fileStr = reader.result;
                    if (typeof fileStr === 'string') {
                        const fileString = fileStr.substr(5);
                        const contentType = fileString.split(';')[0];
                        const startFromPos = fileString.split(',')[0].length;
                        const fileBase64 = fileString.substr((startFromPos + 1));

                        const fileToBeUploaded : IGoogleCloudStorage = {
                            fileName: file?.name,
                            destinationPath: `DispatchNotes/${this.getSiteCodeAndGuid(selectedDispatch?.sourceSiteId)}/${selectedDispatch.materialDispatchCode}/`,
                            contentType,
                            fileBase64,
                        };

                        data.push(fileToBeUploaded);

                        if (data.length === files.length) {
                            try {
                                const res = await GoogleCloudStorageHttpService.uploadFile(data);
                                if (res) {
                                    this.closeDispatchFileUploadDialog();
                                    generalShowSuccessSnackbar('File uploaded successfully.');
                                    this.setLoading(false);
                                }
                            } catch (e) {
                                this.closeDispatchFileUploadDialog();
                                generalShowErrorSnackbar('Error while uploading file.');
                                this.setLoading(false);
                            }
                        }
                    }
                };
                reader.readAsDataURL(file);
            });
        }
    };

    private getSourceMaterialStockDateCode = (row : IMaterialDispatchLineView) => {
        const stock = this.state.stockData.find(x => x.id === row.sourceMaterialStockId);
        return stock ? <PackmanLink
            type={'transactions'}
            targetPage={'materialStock'}
            id={stock.id}
            text={stock.dateCode} /> : '';
    };

    private getDestinationMaterialStockDateCode = (row : IMaterialDispatchLineView) => {
        const stock = this.state.stockData.find(x => x.id === row.destinationMaterialStockId);
        return stock ? <PackmanLink
            type={'transactions'}
            targetPage={'materialStock'}
            id={stock.id}
            text={stock.dateCode} /> : '';
    };

    private getColumns = () => {
        let columns : Array<ICustomTableColumn> = [
            { title: 'Source Material Stock', field: 'sourceMaterialStockId', containerComponent: this.getSourceMaterialStockDateCode, width: 170, enableSorting: true },
            { title: 'Destination Material Stock', field: 'destinationMaterialStockId', containerComponent: this.getDestinationMaterialStockDateCode, width: 170, enableSorting: true },
            { title: 'Created By', field: 'createdByName', enableSorting: true },
            { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
            { title: 'Updated By', field: 'updatedByName', enableSorting: true },
            { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
            { title: 'Active?', field: 'isActive', type: 'boolean', enableSorting: true },
        ];
        return columns;
    };

    private getRows = createSelector(
        [this.getDispatchLineViews],
        (dispatchLineViews) => {
            return dispatchLineViews ?? [];
        },
    );

    private generateBarcodeToPDF = async () => {
        const sites = this.props.sites;
        const siteCode = sites.find(x => x.id === this.state.selectedDispatch?.sourceSiteId)?.code;

        try {
            const res = await MaterialDispatchHttpService.getMaterialDispatch(this.state.selectedDispatch?.id);

            if (res && res.data) {
                generateBarcodesFromMaterialDispatch(res.data, siteCode);
            } else {
                generalShowErrorSnackbar('Failed to load dispatch data for barcode pdf generation');
            }
        } catch (ex) {
            generalShowErrorSnackbar('Failed to load dispatch data for barcode pdf generation');
        }
    };

    private getRights = (props : IMaterialDispatchSummaryProps) => props.auth?.session?.user?.rights || [];

    private hasGenerateBarcodeRight = createSelector(
        [this.getRights],
        rights => rights.some(x => x.isActive && x.code === 'DISPATCH_GENERATE_BARCODES'));

    private getSelectedDispatch = (props : IMaterialDispatchSummaryProps, state : IMaterialDispatchSummaryState) => state.selectedDispatch;
    private getIsLoading = (props : IMaterialDispatchSummaryProps, state : IMaterialDispatchSummaryState) => state.isLoading;

    private getIsButtonDisabled = createSelector(
        [this.getSelectedDispatch, this.getIsLoading],
        (selectedDispatch : IMaterialDispatch, isLoading : boolean) => {
            if (isLoading
                || selectedDispatch?.status === 'Draft'
                || selectedDispatch?.status === 'Planned'
                || selectedDispatch?.status === 'Confirmed'
                || selectedDispatch?.status === 'Instructed'
                || selectedDispatch?.status === 'Staged') {
                return true;
            } else {
                return false;
            }
        },
    );

    public render() {
        return (
            <Screen isPadded={false} isScrollable={false} isLoading={this.state.isLoading}>
                <div className={'fdc wfill hfill'}>
                    <div className={'fdc pl10 pr10 pt10'}>
                        <div className={'fdr aic pt10'}>
                            <PackmanLabel
                                label={'Source Site'}
                                value={this.state.selectedDispatch?.sourceSiteId ? this.getSiteDescription(this.state.selectedDispatch?.sourceSiteId) : ''}
                            />
                            <PackmanLabel
                                label={'Destination Site'}
                                value={this.state.selectedDispatch?.destinationSiteId ? this.getSiteDescription(this.state.selectedDispatch?.destinationSiteId) : ''}
                            />
                            <PackmanLabel
                                label={'Material Dispatch Code'}
                                value={this.state.selectedDispatch?.materialDispatchCode ?? ''}
                            />
                            <PackmanLabel
                                label={'Trip Id'}
                                value={this.state.selectedDispatch?.tripId ? <PackmanLink
                                    type={'tripTransactions'}
                                    targetPage={'dispatch'}
                                    id={this.state.selectedDispatch?.tripId}
                                    text={this.state.selectedDispatch?.tripId} /> : ''}
                            />
                            <PackmanLabel
                                label={'Driver'}
                                value={this.state.selectedDispatch?.driver ?? ''}
                            />
                            <PackmanLabel
                                label={'Status'}
                                value={this.state.selectedDispatch?.status ?? ''}
                            />
                            <PackmanLabel
                                label={'Registration Number'}
                                value={this.state.selectedDispatch?.registrationNumber ?? ''}
                            />
                        </div>
                        <div className={'fdr aic pt10'}>
                            <PackmanLabel
                                label={'Load Date'}
                                value={formatDateTimeToDateOnly(this.state.selectedDispatch?.loadDate ?? '')}
                            />
                            <PackmanLabel
                                label={'Created By'}
                                value={this.state.selectedDispatch?.createdByName ?? ''}
                            />
                            <PackmanLabel
                                label={'Created On'}
                                value={formatDateTime(this.state.selectedDispatch?.createdOn ?? '')}
                            />
                            <PackmanLabel
                                label={'Updated By'}
                                value={this.state.selectedDispatch?.updatedByName ?? ''}
                            />
                            <PackmanLabel
                                label={'Updated On'}
                                value={formatDateTime(this.state.selectedDispatch?.updatedOn ?? '')}
                            />
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Finalized?</Typography>
                                <BooleanFlag value={!!this.state.selectedDispatch?.finalizedSignature ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Printed?</Typography>
                                <BooleanFlag value={this.state.selectedDispatch?.isPrinted ?? false}/>
                            </div>
                        </div>
                        <div className={'fdr aic pt10'}>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Manual Override?</Typography>
                                <BooleanFlag value={this.state.selectedDispatch?.manualOverride ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Status Changed On Web?</Typography>
                                <BooleanFlag value={this.state.selectedDispatch?.webStatusChange ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Active?</Typography>
                                <BooleanFlag value={this.state.selectedDispatch?.isActive ?? false}/>
                            </div>
                            <div className={'fdr aic flx2'} style={{ maxWidth: 'calc(100% * 2/7)' }}>
                                {!this.state.selectedDispatch?.isActive ? <PackmanLabel
                                    label={'Delete Reason'}
                                    maxWidth={'100%'}
                                    className={'wrap2line mxwfill oh'}
                                    value={this.state.selectedDispatch?.deleteReason ?? ''}
                                /> : <></>}
                            </div>
                            <div className='flx2' />
                        </div>
                        <div className={'fdr aic pt10 pl10'}>
                            <div/>
                            <div className='bcg1 PaperBorder p10 h250 w400 asc fdc jcc oln'>
                                <div className={'h200'}>
                                    <img className={'hi wi'} src={this.state.selectedDispatch?.finalizedSignatureBase64} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'wfill flx1 fdc p20'}>
                        <CustomTable<IMaterialDispatchLineView>
                            columns={this.getColumns()}
                            rows={this.getRows(this.props, this.state)}
                            enableSorting
                            initialSortOrder={[{ columnName: 'sequence_Sequence', direction : 'asc' }]}
                            isActive={(row : IMaterialDispatchLineView) => row.isActive}
                            pageHeight={450}
                        />
                    </div>
                    <div style={{ flexWrap: 'wrap' }} className={'fdr p10 wfill hfill'}>
                        <PopupOptionButton
                            text={'DOWNLOAD DISPATCH FILES'}
                            icon={<CloudDownloadIcon/>}
                            onClick={() => this.openDispatchFileDownloadDialog()}
                            disabled
                        />
                        <div className={'w10'}/>
                        <CustomTooltip title={this.getIsButtonDisabled(this.props, this.state) ? 'Dispatch status needs to be dispatched or received to use this action' : ''}>
                            <PopupOptionButton
                                text={'UPLOAD DISPATCH FILES'}
                                icon={<CloudUploadIcon/>}
                                disabled={true}// {this.getIsButtonDisabled(this.props, this.state)}
                                onClick={() => this.openDispatchFileUploadDialog()}
                            />
                        </CustomTooltip>
                        <div className={'w10'}/>
                        <div>
                            <PopupOptionButton
                                text={'GENERATE BARCODES'}
                                icon={<CropFree/>}
                                disabled={!this.hasGenerateBarcodeRight(this.props)}
                                onClick={this.generateBarcodeToPDF}
                            />
                        </div>
                        <div className={'flx1'}></div>
                    </div>
                    {/* {!!this.state.isEmailFormOpen &&
                        <PackmanDialog
                            title='Email PO File'
                            isEdit={true}
                            isLoading={this.state.isLoading}
                            isOpen={this.state.isEmailFormOpen}
                            onClose={this.closeEmailForm}>
                            <Formik
                                initialValues={this.generateEmail(this.props, this.state)}
                                isInitialValid={EmailFormValues.formSchema.isValidSync(this.generateEmail(this.props, this.state))}
                                onSubmit={this.onEmailSubmit}
                                onReset={this.onEmailReset}
                                enableReinitialize
                                validationSchema={EmailFormValues.formSchema}
                                component={EmailForm} />
                        </PackmanDialog >}
                    {/* Dispatch Files Upload Dialog */}
                    {/* <PackmanDialog
                        title={'Upload Dispatch files'}
                        isInfo
                        isLoading={this.state.isLoading}
                        isOpen={this.state.openDispatchFileUploadDialog}
                        onClose={this.closeDispatchFileUploadDialog}>
                        <Screen isPadded={false} isScrollable={false}>
                            <div style={{ height: 500 }} className={'w400 aic fdc p20'}>
                                <div style={{ height: 400 }} className={'wfill'}>
                                    <FileSelector
                                        accept={'image/*,application/pdf'}
                                        files={this.state.selectedFiles ? this.state.selectedFiles : []}
                                        disabled={this.state.isLoading}
                                        onFileSelected={this.handleFileInputChange}
                                        onFileRemoved={this.onFileRemove}
                                    />
                                </div>
                                <div className={'flx1'}/>
                                <div className={'fdr pt10 pb10'}>
                                    <Button
                                        className={'fwb h35'}
                                        variant='text'
                                        color='primary'
                                        onClick={this.closeDispatchFileUploadDialog}>
                                            Cancel
                                    </Button>
                                    <PillButton
                                        disabled={this.state.selectedFiles.length === 0}
                                        className={'ml15 pl20 pr20 h35'}
                                        text={'Upload File'}
                                        color={'secondary'}
                                        onClick={this.onDispatchFilesUpload}
                                    />
                                </div>
                            </div>
                        </Screen>
                    </PackmanDialog > */}
                    {/* Dispatch Files Download Dialog */}
                    {/* <PackmanDialog
                        title={'Download Dispatch files'}
                        isInfo
                        isLoading={this.state.isLoading}
                        isOpen={this.state.openDispatchFileDownloadDialog}
                        onClose={this.closeDispatchFileDownloadDialog}>
                        <Screen isPadded={false} isScrollable={false}>
                            <div style={{ maxHeight: 500, height: 500 }} className={'w400 aic fdc p20 oya'}>
                                {(this.state.dispatchDownloadFiles && this.state.dispatchDownloadFiles?.length > 0) ?
                                    this.getDispatchFiles(this.props, this.state)
                                    :
                                    'No files available'
                                }
                            </div>
                        </Screen>
                    </PackmanDialog >
                    <PackmanDialog
                        title={'PO File Orchard Confirm'}
                        isInfo
                        maxWidth={'lg'}
                        isLoading={this.state.isLoading}
                        isOpen={this.state.isPOFileOrchardConfirmDialogOpen}
                        onClose={this.closePOFileOrchardConfirmDialog}>
                        <div className={'w500 aic fdc p20 oya'}>
                            <Typography className={'mb10'}>Should orchards be included?</Typography>
                            <div className={'fdc wfill jcfs'}>
                                <Typography>Note: This is not recommended when dispatching to CORE.</Typography>
                            </div>
                            <div className={'fdr aife jcfe wfill '}>
                                <Button className={'fwb h35'} variant='text' color='primary'
                                    onClick={this.onPOFileOrchardConfirmNoClick}>
                                    No
                                </Button>
                                <PillButton
                                    color={'secondary'}
                                    className={'ml15 pl20 pr20 h35'}
                                    text={'Yes'}
                                    onClick={this.onPOFileOrchardConfirmYesClick}
                                />
                            </div>
                        </div>
                    </PackmanDialog > */}
                    <ConfirmationPrompt disableYes={this.state.isLoading} disableNo={this.state.isLoading} isLoading={this.state.isLoading} open={this.state.showPrompt} message={this.state.message}
                        onOkClicked={this.state.onYesClick} onCancelClicked={this.state.onNoClick}/>
                    {/* <ConfirmationPrompt isLoading={this.state.isLoading} open={this.state.setLineAsSubstitutable} message={'Are you sure you want to make this dispatch line substitutable' }
                        onOkClicked={this.onSubstituteSubmit} onCancelClicked={this.onSubstituteCancel}/> */}
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        complainces: state.data.compliances,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
        farms: state.masterData.farms,
        contactInfos: state.masterData.contactInfos,
        compliances: state.data.compliances,
        stockData: state.data.stocks,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { }, dispatcher);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(DispatchInstructionSummary));

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { ILog } from '../../../@types/model/logs/Log';

export default class LogHttpService {
    public static getErrorLogs(fromDateUnix ?: number, toDateUnix ?: number) : AxiosPromise<Array<ILog>> {
        return axios.get(`${PACKMAN_API_URL}Log/GetLogs`, {
            params: {
                fromDateUnix,
                toDateUnix,
            },
        });
    }
}

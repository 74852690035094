import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { FarmFormValues } from '../../../../@types/model/masterData/farm/farmFormValues';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import FormAutocompleteSelect from '../../../../components/input/form/FormAutoCompleteSelect';
import { createSelector } from 'reselect';
import { IOrganization } from '../../../../@types/model/masterData/organization/organization';
import { IOptionType } from '../../../../@types/helper';
import { IContactInfo } from '../../../../@types/model/masterData/contactInfo/contactInfo';
import { IAccreditation } from '../../../../@types/model/masterData/accreditation/accreditation';
import { IFtpDetail } from '../../../../@types/model/masterData/ftpDetail/ftpDetail';

interface IFarmFormProps {
    isLoading : boolean;
    organizationOptions : Array<IOptionType>;
    contactInfoOptions : Array<IOptionType>;
    accreditationOptions : Array<IOptionType>;
    ftpDetailOptions : Array<IOptionType>;
}

interface IFarmFormState {
}

type FarmFormPropsType = IFarmFormProps & FormikProps<FarmFormValues>;

class FarmForm extends React.Component<FarmFormPropsType, IFarmFormState> {

    constructor(props : FarmFormPropsType) {
        super(props);
    }

    public render() {
        return (
            <Form className={'p20'}>
                <FormTextInput name={'code'} label={'Code'}/>
                <FormTextInput name={'name'} label={'Name'}/>
                <FormTextInput name={'globalGapNumber'} label={'Global Gap Number (GGN)'}/>
                <FormTextInput multiline name={'address'} label={'Address'} rows={5}/>
                <FormTextInput name={'telNumber'} label={'Telephone Number'}/>
                <FormTextInput name={'faxNumber'} label={'Fax Number'}/>
                <FormTextInput name={'bankAccount'} label={'Bank Account'}/>
                <FormTextInput name={'bankName'} label={'Bank Name'}/>
                <FormTextInput name={'extendedDescription'} label={'Extended Description'}/>
                <FormTextInput name={'vat'} label={'Vat'}/>
                <FormTextInput name={'exportedCode'} label={'Exported Code'}/>
                <FormTextInput name={'exportedDescription'} label={'Exported Description'}/>
                <FormAutocompleteSelect name={'accreditation'} label={'Accreditation'} options={this.props.accreditationOptions}/>
                <FormAutocompleteSelect
                    name={'organizations'}
                    label={'Organizations'}
                    options={this.props.organizationOptions}
                    isMulti
                />
                <FormAutocompleteSelect
                    name={'ftpDetails'}
                    label={'FtpDetails'}
                    options={this.props.ftpDetailOptions}
                    isMulti
                />
                <FormAutocompleteSelect
                    name={'contactInfos'}
                    label={'Contact Info'}
                    options={this.props.contactInfoOptions}
                    isMulti
                />
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}
const getOrganizations = (state : IRootState) => state.masterData.organizations;

const getOrganizationOptions = createSelector(
    [getOrganizations],
    (organizations : Array<IOrganization>) => {
        return organizations.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }));
    },
);

const getContactInfos = (state : IRootState) => state.masterData.contactInfos;

const getContactInfoOptions = createSelector(
    [getContactInfos],
    (contactInfos : Array<IContactInfo>) => {
        return contactInfos.filter(x => x.isActive).map(x => ({ label: `${x.name}`, value: x.id }));
    },
);

const getAccreditations = (state : IRootState) => state.masterData.accreditations;

const getAccreditationOptions = createSelector(
    [getAccreditations],
    (accreditations : Array<IAccreditation>) => {
        return accreditations.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }));
    },
);

const getFtpDetails = (state : IRootState) => state.masterData.ftpDetails;

const getFtpDetailOptions = createSelector(
    [getFtpDetails],
    (ftpDetails : Array<IFtpDetail>) => {
        return ftpDetails.filter(x => x.isActive).map(x => ({ label: `${x.name}`, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        organizationOptions: getOrganizationOptions(state),
        contactInfoOptions: getContactInfoOptions(state),
        accreditationOptions: getAccreditationOptions(state),
        ftpDetailOptions: getFtpDetailOptions(state),
    };
};

export default connect(
    mapStateToProps,
)(FarmForm);

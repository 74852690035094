import * as React from 'react';
import { FieldProps, Field } from 'formik';
import { Omit } from '@material-ui/types';
import Dropzone, { DropzoneRef, DropzoneProps } from 'react-dropzone';
import { Button } from '@mui/material';

type FormImageSelectorProps = Omit<DropzoneProps,
'value'
| 'onDropAccepted'> & {
    onDropAccepted ?: (a : Array<File>, fieldProps : FieldProps) => void;
    name : string;
};

interface IFormImageSelectorState {
}

export default class FormImageSelector extends React.Component<FormImageSelectorProps, IFormImageSelectorState> {
    constructor(props : FormImageSelectorProps) {
        super(props);
    }

    public dropzoneRef = React.createRef<DropzoneRef>();

    public openImageDialog = (event : React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open();
        }
    };

    private onDropAccepted = (a : Array<File>, fieldProps : FieldProps) => {
        const reader = new FileReader();
        reader.onload = () => {
            const img = reader.result;
            if (typeof img === 'string') {
                fieldProps.form.setFieldValue(fieldProps.field.name, img);
            }
        };
        reader.readAsDataURL(a[0]);
        if (this.props.onDropAccepted) this.props.onDropAccepted(a, fieldProps);
    };

    public renderInput = (fieldProps : FieldProps) => {
        return (
            <Dropzone
                accept='image/*'
                ref={this.dropzoneRef}
                onDropAccepted={a => this.onDropAccepted(a, fieldProps)}
                noKeyboard
                aria-hidden
            >
                {({ getRootProps, getInputProps }) => {
                    return (
                        <div className='bcg1 PaperBorder p10 h300 w400 asc fdc aic jcc oln'>
                            <div {...getRootProps({ className: 'fdc hfill wfill aic jcc oln' })}>
                                <input {...getInputProps({ className: 'oln' })} />
                                <div className={'flx1'} />
                                {!!fieldProps.field.value ?
                                    <div className={'h200'}>
                                        <img className={'hi wi'} src={fieldProps.field.value} />
                                    </div>
                                    :
                                    <p>Drop image here</p>

                                }
                                <div className={'flx1'} />
                                <div className={'fdr'}>
                                    <Button
                                        aria-hidden
                                        tabIndex={-1}
                                        variant='contained'
                                        color='primary'
                                        onClick={this.openImageDialog}
                                    >
                                        {!!fieldProps.field.value ? 'Replace Image' : 'Select Image'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    );
                }
                }
            </Dropzone>
        );
    };

    public render() {
        return (<Field name={this.props.name} render={this.renderInput} />);
    }
}

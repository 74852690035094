import axios, { AxiosPromise } from 'axios';
import { IDevice } from '../../../@types/model/masterData/device/device';
import { PACKMAN_API_URL } from '../../../appConstants';

export default class DeviceHttpService {
    public static getDeviceData() : AxiosPromise<Array<IDevice>> {
        return axios.get(`${PACKMAN_API_URL}Device/GetAllWeb`);
    }

    public static addOrUpdateDevice(data : IDevice) : AxiosPromise<IDevice> {
        return axios.post(`${PACKMAN_API_URL}Device/PostDevice`, data);
    }

    public static deleteDevice(deviceId : number) : AxiosPromise<IDevice> {
        return axios.delete(`${PACKMAN_API_URL}Device/Delete`, {
            params : { deviceId },
        });
    }
}

import moment from 'moment';
import { IOrchard } from './orchard';
import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { IVariety } from '../variety/variety';
import { ICommodity } from '../commodity/commodity';
import { IFarm } from '../farm/farm';
import { IDepartment } from '../department/department';
import { IProject } from '../project/project';
import { DATEPICKER_FORMAT_DEFAULT } from '../../../../appConstants';

export interface IOrchardFormValues {
    id : number;
    guid : string;
    isActive : boolean;

    landName : string;
    subBlock : string;
    complianceCode ?: string;
    code : string;
    name : string;
    commodity ?: IOptionType;
    defaultVariety ?: IOptionType;
    farm ?: IOptionType;
    department ?: IOptionType;
    project ?: IOptionType;
    plantedHectares ?: number;
    isImported ?: boolean;
    endedOn ?: moment.Moment | null;
}

export class OrchardFormValues implements IOrchardFormValues {
    public id : number;
    public guid : string;
    public isActive : boolean;

    public landName : string;
    public subBlock : string;
    public complianceCode ?: string;
    public code : string;
    public name : string;
    public commodity ?: IOptionType;
    public defaultVariety ?: IOptionType;
    public farm ?: IOptionType;
    public department ?: IOptionType;
    public project ?: IOptionType;
    public plantedHectares ?: number;
    public isImported ?: boolean;
    public endedOn ?: moment.Moment | null;

    public constructor(
        orchard ?: IOrchard,
        commodities ?: Array<ICommodity>,
        varieties ?: Array<IVariety>,
        farms ?: Array<IFarm>,
        departments ?: Array<IDepartment>,
        projects ?: Array<IProject>,
        selectedCommodity ?: ICommodity,
    ) {
        this.initializeDefaultValues(selectedCommodity);
        if (orchard) {
            this.id = orchard.id;
            this.guid = orchard.guid;
            this.code = orchard.code;
            this.name = orchard.name;
            this.landName = orchard.landName;
            this.subBlock = orchard.subBlock;
            this.complianceCode = orchard.complianceCode;
            this.plantedHectares = orchard.plantedHectares;
            this.isImported = orchard.isImported;
            this.endedOn = !!orchard.endedOn ? moment(orchard.endedOn, DATEPICKER_FORMAT_DEFAULT) : null;

            this.commodity = commodities ? {
                label: commodities.find(x => x.id === orchard.commodityId)?.name ?? '',
                value: orchard.commodityId,
            } : undefined;

            this.defaultVariety = varieties ? {
                label: varieties.find(x => x.id === orchard.defaultVarietyId)?.name ?? '',
                value: orchard.defaultVarietyId,
            } : undefined;

            this.farm = farms ? {
                label: farms.find(x => x.id === orchard.farmId)?.name ?? '',
                value: orchard.farmId,
            } : undefined;

            this.department = departments
                ?
                ((orchard.departmentId)
                    ?
                    {
                        label: departments.find(x => x.id === orchard.departmentId)?.shortDescription ?? '',
                        value: orchard.departmentId ?? undefined,
                    }
                    : undefined)
                : undefined;

            this.project = projects
                ?
                ((orchard.projectId)
                    ?
                    {
                        label: projects.find(x => x.id === orchard.projectId)?.number ?? '',
                        value: orchard.projectId ?? undefined,
                    }
                    : undefined)
                : undefined;

            this.isActive = orchard.isActive;
        }
    }

    public initializeDefaultValues = (selectedCommodity ?: ICommodity) => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.name = '';
        this.landName = '';
        this.subBlock = '';
        this.complianceCode = '';
        this.commodity = undefined;
        this.defaultVariety = undefined;
        this.farm = undefined;
        this.department = undefined;
        this.project = undefined;
        this.plantedHectares = undefined;
        this.isActive = true;
        this.isImported = false;
        this.endedOn = null;

        if (selectedCommodity) {
            this.commodity = {
                label: selectedCommodity?.name ?? '',
                value: selectedCommodity?.id,
            };
        }
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        name: Yup.string().nullable().required('Required'),
        commodity: Yup.object().nullable().required('Required'),
        defaultVariety: Yup.object().nullable().required('Required'),
        farm: Yup.object().nullable().required('Required'),
        department: Yup.object().nullable(),
        project: Yup.object().nullable(),
        plantedHectares: Yup.number().nullable().notRequired(),
        isActive: Yup.boolean().required('Required'),
    });

    public static isImportedformSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        name: Yup.string().nullable(),
        commodity: Yup.object().nullable().required('Required'),
        defaultVariety: Yup.object().nullable().required('Required'),
        farm: Yup.object().nullable().required('Required'),
        department: Yup.object().nullable(),
        project: Yup.object().nullable(),
        plantedHectares: Yup.number().nullable().notRequired(),
        isActive: Yup.boolean().required('Required'),
    });
}

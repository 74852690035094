import { IBaseModel } from '../../../model';
import { ICommodityStateFormValues } from './commodityStateFormValues';

export interface ICommodityState extends IBaseModel {
    commodityIds : Array<number>;
    name : string;
}

export class CommodityState implements ICommodityState {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public name : string;
    public commodityIds : Array<number>;

    public constructor(formValues : ICommodityStateFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.name = formValues.name;

        this.commodityIds = formValues.commodities.map(x => Number(x.value)) ?? [];
    }
}

import * as Yup from 'yup';
import { IIntakeLineFormValues, IntakeLineFormValues } from './intakeLineFormValues';
import { IOptionType } from '../../../helper';
import { IIntake } from '../intake';
import { IIntakeLine } from '../intakeLine';
import { ISite } from '../../masterData/site/site';
import { IOrganization } from '../../masterData/organization/organization';
import { ICommodity } from '../../masterData/commodity/commodity';
import { IFarm } from '../../masterData/farm/farm';
import { IPalletBaseType } from '../../masterData/palletBaseType/palletBaseType';
import { IVariety } from '../../masterData/variety/variety';
import { IOrchard } from '../../masterData/orchard/orchard';
import { IPack } from '../../masterData/pack/pack';
import { ILot } from '../../lot/lot';
import { IStorageUnit } from '../../masterData/storageUnit/storageUnit';
import { IProject } from '../../masterData/project/project';

export interface IIntakeFormValues {
    id : number;
    guid : string;
    isActive : boolean;

    intakeDate ?: string;
    organization ?: IOptionType;
    commodity ?: IOptionType;
    farm ?: IOptionType;
    site ?: IOptionType;
    storageUnit ?: IOptionType;
    shipmentGuid ?: string;
    isTipped ?: boolean;
    intakeLines : Array<IIntakeLineFormValues>;
}

export class IntakeFormValues implements IIntakeFormValues {
    public id : number;
    public guid : string;
    public isActive : boolean;

    public intakeDate ?: string;
    public organization ?: IOptionType;
    public commodity ?: IOptionType;
    public farm ?: IOptionType;
    public site ?: IOptionType;
    public storageUnit ?: IOptionType;
    public shipmentGuid ?: string;
    public isTipped ?: boolean;
    public intakeLines : Array<IIntakeLineFormValues>;

    public constructor(
        intake ?: IIntake,
        site ?: ISite,
        organizations ?: Array<IOrganization>,
        commodities ?: Array<ICommodity>,
        farms ?: Array<IFarm>,
        sites ?: Array<ISite>,
        storageUnits ?: Array<IStorageUnit>,
        palletBaseTypes ?: Array<IPalletBaseType>,
        varieties ?: Array<IVariety>,
        orchards ?: Array<IOrchard>,
        packs ?: Array<IPack>,
        lots ?: Array<ILot>,
        projects ?: Array<IProject>,
    ) {
        this.initializeDefaultValues(site);
        if (intake) {
            this.id = intake.id;
            this.guid = intake.guid;
            this.isActive = intake.isActive;
            this.intakeDate = intake.intakeDate;

            this.organization = organizations ? {
                label: organizations.find(x => x.id === intake.organizationId)?.name ?? '',
                value: intake.organizationId,
            } : undefined;

            this.commodity = commodities ? {
                label: commodities.find(x => x.id === intake.commodityId)?.name ?? '',
                value: intake.commodityId,
            } : undefined;

            this.farm = farms ? {
                label: farms.find(x => x.id === intake.farmId)?.name ?? '',
                value: intake.farmId,
            } : undefined;

            this.site = sites ? {
                label: sites.find(x => x.id === intake.siteId)?.code ?? '',
                value: intake.siteId,
            } : undefined;

            this.storageUnit = storageUnits ? {
                label: storageUnits.find(x => x.id === intake.storageUnitId)?.code ?? '',
                value: intake.storageUnitId ?? 0,
            } : undefined;

            this.shipmentGuid = intake.shipmentGuid;
            this.isTipped = intake.isTipped;

            this.intakeLines = intake.intakeLines?.map((x : IIntakeLine) => new IntakeLineFormValues(x, palletBaseTypes, varieties, orchards, packs, lots, projects));
        }
    }

    public initializeDefaultValues = (site ?: ISite) => {
        this.id = 0;
        this.guid = '';
        this.isActive = false;

        this.organization = undefined;
        this.commodity = undefined;
        this.farm = undefined;
        this.storageUnit = undefined;
        this.shipmentGuid = '';
        this.intakeLines = [];
        this.isTipped = false;

        if (site) {
            this.site = { label: site.code, value: site.id };
        }
    };

    public static formSchema = Yup.object().shape({
        isActive: Yup.boolean().required('Required'),
        organization: Yup.object().nullable().required('Required'),
        commodity: Yup.object().nullable().required('Required'),
        farm: Yup.object().nullable().required('Required'),
        site: Yup.object().nullable().required('Required'),
        intakeLines: Yup.array().of(IntakeLineFormValues.formSchema),
    });
}

import { IRegion } from './region';
import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { IOrganization } from '../organization/organization';

export interface IRegionFormValues {
    id : number;
    guid : string;
    isActive : boolean;

    code : string;
    name : string;
    organizations : Array<IOptionType>;
}

export class RegionFormValues implements IRegionFormValues {
    public id : number;
    public guid : string;
    public isActive : boolean;

    public code : string;
    public name : string;
    public organizations : Array<IOptionType>;

    public constructor(
        region ?: IRegion,
        organizations ?: Array<IOrganization>,
    ) {
        this.initializeDefaultValues();
        if (region) {
            this.id = region.id;
            this.guid = region.guid;
            this.code = region.code;
            this.name = region.name;
            this.isActive = region.isActive;

            this.organizations = organizations?.filter(x => region.organizationIds.some(y => y === x.id)).map(x => ({
                label: x.name,
                value: x.id,
            })) ?? [];
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.name = '';
        this.isActive = true;

        this.organizations = [];
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        organizations: Yup.array().of(Yup.object()).min(1, 'Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

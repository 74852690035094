import * as React from 'react';
import './customTableStyles.scss';
import { DataGridPro, GridActionsCellItem, GridRowParams, GridRow, GridRowProps, GridFooterContainerProps, GridFooterContainer, GridFooter, GridValueFormatterParams, GridValueGetterParams, GridAlignment, GridColDef, GridActionsColDef, GridRenderCellParams, useGridApiContext, GridCsvExportOptions, GridInputRowSelectionModel, GridCellParams, GridEventListener, getGridDateOperators, GridValidRowModel } from '@mui/x-data-grid-pro';
import { Icon, IconButton } from '@mui/material';
import { addArrayElement, addea, addkg, booleanToYesNo, compareDate, formatDateTime, formatDateTimeToDateOnly } from '../../services/appFunctionsService';
import FloatingActionButton from '../input/FloatingActionButton';
import Tooltip from '../tooltip/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { DATETIME_PALTRACK_FILE, DATE_FORMAT_TIMESTAMP_FROM_API } from '../../appConstants';
import moment from 'moment';
import { useLocation } from 'react-router';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import materialTheme from '../../styles/materialTheme';

interface ICustomTableProps<T extends { [key : string] : any }> {
    id ?: string;
    rows : Array<T>;
    columns : Array<ICustomTableColumn>;
    enableFiltering ?: boolean;
    enableSorting ?: boolean;
    enableAdding ?: boolean;
    enableEditing ?: ((row : T) => boolean | undefined) | boolean;
    enableDeleting ?: ((row : T) => boolean | undefined) | boolean;
    enableDetails ?: ((row : T) => boolean | undefined) | boolean;
    enableRefresh ?: boolean;
    enableSelection ?: boolean;
    enableCSVExport ?: boolean;
    csvExportPathname ?: string;
    enableClearFilterButton ?: boolean;
    disableRefreshButton ?: boolean;
    enableHorizontalVirtualize ?: boolean;
    enableDetailAccordion ?: ((row : T) => boolean | undefined) | boolean;
    disableDeleteButton ?: ((row : T) => boolean | undefined) | boolean;
    disableDetailButton ?: ((row : T) => boolean | undefined) | boolean;
    disableEditButton ?: ((row : T) => boolean | undefined) | boolean;
    disableRowSelect ?: (row : T | GridValidRowModel) => boolean;
    disableAddButton ?: boolean;
    isActive ?: ((row : T) => boolean | undefined) | boolean;
    warning ?: ((row : T) => boolean | undefined) | boolean;
    rowColor ?: ((row : T) => string | undefined) | string;
    detailIcon ?: string | React.ReactElement<any>;
    detailsColor ?: string;
    deleteIcon ?: string | React.ReactElement<any>;
    deleteColor ?: string;
    editIcon ?: string | React.ReactElement<any>;
    addIcon ?: string | React.ReactElement<any>;
    editColor ?: string;
    detailTooltip ?: ((row : T) => string | undefined) | string;
    deleteTooltip ?: ((row : T) => string | undefined) | string;
    editTooltip ?: ((row : T) => string | undefined) | string;
    addTooltip ?: ((row : T) => string | undefined) | string;
    addFunction ?: () => void;
    editFunction ?: (row : T) => void;
    deleteFunction ?: (row : T) => void;
    detailFunction ?: (row : T) => void;
    refreshFunction ?: () => void;
    detailAccordionComponent ?: (row : T) => React.ReactElement<any>;
    initialSortOrder ?: Array<{columnName : string; direction : 'asc' | 'desc'}>;
    enablePagination ?: boolean;
    enableTotalRow ?: boolean;
    pageSizes ?: Array<number>;
    fitWidthToPage ?: boolean;
    autoRowHeight ?: boolean;
    disableMultiSelect ?: boolean;
    hide ?: boolean;
    pageHeight ?: number;
    onSelectChange ?: (ids : Array<number>) => void;
    selectedRows ?: GridInputRowSelectionModel;
    filterType ?: 'Textfield' | 'MultiCheckbox';
    filterTypeColumn ?: Array<{ column : string; data : Array<string> }>;
    isCellEditable ?: ((params : GridCellParams<T>) => boolean) | undefined;
    onCellEditStop ?: GridEventListener<'cellEditStop'>;

    filteredRows ?: (rows : Array<T>) => void;
}

// interface ICustomTableState {
//     detailAccordionExpanded : Array<boolean>;
//     filterState : {[column : string] : Array<{filterValue ?: string; showEmpty ?: boolean; showFilled ?: boolean}>};
//     sortState : Array<{columnName : string; direction ?: 'asc' | 'desc'}>;
//     rowState : {[page : number] : Array<any>};
//     freezeUpTo ?: number;
//     columnPositions : Array<number>;
//     openFilterBoxes : Array<string>;
// }

export interface ICustomTableColumn {
    title : string;
    field : string;
    type ?: 'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect' | 'actions' | string;
    width ?: number;
    minWidth ?: number;
    // maxWidth ?: number;
    titleContainerComponent ?: (value : any) => React.ReactElement<any> | undefined;
    containerComponent ?: (row : any, value : any) => JSX.Element | React.ReactElement<any> | string | undefined;
    formatFunction ?: (value : any, row ?: any) => any;
    redFormatFunction ?: (value : any, row ?: any) => any;
    sortFunction ?: (value : any, value2 : any) => any;
    renderHeader ?: (value : any) => React.ReactElement<any> | undefined;
    calculateTotal ?: boolean;
    calculateRedTotal ?: boolean;
    isNumberString ?: boolean;
    style ?: (value : any) => React.CSSProperties | undefined;
    freezeUpTo ?: boolean;
    enableFiltering ?: boolean;
    disableExport ?: boolean;
    enableSorting ?: boolean;
    dontFormatFilter ?: boolean;
    editable ?: boolean;
}

const defaultColumnWidth = 150;

const CustomTable = <T extends { [key : string] : any }>(props : ICustomTableProps<T>) => {

    const { rows, columns, enableDetails, enableEditing, enableDeleting, enableFiltering, enableSorting, enableSelection, enableAdding,
        enableDetailAccordion, enableRefresh, editIcon, detailIcon, deleteIcon, addIcon,
        editTooltip, detailTooltip, deleteTooltip, editFunction, deleteFunction, detailFunction , addFunction, refreshFunction,
        disableEditButton, disableDeleteButton, disableDetailButton, disableAddButton,
        isActive, warning, rowColor, autoRowHeight, isCellEditable, onCellEditStop } = props;

    // const clickShowAllButton = () => {
    //     console.log('clicked')
    //     const apiRef = useGridApiContext();

    //     apiRef.current.hideColumnMenu();
    // };

    // useEffect(() => {
    //     const showAllButton = document.querySelector('.MuiDataGrid-panelFooter > :nth-child(2)');
    //     console.log('called');
    //     if (showAllButton) {
    //         console.log('calledd');
    //         showAllButton.addEventListener('click', clickShowAllButton);

    //         // Cleanup function to remove the event listener when the component is unmounted
    //         return () => {
    //             showAllButton.removeEventListener('click', clickShowAllButton);
    //         };
    //     }
    // }, []);

    // Custom row component that extends DataGridRow
    const CustomRow = (rowProps : GridRowProps) => {
        const row = rows.find(x => x.id === Number(rowProps.rowId));
        if (row) {
            const index = rowProps.index % 2;
            const rowIsActive = typeof isActive === 'boolean' ? isActive : (isActive ? isActive(row) : true);
            const rowWarning = typeof warning === 'boolean' ? warning : (warning ? warning(row) : false);
            const colorForRow = typeof rowColor === 'string' ? rowColor : (rowColor ? rowColor(row) : '');

            const rowBackgroundColor = (index % 2 !== 0) ?
                (!rowIsActive ? materialTheme.custom.table.row1.inactive :
                    (rowWarning ? materialTheme.custom.table.row1.warning :
                        (colorForRow ? colorForRow : materialTheme.custom.table.row1.default)))
                :
                (!rowIsActive ? materialTheme.custom.table.row2.inactive :
                    (rowWarning ? materialTheme.custom.table.row2.warning :
                        (colorForRow ? colorForRow : materialTheme.custom.table.row2.default)));
            return (
                <GridRow
                    {...rowProps}
                    className={rowBackgroundColor}
                    style={ { backgroundColor: rowBackgroundColor } }
                />
            );
        }
        return <GridRow
            {...rowProps}
        />;
    };

    // eslint-disable-next-line no-console
    const onRefreshButtonClick = () => !!props.refreshFunction ? props.refreshFunction() : console.error('No refresh function supplied!');

    // const CustomColumnsPanel = (columnsPanelProps : GridColumnsPanelProps, b : any) => {
    //     const showAllButton = document.querySelector('.MuiDataGrid-panelFooter > :nth-child(2)');
    //     console.log('calllld')
    //     console.log(showAllButton)
    //     if (showAllButton) {
    //         console.log('calledd');
    //         showAllButton.addEventListener('click', clickShowAllButton);

    //         // Cleanup function to remove the event listener when the component is unmounted
    //         return () => {
    //             showAllButton.removeEventListener('click', clickShowAllButton);
    //         };
    //     }
    //     return <GridColumnsPanel />;
    // }

    const CustomFooter = (footerProps : GridFooterContainerProps) => {
        const apiRef = useGridApiContext();

        const handleExport = (options : GridCsvExportOptions) => apiRef.current.exportDataAsCsv(options);

        const path = useLocation().pathname;

        return (
            <GridFooterContainer
                {...footerProps}
                style={{ backgroundColor: materialTheme.custom.table.footer.background }}
            >
                <div className={'fdr flx1'}>
                    <GridFooter style={{ color: materialTheme.custom.table.text }} className='flx1' />
                    <div className='w20'/>
                    <FloatingActionButton
                        color={'secondary'}
                        className='h48 w48'
                        onClick={() => handleExport({ fileName: `Packman-CSV${path ? path.replace('/', '-') : ''}-${moment(moment.now()).format(DATETIME_PALTRACK_FILE)}.csv` })}
                        children={<FontAwesomeIcon className={'ml10 mr10 mb1 cpd'} size={'2x'} icon={faFileCsv}/>}
                    />
                    <div className='w20'/>
                    {enableAdding && <FloatingActionButton
                        color={'secondary'}
                        className='h48 w48 ml10 mr10'
                        onClick={addFunction}
                        children={addIcon ? <Icon fontSize={'large'} className={'cpd'}>{addIcon}</Icon> : undefined}
                        disabled={disableAddButton}
                    />}
                </div>
            </GridFooterContainer>
        );
    };

    const getField = (field : string, title : string) => {
        /* This handles columns that have special actions/renders and use id as the field identifier,
           since this is now a unique identifier we modify them like this to pass the id instead of value and have a unique field */
        return (field === 'id' && title.toLowerCase() !== 'id') ? title?.toLowerCase() ?? '' : field;
    };

    const renderCell = (param : GridRenderCellParams<T>, field : string, title : string, containerComponent ?: (value : any, row ?: any) => JSX.Element | React.ReactElement<any> | string | undefined) => {
        /* This handles columns that have special actions/renders and use id as the field identifier,
           since this is now a unique identifier we modify them like this to pass the id instead of value and have a unique field */
        if (field === 'id' && title.toLowerCase() !== 'id') {
            return containerComponent ? containerComponent(param.row, param.id) : undefined;
        };
        return containerComponent ? containerComponent(param.row, param.value) : undefined;
    };

    const getNestedObject = (nestedObj : { [key : string] : any } | undefined, pathArr : Array<string>) =>  pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
    const getFieldValue = (row : T | undefined, field : string) =>  getNestedObject(row, field.split('.'));

    const valueFormatter = (param : GridValueFormatterParams<T>, field : string, title : string, formatFunction ?: (value : any, row ?: any) => any) => {
        const row = rows.find(y => y.id === Number(param.id));

        /* This handles columns that have special actions/renders and use id as the field identifier,
           since this is now a unique identifier we modify them like this to pass the id instead of value and have a unique field */
        if (field === 'id' && title.toLowerCase() !== 'id') {
            return formatFunction && (formatFunction?.toString() !== booleanToYesNo.toString()) ? formatFunction(param.id, row) : param.id;
        };
        return formatFunction && (formatFunction?.toString() !== booleanToYesNo.toString()) ? formatFunction(getFieldValue(row, field), row) : getFieldValue(row, field);
    };

    const valueGetter = (param : GridValueGetterParams<T>, field : string, title : string, formatFunction ?: (value : any, row ?: any) => any, type ?: 'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect' | 'actions' | string) => {
        /* This handles columns that have special actions/renders and use id as the field identifier,
           since this is now a unique identifier we modify them like this to pass the id instead of value and have a unique field */
        if (type === 'dateTime' || type === 'date') {
            return param.value ? moment.utc(getFieldValue(param.row, field), DATE_FORMAT_TIMESTAMP_FROM_API).local().toDate() : undefined;
        }
        if (field === 'id' && title.toLowerCase() !== 'id') {
            return formatFunction && (formatFunction?.toString() !== booleanToYesNo.toString()) ? formatFunction(param.id, param.row) : param.id;
        };
        return formatFunction && (formatFunction?.toString() !== booleanToYesNo.toString()) ? formatFunction(getFieldValue(param.row, field), param.row) : getFieldValue(param.row, field);
    };

    const formatColumns = React.useMemo(() => {
        if (columns.length > 0) {
            let result : Array<GridColDef<T>> = [...columns.map((x) => {
                let type = x.type;
                let dontFormatFilter = x.dontFormatFilter;
                if (!x.type || (x.type === 'string')) {
                    if (x.formatFunction?.toString() === addea.toString() || x.formatFunction?.toString() === addkg.toString()) {
                        type = 'number';
                        dontFormatFilter = true;
                    }

                    if (x.formatFunction?.toString() === booleanToYesNo.toString()) {
                        type = 'boolean';
                    }

                    if ((x.formatFunction?.toString() === formatDateTime.toString()) || (x.sortFunction?.toString() === compareDate.toString())) {
                        type = 'dateTime';
                    }
                }

                const column : GridColDef = {
                    field: getField(x.field, x.title),
                    headerName: x.title,
                    type,
                    renderHeader: x.renderHeader,
                    editable: x.editable,
                    disableExport: x.disableExport,
                    headerAlign: 'left' as GridAlignment,
                    align: 'left' as GridAlignment,
                    width: x.width ?? defaultColumnWidth,
                    flex: props.fitWidthToPage ? 1 : undefined,
                };

                if (column.type === 'date' || column.type === 'dateTime') {
                    if (column.type === 'date') {
                        column.valueFormatter = param => formatDateTimeToDateOnly(param.value);
                    } else {
                        column.valueFormatter = param => formatDateTime(param.value);
                    }

                    column.filterOperators = getGridDateOperators()
                        .map(operator => ({
                            ...operator,
                            InputComponent: ((operator.value !== 'isEmpty') && (operator.value !== 'isNotEmpty')) ? (textFieldProps) => {
                                return <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        className={'w180'}
                                        label={'Value'}
                                        format={'DD/MM/YYYY'}
                                        onChange={(value : moment.Moment) => {
                                            textFieldProps.apiRef.current.upsertFilterItem({
                                                field: column.field,
                                                operator: textFieldProps.item.operator,
                                                value: moment(value).format('YYYY-MM-DD'),
                                                id: textFieldProps.item.id,
                                            });
                                        }}
                                        slotProps={{
                                            textField: { variant: 'standard' },
                                            actionBar: {
                                                actions: ['clear'],
                                            },
                                        }}
                                    />
                                </LocalizationProvider>;
                            } : undefined,
                        }));
                }

                if (x.containerComponent) {
                    column.renderCell = param => renderCell(param, x.field, x.title, x.containerComponent);
                }

                if (!dontFormatFilter) {
                    column.valueGetter = param => valueGetter(param, x.field, x.title, x.formatFunction, type);
                } else {
                    column.valueFormatter = param => valueFormatter(param, x.field, x.title, x.formatFunction);
                }
                return column;
            })];

            const actions : GridActionsColDef = {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                headerAlign: 'left' as GridAlignment,
                align: 'left' as GridAlignment,
                disableExport: true,
                width: (enableDetails ? 40 : 0) + (enableEditing ? 40 : 0) + (enableDeleting ? 40 : 0) + (enableDetailAccordion ? 40 : 0) + (enableRefresh && !enableDetails && !enableEditing && !enableDeleting && !enableDetailAccordion ? 40 : 0),
                cellClassName: 'actions',
                getActions: (params : GridRowParams) => {
                    const row = rows.find(x => x.id === Number(params.id));
                    const acts = [];
                    if (row) {
                        if (enableEditing && (typeof enableEditing === 'boolean' || enableEditing(row))) {
                            acts.push(<GridActionsCellItem
                                icon={<Icon>{editIcon ? editIcon : 'edit'}</Icon>}
                                label='Edit'
                                disabled={disableEditButton && (disableEditButton === true || disableEditButton(row))}
                                title={editTooltip ? (typeof editTooltip === 'string' ? editTooltip : (editTooltip(row) ?? 'Edit')) : 'Edit'}
                                onClick={() => editFunction ? editFunction(row) : null}
                                showInMenu={false}
                            />);
                        }
                        if (enableDeleting && (typeof enableDeleting === 'boolean' || enableDeleting(row))) {
                            acts.push(<GridActionsCellItem
                                icon={<Icon>{deleteIcon ? deleteIcon : 'delete'}</Icon>}
                                label='Delete'
                                disabled={disableDeleteButton && (disableDeleteButton === true || disableDeleteButton(row))}
                                title={deleteTooltip ? (typeof deleteTooltip === 'string' ? deleteTooltip : (deleteTooltip(row) ?? 'Delete')) : 'Delete'}
                                onClick={() => deleteFunction ? deleteFunction(row) : null}
                                showInMenu={false}
                            />);
                        }
                        if (enableDetails && (typeof enableDetails === 'boolean' || enableDetails(row))) {
                            acts.push(<GridActionsCellItem
                                icon={<Icon>{detailIcon ? detailIcon : 'list'}</Icon>}
                                label='Details'
                                disabled={disableDetailButton && (disableDetailButton === true || disableDetailButton(row))}
                                title={detailTooltip ? (typeof detailTooltip === 'string' ? detailTooltip : (detailTooltip(row) ?? 'Detail')) : 'Detail'}
                                onClick={() => detailFunction ? detailFunction(row) : null}
                                showInMenu={false}
                            />);
                        }
                    }
                    return acts;
                },
            };

            if (enableRefresh && refreshFunction) {
                actions.renderHeader = () => <Tooltip title={'Refresh Data'}>
                    <IconButton disabled={props.disableRefreshButton} className={'w48 mnw48'} onClick={onRefreshButtonClick}>
                        <Icon className={'cw'}>refresh</Icon>
                    </IconButton></Tooltip>;
            }
            if (enableDetails || enableEditing || enableDeleting || enableDetailAccordion || enableRefresh) {
                result = addArrayElement(result, actions, 'start');
            }
            if (!props.enableSorting) {
                result.forEach(x => x.sortable = false);
            }
            return result;
        }
        return [];
    }, [columns, rows]);

    const formatRows = React.useMemo(() => {
        if (columns.length > 0 && rows.length > 0) {
            const result = [...rows];
            return result;
        }
        return [];
    }, [rows, columns]);

    return <DataGridPro
        disableColumnFilter={!enableFiltering}
        disableChildrenFiltering={!enableFiltering}
        disableMultipleColumnsSorting={!enableSorting}
        disableChildrenSorting={!enableSorting}
        checkboxSelection={enableSelection}
        disableRowSelectionOnClick
        isCellEditable={isCellEditable}
        style={{ backgroundColor: materialTheme.custom.table.background }}
        components={ {
            Row: CustomRow,
            Footer: CustomFooter,
            // ColumnsPanel: CustomColumnsPanel,
        }}
        columnBuffer={props.enableHorizontalVirtualize ? 3 : 100}
        className={'hfill'}
        onCellEditStop={onCellEditStop}
        columnHeaderHeight={34}
        getRowHeight={() => autoRowHeight ? 'auto' : undefined}
        columns={formatColumns}
        onRowSelectionModelChange={props.rows && props.rows.length > 0 ? props.onSelectChange : undefined}
        rowSelectionModel={props.selectedRows}
        rows={formatRows}
        initialState={{
            sorting: {
                sortModel: props.initialSortOrder?.map((x) => {
                    return {
                        field: x.columnName.split('_')[0],
                        sort: x.direction,
                    };
                }),
            },
        }}
        disableMultipleRowSelection={props.disableMultiSelect}
        isRowSelectable={params => (enableSelection && props.disableRowSelect && !props.disableRowSelect(params.row)) ?? false}
    />;
};

export default CustomTable;

import * as React from 'react';
import PillButton from '../../components/input/PillButton';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import { Card, Checkbox } from '@mui/material';
import { CustomChangeEvent, IOptionType } from '../../@types/helper';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { createSelector } from 'reselect';
import { CONSTANT_LOT_TYPE_GUIDS } from '../../appConstants';
import { ILot } from '../../@types/model/lot/lot';
import { ILotType } from '../../@types/model/masterData/lotType/lotType';
import lodash from 'lodash';
import { ILotLine } from '../../@types/model/lot/lotLine';
import { addArrayElement, addea, compareDate, formatDateTime, removeArrayElement } from '../../services/appFunctionsService';
import LotHttpService from '../../services/http/lot/lotHttpService';
import { DispatchCall, IRootState, RootAction } from '../../@types/redux';
import { connect } from 'react-redux';
import CustomTable from '../../components/table/CustomTable';
import { ILotCorrection } from '../../@types/model/lot/lotCorrection';
import ConfirmationPrompt from '../../components/dialog/ConfirmationPrompt';
import { dataSetLot, dataSetLotView } from '../../store/data/Functions';
import { dataSetLots } from '../../store/data/Actions';
import { Dispatch, bindActionCreators } from 'redux';

interface ILotCorrectionDialogProps {
    dataSetLots : DispatchCall<Array<ILot>>;
    isLoading : boolean;
    lots : Array<ILot>;
    selectedLot ?: ILot;
    selectedSiteIds : Array<number>;
    lotTypes : Array<ILotType>;
    closeLotCorrectionDialog : () => void;
    refreshData ?: () => void;
    setLoading : (isLoading : boolean) => void;
    setSelectedLotLines ?: (lotLineId : number) => void;
    selectedLotLines ?: Array<number>;
}

interface ILotCorrectionDialogState {
    checkedLotLines : Array<{lotLine : ILotLine; checked : boolean}>;
    selectedLotToCorrect ?: IOptionType;
    selectedSimilarLot ?: IOptionType;

    onYesClick : () => void;
    onNoClick : () => void;
    message : string;
    showPrompt : boolean;
}

class LotCorrectionDialog extends React.Component<ILotCorrectionDialogProps, ILotCorrectionDialogState> {

    constructor(props : ILotCorrectionDialogProps) {
        super(props);

        this.state = {
            checkedLotLines: [],

            onYesClick: () => null,
            onNoClick: () => null,
            message: '',
            showPrompt: false,
        };
    }

    public componentDidMount = async () => {
        this.props.setLoading(true);
        try {
            const res = await LotHttpService.getLotCorrectionData(this.props.selectedSiteIds);

            if (res && res.data) {
                this.props.dataSetLots(res.data);
                if (this.props.selectedLot) {
                    let selectedLines : Array<{lotLine : ILotLine; checked : boolean}> = [];

                    if (this.props.selectedLotLines && this.props.selectedLotLines?.length > 0) {
                        const lines = this.props.selectedLot.lotLines.filter(x => this.props.selectedLotLines?.some(y => y === x.id));

                        lines.forEach((x) => {
                            const line = {
                                lotLine: x,
                                checked: true,
                            };

                            selectedLines = addArrayElement(selectedLines, line);
                        });
                    }

                    const sourceLot = this.props.selectedLot ? { label: this.props.selectedLot?.name, value: this.props.selectedLot?.id } : undefined;

                    this.setState({
                        selectedLotToCorrect: sourceLot,
                        checkedLotLines: selectedLines,
                    });
                }
            }
        } catch (e) {
            generalShowErrorSnackbar('Error occured file loading lot correction data');
        } finally {
            this.props.setLoading(false);
        }
    };

    private showConfirmationPrompt = (onYesClick : () => void, onNoClick : () => void, message : string) => {
        this.setState({ onYesClick, onNoClick, message }, () => {
            this.setState({ showPrompt: true });
        });
    };

    private closeConfirmationPrompt = () => {
        this.setState({ onYesClick: () => null, onNoClick: () => null, message: '', showPrompt: false });
    };

    private onLotCorrectionConfirm = () => {
        this.showConfirmationPrompt(this.lotCorrectionSubmit, this.closeConfirmationPrompt, `The selected lot lines will be reverted on lot ${this.state.selectedLotToCorrect?.value} and added new on lot ${this.state.selectedSimilarLot?.value}. Are you sure?`);
    };

    private lotCorrectionSubmit = async () => {
        const sourceLot = this.state.selectedLotToCorrect;
        const destinationLot = this.state.selectedSimilarLot;
        const lotLines = this.state.checkedLotLines.map(x => x.lotLine);

        if (sourceLot && destinationLot && lotLines.length > 0) {
            this.props.setLoading(true);
            const lotCorrectionValues : ILotCorrection = {
                sourceLotId: Number(sourceLot.value),
                destinationLotId: Number(destinationLot.value),
                lotLineIds: lotLines.map(x => x.id),
            };

            try {
                const res = await LotHttpService.lotCorrection(lotCorrectionValues);

                if (res && res.data) {
                    res.data.map(x => dataSetLotView(x));
                    this.closeConfirmationPrompt();
                    this.closeDialog();
                    generalShowSuccessSnackbar('Lot successfully corrected');
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while performing lot correction.');
            } finally {
                this.props.setLoading(false);
            }
        }
    };

    private onRevertLotLinesClick = async () => {
        const sourceLot = this.state.selectedLotToCorrect;
        const lotLines = this.state.checkedLotLines.map(x => x.lotLine);

        if (sourceLot && lotLines.length > 0) {
            this.props.setLoading(true);

            const lotLineIds = lotLines.map(x => x.id);

            try {
                const res = await LotHttpService.revertLotLines(lotLineIds);

                if (res && res.data) {
                    res.data.map(x => dataSetLot(x));
                    this.closeDialog();
                    this.props.setLoading(false);
                    generalShowSuccessSnackbar('Lot successfully corrected');
                }
            } catch (e) {
                this.props.setLoading(false);
                generalShowErrorSnackbar('An error occurred while performing lot correction.');
            }
        }
    };

    private closeDialog = () => {
        this.setState({ selectedLotToCorrect: undefined, selectedSimilarLot: undefined, checkedLotLines: [] });
        this.props.closeLotCorrectionDialog();
    };

    private handleCheckboxChecked = (line : ILotLine, selected : boolean) => {
        if (selected) {
            const index = this.state.checkedLotLines.findIndex(x => x.lotLine.id === line.id);
            const value = {
                lotLine: line,
                checked: selected,
            };

            if (index === -1) {
                this.setState(prevState => ({ checkedLotLines: addArrayElement(prevState.checkedLotLines, value) }));
            }
        } else {
            const index = this.state.checkedLotLines.findIndex(x => x.lotLine.id === line.id);
            if (index !== -1) {
                this.setState(prevState => ({ checkedLotLines: removeArrayElement(prevState.checkedLotLines, index) }));
            }
        }
        if (this.props.setSelectedLotLines) {
            this.props.setSelectedLotLines(line.id);
        }
    };

    private getSelectedSites = (props : ILotCorrectionDialogProps) => props.selectedSiteIds;
    private getLotData = (props : ILotCorrectionDialogProps) => props.lots;
    public getLotTypes = (props : ILotCorrectionDialogProps) => props.lotTypes;

    private getSelectedLotToCorrectLotLine = (lotId : number) => {
        const lot = this.props.lots.find(x => x.id === lotId);

        if (lot) {
            const isInComingLines = lot.lotLines.filter(x => x.isActive && x.isIncoming);
            const isOutGoingLines = lot.lotLines.filter(x => x.isActive && !x.isIncoming);

            const validLotLines = isInComingLines.filter(x => !!x.unitGuid
                && (isInComingLines.filter(y => y.unitGuid === x.unitGuid).length > isOutGoingLines.filter(y => y.unitGuid === x.unitGuid).length))
                .map(x => lodash.orderBy(isInComingLines.filter(y => y.isActive && y.unitGuid === x.unitGuid), line => line.createdOn, 'desc')[0]);

            return validLotLines;
        }
    };

    private getLotOptions = createSelector(
        [this.getLotData, this.getLotTypes, this.getSelectedSites],
        (lots : Array<ILot>, lotTypes : Array<ILotType>, selectedSiteIds : Array<number>) => {
            if (!lots) return;

            return lots.filter(x => x.isActive
                && !x.isFinalized
                && selectedSiteIds.some(y => y === x.siteId)
                && (lotTypes.find(y => y.id === x.lotTypeId)?.guid === CONSTANT_LOT_TYPE_GUIDS[2] || lotTypes.find(y => y.id === x.lotTypeId)?.guid === CONSTANT_LOT_TYPE_GUIDS[4])).map((x) => {
                return { label: x.name, value: x.id };
            });
        },
    );

    private getSelectedLotToCorrect = (props : ILotCorrectionDialogProps, state : ILotCorrectionDialogState) => state.selectedLotToCorrect;

    private getAnySimilarLotOptions = createSelector(
        [this.getLotData, this.getLotTypes, this.getSelectedSites, this.getSelectedLotToCorrect],
        (lots : Array<ILot>, lotTypes : Array<ILotType>, selectedSiteIds : Array<number>, selectedLotToCorrect ?: IOptionType) => {
            if (!lots) return;

            const lotToCorrect = lots.find(x => x.id === selectedLotToCorrect?.value);

            return lotToCorrect ? lots.filter(x => x.isActive
                && !x.isFinalized
                && selectedSiteIds?.some(y => y === x.siteId)
                && (x.id !== lotToCorrect?.id)
                && (x.lotTypeId === lotToCorrect.lotTypeId)
                && (lotTypes.find(y => y.id === x.lotTypeId)?.guid === CONSTANT_LOT_TYPE_GUIDS[2] || lotTypes.find(y => y.id === x.lotTypeId)?.guid === CONSTANT_LOT_TYPE_GUIDS[4])).map((x) => {
                return { label: x.name, value: x.id };
            })
                : [];
        },
    );

    private onLotToCorrectChange = (e : CustomChangeEvent, selectedLot : IOptionType) => {
        this.setState({ selectedLotToCorrect: selectedLot, checkedLotLines: [] });
    };

    private onSimilarLotChange = (e : CustomChangeEvent, selectedLot : IOptionType) => {
        this.setState({ selectedSimilarLot: selectedLot });
    };

    public render() {
        const lines = this.getSelectedLotToCorrectLotLine(Number(this.state.selectedLotToCorrect?.value) ?? 0);
        return (
            <div style={{ height: 500 }} className={'fdc p20'}>
                <div className={'fdr flx1 aic mb10'}>
                    <div className={'fdc flx1 aic'}>
                        <AutocompleteSelect
                            className={'w350'}
                            name={'lotToCorrect'}
                            label={'Lot'}
                            placeholder={'Select A Lot...'}
                            options={this.getLotOptions(this.props) ?? []}
                            onChange={this.onLotToCorrectChange}
                            value={this.state.selectedLotToCorrect}
                            disabled={!!this.props.selectedLotLines}
                        />
                        <div className={'h10'}/>
                        <Card elevation={0} className={'aic fdc w500 oya'}>
                            <CustomTable<ILotLine>
                                isActive = {(row : ILotLine) => row.isActive}
                                columns={[
                                    {
                                        title: 'Select', field: 'id', width: 70,
                                        containerComponent: (row : ILotLine) => {
                                            return <div>
                                                <Checkbox
                                                    className={'pt0 pb0 pr5 m0'}
                                                    checked={this.state.checkedLotLines.find(y => y.lotLine.id === row.id)?.checked}
                                                    onChange={(event, checked) => this.handleCheckboxChecked(row, checked)}
                                                />
                                            </div>;
                                        },
                                    },
                                    { title: 'Unit Barcode', field: 'unitGuid', width: 130 },
                                    { title: 'No. Units (ea)', field: 'amountOfUnits', width: 120, formatFunction: addea,
                                        containerComponent: (row : ILotLine, value : any) => <div className={'fdr'}>
                                            {(value === '0 ea' ? '0 ea' : row.isIncoming ? <div className={'cpl'} style={{ paddingLeft: 3 }}>{`+${value}`}</div> :
                                                <div className={'cred'} style={{ paddingLeft: 3 }}>{`-${value}`}</div>)}
                                        </div> },
                                    { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate },
                                ]}
                                rows={lines ?? []}
                                pageHeight={650}
                                fitWidthToPage
                                initialSortOrder={[{ columnName: 'id_Id', direction : 'desc' }]}
                            />
                        </Card>
                    </div>
                    <div className={'w300'}/>
                    <div className={'fdc flx1 aic'}>
                        <AutocompleteSelect
                            className={'w350'}
                            name={'similarDestinationLot'}
                            label={'Destination Lot'}
                            placeholder={'Select A Similar Lot...'}
                            options={this.getAnySimilarLotOptions(this.props, this.state) ?? []}
                            onChange={this.onSimilarLotChange}
                            value={this.state.selectedSimilarLot}
                        />
                    </div>
                </div>
                <div className={'fdr jcfe mt10'}>
                    <PillButton
                        text={'REVERT SELECTED LOT LINES'}
                        className={'pl30 pr30 h35'}
                        onClick={this.onRevertLotLinesClick}
                        color={'secondary'}
                        size={'small'}
                        disabled={this.props.isLoading
                                || !this.state.selectedLotToCorrect
                                || this.state.checkedLotLines.length < 1}
                    ></PillButton>
                    <div className={'w10'}/>
                    <PillButton
                        text={'CONFIRM'}
                        className={'pl30 pr30 h35'}
                        onClick={this.onLotCorrectionConfirm}
                        color={'secondary'}
                        size={'small'}
                        disabled={this.props.isLoading
                                || !this.state.selectedLotToCorrect
                                || !this.state.selectedSimilarLot
                                || this.state.checkedLotLines.length < 1}
                    ></PillButton>
                </div>
                <ConfirmationPrompt isLoading={this.props.isLoading} open={this.state.showPrompt} message={this.state.message}
                    onOkClicked={this.state.onYesClick} onCancelClicked={this.state.onNoClick}/>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        lots: state.data.lots,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
        lotTypes: state.masterData.lotTypes,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetLots }, dispatcher,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LotCorrectionDialog);

import * as React from 'react';
import {
    dataSetAllOrderRelatedData,
} from '../../store/data/Functions';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import OpenOrdersTable from './OpenOrdersTable';
import OrderRelatedDataHttpService from '../../services/http/order/orderRelatedDataHttpService';
import { syncMasterData } from '../../services/masterDataSyncService';
import Screen from '../../components/Screen';

interface IOrderProps { }

interface IOrderState {
    isLoading : boolean;
    dataFetched : boolean;
}

export default class Order extends React.Component<IOrderProps, IOrderState> {
    constructor(props : IOrderProps) {
        super(props);

        this.state = {
            isLoading: false,
            dataFetched: false,
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }
        try {
            const res = await OrderRelatedDataHttpService.getAllOrderRelatedData(!isIndexedDBAvailable);

            dataSetAllOrderRelatedData(res.data);
            this.setState({ dataFetched: true }, () => this.setLoading(false));
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving order data.');
            this.setLoading(false);
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    private refreshData = async (noAnnounce ?: boolean) => {
        this.setState({ dataFetched: false }, () => this.setLoading(true));
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;
        try {
            const res = await OrderRelatedDataHttpService.getAllOrderRelatedData(!isIndexedDBAvailable);

            dataSetAllOrderRelatedData(res.data);
            this.setState({ dataFetched: true }, () => this.setLoading(false));
            if (!noAnnounce) {
                generalShowSuccessSnackbar('Data Refreshed');
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving order data.');
            this.setLoading(false);
        }
    };

    public render() {
        return (
            <Screen isPadded={false} isScrollable={false} isLoading={this.state.isLoading}>
                <div className={`${window.innerHeight > 800 ? 'flx1 fdc' : 'mnh695 h695'} p20`}>
                    <div className={'flx1 fdc hfill'}>
                        <OpenOrdersTable dataFetched={this.state.dataFetched} refreshData={this.refreshData} isLoading={this.state.isLoading} />
                        <div className={'h20'}/>
                    </div>
                </div>
            </Screen>
        );
    }
}

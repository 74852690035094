import { IBaseModel } from '../../../model';
import { IBarcodeRangeFormValues } from './barcodeRangeFormValues';

export interface IBarcodeRange extends IBaseModel {
    name : string;
    indicatorDigit ?: number;
    rangeStart : number;
    rangeEnd : number;
    isExport : boolean;
    latest ?: number;
    numRemaining ?: number;
    importCode ?: number;
    siteIds ?: Array<number>;
}

export class BarcodeRange implements IBarcodeRange {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public name : string;
    public indicatorDigit ?: number;
    public rangeStart : number;
    public rangeEnd : number;
    public isExport : boolean;
    public latest ?: number;
    public numRemaining ?: number;
    public importCode ?: number;
    public siteIds ?: Array<number> | undefined;

    public constructor(formValues : IBarcodeRangeFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.name = formValues.name;
        this.indicatorDigit = formValues.indicatorDigit;
        this.rangeStart = formValues.rangeStart;
        this.rangeEnd = formValues.rangeEnd;
        this.isExport = formValues.isExport;
        this.latest = formValues.latest;
        this.numRemaining = formValues.numRemaining;
        this.importCode = formValues.importCode;
        this.siteIds = formValues.sites.map(x => Number(x.value)) ?? undefined;
        this.isActive = formValues.isActive;
    }
}

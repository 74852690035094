import { IBaseModel } from '../../../model';
import { IDeviceFormValues } from './deviceFormValues';

export interface IDevice extends IBaseModel {
    name : string;
    serialNumber : string;
}

export class Device implements IDevice {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public name : string;
    public serialNumber : string;

    public constructor(formValues : IDeviceFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.serialNumber = formValues.serialNumber;
        this.name = formValues.name;
    }
}

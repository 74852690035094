import * as React from 'react';
import { IRootState, IAuthState } from '../../@types/redux';
import { connect } from 'react-redux';
import { Icon, IconButton, Divider, Typography, List, Checkbox } from '@mui/material';
import { formatDateTime, formatDateTimeToDateOnly, compareDate, setArrayElement, addArrayElement } from '../../services/appFunctionsService';
import CustomTable from '../../components/datagrid/CustomTable';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { getUniqueStockLines } from '../../services/mappingFunctionsService';
import { dataSetAllStockRelatedData, dataSetCompliance, dataSetStock } from '../../store/data/Functions';
import posed from 'react-pose';
import moment from 'moment';
import uuidv1 from 'uuid';
import { navComplianceDashboard } from '../../store/nav/Actions';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { IStock } from '../../@types/model/stock/stock';
import { ISite } from '../../@types/model/masterData/site/site';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { IMarket } from '../../@types/model/masterData/market/market';
import { IRegion } from '../../@types/model/masterData/region/region';
import { ICountry } from '../../@types/model/masterData/country/country';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { ISize } from '../../@types/model/masterData/size/size';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import { ICompliance } from '../../@types/model/compliance/compliance';
import { IPalletBaseType } from '../../@types/model/masterData/palletBaseType/palletBaseType';
import { IPackCategory } from '../../@types/model/masterData/pack/packCategory';
import { IStockLine } from '../../@types/model/stock/stockLine';
import { IComplianceStock } from '../../@types/model/compliance/complianceStock';
import { IComplianceLine } from '../../@types/model/compliance/complianceLine';
import { IUniqueCodeObject } from '../../@types/model';
import ComplianceHttpService from '../../services/http/compliance/complianceHttpService';
import PillButton from '../../components/input/PillButton';
import Screen from '../../components/Screen';
import TransactionFilter from '../../components/filters/BasicTransactionScreenFilter';
import { createSelector } from 'reselect';
import { IRight } from '../../@types/model/user/right';
import { RouteComponentProps } from 'react-router';
import CustomTooltip from '../../components/tooltip/tooltip';
import { syncMasterData } from '../../services/masterDataSyncService';
import { getIconLocation } from '../../services/iconHelperService';

const StatusColumnDiv = posed.div(
    {
        expand : {
            width: 350,
        },
        collapse : {
            width: 40,
        },
    },
);

interface IComplianceScreenProps extends RouteComponentProps {
    organizations : Array<IOrganization>;
    stocks : Array<IStock>;
    sites : Array<ISite>;
    farms : Array<IFarm>;
    markets : Array<IMarket>;
    regions : Array<IRegion>;
    countries : Array<ICountry>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    sizes : Array<ISize>;
    colours : Array<IColour>;
    grades : Array<IGrade>;
    orchards : Array<IOrchard>;
    auth : IAuthState;
    compliances : Array<ICompliance>;
    palletBaseTypes : Array<IPalletBaseType>;
    selectedCommodities : Array<ICommodity>;
    selectedVarieties : Array<IUniqueCodeObject>;
    selectedPackCategories : Array<IPackCategory>;
    selectedColours : Array<IUniqueCodeObject>;
    selectedGrades : Array<IUniqueCodeObject>;
    selectedDashboardSiteIds : Array<number>;
    selectedOrganizationIds : Array<number> ;
    selectedSiteIds : Array<number> ;
    selectedExporterOrganization ?: IOrganization;
}

interface IComplianceScreenState {
    isLoading : boolean;
    searchValue : string;
    selectedStock ?: IStock;
    editingStock ?: IStock;
    editingStockLine ?: IStockLine;
    isAdding : boolean;
    isEditingStock : boolean;
    isEditingStockLine : boolean;
    isShowingDetails : boolean;
    rows : Array<IComplianceStock>;
    collapseDraftColumn : boolean;
    selectedCompliance ?: ICompliance;
    showAdvanced : boolean;
    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;
    filtersExpanded : boolean;
    isFiltering : boolean;
}

class ComplianceScreen extends React.Component<IComplianceScreenProps, IComplianceScreenState> {
    private anchorEl : any;

    constructor(props : IComplianceScreenProps) {
        super(props);

        this.state = {
            isLoading: false,
            searchValue: '',
            selectedStock: undefined,
            editingStock: undefined,
            editingStockLine: undefined,
            isAdding: false,
            isEditingStock: false,
            isEditingStockLine: false,
            isShowingDetails: false,
            rows: [],
            collapseDraftColumn : true,
            showAdvanced: false,
            filtersExpanded: true,
            selectedFromDate: moment().local().startOf('day').subtract(3, 'days'),
            selectedToDate: moment().local().endOf('day'),
            isFiltering: false,
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        try {
            const res = await ComplianceHttpService.getComplianceDashboardData(this.getDate('from'), this.getDate('to'), undefined, this.props.selectedSiteIds, !isIndexedDBAvailable);

            dataSetAllStockRelatedData(res.data);
            this.setLoading(false);
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving stock related data.');
            this.setLoading(false);
        }
    };

    public componentDidUpdate = (prevProps : IComplianceScreenProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            if (nextProps.selectedSiteIds !== undefined && prevProps.selectedSiteIds !== nextProps.selectedSiteIds) {
                this.refreshData(true);
            }
            if ((prevProps.stocks !== nextProps.stocks)
                || (prevProps.compliances !== nextProps.compliances)
                || (prevProps.selectedCommodities !== nextProps.selectedCommodities)
                || (prevProps.selectedVarieties !== nextProps.selectedVarieties)
                || (prevProps.selectedColours !== nextProps.selectedColours)
                || (prevProps.selectedGrades !== nextProps.selectedGrades)
                || (prevProps.selectedPackCategories !== nextProps.selectedPackCategories)
                || (prevProps.selectedDashboardSiteIds !== nextProps.selectedDashboardSiteIds)) {
                this.filterRows();
            }
            if ((prevProps.compliances !== nextProps.compliances)
                || (prevProps.selectedExporterOrganization !== nextProps.selectedExporterOrganization)
                || (prevProps.selectedSiteIds !== nextProps.selectedSiteIds)) {
                this.filterRows();
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    public expandFilters = (filtersExpanded : boolean) => this.setState({ filtersExpanded });

    private getSiteShortDescription = (siteId : number) => {
        const sites = this.props.sites;
        const site = sites && sites.find(x => x.id === siteId);
        return site ? site.shortDescription : '';
    };

    private getOrganizationCode = (id : number) => {
        const organizations = this.props.organizations;
        const organization = organizations && organizations.find(x => x.id === id);
        return organization ? organization.code : '';
    };

    private getFarmCode = (farmId : number) => {
        const farms = this.props.farms;
        const farm = farms && farms.find(x => x.id === farmId);
        return farm ? farm.code : '';
    };

    private getPalletBaseTypeCode = (palletBaseTypeId : number) => {
        const palletBaseTypes = this.props.palletBaseTypes;
        const palletBaseType = palletBaseTypes && palletBaseTypes.find(x => x.id === palletBaseTypeId);
        return palletBaseType ? palletBaseType.code : '';
    };

    private getRegionCode = (regionId : number) => {
        const regions = this.props.regions;
        const region = regions && regions.find(x => x.id === regionId);
        return region ? region.code : '';
    };

    private getMarketCode = (marketId : number) => {
        const markets = this.props.markets;
        const market = markets && markets.find(x => x.id === marketId);
        return market ? market.code : '';
    };

    private getCountryCode = (countryId : number) => {
        const countries = this.props.countries;
        const country = countries && countries.find(x => x.id === countryId);
        return country ? country.code : '';
    };

    private getCommodityCode = (id : number) => {
        const commodities = this.props.commodities;
        const commodity = commodities && commodities.find(x => x.id === id);
        return commodity ? commodity.code : '';
    };

    private getVarietyCode = (id : number) => {
        const varieties = this.props.varieties;
        const variety = varieties && varieties.find(x => x.id === id);
        return variety ? variety.code : '';
    };

    private getPackCode = (id : number) => {
        const packs = this.props.packs;
        const pack = packs && packs.find(x => x.id === id);
        return pack ? pack.code : '';
    };

    private getSizeCode = (id : number) => {
        const sizes = this.props.sizes;
        const size = sizes && sizes.find(x => x.id === id);
        return size ? size.code : '';
    };

    private getColourCode = (id : number) => {
        const colours = this.props.colours;
        const colour = colours && colours.find(x => x.id === id);
        return colour ? colour.code : '';
    };

    private getGradeCode = (id : number) => {
        const grades = this.props.grades;
        const grade = grades && grades.find(x => x.id === id);
        return grade ? grade.code : '';
    };

    private getOrchardCode = (id : number) => {
        const orchards = this.props.orchards;
        const orchard = orchards && orchards.find(x => x.id === id);
        return orchard ? orchard.code : '';
    };

    private refreshData = async (noAnnounce ?: boolean) => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;
        try {
            const res = await ComplianceHttpService.getComplianceDashboardData(this.getDate('from'), this.getDate('to'), undefined, this.props.selectedSiteIds, !isIndexedDBAvailable);

            dataSetAllStockRelatedData(res.data);
            this.setLoading(false);
            if (!noAnnounce) {
                generalShowSuccessSnackbar('Data Refreshed');
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving stock related data.');
            this.setLoading(false);
        }
    };

    private getDate = (type : 'from' | 'to') => {
        switch (type) {
            case 'from':
                return this.state.selectedFromDate.startOf('day').utc().unix() * 1000;
            case 'to':
                return this.state.selectedToDate.endOf('day').utc().unix() * 1000;
        }
    };

    private isMainStockLine = (stockline : IStockLine) => {
        if (!stockline) {
            return false;
        }
        const stock = this.props.stocks.find(x => x.id === stockline.stockId && x.isActive);
        if (stock) {
            return !(stock.stockLines.some(x => x.isActive && ((x.cartons > stockline.cartons) || ((x.cartons === stockline.cartons) && (x.id < stockline.id)))));
        }
        return false;
    };

    private isStockLinkedToCompliance = (stockId : number) => {
        let complianceLineLinkedToStock : IComplianceLine | undefined;
        this.props.compliances.forEach((compliance) => {
            compliance.complianceLines.forEach((line) => {
                if (line.isActive && line.stockId === stockId) {
                    complianceLineLinkedToStock = line;
                }
            });
        });

        if (!!complianceLineLinkedToStock) {
            if (!complianceLineLinkedToStock.isInspected && !complianceLineLinkedToStock.isRejected && !complianceLineLinkedToStock.isReplaced) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    private filterRows = () => {
        const rows : Array<IComplianceStock> = [];
        this.props.stocks.filter(x => x.isActive && x.channel === 'E' && !!this.isStockLinkedToCompliance(x.id) /* && !x.isInspected && !x.isRejected && !x.isReplaced*/ && !x.isTemporary
            && (this.props.selectedExporterOrganization ? (x.currentOrganizationId === this.props.selectedExporterOrganization?.id) : true)
            && (this.props.selectedSiteIds?.some(y => y === x.currentSiteId))
            && (moment(x.packDate) >= this.state.selectedFromDate)
            && (moment(x.packDate) <= this.state.selectedToDate)
            && x.stockLines.some(y => y.isActive && this.isMainStockLine(y)))
            .forEach((x) => {
                const stockLine = this.getMainStockLine(x);
                if (stockLine) {
                    rows.push({
                        ...x,
                        organizationId: x.currentOrganizationId,
                        commodityId: stockLine.commodityId,
                        varietyId: stockLine.varietyId,
                        farmId: stockLine.farmId,
                        packId: stockLine.packId,
                        sizeId: stockLine.sizeId,
                        gradeId: stockLine.gradeId,
                        colourId: stockLine.colourId,
                        orchardId: stockLine.orchardId,
                        isSelected: (!!this.state.selectedCompliance && this.state.selectedCompliance.isActive && this.state.selectedCompliance.complianceLines.some(y => y.stockId === x.id && y.isActive)),
                    });
                }
            });
        this.setState({ rows });
    };

    private handleSelectCheckBox = async (row : IComplianceStock, checked : boolean) => {
        this.setLoading(true);
        const newRow = { ...row };
        newRow.isSelected = checked;
        const index = this.state.rows.findIndex(x => x.id === row.id);
        const newCompliance : ICompliance | undefined = this.state.selectedCompliance && { ...this.state.selectedCompliance };
        const oldCompliance : ICompliance | undefined = this.state.selectedCompliance && { ...this.state.selectedCompliance };
        if (newCompliance && oldCompliance) {
            if (!oldCompliance.commodityId) {
                newCompliance.organizationId = row.organizationId;
                newCompliance.siteId = row.originalSiteId;
                newCompliance.commodityId = row.commodityId;
            }
            const oldComplianceLineIndex = newCompliance.complianceLines.findIndex(x => x.stockId === newRow.id);
            const oldComplianceLine = newCompliance.complianceLines[oldComplianceLineIndex];
            if (oldComplianceLine) {
                oldComplianceLine.isActive = checked;
                newCompliance.complianceLines = setArrayElement(newCompliance.complianceLines, oldComplianceLineIndex, oldComplianceLine);
            } else if (checked) {
                const newLine : IComplianceLine = {
                    id: 0,
                    guid: uuidv1(),
                    stockId: row.id,
                    isInspected: false,
                    isRejected: false,
                    isReplaced: false,
                    complianceId: newCompliance.id,
                    isActive: checked,
                };
                newCompliance.complianceLines = addArrayElement(newCompliance.complianceLines, newLine, 'end');
            }
            dataSetCompliance(newCompliance);
            try {
                const res = await ComplianceHttpService.addOrUpdateCompliance(newCompliance);

                if (res && res.data) {
                    newCompliance.complianceLines.forEach((x) => {
                        if (x.stock) {
                            dataSetStock({ ...x.stock });
                        }
                        x.stock = undefined;
                    });
                    dataSetCompliance(newCompliance);
                    this.setState({ selectedCompliance: newCompliance }, this.filterRows);
                    generalShowSuccessSnackbar(`Stock successfully ${checked ? 'added to' : 'removed from'} compliance.`);
                    if (index !== -1) {
                        this.setState({
                            rows: setArrayElement(this.state.rows, index, newRow),
                        });
                    }
                    this.setLoading(false);
                } else {
                    generalShowErrorSnackbar(`An error occurred ${checked ? 'adding the stock to' : 'removing the stock from'} the compliance.`);
                    dataSetCompliance(oldCompliance);
                    this.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar(`An error occurred ${checked ? 'adding the stock to' : 'removing the stock from'} the compliance.`);
                dataSetCompliance(oldCompliance);
                this.setLoading(false);
            }
        }
    };

    private handleSelectAllCheckBox = async (checked : boolean) => {
        const rows = this.state.rows.filter(x => x.isActive && x.isSelected !== checked && !this.isCheckboxDisabled(x));
        const newCompliance : ICompliance | undefined = this.state.selectedCompliance && { ...this.state.selectedCompliance };
        const oldCompliance : ICompliance | undefined = this.state.selectedCompliance && { ...this.state.selectedCompliance };
        this.setLoading(true);
        if (newCompliance && oldCompliance && rows.length !== 0) {
            rows.forEach((x) => {
                x.isSelected = checked;
                const oldComplianceLineIndex = newCompliance.complianceLines.findIndex(y => y.stockId === x.id);
                const oldComplianceLine = newCompliance.complianceLines[oldComplianceLineIndex];
                if (oldComplianceLine) {
                    oldComplianceLine.isActive = checked;
                    newCompliance.complianceLines = setArrayElement(newCompliance.complianceLines, oldComplianceLineIndex, oldComplianceLine);
                } else if (checked) {
                    const newLine : IComplianceLine = {
                        id: 0,
                        guid: uuidv1(),
                        stockId: x.id,
                        isInspected: false,
                        isRejected: false,
                        isReplaced: false,
                        complianceId: newCompliance.id,
                        isActive: checked,
                    };
                    newCompliance.complianceLines = addArrayElement(newCompliance.complianceLines, newLine, 'end');
                }
            });
            dataSetCompliance(newCompliance);
            try {
                const res = await ComplianceHttpService.addOrUpdateCompliance(newCompliance);

                if (res && res.data) {
                    newCompliance.complianceLines.forEach((x) => {
                        if (x.stock) {
                            dataSetStock({ ...x.stock });
                        }
                        x.stock = undefined;
                    });
                    dataSetCompliance(newCompliance);
                    this.setState({ selectedCompliance: newCompliance }, this.filterRows);
                    this.setLoading(false);
                    generalShowSuccessSnackbar(`Stock successfully ${checked ? 'added to' : 'removed from'} compliance.`);
                } else {
                    generalShowErrorSnackbar(`An error occurred ${checked ? 'adding the stock to' : 'removing the stock from'} the compliance.`);
                    dataSetCompliance(oldCompliance);
                    this.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar(`An error occurred ${checked ? 'adding the stock to' : 'removing the stock from'} the compliance.`);
                dataSetCompliance(oldCompliance);
                this.setLoading(false);
            }
        }
    };

    private getMainStockLine = (stock : IStock) => getUniqueStockLines().find(x => x.stockId === stock.id);

    private addNewCompliance = async () => {
        if (!this.props.selectedOrganizationIds || this.props.selectedOrganizationIds?.length !== 1) {
            return;
        }
        const newCompliance : ICompliance = {
            id: 0,
            guid: uuidv1(),
            status: 'Draft',
            organizationId: this.props.selectedOrganizationIds[0],
            isActive: true,
            complianceLines: [],
        };
        this.setLoading(true);
        try {
            const res = await ComplianceHttpService.addOrUpdateCompliance(newCompliance);

            if (res && res.data) {
                newCompliance.complianceLines.forEach((x) => {
                    if (x.stock) {
                        dataSetStock({ ...x.stock });
                    }
                    x.stock = undefined;
                });
                dataSetCompliance(newCompliance);

                this.setState({ selectedCompliance: newCompliance }, this.filterRows);
                generalShowSuccessSnackbar('Compliance successfully added.');
                this.setLoading(false);
            } else {
                generalShowErrorSnackbar('An error occurred adding the compliance.');
                this.setLoading(false);
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred adding the compliance.');
            this.setLoading(false);
        }
    };

    private deleteCompliance = async (compliance : ICompliance, event : React.MouseEvent<HTMLElement | MouseEvent>) => {
        event.stopPropagation();
        const oldCompliance = { ...compliance };
        const newCompliance = { ...compliance };
        newCompliance.updatedOn = undefined;
        newCompliance.isActive = false;
        this.setLoading(true);
        dataSetCompliance(newCompliance);
        try {
            const res = await ComplianceHttpService.addOrUpdateCompliance(newCompliance);

            if (res && res.data) {
                newCompliance.complianceLines.forEach((x) => {
                    if (x.stock) {
                        dataSetStock({ ...x.stock });
                    }
                    x.stock = undefined;
                });
                dataSetCompliance(newCompliance);
                if (res.data.id === (this.state.selectedCompliance && this.state.selectedCompliance.id)) {
                    this.setState({ selectedCompliance: undefined }, this.filterRows);
                }
                generalShowSuccessSnackbar('Compliance successfully removed.');
                this.setLoading(false);
            } else {
                generalShowErrorSnackbar('An error occurred removing the compliance.');
                dataSetCompliance(oldCompliance);
                this.setLoading(false);
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred removing the compliance draft.');
            dataSetCompliance(oldCompliance);
            this.setLoading(false);
        }
    };

    private getColumns = () => {
        const columns = [];
        columns.push(
            { title: 'Select', field: 'isSelected', width: 80,
                titleContainerComponent: (value : string) => {
                    const rows = this.state.rows.filter(x => x.isActive && !this.isCheckboxDisabled(x));
                    return <div className={'fdr aic'}>
                        <Checkbox
                            className={'p0 mr10'}
                            indeterminate={!!this.state.selectedCompliance && rows.some(x => x.isSelected) && rows.some(x => !x.isSelected)}
                            disabled={this.state.isLoading || !this.state.selectedCompliance || (rows.length === 0)}
                            checked={!!this.state.selectedCompliance && !rows.some(x => !x.isSelected)}
                            onChange={(event, checked) => this.handleSelectAllCheckBox(checked)}
                            onClick={e => e.stopPropagation()}
                        />
                        {value}
                    </div>;
                },
                containerComponent: (row : IComplianceStock, value : boolean) => {
                    const disabled = this.isCheckboxDisabled(row);
                    return <Checkbox
                        checked={!!this.state.selectedCompliance && value}
                        disabled={this.state.isLoading || disabled}
                        onChange={(event, checked) => this.handleSelectCheckBox(row, checked)}
                    />;
                },
            },
            { title: 'Pack Date', field: 'packDate', formatFunction: formatDateTimeToDateOnly, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Barcode', field: 'barcode', enableFiltering: true, enableSorting: true /* , freezeUpTo: this.state.showAdvanced*/ },
            { title: 'SSCC', field: 'sscc', enableFiltering: true, enableSorting: true },
            { title: 'Temp Barcode', field: 'tempBarcode', enableFiltering: true, enableSorting: true },
            { title: 'Commodity', field: 'commodityId', formatFunction: this.getCommodityCode, enableFiltering: true, enableSorting: true },
            { title: 'Variety', field: 'varietyId', formatFunction: this.getVarietyCode, enableFiltering: true, enableSorting: true },
            { title: 'Pack', field: 'packId', formatFunction: this.getPackCode, enableFiltering: true, enableSorting: true },
            { title: 'Size', field: 'sizeId', formatFunction: this.getSizeCode, enableFiltering: true, enableSorting: true },
            { title: 'Colour', field: 'colourId', formatFunction: this.getColourCode, enableFiltering: true, enableSorting: true },
            { title: 'Grade', field: 'gradeId', formatFunction: this.getGradeCode, enableFiltering: true, enableSorting: true },
            { title: 'Farm', field: 'farmId', formatFunction: this.getFarmCode, enableFiltering: true, enableSorting: true },
            { title: 'Current Site', field: 'currentSiteId', formatFunction: this.getSiteShortDescription, enableFiltering: true, enableSorting: true },
            { title: 'Orchard', field: 'orchardId', formatFunction: this.getOrchardCode, enableFiltering: true, enableSorting: true },
            { title: 'Pallet Base Type', field: 'palletBaseTypeId', formatFunction: this.getPalletBaseTypeCode, enableFiltering: true, enableSorting: true },
            { title: 'Target Market', field: 'marketId', formatFunction: this.getMarketCode, enableFiltering: true, enableSorting: true },
            { title: 'Target Region', field: 'regionId', formatFunction: this.getRegionCode, enableFiltering: true, enableSorting: true },
            { title: 'Target Country', field: 'countryId', formatFunction: this.getCountryCode, enableFiltering: true, enableSorting: true },
            { title: 'Cartons', field: 'cartons', enableFiltering: true, enableSorting: true },
            { title: 'Status', field: 'status', enableFiltering: true, enableSorting: true },
            { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
            { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
            { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
        );
        return columns;
    };

    private toggleSelectedCompliance = (compliance : ICompliance, event ?: React.MouseEvent<HTMLElement | MouseEvent>) => {
        if (event) {
            event.stopPropagation();
        }
        this.setState({ selectedCompliance: (this.state.selectedCompliance && this.state.selectedCompliance.id) === compliance.id ? undefined : compliance }, this.filterRows);
    };

    private collapseComplianceDrafts = () => this.setState({ collapseDraftColumn: true });
    private expandComplianceDrafts = () => this.setState({ collapseDraftColumn: false });

    private isCheckboxDisabled = (row : IComplianceStock) => {
        const selectedCompliance = this.state.selectedCompliance && { ...this.state.selectedCompliance };
        if (!selectedCompliance || !selectedCompliance.isActive) {
            return true;
        }

        if ((selectedCompliance.organizationId && selectedCompliance.organizationId !== row.organizationId) ||
        (selectedCompliance.siteId && selectedCompliance.siteId !== row.currentSiteId) ||
        (selectedCompliance.siteId && selectedCompliance.siteId !== row.originalSiteId) ||
        (selectedCompliance.commodityId && selectedCompliance.commodityId !== row.commodityId)) {
            return true;
        }

        return this.props.compliances.some(x => x.isActive && x.complianceLines.some(y => y.stockId === row.id && x.id !== selectedCompliance.id && y.isActive));
    };

    private selectedOrganizationHasUnassignedStock = () => {
        if (!this.props.selectedOrganizationIds) {
            return false;
        }
        return this.state.rows.some(x => x.isActive && this.props.selectedOrganizationIds?.some(y => y === x.organizationId)
        && !this.props.compliances.some(y => y.isActive && y.complianceLines.some(z => z.isActive && z.stockId === x.id)));
    };

    private submitComplianceFromDraft = async (compliance : ICompliance, event : React.MouseEvent<HTMLElement | MouseEvent>) => {
        event.stopPropagation();
        const oldCompliance = { ...compliance };
        const newCompliance = { ...compliance };
        newCompliance.updatedOn = undefined;
        newCompliance.status = 'Planned';
        this.setLoading(true);
        dataSetCompliance(newCompliance);
        try {
            const res = await ComplianceHttpService.addOrUpdateCompliance(newCompliance);

            if (res && res.data) {
                newCompliance.complianceLines.forEach((x) => {
                    if (x.stock) {
                        dataSetStock({ ...x.stock });
                    }
                    x.stock = undefined;
                });
                dataSetCompliance(newCompliance);
                if (res.data.id === (this.state.selectedCompliance && this.state.selectedCompliance.id)) {
                    this.setState({ selectedCompliance: undefined }, this.filterRows);
                }
                generalShowSuccessSnackbar('Compliance successfully moved to Planned.');
                this.setLoading(false);
            } else {
                generalShowErrorSnackbar('An error occurred moving the compliance to Planned.');
                dataSetCompliance(oldCompliance);
                this.setLoading(false);
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred moving the compliance to Planned.');
            dataSetCompliance(oldCompliance);
            this.setLoading(false);
        }
    };

    private handleDateRangeChange = (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => {
        const selectedFromDate = changedBy === 'start' ? moment(start).startOf('day') : end < start ? moment(end).startOf('day') : moment(start).startOf('day');
        const selectedToDate = changedBy === 'end' ? moment(end).endOf('day') : start > end ? moment(start).endOf('day') : moment(end).endOf('day');
        this.setState({ selectedFromDate, selectedToDate });
    };

    private onApplyClick = () => {
        this.refreshData(true);
    };

    private getRights = (props : IComplianceScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IComplianceScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        return (
            <Screen isLoading={this.state.isLoading} isPadded={false} isScrollable={false}>
                <div className={'fdc hfill'}>
                    <TransactionFilter className={'pt10 mt10 mr20 mb10'} selectedFromDate={this.state.selectedFromDate} selectedToDate={this.state.selectedToDate} handleDateRangeChange={this.handleDateRangeChange} onApplyClick={this.onApplyClick} />
                    {/* Compliance Stock Table */}
                    <div className={'fdr flx1 oh'}>
                        <div className={'fdc mr20 mt10 mb10 ml20'}>
                            <div className={'bcw hfill'}>
                                <CustomTable<IComplianceStock>
                                    enableSorting
                                    enableFiltering
                                    enableClearFilterButton
                                    enablePagination
                                    enableRefresh
                                    disableRefreshButton={this.state.isLoading}
                                    refreshFunction={this.refreshData}
                                    columns={this.getColumns()}
                                    rows={this.state.rows}
                                    initialSortOrder={[{ columnName: 'packDate_Pack Date', direction : 'desc' }]}
                                    pageSizes={[50, 150, 250, 500, 1000]}
                                    pageHeight={280}
                                />
                            </div>
                        </div>
                        {this.hasEditingRight(this.props) &&
                            <StatusColumnDiv pose={this.state.collapseDraftColumn ? 'collapse' : 'expand'} className={'PaperBorder ml10 mt10 mb10 mr20 fdc pr5 pl5 bcw'} key={'Compliance Drafts'}>
                                <div className={this.state.collapseDraftColumn ? 'fdc hfill wfill jcc' : 'dn'}>
                                    <IconButton style={{ height: 40, width: 40, paddingTop: 8, marginTop: 5 }} onClick={() => this.expandComplianceDrafts()} className={'cpd '}><Icon>chevron_left</Icon></IconButton>
                                    <div className={'flx1'} />
                                    <Typography className={'aic VerticalText wfill'} variant={'subtitle1'}>
                                        <div className={'flx1'} />
                                        {'Compliance Drafts'}
                                    </Typography>
                                    <div className={'flx1 p10'} />
                                </div>

                                <div className={this.state.collapseDraftColumn ? 'dn' : 'bcpd pr5'} >
                                    <Typography className={`aic ${this.state.collapseDraftColumn ? 'VerticalText wfill' : 'pl10 pr10 w300 cw'}`} variant={'subtitle1'}>
                                        <IconButton className={'cw'} onClick={() => this.collapseComplianceDrafts()}><Icon>chevron_right</Icon></IconButton>
                                        <div className={'flx1'} />
                                        {'Compliance Drafts'}
                                        <CustomTooltip title={'Compliance Dashboard'}>
                                            <IconButton className={this.state.collapseDraftColumn ? 'dn' : 'p0 h30 w30 ml20'} onClick={navComplianceDashboard} disabled={this.state.isLoading}>
                                                <img className={'h20 w20 cw'} src={`${getIconLocation()}/dashboard.svg`} />
                                            </IconButton>
                                        </CustomTooltip>
                                    </Typography>
                                </div>
                                <List className={this.state.collapseDraftColumn ? 'dn' : 'oya hfill oxh'}>
                                    {this.props.compliances.filter(x => this.props.selectedOrganizationIds?.some(y => y === x.organizationId) && x.isActive && x.status === 'Draft')
                                        .map(compliance => <div className={`PaperBorder p5 m5 curd fdc DispatchListItem${(this.state.selectedCompliance && this.state.selectedCompliance.id) === compliance.id ? 'Selected' : ''}`} key={`compliance_${compliance.id}`} onClick={event => this.toggleSelectedCompliance(compliance, event)}>
                                            <div className={'fdr wfill aic jcc mt5'} >
                                                <div className={'cgray3 fs12 aic'}>#{compliance.id}</div>
                                                <div className={'flx1'} />
                                                {compliance.waybill}
                                                <div className={'flx1'}/>
                                                <IconButton className={'p0'} disabled={this.state.isLoading} onClick={(event : React.MouseEvent<HTMLButtonElement>) => this.submitComplianceFromDraft(compliance, event)}><Icon fontSize={'small'}>done</Icon></IconButton>
                                                <span className={'w20'}/>
                                                <IconButton className={'p0'} disabled={this.state.isLoading} onClick={(event : React.MouseEvent<HTMLButtonElement>) => this.deleteCompliance(compliance, event)}><Icon fontSize={'small'}>delete</Icon></IconButton>
                                            </div>
                                            <Divider className={'mt10 mb10 wfill'} />
                                            {(!compliance.complianceLines || compliance.complianceLines.filter(x => x.isActive).length === 0) ?
                                                <div className={'fdr wfill fs14 aic'} >
                                                    {'Select pallets to add...'}
                                                </div>
                                                :
                                                <div className={'fdr'} >
                                                    <div className={'fdc'}>
                                                        <div className={'fdr'}>{this.getOrganizationCode(compliance.organizationId)} <div className={'w10'}/> {(compliance.commodityId ? this.getCommodityCode(compliance.commodityId) : '')}</div>
                                                        <div className={'fdr'}>{(compliance.siteId ? this.getSiteShortDescription(compliance.siteId) : '')}</div>
                                                    </div>
                                                    <div className={'flx1'} />
                                                    <div className={'h30 w30 Round bcpd cs ml10 aic jcc fs12'}>
                                                        {compliance.complianceLines.filter(x => x.isActive && !x.isRejected).length}
                                                    </div>
                                                </div>
                                            }
                                        </div>)}
                                </List>
                                <div className={this.state.collapseDraftColumn ? 'dn' : 'fdr p10'}>
                                    <div className={'flx1'} />
                                    <PillButton className={'w100 m5'} color='secondary' onClick={this.addNewCompliance} disabled={this.state.isLoading || !this.selectedOrganizationHasUnassignedStock()} text={'add'} />
                                </div>
                            </StatusColumnDiv>
                        }
                    </div>
                </div>;
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        organizations: state.masterData.organizations,
        compliances: state.data.compliances,
        stocks: state.data.stocks,
        sites: state.masterData.sites,
        farms: state.masterData.farms,
        markets: state.masterData.markets,
        regions: state.masterData.regions,
        countries: state.masterData.countries,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        sizes: state.masterData.sizes,
        colours: state.masterData.colours,
        grades: state.masterData.grades,
        orchards: state.masterData.orchards,
        palletBaseTypes: state.masterData.palletBaseTypes,
        auth: state.auth,
        selectedCommodities: state.data.selectedDashboardCommodities,
        selectedVarieties: state.data.selectedDashboardVarieties,
        selectedPackCategories: state.data.selectedDashboardPackCategories,
        selectedColours: state.data.selectedDashboardColours,
        selectedGrades: state.data.selectedDashboardGrades,
        selectedDashboardSiteIds: state.data.selectedDashboardSiteIds,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
        selectedExporterOrganization: state.data.selectedExporterOrganization,
    };
};

export default connect(
    mapStateToProps,
)(ComplianceScreen);

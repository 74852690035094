import * as Yup from 'yup';
import { IFtpDetail } from './ftpDetail';

export interface IFtpDetailFormValues {
    id : number;
    guid : string;
    name : string;
    address : string;
    port : number;
    username : string;
    password : string;
    path : string;
    isActive : boolean;
}

export class FtpDetailFormValues implements IFtpDetailFormValues {
    public id : number;
    public guid : string;
    public name : string;
    public address : string;
    public port : number;
    public username : string;
    public password : string;
    public path : string;
    public isActive : boolean;

    public constructor(ftpDetail ?: IFtpDetail) {
        this.initializeDefaultValues();
        if (ftpDetail) {
            this.id = ftpDetail.id;
            this.guid = ftpDetail.guid;
            this.name = ftpDetail.name;
            this.address = ftpDetail.address;
            this.port = ftpDetail.port;
            this.username = ftpDetail.username;
            this.password = ftpDetail.password;
            this.path = ftpDetail.path;
            this.isActive = ftpDetail.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.name = '';
        this.address = '';
        this.port = 21;
        this.username = '';
        this.password = '';
        this.path = '';
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        address: Yup.string().required('Required'),
        port: Yup.number().typeError('Value must be numeric').min(0, 'Value can not be negative').nullable(),
        username: Yup.string().required('Required'),
        password: Yup.string().required('Required'),
        path: Yup.string().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

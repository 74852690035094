import { Checkbox } from '@mui/material';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { IOptionType } from '../../@types/helper';
import { IBatchIdCode } from '../../@types/model/batch/batchidcode';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import TransactionFilter from '../../components/filters/BasicTransactionScreenFilter';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import { addArrayElement, formatMomentToDatePicker, removeArrayElement } from '../../services/appFunctionsService';
import BatchHttpService from '../../services/http/batch/batchHttpService';
import CommodityHttpService from '../../services/http/masterData/commodityHttpService';
import FarmHttpService from '../../services/http/masterData/farmHttpService';
import VarietyHttpService from '../../services/http/masterData/varietyHttpService';
import { generalShowErrorSnackbar } from '../../store/general/Functions';
import PillButton from '../../components/input/PillButton';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ISite } from '../../@types/model/masterData/site/site';
import { useAppSelector } from '../../@types/redux';
import { generateBatchReportPdf } from './BatchSummaryPopup';

export interface BatchReportMultiSelectProps {
    isOpen : boolean;
    onClose ?: () => void;
    parentStartDate : moment.Moment;
    parentEndDate : moment.Moment;
}

const BatchReportMultiSelect = (props : BatchReportMultiSelectProps) => {

    const organizationIds = useAppSelector<Array<number> | undefined>(x => x.data.selectedOrganizationIds);
    const siteIds = useAppSelector<Array<number> | undefined>(x => x.data.selectedSiteIds);
    const organizations = useAppSelector<Array<IOrganization>>(x => x.masterData.organizations);
    const sites = useAppSelector<Array<ISite>>(x => x.masterData.sites);
    const farmData = useAppSelector<Array<IFarm>>(x => x.masterData.farms);
    const commodityData = useAppSelector<Array<ICommodity>>(x => x.masterData.commodities);
    const varietyData = useAppSelector<Array<IVariety>>(x => x.masterData.varieties);

    const { isOpen, onClose, parentStartDate, parentEndDate } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<moment.Moment>(parentStartDate);
    const [endDate, setEndDate] = useState<moment.Moment>(parentEndDate);
    const [farms, setFarms] = useState<Array<IFarm>>([]);
    const [commodities, setCommodities] = useState<Array<ICommodity>>([]);
    const [varieties, setVarieties] = useState<Array<IVariety>>([]);
    const [batches, setBatches] = useState<Array<IBatchIdCode>>([]);
    const [selectedBatches, setSelectedBatches] = useState<Array<number>>([]);
    const [selectedFarmOptions, setSelectedFarmOptions] = useState<Array<IOptionType>>([]);
    const [selectedCommodityOptions, setSelectedCommodityOptions] = useState<Array<IOptionType>>([]);
    const [selectedVarietyOptions, setSelectedVarietyOptions] = useState<Array<IOptionType>>([]);
    const [selectedOrganizationOption, setSelectedOrganizationOption] = useState<IOptionType | undefined>(undefined);
    const [selectedSiteOption, setSelectedSiteOption] = useState<IOptionType | undefined>(undefined);

    useEffect(()  => {
        // Also checks if indexedDB is available to prevent any unnecessary loading of master data
        if (!!isOpen && !self.indexedDB) {
            if (!self.indexedDB) {
                const getData = async () => {
                    try {
                        setIsLoading(true);
                        const farmResult = await FarmHttpService.getFarmData(undefined, true, true);
                        const commResult = await CommodityHttpService.getCommodityData(true, true);
                        const varResult = await VarietyHttpService.getVarietyData(true, true);
                        setFarms(farmResult.data);
                        setCommodities(commResult.data);
                        setVarieties(varResult.data);
                        setIsLoading(false);
                    } catch (e) {
                        setIsLoading(false);
                        generalShowErrorSnackbar('An error occurred while fetching data.');
                    }
                };
                getData();
            } else {
                setFarms(farmData);
                setCommodities(commodityData);
                setVarieties(varietyData);
            }
        }
    }, [isOpen]);

    const handleDateRangeChange = (start : moment.Moment, end : moment.Moment) => {
        setStartDate(start);
        setEndDate(end);
    };

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true);
                if (!selectedOrganizationOption?.value || !selectedSiteOption?.value) {
                    setBatches([]);
                }else {
                    const batchResult = await BatchHttpService.getMultiBatchReportData({
                        fromDate: formatMomentToDatePicker(startDate),
                        toDate: formatMomentToDatePicker(endDate),
                        farmIds: selectedFarmOptions.map(x => Number(x.value)),
                        commodityIds: selectedCommodityOptions.map(x => Number(x.value)),
                        varietyIds: selectedVarietyOptions.map(x => Number(x.value)),
                        organizationId: Number(selectedOrganizationOption?.value),
                        siteId: Number(selectedSiteOption?.value),
                    });
                    setBatches(batchResult.data);
                    setSelectedBatches([]);
                }
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                generalShowErrorSnackbar('An error occurred while fetching data.');
            }
        };
        getData();
    },[startDate, endDate, selectedCommodityOptions, selectedFarmOptions, selectedVarietyOptions, selectedOrganizationOption, selectedSiteOption]);

    const getFarmOptions = useMemo(() => {
        return farms
            .filter(x => x.isActive)
            .map((x) => {
                return { value: x.id, label: `${x.code} - ${x.name}` };
            }) ?? [];
    }, [farms]);

    const getCommodityOptions = useMemo(() => {
        return commodities
            .filter(x => x.isActive)
            .map((x) => {
                return { value: x.id, label: `${x.code} - ${x.name}` };
            }) ?? [];
    }, [commodities]);

    const getVarietyOptions = useMemo(() => {
        return varieties
            .filter(x => x.isActive && selectedCommodityOptions.some(y => y.value === x.commodityId))
            .map((x) => {
                return { value: x.id, label: `${x.code} - ${x.name}` };
            }) ?? [];
    }, [varieties, selectedCommodityOptions]);

    const getOrganizationOptions = useMemo(() => {
        const result = organizations
            .filter(x => organizationIds?.some(y => y === x.id) && x.isActive)
            .map((x) => {
                return { value: x.id, label: `${x.code} - ${x.name}` };
            }) ?? [];
        if (result.length === 1) {
            setSelectedOrganizationOption(result[0]);
        }
        return result;
    }, [organizations, organizationIds]);

    const getSiteOptions = useMemo(() => {
        const result = sites
            .filter(x => siteIds?.some(y => y === x.id) && x.isActive)
            .map((x) => {
                return { value: x.id, label: `${x.code} - ${x.description}` };
            }) ?? [];
        if (result.length === 1) {
            setSelectedSiteOption(result[0]);
        }
        return result;
    }, [sites, siteIds, organizations, organizationIds]);

    const onFarmsChange = (e : React.ChangeEvent<{}>, newSelectedFarmOptions : Array<IOptionType>) => {
        setSelectedFarmOptions(newSelectedFarmOptions);
    };

    const onCommoditiesChange = (e : React.ChangeEvent<{}>, newSelectedCommodityOptions : Array<IOptionType>) => {
        setSelectedCommodityOptions(newSelectedCommodityOptions);
    };

    const onVarietiesChange = (e : React.ChangeEvent<{}>, newSelectedVarietyOptions : Array<IOptionType>) => {
        setSelectedVarietyOptions(newSelectedVarietyOptions);
    };

    const onOrganizationChange = (e : React.ChangeEvent<{}>, newSelectedOrganizationOption : IOptionType) => {
        setSelectedOrganizationOption(newSelectedOrganizationOption);
    };

    const onSiteChange = (e : React.ChangeEvent<{}>, newSelectedSiteOption : IOptionType) => {
        setSelectedSiteOption(newSelectedSiteOption);
    };

    const isChecked = (id : number) => selectedBatches.some(x => x === id);

    const toggleBatchChecked = (id : number) => {
        const newBatches = [...selectedBatches];
        const index = newBatches.findIndex(x => x === id);
        if (index === -1) {
            setSelectedBatches(addArrayElement(newBatches, id, 'end'));
        } else {
            setSelectedBatches(removeArrayElement(newBatches, index));
        }
    };

    const generateMultiBatchReport = async () => {
        try {
            setIsLoading(true);
            if (!selectedOrganizationOption?.value || !selectedSiteOption?.value) {
                setBatches([]);
            } else {
                const batchResult = await BatchHttpService.getMultiBatchReportRelatedData(selectedBatches);
                const result = batchResult.data;
                if (result) {
                    generateBatchReportPdf(result.stocks,result.packs,result.batches, result.intakes, result.lots);
                }
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            generalShowErrorSnackbar('An error occurred while generating the report.');
        }
    };

    const selectAll = () => {
        if (batches.length === selectedBatches.length) {
            setSelectedBatches([]);
        } else {
            setSelectedBatches(batches.map(x => x.id));
        }
    };

    return <PackmanDialog className='oyh' fullWidth maxWidth={'xl'} isLoading={isLoading} style={{ zIndex: 1050 }} isOpen={isOpen} onClose={onClose} title={'Generate Multi-Batch Report'}>
        <div className={'p20 oyh'}>
            <div className='fdr'>
                <AutocompleteSelect
                    name={'organization'}
                    label={'Organization'}
                    options={getOrganizationOptions}
                    value={selectedOrganizationOption}
                    onChange={onOrganizationChange}
                    isMulti={false}
                    className={'wfill pb10'}>
                </AutocompleteSelect>
                <AutocompleteSelect
                    name={'site'}
                    label={'Site'}
                    options={getSiteOptions}
                    value={selectedSiteOption}
                    onChange={onSiteChange}
                    isMulti={false}
                    className={'wfill pb10'}>
                </AutocompleteSelect>
                <TransactionFilter selectedFromDate={startDate} selectedToDate={endDate} handleDateRangeChange={handleDateRangeChange} onApplyClick={() => null}/>
            </div>
            <div className='fdr'>
                <AutocompleteSelect
                    name={'farms'}
                    label={'Farms'}
                    options={getFarmOptions}
                    value={selectedFarmOptions}
                    onChange={onFarmsChange}
                    isMulti={true}
                    className={'wfill pb10'}>
                </AutocompleteSelect>
                <AutocompleteSelect
                    name={'commodities'}
                    label={'Commodities'}
                    options={getCommodityOptions}
                    value={selectedCommodityOptions}
                    onChange={onCommoditiesChange}
                    isMulti={true}
                    className={'wfill pb10'}>
                </AutocompleteSelect>
                <AutocompleteSelect
                    name={'varieties'}
                    label={'Varieties'}
                    options={getVarietyOptions}
                    value={selectedVarietyOptions}
                    onChange={onVarietiesChange}
                    isMulti={true}
                    className={'wfill pb10'}>
                </AutocompleteSelect>
            </div>
            <div className={'flx1 oys fdc'} style={{ height: 'calc(100vh - 350px)' }}>
                {batches.map(x => <div className='fdr aic'>
                    <Checkbox
                        checked={isChecked(x.id)}
                        disabled={isLoading}
                        onChange={() => toggleBatchChecked(x.id)}
                    /> {x.batchCode} </div>)}
            </div>
            <div className={'flx1 jcfe fdr p10'}>
                <PillButton
                    className={'ml20 h35 w200 reducedPillButtonShadow'}
                    text={((batches.length !== 0) && (selectedBatches.length === batches.length)) ? 'Deselect All' : 'Select All'}
                    color={'secondary'}
                    disabled={isLoading || (batches.length === 0)}
                    onClick={selectAll}
                />
                <PillButton
                    className={'ml20 h35 w200 reducedPillButtonShadow'}
                    text={'Generate'}
                    color={'secondary'}
                    disabled={isLoading || (selectedBatches.length === 0)}
                    onClick={generateMultiBatchReport}
                />
            </div>
        </div>
    </PackmanDialog>;
};

export default BatchReportMultiSelect;

import * as React from 'react';
import CustomTable from '../../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetSeasons } from '../../../../store/masterData/Actions';
import { formatDateTime, upsertArrayElement } from '../../../../services/appFunctionsService';
import { ICommodity } from '../../../../@types/model/masterData/commodity/commodity';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../../store/general/Functions';
import { FormikActions, Formik } from 'formik';
import { createSelector } from 'reselect';
import PackmanDialog from '../../../../components/dialog/PackmanDialog';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../../@types/model/user/right';
import { ISeason, Season } from '../../../../@types/model/masterData/season/season';
import { ISeasonFormValues, SeasonFormValues } from '../../../../@types/model/masterData/season/seasonFormValue';
import SeasonForm from './form/SeasonForm';
import SeasonHttpService from '../../../../services/http/masterData/seasonHttpService';
import ConfirmationPrompt from '../../../../components/dialog/ConfirmationPrompt';
import { setSeasonMasterDataIndexedDB, syncMasterData } from '../../../../services/masterDataSyncService';

interface ISeasonScreenProps extends RouteComponentProps {
    dataSetSeasons : DispatchCall<Array<ISeason>>;
    seasons : Array<ISeason>;
    selectedCommodity ?: ICommodity;
    commodities : Array<ICommodity>;
    auth : IAuthState;
}

interface ISeasonScreenState {
    rows : Array<ISeason>;
    isLoading : boolean;
    selectedSeason ?: ISeason;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingSeason ?: ISeason;
}

class SeasonScreen extends React.Component<ISeasonScreenProps, ISeasonScreenState> {
    constructor(props : ISeasonScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedSeason: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await SeasonHttpService.getSeasonData();

                this.props.dataSetSeasons(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading seasons.');
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    private getCommodityName = (id : number) => {
        const rows = this.props.commodities;
        const obj = rows && rows.find(x => x.id === id);
        return obj ? obj.name : '';
    };

    public onSubmit = async (values : ISeasonFormValues) => {
        this.setLoading(true);

        try {
            const res = await SeasonHttpService.addOrUpdateSeason(new Season(values));

            const newSeasonList = upsertArrayElement(this.props.seasons, res.data, x => x.id === res.data.id) ?? [];

            this.props.dataSetSeasons(newSeasonList);
            await setSeasonMasterDataIndexedDB(newSeasonList);

            if (this.state.selectedSeason) {
                generalShowSuccessSnackbar('Season updated successfully.');
            } else {
                generalShowSuccessSnackbar('Season added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating season data.');
        } finally {
            this.setLoading(false);
        }
    };

    public onReset = async (formValues : ISeasonFormValues, formikActions : FormikActions<ISeasonFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    private openDeleteConfirmationPopup = (season : ISeason) => {
        this.setState({ isDeletePopupOpen: true, deletingSeason: season });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingSeason: undefined });
    };

    private removeSeason = async () => {
        const newSeason = this.state.deletingSeason;
        if (newSeason) {
            newSeason.isActive = false;
            this.setLoading(true);

            try {
                const res = await SeasonHttpService.deleteSeason(newSeason.id);

                const newSeasonList = upsertArrayElement(this.props.seasons, res.data, x => x.id === res.data.id) ?? [];

                this.props.dataSetSeasons(newSeasonList);
                await setSeasonMasterDataIndexedDB(newSeasonList);

                generalShowSuccessSnackbar('Season updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting season.');
                newSeason.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    public getSelectedSeason = (props : ISeasonScreenProps, state : ISeasonScreenState) => state.selectedSeason;
    public getSelectedCommodity = (props : ISeasonScreenProps) => props.selectedCommodity;
    public getCommodities = (props : ISeasonScreenProps) => props.commodities;

    public getInitialFormValues = createSelector(
        [this.getSelectedSeason, this.getCommodities, this.getSelectedCommodity],
        (season : ISeason, commodities : Array<ICommodity>, commodity ?: ICommodity) => {
            return new SeasonFormValues(season, commodities, commodity);
        },
    );

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedSeason: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    private onSeasonEdit = (row : ISeason) => {
        this.setState({
            isDialogOpen: true,
            selectedSeason: row,
        });
    };

    private getRights = (props : ISeasonScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : ISeasonScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const selectedCommodity = this.props.selectedCommodity;
        const rows = selectedCommodity && this.props.seasons ? this.props.seasons.filter(x => x.commodityId === selectedCommodity?.id) : this.props.seasons;
        const initialValues = this.getInitialFormValues(this.props, this.state);

        return (
            <>
                <PackmanDialog
                    title={'Season' + (this.props.selectedCommodity ? ' - (' + this.props.selectedCommodity?.code + ')' : '')}
                    isEdit={!!this.state.selectedSeason}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={SeasonFormValues.formSchema}
                        component={SeasonForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<ISeason>
                            enableAdding={!!this.props.selectedCommodity && this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.onSeasonEdit}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(colour : ISeason) => colour.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Start Date', field: 'startDate', width: 200, type: 'date', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'End Date', field: 'endDate', width: 200, type: 'date', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Commodity', field: 'commodityId', formatFunction: this.getCommodityName, width: 150, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            fitWidthToPage
                            initialSortOrder={[{ columnName: 'id_Id', direction : 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={340}
                            isActive={(row : ISeason) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Season'}
                    open={this.state.isDeletePopupOpen}
                    isLoading={this.state.isLoading}
                    message={'Are you sure you want to delete this season?'}
                    onOkClicked={this.removeSeason}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        seasons: state.masterData.seasons,
        commodities: state.masterData.commodities,
        auth: state.auth,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetSeasons }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SeasonScreen);

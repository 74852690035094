import { IBaseModel } from '../../../model';
import { IOrganizationFormValues } from './organizationFormValues';

export interface IOrganization extends IBaseModel {
    contactInfoIds : Array<number>;
    code : string;
    name : string;
    isSupplier : boolean;
    isClient : boolean;
    canIntake : boolean;
    requiresProject : boolean;
    exporter ?: string;
    billingParty ?: string;
    waybillPrefix ?: string;
    nextWaybillNumber ?: number;
    waybillRangeEnd ?: number;
}

export class Organization implements IOrganization {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public contactInfoIds : Array<number>;
    public code : string;
    public name : string;
    public isSupplier : boolean;
    public isClient : boolean;
    public canIntake : boolean;
    public requiresProject : boolean;
    public exporter ?: string;
    public billingParty ?: string;
    public waybillPrefix ?: string;
    public nextWaybillNumber ?: number;
    public waybillRangeEnd ?: number;

    public constructor(formValues : IOrganizationFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.contactInfoIds = formValues.contactInfos.map(x => Number(x.value));

        this.code = formValues.code;
        this.name = formValues.name;
        this.isSupplier = formValues.isSupplier;
        this.isClient = formValues.isClient;
        this.canIntake = formValues.canIntake;
        this.requiresProject = formValues.requiresProject;
        this.exporter = formValues.exporter;
        this.billingParty = formValues.billingParty;
        this.waybillPrefix = formValues.waybillPrefix;
        this.nextWaybillNumber = formValues.nextWaybillNumber;
        this.waybillRangeEnd = formValues.waybillRangeEnd;
    }
}

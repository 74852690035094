import { ICommodity } from './commodity';
import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { IOrganization } from '../organization/organization';
import { ICommodityConditionRel } from '../commodityConditionRel/commodityConditionRel';
import { ICommodityCondition } from '../commodityCondition/commodityCondition';
import { orderBy } from 'lodash';

export interface ICommodityFormValues {
    id : number;
    guid : string;
    isActive : boolean;

    code : string;
    name : string;
    organizations : Array<IOptionType>;
    exportCode ?: string;
    temperature ?: string;
    temperatureRegime ?: string;
    isStoneFruit ?: boolean;
    isPhytoCompliant : boolean;
    commodityConditions : Array<ICommodityConditionRel>;
    commodityConditionOptions : Array<IOptionType>;
    isImported ?: boolean;
}

export class CommodityFormValues implements ICommodityFormValues {
    public id : number;
    public guid : string;
    public isActive : boolean;

    public code : string;
    public name : string;
    public organizations : Array<IOptionType>;
    public commodityConditions : Array<ICommodityConditionRel>;
    public commodityConditionOptions : Array<IOptionType>;
    public exportCode ?: string;
    public temperature ?: string;
    public temperatureRegime ?: string;
    public isStoneFruit ?: boolean;
    public isPhytoCompliant : boolean;
    public isImported ?: boolean;

    public constructor(
        commodity ?: ICommodity,
        organizations ?: Array<IOrganization>,
        commodityConditions ?: Array<ICommodityCondition>,
    ) {
        this.initializeDefaultValues();
        if (commodity) {
            this.id = commodity.id;
            this.guid = commodity.guid;
            this.code = commodity.code;
            this.name = commodity.name;
            this.temperature = commodity.temperature;
            this.temperatureRegime = commodity.temperatureRegime;
            this.isActive = commodity.isActive;
            this.isStoneFruit = commodity.isStoneFruit;
            this.isPhytoCompliant = commodity.isPhytoCompliant;
            this.isImported = commodity.isImported;

            this.organizations = organizations?.filter(x => commodity.organizationIds.some(y => y === x.id)).map(x => ({
                label: x.name,
                value: x.id,
            })) ?? [];

            this.commodityConditionOptions = orderBy(commodity?.conditions, x => x.order)?.filter(x => !!x.conditionId || x.isSpacer).map((x) => {
                if (x.isSpacer) {
                    return {
                        label: `(${x.order}) <spacer>`,
                        value: -1 * x.order,
                    };
                }
                return {
                    label: `(${x.order}) ${commodityConditions?.find(y => y.id === x.conditionId)?.condition}`,
                    value: x.conditionId ?? 0,
                };
            }) ?? [];

            this.commodityConditions = commodity.conditions ?? [];

            this.exportCode = commodity.exportCode;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.name = '';
        this.isActive = true;
        this.isPhytoCompliant = false;

        this.organizations = [];
        this.commodityConditionOptions = [];
        this.commodityConditions = [];
        this.exportCode = '';
        this.isImported = false;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        temperature: Yup.string().nullable().max(10, 'Cannot be more than 10 characters'),
        temperatureRegime: Yup.string().nullable().max(20, 'Cannot be more than 20 characters'),
        organizations: Yup.array().of(Yup.object()).min(1, 'Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

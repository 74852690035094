import * as Yup from 'yup';
import { IContactUs } from './contactUs';

export interface IContactUsFormValues {
    id : number;
    guid : string;
    name : string;
    email : string;
    moreInfo : string;
    isActive : boolean;
}

export class ContactUsFormValues implements IContactUsFormValues {
    public id : number;
    public guid : string;
    public name : string;
    public email : string;
    public moreInfo : string;
    public isActive : boolean;

    public constructor(contactUs ?: IContactUs) {
        this.initializeDefaultValues();
        if (contactUs) {
            this.id = contactUs.id;
            this.guid = contactUs.guid;
            this.name = contactUs.name;
            this.email = contactUs.email;
            this.moreInfo = contactUs.moreInfo;
            this.isActive = contactUs.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.name = '';
        this.email = '';
        this.moreInfo = '';
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string().required('Required'),
    });
}

import * as React from 'react';
import { IRootState, IAuthState } from '../../@types/redux';
import { connect } from 'react-redux';
import CustomTable, { ICustomTableColumn } from '../../components/datagrid/CustomTable';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { Button, Paper, Table, TableRow, TableCell, Checkbox } from '@mui/material';
import { dataSetBatch, dataSetBatchScreenData, dataSetBatchView } from '../../store/data/Functions';
import materialTheme from '../../styles/materialTheme';
import ConfirmationPrompt from '../../components/dialog/ConfirmationPrompt';
import { formatDateTime, compareDate, addArrayElement, removeArrayElement } from '../../services/appFunctionsService';
import { ILot } from '../../@types/model/lot/lot';
import { ILotType } from '../../@types/model/masterData/lotType/lotType';
import { IBatch } from '../../@types/model/batch/batch';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import { ISite } from '../../@types/model/masterData/site/site';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import BatchHttpService from '../../services/http/batch/batchHttpService';
import PillButton from '../../components/input/PillButton';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import { Form } from 'formik';
import SingleToggleButton from '../../components/input/SingleToggleButton';
import BatchSummaryPopup from './BatchSummaryPopup';
import moment from 'moment';
import Screen from '../../components/Screen';
import TransactionFilter from '../../components/filters/BasicTransactionScreenFilter';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../@types/model/user/right';
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_TIMESTAMP_FROM_API } from '../../appConstants';
import { ListChildComponentProps, FixedSizeList as WindowList } from 'react-window';
import { IBatchView } from '../../@types/model/batch/batchView';
import { navBatch, navPath } from '../../store/nav/Actions';
import { PackmanLink } from '../../components/link/packmanLink';
import BatchReportMultiSelect from './BatchReportMultiSelect';
import { syncMasterData } from '../../services/masterDataSyncService';
import DeleteConfirmationDialog from '../../components/dialog/DeleteConfirmationDialog';

interface IBatchTableProps extends RouteComponentProps {
    lots : Array<ILot>;
    lotTypes : Array<ILotType>;
    batches : Array<IBatchView>;
    varieties : Array<IVariety>;
    farms : Array<IFarm>;
    commodities : Array<ICommodity>;
    orchards : Array<IOrchard>;
    selectedLoadDate : string;
    sites : Array<ISite>;
    organizations : Array<IOrganization>;
    auth : IAuthState;
    selectedDispatchIndex ?: number;
    showOnlyMyDispatches : boolean;
    selectedOrganizationIds : Array<number> ;
    selectedSiteIds : Array<number> ;
}

interface IBatchTableState {
    isLoading : boolean;
    dataFetched : boolean;
    rows : Array<IBatch>;
    columns : Array<ICustomTableColumn>;
    editingBatch ?: IBatch;
    detailsBatchView ?: IBatchView;
    filterOpen : boolean;
    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;
    endMultipleBatchesSelectedFromDate : moment.Moment;
    endMultipleBatchesSelectedToDate : moment.Moment;
    onYesClick : () => void;
    onNoClick : () => void;
    message : string | React.ReactElement;
    showPrompt : boolean;
    openEndMultiBatchesDialog : boolean;
    selectedFilterDate : string | null;
    checkedBatchesToEnd : Array<IBatch>;
    batchesAvailableToEnd : Array<IBatch>;
    isDeleting : boolean;
    deleteReason : string;
    deleteItem ?: IBatchView;
    isBatchReportMultiSelectOpen : boolean;
}

class BatchTable extends React.Component<IBatchTableProps, IBatchTableState> {
    constructor(props : IBatchTableProps) {
        super(props);

        this.state = {
            isLoading: false,
            dataFetched: false,
            rows: [],
            columns: this.getColumns(),
            filterOpen: false,
            selectedFromDate: moment().local().startOf('day').subtract(7, 'days'),
            selectedToDate: moment().local().endOf('day'),
            endMultipleBatchesSelectedFromDate: moment().local().startOf('day').subtract(7, 'days'),
            endMultipleBatchesSelectedToDate: moment().local().endOf('day'),
            onYesClick: () => null,
            onNoClick: () => null,
            message: '',
            showPrompt: false,
            openEndMultiBatchesDialog: false,
            selectedFilterDate: null,
            checkedBatchesToEnd: [],
            batchesAvailableToEnd: [],
            isDeleting: false,
            deleteReason: '',
            deleteItem: undefined,
            isBatchReportMultiSelectOpen: false,
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        try {
            let batchId : number | undefined;
            if (this.props.location.pathname?.includes(':')) {
                batchId = Number(this.props.location.pathname.split(':')[1].split('/')[0]);
            }
            const res = await BatchHttpService.getBatchViews(this.getDate('from'), this.getDate('to'), this.props.selectedSiteIds, !isIndexedDBAvailable, batchId);
            dataSetBatchScreenData(res.data);
            this.setLoading(false);
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving lot data.');
            this.setLoading(false);
        }
    };

    public componentDidUpdate = (prevProps : IBatchTableProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            /* prop changes go here */
            if (prevProps.location.pathname?.includes(':') && !nextProps.location.pathname?.includes(':')) {
                this.closeBatchSummary();
            }
            if (!prevProps.location.pathname?.includes(':') && nextProps.location.pathname?.includes(':')) {
                this.showBatchSummary(Number(nextProps.location.pathname.split(':')[1].split('/')[0]));
            }
            if (prevProps.location.pathname?.includes(':') && nextProps.location.pathname?.includes(':')) {
                const prevId = prevProps.location.pathname.split(':')[1].split('/')[0];
                const nextId = nextProps.location.pathname.split(':')[1].split('/')[0];
                if (prevId !== nextId) {
                    this.showBatchSummary(Number(nextId));
                }
            }
            if (nextProps.selectedSiteIds !== undefined && prevProps.selectedSiteIds !== nextProps.selectedSiteIds) {
                this.refreshData();
            }
            if (nextProps.location.pathname?.includes(':') && (nextProps.batches.length > prevProps.batches.length)) {
                this.showBatchSummary(Number(nextProps.location.pathname.split(':')[1].split('/')[0]));
            }
        }
    };

    private getBatches = (props : IBatchTableProps) => props.batches ?? [];
    private getSelectedSites = (props : IBatchTableProps) => props.selectedSiteIds;

    private getRows = createSelector(
        [this.getBatches, this.getSelectedSites],
        (batches, selectedSiteIds) => {
            return batches.filter(x => selectedSiteIds?.some(y => y === x.siteId)).map((x) => {
                return {
                    ...x,
                    hasEnded: this.getEndedBool(x.endedOn?.toString()) === 'Yes' ? true : false,
                };
            });
        },
    );

    private setLoading = (isLoading : boolean = false) => {
        this.setState({ isLoading });
    };

    private getLot = (id : number) => {
        const lot = this.props.lots.find(x => x.id === id);

        return lot;
    };

    private getDate = (type : 'from' | 'to') => {
        switch (type) {
            case 'from':
                return this.state.selectedFromDate.startOf('day').utc().unix() * 1000;
            case 'to':
                return this.state.selectedToDate.endOf('day').utc().unix() * 1000;
        }
    };

    private getMultiBatchEndDate = (type : 'from' | 'to') => {
        switch (type) {
            case 'from':
                return this.state.endMultipleBatchesSelectedFromDate.startOf('day').utc().unix() * 1000;
            case 'to':
                return this.state.endMultipleBatchesSelectedToDate.endOf('day').utc().unix() * 1000;
        }
    };

    private getOrganizationName = (id : number) => {
        const rows = this.props.organizations;
        const obj = rows && rows.find(x => x.id === id);
        return obj ? obj.name : '';
    };

    private getSiteDescription = (id : number) => {
        const rows = this.props.sites;
        const obj = rows && rows.find(x => x.id === id);
        return obj ? obj.description : '';
    };

    private getFarmCodeList = (farmCodes : string) => {
        if (!farmCodes) return '';
        const farms : Array<{ code : string; name : string }> = farmCodes.split(', ').map((x) => {
            const farm : Array<string> = x.split(' - ');
            return {
                code: farm[0],
                name: farm[1],
            };
        });
        return farms.map(x => x.code).toString();
    };

    private getOrchardCodeList = (orchardCodes : string) => {
        if (!orchardCodes) return '';
        return '(' + orchardCodes.replace(/, /g, ',\n(',).replace(/ - /g, ') ');
    };

    private getCommodityCodeList = (commoditCodes : string) => {
        if (!commoditCodes) return '';
        const commodities : Array<{ code : string; name : string }> = commoditCodes.split(', ').map((x) => {
            const commodity : Array<string> = x.split(' - ');
            return {
                code: commodity[0],
                name: commodity[1],
            };
        });
        return commodities.map(x => x.code).toString();
    };

    private getVarietyCodeList = (varietyCodes : string) => {
        if (!varietyCodes) return '';
        const varieties : Array<{ code : string; name : string }> = varietyCodes.split(', ').map((x) => {
            const variety : Array<string> = x.split(' - ');
            return {
                code: variety[0],
                name: variety[1],
            };
        });
        return varieties.map(x => x.code).toString();
    };

    private refreshData = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        try {
            let batchId : number | undefined;
            if (this.props.location.pathname?.includes(':')) {
                batchId = Number(this.props.location.pathname.split(':')[1].split('/')[0]);
            }
            const res = await BatchHttpService.getBatchViews(this.getDate('from'), this.getDate('to'), this.props.selectedSiteIds, !isIndexedDBAvailable, batchId);

            dataSetBatchScreenData(res.data);

            this.setLoading(false);
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving batch data.');
            this.setLoading(false);
        }
    };

    private submitEndBatch = async (row ?: IBatchView) => {
        this.setLoading(true);
        if (row) {
            try {
                const res = await BatchHttpService.endBatch(row.id);

                if (res && res.data) {
                    if (res.data) {
                        dataSetBatchView(res.data);
                    }

                    this.closeConfirmationPrompt();
                }

                this.setLoading(false);
                generalShowSuccessSnackbar('Batch ended!');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred ending the batch.');
                this.setLoading(false);
            }
        }
    };

    private submitEndMultipleBatches = async () => {
        this.setLoading(true);
        if (this.state.checkedBatchesToEnd.length > 0) {
            try {
                const batchIds = this.state.checkedBatchesToEnd.map(x => x.id);
                const res = await BatchHttpService.endMultiBatch(batchIds);

                if (res && res.data) {
                    if (res.data) {
                        res.data.forEach(x => dataSetBatchView(x));
                    }

                    this.closeEndMultiBatchesDialog();
                }

                this.setLoading(false);
                generalShowSuccessSnackbar('Batches ended succesfully!');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred ending these batches.');
                this.closeEndMultiBatchesDialog();
                this.setLoading(false);
            }
        }
    };

    private openBatchReportMultiSelect = () => this.setState({ isBatchReportMultiSelectOpen: true });
    private closeBatchReportMultiSelect = () => this.setState({ isBatchReportMultiSelectOpen: false });

    private submitResumeBatch = async (row ?: IBatchView) => {
        this.setLoading(true);
        if (row) {
            try {
                const res = await BatchHttpService.resumeBatch(row.id);

                if (res && res.data) {
                    if (res.data) {
                        dataSetBatchView(res.data);
                    }

                    this.closeConfirmationPrompt();
                }

                this.setLoading(false);
                generalShowSuccessSnackbar('Batch resumed!');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred resuming the batch.');
                this.setLoading(false);
            }
        }
    };

    private showConfirmationPrompt = (onYesClick : () => void, onNoClick : () => void, message : string | React.ReactElement) => {
        this.setState({ onYesClick, onNoClick, message }, () => {
            this.setState({ showPrompt: true });
        });
    };

    private closeConfirmationPrompt = () => {
        this.setState({ onYesClick: () => null, onNoClick: () => null, message: '', showPrompt: false });
    };

    private clearEditingState = () => this.setState({ editingBatch: undefined });
    private closeDeleteConfirmationPopup = () => this.setState({ isDeleting: false, deleteItem: undefined, deleteReason: '' });

    private toggleEditingIsActive = () => {
        const editingBatch = this.state.editingBatch && { ...this.state.editingBatch };
        if (editingBatch) {
            editingBatch.isActive = !editingBatch.isActive;
            this.setState({ editingBatch });
        }
    };

    private submitEditingBatch = async () => {
        if (this.state.editingBatch) {
            const oldBatch = { ...this.state.editingBatch };
            const newBatch = { ...this.state.editingBatch };
            dataSetBatch(newBatch);
            newBatch.updatedOn = undefined;
            this.setLoading(true);

            try {
                const res = await BatchHttpService.addOrUpdateBatch(newBatch);

                if (res && res.data) {
                    if (res.data) {
                        dataSetBatch(res.data);
                    }
                    generalShowSuccessSnackbar('Batch successfully updated.');
                    this.setLoading(false);
                } else {
                    generalShowErrorSnackbar('An error occurred updating the Batch.');
                    dataSetBatch(oldBatch);
                    this.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred updating the Batch.');
                dataSetBatch(oldBatch);
                this.setLoading(false);
            }
        }
        this.setState({ editingBatch: undefined });
    };

    private getEndedBool = (endedOn ?: string) => !!endedOn ? 'Yes' : 'No';

    private formatLotId = (lotId : number) => {
        return <PackmanLink
            type={'transactions'}
            targetPage={'lot'}
            id={Number(lotId)}
            text={`${lotId}`} />;
    };

    private formatLotName = (lotName : string, row : IBatchView) => {
        return <PackmanLink
            type={'transactions'}
            targetPage={'lot'}
            id={Number(row.lotId)}
            text={lotName} />;
    };

    private getColumns = () => {
        const columns : Array<ICustomTableColumn> = [
            { title: 'End/Resume', field: 'Ended?', width: 160, disableExport: true,
                containerComponent: (row : IBatchView) => {
                    const batchLot = this.getLot(row.lotId);
                    return (
                        (!row.isActive || (!!row.endedOn && batchLot && batchLot.isFinalized))
                            ?
                            <div></div>
                            :
                            <div>
                                <PillButton
                                    className={'pl10 pr10 h35 zi0 w140 reducedPillButtonShadow'}
                                    size='medium'
                                    text={`${row.endedOn ? 'Resume' : 'End'} Batch`}
                                    type={'submit'}
                                    color={'secondary'}
                                    disabled={!row.isActive}
                                    onClick={row.endedOn ?
                                        () => this.showConfirmationPrompt(
                                            () => this.submitResumeBatch(row),
                                            this.closeConfirmationPrompt,
                                            <div className={'fdc aic jcc'}>
                                                <div className={'mb5'}>{'Are you sure you want to resume this batch?'}</div>
                                                <div>{row.batchCode}</div>
                                            </div>)
                                        : () => this.showConfirmationPrompt(
                                            () => this.submitEndBatch(row),
                                            this.closeConfirmationPrompt,
                                            <div className={'fdc aic jcc'}>
                                                <div className={'mb5'}>{'Are you sure you want to end this batch?'}</div>
                                                <div>{row.batchCode}</div>
                                            </div>)}
                                />
                            </div>
                    );
                } },
            { title: 'Id', field: 'id', enableFiltering: true, enableSorting: true },
            { title: 'Batch Code', field: 'batchCode', enableFiltering: true, enableSorting: true },
            { title: 'Ended?', field: 'hasEnded', formatFunction: this.getEndedBool, enableFiltering: true, enableSorting: true },
            { title: 'Started On', field: 'startedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Ended On', field: 'endedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Organization', field: 'organizationId', formatFunction: this.getOrganizationName, enableFiltering: true, enableSorting: true },
            { title: 'Site', field: 'siteId', formatFunction: this.getSiteDescription, enableFiltering: true, enableSorting: true },
            { title: 'Lot Id', field: 'lotId', containerComponent: (row : IBatchView, value : number) => this.formatLotId(value), enableFiltering: true, enableSorting: true },
            { title: 'Lot Name', field: 'lotName', containerComponent: (row : IBatchView, value : string) => this.formatLotName(value, row), enableFiltering: true, enableSorting: true },
            { title: 'Farms', field: 'farmCodes', formatFunction: this.getFarmCodeList, enableFiltering: true, enableSorting: true },
            { title: 'Orchards', field: 'orchardCodes', formatFunction: this.getOrchardCodeList, enableFiltering: true, enableSorting: true },
            { title: 'Commodities', field: 'commodityCodes', formatFunction: this.getCommodityCodeList, enableFiltering: true, enableSorting: true },
            { title: 'Varieties', field: 'varietyCodes', formatFunction: this.getVarietyCodeList, enableFiltering: true, enableSorting: true },
            { title: 'Comment', field: 'batchComment', width: 250, enableFiltering: true, enableSorting: true },
            { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
            { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
            { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
        ];
        return columns;
    };

    private handleDateRangeChange = (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => {
        const selectedFromDate = changedBy === 'start' ? moment(start).startOf('day') : end < start ? moment(end).startOf('day') : moment(start).startOf('day');
        const selectedToDate = changedBy === 'end' ? moment(end).endOf('day') : start > end ? moment(start).endOf('day') : moment(end).endOf('day');
        this.setState({ selectedFromDate, selectedToDate });
    };

    private handleEndMultipleBatchesDateRangeChange = (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => {
        const selectedFromDate = changedBy === 'start' ? moment(start).startOf('day') : end < start ? moment(end).startOf('day') : moment(start).startOf('day');
        const selectedToDate = changedBy === 'end' ? moment(end).endOf('day') : start > end ? moment(start).endOf('day') : moment(end).endOf('day');
        this.setState({ endMultipleBatchesSelectedFromDate: selectedFromDate, endMultipleBatchesSelectedToDate: selectedToDate });
    };

    private openEndMultiBatchesDialog = async () => {
        this.setState({ openEndMultiBatchesDialog: true }, async () => {
            try {
                this.setLoading(true);
                const res = await BatchHttpService.getBatchesToEnd(this.getMultiBatchEndDate('from'), this.getMultiBatchEndDate('to'), this.props.selectedSiteIds);

                if (res && res.data) {
                    this.setState({ batchesAvailableToEnd: res.data });
                }

            } catch (e) {
                generalShowErrorSnackbar('Failed to load batches');
            } finally {
                this.setLoading(false);
            }
        });
    };

    private generateMultiBatchReport = async () => {
        this.openBatchReportMultiSelect();
    };

    private closeEndMultiBatchesDialog = () => {
        this.setState({ openEndMultiBatchesDialog: false, checkedBatchesToEnd: [] });
    };

    private getBatchesAvailableToEnd = (props : IBatchTableProps, state : IBatchTableState) => state.batchesAvailableToEnd;

    private getBatchToEnd = createSelector(
        [this.getBatchesAvailableToEnd],
        (batches : Array<IBatch>) => {
            if (!batches) return [];

            return batches.filter(x => x.isActive && !x.endedOn);
        },
    );

    private handleCheckboxChecked = (batch : IBatch, selected : boolean) => {
        if (selected) {
            const index = this.state.checkedBatchesToEnd.findIndex(x => x.id === batch.id);

            if (index === -1) {
                this.setState(prevState => ({ checkedBatchesToEnd: addArrayElement(prevState.checkedBatchesToEnd, batch) }));
            }
        } else {
            const index = this.state.checkedBatchesToEnd.findIndex(x => x.id === batch.id);
            if (index !== -1) {
                this.setState(prevState => ({ checkedBatchesToEnd: removeArrayElement(prevState.checkedBatchesToEnd, index) }));
            }
        }
    };

    private handleSelectAllBatchesToEnd = () => {
        const batchesAvailableToEnd = this.state.batchesAvailableToEnd;
        const batchesAlreadyChecked = this.state.checkedBatchesToEnd;

        if  (batchesAvailableToEnd.length !== batchesAlreadyChecked.length) {
            batchesAvailableToEnd.forEach((batch) => {
                const index = batchesAlreadyChecked.findIndex(x => x.id === batch.id);

                if (index === -1) {
                    this.setState(prevState => ({ checkedBatchesToEnd: addArrayElement(prevState.checkedBatchesToEnd, batch) }));
                }
            });
        } else {
            this.setState({ checkedBatchesToEnd: [] });
        }
    };

    private refreshBatchesAvailableToEnd = async () => {
        try {
            this.setLoading(true);
            const res = await BatchHttpService.getBatchesToEnd(this.getMultiBatchEndDate('from'), this.getMultiBatchEndDate('to'), this.props.selectedSiteIds);

            if (res && res.data) {
                this.setState({ batchesAvailableToEnd: res.data });
            }
        } catch (e) {
            generalShowErrorSnackbar('Failed to load batches');
        } finally {
            this.setLoading(false);
        }
    };

    private setDeleteItem = (item ?: IBatchView) => {
        this.setState({
            deleteItem: item,
            isDeleting: true,
        });
    };

    private removeBatch = async (deleteReason : string) => {
        const newBatch = this.state.deleteItem;

        if (newBatch) {
            if (deleteReason !== '' && deleteReason.length >= 200) {
                this.setLoading(true);

                try {
                    const res = await BatchHttpService.deleteBatch(newBatch.id, deleteReason);

                    if (res && res.data) {
                        dataSetBatchView(res.data);
                    }

                    generalShowSuccessSnackbar('Batch updated successfully.');
                } catch (e) {
                    if (e.data && e.data !== '') {
                        generalShowErrorSnackbar(e.data);
                    } else {
                        generalShowErrorSnackbar('Error occured while attempting to delete batch');
                    }
                } finally {
                    this.closeDeleteConfirmationPopup();
                    this.setLoading(false);
                }
            } else {
                generalShowErrorSnackbar('Reason for deleting this Batch must be at least 200 characters.');
            }

        }
    };

    private showBatchSummary = async (id : number) => {
        this.setLoading(true);
        const selectedBatchView = this.props.batches.find(x => x.id === id);
        this.setState({ detailsBatchView: selectedBatchView }, () => this.setLoading(false));
    };

    private updateDetailsBatchView = async (detailsBatchView : IBatchView) => {
        this.setState({ detailsBatchView }, () => this.setLoading(false));
    };

    private closeBatchSummary = () => {
        this.setState({ detailsBatchView: undefined }, () => navPath(this.props.location?.pathname?.split('/:')[0]));
    };

    private getRights = (props : IBatchTableProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IBatchTableProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    private hasAdminEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_ADMIN_EDIT')));

    private hasBatchLotFinalizeRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_FINALIZE_LOT')));

    public render() {
        const batchesAvailableToEnd = this.getBatchToEnd(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading} isScrollable={false} isPadded={false}>
                <div className={'fdc hfill'}>
                    <div className={'fdr aic'}>
                        <PillButton
                            className={'ml20 h35 w200 reducedPillButtonShadow'}
                            text={'End Batches'}
                            color={'secondary'}
                            disabled={this.state.isLoading}
                            onClick={this.openEndMultiBatchesDialog}
                        />
                        <PillButton
                            className={'ml20 h35 w200 reducedPillButtonShadow'}
                            text={'Generate Report'}
                            color={'secondary'}
                            disabled={this.state.isLoading}
                            onClick={this.generateMultiBatchReport}
                        />
                        <div className='flx1'/>
                        <TransactionFilter className={'pt10 mt10 mr20 mb10'} selectedFromDate={this.state.selectedFromDate} selectedToDate={this.state.selectedToDate} handleDateRangeChange={this.handleDateRangeChange} onApplyClick={this.refreshData} />
                    </div>
                    <Paper className={'fdc mr20 mt10 mb10 ml20 hfill'}>
                        <CustomTable<IBatchView>
                            pageHeight={270}
                            enableSorting
                            enableFiltering
                            enablePagination
                            pageSizes={[50, 150, 250, 500, 1000]}
                            enableTotalRow
                            enableRefresh
                            enableDetails
                            detailFunction={(row : IBatchView) => navBatch(row.id)}
                            detailIcon ={'info'}
                            detailsColor={materialTheme.palette.primary.main}
                            disableRefreshButton={this.state.isLoading}
                            refreshFunction={this.refreshData}
                            columns={this.state.columns}
                            rows={this.getRows(this.props)}
                            isActive = {(row : IBatchView) => row.isActive}
                            enableDeleting = {(row : IBatchView) => row.isActive && this.hasAdminEditingRight(this.props)}
                            deleteFunction={this.setDeleteItem}
                            initialSortOrder={[{ columnName: 'id_Id', direction : 'desc' }]}
                        />
                    </Paper>
                    <ConfirmationPrompt isLoading={this.state.isLoading} open={this.state.showPrompt} message={this.state.message}
                        onOkClicked={this.state.onYesClick} onCancelClicked={this.state.onNoClick}/>
                    <PackmanDialog
                        isOpen={!!this.state.editingBatch}
                        title={''}
                        isEdit
                        onClose={this.clearEditingState} >
                        <div className={'p20 fdc aic jcc'}>
                            <Form className={'jcc fdc aic wfill'}>
                                <div className={'fdr mb10 wfill'}>
                                    <SingleToggleButton
                                        label={'Active?'}
                                        onChange={this.toggleEditingIsActive}
                                        name=''
                                        value={this.state.editingBatch ? this.state.editingBatch.isActive : false}
                                    />
                                </div>
                            </Form>
                            <div className={'fdr aife jcfe wfill '}>
                                <Button className={'fwb h35'} variant='text' color='primary'
                                    onClick={this.clearEditingState}>
                                        Cancel
                                </Button>
                                <PillButton color={'secondary'} className={'ml15 pl20 pr20 h35'} type={'submit'} text={'Save'} onClick={this.submitEditingBatch}/>
                            </div>
                        </div>
                    </PackmanDialog>
                    {this.state.detailsBatchView &&
                        <PackmanDialog isInfo fullScreen title={'Batch Summary'} isOpen={this.state.detailsBatchView != null} onClose={this.closeBatchSummary}>
                            <BatchSummaryPopup endBatchPressed={this.state.detailsBatchView.endedOn ?
                                () => this.showConfirmationPrompt(
                                    () => this.submitResumeBatch(this.state.detailsBatchView),
                                    this.closeConfirmationPrompt,
                                    <div className={'fdc aic jcc'}>
                                        <div className={'mb5'}>{'Are you sure you want to resume this batch?'}</div>
                                        <div>{this.state.detailsBatchView?.batchCode}</div>
                                    </div>)
                                : () => this.showConfirmationPrompt(
                                    () => this.submitEndBatch(this.state.detailsBatchView),
                                    this.closeConfirmationPrompt,
                                    <div className={'fdc aic jcc'}>
                                        <div className={'mb5'}>{'Are you sure you want to end this batch?'}</div>
                                        <div>{this.state.detailsBatchView?.batchCode}</div>
                                    </div>)}
                            onEndCancelled={this.closeConfirmationPrompt}
                            hasEditingRights={this.hasEditingRight(this.props)}
                            hasBatchLotFinalizeRight={this.hasBatchLotFinalizeRight(this.props)}
                            batchView={this.state.detailsBatchView}
                            refreshData={this.refreshData}
                            updateBatch={this.updateDetailsBatchView}
                            isLoading={this.state.isLoading}
                            setLoading={this.setLoading}/>
                        </PackmanDialog>
                    }
                    {/* Delete Dialog */}
                    { !!this.state.isDeleting &&
                        <DeleteConfirmationDialog
                            isLoading={this.state.isLoading}
                            onSubmit={this.removeBatch}
                            onclose={this.closeDeleteConfirmationPopup}
                            isOpen={!!this.state.isDeleting}
                            title={'Batch'}/>
                    }
                    <PackmanDialog
                        isOpen={!!this.state.openEndMultiBatchesDialog}
                        title={'End Multiple Batches'}
                        isInfo={true}
                        maxWidth={'lg'}
                        onClose={this.closeEndMultiBatchesDialog} >
                        <div className={'p20 fdc w700'}>
                            <div className={'fdr aic'}>
                                <TransactionFilter className={'pt10 mt10 mr20 mb10'}
                                    selectedFromDate={this.state.endMultipleBatchesSelectedFromDate}
                                    selectedToDate={this.state.endMultipleBatchesSelectedToDate}
                                    handleDateRangeChange={this.handleEndMultipleBatchesDateRangeChange}
                                    onApplyClick={this.refreshBatchesAvailableToEnd} />
                                <div className={'flx1'}/>
                                <PillButton
                                    color={'secondary'}
                                    className={'h35'}
                                    text={`${this.state.checkedBatchesToEnd.length < 1 ? 'Select All' : 'Unselect All'}`}
                                    disabled={!!(this.state.batchesAvailableToEnd.length < 1)}
                                    onClick={() => this.handleSelectAllBatchesToEnd()}/>
                            </div>
                            <Table className= {'PaperBorder'}>
                                {/* Headings */}
                                <TableRow className={'fdr bcTableHead'}>
                                    <TableCell className={'flx3 jcc aic p5 bcTableHead h23 cw fs14'}>
                                        <div style={{ width: 50, minWidth: 50 }} className={'aic jcc'}>Select</div>
                                        <div style={{ width: 85, maxWidth: 85 }} className={'aic jcc'}>Id</div>
                                        <div className={'flx1 aic jcc'}>Batch Code</div>
                                        <div className={'flx1 aic jcc'}>Started On</div>
                                        <div className={'flx1 aic jcc'}>Updated On</div>
                                    </TableCell>
                                </TableRow>
                                {/* body */}
                                <WindowList
                                    itemCount={batchesAvailableToEnd.length}
                                    width={660}
                                    height={300}
                                    itemSize={40}
                                    itemData={batchesAvailableToEnd}
                                >
                                    {React.memo(({ data, index, style } : React.PropsWithChildren<ListChildComponentProps>) => {
                                        const batch : IBatch = data[index];
                                        if (!batch) return <div />;
                                        const isChecked : boolean = !!this.state.checkedBatchesToEnd.find(x => x.id === batch.id);
                                        return (
                                            <TableRow style={style} className={`${index % 2 === 0 ? 'bcTableRow2' : 'bcTableRow1'}`}>
                                                <TableCell className={'wfill jcc aic h40 fs14 p0 fw500'}>
                                                    <div style={{ width: 50, minWidth: 50 }} className={'aic jcc'}>
                                                        <Checkbox
                                                            className={'pt0 pb0 pr5 m0'}
                                                            checked={isChecked}
                                                            onChange={(event, checked) => this.handleCheckboxChecked(batch, checked)}
                                                        />
                                                    </div>
                                                    <div style={{ width: 85, maxWidth: 85 }} className={'aic jcc hfill'}>{batch.id}</div>
                                                    <div className={'flx1 aic jcc bl1 hfill'}>{batch.batchCode}</div>
                                                    <div className={'flx1 aic jcc bl1 hfill'}>{moment.utc(batch.startedOn, DATE_FORMAT_TIMESTAMP_FROM_API).local().format(DATE_FORMAT_DEFAULT)}</div>
                                                    <div className={'flx1 aic jcc bl1 hfill'}>{formatDateTime(batch.updatedOn ?? '')}</div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </WindowList>
                            </Table>
                            <div className={'fdr aife jcfe wfill mt10'}>
                                <Button className={'fwb h35'} variant='text' color='primary'
                                    onClick={this.closeEndMultiBatchesDialog}>
                                        Cancel
                                </Button>
                                <PillButton
                                    color={'secondary'}
                                    className={'ml15 pl20 pr20 h35'}
                                    text={'Submit'}
                                    disabled={!!(this.state.checkedBatchesToEnd.length < 1)}
                                    onClick={this.submitEndMultipleBatches}/>
                            </div>
                        </div>
                    </PackmanDialog>
                    { this.state.isBatchReportMultiSelectOpen &&
                        <BatchReportMultiSelect
                            isOpen={this.state.isBatchReportMultiSelectOpen}
                            onClose={this.closeBatchReportMultiSelect}
                            parentStartDate={this.state.selectedFromDate}
                            parentEndDate={this.state.selectedToDate}
                        />
                    }
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        lots: state.data.lots,
        lotTypes: state.masterData.lotTypes,
        batches: state.data.batchViews,
        farms: state.masterData.farms,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        orchards: state.masterData.orchards,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
    };
};

export default connect(
    mapStateToProps,
)(BatchTable);

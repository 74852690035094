import * as React from 'react';
import { Card, Table, TableRow, TableCell, Accordion, AccordionSummary, AccordionDetails, Icon, Tooltip, Switch, Divider, TextField, IconButton, Typography, Checkbox, Button } from '@mui/material';
import { connect } from 'react-redux';
import { Formik, FormikActions } from 'formik';
import { createSelector } from 'reselect';
import DispatchHttpService from '../../services/http/dispatch/dispatchHttpService';
import { dataSetDispatchInstruction, dataSetMultiDispatchWizardData, dataSetTrip } from '../../store/data/Functions';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar, generalShowWarningSnackbar } from '../../store/general/Functions';
import { RouteComponentProps } from 'react-router';
import { DispatchCall, IAuthState, IRootState, RootAction } from '../../@types/redux';
import Screen from '../../components/Screen';
import { IMultiDispatchStockView } from '../../@types/model/stock/multiDispatchStockView';
import { addArrayElement, compareDate, compareNumber, formatDateTime, formatDateTimeToDateOnly, removeArrayElement, upsertArrayElement } from '../../services/appFunctionsService';
import { ISite } from '../../@types/model/masterData/site/site';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { ISize } from '../../@types/model/masterData/size/size';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { ISelectedMultiDispatchStock } from '../../@types/model/stock/selectedMultiDispatchStock';
import { IStock } from '../../@types/model/stock/stock';
import StockHttpService from '../../services/http/stock/stockHttpService';
import PillButton from '../../components/input/PillButton';
import moment from 'moment';
import { IDispatchInstruction } from '../../@types/model/dispatch/dispatchInstruction';
import {  IOptionType } from '../../@types/helper';
import { ICreateInstructedDispatch } from '../../@types/model/dispatch/createInstructedDispatch';
import { DATEPICKER_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT_NO_TIME, DATE_FORMAT_TIMESTAMP_FROM_API, DATE_FORMAT_DEFAULT } from '../../appConstants';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import { ICarrier } from '../../@types/model/masterData/carrier/carrier';
import { ITruckType } from '../../@types/model/masterData/truckType/truckType';
import { ITrip, Trip } from '../../@types/model/dispatch/trip';
import { ITripFormValues, TripFormValues } from '../../@types/model/dispatch/tripFormValues';
import TripForm from './trip/form/TripForm';
import TripHttpService from '../../services/http/trip/tripHttpService';
import { ITemperatureUnit } from '../../@types/model/dispatch/temperatureUnit';
import { IMultiDispatchWizardFinalSubmit } from '../../@types/model/dispatch/multiDispatchWizardFinalSubmit';
import { IDispatchDrafts } from '../../@types/model/dispatch/dispatchDrafts';
import { ICreateDispatchDraft } from '../../@types/model/dispatch/createDispatchDraft';
import randomColor from 'randomcolor';
import { dataSetMultiDispatchStockViews, dataSetDispatchInstructions, dataSetStocks, dataSetCompliances, dataSetTrips, dataSetEditingTrip, dataSetDispatchInstructionStocks } from '../../store/data/Actions';
import { Dispatch, bindActionCreators } from 'redux';
import ConfirmationPrompt from '../../components/dialog/ConfirmationPrompt';
import { IMarket } from '../../@types/model/masterData/market/market';
import { IRegion } from '../../@types/model/masterData/region/region';
import { IStockLine } from '../../@types/model/stock/stockLine';
import { IDispatchInstructionLine } from '../../@types/model/dispatch/dispatchInstructionLine';
import { getOrganizationLocalStorage, getSiteLocalStorage } from '../../services/localStorageService';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import ComplianceHttpService from '../../services/http/compliance/complianceHttpService';
import { ICompliance } from '../../@types/model/compliance/compliance';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import CustomTable from '../../components/table/CustomTable';
import TransactionFilter from '../../components/filters/BasicTransactionScreenFilter';
import lodash, { uniq } from 'lodash';
import { ISalesPoint } from '../../@types/model/masterData/salesPoint/salesPoint';
import CustomTooltip from '../../components/tooltip/tooltip';
import { IStorageUnit } from '../../@types/model/masterData/storageUnit/storageUnit';
import materialTheme from '../../styles/materialTheme';
import DeleteConfirmationDialog from '../../components/dialog/DeleteConfirmationDialog';

interface IMultiDispatchWizardExportProps extends RouteComponentProps {
    dataSetMultiDispatchStockViews : DispatchCall<Array<IMultiDispatchStockView>>;
    dataSetDispatchInstructions : DispatchCall<Array<IDispatchInstruction>>;
    dataSetStocks : DispatchCall<Array<IStock>>;
    dataSetCompliances : DispatchCall<Array<ICompliance>>;
    dataSetTrips : DispatchCall<Array<ITrip>>;
    dataSetDispatchInstructionStocks : DispatchCall<Array<IStock>>;
    dataSetEditingTrip : DispatchCall<boolean>;
    auth : IAuthState;
    stockViewData : Array<IMultiDispatchStockView>;
    organizations : Array<IOrganization>;
    sites : Array<ISite>;
    packs : Array<IPack>;
    sizes : Array<ISize>;
    grades : Array<IGrade>;
    colours : Array<IColour>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    farms : Array<IFarm>;
    salesPoints : Array<ISalesPoint>;
    selectedOrganizationIds : Array<number>;
    selectedSiteIds : Array<number>;
    carriers : Array<ICarrier>;
    truckTypes : Array<ITruckType>;
    markets : Array<IMarket>;
    regions : Array<IRegion>;
    stocks : Array<IStock>;
    trips : Array<ITrip>;
    dispatchInstructions : Array<IDispatchInstruction>;
    compliances : Array<ICompliance>;
    storageUnits : Array<IStorageUnit>;
}

interface IMultiDispatchWizardExportState {
    isLoading : boolean;
    selectedStockRows : Array<ISelectedMultiDispatchStock>;
    selectedStockRow ?: IMultiDispatchStockView;
    availableStockSelected : Array<IMultiDispatchStockView>;
    selectedStocks : Array<IStock>;
    existingTrips : Array<ITrip>;
    stockId ?: number;
    isExpanded : boolean;
    selectedRow ?: number;
    isRowExpanded : boolean;
    selectedTargetOrganization ?: IOptionType;
    selectedDistinationSite ?: IOptionType;
    dispatchDrafts : Array<IDispatchInstruction>;
    createdDrafts : Array<IDispatchInstruction>;
    isTripEditFormOpen : boolean;
    selectedTrip ?: ITrip;
    selectedExistingTrip ?: ITrip;
    showMixedStock : boolean;
    editTrip : boolean;
    isStockAmountDialogOpen : boolean;
    amountOfStock ?: number;
    maxAmountOfStock ?: number;
    isDeletePopupOpen : boolean;
    deletingDispatch ?: IDispatchInstruction;
    selectedDispatches : Array<IDispatchInstruction>;
    selectedCompliance ?: IOptionType;
    selectedStorageUnit ?: IOptionType;
    selectedMarkets : Array<IOptionType>;
    selectedCommodity ?: IOptionType;
    selectedVariety ?: IOptionType;
    selectedPack ?: IOptionType;
    selectedSize ?: IOptionType;
    selectedGrade ?: IOptionType;
    editingDispatch ?: IDispatchInstruction;
    selectedDispatchLinesToRemove ?: IDispatchInstructionLine;
    isRemoveStockConfirmDialogOpen : boolean;
    selectedSites : Array<ISite>;
    tempSelectedSites : Array<ISite>;
    isFilterSitesPopupOpen : boolean;
    isExistingTripPopupOpen : boolean;
    initialStockRows : Array<IMultiDispatchStockView>;

    showMixedSalesPointErrorDialog : boolean;
    showMixedSalesPointSplitWarning : boolean;
    showDestinationSiteWarningPopup : boolean;

    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;
    selectedTempExistingTrip ?: ITrip;

    stocksWithMixedSalesPoints : Array<IStock>;
}

class MultiDispatchWizardExport extends React.Component<IMultiDispatchWizardExportProps, IMultiDispatchWizardExportState> {
    constructor(props : IMultiDispatchWizardExportProps) {
        super(props);

        this.state = {
            isLoading: false,
            isExpanded: false,
            isRowExpanded: false,
            selectedStockRows: [],
            availableStockSelected: [],
            selectedStocks: [],
            dispatchDrafts: [],
            isTripEditFormOpen: false,
            showMixedStock: false,
            editTrip: false,
            isStockAmountDialogOpen: false,
            isDeletePopupOpen: false,
            selectedDispatches: [],
            createdDrafts: [],
            selectedMarkets: [],
            isRemoveStockConfirmDialogOpen: false,
            selectedSites: [],
            tempSelectedSites: [],
            isFilterSitesPopupOpen: false,
            isExistingTripPopupOpen: false,
            existingTrips: [],
            initialStockRows: [],

            showMixedSalesPointErrorDialog: false,
            showMixedSalesPointSplitWarning: false,
            showDestinationSiteWarningPopup: false,
            selectedFromDate: moment().local(),
            selectedToDate: moment().local(),

            stocksWithMixedSalesPoints: [],
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        try {
            const selectedOrganizationIds = getOrganizationLocalStorage();
            const selectedSiteIds = getSiteLocalStorage();
            const res = await DispatchHttpService.getMultiDispatchWizardExportRelatedData(false, selectedSiteIds);
            const res2 = await DispatchHttpService.getDispatchDrafts(selectedSiteIds);
            const res3 = await ComplianceHttpService.getMultiDispatchWizardComplianceData(selectedSiteIds);

            dataSetMultiDispatchWizardData(res.data);

            this.props.dataSetStocks(res2.data.stocks);
            this.props.dataSetCompliances(res3.data);

            const rows = res.data.stocks.filter(x => !!x.mainStockLine.packId && !!x.mainStockLine.sizeId && !!x.mainStockLine.gradeId);
            let SitesWithStock : Array<IMultiDispatchStockView> = [];
            rows.filter(x => !!x.barcode && x.barcode !== '').forEach((stock) => {
                const index = SitesWithStock.findIndex(x => x.currentSiteId === stock.currentSiteId);

                if (index === -1) {
                    stock.numberOfSimilarStock = 1;
                    SitesWithStock = addArrayElement(rows, stock);
                } else {
                    const row = { ...SitesWithStock[index] };
                    row.numberOfSimilarStock = (row.numberOfSimilarStock ?? 0) + 1;
                }
            });

            const sitesFiltered = res.data.sites.filter(x => (SitesWithStock.length > 0 ? SitesWithStock.some(y => y.currentSiteId === x.id) : true) && x.isActive && x.organizationIds?.some(y => selectedOrganizationIds?.some(z => z === y)));

            const sitesFilteredBySelectedSiteIds = sitesFiltered.filter(x => selectedSiteIds?.some(y => y === x.id));

            const initialStockRows = res.data.stocks.filter(x => this.props.sites.some(y => y.id === x.currentSiteId));

            this.setState({ dispatchDrafts: res2.data.dispatches, selectedSites: sitesFilteredBySelectedSiteIds, initialStockRows });
            this.props.dataSetDispatchInstructions(res2.data.dispatches);
            this.props.dataSetTrips(res2.data.trips);
            this.setLoading(false);
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading data.');
            this.setLoading(false);
        }
    };

    public componentDidUpdate = (prevProps : IMultiDispatchWizardExportProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            if (nextProps.selectedSiteIds !== undefined && prevProps.selectedSiteIds !== nextProps.selectedSiteIds) {
                this.setState({ selectedDispatches: [], selectedStocks: [], selectedStockRows: [], selectedRow: undefined });
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private refreshData = async (showMixed : boolean, noAnnounce ?: boolean) => {
        this.setLoading(true);
        try {
            const res = await DispatchHttpService.getMultiDispatchWizardExportRelatedData(showMixed, this.state.selectedSites.map(x => x.id));
            const res2 = await DispatchHttpService.getDispatchDrafts(this.state.selectedSites.map(x => x.id));
            const res3 = await ComplianceHttpService.getMultiDispatchWizardComplianceData(this.state.selectedSites.map(x => x.id));

            const initialStockRows = res.data.stocks.filter(x => this.props.sites.some(y => y.id === x.currentSiteId));

            dataSetMultiDispatchWizardData(res.data);

            this.props.dataSetStocks(res2.data.stocks);
            this.props.dataSetDispatchInstructions(res2.data.dispatches);
            this.props.dataSetCompliances(res3.data);
            this.setState({ dispatchDrafts: res2.data.dispatches, initialStockRows });

            this.setLoading(false);
            if (!noAnnounce) {
                generalShowSuccessSnackbar('Data Refreshed');
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading data.');
            this.setLoading(false);
        }
    };

    private refreshAvailableStockData = async (noAnnounce ?: boolean, marketIds ?: Array<number>, selectedPackId ?: number, selectedSizeId ?: number, selectedGradeId ?: number, selectedCommodityId ?: number, selectedVarietyId ?: number, selectedComplianceId ?: number, selectedStorageUnitId ?: number) => {
        this.setLoading(true);
        try {
            const showMixed = this.state.showMixedStock;
            const res = await DispatchHttpService.refreshMultiDispatchWizardExportData(showMixed,
                marketIds,
                selectedPackId,
                selectedSizeId,
                selectedGradeId,
                selectedCommodityId,
                selectedVarietyId,
                this.state.selectedSites.map(x => x.id),
                selectedComplianceId,
                selectedStorageUnitId);

            const initialStockRows = res.data.filter(x => this.props.sites.some(y => y.id === x.currentSiteId));

            this.props.dataSetMultiDispatchStockViews(res.data);
            this.setState({ initialStockRows });
            this.setLoading(false);
            if (!noAnnounce) {
                generalShowSuccessSnackbar('Available Stocks Refreshed');
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while refreshing available stock data.');
            this.setLoading(false);
        }
    };

    private refreshDispatchDrafts = async (noAnnounce ?: boolean) => {
        this.setLoading(true);
        try {
            const res = await DispatchHttpService.getDispatchDrafts(this.state.selectedSites.map(x => x.id));

            this.props.dataSetStocks(res.data.stocks);
            this.props.dataSetDispatchInstructions(res.data.dispatches);
            this.props.dataSetTrips(res.data.trips);

            this.setState({ dispatchDrafts: res.data.dispatches });
            this.setLoading(false);
            if (!noAnnounce) {
                generalShowSuccessSnackbar('Drafts Refreshed');
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading dispatch drafts data.');
            this.setLoading(false);
        }
    };

    private openStockAmountPopup = (selectedStockRow : IMultiDispatchStockView, stocks : Array<IMultiDispatchStockView>) => {
        const selectedStocks = this.state.selectedStocks;
        let availableStocks : Array<IMultiDispatchStockView> = [];

        if (stocks.some(x => x.currentSiteId === this.state.selectedDistinationSite?.value)) {
            generalShowErrorSnackbar('Stocks is already at the selected destination site');
            return;
        }

        stocks.forEach((x) => {
            const stock = selectedStocks.find(y => x.id === y.id);
            if (!stock) {
                const index = availableStocks.findIndex(y => y.id === x.id);
                if (index === -1) {
                    availableStocks = addArrayElement(availableStocks, x);
                }
            }
        });

        if (stocks.length === 1) {
            this.setState({ selectedStockRow, availableStockSelected: stocks, maxAmountOfStock: stocks.length, amountOfStock: stocks.length }, () => {
                this.getStocks();
            });
        } else {
            this.setState({ isStockAmountDialogOpen: true, selectedStockRow, availableStockSelected: availableStocks, amountOfStock: availableStocks.length, maxAmountOfStock: availableStocks.length });
        }
    };

    private getAllAvailableStock = async () => {
        const rowsFilteredByPackSizeCode = this.getRowsFilteredByPackSizeGrade(this.props, this.state);
        const rowSites = this.getRowsFilteredBySites(this.props);
        const sites = this.props.sites.filter(x => rowSites?.some(y => y.currentSiteId === x.id));

        const dispatchDrafts = [...this.state.dispatchDrafts];

        let dispatchDraftStocks : Array<number> = [];

        dispatchDrafts.forEach((dispatch) => {
            dispatch.dispatchLines.forEach((line) => {
                const index = dispatchDraftStocks.findIndex(x => x === line.currentStockId);
                if (line.isActive && index === -1) {
                    dispatchDraftStocks = addArrayElement(dispatchDraftStocks, line.currentStockId);
                }
            });
        });

        let selectedRows : Array<ISelectedMultiDispatchStock> = [...this.state.selectedStockRows];

        let selectedStockIds : Array<number> = [];

        rowsFilteredByPackSizeCode.forEach((row) => {
            sites.filter(site => (this.state.selectedSites.length < 1 || this.state.selectedSites?.some(y => y.id === site.id))).forEach((site) => {
                const stocksAtSite = this.props.stockViewData.filter(z => z.currentSiteId === site.id);
                const stocks = stocksAtSite.filter(x => dispatchDraftStocks.length > 0 ? dispatchDraftStocks.some(y => y !== x.id) : true).filter(stock => row.mainStockLine.packId === stock.mainStockLine.packId
                                                                && row.mainStockLine.sizeId === stock.mainStockLine.sizeId
                                                                && row.mainStockLine.gradeId === stock.mainStockLine.gradeId);

                let data : ISelectedMultiDispatchStock;
                if (stocks.length > 0) {
                    data = {
                        siteId: stocks[0].currentSiteId,
                        packId: row.mainStockLine.packId,
                        sizeId: row.mainStockLine.sizeId,
                        gradeId: row.mainStockLine.gradeId,
                        colourId: row.mainStockLine.colourId,
                        stockIds: stocks.map(x => x.id),
                    };

                    const position = this.state.selectedStockRows.findIndex(x => x.siteId === data.siteId
                                                                                && x.packId === data.packId
                                                                                && x.sizeId === data.sizeId
                                                                                && x.gradeId === data.gradeId
                                                                                && x.colourId === data.colourId
                                                                                && x.stockIds.every(y => data.stockIds?.some(z => y === z)));
                    if (position === -1) {
                        selectedRows = addArrayElement(selectedRows, { ...data });
                    }

                    stocks.forEach((stock) => {
                        const index = selectedStockIds.findIndex(x => x === stock.id);
                        if (index === -1) {
                            selectedStockIds = addArrayElement(selectedStockIds, stock.id);
                        }
                    });
                }
            });
        });

        const stockIds = selectedStockIds.filter(x => !dispatchDraftStocks.some(y => y === x));

        this.setState({ selectedStockRows: selectedRows, stockId: undefined }, async () => {
            if (stockIds.length > 0) {
                this.setLoading(true);
                try {
                    const res = await StockHttpService.getStocks(stockIds);
                    if (res && res.data) {
                        let resStocks : Array<IStock> = [...this.state.selectedStocks];
                        res.data.forEach((x) => {
                            const index = resStocks.findIndex(y => y.id === x.id);
                            if (index === -1) {
                                resStocks = addArrayElement(resStocks, { ...x });
                            }
                        });
                        this.setState({ selectedStocks: resStocks, selectedStockRows: selectedRows, stockId: undefined });
                        this.setLoading(false);
                    }
                } catch (e) {
                    generalShowErrorSnackbar('Failed to load selected stocks data');
                    this.setLoading(false);
                }
            } else {
                generalShowErrorSnackbar('There is no more stock available');
            }
        });
    };

    private closeStockAmountPopup = () => {
        this.setState({ isStockAmountDialogOpen: false, amountOfStock: undefined, maxAmountOfStock: undefined });
    };

    private getStocks = async () => {
        this.setLoading(true);

        const amountOfStockSelected = this.state.amountOfStock;
        const selectedStockRow = this.state.selectedStockRow;
        const stocks = this.state.availableStockSelected;

        const stocksSorted = stocks.sort((z, y) => (this.getAgeInDays(z?.createdOn) < this.getAgeInDays(y?.createdOn)) ? 1 : (this.getAgeInDays(z?.createdOn) > this.getAgeInDays(y?.createdOn)) ? -1 : 0);

        if (selectedStockRow) {
            const data : ISelectedMultiDispatchStock = {
                siteId: stocks[0].currentSiteId,
                packId: selectedStockRow.mainStockLine.packId,
                sizeId: selectedStockRow.mainStockLine.sizeId,
                gradeId: selectedStockRow.mainStockLine.gradeId,
                colourId: selectedStockRow.mainStockLine.colourId,
                stockIds: amountOfStockSelected ? stocksSorted.map(x => x.id).slice(0, amountOfStockSelected) : stocks.map(x => x.id),
            };

            const position = this.state.selectedStockRows.findIndex(x => x.siteId === data.siteId
                                                                        && x.packId === data.packId
                                                                        && x.sizeId === data.sizeId
                                                                        && x.gradeId === data.gradeId
                                                                        && x.colourId === data.colourId
                                                                        && x.stockIds.every(y => data.stockIds?.some(z => y === z)));

            let selectedRows : Array<ISelectedMultiDispatchStock> = [...this.state.selectedStockRows];
            if (position === -1) {
                selectedRows = addArrayElement(this.state.selectedStockRows, { ...data });
            }

            try {
                const res = await StockHttpService.getStocks(data.stockIds);
                if (res && res.data) {
                    let resStocks : Array<IStock> = [...this.state.selectedStocks];
                    res.data.forEach((x) => {
                        const index = resStocks.findIndex(y => y.id === x.id);
                        if (index === -1) {
                            resStocks = addArrayElement(resStocks, { ...x });
                        }
                    });
                    this.setState({ selectedStocks: resStocks, selectedStockRows: selectedRows, stockId: undefined });
                    this.closeStockAmountPopup();
                    this.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar('Failed to load selected stocks data');
                this.closeStockAmountPopup();
                this.setLoading(false);
            }
        }
    };

    private getSiteShortDescription = (id : number) => {
        const site = this.props.sites && this.props.sites.find(x => x.id === id);
        return site && site.shortDescription ? site.shortDescription : 'UNK?';
    };

    private getPack = (id : number) => {
        const pack = this.props.packs.find(x => x.id === id);

        return pack ? pack?.code : '';
    };

    private getSize = (id : number) => {
        const size = this.props.sizes.find(x => x.id === id);

        return size ? size?.code : '';
    };

    private getGrade = (id : number) => {
        const grade = this.props.grades.find(x => x.id === id);

        return grade ? grade?.code : '';
    };

    private getColour = (id : number) => {
        const colour = this.props.colours.find(x => x.id === id);

        return colour ? colour?.code : '';
    };

    private getFarm = (id : number) => {
        const farm = this.props.farms.find(x => x.id === id);

        return farm ? farm?.code : '';
    };

    private getPackSizeGrade = (row : IMultiDispatchStockView) => {
        const pack = this.props.packs.find(x => x.id === row.mainStockLine.packId);
        const size = this.props.sizes.find(x => x.id === row.mainStockLine.sizeId);
        const grade = this.props.grades.find(x => x.id === row.mainStockLine.gradeId);

        return `${pack?.code} ${size?.code} ${grade?.code}`;
    };

    private getCarrierName = (carrierId ?: number) => {
        const carrier = this.props.carriers.find(x => x.id === carrierId);
        return carrier?.name;
    };

    private getTruckTypeCode = (truckTypeId ?: number) => {
        const truckType = this.props.truckTypes.find(x => x.id === truckTypeId);
        return truckType?.code;
    };

    private getDevices = (tempUnits : Array<ITemperatureUnit>) => tempUnits.filter(x => x.isActive).map(x => x.deviceNumber).toString().replace(/,/g, ', ');

    private toggleRowExpand = (id : number) => {
        if (id === this.state.selectedRow) {
            this.setState({ isRowExpanded: !this.state.isRowExpanded }, () => {
                if (this.state.isRowExpanded) {
                    this.setState({ selectedRow: id });
                } else {
                    this.setState({ selectedRow: undefined });
                }
            });
        } else {
            this.setState({ selectedRow: undefined, isRowExpanded: false }, () => {
                this.setState({ isRowExpanded: !this.state.isRowExpanded }, () => {
                    if (this.state.isRowExpanded) {
                        this.setState({ selectedRow: id });
                    } else {
                        this.setState({ selectedRow: undefined });
                    }
                });
            });
        }
    };

    private getOrganizations = (props : IMultiDispatchWizardExportProps) => props.organizations;
    private getSites = (props : IMultiDispatchWizardExportProps) => props.sites;
    private getStockViewData = (props : IMultiDispatchWizardExportProps) => props.stockViewData;
    private getCompliances = (props : IMultiDispatchWizardExportProps) => props.compliances;
    private getStorageUnits = (props : IMultiDispatchWizardExportProps) => props.storageUnits;
    private getStockData = (props : IMultiDispatchWizardExportProps) => props.stocks;
    private getPacks = (props : IMultiDispatchWizardExportProps) => props.packs;
    private getSizes = (props : IMultiDispatchWizardExportProps) => props.sizes;
    private getGrades = (props : IMultiDispatchWizardExportProps) => props.grades;
    private getColourData = (props : IMultiDispatchWizardExportProps) => props.colours;
    private getRegions = (props : IMultiDispatchWizardExportProps) => props.regions;
    private getMarkets = (props : IMultiDispatchWizardExportProps) => props.markets;
    private getCommodities = (props : IMultiDispatchWizardExportProps) => props.commodities;
    private getVarieties = (props : IMultiDispatchWizardExportProps) => props.varieties;
    private getCarriers = (props : IMultiDispatchWizardExportProps) => props.carriers;
    private getSelectedOrganizationIds = (props : IMultiDispatchWizardExportProps) => props.selectedOrganizationIds;
    private getSelectedSiteIds = (props : IMultiDispatchWizardExportProps) => props.selectedSiteIds;
    private getTruckTypes = (props : IMultiDispatchWizardExportProps) => props.truckTypes;
    private getTrips = (props : IMultiDispatchWizardExportProps) => props.trips;

    private getDispatchDrafts = (props : IMultiDispatchWizardExportProps, state : IMultiDispatchWizardExportState) => state.dispatchDrafts;
    private getSelectedRow = (props : IMultiDispatchWizardExportProps, state : IMultiDispatchWizardExportState) => state.selectedRow;
    private getSelectedStocks = (props : IMultiDispatchWizardExportProps, state : IMultiDispatchWizardExportState) => state.selectedStocks;
    private getSelectedStockRows = (props : IMultiDispatchWizardExportProps, state : IMultiDispatchWizardExportState) => state.selectedStockRows;
    private getSelectedTargetOrganization = (props : IMultiDispatchWizardExportProps, state : IMultiDispatchWizardExportState) => state.selectedTargetOrganization;
    private getSelectedDestinationSite = (props : IMultiDispatchWizardExportProps, state : IMultiDispatchWizardExportState) => state.selectedDistinationSite;
    private getSelectedPack = (props : IMultiDispatchWizardExportProps, state : IMultiDispatchWizardExportState) => state.selectedPack;
    private getSelectedCommodity = (props : IMultiDispatchWizardExportProps, state : IMultiDispatchWizardExportState) => state.selectedCommodity;
    private getSelectedVariety = (props : IMultiDispatchWizardExportProps, state : IMultiDispatchWizardExportState) => state.selectedVariety;
    private getSelectedDispatches = (props : IMultiDispatchWizardExportProps, state : IMultiDispatchWizardExportState) => state.selectedDispatches;
    private getExpandedStockId = (props : IMultiDispatchWizardExportProps, state : IMultiDispatchWizardExportState) => state.stockId;
    private getSelectedTripFromState = (props : IMultiDispatchWizardExportProps, state : IMultiDispatchWizardExportState) => state.selectedTrip;
    private getSelectedSites = (props : IMultiDispatchWizardExportProps, state : IMultiDispatchWizardExportState) => state.selectedSites;
    private getSelectedExistingTrip = (props : IMultiDispatchWizardExportProps, state : IMultiDispatchWizardExportState) => state.selectedExistingTrip;
    private getExistingTripSelectedFromDate = (props : IMultiDispatchWizardExportProps, state : IMultiDispatchWizardExportState) => state.selectedFromDate;
    private getExistingTripSelectedToDate = (props : IMultiDispatchWizardExportProps, state : IMultiDispatchWizardExportState) => state.selectedToDate;
    private getExistingTrips = (props : IMultiDispatchWizardExportProps, state : IMultiDispatchWizardExportState) => state.existingTrips;
    private getStocksWithMixedSalesPoints = (props : IMultiDispatchWizardExportProps, state : IMultiDispatchWizardExportState) => state.stocksWithMixedSalesPoints;
    private getInitialStockRows = (props : IMultiDispatchWizardExportProps, state : IMultiDispatchWizardExportState) => state.initialStockRows;

    private getSelectedTrip = createSelector(
        [this.getSelectedTripFromState, this.getSelectedExistingTrip, this.getSelectedDispatches, this.getTrips, this.getExistingTrips],
        (selectedTrip, selectedExistingTrip, selectedDispatches, trips, existingTrips) => {
            const tripIds = uniq(selectedDispatches.map(x => x.tripId));
            if (tripIds.length === 1) {
                return trips.find(x => x.id === tripIds[0]) ?? existingTrips.find(x => x.id === tripIds[0]) ?? selectedTrip ?? selectedExistingTrip;
            }
            return selectedTrip ?? selectedExistingTrip;
        },
    );

    private getRowsFilteredBySites = createSelector(
        [this.getStockViewData],
        (stockViewData) => {
            const rows = stockViewData.filter(x => !!x.mainStockLine.packId && !!x.mainStockLine.sizeId && !!x.mainStockLine.gradeId);
            let filteredRows : Array<IMultiDispatchStockView> = [];
            rows.filter(x => !!x.barcode && x.barcode !== '').forEach((stock) => {
                const index = filteredRows.findIndex(x => x.currentSiteId === stock.currentSiteId);

                if (index === -1) {
                    stock.numberOfSimilarStock = 1;
                    filteredRows = addArrayElement(rows, stock);
                } else {
                    const row = { ...filteredRows[index] };
                    row.numberOfSimilarStock = (row.numberOfSimilarStock ?? 0) + 1;
                }
            });

            return filteredRows;
        },
    );

    private getRowsFilteredByPackSizeGrade = createSelector(
        [this.getStockViewData, this.getSelectedSites],
        (stockViewData, selectedSites) => {
            const rows = stockViewData.filter(x => (selectedSites.length < 1 || selectedSites?.some(y => y.id === x.currentSiteId)) && !!x.mainStockLine.packId && !!x.mainStockLine.sizeId && !!x.mainStockLine.gradeId);
            let filteredRows : Array<IMultiDispatchStockView> = [];
            rows.forEach((stock) => {
                const index = filteredRows.findIndex(x => x.mainStockLine.packId === stock.mainStockLine.packId
                                                            && x.mainStockLine.sizeId === stock.mainStockLine.sizeId
                                                            && x.mainStockLine.gradeId === stock.mainStockLine.gradeId);

                if (index === -1) {
                    stock.numberOfSimilarStock = 1;
                    filteredRows = addArrayElement(filteredRows, stock);
                } else {
                    const row = { ...filteredRows[index] };
                    row.numberOfSimilarStock = (row.numberOfSimilarStock ?? 0) + 1;
                }
            });

            return filteredRows;
        },
    );

    private getColours = createSelector(
        [this.getColourData, this.getStockViewData, this.getSelectedRow],
        (colourData, stockViewData, selectedRowId) => {
            const selectedRow = stockViewData.find(x => x.id === selectedRowId);

            const stocks = stockViewData.filter(x => x.mainStockLine.packId === selectedRow?.mainStockLine.packId
                                                                && x.mainStockLine.sizeId === selectedRow?.mainStockLine.sizeId
                                                                && x.mainStockLine.gradeId === selectedRow?.mainStockLine.gradeId);

            const mainStockLines = stocks.map(x => x.mainStockLine);

            const colours = colourData.filter(x => mainStockLines?.some(y => y.colourId === x.id));
            return colours;
        },
    );

    private getSitesFilteredByOrganization = createSelector(
        [this.getSites, this.getSelectedOrganizationIds],
        (sites, selectedOrganizationIds) => {
            return sites.filter(x => x.organizationIds?.some(y => selectedOrganizationIds?.some(z => z === y)));
        },
    );

    private renderTable = createSelector(
        [this.getStockViewData, this.getDispatchDrafts, this.getSitesFilteredByOrganization, this.getPacks, this.getSizes, this.getGrades, this.getRowsFilteredBySites, this.getRowsFilteredByPackSizeGrade, this.getColours, this.getSelectedSites, this.getSelectedStocks, this.getSelectedRow],
        (stockViewData, dispatchDrafts, siteData, packData, sizeData, gradeData, rowSites, rowsFilteredByPackSizeCode, colours, selectedSites, selectedStocks, selectedRow) => {
            const sites = siteData.filter(x => rowSites?.some(y => y.currentSiteId === x.id));

            const newRowsFilteredByPackSizeCode = rowsFilteredByPackSizeCode.filter(x => sites.some(y => y.id === x.currentSiteId)).map((x) => {
                return { id: x.id, packCode: this.getPack(x.mainStockLine.packId),  gradeCode: this.getGrade(x.mainStockLine.gradeId), sizeCode: this.getSize(x.mainStockLine.gradeId) };
            });

            const newRowsFilteredByPackSizeCodeSorted = newRowsFilteredByPackSizeCode.sort((a, b) => {
                if (a.packCode > b.packCode) {
                    return 1;
                } else if (a.packCode < b.packCode) {
                    return -1;
                } else {
                    if (a.gradeCode > b.gradeCode) {
                        return 1;
                    } else if (a.gradeCode < b.gradeCode) {
                        return -1;
                    } else {
                        if (a.sizeCode > b.sizeCode) {
                            return 1;
                        } else if (a.sizeCode < b.sizeCode) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }
                }
            });

            return (
                <Table className= {'PaperBorder'}>
                    {/* Headings */}
                    <TableRow style={{ top: 55, opacity: 2, backgroundColor: materialTheme.custom.multiDispatchTable.background }}
                        className={'fdr zi999 posstick bb1 bocg3'}
                    >
                        <TableCell className={'w150 fdc jcc aic p0 fw500 bl1 br1 bb1 bocg3'}>
                            {' '}
                        </TableCell>
                        {
                            sites.filter(x => (selectedSites.length < 1 || selectedSites?.some(y => y.id === x.id))).map((x) => {
                                return (
                                    <TableCell
                                        style={{ color: materialTheme.custom.multiDispatchTable.text }}
                                        className={'flx1 fdc jcc aic p0 fw500 bl1 br1 bb1 bocg3'}
                                    >
                                        {x.shortDescription}
                                    </TableCell>
                                );
                            })
                        }
                    </TableRow>
                    <TableRow
                        style={{ top: 77, opacity: 2, color: materialTheme.custom.multiDispatchTable.text, backgroundColor: materialTheme.custom.multiDispatchTable.background }}
                        className={'fdr zi999 posstick bb1 bocg3'}
                    >
                        <TableCell className={'w150 fdc jcc aic p0 fw500 bl1 br1 bb1 bocg3'}>
                            {'Specification'}
                        </TableCell>
                        {
                            sites.filter(x => (selectedSites.length < 1 || selectedSites?.some(y => y.id === x.id))).map(() => {
                                return (
                                    <TableCell className={'flx1 fdc jcc aic p0 fw500 bl1 br1 bb1 bocg3'}>{'Total'}</TableCell>
                                );
                            })
                        }
                    </TableRow>
                    {/* Body */}
                    <div>
                        {
                            newRowsFilteredByPackSizeCodeSorted.map((x, rowIndex) => {
                                const row = rowsFilteredByPackSizeCode.find(y => y.id === x.id);
                                if (row) {
                                    const packCode = packData?.find(y => y.id === row?.mainStockLine.packId)?.code;
                                    const sizeCode = sizeData?.find(y => y.id === row?.mainStockLine.sizeId)?.code;
                                    const gradeCode = gradeData?.find(y => y.id === row?.mainStockLine.gradeId)?.code;

                                    const selectedStockViews = stockViewData.filter(stock => selectedStocks.some(y => y.id === stock.id));

                                    const rowBackgroundColor = (rowIndex % 2 !== 0)
                                        ? materialTheme.custom.multiDispatchTable.row1.default
                                        : materialTheme.custom.multiDispatchTable.row2.default;
                                    return (
                                        <Accordion
                                            key={`stock_id_#${row?.id}`}
                                            className={'fdc wfill op100p m0 p0'}
                                            expanded={x.id === selectedRow}
                                            onChange={() => this.toggleRowExpand(x.id)}
                                        >
                                            <AccordionSummary style={{ minWidth: 50, backgroundColor: rowBackgroundColor }} className={'h50 p0 m0'}>
                                                <TableRow className={'fdr wfill h50'}>
                                                    <TableCell style={{ color: materialTheme.custom.multiDispatchTable.text }} className={'w150 h50 fdc jcc aic p0 m0 fw500 bl1 br1 bb1 bocg3'}>
                                                        {`${packCode} ${gradeCode} ${sizeCode}`}
                                                    </TableCell>
                                                    {
                                                        sites.filter(site => (selectedSites.length < 1 || selectedSites?.some(y => y.id === site.id))).map((site) => {
                                                            const stocks = stockViewData.filter(stock => stock.currentSiteId === site.id
                                                                                                            && row?.mainStockLine.packId === stock.mainStockLine.packId
                                                                                                            && row?.mainStockLine.sizeId === stock.mainStockLine.sizeId
                                                                                                            && row?.mainStockLine.gradeId === stock.mainStockLine.gradeId);

                                                            const selectedStocksAtRow = selectedStockViews.filter(stock => stock.currentSiteId === site.id
                                                                                                            && row?.mainStockLine.packId === stock.mainStockLine.packId
                                                                                                            && row?.mainStockLine.sizeId === stock.mainStockLine.sizeId
                                                                                                            && row?.mainStockLine.gradeId === stock.mainStockLine.gradeId);

                                                            const dispatchesLinkedToStocks = dispatchDrafts.filter(dispatchDraft => dispatchDraft.dispatchLines.some(y => stocks.some(z => !!y.isActive && z.id === y.currentStockId)));

                                                            let dispatchLinesLinkedToStock : Array<IDispatchInstructionLine> = [];

                                                            dispatchesLinkedToStocks.forEach((draft) => {
                                                                draft.dispatchLines.forEach((line) => {
                                                                    const index = dispatchLinesLinkedToStock.findIndex(y => y.id === line.id);

                                                                    if (index === -1 && stocks.some(y => y.id === line.currentStockId)) {
                                                                        dispatchLinesLinkedToStock = addArrayElement(dispatchLinesLinkedToStock, line);
                                                                    }
                                                                });
                                                            });

                                                            const dispatchLines = dispatchLinesLinkedToStock;
                                                            const allocatedStock = stocks.filter(y => dispatchLines?.some(z => z.currentStockId === y.id));

                                                            const availableStocks = stocks.length - (selectedStocksAtRow.length + allocatedStock.length);
                                                            return (
                                                                <TableCell
                                                                    style={dispatchesLinkedToStocks.length > 0 && dispatchesLinkedToStocks[0].id
                                                                        ? { backgroundColor: randomColor({
                                                                            hue: 'random',
                                                                            format: 'rgba',
                                                                            alpha: materialTheme.custom.randomColor.alphaValue,
                                                                            seed: dispatchesLinkedToStocks[0].id * 1000000000 }),
                                                                        color: materialTheme.custom.multiDispatchTable.text,
                                                                        }
                                                                        : { color: materialTheme.custom.multiDispatchTable.text }
                                                                    }
                                                                    className={'flx1 h50 fdc jcc aic p0 m0 fw500 br1 bl1 bb1 bocg3'}>
                                                                    <div className={'fdr aic jcc'}>
                                                                        {stocks.length === 0 ? '-' : (availableStocks < 1 ? '-' : availableStocks)}
                                                                    </div>
                                                                </TableCell>
                                                            );
                                                        })
                                                    }
                                                </TableRow>
                                            </AccordionSummary>
                                            <AccordionDetails className={'fdc hfill wfill p0 m0'}>
                                                {colours && colours.length > 0 ?
                                                    colours.map((colour) => {
                                                        return (
                                                            <TableRow className={'fdr wfill hfill'}>
                                                                <TableCell style={{ color: materialTheme.custom.multiDispatchTable.text }} className={'w150 fdc jcc aic p0 fw500 br1 bb1 bocg3'}>
                                                                    {colour.code}
                                                                </TableCell>
                                                                {
                                                                    sites.filter(site => (selectedSites.length < 1 || selectedSites?.some(y => y.id === site.id))).map((site) => {
                                                                        const stocksAtSite = stockViewData.filter(z => z.currentSiteId === site.id);
                                                                        const stocks = stocksAtSite.filter(stock => row?.mainStockLine.packId === stock.mainStockLine.packId
                                                                                                                        && row?.mainStockLine.sizeId === stock.mainStockLine.sizeId
                                                                                                                        && row?.mainStockLine.gradeId === stock.mainStockLine.gradeId);

                                                                        const selectedStocksAtRow = selectedStockViews.filter(stock => stock.currentSiteId === site.id
                                                                                                                        && row?.mainStockLine.packId === stock.mainStockLine.packId
                                                                                                                        && row?.mainStockLine.sizeId === stock.mainStockLine.sizeId
                                                                                                                        && row?.mainStockLine.gradeId === stock.mainStockLine.gradeId);

                                                                        const stocksAtColour = stocks.filter(y => y.mainStockLine.colourId === colour.id);

                                                                        const dispatchesLinkedToStocks = dispatchDrafts.filter(dispatchDraft => dispatchDraft.dispatchLines.filter(y => y.isActive)?.some(y => stocksAtColour.some(z => !!y.isActive && z.id === y.currentStockId)));

                                                                        let dispatchLinesLinkedToStock : Array<IDispatchInstructionLine> = [];

                                                                        dispatchesLinkedToStocks.forEach((draft) => {
                                                                            draft.dispatchLines.forEach((line) => {
                                                                                const index = dispatchLinesLinkedToStock.findIndex(y => y.id === line.id);

                                                                                if (index === -1 && stocks.some(y => y.id === line.currentStockId)) {
                                                                                    dispatchLinesLinkedToStock = addArrayElement(dispatchLinesLinkedToStock, line);
                                                                                }
                                                                            });
                                                                        });

                                                                        const dispatchLines = dispatchLinesLinkedToStock;
                                                                        const allocatedStock = stocks.filter(y => dispatchLines?.some(z => z.currentStockId === y.id));

                                                                        let availableStocks : Array<IMultiDispatchStockView> = [];

                                                                        stocksAtColour.forEach((z) => {
                                                                            const stock = allocatedStock.find(y => z.id === y.id);
                                                                            const selectedStock = selectedStocksAtRow.find(y => z.id === y.id);
                                                                            if (!stock && !selectedStock) {
                                                                                const index = availableStocks.findIndex(y => y.id === z.id);
                                                                                if (index === -1) {
                                                                                    availableStocks = addArrayElement(availableStocks, z);
                                                                                }
                                                                            }
                                                                        });

                                                                        return (
                                                                            <Tooltip disableInteractive arrow title={`${stocksAtColour.length > 0 ? 'Selected Stock' : ''}`}>
                                                                                <TableCell
                                                                                    style={
                                                                                        dispatchesLinkedToStocks.length > 0 && dispatchesLinkedToStocks[0].id
                                                                                            ? { backgroundColor: randomColor({
                                                                                                hue: 'random',
                                                                                                format: 'rgba',
                                                                                                alpha: materialTheme.custom.randomColor.alphaValue,
                                                                                                seed: dispatchesLinkedToStocks[0].id * 1000000000 }),
                                                                                            color: materialTheme.custom.multiDispatchTable.text,
                                                                                            }
                                                                                            : { color: materialTheme.custom.multiDispatchTable.text }
                                                                                    }
                                                                                    onClick={() => availableStocks.length > 0 ? this.openStockAmountPopup(row, availableStocks) : null}
                                                                                    className={`flx1 fdc jcc aic p0 fw500 br1 bl1 bb1 bocg3 ${stocksAtColour.length > 0 ? 'greyHover curp' : ''}`}
                                                                                >
                                                                                    {availableStocks.length === 0 ? '-' : availableStocks.length}
                                                                                </TableCell>
                                                                            </Tooltip>
                                                                        );
                                                                    })
                                                                }
                                                            </TableRow>
                                                        );
                                                    })
                                                    :
                                                    <div className={'wfill aic jcc'}>{'Stocks have no colours'}</div>
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                }
                            })
                        }
                    </div>
                </Table>
            );
        },
    );

    private toggleExpand = (id : number) => {
        if (id === this.state.stockId) {
            this.setState({ isExpanded: !this.state.isExpanded }, () => {
                if (this.state.isExpanded) {
                    this.setState({ stockId: id });
                } else {
                    this.setState({ stockId: undefined });
                }
            });
        } else {
            this.setState({ stockId: undefined, isExpanded: false }, () => {
                this.setState({ isExpanded: !this.state.isExpanded }, () => {
                    if (this.state.isExpanded) {
                        this.setState({ stockId: id });
                    } else {
                        this.setState({ stockId: undefined });
                    }
                });
            });
        }
    };

    private getMainStockLine = (stock : IStock) => {
        if (stock.stockLines.length > 0) {
            let mainStockLine = { ...stock.stockLines[0] };
            stock.stockLines.forEach((x) => {
                if ((mainStockLine.cartons < x.cartons) || ((mainStockLine.cartons === x.cartons) && (mainStockLine.id > x.id))) {
                    mainStockLine = x;
                }
            });

            return mainStockLine;
        }
    };

    private getSelectedStockAtDestinationSite = createSelector(
        [this.getSelectedStocks, this.getSelectedDestinationSite],
        (selectedStocks : Array<IStock>, selectedDestinationSite ?: IOptionType) => {
            if (!selectedStocks || selectedStocks.length < 1 || !selectedDestinationSite) return [];

            return selectedStocks.filter(x => x.currentSiteId === selectedDestinationSite.value);
        },
    );

    private palletsMixingSalesPointsErrorDialogNoClicked = () => this.setState({ showMixedSalesPointErrorDialog: false, stocksWithMixedSalesPoints: [] });
    private closeDestinationSiteWarningPopup = () => this.setState({ showDestinationSiteWarningPopup: false });

    private createDispatchDraftMutliSalesPointCheck = () => {
        const selectedStockRows = this.state.selectedStockRows;
        const selectedStocks = this.state.selectedStocks;
        const targetOrganization = this.props.organizations.find(x => x.id === this.state.selectedTargetOrganization?.value);
        const destinationSite = this.props.sites.find(x => x.id === this.state.selectedDistinationSite?.value);

        if (selectedStockRows.length > 0 && selectedStocks.length > 0 && !!destinationSite && !!targetOrganization) {
            if (selectedStocks.some(x => x.currentSiteId === destinationSite.id)) {
                this.setState({ showDestinationSiteWarningPopup: true });
                return;
            }

            if (!destinationSite.canMixSalesPoint) {
                const firstStock = selectedStocks[0];
                const firstStockMainStockLine = this.getMainStockLine(firstStock);
                const firstStockFarm = this.props.farms.find(y => y.id === firstStockMainStockLine?.farmId);
                const firstStockFarmSalesPoint = this.props.salesPoints.find(x => x.isActive && x.farmIds?.some(y => y === firstStockFarm?.id));

                let stocksWithDifferentMainSalesPoint : Array<IStock> = []; // stock which main stock line sales point is different from the first stocks main stock line sales point
                let stocksWithMixedSalesPoints : Array<IStock> = []; // stocks where some farms on a stock is linked to more then one sales point

                selectedStocks.forEach((selectedStock) => {
                    const mainStockLine = this.getMainStockLine(selectedStock);
                    const stockFarm = this.props.farms.find(y => y.id === mainStockLine?.farmId);
                    const stockFarmSalesPoint = this.props.salesPoints.find(x => x.farmIds?.some(y => y === stockFarm?.id));

                    selectedStock.stockLines.forEach((line) => {
                        const salesPoint = this.props.salesPoints.find(sp => sp.id !== stockFarmSalesPoint?.id && sp.farmIds?.some(farmId => farmId === line.farmId));
                        const index = stocksWithMixedSalesPoints.findIndex(stock => stock.id === selectedStock.id);

                        if (salesPoint && index === -1) {
                            stocksWithMixedSalesPoints = addArrayElement(stocksWithMixedSalesPoints, selectedStock);
                        }
                    });

                    if (stockFarm
                        && ((firstStockFarmSalesPoint && stockFarmSalesPoint && firstStockFarmSalesPoint?.id !== stockFarmSalesPoint?.id)
                            || (firstStockFarmSalesPoint && !stockFarmSalesPoint)
                            || (stockFarmSalesPoint && !firstStockFarmSalesPoint))) {
                        stocksWithDifferentMainSalesPoint = addArrayElement(stocksWithDifferentMainSalesPoint, selectedStock);
                    }

                });

                if (stocksWithDifferentMainSalesPoint.length > 0 || stocksWithMixedSalesPoints.length > 0) {
                    this.setState({ showMixedSalesPointErrorDialog: true, stocksWithMixedSalesPoints });
                } else {
                    this.createDispatchDrafts(false);
                }
            } else {
                this.createDispatchDrafts(false);
            }
        } else if (selectedStocks.length < 1) {
            generalShowErrorSnackbar('No selected stock was found');
        } else if (!targetOrganization) {
            generalShowErrorSnackbar('Please select a target organization');
        } else if (!destinationSite) {
            generalShowErrorSnackbar('Please select a destination site');
        }
    };

    private getMixingSalesPointErrorDialogMessage = createSelector(
        [this.getStocksWithMixedSalesPoints],
        (stocksWithMixedSalesPoints : Array<IStock>) => {
            return (
                <div className={'fdc wfill hfill'}>
                    <div className={'fs16 fw550'}>{'The selected destination does not allow for mixing of sales points on this dispatch.'}</div>
                    <div className={'fs16 fw550'}>{'Not all pallets have farms linked to the same sales point or some pallets have farms linked to different sales points on them.'}</div>
                    {stocksWithMixedSalesPoints.length > 0 &&
                        <div className={'fdc fs16 fw550 mt10'}>
                            {`Pallet${stocksWithMixedSalesPoints.length > 1 ? '(s)' : ''}: ${stocksWithMixedSalesPoints.slice(0, 2).map(x => x.barcode).join(', ')} ${stocksWithMixedSalesPoints.length > 2 ? `, \n${stocksWithMixedSalesPoints[2].barcode}` : ''}`}
                            {stocksWithMixedSalesPoints.length > 3 &&
                                <div className={'fs16 fw550'}>{`And ${stocksWithMixedSalesPoints.length - 3} more`}</div>
                            }
                            <div className={'fs16 fw550 mt10'}>{'Will not be included in the split since they are mixing sales points on each individual stock'}</div>
                        </div>
                    }
                    <div className={'fs16 fw550 mt10'}>{`Note: By clicking "Split" you will continue but the pallets\n will be split into separate dispatches ${stocksWithMixedSalesPoints.length > 0 ? 'excluding the pallet(s) listed above' : ''}`}</div>
                </div>
            );
        }
    );

    private createDispatchDrafts = async (splitPallets : boolean) => {
        this.setLoading(true);
        const selectedStockRows = this.state.selectedStockRows;
        const selectedStocks = this.state.selectedStocks;
        const targetOrganization = this.props.organizations.find(x => x.id === this.state.selectedTargetOrganization?.value);
        const destinationSite = this.props.sites.find(x => x.id === this.state.selectedDistinationSite?.value);

        if (selectedStockRows.length > 0 && selectedStocks.length > 0 && !!destinationSite && !!targetOrganization) {

            let dispatches : Array<ICreateInstructedDispatch> = [];

            let selectedStockRowsFiltered : Array<{ siteId : number; stockIds : Array<number> }> = [];

            selectedStockRows.forEach((row) => {
                const selectedRow = selectedStockRowsFiltered.find(x => x.siteId === row.siteId);

                if (!selectedRow) {
                    selectedStockRowsFiltered = addArrayElement(selectedStockRowsFiltered, { siteId: row.siteId, stockIds: row.stockIds });
                } else {
                    let newStockIdList : Array<number> = [...selectedRow.stockIds];
                    row.stockIds.forEach((id) => {
                        const index = newStockIdList.findIndex(x => x === id);

                        if (index === -1) {
                            newStockIdList = addArrayElement(newStockIdList, id);
                        }
                    });
                    selectedStockRowsFiltered = upsertArrayElement(selectedStockRowsFiltered, { siteId: row.siteId, stockIds: newStockIdList }, x => x.siteId === row.siteId) ?? [];
                }
            });

            // Creation of each dispatch draft
            if (!!splitPallets) { // Indicate if stocks should be split up and grouped by sales point that the farm is linked to.
                selectedStockRowsFiltered.forEach((row) => {
                    const sourceSite = this.props.sites.find(x => x.id === row.siteId);

                    const stocks = selectedStocks.filter(x => row.stockIds.some(y => y === x.id) && (this.state.stocksWithMixedSalesPoints.length === 0 || this.state.stocksWithMixedSalesPoints.some(y => x.id !== y.id)));

                    let selectedStockMainStockLines : Array<IStockLine> = [];

                    // populates selectedStockMainStockLines list with each stock main stock line
                    stocks.forEach((stock) => {
                        const mainStockLine = this.getMainStockLine(stock);
                        const index = selectedStockMainStockLines.findIndex(x => x.stockId === stock.id);

                        if (!!mainStockLine && index === -1) {
                            selectedStockMainStockLines = addArrayElement(selectedStockMainStockLines, mainStockLine);
                        }
                    });

                    // Grouping main stock lines by sales point id
                    const mainStockLinesGroupedBySalesPointId = lodash.groupBy(selectedStockMainStockLines, x => this.props.salesPoints.find(sp => sp.farmIds?.some(farmId => farmId === x.farmId))?.id);

                    // Creates new dispatch for each sales point grouping
                    lodash.forEach(mainStockLinesGroupedBySalesPointId, (splitInstance) => {
                        const splitInstanceStocks = stocks.filter(x => splitInstance.some(y => y.stockId === x.id));

                        const newStock : Array<{stockId : number; isSubstitutable : boolean}> = splitInstanceStocks.map((x) => {
                            return {
                                stockId: x.id,
                                isSubstitutable: false,
                            };
                        });

                        const dispatch : ICreateDispatchDraft = {
                            dispatchId: 0,
                            loadDate: moment().startOf('day').format(DATEPICKER_FORMAT_DEFAULT),
                            sourceSiteId: sourceSite?.id ?? 0,
                            destinationSiteId: destinationSite?.id,
                            organizationId: this.props.selectedOrganizationIds[0],
                            targetOrganizationId: targetOrganization?.id,
                            stocks: newStock,
                            createCompliance: false,
                        };
                        dispatches = addArrayElement(dispatches, dispatch);
                    });
                });
            } else {
                selectedStockRowsFiltered.forEach((row) => {
                    const stockIds = selectedStocks.filter(x => row.stockIds.some(y => y === x.id)).map(x => x.id);
                    const stocks = selectedStocks.filter(x => row.stockIds.some(y => y === x.id));

                    const newStock : Array<{stockId : number; isSubstitutable : boolean}> = [];

                    stockIds.forEach((x) => {
                        const data = {
                            stockId: x,
                            isSubstitutable: false,
                        };

                        newStock.push(data);
                    });

                    // Dispatch Source Site
                    const sourceSite = this.props.sites.find(x => x.id === row.siteId);

                    let matchingDispatchDraft : IDispatchInstruction | undefined;

                    const existingDispatchDrafts = this.state.dispatchDrafts.filter(x =>
                        x.sourceSiteId === sourceSite?.id
                        && x.destinationSiteId === destinationSite.id
                        && x.loadDate >= moment().startOf('day').format(DATEPICKER_FORMAT_DEFAULT));

                    if (stocks.every(x => x.channel === 'L') && existingDispatchDrafts.length > 0) {
                        matchingDispatchDraft = existingDispatchDrafts[0];
                    } else if (existingDispatchDrafts.length > 0) {
                        matchingDispatchDraft = existingDispatchDrafts.find((draft) => {
                            const dispatchLines = draft.dispatchLines.filter(x => !!x.isActive);
                            const currentStocks = this.props.stocks.filter(x => dispatchLines.some(y => y.currentStockId === x.id));

                            if (!currentStocks.filter(x => x.channel === 'E')?.some(x => stocks.filter(y => y.channel === 'E')?.some(y => this.getMainStockLine(x)?.farmId !== this.getMainStockLine(y)?.farmId))) {
                                return true;
                            }
                        });
                    }

                    if (!!matchingDispatchDraft) {
                        const dispatch : ICreateDispatchDraft = {
                            dispatchId: matchingDispatchDraft ? matchingDispatchDraft?.id : 0,
                            loadDate: matchingDispatchDraft ? matchingDispatchDraft.loadDate : moment().startOf('day').format(DATEPICKER_FORMAT_DEFAULT),
                            sourceSiteId: matchingDispatchDraft && matchingDispatchDraft?.sourceSiteId ? matchingDispatchDraft?.sourceSiteId : sourceSite?.id ?? 0,
                            destinationSiteId: matchingDispatchDraft && matchingDispatchDraft?.destinationSiteId ? matchingDispatchDraft?.destinationSiteId : destinationSite?.id,
                            organizationId: matchingDispatchDraft && matchingDispatchDraft?.organizationId ? matchingDispatchDraft?.organizationId : this.props.selectedOrganizationIds[0],
                            targetOrganizationId: matchingDispatchDraft && matchingDispatchDraft?.targetOrganizationId ? matchingDispatchDraft?.targetOrganizationId : targetOrganization?.id,
                            stocks: newStock,
                            createCompliance: false,
                        };

                        if (newStock.length > 0) {
                            dispatches = addArrayElement(dispatches, dispatch);
                        }
                    } else {
                        const dispatch : ICreateDispatchDraft = {
                            dispatchId: 0,
                            loadDate: moment().startOf('day').format(DATEPICKER_FORMAT_DEFAULT),
                            sourceSiteId: sourceSite?.id ?? 0,
                            destinationSiteId: destinationSite?.id,
                            organizationId: this.props.selectedOrganizationIds[0],
                            targetOrganizationId: targetOrganization?.id,
                            stocks: newStock,
                            createCompliance: false,
                        };

                        if (newStock.length > 0) {
                            dispatches = addArrayElement(dispatches, dispatch);
                        }
                    }
                });
            }

            const dispatchDrafts : IDispatchDrafts = {
                dispatches,
            };

            try {
                const res = await DispatchHttpService.createDispatchDrafts(dispatchDrafts);

                if (res) {
                    const res2 = await DispatchHttpService.getDispatchDrafts(this.state.selectedSites.map(x => x.id));
                    this.props.dataSetStocks(res2.data.stocks);
                    generalShowSuccessSnackbar('Dispatch drafts created successfully!');
                    let newCreatedDraftsList : Array<IDispatchInstruction> = [...this.state.createdDrafts];
                    res.data.forEach((x) => {
                        const index = this.state.createdDrafts.findIndex(y => y.id === x.id);

                        if (index === -1) {
                            newCreatedDraftsList = addArrayElement(newCreatedDraftsList, x);
                        } else {
                            newCreatedDraftsList = upsertArrayElement(newCreatedDraftsList, x, y => y.id === x.id) ?? [];
                        }
                    });
                    let newDispatchDrafts : Array<IDispatchInstruction> = [...this.state.dispatchDrafts];
                    res.data.forEach((x) => {
                        const index = this.state.dispatchDrafts.findIndex(y => y.id === x.id);

                        if (index === -1) {
                            newDispatchDrafts = addArrayElement(newDispatchDrafts, x);
                        } else {
                            newDispatchDrafts = upsertArrayElement(newDispatchDrafts, x, y => y.id === x.id) ?? [];
                        }
                    });
                    this.setState({ createdDrafts: newCreatedDraftsList, dispatchDrafts: newDispatchDrafts, selectedStockRows: [], selectedStocks: [], selectedRow: undefined, stocksWithMixedSalesPoints: [] });
                    this.props.dataSetDispatchInstructions(newDispatchDrafts);
                    this.setLoading(false);
                }
            } catch (ex) {
                if (!!ex && ex.status === 400) {
                    generalShowErrorSnackbar(ex.data.Message);
                } else {
                    generalShowErrorSnackbar('Error occured creating dispatches');
                }
                this.setLoading(false);
            } finally {
                this.palletsMixingSalesPointsErrorDialogNoClicked();
            }
        }
    };

    private createInstructedDispatches = async () => {
        const selectedTrip = this.getSelectedTrip(this.props, this.state);
        const data : IMultiDispatchWizardFinalSubmit = {
            dispatchIds: this.state.selectedDispatches.map(x => x.id),
            status: 'Instructed',
            tripId: selectedTrip?.id ?? 0,
        };

        if (this.state.dispatchDrafts.length > 0) {
            this.setLoading(true);
            try {
                const res = await DispatchHttpService.createMultipleInstructedDispatches(data);

                if (res && res.data) {

                    let newDispatchDraftList = [...this.state.dispatchDrafts];
                    res.data.forEach((dispatch) => {
                        const index = newDispatchDraftList.findIndex(x => x.id === dispatch.id);
                        newDispatchDraftList = removeArrayElement(newDispatchDraftList, index);
                    });

                    generalShowSuccessSnackbar('Dispatches saved successfully!');
                    this.setState({ selectedStockRows: [],
                        selectedStocks: [],
                        selectedDispatches: [],
                        selectedTrip: undefined,
                        dispatchDrafts: newDispatchDraftList,
                        createdDrafts: [],
                        selectedPack: undefined,
                        selectedSize: undefined,
                        selectedCommodity: undefined,
                        selectedVariety: undefined,
                        selectedGrade: undefined,
                        selectedMarkets: [],
                        selectedCompliance: undefined,
                        selectedExistingTrip: undefined,
                    });
                    this.refreshData(false, true);
                }
            } catch (e) {
                generalShowErrorSnackbar('Error occured saving the dispatches');
                this.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('No dispatches found');
        }
    };

    private getAgeInDays = (createdOn ?: string) => {
        if (!createdOn) {
            return -1;
        } else {
            const currentDay = moment().local().startOf('day').format(DATE_FORMAT_DEFAULT_NO_TIME);
            const createdOnDate = moment(createdOn).format(DATE_FORMAT_DEFAULT_NO_TIME);

            if (currentDay === createdOnDate) {
                return 0;
            } else {
                const age =  moment({ hours: 0 }).diff(createdOn, 'days', true);
                return Math.ceil(age);
            }
        }

    };

    private getTargetOrganizationOptions = createSelector(
        [this.getOrganizations],
        (organizations) => {
            return lodash.chain(organizations)
                .filter(x => x.isActive && x.isClient)
                .orderBy(x => x.code, 'asc')
                .map((x) => {
                    return { label: `${x.code} - ${x.name}`, value: x.id };
                }).value();
        },
    );

    private getComplianceOptions = createSelector(
        [this.getCompliances],
        (compliances : Array<ICompliance>) => {
            return lodash.chain(compliances)
                .filter(x => !!x.waybill)
                .orderBy(x => x.waybill, 'asc')
                .map((x) => {
                    return { label: x.waybill ?? '', value: x.id };
                }).value();
        },
    );

    private getStorageUnitOptions = createSelector(
        [this.getStorageUnits, this.getSelectedSiteIds],
        (storageUnits : Array<IStorageUnit>, selectedSiteIds : Array<number>) => {
            return lodash.chain(storageUnits)
                .filter(x => x.isActive && selectedSiteIds?.some(y => y === x.siteId))
                .orderBy(x => x.code, 'asc')
                .map((x) => {
                    return { label: `${x.code} - ${x.description}`, value: x.id };
                }).value();
        },
    );

    private getMarketOptions = createSelector(
        [this.getMarkets, this.getInitialStockRows],
        (markets, stocks) => {
            return lodash.chain(markets)
                .filter(x => x.isActive && stocks.some(y => y.marketId === x.id))
                .orderBy(x => x.code, 'asc')
                .map((x) => {
                    return { label: `${x.code} - ${x.name}`, value: x.id };
                }).value();
        },
    );

    private getCommodityOptions = createSelector(
        [this.getCommodities, this.getRowsFilteredBySites, this.getSelectedSiteIds],
        (commodities : Array<ICommodity>, rowsFilteredBySite, selectedSiteIds) => {
            const rowsAtSelectedSites = rowsFilteredBySite?.filter(x => selectedSiteIds?.some(y => y === x.currentSiteId));

            return lodash.chain(commodities)
                .filter(x => x.isActive && rowsAtSelectedSites.some(y => y.mainStockLine.commodityId === x.id))
                .orderBy(x => x.code, 'asc')
                .map((x) => {
                    return { label: `(${x.code}) ${x.name}`, value: x.id };
                }).value();
        },
    );

    private getPackOptions = createSelector(
        [this.getPacks, this.getSelectedCommodity, this.getRowsFilteredBySites, this.getSelectedSiteIds],
        (packs : Array<IPack>, selectedCommodity : IOptionType, rowsFilteredBySite, selectedSiteIds) => {
            const rowsAtSelectedSites = rowsFilteredBySite.filter(x => selectedSiteIds?.some(y => y === x.currentSiteId));

            return lodash.chain(packs)
                .filter(x => x.isActive && rowsAtSelectedSites.some(y => y.mainStockLine.packId === x.id) && (selectedCommodity?.value ? (x.commodityId === selectedCommodity.value) : true))
                .orderBy(x => x.code, 'asc')
                .map((x) => {
                    return { label: `(${x.code}) ${x.description}`, value: x.id };
                }).value();
        },
    );

    private getVarietyOptions = createSelector(
        [this.getVarieties, this.getSelectedCommodity, this.getRowsFilteredBySites, this.getSelectedSiteIds],
        (varieties : Array<IVariety>, selectedCommodity : IOptionType, rowsFilteredBySite, selectedSiteIds) => {
            const rowsAtSelectedSites = rowsFilteredBySite?.filter(x => selectedSiteIds?.some(y => y === x.currentSiteId));

            return lodash.chain(varieties)
                .filter(x => x.isActive && rowsAtSelectedSites.some(y => y.mainStockLine.varietyId === x.id) && (selectedCommodity?.value ? (x.commodityId === selectedCommodity.value) : true))
                .orderBy(x => x.code, 'asc')
                .map((x) => {
                    return { label: `(${x.code}) ${x.name}`, value: x.id };
                }).value();
        },
    );

    private getSizeOptions = createSelector(
        [this.getSizes, this.getPacks, this.getSelectedPack, this.getRowsFilteredBySites, this.getSelectedSiteIds],
        (sizes : Array<ISize>, packs : Array<IPack>, selectedPack : IOptionType, rowsFilteredBySite, selectedSiteIds) => {
            const rowsAtSelectedSites = rowsFilteredBySite.filter(x => selectedSiteIds?.some(y => y === x.currentSiteId));
            const pack = packs.find(x => x.id === selectedPack?.value);

            return lodash.chain(sizes)
                .filter(x => x.isActive && rowsAtSelectedSites.some(y => y.mainStockLine.sizeId === x.id) && (pack ? (pack?.sizeIds?.some(y => y === x.id)) : true))
                .orderBy(x => x.code, 'asc')
                .map((x) => {
                    return { label: `(${x.code}) ${x.name}`, value: x.id };
                }).value();
        },
    );

    private getGradeOptions = createSelector(
        [this.getGrades, this.getSelectedCommodity, this.getRowsFilteredBySites, this.getSelectedSiteIds],
        (grades : Array<IGrade>, selectedCommodity : IOptionType, rowsFilteredBySite, selectedSiteIds) => {
            const rowsAtSelectedSites = rowsFilteredBySite.filter(x => selectedSiteIds?.some(y => y === x.currentSiteId));

            return lodash.chain(grades)
                .filter(x => x.isActive && rowsAtSelectedSites.some(y => y.mainStockLine.gradeId === x.id) && (selectedCommodity?.value ? (x.commodityIds.some(y => y === selectedCommodity.value)) : true))
                .orderBy(x => x.code, 'asc')
                .map((x) => {
                    return { label: `(${x.code}) ${x.name}`, value: x.id };
                }).value();
        },
    );

    private getDestinationSiteOptions = createSelector(
        [this.getSites, this.getSelectedTargetOrganization],
        (sites, selectedtargetOrganization) => {
            return lodash.chain(sites)
                .filter(x => x.isActive && x.organizationIds?.some(y => y === selectedtargetOrganization?.value))
                .orderBy(x => x.code, 'asc')
                .map((x) => {
                    return { label: `${x.code} - ${x.description}`, value: x.id };
                }).value();
        },
    );

    private onTargetOrganizationChange = (e : React.ChangeEvent<{}>, selectedTargetOrganization : IOptionType) => {
        this.setState({ selectedTargetOrganization, selectedDistinationSite: undefined });
    };

    private onDestinationSiteChange = (e : React.ChangeEvent<{}>, selectedDistinationSite : IOptionType) => {
        this.setState({ selectedDistinationSite });
    };

    private onMarketChange = (e : React.ChangeEvent<{}>, selectedMarkets : Array<IOptionType>) => {
        this.setState({ selectedMarkets }, () => {
            this.refreshAvailableStockData(true,
                selectedMarkets.map(x => Number(x.value)),
                Number(this.state.selectedPack?.value),
                Number(this.state.selectedSize?.value),
                Number(this.state.selectedGrade?.value),
                Number(this.state.selectedCommodity?.value),
                Number(this.state.selectedVariety?.value),
                Number(this.state.selectedCompliance?.value),
                Number(this.state.selectedStorageUnit?.value));
        });
    };

    private onCommodityChange = (e : React.ChangeEvent<{}>, selectedCommodity : IOptionType) => {
        this.setState({ selectedCommodity }, () => {
            this.refreshAvailableStockData(true,
                this.state.selectedMarkets?.map(x => Number(x.value)),
                Number(this.state.selectedPack?.value),
                Number(this.state.selectedSize?.value),
                Number(this.state.selectedGrade?.value),
                Number(selectedCommodity?.value),
                Number(this.state.selectedVariety?.value),
                Number(this.state.selectedCompliance?.value),
                Number(this.state.selectedStorageUnit?.value));
        });
    };

    private onComplianceChange = (e : React.ChangeEvent<{}>, selectedCompliance : IOptionType) => {
        this.setState({ selectedCompliance }, () => {
            this.refreshAvailableStockData(true,
                this.state.selectedMarkets?.map(x => Number(x.value)),
                Number(this.state.selectedPack?.value),
                Number(this.state.selectedSize?.value),
                Number(this.state.selectedGrade?.value),
                Number(this.state.selectedCommodity?.value),
                Number(this.state.selectedVariety?.value),
                Number(selectedCompliance?.value),
                Number(this.state.selectedStorageUnit?.value));
        });
    };

    private onStorageUnitChange = (e : React.ChangeEvent<{}>, selectedStorageUnit : IOptionType) => {
        this.setState({ selectedStorageUnit }, () => {
            this.refreshAvailableStockData(true,
                this.state.selectedMarkets?.map(x => Number(x.value)),
                Number(this.state.selectedPack?.value),
                Number(this.state.selectedSize?.value),
                Number(this.state.selectedGrade?.value),
                Number(this.state.selectedCommodity?.value),
                Number(this.state.selectedVariety?.value),
                Number(this.state.selectedCompliance?.value),
                Number(selectedStorageUnit?.value));
        });
    };

    private onVarietyChange = (e : React.ChangeEvent<{}>, selectedVariety : IOptionType) => {
        this.setState({ selectedVariety }, () => {
            this.refreshAvailableStockData(true,
                this.state.selectedMarkets?.map(x => Number(x.value)),
                Number(this.state.selectedPack?.value),
                Number(this.state.selectedSize?.value),
                Number(this.state.selectedGrade?.value),
                Number(this.state.selectedCommodity?.value),
                Number(selectedVariety?.value),
                Number(this.state.selectedCompliance?.value),
                Number(this.state.selectedStorageUnit?.value));
        });
    };

    private onPackChange = (e : React.ChangeEvent<{}>, selectedPack : IOptionType) => {
        if (selectedPack === null) {
            this.setState({ selectedSize: undefined });
        }
        this.setState({ selectedPack }, () => {
            this.refreshAvailableStockData(true,
                this.state.selectedMarkets?.map(x => Number(x.value)),
                Number(selectedPack?.value),
                Number(this.state.selectedSize?.value),
                Number(this.state.selectedGrade?.value),
                Number(this.state.selectedCommodity?.value),
                Number(this.state.selectedVariety?.value),
                Number(this.state.selectedCompliance?.value),
                Number(this.state.selectedStorageUnit?.value));
        });
    };

    private onSizeChange = (e : React.ChangeEvent<{}>, selectedSize : IOptionType) => {
        this.setState({ selectedSize }, () => {
            this.refreshAvailableStockData(true,
                this.state.selectedMarkets?.map(x => Number(x.value)),
                Number(this.state.selectedPack?.value),
                Number(selectedSize?.value),
                Number(this.state.selectedGrade?.value),
                Number(this.state.selectedCommodity?.value),
                Number(this.state.selectedVariety?.value),
                Number(this.state.selectedCompliance?.value),
                Number(this.state.selectedStorageUnit?.value));
        });
    };

    private onGradeChange = (e : React.ChangeEvent<{}>, selectedGrade : IOptionType) => {
        this.setState({ selectedGrade }, () => {
            this.refreshAvailableStockData(true,
                this.state.selectedMarkets?.map(x => Number(x.value)),
                Number(this.state.selectedPack?.value),
                Number(this.state.selectedSize?.value),
                Number(selectedGrade?.value),
                Number(this.state.selectedCommodity?.value),
                Number(this.state.selectedVariety?.value),
                Number(this.state.selectedCompliance?.value),
                Number(this.state.selectedStorageUnit?.value));
        });
    };

    private onUpdateTripInfoOpen = () => {
        let dispatchStocks : Array<IStock> = [];
        this.state.selectedDispatches.forEach((dispatch) => {
            dispatch.dispatchLines.forEach((line) => {
                const currentStock = this.props.stocks.find(x => x.id === line.currentStockId);
                if (currentStock) {
                    const index = dispatchStocks.findIndex(x => x.id === line.currentStockId);
                    if (line.isActive && index === -1) {
                        dispatchStocks = addArrayElement(dispatchStocks, currentStock);
                    }
                }
            });
        });
        this.props.dataSetDispatchInstructionStocks(dispatchStocks);
        this.props.dataSetEditingTrip(true);
        this.setState({ isTripEditFormOpen: true });
    };

    private onUpdateTripClose = () => {
        this.props.dataSetEditingTrip(false);
        this.props.dataSetDispatchInstructionStocks([]);
        this.setState({ isTripEditFormOpen: false });
    };

    public getInitialFormValues = createSelector(
        [this.getSelectedTripFromState, this.getCarriers, this.getTruckTypes, this.getSelectedDispatches, this.getStockData],
        (trip, carriers, truckTypes, dispatches, stocks) => {
            return new TripFormValues(trip, carriers, truckTypes, undefined, undefined, dispatches, stocks);
        },
    );

    public onReset = async (formValues : ITripFormValues, formikActions : FormikActions<ITripFormValues>) => {
        formikActions.resetForm();
        this.onUpdateTripClose();
    };

    public onTripSubmit = async (value : ITripFormValues) => {
        this.setLoading(true);
        if (this.state.selectedDispatches.length > 0) {
            try {
                const res = await TripHttpService.addOrUpdateTrip(new Trip(value));

                if (res.data) {
                    dataSetTrip(res.data);
                    const dispatches = [...this.state.selectedDispatches];

                    dispatches.forEach((x) => {
                        x.tripId = res.data.id;
                    });

                    this.setState({ selectedTrip: res.data, selectedDispatches: dispatches });
                    generalShowSuccessSnackbar('Trip updated successfully.');
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred updating Trip data.');
            } finally {
                this.onUpdateTripClose();
                this.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('No selected dispatches found');
        }
    };

    private showMixedStock = async () => {
        this.setState({ showMixedStock: !this.state.showMixedStock, selectedStocks: [] });
        this.refreshData(!this.state.showMixedStock, true);
    };

    private getTextInputError = () => {
        const amount = this.state.amountOfStock;
        const maxAmount = this.state.maxAmountOfStock;

        if (amount && maxAmount) {
            if (amount > maxAmount) {
                return 'Specified amount is more then available amount';
            } else if (amount === 0) {
                return 'Value cannot be 0';
            } else if (amount < 0) {
                return 'Value must be non negative';
            } else {
                return '';
            }
        } else {
            return '';
        }
    };

    private removeSelectedStock = (id : number) => {
        const selectedStockIndex = this.state.selectedStocks.findIndex(x => x.id === id);
        let newSelectedStockList = this.state.selectedStocks;

        if (selectedStockIndex !== -1) {
            newSelectedStockList = removeArrayElement(newSelectedStockList, selectedStockIndex);
            this.setState({ selectedStocks: newSelectedStockList });
        }
    };

    private clearSelectedStock = () => {
        this.setState({ selectedStockRows: [], selectedStocks: [], selectedRow: undefined });
    };

    private onDispatchDraftDeleteClick = (id : number) => {
        const deletingDispatch = this.state.dispatchDrafts.find(x => x.id === id);
        this.setState({ deletingDispatch, isDeletePopupOpen: true });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ deletingDispatch: undefined, isDeletePopupOpen: false });
    };

    private deleteDispatchDraft = async (deleteReason : string) => {
        let dispatchDrafts = this.state.dispatchDrafts;
        const deletingDispatch = this.state.deletingDispatch;

        if (deletingDispatch) {
            deletingDispatch.isActive = false;
            if (deleteReason !== '' && deleteReason.length >= 200) {
                this.setLoading(true);
                try {
                    await DispatchHttpService.dispatchDelete(deletingDispatch.id, deleteReason);
                    const index = dispatchDrafts.findIndex(x => x.id === deletingDispatch.id);
                    dispatchDrafts = removeArrayElement(dispatchDrafts, index);

                    this.setState({ dispatchDrafts });

                    generalShowSuccessSnackbar('Dispatch successfully deleted.');
                    this.refreshAvailableStockData(true, this.state.selectedMarkets.map(x => Number(x.value)), Number(this.state.selectedPack?.value), Number(this.state.selectedSize?.value), Number(this.state.selectedGrade?.value));
                } catch (e) {
                    generalShowErrorSnackbar('An error occurred deleting the dispatch instruction.');
                } finally {
                    this.closeDeleteConfirmationPopup();
                    this.setLoading(false);
                }
            } else {
                generalShowErrorSnackbar('Please give a reason for deleting this dispatch');
            }
        }
    };

    private onEnterPressed = (event : React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            this.getStocks();
        }
    };

    private handleDispatchCheckboxChecked = (dispatch : IDispatchInstruction, checked : boolean) => {
        this.setLoading(true);
        if (checked) {
            const index = this.state.selectedDispatches.findIndex(x => x.id === dispatch.id);

            if (this.state.selectedTrip) {
                dispatch.tripId = this.state.selectedTrip.id;
            } else if (this.state.selectedExistingTrip) {
                dispatch.tripId = this.state.selectedExistingTrip.id;
            }

            if (index === -1) {
                this.setState(prevState => ({ selectedDispatches: addArrayElement(prevState.selectedDispatches, dispatch) }), () => this.setLoading(false));
            } else {
                this.setLoading(false);
            }
        } else {
            if (dispatch.tripId) {
                dispatch.tripId = undefined;
            }

            const index = this.state.selectedDispatches.findIndex(x => x.id === dispatch.id);
            if (index !== -1) {
                this.setState(prevState => ({ selectedDispatches: removeArrayElement(prevState.selectedDispatches, index), dispatchDrafts: upsertArrayElement(prevState.dispatchDrafts, dispatch, x => x.id === dispatch.id) ?? prevState.dispatchDrafts }), () => this.setLoading(false));
            } else {
                this.setLoading(false);
            }
        }
    };

    private getTotalInners = (selectedStocks : Array<IStock>) => {
        let stockLines : Array<IStockLine> = [];

        selectedStocks.forEach((x) => {
            x.stockLines.forEach((line) => {
                const index = stockLines.findIndex(y => y.id === line.id);
                if (index === -1) {
                    stockLines = addArrayElement(stockLines, line);
                }
            });
        });

        return stockLines.map(x => x.totalInners ?? 0).reduce((sum, current) => sum + current, 0);
    };

    private onDispatchEditClick = (dispatch : IDispatchInstruction) => {
        this.setLoading(true);
        this.setState({ editingDispatch: dispatch }, async () => {
            try {
                const res = await StockHttpService.getStocks(dispatch?.dispatchLines?.map(x => x.currentStockId));

                if (res && res.data) {
                    this.props.dataSetStocks(res.data);
                    this.setLoading(false);
                }
            } catch (e) {
                this.setLoading(false);
                generalShowErrorSnackbar('Failed to stock data for selected dispatch');
            }
        });
    };

    private onDispatchEditClose = () => {
        this.setState({ editingDispatch: undefined });
    };

    private getStockBarcode = (stockId : number) => {
        const stock = this.props.stocks.find(x => x.id === stockId);

        return stock ? stock.barcode : 'Stock Not Found';
    };

    private openDeleteStockConfirmationDialog = (dispatchLineId : number) => {
        const disaptchLine = this.state.editingDispatch?.dispatchLines.find(x => x.id === dispatchLineId);
        this.setState({ isRemoveStockConfirmDialogOpen: true, selectedDispatchLinesToRemove: disaptchLine });
    };

    private closeDeleteStockConfirmationDialog = () => {
        this.setState({ isRemoveStockConfirmDialogOpen: false, selectedDispatchLinesToRemove: undefined });
    };

    private removeDispatchLine = async () => {
        const editingDispatch = this.state.editingDispatch;
        const dispatchLine = this.state.selectedDispatchLinesToRemove;
        if (editingDispatch && dispatchLine) {
            const data = {
                dispatchId: editingDispatch.id,
                stockId: dispatchLine?.currentStockId,
            };

            this.setLoading(true);
            try {
                const res = await DispatchHttpService.removeStockFromDispatch(data);

                if (res && res.data) {
                    let dispatchDrafts = [...this.state.dispatchDrafts];

                    dispatchDrafts = upsertArrayElement(dispatchDrafts, res.data, x => x.id === res.data.id) ?? this.state.dispatchDrafts;

                    this.setState({ dispatchDrafts, editingDispatch: res.data });
                    generalShowSuccessSnackbar('Pallet successfully removed from dispatch');
                    this.refreshAvailableStockData(true, this.state.selectedMarkets.map(x => Number(x.value)), Number(this.state.selectedPack?.value), Number(this.state.selectedSize?.value), Number(this.state.selectedGrade?.value));
                    this.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar('Failed to remove pallet from dispatch');
                this.setLoading(false);
            } finally {
                this.closeDeleteStockConfirmationDialog();
            }
        } else {
            generalShowErrorSnackbar('Selected dispatch or selected pallet could not be found.');
        }
    };

    private getSelectedDraftsTotalGrossWeight = createSelector(
        [this.getStockData, this.getSelectedDispatches],
        (stockData, selectedDispatchDrafts) => {

            let dispatchLines : Array<IDispatchInstructionLine> = [];

            selectedDispatchDrafts.forEach((x) => {
                x.dispatchLines.filter(line => line.isActive).forEach((line) => {
                    const index = dispatchLines.findIndex(y => y.id === line.id);
                    if (index === -1) {
                        dispatchLines = addArrayElement(dispatchLines, line);
                    }
                });
            });

            const stocks = stockData.filter(x => dispatchLines.some(y => y.currentStockId === x.id));

            return stocks.length > 0 ? stocks.map(x => x.grossWeight).reduce((sum, current) => sum + current, 0).toFixed(3) : '';
        },
    );

    private getSelectedTripTotalGrossWeight = createSelector(
        [this.getStockData, this.getSelectedDispatches, this.getSelectedTrip],
        (stockData, selectedDispatchDrafts, selectedTrip) => {

            let dispatchLines : Array<IDispatchInstructionLine> = [];

            const skipDispatches = selectedTrip?.weighedDispatchIds;

            selectedDispatchDrafts.filter(x => !skipDispatches?.some(y => y === x.id)).forEach((x) => {
                x.dispatchLines.filter(line => line.isActive).forEach((line) => {
                    const index = dispatchLines.findIndex(y => y.id === line.id);
                    if (index === -1) {
                        dispatchLines = addArrayElement(dispatchLines, line);
                    }
                });
            });

            const stocks = stockData.filter(x => dispatchLines.some(y => y.currentStockId === x.id));

            return !!selectedTrip ? (stocks.map(x => x.grossWeight).reduce((sum, current) => sum + current, 0) + (selectedTrip?.totalWeight ?? 0)).toFixed(3) : '';
        },
    );

    private getSelectedTripTruckTypeMaxPayload = createSelector(
        [this.getTruckTypes, this.getStockData, this.getSelectedDispatches, this.getSelectedTrip],
        (truckTypes, stockData, selectedDispatchDrafts, selectedTrip) => {
            let dispatchLines : Array<IDispatchInstructionLine> = [];

            selectedDispatchDrafts.forEach((x) => {
                x.dispatchLines.filter(line => line.isActive).forEach((line) => {
                    const index = dispatchLines.findIndex(y => y.id === line.id);
                    if (index === -1) {
                        dispatchLines = addArrayElement(dispatchLines, line);
                    }
                });
            });

            const stocks = stockData.filter(x => dispatchLines.some(y => y.currentStockId === x.id));
            const totalWeight = stocks.map(x => x.grossWeight).reduce((sum, current) => sum + current, 0);
            const truckType = truckTypes.find(x => x.id === selectedTrip?.truckTypeId);

            if (truckType && totalWeight && truckType?.maxPayload && truckType?.percentageWarning && (totalWeight > truckType?.maxPayload)) {
                const maxPayloadPlusPercentageValue = truckType?.maxPayload + (truckType?.maxPayload * (truckType?.percentageWarning / 100));
                if (totalWeight > maxPayloadPlusPercentageValue) {
                    generalShowWarningSnackbar('Pallets total weight exceeds the trucks max payload and percentage buffer'); // TODO: Change to error and block process in future if error is displayed
                } else {
                    generalShowWarningSnackbar('Pallets total weight is more then the truck max payload');
                }
            }

            return truckType && truckType.maxPayload ? truckType.maxPayload : '';
        },
    );

    private getExistingTripTruckTypeMaxPayload = (tripId : number) => {
        const truckTypes = this.getTruckTypes(this.props);
        const trip = this.props.trips.find(x => x.id === tripId);
        const truckType = truckTypes.find(x => x.id === trip?.truckTypeId);

        return truckType && truckType.maxPayload ? truckType.maxPayload : '';
    };

    private openFilterSitesPopup = () => {
        const selectedSites = this.state.selectedSites;
        this.setState({ isFilterSitesPopupOpen: true, tempSelectedSites: selectedSites });
    };

    private closeFilterSitesPopup = () => {
        this.setState({ isFilterSitesPopupOpen: false });
    };

    private confirmSelectedSiteFilters = () => {
        const tempSiteChanges = this.state.tempSelectedSites;
        this.setState({ isFilterSitesPopupOpen: false, selectedSites: tempSiteChanges, tempSelectedSites: [] }, () => {
            this.refreshAvailableStockData(true,
                this.state.selectedMarkets?.map(x => Number(x.value)),
                Number(this.state.selectedPack?.value),
                Number(this.state.selectedSize?.value),
                Number(this.state.selectedGrade?.value),
                Number(this.state.selectedCommodity?.value),
                Number(this.state.selectedVariety?.value),
                Number(this.state.selectedCompliance?.value));
        });
    };

    private handleSelectedSiteCheckboxChecked = (site : ISite, checked : boolean) => {
        this.setLoading(true);
        if (checked) {
            const index = this.state.tempSelectedSites.findIndex(x => x.id === site.id);

            if (index === -1) {
                this.setState(prevState => ({ tempSelectedSites: addArrayElement(prevState.tempSelectedSites, site) }), () => this.setLoading(false));
            } else {
                this.setLoading(false);
            }
        } else {
            const index = this.state.tempSelectedSites.findIndex(x => x.id === site.id);
            if (index !== -1) {
                this.setState(prevState => ({ tempSelectedSites: removeArrayElement(prevState.tempSelectedSites, index) }), () => this.setLoading(false));
            } else {
                this.setLoading(false);
            }
        }
    };

    private setExistingTrips = (trips : Array<ITrip>) => this.setState({ existingTrips: trips });

    private openExistingTripPopup = async () => {
        this.setState({ isExistingTripPopupOpen: true, selectedTempExistingTrip: this.state.selectedExistingTrip }, async () => {
            this.setLoading(true);
            try {
                const fromLoadDate = this.state.selectedFromDate.utc().startOf('day').unix() * 1000;
                const toLoadDate = this.state.selectedToDate.utc().endOf('day').unix() * 1000;
                const res = await TripHttpService.getAvailableTrips(fromLoadDate, toLoadDate);

                if (res && res.data) {
                    this.setExistingTrips(res.data);
                }
            } catch (ex) {
                generalShowErrorSnackbar('Failed to load existing trips');
            } finally {
                this.setLoading(false);
            }
        });
    };

    private unassignTrips = async () => {
        const dispatches = [...this.state.selectedDispatches];
        dispatches.forEach((x) => {
            x.tripId = undefined;
            dataSetDispatchInstruction(x);
        });

        this.setState({ selectedDispatches: dispatches, selectedTrip: undefined, selectedExistingTrip: undefined });
    };

    private closeExistingTripPopup = () => {
        this.setState({ isExistingTripPopupOpen: false, selectedTempExistingTrip: undefined });
    };

    private clearExistingTripSelection = async () => {
        await this.unassignTrips();
        this.setState({ selectedExistingTrip: undefined, selectedTempExistingTrip: undefined, isExistingTripPopupOpen: false });
    };

    private onAssignExistingTrip = () => {
        const selectedTempTrip = this.state.selectedTempExistingTrip;
        const dispatches = [...this.state.selectedDispatches];

        dispatches.forEach((x) => {
            x.tripId = selectedTempTrip?.id;
            dataSetDispatchInstruction(x);
        });

        this.setState({ selectedExistingTrip: selectedTempTrip, isExistingTripPopupOpen: false, selectedTempExistingTrip: undefined, selectedDispatches: dispatches });
    };

    private renderSelectedStockRows = createSelector(
        [this.getSelectedStocks, this.getStockViewData, this.getSelectedStockRows, this.getExpandedStockId, this.getSelectedDestinationSite],
        (selectedStocks, stockViewData, selectedStockRows, stockId, selectedDistinationSite) => {
            return selectedStocks?.map((x) => {
                return (
                    <Accordion
                        key={`stock_id_#${x.id}`}
                        className={'fdc mnw520 mb10'}
                        expanded={x.id === stockId}
                        onChange={() => this.toggleExpand(x.id)}
                    >
                        <AccordionSummary className={'aic flx1 p0 cw'} expandIcon={<Icon className={'cw'}>expand_more</Icon>}>
                            <div className={'flx1 fdr'}>
                                <IconButton className={'p0 m0'} size={'small'} onClick={() => this.removeSelectedStock(x.id)}>
                                    <Icon className={'cw'}>delete</Icon>
                                </IconButton>
                                <div className={'fdc w125 pr10'}>
                                    <div className={'fw500'}>{'Barcode:'}</div>
                                    <CustomTooltip title={x.currentSiteId === selectedDistinationSite?.value ? 'Stock already at destination site' : ''}>
                                        <div className={x.currentSiteId === selectedDistinationSite?.value ? 'fdr cred fs12' : 'fdr fs12'}>{x?.barcode}</div>
                                    </CustomTooltip>
                                </div>
                                <div className={'fdr flx1 jcfs'}>
                                    <div className={'fdc pl8'}>
                                        <div className={'fw500'}>{'Fruit Spec:'}</div>
                                        <div className={'fdr pl8 fs12'}>{this.getPack(this.getMainStockLine(x)?.packId ?? 0) + ' ' + this.getGrade(this.getMainStockLine(x)?.gradeId ?? 0) + ' ' + this.getSize(this.getMainStockLine(x)?.sizeId ?? 0) + ' ' + this.getColour(this.getMainStockLine(x)?.colourId ?? 0)}</div>
                                    </div>
                                    <div className={'fdc pl8'}>
                                        <div className={'fw500'}>{'Age(Days): '}</div>
                                        <div className={`pl8 aic fs12 ${this.getAgeInDays(x?.createdOn) === 0 ? 'cp' : (this.getAgeInDays(x?.createdOn) === 1 ? 'cOrange' : 'cred')}`}>{this.getAgeInDays(x?.createdOn)}</div>
                                    </div>
                                    <div className={'fdc pl8'}>
                                        <div className={'fw500'}>{'Cartons:'}</div>
                                        <div className={'pl8 aic fs12'}>{x?.cartons}</div>
                                    </div>
                                    <div className={'fdc pl8'}>
                                        <div className={'fw500'}>{'Weight(kg):'}</div>
                                        <div className={'pl8 aic fs12'}>{x?.grossWeight}</div>
                                    </div>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={'fdc hfill wfill'}>
                            <div className={'fdc'}>
                                <div className={'fdr'}>
                                    <div className={x.currentSiteId === selectedDistinationSite?.value ? 'ml20 fs16 fw500 cred' : 'ml20 fs16 fw500'}>
                                        {'Site: ' + this.getSiteShortDescription(x.currentSiteId ?? 0)}
                                    </div>
                                </div>
                                <div className={'ml20 jcfs aic fw500'}>Stock lines</div>
                                {
                                    x?.stockLines.filter(line => line.isActive).sort((a, b) => compareNumber(a.sequence, b.sequence)).map((line) => {
                                        return (
                                            <div className={'fdr'}>
                                                <div className={'ml20 mr10 w90'}>{'Sequence: ' + line.sequence}</div>
                                                <div className={'mr10 w90'}>{'Pack: ' + this.getPack(line.packId)}</div>
                                                <div className={'mr10 w90'}>{'Size: ' + this.getSize(line.sizeId)}</div>
                                                <div className={'mr10 w90'}>{'Grade: ' + this.getGrade(line.gradeId)}</div>
                                                <div className={'mr10 w90'}>{'Colour: ' + this.getColour(line.colourId ?? 0)}</div>
                                                <div className={'mr10 w90'}>{'Farm: ' + this.getFarm(line.farmId)}</div>
                                                <div className={'flx1'}/>
                                                <div className={'mr20 w90'}>{'Cartons: ' + line.cartons}</div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                );
            });
        },
    );

    private handleExistingTripDateRangeChange = async (start : moment.Moment, end : moment.Moment) => {
        this.setLoading(true);
        try {
            const fromLoadDate = start.utc().unix() * 1000;
            const toLoadDate = end.utc().unix() * 1000;
            this.setState({ selectedFromDate: start, selectedToDate: end });
            const res = await TripHttpService.getAvailableTrips(fromLoadDate, toLoadDate);

            if (res && res.data) {
                this.setExistingTrips(res.data);
            }
        } catch (ex) {
            generalShowErrorSnackbar('Failed to load existing trips');
        } finally {
            this.setLoading(false);
        }
    };

    private getExistingTripRows = createSelector(
        [this.getExistingTrips],
        trips => trips?.filter(x => x.isActive) ?? [],
    );

    private formatTripLoadDate = (dateTime : moment.Moment) => {
        return dateTime ? moment.utc(dateTime, DATE_FORMAT_TIMESTAMP_FROM_API).format(DATE_FORMAT_DEFAULT) : '';
    };

    private isChecked = (id : number) => !!this.state.selectedTempExistingTrip && this.state.selectedTempExistingTrip.id === id;

    private handleExistingTripCheckboxChecked = (row : ITrip, checked : boolean) => {
        if (checked) {
            this.setState({ selectedTempExistingTrip: row });
        } else {
            this.setState({ selectedTempExistingTrip: undefined });
        }
    };

    private formatWeight : (x ?: number) => string = (x ?: number) => x?.toFixed(3) ?? '';

    private getExistingTripColumns = () => {
        const columns = [];
        columns.push(
            { title: 'Select', field: 'id', formatFunction: this.isChecked, width: 100,
                containerComponent: (row : ITrip, value : boolean) => {
                    return <Checkbox
                        checked={!!value}
                        onChange={(event, checked) => this.handleExistingTripCheckboxChecked(row, checked)}
                    />;
                },
            },
            { title: 'Id', field: 'id', enableFiltering: true, enableSorting: true },
            { title: 'Load Date', field: 'loadDate', formatFunction: this.formatTripLoadDate, sortFunction: compareDate },
            { title: 'Fleet Number', field: 'fleetNumber', enableFiltering: true, enableSorting: true },
            { title: 'Carrier', field: 'carrierId', formatFunction: this.getCarrierName, enableFiltering: true, enableSorting: true },
            { title: 'Truck Type', field: 'truckTypeId', formatFunction: this.getTruckTypeCode, enableFiltering: true, enableSorting: true },
            { title: 'Description', field: 'description', enableFiltering: true, enableSorting: true },
            { title: 'Registration Number', field: 'registrationNumber', enableFiltering: true, enableSorting: true },
            { title: 'Driver', field: 'driver', enableFiltering: true, enableSorting: true },
            { title: 'Container', field: 'container', enableFiltering: true, enableSorting: true },
            { title: 'Container Tare Weight', field: 'containerTareWeight' },
            { title: 'Seal Number', field: 'sealNumber', enableFiltering: true, enableSorting: true },
            { title: 'Truck Max Payload', field: 'id', formatFunction: this.getExistingTripTruckTypeMaxPayload },
            { title: 'Total Weight', field: 'totalWeight', formatFunction: this.formatWeight },
        );
        return columns;
    };

    private getSelectedAllAvailableStockButton = createSelector(
        [this.getStockViewData, this.getSelectedStocks],
        (stockViewData, selectedStocks) => {
            let disabled = false;
            const availableStock = stockViewData;

            if (availableStock.length < 1) {
                disabled = true;
            } else if (selectedStocks.length > 0) {
                if (availableStock.length === selectedStocks.length) {
                    if (availableStock.every(x => selectedStocks.some(y => y.id === x.id))) {
                        disabled = true;
                    }
                } else if (availableStock.every(x => selectedStocks.some(y => y.id === x.id))) {
                    disabled = true;
                }
            }

            return (
                <PillButton color={'secondary'}
                    className={'ml15 pl20 pr20 h35'}
                    disabled={disabled}
                    text={'Select All'}
                    onClick={this.getAllAvailableStock} />
            );
        },
    );

    public render() {
        const initialValues = this.getInitialFormValues(this.props, this.state);
        const selectedTrip = this.getSelectedTrip(this.props, this.state);

        return (
            <Screen isLoading={this.state.isLoading} isScrollable={false} isPadded={false}>
                <div className={'fdr'}>
                    <div className={'fdc flx1 pl15'}>
                        <div className={'fdr wfill aic'}>
                            <AutocompleteSelect
                                className={'w200 pt0 mt0'}
                                name={'markets'}
                                label={'Markets'}
                                options={this.getMarketOptions(this.props, this.state)}
                                onChange={this.onMarketChange}
                                isMulti
                                preventSelectAll
                                value={this.state.selectedMarkets ?? []}
                            />
                            <AutocompleteSelect
                                className={'w200 pt0 mt0'}
                                name={'commodity'}
                                label={'Commodity'}
                                options={this.getCommodityOptions(this.props)}
                                onChange={this.onCommodityChange}
                                value={this.state.selectedCommodity}
                            />
                            <AutocompleteSelect
                                className={'w200 pt0 mt0'}
                                name={'variety'}
                                label={'Variety'}
                                options={this.getVarietyOptions(this.props, this.state)}
                                onChange={this.onVarietyChange}
                                value={this.state.selectedVariety}
                            />
                        </div>
                        <div className={'fdr wfill aic'}>
                            <AutocompleteSelect
                                className={'w200 pt0 mt0'}
                                name={'grade'}
                                label={'Grade'}
                                options={this.getGradeOptions(this.props, this.state)}
                                onChange={this.onGradeChange}
                                value={this.state.selectedGrade}
                            />
                            <AutocompleteSelect
                                className={'w200 pt0 mt0'}
                                name={'pack'}
                                label={'Pack Type'}
                                options={this.getPackOptions(this.props, this.state)}
                                onChange={this.onPackChange}
                                value={this.state.selectedPack}
                            />
                            <AutocompleteSelect
                                className={'w200 pt0 mt0'}
                                name={'size'}
                                label={'Size'}
                                options={this.getSizeOptions(this.props, this.state)}
                                onChange={this.onSizeChange}
                                value={this.state.selectedSize}
                            />
                        </div>
                        <div className={'fdr wfill aic'}>
                            <AutocompleteSelect
                                className={'w200 pt0 mt0 mb0 pb0'}
                                name={'compliance'}
                                label={'Compliance'}
                                options={this.getComplianceOptions(this.props)}
                                onChange={this.onComplianceChange}
                                value={this.state.selectedCompliance}
                            />
                            <AutocompleteSelect
                                className={'w200 pt0 mt0 mb0 pb0'}
                                name={'storageUnits'}
                                label={'Storage Units'}
                                options={this.getStorageUnitOptions(this.props)}
                                onChange={this.onStorageUnitChange}
                                value={this.state.selectedStorageUnit}
                            />
                            <div className={'fdr w200 aic jcc'}>
                                Show Mixed
                                <Switch
                                    color={'primary'}
                                    checked={this.state.showMixedStock}
                                    onChange={this.showMixedStock} />
                            </div>
                        </div>
                    </div>
                    <div className={'fdr flx2 mb10 aic jcfs'}>
                        <div className={'w50'}/>
                        <AutocompleteSelect
                            className={'w250 pt0 mt0'}
                            name={'targetOrganizations'}
                            label={'Select Target Organization'}
                            options={this.getTargetOrganizationOptions(this.props)}
                            onChange={this.onTargetOrganizationChange}
                            value={this.state.selectedTargetOrganization}
                        />
                        <div className={'w10'}/>
                        <AutocompleteSelect
                            className={'w250 pt0 mt0'}
                            name={'destinationSite'}
                            label={'Select Destination Site'}
                            options={this.getDestinationSiteOptions(this.props, this.state)}
                            onChange={this.onDestinationSiteChange}
                            value={this.state.selectedDistinationSite}
                        />
                    </div>
                </div>
                <div className={'fdr wfill hfill p10'}>
                    {/* Available Stock Panel */}
                    <Card style={{ height: 'calc(70vh)' }} className={'fdc oya flx1'}>
                        <div style={{ opacity: 2 }} className={'wfill post0 zi999 posstick aic jcc fs18 pt10 pb5 bcp cw'}>
                            <CustomTooltip title={'Refresh Available Stock'}>
                                <IconButton color={'inherit'} onClick={() => this.refreshAvailableStockData(false, this.state.selectedMarkets.map(x => Number(x.value)), Number(this.state.selectedPack?.value), Number(this.state.selectedSize?.value), Number(this.state.selectedGrade?.value))}>
                                    <Icon>refresh</Icon>
                                </IconButton>
                            </CustomTooltip>
                            <div className={'flx1'}/>
                            {'Available Stock'}
                            <div className={'flx1'}/>
                            <CustomTooltip title={'Select all available stock'}>
                                {this.getSelectedAllAvailableStockButton(this.props, this.state)}
                            </CustomTooltip>
                            <div className={'w10'}/>
                        </div>
                        {this.renderTable(this.props, this.state)}
                    </Card>
                    <div className={'w20'}/>
                    {/* Selected Stock Panel */}
                    <Card style={{ height: 'calc(70vh)' }} className={'fdc flx1'}>
                        <div className={'wfill fdc post0 zi1 posstick pb5 bcp cw'}>
                            <div className={'fdr wfill aic jcc pb0 mb0'}>
                                <div className={'flx1'}/>
                                <div className={'w20'}/>
                                <div className={'pr10'}>Total Cartons: {this.state.selectedStocks.map(x => x.cartons).reduce((sum, current) => sum + current, 0)}</div>
                                <div className={'pr10'}>Total Inners: {this.getTotalInners(this.state.selectedStocks)}</div>
                            </div>
                            <div className={'fdr p0 m0'}>
                                <CustomTooltip title={'Remove all selected stock'}>
                                    <IconButton className={'p0 m0'} size={'small'} color={'inherit'} onClick={() => this.clearSelectedStock()}>
                                        <Icon className={'cw'}>delete</Icon>
                                    </IconButton>
                                </CustomTooltip>
                                <div className={'flx2'}/>
                                <div className={'fs18'}>{'Selected Stock'}</div>
                                <div className={'flx1'}/>
                                <div className={'w30'}/>
                                <div className={'pr10'}>Total Gross Weight(kg): {this.state.selectedStocks.map(x => x.grossWeight).reduce((sum, current) => sum + current, 0)}</div>
                            </div>
                            <div className={'fdr jcfe'}>
                                <div className={'pr10'}>Total Stock: {this.state.selectedStocks.length}</div>
                            </div>
                        </div>
                        <div className={'h10'}/>
                        <div className={'wfill fdc flx1 oya pl10 pr10'}>
                            {this.renderSelectedStockRows(this.props, this.state)}
                        </div>
                        <div className={'fdr jcfe'}>
                            <CustomTooltip title={`${this.props.selectedOrganizationIds?.length !== 1 ? 'Please select only one organization' : 'Create Draft'}`}>
                                <PillButton color={'secondary'}
                                    className={'ml15 pl20 pr20 h35'}
                                    disabled={this.props.selectedOrganizationIds?.length !== 1 || !this.state.selectedDistinationSite || this.state.selectedStocks.length < 1}
                                    text={'SEND'}
                                    onClick={this.createDispatchDraftMutliSalesPointCheck}
                                />
                            </CustomTooltip>
                            <div className={'w10'}/>
                        </div>
                        <div className={'h10'}/>
                    </Card>
                    <div className={'w20'}/>
                    {/* Created Dispatch drafts Panel */}
                    <Card style={{ height: 'calc(70vh)' }} className={'fdc flx1'}>
                        <div className={'wfill post0 zi1 posstick aic jcc fs18 pt10 pb5 bcp cw'}>
                            <CustomTooltip title={'Refresh Dispatches'}>
                                <IconButton color={'inherit'} onClick={() => this.refreshDispatchDrafts()}>
                                    <Icon>refresh</Icon>
                                </IconButton>
                            </CustomTooltip>
                            <div className={'flx1'}/>
                            {'Dispatches'}
                            <div className={'flx1'}/>
                        </div>
                        <div className={'flx1 fdc pl10 pr10 oya'}>
                            {this.state.dispatchDrafts.length > 0 &&
                                this.state.dispatchDrafts.map((dispatch) => {
                                    return (
                                        <div>
                                            <Card
                                                style={this.state.createdDrafts.find(x => x.id === dispatch.id) ? { backgroundColor: randomColor({
                                                    hue: 'random',
                                                    format: 'rgba',
                                                    alpha: materialTheme.custom.randomColor.alphaValue,
                                                    seed: dispatch.id * 1000000000 }), minHeight: 90 } : {}}
                                                className={'wfill h90 fdc'}>
                                                <div style={{ minHeight: 40 }} className={'fdr aic p10 h40'}>
                                                    <CustomTooltip title={'Selected Dispatch to send to instructed'}>
                                                        <Checkbox
                                                            checked={!!this.state.selectedDispatches.find(x => x.id === dispatch.id)}
                                                            onChange={(event, checked) => this.handleDispatchCheckboxChecked(dispatch, checked)}
                                                        />
                                                    </CustomTooltip>
                                                    <div className={'fs14 aic w100'}>{formatDateTimeToDateOnly(dispatch.loadDate)}</div>
                                                    <div className={'flx1'}/>
                                                    <div className={'fs16 aic fw500 pr10'}>{dispatch.dispatchCode}</div>
                                                    <div className={'flx1'}/>
                                                    <div className={`fs14 aic w100 ${!!dispatch.tripId ? 'fwb' : ''}`}>{'Trip: ' + (!!dispatch.tripId ? dispatch.tripId : 'No Trip')}</div>
                                                    <CustomTooltip title={'Remove pallets'}>
                                                        <IconButton onClick={() => this.onDispatchEditClick(dispatch)}>
                                                            <Icon>edit</Icon>
                                                        </IconButton>
                                                    </CustomTooltip>
                                                </div>
                                                <div className={'fdr aic jcc p10'}>
                                                    <div className={'w100 pl10'}>{`Pallets: ${dispatch?.dispatchLines.filter(line => line.isActive)?.length}`}</div>
                                                    <div className={'flx1'}/>
                                                    <div className={'fs16 fw550'}>
                                                        {this.getSiteShortDescription(dispatch.sourceSiteId ?? 0) + ' - ' + this.getSiteShortDescription(dispatch.destinationSiteId ?? 0)}
                                                    </div>
                                                    <div className={'flx1'}/>
                                                    <div className={'w100 fdr jcfe pr10'}>{`Created On: ${formatDateTimeToDateOnly(dispatch.createdOn ?? '')}`}</div>
                                                    <CustomTooltip title={'Delete dispatch draft'}>
                                                        <IconButton onClick={() => this.onDispatchDraftDeleteClick(dispatch.id)}>
                                                            <Icon>delete</Icon>
                                                        </IconButton>
                                                    </CustomTooltip>
                                                </div>
                                            </Card>
                                            <div className={'h10'}/>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <Divider className={'wfill mb5'} />
                        <div className='fdr mt10 ml10 mr10 pl10'>
                            <div className={'fw500 mr5'}> Selected Trip:</div>
                            {selectedTrip?.id}
                        </div>
                        {/* Selected Trip Detail */}
                        <div className={'wfill fdr mb10 ml10 mr10'}>
                            <div className={'fdc jcc p10'}>
                                <div className={'fdr aic'}>
                                    <div className={'fw500 mr5'}>{'Load Date: '}</div>
                                    {formatDateTime(selectedTrip?.loadDate ?? '')}
                                </div>
                                <div className={'h10'}/>
                                <div className={'fdr aic'}>
                                    <div className={'fw500 mr5'}>{'Description: '}</div>
                                    {selectedTrip?.description}
                                </div>
                                <div className={'h10'}/>
                                <div className={'fdr aic'}>
                                    <div className={'fw500 mr5'}>{'Driver: '}</div>
                                    {selectedTrip?.driver}
                                </div>
                                <div className={'h10'}/>
                                <div className={'fdr aic'}>
                                    <div className={'fw500 mr5'}>{'Container: '}</div>
                                    {selectedTrip?.container}
                                </div>
                                <div className={'h10'}/>
                                <div className={'fdr aic'}>
                                    <div className={'fw500 mr5'}>{'Container Tare Weight: '}</div>
                                    {selectedTrip?.containerTareWeight?.toFixed(3)}
                                </div>
                                <div className={'h10'}/>
                                <div className={'fdr aic'}>
                                    <div className={'fw500 mr5'}>{'Reg Nr: '}</div>
                                    {selectedTrip?.registrationNumber}
                                </div>
                                <div className={'h10'}/>
                                <div className={'fdr aic'}>
                                    <div className={'fw500 mr5'}>{'Total Pallet Gross Weight(kg): '}</div>
                                    {this.getSelectedDraftsTotalGrossWeight(this.props, this.state)}
                                </div>
                            </div>
                            <div className={'flx1'}/>
                            <div className={'fdc jcc p10'}>
                                <div className={'fdr aic'}>
                                    <div className={'fw500 mr5'}>{'Fleet Nr: '}</div>
                                    {selectedTrip?.fleetNumber}
                                </div>
                                <div className={'h10'}/>
                                <div className={'fdr aic'}>
                                    <div className={'fw500 mr5'}>{'Carrier: '}</div>
                                    {this.getCarrierName(selectedTrip?.carrierId) ?? ''}
                                </div>
                                <div className={'h10'}/>
                                <div className={'fdr aic'}>
                                    <div className={'fw500 mr5'}>{'Truck Type: '}</div>
                                    {this.getTruckTypeCode(selectedTrip?.truckTypeId) ?? ''}
                                </div>
                                <div className={'h10'}/>
                                <div className={'fdr aic'}>
                                    <div className={'fw500 mr5'}>{'Devices: '}</div>
                                    {this.getDevices(selectedTrip?.temperatureUnits ?? []) ?? ''}
                                </div>
                                <div className={'h10'}/>
                                <div className={'fdr aic'}>
                                    <div className={'fw500 mr5'}>{'Seal Nr: '}</div>
                                    {selectedTrip?.sealNumber}
                                </div>
                                <div className={'h10'}/>
                                <div className={'fdr aic'}>
                                    <div className={'fw500 mr5'}>{'Truck Max Payload (kg): '}</div>
                                    {this.getSelectedTripTruckTypeMaxPayload(this.props, this.state)}
                                </div>
                                <div className={'h10'}/>
                                <div className={'fdr aic'}>
                                    <div className={'fw500 mr5'}>{'Total Trip Gross Weight(kg): '}</div>
                                    {this.getSelectedTripTotalGrossWeight(this.props, this.state)}
                                </div>
                            </div>
                            <div className={'flx1'}/>
                        </div>
                        <Divider className={'wfill mb5'} />
                        <div className={'fdr'}>
                            <PillButton color={'secondary'}
                                disabled={this.state.selectedDispatches.length < 1 || !!this.state.selectedExistingTrip}
                                className={'ml15 pl20 pr20 h35'}
                                text={'New Trip'}
                                onClick={this.onUpdateTripInfoOpen} />
                            <PillButton color={'secondary'}
                                disabled={this.state.selectedDispatches.length < 1 || !!this.state.selectedTrip}
                                className={'ml15 pl20 pr20 h35'}
                                text={'Existing Trip'}
                                onClick={this.openExistingTripPopup} />
                            <PillButton color={'secondary'}
                                disabled={this.state.selectedDispatches.filter(x => !!x.tripId).length < 1 && !this.state.selectedTrip && !this.state.selectedExistingTrip}
                                className={'ml15 pl20 pr20 h35'}
                                text={'Unassign Trip'}
                                onClick={this.unassignTrips} />
                            <div className={'flx1'}/>
                            <CustomTooltip title={`${!this.state.selectedTrip ? 'Please add a trip' : 'Move Dispatch to Instructed'}`}>
                                <PillButton color={'secondary'}
                                    className={'ml15 pl20 pr20 h35'}
                                    disabled={this.state.selectedDispatches.length < 1}
                                    text={'CREATE'}
                                    onClick={this.createInstructedDispatches}
                                />
                            </CustomTooltip>
                            <div className={'w10'}/>
                        </div>
                        <div className={'h10'}/>
                    </Card>
                </div>
                {/* Edit Dialog */}
                <PackmanDialog
                    title={'Trip'}
                    isEdit={!!this.state.selectedTrip}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isTripEditFormOpen}
                    onClose={this.onUpdateTripClose}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onTripSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={TripFormValues.formSchema}
                        component={TripForm}/>
                </PackmanDialog >
                {/* Select Stock Amount Dialog */}
                <PackmanDialog
                    title={'Stock'}
                    isInfo
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isStockAmountDialogOpen}
                    onClose={this.closeStockAmountPopup}>
                    <div className={'fdc p10 jcc w300 h200'}>
                        <Typography className={'mb5'}>Please select amount of stock to use</Typography>
                        <TextField
                            autoFocus={true}
                            value={this.state.amountOfStock ?? ''}
                            label={'Amount'}
                            name={'stockAmount'}
                            type={'number'}
                            onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.setState({ amountOfStock: Number(e.target.value) })}
                            onKeyDown={this.onEnterPressed}
                            onFocus={(event) => { event.currentTarget.select(); }}
                        />
                        <Typography className={'m0 p0 cred'}>{this.getTextInputError()}</Typography>
                        <div className={'h10'}/>
                        <div className={'fdr jcfe'}>
                            <PillButton color={'secondary'}
                                disabled={!this.state.amountOfStock || !this.state.maxAmountOfStock || (this.state.amountOfStock > this.state.maxAmountOfStock) || !!(this.state.amountOfStock < 0)}
                                className={'ml15 pl20 pr20 h35'}
                                text={'SUBMIT'}
                                onClick={this.getStocks} />
                        </div>
                    </div>
                </PackmanDialog >
                {/* Remove Pallet dialog */}
                <PackmanDialog
                    title={`Dispatch ${this.state.editingDispatch?.dispatchCode}`}
                    isInfo
                    isLoading={this.state.isLoading}
                    isOpen={!!this.state.editingDispatch}
                    onClose={this.onDispatchEditClose}>
                    <div style={{ maxHeight: 350, height: 350, minHeight: 350 }} className={'fdc w300 oya'}>
                        {!!this.state.editingDispatch && !!this.props.stocks.find(x => this.state.editingDispatch?.dispatchLines?.some(y => y.currentStockId === x.id)) ?
                            this.state.editingDispatch.dispatchLines.filter(x => x.isActive).map((line) => {
                                return (
                                    <div className={'fdc'}>
                                        <div className={'fdr aic wfill'}>
                                            <CustomTooltip title={'Remove pallet from dispatch'}>
                                                <IconButton onClick={() => this.openDeleteStockConfirmationDialog(line.id)}>
                                                    <Icon>delete</Icon>
                                                </IconButton>
                                            </CustomTooltip>
                                            <div className={'flx1'}/>
                                            <div className={'fdc'}>
                                                <Typography className={'mb2'}>{this.getStockBarcode(line.currentStockId)}</Typography>
                                                <div className={'fdr mb5'}>{this.getPack(line?.packId ?? 0) + ' ' + this.getGrade(line?.gradeId ?? 0) + ' ' + this.getSize(line?.sizeId ?? 0) + ' ' + this.getColour(line?.colourId ?? 0)}</div>
                                            </div>
                                            <div className={'flx1'}/>
                                        </div>
                                        <Divider className={'wfill mb5'} />
                                    </div>
                                );
                            })
                            :
                            <div className={'wfill hfill'}></div>
                        }
                    </div>
                </PackmanDialog >
                {/* Filter Sites dialog */}
                <PackmanDialog
                    title={'Select Sites'}
                    isInfo
                    isLoading={this.state.isLoading}
                    isOpen={!!this.state.isFilterSitesPopupOpen}
                    onClose={this.closeFilterSitesPopup}>
                    <div style={{ maxHeight: 350 }} className={'fdc w300 oya'}>
                        {
                            this.props.sites.filter(x => this.props.selectedSiteIds?.some(y => y === x.id) && x.isActive && x.organizationIds?.some(y => this.props.selectedOrganizationIds?.some(z => z === y))).map((site) => {
                                return (
                                    <div className={'aic pb2 m0'}>
                                        <Checkbox
                                            className={'pt0 pb0 pr5 m0'}
                                            size={'small'}
                                            checked={!!this.state.tempSelectedSites.find(x => x.id === site.id)}
                                            onChange={(event, checked) => this.handleSelectedSiteCheckboxChecked(site, checked)}
                                        />
                                        <Typography variant={'caption'}>{`(${site.shortDescription}) ${site.description}`}</Typography>
                                    </div>
                                );
                            })
                        }
                        <div className={'fdr jcfe pr5 pb5'}>
                            <PillButton
                                color={'secondary'}
                                className={'pl20 pr20 h35'}
                                text={'Confirm'}
                                onClick={this.confirmSelectedSiteFilters} />
                        </div>
                    </div>
                </PackmanDialog >
                {/* Existing Trip dialog */}
                <PackmanDialog
                    title={'Existing Trips'}
                    isInfo
                    maxWidth={'lg'}
                    fullWidth={true}
                    isLoading={this.state.isLoading}
                    isOpen={!!this.state.isExistingTripPopupOpen}
                    onClose={this.closeExistingTripPopup}>
                    <div className={'fdc h500 aic jcc p10'}>
                        <div className={'fdr wfill'}>
                            <div className={'flx1'}/>
                            <TransactionFilter
                                enableApplyButton={false}
                                enableCloseButton={true}
                                closeButtonTitle={'Close'}
                                className={'pt5 mt10 pt10 mr15 mb10'}
                                selectedFromDate={this.state.selectedFromDate}
                                selectedToDate={this.state.selectedToDate}
                                handleDateRangeChange={this.handleExistingTripDateRangeChange}
                                onApplyClick={() => null}
                            />
                        </div>
                        <Card elevation={0} className={'aic fdc wfill oya'}>
                            <CustomTable<ITrip>
                                enableFiltering
                                enableSorting
                                columns={this.getExistingTripColumns()}
                                rows={this.getExistingTripRows(this.props, this.state)}
                                initialSortOrder={[{ columnName: 'loadDate_Load Date', direction : 'desc' }]}
                                pageHeight={400}
                                isActive={(row : ITrip) => row.isActive}
                            />
                        </Card>
                        <div className={'fdr aife jcfe wfill mt10'}>
                            <Button
                                className={'fwb h35'}
                                variant='text'
                                disabled={!this.state.selectedTempExistingTrip}
                                onClick={this.clearExistingTripSelection}>
                                    Unassign
                            </Button>
                            <PillButton
                                color={'secondary'}
                                className={'ml15 pl20 pr20 h35'}
                                text={'Assign'}
                                disabled={!this.state.selectedTempExistingTrip}
                                onClick={this.onAssignExistingTrip}
                            />
                        </div>
                    </div>
                </PackmanDialog >
                {/* Mixing Sales Point Check Dialog */}
                <PackmanDialog
                    title={'Error!'}
                    isError
                    isLoading={this.state.isLoading}
                    isOpen={!!this.state.showMixedSalesPointErrorDialog}
                    onClose={this.palletsMixingSalesPointsErrorDialogNoClicked}>
                    <div className={'fdc p10'}>
                        {this.getMixingSalesPointErrorDialogMessage(this.props, this.state)}
                        <div className={'fdr flx1 mt20'}>
                            <CustomTooltip title={'This will split pallets into separate dispatches'}>
                                <PillButton color={'secondary'}
                                    className={'ml15 pl20 pr20 h35'}
                                    text={'Split'}
                                    onClick={() => this.createDispatchDrafts(true)}
                                    disabled={this.state.isLoading}
                                />
                            </CustomTooltip>
                            <div className={'flx1'}/>
                            <Button
                                className={'fwb h35'}
                                variant='text'
                                color='primary'
                                disabled={this.state.isLoading}
                                onClick={this.palletsMixingSalesPointsErrorDialogNoClicked}>
                                    CLOSE
                            </Button>
                        </div>
                    </div>
                </PackmanDialog >
                {/* Delete Dispatch Draft dialog */}
                {this.state.isDeletePopupOpen &&
                    <DeleteConfirmationDialog
                        isLoading={this.state.isLoading}
                        onSubmit={this.deleteDispatchDraft}
                        onclose={this.closeDeleteConfirmationPopup}
                        isOpen={this.state.isDeletePopupOpen}
                        title={'Dispatch Draft'}/>
                }
                {/* Destination Site Warning */}
                <PackmanDialog
                    isOpen={this.state.showDestinationSiteWarningPopup}
                    title={'Destination Site Conflict'}
                    isInfo
                    isLoading={this.state.isLoading}
                    onClose={this.closeDestinationSiteWarningPopup}
                >
                    <div className={'p20 fdc aic jcc wfill'}>
                        <div className={'fdr mb10 wfill'}>
                            <Typography className={'aic wfill'} variant={'subtitle2'}>
                                {'These selected stocks is already at the selected destination site.'}
                            </Typography>
                        </div>
                        <div className={'fdc oya mxh250 wfill'}>
                            {
                                this.getSelectedStockAtDestinationSite(this.props, this.state).map((stock) => {
                                    return (
                                        <div className={'fdc aic wfill'}>
                                            <Typography className={'mb2'}>{stock.barcode}</Typography>
                                            <Divider className={'wfill mb5'} />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </PackmanDialog>
                <ConfirmationPrompt
                    title={'Remove pallet'}
                    open={this.state.isRemoveStockConfirmDialogOpen}
                    isLoading={this.state.isLoading}
                    message={'Are you sure you want to remove this pallet from the dispatch draft?'}
                    onOkClicked={this.removeDispatchLine}
                    onCancelClicked={this.closeDeleteStockConfirmationDialog}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        stockViewData: state.data.multiDispatchStockViews,
        auth: state.auth,
        sites: state.masterData.sites,
        packs: state.masterData.packs,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        sizes: state.masterData.sizes,
        grades: state.masterData.grades,
        colours: state.masterData.colours,
        farms: state.masterData.farms,
        selectedSiteIds: state.data.selectedSiteIds,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        carriers: state.masterData.carriers,
        truckTypes: state.masterData.truckTypes,
        organizations: state.masterData.organizations,
        markets: state.masterData.markets,
        regions: state.masterData.regions,
        stocks: state.data.stocks,
        trips: state.data.trips,
        dispatchInstructions: state.data.dispatchInstructions,
        compliances: state.data.compliances,
        storageUnits: state.masterData.storageUnits,
        salesPoints: state.masterData.salesPoints,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    {
        dataSetMultiDispatchStockViews,
        dataSetStocks,
        dataSetCompliances,
        dataSetTrips,
        dataSetEditingTrip,
        dataSetDispatchInstructions,
        dataSetDispatchInstructionStocks,
    },
    dispatcher,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MultiDispatchWizardExport);

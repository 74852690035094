import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IBatch } from '../../../@types/model/batch/batch';
import { ILotRelatedData } from '../../../@types/model/lot/lotRelatedData';
import qs from 'qs';
import { IBatchSummaryRelatedData } from '../../../@types/model/batch/batchSummaryRelatedData';
import { IBatchView } from '../../../@types/model/batch/batchView';
import { IBatchViewRelatedData } from '../../../@types/model/batch/batchViewRelatedData';
import { IBatchReportRelatedData } from '../../../@types/model/batch/batchReportRelatedData';
import { IBatchIdCode } from '../../../@types/model/batch/batchidcode';
import { IMultiBatchReportData } from '../../../@types/model/batch/multiBatchReportData';
import { IBatchAppView } from '../../../@types/model/batch/batchAppView';

export default class BatchHttpService {
    public static getBatchData() : AxiosPromise<Array<IBatch>> {
        return axios.get(`${PACKMAN_API_URL}Batch/GetAllWeb`);
    }

    public static getBatchAppView(batchGuid : string) : AxiosPromise<IBatchAppView> {
        return axios.get(`${PACKMAN_API_URL}Batch/GetAppBatch`, {
            params: {
                batchGuid,
            },
        });
    }

    public static getMultiBatchReportData(data : IMultiBatchReportData) : AxiosPromise<Array<IBatchIdCode>> {
        return axios.post(`${PACKMAN_API_URL}Batch/GetMultiBatchReportData`, data);
    }

    public static addOrUpdateBatch(data : IBatch) : AxiosPromise<IBatch> {
        return axios.post(`${PACKMAN_API_URL}Batch/PostBatch`, data);
    }

    public static addOrchardsToBatch(batchId : number, orchardIds : Array<number>) : AxiosPromise<IBatchView> {
        return axios.post(`${PACKMAN_API_URL}Batch/AddOrchardsToBatch`, {
            batchId,
            orchardIds,
        });
    }

    public static addDepartmentsToBatch(batchId : number, departmentIds : Array<number>) : AxiosPromise<IBatchView> {
        return axios.post(`${PACKMAN_API_URL}Batch/AddDepartmentsToBatch`, {
            batchId,
            departmentIds,
        });
    }

    public static endBatch(batchId : number) : AxiosPromise<IBatchView> {
        return axios.post(`${PACKMAN_API_URL}Batch/EndBatchWeb`, { batchId });
    }

    public static endMultiBatch(batchIds : Array<number>) : AxiosPromise<Array<IBatchView>> {
        return axios.post(`${PACKMAN_API_URL}Batch/EndMultiBatchWeb`, { batchIds });
    }

    public static resumeBatch(batchId : number) : AxiosPromise<IBatchView> {
        return axios.post(`${PACKMAN_API_URL}Batch/ResumeBatchWeb`, { batchId });
    }

    public static getBatchTransactionData(fromDateUnix ?: number, toDateUnix ?: number, siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<ILotRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Batch/WebTransactions`, {
            params: {
                fromDateUnix,
                toDateUnix,
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getBatchViews(fromDateUnix ?: number, toDateUnix ?: number, siteIds ?: Array<number>, loadMasterData ?: boolean, batchId ?: number) : AxiosPromise<IBatchViewRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Batch/WebTransactionViews`, {
            params: {
                fromDateUnix,
                toDateUnix,
                siteIds,
                loadMasterData,
                batchId,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getBatchView(id ?: number) : AxiosPromise<IBatch> {
        return axios.get(`${PACKMAN_API_URL}Batch/GetBatchView`, {
            params: {
                id,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getBatchesToEnd(fromDateUnix ?: number, toDateUnix ?: number, siteIds ?: Array<number>) : AxiosPromise<Array<IBatch>> {
        return axios.get(`${PACKMAN_API_URL}Batch/GetBatchesAvailableToEnd`, {
            params: {
                fromDateUnix,
                toDateUnix,
                siteIds,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getBatchSummaryRelatedData(batchId ?: number, loadMasterData ?: boolean) : AxiosPromise<IBatchSummaryRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Batch/SummaryData`, {
            params: {
                batchId,
                loadMasterData,
            },
        });
    }

    public static getBatchReportRelatedData(batchId ?: number, loadMasterData ?: boolean) : AxiosPromise<IBatchReportRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Batch/GetReportRelatedData`, {
            params: {
                batchId,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getMultiBatchReportRelatedData(batchIds : Array<number>, loadMasterData ?: boolean) : AxiosPromise<IBatchReportRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Batch/GetMultiReportRelatedData`, {
            params: {
                batchIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static deleteBatch(batchId : number, deleteReason : string) : AxiosPromise<IBatchView> {
        return axios.delete(`${PACKMAN_API_URL}Batch/Delete`, {
            params : {
                batchId,
                deleteReason,
            },
        });
    }
}

import moment from 'moment';
import * as Yup from 'yup';
import { DATEPICKER_FORMAT_DEFAULT } from '../../../../appConstants';
import { IOptionType } from '../../../helper';
import { ICommodity } from '../commodity/commodity';
import { ISeason } from './season';

export interface ISeasonFormValues {
    id : number;
    guid : string;
    name : string;
    code : string;
    startDate : moment.Moment;
    endDate : moment.Moment | null;
    commodity ?: IOptionType;
    isActive : boolean;
}

export class SeasonFormValues implements ISeasonFormValues {
    public id : number;
    public guid : string;
    public name : string;
    public code : string;
    public startDate : moment.Moment;
    public endDate : moment.Moment | null;
    public commodity ?: IOptionType;
    public isActive : boolean;

    public constructor(
        season ?: ISeason,
        commodities ?: Array<ICommodity>,
        selectedCommodity ?: ICommodity,
    ) {
        this.initializeDefaultValues(selectedCommodity);
        if (season) {
            this.id = season.id;
            this.guid = season.guid;
            this.startDate = moment(season.startDate, DATEPICKER_FORMAT_DEFAULT);
            this.endDate = season.endDate != null ? moment(season.endDate, DATEPICKER_FORMAT_DEFAULT) : null;
            this.name = season.name;
            this.code = season.code;

            this.commodity = commodities ? {
                label: commodities.find(x => x.id === season.commodityId)?.name ?? '',
                value: season.commodityId ?? undefined,
            } : undefined;

            this.isActive = season.isActive;
        }
    }

    public initializeDefaultValues = (selectedCommodity ?: ICommodity) => {
        this.id = 0;
        this.guid = '';
        this.name = '';
        this.code = '';
        this.startDate = moment();
        this.endDate = null;
        this.commodity = undefined;
        this.isActive = true;

        if (selectedCommodity) {
            this.commodity = {
                label: selectedCommodity?.name ?? '',
                value: selectedCommodity?.id,
            };
        }
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        code: Yup.string().required('Required'),
        startDate: Yup.date().required('Required'),
        endDate: Yup.date().nullable(),
        commodity: Yup.object().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

import * as Yup from 'yup';
import { IInfoBoardData } from './infoBoardData';

export interface IInfoBoardDataFormValues {
    id : number;
    guid : string;
    heading : string;
    body : string;
    isActive : boolean;
}

export class InfoBoardDataFormValues implements IInfoBoardDataFormValues {
    public id : number;
    public guid : string;
    public heading : string;
    public body : string;
    public isActive : boolean;

    public constructor(infoBoardData ?: IInfoBoardData) {
        this.initializeDefaultValues();
        if (infoBoardData) {
            this.id = infoBoardData.id;
            this.guid = infoBoardData.guid;
            this.heading = infoBoardData.heading;
            this.body = infoBoardData.body;
            this.isActive = infoBoardData.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.heading = '';
        this.body = '';
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        heading: Yup.string().required('Required'),
    });
}

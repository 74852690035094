import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IGrade } from '../../../@types/model/masterData/grade/grade';

export default class GradeHttpService {
    public static getGradeData() : AxiosPromise<Array<IGrade>> {
        return axios.get(`${PACKMAN_API_URL}Grade/GetAllWeb`);
    }

    public static addOrUpdateGrade(data : IGrade) : AxiosPromise<IGrade> {
        return axios.post(`${PACKMAN_API_URL}Grade/PostGrade`, data);
    }

    public static deleteGrade(gradeId : number) : AxiosPromise<IGrade> {
        return axios.delete(`${PACKMAN_API_URL}Grade/Delete`, {
            params : { gradeId },
        });
    }
}

import * as React from 'react';
import AppBar from '@mui/material/AppBar/AppBar';
import Dialog from '@mui/material/Dialog/Dialog';
import Typography from '@mui/material/Typography/Typography';
import Toolbar from '@mui/material/Toolbar/Toolbar';
import SlideUpDialogTransition from '../animated/SlideUpDialogTransition';
import IconButton from '@mui/material/IconButton/IconButton';
import Icon from '@mui/material/Icon/Icon';
import CircularProgress from '@mui/material/CircularProgress';
import { ChevronLeft } from '@mui/icons-material';
import { navBack } from '../../store/nav/Actions';
import CustomTooltip from '../tooltip/tooltip';

interface IPackmanDialogProps {
    isEdit ?: boolean;
    isInfo ?: boolean;
    isError ?: boolean;
    isWarning ?: boolean;
    isOpen : boolean;
    isLoading ?: boolean;
    isCloseDisabled ?: boolean;
    onClose ?: ((event ?: {}, reason ?: 'backdropClick' | 'escapeKeyDown') => void) | undefined;
    title ?: string;
    fullScreen ?: boolean;
    maxWidth ?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    fullWidth ?: boolean;
    className ?: string;
    style ?: React.CSSProperties;
}

interface IPackmanDialogState {
}

export default class PackmanDialog extends React.Component<IPackmanDialogProps, IPackmanDialogState> {

    constructor(props : IPackmanDialogProps) {
        super(props);
    }

    public render() {
        return (
            <Dialog
                style={this.props.style}
                fullWidth={this.props.fullWidth}
                maxWidth={this.props.maxWidth ? this.props.maxWidth : undefined}
                open={this.props.isOpen}
                onClose={this.props.onClose}
                className={'fdc' + this.props.className}
                TransitionComponent={SlideUpDialogTransition}
                fullScreen={this.props.fullScreen}>
                <AppBar position='static' elevation={0}>
                    <Toolbar variant='dialog' className={'fdr'}>
                        <div className={'flx3'}>
                            <Typography color='inherit' variant='h6'>{(this.props.isInfo || this.props.isError ? '' : (this.props.isEdit ? 'Edit ' : 'Create ')) + this.props.title}</Typography>
                        </div>
                        {/* { this.props.onClose && // TODO: find a way to get this working (( supposed to jump back to where the link jumping started from but hard to determine ))
                         <div className={'fdr jcfe aic' + (this.props.fullScreen ? '' : ' pt10 pb10')}>
                            <IconButton disabled className={'cw jcfe'} onClick={this.props.onClose}>
                                <FirstPage/>
                            </IconButton>
                        </div> } */}
                        { this.props.onClose && this.props.fullScreen && // TODO: find a way to show this only when needed
                            <div className={'fdr jcfe aic' + (this.props.fullScreen ? '' : ' pt10 pb10')}>
                                <IconButton className={'cw jcfe'} onClick={navBack}>
                                    <ChevronLeft/>
                                </IconButton>
                            </div> }
                        { this.props.onClose &&
                            <div className={'fdr jcfe aic' + (this.props.fullScreen ? '' : ' pt10 pb10')}>
                                <CustomTooltip title={this.props.isCloseDisabled ? 'Please select atleast one organization and site' : 'Close'}>
                                    <IconButton className={'cw jcfe'} onClick={this.props.onClose} disabled={this.props.isCloseDisabled}>
                                        <Icon className={'cw'}>close</Icon>
                                    </IconButton>
                                </CustomTooltip>
                            </div> }
                    </Toolbar>
                </AppBar>
                {this.props.isLoading &&
                    <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                        <div className={'posr aic jcc h50 w50'}>
                            <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                                <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                            </div>
                            <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                        </div>
                    </div>
                }
                {this.props.children}
            </Dialog>
        );
    }
}

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IAgreementCode } from '../../../@types/model/masterData/agreementCode/agreementCode';

export default class AgreementCodeHttpService {
    public static getAgreementCodeData() : AxiosPromise<Array<IAgreementCode>> {
        return axios.get(`${PACKMAN_API_URL}AgreementCode/GetAllWeb`);
    }

    public static addOrUpdateAgreementCode(data : IAgreementCode) : AxiosPromise<IAgreementCode> {
        return axios.post(`${PACKMAN_API_URL}AgreementCode/PostAgreementCode`, data);
    }

    public static syncAgreementCodes() : AxiosPromise<boolean> {
        return axios.post(`${PACKMAN_API_URL}AgreementCode/SyncAgreementCodes`);
    }
}

import axios, { AxiosPromise } from 'axios';
import { IPrintServer } from '../../../@types/model/masterData/printServer/printServer';
import { PACKMAN_API_URL } from '../../../appConstants';

export default class PrintServerHttpService {
    public static getPrintServerData() : AxiosPromise<Array<IPrintServer>> {
        return axios.get(`${PACKMAN_API_URL}PrintServer/GetAllWeb`);
    }

    public static addOrUpdatePrintServer(data : IPrintServer) : AxiosPromise<IPrintServer> {
        return axios.post(`${PACKMAN_API_URL}PrintServer/PostPrintServer`, data);
    }

    public static deletePrintServer(printServerId : number) : AxiosPromise<IPrintServer> {
        return axios.delete(`${PACKMAN_API_URL}PrintServer/Delete`, {
            params : { printServerId },
        });
    }
}

import * as React from 'react';
import { LayersControl, TileLayer } from 'react-leaflet';

interface IStandardLayerControlProps {
    position : 'topleft' | 'topright' | 'bottomleft' | 'bottomright';
}

interface IStandardLayerControlState {}

export default class StandardLayerControl extends React.Component<IStandardLayerControlProps, IStandardLayerControlState> {
    constructor(props : IStandardLayerControlProps) {
        super(props);
    }

    public render = () => {
        return (
            <LayersControl position={this.props.position}>
                <LayersControl.BaseLayer name={'Satelitte'}>
                    <TileLayer
                        zIndex={1}
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        url='http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name={'Terrain'} checked>
                    <TileLayer
                        zIndex={1}
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        url='http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name={'Map'}>
                    <TileLayer
                        zIndex={1}
                        maxZoom={20}
                        url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}'
                    />
                </LayersControl.BaseLayer>
            </LayersControl>
        );
    };
}

import axios, { AxiosPromise } from 'axios';
import { IOutlet } from '../../../@types/model/masterData/outlet/outlet';
import { PACKMAN_API_URL } from '../../../appConstants';

export default class OutletHttpService {
    public static getOutletData() : AxiosPromise<Array<IOutlet>> {
        return axios.get(`${PACKMAN_API_URL}Outlet/GetAllWeb`);
    }

    public static addOrUpdateOutlet(data : IOutlet) : AxiosPromise<IOutlet> {
        return axios.post(`${PACKMAN_API_URL}Outlet/PostOutlet`, data);
    }

    public static deleteOutlet(outletId : number) : AxiosPromise<IOutlet> {
        return axios.delete(`${PACKMAN_API_URL}Outlet/Delete`, {
            params : { outletId },
        });
    }
}

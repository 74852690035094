import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { authManualLogIn } from '../store/auth/Functions';
import { navPath, navHome } from '../store/nav/Actions';
import { useAppDispatch, useAppSelector } from '../@types/redux';
import { authSetLoggingIn, authSetLoggedIn } from '../store/auth/Actions';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../store/general/Functions';
import { Card, Toolbar, TextField, FormControl } from '@mui/material';
import NavBar from './root/NavBar';
import { useLocation } from 'react-router';
import PackmanDialog from '../components/dialog/PackmanDialog';
import PillButton from '../components/input/PillButton';
import { requestForgottenPasswordEmail } from '../services/right/userService';
import GoogleSignInButton from '../components/button/GoogleSignInButton';
import { GoogleOAuthProvider } from '@react-oauth/google';

const Login = () => {
    const location = useLocation();
    const session = useAppSelector(x => x.auth.session);
    const isLoggedIn = useAppSelector(x => x.auth.isLoggedIn);
    const isLoggingIn = useAppSelector(x => x.auth.isLoggingIn);

    const [isLoading, setLoading] = useState<boolean>(false);
    const [showForgottenPasswordDialog, setShowForgottenPasswordDialog] = useState<boolean>(false);
    const [emailOrUsername, setEmailOrUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (location?.state) {
            if (location.state.from?.pathname) {
                navPath(location.state.from.pathname);
            } else {
                navHome();
            }
        }
        if (location.pathname === '/login' && isLoggedIn) {
            navHome();
        }
    }, [location, session, isLoggedIn, location]);

    const manualLogin = async () => {
        setLoading(true);
        const result = await authManualLogIn(emailOrUsername, password);
        setPassword('');
        dispatch(authSetLoggingIn(false));
        if (result) {
            dispatch(authSetLoggedIn(true));
            generalShowSuccessSnackbar('Success');
        }
        setLoading(false);
    };

    const onEmailOrUsernameChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => setEmailOrUsername(e.target.value);
    const onPasswordChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => setPassword(e.target.value);

    const enterPressed = (event : React.KeyboardEvent<HTMLDivElement>) => {
        if (emailOrUsername && password) {
            if (event.key === 'Enter') {
                manualLogin();
            }
        }
    };

    const onForgottenPasswordClick = () => setShowForgottenPasswordDialog(true);
    const closeForgottenPasswordDialog = () => setShowForgottenPasswordDialog(false);

    const requestForgottenPassword = async () => {
        setLoading(true);
        const result = await requestForgottenPasswordEmail(emailOrUsername);
        if (result) {
            generalShowSuccessSnackbar('Password recovery submitted, please check your email');
        } else {
            generalShowErrorSnackbar('Password recovery could not be submitted');
        }
        setLoading(false);
        setShowForgottenPasswordDialog(false);
    };

    return <div className={'flx1 fdc posr oh'}>
        <div className={'posa op30p hfill wfill bcpd'}
            style={{ backgroundImage: `url(${ASSET_BASE}/assets/images/background_new-min.jpg)`,
                backgroundSize: 'cover', backgroundPosition: '50% 50%' }}/>
        <div className={'bcpd posa wfill hfill op70p'}/>
        <NavBar/>
        <div className={'flx1 fdc aic jcc'}>
            {isLoading &&
                <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                    <div className={'posr aic jcc h50 w50'}>
                        <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                            <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                        </div>
                        <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                    </div>
                </div>
            }
            {
                isLoggingIn ? (
                    <div className={'flx1 fdc aic jcc'}>
                        {<img height={60} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} className={'posr post70'} />}
                        <CircularProgress size={80} />
                    </div>
                ) : (
                    <div className={'flx1 fdr aic jcc oppNone'}>
                        <Card className={'w500 fdc jcc zi2'}>
                            <Toolbar className={'bcpd h50'}>
                                <Typography variant='h6' className='flx1 cw jcc'>
                                        PLEASE LOG IN
                                </Typography>
                            </Toolbar>
                            <div className={'pl20 pr20 pt10 pb10 pb10'}>
                                <div className={'fdr p5'}>
                                    <TextField className={'wfill'} label={'Email Or Employee number'} name={'emailOrUsername'} value={emailOrUsername} onChange={onEmailOrUsernameChange} />
                                </div>
                                <div className={'fdr p5'}>
                                    <TextField className={'wfill'} label={'Password'} name={'password'} value={password} type={'password'} onChange={onPasswordChange} onKeyDown={enterPressed} />
                                </div>
                                <div className={'fdr p5 aic jcc'}>
                                    <Button
                                        className={'br25 w250 cpd'}
                                        variant='contained'
                                        color='secondary'
                                        onClick={manualLogin}
                                        disabled={!emailOrUsername || !password || isLoading}
                                    >
                                            LOGIN
                                    </Button>
                                </div>
                                <div className={'fdr flx1 p5 aic jcc'}>
                                    <GoogleOAuthProvider clientId={OAUTH_CLIENT_ID}>
                                        <GoogleSignInButton
                                            // id={'testgooglebutton'}
                                            // isLoading={isLoading}
                                            // setLoading={setLoading}
                                        />
                                    </GoogleOAuthProvider>
                                </div>
                                <div className={'fdr p5 mt30 jcfe'}>
                                    <Button
                                        className={''}
                                        variant={'text'}
                                        color='primary'
                                        onClick={onForgottenPasswordClick}
                                        disabled={isLoading}
                                    >
                                            Forgotten password
                                    </Button>
                                </div>
                            </div>
                        </Card>
                        { showForgottenPasswordDialog && <PackmanDialog
                            isInfo
                            fullWidth
                            isOpen={showForgottenPasswordDialog}
                            title='Password Recovery'>
                            <div className='pl20 pr20 pt10 pb20 fdc aic jcc'>
                                <div className={'fdr jcs aifs wfill pb10'}>
                                    <FormControl>
                                        <TextField
                                            required
                                            autoFocus
                                            margin='dense'
                                            id='recovery-email-username'
                                            label='Email Or Employee number'
                                            fullWidth
                                            className={'w250'}
                                            value={emailOrUsername}
                                            onChange={onEmailOrUsernameChange}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'fdr aife jcfe wfill '}>
                                    <Button color='primary' onClick={closeForgottenPasswordDialog} disabled={isLoading}>
                                                CANCEL
                                    </Button>
                                    <PillButton color={'secondary'} className={'ml15 h30 pt3 pl20 pr20 mb3'} disabled={isLoading} type={'submit'} text={'SUBMIT'} onClick={requestForgottenPassword}/>
                                </div>
                            </div>
                        </PackmanDialog>}
                    </div>
                )
            }
        </div>
    </div>;

};

export default Login;

import * as React from 'react';
import CustomTable from '../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { DispatchCall, IAuthState, IRootState, RootAction } from '../../../@types/redux';
import { dataSetUnitOfMeasures, dataSetUnitOfMeasureTypes } from '../../../store/masterData/Actions';
import { bindActionCreators, Dispatch } from 'redux';
import { formatDateTime, upsertArrayElement } from '../../../services/appFunctionsService';
import { IUnitOfMeasure, UnitOfMeasure } from '../../../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import UnitOfMeasureHttpService from '../../../services/http/masterData/unitOfMeasureHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import { connect } from 'react-redux';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikActions } from 'formik';
import { IUnitOfMeasureFormValues, UnitOfMeasureFormValues } from '../../../@types/model/masterData/unitOfMeasure/unitOfMeasureFormValues';
import { createSelector } from 'reselect';
import UnitOfMeasureForm from './form/UnitOfMeasureForm';
import { IUnitOfMeasureType } from '../../../@types/model/masterData/unitOfMeasureType/unitOfMeasureType';
import UnitOfMeasureTypeHttpService from '../../../services/http/masterData/unitOfMeasureTypeHttpService';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { setUnitOfMeasureMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IUnitOfMeasureScreenProps extends RouteComponentProps {
    dataSetUnitOfMeasures : DispatchCall<Array<IUnitOfMeasure>>;
    dataSetUnitOfMeasureTypes : DispatchCall<Array<IUnitOfMeasureType>>;
    unitOfMeasureData : Array<IUnitOfMeasure>;
    unitOfMeasureTypeData : Array<IUnitOfMeasureType>;
    auth : IAuthState;
}

interface IUnitOfMeasureScreenState {
    rows : Array<IUnitOfMeasure>;
    isLoading : boolean;
    selectedUnitOfMeasure ?: IUnitOfMeasure;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingUnitOfMeasure ?: IUnitOfMeasure;
}

class UnitOfMeasureScreen extends React.Component<IUnitOfMeasureScreenProps, IUnitOfMeasureScreenState> {
    constructor(props : IUnitOfMeasureScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedUnitOfMeasure: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await UnitOfMeasureHttpService.getUnitOfMeasureData();

                const unitOfMeasureData = res.data;
                if (unitOfMeasureData) {
                    const res2 = await UnitOfMeasureTypeHttpService.getUnitOfMeasureTypeData();

                    const unitOfMeasureTypeData = res2.data;
                    this.props.dataSetUnitOfMeasureTypes(unitOfMeasureTypeData);
                    this.props.dataSetUnitOfMeasures(unitOfMeasureData);
                } else {
                    this.props.dataSetUnitOfMeasures([]);
                }

                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading unit of measures.');
                this.setLoading(false);
            }
        }
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedUnitOfMeasure: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    public editUnitOfMeasure = (unitOfMeasure : IUnitOfMeasure) => {
        this.setState({
            isDialogOpen: true,
            selectedUnitOfMeasure: unitOfMeasure,
        });
        this.openDialog();
    };

    public onSubmit = async (values : IUnitOfMeasureFormValues) => {
        this.setLoading(true);

        try {
            const res = await UnitOfMeasureHttpService.addOrUpdateUnitOfMeasure(new UnitOfMeasure(values));

            const newUnitOfMeasureList = upsertArrayElement(this.props.unitOfMeasureData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetUnitOfMeasures(newUnitOfMeasureList);
            await setUnitOfMeasureMasterDataIndexedDB(newUnitOfMeasureList);

            if (this.state.selectedUnitOfMeasure) {
                generalShowSuccessSnackbar('Unit Of Measure updated successfully.');
            } else {
                generalShowSuccessSnackbar('Unit Of Measure added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating unit of measure data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (unitOfMeasure : IUnitOfMeasure) => {
        this.setState({ isDeletePopupOpen: true, deletingUnitOfMeasure: unitOfMeasure });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingUnitOfMeasure: undefined });
    };

    private removeUnitOfMeasure = async () => {
        const newUnitOfMeasure = this.state.deletingUnitOfMeasure;
        if (newUnitOfMeasure) {
            newUnitOfMeasure.isActive = false;
            this.setLoading(true);

            try {
                const res = await UnitOfMeasureHttpService.deleteUnitOfMeasure(newUnitOfMeasure.id);

                const newUnitOfMeasureList = upsertArrayElement(this.props.unitOfMeasureData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetUnitOfMeasures(newUnitOfMeasureList);
                await setUnitOfMeasureMasterDataIndexedDB(newUnitOfMeasureList);

                generalShowSuccessSnackbar('Unit Of Measure updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting unit of measure.');
                newUnitOfMeasure.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public onReset = async (formValues : IUnitOfMeasureFormValues, formikActions : FormikActions<IUnitOfMeasureFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    public getSelectedUnitOfMeasure = (props : IUnitOfMeasureScreenProps, state : IUnitOfMeasureScreenState) => state.selectedUnitOfMeasure;

    public getInitialFormValues = createSelector(
        [this.getSelectedUnitOfMeasure],
        (unitOfMeasure : IUnitOfMeasure) => {
            return new UnitOfMeasureFormValues(unitOfMeasure, this.props.unitOfMeasureTypeData);
        },
    );

    private getUnitOfMeasureTypeName = (unitOfMeasureTypeId : number) => {
        const unitOfMeasureType = this.props.unitOfMeasureTypeData?.find(x => x.id === unitOfMeasureTypeId);
        return unitOfMeasureType?.name ?? '';
    };

    private getRights = (props : IUnitOfMeasureScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IUnitOfMeasureScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.unitOfMeasureData ? this.props.unitOfMeasureData : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Unit Of Measure'
                    isEdit={!!this.state.selectedUnitOfMeasure}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={UnitOfMeasureFormValues.formSchema}
                        component={UnitOfMeasureForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IUnitOfMeasure>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editUnitOfMeasure}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(unitOfMeasure : IUnitOfMeasure) => unitOfMeasure.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Unit Of Measure Type', field: 'typeId', formatFunction: this.getUnitOfMeasureTypeName, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IUnitOfMeasure) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Unit Of Measure'}
                    open={this.state.isDeletePopupOpen}
                    isLoading={this.state.isLoading}
                    message={'Are you sure you want to delete this unit of measure?'}
                    onOkClicked={this.removeUnitOfMeasure}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        unitOfMeasureData: state.masterData.unitOfMeasures,
        unitOfMeasureTypeData: state.masterData.unitOfMeasureTypes,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetUnitOfMeasures, dataSetUnitOfMeasureTypes }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnitOfMeasureScreen);

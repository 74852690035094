import { IDataState, DataAction } from '../../@types/redux';
import * as dataActions from './Actions';
import { getType } from 'typesafe-actions';
import { formatMomentToDatePicker } from '../../services/appFunctionsService';
import moment from 'moment';

const initialState = {
    /* Global */
    selectedOrganization: undefined,
    selectedSite: undefined,
    selectedExporterOrganization: undefined,
    selectedInventory: undefined,
    organizationToEdit: undefined,

    /* Stock */
    stocks: [],
    tempStockUnits: [],
    stockDataViews: [],
    stockWithCartonsDataViews: [],
    multiDispatchStockViews: [],
    stockTomatoReportViews: [],

    /* Dispatch */
    dispatchInstructions: [],
    dispatchViews: [],
    trips: [],
    isEditingTrip: false,
    dispatchInstructionStocks: [],

    /* Stock / Dispatch Dashboards */
    dashboardShowOnlyMyDispatches: false,
    dashboardDispatchSelectedLoadDate: formatMomentToDatePicker(moment().local().startOf('day')),
    dashboardDispatchSelectedDispatchInstructionId: undefined,
    selectedDashboardSiteIds: [],
    selectedDashboardOrganizations: [],
    selectedDashboardCommodities: [],
    selectedDashboardVarieties: [],
    selectedDashboardGrades: [],
    selectedDashboardColours: [],
    selectedDashboardPackCategories: [],

    /* Order */
    orderHeaders: [],

    /* Compliance */
    compliances: [],

    /* Lot */
    lots: [],
    lotViews: [],

    /* Intake */
    intakes: [],
    intakeViews: [],

    /* Batch */
    batches: [],
    batchViews: [],

    /* Material */
    materialStocks: [],
    materialStockViews: [],
    materialDispatches: [],
    dashboardSelectedMaterialDispatchId: undefined,
    materialDispatchViews: [],

    /* Rights */
    rights: [],
    roles: [],

    /* FAQs */
    frequentlyAskedQuestions: [],

    /* Home */
    infoBoardData: [],
    contactUsData: [],

    /* sales */
    sales: [],
    saleLines: [],
    saleLineViewData: [],

    /* Integration */
    recipes: [],
    recipeTables: [],
};

export default function dataReducer(state : IDataState = initialState, action : DataAction) : IDataState {
    switch (action.type) {

        /* Global */
        case getType(dataActions.dataSetSelectedOrganizationIds):
            return { ...state, selectedOrganizationIds: action.payload, selectedSiteIds: undefined }; // Site has to be cleared when organization changes
        case getType(dataActions.dataSetSelectedSiteIds):
            return { ...state, selectedSiteIds: action.payload };
        case getType(dataActions.dataSetSelectedExporterOrganization):
            return { ...state, selectedExporterOrganization: action.payload };
        case getType(dataActions.dataSetSelectedInventory):
            return { ...state, selectedInventory: action.payload };
        case getType(dataActions.dataSetOrganizationToEdit):
            return { ...state, organizationToEdit: action.payload };
        case getType(dataActions.dataSetEditingTrip):
            return { ...state, isEditingTrip: action.payload };

        /* Stock */
        case getType(dataActions.dataSetStocks):
            return { ...state, stocks: action.payload };
        case getType(dataActions.dataSetStockDataViews):
            return { ...state, stockDataViews: action.payload };
        case getType(dataActions.dataSetStockWithCartonsDataViews):
            return { ...state, stockWithCartonsDataViews: action.payload };
        case getType(dataActions.dataSetTempStockUnits):
            return { ...state, tempStockUnits: action.payload };
        case getType(dataActions.dataSetMultiDispatchStockViews):
            return { ...state, multiDispatchStockViews: action.payload };
        case getType(dataActions.dataSetStockTomatoReportViews):
            return { ...state, stockTomatoReportViews: action.payload };

        /* Dispatch */
        case getType(dataActions.dataSetDispatchInstructions):
            return { ...state, dispatchInstructions: action.payload };
        case getType(dataActions.dataSetDispatchViews):
            return { ...state, dispatchViews: action.payload };
        case getType(dataActions.dataSetTrips):
            return { ...state, trips: action.payload };
        case getType(dataActions.dataSetDispatchInstructionStocks):
            return { ...state, dispatchInstructionStocks: action.payload };

        /* Stock / Dispatch Dashboards */
        case getType(dataActions.dataSetDashboardShowOnlyMyDispatches):
            return { ...state, dashboardShowOnlyMyDispatches: action.payload };
        case getType(dataActions.dataSetDashboardDispatchSelectedLoadDate):
            return { ...state, dashboardDispatchSelectedLoadDate: action.payload };
        case getType(dataActions.dataSetDashboardDispatchSelectedDispatchInstructionId):
            return { ...state, dashboardDispatchSelectedDispatchInstructionId: action.payload };
        case getType(dataActions.dataSetStockDashboardSelectedSiteIds):
            return { ...state, selectedDashboardSiteIds: action.payload };
        case getType(dataActions.dataSetStockDashboardSelectedOrganizations):
            return { ...state, selectedDashboardOrganizations: action.payload };
        case getType(dataActions.dataSetStockDashboardSelectedCommodities):
            return { ...state, selectedDashboardCommodities: action.payload };
        case getType(dataActions.dataSetStockDashboardSelectedVarieties):
            return { ...state, selectedDashboardVarieties: action.payload };
        case getType(dataActions.dataSetStockDashboardSelectedGrades):
            return { ...state, selectedDashboardGrades: action.payload };
        case getType(dataActions.dataSetStockDashboardSelectedColours):
            return { ...state, selectedDashboardColours: action.payload };
        case getType(dataActions.dataSetStockDashboardSelectedPackCategories):
            return { ...state, selectedDashboardPackCategories: action.payload };

        /* Order */
        case getType(dataActions.dataSetOrderHeaders):
            return { ...state, orderHeaders: action.payload };

        /* Compliance */
        case getType(dataActions.dataSetCompliances):
            return { ...state, compliances: action.payload };

        /* Rights */
        case getType(dataActions.dataSetRights):
            return { ...state, rights: action.payload };
        case getType(dataActions.dataSetRoles):
            return { ...state, roles: action.payload };

        /* Lot */
        case getType(dataActions.dataSetLots):
            return { ...state, lots: action.payload };
        case getType(dataActions.dataSetLotViews):
            return { ...state, lotViews: action.payload };

        /* Intake */
        case getType(dataActions.dataSetIntakes):
            return { ...state, intakes: action.payload };
        case getType(dataActions.dataSetIntakeViews):
            return { ...state, intakeViews: action.payload };

        /* Batch */
        case getType(dataActions.dataSetBatches):
            return { ...state, batches: action.payload };
        case getType(dataActions.dataSetBatchViews):
            return { ...state, batchViews: action.payload };

        /* Material */
        case getType(dataActions.dataSetMaterialStocks):
            return { ...state, materialStocks: action.payload };
        case getType(dataActions.dataSetMaterialStockViews):
            return { ...state, materialStockViews: action.payload };
        case getType(dataActions.dataSetMaterialDispatches):
            return { ...state, materialDispatches: action.payload };
        case getType(dataActions.dataSetMaterialDispatchViews):
            return { ...state, materialDispatchViews: action.payload };
        case getType(dataActions.dataSetDashboardSelectedMaterialDispatchId):
            return { ...state, dashboardSelectedMaterialDispatchId: action.payload };

        /* Frequently Asked Question (FAQs) */
        case getType(dataActions.dataSetFAQs):
            return { ...state, frequentlyAskedQuestions: action.payload };

        /* Home */
        case getType(dataActions.dataSetInfoBoardData):
            return { ...state, infoBoardData: action.payload };
        case getType(dataActions.dataSetContactUsData):
            return { ...state, contactUsData: action.payload };

        /* Sales */
        case getType(dataActions.dataSetSales):
            return { ...state, sales: action.payload };
        case getType(dataActions.dataSetSaleLines):
            return { ...state, saleLines: action.payload };
        case getType(dataActions.dataSetSaleLineViewData):
            return { ...state, saleLineViewData: action.payload };

        /* Integrations */
        case getType(dataActions.dataSetRecipes):
            return { ...state, recipes: action.payload };
        case getType(dataActions.dataSetRecipeTables):
            return { ...state, recipeTables: action.payload };

        default:
            return state;
    }
}

import * as React from 'react';
import CustomTable from '../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetAgreementCodes, dataSetCommodities, dataSetCountries, dataSetMarkets } from '../../../store/masterData/Actions';
import { formatDateTime, upsertArrayElement } from '../../../services/appFunctionsService';
import { IAgreementCode } from '../../../@types/model/masterData/agreementCode/agreementCode';
import AgreementCodeHttpService from '../../../services/http/masterData/agreementCodeHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import CommodityHttpService from '../../../services/http/masterData/commodityHttpService';
import CountryHttpService from '../../../services/http/masterData/countryHttpService';
import { ICountry } from '../../../@types/model/masterData/country/country';
import { ICommodity } from '../../../@types/model/masterData/commodity/commodity';
import PillButton from '../../../components/input/PillButton';
import MarketHttpService from '../../../services/http/masterData/marketHttpService';
import { IMarket } from '../../../@types/model/masterData/market/market';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikActions } from 'formik';
import { AgreementCodeFormValues, IAgreementCodeFormValues } from '../../../@types/model/masterData/agreementCode/agreementCodeFormValues';
import AgreementCodeForm from './form/AgreementCodeForm';
import { setAgreementCodesMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IAgreementCodeScreenProps extends RouteComponentProps {
    dataSetAgreementCodes : DispatchCall<Array<IAgreementCode>>;
    dataSetCountries : DispatchCall<Array<ICountry>>;
    dataSetCommodities : DispatchCall<Array<ICommodity>>;
    dataSetMarkets : DispatchCall<Array<IMarket>>;
    agreementCodes : Array<IAgreementCode>;
    commodities : Array<ICommodity>;
    countries : Array<ICountry>;
    markets : Array<IMarket>;
    auth : IAuthState;
}

interface IAgreementCodeScreenState {
    rows : Array<IAgreementCode>;
    isLoading : boolean;
    selectedAgreementCode ?: IAgreementCode;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingAgreementCode ?: IAgreementCode;
}

class AgreementCodeScreen extends React.Component<IAgreementCodeScreenProps, IAgreementCodeScreenState> {
    constructor(props : IAgreementCodeScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedAgreementCode: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }
        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await AgreementCodeHttpService.getAgreementCodeData();
                const res2 = await CommodityHttpService.getCommodityData(true, true);
                const res3 = await CountryHttpService.getCountryData();
                const res4 = await MarketHttpService.getMarketData();

                this.props.dataSetAgreementCodes(res.data);
                this.props.dataSetCommodities(res2.data);
                this.props.dataSetCountries(res3.data);
                this.props.dataSetMarkets(res4.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading agreement codes.');
                this.setLoading(false);
            }
        }
    };

    public onReset = async (formValues : IAgreementCodeFormValues, formikActions : FormikActions<IAgreementCodeFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    public onSubmit = async (values : IAgreementCodeFormValues) => {
        this.setLoading(true);
        const selectedAgreementCode = this.state.selectedAgreementCode;

        if (selectedAgreementCode) {
            try {
                selectedAgreementCode.targetMarketId = (!!values.targetMarket?.value && Number(values.targetMarket?.value) > 0) ? Number(values.targetMarket?.value) : undefined;
                const res = await AgreementCodeHttpService.addOrUpdateAgreementCode(selectedAgreementCode);

                const newAgreementCodeList = upsertArrayElement(this.props.agreementCodes, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetAgreementCodes(newAgreementCodeList);
                await setAgreementCodesMasterDataIndexedDB(newAgreementCodeList);

                if (this.state.selectedAgreementCode) {
                    generalShowSuccessSnackbar('Carrier updated successfully.');
                } else {
                    generalShowSuccessSnackbar('Carrier added successfully.');
                }

                this.closeDialog();
            } catch (e) {
                generalShowErrorSnackbar('An error occurred updating carrier data.');
            } finally {
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private formatCommodities = (commodityIds : Array<number>) => this.props.commodities
        .filter(x => commodityIds.some(y => y === x.id))
        .map(x => x.code).toString().replace(/,/g, ', ');

    private formatCountries = (countryIds : Array<number>) => this.props.countries
        .filter(x => countryIds.some(y => y === x.id))
        .map(x => x.code).toString().replace(/,/g, ', ');

    private getTargetMarket = (marketId : number) => {
        const market = this.props.markets.find(x => x.id === marketId);

        return market ? market.name : '';
    };

    private getRows = (props : IAgreementCodeScreenProps) => props.agreementCodes;
    public getMarkets = (props : IAgreementCodeScreenProps) => props.markets;
    public getSelectedAgreementCode = (props : IAgreementCodeScreenProps, state : IAgreementCodeScreenState) => state.selectedAgreementCode;

    public getInitialFormValues = createSelector(
        [this.getMarkets, this.getSelectedAgreementCode],
        (markets : Array<IMarket>, agreementCode : IAgreementCode) => {
            return new AgreementCodeFormValues(agreementCode, markets);
        },
    );

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedAgreementCode: undefined,
        });
    };

    public openDialog = (agreementCode : IAgreementCode) => {
        this.setState({
            isDialogOpen: true,
            selectedAgreementCode: agreementCode,
        });
    };

    private syncAgreementCodes = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }
        this.setLoading(true);
        try {
            await AgreementCodeHttpService.syncAgreementCodes();
            const agreementCodeData = await AgreementCodeHttpService.getAgreementCodeData();

            if (!isIndexedDBAvailable) {
                const commodityData = await CommodityHttpService.getCommodityData();
                const countryData = await CountryHttpService.getCountryData();
                const marketData = await MarketHttpService.getMarketData();

                this.props.dataSetCommodities(commodityData.data);
                this.props.dataSetCountries(countryData.data);
                this.props.dataSetMarkets(marketData.data);
            }

            this.props.dataSetAgreementCodes(agreementCodeData.data);
            await setAgreementCodesMasterDataIndexedDB(agreementCodeData.data);
            this.setLoading(false);
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while syncing agreement codes.');
            this.setLoading(false);
        }

    };

    private getRights = (props : IAgreementCodeScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IAgreementCodeScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.getRows(this.props);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Agreement Code'
                    isEdit={!!this.state.selectedAgreementCode}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={this.getInitialFormValues(this.props, this.state)}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={AgreementCodeFormValues.formSchema}
                        component={AgreementCodeForm} />
                </PackmanDialog>
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IAgreementCode>
                            enableSorting
                            enableFiltering
                            enablePagination
                            enableEditing={this.hasEditingRight(this.props)}
                            editFunction={this.openDialog}
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Description', field: 'description', enableFiltering: true, enableSorting: true },
                                { title: 'Start Date', field: 'startDate', enableFiltering: true, enableSorting: true, type: 'date', formatFunction: formatDateTime },
                                { title: 'End Date', field: 'endDate', enableFiltering: true, enableSorting: true, type: 'date', formatFunction: formatDateTime },
                                { title: 'Commodities', field: 'commodityIds', formatFunction: this.formatCommodities, width: 200, enableFiltering: true, enableSorting: true },
                                { title: 'Countries', field: 'countryIds', formatFunction: this.formatCountries, width: 200, enableFiltering: true, enableSorting: true },
                                { title: 'Target Market', field: 'targetMarketId', formatFunction: this.getTargetMarket, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={245}
                            isActive={(row : IAgreementCode) => row.isActive}
                        />
                    </Card>
                    { this.hasEditingRight(this.props) &&
                        <div className='fdr mt20'>
                            <div className='flx1' />
                            <PillButton color={'secondary'} disabled={this.state.isLoading} className={'ml15 pl20 pr20 mr10 h35'} text='Re-Sync Agreement Codes' onClick={this.syncAgreementCodes}/>
                        </div>
                    }
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        agreementCodes: state.masterData.agreementCodes,
        commodities: state.masterData.commodities,
        countries: state.masterData.countries,
        markets: state.masterData.markets,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetAgreementCodes, dataSetCountries, dataSetCommodities, dataSetMarkets }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AgreementCodeScreen);

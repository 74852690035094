import axios, { AxiosPromise } from 'axios';
import { ITruckType } from '../../../@types/model/masterData/truckType/truckType';
import { PACKMAN_API_URL } from '../../../appConstants';

export default class TruckTypeHttpService {
    public static getTruckTypeData() : AxiosPromise<Array<ITruckType>> {
        return axios.get(`${PACKMAN_API_URL}TruckType/GetAllWeb`);
    }

    public static addOrUpdateTruckType(data : ITruckType) : AxiosPromise<ITruckType> {
        return axios.post(`${PACKMAN_API_URL}TruckType/PostTruckType`, data);
    }

    public static deleteTruckType(truckTypeId : number) : AxiosPromise<ITruckType> {
        return axios.delete(`${PACKMAN_API_URL}TruckType/Delete`, {
            params : { truckTypeId },
        });
    }
}

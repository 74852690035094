import * as React from 'react';
import {
    dataSetDashboardDispatchSelectedLoadDate,
    dataSetDashboardSelectedMaterialDispatchId,
} from '../../../store/data/Actions';
import { DispatchCall, RootAction, IRootState, IAuthState } from '../../../@types/redux';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import MaterialStockDashboardTable from './MaterialDashboardTable';
import { dataSetAllMaterialRelatedData } from '../../../store/data/Functions';
import MaterialStockDashboardDispatchDraftSidePanel from './MaterialDashboardDispatchDraftSidePanel';
import { IMaterialDispatch } from '../../../@types/model/materialDispatch/materialDispatch';
import { ISite } from '../../../@types/model/masterData/site/site';
import MaterialRelatedDataHttpService from '../../../services/http/masterData/materialRelatedDataHttpService';
import Screen from '../../../components/Screen';
import moment from 'moment';
import TransactionFilter from '../../../components/filters/BasicTransactionScreenFilter';
import { syncMasterData } from '../../../services/masterDataSyncService';

interface IMaterialStockDashboardProps {
    dataSetDashboardDispatchSelectedLoadDate : DispatchCall<string>;
    dataSetDashboardSelectedMaterialDispatchId : DispatchCall<number | undefined>;
    materialDispatches : Array<IMaterialDispatch>;
    selectedLoadDate : string;
    sites : Array<ISite>;
    auth : IAuthState;
    selectedDispatchIndex ?: number;
    showOnlyMyDispatches : boolean;
    selectedSiteIds : Array<number> ;
}

interface IMaterialStockDashboardState {
    isLoading : boolean;
    dataFetched : boolean;
    isAddingMaterialStock : boolean;
    materialDispatchMode : boolean;
    filtersExpanded : boolean;
    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;
}

const drawerWidth = 300;

class MaterialStockDashboard extends React.Component<IMaterialStockDashboardProps, IMaterialStockDashboardState> {
    constructor(props : IMaterialStockDashboardProps) {
        super(props);

        this.state = {
            isLoading: false,
            isAddingMaterialStock: false,
            dataFetched: false,
            materialDispatchMode: false,
            filtersExpanded: true,
            selectedFromDate: moment().local().startOf('day').subtract(7, 'days'),
            selectedToDate: moment().local().endOf('day'),
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }
        this.props.dataSetDashboardSelectedMaterialDispatchId(undefined);
        try {
            const res = await MaterialRelatedDataHttpService.getAllMaterialRelatedData(this.getDate('from'), this.getDate('to'), this.props.selectedSiteIds, !isIndexedDBAvailable);

            dataSetAllMaterialRelatedData(res.data);
            this.setState({ dataFetched: true }, () => this.setLoading(false));
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading material related data.');
            this.setLoading(false);
        }
    };

    public componentDidUpdate = (prevProps : IMaterialStockDashboardProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            if (nextProps.selectedSiteIds !== undefined && prevProps.selectedSiteIds !== nextProps.selectedSiteIds) {
                this.refreshData(true);
            }
        }
    };

    private handleDateRangeChange = (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => {
        const selectedFromDate = changedBy === 'start' ? moment(start).startOf('day') : end < start ? moment(end).startOf('day') : moment(start).startOf('day');
        const selectedToDate = changedBy === 'end' ? moment(end).endOf('day') : start > end ? moment(start).endOf('day') : moment(end).endOf('day');
        this.setState({ selectedFromDate, selectedToDate });
    };

    private onApplyClick = () => {
        this.setState({ isLoading: true }, () => {
            this.refreshData();
        });

        this.setState({
            isLoading: false,
        });
    };

    private getDate = (type : 'from' | 'to') => {
        switch (type) {
            case 'from':
                return this.state.selectedFromDate.startOf('day').utc().unix() * 1000;
            case 'to':
                return this.state.selectedToDate.endOf('day').utc().unix() * 1000;
        }
    };

    private setLoading = (isLoading : boolean = false) => {
        this.setState({ isLoading });
    };

    private refreshData = async (noAnnounce ?: boolean) => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;
        try {
            const res = await MaterialRelatedDataHttpService.getAllMaterialRelatedData(this.getDate('from'), this.getDate('to'), this.props.selectedSiteIds, !isIndexedDBAvailable);

            dataSetAllMaterialRelatedData(res.data);
            this.setLoading(false);
            if (!noAnnounce) {
                generalShowSuccessSnackbar('Data Refreshed');
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading material related data.');
            this.setLoading(false);
        }
    };

    private onMaterialStockAdd = () => {
        this.setState({ isAddingMaterialStock : true });
    };

    private onMaterialStockFormClose = () => {
        this.setState({
            isAddingMaterialStock: false,
        });
    };

    private enableMaterialDispatchMode = () => {
        this.setState({ materialDispatchMode: true });
    };

    private disableMaterialDispatchMode = () => {
        this.setState({ materialDispatchMode: false });
    };

    public expandFilters = (filtersExpanded : boolean) => this.setState({ filtersExpanded });

    public render() {
        return (
            <Screen isPadded={false} isLoading={this.state.isLoading} isScrollable={false}>
                <div className={'pl20 pr20'}>
                    <TransactionFilter className={'pt10 mb10'} selectedFromDate={this.state.selectedFromDate} selectedToDate={this.state.selectedToDate} handleDateRangeChange={this.handleDateRangeChange} onApplyClick={this.onApplyClick} />
                    <div className={'flx1 fdc'} style={this.state.materialDispatchMode ? { width: `calc(100% - ${drawerWidth}px)` } : {}}>
                        <MaterialStockDashboardTable
                            setLoading={this.setLoading}
                            isLoading={this.state.isLoading}
                            materialDispatchMode={this.state.materialDispatchMode}
                            dataFetched={this.state.dataFetched} refreshData={this.refreshData}
                        />
                        {this.state.dataFetched && !this.state.materialDispatchMode && <div className={'fdr mt10'}>
                            <div className={'flx1'}/>
                            <Button variant='contained' color='primary' onClick={this.enableMaterialDispatchMode}>Draft Dispatch Instructions</Button>
                            <span className={'w10'} />
                            <Button variant='contained' color='primary' onClick={this.onMaterialStockAdd}>Add Material Stock</Button>
                        </div>}
                    </div>
                    <MaterialStockDashboardDispatchDraftSidePanel
                        setLoading={this.setLoading}
                        isLoading={this.state.isLoading}
                        refreshData={this.refreshData}
                        disableMaterialDispatchMode={this.disableMaterialDispatchMode}
                        materialDispatchMode={this.state.materialDispatchMode}
                        drawerWidth={drawerWidth}
                    />
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        selectedLoadDate: state.data.dashboardDispatchSelectedLoadDate,
        selectedDispatchIndex: state.data.dashboardSelectedMaterialDispatchId,
        showOnlyMyDispatches: state.data.dashboardShowOnlyMyDispatches,
        materialDispatches: state.data.materialDispatches,
        selectedSiteIds: state.data.selectedSiteIds,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    {
        dataSetDashboardDispatchSelectedLoadDate,
        dataSetDashboardSelectedMaterialDispatchId,
    },
    dispatcher,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MaterialStockDashboard);

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IConsignmentStorage } from '../../../@types/model/compliance/titan-TUR/consignmentStorage';

export default class ConsignmentHttpService {
    public static getConsignmentData() : AxiosPromise<Array<IConsignmentStorage>> {
        return axios.get(`${PACKMAN_API_URL}Consignment`);
    }

    public static addOrUpdateConsignment(data : IConsignmentStorage) : AxiosPromise<IConsignmentStorage> {
        return axios.post(`${PACKMAN_API_URL}Consignment/PostConsignment`, data);
    }
}

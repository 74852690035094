import { IBaseModel } from '../../model';
import { IIntakeLine, IntakeLine } from './intakeLine';
import { IIntakeFormValues } from './form/intakeFormValues';

export interface IIntake extends IBaseModel {
    organizationId : number;
    commodityId : number;
    siteId : number;
    storageUnitId ?: number;
    farmId : number;
    shipmentGuid ?: string;
    isTipped ?: boolean;
    intakeDate ?: string;
    intakeLines : Array<IIntakeLine>;
    deleteReason ?: string;
}

export class Intake implements IIntake {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public organizationId : number;
    public commodityId : number;
    public siteId : number;
    public storageUnitId ?: number;
    public farmId : number;
    public shipmentGuid ?: string;
    public isTipped ?: boolean;
    public intakeDate ?: string;
    public deleteReason ?: string;

    public intakeLines : Array<IIntakeLine>;

    public constructor(formValues : IIntakeFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.organizationId = Number(formValues.organization?.value) ?? 0;
        this.commodityId = Number(formValues.commodity?.value) ?? 0;
        this.siteId = Number(formValues.site?.value) ?? 0;
        this.storageUnitId = Number(formValues.storageUnit?.value) && Number(formValues.storageUnit?.value) > 0 ? Number(formValues.storageUnit?.value) : undefined;
        this.farmId = Number(formValues.farm?.value) ?? 0;
        this.shipmentGuid = formValues.shipmentGuid;
        this.isTipped = formValues.isTipped;
        this.intakeDate = formValues.intakeDate;

        this.intakeLines = formValues.intakeLines.map(x => new IntakeLine(x));
    }
}

import { IAccreditation } from './accreditation';
import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import moment from 'moment';
import { DATEPICKER_FORMAT_DEFAULT } from '../../../../appConstants';
import { IFarm } from '../farm/farm';

export interface IAccreditationFormValues {
    id : number;
    guid : string;
    code : string;
    name : string;
    issueDate : moment.Moment;
    expirationDate : moment.Moment;
    farms : Array<IOptionType>;
    isActive : boolean;
}

export class AccreditationFormValues implements IAccreditationFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public name : string;
    public issueDate : moment.Moment;
    public expirationDate : moment.Moment;
    public farms : Array<IOptionType>;
    public isActive : boolean;

    public constructor(
        accreditation ?: IAccreditation,
        farms ?: Array<IFarm>,
    ) {
        this.initializeDefaultValues();
        if (accreditation) {
            this.id = accreditation.id;
            this.guid = accreditation.guid;
            this.code = accreditation.code;
            this.name = accreditation.name;
            this.issueDate = moment(accreditation.issueDate, DATEPICKER_FORMAT_DEFAULT);
            this.expirationDate = moment(accreditation.expirationDate, DATEPICKER_FORMAT_DEFAULT);
            this.farms = farms?.filter(x => accreditation.farmIds?.some(y => y === x.id))?.map(x => ({
                label: x.name,
                value: x.id,
            })) ?? [];
            this.isActive = accreditation.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.name = '';
        this.issueDate = moment();
        this.expirationDate = moment();
        this.farms = [];
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        issueDate: Yup.date().required('Required'),
        expirationDate: Yup.date().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

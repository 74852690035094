import * as React from 'react';
import * as rightService from '../../services/right/rightService';
import { Checkbox, CircularProgress, Paper, Zoom } from '@mui/material';
import { IMappedRight } from '../../@types/model/user/mappedRight';
import PillButton from '../input/PillButton';
import { RIGHT_GRANTS } from '../../appConstants';
import { forEachRight } from 'lodash';
import { IRightGrant } from '../../@types/model/user/rightGrant';
import CustomTooltip from '../tooltip/tooltip';
import { getThemeMode } from '../../styles/materialTheme';

const themeMode = getThemeMode(); // needed to apply hover effect to dispatch

interface IParentColors {
    background : string;
    button : {
        background : string;
        text : string;
        border : string;
    };
};
interface IChildColors {
    button : {
        background : string;
        text : string;
        border : string;
    };
    checkbox ?: string;
};

interface IRightsSelectProps {
    selectedRights : Array<IRightGrant>;
    isMobile : boolean;

    onSelected : (id : number, checked : boolean, childrenIds ?: Array<number>) => void;

    onCheckBoxChecked : (id : number, checked : boolean, grant : number) => void;

    disablePillButton : boolean;
    setPillButtonDisable : (isLoading : boolean) => void;
}

interface IRightsSelectState {
    isLoading : boolean;

    rights : Array<IMappedRight>;
}

export default class RightsSelect extends React.Component<IRightsSelectProps, IRightsSelectState> {

    constructor(props : IRightsSelectProps) {
        super(props);

        this.state = {
            isLoading: true,
            rights: [],
        };
    }

    public componentDidMount = () => {
        this.loadRights();
    };

    public loadRights = () => {
        rightService.getList(undefined, undefined, true).then((result) => {
            this.setState({
                isLoading: false,
                rights: rightService.mapRightsToMappedRights(result),
            });
        });
    };

    private getChildrenIds = (right : IMappedRight) => right.children.map(x => x.id);

    public onSelectionChanged = (right : IMappedRight, checked : boolean) => {
        this.props.setPillButtonDisable(true);
        const childrenIds : Array<number> = this.getChildrenIds(right);

        this.props.onSelected(right.id, checked, childrenIds);
    };

    public render() {
        const { isLoading, rights } = this.state;
        const { selectedRights } = this.props;
        return (
            <Paper className={'fdc'}>
                {!!isLoading &&
                    <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                        <div className={'posr aic jcc h50 w50'}>
                            <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                                <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                            </div>
                            <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                        </div>
                    </div>
                }
                {rights.filter(x => x.isMobile === this.props.isMobile).map((n =>
                    <div key={`${n.code}_parent_div`} className={'fdr aic bb1 cgray3'}>
                        <RightParent key={`${n.code}_parent_select`} right={n} selected={selectedRights?.some(x => x.rightId === n.id)} disabled={this.props.disablePillButton} onClicked={this.onSelectionChanged} />
                        <Zoom in={selectedRights?.some(x => x.rightId === n.id)}>
                            <div className={'flx7 jcfs fdr oxs right-scroll-area'}>
                                {
                                    n.children.map(o =>
                                        <RightChild key={`${o.code}_child_select`} right={o} selected={selectedRights?.some(x => x.rightId === o.id)} grant={selectedRights?.find(x => x.rightId === o.id)?.grant ?? 0} onClicked={this.onSelectionChanged} onCheckBoxChecked={this.props.onCheckBoxChecked}/>)
                                }
                                <span className={'mnw20'} />
                            </div>
                        </Zoom>
                    </div>
                ))}
            </Paper>
        );
    }
}

interface IRightsParentProps {
    selected : boolean;
    right : IMappedRight;
    disabled ?: boolean;
    onClicked : (right : IMappedRight, checked : boolean) => void;
}

class RightParent extends React.Component<IRightsParentProps> {

    private getThemeColors = () => {
        let result : IParentColors;

        if (themeMode === 'light') {
            if (this.props.selected) {
                result = {
                    background: 'bcg3',
                    button: {
                        background: 'bcpd',
                        text: 'cw',
                        border: 'bocpd',
                    },
                };
            } else {
                result = {
                    background: 'bcg3',
                    button: {
                        background: 'bcw',
                        text: 'cpd',
                        border: 'bocpd',
                    },
                };
            }
        } else if (themeMode === 'dark') {
            if (this.props.selected) {
                result = {
                    background: 'bcpl',
                    button: {
                        background: 'bcpd',
                        text: 'cw',
                        border: 'bocpd',
                    },
                };
            } else {
                result = {
                    background: 'bcpl',
                    button: {
                        background: 'bcw',
                        text: 'cpd',
                        border: 'bocpd',
                    },
                };
            }
        }  else {
            if (this.props.selected) {
                result = {
                    background: 'bcg3',
                    button: {
                        background: 'bcpd',
                        text: 'cw',
                        border: 'bocpd',
                    },
                };
            } else {
                result = {
                    background: 'bcg3',
                    button: {
                        background: 'bcw',
                        text: 'cpd',
                        border: 'bocpd',
                    },
                };
            }
        }

        return result;
    };
    public render() {
        const { right, selected, onClicked } = this.props;
        const colors = this.getThemeColors();
        return (
            <>
                <div className={`flx1 jcfe p10 ${colors.background}`}>
                    <PillButton
                        text={right.name}
                        className={`pl25 br25 pr25 pt10 pb10 bw1 ${colors.button.text} ${colors.button.background} ${colors.button.border}`}
                        // className={`RightsParentPillButton${!selected ? '' : 'Selected'}`}
                        onClick={() => onClicked(right, !selected)}
                        color={'inherit'}
                        disabled={this.props.disabled}
                    />
                </div>
            </>
        );
    }
}

interface IRightsChildProps {
    selected : boolean;
    right : IMappedRight;
    grant : number;
    onClicked : (right : IMappedRight, checked : boolean) => void;
    onCheckBoxChecked : (rightId : number, checked : boolean, grant : number) => void;
}

class RightChild extends React.Component<IRightsChildProps> {

    private getThemeColors = () => {
        let result : IChildColors;

        if (themeMode === 'light') {
            if (this.props.selected) {
                result = {
                    button: {
                        background: 'bcp',
                        text: 'cw',
                        border: 'bocpd',
                    },
                };
            } else {
                result = {
                    button: {
                        background: 'bcw',
                        text: 'cpd',
                        border: 'bocpd',
                    },
                };
            }
        } else if (themeMode === 'dark') {
            if (this.props.selected) {
                result = {
                    button: {
                        background: 'bcpd',
                        text: 'cw',
                        border: 'bocs',
                    },
                };
            } else {
                result = {
                    button: {
                        background: 'bcw',
                        text: 'cpd',
                        border: 'bocpd',
                    },
                };
            }
        }  else {
            if (this.props.selected) {
                result = {
                    button: {
                        background: 'bcpd',
                        text: 'cw',
                        border: 'bocpd',
                    },
                };
            } else {
                result = {
                    button: {
                        background: 'bcw',
                        text: 'cpd',
                        border: 'bocpd',
                    },
                };
            }
        }

        return result;
    };

    private getRightsGranted = () => {
        let remainingGrant = this.props.grant;
        const grants : Array<string> = [];
        forEachRight(RIGHT_GRANTS, (x, i) => {
            if ((remainingGrant - Number(i)) >= 0) {
                remainingGrant -= (Number(i));
                grants.push(x);
            }
        });

        return grants;
    };

    private hasGrant = (grant : string) => this.getRightsGranted().some(y => y === grant);

    public render() {
        const { selected, right, onClicked } = this.props;
        this.getRightsGranted();

        const colors = this.getThemeColors();
        return (
            <>
                <div className={`right-child ${colors.button.background} ${colors.button.text} ${colors.button.border}`} onClick={() => onClicked(right, !!!selected)}>
                    <span className={`${!!!selected ? 'not-selected' : 'selected'} aic jcc`}>
                        {right.name}
                        <CustomTooltip title={'Add'}>
                            <Checkbox
                                className={`${colors.checkbox}`}
                                checked={this.hasGrant(RIGHT_GRANTS[1])}
                                disabled={!selected}
                                onClick={e => e.preventDefault()}
                                onChange={(event, checked) => this.props.onCheckBoxChecked(right.id, checked, 1)}
                            />
                        </CustomTooltip> { /* Add - 1 */}
                        <CustomTooltip title={'Edit'}>
                            <Checkbox
                                className={`${colors.checkbox}`}
                                checked={this.hasGrant(RIGHT_GRANTS[2])}
                                disabled={!selected}
                                onClick={e => e.preventDefault()}
                                onChange={(event, checked) => this.props.onCheckBoxChecked(right.id, checked, 2)}
                            />
                        </CustomTooltip> { /* Edit - 2 */}
                        <CustomTooltip title={'Delete'}>
                            <Checkbox
                                className={`${colors.checkbox}`}
                                checked={this.hasGrant(RIGHT_GRANTS[4])}
                                disabled={!selected}
                                onClick={e => e.preventDefault()}
                                onChange={(event, checked) => this.props.onCheckBoxChecked(right.id, checked, 4)}
                            />
                        </CustomTooltip> { /* Delete - 4 */}
                        <CustomTooltip title={'Admin'}>
                            <Checkbox
                                className={`${colors.checkbox}`}
                                checked={this.hasGrant(RIGHT_GRANTS[8])}
                                disabled={!selected}
                                onClick={e => e.preventDefault()}
                                onChange={(event, checked) => this.props.onCheckBoxChecked(right.id, checked, 8)}
                            />
                        </CustomTooltip> { /* Admin - 8 */}
                        <CustomTooltip title={'Super Admin'}>
                            <Checkbox
                                className={`${colors.checkbox}`}
                                checked={this.hasGrant(RIGHT_GRANTS[16])}
                                disabled={!selected}
                                onClick={e => e.preventDefault()}
                                onChange={(event, checked) => this.props.onCheckBoxChecked(right.id, checked, 16)}
                            />
                        </CustomTooltip> { /* Super Admin - 16 */}
                    </span>
                </div>
            </>
        );
    }
}

import { ICountry } from './country';
import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { IRegion } from '../region/region';

export interface ICountryFormValues {
    id : number;
    guid : string;
    code : string;
    name : string;
    region ?: IOptionType;
    isActive : boolean;
}

export class CountryFormValues implements ICountryFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public name : string;
    public region ?: IOptionType;
    public isActive : boolean;

    public constructor(
        country ?: ICountry,
        regions ?: Array<IRegion>,
    ) {
        this.initializeDefaultValues();
        if (country) {
            this.id = country.id;
            this.guid = country.guid;
            this.code = country.code;
            this.name = country.name;

            this.region = regions ? {
                label: regions.find(x => x.id === country.regionId)?.name ?? '',
                value: country.regionId,
            } : undefined;

            this.isActive = country.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.name = '';
        this.region = undefined;
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        region: Yup.object().nullable().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

import * as Yup from 'yup';
import { IDepartment } from './department';

export interface IDepartmentFormValues {
    id : number;
    guid : string;
    code : string;
    shortDescription : string;
    description : string;
    isActive : boolean;
    isImported ?: boolean;
}

export class DepartmentFormValues implements IDepartmentFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public shortDescription : string;
    public description : string;
    public isActive : boolean;
    public isImported ?: boolean;

    public constructor(department ?: IDepartment) {
        this.initializeDefaultValues();
        if (department) {
            this.id = department.id;
            this.guid = department.guid;
            this.code = department.code;
            this.shortDescription = department.shortDescription;
            this.description = department.description;
            this.isActive = department.isActive;
            this.isImported = department.isImported;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.shortDescription = '';
        this.description = '';
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required').max(3),
        shortDescription: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

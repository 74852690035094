import * as React from 'react';
import { Form, FormikProps } from 'formik';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import FormAutocompleteSelect from '../../../../components/input/form/FormAutoCompleteSelect';
import { createSelector } from 'reselect';
import { IOptionType } from '../../../../@types/helper';
import { SalesPointFormValues } from '../../../../@types/model/masterData/salesPoint/salesPointFormValues';
import { IFarm } from '../../../../@types/model/masterData/farm/farm';

interface ISalesPointFormProps {
    isLoading : boolean;
    farmOptions : Array<IOptionType>;
}

interface ISalesPointFormState {
}

type SalesPointFormPropsType = ISalesPointFormProps & FormikProps<SalesPointFormValues>;

class SalesPointForm extends React.Component<SalesPointFormPropsType, ISalesPointFormState> {

    constructor(props : SalesPointFormPropsType) {
        super(props);
    }

    public render() {
        return (
            <Form className={'p20 mnw400'}>
                <FormTextInput name={'name'} label={'Name'}/>
                <FormAutocompleteSelect
                    name={'farms'}
                    label={'Farms'}
                    options={this.props.farmOptions}
                    isMulti
                />
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const getFarms = (state : IRootState) => state.masterData.farms;

const getFarmOptions = createSelector(
    [getFarms],
    (farms : Array<IFarm>) => {
        return farms.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        farmOptions: getFarmOptions(state),
    };
};

export default connect(
    mapStateToProps,
)(SalesPointForm);

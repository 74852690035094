import * as React from 'react';
import { FormControl, FormHelperText, PropTypes, TextField } from '@mui/material';
import { asField, FieldContext, FieldProps, FormValues } from 'informed';
import { IFormValue } from '../../@types/other';

interface ICustomTextInputProps extends FieldProps<IFormValue, FormValues> {
    onChange ?: (newValue ?: any, event ?: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => void;
    onBlur ?: (event : React.FocusEvent<HTMLInputElement | HTMLDivElement | HTMLTextAreaElement>) => void;
    initialValue ?: any;
    id ?: any;
    disabled ?: boolean;
    type ?: any;
    label ?: string;
    margin ?: PropTypes.Margin;
    className ?: string;
    field : string;
    forwardedRef ?: React.RefObject<HTMLInputElement>;
    style ?: React.CSSProperties;
    defaultValue ?: string;
    forceUppercase ?: boolean;
    value ?: any;
}

class CustomTextInput extends React.Component<ICustomTextInputProps & FieldContext<IFormValue>> {

    constructor(props : ICustomTextInputProps & FieldContext<IFormValue>) {
        super(props);
    }

    public componentDidUpdate = (prevProps : ICustomTextInputProps) => {
        const nextProps = this.props;
        if (nextProps && (!prevProps?.value && nextProps?.value)) {
            nextProps.fieldApi.setValue(nextProps.value);
            if (nextProps.forceUppercase) {
                nextProps.fieldApi.setValue(nextProps.value.toUpperCase());
            } else {
                nextProps.fieldApi.setValue(nextProps.value);
            }
            if (!!nextProps.onChange) {
                nextProps.onChange(nextProps?.value);
            }
        }
    };

    public render() {
        const { onChange, onBlur, initialValue,
            id, value, label, margin, className, type, required, fieldState, fieldApi, disabled, forceUppercase } = this.props;

        const { error } = fieldState;
        const { setValue, setTouched } = fieldApi;

        if (initialValue) {
            setValue(initialValue);
        }

        return (
            <>
                <FormControl margin={margin} className={className} required={required}>
                    <TextField error={
                        (!!required && !!!fieldApi.getValue()) ||
                        !!error
                    }
                    id={id}
                    type={type}
                    label={label}
                    className={className}
                    disabled={disabled}
                    value={!value && value !== 0 ? '' : value}
                    onChange={(e) => {
                        if (forceUppercase) {
                            setValue(e.target.value.toUpperCase());
                        } else {
                            setValue(e.target.value);
                        }
                        if (!!onChange) {
                            onChange(e.target.value, e);
                        }
                    }}
                    onBlur={(e) => {
                        setTouched(true);
                        if (!!onBlur) {
                            onBlur(e);
                        }
                    }}
                    />
                    {
                        !!this.props.children && this.props.children
                    }
                    {
                        !!fieldApi && !!error ? (
                            <FormHelperText error>{error}</FormHelperText>
                        ) : null
                    }
                </FormControl>
            </>
        );
    }
}

export default asField<ICustomTextInputProps>(CustomTextInput);

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IFaq } from '../../../@types/model/frequentlyAskedQuestions/faq';

export default class FrequentlyAskedQuestionsHttpService {
    public static getFaqData() : AxiosPromise<Array<IFaq>> {
        return axios.get(`${PACKMAN_API_URL}Faq`);
    }

    public static addOrUpdateFaq(data : IFaq) : AxiosPromise<IFaq> {
        return axios.post(`${PACKMAN_API_URL}Faq/PostFaq`, data);
    }
}

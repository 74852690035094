import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { ITrip } from '../../../@types/model/dispatch/trip';
import { ITripRelatedData } from '../../../@types/model/dispatch/tripRelatedData';
import qs from 'qs';

export default class TripHttpService {
    public static getTripData() : AxiosPromise<Array<ITrip>> {
        return axios.get(`${PACKMAN_API_URL}Trip`);
    }

    public static getAvailableTrips(fromDateUnix ?: number, toDateUnix ?: number) : AxiosPromise<Array<ITrip>> {
        return axios.get(`${PACKMAN_API_URL}Trip/GetAvailableTrips`, {
            params: {
                fromDateUnix,
                toDateUnix,
            },
        });
    }

    public static getTrip(id : number) : AxiosPromise<ITrip> {
        return axios.get(`${PACKMAN_API_URL}Trip/GetTrip`, {
            params: {
                id,
            },
        });
    }

    public static addOrUpdateTrip(data : ITrip) : AxiosPromise<ITrip> {
        return axios.post(`${PACKMAN_API_URL}Trip/PostTrip`, data);
    }

    public static getTripTransactionData(fromDateUnix ?: number, toDateUnix ?: number, organizationIds ?: Array<number>, siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<ITripRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Trip/Transaction`, {
            params: {
                fromDateUnix,
                toDateUnix,
                organizationIds,
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static tripDelete = (tripId : number, deleteReason : string) : AxiosPromise<ITrip> => {
        return axios.delete(`${PACKMAN_API_URL}Trip/Delete`, {
            params : { tripId, deleteReason },
        });
    };
}

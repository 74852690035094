import { TextField } from '@mui/material';
import * as React from 'react';
import { IOPLine } from '../../../../../@types/model/integration/poFile/opLine';

interface IImportPOFileInfoDialogProps {
    selectedOPLine : IOPLine;
}

interface IImportPOFileInfoDialogState {
}

class ImportPOFileInfoDialog extends React.Component<IImportPOFileInfoDialogProps, IImportPOFileInfoDialogState> {
    constructor(props : IImportPOFileInfoDialogProps) {
        super(props);
    }

    public render() {
        return (
            <div className={'fdc wfill p10'}>
                <div className={'fdc mb20'}>
                    <div className={'fdr pl10'}>
                        <TextField
                            fullWidth
                            className={'mr5 flx1 mb5'}
                            variant='standard'
                            label={'Original Organization'}
                            value={''}
                            disabled={true}
                        />
                        <TextField
                            fullWidth
                            className={'mr5 flx1 mb5'}
                            variant='standard'
                            label={'Original Site'}
                            value={this.props.selectedOPLine.packhouseCode}
                            disabled={true}
                        />
                        <TextField
                            fullWidth
                            className={'mr5 flx1 mb5'}
                            variant='standard'
                            label={'Current Organization'}
                            value={this.props.selectedOPLine.organization}
                            disabled={true}
                        />
                        <TextField
                            fullWidth
                            className={'mr5 flx1 mb5'}
                            variant='standard'
                            label={'Current Site'}
                            value={this.props.selectedOPLine.locationCode}
                            disabled={true}
                        />
                        <TextField
                            fullWidth
                            className={'mr5 flx1 mb5'}
                            variant='standard'
                            label={'Pallet Base Type'}
                            value={this.props.selectedOPLine.palletBaseType}
                            disabled={true}
                        />
                    </div>
                    <div className={'fdr pl10'}>
                        <TextField
                            fullWidth
                            className={'mr5 flx1 mb5'}
                            variant='standard'
                            label={'Market'}
                            value={this.props.selectedOPLine.targetMarket}
                            disabled={true}
                        />
                        <TextField
                            fullWidth
                            className={'mr5 flx1 mb5'}
                            variant='standard'
                            label={'Brand'}
                            value={this.props.selectedOPLine.mark}
                            disabled={true}
                        />
                        <TextField
                            fullWidth
                            className={'mr5 flx1 mb5'}
                            variant='standard'
                            label={'Region'}
                            value={this.props.selectedOPLine.targetRegion}
                            disabled={true}
                        />
                        <TextField
                            fullWidth
                            className={'mr5 flx1 mb5'}
                            variant='standard'
                            label={'Country'}
                            value={this.props.selectedOPLine.targetCountry}
                            disabled={true}
                        />
                        <TextField
                            fullWidth
                            className={'mr5 flx1 mb5'}
                            variant='standard'
                            label={'Inventory'}
                            value={this.props.selectedOPLine.inventoryCode}
                            disabled={true}
                        />
                    </div>
                    <div className={'fdr pl10'}>
                        <TextField
                            fullWidth
                            className={'mr5 flx1'}
                            variant='standard'
                            label={'Commodity'}
                            value={this.props.selectedOPLine.commodity}
                            disabled={true}
                        />
                        <TextField
                            fullWidth
                            className={'mr5 flx1'}
                            variant='standard'
                            label={'Variety'}
                            value={this.props.selectedOPLine.variety}
                            disabled={true}
                        />
                        <TextField
                            fullWidth
                            className={'mr5 flx1'}
                            variant='standard'
                            label={'Pack'}
                            value={this.props.selectedOPLine.pack}
                            disabled={true}
                        />
                        <TextField
                            fullWidth
                            className={'mr5 flx1'}
                            variant='standard'
                            label={'Grade'}
                            value={this.props.selectedOPLine.grade}
                            disabled={true}
                        />
                        <TextField
                            fullWidth
                            className={'mr5 flx1'}
                            variant='standard'
                            label={'Size'}
                            value={this.props.selectedOPLine.sizeCount}
                            disabled={true}
                        />
                    </div>
                    <div className={'fdr pl10 jcfs'}>
                        <TextField
                            fullWidth
                            className={'mr5 flx1'}
                            variant='standard'
                            label={'Colour'}
                            value={this.props.selectedOPLine.varietyGroup}
                            disabled={true}
                        />
                        <TextField
                            fullWidth
                            className={'mr5 flx1'}
                            variant='standard'
                            label={'Orchard'}
                            value={this.props.selectedOPLine.orchard}
                            disabled={true}
                        />
                        <div className={'fdr flx3'}/>
                    </div>
                </div>
            </div>
        );
    };
}

export default ImportPOFileInfoDialog;

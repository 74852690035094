
import * as Yup from 'yup';

export interface IFreshServiceTicketFormValues {
    name : string;
    email : string;
    subject : string;
    description : string;
    status ?: number;
    priority ?: number;

}

export class FreshServiceTicketFormValues implements IFreshServiceTicketFormValues {
    public name : string;
    public email : string;
    public subject : string;
    public description : string;
    public status ?: number;
    public priority ?: number;

    public constructor(
        name : string,
        email : string,
        subject : string,
        description ?: string,
        status ?: number,
        priority ?: number,
    ) {
        this.name = name;
        this.email = email;
        this.subject = subject;
        this.priority = priority;
        this.status = status;
        this.description = description ?? '';
    }

    public static formSchema = Yup.object().shape({
        subject: Yup.string().required('required'),
    });
}

import * as React from 'react';
import IFrame from 'react-iframe';
import { IRootState, IAuthState } from '../../@types/redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Screen from '../../components/Screen';

interface IReportProps extends RouteComponentProps {
    auth : IAuthState;
}

class Report extends React.Component<IReportProps> {

    constructor(props : IReportProps) {
        super(props);
    }

    public render() {
        const reportURL = this.props.auth.session?.user?.rights?.find(x => x.url === this.props.location.pathname)?.reportURL;
        return <Screen isPadded={false} isScrollable={false}>
            <div className={'fdr wfill hfill oh'}>
                <div className={'flx1'}/>
                <IFrame
                    height={'100%'}
                    width={'65%'}
                    url={reportURL ?? ''}
                />
                <div className={'flx1'}/>
            </div>
        </Screen>;
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

export default withRouter(connect(
    mapStateToProps,
)(Report));

import { IBaseModel } from '../../../model';
import { ICommodityFormValues } from './commodityFormValues';
import { ICommodityConditionRel } from '../commodityConditionRel/commodityConditionRel';

export interface ICommodity extends IBaseModel {
    organizationIds : Array<number>;
    code : string;
    name : string;
    isStoneFruit ?: boolean;
    isPhytoCompliant : boolean;
    exportCode ?: string;
    temperature ?: string;
    temperatureRegime ?: string;
    conditions : Array<ICommodityConditionRel>;
    isImported ?: boolean;
}

export class Commodity implements ICommodity {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public name : string;
    public isStoneFruit ?: boolean;
    public isPhytoCompliant : boolean;
    public organizationIds : Array<number>;
    public exportCode ?: string | undefined;
    public temperature ?: string;
    public temperatureRegime ?: string;
    public conditions : Array<ICommodityConditionRel>;
    public isImported ?: boolean;

    public constructor(formValues : ICommodityFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.code = formValues.code;
        this.name = formValues.name;
        this.isStoneFruit = formValues.isStoneFruit;
        this.temperature = formValues.temperature;
        this.temperatureRegime = formValues.temperatureRegime;
        this.isPhytoCompliant = formValues.isPhytoCompliant;
        this.isImported = formValues.isImported;

        this.organizationIds = formValues.organizations.map(x => Number(x.value)) ?? [];
        this.conditions = formValues.commodityConditionOptions.map((x, i) => {
            return {
                order: i + 1,
                commodityId: formValues.id,
                isSpacer: x.label.includes('<spacer>'),
                conditionId: Number(x.value) > 0 ? Number(x.value) : undefined,
            };
        });
        this.exportCode = formValues.exportCode;
    }
}

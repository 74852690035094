import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IUnitOfMeasureType } from '../../../@types/model/masterData/unitOfMeasureType/unitOfMeasureType';

export default class UnitOfMeasureTypeHttpService {
    public static getUnitOfMeasureTypeData() : AxiosPromise<Array<IUnitOfMeasureType>> {
        return axios.get(`${PACKMAN_API_URL}UnitOfMeasureType/GetAllWeb`);
    }

    public static addOrUpdateUnitOfMeasureType(data : IUnitOfMeasureType) : AxiosPromise<IUnitOfMeasureType> {
        return axios.post(`${PACKMAN_API_URL}UnitOfMeasureType/PostUnitOfMeasureType`, data);
    }

    public static deleteUnitOfMeasureType(unitOfMeasureTypeId : number) : AxiosPromise<IUnitOfMeasureType> {
        return axios.delete(`${PACKMAN_API_URL}UnitOfMeasureType/Delete`, {
            params : { unitOfMeasureTypeId },
        });
    }
}

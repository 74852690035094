import axios, { AxiosPromise } from 'axios';
import { IGoogleCloudStorage } from '../@types/model/googleCloudStorage/googleCloudStorage';
import { PACKMAN_API_URL } from '../appConstants';

export default class GoogleCloudStorageHttpService {
    public static getListData(path : string) : AxiosPromise<Array<any>> {
        return axios.get(`${PACKMAN_API_URL}GoogleCloudStorage?path=${path}`);
    }

    public static downloadFile(path : string, fileName : string) : AxiosPromise<string> {
        return axios.get(`${PACKMAN_API_URL}GoogleCloudStorage/DownloadFile`, {
            params: {
                fileName,
                path,
            },
        });
    }

    public static uploadFile(data : Array<IGoogleCloudStorage>) : AxiosPromise<any> {
        return axios.post(`${PACKMAN_API_URL}GoogleCloudStorage/PostGoogleCloudStorage`, data);
    }

    public static deleteFile(path : string, fileName : string) : AxiosPromise<any> {
        return axios.delete(`${PACKMAN_API_URL}GoogleCloudStorage/DeleteFile`, {
            params: {
                fileName,
                path,
            },
        });
    }
}

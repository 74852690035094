import * as React from 'react';
import { Form, FormikProps, FieldProps, Formik, FormikActions } from 'formik';
import { CommodityFormValues } from '../../../../@types/model/masterData/commodity/commodityFormValues';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import { DispatchCall, IRootState, RootAction, IAuthState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import FormAutocompleteSelect from '../../../../components/input/form/FormAutoCompleteSelect';
import { createSelector } from 'reselect';
import { IRight } from '../../../../@types/model/user/right';
import { IOrganization } from '../../../../@types/model/masterData/organization/organization';
import { IOptionType } from '../../../../@types/helper';
import { Draggable, Droppable, DragDropContext, DropResult } from 'react-beautiful-dnd';
import { ICommodityConditionRel } from '../../../../@types/model/masterData/commodityConditionRel/commodityConditionRel';
import { CommodityCondition, ICommodityCondition } from '../../../../@types/model/masterData/commodityCondition/commodityCondition';
import { Card, Icon, Typography } from '@mui/material';
import { orderBy } from 'lodash';
import { dataSetCommodityConditions } from '../../../../store/masterData/Actions';
import { addArrayElement, upsertArrayElement } from '../../../../services/appFunctionsService';
import { MAX_COMMODITY_CONDITION_COLUMNS, renderCommodityConditionsHeader } from '../../../../services/compliance/documentService';
import { Document, Page, PDFViewer } from '@react-pdf/renderer';
import PackmanDialog from '../../../../components/dialog/PackmanDialog';
import { CommodityConditionFormValues, ICommodityConditionFormValues } from '../../../../@types/model/masterData/commodityCondition/commodityConditionFormValues';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../../store/general/Functions';
import CommodityConditionHttpService from '../../../../services/http/masterData/commodityConditionHttpService';
import CommodityConditionForm from '../../commodityCondition/form/CommodityConditionForm';
import CustomTooltip from '../../../../components/tooltip/tooltip';

const COMMODITY_CONDITION_EDIT = 'COMMODITY_CONDITION_EDIT';

interface ICommodityFormProps {
    dataSetCommodityConditions : DispatchCall<Array<ICommodityCondition>>;
    isLoading : boolean;
    organizationOptions : Array<IOptionType>;
    commodityConditionOptions : Array<IOptionType>;
    commodityConditions : Array<ICommodityCondition>;
    auth : IAuthState;
}

interface ICommodityFormState {
    conditions : Array<ICommodityConditionRel>;
    selectedCommodityCondition ?: ICommodityCondition;
    isDialogOpen : boolean;
    isLoading : boolean;
}

type CommodityFormPropsType = ICommodityFormProps & FormikProps<CommodityFormValues>;

class CommodityForm extends React.Component<CommodityFormPropsType, ICommodityFormState> {

    constructor(props : CommodityFormPropsType) {
        super(props);

        this.state = {
            conditions: [],
            isLoading: false,
            selectedCommodityCondition: undefined,
            isDialogOpen: false,
        };
    }

    public componentDidMount = () => {
        this.setState({ conditions: this.props.values.commodityConditions });
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private onDragStart = () => null;
    private onDragEnd = (a : DropResult) => {
        const startOrder = a.source.index;
        const newOrder = (a.destination?.index ?? 0);

        if (a.destination && (startOrder !== (newOrder + 1))) {
            const conditions = [...this.state.conditions];
            const index = conditions.findIndex(x => x.order === startOrder);
            if (index !== -1) {
                const condition = { ...conditions[index] };
                conditions.splice(index, 1);
                conditions.splice(newOrder, 0, condition);
                conditions.forEach((x, i) => x.order = i + 1);
                this.setState({ conditions });
                const options = conditions?.filter(x => !!x.conditionId || x.isSpacer).map((x) => {
                    if (x.isSpacer) {
                        return {
                            label: `(${x.order}) <spacer>`,
                            value: -1 * x.order,
                        };
                    }
                    return {
                        label: `(${x.order}) ${this.props.commodityConditions?.find(y => y.id === x.conditionId)?.condition}`,
                        value: x.conditionId ?? 0,
                    };
                }) ?? [];
                this.props.setFieldValue('commodityConditionOptions', options);
            }
        }
    };

    private addSpacer = () => {
        const values = { ...this.props.values };
        let conditionOptions = [...values['commodityConditionOptions']];
        conditionOptions = addArrayElement(conditionOptions, {
            value: -1 * (conditionOptions.length + 1),
            label : `(${conditionOptions.length + 1}) <spacer>`,
        }, 'end');

        const conditions : Array<ICommodityConditionRel> = conditionOptions.map((x, i) => {
            return {
                order: i + 1,
                commodityId: this.props.initialValues.id,
                isSpacer: x.label.includes('<spacer>'),
                conditionId: Number(x.value) > 0 ? Number(x.value) : undefined,
            };
        });

        this.props.setFieldValue('commodityConditionOptions', conditionOptions);
        this.setState({ conditions });
    };

    private addCommodityCondition = () => this.openDialog();

    public onSubmit = async (values : ICommodityConditionFormValues) => {
        this.setLoading(true);

        try {
            const res = await CommodityConditionHttpService.addOrUpdateCommodityCondition(new CommodityCondition(values));

            const newCommodityConditionList = upsertArrayElement(this.props.commodityConditions, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetCommodityConditions(newCommodityConditionList);
            if (this.state.selectedCommodityCondition) {
                generalShowSuccessSnackbar('Commodity Condition updated successfully.');
            } else {
                generalShowSuccessSnackbar('Commodity Condition added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating Commodity Condition data.');
        } finally {
            this.setLoading(false);
        }
    };

    private getRights = (props : ICommodityFormProps) => props.auth?.session?.user?.rights || [];

    private hasCommodityConditionEditingRight = createSelector(
        [this.getRights],
        (rights : Array<IRight>) => rights.some(x => x.code === COMMODITY_CONDITION_EDIT && x.isActive));

    public onReset = async (formValues : ICommodityConditionFormValues, formikActions : FormikActions<ICommodityConditionFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    private onCommodityOptionsChanged = (values : Array<IOptionType>, fieldProps : FieldProps<IOptionType>) => {
        if (values.some(x => !x.label.startsWith('('))) {
            /* new element got added */
            values.forEach((x, i) => {
                if (!x.label.startsWith('(')) {
                    x.label = `(${(i + 1)}) ${x.label}`;
                }
            });
            if (!values.some((x, i) => {
                const order = x.label?.split('(')[1]?.split(')')[0];
                return order !== (i + 1).toString();
            })) {
                fieldProps.form.setFieldValue(fieldProps.field.name, values);
            }
        }

        if (values.some((x, i) => {
            const order = x.label?.split('(')[1]?.split(')')[0];
            return order !== (i + 1).toString();
        })) {
            /* fix order of the whole array */
            values.forEach((x, i) => {
                const order = x.label?.split('(')[1]?.split(')')[0];
                if (order !== (i + 1).toString()) {
                    x.label = `(${(i + 1).toString()})` + x.label?.split(')')[1];
                }
            });
            fieldProps.form.setFieldValue(fieldProps.field.name, values);
        }

        const conditions : Array<ICommodityConditionRel> = values.map((x, i) => {
            return {
                order: i + 1,
                commodityId: this.props.initialValues.id,
                isSpacer: x.label.includes('<spacer>'),
                conditionId: Number(x.value) > 0 ? Number(x.value) : undefined,
            };
        });

        this.setState({ conditions });
    };

    private getCommodityConditionOptions = (props : ICommodityFormProps) => props.commodityConditionOptions;
    private getConditions = (props : ICommodityFormProps, state : ICommodityFormState) => state.conditions;

    private getFilteredCommodityConditionOptions = createSelector([this.getCommodityConditionOptions, this.getConditions],
        (options : Array<IOptionType>) => options.filter(x => (this.getConditionRows(Number(x.value)) + this.getConditionTotalRows()) <= MAX_COMMODITY_CONDITION_COLUMNS));

    private getCommodityConditionName = (conditionId : number) => this.props.commodityConditions.find(x => x.id === conditionId)?.condition;

    private renderPDF = createSelector([this.getConditions],
        (conditions : Array<ICommodityConditionRel>) => conditions && conditions.length > 0 ? <div className={'commodityPDFpreview-parent'}>
            <PDFViewer className={'commodityPDFpreview'}>
                <Document>
                    <Page size={[545, 90]}>
                        {renderCommodityConditionsHeader(undefined, orderBy(conditions, x => x.order))}
                    </Page>
                </Document>
            </PDFViewer>
        </div> : <div/>);

    private getConditionRows = (conditionId ?: number) => {
        const value = this.props.commodityConditions.find(x => x.id === conditionId)?.condition ?? '';
        return value?.split(/\r\n|\r|\n/)?.length ?? 1;
    };

    private getConditionTotalRows = () => {
        const newConditions = [...this.state.conditions];
        let total = 0;
        newConditions?.forEach(x => total += this.getConditionRows(x.conditionId));
        return total;
    };

    public getSelectedCommodityCondition = (props : ICommodityFormProps, state : ICommodityFormState) => state.selectedCommodityCondition;

    public getInitialFormValues = createSelector(
        [this.getSelectedCommodityCondition],
        (commodityCondition : ICommodityCondition) => {
            return new CommodityConditionFormValues(commodityCondition);
        },
    );

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedCommodityCondition: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    public render() {
        const selectedCommodity = this.props.values;
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (<>
            <PackmanDialog
                title='Commodity Condition'
                isEdit={!!this.state.selectedCommodityCondition}
                isLoading={this.state.isLoading}
                isOpen={this.state.isDialogOpen}
                onClose={this.closeDialog}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={this.onSubmit}
                    onReset={this.onReset}
                    enableReinitialize
                    validationSchema={CommodityConditionFormValues.formSchema}
                    component={CommodityConditionForm} />
            </PackmanDialog >
            <Form className={'p20'}>
                <div className={'fdr'}>
                    <CustomTooltip title={!!selectedCommodity.isImported ? 'Cannot change this field if commodity is imported' : ''}>
                        <div className={'flx1'}>
                            <FormTextInput name={'code'} label={'Code'} className={'flx1'} disabled={!!selectedCommodity.isImported}/>
                        </div>
                    </CustomTooltip>
                    <CustomTooltip title={!!selectedCommodity.isImported ? 'Cannot change this field if commodity is imported' : ''}>
                        <div className={'flx1'}>
                            <FormTextInput name={'name'} label={'Name'} className={'flx1'} disabled={!!selectedCommodity.isImported}/>
                        </div>
                    </CustomTooltip>
                    <div className={'flx1'}>
                        <FormTextInput name={'exportCode'} label={'Export Code'} className={'flx1'}/>
                    </div>
                </div>
                <div className={'fdr'}>
                    <FormAutocompleteSelect
                        name={'organizations'}
                        label={'Organizations'}
                        options={this.props.organizationOptions}
                        isMulti
                        className={'flx1'}
                    />
                    <div className={'flx1'}>
                        <FormTextInput name={'temperature'} label={'Temperature'} className={'flx1'}/>
                    </div>
                    <div className={'flx1'}>
                        <FormTextInput name={'temperatureRegime'} label={'Temperature Regime'} className={'flx1'}/>
                    </div>
                </div>
                <div className={'fdr'}>
                    <FormSingleToggleButton name={'isStoneFruit'} label={'Is Stone Fruit'} />
                    <FormSingleToggleButton name={'isPhytoCompliant'} label={'Is Phyto Compliant'} />
                    <CustomTooltip title={!!selectedCommodity.isImported ? 'Cannot change this field if commodity is imported' : ''}>
                        <FormSingleToggleButton name={'isActive'} label={'Is Active'} disabled={!!selectedCommodity.isImported}/>
                    </CustomTooltip>
                </div>
                <Typography color={'primary'} variant={'h6'} className={'pl20'}>FINDING SHEET CONDITIONS</Typography>
                <div className={'fdr'}>
                    <div className={'fdc w400'}>
                        <DragDropContext onDragEnd={this.onDragEnd} onBeforeDragStart={this.onDragStart}>
                            <Droppable droppableId={'complianceConditions'}>
                                {dropProvided => <div className={'hfill p20'} ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                                    <Card className={'h600 wfill'}>
                                        {orderBy(this.state.conditions, x => x.order).map(x =>
                                            <Draggable index={x.order} draggableId={x.order.toString()} key={`condition_drag_${x.order.toString() + x.isSpacer + x.conditionId}`}>
                                                {dragProvided => <div
                                                    ref={dragProvided.innerRef}
                                                    {...dragProvided.draggableProps}
                                                    {...dragProvided.dragHandleProps}>
                                                    <div className={'m5 p5 PaperBorder fdc DispatchListItem'} >
                                                        {x.isSpacer ?
                                                            `(${x.order}) <spacer>`
                                                            :
                                                            `(${x.order}) ${x.conditionId && this.getCommodityConditionName(x.conditionId)}`
                                                        }
                                                    </div>

                                                </div>
                                                }
                                            </Draggable>)
                                        }
                                        <div className={'dn' }>{dropProvided.placeholder}</div>
                                    </Card>
                                </div>}
                            </Droppable>
                        </DragDropContext>
                    </div>
                    <div className={'fdc flx1'}>
                        <div className={'fdc aifs pb20'}>
                            <FormAutocompleteSelect
                                name={'commodityConditionOptions'}
                                label={'Commodity Conditions'}
                                options={this.getFilteredCommodityConditionOptions(this.props, this.state)}
                                isMulti
                                className={'flx1 mnw400'}
                                onChange={this.onCommodityOptionsChanged}
                            />
                            <Button
                                className={'fwb h35 w200 jcfs'}
                                startIcon={<Icon>add_circle</Icon>}
                                variant='text' color='primary'
                                onClick={this.addCommodityCondition}
                                disabled={!this.hasCommodityConditionEditingRight(this.props)}>
                                Add New Condition
                            </Button>
                            <Button
                                className={'fwb h35 w200 jcfs'}
                                startIcon={<Icon>add_circle</Icon>}
                                variant='text' color='primary'
                                onClick={this.addSpacer}>
                                Add Spacer
                            </Button>
                        </div>
                        <div className={'fdr w700 pt20 pb10 aic'}>
                            <Typography color={'primary'} variant={'h6'}>PREVIEW</Typography>
                            <div className={'flx1'}/>
                            <Typography variant={'subtitle2'}>{`ROWS: ${this.getConditionTotalRows()} / ${MAX_COMMODITY_CONDITION_COLUMNS}`}</Typography>
                        </div>
                        <div className={'fdr flx1'}>
                            {this.renderPDF(this.props, this.state)}
                        </div>
                        <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                            <Button
                                className={'fwb h35'}
                                variant='text' color='primary'
                                type={'reset'}>
                                Clear
                            </Button>
                            <PillButton
                                disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                                className={'ml15 pl20 pr20 h35'}
                                text={'Save'}
                                type={'submit'}
                                color={'secondary'}
                            />
                        </div>
                    </div>
                </div>
            </Form>

        </>
        );
    }
}

const getOrganizations = (state : IRootState) => state.masterData.organizations;
const getCommodityConditions = (state : IRootState) => state.masterData.commodityConditions;

const getOrganizationOptions = createSelector(
    [getOrganizations],
    (organizations : Array<IOrganization>) => {
        return organizations.filter(x => x.isActive).map(x => ({ label:  `(${x.code}) ${x.name}`, value: x.id }));
    },
);

const getCommodityConditionOptions = createSelector(
    [getCommodityConditions],
    (commodityConditions : Array<ICommodityCondition>) => {
        return commodityConditions?.filter(x => x.isActive).map(x => ({ label: x.condition, value: x.id })) ?? [];
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        organizationOptions: getOrganizationOptions(state),
        commodityConditionOptions: getCommodityConditionOptions(state),
        commodityConditions: state.masterData.commodityConditions,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetCommodityConditions }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CommodityForm);

import * as React from 'react';
import { Typography, IconButton, Icon } from '@mui/material';
import { IRootState, IAuthState } from '../../@types/redux';
import { connect } from 'react-redux';
import { Form, FormApi } from 'informed';
import { isEmptyObject } from '../../services/appFunctionsService';
import { generalShowErrorSnackbar } from '../../store/general/Functions';
import uuidv1 from 'uuid';
import validationFunctions from '../../services/validationService';
import CustomAutoSuggest from '../../components/input/CustomAutoSuggest';
import { IDropDownOptions } from '../../@types/other';
import { IOrderRequest } from '../../@types/model/order/orderRequest';
import { IOrderHeader } from '../../@types/model/order/orderHeader';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { ISite } from '../../@types/model/masterData/site/site';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { ISize } from '../../@types/model/masterData/size/size';
import CustomTextInput from '../../components/input/CustomTextInput';

interface IOrderRequestFormProps {
    selectedOrderRequest ?: IOrderRequest;
    selectedOrder ?: IOrderHeader;
    selectedOrganization : number;
    orderHeaderData : Array<IOrderHeader>;
    organizations : Array<IOrganization>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    sizes : Array<ISize>;
    sites : Array<ISite>;
    colours : Array<IColour>;
    grades : Array<IGrade>;
    isEditing ?: boolean;
    isAdding ?: boolean;
    onClose : () => void;
    auth : IAuthState;
    addOrderRequest : (orderRequest : IOrderRequest) => void;
}
interface IOrderRequestFormState {
    selectedCommodityId : number;
    selectedPack ?: IPack;
}

class OrderRequestForm extends React.Component<IOrderRequestFormProps, IOrderRequestFormState> {
    private formApi : FormApi<IOrderRequest>;
    constructor(props : IOrderRequestFormProps) {
        super(props);

        this.state = {
            selectedCommodityId: 0,
        };

    }

    public componentDidMount = () => {
        const request = this.props.selectedOrderRequest;
        this.setState({
            selectedCommodityId: request ? request.commodityId : 0,
            selectedPack: request && request.pack,
        });
    };

    private close = () => {
        this.formApi.reset();
        this.props.onClose();
    };

    private submit = () => {
        const values = this.formApi.getState().values;
        const touched = this.formApi.getState().touched;
        const valid = !this.formApi.getState().invalid;

        const existingOrderRequest = this.props.selectedOrderRequest;

        if (!valid) {
            generalShowErrorSnackbar('Check form validation!');
            return;
        }

        if (isEmptyObject(touched)) {
            if (this.props.isEditing) {
                generalShowErrorSnackbar('Nothing to update!');
            } else if (this.props.isAdding) {
                generalShowErrorSnackbar('Nothing to add!');
            }
            return;
        }

        if (this.props.isEditing && !this.props.selectedOrderRequest) {
            generalShowErrorSnackbar('No order request selected!');
            return;
        }

        if (!isEmptyObject(touched)) {
            const newRowModel : IOrderRequest = { ...values };
            if (this.props.isEditing && this.props.selectedOrderRequest) {
                newRowModel.id = this.props.selectedOrderRequest ? this.props.selectedOrderRequest.id : 0;
                newRowModel.orderId = this.props.selectedOrder ? this.props.selectedOrder.id : 0;
                newRowModel.guid = existingOrderRequest ? existingOrderRequest.guid : uuidv1();
                newRowModel.isActive = existingOrderRequest ? existingOrderRequest.isActive : false;
            }

            if (this.props.isAdding) {
                newRowModel.guid = uuidv1();
                newRowModel.id = 0;
                newRowModel.orderId = this.props.selectedOrder ? this.props.selectedOrder.id : 0;
                newRowModel.isActive = true;
            }

            this.props.addOrderRequest(newRowModel);
            this.close();
        }
    };

    private setFormApi = (formApi : FormApi<IOrderRequest>) => {
        this.formApi = formApi;
    };

    private commodityOptions = () : Array<IDropDownOptions> => {
        const organization = this.props.selectedOrganization;
        if (!this.props.commodities || !organization) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Commodity', disabled: true });
        this.props.commodities.filter(x => x.organizationIds.some(y => y === organization) && x.isActive)
            .forEach(x => returnValue.push({ value: x.id, label: x.code + '-' + x.name }));
        return returnValue;
    };

    private varietyOptions = () : Array<IDropDownOptions> => {
        const organization = this.props.selectedOrganization;
        const commodity = this.state.selectedCommodityId;
        if (!this.props.varieties || !organization || !commodity) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Variety', disabled: true });
        this.props.varieties.filter(x => x.isActive && x.commodityId === Number(commodity)).forEach(x => returnValue.push({ value: x.id, label: x.code + '-' + x.name }));
        return returnValue;
    };

    private packOptions = () : Array<IDropDownOptions> => {
        const organization = this.props.selectedOrganization;
        const commodity = this.state.selectedCommodityId;
        if (!this.props.packs || !organization || !commodity) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Pack', disabled: true });
        this.props.packs.filter(x => x.isActive && x.commodityId === Number(commodity))
            .forEach(x => returnValue.push({ value: x.id, label: x.code + '-' + x.description }));
        return returnValue;
    };

    private sizeOptions = () : Array<IDropDownOptions> => {
        const pack = this.state.selectedPack;
        if (!pack || (pack && !pack.sizeIds)) {
            return [];
        }

        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Size', disabled: true });

        this.props.sizes
            .filter(x => pack.sizeIds.some(y => y === x.id))
            .filter(x => x.isActive)
            .forEach(x => returnValue
                .push({ value: x.id, label: x.code + '-' + x.name }));
        return returnValue;
    };

    private gradeOptions = () : Array<IDropDownOptions> => {
        const organization = this.props.selectedOrganization;
        const commodity = this.state.selectedCommodityId;
        if (!this.props.grades || !organization || !commodity) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Grade', disabled: true });
        this.props.grades.filter(x => x.isActive && x.commodityIds.some(y => y === Number(commodity)))
            .forEach(x => returnValue.push({ value: x.id, label: x.code + '-' + x.name }));
        return returnValue;
    };

    private colourOptions = () : Array<IDropDownOptions> => {
        const organization = this.props.selectedOrganization;
        const commodity = this.state.selectedCommodityId;
        if (!this.props.colours || !organization || !commodity) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Colour', disabled: true });
        this.props.colours.filter(x => x.isActive && x.commodityIds?.some(y => y === Number(commodity)))
            .forEach(x => returnValue.push({ value: x.id, label: x.code + '-' + x.name }));
        return returnValue;
    };

    private setSelectedCommodityId = (value : number) => this.setState({ selectedCommodityId: value });
    private setSelectedPack = (packId : number) =>  {
        const pack = this.props.packs.find(x => x.id === packId);
        this.setState({ selectedPack: pack });
    };

    public render() {
        const selectedOrderRequest = this.props.selectedOrderRequest;
        return <div>
            <Form getApi={this.setFormApi}>
                <div className={'fdr mb10'}>
                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                        <CustomAutoSuggest
                            initialValue={selectedOrderRequest ? selectedOrderRequest.commodityId : 0}
                            hasInitialValue={!!(selectedOrderRequest && selectedOrderRequest.commodityId)}
                            className={'flx1'}
                            label={'Commodity'}
                            field={'commodityId'}
                            id={'order_request_commodityId'}
                            options={this.commodityOptions()}
                            onValueChange={value => this.setSelectedCommodityId(Number(value))}
                            placeholder={'Select Commodity...'}
                            validate={validationFunctions.required}
                            validateOnMount
                            validateOnChange
                            validateOnBlur
                        />
                    </Typography>
                    <span className={'w10'} />
                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                        <CustomAutoSuggest
                            initialValue={selectedOrderRequest ? selectedOrderRequest.varietyId : 0}
                            hasInitialValue={!!(selectedOrderRequest && selectedOrderRequest.varietyId)}
                            className={'flx1'}
                            field={'varietyId'}
                            id={'order_request_varietyId'}
                            label={'Variety'}
                            options={this.varietyOptions()}
                            placeholder={'Select Variety...'}
                            validate={validationFunctions.required}
                            validateOnMount
                            validateOnChange
                            validateOnBlur
                        />
                    </Typography>
                    <span className={'w10'} />
                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                        <CustomAutoSuggest
                            initialValue={selectedOrderRequest ? selectedOrderRequest.packId : 0}
                            hasInitialValue={!!(selectedOrderRequest && selectedOrderRequest.packId)}
                            className={'flx1'}
                            field={'packId'}
                            id={'order_request_packId'}
                            label={'Pack'}
                            options={this.packOptions()}
                            placeholder={'Select Pack...'}
                            onValueChange={value => this.setSelectedPack(Number(value))}
                            validate={validationFunctions.required}
                            validateOnMount
                            validateOnChange
                            validateOnBlur
                        />
                    </Typography>
                    <span className={'w10'} />
                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                        <CustomAutoSuggest
                            initialValue={selectedOrderRequest ? selectedOrderRequest.sizeId : 0}
                            hasInitialValue={!!(selectedOrderRequest && selectedOrderRequest.sizeId)}
                            className={'flx1'}
                            field={'sizeId'}
                            id={'order_request_sizeId'}
                            label={'Size'}
                            options={this.sizeOptions()}
                            placeholder={'Select Size...'}
                            validate={validationFunctions.required}
                            validateOnMount
                            validateOnChange
                            validateOnBlur
                        />
                    </Typography>
                    <span className={'w10'} />
                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                        <CustomAutoSuggest
                            initialValue={selectedOrderRequest ? selectedOrderRequest.gradeId : 0}
                            hasInitialValue={!!(selectedOrderRequest && selectedOrderRequest.gradeId)}
                            className={'flx1'}
                            field={'gradeId'}
                            id={'order_request_gradeId'}
                            label={'Grade'}
                            options={this.gradeOptions()}
                            placeholder={'Select Grade...'}
                            validate={validationFunctions.required}
                            validateOnMount
                            validateOnChange
                            validateOnBlur
                        />
                    </Typography>
                    <span className={'w10'} />
                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                        <CustomAutoSuggest
                            initialValue={selectedOrderRequest ? selectedOrderRequest.colourId : 0}
                            hasInitialValue={!!(selectedOrderRequest && selectedOrderRequest.colourId)}
                            className={'flx1'}
                            field={'colourId'}
                            id={'order_request_colourId'}
                            label={'Colour'}
                            options={this.colourOptions()}
                            placeholder={'Select Colour...'}
                            validate={validationFunctions.required}
                            validateOnMount
                            validateOnChange
                            validateOnBlur
                        />
                    </Typography>
                    <span className={'w10'} />
                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                        <CustomTextInput
                            className={'flx1'}
                            type='number'
                            field={'quantity'}
                            label={'Requested Quantity'}
                            initialValue={selectedOrderRequest ? selectedOrderRequest.quantity : 0}
                        />
                    </Typography>
                </div>
                <div className={'fdr p20'}><div className={'flx1'}/>
                    <IconButton className={'cw h50 bcp'} onClick={this.submit}>
                        <Icon>{ this.props.isAdding ? 'add' : 'done'}</Icon>
                    </IconButton>
                </div>
            </Form>
        </div>;
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth : state.auth,
        orderHeaderData: state.data.orderHeaders,
        organizations: state.masterData.organizations,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        colours: state.masterData.colours,
        grades: state.masterData.grades,
        sites: state.masterData.sites,
        sizes: state.masterData.sizes,
    };
};

export default connect(
    mapStateToProps,
)(OrderRequestForm);

import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState, RootAction } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Typography } from '@mui/material';
import { formatDateTimeToDateOnly, compareDate, formatDateTime } from '../../../services/appFunctionsService';
import CustomTable from '../../../components/datagrid/CustomTable';
import { IOrganization } from '../../../@types/model/masterData/organization/organization';
import { ISite } from '../../../@types/model/masterData/site/site';
import Screen from '../../../components/Screen';
import { ITrip } from '../../../@types/model/dispatch/trip';
import { IDispatchInstruction } from '../../../@types/model/dispatch/dispatchInstruction';
import { ICarrier } from '../../../@types/model/masterData/carrier/carrier';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { ITemperatureUnit } from '../../../@types/model/dispatch/temperatureUnit';
import { IStock } from '../../../@types/model/stock/stock';
import { ITruckType } from '../../../@types/model/masterData/truckType/truckType';
import { PackmanLabel } from '../../../components/label/PackmanLabel';
import { PackmanLink } from '../../../components/link/packmanLink';
import { BooleanFlag } from '../../../components/label/BooleanFlag';

interface ITripSummaryProps {
    selectedTrip : ITrip;
    dispatches : Array<IDispatchInstruction>;
    carriers : Array<ICarrier>;
    sites : Array<ISite>;
    stocks : Array<IStock>;
    organizations : Array<IOrganization>;
    truckTypes : Array<ITruckType>;
    isLoading : boolean;
}

interface ITripSummaryState {
}

class TripSummary extends React.Component<ITripSummaryProps, ITripSummaryState> {
    constructor(props : ITripSummaryProps) {
        super(props);
    }

    private getOrganizationName = (orgId : number) => {
        const organizations = this.props.organizations;
        const organization = organizations && organizations.find(x => x.id === orgId);
        return organization ? organization.code : '';
    };

    private getCarrierName = (carrierId : number) => {
        const carriers = this.props.carriers;
        const carrier = carriers && carriers.find(x => x.id === carrierId);
        return carrier ? carrier.name : '';
    };

    private getTruckTypeCode = (id : number) => {
        const truckTypes = this.props.truckTypes;
        const truckType = truckTypes && truckTypes.find(x => x.id === id);
        return truckType ? truckType.code : '';
    };

    private getSiteShortDescription = (id : number) => {
        const site = this.props.sites && this.props.sites.find(x => x.id === id);
        return site && site.shortDescription ? site.shortDescription : 'UNK?';
    };

    private getStockBarcode = (stockId : number) => {
        const barcode = this.props.stocks.find(x => x.id === stockId)?.barcode;

        return <PackmanLink
            type={'transactions'}
            targetPage={'dispatch'}
            id={Number(stockId)}
            text={barcode} />;
    };

    private getTemperatureUnitColumns = () => [
        { title: 'Barcode', field: 'stockId', formatFunction: this.getStockBarcode, enableSorting: true },
        { title: 'Device Number', field: 'deviceNumber', enableSorting: true },
    ];

    private formatDispatchCode = (row : IDispatchInstruction, code : string) => {
        return <PackmanLink
            type={'transactions'}
            targetPage={'dispatch'}
            id={Number(row.id)}
            text={code} />;
    };

    private getColumns = () => [
        { title: 'Dispatch Code', field: 'dispatchCode', containerComponent: this.formatDispatchCode, enableSorting: true },
        { title: 'Source Site', field: 'sourceSiteId', formatFunction: this.getSiteShortDescription, enableSorting: true },
        { title: 'Organization', field: 'organizationId', formatFunction: this.getOrganizationName, enableSorting: true },
        { title: 'Destination Site', field: 'destinationSiteId', formatFunction: this.getSiteShortDescription, enableSorting: true },
        { title: 'Load Date', field: 'loadDate', formatFunction: formatDateTimeToDateOnly, sortFunction: compareDate, type: 'date', enableSorting: true },
        { title: 'Compliance Waybill(s)', field: 'complianceCode', enableSorting: true },
        { title: 'Sent Gross Weight (kg)', field: 'sentGrossWeight', enableSorting: true },
        { title: 'Received Gross Weight (kg)', field: 'receiveGrossWeight', enableSorting: true },
        { title: 'Printed?', field: 'isPrinted', type: 'boolean', enableSorting: true },
        { title: 'Created By', field: 'createdByName', enableSorting: true },
        { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
        { title: 'Updated By', field: 'updatedByName', enableSorting: true },
        { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
        { title: 'Active?', field: 'isActive', type: 'boolean', enableSorting: true },
    ];

    private getDispatches = (props : ITripSummaryProps) => props.dispatches;
    private getSelectedTrip = (props : ITripSummaryProps) => props.selectedTrip;

    private getRows = createSelector(
        [this.getDispatches, this.getSelectedTrip],
        (dispatches : Array<IDispatchInstruction>, selectedTrip : ITrip) => {
            if (!dispatches) return [];

            return lodash.filter(dispatches, x => x.tripId === selectedTrip.id);
        },
    );

    public render() {
        return (
            <Screen isPadded={false} isScrollable={false} isLoading={this.props.isLoading}>
                <div className={'fdc hfill'}>
                    <div className={'fdc pl10 pr10 pt10'}>
                        <div className={'fdr aic pt10 flx1'}>
                            <PackmanLabel
                                label={'Description'}
                                value={this.props.selectedTrip?.description ?? ''}
                            />
                            <PackmanLabel
                                label={'Load Date'}
                                value={this.props.selectedTrip.loadDate.toString()}
                            />
                            <PackmanLabel
                                label={'Driver'}
                                value={this.props.selectedTrip.driver ?? ''}
                            />
                            <PackmanLabel
                                label={'Container'}
                                value={this.props.selectedTrip.container ?? ''}
                            />
                            <PackmanLabel
                                label={'Container Tare Weight'}
                                value={this.props.selectedTrip.containerTareWeight ?? ''}
                            />
                            <PackmanLabel
                                label={'Registration Number'}
                                value={this.props.selectedTrip.registrationNumber ?? ''}
                            />
                            <PackmanLabel
                                label={'Carrier'}
                                value={this.getCarrierName(this.props.selectedTrip.carrierId ?? 0)}
                            />
                            <PackmanLabel
                                label={'Fleet Number'}
                                value={this.props.selectedTrip.registrationNumber ?? ''}
                            />
                        </div>
                        <div className={'fdr aifs pt10 flx1'}>
                            <PackmanLabel
                                label={'Truck Type'}
                                value={this.getTruckTypeCode(this.props.selectedTrip.truckTypeId ?? 0)}
                            />
                            <PackmanLabel
                                label={'Created By'}
                                value={this.props.selectedTrip.createdByName ?? ''}
                            />
                            <PackmanLabel
                                label={'Created On'}
                                value={formatDateTime(this.props.selectedTrip?.createdOn ?? '')}
                            />
                            <PackmanLabel
                                label={'Updated By'}
                                value={this.props.selectedTrip?.updatedByName ?? ''}
                            />
                            <PackmanLabel
                                label={'Updated On'}
                                value={formatDateTime(this.props.selectedTrip?.updatedOn ?? '')}
                            />
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Active?</Typography>
                                <BooleanFlag value={this.props.selectedTrip?.isActive ?? false}/>
                            </div>
                            <div className={'fdr aic flx2'} style={{ maxWidth: 'calc(100% * 2/8)' }}>
                                {!this.props.selectedTrip?.isActive ? <PackmanLabel
                                    label={'Delete Reason'}
                                    maxWidth={'100%'}
                                    className={'wrap2line mxwfill oh'}
                                    value={this.props.selectedTrip?.deleteReason ?? ''}
                                /> : <></>}
                            </div>
                        </div>
                    </div>
                    <Typography className={'pb0 pt10 pl20'}>Dispatches</Typography>
                    <div className={'flx1 fdr p20'}>
                        <CustomTable<IDispatchInstruction>
                            columns={this.getColumns()}
                            rows={this.getRows(this.props)}
                            fitWidthToPage
                            enableSorting
                            initialSortOrder={[{ columnName: 'loadDate_Load Date', direction : 'asc' }]}
                            isActive={(row : IDispatchInstruction) => row.isActive}
                        />
                    </div>
                    <Typography className={'pb0 pt10 pl20'}>Devices</Typography>
                    <div className={'flx1 fdr p20'}>
                        <CustomTable<ITemperatureUnit>
                            columns={this.getTemperatureUnitColumns()}
                            rows={this.props.selectedTrip?.temperatureUnits ?? []}
                            fitWidthToPage
                            enableSorting
                            initialSortOrder={[{ columnName: 'stockId_Barcode', direction : 'desc' }]}
                            isActive={(row : ITemperatureUnit) => row.isActive}
                        />
                    </div>
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        dispatches: state.data.dispatchInstructions,
        carriers: state.masterData.carriers,
        sites: state.masterData.sites,
        stocks: state.data.stocks,
        organizations: state.masterData.organizations,
        truckTypes: state.masterData.truckTypes,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TripSummary);

import * as React from 'react';
import { FormikProps, FieldArrayRenderProps } from 'formik';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { IIntakeLineFormValues, IntakeLineFormValues } from '../../../@types/model/intake/form/intakeLineFormValues';
import FormAutocompleteSelect from '../../../components/input/form/FormAutoCompleteSelect';
import FormTextInput from '../../../components/input/form/FormTextInput';
import { Accordion, AccordionSummary, Typography, AccordionDetails, Icon } from '@mui/material';
import FormSingleToggleButton from '../../../components/input/form/FormSingleToggleButton';
import { IRootState } from '../../../@types/redux';
import { IPalletBaseType } from '../../../@types/model/masterData/palletBaseType/palletBaseType';
import { IOptionType } from '../../../@types/helper';
import { IIntakeLine } from '../../../@types/model/intake/intakeLine';
import IntakeLineLayerForm from './IntakeLineLayerForm';
import PillButton from '../../../components/input/PillButton';
import { IPack } from '../../../@types/model/masterData/pack/pack';
import { setArrayElement } from '../../../services/appFunctionsService';
import { IIntakeLineLayerFormValues } from '../../../@types/model/intake/form/intakeLineLayerFormValues';
import { IIntakeLineLayerUnitFormValues } from '../../../@types/model/intake/form/intakeLineLayerUnitFormValues';

interface IIntakeLineFormProps {
    palletBaseTypeOptions : Array<IOptionType>;
    packs : Array<IPack>;
    palletBaseTypes : Array<IPalletBaseType>;
}

interface IIntakeLineFormState {
    expanded : Array<number>;
}

type IntakeLineFormPropsType = IIntakeLineFormProps & FormikProps<IIntakeLineFormValues> & FieldArrayRenderProps;

class IntakeLineForm extends React.Component<IntakeLineFormPropsType, IIntakeLineFormState> {
    constructor(props : IntakeLineFormPropsType) {
        super(props);
        this.state = {
            expanded: [],
        };
    }

    private toggleExpanded = (key : number) => {
        if (this.state.expanded.some(x => x === key)) {
            const array = [...this.state.expanded.filter(x => x !== key)];
            this.setState({ expanded: array });
        } else {
            this.setState({ expanded: [...this.state.expanded, key] });
        }

    };

    private updateWeights = (lineIndex : number, newPalletBaseType ?: IOptionType) => {
        const lines : Array<IIntakeLineFormValues> = [...this.props.form.values.intakeLines];
        const intakeLine : IIntakeLineFormValues = lines[lineIndex];

        if (intakeLine.grossWeight > 0 && intakeLine.intakeLineLayers.length > 0) {
            // Run through all the layers and update their weights
            let totalEstimatedWeight = 0;

            if (intakeLine.palletBaseType) {
                let palletBase : IPalletBaseType | undefined;
                if (newPalletBaseType) {
                    palletBase = this.props.palletBaseTypes.find(x => x.id === newPalletBaseType?.value);
                } else {
                    palletBase = this.props.palletBaseTypes.find(x => x.id === intakeLine.palletBaseType?.value);
                }

                if (palletBase) {
                    intakeLine.nettWeight = Number((intakeLine.grossWeight - palletBase.defaultWeight).toFixed(3));
                }

                let totalUnits = 0;

                intakeLine.intakeLineLayers.filter((x : IIntakeLineLayerFormValues) => x.isActive).forEach((x : IIntakeLineLayerFormValues) => {
                    x.noOfUnits = x.intakeLineLayerUnits?.length;
                    if (x.noOfUnits != null && x.pack != null) {
                        const packType = this.props.packs.find(y => y.id === x.pack?.value);

                        if (packType) {
                            totalEstimatedWeight += packType.grossWeight * x.noOfUnits;
                        }
                    }

                    // Get total units to divide later
                    x.intakeLineLayerUnits?.filter((y : IIntakeLineLayerUnitFormValues) => y.isActive).forEach((y : IIntakeLineLayerUnitFormValues) => {
                        totalUnits += Number(y.noOfUnits);
                    });
                });

                // Loop again to calculate actual values.
                intakeLine.intakeLineLayers.filter((x : IIntakeLineLayerFormValues) => x.isActive).forEach((x : IIntakeLineLayerFormValues) => {
                    if (x.noOfUnits != null) {
                        if (x.pack != null && x.noOfUnits > 0 && totalEstimatedWeight > 0) {
                            const packType = this.props.packs.find(y => y.id === x.pack?.value);
                            let estimatedWeight = 0;

                            if (packType) {
                                estimatedWeight = packType.grossWeight * x.noOfUnits;

                                const ratio = estimatedWeight / totalEstimatedWeight;

                                x.grossWeight = ratio * intakeLine.nettWeight;
                                x.nettWeight = ratio * intakeLine.nettWeight - (packType.packagingWeight * x.noOfUnits);
                            }
                        }
                    }

                    if (x.intakeLineLayerUnits != null && x.intakeLineLayerUnits.length > 0) {
                        x.intakeLineLayerUnits.filter((y : IIntakeLineLayerUnitFormValues) => y.isActive).forEach((y : IIntakeLineLayerUnitFormValues) => {
                            y.grossWeight = (x.grossWeight) * (y.noOfUnits / totalUnits);
                            y.nettWeight = (x.nettWeight) * (y.noOfUnits / totalUnits);
                            y.unitGrossWeight = y.grossWeight / y.noOfUnits;
                            y.unitNettWeight = y.nettWeight / y.noOfUnits;
                        });
                    }
                });
            }
        }

        // TODO: Check with Etienne
        this.setState(() => {
            this.props.form.values.intakeLines = setArrayElement(lines, lineIndex, intakeLine);
        });
    };

    public addNewLine = (event : React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        this.props.push(new IntakeLineFormValues());
    };

    public removeLine = (event : React.MouseEvent<HTMLElement, MouseEvent>, index : number) => {
        event.stopPropagation();
        const intakeLines : Array<IIntakeLineFormValues> = { ...this.props.form.values.intakeLines };
        const selectedLine = intakeLines[index];
        const layers = selectedLine && selectedLine.intakeLineLayers;

        this.props.form.setFieldValue(`intakeLines.${index}.isActive`, false);
        layers.forEach((x, layerIndex) => {
            this.props.form.setFieldValue(`intakeLines.${index}.intakeLineLayers.${layerIndex}.isActive`, false);
            x.intakeLineLayerUnits.forEach((y, unitIndex) => {
                this.props.form.setFieldValue(`intakeLines.${index}.intakeLineLayers.${layerIndex}.intakeLineLayerUnits.${unitIndex}.isActive`, false);
            });
        });
    };

    public render() {
        const intakeLines = this.props.form.values.intakeLines ? this.props.form.values.intakeLines : [];
        const selectedIntake = this.props.form.values ? this.props.form.values : undefined;

        return (
            intakeLines.map((intakeLine : IIntakeLine, index : number) => {
                return (
                    <Accordion key={`${intakeLine.id}-${index}-intake-line-form`} className={'mb5'} expanded={this.state.expanded.some(x => x === index)}>
                        <AccordionSummary
                            expandIcon={<Icon className={'cw'}>expand_more</Icon>}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                            className='bb1 bocg1'
                            onClick={() => this.toggleExpanded(index)}
                        >
                            <div className='fdr wfill aic'>
                                <div className={this.state.expanded.some(x => x === index) ? 'pt10 pb10' : ''}>
                                    <PillButton
                                        className={'bcpd cw pl6'}
                                        size={'small'}
                                        fontSize={22}
                                        text={`${index + 1}`}
                                    />
                                </div>
                                <Typography className='aic flx1 pl10 pt5 pb5 fw500 fs18 cw'>PALLET {index + 1}</Typography>
                                <div className='fdr aic pr30'>
                                    <Typography className={'pr10 fw500 fs14 cw'}>NETT</Typography>
                                    <div className={'bw1 br5 fw500 fs14 cw'}>
                                        <div className={'pl10 pr50 pt5 pb5'}>
                                            {intakeLine.nettWeight} KG
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className='p0'>
                            <div className='fdc wfill'>
                                <div className='fdr aic pl10 pr10'>
                                    <FormTextInput
                                        name={`intakeLines.${index}.intakePalletRef`}
                                        label={'Pallet Reference'}
                                        className={'flx1 pt5'}
                                        disabled/>
                                    <FormAutocompleteSelect
                                        name={`intakeLines.${index}.palletBaseType`}
                                        label={'Pallet Base Type'}
                                        options={this.props.palletBaseTypeOptions}
                                        className={'flx1'}
                                        onChange={(newPalletBaseType : IOptionType) => this.updateWeights(index, newPalletBaseType)}
                                        disabled/>
                                    <FormTextInput
                                        name={`intakeLines.${index}.grossWeight`}
                                        label={'Gross Weight'}
                                        className={'flx1 pt5'}
                                        disabled/>
                                    <FormSingleToggleButton
                                        name={`intakeLines.${index}.isActive`}
                                        label={'Is Active'}
                                        disabled/>
                                </div>
                                <IntakeLineLayerForm
                                    intakeLineIndex={index}
                                    updateWeight={this.updateWeights}
                                    selectedIntake={selectedIntake}
                                />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                );
            })
        );
    }
}

const getPalletBaseTypes = (state : IRootState) => state.masterData.palletBaseTypes;

const getPalletBaseTypeOptions = createSelector(
    [getPalletBaseTypes],
    (palletBaseTypes : Array<IPalletBaseType>) => {
        return palletBaseTypes.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        palletBaseTypeOptions: getPalletBaseTypeOptions(state),
        palletBaseTypes: state.masterData.palletBaseTypes,
        packs: state.masterData.packs,
    };
};

export default connect(
    mapStateToProps,
)(IntakeLineForm);

import { IPack } from './pack';
import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { ICommodity } from '../commodity/commodity';
import { IPackCategory } from './packCategory';
import { ISize } from '../size/size';
import { IBillOfMaterials } from './billOfMaterials';

export interface IPackFormValues {
    id : number;
    guid : string;
    isActive : boolean;

    code : string;
    description : string;
    noCartons : number;
    nettWeight : number;
    packagingWeight : number;
    grossWeight : number;
    isIntakePack : boolean;
    hasInnerPack : boolean;
    noUnitsPerCarton ?: number;
    numberOfStackedUnits ?: number;
    unitNettWeight ?: number;
    unitPackagingWeight ?: number;
    unitGrossWeight ?: number;

    sizes : Array<IOptionType>;
    commodity ?: IOptionType;
    category ?: IOptionType;

    billOfMaterials : Array<IBillOfMaterials>;
}

export class PackFormValues implements IPackFormValues {
    public id : number;
    public guid : string;
    public isActive : boolean;

    public code : string;
    public description : string;
    public noCartons : number;
    public nettWeight : number;
    public packagingWeight : number;
    public grossWeight : number;
    public isIntakePack : boolean;
    public hasInnerPack : boolean;
    public noUnitsPerCarton ?: number;
    public numberOfStackedUnits ?: number;
    public unitNettWeight ?: number;
    public unitPackagingWeight ?: number;
    public unitGrossWeight ?: number;

    public sizes : Array<IOptionType>;
    public commodity ?: IOptionType;
    public category ?: IOptionType;

    public billOfMaterials : Array<IBillOfMaterials>;

    public constructor(
        pack ?: IPack,
        selectedCommodity ?: ICommodity,
        commodities ?: Array<ICommodity>,
        categories ?: Array<IPackCategory>,
        sizes ?: Array<ISize>,
    ) {
        this.initializeDefaultValues(selectedCommodity);
        if (pack) {
            this.id = pack.id;
            this.guid = pack.guid;
            this.code = pack.code;
            this.description = pack.description;
            this.noCartons = pack.noCartons;
            this.nettWeight = pack.nettWeight;
            this.packagingWeight = pack.packagingWeight;
            this.grossWeight = pack.grossWeight;
            this.isIntakePack = pack.isIntakePack;
            this.hasInnerPack = pack.hasInnerPack;
            this.unitGrossWeight = pack.unitGrossWeight;
            this.unitNettWeight = pack.unitNettWeight;
            this.unitPackagingWeight = pack.unitPackagingWeight;
            this.noUnitsPerCarton = pack.noUnitsPerCarton;
            this.numberOfStackedUnits = pack.numberOfStackedUnits;

            this.billOfMaterials = pack.billOfMaterials;

            this.sizes = sizes?.filter(x => pack.sizeIds?.some(y => y === x.id))?.map(x => ({
                label: x.name,
                value: x.id,
            })) ?? [];

            this.commodity = commodities ? {
                label: commodities.find(x => x.id === pack.commodityId)?.name ?? '',
                value: pack.commodityId,
            } : undefined;

            this.category = categories ? {
                label: categories.find(x => x.id === pack.categoryId)?.category ?? '',
                value: pack.categoryId,
            } : undefined;

            this.isActive = pack.isActive;
        }
    }

    public initializeDefaultValues = (selectedCommodity ?: ICommodity) => {
        this.id = 0;
        this.guid = '';
        this.isActive = true;

        this.code = '';
        this.description = '';
        this.noCartons = 0;
        this.nettWeight = 0;
        this.packagingWeight = 0;
        this.grossWeight = 0;
        this.isIntakePack = false;
        this.hasInnerPack = false;
        this.noUnitsPerCarton = 0;
        this.numberOfStackedUnits = undefined;
        this.unitNettWeight = 0;
        this.unitPackagingWeight = 0;
        this.unitGrossWeight = 0;

        this.sizes = [];
        this.commodity = undefined;
        this.category = undefined;

        this.billOfMaterials = [];

        if (selectedCommodity) {
            this.commodity = {
                label: selectedCommodity?.name ?? '',
                value: selectedCommodity?.id,
            };
        }
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        noCartons: Yup.number().min(0).required('Required'),
        packagingWeight: Yup.number().min(0).required('Required'),
        grossWeight: Yup.number().min(0).required('Required'),
        commodity: Yup.object().nullable().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

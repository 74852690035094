import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { IMarket } from '../market/market';
import { IAgreementCode } from './agreementCode';

export interface IAgreementCodeFormValues {
    id : number;
    guid : string;
    code : string;
    name : string;
    description ?: string;
    targetMarket ?: IOptionType;
    startDate ?: string;
    endDate ?: string;
    commodityIds : Array<number>;
    countryIds : Array<number>;
    isActive : boolean;
}

export class AgreementCodeFormValues implements IAgreementCodeFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public name : string;
    public description ?: string;
    public targetMarket ?: IOptionType;
    public startDate ?: string;
    public endDate ?: string;
    public commodityIds : Array<number>;
    public countryIds : Array<number>;
    public isActive : boolean;

    public constructor(
        agreementCode ?: IAgreementCode,
        markets ?: Array<IMarket>,
    ) {
        this.initializeDefaultValues();
        if (agreementCode) {
            this.id = agreementCode.id;
            this.guid = agreementCode.guid;
            this.code = agreementCode.code;
            this.name = agreementCode.name;
            this.description = agreementCode.description;
            this.startDate = agreementCode.startDate;
            this.endDate = agreementCode.endDate;
            this.commodityIds = agreementCode.commodityIds;
            this.countryIds = agreementCode.countryIds;

            this.targetMarket = markets
                ?
                ((agreementCode.targetMarketId)
                    ?
                    {
                        label: markets.find(x => x.id === agreementCode.targetMarketId)?.name ?? '',
                        value: agreementCode.targetMarketId,
                    }
                    : undefined)
                : undefined;

            this.isActive = agreementCode.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.name = '';
        this.description = '';
        this.targetMarket = undefined;
        this.startDate = undefined;
        this.endDate = undefined;
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        targetMarket: Yup.string().nullable(),
    });
}

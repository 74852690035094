import * as React from 'react';
import { FieldProps, Field } from 'formik';
import { Omit } from '@material-ui/types';
import SingleToggleButton, { ISingleToggleButtonProps } from '../SingleToggleButton';

type FormSingleToggleButtonProps = Omit<ISingleToggleButtonProps,
'value'
| 'onChange'> & {
    onChange ?: (selected : boolean, fieldProps : FieldProps) => void;
};

interface IFormSingleToggleButtonState {
}

export default class FormSingleToggleButton extends React.Component<FormSingleToggleButtonProps, IFormSingleToggleButtonState> {
    constructor(props : FormSingleToggleButtonProps) {
        super(props);
    }

    public onChange = (selected : boolean, fieldProps : FieldProps) => {
        fieldProps.form.setFieldValue(fieldProps.field.name, selected);
        if (this.props.onChange) this.props.onChange(selected, fieldProps);
    };

    public renderInput = (fieldProps : FieldProps) => {
        return (
            <SingleToggleButton
                {...this.props}
                onChange={v => this.onChange(v, fieldProps)}
                value={fieldProps.field.value}
            />
        );
    };

    public render() {
        return (<Field name={this.props.name} render={this.renderInput} />);
    }
}

import moment from 'moment';
import { DATEPICKER_FORMAT_DEFAULT } from '../../../appConstants';
import { IBaseModel } from '../../model';
import { ITemperatureUnit } from './temperatureUnit';
import { ITripFormValues } from './tripFormValues';

export interface ITrip extends IBaseModel {
    description ?: string;
    loadDate : string;
    driver ?: string;
    container ?: string;
    containerTareWeight ?: number;
    registrationNumber ?: string;
    sealNumber ?: string;
    carrierId ?: number;
    temperatureUnits : Array<ITemperatureUnit>;
    fleetNumber ?: string;
    truckTypeId ?: number;
    totalWeight ?: number;
    weighedDispatchIds ?: Array<number>;
    deleteReason ?: string;
}

export class Trip implements ITrip {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public description ?: string;
    public loadDate : string;
    public driver ?: string;
    public container ?: string;
    public containerTareWeight ?: number;
    public registrationNumber ?: string;
    public sealNumber ?: string;
    public carrierId ?: number;
    public temperatureUnits : Array<ITemperatureUnit>;
    public fleetNumber ?: string;
    public truckTypeId ?: number;
    public totalWeight ?: number;
    public weighedDispatchIds ?: Array<number>;
    public deleteReason ?: string;

    public constructor(formValues : ITripFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.description = formValues.description;
        this.loadDate = moment(formValues.loadDate).format(DATEPICKER_FORMAT_DEFAULT);
        this.driver = formValues.driver;
        this.container = formValues.container;
        this.containerTareWeight = formValues.containerTareWeight;
        this.sealNumber = formValues.sealNumber;
        this.registrationNumber = formValues.registrationNumber;
        this.carrierId = Number(formValues.carrier?.value) ?? undefined;
        this.temperatureUnits = formValues.temperatureUnits ?? [];
        this.truckTypeId = Number(formValues.truckType?.value) ?? undefined;
        this.fleetNumber = formValues.fleetNumber;
    }
}

import * as React from 'react';
import CustomTable from '../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetTruckTypes } from '../../../store/masterData/Actions';
import { formatDateTime, upsertArrayElement } from '../../../services/appFunctionsService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikActions } from 'formik';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { ITruckType, TruckType } from '../../../@types/model/masterData/truckType/truckType';
import { ITruckTypeFormValues, TruckTypeFormValues } from '../../../@types/model/masterData/truckType/truckTypeFormValues';
import TruckTypeHttpService from '../../../services/http/masterData/truckTypeHttpService';
import TruckTypeForm from './form/TruckTypeForm';
import { setTruckTypeMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface ITruckTypeScreenProps extends RouteComponentProps {
    dataSetTruckTypes : DispatchCall<Array<ITruckType>>;
    truckTypeData : Array<ITruckType>;
    auth : IAuthState;
}

interface ITruckTypeScreenState {
    rows : Array<ITruckType>;
    isLoading : boolean;
    selectedTruckType ?: ITruckType;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingTruckType ?: ITruckType;
}

class TruckTypeScreen extends React.Component<ITruckTypeScreenProps, ITruckTypeScreenState> {
    constructor(props : ITruckTypeScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedTruckType: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await TruckTypeHttpService.getTruckTypeData();

                this.props.dataSetTruckTypes(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading truck types.');
                this.setLoading(false);
            }
        }
    };

    public editTruckType = (truckType : ITruckType) => {
        this.setState({
            isDialogOpen: true,
            selectedTruckType: truckType,
        });
        this.openDialog();
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedTruckType: undefined,
        });
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public getSelectedTruckType = (props : ITruckTypeScreenProps, state : ITruckTypeScreenState) => state.selectedTruckType;

    public getInitialFormValues = createSelector(
        [this.getSelectedTruckType],
        (truckType : ITruckType) => {
            return new TruckTypeFormValues(truckType);
        },
    );

    public onReset = async (formValues : ITruckTypeFormValues, formikActions : FormikActions<ITruckTypeFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    public onSubmit = async (values : ITruckTypeFormValues) => {
        this.setLoading(true);

        try {
            const res = await TruckTypeHttpService.addOrUpdateTruckType(new TruckType(values));

            const newTruckTypeList = upsertArrayElement(this.props.truckTypeData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetTruckTypes(newTruckTypeList);
            await setTruckTypeMasterDataIndexedDB(newTruckTypeList);

            if (this.state.selectedTruckType) {
                generalShowSuccessSnackbar('Truck type updated successfully.');
            } else {
                generalShowSuccessSnackbar('Truck type added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating Truck type data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (truckType : ITruckType) => {
        this.setState({ isDeletePopupOpen: true, deletingTruckType: truckType });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingTruckType: undefined });
    };

    private removeTruckType = async () => {
        const newTruckType = this.state.deletingTruckType;
        if (newTruckType) {
            newTruckType.isActive = false;
            this.setLoading(true);

            try {
                const res = await TruckTypeHttpService.deleteTruckType(newTruckType.id);

                const newTruckTypeList = upsertArrayElement(this.props.truckTypeData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetTruckTypes(newTruckTypeList);
                await setTruckTypeMasterDataIndexedDB(newTruckTypeList);

                generalShowSuccessSnackbar('Truck type updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting Truck type.');
                newTruckType.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private getRights = (props : ITruckTypeScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : ITruckTypeScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.truckTypeData ? this.props.truckTypeData : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Truck Type'
                    maxWidth={'md'}
                    isEdit={!!this.state.selectedTruckType}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={TruckTypeFormValues.formSchema}
                        component={TruckTypeForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<ITruckType>
                            enableAdding={this.hasEditingRight(this.props)}
                            enableDeleting={(truckType : ITruckType) => truckType.isActive && this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editTruckType}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Description', field: 'description', enableFiltering: true, enableSorting: true },
                                { title: 'Default No. Pallets on Truck', field: 'defaultNumberOfPallets', enableFiltering: true, enableSorting: true },
                                { title: 'Max Paykoad (kg)', field: 'maxPayload', enableFiltering: true, enableSorting: true },
                                { title: 'Weight of Truck (kg)', field: 'weightOfTruck', enableFiltering: true, enableSorting: true },
                                { title: 'Tare Weight (kg)', field: 'tareWeight', enableFiltering: true, enableSorting: true },
                                { title: 'Percentage Warning', field: 'percentageWarning', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : ITruckType) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Truck Type'}
                    open={this.state.isDeletePopupOpen}
                    isLoading={this.state.isLoading}
                    message={'Are you sure you want to delete this truck type?'}
                    onOkClicked={this.removeTruckType}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        truckTypeData: state.masterData.truckTypes,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetTruckTypes }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TruckTypeScreen);

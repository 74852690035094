import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { IPrintServer } from '../printServer/printServer';
import { IPrinter } from './printer';

export interface IPrinterFormValues {
    id : number;
    guid : string;
    name : string;
    defaultPaper : string;
    apiAddress : string;
    printServer ?: IOptionType;
    isActive : boolean;
}

export class PrinterFormValues implements IPrinterFormValues {
    public id : number;
    public guid : string;
    public name : string;
    public defaultPaper : string;
    public apiAddress : string;
    public printServer ?: IOptionType;
    public isActive : boolean;

    public constructor(
        printer ?: IPrinter,
        printServers ?: Array<IPrintServer>,
    ) {
        this.initializeDefaultValues();
        if (printer) {
            this.id = printer.id;
            this.guid = printer.guid;
            this.name = printer.name;
            this.defaultPaper = printer.defaultPaper;
            this.apiAddress = printer.apiAddress;

            this.printServer = printServers ? {
                label: !!printServers.find(x => x.id === printer.printServerId) ? `${printServers.find(x => x.id === printer.printServerId)?.name}` : '',
                value: printer.printServerId,
            } : undefined;

            this.isActive = printer.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.name = '';
        this.defaultPaper = '';
        this.apiAddress = '';
        this.printServer = undefined;
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        printServer: Yup.object().nullable().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

import { IBaseModel } from '../../../model';
import { IPalletBaseTypeFormValues } from './palletBaseTypeFormValues';

export interface IPalletBaseType extends IBaseModel {
    code : string;
    name : string;
    defaultWeight : number;
}

export class PalletBaseType implements IPalletBaseType {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public name : string;
    public defaultWeight : number;

    public constructor(formValues : IPalletBaseTypeFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.code = formValues.code;
        this.name = formValues.name;
        this.defaultWeight = formValues.defaultWeight;
    }
}

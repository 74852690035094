import * as React from 'react';
import { FieldProps, Field } from 'formik';
import TextInput, { ITextInputProps } from '../TextInput';
import { CustomChangeEvent, CustomFocusEvent } from '../../../@types/helper';
import { getFormikErrorMessage, uppercase } from '../../../services/appFunctionsService';

type FormTextInputProps = Omit<ITextInputProps,
'value'
| 'onChange'
| 'onBlur'> & {
    forceUppercase ?: boolean;
    className ?: string;
    onChange ?: (e : CustomChangeEvent, fieldProps : FieldProps) => void;
    onBlur ?: (e : CustomFocusEvent, fieldProps : FieldProps) => void;
};

interface IFormTextInputState {
}

export default class FormTextInput extends React.Component<FormTextInputProps, IFormTextInputState> {
    constructor(props : FormTextInputProps) {
        super(props);
    }

    public onChange = (e : CustomChangeEvent, fieldProps : FieldProps) => {
        fieldProps.form.setFieldValue(fieldProps.field.name, this.props.forceUppercase ? uppercase(e.currentTarget.value) : e.currentTarget.value);
        if (this.props.onChange) this.props.onChange(e, fieldProps);
    };

    public onBlur = (e : CustomFocusEvent, fieldProps : FieldProps) => {
        fieldProps.field.onBlur(e);
        if (this.props.onBlur) this.props.onBlur(e, fieldProps);
    };

    public renderInput = (fieldProps : FieldProps) => {
        const error = getFormikErrorMessage(fieldProps.form.errors, fieldProps.field.name);

        return (
            <TextInput
                {...this.props}
                onChange={e => this.onChange(e, fieldProps)}
                onBlur={e => this.onBlur(e, fieldProps)}
                value={this.props.forceUppercase ? uppercase(fieldProps.field.value) : fieldProps.field.value}
                errorMargin
                error={error ?? ''}
            />
        );
    };

    public render() {
        return (<Field name={this.props.name} render={this.renderInput} />);
    }
}

import { ThemeOptions, createTheme } from '@mui/material/styles';
import { getSelectedThemeLocalStorage } from '../services/localStorageService';
import defaultTheme from './theme';

declare module '@mui/material/styles' {
    interface CustomTheme {
        custom : {
            randomColor : {
                alphaValue ?: number;
            };
            screen : {
                background ?: string;
            };
            navDrawer : {
                background ?: string;
            };
            form : {
                background ?: string;
            };
            packmanLink : {
                main ?: string;
                error ?: string;
                warning ?: string;
            };
            basicTransactionDateFilter : {
                background ?: string;
                days : {
                    selected : {
                        background ?: string;
                        text ?: string;
                    };
                };
            };
            commodityListItem : {
                selected : {
                    background ?: string;
                    text ?: string;
                };
                default : {
                    text ?: string;
                };
            };
            panel : {
                background ?: string;
                card : {
                    header ?: string;
                    hover ?: string;
                    background ?: string;
                };
            };
            table : {
                background ?: string;
                text ?: string;
                icon ?: string;
                row1 : {
                    default ?: string;
                    inactive ?: string;
                    warning ?: string;
                };
                row2 : {
                    default ?: string;
                    inactive ?: string;
                    warning ?: string;
                };
                footer : {
                    background ?: string;
                };
            };
            multiDispatchTable : {
                background ?: string;
                text ?: string;
                icon ?: string;
                row1 : {
                    default ?: string;
                    inactive ?: string;
                    warning ?: string;
                };
                row2 : {
                    default ?: string;
                    inactive ?: string;
                    warning ?: string;
                };
                footer : {
                    background ?: string;
                };
            };
        };
    }

    interface Theme extends CustomTheme {}
    interface ThemeOptions extends CustomTheme {}
}

declare module '@mui/material/Toolbar' {
    interface ToolbarPropsVariantOverrides {
        dialog : true;
        navbar : true;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        homeHeader : true;
        packmanLabel : true;
        stockFormHeader : true;
    }
}

export function getAppTheme() {
    const selectedThemeType = getSelectedThemeLocalStorage();

    let result : {
        palette : {
            mode : 'light' | 'dark';
            material : any;
        };
        constant : any;
        custom : any;
    };

    if (selectedThemeType === 'Default') {
        result = {
            palette: {
                material: { ...defaultTheme.colors.material },
                mode: 'light',
            },
            constant: defaultTheme.colors.constant,
            custom: defaultTheme.colors.light,
        };
    } else if (selectedThemeType === 'Dark') {
        result = {
            palette: {
                material: { ...defaultTheme.colors.material },
                mode: 'dark',
            },
            constant: defaultTheme.colors.constant,
            custom: defaultTheme.colors.dark,
        };
    } else {
        result = {
            palette: {
                material: { ...defaultTheme.colors.material },
                mode: 'light',
            },
            constant: defaultTheme.colors.constant,
            custom: defaultTheme.colors.light,
        };
    }

    return result;
};

export function getThemeMode() {
    const appTheme = getAppTheme();
    const mode : string = appTheme.palette.mode;

    return mode;
};

const theme : ThemeOptions = {
    palette: {
        mode: getAppTheme().palette.mode,
        primary: {
            main: getAppTheme().palette.material.primary.main,
            dark: getAppTheme().palette.material.primary.dark,
            light: getAppTheme().palette.material.primary.light,
            contrastText: getAppTheme().palette.material.primary.contrastText,
        },
        secondary: {
            main: getAppTheme().palette.material.secondary.main,
        },
    },
    typography: {
        fontFamily: [
            'Roboto',
        ].join(','),
    },
    components: {
        MuiToolbar: {
            variants: [{
                props: {
                    variant: 'dialog',
                },
                style: {
                    height: 48,
                    background: getAppTheme().custom.background.dialogAppBar,
                },
            },{
                props: {
                    variant: 'navbar',
                },
                style: {
                    background: getAppTheme().custom.background.dialogAppBar,
                },
            }],
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: getAppTheme().custom.checkbox.main,
                    '&.Mui-checked': {
                        color: getAppTheme().custom.checkbox.checked,
                    },
                    '&.Mui-disabled': {
                        color: getAppTheme().custom.checkbox.disabled,
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    background: getAppTheme().custom.background.dialog,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: getAppTheme().custom.background.dialog,
                },
            },
        },
        MuiIcon: {
            variants: [{
                props: {
                    id: 'booleanFlagTrue',
                },
                style: {
                    color: getAppTheme().custom.icon.booleanFlag.true,
                },
            },{
                props: {
                    id: 'booleanFlagFalse',
                },
                style: {
                    color: getAppTheme().custom.icon.booleanFlag.false,
                },
            },{
                props: {
                    id: 'toolbar',
                },
                style: {
                    color: getAppTheme().constant.white,
                },
            }],
            styleOverrides: {
                root: {
                    color: getAppTheme().custom.icon.main,
                },
            },
        },
        MuiTypography: {
            variants: [{
                props: {
                    variant: 'homeHeader',
                },
                style: {
                    color: getAppTheme().custom.typography.stockFormHeader.text,
                    fontSize: 18,
                    fontWeight: 500,
                },
            },{
                props: {
                    variant: 'packmanLabel',
                },
                style: {
                    color: getAppTheme().custom.typography.stockFormHeader.text,
                    fontSize: 14,
                    fontWeight: 500,
                },
            },{
                props: {
                    variant: 'stockFormHeader',
                },
                style: {
                    color: getAppTheme().custom.typography.stockFormHeader.text,
                    backgroundColor: getAppTheme().custom.typography.stockFormHeader.background,
                    fontSize: 18,
                    fontWeight: 500,
                },
            }],
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-focused': {
                        color: getAppTheme().custom.textfield.underline.focus,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: getAppTheme().custom.button.form,
                },
            },
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: getAppTheme().custom.circularProgress.color,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: getAppTheme().custom.background.dialog,
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    backgroundColor: getAppTheme().custom.accordion.summary.background,
                    '&.Mui-disabled': getAppTheme().constant.grey2,
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    backgroundColor: getAppTheme().custom.accordion.details.background,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                body: {
                    backgroundColor: getAppTheme().custom.table.cell.background + ' !important',
                },
            },
        },
    },
    custom: {
        randomColor: {
            alphaValue: getAppTheme().custom.randomColor.alphaValue,
        },
        screen: {
            background: getAppTheme().custom.background.main,
        },
        navDrawer: {
            background: getAppTheme().custom.background.sideDrawer,
        },
        form: {
            background: getAppTheme().custom.background.form,
        },
        packmanLink: {
            main: getAppTheme().custom.packmanLink.main,
            error: getAppTheme().custom.packmanLink.error,
            warning: getAppTheme().custom.packmanLink.warning,
        },
        basicTransactionDateFilter: {
            background: getAppTheme().custom.dateRangeFilter.background,
            days: {
                selected: {
                    background: getAppTheme().custom.dateRangeFilter.days.selected.background,
                    text: getAppTheme().custom.dateRangeFilter.days.selected.text,
                },
            },
        },
        commodityListItem: {
            selected: {
                background: getAppTheme().custom.commodityListItem.selected.background,
                text: getAppTheme().custom.commodityListItem.selected.text,
            },
            default: {
                text: getAppTheme().custom.commodityListItem.text,
            },
        },
        panel: {
            background: getAppTheme().custom.panel.background,
            card: {
                header: getAppTheme().custom.panel.card.header,
                hover: getAppTheme().custom.panel.card.backgroundHover,
                background: getAppTheme().custom.panel.card.background,
            },
        },
        table: {
            background: getAppTheme().custom.table.background,
            text: getAppTheme().custom.table.text,
            icon: getAppTheme().custom.table.icon,
            row1: {
                default: getAppTheme().custom.table.row1.default,
                inactive: getAppTheme().custom.table.row1.inactive,
                warning: getAppTheme().custom.table.row1.warning,
            },
            row2: {
                default: getAppTheme().custom.table.row2.default,
                inactive: getAppTheme().custom.table.row2.inactive,
                warning: getAppTheme().custom.table.row2.warning,
            },
            footer: {
                background: getAppTheme().custom.table.footer.background,
            },
        },
        multiDispatchTable: {
            background: getAppTheme().custom.table.background,
            text: getAppTheme().custom.table.text,
            icon: getAppTheme().custom.table.icon,
            row1: {
                default: getAppTheme().custom.table.row1.default,
                inactive: getAppTheme().custom.table.row1.inactive,
                warning: getAppTheme().custom.table.row1.warning,
            },
            row2: {
                default: getAppTheme().custom.table.row2.default,
                inactive: getAppTheme().custom.table.row2.inactive,
                warning: getAppTheme().custom.table.row2.warning,
            },
            footer: {
                background: getAppTheme().custom.table.footer.background,
            },
        },
    },
};

const materialTheme = createTheme(theme);

export default materialTheme;

import { IMarket } from './market';
import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { IRegion } from '../region/region';

export interface IMarketFormValues {
    id : number;
    guid : string;
    code : string;
    name : string;
    channel ?: IOptionType;
    region ?: IOptionType;
    isActive : boolean;
}

export class MarketFormValues implements IMarketFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public name : string;
    public channel ?: IOptionType;
    public region ?: IOptionType;
    public isActive : boolean;

    public constructor(
        market ?: IMarket,
        regions ?: Array<IRegion>,
        channels ?: Array<IOptionType>,
    ) {
        this.initializeDefaultValues();
        if (market) {
            this.id = market.id;
            this.guid = market.guid;
            this.code = market.code;
            this.name = market.name;
            this.channel = channels
                ? channels.find(x => x.label.charAt(0) === market.channel) ?? undefined
                : undefined;
            this.region = regions ? {
                label: regions.find(x => x.id === market.regionId)?.name ?? '',
                value: market.regionId,
            } : undefined;
            this.isActive = market.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.name = '';
        this.channel = undefined;
        this.region = undefined;
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        channel: Yup.object().nullable().required('Required'),
        region: Yup.object().nullable().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

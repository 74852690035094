import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IStock } from '../../../@types/model/stock/stock';
import { IStockRelatedData } from '../../../@types/model/stock/stockRelatedData';
import { IStockUpgrade } from '../../../@types/model/stock/stockUpgrade';
import qs from 'qs';
import { IStockViewRelatedData } from '../../../@types/model/stock/stockViewRelatedData';
import { IStockEditFormRelatedData } from '../../../@types/model/stock/StockEditFormRelatedData';
import { IStockSummaryRelatedData } from '../../../@types/model/stock/StockSummaryRelatedData';
import { IStockTomatoReportRelatedData } from '../../../@types/model/stock/stockTomatoReportRelatedData';
import { IStockView } from '../../../@types/model/stock/stockView';
import { IComplianceReplacementStockRelatedData } from '../../../@types/model/compliance/complianceReplacementStockRelatedData';
import { ICombineStockRelatedData } from '../../../@types/model/stock/combineStockRelatedData';
import { IStockAudit } from '../../../@types/model/stock/stockAudit';
import { ICombineStock } from '../../../@types/model/stock/combineStock';
import { IStockAppView } from '../../../@types/model/stock/stockAppView';

export default class StockHttpService {
    public static getStockData(siteIds ?: Array<number>) : AxiosPromise<Array<IStock>> {
        return axios.get(`${PACKMAN_API_URL}Stock/GetAllWeb`, {
            params: {
                siteIds,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getStockAppView(stockGuid : string) : AxiosPromise<IStockAppView> {
        return axios.get(`${PACKMAN_API_URL}Stock/GetAppStock`, {
            params: {
                stockGuid,
            },
        });
    }

    public static getCombineStockRelatedData(siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<ICombineStockRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Stock/GetCombineStockRelatedData`, {
            params: {
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getStockEditFormData(siteId : number, stockId ?: number, loadMasterData ?: boolean) : AxiosPromise<IStockEditFormRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Stock/StockFormDataById`, {
            params: {
                siteId,
                stockId,
                loadMasterData,
            },
        });
    }

    public static getStockById(stockId ?: number, onlyIncludeStockLines ?: boolean, onlyIncludeMaterialStockLines ?: boolean, onlyIncludeLines ?: boolean) : AxiosPromise<IStock> {
        return axios.get(`${PACKMAN_API_URL}Stock/StockById`, {
            params: {
                stockId,
                onlyIncludeStockLines,
                onlyIncludeMaterialStockLines,
                onlyIncludeLines,
            },
        });
    }

    public static getStockScreenData(fromDateUnix ?: number, toDateUnix ?: number, siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<IStockViewRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Stock/StockWebViewData`, {
            params: {
                fromDateUnix,
                toDateUnix,
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getStockTomatoReportRelatedData(fromDateUnix ?: number, toDateUnix ?: number, siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<IStockTomatoReportRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Stock/GetTomatoReportData`, {
            params: {
                fromDateUnix,
                toDateUnix,
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getStockTransactionData(fromDateUnix ?: number, toDateUnix ?: number, siteIds ?: Array<number>) : AxiosPromise<IStockRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Stock/WebTransactions`, {
            params: {
                fromDateUnix,
                toDateUnix,
                siteIds,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getReplacementStock(stockIds : Array<number>) : AxiosPromise<IComplianceReplacementStockRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Stock/GetMatchingReplacementStockDataAsync`, {
            params: {
                stockIds,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getDispatchLineReplacementStock(dispatchId : number) : AxiosPromise<Array<IStock>> {
        return axios.get(`${PACKMAN_API_URL}Stock/GetMatchingDispatchLineStock`, {
            params: {
                dispatchId,
            },
        });
    }

    public static getDispatchStocks(dispatchId : number) : AxiosPromise<Array<IStock>> {
        return axios.get(`${PACKMAN_API_URL}Stock/GetDispatchStocks`, {
            params: {
                dispatchId,
            },
        });
    }

    public static getStocks(stockIds : Array<number>) : AxiosPromise<Array<IStock>> {
        return axios.post(`${PACKMAN_API_URL}Stock/GetStockData`, stockIds);
    }

    public static addOrUpdateStock(data : IStock) : AxiosPromise<IStock> {
        return axios.post(`${PACKMAN_API_URL}Stock/PostStock`, data);
    }

    public static mergeStocks(data : ICombineStock) : AxiosPromise<Array<IStockView>> {
        return axios.post(`${PACKMAN_API_URL}Stock/MergeStocks`, data);
    }

    public static combineStockIntoNewStock(data : ICombineStock) : AxiosPromise<Array<IStockView>> {
        return axios.post(`${PACKMAN_API_URL}Stock/CombineStocksIntoNewStock`, data);
    }

    public static upgradeStock(data : IStockUpgrade) : AxiosPromise<IStock> {
        return axios.post(`${PACKMAN_API_URL}Stock/UpgradeStockWeb`, data);
    }

    public static breakDownStock(stockView : IStockView) : AxiosPromise<IStockView> {
        return axios.post(`${PACKMAN_API_URL}Stock/BreakDown`, stockView);
    }

    public static breakDownStockIntoLots(stockView : IStockView) : AxiosPromise<IStockView> {
        return axios.post(`${PACKMAN_API_URL}Stock/BreakDownIntoLots`, stockView);
    }

    public static breakDownMultipleStocksIntoLots(stockIds : Array<number>) : AxiosPromise<Array<IStockView>> {
        return axios.post(`${PACKMAN_API_URL}Stock/BreakDownMultipleStocksIntoLots`, stockIds);
    }

    public static sendStocksToStorageUnit(stockIds : Array<number>, newStorageUnitId : number) : AxiosPromise<Array<IStock>> {
        return axios.post(`${PACKMAN_API_URL}Stock/SendStocksToStorageUnit`, {
            stockIds,
            newStorageUnitId,
        });
    }

    public static revertStockBreakDown(stockView : IStockView) : AxiosPromise<IStockView> {
        return axios.post(`${PACKMAN_API_URL}Stock/RevertBreakDown`, stockView);
    }

    public static stockDelete = (stockId : number, deleteReason : string) : AxiosPromise<IStock> => {
        return axios.delete(`${PACKMAN_API_URL}Stock/Delete`, {
            params : { stockId, deleteReason },
        });
    };

    public static getStockSummaryData(stockId ?: number, loadMasterData ?: boolean) : AxiosPromise<IStockSummaryRelatedData> {
        return axios.get(`${PACKMAN_API_URL}StockLine/StockSummaryRelatedData`, {
            params: {
                stockId,
                loadMasterData,
            },
        });
    }

    public static getStockAudits(stockId ?: number) : AxiosPromise<Array<IStockAudit>> {
        return axios.get(`${PACKMAN_API_URL}Stock/GetStockAudits`, {
            params: {
                stockId,
            },
        });
    }
}

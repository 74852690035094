import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { navHome } from '../store/nav/Actions';
import { generalShowSuccessSnackbar } from '../store/general/Functions';
import { Card, Toolbar, TextField, FormControl, FormHelperText, CircularProgress } from '@mui/material';
import NavBar from './root/NavBar';
import { useLocation } from 'react-router-dom';
import { resetPassword } from '../services/right/userService';
import { setLocalStorageSession } from '../services/localStorageService';

const PasswordRecovery = () => {

    const [isLoading, setLoading] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const location = useLocation();
    const search = location?.search;

    const recoveryCode = search?.split('recoveryCode=')[1]?.split('&')[0];
    const email = search?.split('email=')[1];

    const submitPasswordRecoveryPasswordChange = async () => {
        setLoading(true);
        await resetPassword(recoveryCode, password, email);
        setPassword('');
        setConfirmPassword('');
        await setLocalStorageSession(null);
        navHome();
        generalShowSuccessSnackbar('Your password has been reset successfully');
        setLoading(false);
    };

    const onPasswordChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => setPassword(e.target.value);
    const onConfirmPasswordChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => setConfirmPassword(e.target.value);

    return <div className={'flx1 fdc posr oh'}>
        <div className={'posa op30p hfill wfill bcpd'}
            style={{ backgroundImage: `url(${ASSET_BASE}/assets/images/background_new-min.jpg)`,
                backgroundSize: 'cover', backgroundPosition: '50% 50%' }}/>
        <div className={'bcpd posa wfill hfill op70p'}/>
        <NavBar/>
        <div className={'flx1 fdc aic jcc'}>
            {isLoading &&
                <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                    <div className={'posr aic jcc h50 w50'}>
                        <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                            <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                        </div>
                        <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                    </div>
                </div>
            }
            {
                <div className={'flx1 fdr aic jcc oppNone'}>
                    <Card className={'w500 fdc jcc zi2'}>
                        <Toolbar className={'bcpd h50'}>
                            <Typography variant='h6' className='flx1 cw jcc'>
                                Password Recovery
                            </Typography>
                        </Toolbar>
                        <div className={'pl20 pr20 pt10 pb10 pb10'}>
                            <FormControl className={'wfill'}>
                                <div className='fdc wfill'>
                                    <div className='fdc wfill'>
                                        <TextField
                                            className='wfill'
                                            required
                                            error={!password}
                                            autoFocus
                                            margin='dense'
                                            id='password'
                                            label='Password'
                                            type='password'
                                            value={password}
                                            onChange={onPasswordChange}
                                        />
                                        {
                                            !password &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </div>
                                    <div className='fdc wfill'>
                                        <TextField
                                            className='wfill'
                                            required
                                            error={!confirmPassword}
                                            margin='dense'
                                            id='confirmPassword'
                                            label='Confirm Password'
                                            type='password'
                                            value={confirmPassword}
                                            onChange={onConfirmPasswordChange}
                                        />
                                        {
                                            !confirmPassword &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                        {
                                            (password !== confirmPassword) &&
                                            <FormHelperText error>Passwords don't match</FormHelperText>
                                        }
                                    </div>
                                </div>
                            </FormControl>
                            <div className={'fdr p5 pt20'}>
                                <Button
                                    className={'flx1 br25'}
                                    variant='contained'
                                    color='secondary'
                                    onClick={submitPasswordRecoveryPasswordChange}
                                    disabled={!password || isLoading || (password !== confirmPassword)}
                                >
                                    CHANGE PASSWORD
                                </Button>
                            </div>
                        </div>
                    </Card>
                </div>
            }
        </div>
    </div>;

};

export default PasswordRecovery;

import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { InspectionPointFormValues } from '../../../../@types/model/masterData/inspectionPoint/inspectionPointFormValues';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';

interface IInspectionPointFormProps {
    isLoading : boolean;
}

interface IInspectionPointFormState {
}

type InspectionPointFormPropsType = IInspectionPointFormProps & FormikProps<InspectionPointFormValues>;

export default class InspectionPointForm extends React.Component<InspectionPointFormPropsType, IInspectionPointFormState> {

    constructor(props : InspectionPointFormPropsType) {
        super(props);
    }

    public render() {
        return (
            <Form className={'p20'}>
                <FormTextInput name={'code'} label={'Code'}/>
                <FormTextInput name={'name'} label={'Name'}/>
                <FormTextInput name={'bookingRefNr'} label={'Booking Ref Nr'}/>
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState, RootAction } from '../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IStock } from '../../@types/model/stock/stock';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ISite } from '../../@types/model/masterData/site/site';
import { IMarket } from '../../@types/model/masterData/market/market';
import { IMark } from '../../@types/model/masterData/mark/mark';
import { IRegion } from '../../@types/model/masterData/region/region';
import { ICountry } from '../../@types/model/masterData/country/country';
import { CustomChangeEvent, IOptionType } from '../../@types/helper';
import { createSelector } from 'reselect';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { uppercase } from '../../services/appFunctionsService';
import { IStockUpgrade } from '../../@types/model/stock/stockUpgrade';
import StockHttpService from '../../services/http/stock/stockHttpService';
import { dataSetStockView } from '../../store/data/Functions';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import { Button, TextField } from '@mui/material';
import PillButton from '../../components/input/PillButton';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import { IMaterialStock } from '../../@types/model/materialStock/materialStock';
import AllocateMaterialsToStockDialog from './AllocateMaterialsToStockDialog';
import { IAllocateMaterials } from '../../@types/model/stock/allocateMaterials';
import { IStockView } from '../../@types/model/stock/stockView';
import { IInventory } from '../../@types/model/masterData/inventory/inventory';

interface IStockUpgradeDialogProps {
    setLoading : (loading : boolean) => void;
    refreshData : () => void;
    closeStockUpgradeDialog : () => void;
    isLoading : boolean;
    stockToUpgrade : IStockView;
    organizations : Array<IOrganization>;
    sites : Array<ISite>;
    stockData : Array<IStock>;
    markets : Array<IMarket>;
    marks : Array<IMark>;
    regions : Array<IRegion>;
    countries : Array<ICountry>;
    inventories : Array<IInventory>;
    materialStockData : Array<IMaterialStock>;
}

interface IStockUpgradeDialogState {
    selectedStockToUpgrade ?: IStock;
    selectedUpgradeStockOrganization ?: IOptionType;
    selectedUpgradeStockSite ?: IOptionType;
    selectedUpgradeStockBrand ?: IOptionType;
    selectedUpgradeStockTargetMarket ?: IOptionType;
    selectedUpgradeStockTargetCountry ?: IOptionType;
    selectedUpgradeStockInventory ?: IOptionType;
    upgradeStockGrossWeight : number;

    showAllocateMaterialToStockDialog : boolean;
    allocatedMaterialsToStock : Array<IAllocateMaterials>;
}

class StockUpgradeDialog extends React.Component<IStockUpgradeDialogProps, IStockUpgradeDialogState> {
    constructor(props : IStockUpgradeDialogProps) {
        super(props);

        this.state = {
            upgradeStockGrossWeight: 0,
            showAllocateMaterialToStockDialog: false,
            allocatedMaterialsToStock: [],
        };
    }

    public componentDidMount = async () => {
        if (this.props.stockToUpgrade) {
            this.props.setLoading(true);
            try {
                const res = await StockHttpService.getStockById(this.props.stockToUpgrade.id, false, true);

                if (res && res.data) {
                    const selectedStockToUpgrade = { ...res.data };
                    const allocatedMaterialsToStock : Array<IAllocateMaterials> = res.data.materialStockLines.map((x) => {
                        return {
                            materialStockId: x.materialStockId,
                            stockId: x.stockId,
                            tempStockUnitId: x.tempStockUnitId,
                            amount: x.amount,
                        };
                    });
                    this.setState({ selectedStockToUpgrade, allocatedMaterialsToStock }, () => {
                        const stockCurrentOrg = this.props.organizations.find(x => x.id === this.state.selectedStockToUpgrade?.currentOrganizationId);
                        const stockCurrentSite = this.props.sites.find(x => x.id === this.state.selectedStockToUpgrade?.currentSiteId);
                        const stockBrand = this.props.marks.find(x => x.id === this.state.selectedStockToUpgrade?.markId);
                        const stockTargetMarket = this.props.markets.find(x => x.id === this.state.selectedStockToUpgrade?.marketId);
                        const stockTargetCountry = this.props.countries.find(x => x.id === this.state.selectedStockToUpgrade?.countryId);
                        const stockInventory = this.props.inventories.find(x => x.id === this.state.selectedStockToUpgrade?.inventoryId);

                        const orgOption = { label: `(${stockCurrentOrg?.code}) - ${stockCurrentOrg?.name}`, value: stockCurrentOrg?.id ?? 0 };
                        const siteOption = { label: `(${stockCurrentSite?.code}) - ${stockCurrentSite?.shortDescription}`, value: stockCurrentSite?.id ?? 0 };
                        const brandOption = stockBrand ? { label: `(${stockBrand?.code}) - ${stockBrand?.description}`, value: stockBrand?.id ?? 0 } : undefined;
                        const inventoryOption = stockInventory ? { label: `(${stockInventory?.code}) - ${stockInventory?.description}`, value: stockInventory?.id ?? 0 } : undefined;
                        const targetMarketOption = stockTargetMarket ? { label: `(${stockTargetMarket?.code}) - ${stockTargetMarket?.name}`, value: stockTargetMarket?.id ?? 0 } : undefined;
                        const targetCountryOption = stockTargetCountry ? { label: `(${stockTargetCountry?.code}) - ${stockTargetCountry?.name}`, value: stockTargetCountry?.id ?? 0 } : undefined;

                        this.setState({ selectedUpgradeStockOrganization: orgOption,
                            selectedUpgradeStockSite: siteOption,
                            selectedUpgradeStockBrand: brandOption,
                            selectedUpgradeStockInventory: inventoryOption,
                            selectedUpgradeStockTargetMarket: targetMarketOption,
                            selectedUpgradeStockTargetCountry: targetCountryOption,
                            upgradeStockGrossWeight: this.props.stockToUpgrade.grossWeight,
                        });
                    });
                    this.props.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar('Failed to load allocateded material data');
                this.props.setLoading(false);
            }
        }
    };

    private getOrganizations = (props : IStockUpgradeDialogProps) => props.organizations;
    private getSites = (props : IStockUpgradeDialogProps) => props.sites;
    private getBrands = (props : IStockUpgradeDialogProps) => props.marks;
    private getMarkets = (props : IStockUpgradeDialogProps) => props.markets;
    private getCountries = (props : IStockUpgradeDialogProps) => props.countries;
    private getInventories = (props : IStockUpgradeDialogProps) => props.inventories;

    private getSelectedUpgradeStockOrganization = (props : IStockUpgradeDialogProps, state : IStockUpgradeDialogState) => state.selectedUpgradeStockOrganization;
    private getSelectedUpgradeStockTargetMarket = (props : IStockUpgradeDialogProps, state : IStockUpgradeDialogState) => state.selectedUpgradeStockTargetMarket;
    private getSelectedUpgradeStockTargetCountry = (props : IStockUpgradeDialogProps, state : IStockUpgradeDialogState) => state.selectedUpgradeStockTargetCountry;

    private getCurrentOrganizationOptions = createSelector(
        [this.getOrganizations],
        (organizations : Array<IOrganization>) => {
            if (!organizations) return [];

            return organizations.filter(x => x.isActive).map((x) => {
                return { label: `(${x.code}) - ${x.name}`, value: x.id };
            });
        },
    );

    private getCurrentSiteOptions = createSelector(
        [this.getSites, this.getSelectedUpgradeStockOrganization],
        (sites : Array<ISite>, selectedUpgradeStockOrganization : IOptionType) => {
            if (!sites) return [];

            return sites.filter(x => x.isActive && x.organizationIds.some(y => y === selectedUpgradeStockOrganization?.value)).map((x) => {
                return { label: `(${x.code}) - ${x.shortDescription}`, value: x.id };
            });
        },
    );

    private getBrandOptions = createSelector(
        [this.getBrands],
        (marks : Array<IMark>) => {
            if (!marks) return [];

            return marks.filter(x => x.isActive).map((x) => {
                return { label: `(${x.code}) - ${x.description}`, value: x.id };
            });
        },
    );

    private getTargetMarketOptions = createSelector(
        [this.getMarkets, this.getCountries],
        (markets : Array<IMarket>, countries : Array<ICountry>) => {
            if (!markets) return [];

            return markets.filter(x => (countries.findIndex(y => y.regionId === x.regionId) !== -1) && x.isActive).map((x) => {
                return { label: `(${x.code}) - ${x.name}`, value: x.id };
            });
        },
    );

    private getTargetCountryOptions = createSelector(
        [this.getCountries, this.getSelectedUpgradeStockTargetMarket],
        (countries : Array<ICountry>, selectedUpgradeStockTargetMarket : IOptionType) => {
            if (!countries) return [];

            const selectedRegion = selectedUpgradeStockTargetMarket && this.getRegionId(Number(selectedUpgradeStockTargetMarket?.value));

            return countries.filter(x => x.isActive && x.regionId === Number(selectedRegion)).map((x) => {
                return { label: `(${x.code}) - ${x.name}`, value: x.id };
            });
        },
    );

    private getInventoryOptions = createSelector(
        [this.getInventories],
        (inventories : Array<IInventory>) => {
            return inventories.filter(x => x.isActive).map((x) => {
                return { label: `(${x.code}) - ${x.description}`, value: x.id };
            });
        },
    );

    private getSelectedTargetCountry = createSelector(
        [this.getSelectedUpgradeStockTargetCountry],
        (selectedUpgradeStockTargetCountry : IOptionType) => {

            return selectedUpgradeStockTargetCountry;
        },
    );

    private onSelectedUpgradeStockOrganizationChange = (e : React.ChangeEvent<{}>, selectedOrganization : IOptionType) => {
        this.setState({ selectedUpgradeStockOrganization: selectedOrganization });
    };

    private onSelectedUpgradeStockSiteChange = (e : React.ChangeEvent<{}>, selectedSite : IOptionType) => {
        this.setState({ selectedUpgradeStockSite: selectedSite });
    };

    private onSelectedUpgradeStockBrandChange = (e : React.ChangeEvent<{}>, selectedBrand : IOptionType) => {
        this.setState({ selectedUpgradeStockBrand: selectedBrand });
    };

    private onSelectedUpgradeStockInventoryChange = (e : React.ChangeEvent<{}>, selectedInventory : IOptionType) => {
        this.setState({ selectedUpgradeStockInventory: selectedInventory });
    };

    private onSelectedUpgradeStockTargetMarketChange = (e : React.ChangeEvent<{}>, selectedTargetMarket : IOptionType) => {
        if (selectedTargetMarket !== null) {
            const marketId = selectedTargetMarket ? selectedTargetMarket.value : 0;
            const regionId = this.getRegionId(Number(marketId));
            if (this.isLocalPallet(Number(marketId)) && regionId) {
                const country = this.getLocalCountry(regionId);
                const countryOption = { label: `(${country?.code}) - ${country?.name}`, value: country?.id ?? 0 };
                this.setState({ selectedUpgradeStockTargetMarket: selectedTargetMarket, selectedUpgradeStockTargetCountry: countryOption });
            } else {
                this.setState({ selectedUpgradeStockTargetMarket: selectedTargetMarket });
            }
        } else {
            this.setState({ selectedUpgradeStockTargetMarket: selectedTargetMarket, selectedUpgradeStockTargetCountry: undefined });
        }
    };

    private getRegionId = (marketId : number) => {
        if (!this.props.markets) {
            return undefined;
        }
        const market = this.props.markets.find(x => x.id === marketId);
        return market && market.regionId;
    };

    private isLocalPallet = (marketId ?: number) => {
        if (!this.props.markets) {
            return false;
        }
        const mId = marketId ?? 0;
        const market = this.props.markets.find(x => x.id === mId);
        return market && market.channel !== 'E' ? true : false;
    };

    private getLocalCountry = (regionId : number) => {
        if (!this.props.countries) {
            return;
        }
        const country = this.props.countries.find(x => x.regionId === regionId && uppercase(x.name) === 'SOUTH AFRICA');
        return country;
    };

    private onSelectedUpgradeStockTargetCountryChange = (e : React.ChangeEvent<{}>, selectedTargetCountry : IOptionType) => {
        this.setState({ selectedUpgradeStockTargetCountry: selectedTargetCountry });
    };

    private onUpgradeStockGrossWeightChange = (event : CustomChangeEvent) => {
        this.setState({ upgradeStockGrossWeight: Number(event.currentTarget.value) });
    };

    private submitStockUpgrade = async () => {
        if (this.state.selectedStockToUpgrade) {

            if (!this.state.selectedUpgradeStockOrganization) {
                generalShowErrorSnackbar('Current Organization is required!');
                return;
            }

            if (!this.state.selectedUpgradeStockSite) {
                generalShowErrorSnackbar('Current Site is required!');
                return;
            }

            if (!this.state.selectedUpgradeStockTargetMarket) {
                generalShowErrorSnackbar('Target Market is required!');
                return;
            }

            if (!this.state.selectedUpgradeStockTargetCountry) {
                generalShowErrorSnackbar('Target country is required!');
                return;
            }

            if (!this.state.selectedUpgradeStockInventory) {
                generalShowErrorSnackbar('Inventory is required!');
                return;
            }

            const selectedCurrentSite = this.props.sites.find(x => x.id === this.state.selectedUpgradeStockSite?.value);

            if (selectedCurrentSite && selectedCurrentSite.isBRCCompliant && this.state.allocatedMaterialsToStock.length < 1) {
                generalShowErrorSnackbar('Materials is required. Please allocate materials to this pallet.');
                return;
            }

            try {
                this.props.setLoading(true);
                const stockToUpgrade : IStockUpgrade = {
                    stockId: this.state.selectedStockToUpgrade?.id,
                    organizationId: Number(this.state.selectedUpgradeStockOrganization?.value ?? this.state.selectedStockToUpgrade?.currentOrganizationId),
                    siteId: Number(this.state.selectedUpgradeStockSite?.value ?? this.state.selectedStockToUpgrade?.currentSiteId),
                    brandId: Number(this.state.selectedUpgradeStockBrand?.value ?? this.state.selectedStockToUpgrade?.markId),
                    markId: Number(this.state.selectedUpgradeStockBrand?.value ?? this.state.selectedStockToUpgrade?.markId),
                    inventoryId: Number(this.state.selectedUpgradeStockInventory?.value ?? this.state.selectedStockToUpgrade?.inventoryId),
                    targetMarketId: Number(this.state.selectedUpgradeStockTargetMarket?.value ?? this.state.selectedStockToUpgrade?.marketId),
                    targetCountryId: Number(this.state.selectedUpgradeStockTargetCountry?.value ?? this.state.selectedStockToUpgrade?.countryId),
                    grossWeight: this.state.upgradeStockGrossWeight,
                    materials: this.state.allocatedMaterialsToStock,
                };

                const res = await StockHttpService.upgradeStock(stockToUpgrade);

                if (res) {
                    const markDescription = this.props.marks.find(x => x.id === stockToUpgrade.markId)?.description;
                    const marketName = this.props.markets.find(x => x.id === stockToUpgrade.brandId)?.name;
                    const countryName = this.props.countries.find(x => x.id === stockToUpgrade.targetCountryId)?.name;
                    const inventoryCode = this.props.inventories.find(x => x.id === stockToUpgrade.inventoryId)?.code;

                    const newStockView : IStockView = {
                        ...this.props.stockToUpgrade,
                        currentOrganizationId: stockToUpgrade.organizationId,
                        currentSiteId: stockToUpgrade.siteId,
                        markDescription: markDescription ?? '',
                        marketName: marketName ?? '',
                        countryName: countryName ?? '',
                        inventoryCode: inventoryCode ?? '',
                        grossWeight: this.state.upgradeStockGrossWeight,
                    };

                    dataSetStockView(newStockView);
                    this.props.refreshData();
                    generalShowSuccessSnackbar('Stock Upgrade Successfully!');
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while upgrading stock!');
            } finally {
                this.props.setLoading(false);
                this.props.closeStockUpgradeDialog();
            }
        }
    };

    private openMaterialAllocationToStockDialog = () => {
        this.setState({ showAllocateMaterialToStockDialog: true });
    };

    private closeMaterialAllocationToStockDialog = () => {
        this.setState({ showAllocateMaterialToStockDialog: false });
    };

    private getAllocatedMaterialStockToStock = (props : IStockUpgradeDialogProps, state : IStockUpgradeDialogState) => state.allocatedMaterialsToStock;

    private getAddMaterialsButton = createSelector(
        [this.getAllocatedMaterialStockToStock],
        (allocatedMaterialsToStock : Array<IAllocateMaterials>) => {
            if (allocatedMaterialsToStock.length < 1) {
                return (
                    <Button
                        className={'fwb h35'}
                        variant='text'
                        color='primary'
                        onClick={this.openMaterialAllocationToStockDialog}>
                        Add Materials
                    </Button>
                );
            } else {
                return (
                    <PillButton
                        text={'Update Materials'}
                        className={'ml15 pl20 pr20 h35 cpd'}
                        onClick={this.openMaterialAllocationToStockDialog}
                        color={'secondary'}
                        size={'small'}
                        disabled={this.props.isLoading}
                    />
                );
            }
        },
    );

    private onAllocateMaterialsToStockSubmit = (allocatedMaterials : Array<IAllocateMaterials>) => {
        if (allocatedMaterials.length >= 1) {
            this.setState({ allocatedMaterialsToStock: allocatedMaterials, showAllocateMaterialToStockDialog: false });
        }
    };

    public render() {
        return (
            <div className={'w600 aic fdc p20'}>
                <div className={'fdr aic wfill'}>
                    <AutocompleteSelect
                        name={'currentOrganization'}
                        label={'Current Organization'}
                        options={this.getCurrentOrganizationOptions(this.props)}
                        value={this.state.selectedUpgradeStockOrganization}
                        onChange={this.onSelectedUpgradeStockOrganizationChange}
                        className={'flx1'}
                    />
                    <div className={'w10'}/>
                    <AutocompleteSelect
                        name={'currentSite'}
                        label={'Current Site'}
                        options={this.getCurrentSiteOptions(this.props, this.state)}
                        value={this.state.selectedUpgradeStockSite}
                        onChange={this.onSelectedUpgradeStockSiteChange}
                        className={'flx1'}
                    />
                </div>
                <div className={'fdr aic wfill'}>
                    <AutocompleteSelect
                        name={'targetMarket'}
                        label={'Target Market'}
                        options={this.getTargetMarketOptions(this.props)}
                        value={this.state.selectedUpgradeStockTargetMarket}
                        onChange={this.onSelectedUpgradeStockTargetMarketChange}
                        className={'flx1'}
                    />
                    <div className={'w10'}/>
                    <AutocompleteSelect
                        name={'targetCountry'}
                        label={'Target Country'}
                        options={this.getTargetCountryOptions(this.props, this.state)}
                        value={this.getSelectedTargetCountry(this.props, this.state)}
                        onChange={this.onSelectedUpgradeStockTargetCountryChange}
                        className={'flx1'}
                    />
                </div>
                <div className={'fdr aic wfill'}>
                    <AutocompleteSelect
                        name={'brand'}
                        label={'Brand'}
                        options={this.getBrandOptions(this.props)}
                        value={this.state.selectedUpgradeStockBrand}
                        onChange={this.onSelectedUpgradeStockBrandChange}
                        className={'flx1'}
                    />
                    <div className={'w10'}/>
                    <TextField
                        className={'flx1'}
                        value={this.state.upgradeStockGrossWeight}
                        label={'Gross Weight (kg)'}
                        type={'number'}
                        onChange={this.onUpgradeStockGrossWeightChange}
                    />
                </div>
                <div className={'fdr aic wfill'}>
                    <AutocompleteSelect
                        name={'inventory'}
                        label={'Inventory'}
                        options={this.getInventoryOptions(this.props)}
                        value={this.state.selectedUpgradeStockInventory}
                        onChange={this.onSelectedUpgradeStockInventoryChange}
                        className={'flx1'}
                    />
                    <div className={'w10'}/>
                    <div className={'flx1'}/>
                </div>
                <div className={'fdr pt10 pb10 wfill jcfe'}>
                    {this.getAddMaterialsButton(this.props, this.state)}
                    <Button
                        className={'fwb h35'}
                        variant='text'
                        color='primary'
                        onClick={this.props.closeStockUpgradeDialog}>
                        Cancel
                    </Button>
                    <PillButton
                        disabled={this.props.isLoading
                            || !this.state.selectedStockToUpgrade
                            || !this.state.selectedUpgradeStockOrganization
                            || !this.state.selectedUpgradeStockSite
                            || !this.state.selectedUpgradeStockTargetMarket
                            || !this.state.selectedUpgradeStockInventory
                            || (this.state.selectedUpgradeStockTargetMarket && !this.state.selectedUpgradeStockTargetCountry)}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Submit'}
                        color={'secondary'}
                        onClick={this.submitStockUpgrade}
                    />
                </div>
                {this.state.selectedStockToUpgrade &&
                    <PackmanDialog
                        title={'Allocate Material To Stock'}
                        isInfo={true}
                        isLoading={this.props.isLoading}
                        isOpen={this.state.showAllocateMaterialToStockDialog}
                        onClose={this.closeMaterialAllocationToStockDialog}>
                        {
                            <AllocateMaterialsToStockDialog
                                isLoading={this.props.isLoading}
                                selectedStock={this.state.selectedStockToUpgrade}
                                materialsAlreadyOnStock={this.state.allocatedMaterialsToStock}
                                submit={allocatedMaterials => this.onAllocateMaterialsToStockSubmit(allocatedMaterials)}
                                closeMaterialAllocationToStockDialog={this.closeMaterialAllocationToStockDialog}/>
                        }
                    </PackmanDialog >
                }
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        stockData: state.data.stocks,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
        markets: state.masterData.markets,
        marks: state.masterData.marks,
        regions: state.masterData.regions,
        countries: state.masterData.countries,
        inventories: state.masterData.inventories,
        materialStockData: state.data.materialStocks,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StockUpgradeDialog);

import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { RegionFormValues } from '../../../../@types/model/masterData/region/regionFormValues';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import FormAutocompleteSelect from '../../../../components/input/form/FormAutoCompleteSelect';
import { createSelector } from 'reselect';
import { IOrganization } from '../../../../@types/model/masterData/organization/organization';
import { IOptionType } from '../../../../@types/helper';

interface IRegionFormProps {
    isLoading : boolean;
    organizationOptions : Array<IOptionType>;
}

interface IRegionFormState {
}

type RegionFormPropsType = IRegionFormProps & FormikProps<RegionFormValues>;

class RegionForm extends React.Component<RegionFormPropsType, IRegionFormState> {

    constructor(props : RegionFormPropsType) {
        super(props);
    }

    public render() {
        return (
            <Form className={'p20 w300'}>
                <FormTextInput name={'code'} label={'Code'}/>
                <FormTextInput name={'name'} label={'Name'}/>
                <FormAutocompleteSelect
                    name={'organizations'}
                    label={'Organizations'}
                    options={this.props.organizationOptions}
                    isMulti
                />
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const getOrganizations = (state : IRootState) => state.masterData.organizations;

const getOrganizationOptions = createSelector(
    [getOrganizations],
    (organizations : Array<IOrganization>) => {
        return organizations.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        organizationOptions: getOrganizationOptions(state),
    };
};

export default connect(
    mapStateToProps,
)(RegionForm);

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IUnitOfMeasure } from '../../../@types/model/masterData/unitOfMeasure/unitOfMeasure';

export default class UnitOfMeasureHttpService {
    public static getUnitOfMeasureData() : AxiosPromise<Array<IUnitOfMeasure>> {
        return axios.get(`${PACKMAN_API_URL}UnitOfMeasure/GetAllWeb`);
    }

    public static addOrUpdateUnitOfMeasure(data : IUnitOfMeasure) : AxiosPromise<IUnitOfMeasure> {
        return axios.post(`${PACKMAN_API_URL}UnitOfMeasure/PostUnitOfMeasure`, data);
    }

    public static deleteUnitOfMeasure(unitOfMeasureId : number) : AxiosPromise<IUnitOfMeasure> {
        return axios.delete(`${PACKMAN_API_URL}UnitOfMeasure/Delete`, {
            params : { unitOfMeasureId },
        });
    }
}

import * as React from 'react';
import { FieldProps, Form, FormikProps } from 'formik';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IOptionType, CustomChangeEvent } from '../../../../@types/helper';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import PillButton from '../../../../components/input/PillButton';
import { IRootState } from '../../../../@types/redux';
import FormAutocompleteSelect from '../../../../components/input/form/FormAutoCompleteSelect';
import { ICarrier } from '../../../../@types/model/masterData/carrier/carrier';
import { TripFormValues } from '../../../../@types/model/dispatch/tripFormValues';
import { addArrayElement, setArrayElement } from '../../../../services/appFunctionsService';
import Typography from '@mui/material/Typography';
import FormDateInput from '../../../../components/input/form/FormDateInput';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Icon, IconButton, Tooltip } from '@mui/material';
import CustomTable from '../../../../components/datagrid/CustomTable';
import { ITemperatureUnit } from '../../../../@types/model/dispatch/temperatureUnit';
import materialTheme from '../../../../styles/materialTheme';
import PackmanDialog from '../../../../components/dialog/PackmanDialog';
import { IStock } from '../../../../@types/model/stock/stock';
import { IDispatchInstruction } from '../../../../@types/model/dispatch/dispatchInstruction';
import CustomAutoSuggest from '../../../../components/input/CustomAutoSuggest';
import validationService from '../../../../services/validationService';
import CustomTextInput from '../../../../components/input/CustomTextInput';
import SingleToggleButton from '../../../../components/input/SingleToggleButton';
import { ITruckType } from '../../../../@types/model/masterData/truckType/truckType';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RouteComponentProps } from 'react-router';
import { sum, uniq } from 'lodash';
import CustomTooltip from '../../../../components/tooltip/tooltip';

interface ITripFormProps extends RouteComponentProps {
    carrierOptions : Array<IOptionType>;
    stocks : Array<IStock>;
    dispatchInstructions : Array<IDispatchInstruction>;
    truckTypeOptions : Array<IOptionType>;
    isEditingTrip : boolean;
    dispatchInstructionStocks : Array<IStock>;
}

interface ITripFormState {
    isAdding : boolean;
    editingTemperatureUnit ?: ITemperatureUnit;
    selectedStockId ?: number;
    deviceNumber ?: string;
    editingTemperatureUnitIsActive : boolean;
    isDevicesAccordionExpanded : boolean;
    isContainerPopupOpen : boolean;
    isDispatchCodesPopupOpen : boolean;
}

type TripFormPropsType = ITripFormProps & FormikProps<TripFormValues> | undefined;

class TripForm extends React.Component<TripFormPropsType, ITripFormState> {

    constructor(props : TripFormPropsType) {
        super(props);

        this.state = {
            isAdding: false,
            editingTemperatureUnitIsActive: true,
            isDevicesAccordionExpanded: false,
            isContainerPopupOpen: false,
            isDispatchCodesPopupOpen: false,
        };
    }

    public componentDidUpdate = (prevProps : TripFormPropsType) => {
        const nextProps = this.props;
        if (prevProps && nextProps && !!nextProps.values && !!nextProps.values.container && nextProps.values.container !== '' && !nextProps.values.sealNumber && nextProps.values.sealNumber === '') {
            nextProps.setFieldError('sealNumber', 'Required');
            nextProps.setFieldError('containerTareWeight', 'Required');
        }
    };

    private getFormValues = (props : TripFormPropsType) => props?.values;
    private getDispatches = (props : ITripFormProps) => props?.dispatchInstructions;
    private getStocks = (props : ITripFormProps) => props?.stocks;
    private getSelectedTrip = (props : TripFormPropsType) => props?.values;
    private getIsEditingTrip = (props : TripFormPropsType) => props?.isEditingTrip;
    private getDispatchInstructionStocks = (props : TripFormPropsType) => props?.dispatchInstructionStocks;
    private getEditingTemperatureUnit = (props : ITripFormProps, state : ITripFormState) => state.editingTemperatureUnit;
    private getTemperatureUnits = createSelector([this.getFormValues], values => values?.temperatureUnits ?? []);

    private getStockBarcode = (stockId : number) => this.props.stocks.find(x => x.id === stockId)?.barcode;

    private getTemperatureUnitColumns = () => [
        { title: 'Barcode', field: 'stockId', formatFunction: this.getStockBarcode },
        { title: 'Device Number', field: 'deviceNumber' },
    ];

    private onAddTemperatureUnit = () => this.setState({ isAdding: true });

    private onEditTemperatureUnit = (editingTemperatureUnit : ITemperatureUnit) => this.setState({
        editingTemperatureUnit,
        selectedStockId: editingTemperatureUnit.stockId,
        deviceNumber: editingTemperatureUnit.deviceNumber,
        editingTemperatureUnitIsActive: editingTemperatureUnit.isActive,
    });

    private onDeleteTemperatureUnit = (temperatureUnit : ITemperatureUnit) => {
        let temperatureUnits = [...this.getTemperatureUnits(this.props)];
        const index = temperatureUnits.findIndex(x => (x.deviceNumber === temperatureUnit.deviceNumber) && (x.stockId === temperatureUnit.stockId));
        const newTempUnit = { ...temperatureUnit };
        newTempUnit.isActive = false;
        temperatureUnits = setArrayElement(temperatureUnits, index, newTempUnit);
        this.props.setFieldValue('temperatureUnits', temperatureUnits);
    };

    private getStockOptions = createSelector(
        [this.getStocks, this.getDispatches, this.getSelectedTrip, this.getTemperatureUnits, this.getEditingTemperatureUnit, this.getIsEditingTrip, this.getDispatchInstructionStocks],
        (stocks, dispatches, trip, temperatureUnits, editing, isEditingTrip, dispatchInstructionStocks) => {

            if (isEditingTrip && dispatchInstructionStocks && dispatchInstructionStocks.length > 0) {
                return dispatchInstructionStocks.filter(stock => stock.isActive
                    && ((stock.id === editing?.stockId) || !temperatureUnits.filter(x => x.isActive).some(unit => unit.stockId === stock.id))).map(x => ({ label: x.barcode, value: x.id }));
            } else {
                return stocks.filter(stock => stock.isActive
                    && ((stock.id === editing?.stockId) || !temperatureUnits.filter(x => x.isActive).some(unit => unit.stockId === stock.id))
                    && dispatches.some(dispatch => dispatch.isActive && !!dispatch.tripId
                        && (dispatch.tripId === trip?.id)
                        && dispatch.dispatchLines.some(line => line.isActive && line.currentStockId === stock.id))).map(x => ({ label: x.barcode, value: x.id }));
            }
        },
    );

    private onDialogClose = () => this.setState({ isAdding: false, editingTemperatureUnit: undefined });

    private onTemperatureUnitSubmit = () => {
        const { deviceNumber, selectedStockId, editingTemperatureUnit, editingTemperatureUnitIsActive } = this.state;

        if (selectedStockId && deviceNumber && this.props.values) {
            let temperatureUnits = [...this.getTemperatureUnits(this.props)];
            let temperatureUnit : ITemperatureUnit;
            if (!!editingTemperatureUnit) {
                temperatureUnit = { ...editingTemperatureUnit };
                temperatureUnit.stockId = selectedStockId;
                temperatureUnit.deviceNumber = deviceNumber;
                temperatureUnit.isActive = editingTemperatureUnitIsActive;
                temperatureUnit.tripId = this.props.values.id;
                const index = temperatureUnits.findIndex(x => (x.deviceNumber === editingTemperatureUnit?.deviceNumber) && (x.stockId === editingTemperatureUnit?.stockId));
                temperatureUnits = setArrayElement(temperatureUnits, index, temperatureUnit);
            } else {
                temperatureUnit = {
                    id: 0,
                    stockId: selectedStockId,
                    deviceNumber,
                    tripId: this.props.values.id,
                    guid: '',
                    isActive: true,
                };
                temperatureUnits = addArrayElement(temperatureUnits, temperatureUnit, 'end');
            }
            this.props.setFieldValue('temperatureUnits', temperatureUnits);
            this.onDialogClose();
        }
    };

    private toggleEditingIsActive = () => {
        this.setState({ editingTemperatureUnitIsActive: !this.state.editingTemperatureUnitIsActive });
    };

    private isStockActive = (tempUnit : ITemperatureUnit) => {
        const stock = this.props.stocks.find(x => x.id === tempUnit.stockId);
        let exist : boolean = true;

        if (stock) {
            if (stock?.isActive) {
                exist = true;
            } else {
                exist = false;
            }
        }

        return exist;
    };

    private toggleExpand = () => {
        this.setState({ isDevicesAccordionExpanded: !this.state.isDevicesAccordionExpanded });
    };

    private openContainerNumberInfoPopup = () => {
        this.setState({ isContainerPopupOpen: true });
    };

    private closeContainerNumberInfoPopup = () => {
        this.setState({ isContainerPopupOpen: false });
    };

    private openDispatchCodesPopup = () => {
        this.setState({ isDispatchCodesPopupOpen: true });
    };

    private closeDispatchCodesPopup = () => {
        this.setState({ isDispatchCodesPopupOpen: false });
    };

    private isTripLinkedToDispatch = (tripId : number) => {
        const dispatches = this.props.dispatchInstructions.filter(x => x.isActive && x.tripId === tripId);

        if (dispatches.length > 0) {
            return false;
        } else {
            return true;
        }
    };

    private getDispatchesLinkedToTrip = (tripId : number) => {
        const dispatches = this.props.dispatchInstructions.filter(x => x.isActive && x.tripId === tripId);

        return dispatches;
    };

    private onTareWeightChange = (e : CustomChangeEvent, fieldProps : FieldProps<TripFormValues>) => {
        const tareWeight = Number(e.target.value);
        const stockIds : Array<number> = [];
        this.props.dispatchInstructions.filter(x => x.isActive).forEach(x => x.dispatchLines.filter(y => y.isActive).forEach(y => stockIds.push(y.currentStockId)));
        const stockWeight = sum(this.props.stocks.filter(x => x.isActive && uniq(stockIds).some(y => y === x.id)).map(x => x.grossWeight));
        fieldProps.form.setFieldValue('containerGrossWeight', tareWeight ? (tareWeight + stockWeight) : '');
    };

    public render() {
        const selectedTrip = this.props.values;
        return (
            <Form className={'p20 w500'}>
                <div className={'fdc'}>
                    <div className={'fdr flx1'}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <FormDateInput name={'loadDate'} label={'Load Date'} variant={'standard'} className={'pt8 mt0 flx1'}/>
                        </LocalizationProvider>
                        <div className={'w10'}/>
                        <FormTextInput name={'description'} label={'Description'} className={'flx1'}/>
                    </div>
                    <div className={'fdr flx1'}>
                        <FormTextInput name={'driver'} label={'Driver'} className={'flx1'}/>
                        <div className={'w10'}/>
                        <FormTextInput name={'registrationNumber'} label={'Registration Number'} className={'flx1'}/>
                    </div>
                    <div className={'fdr flx1'}>
                        <FormAutocompleteSelect
                            name={'carrier'}
                            label={'Carrier'}
                            className={'flx1'}
                            options={this.props.carrierOptions}
                        />
                        <div className={'w10'}/>
                        <FormAutocompleteSelect
                            name={'truckType'}
                            label={'Truck Type'}
                            className={'flx1'}
                            options={this.props.truckTypeOptions}
                        />
                    </div>
                    <div className={'fdr flx1'}>
                        <div className='fdr flx1 pr20'>
                            <FormTextInput name={'container'} label={'Container'} className={'pr10'}/>
                            <CustomTooltip title={'Container Number Info'}>
                                <IconButton className={'mt15 h30 w30'} onClick={this.openContainerNumberInfoPopup}>
                                    <Icon fontSize={'small'}>info</Icon>
                                </IconButton>
                            </CustomTooltip>
                        </div>
                        <div className={'w10'}/>
                        <FormTextInput name={'sealNumber'} label={'Seal Number'} className={'flx1'}/>
                    </div>
                    <div className={'fdr flx1'}>
                        <FormTextInput name={'containerTareWeight'} label={'Container Tare Weight'} className={'flx1'} onChange={this.onTareWeightChange}/>
                        <div className={'w10'}/>
                        <FormTextInput name={'containerGrossWeight'} label={'Container Gross Weight'} className={'flx1'} disabled/>
                    </div>
                    <div className={'fdr flx1'}>
                        <div className={'flx1'}>
                            <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                        </div>
                        <div className={'w10'}/>
                        <FormTextInput name={'fleetNumber'} label={'Fleet Number'} className={'flx1'}/>
                    </div>
                </div>
                <div className={'fdr jcsb'}>
                    <PillButton
                        className={!this.isTripLinkedToDispatch(selectedTrip.id) ? 'h35' : 'dn'}
                        text={'Linked Dispatches'}
                        onClick={this.openDispatchCodesPopup}
                        color={'secondary'}
                    />
                </div>

                {(!!this.props.values?.id || !!this.props.isEditingTrip) &&
                    <>
                        <Tooltip disableInteractive title={!this.isTripLinkedToDispatch(selectedTrip.id) ? '' : 'No dispatches linked to trip'}>
                            <div>
                                <Accordion
                                    key={'trip_devices'}
                                    className={'fdc wfill mb10'}
                                    expanded={!!this.state.isDevicesAccordionExpanded}
                                    disabled={selectedTrip.id > 0 ? this.isTripLinkedToDispatch(selectedTrip.id) : false}
                                    onChange={this.toggleExpand}
                                >
                                    <AccordionSummary className={'aic jcc bt1 cgray3 bb1'} expandIcon={<ExpandMoreIcon className={'cw'}/>}>
                                        <Typography>{'Devices'}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={'fdc hfill wfill'}>
                                        <div className={'fdc'}>
                                            <CustomTable<ITemperatureUnit>
                                                enableAdding={true}
                                                addFunction={this.onAddTemperatureUnit}
                                                editFunction={this.onEditTemperatureUnit}
                                                enableEditing={(row : ITemperatureUnit) => !this.getTemperatureUnits(this.props).filter(x => x.isActive && x.id !== row.id).some(x => x.stockId === row.stockId)}
                                                fitWidthToPage
                                                enableDeleting={(row : ITemperatureUnit) => row.isActive}
                                                deleteColor={materialTheme.palette.primary.main}
                                                deleteFunction={this.onDeleteTemperatureUnit}
                                                columns={this.getTemperatureUnitColumns()}
                                                rows={this.getTemperatureUnits(this.props)}
                                                initialSortOrder={[{ columnName: 'stockId_Barcode', direction : 'desc' }]}
                                                isActive={(row : ITemperatureUnit) => row.isActive && this.isStockActive(row)}
                                            />
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </Tooltip>
                        {(this.state.isAdding || !!this.state.editingTemperatureUnit) &&
                            <PackmanDialog
                                maxWidth={'md'}
                                isOpen={this.state.isAdding || !!this.state.editingTemperatureUnit}
                                title={'Temperature Unit'}
                                isEdit={!!this.state.editingTemperatureUnit}
                                onClose={this.onDialogClose}
                            >
                                <div className={'fdr p10'}>
                                    <CustomAutoSuggest
                                        className={'flx1'}
                                        options={this.getStockOptions(this.props, this.state)}
                                        label={'Stock'}
                                        initialValue={this.state.editingTemperatureUnit?.stockId}
                                        hasInitialValue={!!this.state.editingTemperatureUnit?.stockId}
                                        value={this.state.editingTemperatureUnit?.stockId}
                                        validate={validationService.required}
                                        validateOnBlur
                                        validateOnChange
                                        field={'stockbarcode'}
                                        onChange={(e : any) => this.setState({ selectedStockId: e })}
                                        enableDropDownIcon
                                    />
                                    <div className={'w20'} />
                                    <CustomTextInput
                                        className={'flx1'}
                                        field={'deviceNumber'}
                                        label={'Device Number'}
                                        initialValue={this.state.editingTemperatureUnit?.deviceNumber ?? ''}
                                        onChange={(e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => this.setState({ deviceNumber: e.target.value })}
                                        validate={validationService.required}
                                        validateOnBlur
                                        validateOnChange
                                    />
                                </div>
                                <div className={'fdr'}>
                                    <SingleToggleButton
                                        className={'w200'}
                                        label={'Is Active'}
                                        onChange={this.toggleEditingIsActive}
                                        name='isActive'
                                        value={this.state.editingTemperatureUnitIsActive}
                                    />
                                    <div className={'w20'} />
                                    <div className={'flx1'} />
                                </div>
                                <div className={'fdr ml10 ais jcfe pt10 pb10 pr10'}>
                                    <Button
                                        className={'fwb h35'}
                                        variant='text' color='primary'
                                        type={'reset'}>
                                        Clear
                                    </Button>
                                    <PillButton
                                        disabled={!this.state.selectedStockId || !this.state.deviceNumber}
                                        className={'ml15 pl20 pr20 h35'}
                                        text={'Save'}
                                        onClick={this.onTemperatureUnitSubmit}
                                        color={'secondary'}
                                    />
                                </div>

                            </PackmanDialog>
                        }
                    </>
                }
                {!!this.state.isContainerPopupOpen &&
                    <PackmanDialog
                        maxWidth={'md'}
                        isOpen={this.state.isContainerPopupOpen}
                        title={'Container Number Info'}
                        isInfo
                        onClose={this.closeContainerNumberInfoPopup}
                    >
                        <div className={'fdc p20'}>
                            <img className={'hi wi mb10'} src={`${ASSET_BASE}/assets/images/container-id-number.png`} />
                            <div className='fdr'>
                                <Typography>{'For more info click: '}</Typography>
                                <div className={'w10'}/>
                                <a className='pt2' href={'https://www.bic-code.org/identification-number/'}>https://www.bic-code.org/identification-number/</a>
                            </div>
                        </div>
                    </PackmanDialog>
                }
                {!!this.state.isDispatchCodesPopupOpen &&
                    <PackmanDialog
                        maxWidth={'md'}
                        isOpen={this.state.isDispatchCodesPopupOpen}
                        title={'Linked Dispatch Codes'}
                        isInfo
                        onClose={this.closeDispatchCodesPopup}
                    >
                        <div className={'fdc p10 oya h300'}>
                            {
                                this.getDispatchesLinkedToTrip(selectedTrip.id).map((dispatch) => {
                                    return (
                                        <div className={'fdc'}>
                                            <div className={'fdr jcc fw600'}>{dispatch.dispatchCode}</div>
                                            <Divider className={'wfill mt5 mb5'} />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </PackmanDialog>
                }
                <div className={'fdr ml10 ais jcfe pt20 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isSubmitting}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const getCarriers = (state : IRootState) => state.masterData.carriers ?? [];

const getTruckTypeData = (state : IRootState) => state.masterData.truckTypes ?? [];

const getDispatchInstructions = (state : IRootState) => state.data.dispatchInstructions ?? [];

const getStocks = (state : IRootState) => state.data.stocks ?? [];

const getCarrierOptions = createSelector(
    [getCarriers],
    (carriers : Array<ICarrier>) => {
        return carriers.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }));
    },
);

const getTruckTypeOptions = createSelector(
    [getTruckTypeData],
    (truckTypes : Array<ITruckType>) => {
        return truckTypes.filter(x => x.isActive).map(x => ({ label: `(${x.code}) ${x.description}`, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        carrierOptions: getCarrierOptions(state),
        stocks: getStocks(state),
        dispatchInstructions: getDispatchInstructions(state),
        truckTypeOptions: getTruckTypeOptions(state),
        isEditingTrip: state.data.isEditingTrip,
        dispatchInstructionStocks: state.data.dispatchInstructionStocks,
    };
};

export default connect(
    mapStateToProps,
)(TripForm);

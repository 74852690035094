import axios, { AxiosPromise } from 'axios';
import { IDispatchInstruction } from '../../../../@types/model/dispatch/dispatchInstruction';
import { IPOFileRelatedData } from '../../../../@types/model/integration/fileImport/poFileRelatedData';
import { IUpsertImportedPOFileDispatch } from '../../../../@types/model/integration/poFile/upsertImportedPOFileDispatch';
import { PACKMAN_API_URL } from '../../../../appConstants';
import qs from 'qs';
import { IExistingImportedPOFileData } from '../../../../@types/model/integration/poFile/existingImportedPOFileData';

export default class FileImportHttpService {
    public static getPOFileRelatedData() : AxiosPromise<IPOFileRelatedData> {
        return axios.get(`${PACKMAN_API_URL}POFile/GetPOFileRelatedData`);
    }

    public static getExistingImportedFileData(stockBarcodes : Array<string>) : AxiosPromise<IExistingImportedPOFileData> {
        return axios.get(`${PACKMAN_API_URL}POFile/GetExistingImportedFileData`, {
            params: {
                stockBarcodes,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static upsertImportedPOFileData(poFileData : Array<IUpsertImportedPOFileDispatch>) : AxiosPromise<IDispatchInstruction> {
        return axios.post(`${PACKMAN_API_URL}POFile/UpsertImportedPOFileData`, poFileData);
    }
}

import * as React from 'react';
import lodash from 'lodash';
import { Table, TableBody, TableHead, TableCell, TableFooter, TableSortLabel, TablePagination, TableRow, IconButton, Icon, Tooltip } from '@mui/material';
import { removeObjectAttribute, compareString, isEmptyObject, setArrayElement, addArrayElement, removeArrayElement } from '../../services/appFunctionsService';
import { TablePaginationActionsWrapped } from './TablePaginationActions';
import './customTableStyles.scss';
import FloatingActionButton from '../input/FloatingActionButton';
import CustomTableFilter from './CustomTableFilter';
import CustomTableMultiCheckboxFilter from './CustomTableMultiCheckboxFilter';
import { createSelector } from 'reselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { DATETIME_PALTRACK_FILE } from '../../appConstants';
import CustomTooltip from '../tooltip/tooltip';
import materialTheme from '../../styles/materialTheme';

interface ICustomTableProps<T> {
    id ?: string;
    rows : Array<T>;
    columns : Array<ICustomTableColumn>;
    enableFiltering ?: boolean;
    enableSorting ?: boolean;
    enableAdding ?: boolean;
    enableEditing ?: ((row : T) => boolean | undefined) | boolean;
    enableDeleting ?: ((row : T) => boolean | undefined) | boolean;
    enableDetails ?: ((row : T) => boolean | undefined) | boolean;
    enableRefresh ?: boolean;
    enableCSVExport ?: boolean;
    csvExportPathname ?: string;
    enableClearFilterButton ?: boolean;
    disableRefreshButton ?: boolean;
    enableDetailAccordion ?: ((row : T) => boolean | undefined) | boolean;
    disableDeleteButton ?: ((row : T) => boolean | undefined) | boolean;
    disableDetailButton ?: ((row : T) => boolean | undefined) | boolean;
    disableEditButton ?: ((row : T) => boolean | undefined) | boolean;
    disableAddButton ?: boolean;
    isActive ?: ((row : T) => boolean | undefined) | boolean;
    warning ?: ((row : T) => boolean | undefined) | boolean;
    rowColor ?: ((row : T) => string | undefined) | string;
    detailIcon ?: string | React.ReactElement<any>;
    detailsColor ?: string;
    deleteIcon ?: string | React.ReactElement<any>;
    deleteColor ?: string;
    editIcon ?: string | React.ReactElement<any>;
    addIcon ?: string | React.ReactElement<any>;
    editColor ?: string;
    detailTooltip ?: string;
    deleteTooltip ?: ((row : T) => string | undefined) | string;
    editTooltip ?: ((row : T) => string | undefined) | string;
    addTooltip ?: ((row : T) => string | undefined) | string;
    addFunction ?: () => void;
    editFunction ?: (row : T) => void;
    deleteFunction ?: (row : T) => void;
    detailFunction ?: (row : T) => void;
    refreshFunction ?: () => void;
    detailAccordionComponent ?: (row : T) => React.ReactElement<any>;
    initialSortOrder ?: Array<{columnName : string; direction : 'asc' | 'desc'}>;
    enablePagination ?: boolean;
    enableTotalRow ?: boolean;
    pageSizes ?: Array<number>;
    fitWidthToPage ?: boolean;
    hide ?: boolean;
    pageHeight ?: number;
    filterType ?: 'Textfield' | 'MultiCheckbox';
    filterTypeColumn ?: Array<{ column : string; data : Array<string> }>;

    filteredRows ?: (rows : Array<T>) => void;
}

interface ICustomTableState {
    rowsPerPage ?: number;
    currentPage : number;
    detailAccordionExpanded : Array<boolean>;
    filterState : {[column : string] : Array<{filterValue ?: string; showEmpty ?: boolean; showFilled ?: boolean}>};
    sortState : Array<{columnName : string; direction ?: 'asc' | 'desc'}>;
    rowState : {[page : number] : Array<any>};
    freezeUpTo ?: number;
    columnPositions : Array<number>;
    openFilterBoxes : Array<string>;
}

export interface ICustomTableColumn {
    title : string;
    field : string;
    type ?: string; // not used here, just for datagrid use
    width ?: number;
    minWidth ?: number;
    // maxWidth ?: number;
    titleContainerComponent ?: (value : any) => React.ReactElement<any> | undefined;
    containerComponent ?: (row : any, value : any, index ?: number) => React.ReactElement<any> | undefined;
    formatFunction ?: (value : any, row ?: any) => any;
    redFormatFunction ?: (value : any, row ?: any) => any;
    sortFunction ?: (value : any, value2 : any) => any;
    calculateTotal ?: boolean;
    calculateRedTotal ?: boolean;
    isNumberString ?: boolean;
    style ?: (value : any) => React.CSSProperties | undefined;
    freezeUpTo ?: boolean;
    enableFiltering ?: boolean;
    enableSorting ?: boolean;
}

const defaultColumnWidth = 150;

export default class CustomTable<T extends { [key : string] : any }> extends React.Component<ICustomTableProps<T>, ICustomTableState> {
    public constructor(props : ICustomTableProps<T>) {
        super(props);

        this.state = {
            rowsPerPage: this.props.pageSizes && this.props.pageSizes.length > 0 ? { ...this.props.pageSizes }[0] : undefined,
            currentPage: 0,
            filterState: {},
            sortState: this.props.initialSortOrder ? [...this.props.initialSortOrder] : [] ,
            rowState: {},
            detailAccordionExpanded: [],
            columnPositions: [],
            openFilterBoxes: [],
        };
    }

    public componentDidUpdate(prevProps : ICustomTableProps<T>) {
        const nextProps = this.props;
        if (nextProps.columns !== prevProps.columns && nextProps.columns.some(x => !!x.freezeUpTo)) {
            let freezeUpTo = 0;
            nextProps.columns.forEach((x, i) => {
                if (x.freezeUpTo) {
                    freezeUpTo = i;
                }
            });
            this.setState({ freezeUpTo });
        }
    }

    private setOpenFilterBoxes = (open : boolean, columnFilterTitle : string) => {
        const index = this.state.openFilterBoxes.findIndex(x => x === columnFilterTitle);
        if (open && index === -1) {
            this.setState(prevState => ({ openFilterBoxes: addArrayElement(prevState.openFilterBoxes, columnFilterTitle, 'end') }));
        } else if (!open && index !== -1) {
            this.setState(prevState => ({ openFilterBoxes: removeArrayElement(prevState.openFilterBoxes, index) }));
        }
    };

    private setRowsPerPage = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => this.setState({ rowsPerPage: Number(e.target.value), currentPage: 0 });

    private changePage = (e : React.MouseEvent<HTMLButtonElement> | null, page : number) => this.setState({ currentPage : page });

    private setFilters = (columnField : string, fieldValue ?: string, showEmpty ?: boolean, showFilled ?: boolean) => {
        let currentFilters = { ...this.state.filterState };
        if ((!fieldValue && !showEmpty && !showFilled) && !!currentFilters[columnField]) {
            currentFilters = removeObjectAttribute(currentFilters, columnField);
        } else if (fieldValue) {
            const filterValue : { filterValue ?: string; showEmpty ?: boolean; showFilled ?: boolean } = {
                filterValue: fieldValue,
                showEmpty,
                showFilled,
            };
            currentFilters[columnField] = [filterValue];
        } else if (showEmpty || showFilled) {
            const filterValue : { filterValue ?: string; showEmpty ?: boolean; showFilled ?: boolean } = {
                filterValue: fieldValue,
                showEmpty,
                showFilled,
            };
            currentFilters[columnField] = [filterValue];
        } else {
            return;
        }
        this.setState({ filterState: currentFilters, currentPage: 0 });
    };

    private setMultiCheckboxFilters = (columnField : string, fieldValue ?: Array<string>) => {
        let currentFilters = { ...this.state.filterState };
        if ((fieldValue && fieldValue?.length < 0) && !!currentFilters[columnField]) {
            currentFilters = removeObjectAttribute(currentFilters, columnField);
        } else if (fieldValue) {
            let filterValues : Array<{filterValue : string; showEmpty ?: boolean; showFilled ?: boolean}> = [];
            fieldValue.forEach((x) => {
                const value : {filterValue : string; showEmpty ?: boolean; showFilled ?: boolean} = {
                    filterValue: x,
                    showEmpty: false,
                    showFilled: false,
                };

                filterValues = addArrayElement(filterValues, value);
            });
            currentFilters[columnField] = filterValues;
        } else {
            return;
        }
        this.setState({ filterState: currentFilters, currentPage: 0 });
    };

    private setSorting = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const columnIndex = Number(event.currentTarget.dataset.columnindex);
        const column = this.props.columns[columnIndex];
        const columnFilterTitle = this.getColumnFilterTitle(column);
        const index = this.state.sortState.findIndex(x => x.columnName === columnFilterTitle);
        const currentSort = index !== -1 ? this.state.sortState[index] : { columnName: columnFilterTitle, direction: undefined };
        const newSort : {columnName : string; direction ?: 'asc' | 'desc'} = { columnName: columnFilterTitle };
        switch (currentSort.direction) {
            case 'asc':
                newSort.direction = 'desc';
                break;
            case 'desc':
                newSort.direction = undefined;
                break;
            case undefined:
                newSort.direction = 'asc';
                break;
        }

        const sortState = [...this.state.sortState];

        const newSortState = index !== -1 ?
            (newSort.direction ? setArrayElement(sortState, index, newSort) : removeArrayElement(sortState, index))
            : addArrayElement(sortState, newSort, 'end');

        this.setState({ sortState: newSortState.filter(x => newSortState.length === 1 || this.props.columns.map(y => this.getColumnFilterTitle(y)).some(y => y === x.columnName)), currentPage: 0 });
    };

    private getColumnFilterTitle = (column : ICustomTableColumn) => column.field + '_' + column.title;

    private getRowsData = (props : ICustomTableProps<T>) => props.rows;
    private getEnableSorting = (props : ICustomTableProps<T>) => props.enableSorting;
    private getEnableFiltering = (props : ICustomTableProps<T>) => props.enableFiltering;
    private getEnablePagination = (props : ICustomTableProps<T>) => props.enablePagination;
    private getColumns = (props : ICustomTableProps<T>) => props.columns;
    private getSortState = (props : ICustomTableProps<T>, state : ICustomTableState) => state.sortState;
    private getFilterState = (props : ICustomTableProps<T>, state : ICustomTableState) => state.filterState;
    private getRowsPerPage = (props : ICustomTableProps<T>, state : ICustomTableState) => state.rowsPerPage;
    private getCurrentPage = (props : ICustomTableProps<T>, state : ICustomTableState) => state.currentPage;

    private getFilteredRows = createSelector([this.getRowsData, this.getEnableFiltering, this.getFilterState, this.getColumns], (rows, enableFiltering, filterState, columns) => {
        const unfiltered : Array<any> = rows ? [...rows] : [];
        if (!enableFiltering && !isEmptyObject(filterState)) {
            return unfiltered;
        }
        let filtered : Array<any> = [...unfiltered];
        const filters = { ...filterState };
        lodash.forEach(filters, (x, column) => {
            const columnObj = columns.find(y => this.getColumnFilterTitle(y) === column);
            const filterTypeColumn = this.props.filterTypeColumn?.find(y => y.column === column.split('_')[1]);
            if (columnObj && columnObj.formatFunction) {
                if (filterTypeColumn) {
                    filtered = filtered.filter(row =>
                        row
                        && x
                        && (row[columnObj.field] !== undefined)
                        && (row[columnObj.field] !== null)
                        && ((x.length > 0)
                            ? x.some(y => y.filterValue !== 'ShowMixed'
                                ? columnObj.formatFunction && columnObj.formatFunction(row[columnObj.field], row)?.toString().toLowerCase() === y.filterValue?.toString().toLowerCase()
                                : columnObj.formatFunction && columnObj.formatFunction(row[columnObj.field], row).split(', ').length > 1)
                            : true));
                } else {
                    filtered = filtered.filter(row =>
                        row
                        && x
                        && ((x.length > 0)
                            ? x.some(y => columnObj.formatFunction
                                    && ((row[columnObj?.field ?? ''] !== null && row[columnObj?.field ?? ''] !== undefined) ? columnObj.formatFunction(row[columnObj.field], row)?.toString().toLowerCase().includes(y.filterValue?.toString().toLowerCase()) : y.filterValue ? false : true)
                                    && (y.showEmpty && !y.showFilled
                                        ? (columnObj.formatFunction(row[columnObj.field], row) === null || columnObj.formatFunction(row[columnObj.field], row) === undefined || columnObj.formatFunction(row[columnObj.field], row) === '')
                                        : (!y.showEmpty && y.showFilled)
                                            ? (!!columnObj.formatFunction(row[columnObj.field], row))
                                            : true))
                            : true));
                }
            } else {
                if (filterTypeColumn) {
                    filtered = filtered.filter(row =>
                        row
                        && x
                        && (row[columnObj?.field ?? ''] !== undefined)
                        && (row[columnObj?.field ?? ''] !== null)
                        && ((x.length > 0)
                            ? x.some(y => y.filterValue !== 'ShowMixed'
                                ? row[columnObj?.field ?? '']?.toString().toLowerCase() === y.filterValue?.toString().toLowerCase()
                                : row[columnObj?.field ?? ''].split(', ').length > 1)
                            : true));
                } else {
                    filtered = filtered.filter(row =>
                        row
                        && x
                        && ((x.length > 0) ?
                            x.some(y => ((row[columnObj?.field ?? ''] !== null && row[columnObj?.field ?? ''] !== undefined) ? row[columnObj?.field ?? '']?.toString().toLowerCase().includes(y.filterValue?.toString().toLowerCase()) : y.filterValue ? false : true)
                                && (y.showEmpty && !y.showFilled
                                    ? (row[columnObj?.field ?? ''] === null || row[columnObj?.field ?? ''] === undefined || row[columnObj?.field ?? ''] === '')
                                    : (!y.showEmpty && y.showFilled)
                                        ? (!!row[columnObj?.field ?? ''])
                                        : true))
                            : true));
                }
            }
        });

        if (this.props.filteredRows) {
            this.props.filteredRows(filtered);
        }

        return filtered;
    });

    private getPaginationCount = createSelector([this.getRowsData, this.getFilteredRows, this.getFilterState], (rows, filteredRows, filterState) => {
        return isEmptyObject(filterState) ? (rows ? rows.length : 0) : filteredRows.length;
    });

    private getSortedRows = createSelector([this.getFilteredRows, this.getEnableSorting, this.getSortState, this.getColumns], (rows, enableSorting, sortState, columns) => {
        const unsorted = [...rows];
        if (!enableSorting || !sortState || sortState.length === 0) {
            return unsorted;
        }
        return unsorted.sort((a, b) => this.recursiveSort(a, b, 0, sortState, columns));
    });

    private recursiveSort = (a : any, b : any, index : number, sortState : Array<{columnName : string; direction ?: 'asc' | 'desc'}>, columns : Array<ICustomTableColumn>) : number =>  {
        const sortElement = { ...sortState[index] };
        const column = columns.find(x => this.getColumnFilterTitle(x) === sortElement.columnName);
        const unformattedFirstValue = a[column?.field ?? ''];
        const unformattedSecondValue = b[column?.field ?? ''];
        const firstValue = column && column.formatFunction ? column.formatFunction(unformattedFirstValue, a)?.toString() : unformattedFirstValue?.toString();
        const secondValue = column && column.formatFunction ? column.formatFunction(unformattedSecondValue, b)?.toString() : unformattedSecondValue?.toString();
        if (firstValue === secondValue && (sortState.length - 1 !== index)) {
            return this.recursiveSort(a, b, index + 1, sortState, columns);
        }
        if (column && column.sortFunction) {
            return sortElement.direction === 'asc' ? column.sortFunction(firstValue, secondValue) : column.sortFunction(secondValue, firstValue);
        }
        return sortElement.direction === 'asc' ? compareString(firstValue, secondValue) : compareString(secondValue, firstValue);
    };

    private getPagedRows = createSelector([this.getSortedRows, this.getEnablePagination, this.getRowsPerPage], (rows, enablePagination, rowsPerPage) => {
        const unpaged = [...rows];
        if (!unpaged || unpaged.length === 0) {
            return { 1: [] };
        }
        if (!enablePagination || !rowsPerPage) {
            return ({ 0: unpaged });
        }
        const pageCount = unpaged.length / rowsPerPage;
        const pagedRows : { [page : number] : Array<any> } = {};
        for (let i = 0; i < pageCount; i++) {
            pagedRows[i] = unpaged.slice((i * rowsPerPage), (i * rowsPerPage) + rowsPerPage);
        }
        return pagedRows;
    });

    private getRows = createSelector([this.getPagedRows, this.getSortState], (rows) => {
        return { ...rows };
    });

    private getRowsAtCurrentPage = createSelector([this.getRows, this.getCurrentPage, this.getSortState], (rows, currentPage) => {
        const newRows = { ...rows };
        return newRows[currentPage];
    });

    private hasTotalColumn = () => {
        let returnValue = false;
        this.props.columns.forEach((x) => {
            if (x.calculateTotal === true) {
                returnValue = true;
            }
        });
        return returnValue;
    };

    private exportToCSV = () => {
        const path = this.props.csvExportPathname ? this.props.csvExportPathname : '';

        const columns = this.props.columns;

        let csvString : string = '';

        // Add header

        columns.forEach((x, i) => csvString += ((i === columns.length - 1) ? x.title : `${x.title}, `));
        csvString += '\r\n';

        this.getRowsAtCurrentPage(this.props, this.state).forEach((row) => {
            columns.forEach((column, index) => {
                if (column.isNumberString) {
                    csvString +=  column.formatFunction ?
                        ((index === columns.length - 1)
                            ? `'${column.formatFunction(this.getField(row, column.field), row)}`
                            : `'${column.formatFunction(this.getField(row, column.field), row)}, `)
                        :
                        ((index === columns.length - 1)
                            ? `'${this.getField(row, column.field)}`
                            : `'${this.getField(row, column.field)}, `);
                } else {
                    csvString +=  column.formatFunction ?
                        ((index === columns.length - 1)
                            ? column.formatFunction(this.getField(row, column.field), row)
                            : `${column.formatFunction(this.getField(row, column.field), row)}, `)
                        :
                        ((index === columns.length - 1)
                            ? this.getField(row, column.field)
                            : `${this.getField(row, column.field)}, `);
                }
            });
            csvString += '\r\n';
        });

        saveAs(new Blob([csvString]), `Packman-CSV${path ? path.replace('/', '-') : ''}-${moment(moment.now()).format(DATETIME_PALTRACK_FILE)}.csv`);
    };

    private calculateColumnTotal = (column : ICustomTableColumn) => {
        let total = 0;
        this.getSortedRows(this.props, this.state).forEach(row => row[column.field] && (column.formatFunction ?
            Number(column.formatFunction(row[column.field], row)) && (total += Number(column.formatFunction(row[column.field], row))) :
            Number(row[column.field]) && (total += Number(row[column.field]))));
        return total;
    };

    private calculateColumnRedTotal = (column : ICustomTableColumn) => {
        let total = 0;
        this.getSortedRows(this.props, this.state).forEach(row => row[column.field] && (column.redFormatFunction ?
            Number(column.redFormatFunction(row[column.field], row)) && (total += Number(column.redFormatFunction(row[column.field], row))) :
            Number(row[column.field]) && (total += Number(row[column.field]))));
        return total;
    };

    private handleScroll = () => {
        const scrollArea = document.getElementById(this.props.id + '_scrollArea');
        // @ts-ignore
        const detailAccordions : HTMLCollectionOf<HTMLElement> = document.getElementsByClassName(this.props.id + '_detailAccordion');
        // @ts-ignore
        const frozenCells : HTMLCollectionOf<HTMLElement> = document.getElementsByClassName('frozenColumn');
        if (scrollArea && (detailAccordions.length > 0)) {
            // tslint:disable-next-line
            for (let i = 0; i < detailAccordions.length; i++) {
                detailAccordions[i].style.left = scrollArea.scrollLeft + 'px';
            }
        }
        if (scrollArea && frozenCells.length > 0) {
            // tslint:disable-next-line
            for (let i = 0; i < frozenCells.length; i++) {
                frozenCells[i].style.left = scrollArea.scrollLeft + 'px';
            }
        }
    };

    private getFilters = (columnFilterTitle : string, column : ICustomTableColumn) => {
        const filterColumn = this.props.filterTypeColumn?.find(x => x.column.toLowerCase() === column.title.toLowerCase());
        if (this.props.filterType === 'MultiCheckbox' && filterColumn) {
            return (
                <CustomTableMultiCheckboxFilter field={columnFilterTitle} data={filterColumn?.data ?? []} onFilterChanged={this.setMultiCheckboxFilters} setOpen={this.setOpenFilterBoxes} />
            );
        } else {
            return (
                <CustomTableFilter field={columnFilterTitle} onFilterChanged={this.setFilters} setOpen={this.setOpenFilterBoxes} enableAdditionalFiltering={true} />
            );
        }
    };

    private getNestedObject = (nestedObj : { [key : string] : any }, pathArr : Array<string>) =>  pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);

    private getField = (row : T, field : string) =>  this.getNestedObject(row, field.split('.'));

    private isFilterOpen = (columnFilterTitle : string) => !!this.state.openFilterBoxes.find(x => x === columnFilterTitle);

    private buttonColumnWidth = () => ((this.props.enableDetails ? 40 : 0) + (this.props.enableEditing ? 40 : 0) + (this.props.enableDeleting ? 40 : 0) + (this.props.enableDetailAccordion ? 40 : 0) + ((this.props.enableRefresh || (this.props.enableFiltering && this.props.enableClearFilterButton)) && !this.props.enableDetails && !this.props.enableEditing && !this.props.enableDeleting && !this.props.enableDetailAccordion ? 40 : 0));

    // eslint-disable-next-line no-console
    private onAddButtonClick = () => !!this.props.addFunction ? this.props.addFunction() : console.error('No add function supplied!');
    private onDeleteButtonClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const index = Number(event.currentTarget.value);
        const row = this.getRowsAtCurrentPage(this.props, this.state)[index];
        // eslint-disable-next-line no-console
        return !!this.props.deleteFunction ? this.props.deleteFunction(row) : () => console.error('No delete function supplied!');
    };
    private onEditButtonClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const index = Number(event.currentTarget.value);
        const row = this.getRowsAtCurrentPage(this.props, this.state)[index];
        // eslint-disable-next-line no-console
        return !!this.props.editFunction ? this.props.editFunction(row) : () => console.error('No edit function supplied!');
    };
    private onDetailButtonClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const index = Number(event.currentTarget.value);
        const row = this.getRowsAtCurrentPage(this.props, this.state)[index];
        // eslint-disable-next-line no-console
        return !!this.props.detailFunction ? this.props.detailFunction(row) : () => console.error('No detail function supplied!');
    };
    private onDetailAccordionExpandClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const index = Number(event.currentTarget.value);
        // eslint-disable-next-line no-console
        return !!this.props.detailAccordionComponent ? this.setState({ detailAccordionExpanded: setArrayElement(this.state.detailAccordionExpanded, index, !this.state.detailAccordionExpanded[index]) }) : console.error('No detail accordian component supplied!');
    };
    // eslint-disable-next-line no-console
    private onRefreshButtonClick = () => !!this.props.refreshFunction ? this.props.refreshFunction() : console.error('No refresh function supplied!');

    private updateColorAlpha = (color : string, opacity : number) => {
        // coerce values so ti is between 0 and 1.
        const newOpacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
        return color + newOpacity.toString(16).toUpperCase();
    };

    private getTableRowCellStyle = (column : ICustomTableColumn, row : any, rowIndex : number, colorForRow ?: string, rowIsActive ?: boolean, rowWarning ?: boolean) => {
        let style : React.CSSProperties | undefined;

        const rowBackgroundColor = (rowIndex % 2 !== 0) ?
            (!rowIsActive ? materialTheme.custom.table.row1.inactive :
                (rowWarning ? materialTheme.custom.table.row1.warning :
                    (colorForRow ? '' :  materialTheme.custom.table.row1.default)))
            :
            (!rowIsActive ? materialTheme.custom.table.row2.inactive :
                (rowWarning ? materialTheme.custom.table.row2.warning :
                    (colorForRow ? '' :  materialTheme.custom.table.row2.default)));

        const fitWidthToPage = this.props.fitWidthToPage;

        if (fitWidthToPage && !column.width && !column.minWidth) {
            style = {
                backgroundColor: rowBackgroundColor,
                minWidth: defaultColumnWidth,
                ...(column.style && column.style(row[column.field])),
            };
        } else if (column.width || column.minWidth) {
            if (column.width && !column.minWidth) {
                style = { backgroundColor: rowBackgroundColor, width: column.width, minWidth: column.width, maxWidth: column.width, ...(column.style && column.style(row[column.field])) };
            } else if (column.minWidth && !column.width) {
                style = { backgroundColor: rowBackgroundColor, minWidth: column.minWidth, ...(column.style && column.style(row[column.field])) };
            } else {
                style = { backgroundColor: rowBackgroundColor, width: column.width, minWidth: column.minWidth, ...(column.style && column.style(row[column.field])) };
            }
        } else {
            style = { backgroundColor: rowBackgroundColor, width: defaultColumnWidth, minWidth: defaultColumnWidth, maxWidth: defaultColumnWidth, ...(column.style && column.style(row[column.field])) };
        }

        return style;
    };

    private getTableHeaderColumnStyle = (column : ICustomTableColumn) => {
        let style : React.CSSProperties | undefined;

        const fitWidthToPage = this.props.fitWidthToPage;

        if (fitWidthToPage && !column.width && !column.minWidth) {
            style = { minWidth: defaultColumnWidth };
        } else if (column.width || column.minWidth) {
            if (column.width && !column.minWidth) {
                style = { width: column.width, minWidth: column.width, maxWidth: column.width };
            } else if (column.minWidth && !column.width) {
                style = { minWidth: column.minWidth };
            } else {
                style = { width: column.width, minWidth: column.width };
            }
        } else {
            style = { width: defaultColumnWidth, minWidth: defaultColumnWidth, maxWidth: defaultColumnWidth };
        }

        return style;
    };

    public render() {
        const {
            enableFiltering,
            enableSorting,
            enableEditing,
            enableAdding,
            enableCSVExport,
            enableDeleting,
            enableDetailAccordion,
            enableDetails,
            enablePagination,
            enableRefresh,
            enableTotalRow,
            addIcon,
            editIcon,
            editColor,
            addTooltip,
            editTooltip,
            disableAddButton,
            disableEditButton,
            pageHeight,
            disableRefreshButton,
            hide,
            id,
            detailAccordionComponent,
            columns,
            deleteIcon,
            deleteColor,
            deleteTooltip,
            detailIcon,
            detailTooltip,
            detailsColor,
            disableDeleteButton,
            disableDetailButton,
            fitWidthToPage,
            isActive,
            warning,
            rowColor,
            pageSizes,
        } = this.props;

        const rows = this.getRows(this.props, this.state);
        const rowsAtCurrentPage = this.getRowsAtCurrentPage(this.props, this.state);

        return (
            <div className={hide ? 'dn' : 'mxhfill wfill fdc CustomTableBorder'}>
                <div className={'posr fdc mxhfill'}>
                    <div className={`oa ${pageHeight ? '' : 'mxhfill'} mnh220`} style={pageHeight ? { maxHeight: `calc(100vh - ${pageHeight}px)` } : undefined} onScroll={this.handleScroll} id={id + '_scrollArea'}>
                        <Table className={'tlf posstick bcw post0 zi1'}>
                            <TableHead className={''}>
                                <TableRow className={'fdr wfill bcTableHead'}>{/* Column Names*/}
                                    { (enableEditing || enableDetails || enableDeleting || enableDetailAccordion || enableRefresh) &&
                                    <TableCell className={`pl15 pr15 jcc aic ${this.state.freezeUpTo ? 'frozenColumn bcTableHead zi1' : ''}`} style={{ minWidth: this.buttonColumnWidth() }}>
                                        { enableRefresh &&
                                            // eslint-disable-next-line no-console
                                            <IconButton disabled={disableRefreshButton} className={'w48 mnw48'} onClick={this.onRefreshButtonClick}>
                                                <Icon className={'cw'}>refresh</Icon>
                                            </IconButton>
                                        }
                                    </TableCell>
                                    }
                                    {columns.map((column, columnIndex) => {
                                        const columnFilterTitle = this.getColumnFilterTitle(column);
                                        const sortElement = this.state.sortState.find(x => x.columnName === columnFilterTitle);
                                        return <TableCell key={columnIndex + '_column'} className={`${fitWidthToPage ? 'flx1' : ''} aic jcl ${this.isFilterOpen(columnFilterTitle) ? 'zi1000' : 'zi1'} bcTableHead tcTableHead posr pt0 pb0 pl15 pr0 ${this.state.freezeUpTo && this.state.freezeUpTo >= columnIndex ? 'frozenColumn' : ''}` }
                                            style={this.getTableHeaderColumnStyle(column)} >
                                            { enableSorting && column.enableSorting ?
                                                <>
                                                    <TableSortLabel
                                                        active={!!sortElement?.direction}
                                                        className={`tcTableHead ${sortElement ? 'Sorted' : ''}`}
                                                        direction={ sortElement ? sortElement.direction : undefined }
                                                        data-columnindex={columnIndex}
                                                        onClick={this.setSorting}
                                                        color={'#ffffff'}
                                                    >
                                                        {column.titleContainerComponent ? column.titleContainerComponent(column.title) : column.title}
                                                    </TableSortLabel>
                                                    {   enableFiltering && column.enableFiltering &&
                                                    this.getFilters(columnFilterTitle, column)
                                                    }
                                                </>
                                                :
                                                (column.titleContainerComponent ? column.titleContainerComponent(column.title) : column.title)
                                            }
                                            <div className={'posa cs post50 posl20'}>{this.state.filterState[columnFilterTitle] ? this.state.filterState[columnFilterTitle].map(x => x.filterValue).toString().replace(/,/g, ', ') : ''}</div>
                                        </TableCell>;
                                    })}
                                </TableRow>
                                { enableTotalRow && this.hasTotalColumn() &&
                                <TableRow className={'fdr wfill'}>{/* Total Row*/}
                                    { (enableEditing || enableDetails || enableDeleting || enableDetailAccordion || enableRefresh) &&
                                        <TableCell className={`pl15 pr15 ${this.state.freezeUpTo ? 'frozenColumn' : ''}`} style={{ minWidth: (enableDetails ? 40 : 0) + (enableEditing ? 40 : 0) + (enableDeleting ? 40 : 0) + (enableDetailAccordion ? 40 : 0) + (enableRefresh && !enableDetails && !enableEditing && !enableDeleting && !enableDetailAccordion ? 40 : 0) }}/>
                                    }
                                    {columns.map((column, columnIndex) => {
                                        return <TableCell key={'totalRowAtColumn_' + columnIndex} className={`aic jcl bcw pt0 pb0 pl15 pr0 ${!!fitWidthToPage ? 'flx1' : ''} ${!!column.calculateTotal ? 'aic jcc cw fw700' : ''} ${this.state.freezeUpTo && this.state.freezeUpTo >= columnIndex ? 'frozenColumn' : ''}`}
                                            style={this.getTableHeaderColumnStyle(column)}>
                                            {column.calculateRedTotal && this.calculateColumnRedTotal(column) ?
                                                <div className={'fdr aic bcp'} style={{ marginRight: '-8px', marginLeft: '-8px', height: 'calc(100% + 32px)', width: 'calc(100% + 16px)' }}>
                                                    <div className={'cw ml5 mr5 fw300'}>{column.calculateTotal ? this.calculateColumnTotal(column) : ''}</div>
                                                    <div className={'cOrange'} >{`(${this.calculateColumnRedTotal(column)})`}</div>
                                                </div> : ''}
                                        </TableCell>;
                                    })}
                                </TableRow>
                                }
                            </TableHead>
                        </Table>
                        <Table className={'tlf'}>
                            <TableBody className={''}>
                                { !rows || !rows[0] || rows[0].length === 0 ?
                                    <TableRow className={'fdr flx1 pb30 pt0'}>
                                        <TableCell className={'aic jcc flx1 pt30 pb30'}>No Data!</TableCell>
                                    </TableRow>
                                    :
                                    rowsAtCurrentPage &&
                                rowsAtCurrentPage.length !== 0 &&
                                rowsAtCurrentPage.map((row, index) => {
                                    const rowIsActive = typeof isActive === 'boolean' ? isActive : (isActive ? isActive(row) : true);
                                    const rowWarning = typeof warning === 'boolean' ? warning : (warning ? warning(row) : false);
                                    const colorForRow = typeof rowColor === 'string' ? rowColor : (rowColor ? rowColor(row) : '');

                                    const rowBackgroundColor = (index % 2 !== 0) ?
                                        (!rowIsActive ? materialTheme.custom.table.row1.inactive :
                                            (rowWarning ? materialTheme.custom.table.row1.warning :
                                                (colorForRow ? '' :  materialTheme.custom.table.row1.default)))
                                        :
                                        (!rowIsActive ? materialTheme.custom.table.row2.inactive :
                                            (rowWarning ? materialTheme.custom.table.row2.warning :
                                                (colorForRow ? '' :  materialTheme.custom.table.row2.default)));

                                    return <React.Fragment key={'row_ ' + index}>
                                        <TableRow className={'wfill flx1'}>
                                            { (enableEditing || enableDetails || enableDeleting || enableDetailAccordion || enableRefresh) &&
                                                <TableCell className={`pl15 pr0 pb0 pt0 aic jcc
                                                    ${this.state.freezeUpTo ? 'frozenColumn' : ''}`}
                                                style={{
                                                    backgroundColor: rowBackgroundColor,
                                                    minWidth: (enableDetails ? 40 : 0) + (enableEditing ? 40 : 0) + (enableDeleting ? 40 : 0) + (enableDetailAccordion ? 40 : 0) + (enableRefresh && !enableDetails && !enableEditing && !enableDeleting && !enableDetailAccordion ? 40 : 0) }}>
                                                    { enableEditing &&
                                                        ((typeof enableEditing === 'boolean' || enableEditing(row)) ?
                                                            <Tooltip disableInteractive title={editTooltip ? (typeof editTooltip === 'string' ? editTooltip : (editTooltip(row) ?? 'Edit')) : 'Edit'}>
                                                                {// eslint-disable-next-line no-console
                                                                    <div>
                                                                        <IconButton style={editColor ? { color: editColor } : { color : ''  } }
                                                                            value={index}
                                                                            className={`w48 mnw48 ${disableEditButton && (disableEditButton === true || disableEditButton(row)) ? 'cgray3' : 'cpd'}`}
                                                                            onClick={this.onEditButtonClick} disabled={disableEditButton !== undefined && disableEditButton !== null && disableEditButton !== false && (disableEditButton === true || disableEditButton(row))}>
                                                                            <Icon>{editIcon ? editIcon : 'edit'}</Icon>
                                                                        </IconButton>
                                                                    </div>}
                                                            </Tooltip>
                                                            :
                                                            <div className={'w40'} />)
                                                    }
                                                    { enableDeleting &&
                                                        ((typeof enableDeleting === 'boolean' || enableDeleting(row)) ?
                                                            <Tooltip disableInteractive title={deleteTooltip ? (typeof deleteTooltip === 'string' ? deleteTooltip : (deleteTooltip(row) ?? 'Delete')) : 'Delete'}>
                                                                {// eslint-disable-next-line no-console
                                                                    <div>
                                                                        <IconButton style={deleteColor ? { color: deleteColor } : {}}
                                                                            value={index}
                                                                            className={`w48 mnw48 ${disableDeleteButton && (disableDeleteButton === true || disableDeleteButton(row)) ? 'cgray3' : 'cpd'}`}
                                                                            onClick={this.onDeleteButtonClick} disabled={disableDeleteButton !== undefined && disableDeleteButton !== null && disableDeleteButton !== false && (disableDeleteButton === true || disableDeleteButton(row))}>
                                                                            <Icon>{deleteIcon ? deleteIcon : 'delete'}</Icon>
                                                                        </IconButton>
                                                                    </div>}
                                                            </Tooltip>
                                                            :
                                                            <div className={'w40'} />)
                                                    }
                                                    { enableDetails &&
                                                        ((typeof enableDetails === 'boolean' || enableDetails(row)) ?
                                                            <Tooltip disableInteractive title={detailTooltip ? detailTooltip : 'Detail'}>
                                                                {// eslint-disable-next-line no-console
                                                                    <IconButton style={detailsColor ? { color: detailsColor } : {}} value={index} className={'w48 mnw48 cpd'} onClick={this.onDetailButtonClick} disabled={disableDetailButton !== undefined && disableDetailButton !== null && disableDetailButton !== false && (disableDetailButton === true || disableDetailButton(row))}>
                                                                        <Icon>{detailIcon ? detailIcon : 'list'}</Icon>
                                                                    </IconButton>}
                                                            </Tooltip>
                                                            :
                                                            <div className={'w48 mnw48'} />)
                                                    }
                                                    { enableDetailAccordion &&
                                                        ((typeof enableDetailAccordion === 'boolean' || enableDetailAccordion(row)) ?
                                                            // eslint-disable-next-line no-console
                                                            <IconButton className={'w48 mnw48 cpd'} value={index} onClick={this.onDetailAccordionExpandClick}>
                                                                { this.state.detailAccordionExpanded[index] ?
                                                                    <Icon>expand_less</Icon>
                                                                    :
                                                                    <Icon>expand_more</Icon>
                                                                }
                                                            </IconButton>
                                                            :
                                                            <div className={'w40'} />)
                                                    }
                                                </TableCell>
                                            }
                                            {columns.map((column, columnIndex) => {
                                                return (
                                                    <TableCell key={'row_' + index + '_column_' + columnIndex} className={`aic jcl pt0 pb0 pl15 pr0 ${fitWidthToPage ? 'flx1' : ''} ${this.state.freezeUpTo && this.state.freezeUpTo >= columnIndex ? 'frozenColumn' : ''}`}
                                                        style={this.getTableRowCellStyle(column, row, index, colorForRow, rowIsActive, rowWarning)}>
                                                        {column.containerComponent && column.containerComponent(row, column.formatFunction ? column.formatFunction(this.getField(row, column.field), row) : this.getField(row, column.field), index) ?
                                                            column.containerComponent(row, column.formatFunction ? column.formatFunction(this.getField(row, column.field), row) : this.getField(row, column.field), index)
                                                            :
                                                            column.formatFunction ? column.formatFunction(this.getField(row, column.field), row) : this.getField(row, column.field)}
                                                    </TableCell>);
                                            })}
                                        </TableRow>
                                        { enableDetailAccordion !== undefined && enableDetailAccordion !== null && enableDetailAccordion !== false && (enableDetailAccordion === true || enableDetailAccordion(row)) && detailAccordionComponent && this.state.detailAccordionExpanded[index] &&
                                        <TableRow>
                                            <TableCell id={id + '_detailAccordion'} className={`posr ${id}_detailAccordion bcg2`}>{detailAccordionComponent(row)}</TableCell>
                                        </TableRow>}
                                    </React.Fragment>;
                                })
                                }
                            </TableBody>
                        </Table>
                    </div>
                </div>
                <div className={'flx1 zi1'}/>
                { (!!enablePagination || !!enableAdding || !!enableCSVExport) &&
                    <Table className={'zi1'}>
                        <TableFooter>
                            <TableRow className={'flx1 fdr'}>
                                <TableCell className='w600 jcfs aic p0' >
                                    { !!enablePagination && this.state.rowsPerPage &&
                                        <TablePagination
                                            className={'jcfs fdr flx1'}
                                            count={this.getPaginationCount(this.props, this.state)}
                                            onPageChange={this.changePage}
                                            onRowsPerPageChange={this.setRowsPerPage}
                                            page={this.state.currentPage}
                                            rowsPerPage={this.state.rowsPerPage}
                                            rowsPerPageOptions={pageSizes}
                                            ActionsComponent={TablePaginationActionsWrapped}
                                        />
                                    }
                                </TableCell>
                                <TableCell className='flx1 jcfe pt0 pl0 pb0 pr20' >
                                    { enableCSVExport &&
                                        <TableCell className={'w80 mnw80 mnh60 aic p20'}>
                                            {
                                                enableCSVExport &&
                                                <CustomTooltip title={'Download CSV'}>
                                                    <div className={'posr zi1'}>
                                                        <FloatingActionButton
                                                            color={'secondary'}
                                                            onClick={this.exportToCSV}
                                                            children={<FontAwesomeIcon className={'ml10 mr10 mb1 cpd'} size={'2x'} icon={faFileCsv}/>}
                                                        />
                                                    </div>
                                                </CustomTooltip>
                                            }
                                        </TableCell>
                                    }
                                    { enableAdding &&
                                        <TableCell className={'w80 mnw80 mnh60 aic p20'}>
                                            {
                                                enableAdding &&
                                                <CustomTooltip title={addTooltip ? (typeof addTooltip === 'string' ? addTooltip : 'Add') : 'Add'}>
                                                    <div className={'posr zi1'}>
                                                        <FloatingActionButton
                                                            color={'secondary'}
                                                            onClick={this.onAddButtonClick}
                                                            children={addIcon ? <Icon fontSize={'large'} className={'cpd'}>{addIcon}</Icon> : undefined}
                                                            disabled={disableAddButton}
                                                        />
                                                    </div>
                                                </CustomTooltip>
                                            }
                                        </TableCell>
                                    }
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                }
            </div>
        );
    }
}

import * as Yup from 'yup';
import { Inventory } from './inventory';

export interface IInventoryFormValues {
    id : number;
    guid : string;
    code : string;
    description : string;
    isActive : boolean;
}

export class InventoryFormValues implements IInventoryFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public description : string;
    public isActive : boolean;

    public constructor(inventory ?: Inventory) {
        this.initializeDefaultValues();
        if (inventory) {
            this.id = inventory.id;
            this.guid = inventory.guid;
            this.code = inventory.code;
            this.description = inventory.description;
            this.isActive = inventory.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.description = '';
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().max(2).required('Required'),
        description: Yup.string().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IPackCategory } from '../../../@types/model/masterData/pack/packCategory';

export default class PackCategoryHttpService {
    public static getPackCategoryData() : AxiosPromise<Array<IPackCategory>> {
        return axios.get(`${PACKMAN_API_URL}PackCategory/GetAllWeb`);
    }

    public static addOrUpdatePackCategory(data : IPackCategory) : AxiosPromise<IPackCategory> {
        return axios.post(`${PACKMAN_API_URL}PackCategory/PostPackCategory`, data);
    }

    public static deletePackCategory(packCategoryId : number) : AxiosPromise<IPackCategory> {
        return axios.delete(`${PACKMAN_API_URL}PackCategory/Delete`, {
            params : { packCategoryId },
        });
    }
}

import * as React from 'react';
import { Form, FormikProps } from 'formik';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { RightFormValues } from '../../../@types/model/user/rightFormValues';
import { IOptionType } from '../../../@types/helper';
import FormTextInput from '../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../components/input/form/FormSingleToggleButton';
import PillButton from '../../../components/input/PillButton';
import { IRootState } from '../../../@types/redux';
import FormAutocompleteSelect from '../../../components/input/form/FormAutoCompleteSelect';
import { IRight } from '../../../@types/model/user/right';

interface IRightEditFormProps {
    rightParentOptions : Array<IOptionType>;
}

interface IRightEditFormState {
}

type RightEditFormPropsType = IRightEditFormProps & FormikProps<RightFormValues>;

class RightEditForm extends React.Component<RightEditFormPropsType, IRightEditFormState> {

    constructor(props : RightEditFormPropsType) {
        super(props);
    }

    public render() {
        return (
            <Form className={'p20'}>
                <FormAutocompleteSelect
                    name={'parent'}
                    label={'Parent'}
                    options={this.props.rightParentOptions}
                />
                <FormAutocompleteSelect
                    name={'breadcrumbParent'}
                    label={'Breadcrumb Parent'}
                    options={this.props.rightParentOptions}
                />
                <FormTextInput name={'code'} label={'Code'}/>
                <FormTextInput name={'name'} label={'Name'}/>
                <FormTextInput name={'url'} label={'URL'}/>
                <FormTextInput name={'pageOrder'} label={'Page Order'}/>
                <FormTextInput name={'sectionOrder'} label={'Section Order'}/>
                <FormTextInput name={'reportURL'} label={'Report URL'}/>
                <FormSingleToggleButton name={'isMenu'} label={'Menu?'} />
                <FormSingleToggleButton name={'isMobile'} label={'Mobile?'} />
                <FormSingleToggleButton name={'isOnNavDrawer'} label={'On Nav Drawer?'} />
                <FormSingleToggleButton name={'isPage'} label={'Page?'} />
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isSubmitting}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const getRights = (state : IRootState) => state.data.rights;

const getRightParentOptions = createSelector(
    [getRights],
    (rights : Array<IRight>) => {
        return rights.map(x => ({ label: `(${x.code}) ${x.name}`, value: x.id }));
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        rightParentOptions: getRightParentOptions(state),
    };
};

export default connect(
    mapStateToProps,
)(RightEditForm);

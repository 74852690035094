import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { ISeason } from '../../../@types/model/masterData/season/season';

export default class SeasonHttpService {
    public static getSeasonData() : AxiosPromise<Array<ISeason>> {
        return axios.get(`${PACKMAN_API_URL}Season/GetAllWeb`);
    }

    public static addOrUpdateSeason(data : ISeason) : AxiosPromise<ISeason> {
        return axios.post(`${PACKMAN_API_URL}Season/PostSeason`, data);
    }

    public static deleteSeason(seasonId : number) : AxiosPromise<ISeason> {
        return axios.delete(`${PACKMAN_API_URL}Season/Delete`, {
            params : { seasonId },
        });
    }
}

import { IBaseModel } from '../../model';

export interface IInfoBoardData extends IBaseModel {
    heading : string;
    body : string;
}

export class InfoBoardData implements IInfoBoardData {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public heading : string;
    public body : string;

    public constructor(formValues : IInfoBoardData) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.heading = formValues.heading;
        this.body = formValues.body;
    }
}

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { ICommodityState } from '../../../@types/model/masterData/commodityState/commodityState';

export default class CommodityStateHttpService {
    public static getCommodityStateData() : AxiosPromise<Array<ICommodityState>> {
        return axios.get(`${PACKMAN_API_URL}CommodityState/GetAllWeb`);
    }

    public static addOrUpdateCommodityState(data : ICommodityState) : AxiosPromise<ICommodityState> {
        return axios.post(`${PACKMAN_API_URL}CommodityState/PostCommodityState`, data);
    }

    public static deleteCommodityState(commodityStateId : number) : AxiosPromise<ICommodityState> {
        return axios.delete(`${PACKMAN_API_URL}CommodityState/Delete`, {
            params : { commodityStateId },
        });
    }
}

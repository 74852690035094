export const REGEXP_NUMBER = new RegExp('^[0-9]*$');
export const REGEXP_GEOM = new RegExp('^[-]?[0-9]+[.,]?[0-9]*$');
export const REGEXP_UNSIGNED_DECIMAL = new RegExp('^[0-9]+[.,]?[0-9]*$');

const compose = (...validationFunctions : Array<ValidationFunctionType>) => (value : any) => {
    let result : any;
    validationFunctions.forEach((validationFunc) => {
        result = result || validationFunc(value);
    });
    return result;
};

const required = (value : any) => {
    return !value && value !== 0 ? 'Field required.' : undefined;
};

const length = (minimum ?: number, maximum ?: number) => (value : any) => {
    if (minimum && maximum) {
        return value && typeof value === 'string' && (value.length < minimum || value.length > maximum) ? 'Value must be between ' + minimum + ' and ' + maximum + ' characters.' : undefined;
    }
    if (minimum) {
        return value && typeof value === 'string' && (value.length < minimum) ? 'Value must be more than ' + minimum + ' characters.' : undefined;
    }
    if (maximum) {
        return value && typeof value === 'string' && (value.length > maximum) ? 'Value must be less than ' + maximum + ' characters.' : undefined;
    }
    return undefined;
};

const geom = (value : any) => {
    return matchesRegex(REGEXP_GEOM, 'Value is not a valid number or contains invalid characters.')(value);
};

const unsignedDecimal = (value : any) => {
    return matchesRegex(REGEXP_UNSIGNED_DECIMAL, 'Value is not a valid number or contains invalid characters.')(value);
};

const digitsonly = (value : any) => {
    return matchesRegex(REGEXP_NUMBER, 'Value must be a number.')(value);
};

const matchesRegex = (expression : RegExp, message : string) => (value : any) => {
    let newValue = value;
    if (typeof value === 'number') {
        newValue = Number(value).toString();
    }
    if (typeof value === undefined) {
        newValue = '';
    }
    return newValue && typeof newValue === 'string' && expression.test(newValue) ? undefined : message;
};

type ValidationFunctionType = (mixed : any) => string | undefined;

export default {
    compose,
    required,
    length,
    geom,
    unsignedDecimal,
    digitsonly,
    matchesRegex,
};

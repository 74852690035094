const theme = {
    colors: {
        constant: {
            white: '#FFFFFF',
            black: '#000000',
            blue: '#192bc2',
            red: '#B92D00',
            orange: '#FF7F00',

            accent1: '#fddb00',
            accent2: '#054F87',
            accent3: '#27C062',
            accent4: '#79CBBC',
            accent5: '#FFB82C',

            grey1: '#F7F7F7',
            grey2: '#EAEAEA',
            grey3: '#B4B4B3',
            grey4: '#434343',
        },

        material: {
            primary: {
                dark: '#0F4323',
                main: '#0D703F',
                light: '#098442',
                contrastText: '#FFFFFF',
            },
            secondary: {
                main: '#FFDD00',
                contrastText: '#0D703F',
                hover: '#b8ae1f',
            },
            text: {
                primary: '#424242',
                secondary: '#757575',
                disabled: '#969696',
            },
        },

        light: {
            randomColor: {
                alphaValue: 0.3,
            },
            background: {
                main: '#EAEAEA',
                dialogAppBar: '#0D703F',
                navbar: '#0D703F',
                dialog: '#FFFFFF',
                form: '#FFFFFF',
                sideDrawer: '#FFFFFF',
                dateFilter: '#FFFFFF',
            },
            text: {
                primary: '#424242',
                secondary: '#757575',
                disabled: '#969696',
                header: '#0D703F',
                sideMenu: '#0F4323',
            },
            textfield: {
                underline: {
                    focus: '#0D703F',
                },
            },
            dateRangeFilter: {
                background: '#FFFFFF',
                days: {
                    selected: {
                        background: '#EAEAEA',
                        text: '#FFFFFF',
                    },
                },
            },
            panel: {
                background: '#FFFFFF',
                card: {
                    header: '#EAEAEA',
                    hover: '#F7F7F7',
                    background: '#FFFFFF',
                },
            },
            icon: {
                main: '#0F4323',
                booleanFlag: {
                    true: '#0F4323',
                    false: '#000000',
                },
            },
            typography: {
                homeHeader: {
                    text: '#0D703F',
                },
                packmanLabel: {
                    text: '#0D703F',
                },
                stockFormHeader: {
                    text: '#0D703F',
                    background: '#EAEAEA',
                },
            },
            accordion: {
                summary: {
                    background: '#0D703F',
                },
                details: {
                    background: '#FFFFFF',
                },
            },
            button: {
                logout: {
                    text: '#0D703F',
                },
                form: '#0D703F',
            },
            checkbox: {
                main: '#0D703F',
                disabled: '#B4B4B3',
                checked: '#0D703F',
            },
            packmanLink: {
                main: '#0D703F',
                error: '#B92D00',
                warning: '#FF7F00',
            },
            commodityListItem : {
                selected: {
                    background: '#0D703F',
                    text: '#FFFFFF',
                },
                default: {
                    text: '#0F4323',
                },
            },
            table: {
                background: '#FFFFFF',
                text: '#000000',
                row1: {
                    default: '#EAEAEA',
                    inactive: '#fad1d0',
                    warning: '#f8cca3',
                },
                row2: {
                    default: '#FFFFFF',
                    inactive: '#ffc6c4',
                    warning: '#fdb482',
                },
                footer: {
                    background: '#FFFFFF',
                },
                cell: {
                    background: '#FFFFFF',
                },
                icon: '#0D703F',
            },
            multiDispatchTable: {
                background: '#FFFFFF',
                text: '#000000',
                row1: {
                    default: '#EAEAEA',
                    inactive: '#fad1d0',
                    warning: '#f8cca3',
                },
                row2: {
                    default: '#FFFFFF',
                    inactive: '#ffc6c4',
                    warning: '#fdb482',
                },
                footer: {
                    background: '#FFFFFF',
                },
                icon: '#0D703F',
            },
            circularProgress: {
                color: '#0D703F',
            },
        },
        dark: {
            randomColor: {
                alphaValue: 1,
            },
            background: {
                main: '#0F4323',
                dialogAppBar: '#0D703F',
                navbar: '#0D703F',
                dialog: '#0F4323',
                form: '#0F4323',
                sideDrawer: '#0F4323',
                dateFilter: '#0D703F',
            },
            text: {
                primary: '#FFFFFF',
                secondary: '#000000',
                disabled: '#969696',
                header: '#FFDD00',
                sideMenu: '#FFFFFF',
            },
            textfield: {
                underline: {
                    focus: '#FFFFFF',
                },
            },
            dateRangeFilter: {
                background: '#0F4323',
                days: {
                    selected: {
                        background: '#d2b600',
                        text: '#0D703F',
                    },
                },
            },
            panel: {
                background: '#0F4323',
                card: {
                    header: '#098442',
                    hover: '#098442',
                    background: '#0D703F',
                },
            },
            icon: {
                main: '#FFFFFF',
                booleanFlag: {
                    true: '#FFFFFF',
                    false: '#FFFFFF',
                },
            },
            typography: {
                homeHeader: {
                    text: '#FFDD00',
                },
                packmanLabel: {
                    text: '#FFDD00',
                },
                stockFormHeader: {
                    text: '#FFFFFF',
                    background: '#0D703F',
                },
            },
            accordion: {
                summary: {
                    background: '#0D703F',
                },
                details: {
                    background: '#098442',
                },
            },
            button: {
                logout: {
                    text: '#FFFFFF',
                },
                form: '#FFFFFF',
            },
            checkbox: {
                main: '#FFFFFF',
                disabled: '#B4B4B3',
                checked: '#FFDD00',
            },
            packmanLink: {
                main: '#FFDD00',
                error: '#B92D00',
                warning: '#FF7F00',
            },
            commodityListItem : {
                selected: {
                    background: '#098442',
                    text: '#FFFFFF',
                },
                default: {
                    text: '#FFFFFF',
                },
            },
            table: {
                background: '#0F4323',
                text: '#FFFFFF',
                row1: {
                    default: '#0F4323',
                    inactive: '#e83341',
                    warning: '#fa7d2f',
                },
                row2: {
                    default: '#175D3A',
                    inactive: '#831D25',
                    warning: '#BF6127',
                },
                footer: {
                    background: '#0D703F',
                },
                cell: {
                    background: '#0F4323',
                },
                icon: '#FFFFFF',
            },
            multiDispatchTable: {
                background: '#0F4323',
                text: '#FFFFFF',
                row1: {
                    default: '#0F4323',
                    inactive: '#e83341',
                    warning: '#fa7d2f',
                },
                row2: {
                    default: '#175D3A',
                    inactive: '#831D25',
                    warning: '#BF6127',
                },
                footer: {
                    background: '#0D703F',
                },
                icon: '#FFFFFF',
            },
            circularProgress: {
                color: '#FFDD00',
            },
        },
    },
};

module.exports = theme;

import * as React from 'react';
import { Form, FormikProps } from 'formik';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import { DepartmentFormValues } from '../../../../@types/model/masterData/department/departmentFormValues';
import CustomTooltip from '../../../../components/tooltip/tooltip';

interface IDepartmentFormProps {
    isLoading : boolean;
}

interface IDepartmentFormState {
}

type DepartmentFormPropsType = IDepartmentFormProps & FormikProps<DepartmentFormValues>;

export default class DepartmentForm extends React.Component<DepartmentFormPropsType, IDepartmentFormState> {

    constructor(props : DepartmentFormPropsType) {
        super(props);
    }

    public render() {
        const selectedDepartment = this.props.values;
        return (
            <Form className={'p20'}>
                <CustomTooltip title={!!selectedDepartment.isImported ? 'Cannot change this field if department is imported' : ''}>
                    <FormTextInput name={'code'} label={'Code'} disabled={!!selectedDepartment.isImported}/>
                </CustomTooltip>
                <CustomTooltip title={!!selectedDepartment.isImported ? 'Cannot change this field if department is imported' : ''}>
                    <FormTextInput name={'shortDescription'} label={'Short Description'} disabled={!!selectedDepartment.isImported}/>
                </CustomTooltip>
                <CustomTooltip title={!!selectedDepartment.isImported ? 'Cannot change this field if department is imported' : ''}>
                    <FormTextInput name={'description'} label={'Description'} disabled={!!selectedDepartment.isImported}/>
                </CustomTooltip>
                <CustomTooltip title={!!selectedDepartment.isImported ? 'Cannot change this field if department is imported' : ''}>
                    <FormSingleToggleButton name={'isActive'} label={'Is Active'} disabled={!!selectedDepartment.isImported}/>
                </CustomTooltip>
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

import axios, { AxiosPromise } from 'axios';
import { IDomain } from '../../@types/model/user/domain';
import { PACKMAN_API_URL } from '../../appConstants';

const DOMAIN_API_PATH = `${PACKMAN_API_URL}Rights/Domain/`;

export default class DomainHttpService {
    public static getDomainData() : AxiosPromise<Array<IDomain>> {
        return axios.get(`${DOMAIN_API_PATH}GetList`);
    }

    public static addOrUpdateDomain(data : IDomain) : AxiosPromise<IDomain> {
        return axios.post(`${DOMAIN_API_PATH}PostDomain`, data);
    }

    public static deleteDomain(domainId : number) : AxiosPromise<IDomain> {
        return axios.delete(`${DOMAIN_API_PATH}Delete`, {
            params : { domainId },
        });
    }
}

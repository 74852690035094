import * as React from 'react';
import PillButton from '../../components/input/PillButton';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import { Typography, Button, TextField, Checkbox, Table, TableRow, TableCell } from '@mui/material';
import { ListChildComponentProps, FixedSizeList as WindowList } from 'react-window';
import { CustomChangeEvent, IOptionType } from '../../@types/helper';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar, generalShowWarningSnackbar } from '../../store/general/Functions';
import { createSelector } from 'reselect';
import { CONSTANT_LOT_TYPE_GUIDS } from '../../appConstants';
import { ILot } from '../../@types/model/lot/lot';
import { IStorageUnit } from '../../@types/model/masterData/storageUnit/storageUnit';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { ILotType } from '../../@types/model/masterData/lotType/lotType';
import lodash from 'lodash';
import { ILotLine } from '../../@types/model/lot/lotLine';
import { addArrayElement, formatDateTime, removeArrayElement } from '../../services/appFunctionsService';
import { ILotTransfer } from '../../@types/model/lot/lotTransfer';
import LotHttpService from '../../services/http/lot/lotHttpService';
import { DispatchCall, IRootState, RootAction } from '../../@types/redux';
import { connect } from 'react-redux';
import { dataSetLots } from '../../store/data/Actions';
import { Dispatch, bindActionCreators } from 'redux';
import { dataSetLotView } from '../../store/data/Functions';
import { IDocumentProps } from '../../@types/other';
import {  Document, Page, View, Image, Text, StyleSheet, Font } from '@react-pdf/renderer';
import Code39 from '../../fonts/code39.ttf';
import QRCode from 'qrcode.react';
import { createAndDownloadPDF } from '../../services/compliance/documentService';

const styles = StyleSheet.create({
    section: {
        margin: 10,
        padding: 10,
        flex: 1,
    },
    page: {
        paddingTop: 25,
        paddingBottom: 65,
        marginBottom: 65,
    },
    pageNumber: {
        fontSize: 11,
        position: 'absolute',
        bottom: 35,
        right: 25,
    },
    pageMargin: {
        marginLeft: 25,
        marginRight: 25,
        flex: 1,
    },
    tableRow: {
        borderBottom: '1pt solid black',
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 8,
        flexDirection: 'row',
    },
    findingSheetColumn: {
        fontSize: 8,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        paddingTop: 5,
        borderRight: '1pt solid black',
        alignItems: 'center',
    },
    fdc: { flexDirection: 'column' },
    fdr: { flexDirection: 'row' },
    verticalText: {
        transform: 'rotate(90deg)',
        width: 80,
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    barcode: {
        fontFamily: 'code39',
        fontSize: 20,
        transform: 'scaleY(2)',
        top: 10,
    },
    barcodeLabel: {
        fontSize: 9,
        letterSpacing: 2.5,
        top: 20,
    },
    qrCode: {
        width: '100%',
        height: '100%',
    },
    fs6: { fontSize: 6 },
    fs8: { fontSize: 8 },
    fs10: { fontSize: 10 },
    fs11: { fontSize: 11 },
    fs12: { fontSize: 12 },
    fs13: { fontSize: 13 },
    fs14: { fontSize: 14 },
    fs15: { fontSize: 15 },
    bold: { fontWeight: 'bold' },
    pt5: { paddingTop: 5 },
    pt10: { paddingTop: 10 },
    pt35: { paddingTop: 35 },
    pt50: { paddingTop: 50 },
    pb5: { paddingBottom: 5 },
    pb10: { paddingBottom: 10 },
    pb35: { paddingBottom: 35 },
    pb50: { paddingBottom: 50 },
    pl2: { paddingLeft: 2 },
    pl4: { paddingLeft: 4 },
    pl5: { paddingLeft: 5 },
    pl20: { paddingLeft: 20 },
    pl40: { paddingLeft: 40 },
    pr2: { paddingRight: 2 },
    pr20: { paddingRight: 20 },
    pr80: { paddingRight: 80 },
    pr100: { paddingRight: 100 },
    mt5: { marginTop: 5 },
    mt10: { marginTop: 10 },
    mt15: { marginTop: 15 },
    mt20: { marginTop: 20 },
    mt35: { marginTop: 35 },
    mb5: { marginBottom: 5 },
    mb10: { marginBottom: 10 },
    mb20: { marginBottom: 20 },
    mb35: { marginBottom: 35 },
    mb50: { marginBottom: 50 },
    flx1: { flex: 1 },
    blw1: { borderLeft: '1pt solid black' },
    brw1: { borderRight: '1pt solid black' },
    btw1: { borderTop: '1pt solid black' },
    bbw1: { borderBottom: '1pt solid black' },
    jcc: { justifyContent: 'center' },
    ail: { alignItems: 'left' },
    aic: { alignItems: 'center' },
    aife: { alignItems: 'flex-end' },
    tac: { textAlign: 'center' },
    posa: { position: 'absolute' },
    w50: { width: 50 },
    w100: { width: 100 },
    w130: { width: 130 },
    w140: { width: 140 },
    w175: { width: 175 },
    w180: { width: 180 },
    w190: { width: 190 },
    w200: { width: 200 },
    w280: { width: 280 },
    h40: { height: 40 },
    h80: { height: 80 },
    h90: { height: 90 },
    h100: { height: 100 },
    h130: { height: 130 },
    h150: { height: 150 },
    h160: { height: 160 },
    h170: { height: 170 },
    h180: { height: 180 },
    h185: { height: 185 },
    h190: { height: 190 },
    h200: { height: 200 },
    h205: { height: 205 },
    h210: { height: 210 },
    h220: { height: 220 },
    h230: { height: 230 },
    h240: { height: 240 },
    h250: { height: 250 },
    h260: { height: 260 },
    t5: { top: 5 },
    certCol1: {
        left: 0,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 1,
        position: 'absolute',
    },
    certCol2: {
        left: 35,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 2,
        position: 'absolute',
    },
    certCol3: {
        left: 70,
        width: 30,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 3,
        position: 'absolute',
    },
    certCol4: {
        left: 100,
        width: 25,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 4,
        position: 'absolute',
    },
    certCol5: {
        left: 125,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 5,
        position: 'absolute',
    },
    certCol6: {
        left: 160,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 6,
        position: 'absolute',
    },
    certCol7: {
        left: 195,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 7,
        position: 'absolute',
    },
    certCol8: {
        left: 230,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 8,
        position: 'absolute',
    },
    certCol9: {
        left: 265,
        width: 25,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 9,
        position: 'absolute',
    },
    certCol10: {
        left: 290,
        width: 30,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 10,
        position: 'absolute',
    },
    certCol11: {
        left: 320,
        width: 70,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 11,
        position: 'absolute',
    },
    certCol12: {
        left: 390,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 12,
        position: 'absolute',
    },
    certCol13: {
        left: 425,
        width: 85,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 13,
        position: 'absolute',
    },
    certCol14: {
        left: 510,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 14,
        position: 'absolute',
    },
});

Font.register({
    family: 'code39',
    src: Code39,
});
interface ILotTransferDialogProps {
    dataSetLots : DispatchCall<Array<ILot>>;
    isLoading : boolean;
    lots : Array<ILot>;
    storageUnits : Array<IStorageUnit>;
    packs : Array<IPack>;
    selectedLotId ?: number;
    selectedOrganizationIds : Array<number>;
    selectedSiteIds : Array<number>;
    lotTypes : Array<ILotType>;
    closeLotTransferDialog : () => void;
    isTransferFinalizeButtonClicked : boolean;
    refreshData ?: () => void;
    setLoading : (isLoading : boolean) => void;
    selectedLotLines ?: Array<number>;
    setSelectedLotLines ?: (lotLineId : number) => void;
}

interface ILotTransferDialogState {
    lotToTransfer ?: ILot;
    selectedSourceLot ?: IOptionType;
    selectedDestinationLot ?: IOptionType;
    selectedSourceStorageUnit ?: IOptionType;
    selectedDestinationStorageUnit ?: IOptionType;
    selectedPack ?: IOptionType;
    lotTransferNoUnitsAmount ?: number;
    lotTransferSelectedTotalNoUnitsAmount ?: number;
    lotTransferGrossWeight ?: number;
    lotTransferSelectedTotalGrossWeight ?: number;
    checkedLotLines : Array<{lotLine : ILotLine; checked : boolean}>;

    unitbarcodeSearchValue : string;
}

class LotTransferDialog extends React.Component<ILotTransferDialogProps, ILotTransferDialogState> {

    constructor(props : ILotTransferDialogProps) {
        super(props);

        this.state = {
            checkedLotLines: [],
            unitbarcodeSearchValue: '',
        };
    }

    public componentDidMount = async () => {
        this.props.setLoading(true);
        try {
            const res = await LotHttpService.getLotTransferRelatedData(this.props.selectedLotId, this.props.selectedOrganizationIds, this.props.selectedSiteIds);

            if (res && res.data) {
                this.props.dataSetLots(res.data);

                const selectedLot = res.data.find(x => x.id === this.props.selectedLotId);
                if (selectedLot) {
                    let selectedLines : Array<{lotLine : ILotLine; checked : boolean}> = [];

                    let unitsAtDifferentSite : Array<ILotLine> = [];

                    if (this.props.selectedLotLines && this.props.selectedLotLines?.length > 0) {
                        const lines = selectedLot.lotLines.filter(x => this.props.selectedLotLines?.some(y => y === x.id));

                        lines.forEach((x) => {
                            const line = {
                                lotLine: x,
                                checked: true,
                            };

                            if (!x.isIncoming  || !!x.toLotLineId || !!x.toStockLineId) {
                                unitsAtDifferentSite = addArrayElement(unitsAtDifferentSite, x, 'end');
                            }

                            selectedLines = addArrayElement(selectedLines, line);
                        });
                    }

                    const storageUnit = this.props.storageUnits.find(x => x.id === selectedLot?.storageUnitId);

                    const sourceLot = selectedLot ? { label: selectedLot?.name, value: selectedLot?.id } : undefined;

                    const sourceStorageUnit = storageUnit ? { label: `(${storageUnit?.code}) ${storageUnit?.description}`, value: storageUnit?.id } : undefined;

                    const sourceLotPack = this.props.packs.find(x => x.id === selectedLot?.packId);

                    const selectedPack = sourceLotPack ? { label: sourceLotPack.code + ' - ' + sourceLotPack.description, value: sourceLotPack.id } : undefined;

                    if (unitsAtDifferentSite.length < 1) {
                        this.setState({
                            lotToTransfer: selectedLot,
                            selectedSourceLot: sourceLot,
                            selectedSourceStorageUnit: sourceStorageUnit,
                            selectedPack,
                            checkedLotLines: selectedLines,
                        });
                    } else {
                        generalShowWarningSnackbar(`Some selected units is not at the same site. Unit "${unitsAtDifferentSite[0].unitGuid}" is not at this site.`);
                    }
                }
            }
        } catch (e) {
            generalShowErrorSnackbar('Error occured while loading lot transfer related data');
        } finally {
            this.props.setLoading(false);
        }
    };

    public closeDialog = () => {
        this.setState({
            lotToTransfer: undefined,
            selectedSourceLot: undefined,
            selectedDestinationLot: undefined,
            selectedSourceStorageUnit: undefined,
            selectedDestinationStorageUnit: undefined,
            selectedPack: undefined,
            lotTransferGrossWeight: undefined,
            lotTransferNoUnitsAmount: undefined,
            checkedLotLines: [],
        });
        this.props.closeLotTransferDialog();
    };

    private getSelectedOrganizationIds = (props : ILotTransferDialogProps) => props.selectedOrganizationIds;
    private getSelectedSites = (props : ILotTransferDialogProps) => props.selectedSiteIds;
    private getStorageUnits = (props : ILotTransferDialogProps) => props.storageUnits;
    private getLotData = (props : ILotTransferDialogProps) => props.lots;
    private getLotToTransfer = (props : ILotTransferDialogProps, state : ILotTransferDialogState) => state.lotToTransfer;
    private getPackData = (props : ILotTransferDialogProps) => props.packs;
    private getSelectedSourceStorageUnit = (props : ILotTransferDialogProps, state : ILotTransferDialogState) => state.selectedSourceStorageUnit;
    private getSelectedDestinationStorageUnit = (props : ILotTransferDialogProps, state : ILotTransferDialogState) => state.selectedDestinationStorageUnit;
    private getIsTransferFinalizeButtonClicked = (props : ILotTransferDialogProps) => props.isTransferFinalizeButtonClicked;
    private getSelectedPack = (props : ILotTransferDialogProps, state : ILotTransferDialogState) => state.selectedPack;

    private getSourceLotOptions = createSelector(
        [this.getLotData, this.getLotToTransfer, this.getSelectedSourceStorageUnit, this.getSelectedOrganizationIds, this.getSelectedSites],
        (lots, lotToTransfer, selectedSourceStorageUnit, selectedOrganizationIds, selectedSiteIds) => {
            if (!lots) return [];

            const lotsFiltered = lots.filter(x => x.isActive
                && (lotToTransfer !== undefined
                    ? x.id === lotToTransfer?.id
                    : (selectedOrganizationIds?.some(y => y === x.organizationId)
                        && (selectedSiteIds?.some(y => y === x.siteId))
                        && (!!selectedSourceStorageUnit ? x.storageUnitId === selectedSourceStorageUnit.value : true)
                        && (this.getLotType(x.lotTypeId) === CONSTANT_LOT_TYPE_GUIDS[2] || this.getLotType(x.lotTypeId) === CONSTANT_LOT_TYPE_GUIDS[4]))));

            return lotsFiltered.map((x) => {
                return { label: x.name, value: x.id };
            });
        },
    );

    private getDestinationLotOptions = createSelector(
        [this.getLotData, this.getSelectedDestinationStorageUnit, this.getLotToTransfer, this.getSelectedOrganizationIds, this.getSelectedSites],
        (lots, selectedDestinationStorageUnit, lotToTransfer, selectedOrganizationIds, selectedSiteIds) => {
            return lots ? lots.filter(x => x.isActive && (x.id !== (lotToTransfer ? lotToTransfer.id : 0))
                    && (selectedOrganizationIds?.some(y => y === x.organizationId))
                    && (selectedSiteIds?.some(y => y === x.siteId))
                    && (this.getLotType(x.lotTypeId) === CONSTANT_LOT_TYPE_GUIDS[2] || this.getLotType(x.lotTypeId) === CONSTANT_LOT_TYPE_GUIDS[4])
                    && (selectedDestinationStorageUnit ? x.storageUnitId === selectedDestinationStorageUnit.value : (x.storageUnitId === null))
                    && (x.id !== lotToTransfer?.id)
                    && (x.commodityId === lotToTransfer?.commodityId)
                    && (x.varietyId === lotToTransfer?.varietyId)
                    && (x.orchardId === lotToTransfer?.orchardId)
                    && (x.packId === lotToTransfer?.packId || !x.packId))
                .map((x) => {
                    return { label: x.name, value: x.id };
                }) : [];
        },
    );

    private getLotType = (lotTypeId : number) => {
        const lotType = this.props.lotTypes.find(x => x.id === lotTypeId);
        return lotType ? lotType.guid : '';
    };

    private getSourceStorageUnitOptions = createSelector(
        [this.getStorageUnits, this.getLotToTransfer, this.getIsTransferFinalizeButtonClicked, this.getSelectedSites],
        (storageUnits, lotToTransfer, isTransferFinalizeButtonClicked, selectedSiteIds) => {
            if (!storageUnits) return;

            return storageUnits.filter(x => x.isActive && isTransferFinalizeButtonClicked ? (selectedSiteIds?.some(y => y === x.siteId)) : x.id === lotToTransfer?.storageUnitId).map((x) => {
                return { label: `(${x.code}) ${x.description}`, value: x.id };
            });
        },
    );

    private getDestinationStorageUnitOptions = createSelector(
        [this.getStorageUnits, this.getSelectedSites, this.getSelectedSourceStorageUnit],
        (storageUnits, selectedSiteIds, selectedSourceStorageUnit) => {
            if (!storageUnits) return;

            return storageUnits.filter(x => x.isActive
                && x.id !== selectedSourceStorageUnit?.value
                && (selectedSiteIds?.some(y => y === x.siteId))).map((x) => {
                return { label: `(${x.code}) ${x.description}`, value: x.id };
            });
        },
    );

    private getPackOptions = createSelector(
        [this.getPackData, this.getLotToTransfer],
        (packs, lotToTransfer) => {
            if (!packs) return;

            return packs.filter(x => x.isActive && x.commodityId === lotToTransfer?.commodityId).map((x) => {
                return { label: `(${x.code}) ${x.description}`, value: x.id };
            });
        },
    );

    private onSourceLotChange = (e : CustomChangeEvent, selectedLot : IOptionType) => {
        if (selectedLot !== null) {
            const lot = this.props.lots.find(x => x.id === selectedLot.value);
            const pack = this.props.packs.find(x => x.id === lot?.packId);
            const selectedPack = pack ? { label: pack.code + ' - ' + pack.description, value: pack.id } : undefined;

            const incomingLotLines = lot?.lotLines?.filter(x => !!x.isIncoming).map(x => x.amountOfUnits).reduce((a, b) => a + b, 0);
            const outgoingLotLines = lot?.lotLines?.filter(x => !x.isIncoming).map(x => x.amountOfUnits).reduce((a, b) => a + b, 0);

            if (incomingLotLines && outgoingLotLines && ((incomingLotLines - outgoingLotLines) < 1)) {
                generalShowWarningSnackbar('The selected source lot has no units.');
            }

            this.setState({ lotToTransfer: lot, selectedSourceLot: selectedLot, selectedPack, checkedLotLines: [] });
        } else {
            this.setState({ lotToTransfer: undefined, selectedSourceLot: undefined, selectedPack: undefined, checkedLotLines: [] });
        }
    };

    private onDestinationLotChange = (e : CustomChangeEvent, selectedLot : IOptionType) => {
        this.setState({ selectedDestinationLot: selectedLot });
    };

    private onSourceStorageUnitChange = (e : CustomChangeEvent, selectedStorageUnit : IOptionType) => {
        this.setState({ selectedSourceStorageUnit: selectedStorageUnit });
    };

    private onDestinationStorageUnitChange = (e : CustomChangeEvent, selectedStorageUnit : IOptionType) => {
        this.setState({ selectedDestinationStorageUnit: selectedStorageUnit });
    };

    private onPackChange = (e : CustomChangeEvent, selectedPack : IOptionType) => {
        this.setState({ selectedPack });
    };

    private getSelectedPackValue = createSelector(
        [this.getPackData, this.getSelectedPack],
        (packs : Array<IPack>, selectedPack : IOptionType) => {
            if (!packs) return;

            const pack = packs.find(x => x.id === selectedPack?.value);

            return pack ? { label: pack.code + ' - ' + pack.description, value: pack.id } : undefined;
        },
    );

    private getLotToTransferLotLines = (lotId : number) => {
        const lot = this.props.lots.find(x => x.id === lotId);

        if (lot) {
            const isInComingLines = lot.lotLines.filter(x => x.isActive && x.isIncoming);
            const isOutGoingLines = lot.lotLines.filter(x => x.isActive && !x.isIncoming);
            const unitBarcode = this.state.unitbarcodeSearchValue;

            const validLotLines = isInComingLines.filter(x => !!x.unitGuid && (x.unitGuid.toLowerCase().includes(unitBarcode.toLowerCase()))
                && (isInComingLines.filter(y => y.unitGuid === x.unitGuid).length > isOutGoingLines.filter(y => y.unitGuid === x.unitGuid).length))
                .map(x => lodash.orderBy(isInComingLines.filter(y => y.isActive && y.unitGuid === x.unitGuid), line => line.createdOn, 'desc')[0]);

            return validLotLines ?? [];
        }
    };

    private handleCheckboxChecked = (line : ILotLine, selected : boolean) => {
        if (selected) {
            const index = this.state.checkedLotLines.findIndex(x => x.lotLine.id === line.id);
            const value = {
                lotLine: line,
                checked: selected,
            };

            if (!line.isIncoming || !!line.toLotLineId || !!line.toStockLineId) {
                generalShowWarningSnackbar(`Selected unit ${line.unitGuid} is no longer at this site.`);
            }

            if (index === -1) {
                this.setState(prevState => ({ checkedLotLines: addArrayElement(prevState.checkedLotLines, value) }));
            }
        } else {
            const index = this.state.checkedLotLines.findIndex(x => x.lotLine.id === line.id);
            if (index !== -1) {
                this.setState(prevState => ({ checkedLotLines: removeArrayElement(prevState.checkedLotLines, index) }));
            }
        }
        if (this.props.setSelectedLotLines) {
            this.props.setSelectedLotLines(line.id);
        }
    };

    private onLotTransferSubmit = async () => {
        this.props.setLoading(true);
        if (this.state.lotToTransfer) {

            let data : ILotTransfer;
            if (this.state.checkedLotLines.length > 0) {
                data = {
                    sourceLotId: Number(this.state.selectedSourceLot?.value) ?? 0,
                    destinationLotId: Number(this.state.selectedDestinationLot?.value) ?? 0,
                    noOfUnits: this.state.lotTransferNoUnitsAmount,
                    inputWeight: this.state.lotTransferGrossWeight,
                    targetPackId: Number(this.state.selectedPack?.value) ?? 0,
                    lotLineIds: this.state.checkedLotLines.map(x => x.lotLine.id),
                    weighedWithPack: true,
                };
            } else {
                data = {
                    sourceLotId: Number(this.state.selectedSourceLot?.value) ?? 0,
                    destinationLotId: Number(this.state.selectedDestinationLot?.value) ?? 0,
                    noOfUnits: this.state.lotTransferNoUnitsAmount ?? 0,
                    inputWeight: this.state.lotTransferGrossWeight ?? 0,
                    targetPackId: Number(this.state.selectedPack?.value) ?? 0,
                    weighedWithPack: true,
                };
            }

            try {
                const res = await LotHttpService.doLotTransfer(data);

                if (res.data) {
                    res.data.map(x => dataSetLotView(x));
                    if (!!this.props.refreshData) {
                        this.props.refreshData();
                    }

                    this.closeDialog();
                    generalShowSuccessSnackbar('Lot transfer successful!');
                } else {
                    generalShowErrorSnackbar('An error occurred while performing lot transfer.');
                    this.closeDialog();
                }
            } catch (e) {
                this.closeDialog();
                generalShowErrorSnackbar('An error occurred while performing lot transfer.');
            } finally {
                this.props.setLoading(false);
            }
        }
    };

    private onSendLotToWasteSubmit = async () => {
        this.props.setLoading(true);
        if (this.state.lotToTransfer) {
            let data;
            if (this.state.checkedLotLines.length > 0) {
                data = {
                    sourceLotId: Number(this.state.selectedSourceLot?.value) ?? 0,
                    wasteLotId: Number(this.state.selectedDestinationLot?.value) ?? 0,
                    noOfUnits: this.state.lotTransferNoUnitsAmount ?? 0,
                    inputWeight: this.state.lotTransferGrossWeight ?? 0,
                    targetPackId: Number(this.state.selectedPack?.value) ?? 0,
                    lotLineIds: this.state.checkedLotLines.map(x => x.lotLine.id),
                    weighedWithPack: true,
                };
            } else {
                data = {
                    sourceLotId: Number(this.state.selectedSourceLot?.value) ?? 0,
                    wasteLotId: Number(this.state.selectedDestinationLot?.value) ?? 0,
                    noOfUnits: this.state.lotTransferNoUnitsAmount ?? 0,
                    inputWeight: this.state.lotTransferGrossWeight ?? 0,
                    targetPackId: Number(this.state.selectedPack?.value) ?? 0,
                    weighedWithPack: true,
                };
            }

            try {
                const res = await LotHttpService.sendLotToWaste(data);

                if (res.data) {
                    if (!!this.props.refreshData) {
                        this.props.refreshData();
                    }

                    this.closeDialog();
                    generalShowSuccessSnackbar('Lot transfer successful!');
                } else {
                    generalShowErrorSnackbar('An error occurred while performing lot transfer.');
                    this.closeDialog();
                    this.props.setLoading(false);
                }
            } catch (e) {
                this.closeDialog();
                generalShowErrorSnackbar('An error occurred while performing lot transfer.');
                this.props.setLoading(false);
            }
        }
    };

    private unitBarcodesPDF = (lotlines : Array<ILotLine>) => {
        return <Document>
            <Page size='A4' style={styles.page}>
                <View style={styles.pageMargin}>
                    <View style={styles.fdr}>
                        <Image style={{ height: 42 }} source={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`}/>
                        <Text style={[styles.fs13, styles.bold, styles.mt15, styles.pl20]}>PACKMAN</Text>
                        <View style={styles.flx1} />
                    </View>
                    <View style={[styles.fdr, styles.pb10]} fixed>
                        <View style={styles.flx1} />
                        <Text style={styles.fs8} fixed render={({ pageNumber, totalPages }) => (
                            `PAGE ${pageNumber} OF ${totalPages}`
                        )}> </Text>
                    </View>
                    <View style={[styles.fdr, styles.bbw1]}>
                    </View>
                    <View style={[styles.fdr]}>
                        <Text style={[styles.fs10, styles.pb10, styles.pt10, styles.w280]}>{'QR CODES'}</Text>
                    </View>
                    {this.unitBarcodes(lotlines ?? [])}
                </View>
            </Page>
        </Document>;
    };

    private unitBarcodes = (lotlines : Array<ILotLine>) => {
        let qrCodes : any = [];
        lotlines.forEach((lotline) => {
            qrCodes = addArrayElement(qrCodes,
                <View wrap={false} style={[styles.fdr, styles.h190, styles.mb20, styles.bbw1, styles.btw1, styles.brw1, styles.blw1]}>
                    <View style={[styles.flx1, styles.fdc, styles.pb5, styles.aic, styles.jcc]}>
                        {<Image style={[styles.qrCode, styles.h130, { width: 130 }]} src={this.renderQRCode(lotline)}/>}
                        {<Text style={[styles.bold, styles.pt5, styles.fs10]}>{lotline.unitGuid}</Text>}
                    </View>
                </View>, 'end');
        });
        return qrCodes;
    };

    private getGuidQRValue = (guid : string = '') => {
        const parts = guid.split('_');
        return parts[0] + '|1|' + parts[1];
    };

    private renderQRCode = (lotline : ILotLine) => {
        const renderedQRCode = document.getElementById(`${lotline.id}_qr_code`) as HTMLCanvasElement;

        return renderedQRCode.toDataURL();
    };

    private renderQRCodes = (lotlines : Array<ILotLine>) => {
        return lotlines.map((lotline) => {
            return (
                <div style={{ display: 'none' }}>
                    <QRCode
                        id={`${lotline.id}_qr_code`}
                        value={this.getGuidQRValue(lotline.unitGuid)}
                        bgColor={'#ffffff'}
                        fgColor={'#000000'}
                        level={'H'}
                    />
                </div>
            );
        });
    };

    private generateUnitGuidPDF = async (lotlines : Array<ILotLine>, callback ?: () => void) => this.buildUnitGuidPDF(this.unitBarcodesPDF, 'Unit Barcodes.pdf', lotlines, callback);

    private buildUnitGuidPDF = (document : any, fileName : string, lotlines : Array<ILotLine>, callback ?: () => void) => {
        const doc : () => React.ReactElement<IDocumentProps> = () => document(lotlines);

        createAndDownloadPDF(
            doc,
            fileName,
            'pdf-creator-link',
            // tslint:disable-next-line: no-empty
            callback ? callback : () => {},
        );
    };

    private downloadBarcodes = () => this.generateUnitGuidPDF(this.getLotToTransferLotLines(this.state.lotToTransfer?.id ?? 0) ?? []);

    private renderListElement = React.memo(({ data, index, style } : React.PropsWithChildren<ListChildComponentProps>) => {
        const lotLine : ILotLine = data[index];
        if (!lotLine) return <div />;
        const isChecked : boolean = !!this.state.checkedLotLines.find(x => x.lotLine.id === lotLine.id);
        return (
            <TableRow style={style} className={`${index % 2 === 0 ? 'bcTableRow2' : 'bcTableRow1'}`}>
                <TableCell className={'wfill jcc aic h33 fs14 p0 fw500'}>
                    <div style={{ width: 60, minWidth: 60 }} className={'aic jcc'}>
                        <Checkbox
                            className={'pt0 pb0 pr5 m0 cp'}
                            checked={isChecked}
                            onChange={(event, checked) => this.handleCheckboxChecked(lotLine, checked)}
                        />
                    </div>
                    <div className={'flx2 aic jcc hfill'}>{lotLine.unitGuid}</div>
                    <div className={'w100 aic jcc bl1 hfill'}>{lotLine.amountOfUnits}</div>
                    <div className={'flx2 aic jcc bl1 hfill'}>{formatDateTime(lotLine.updatedOn ?? '')}</div>
                </TableCell>
            </TableRow>
        );
    });

    private onUnitBarcodeSearchTextChange = (event : CustomChangeEvent) => {
        this.setState({ unitbarcodeSearchValue: event.currentTarget.value });
    };

    public render() {
        const lotToTransferLotLines = this.getLotToTransferLotLines(this.state.lotToTransfer?.id ?? 0);
        return (
            <div className={'fdc hfill p20'}>
                {this.renderQRCodes(lotToTransferLotLines ?? [])}
                <div className={'fdr flx1 aic mb10'}>
                    <AutocompleteSelect
                        className={'flx1'}
                        name={'sourceStorageUnit'}
                        label={'Source Storage Unit'}
                        options={this.getSourceStorageUnitOptions(this.props, this.state) ?? []}
                        onChange={this.onSourceStorageUnitChange}
                        value={this.state.selectedSourceStorageUnit}
                        disabled={!this.props.isTransferFinalizeButtonClicked || !!this.props.selectedLotLines}
                    />
                    <div className={'w10'}/>
                    <AutocompleteSelect
                        className={'flx1'}
                        name={'destinationStorageUnit'}
                        label={'Destination Storage Unit'}
                        options={this.getDestinationStorageUnitOptions(this.props, this.state) ?? []}
                        onChange={this.onDestinationStorageUnitChange}
                        value={this.state.selectedDestinationStorageUnit}
                    />
                </div>
                <div className={'fdr flx1 aic mb10'}>
                    <AutocompleteSelect
                        className={'flx1'}
                        name={'sourceLot'}
                        label={'Source Lot'}
                        options={this.getSourceLotOptions(this.props, this.state) ?? []}
                        onChange={this.onSourceLotChange}
                        value={this.state.selectedSourceLot}
                        disabled={!this.props.isTransferFinalizeButtonClicked || !!this.props.selectedLotLines}
                    />
                    <div className={'w10'}/>
                    <AutocompleteSelect
                        className={'flx1'}
                        name={'destinationLot'}
                        label={'Destination Lot'}
                        options={this.getDestinationLotOptions(this.props, this.state) ?? []}
                        onChange={this.onDestinationLotChange}
                        value={this.state.selectedDestinationLot}
                    />
                </div>
                <div className={'fdr flx1 jcsb aic mb10'}>
                    <div style={{ minWidth: 450 }} className={'fdc pl10 pr10 w450 jcfs'}>
                        <TextField
                            value={this.state.unitbarcodeSearchValue}
                            label={'Filter Unit Barcode'}
                            placeholder={'Filter by unit barcode...'}
                            className={`${lotToTransferLotLines ? 'h40 mb10' : 'dn'}`}
                            onChange={this.onUnitBarcodeSearchTextChange}
                        />
                        <div  style={{ height: 20, minHeight: 20 }}/>
                        <Table className= {'PaperBorder'}>
                            {/* Headings */}
                            <TableRow className={'fdr bcTableHead'}>
                                <TableCell className={'flx3 jcc aic p5 bcTableHead h23 cw fs14'}>
                                    <div style={{ width: 60, minWidth: 60 }} className={'aic jcc'}>Select</div>
                                    <div className={'flx2 aic jcc'}>Unit Barcode</div>
                                    <div className={'w100 aic jcc'}>No. Units(ea)</div>
                                    <div className={'flx2 aic jcc'}>Updated On</div>
                                </TableCell>
                            </TableRow>
                            {/* body */}
                            { lotToTransferLotLines &&
                                <WindowList
                                    itemCount={lotToTransferLotLines.length}
                                    width={440}
                                    height={300}
                                    itemSize={40}
                                    itemData={lotToTransferLotLines}
                                >
                                    {this.renderListElement}
                                </WindowList>
                            }
                        </Table>
                    </div>
                    <div className={'w10'}/>
                    <div className={'fdc wfill p10 jcfs'}>
                        <div>
                            <AutocompleteSelect
                                className={'wfill'}
                                name={'pack'}
                                label={'Pack'}
                                options={this.getPackOptions(this.props, this.state) ?? []}
                                onChange={this.onPackChange}
                                value={this.getSelectedPackValue(this.props, this.state)}
                            />
                        </div>
                        <div className={'h10'}/>
                        <Typography className={`${this.state.checkedLotLines.length > 0 ? 'ml10' : 'dn' }`}>Additional:</Typography>
                        <div className={'fdr wfill jcsb aic mr10 ml10 pr10'}>
                            <TextField
                                value={this.state.lotTransferNoUnitsAmount}
                                label={'No. Units'}
                                type={'number'}
                                className={'flx1'}
                                onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.setState({ lotTransferNoUnitsAmount: Number(e.target.value) })}
                            />
                            <div className={'w10'}/>
                            <TextField
                                value={this.state.lotTransferGrossWeight}
                                label={'Gross Weight (kg)'}
                                type={'number'}
                                className={'flx1'}
                                onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.setState({ lotTransferGrossWeight: Number(e.target.value) })}
                            />
                        </div>
                        <div className={'h10'}/>
                        <Typography className={`${this.state.checkedLotLines.length > 0 ? 'pb5 ml10' : 'dn'}`}>Selected:</Typography>
                        <div className={`${this.state.checkedLotLines.length > 0 ? 'fdr wfill jcsb aic mr15 ml15' : 'dn'}`}>
                            <TextField
                                value={this.state.checkedLotLines.map(x => x.lotLine.amountOfUnits).reduce((a, b) => a + b, 0)}
                                label={'No. Units'}
                                type={'number'}
                                className={'flx1'}
                                onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.setState({ lotTransferSelectedTotalNoUnitsAmount: Number(e.target.value) })}
                                disabled={true}
                            />
                            <div className={'w10'}/>
                            <TextField
                                value={this.state.checkedLotLines.map(x => x.lotLine.grossWeight).reduce((a, b) => a + b, 0)}
                                label={'Gross Weight (kg)'}
                                type={'number'}
                                className={'flx1'}
                                disabled={true}
                                onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.setState({ lotTransferSelectedTotalGrossWeight: Number(e.target.value) })}
                            />
                        </div>
                    </div>
                </div>
                <div className={'fdr jcfe mt10'}>
                    <PillButton
                        text={'Download'}
                        className={'fwb mr5 h35'}
                        color='secondary'
                        disabled={this.props.isLoading}
                        onClick={this.downloadBarcodes}
                    />
                    <div className='flx1'/>
                    <Button
                        className={'fwb mr5 h35'}
                        variant='text'
                        disabled={this.props.isLoading}
                        onClick={this.closeDialog}>
                        Cancel
                    </Button>
                    <PillButton
                        text={'Send to destination'}
                        className={'pl30 pr30 h35'}
                        onClick={this.onLotTransferSubmit}
                        disabled={this.props.isLoading
                            || !this.state.selectedSourceLot
                            || !this.state.selectedDestinationLot
                            || (!this.state.lotTransferNoUnitsAmount ? (this.state.checkedLotLines.length < 1 ? true : false) : false)
                            || (!this.state.lotTransferGrossWeight ? (this.state.checkedLotLines.length < 1 ? true : false) : false)
                            || !this.state.selectedPack}
                        color={'secondary'}
                        size={'small'}
                    ></PillButton>
                    <PillButton
                        text={'Send to waste'}
                        className={'ml5 pl30 pr30 h35'}
                        onClick={this.onSendLotToWasteSubmit}
                        disabled={this.props.isLoading
                            || !!this.state.selectedDestinationStorageUnit
                            || !!this.state.selectedDestinationLot
                            || !this.state.selectedSourceLot
                            || (!this.state.lotTransferNoUnitsAmount ? (this.state.checkedLotLines.length < 1 ? true : false) : false)
                            || (!this.state.lotTransferGrossWeight ? (this.state.checkedLotLines.length < 1 ? true : false) : false)
                            || !this.state.selectedPack}
                        color={'secondary'}
                        size={'small'}
                    ></PillButton>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        lots: state.data.lots,
        packs: state.masterData.packs,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
        storageUnits: state.masterData.storageUnits,
        lotTypes: state.masterData.lotTypes,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetLots }, dispatcher,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LotTransferDialog);

import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { IFarm } from '../farm/farm';
import { ISalesPoint } from './salesPoint';

export interface ISalesPointFormValues {
    id : number;
    guid : string;
    name : string;
    farms : Array<IOptionType>;
    isActive : boolean;
}

export class SalesPointFormValues implements ISalesPointFormValues {
    public id : number;
    public guid : string;
    public name : string;
    public farms : Array<IOptionType>;
    public isActive : boolean;

    public constructor(
        salesPoint ?: ISalesPoint,
        farms ?: Array<IFarm>,
    ) {
        this.initializeDefaultValues();
        if (salesPoint) {
            this.id = salesPoint.id;
            this.guid = salesPoint.guid;
            this.name = salesPoint.name;

            this.farms = farms?.filter(x => salesPoint.farmIds?.some(y => y === x.id))?.map(x => ({
                label: x.name,
                value: x.id,
            })) ?? [];

            this.isActive = salesPoint.isActive;
        }

    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.name = '';
        this.farms = [];
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        farms: Yup.array().of(Yup.object()).min(1, 'Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

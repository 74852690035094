import { IRootState } from '../../../@types/redux';
import React from 'react';
import { connect } from 'react-redux';
import { Paper } from '@mui/material';
import { formatDateTime, compareDate, booleanToYesNo } from '../../../services/appFunctionsService';
import CustomTable from '../../../components/datagrid/CustomTable';
import { IRight, Right } from '../../../@types/model/user/right';
import * as rightService from '../../../services/right/rightService';
import materialTheme from '../../../styles/materialTheme';
import { generalShowSuccessSnackbar, generalShowErrorSnackbar } from '../../../store/general/Functions';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import Screen from '../../../components/Screen';
import { createSelector } from 'reselect';
import { IRightFormValues, RightFormValues } from '../../../@types/model/user/rightFormValues';
import RightEditForm from './RightEditForm';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik } from 'formik';
import PillButton from '../../../components/input/PillButton';
import CustomTooltip from '../../../components/tooltip/tooltip';

interface IRightsListProps {
}

interface IRightsListState {
    isLoading : boolean;

    rightsList : Array<IRight>;
    editItem ?: IRight;
    deleteItem ?: IRight;
    isAdding : boolean;
    isEditing : boolean;
    isDeleting : boolean;
    copiedRight ?: IRight;
    useCopiedRightDetail : boolean;
}

class RightsList extends React.Component<IRightsListProps, IRightsListState> {
    constructor(props : IRightsListProps) {
        super(props);

        this.state = {
            isLoading: true,
            rightsList: [],
            editItem: undefined,
            deleteItem: undefined,
            isAdding: false,
            isEditing: false,
            isDeleting: false,
            useCopiedRightDetail: false,
        };
    }

    public componentDidMount = () => {
        this.loadData();
    };

    public loadData = () => {
        rightService.getList(undefined, undefined, undefined).then((result) => {
            this.setState({
                rightsList: result,
                isLoading: false,
            });
        });
    };

    private onAdd = () => {
        this.setState({ isAdding: true });
    };

    private onClose = () => {
        this.setState({
            isAdding: false,
            isEditing: false,
            editItem: undefined,
        });
        this.refresh();
    };

    private onDismiss = () => {
        this.setState({
            isAdding: false,
            isEditing: false,
            editItem: undefined,
            useCopiedRightDetail: false,
            copiedRight: undefined,
        });
    };

    private setEditItem = (item ?: IRight) => {
        this.setState({
            isEditing: true,
            editItem: item,
        });
    };

    private setDeleteItem = (item ?: IRight) => {
        this.setState({
            deleteItem: item,
            isDeleting: true,
        });
    };

    private refresh = () => {
        this.setState({
            isLoading: true,
        });

        this.loadData();
    };

    private getParentName = (parentId : number) => {
        const rights = this.state.rightsList;
        const parent = rights && rights.find(x => x.id === parentId);
        return parent ? parent.name : '';
    };

    private onDeleteYesClick = async () => {
        const newRight = this.state.deleteItem;

        if (newRight) {
            newRight.isActive = false;
            this.setState({ isLoading: true });
            try {
                const res = await rightService.addOrUpdateRights(newRight);

                if (res) {
                    this.onDeleteNoClick();

                    this.loadData();

                    generalShowSuccessSnackbar('Right deleted successfully.');
                }
            } catch (e) {
                newRight.isActive = true;
                this.onDeleteNoClick();
                generalShowErrorSnackbar('An error occurred deleting right.');
            } finally {
                this.setState({ isLoading: false });
            }
        }
    };

    private onDeleteNoClick = () => {
        this.setState({
            deleteItem: undefined,
            isDeleting: false,
        });
    };

    public onSubmit = async (value : IRightFormValues) => {
        this.setState({ isLoading: true });
        const newRight : Right = {
            ...value,
            parentId: Number(value.parent?.value),
            breadcrumbParentId: Number(value.breadcrumbParent?.value),
        };
        const res = await rightService.addOrUpdateRights(newRight);

        if (res) {
            generalShowSuccessSnackbar('Right saved successfully.');
            this.setState({ isLoading: false });
            this.onClose();
        }
    };

    private setCopiedRight = (row : IRight) => {
        const right = this.state.rightsList.find(x => x.id === row.id);

        if (right) {
            const rights = this.state.rightsList.filter(x => x.parentId === right?.parentId);

            const pageOrder = rights.map(x => x.pageOrder).reduce((a : number, b : number) => {
                return (a > b) ? a : b;
            });

            const sectionOrder = rights.map(x => x.sectionOrder).reduce((a : number, b : number) => {
                return (a > b) ? a : b;
            });

            const copiedDetail = {
                id: 0,
                guid: '',
                isActive: true,
                parentId : right.parentId,
                breadcrumbParentId : right.breadcrumbParentId,
                code : right.code,
                name : right.name,
                url : right.url,
                isMenu : right.isMenu,
                isMobile : right.isMobile,
                isPage : right.isPage,
                isOnNavDrawer : right.isOnNavDrawer,
                pageOrder : right.name === 'Home' || rights && rights.some(x => x.pageOrder !== null) ? pageOrder && pageOrder + 1 : right.pageOrder,
                sectionOrder : rights && rights.some(x => x.sectionOrder !== null) ? sectionOrder && sectionOrder + 1 : right.sectionOrder,
                reportURL : right.reportURL,
            };

            this.setState({ copiedRight: copiedDetail }, () => {
                this.setState({ useCopiedRightDetail: true });
            });
        }
    };

    public getSelectedRight = (props : IRightsListProps, state : IRightsListState) => state.editItem;
    public getCopiedRight = (props : IRightsListProps, state : IRightsListState) => state.copiedRight;
    public getIsAdding = (props : IRightsListProps, state : IRightsListState) => state.isAdding;
    public getUseCopiedRightDetail = (props : IRightsListProps, state : IRightsListState) => state.useCopiedRightDetail;
    public getRightsList = (props : IRightsListProps, state : IRightsListState) => state.rightsList;

    public getInitialFormValues = createSelector(
        [this.getSelectedRight, this.getCopiedRight, this.getRightsList, this.getUseCopiedRightDetail],
        (selectedRight, copiedRight, rightsList, useCopiedRightDetail) => {
            return new RightFormValues(useCopiedRightDetail ? copiedRight : selectedRight, rightsList.filter(x => x.isActive && x.id !== selectedRight?.id));
        },
    );

    public render() {
        const initialValues = this.getInitialFormValues(this.props, this.state);

        return (
            <Screen isPadded isScrollable={false} isLoading={this.state.isLoading}>
                <div className={'wfill hfill fdc'}>
                    <Paper className='hfill'>
                        <CustomTable<IRight>
                            enableEditing={() => true}
                            editColor={materialTheme.palette.primary.main}
                            editFunction={this.setEditItem}
                            enableAdding
                            addFunction={this.onAdd}
                            enableSorting
                            enableFiltering
                            enablePagination
                            enableRefresh
                            refreshFunction={this.refresh}
                            enableDeleting={() => true}
                            deleteColor={materialTheme.palette.primary.main}
                            deleteFunction={this.setDeleteItem}
                            isActive={(row : IRight) => row.isActive}
                            columns={[
                                { title: '', field: 'id',
                                    containerComponent: (row : IRight) => {
                                        return (
                                            <CustomTooltip title={'Copy right detail'}>
                                                <div className={'wfill hfill jcc'}>
                                                    <PillButton
                                                        className={'pl10 pr10 h35 zi0 w100 reducedPillButtonShadow'}
                                                        text={'Copy'}
                                                        type={'submit'}
                                                        color={'secondary'}
                                                        onClick={() => this.setCopiedRight(row)}
                                                    />
                                                </div>
                                            </CustomTooltip>
                                        );
                                    },
                                },
                                { title: 'Code', field: 'code', width: 200, enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', width: 200, enableFiltering: true, enableSorting: true },
                                { title: 'Parent', field: 'parentId', formatFunction: this.getParentName, enableFiltering: true, enableSorting: true },
                                { title: 'Breadcrumb Parent', field: 'breadcrumbParentId', formatFunction: this.getParentName, enableFiltering: true, enableSorting: true },
                                { title: 'Url', field: 'url', width: 200, enableFiltering: true, enableSorting: true },
                                { title: 'Page Order', field: 'pageOrder', enableFiltering: true, enableSorting: true },
                                { title: 'Section Order', field: 'sectionOrder', enableFiltering: true, enableSorting: true },
                                { title: 'Report Url', field: 'reportURL', enableFiltering: true, enableSorting: true },
                                { title: 'Menu?', field: 'isMenu', formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Mobile?', field: 'isMobile', formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'On Nav Drawer?', field: 'isOnNavDrawer', formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Page?', field: 'isPage', formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
                            ]}
                            rows={this.state.rightsList}
                            initialSortOrder={[{ columnName: 'createdOn_Created On', direction : 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                        />
                    </Paper>
                </div>
                <PackmanDialog
                    title={'Right'}
                    isEdit={!!this.state.editItem}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isAdding || this.state.isEditing || this.state.useCopiedRightDetail}
                    onClose={this.onDismiss}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onDismiss}
                        enableReinitialize
                        validationSchema={RightFormValues.formSchema}
                        component={RightEditForm} />
                </PackmanDialog >
                <ConfirmationPrompt title={'Delete Right'} open={this.state.isDeleting} message={'Are you sure you want to delete this right?'}
                    onOkClicked={this.onDeleteYesClick} onCancelClicked={this.onDeleteNoClick}/>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
)(RightsList);

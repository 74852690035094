import * as React from 'react';
import { Typography, IconButton, Icon } from '@mui/material';
import { IRootState, IAuthState } from '../../@types/redux';
import { connect } from 'react-redux';
import { Form, FormApi } from 'informed';
import { isEmptyObject, addArrayElement, setArrayElement } from '../../services/appFunctionsService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import uuidv1 from 'uuid';
import { dataSetOrderHeader } from '../../store/data/Functions';
import validationFunctions from '../../services/validationService';
import CustomAutoSuggest from '../../components/input/CustomAutoSuggest';
import { IDropDownOptions } from '../../@types/other';
import { IOrderPlan } from '../../@types/model/order/orderPlan';
import { IOrderRequest } from '../../@types/model/order/orderRequest';
import { IOrderHeader } from '../../@types/model/order/orderHeader';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ISite } from '../../@types/model/masterData/site/site';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import OrderHttpService from '../../services/http/order/orderHttpService';
import { ISize } from '../../@types/model/masterData/size/size';
import CustomTextInput from '../../components/input/CustomTextInput';

interface IOrderPlanningFormProps {
    selectedOrderPlan ?: IOrderPlan;
    orderRequest ?: IOrderRequest;
    orderHeaderData : Array<IOrderHeader>;
    organizations : Array<IOrganization>;
    sites : Array<ISite>;
    sizes : Array<ISize>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    colours : Array<IColour>;
    grades : Array<IGrade>;
    isEditing ?: boolean;
    isAdding ?: boolean;
    onClose : () => void;
    auth : IAuthState;
}
interface IOrderPlanningFormState {
    isLoading : boolean;
    selectedCommodityId : number;
    selectedPack ?: IPack;
}

class OrderPlanningForm extends React.Component<IOrderPlanningFormProps, IOrderPlanningFormState> {
    private formApi : FormApi<IOrderPlan>;
    constructor(props : IOrderPlanningFormProps) {
        super(props);

        this.state = {
            isLoading: false,
            selectedCommodityId: 0,
        };

    }

    public componentDidMount = () => {
        const plan = this.props.selectedOrderPlan;
        this.setState({
            selectedCommodityId: plan ? plan.commodityId : 0,
            selectedPack: plan && plan.pack,
        });
    };

    private close = () => {
        this.formApi.reset();
        this.props.onClose();
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    private submit = async () => {
        const values = this.formApi.getState().values;
        const touched = this.formApi.getState().touched;
        const valid = !this.formApi.getState().invalid;

        const existingOrderPlan = this.props.selectedOrderPlan;
        const orderHeader = this.getOrderHeader();
        const orderRequest = this.props.orderRequest;
        if (!valid) {
            generalShowErrorSnackbar('Check form validation!');
            return;
        }

        if (!orderHeader || !orderRequest) {
            generalShowErrorSnackbar('Order header or request data missing!');
            return;
        }

        if (isEmptyObject(touched)) {
            if (this.props.isEditing) {
                generalShowErrorSnackbar('Nothing to update!');
            } else if (this.props.isAdding) {
                generalShowErrorSnackbar('Nothing to add!');
            }
            return;
        }

        if (!isEmptyObject(touched)) {
            const newRowModel : IOrderPlan = { ...values };
            if (this.props.isEditing && this.props.selectedOrderPlan) {
                newRowModel.id = this.props.selectedOrderPlan.id;
                newRowModel.orderRequestId = orderRequest.id;
                newRowModel.guid = existingOrderPlan ? existingOrderPlan.guid : uuidv1();
                newRowModel.isNew = true;
                newRowModel.isActive = true; // TODO: temporary, add delete functionality
            } else if (this.props.isEditing && !this.props.selectedOrderPlan) {
                generalShowErrorSnackbar('No order plan selected!');
                return;
            }

            if (this.props.isAdding) {
                newRowModel.guid = uuidv1();
                newRowModel.id = 0;
                newRowModel.orderRequestId = orderRequest.id;
                newRowModel.isNew = true;
                newRowModel.isActive = true;
            }
            this.setLoading(true);
            newRowModel.quantity = Number(newRowModel.quantity);
            const orderPlanIndex = orderRequest.orderPlannedLines.findIndex(x => newRowModel.id !== 0 && x.id === newRowModel.id);
            orderRequest.orderPlannedLines = orderPlanIndex === -1 ? addArrayElement(orderRequest.orderPlannedLines, newRowModel, 'end') : setArrayElement(orderRequest.orderPlannedLines, orderPlanIndex, newRowModel);
            const orderRequestIndex = orderHeader.orderRequestLines.findIndex(x => orderRequest.id !== 0 && x.id === orderRequest.id);
            if (orderRequestIndex === -1) {
                generalShowErrorSnackbar('Order request data missing!');
                return;
            }
            orderHeader.orderRequestLines = setArrayElement(orderHeader.orderRequestLines, orderRequestIndex, orderRequest);
            orderHeader.status = 'Planned';
            try {
                const res = await OrderHttpService.addOrUpdateOrderHeader(orderHeader);

                if (res && res.data) {
                    dataSetOrderHeader(res.data);
                    if (this.props.isEditing) {
                        generalShowSuccessSnackbar('Order Plan updated successfully.');
                        this.setLoading(false);
                        this.close();
                    } else {
                        generalShowSuccessSnackbar('Order Plan added successfully.');
                        this.setLoading(false);
                        this.close();
                    }
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred updating order plan data.');
                this.setLoading(false);
            }
        }
    };

    private getOrderHeader = () => this.props.orderHeaderData.find(x => x.id === (this.props.orderRequest && this.props.orderRequest.orderId));

    private setFormApi = (formApi : FormApi<IOrderPlan>) => {
        this.formApi = formApi;
    };

    private commodityOptions = () : Array<IDropDownOptions> => {
        const orderHeader = this.getOrderHeader();
        const organization = orderHeader && orderHeader.supplierId;
        if (!this.props.commodities || !organization) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Commodity', disabled: true });
        this.props.commodities.filter(x => x.organizationIds.some(y => y === organization) && x.isActive)
            .forEach(x => returnValue.push({ value: x.id, label: x.code + '-' + x.name }));
        return returnValue;
    };

    private varietyOptions = () : Array<IDropDownOptions> => {
        const orderHeader = this.getOrderHeader();
        const organization = orderHeader && orderHeader.supplierId;
        const commodity = this.state.selectedCommodityId;
        if (!this.props.varieties || !organization || !commodity) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Variety', disabled: true });
        this.props.varieties.filter(x => x.isActive && x.commodityId === Number(commodity)).forEach(x => returnValue.push({ value: x.id, label: x.code + '-' + x.name }));
        return returnValue;
    };

    private packOptions = () : Array<IDropDownOptions> => {
        const orderHeader = this.getOrderHeader();
        const organization = orderHeader && orderHeader.supplierId;
        const commodity = this.state.selectedCommodityId;
        if (!this.props.packs || !organization || !commodity) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Pack', disabled: true });
        this.props.packs.filter(x => x.isActive && x.commodityId === Number(commodity))
            .forEach(x => returnValue.push({ value: x.id, label: x.code + '-' + x.description }));
        return returnValue;
    };

    private sizeOptions = () : Array<IDropDownOptions> => {
        const pack = this.state.selectedPack;
        if (!pack || (pack && !pack.sizeIds)) {
            return [];
        }

        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Size', disabled: true });

        this.props.sizes
            .filter(x => pack.sizeIds.some(y => y === x.id))
            .filter(x => x.isActive)
            .forEach(x => returnValue
                .push({ value: x.id, label: x.code + '-' + x.name }));
        return returnValue;
    };

    private gradeOptions = () : Array<IDropDownOptions> => {
        const orderHeader = this.getOrderHeader();
        const organization = orderHeader && orderHeader.supplierId;
        const commodity = this.state.selectedCommodityId;
        if (!this.props.grades || !organization || !commodity) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Grade', disabled: true });
        this.props.grades.filter(x => x.isActive && x.commodityIds?.some(y => y === Number(commodity)))
            .forEach(x => returnValue.push({ value: x.id, label: x.code + '-' + x.name }));
        return returnValue;
    };

    private colourOptions = () : Array<IDropDownOptions> => {
        const orderHeader = this.getOrderHeader();
        const organization = orderHeader && orderHeader.supplierId;
        const commodity = this.state.selectedCommodityId;
        if (!this.props.colours || !organization || !commodity) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Colour', disabled: true });
        this.props.colours.filter(x => x.isActive && x.commodityIds?.some(y => y === Number(commodity)))
            .forEach(x => returnValue.push({ value: x.id, label: x.code + '-' + x.name }));
        return returnValue;
    };

    private siteOptions = (type : 'source' | 'destination') : Array<IDropDownOptions> => {
        const orderHeader = this.getOrderHeader();
        const organization = orderHeader && (type === 'source' ? orderHeader.supplierId : orderHeader.clientId);
        if (!this.props.sites || !organization) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: type === 'source' ? 'Select Source Site' : 'Select Destination Site', disabled: true });
        this.props.sites.filter(x => x.organizationIds.some(y => y  === organization) && (type === 'source' ? x.isPackhouse : x.isDeliveryPoint) && x.isActive)
            .forEach(x => returnValue.push({ value: x.id, label: x.code + '-' + x.description }));
        return returnValue;
    };

    private setSelectedCommodityId = (value : number) => this.setState({ selectedCommodityId: value });

    private setSelectedPackId = (packId : number) =>  {
        const pack = this.props.packs.find(x => x.id === packId);
        this.setState({ selectedPack: pack });
    };

    public render() {
        const selectedOrderPlan = this.props.selectedOrderPlan;
        return <div>
            <Form getApi={this.setFormApi}>
                <div className={'fdr mb10'}>
                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                        <CustomAutoSuggest
                            initialValue={selectedOrderPlan ? selectedOrderPlan.commodityId : 0}
                            hasInitialValue={!!(selectedOrderPlan && selectedOrderPlan.commodityId)}
                            className={'flx1'}
                            label={'Commodity'}
                            field={'commodityId'}
                            id={'order_planning_commodityId'}
                            options={this.commodityOptions()}
                            onValueChange={value => this.setSelectedCommodityId(Number(value))}
                            placeholder={'Select Commodity...'}
                            validate={validationFunctions.required}
                            validateOnMount
                            validateOnChange
                            validateOnBlur
                        />
                    </Typography>
                    <span className={'w10'} />
                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                        <CustomAutoSuggest
                            initialValue={selectedOrderPlan ? selectedOrderPlan.varietyId : 0}
                            hasInitialValue={!!(selectedOrderPlan && selectedOrderPlan.varietyId)}
                            className={'flx1'}
                            field={'varietyId'}
                            id={'order_planning_varietyId'}
                            label={'Variety'}
                            options={this.varietyOptions()}
                            placeholder={'Select Variety...'}
                            validate={validationFunctions.required}
                            validateOnMount
                            validateOnChange
                            validateOnBlur
                        />
                    </Typography>
                    <span className={'w10'} />
                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                        <CustomAutoSuggest
                            initialValue={selectedOrderPlan ? selectedOrderPlan.packId : 0}
                            hasInitialValue={!!(selectedOrderPlan && selectedOrderPlan.packId)}
                            className={'flx1'}
                            field={'packId'}
                            id={'order_planning_packId'}
                            label={'Pack'}
                            options={this.packOptions()}
                            placeholder={'Select Pack...'}
                            onValueChange={value => this.setSelectedPackId(Number(value))}
                            validate={validationFunctions.required}
                            validateOnMount
                            validateOnChange
                            validateOnBlur
                        />
                    </Typography>
                    <span className={'w10'} />
                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                        <CustomAutoSuggest
                            initialValue={selectedOrderPlan ? selectedOrderPlan.sizeId : 0}
                            hasInitialValue={!!(selectedOrderPlan && selectedOrderPlan.sizeId)}
                            className={'flx1'}
                            field={'sizeId'}
                            id={'order_planning_sizeId'}
                            label={'Size'}
                            options={this.sizeOptions()}
                            placeholder={'Select Size...'}
                            validate={validationFunctions.required}
                            validateOnMount
                            validateOnChange
                            validateOnBlur
                        />
                    </Typography>
                    <span className={'w10'} />
                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                        <CustomAutoSuggest
                            initialValue={selectedOrderPlan ? selectedOrderPlan.gradeId : 0}
                            hasInitialValue={!!(selectedOrderPlan && selectedOrderPlan.gradeId)}
                            className={'flx1'}
                            field={'gradeId'}
                            id={'order_planning_gradeId'}
                            label={'Grade'}
                            options={this.gradeOptions()}
                            placeholder={'Select Grade...'}
                            validate={validationFunctions.required}
                            validateOnMount
                            validateOnChange
                            validateOnBlur
                        />
                    </Typography>
                    <span className={'w10'} />
                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                        <CustomAutoSuggest
                            initialValue={selectedOrderPlan ? selectedOrderPlan.colourId : 0}
                            hasInitialValue={!!(selectedOrderPlan && selectedOrderPlan.colourId)}
                            className={'flx1'}
                            field={'colourId'}
                            id={'order_planning_colourId'}
                            label={'Colour'}
                            options={this.colourOptions()}
                            placeholder={'Select Colour...'}
                            validate={validationFunctions.required}
                            validateOnMount
                            validateOnChange
                            validateOnBlur
                        />
                    </Typography>
                    <span className={'w10'} />
                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                        <CustomAutoSuggest
                            initialValue={selectedOrderPlan ? selectedOrderPlan.siteSourceId : 0}
                            hasInitialValue={!!(selectedOrderPlan && selectedOrderPlan.siteSourceId)}
                            className={'flx1'}
                            field={'siteSourceId'}
                            id={'order_planning_siteSourceId'}
                            label={'Source Site'}
                            options={this.siteOptions('source')}
                            placeholder={'Select Source Site...'}
                            validate={validationFunctions.required}
                            validateOnMount
                            validateOnChange
                            validateOnBlur
                        />
                    </Typography>
                    <span className={'w10'} />
                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                        <CustomAutoSuggest
                            initialValue={selectedOrderPlan ? selectedOrderPlan.siteDestinationId : 0}
                            hasInitialValue={!!(selectedOrderPlan && selectedOrderPlan.siteDestinationId)}
                            className={'flx1'}
                            field={'siteDestinationId'}
                            id={'order_planning_siteDestinationId'}
                            label={'Destination Site'}
                            options={this.siteOptions('destination')}
                            placeholder={'Select Destination Site...'}
                            validate={validationFunctions.required}
                            validateOnMount
                            validateOnChange
                            validateOnBlur
                        />
                    </Typography>
                    <span className={'w10'} />
                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                        <CustomTextInput
                            className={'flx1'}
                            type='number'
                            field={'quantity'}
                            label={'Planned Quantity'}
                            initialValue={selectedOrderPlan ? Number(selectedOrderPlan.quantity) : 0}
                        />
                    </Typography>
                </div>
                <div className={'fdr p20'}><div className={'flx1'}/>
                    <IconButton className={'cw h50 bcp'} onClick={this.submit}>
                        <Icon>done</Icon>
                    </IconButton>
                </div>
            </Form>
        </div>;
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth : state.auth,
        orderHeaderData: state.data.orderHeaders,
        organizations: state.masterData.organizations,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        colours: state.masterData.colours,
        grades: state.masterData.grades,
        sites: state.masterData.sites,
        sizes: state.masterData.sizes,
    };
};

export default connect(
    mapStateToProps,
)(OrderPlanningForm);

import * as React from 'react';
import { FieldProps, Field } from 'formik';
import { AutocompleteValueType } from '../../../@types/helper';
import { Omit } from '@material-ui/types';
import AutocompleteSelect, { IAutocompleteSelectProps } from '../AutoCompleteSelect';
import { getFormikErrorMessage } from '../../../services/appFunctionsService';

type FormAutocompleteSelectProps = Omit<IAutocompleteSelectProps,
'value'
| 'onChange'
| 'onBlur'> & {
    onChange ?: (v : AutocompleteValueType, fieldProps : FieldProps) => void;
    onBlur ?: (e : React.FocusEvent<HTMLDivElement>, fieldProps : FieldProps) => void;
    className ?: string;
};

interface IFormAutocompleteSelectState {
}

export default class FormAutocompleteSelect extends React.Component<FormAutocompleteSelectProps, IFormAutocompleteSelectState> {
    constructor(props : FormAutocompleteSelectProps) {
        super(props);
    }

    public onChange = (e : React.ChangeEvent<{}>, v : AutocompleteValueType , fieldProps : FieldProps) => {
        let value = v;

        /*
         * In some circumstances, the value ends up as a string in freeSolo mode. This ensures that we wrap it in an
         * IOptionType before it goes into the form.
         */
        if (typeof value === 'string') {
            value = { label: value, value: -1 };
        }

        fieldProps.form.setFieldValue(fieldProps.field.name, value);
        if (this.props.onChange) this.props.onChange(value, fieldProps);
    };

    public onBlur = (e : React.FocusEvent<HTMLDivElement>, fieldProps : FieldProps) => {
        fieldProps.field.onBlur(e);
        if (this.props.onBlur) this.props.onBlur(e, fieldProps);
    };

    public renderInput = (fieldProps : FieldProps) => {
        const error = getFormikErrorMessage(fieldProps.form.errors, fieldProps.field.name);
        return (
            <AutocompleteSelect
                {...this.props}
                onChange={(e, v) => this.onChange(e, v, fieldProps)}
                onBlur={e => this.onBlur(e, fieldProps)}
                value={fieldProps.field.value ?? (this.props.isMulti ? [] : undefined)}
                hasError={!!error}
                errorMargin
                className={this.props.className}
                errorMessage={error ?? ''}
            />
        );
    };

    public render() {
        return (<Field name={this.props.name} render={this.renderInput} />);
    }
}

import { IStorageUnit } from './storageUnit';
import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { ISite } from '../site/site';

export interface IStorageUnitFormValues {
    id : number;
    guid : string;
    code : string;
    description : string;
    isTippable : boolean;
    hasRawStock : boolean;
    hasPackedStock : boolean;
    site ?: IOptionType;
    latitude ?: number | string;
    longitude ?: number | string;
    isActive : boolean;
}

export class StorageUnitFormValues implements IStorageUnitFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public description : string;
    public isTippable : boolean;
    public hasRawStock : boolean;
    public hasPackedStock : boolean;
    public site ?: IOptionType;
    public latitude ?: number | string;
    public longitude ?: number | string;
    public isActive : boolean;

    public constructor(
        storageUnit ?: IStorageUnit,
        sites ?: Array<ISite>,
        site ?: ISite,
    ) {
        this.initializeDefaultValues(site);
        if (storageUnit) {
            this.id = storageUnit.id;
            this.guid = storageUnit.guid;
            this.code = storageUnit.code;
            this.description = storageUnit.description;
            this.latitude = storageUnit.latitude;
            this.longitude = storageUnit.longitude;
            this.isTippable = storageUnit.isTippable;
            this.hasRawStock = storageUnit.hasRawStock;
            this.hasPackedStock = storageUnit.hasPackedStock;
            this.site = sites ? {
                label: sites.find(x => x.id === storageUnit.siteId)?.description ?? '',
                value: storageUnit.siteId,
            } : undefined;
            this.isActive = storageUnit.isActive;
        }
    }

    public initializeDefaultValues = (site ?: ISite) => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.description = '';
        this.isTippable = false;
        this.hasRawStock = false;
        this.hasPackedStock = false;
        this.site = site ? {
            label: `${site.code} - ${site.description}`,
            value: site.id,
        } : undefined;
        this.latitude = '';
        this.longitude = '';
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        site: Yup.object().nullable().required('Required'),
        isTippable: Yup.boolean().required('Required'),
        hasRawStock: Yup.boolean().required('Required'),
        hasPackedStock: Yup.boolean().required('Required'),
        latitude: Yup.number().required('Required'),
        longitude: Yup.number().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}

import * as React from 'react';
import CustomTable from '../../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetVarieties } from '../../../../store/masterData/Actions';
import { formatDateTime, upsertArrayElement } from '../../../../services/appFunctionsService';
import { IVariety, Variety } from '../../../../@types/model/masterData/variety/variety';
import { ICommodity } from '../../../../@types/model/masterData/commodity/commodity';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../../store/general/Functions';
import VarietyHttpService from '../../../../services/http/masterData/varietyHttpService';
import PackmanDialog from '../../../../components/dialog/PackmanDialog';
import { Formik, FormikActions } from 'formik';
import { createSelector } from 'reselect';
import VarietyForm from './form/VarietyForm';
import { IVarietyFormValues, VarietyFormValues } from '../../../../@types/model/masterData/variety/varietyFormValues';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../../@types/model/user/right';
import ConfirmationPrompt from '../../../../components/dialog/ConfirmationPrompt';
import { setVarietyMasterDataIndexedDB, syncMasterData } from '../../../../services/masterDataSyncService';

interface IVarietyScreenProps extends RouteComponentProps {
    dataSetVarieties : DispatchCall<Array<IVariety>>;
    varietyData : Array<IVariety>;
    selectedCommodity ?: ICommodity;
    commodities ?: Array<ICommodity>;
    auth : IAuthState;
}

interface IVarietyScreenState {
    rows : Array<IVariety>;
    isLoading : boolean;
    selectedVariety ?: IVariety;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingVariety ?: IVariety;
}

class VarietyScreen extends React.Component<IVarietyScreenProps, IVarietyScreenState> {
    constructor(props : IVarietyScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedVariety: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);

            try {
                const res = await VarietyHttpService.getVarietyData();

                this.props.dataSetVarieties(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading varieties.');
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    public onSubmit = async (values : IVarietyFormValues) => {
        this.setLoading(true);

        try {
            const res = await VarietyHttpService.addOrUpdateVariety(new Variety(values));

            const newVarietyList = upsertArrayElement(this.props.varietyData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetVarieties(newVarietyList);
            await setVarietyMasterDataIndexedDB(newVarietyList);

            if (this.state.selectedVariety) {
                generalShowSuccessSnackbar('Variety updated successfully.');
            } else {
                generalShowSuccessSnackbar('Variety added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating variety data.');
        } finally {
            this.setLoading(false);
        }
    };

    public onReset = async (formValues : IVarietyFormValues, formikActions : FormikActions<IVarietyFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    private openDeleteConfirmationPopup = (variety : IVariety) => {
        this.setState({ isDeletePopupOpen: true, deletingVariety: variety });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingVariety: undefined });
    };

    private removeVariety = async () => {
        const newVariety = this.state.deletingVariety;
        if (newVariety) {
            newVariety.isActive = false;
            this.setLoading(true);

            try {
                const res = await VarietyHttpService.deleteVariety(newVariety.id);

                const newVarietyList = upsertArrayElement(this.props.varietyData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetVarieties(newVarietyList);
                await setVarietyMasterDataIndexedDB(newVarietyList);

                generalShowSuccessSnackbar('Variety updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting variety.');
                newVariety.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    public getSelectedVariety = (props : IVarietyScreenProps, state : IVarietyScreenState) => state.selectedVariety;
    public getSelectedCommodity = (props : IVarietyScreenProps) => props.selectedCommodity;
    public getCommodities = (props : IVarietyScreenProps) => props.commodities;

    public getInitialFormValues = createSelector(
        [this.getSelectedVariety, this.getCommodities, this.getSelectedCommodity],
        (variety : IVariety, commodities ?: Array<ICommodity>, commodity ?: ICommodity) => {
            return new VarietyFormValues(variety, commodities, commodity);
        },
    );

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedVariety: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    private onVarietyEdit = (row : IVariety) => {
        this.setState({
            isDialogOpen: true,
            selectedVariety: row,
        });
    };

    private getRights = (props : IVarietyScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IVarietyScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const selectedCommodity = this.props.selectedCommodity;
        const rows = selectedCommodity && this.props.varietyData ? this.props.varietyData.filter(x => x.commodityId === selectedCommodity?.id) : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);

        return (
            <>
                <PackmanDialog
                    title={'Variety' + (this.props.selectedCommodity ? ' - (' + this.props.selectedCommodity?.code + ')' : '')}
                    isEdit={!!this.state.selectedVariety}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={VarietyFormValues.formSchema}
                        component={VarietyForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IVariety>
                            enableAdding={!!this.props.selectedCommodity && this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.onVarietyEdit}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(variety : IVariety) => variety.isActive && this.hasEditingRight(this.props) && !variety.isImported}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Imported?', field: 'isImported', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            fitWidthToPage
                            initialSortOrder={[{ columnName: 'id_Id', direction : 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={340}
                            isActive={(row : IVariety) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Variety'}
                    open={this.state.isDeletePopupOpen}
                    isLoading={this.state.isLoading}
                    message={'Are you sure you want to delete this variety?'}
                    onOkClicked={this.removeVariety}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        varietyData: state.masterData.varieties,
        commodities: state.masterData.commodities,
        auth: state.auth,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetVarieties }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VarietyScreen);

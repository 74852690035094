import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IIntake } from '../../../@types/model/intake/intake';
import { IIntakeRelatedData } from '../../../@types/model/intake/intakeRelatedData';
import qs from 'qs';
import { IIntakeView } from '../../../@types/model/intake/intakeView';
import { IIntakeSummaryRelatedData } from '../../../@types/model/intake/intakeSummaryRelatedData';
import { IIntakeViewRelatedData } from '../../../@types/model/intake/intakeViewRelatedData';
import { IIntakeAppView } from '../../../@types/model/intake/intakeAppView';

export default class IntakeHttpService {
    public static getIntakeData() : AxiosPromise<Array<IIntake>> {
        return axios.get(`${PACKMAN_API_URL}Intake`);
    }

    public static getIntakeAppView(intakeGuid : string) : AxiosPromise<IIntakeAppView> {
        return axios.get(`${PACKMAN_API_URL}Intake/GetAppIntake`, {
            params: {
                intakeGuid,
            },
        });
    }

    public static addOrUpdateIntake(data : IIntake) : AxiosPromise<IIntakeView> {
        return axios.post(`${PACKMAN_API_URL}Intake/PostIntake`, data);
    }

    public static deleteIntake(intakeId : number, deleteReason : string) : AxiosPromise<IIntakeView> {
        return axios.delete(`${PACKMAN_API_URL}Intake/Delete`, {
            params : {
                intakeId,
                deleteReason,
            },
        });
    }

    public static getIntakeTransactionData(fromDateUnix ?: number, toDateUnix ?: number, siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<IIntakeRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Intake/WebTransactions`, {
            params: {
                fromDateUnix,
                toDateUnix,
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getIntakeTransactionViewData(fromDateUnix ?: number, toDateUnix ?: number, siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<IIntakeViewRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Intake/WebTransactionViews`, {
            params: {
                fromDateUnix,
                toDateUnix,
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getIntakeSummaryData(intakeId : number, loadMasterData ?: boolean) : AxiosPromise<IIntakeSummaryRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Intake/SummaryRelatedData`, {
            params: { intakeId, loadMasterData },
        });
    }

    public static getIntake(intakeId : number) : AxiosPromise<IIntake> {
        return axios.get(`${PACKMAN_API_URL}Intake/GetIntake`, {
            params: { intakeId },
        });
    }
}

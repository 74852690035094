import { IBaseModel } from '../../../model';
import { IPack } from '../pack/pack';
import { ISizeFormValues } from './sizeFormValues';

export interface ISize extends IBaseModel {
    code : string;
    name : string;
    packIds : Array<number>;
    packs : Array<IPack>;
}

export class Size implements ISize {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public name : string;
    public packIds : Array<number>;
    public packs : Array<IPack>;

    public constructor(formValues : ISizeFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.code = formValues.code;
        this.name = formValues.name;
        this.packIds = formValues.packs.map(x => Number(x.value));
    }
}

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { ICompliance } from '../../../@types/model/compliance/compliance';
import { IDispatchCompliance } from '../../../@types/model/dispatch/dispatchCompliance';
import { IStockRelatedData } from '../../../@types/model/stock/stockRelatedData';
import qs from 'qs';
import { IComplianceStatus } from '../../../@types/model/compliance/complianceStatus';
import { ICreateWizardCompliance } from '../../../@types/model/compliance/createWizardcompliance';
import { IComplianceAudit } from '../../../@types/model/compliance/complianceAudit';
import { VERSION } from '../../../../version';

export default class ComplianceHttpService {
    public static getComplianceData() : AxiosPromise<Array<ICompliance>> {
        return axios.get(`${PACKMAN_API_URL}Compliance/GetAllWeb`);
    }
    public static getCompliance(complianceId : number) : AxiosPromise<ICompliance> {
        return axios.get(`${PACKMAN_API_URL}Compliance/Get`, {
            params: { complianceId },
        });
    }

    public static getComplianceLatestAudit(complianceId : number) : AxiosPromise<IComplianceAudit> {
        return axios.get(`${PACKMAN_API_URL}Compliance/GetLatestAudit`, {
            params: { complianceId },
        });
    }

    public static getComplianceAudits(complianceId ?: number) : AxiosPromise<Array<IComplianceAudit>> {
        return axios.get(`${PACKMAN_API_URL}Compliance/GetComplianceAudits`, {
            params: {
                complianceId,
            },
        });
    }

    public static getActiveCompliancesByStock(stockIds ?: Array<number>) : AxiosPromise<Array<ICompliance>> {
        return axios.get(`${PACKMAN_API_URL}Compliance/GetActiveCompliancesByStock`, {
            params: { stockIds },
            paramsSerializer: qs.stringify,
        });
    }

    public static getMultiDispatchWizardComplianceData(siteIds ?: Array<number>) : AxiosPromise<Array<ICompliance>> {
        return axios.get(`${PACKMAN_API_URL}Compliance/GetMultiDispatchWizardComplianceData`, {
            params: { siteIds },
            paramsSerializer: qs.stringify,
        });
    }

    public static addOrUpdateCompliance(data : ICompliance) : AxiosPromise<ICompliance> {
        return axios.post(`${PACKMAN_API_URL}Compliance/PostCompliance`, data);
    }

    public static createWizardCompliance(data : ICreateWizardCompliance) : AxiosPromise<ICompliance> {
        return axios.post(`${PACKMAN_API_URL}Compliance/CreateWizardCompliance`, data);
    }

    public static deleteCompliance(complianceId : number, deleteReason : string) : AxiosPromise<ICompliance> {
        return axios.delete(`${PACKMAN_API_URL}Compliance/Delete`, {
            params : { complianceId, deleteReason },
        });
    }

    public static setComplianceStatus(data : IComplianceStatus) : AxiosPromise<boolean> {
        return axios.post(`${PACKMAN_API_URL}Compliance/SetComplianceStatus`, data);
    }

    public static updateComplianceAfterPPECBConsignmentDelete(data : IComplianceStatus) : AxiosPromise<boolean> {
        return axios.post(`${PACKMAN_API_URL}Compliance/updateComplianceAfterPPECBConsignmentDelete`, data);
    }

    public static addOrRemoveDispatchOnCompliance(data : IDispatchCompliance) : AxiosPromise<ICompliance> {
        return axios.post(`${PACKMAN_API_URL}Compliance/Dispatch`, data);
    }
    public static getComplianceDashboardData(fromDateUnix ?: number, toDateUnix ?: number, barcode ?: string, siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<IStockRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Compliance/Dashboard`, {
            params: {
                fromDateUnix,
                toDateUnix,
                barcode,
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }
    public static getComplianceWizardStockData(fromDateUnix ?: number, toDateUnix ?: number, siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<IStockRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Compliance/WizardStock`, {
            params: {
                fromDateUnix,
                toDateUnix,
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }
    public static getComplianceWizardDispatchData(fromDateUnix ?: number, toDateUnix ?: number, siteIds ?: Array<number>, loadMasterData ?: boolean) : AxiosPromise<IStockRelatedData> {
        return axios.get(`${PACKMAN_API_URL}Compliance/WizardDispatch`, {
            params: {
                fromDateUnix,
                toDateUnix,
                siteIds,
                loadMasterData,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static getPIFileDataForDownload(complianceId : number) : AxiosPromise<{ fileName : string; fileContent : string }> {
        const version = VERSION.version;
        return axios.get(`${PACKMAN_API_URL}Compliance/GetPIFileDataForDownload`, {
            params: {
                complianceId,
                version,
            },
        });
    }

    public static setPIDownloadedFlag(data : { complianceId : number }) : AxiosPromise<ICompliance> {
        return axios.post(`${PACKMAN_API_URL}Compliance/SetPIDownloaded`, data);
    }
}

import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { CommodityConditionFormValues } from '../../../../@types/model/masterData/commodityCondition/commodityConditionFormValues';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import { connect } from 'react-redux';
import { IOptionType } from '../../../../@types/helper';
import { createSelector } from 'reselect';
import { IRootState } from '../../../../@types/redux';
import { ICommodity } from '../../../../@types/model/masterData/commodity/commodity';
import FormAutocompleteSelect from '../../../../components/input/form/FormAutoCompleteSelect';

interface ICommodityConditionFormProps {
    isLoading : boolean;
    commodityOptions : Array<IOptionType>;
}

interface ICommodityConditionFormState {
}

type CommodityConditionFormPropsType = ICommodityConditionFormProps & FormikProps<CommodityConditionFormValues>;

class CommodityConditionForm extends React.Component<CommodityConditionFormPropsType, ICommodityConditionFormState> {

    constructor(props : CommodityConditionFormPropsType) {
        super(props);
    }

    public render() {
        return (
            <Form className={'p20'}>
                <FormTextInput name={'condition'} label={'Condition'} multiline rows={3}/>
                {   this.props.values.isMasterView &&
                    <FormAutocompleteSelect
                        name={'selectedCommodities'}
                        label={'Commodities'}
                        options={this.props.commodityOptions}
                        isMulti
                    />
                }
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text' color='primary'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const getCommodities = (state : IRootState) => state.masterData.commodities;

const getCommodityOptions = createSelector(
    [getCommodities],
    (commodities : Array<ICommodity>) => {
        return commodities?.filter(x => x.isActive).map(x => ({ label: `${x.code} - ${x.name}`, value: x.id })) ?? [];
    },
);

const mapStateToProps = (state : IRootState) => {
    return {
        commodityOptions: getCommodityOptions(state),
    };
};

export default connect(
    mapStateToProps,
)(CommodityConditionForm);

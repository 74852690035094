import * as React from 'react';
import CustomTable from '../../components/table/CustomTable';
import { CircularProgress, IconButton } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction } from '../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { uppercase, booleanToYesNo, formatDateTimeToDateOnly, compareDate, removeArrayElement, addArrayElement } from '../../services/appFunctionsService';
import OrderRequestForm from './OrderRequestScreen';
import OrderPlanningScreen from './OrderPlanningScreen';
import { IOrderHeader } from '../../@types/model/order/orderHeader';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ISite } from '../../@types/model/masterData/site/site';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { ISize } from '../../@types/model/masterData/size/size';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { IOrderRequest } from '../../@types/model/order/orderRequest';
import { IOpenOrder } from '../../@types/model/order/openOrder';
import { IOrderPlan } from '../../@types/model/order/orderPlan';
import CustomTooltip from '../../components/tooltip/tooltip';
import { getIconLocation } from '../../services/iconHelperService';

interface IOpenOrdersTableProps {
    dataFetched : boolean;
    refreshData : (noAnnounce ?: boolean) => void;
    isLoading : boolean;
    orderHeaders : Array<IOrderHeader>;
    organizations : Array<IOrganization>;
    sites : Array<ISite>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    sizes : Array<ISize>;
    grades : Array<IGrade>;
    colours : Array<IColour>;
    // stock : Array<IStock>;
}

interface IOpenOrdersTableState {
    selectedOrder ?: IOrderHeader;
    selectedOrderRequest ?: IOrderRequest;
    isAdding : boolean;
    isEditing : boolean;
    selectedStatusses : Array<'pending' | 'planned' | 'assigned' | 'completed'>;
    rows : Array<IOpenOrder>;
}

class OpenOrdersTable extends React.Component<IOpenOrdersTableProps, IOpenOrdersTableState> {
    constructor(props : IOpenOrdersTableProps) {
        super(props);

        this.state = {
            selectedOrder: undefined,
            isAdding: false,
            isEditing: false,
            rows: [],
            selectedStatusses: ['pending', 'planned', 'assigned'],
        };
    }

    public componentDidUpdate = (prevProps : IOpenOrdersTableProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps && !prevProps.dataFetched && nextProps.dataFetched) {
            this.setState({ rows: this.mapOpenOrderData() });
        }
    };

    private refreshData = (noAnnounce ?: boolean) => this.props.refreshData(noAnnounce);

    private onOrderEdit = (row : IOpenOrder) => {
        this.setState({ isEditing: true, selectedOrder: row && this.getOrderHeader(row.orderHeaderId) });
    };

    private getOrderHeader = (id : number) => this.props.orderHeaders.find(x => x.id === id);

    private onOrderAdd = () => {
        this.setState({ isAdding: true });
    };

    private onOrderDetail = (row : IOpenOrder) => this.setState({ selectedOrderRequest: this.getOrderRequest(row.orderHeaderId, row.orderRequestId) });

    private getOrderRequest = (headerId : number, requestId : number) => {
        const header = this.props.orderHeaders.find(x => x.id === headerId);
        return header && header.orderRequestLines.find(x => x.id === requestId);
    };

    private onFormClose = () => {
        this.refreshData(true);
        this.setState({
            isAdding: false,
            isEditing: false,
            selectedOrder: undefined,
            rows: this.mapOpenOrderData(),
        });
    };

    private onOrderPlanningClose = () => {
        this.setState({
            selectedOrderRequest: undefined,
            rows: this.mapOpenOrderData(),
        });
    };

    private mapOpenOrderData = () => {
        if (!this.props.orderHeaders || this.props.orderHeaders.length === 0) {
            return [];
        }
        const openOrders : Array<IOpenOrder> = [];
        this.props.orderHeaders.forEach((x) => {
            const requests = x.orderRequestLines;
            if (requests.length === 0) {
                openOrders.push({
                    orderHeaderId: x.id,
                    orderRequestId: 0,
                    orderPlanId: 0,
                    orderNumber: x.orderNumber,
                    orderDate: x.orderDate,
                    clientId: x.clientId,
                    supplierId: x.supplierId,
                    status: x.status,
                    comment: x.comment,
                    siteSourceId: 0,
                    siteDestinationId: 0,
                    commodityId: 0,
                    varietyId: 0,
                    packId: 0,
                    sizeId: 0,
                    gradeId: 0,
                    colourId: 0,
                    requestedQuantity: 0,
                    plannedQuantity: 0,
                    actualQuantity: 0, // TODO: orders at stock?
                    isNew: true,
                });
            }
            requests.forEach((y) => {
                const plans = y.orderPlannedLines;
                if (plans.length === 0) {
                    openOrders.push({
                        orderHeaderId: x.id,
                        orderRequestId: y.id,
                        orderPlanId: 0,
                        orderNumber: x.orderNumber,
                        orderDate: x.orderDate,
                        clientId: x.clientId,
                        supplierId: x.supplierId,
                        status: x.status,
                        comment: x.comment,
                        siteSourceId: 0,
                        siteDestinationId: 0,
                        commodityId: y.commodityId,
                        varietyId: y.varietyId,
                        packId: y.packId,
                        sizeId: y.sizeId,
                        gradeId: y.gradeId,
                        colourId: y.colourId,
                        requestedQuantity: y.quantity,
                        plannedQuantity: 0,
                        actualQuantity: 0, // TODO: orders at stock?
                        isNew: true,
                    });
                }
                plans.forEach((z) => {
                    openOrders.push({
                        orderHeaderId: x.id,
                        orderRequestId: y.id,
                        orderPlanId: z.id,
                        orderNumber: x.orderNumber,
                        orderDate: x.orderDate,
                        clientId: x.clientId,
                        supplierId: x.supplierId,
                        status: x.status,
                        comment: x.comment,
                        siteSourceId: z.siteSourceId,
                        siteDestinationId: z.siteDestinationId,
                        commodityId: z.commodityId,
                        varietyId: z.varietyId,
                        packId: z.packId,
                        sizeId: z.sizeId,
                        gradeId: z.gradeId,
                        colourId: z.colourId,
                        requestedQuantity: this.sameFruitSpec(y, z) ? y.quantity : 0,
                        plannedQuantity: z.quantity,
                        actualQuantity: 0, // TODO: orders at stock?
                        isNew: z.isNew,
                    });

                });
            });
        });
        return openOrders;
    };

    private sameFruitSpec = (orderRequest : IOrderRequest, orderPlan : IOrderPlan) => {
        return orderRequest.commodityId === orderPlan.commodityId &&
            orderRequest.varietyId === orderPlan.varietyId &&
            orderRequest.packId === orderPlan.packId &&
            orderRequest.sizeId === orderPlan.sizeId &&
            orderRequest.gradeId === orderPlan.gradeId &&
            orderRequest.colourId === orderPlan.colourId;
    };

    private getOrganizationName = (orgId : number) => {
        if (orgId === 0) {
            return '';
        }
        const organization = this.props.organizations.find(x => x.id === orgId);
        return organization && organization.name ? uppercase(organization.name) : 'UNKNOWN VALUE';
    };

    private getSiteDescription = (siteId : number) => {
        if (siteId === 0) {
            return '';
        }
        const site = this.props.sites.find(x => x.id === siteId);
        return site && site.description ? uppercase(site.description) : 'UNKNOWN VALUE';
    };

    private getCommodityName = (commId : number) => {
        if (commId === 0) {
            return '';
        }
        const comm = this.props.commodities.find(x => x.id === commId);
        return comm && comm.name ? uppercase(comm.name) : 'UNKNOWN VALUE';
    };

    private getVarietyName = (varId : number) => {
        if (varId === 0) {
            return '';
        }
        const variety = this.props.varieties.find(x => x.id === varId);
        return variety && variety.name ? uppercase(variety.name) : 'UNKNOWN VALUE';
    };

    private getPackDescription = (packId : number) => {
        if (packId === 0) {
            return '';
        }
        const pack = this.props.packs.find(x => x.id === packId);
        return pack && pack.description ? uppercase(pack.description) : 'UNKNOWN VALUE';
    };

    private getSizeName = (sizeId : number) => {
        if (sizeId === 0) {
            return '';
        }
        const size = this.props.sizes.find(x => x.id === sizeId);
        return size && size.name ? uppercase(size.name) : 'UNKNOWN VALUE';
    };

    private getGradeName = (gradeId : number) => {
        if (gradeId === 0) {
            return '';
        }
        const grade = this.props.grades.find(x => x.id === gradeId);
        return grade && grade.name ? uppercase(grade.name) : 'UNKNOWN VALUE';
    };

    private getColourName = (colourId : number) => {
        if (colourId === 0) {
            return '';
        }
        const colour = this.props.colours.find(x => x.id === colourId);
        return colour && colour.name ? uppercase(colour.name) : 'UNKNOWN VALUE';
    };

    private toggleStatus = (type : 'pending' | 'assigned' | 'planned' | 'completed') => {
        const statusses = [...this.state.selectedStatusses];
        const statusIndex = statusses.findIndex(x => x === type);
        if (statusIndex !== -1) {
            this.setState({ selectedStatusses: removeArrayElement(statusses, statusIndex) });
        } else {
            this.setState({ selectedStatusses: addArrayElement(statusses, type, 'end') });
        }
    };

    private filterRows = () => {
        return this.state.rows.filter(x => this.state.selectedStatusses.findIndex(y => y === (x.status && x.status.toLowerCase())) !== -1);
    };

    public render() {
        const iconsLocation = getIconLocation();
        const rows = this.filterRows();
        return <div className={'fdc flx1'}>
            {this.props.isLoading &&
                <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                    <div className={'posr aic jcc h50 w50'}>
                        <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                            <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                        </div>
                        <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                    </div>
                </div>
            }
            <div className='wfill fdr pb20'>
                <div className={'flx1'}/>
                <CustomTooltip title='Pending' aria-label='Pending'>
                    <IconButton onClick={() => this.toggleStatus('pending')}>
                        <img className={'h24'} src={`${iconsLocation}/pending_${this.state.selectedStatusses.findIndex(x => x === 'pending') === -1 ? 'inactive' : 'active'}.svg`} />
                    </IconButton>
                </CustomTooltip>
                <CustomTooltip title='Planned' aria-label='Planned'>
                    <IconButton onClick={() => this.toggleStatus('planned')}>
                        <img className={'h24'} src={`${iconsLocation}/planned_${this.state.selectedStatusses.findIndex(x => x === 'planned') === -1 ? 'inactive' : 'active'}.svg`} />
                    </IconButton>
                </CustomTooltip>
                <CustomTooltip title='Assigned' aria-label='Assigned'>
                    <IconButton onClick={() => this.toggleStatus('assigned')}>
                        <img className={'h24'} src={`${iconsLocation}/assigned_${this.state.selectedStatusses.findIndex(x => x === 'assigned') === -1 ? 'inactive' : 'active'}.svg`} />
                    </IconButton>
                </CustomTooltip>
                <CustomTooltip title='Completed' aria-label='Completed'>
                    <IconButton onClick={() => this.toggleStatus('completed')}>
                        <img className={'h24'} src={`${iconsLocation}/completed_${this.state.selectedStatusses.findIndex(x => x === 'completed') === -1 ? 'inactive' : 'active'}.svg`} />
                    </IconButton>
                </CustomTooltip>
                <span className={'w40'} />
            </div>
            <CustomTable<IOpenOrder>
                enableAdding={this.props.dataFetched}
                addFunction={this.onOrderAdd}
                enableEditing={() => true}
                editFunction={this.onOrderEdit}
                enableSorting
                enableFiltering
                enablePagination
                enableRefresh
                refreshFunction={this.refreshData}
                enableDetails
                detailFunction={this.onOrderDetail}
                columns={[
                    { title: 'Order No', field: 'orderNumber', formatFunction: uppercase , enableFiltering: true, enableSorting: true },
                    { title: 'Order Date', field: 'orderDate', formatFunction: formatDateTimeToDateOnly, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
                    { title: 'Client', field: 'clientId', formatFunction: this.getOrganizationName, enableFiltering: true, enableSorting: true },
                    { title: 'Site Destination', field: 'siteDestinationId', formatFunction: this.getSiteDescription, enableFiltering: true, enableSorting: true },
                    { title: 'Supplier', field: 'supplierId', formatFunction: this.getOrganizationName, enableFiltering: true, enableSorting: true },
                    { title: 'Site Source', field: 'siteSourceId', formatFunction: this.getSiteDescription, enableFiltering: true, enableSorting: true },
                    { title: 'Commodity', field: 'commodityId', formatFunction: this.getCommodityName, enableFiltering: true, enableSorting: true },
                    { title: 'Variety', field: 'varietyId', formatFunction: this.getVarietyName, enableFiltering: true, enableSorting: true },
                    { title: 'Pack', field: 'packId', formatFunction: this.getPackDescription, enableFiltering: true, enableSorting: true },
                    { title: 'Size', field: 'sizeId', formatFunction: this.getSizeName, enableFiltering: true, enableSorting: true },
                    { title: 'Grade', field: 'gradeId', formatFunction: this.getGradeName, enableFiltering: true, enableSorting: true },
                    { title: 'Colour', field: 'colourId', formatFunction: this.getColourName, enableFiltering: true, enableSorting: true },
                    { title: 'Requested Qty', field: 'requestedQuantity', enableFiltering: true, enableSorting: true },
                    { title: 'Planned Qty', field: 'plannedQuantity', enableFiltering: true, enableSorting: true },
                    { title: 'Actual Qty', field: 'actualQuantity', enableFiltering: true, enableSorting: true },
                    { title: 'Status', field: 'status', enableFiltering: true, enableSorting: true },
                    { title: 'Comment', field: 'comment', enableFiltering: true, enableSorting: true },
                    { title: 'New?', field: 'isNew', formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true },
                ]}
                rows={rows}
                initialSortOrder={[{ columnName: 'orderDate_Order Date', direction : 'desc' }]}
                pageSizes={[50, 150, 250, 500, 1000]}
                pageHeight={280}
            />
            {<OrderRequestForm
                selectedOrder={this.state.selectedOrder}
                isAdding={this.state.isAdding}
                isEditing={this.state.isEditing}
                onClose={this.onFormClose}/>}
            {<OrderPlanningScreen
                selectedOrderRequest={this.state.selectedOrderRequest}
                onClose={this.onOrderPlanningClose}/>}
        </div>;
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        orderHeaders: state.data.orderHeaders,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        sizes: state.masterData.sizes,
        grades: state.masterData.grades,
        colours: state.masterData.colours,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OpenOrdersTable);

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IOrchard } from '../../../@types/model/masterData/orchard/orchard';
import qs from 'qs';

export default class OrchardHttpService {
    public static getOrchardData(organizationIds : Array<number>) : AxiosPromise<Array<IOrchard>> {
        return axios.get(`${PACKMAN_API_URL}Orchard/GetAllWeb`, {
            params: {
                organizationIds,
            },
            paramsSerializer: qs.stringify,
        });
    }

    public static addOrUpdateOrchard(data : IOrchard) : AxiosPromise<IOrchard> {
        return axios.post(`${PACKMAN_API_URL}Orchard/PostOrchard`, data);
    }

    public static deleteOrchard(orchardId : number) : AxiosPromise<IOrchard> {
        return axios.delete(`${PACKMAN_API_URL}Orchard/Delete`, {
            params : { orchardId },
        });
    }
}

import * as React from 'react';
import Screen from '../../components/Screen';
import TicketCreationPopup from '../../components/TicketCreationPopup';

interface INoRightsScreenProps {
}

interface INoRightsScreenState {
    isSupportOpen : boolean;
}

export class NoRightsScreen extends React.Component<INoRightsScreenProps, INoRightsScreenState> {
    constructor(props : INoRightsScreenProps) {
        super(props);
        this.state = {
            isSupportOpen: false,
        };
    }

    private setSupportTicketOpen = () => {
        this.setState({ isSupportOpen : true });
    };

    private setSupportTicketClosed = () => {
        this.setState({ isSupportOpen : false });
    };

    public render() {
        const { isSupportOpen } = this.state;
        return (
            <Screen>
                <TicketCreationPopup onClose={this.setSupportTicketClosed}  isOpen={isSupportOpen}/>
                <div className={'fdc hfill jcc aic'}>
                    <img src={`${ASSET_BASE}/assets/images/no_rights.svg`}></img>
                    <p className={'cgray3 fs37'}>Sorry!</p>
                    <p className={'cgray3 fs25'}>You do not have access to this page.</p>
                    <a onClick={this.setSupportTicketOpen} className='supportLink'>Click here to open a ticket.</a>
                </div>
            </Screen>
        );
    }
}

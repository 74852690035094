import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState, RootAction } from '../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Typography, Button, TextField, Table, TableRow, TableCell, Switch } from '@mui/material';
import { formatDateTime, booleanToYesNo, addkg, addea, addArrayElement, removeArrayElement } from '../../services/appFunctionsService';
import CustomTable from '../../components/datagrid/CustomTable';
import { generalShowSuccessSnackbar, generalShowErrorSnackbar } from '../../store/general/Functions';
import { CONSTANT_LOT_TYPE_GUIDS } from '../../appConstants';
import { ILot } from '../../@types/model/lot/lot';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { ILotType } from '../../@types/model/masterData/lotType/lotType';
import { ILotLine } from '../../@types/model/lot/lotLine';
import LotHttpService from '../../services/http/lot/lotHttpService';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ISite } from '../../@types/model/masterData/site/site';
import { IBatch } from '../../@types/model/batch/batch';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { ICommodityState } from '../../@types/model/masterData/commodityState/commodityState';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import PillButton from '../../components/input/PillButton';
import Screen from '../../components/Screen';
import ConfirmationPrompt from '../../components/dialog/ConfirmationPrompt';
import { createSelector } from 'reselect';
import materialTheme from '../../styles/materialTheme';
import { IStorageUnit } from '../../@types/model/masterData/storageUnit/storageUnit';
import { dataSetLot } from '../../store/data/Functions';
import LotCorrectionDialog from './LotCorrectionDialog';
import lodash from 'lodash';
import LotTransferDialog from './LotTransferDialog';
import { ILotLineEdit } from '../../@types/model/lot/lotLineEdit';
import { ILotLineView } from '../../@types/model/lot/LotLineView';
import { IDepartment } from '../../@types/model/masterData/department/department';
import { PackmanLink } from '../../components/link/packmanLink';
import { PackmanLabel } from '../../components/label/PackmanLabel';
import { BooleanFlag } from '../../components/label/BooleanFlag';

interface ILotSummaryProps {
    lot ?: ILot;
    lotLineViews ?: Array<ILotLineView>;
    setLoading : (loading : boolean) => void;
    refreshData : () => void;
    refreshLotSummaryData ?: () => void;
    isLoading : boolean;
    hasEditingRight ?: boolean;
    storageUnits : Array<IStorageUnit>;
    lots : Array<ILot>;
    packs : Array<IPack>;
    lotTypes : Array<ILotType>;
    farms : Array<IFarm>;
    sites : Array<ISite>;
    commodityStates : Array<ICommodityState>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    orchards : Array<IOrchard>;
    departments : Array<IDepartment>;
    organizations : Array<IOrganization>;
    batches : Array<IBatch>;
}

interface ILotSummaryState {
    showDialog : boolean;
    editingLotLine ?: ILotLine;
    newLotLineWeight ?: number;
    newLotLineNoOfUnits ?: number;
    isTransferDialogOpen : boolean;
    isLotCorrectionsDialogOpen : boolean;
    showLinesValidForTransfer : boolean;

    revertLotLines : Array<number>;

    showConfirmationPrompt : boolean;

    filteredLotLines : Array<ILotLineView>;
    isInfoPopupOpen : boolean;
    selectedLotLine ?: ILotLineView;

    editLotLineWeight : boolean;
}

class LotSummary extends React.Component<ILotSummaryProps, ILotSummaryState> {
    constructor(props : ILotSummaryProps) {
        super(props);

        this.state = {
            showDialog: false,
            showConfirmationPrompt: false,
            isTransferDialogOpen: false,
            isLotCorrectionsDialogOpen: false,
            showLinesValidForTransfer: false,
            revertLotLines: [],
            filteredLotLines: [],
            isInfoPopupOpen: false,
            editLotLineWeight: false,
        };
    }

    // Lot Names
    private getLotTypeName = (id : number) => this.props.lotTypes?.find(x => x.id === id)?.name ?? '';

    private getOrganizationInfo = (id : number) => {
        const organization = this.props.organizations?.find(x => x.id === id);
        return organization ? `${organization?.code} - ${organization?.name}` : '';
    };

    private getSiteInfo = (id : number) => {
        const site = this.props.sites?.find(x => x.id === id);
        return site ? `${site?.code} - ${site?.description}` : '';
    };

    private getStorageUnitInfo = (id ?: number) => {
        const storageUnit = this.props.storageUnits?.find(x => x.id === id);
        return storageUnit ? `${storageUnit?.code} - ${storageUnit?.description}` : '';
    };

    private getFarmInfo = (id : number) => {
        const farm = this.props.farms?.find(x => x.id === id);
        return farm ? `${farm?.code} - ${farm?.name}` : '';
    };

    private getCommodityInfo = (id : number) => {
        const commodity = this.props.commodities?.find(x => x.id === id);
        return commodity ? `${commodity?.code} - ${commodity?.name}` : '';
    };

    private getVarietyInfo = (id : number) => {
        const variety = this.props.varieties?.find(x => x.id === id);
        return variety ? `${variety?.code} - ${variety?.name}` : '';
    };

    private getOrchardInfo = (id : number) => {
        const orchard = this.props.orchards?.find(x => x.id === id);
        return orchard ? `${orchard?.code} - ${orchard?.name}` : '';
    };

    private getPackInfo = (id : number) => {
        const pack = this.props.packs?.find(x => x.id === id);
        return pack ? `${pack?.code} - ${pack?.description}` : '';
    };

    private getDepartmentInfo = (orchardId : number) => {
        const orchard = this.props.orchards?.find(x => x.id === orchardId);
        const department = this.props.departments?.find(x => x.id === orchard?.departmentId);
        return department ? `${department?.code} - ${department?.description}` : '';
    };

    private getCommodityStateName = (id ?: number) => this.props.commodityStates?.find(x => x.id === id)?.name ?? '';

    private getBatchCode = (id : number) => {
        return this.props.batches?.find(x => x.id === id)?.batchCode ?? '';
        // TODO: figure out a way to get links displaying in inputs
        // const batchCode = this.props.batches?.find(x => x.id === id)?.batchCode ?? '';
        // return <PackmanLink
        //     type={'transactions'}
        //     targetPage={'batch'}
        //     id={id}
        //     text={batchCode} />;
    };

    private getLotLotTypeGuid = (id : number) => {
        const lot = this.props.lots.find(x => x.id === id);
        const lotType = lot && this.props.lotTypes.find(x => x.id === lot.lotTypeId);
        return lotType ? lotType.guid : '';
    };

    private getLotLineLotId = (lineId : number) => this.props.lots?.find(x => x.lotLines?.some(y => y.id === lineId))?.id ?? '';

    private getLotLineLotTypeGuid = (id : number) => {
        const lotId = this.getLotLineLotId(id);
        return lotId ? this.getLotLotTypeGuid(lotId) : '';
    };

    private getLot = (props : ILotSummaryProps) => props.lot;
    private getLotLineViews = (props : ILotSummaryProps) => props.lotLineViews;
    private showValidLotLinesForTransfer = (props : ILotSummaryProps, state : ILotSummaryState) => state.showLinesValidForTransfer;

    private filterRows = createSelector(
        [this.getLotLineViews, this.showValidLotLinesForTransfer],
        (lotLineViews : Array<ILotLineView>, showLinesValidForTransfer : boolean) => {
            if (!lotLineViews) return [];

            const isInComingLines = lotLineViews.filter(x => x.isActive && x.isIncoming);
            const isOutGoingLines = lotLineViews.filter(x => x.isActive && !x.isIncoming);

            const validLotLines = isInComingLines.filter(x => !!x.unitGuid
                && (isInComingLines.filter(y => y.unitGuid === x.unitGuid).length > isOutGoingLines.filter(y => y.unitGuid === x.unitGuid).length))
                .map(x => lodash.orderBy(isInComingLines.filter(y => y.isActive && y.unitGuid === x.unitGuid), line => line.createdOn, 'desc')[0]);

            if (!showLinesValidForTransfer) {
                return lotLineViews;
            } else {
                return lotLineViews.filter(x => validLotLines.some(y => y.id === x.id));
            }
        },
    );

    private calculateTotal = (type : 'gross' | 'nett' | 'unit', incoming ?: boolean) => {
        let total = 0;
        const lotLines = this.props.lot?.lotLines.filter(x => x.isActive);

        lotLines?.forEach((x) => {
            if ((incoming === undefined && !x.isIncoming) || (incoming === x.isIncoming)) {
                switch (type) {
                    case 'gross':
                        total += x.grossWeight;
                        break;
                    case 'nett':
                        total += x.nettWeight;
                        break;
                    case 'unit':
                        total += x.amountOfUnits;
                        break;
                }
            }
        });

        return total;
    };

    private calculateTotalByLotType = (lotType : string, type : 'gross' | 'nett' | 'unit', incoming : boolean) => {
        let total = 0;
        const lotLines = this.props.lot?.lotLines;

        lotLines?.forEach((x) => {
            if (((incoming && !!x.fromLotLineId && this.getLotLineLotTypeGuid(x.fromLotLineId)) || (!incoming && !!x.toLotLineId && this.getLotLineLotTypeGuid(x.toLotLineId))) === lotType) {
                switch (type) {
                    case 'gross':
                        total += x.grossWeight;
                        break;
                    case 'nett':
                        total += x.nettWeight;
                        break;
                    case 'unit':
                        total += x.amountOfUnits;
                        break;
                }
            }
        });

        return total;
    };

    private calculateStockWeight = (type : 'gross' | 'nett' | 'unit', incoming : boolean) => {
        let total = 0;
        const lotLines = this.props.lot?.lotLines;

        lotLines?.forEach((x) => {
            switch (type) {
                case 'gross':
                    if ((incoming && !!x.fromStockLineId) || (!incoming && !!x.toStockLineId)) total += x.grossWeight;
                    break;
                case 'nett':
                    if ((incoming && !!x.fromStockLineId) || (!incoming && !!x.toStockLineId)) total += x.nettWeight;
                    break;
                case 'unit':
                    if ((incoming && !!x.fromStockLineId) || (!incoming && !!x.toStockLineId)) total += x.amountOfUnits;
                    break;
            }
        });

        return total;
    };

    private submitEdit = async () => {
        const newGrossWeight = this.state.newLotLineWeight;
        const newNoOfUnits = this.state.newLotLineNoOfUnits;
        const lotLineId = this.state.editingLotLine && this.state.editingLotLine.id;
        if (lotLineId && newGrossWeight && newNoOfUnits) {
            this.props.setLoading(true);
            const data : ILotLineEdit = {
                lotLineId,
                newGrossWeight,
                newNoOfUnits,
            };
            try {
                const res = await LotHttpService.editLotLine(data);

                res.data.forEach((x) => {
                    dataSetLot(x);
                });

                this.cancelEdit();
                generalShowSuccessSnackbar('Lot line has been updated!');
                if (this.props.refreshLotSummaryData) {
                    this.props.refreshLotSummaryData();
                } else {
                    this.props.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred updating the lot line.');
                this.props.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Lot Line weight or no. of units was not specified');
        }
    };

    private EditLotLineWeight = async () => {
        const newGrossWeight = this.state.newLotLineWeight;
        const lotLineId = this.state.editingLotLine && this.state.editingLotLine.id;
        if (lotLineId && newGrossWeight) {
            this.props.setLoading(true);
            try {
                const res = await LotHttpService.changeLotLineWeight({ lotLineId, newGrossWeight });

                res.data.forEach((x) => {
                    dataSetLot(x);
                });
                this.cancelWeightChangeEdit();
                generalShowSuccessSnackbar('Lot line has been updated!');
                if (this.props.refreshLotSummaryData) {
                    this.props.refreshLotSummaryData();
                } else {
                    this.props.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred updating the lot line.');
                this.props.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Lot Line weight was not specified');
        }
    };

    private cancelEdit = () => this.setState({ showDialog: false, editingLotLine : undefined, newLotLineWeight: undefined, newLotLineNoOfUnits: undefined });
    private cancelWeightChangeEdit = () => this.setState({ editLotLineWeight: false, editingLotLine : undefined, newLotLineWeight: undefined });

    private renderTable = () => {
        return (
            <div className={'fdr flx1 p20'}>
                <Table style={{ backgroundColor: materialTheme.custom.table.background }} className= {'PaperBorder'}>
                    {/* Headings */}
                    <TableRow className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0'}>
                            {' '}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic p5 lotSummaryDarkBlue h27 cw fw500'}>
                            {'INCOMING'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic p5 lotSummaryDarkGreen h27 cw fw500'}>
                            {'OUTGOING'}
                        </TableCell>
                    </TableRow>
                    <TableRow className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0'}>
                            {' '}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic p5 lotSummaryLightBlue h23 cw fs14'}>
                            <div className={'flx1 aic jcc'}>Units</div>
                            <div className={'flx1 aic jcc'}>Gross Weight</div>
                            <div className={'flx1 aic jcc'}>Nett Weight</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic p5 lotSummaryLightGreen h23 cw fs14'}>
                            <div className={'flx1 aic jcc'}>Units</div>
                            <div className={'flx1 aic jcc'}>Gross Weight</div>
                            <div className={'flx1 aic jcc'}>Nett Weight</div>
                        </TableCell>
                    </TableRow>
                    {/* Calculated Values */}
                    <TableRow style={{ backgroundColor: materialTheme.custom.table.row1.default }} className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0 fw500'}>
                            {'Total'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 p0 fw500'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateTotal('unit', true)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotal('gross', true))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotal('nett', true))}</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0 fw500'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateTotal('unit', false)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotal('gross', false))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotal('nett', false))}</div>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: materialTheme.custom.table.row2.default }} className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0 fw500'}>
                            {'Intake'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 p0'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[2], 'unit', true)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[2], 'gross', true))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[2], 'nett', true))}</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[2], 'unit', false)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[2], 'gross', false))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[2], 'nett', false))}</div>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: materialTheme.custom.table.row1.default }} className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0 fw500'}>
                            {'Batch'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 p0'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[0], 'unit', true)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[0], 'gross', true))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[0], 'nett', true))}</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[0], 'unit', false)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[0], 'gross', false))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[0], 'nett', false))}</div>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: materialTheme.custom.table.row2.default }} className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0 fw500'}>
                            {'Stock'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 p0'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateStockWeight('unit', true)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateStockWeight('gross', true))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateStockWeight('nett', true))}</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0'}>
                            <div className={'flx1 aic jcc hfill'}>{this.calculateStockWeight('unit', false)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateStockWeight('gross', false))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateStockWeight('nett', false))}</div>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: materialTheme.custom.table.row1.default }} className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0 fw500'}>
                            {'Waste'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 p0'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[1], 'unit', true)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[1], 'gross', true))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[1], 'nett', true))}</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[1], 'unit', false)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[1], 'gross', false))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[1], 'nett', false))}</div>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: materialTheme.custom.table.row2.default }} className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0 fw500'}>
                            {'Variance'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 p0'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[3], 'unit', true)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[3], 'gross', true))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[3], 'nett', true))}</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[3], 'unit', false)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[3], 'gross', false))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[3], 'nett', false))}</div>
                        </TableCell>
                    </TableRow>
                </Table>
            </div>
        );
    };

    private formatWeight = (weight : number) => weight.toFixed(3) + ' kg';

    private onRevertLotLinesClick = () => {
        this.setState({ showConfirmationPrompt: true });
    };

    private onLotCorrectionClick = () => {
        this.setState({ isLotCorrectionsDialogOpen: true });
    };

    public closeLotCorrectionDialog = () => {
        this.setState({ isLotCorrectionsDialogOpen: false });
    };

    private getRevertLotLineIds = (props : ILotSummaryProps, state : ILotSummaryState) => state.revertLotLines;

    private isCorrectionButtonDisabled = createSelector(
        [this.getLot, this.getRevertLotLineIds],
        (lot : ILot, selectedLotLineIds : Array<number>) => {
            const isInComingLines = lot.lotLines.filter(x => x.isActive && x.isIncoming);
            const isOutGoingLines = lot.lotLines.filter(x => x.isActive && !x.isIncoming);

            const validLotLines = isInComingLines.filter(x => !!x.unitGuid
                && (isInComingLines.filter(y => y.unitGuid === x.unitGuid).length > isOutGoingLines.filter(y => y.unitGuid === x.unitGuid).length))
                .map(x => lodash.orderBy(isInComingLines.filter(y => y.isActive && y.unitGuid === x.unitGuid), line => line.createdOn, 'desc')[0]);

            return !selectedLotLineIds.every(x => validLotLines.some(y => y.id === x));
        },
    );

    private isTransferButtonDisabled = createSelector(
        [this.getLot, this.getRevertLotLineIds],
        (lot : ILot, selectedLotLineIds : Array<number>) => {
            const isInComingLines = lot.lotLines.filter(x => x.isActive && x.isIncoming);
            const isOutGoingLines = lot.lotLines.filter(x => x.isActive && !x.isIncoming);

            const validLotLines = isInComingLines.filter(x => !!x.unitGuid
                && (isInComingLines.filter(y => y.unitGuid === x.unitGuid).length > isOutGoingLines.filter(y => y.unitGuid === x.unitGuid).length))
                .map(x => lodash.orderBy(isInComingLines.filter(y => y.isActive && y.unitGuid === x.unitGuid), line => line.createdOn, 'desc')[0]);

            return !selectedLotLineIds.every(x => validLotLines.some(y => y.id === x));
        },
    );

    private showValidLinesForTransfer = () => {
        this.setState({ showLinesValidForTransfer: !this.state.showLinesValidForTransfer, revertLotLines: [] });
    };

    private onLotTransferClick = () => {
        this.setState({ isTransferDialogOpen: true });
    };

    public closeLotTransferDialog = () => {
        this.setState({ isTransferDialogOpen: false });
    };

    private submitRevertLotLines = async () => {
        const lotLineIds = [...this.state.revertLotLines];
        if (lotLineIds.length !== 0) {
            this.props.setLoading(true);
            try {
                const res = await LotHttpService.revertLotLines(lotLineIds);

                if (res && res.data) {
                    this.setState({ revertLotLines: [], showConfirmationPrompt: false });
                    res.data.forEach(x => dataSetLot(x));
                }
                this.props.setLoading(false);
                generalShowSuccessSnackbar('Lot lines reverted successfully');
            } catch (e) {
                this.setState({ revertLotLines: [], showConfirmationPrompt: false });
                generalShowErrorSnackbar('An error occurred reverting the lot lines');
                this.props.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Lot Line was not specified');
        }
    };

    private onRevertLotLineCancel = () => {
        this.setState({ showConfirmationPrompt: false });
    };

    private isChecked = (id : number) => this.state.revertLotLines.some(x => x === id);

    private handleRevertCheckboxChecked = (id : number) => {
        const index = this.state.revertLotLines.findIndex(x => x === id);
        if (index === -1) {
            this.setState(prevState => ({ revertLotLines: addArrayElement(prevState.revertLotLines, id, 'end') }));
        } else {
            this.setState(prevState => ({ revertLotLines: removeArrayElement(prevState.revertLotLines, index) }));
        }
    };

    private handleRevertChecked = (revertLotLines : Array<number>) => this.setState({ revertLotLines });

    private getFilteredLotLines = (props : ILotSummaryProps, state : ILotSummaryState) => state.filteredLotLines;

    private getLotLineRows = createSelector(
        [this.getFilteredLotLines],
        (lines : Array<ILotLineView>) => {
            if (!lines) return [];

            return lines.filter(x => x.isActive);
        },
    );

    private handleSelectAllCheckBox = (checked : boolean) => {
        this.setState({ revertLotLines: checked ? this.getLotLineRows(this.props, this.state).map(x => x.id) : [] });
    };

    private setFilteredRows = (filteredLines : Array<ILotLineView>) => {
        if (this.state.filteredLotLines.length !== filteredLines.length) {
            this.setState({ filteredLotLines: filteredLines });
        }
    };

    private formatFromLotLink = (row : ILotLineView, lotName : string) => {
        return <PackmanLink
            type={'transactions'}
            targetPage={'lot'}
            id={Number(row.fromLotId)}
            text={lotName} />;
    };

    private formatToLotLink = (row : ILotLineView, lotName : string) => {
        return <PackmanLink
            type={'transactions'}
            targetPage={'lot'}
            id={Number(row.toLotId)}
            text={lotName} />;
    };

    private formatFromStockLink = (row : ILotLineView, barcode : string) => {
        return <PackmanLink
            type={'transactions'}
            targetPage={'stock'}
            id={Number(row.fromStockId)}
            text={barcode} />;
    };

    private formatToStockLink = (row : ILotLineView, barcode : string) => {
        return <PackmanLink
            type={'transactions'}
            targetPage={'stock'}
            id={Number(row.toStockId)}
            text={barcode} />;
    };

    private onLotLineInfoPopupOpen = (row : ILotLineView) => {
        this.setState({ isInfoPopupOpen: true, selectedLotLine: row });
    };

    private onLotLineInfoPopupClose = () => {
        this.setState({ isInfoPopupOpen: false, selectedLotLine: undefined });
    };

    private onLotLineEdit = (row : ILotLine) => {
        const toLotType = this.getLotLineLotTypeGuid(row.toLotLineId ?? 0);
        if (row && toLotType === CONSTANT_LOT_TYPE_GUIDS[0]) {
            this.setState({ editingLotLine: row, showDialog: true });
        } else {
            this.setState({ editingLotLine: row, editLotLineWeight: true });
        }
    };

    public render() {
        return (
            <Screen isPadded={false} isScrollable={false}>
                <div className={'fdc pl20 pr20 pt10'}>
                    <div className={'fdr aifs pt10'}>
                        <PackmanLabel
                            label={'ID'}
                            value={this.props.lot?.id ?? ''}
                        />
                        <PackmanLabel
                            label={'Name'}
                            value={this.props.lot?.name ?? ''}
                        />
                        <PackmanLabel
                            label={'Type'}
                            value={this.getLotTypeName(this.props.lot?.lotTypeId ?? 0)}
                        />
                        <PackmanLabel
                            label={'Gross Weight (kg)'}
                            value={this.props.lot?.grossWeight.toFixed(3) ?? ''}
                        />
                        <PackmanLabel
                            label={'Nett Weight (kg)'}
                            value={this.props.lot?.nettWeight.toFixed(3) ?? ''}
                        />
                        <PackmanLabel
                            label={'Batch'}
                            value={this.props.lot?.batchId ? <PackmanLink
                                type={'transactions'}
                                targetPage={'batch'}
                                id={Number(this.props.lot?.batchId)}
                                text={this.getBatchCode(this.props.lot?.batchId ?? 0)}
                            /> : undefined}
                        />
                    </div>
                    <div className={'fdr aifs pt10'}>
                        <PackmanLabel
                            label={'Organization'}
                            value={this.getOrganizationInfo(this.props.lot?.organizationId ?? 0)}
                        />
                        <PackmanLabel
                            label={'Site'}
                            value={this.getSiteInfo(this.props.lot?.siteId ?? 0)}
                        />
                        <PackmanLabel
                            label={'StorageUnit'}
                            value={this.getStorageUnitInfo(this.props.lot?.storageUnitId)}
                        />
                        <PackmanLabel
                            label={'Farm'}
                            value={this.getFarmInfo(this.props.lot?.farmId ?? 0)}
                        />
                        <PackmanLabel
                            label={'Commodity'}
                            value={this.getCommodityInfo(this.props.lot?.commodityId ?? 0)}
                        />
                        <PackmanLabel
                            label={'Variety'}
                            value={this.getVarietyInfo(this.props.lot?.varietyId ?? 0)}
                        />
                    </div>
                    <div className={'fdr aifs pt10'}>
                        <PackmanLabel
                            label={'Orchard'}
                            value={this.getOrchardInfo(this.props.lot?.orchardId ?? 0)}
                        />
                        <PackmanLabel
                            label={'Department'}
                            value={this.getDepartmentInfo(this.props.lot?.orchardId ?? 0)}
                        />
                        <PackmanLabel
                            label={'Commodity State'}
                            value={this.getCommodityStateName(this.props.lot?.commodityStateId ?? 0)}
                        />
                        <PackmanLabel
                            label={'Pack Config'}
                            value={this.getPackInfo(this.props.lot?.packId ?? 0)}
                        />
                        <PackmanLabel
                            label={'Intake'}
                            value={this.props.lot?.intakeId ? <PackmanLink
                                type={'transactions'}
                                targetPage={'intake'}
                                id={Number(this.props.lot?.intakeId)}
                                text={this.props.lot?.intakeId}
                            /> : undefined}
                        />
                        <PackmanLabel
                            label={'Intake Shipment Ref'}
                            value={this.props.lot?.intakeShipmentRef ? <PackmanLink
                                type={'transactions'}
                                targetPage={'intake'}
                                id={Number(this.props.lot?.intakeId)}
                                text={this.props.lot?.intakeShipmentRef}
                            /> : undefined}
                        />
                    </div>
                    <div className={'fdr aifs pt10'}>
                        <PackmanLabel
                            label={'Created By'}
                            value={this.props.lot?.createdByName ?? ''}
                        />
                        <PackmanLabel
                            label={'Created On'}
                            value={formatDateTime(this.props.lot?.createdOn ?? '')}
                        />
                        <PackmanLabel
                            label={'Updated By'}
                            value={this.props.lot?.updatedByName ?? ''}
                        />
                        <PackmanLabel
                            label={'Updated On'}
                            value={formatDateTime(this.props.lot?.updatedOn ?? '')}
                        />
                        <div className={'fdr aic flx1'}>
                            <Typography className={'fs14 mr5'}>Finalized?</Typography>
                            <BooleanFlag value={this.props.lot?.isFinalized ?? false}/>
                        </div>
                        <div className={'fdr aic flx1'}>
                            <Typography className={'fs14 mr5'}>Active?</Typography>
                            <BooleanFlag value={this.props.lot?.isActive ?? false}/>
                        </div>
                    </div>
                    <div className={'fdr aifs pt10'}>
                        <div className={'fdr aic flx2'} style={{ maxWidth: 'calc(100% * 2/6)' }}>
                            {!this.props.lot?.isActive ? <PackmanLabel
                                label={'Delete Reason'}
                                maxWidth={'100%'}
                                className={'wrap2line mxwfill oh'}
                                value={this.props.lot?.deleteReason ?? ''}
                            /> : <></>}
                        </div>
                        <div className='flx4' />
                    </div>
                </div>
                <div className={'fdc mnh200 mxh500 pt20 pl20 pr20'}>
                    <CustomTable<ILotLineView>
                        enableEditing={(editingLotLine : ILotLineView) => (this.props.hasEditingRight && (editingLotLine.toLotTypeName === 'Waste' || (this.getLotLotTypeGuid(this.props.lot?.id ?? 0) === CONSTANT_LOT_TYPE_GUIDS[1] && editingLotLine.isIncoming)))}
                        enableFiltering
                        editFunction={(editingLotLine : ILotLineView) => this.onLotLineEdit(editingLotLine)}
                        enableDetails={true}
                        enableSelection
                        onSelectChange={this.handleRevertChecked}
                        disableRowSelect={row => !row.isActive}
                        detailIcon={'info'}
                        detailTooltip={'Info'}
                        detailFunction={this.onLotLineInfoPopupOpen}
                        detailsColor={materialTheme.palette.primary.main}
                        columns={[
                            { title: 'Id', field: 'id', width: 60, enableFiltering: true, enableSorting: true },
                            { title: 'No. Units (ea)', field: 'amountOfUnits', width: 80, formatFunction: addea, enableFiltering: true, enableSorting: true,
                                containerComponent: (row : ILotLineView, value : any) => <div className={'fdr'}>
                                    {(value === '0 ea' ? '0 ea' : row.isIncoming ? <div className={'cpl'} style={{ paddingLeft: 3 }}>{`+${value}`}</div> :
                                        <div className={'cred'} style={{ paddingLeft: 3 }}>{`-${value}`}</div>)}
                                </div> },
                            { title: 'Gross Weight (kg)', field: 'grossWeight', width: 120, formatFunction: addkg, enableFiltering: true, enableSorting: true,
                                containerComponent: (row : ILotLineView, value : any) => <div className={'fdr'}>
                                    {(value === '0 kg' ? '0 kg' : row.isIncoming ? <div className={'cpl'} style={{ paddingLeft: 3 }}>{`+${value}`}</div> :
                                        <div className={'cred'} style={{ paddingLeft: 3 }}>{`-${value}`}</div>)}
                                </div>  },
                            { title: 'Nett Weight (kg)', field: 'nettWeight', width: 120, formatFunction: addkg, enableFiltering: true, enableSorting: true,
                                containerComponent: (row : ILotLineView, value : any) => <div className={'fdr'}>
                                    {(value === '0 kg' ? '0 kg' : row.isIncoming ? <div className={'cpl'} style={{ paddingLeft: 3 }}>{`+${value}`}</div> :
                                        <div className={'cred'} style={{ paddingLeft: 3 }}>{`-${value}`}</div>)}
                                </div> },
                            { title: 'Unit Gross Weight (kg)', field: 'unitGrossWeight', width: 120, formatFunction: addkg, enableFiltering: true, enableSorting: true,
                                containerComponent: (row : ILotLineView, value : any) => <div className={'fdr'}>
                                    {(value === '0 kg' ? '0 kg' : row.isIncoming ? <div className={'cpl'} style={{ paddingLeft: 3 }}>{`+${value}`}</div> :
                                        <div className={'cred'} style={{ paddingLeft: 3 }}>{`-${value}`}</div>)}
                                </div>  },
                            { title: 'Unit Nett Weight (kg)', field: 'unitNettWeight', width: 120, formatFunction: addkg, enableFiltering: true, enableSorting: true,
                                containerComponent: (row : ILotLineView, value : any) => <div className={'fdr'}>
                                    {(value === '0 kg' ? '0 kg' : row.isIncoming ? <div className={'cpl'} style={{ paddingLeft: 3 }}>{`+${value}`}</div> :
                                        <div className={'cred'} style={{ paddingLeft: 3 }}>{`-${value}`}</div>)}
                                </div>  },
                            { title: 'Unit Barcode', field: 'unitGuid', width: 100, enableFiltering: true, enableSorting: true },
                            { title: 'From Lot', field: 'fromLotName', containerComponent: this.formatFromLotLink, enableFiltering: true, enableSorting: true },
                            { title: 'To Lot', field: 'toLotName', containerComponent: this.formatToLotLink, enableFiltering: true, enableSorting: true },
                            { title: 'From Lot Type', field: 'fromLotTypeName', enableFiltering: true, enableSorting: true },
                            { title: 'To Lot Type', field: 'toLotTypeName', enableFiltering: true, enableSorting: true },
                            { title: 'From Stock', field: 'fromStockBarcode', containerComponent: this.formatFromStockLink, enableFiltering: true, enableSorting: true, width: 180 },
                            { title: 'To Stock', field: 'toStockBarcode', containerComponent: this.formatToStockLink, enableFiltering: true, enableSorting: true, width: 180 },
                            { title: 'Pack', field: 'packCode', width: 100, enableFiltering: true, enableSorting: true },
                            { title: 'Active?', field: 'isActive', formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true },
                        ]}
                        rows={this.filterRows(this.props, this.state)}
                        enableSorting
                        enablePagination
                        pageSizes={[50, 150, 250, 500, 1000]}
                        initialSortOrder={[{ columnName: 'id_Id', direction : 'asc' }]}
                        isActive = {(row : ILotLineView) => row.isActive}
                        pageHeight={400}
                        filteredRows={this.setFilteredRows}
                    />
                </div>
                {(this.props.lot && !this.props.lot.isFinalized) &&
                    <div className={'flx1 pt20 pr20'}>
                        <div className={'flx1'} />
                        <div className={'fdr aic jcc'}>
                            Show lot lines valid for Transfer or Correction
                            <Switch
                                color={'primary'}
                                checked={this.state.showLinesValidForTransfer}
                                onChange={this.showValidLinesForTransfer} />
                        </div>
                        <div className={'w10'} />
                        <PillButton
                            className={'pl10 pr10 h35 w300 reducedPillButtonShadow'}
                            text={'Transfer Selected Lot Lines'}
                            disabled={this.props.isLoading || this.state.revertLotLines.length === 0 || this.isTransferButtonDisabled(this.props, this.state)}
                            type={'submit'}
                            color={'secondary'}
                            onClick={() => this.onLotTransferClick()}
                        />
                        <div className={'w10'} />
                        <div className={`${this.props.lot?.name?.startsWith('L_BD_') ? 'dn' : 'fdr'}`}>
                            <PillButton
                                className={'pl10 pr10 h35 w300 reducedPillButtonShadow'}
                                text={'Correct Selected Lot Lines'}
                                disabled={this.props.isLoading || this.state.revertLotLines.length === 0 || this.isCorrectionButtonDisabled(this.props, this.state)}
                                type={'submit'}
                                color={'secondary'}
                                onClick={() => this.onLotCorrectionClick()}
                            />
                            <div className={'w10'} />
                            <PillButton
                                className={'pl10 pr10 h35 w300 reducedPillButtonShadow'}
                                text={'Revert Selected Lot Lines'}
                                disabled={this.props.isLoading || this.state.revertLotLines.length === 0}
                                type={'submit'}
                                color={'secondary'}
                                onClick={() => this.onRevertLotLinesClick()}
                            />
                        </div>
                    </div>
                }
                {this.renderTable()}
                {/* Edit lot line noOfunits and grossWeight */}
                <PackmanDialog
                    title={`Lot Line ${this.state.editingLotLine?.id}`}
                    isInfo
                    isLoading={this.props.isLoading}
                    isOpen={this.state.showDialog}
                    onClose={this.cancelEdit}>
                    <div className={'fdc p20'}>
                        <div className={'fdr aic jcc pb5'}>{'This lot line may have had an incorrect weight tipped \n'}</div>
                        <div className={'fdr aic jcc pb5'}>{`Current No. of Units: ${this.state.editingLotLine ? this.state.editingLotLine.amountOfUnits : ''} Current Weight: ${this.state.editingLotLine ? this.state.editingLotLine.grossWeight : ''} kg`}</div>
                        <div className={'fdr aic jcc pb5'}>
                            <TextField
                                label={'New No. Of Units'}
                                value={this.state.newLotLineNoOfUnits}
                                type={'number'}
                                onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.setState({ newLotLineNoOfUnits: Number(e.target.value) })}
                            />
                            <div className={'w10'}/>
                            <TextField
                                label={'New Weight'}
                                value={this.state.newLotLineWeight}
                                type={'number'}
                                onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.setState({ newLotLineWeight: Number(e.target.value) })}
                            />
                        </div>
                        <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                            <Button
                                className={'fwb h35'}
                                variant='text' color='primary'
                                onClick={this.cancelEdit}
                            >
                                Clear
                            </Button>
                            <PillButton
                                className={'ml15 pl20 pr20 h35'}
                                text={'Save'}
                                color={'secondary'}
                                onClick={this.submitEdit}
                            />
                        </div>
                    </div>
                </PackmanDialog>
                {/* Edit lot line grossWeight */}
                <PackmanDialog
                    title={`Lot Line ${this.state.editingLotLine?.id}`}
                    isInfo
                    isLoading={this.props.isLoading}
                    isOpen={this.state.editLotLineWeight}
                    onClose={this.cancelWeightChangeEdit}>
                    <div className={'fdc p20'}>
                        <div className={'fdr aic jcc pb5'}>{'This lot line may have had an incorrect weight tipped \n'}</div>
                        <div className={'fdr aic jcc pb5'}>{`Current Weight: ${this.state.editingLotLine ? this.state.editingLotLine.grossWeight : ''} kg`}</div>
                        <div className={'fdr aic jcc pb5'}>
                            <TextField
                                className={'w250'}
                                label={'New Weight'}
                                value={this.state.newLotLineWeight}
                                type={'number'}
                                onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.setState({ newLotLineWeight: Number(e.target.value) })}
                            />
                        </div>
                        <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                            <Button
                                className={'fwb h35'}
                                variant='text' color='primary'
                                onClick={this.cancelWeightChangeEdit}
                            >
                                Clear
                            </Button>
                            <PillButton
                                className={'ml15 pl20 pr20 h35'}
                                text={'Save'}
                                color={'secondary'}
                                onClick={this.EditLotLineWeight}
                            />
                        </div>
                    </div>
                </PackmanDialog>
                {/* lot transfer dialog */}
                <PackmanDialog
                    title='Lot Transfer'
                    isInfo={true}
                    maxWidth={'md'}
                    fullWidth
                    isLoading={this.props.isLoading}
                    isOpen={this.state.isTransferDialogOpen}
                    onClose={this.closeLotTransferDialog}>
                    <LotTransferDialog
                        isLoading={this.props.isLoading}
                        selectedLotId={this.props.lot?.id}
                        selectedLotLines={this.state.revertLotLines}
                        isTransferFinalizeButtonClicked={false}
                        setSelectedLotLines={lotLineId => this.handleRevertCheckboxChecked(lotLineId)}
                        closeLotTransferDialog={this.closeLotTransferDialog}
                        refreshData={this.props.refreshLotSummaryData}
                        setLoading={value => this.props.setLoading(value)}/>
                </PackmanDialog>
                {!!this.state.isLotCorrectionsDialogOpen &&
                    <PackmanDialog
                        title='Lot Corrections'
                        isInfo={true}
                        maxWidth={'md'}
                        fullWidth
                        isLoading={this.props.isLoading}
                        isOpen={this.state.isLotCorrectionsDialogOpen}
                        onClose={this.closeLotCorrectionDialog}>
                        <LotCorrectionDialog
                            isLoading={this.props.isLoading}
                            selectedLot={this.props.lot}
                            selectedLotLines={this.state.revertLotLines}
                            setSelectedLotLines={lotLineId => this.handleRevertCheckboxChecked(lotLineId)}
                            refreshData={this.props.refreshLotSummaryData}
                            setLoading={value => this.props.setLoading(value)}
                            closeLotCorrectionDialog={this.closeLotCorrectionDialog} />
                    </PackmanDialog>
                }
                {!!this.state.isInfoPopupOpen && !!this.state.selectedLotLine &&
                    <PackmanDialog
                        title={`Lot Line: ${this.state.selectedLotLine.id}`}
                        isInfo={true}
                        isLoading={this.props.isLoading}
                        isOpen={this.state.isInfoPopupOpen}
                        onClose={this.onLotLineInfoPopupClose}>
                        <div className={'fdc p10'}>
                            <div className={'fdr jcfs mb5'}>
                                <Typography style={{ marginRight: 13 }} className={'fw500'}>Created By:</Typography>
                                <Typography>{this.state.selectedLotLine.createdByName}</Typography>
                                <div className={'w20'}/>
                                <Typography className={'mr15 fw500'}>Created On:</Typography>
                                <Typography>{formatDateTime(this.state.selectedLotLine.createdOn ?? '')}</Typography>
                            </div>
                            <div className={'fdr jcfs mb5'}>
                                <Typography className={'mr10 fw500'}>Updated By:</Typography>
                                <Typography>{this.state.selectedLotLine.updatedByName}</Typography>
                                <div className={'w20'}/>
                                <Typography className={'mr10 fw500'}>Updated On:</Typography>
                                <Typography>{formatDateTime(this.state.selectedLotLine.updatedOn ?? '')}</Typography>
                            </div>
                            <div className={'fdr wfill jcc aic'}>
                                <Typography className={'fs14 mr5'}>Active?</Typography>
                                <BooleanFlag value={this.state.selectedLotLine?.isActive ?? false}/>
                            </div>
                        </div>
                    </PackmanDialog>
                }
                <ConfirmationPrompt
                    isLoading={this.props.isLoading}
                    open={this.state.showConfirmationPrompt}
                    message={'By clicking "Ok" you confirm that these are the correct lot lines to be reverted.' }
                    onOkClicked={this.submitRevertLotLines}
                    onCancelClicked={this.onRevertLotLineCancel}
                    disableYes={this.props.isLoading} disableNo={this.props.isLoading}/>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        storageUnits : state.masterData.storageUnits,
        lots : state.data.lots,
        packs : state.masterData.packs,
        lotTypes : state.masterData.lotTypes,
        farms : state.masterData.farms,
        sites : state.masterData.sites,
        commodityStates : state.masterData.commodityStates,
        commodities : state.masterData.commodities,
        varieties : state.masterData.varieties,
        orchards : state.masterData.orchards,
        departments : state.masterData.departments,
        organizations : state.masterData.organizations,
        batches : state.data.batches,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LotSummary);

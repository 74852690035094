import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IMark } from '../../../@types/model/masterData/mark/mark';

export default class MarkHttpService {
    public static getMarkData() : AxiosPromise<Array<IMark>> {
        return axios.get(`${PACKMAN_API_URL}Mark/GetAllWeb`);
    }

    public static addOrUpdateMark(data : IMark) : AxiosPromise<IMark> {
        return axios.post(`${PACKMAN_API_URL}Mark/PostMark`, data);
    }

    public static deleteMark(markId : number) : AxiosPromise<IMark> {
        return axios.delete(`${PACKMAN_API_URL}Mark/Delete`, {
            params : { markId },
        });
    }
}

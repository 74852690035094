import { IBaseModel } from '../../model';
import { IIntakeLineLayerUnitFormValues } from './form/intakeLineLayerUnitFormValues';

export interface IIntakeLineLayerUnit extends IBaseModel {
    intakeLineLayerId : number;
    unitGuid ?: string;
    varietyId ?: number;
    packId ?: number;
    lotLineId ?: number;
    grossWeight : number;
    nettWeight : number;
    unitGrossWeight : number;
    unitNettWeight : number;
    noOfUnits : number;
}

export class IntakeLineLayerUnit implements IIntakeLineLayerUnit {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public intakeLineLayerId : number;
    public unitGuid ?: string;
    public varietyId ?: number;
    public packId ?: number;
    public lotLineId ?: number;
    public grossWeight : number;
    public nettWeight : number;
    public unitGrossWeight : number;
    public unitNettWeight : number;
    public noOfUnits : number;

    public constructor(formValues : IIntakeLineLayerUnitFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.unitGuid = formValues.unitGuid;
        this.grossWeight = formValues.grossWeight;
        this.nettWeight = formValues.nettWeight;
        this.unitGrossWeight = formValues.unitGrossWeight;
        this.unitNettWeight = formValues.unitNettWeight;
        this.noOfUnits = formValues.noOfUnits;
    }

}

import axios, { AxiosPromise } from 'axios';
import { IPrintServer } from '../../../@types/model/masterData/printServer/printServer';
import { IDispatchInstructionAppView } from '../../../@types/model/dispatch/dispatchInstructionAppView';
import { IBatchAppView } from '../../../@types/model/batch/batchAppView';
import { IStockAppView } from '../../../@types/model/stock/stockAppView';
import { IIntakeAppView } from '../../../@types/model/intake/intakeAppView';
import { ITempStockUnitAppView } from '../../../@types/model/stock/tempStockUnitAppView';

export default class PrintServerReportHttpService {
    public static getBatchReportPreview(printServer : IPrintServer, batch : IBatchAppView, batchGuid : string, reportGuid : string) : AxiosPromise<ArrayBuffer> {
        return axios.post(`${printServer.defaultScheme}://${printServer.primaryUrl ?? printServer.ipAddress}${printServer.port ? ':'  + printServer.port : ''}/api/Batch/GetBatchReportPreviewApp`, batch, {
            params: {
                batchGuid,
                reportGuid,
            }, responseType: 'arraybuffer',
        });
    }

    public static getTempStockReportPreview(printServer : IPrintServer, tempStock : ITempStockUnitAppView, tempStockGuid : string, reportGuid : string) : AxiosPromise<ArrayBuffer> {
        return axios.post(`${printServer.defaultScheme}://${printServer.primaryUrl ?? printServer.ipAddress}${printServer.port ? ':'  + printServer.port : ''}/api/Batch/GetTempStockReportPreviewApp`, tempStock, {
            params: {
                tempStockGuid,
                reportGuid,
            }, responseType: 'arraybuffer',
        });
    }

    public static getDispatchReportPreview(printServer : IPrintServer, dispatch : IDispatchInstructionAppView, dispatchGuid : string, reportGuid : string) : AxiosPromise<ArrayBuffer> {
        return axios.post(`${printServer.defaultScheme}://${printServer.primaryUrl ?? printServer.ipAddress}${printServer.port ? ':'  + printServer.port : ''}/api/Dispatch/GenerateDispatchReportPDF`, dispatch, {
            params: {
                dispatchGuid,
                reportGuid,
            }, responseType: 'arraybuffer',
        });
    }

    public static getGenericReportAppPreview(printServer : IPrintServer) : AxiosPromise<ArrayBuffer> {
        return axios.get(`${printServer.defaultScheme}://${printServer.primaryUrl ?? printServer.ipAddress}${printServer.port ? ':'  + printServer.port : ''}/api/Generic/GetGenericReportPreviewApp`);
    }

    public static getGenericAdvancedReportAppPreview(printServer : IPrintServer) : AxiosPromise<ArrayBuffer> {
        return axios.get(`${printServer.defaultScheme}://${printServer.primaryUrl ?? printServer.ipAddress}${printServer.port ? ':'  + printServer.port : ''}/api/Generic/GetGenericAdvancedReportPreviewApp`);
    }

    public static getIntakeReportPreview(printServer : IPrintServer, intake : IIntakeAppView, intakeGuid : string, reportGuid : string) : AxiosPromise<ArrayBuffer> {
        return axios.post(`${printServer.defaultScheme}://${printServer.primaryUrl ?? printServer.ipAddress}${printServer.port ? ':'  + printServer.port : ''}/api/Intake/GetReportPreviewApp`, intake, {
            params: {
                intakeGuid,
                reportGuid,
            }, responseType: 'arraybuffer',
        });
    }

    public static getMaterialDispatchReportPreview(printServer : IPrintServer) : AxiosPromise<ArrayBuffer> {
        return axios.get(`${printServer.defaultScheme}://${printServer.primaryUrl ?? printServer.ipAddress}${printServer.port ? ':'  + printServer.port : ''}/api/MaterialDispatch/GetReportPreviewApp`);
    }

    public static getStockReportPreview(printServer : IPrintServer, stock : IStockAppView, stockGuid : string, reportGuid : string) : AxiosPromise<ArrayBuffer> {
        return axios.post(`${printServer.defaultScheme}://${printServer.primaryUrl ?? printServer.ipAddress}${printServer.port ? ':'  + printServer.port : ''}/api/Stock/GetReportPreviewApp`, stock, {
            params: {
                stockGuid,
                reportGuid,
            }, responseType: 'arraybuffer',
        });
    }
}

import { IBaseModel } from '../../../model';
import { IFarmFormValues } from './farmFormValues';

export interface IFarm extends IBaseModel {
    code : string;
    name : string;
    organizationIds : Array<number>;
    ftpDetailIds : Array<number>;
    contactInfoIds : Array<number>;
    globalGapNumber ?: string;
    address ?: string;
    telNumber ?: string;
    faxNumber ?: string;
    bankAccount ?: string;
    bankName ?: string;
    extendedDescription ?: string;
    vat ?: string;
    exportedCode ?: string;
    exportedDescription ?: string;
    accreditationId : number;
}

export class Farm implements IFarm {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public name : string;
    public organizationIds : Array<number>;
    public ftpDetailIds : Array<number>;
    public contactInfoIds : Array<number>;
    public globalGapNumber ?: string;
    public address ?: string;
    public telNumber ?: string;
    public faxNumber ?: string;
    public bankAccount ?: string;
    public bankName ?: string;
    public extendedDescription ?: string;
    public vat ?: string;
    public exportedCode ?: string;
    public exportedDescription ?: string;
    public accreditationId : number;

    public constructor(formValues : IFarmFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.code = formValues.code;
        this.name = formValues.name;
        this.organizationIds = formValues.organizations.map(x => Number(x.value));
        this.ftpDetailIds = formValues.ftpDetails.map(x => Number(x.value));
        this.contactInfoIds = formValues.contactInfos.map(x => Number(x.value));
        this.globalGapNumber = formValues.globalGapNumber;
        this.address = formValues.address;
        this.telNumber = formValues.telNumber;
        this.faxNumber = formValues.faxNumber;
        this.bankAccount = formValues.bankAccount;
        this.bankName = formValues.bankName;
        this.extendedDescription = formValues.extendedDescription;
        this.vat = formValues.vat;
        this.exportedCode = formValues.exportedCode;
        this.exportedDescription = formValues.exportedDescription;
        this.accreditationId = Number(formValues.accreditation?.value) ?? 0;
    }
}

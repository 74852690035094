import { openDb } from 'idb';
import { getMasterDataLastSyncDateLocalStorage, setMasterDataLastSyncDateLocalStorage, upgradeDb } from './localStorageService';
import { IOrganization } from '../@types/model/masterData/organization/organization';
import { IInventory } from '../@types/model/masterData/inventory/inventory';
import { ISite } from '../@types/model/masterData/site/site';
import { IAccreditation } from '../@types/model/masterData/accreditation/accreditation';
import { IAgreementCode } from '../@types/model/masterData/agreementCode/agreementCode';
import { IBarcodeRange } from '../@types/model/masterData/barcodeRange/barcodeRange';
import { ICarrier } from '../@types/model/masterData/carrier/carrier';
import { ICommodity } from '../@types/model/masterData/commodity/commodity';
import { ICommodityCondition } from '../@types/model/masterData/commodityCondition/commodityCondition';
import { IColour } from '../@types/model/masterData/colour/colour';
import { ICommodityState } from '../@types/model/masterData/commodityState/commodityState';
import { IGrade } from '../@types/model/masterData/grade/grade';
import { IOrchard } from '../@types/model/masterData/orchard/orchard';
import { IPack } from '../@types/model/masterData/pack/pack';
import { ISeason } from '../@types/model/masterData/season/season';
import { ISize } from '../@types/model/masterData/size/size';
import { IVariety } from '../@types/model/masterData/variety/variety';
import { IContactInfo } from '../@types/model/masterData/contactInfo/contactInfo';
import { ICountry } from '../@types/model/masterData/country/country';
import { IDepartment } from '../@types/model/masterData/department/department';
import { IDevice } from '../@types/model/masterData/device/device';
import { IDomain } from '../@types/model/user/domain';
import { IFarm } from '../@types/model/masterData/farm/farm';
import { IInspectionPoint } from '../@types/model/masterData/inspectionPoint/inspectionPoint';
import { ILotType } from '../@types/model/masterData/lotType/lotType';
import { IMark } from '../@types/model/masterData/mark/mark';
import { IMarket } from '../@types/model/masterData/market/market';
import { IMaterial } from '../@types/model/masterData/material/material';
import { IMaterialType } from '../@types/model/masterData/materialType/materialType';
import { IOutlet } from '../@types/model/masterData/outlet/outlet';
import { IPackCategory } from '../@types/model/masterData/pack/packCategory';
import { IPackLine } from '../@types/model/masterData/packLine/packLine';
import { IPalletBaseType } from '../@types/model/masterData/palletBaseType/palletBaseType';
import { IPrinter } from '../@types/model/masterData/printer/printer';
import { IPrintServer } from '../@types/model/masterData/printServer/printServer';
import { IProject } from '../@types/model/masterData/project/project';
import { IRegion } from '../@types/model/masterData/region/region';
import { ISalesPoint } from '../@types/model/masterData/salesPoint/salesPoint';
import { IStorageUnit } from '../@types/model/masterData/storageUnit/storageUnit';
import { ITruckType } from '../@types/model/masterData/truckType/truckType';
import { IUnitOfMeasure } from '../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import { IUnitOfMeasureType } from '../@types/model/masterData/unitOfMeasureType/unitOfMeasureType';
import { IMasterDataSyncData } from '../@types/model/masterData/sync/MasterDataSyncData';
import { checkIfReduxMasterDataIsPopulated, dataSetAllSyncedMasterData } from '../store/masterData/Functions';
import MasterDataSyncHttpService from './http/masterData/masterDataSyncHttpService';
import moment from 'moment';
import { IReport } from '../@types/model/masterData/report/report';
import { IFtpDetail } from '../@types/model/masterData/ftpDetail/ftpDetail';
import { ISiteSetting } from '../@types/model/masterData/siteSetting/siteSetting';
import { generalShowErrorSnackbar } from '../store/general/Functions';

// IndexedDB Master Data Object Store(table) names
export const ORGANIZATION_TABLE_NAME = 'organization-table';
export const SITE_TABLE_NAME = 'site-table';
export const ACCREDITATION_TABLE_NAME = 'accreditation-table';
export const AGREEMENT_CODE_TABLE_NAME = 'agreement-code-table';
export const BARCODE_RANGE_TABLE_NAME = 'barcode-range-table';
export const CARRIER_TABLE_NAME = 'carrier-table';
export const COLOUR_TABLE_NAME = 'colour-table';
export const COMMODITY_TABLE_NAME = 'commodity-table';
export const COMMODITY_CONDITION_TABLE_NAME = 'commodity-condition-table';
export const COMMODITY_STATE_TABLE_NAME = 'commodity-state-table';
export const GRADE_TABLE_NAME = 'grade-table';
export const ORCHARD_TABLE_NAME = 'orchard-table';
export const PACK_TABLE_NAME = 'pack-table';
export const SEASON_TABLE_NAME = 'season-table';
export const SIZE_TABLE_NAME = 'size-table';
export const VARIETY_TABLE_NAME = 'variety-table';
export const CONTACT_INFO_TABLE_NAME = 'contact-info-table';
export const COUNTRY_TABLE_NAME = 'country-table';
export const DEPARTMENT_TABLE_NAME = 'department-table';
export const DEVICE_TABLE_NAME = 'device-table';
export const DOMAIN_TABLE_NAME = 'domain-table';
export const FARM_TABLE_NAME = 'farm-table';
export const FTP_DETAIL_TABLE_NAME = 'ftp-detail-table';
export const INSPECTION_POINT_TABLE_NAME = 'inspection-point-table';
export const INVENTORY_TABLE_NAME = 'inventory-table';
export const LOT_TYPE_TABLE_NAME = 'lot-type-table';
export const MARK_TABLE_NAME = 'mark-table';
export const MARKET_TABLE_NAME = 'market-table';
export const MATERIAL_TABLE_NAME = 'material-table';
export const MATERIAL_TYPE_TABLE_NAME = 'material-type-table';
export const OUTLET_TABLE_NAME = 'outlet-table';
export const PACK_CATEGORY_TABLE_NAME = 'pack-category-table';
export const PACK_LINE_TABLE_NAME = 'pack-line-table';
export const PALLET_BASE_TYPE_TABLE_NAME = 'pallet-base-type-table';
export const PRINTER_TABLE_NAME = 'printer-table';
export const PROJECT_TABLE_NAME = 'project-table';
export const PRINT_SERVER_TABLE_NAME = 'print-server-table';
export const REGION_TABLE_NAME = 'region-table';
export const REPORT_TABLE_NAME = 'report-table';
export const SITE_SETTING_TABLE_NAME = 'site-setting-table';
export const SALES_POINT_TABLE_NAME = 'sales-point-table';
export const STORAGE_UNIT_TABLE_NAME = 'storage-unit-table';
export const TRUCK_TYPE_TABLE_NAME = 'truck-type-table';
export const UNIT_OF_MEASURE_TABLE_NAME = 'unit-of-measure-table';
export const UNIT_OF_MEASURE_TYPE_TABLE_NAME = 'unit-of-measure-type-table';

/* ****************************************************************************** */
/* Start of Master Data IndexedDB storage */

/**
 * Sync all master data
 *
 * @param boolean fullSync
 */
export async function syncMasterData(fullSync : boolean) {
    const lastSyncDate : moment.Moment = getMasterDataLastSyncDateLocalStorage();

    const newMasterDataLastSyncDate : moment.Moment = moment().utc().subtract(1, 'hours');

    setMasterDataLastSyncDateLocalStorage(newMasterDataLastSyncDate);

    const lastSyncDateUnix = !fullSync ? moment(lastSyncDate).unix() * 1000 : undefined;

    try {
        const masterData = await MasterDataSyncHttpService.getAllMasterData(lastSyncDateUnix);

        // if (fullSync) {
        //     await clearIndexedDBObjectStores();
        // }

        await setIndexedDBMasterData(masterData.data);
    } catch (e) {
        console.log('masterdata', e, e?.data?.Message);
        generalShowErrorSnackbar(e?.data?.Message);
    }
};

/**
 * Updates all master data object stores in indexedDB with data returned from master data sync api call
 *
 */
export async function setIndexedDBMasterData(masterData : IMasterDataSyncData) {
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        // gets transaction containing all master data object stores
        const tx = db.transaction([ORGANIZATION_TABLE_NAME,
                                   SITE_TABLE_NAME,
                                   ACCREDITATION_TABLE_NAME,
                                   AGREEMENT_CODE_TABLE_NAME,
                                   BARCODE_RANGE_TABLE_NAME,
                                   CARRIER_TABLE_NAME,
                                   COLOUR_TABLE_NAME,
                                   COMMODITY_TABLE_NAME,
                                   COMMODITY_CONDITION_TABLE_NAME,
                                   COMMODITY_STATE_TABLE_NAME,
                                   GRADE_TABLE_NAME,
                                   ORCHARD_TABLE_NAME,
                                   PACK_TABLE_NAME,
                                   SEASON_TABLE_NAME,
                                   SIZE_TABLE_NAME,
                                   VARIETY_TABLE_NAME,
                                   CONTACT_INFO_TABLE_NAME,
                                   COUNTRY_TABLE_NAME,
                                   DEPARTMENT_TABLE_NAME,
                                   DEVICE_TABLE_NAME,
                                   DOMAIN_TABLE_NAME,
                                   FARM_TABLE_NAME,
                                   FTP_DETAIL_TABLE_NAME,
                                   INSPECTION_POINT_TABLE_NAME,
                                   INVENTORY_TABLE_NAME,
                                   LOT_TYPE_TABLE_NAME,
                                   MARK_TABLE_NAME,
                                   MARKET_TABLE_NAME,
                                   MATERIAL_TABLE_NAME,
                                   MATERIAL_TYPE_TABLE_NAME,
                                   OUTLET_TABLE_NAME,
                                   PACK_CATEGORY_TABLE_NAME,
                                   PACK_LINE_TABLE_NAME,
                                   PALLET_BASE_TYPE_TABLE_NAME,
                                   PRINTER_TABLE_NAME,
                                   PROJECT_TABLE_NAME,
                                   PRINT_SERVER_TABLE_NAME,
                                   SALES_POINT_TABLE_NAME,
                                   REGION_TABLE_NAME,
                                   REPORT_TABLE_NAME,
                                   SITE_SETTING_TABLE_NAME,
                                   STORAGE_UNIT_TABLE_NAME,
                                   TRUCK_TYPE_TABLE_NAME,
                                   UNIT_OF_MEASURE_TABLE_NAME,
                                   UNIT_OF_MEASURE_TYPE_TABLE_NAME], 'readwrite');

        const organizationStore = tx.objectStore(ORGANIZATION_TABLE_NAME);
        const siteStore = tx.objectStore(SITE_TABLE_NAME);
        const accreditationStore = tx.objectStore(ACCREDITATION_TABLE_NAME);
        const agreementCodeStore = tx.objectStore(AGREEMENT_CODE_TABLE_NAME);
        const barcodeRangeStore = tx.objectStore(BARCODE_RANGE_TABLE_NAME);
        const carrierStore = tx.objectStore(CARRIER_TABLE_NAME);
        const colourStore = tx.objectStore(COLOUR_TABLE_NAME);
        const commodityStore = tx.objectStore(COMMODITY_TABLE_NAME);
        const commodityConditionStore = tx.objectStore(COMMODITY_CONDITION_TABLE_NAME);
        const commodityStateStore = tx.objectStore(COMMODITY_STATE_TABLE_NAME);
        const gradeStore = tx.objectStore(GRADE_TABLE_NAME);
        const orchardStore = tx.objectStore(ORCHARD_TABLE_NAME);
        const packStore = tx.objectStore(PACK_TABLE_NAME);
        const seasonStore = tx.objectStore(SEASON_TABLE_NAME);
        const sizeStore = tx.objectStore(SIZE_TABLE_NAME);
        const varietyStore = tx.objectStore(VARIETY_TABLE_NAME);
        const contactInfoStore = tx.objectStore(CONTACT_INFO_TABLE_NAME);
        const countryStore = tx.objectStore(COUNTRY_TABLE_NAME);
        const departmentStore = tx.objectStore(DEPARTMENT_TABLE_NAME);
        const deviceStore = tx.objectStore(DEVICE_TABLE_NAME);
        const domainStore = tx.objectStore(DOMAIN_TABLE_NAME);
        const farmStore = tx.objectStore(FARM_TABLE_NAME);
        const ftpDetailStore = tx.objectStore(FTP_DETAIL_TABLE_NAME);
        const inspectionPointStore = tx.objectStore(INSPECTION_POINT_TABLE_NAME);
        const inventoryStore = tx.objectStore(INVENTORY_TABLE_NAME);
        const lotTypeStore = tx.objectStore(LOT_TYPE_TABLE_NAME);
        const markStore = tx.objectStore(MARK_TABLE_NAME);
        const marketStore = tx.objectStore(MARKET_TABLE_NAME);
        const materialStore = tx.objectStore(MATERIAL_TABLE_NAME);
        const materialTypeStore = tx.objectStore(MATERIAL_TYPE_TABLE_NAME);
        const outletStore = tx.objectStore(OUTLET_TABLE_NAME);
        const packCategoryStore = tx.objectStore(PACK_CATEGORY_TABLE_NAME);
        const packLineStore = tx.objectStore(PACK_LINE_TABLE_NAME);
        const palletBaseTypeStore = tx.objectStore(PALLET_BASE_TYPE_TABLE_NAME);
        const printerStore = tx.objectStore(PRINTER_TABLE_NAME);
        const projectStore = tx.objectStore(PROJECT_TABLE_NAME);
        const printServerStore = tx.objectStore(PRINT_SERVER_TABLE_NAME);
        const salesPointStore = tx.objectStore(SALES_POINT_TABLE_NAME);
        const regionStore = tx.objectStore(REGION_TABLE_NAME);
        const reportStore = tx.objectStore(REPORT_TABLE_NAME);
        const siteSettingStore = tx.objectStore(SITE_SETTING_TABLE_NAME);
        const storageUnitStore = tx.objectStore(STORAGE_UNIT_TABLE_NAME);
        const truckTypeStore = tx.objectStore(TRUCK_TYPE_TABLE_NAME);
        const unitOfMeasureStore = tx.objectStore(UNIT_OF_MEASURE_TABLE_NAME);
        const unitOfMeasureTypeStore = tx.objectStore(UNIT_OF_MEASURE_TYPE_TABLE_NAME);

        masterData.organizations.forEach((x) => {
            organizationStore.put(x, x.guid);
        });

        masterData.sites.forEach((x) => {
            siteStore.put(x, x.guid);
        });

        masterData.accreditations.forEach((x) => {
            accreditationStore.put(x, x.guid);
        });

        masterData.agreementCodes.forEach((x) => {
            agreementCodeStore.put(x, x.guid);
        });

        masterData.barcodeRanges.forEach((x) => {
            barcodeRangeStore.put(x, x.guid);
        });

        masterData.carriers.forEach((x) => {
            carrierStore.put(x, x.guid);
        });

        masterData.colours.forEach((x) => {
            colourStore.put(x, x.guid);
        });

        masterData.commodities.forEach((x) => {
            commodityStore.put(x, x.guid);
        });

        masterData.commodityConditions.forEach((x) => {
            commodityConditionStore.put(x, x.guid);
        });

        masterData.commodityStates.forEach((x) => {
            commodityStateStore.put(x, x.guid);
        });

        masterData.grades.forEach((x) => {
            gradeStore.put(x, x.guid);
        });

        masterData.orchards.forEach((x) => {
            orchardStore.put(x, x.guid);
        });

        masterData.packs.forEach((x) => {
            packStore.put(x, x.guid);
        });

        masterData.seasons.forEach((x) => {
            seasonStore.put(x, x.guid);
        });

        masterData.sizes.forEach((x) => {
            sizeStore.put(x, x.guid);
        });

        masterData.varieties.forEach((x) => {
            varietyStore.put(x, x.guid);
        });

        masterData.contactInfos.forEach((x) => {
            contactInfoStore.put(x, x.guid);
        });

        masterData.countries.forEach((x) => {
            countryStore.put(x, x.guid);
        });

        masterData.departments.forEach((x) => {
            departmentStore.put(x, x.guid);
        });

        masterData.devices.forEach((x) => {
            deviceStore.put(x, x.guid);
        });

        masterData.domains.forEach((x) => {
            domainStore.put(x, x.guid);
        });

        masterData.farms.forEach((x) => {
            farmStore.put(x, x.guid);
        });

        masterData.ftpDetails.forEach((x) => {
            ftpDetailStore.put(x, x.guid);
        });

        masterData.inspectionPoints.forEach((x) => {
            inspectionPointStore.put(x, x.guid);
        });

        masterData.inventories.forEach((x) => {
            inventoryStore.put(x, x.guid);
        });

        masterData.lotTypes.forEach((x) => {
            lotTypeStore.put(x, x.guid);
        });

        masterData.marks.forEach((x) => {
            markStore.put(x, x.guid);
        });

        masterData.markets.forEach((x) => {
            marketStore.put(x, x.guid);
        });

        masterData.materials.forEach((x) => {
            materialStore.put(x, x.guid);
        });

        masterData.materialTypes.forEach((x) => {
            materialTypeStore.put(x, x.guid);
        });

        masterData.outlets.forEach((x) => {
            outletStore.put(x, x.guid);
        });

        masterData.packCategories.forEach((x) => {
            packCategoryStore.put(x, x.guid);
        });

        masterData.packLines.forEach((x) => {
            packLineStore.put(x, x.guid);
        });

        masterData.palletBaseTypes.forEach((x) => {
            palletBaseTypeStore.put(x, x.guid);
        });

        masterData.printers.forEach((x) => {
            printerStore.put(x, x.guid);
        });

        masterData.projects.forEach((x) => {
            projectStore.put(x, x.guid);
        });

        masterData.printServers.forEach((x) => {
            printServerStore.put(x, x.guid);
        });

        masterData.salesPoints.forEach((x) => {
            salesPointStore.put(x, x.guid);
        });

        masterData.regions.forEach((x) => {
            regionStore.put(x, x.guid);
        });

        masterData.reports.forEach((x) => {
            reportStore.put(x, x.guid);
        });

        masterData.siteSettings.forEach((x) => {
            siteSettingStore.put(x, x.guid);
        });

        masterData.storageUnits.forEach((x) => {
            storageUnitStore.put(x, x.guid);
        });

        masterData.truckTypes.forEach((x) => {
            truckTypeStore.put(x, x.guid);
        });

        masterData.unitOfMeasures.forEach((x) => {
            unitOfMeasureStore.put(x, x.guid);
        });

        masterData.unitOfMeasureTypes.forEach((x) => {
            unitOfMeasureTypeStore.put(x, x.guid);
        });

        const updatedOrganizations = await tx.objectStore<IOrganization>(ORGANIZATION_TABLE_NAME).getAll();
        const updatedSites = await tx.objectStore<ISite>(SITE_TABLE_NAME).getAll();
        const updatedAccreditations = await tx.objectStore<IAccreditation>(ACCREDITATION_TABLE_NAME).getAll();
        const updatedAgreementCodes = await tx.objectStore<IAgreementCode>(AGREEMENT_CODE_TABLE_NAME).getAll();
        const updatedBarcodeRanges = await tx.objectStore<IBarcodeRange>(BARCODE_RANGE_TABLE_NAME).getAll();
        const updatedCarriers = await tx.objectStore<ICarrier>(CARRIER_TABLE_NAME).getAll();
        const updatedColours = await tx.objectStore<IColour>(COLOUR_TABLE_NAME).getAll();
        const updatedCommodities = await tx.objectStore<ICommodity>(COMMODITY_TABLE_NAME).getAll();
        const updatedCommodityConditions = await tx.objectStore<ICommodityCondition>(COMMODITY_CONDITION_TABLE_NAME).getAll();
        const updatedCommodityStates = await tx.objectStore<ICommodityState>(COMMODITY_STATE_TABLE_NAME).getAll();
        const updatedGrades = await tx.objectStore<IGrade>(GRADE_TABLE_NAME).getAll();
        const updatedOrchards = await tx.objectStore<IOrchard>(ORCHARD_TABLE_NAME).getAll();
        const updatedPacks = await tx.objectStore<IPack>(PACK_TABLE_NAME).getAll();
        const updatedSeasons = await tx.objectStore<ISeason>(SEASON_TABLE_NAME).getAll();
        const updatedSizes = await tx.objectStore<ISize>(SIZE_TABLE_NAME).getAll();
        const updatedVarieties = await tx.objectStore<IVariety>(VARIETY_TABLE_NAME).getAll();
        const updatedContactInfos = await tx.objectStore<IContactInfo>(CONTACT_INFO_TABLE_NAME).getAll();
        const updatedCountries = await tx.objectStore<ICountry>(COUNTRY_TABLE_NAME).getAll();
        const updatedDepartments = await tx.objectStore<IDepartment>(DEPARTMENT_TABLE_NAME).getAll();
        const updatedDevices = await tx.objectStore<IDevice>(DEVICE_TABLE_NAME).getAll();
        const updatedDomains = await tx.objectStore<IDomain>(DOMAIN_TABLE_NAME).getAll();
        const updatedFarms = await tx.objectStore<IFarm>(FARM_TABLE_NAME).getAll();
        const updatedFtpDetails = await tx.objectStore<IFtpDetail>(FTP_DETAIL_TABLE_NAME).getAll();
        const updatedInspectionPoints = await tx.objectStore<IInspectionPoint>(INSPECTION_POINT_TABLE_NAME).getAll();
        const updatedInventories = await tx.objectStore<IInventory>(INVENTORY_TABLE_NAME).getAll();
        const updatedLotTypes = await tx.objectStore<ILotType>(LOT_TYPE_TABLE_NAME).getAll();
        const updatedMarks = await tx.objectStore<IMark>(MARK_TABLE_NAME).getAll();
        const updatedMarkets = await tx.objectStore<IMarket>(MARKET_TABLE_NAME).getAll();
        const updatedMaterials = await tx.objectStore<IMaterial>(MATERIAL_TABLE_NAME).getAll();
        const updatedMaterialTypes = await tx.objectStore<IMaterialType>(MATERIAL_TYPE_TABLE_NAME).getAll();
        const updatedOutlets = await tx.objectStore<IOutlet>(OUTLET_TABLE_NAME).getAll();
        const updatedPackCategories = await tx.objectStore<IPackCategory>(PACK_CATEGORY_TABLE_NAME).getAll();
        const updatedPackLines = await tx.objectStore<IPackLine>(PACK_LINE_TABLE_NAME).getAll();
        const updatedPalletBaseTypes = await tx.objectStore<IPalletBaseType>(PALLET_BASE_TYPE_TABLE_NAME).getAll();
        const updatedPrinters = await tx.objectStore<IPrinter>(PRINTER_TABLE_NAME).getAll();
        const updatedProjects = await tx.objectStore<IProject>(PROJECT_TABLE_NAME).getAll();
        const updatedPrintServers = await tx.objectStore<IPrintServer>(PRINT_SERVER_TABLE_NAME).getAll();
        const updatedSalesPoints = await tx.objectStore<ISalesPoint>(SALES_POINT_TABLE_NAME).getAll();
        const updatedRegions = await tx.objectStore<IRegion>(REGION_TABLE_NAME).getAll();
        const updatedReports = await tx.objectStore<IReport>(REPORT_TABLE_NAME).getAll();
        const updatedSiteSettings = await tx.objectStore<ISiteSetting>(SITE_SETTING_TABLE_NAME).getAll();
        const updatedStorageUnits = await tx.objectStore<IStorageUnit>(STORAGE_UNIT_TABLE_NAME).getAll();
        const updatedTruckTypes = await tx.objectStore<ITruckType>(TRUCK_TYPE_TABLE_NAME).getAll();
        const updatedUnitOfMeasures = await tx.objectStore<IUnitOfMeasure>(UNIT_OF_MEASURE_TABLE_NAME).getAll();
        const updatedUnitOfMeasureTypes = await tx.objectStore<IUnitOfMeasureType>(UNIT_OF_MEASURE_TYPE_TABLE_NAME).getAll();

        const updatedMasterData : IMasterDataSyncData = {
            organizations: updatedOrganizations,
            sites: updatedSites,
            accreditations: updatedAccreditations,
            agreementCodes: updatedAgreementCodes,
            barcodeRanges: updatedBarcodeRanges,
            carriers: updatedCarriers,
            colours: updatedColours,
            commodities: updatedCommodities,
            commodityConditions: updatedCommodityConditions,
            commodityStates: updatedCommodityStates,
            grades: updatedGrades,
            orchards: updatedOrchards,
            packs: updatedPacks,
            seasons: updatedSeasons,
            sizes: updatedSizes,
            varieties: updatedVarieties,
            contactInfos: updatedContactInfos,
            countries: updatedCountries,
            departments: updatedDepartments,
            devices: updatedDevices,
            domains: updatedDomains,
            farms: updatedFarms,
            ftpDetails: updatedFtpDetails,
            inspectionPoints: updatedInspectionPoints,
            inventories: updatedInventories,
            lotTypes: updatedLotTypes,
            marks: updatedMarks,
            markets: updatedMarkets,
            materials: updatedMaterials,
            materialTypes: updatedMaterialTypes,
            outlets: updatedOutlets,
            packCategories: updatedPackCategories,
            packLines: updatedPackLines,
            palletBaseTypes: updatedPalletBaseTypes,
            printers: updatedPrinters,
            projects: updatedProjects,
            printServers: updatedPrintServers,
            regions: updatedRegions,
            reports: updatedReports,
            siteSettings: updatedSiteSettings,
            salesPoints: updatedSalesPoints,
            storageUnits: updatedStorageUnits,
            truckTypes: updatedTruckTypes,
            unitOfMeasures: updatedUnitOfMeasures,
            unitOfMeasureTypes: updatedUnitOfMeasureTypes,
        };

        await tx.complete;

        dataSetAllSyncedMasterData(updatedMasterData);
    }
}

/**
 * Checks if master data object stores is populated
 * If object stores is populated. Retrieves data and populate redux master data store
 */
export async function shouldSyncMasterData() {
    let syncData = false;
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        // gets transaction containing all master data object stores
        const tx = db.transaction([ORGANIZATION_TABLE_NAME,
                                   SITE_TABLE_NAME,
                                   ACCREDITATION_TABLE_NAME,
                                   AGREEMENT_CODE_TABLE_NAME,
                                   BARCODE_RANGE_TABLE_NAME,
                                   CARRIER_TABLE_NAME,
                                   COLOUR_TABLE_NAME,
                                   COMMODITY_TABLE_NAME,
                                   COMMODITY_CONDITION_TABLE_NAME,
                                   COMMODITY_STATE_TABLE_NAME,
                                   GRADE_TABLE_NAME,
                                   ORCHARD_TABLE_NAME,
                                   PACK_TABLE_NAME,
                                   SEASON_TABLE_NAME,
                                   SIZE_TABLE_NAME,
                                   VARIETY_TABLE_NAME,
                                   CONTACT_INFO_TABLE_NAME,
                                   COUNTRY_TABLE_NAME,
                                   DEPARTMENT_TABLE_NAME,
                                   DEVICE_TABLE_NAME,
                                   DOMAIN_TABLE_NAME,
                                   FARM_TABLE_NAME,
                                   FTP_DETAIL_TABLE_NAME,
                                   INSPECTION_POINT_TABLE_NAME,
                                   INVENTORY_TABLE_NAME,
                                   LOT_TYPE_TABLE_NAME,
                                   MARK_TABLE_NAME,
                                   MARKET_TABLE_NAME,
                                   MATERIAL_TABLE_NAME,
                                   MATERIAL_TYPE_TABLE_NAME,
                                   OUTLET_TABLE_NAME,
                                   PACK_CATEGORY_TABLE_NAME,
                                   PACK_LINE_TABLE_NAME,
                                   PALLET_BASE_TYPE_TABLE_NAME,
                                   PRINTER_TABLE_NAME,
                                   PROJECT_TABLE_NAME,
                                   PRINT_SERVER_TABLE_NAME,
                                   SALES_POINT_TABLE_NAME,
                                   REGION_TABLE_NAME,
                                   REPORT_TABLE_NAME,
                                   SITE_SETTING_TABLE_NAME,
                                   STORAGE_UNIT_TABLE_NAME,
                                   TRUCK_TYPE_TABLE_NAME,
                                   UNIT_OF_MEASURE_TABLE_NAME,
                                   UNIT_OF_MEASURE_TYPE_TABLE_NAME], 'readwrite');

        const organizations = await tx.objectStore<IOrganization>(ORGANIZATION_TABLE_NAME).getAll();
        const sites = await tx.objectStore<ISite>(SITE_TABLE_NAME).getAll();
        const accreditations = await tx.objectStore<IAccreditation>(ACCREDITATION_TABLE_NAME).getAll();
        const agreementCodes = await tx.objectStore<IAgreementCode>(AGREEMENT_CODE_TABLE_NAME).getAll();
        const barcodeRanges = await tx.objectStore<IBarcodeRange>(BARCODE_RANGE_TABLE_NAME).getAll();
        const carriers = await tx.objectStore<ICarrier>(CARRIER_TABLE_NAME).getAll();
        const colours = await tx.objectStore<IColour>(COLOUR_TABLE_NAME).getAll();
        const commodities = await tx.objectStore<ICommodity>(COMMODITY_TABLE_NAME).getAll();
        const commodityConditions = await tx.objectStore<ICommodityCondition>(COMMODITY_CONDITION_TABLE_NAME).getAll();
        const commodityStates = await tx.objectStore<ICommodityState>(COMMODITY_STATE_TABLE_NAME).getAll();
        const grades = await tx.objectStore<IGrade>(GRADE_TABLE_NAME).getAll();
        const orchards = await tx.objectStore<IOrchard>(ORCHARD_TABLE_NAME).getAll();
        const packs = await tx.objectStore<IPack>(PACK_TABLE_NAME).getAll();
        const seasons = await tx.objectStore<ISeason>(SEASON_TABLE_NAME).getAll();
        const sizes = await tx.objectStore<ISize>(SIZE_TABLE_NAME).getAll();
        const varieties = await tx.objectStore<IVariety>(VARIETY_TABLE_NAME).getAll();
        const contactInfos = await tx.objectStore<IContactInfo>(CONTACT_INFO_TABLE_NAME).getAll();
        const countries = await tx.objectStore<ICountry>(COUNTRY_TABLE_NAME).getAll();
        const departments = await tx.objectStore<IDepartment>(DEPARTMENT_TABLE_NAME).getAll();
        const devices = await tx.objectStore<IDevice>(DEVICE_TABLE_NAME).getAll();
        const domains = await tx.objectStore<IDomain>(DOMAIN_TABLE_NAME).getAll();
        const farms = await tx.objectStore<IFarm>(FARM_TABLE_NAME).getAll();
        const ftpDetails = await tx.objectStore<IFtpDetail>(FTP_DETAIL_TABLE_NAME).getAll();
        const inspectionPoints = await tx.objectStore<IInspectionPoint>(INSPECTION_POINT_TABLE_NAME).getAll();
        const inventories = await tx.objectStore<IInventory>(INVENTORY_TABLE_NAME).getAll();
        const lotTypes = await tx.objectStore<ILotType>(LOT_TYPE_TABLE_NAME).getAll();
        const marks = await tx.objectStore<IMark>(MARK_TABLE_NAME).getAll();
        const markets = await tx.objectStore<IMarket>(MARKET_TABLE_NAME).getAll();
        const materials = await tx.objectStore<IMaterial>(MATERIAL_TABLE_NAME).getAll();
        const materialTypes = await tx.objectStore<IMaterialType>(MATERIAL_TYPE_TABLE_NAME).getAll();
        const outlets = await tx.objectStore<IOutlet>(OUTLET_TABLE_NAME).getAll();
        const packCategories = await tx.objectStore<IPackCategory>(PACK_CATEGORY_TABLE_NAME).getAll();
        const packLines = await tx.objectStore<IPackLine>(PACK_LINE_TABLE_NAME).getAll();
        const palletBaseTypes = await tx.objectStore<IPalletBaseType>(PALLET_BASE_TYPE_TABLE_NAME).getAll();
        const printers = await tx.objectStore<IPrinter>(PRINTER_TABLE_NAME).getAll();
        const projects = await tx.objectStore<IProject>(PROJECT_TABLE_NAME).getAll();
        const printServers = await tx.objectStore<IPrintServer>(PRINT_SERVER_TABLE_NAME).getAll();
        const salesPoints = await tx.objectStore<ISalesPoint>(SALES_POINT_TABLE_NAME).getAll();
        const regions = await tx.objectStore<IRegion>(REGION_TABLE_NAME).getAll();
        const reports = await tx.objectStore<IReport>(REPORT_TABLE_NAME).getAll();
        const siteSettings = await tx.objectStore<ISiteSetting>(SITE_SETTING_TABLE_NAME).getAll();
        const storageUnits = await tx.objectStore<IStorageUnit>(STORAGE_UNIT_TABLE_NAME).getAll();
        const truckTypes = await tx.objectStore<ITruckType>(TRUCK_TYPE_TABLE_NAME).getAll();
        const unitOfMeasures = await tx.objectStore<IUnitOfMeasure>(UNIT_OF_MEASURE_TABLE_NAME).getAll();
        const unitOfMeasureTypes = await tx.objectStore<IUnitOfMeasureType>(UNIT_OF_MEASURE_TYPE_TABLE_NAME).getAll();

        const masterData : IMasterDataSyncData = {
            organizations,
            sites,
            accreditations,
            agreementCodes,
            barcodeRanges,
            carriers,
            colours,
            commodities,
            commodityConditions,
            commodityStates,
            grades,
            orchards,
            packs,
            seasons,
            sizes,
            varieties,
            contactInfos,
            countries,
            departments,
            devices,
            domains,
            farms,
            ftpDetails,
            inspectionPoints,
            inventories,
            lotTypes,
            marks,
            markets,
            materials,
            materialTypes,
            outlets,
            packCategories,
            packLines,
            palletBaseTypes,
            printers,
            projects,
            printServers,
            regions,
            reports,
            siteSettings,
            salesPoints,
            storageUnits,
            truckTypes,
            unitOfMeasures,
            unitOfMeasureTypes,
        };

        await tx.complete;

        if (organizations.length < 1
            || sites.length < 1
            || accreditations.length < 1
            || agreementCodes.length < 1
            || barcodeRanges.length < 1
            || carriers.length < 1
            || colours.length < 1
            || commodities.length < 1
            || commodityConditions.length < 1
            || commodityStates.length < 1
            || grades.length < 1
            || orchards.length < 1
            || packs.length < 1
            || seasons.length < 1
            || sizes.length < 1
            || varieties.length < 1
            || contactInfos.length < 1
            || countries.length < 1
            || departments.length < 1
            || devices.length < 1
            || domains.length < 1
            || farms.length < 1
            || ftpDetails.length < 1
            || inspectionPoints.length < 1
            || inventories.length < 1
            || lotTypes.length < 1
            || marks.length < 1
            || markets.length < 1
            || materials.length < 1
            || materialTypes.length < 1
            || outlets.length < 1
            || packCategories.length < 1
            || packLines.length < 1
            || palletBaseTypes.length < 1
            || printers.length < 1
            || projects.length < 1
            || printServers.length < 1
            || regions.length < 1
            || reports.length < 1
            || siteSettings.length < 1
            || salesPoints.length < 1
            || storageUnits.length < 1
            || truckTypes.length < 1
            || unitOfMeasures.length < 1
            || unitOfMeasureTypes.length < 1) {
            syncData = true;
        } else {
            syncData = false;

            const isReduxMasterDataPopulated = checkIfReduxMasterDataIsPopulated();
            if (!isReduxMasterDataPopulated) {
                dataSetAllSyncedMasterData(masterData);
            }
        }
    }

    return syncData;
}

/**
 * Popuplates organization object store. Adding new record or updating if the record already exists
 *
 */
export async function setOrganizationMasterDataIndexedDB(organizations : Array<IOrganization>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ORGANIZATION_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(ORGANIZATION_TABLE_NAME);

        organizations.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves organizations.
 */
export async function getOrganizationMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ORGANIZATION_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IOrganization>(ORGANIZATION_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates site object store. Adding new record or updating if the record already exists
 *
 */
export async function setSiteMasterDataIndexedDB(sites : Array<ISite>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SITE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(SITE_TABLE_NAME);

        sites.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves sites.
 */
export async function getSiteMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SITE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IOrganization>(SITE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates accreditation object store. Adding new record or updating if the record already exists
 *
 */
export async function setAccreditationMasterDataIndexedDB(accreditations : Array<IAccreditation>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ACCREDITATION_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(ACCREDITATION_TABLE_NAME);

        accreditations.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves accreditations.
 */
export async function getAccreditationMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ACCREDITATION_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IAccreditation>(ACCREDITATION_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates agreement code object store. Adding new record or updating if the record already exists
 *
 */
export async function setAgreementCodesMasterDataIndexedDB(agreementCodes : Array<IAgreementCode>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(AGREEMENT_CODE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(AGREEMENT_CODE_TABLE_NAME);

        agreementCodes.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves agreement codes.
 */
export async function getAgreementCodesMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(AGREEMENT_CODE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IAgreementCode>(AGREEMENT_CODE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates barcode range object store. Adding new record or updating if the record already exists
 *
 */
export async function setBarcodeRangeMasterDataIndexedDB(barcodeRanges : Array<IBarcodeRange>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(BARCODE_RANGE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(BARCODE_RANGE_TABLE_NAME);

        barcodeRanges.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves barcode ranges.
 */
export async function getBarcodeRangeMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(BARCODE_RANGE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IBarcodeRange>(BARCODE_RANGE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates carrier object store. Adding new record or updating if the record already exists
 *
 */
export async function setCarrierMasterDataIndexedDB(carriers : Array<ICarrier>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(CARRIER_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(CARRIER_TABLE_NAME);

        carriers.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves carriers.
 */
export async function getCarrierMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(CARRIER_TABLE_NAME, 'readonly');

        const result = tx.objectStore<ICarrier>(CARRIER_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates commodity object store. Adding new record or updating if the record already exists
 *
 */
export async function setCommodityMasterDataIndexedDB(commodities : Array<ICommodity>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(COMMODITY_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(COMMODITY_TABLE_NAME);

        commodities.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves commodities.
 */
export async function getCommodityMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(COMMODITY_TABLE_NAME, 'readonly');

        const result = tx.objectStore<ICommodity>(COMMODITY_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates commodity condition object store. Adding new record or updating if the record already exists
 *
 */
export async function setCommodityConditionMasterDataIndexedDB(commodityConditions : Array<ICommodityCondition>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(COMMODITY_CONDITION_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(COMMODITY_CONDITION_TABLE_NAME);

        commodityConditions.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves commodity conditions.
 */
export async function getCommodityConditionMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(COMMODITY_CONDITION_TABLE_NAME, 'readonly');

        const result = tx.objectStore<ICommodityCondition>(COMMODITY_CONDITION_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates colour object store. Adding new record or updating if the record already exists
 *
 */
export async function setColourMasterDataIndexedDB(colours : Array<IColour>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(COLOUR_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(COLOUR_TABLE_NAME);

        colours.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves colours.
 */
export async function getColourMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(COLOUR_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IColour>(COLOUR_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates commodity state object store. Adding new record or updating if the record already exists
 *
 */
export async function setCommodityStateMasterDataIndexedDB(commodityStates : Array<ICommodityState>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(COMMODITY_STATE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(COMMODITY_STATE_TABLE_NAME);

        commodityStates.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves commodity states.
 */
export async function getCommodityStateMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(COMMODITY_STATE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<ICommodityState>(COMMODITY_STATE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates grade object store. Adding new record or updating if the record already exists
 *
 */
export async function setGradeMasterDataIndexedDB(grades : Array<IGrade>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(GRADE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(GRADE_TABLE_NAME);

        grades.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves grades.
 */
export async function getGradeMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(GRADE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IGrade>(GRADE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates orchard object store. Adding new record or updating if the record already exists
 *
 */
export async function setOrchardMasterDataIndexedDB(orchards : Array<IOrchard>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ORCHARD_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(ORCHARD_TABLE_NAME);

        orchards.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves orchards.
 */
export async function getOrchardMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ORCHARD_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IOrchard>(ORCHARD_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates pack object store. Adding new record or updating if the record already exists
 *
 */
export async function setPackMasterDataIndexedDB(packs : Array<IPack>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PACK_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(PACK_TABLE_NAME);

        packs.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves packs.
 */
export async function getPackMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PACK_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IPack>(PACK_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates season object store. Adding new record or updating if the record already exists
 *
 */
export async function setSeasonMasterDataIndexedDB(seasons : Array<ISeason>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SEASON_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(SEASON_TABLE_NAME);

        seasons.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves seasons.
 */
export async function getSeasonMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SEASON_TABLE_NAME, 'readonly');

        const result = tx.objectStore<ISeason>(SEASON_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates size object store. Adding new record or updating if the record already exists
 *
 */
export async function setSizeMasterDataIndexedDB(sizes : Array<ISize>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SIZE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(SIZE_TABLE_NAME);

        sizes.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves sizes.
 */
export async function getSizeMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SIZE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<ISize>(SIZE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates variety object store. Adding new record or updating if the record already exists
 *
 */
export async function setVarietyMasterDataIndexedDB(varieties : Array<IVariety>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(VARIETY_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(VARIETY_TABLE_NAME);

        varieties.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves varieties.
 */
export async function getVarietyMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(VARIETY_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IVariety>(VARIETY_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates contact info object store. Adding new record or updating if the record already exists
 *
 */
export async function setContactInfoMasterDataIndexedDB(contactInfoData : Array<IContactInfo>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(CONTACT_INFO_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(CONTACT_INFO_TABLE_NAME);

        contactInfoData.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves contactInfoData.
 */
export async function getContactInfoMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(CONTACT_INFO_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IContactInfo>(CONTACT_INFO_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates country object store. Adding new record or updating if the record already exists
 *
 */
export async function setCountryMasterDataIndexedDB(countries : Array<ICountry>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(COUNTRY_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(COUNTRY_TABLE_NAME);

        countries.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves countries.
 */
export async function getCountryMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(COUNTRY_TABLE_NAME, 'readonly');

        const result = tx.objectStore<ICountry>(COUNTRY_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates department object store. Adding new record or updating if the record already exists
 *
 */
export async function setDepartmentMasterDataIndexedDB(departments : Array<IDepartment>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(DEPARTMENT_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(DEPARTMENT_TABLE_NAME);

        departments.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves departments.
 */
export async function getDepartmentMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(DEPARTMENT_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IDepartment>(DEPARTMENT_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates device object store. Adding new record or updating if the record already exists
 *
 */
export async function setDeviceMasterDataIndexedDB(devices : Array<IDevice>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(DEVICE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(DEVICE_TABLE_NAME);

        devices.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves devices.
 */
export async function getDeviceMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(DEVICE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IDevice>(DEVICE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates domain object store. Adding new record or updating if the record already exists
 *
 */
export async function setDomainMasterDataIndexedDB(domains : Array<IDomain>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(DOMAIN_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(DOMAIN_TABLE_NAME);

        domains.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves domains.
 */
export async function getDomainMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(DOMAIN_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IDomain>(DOMAIN_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates farm object store. Adding new record or updating if the record already exists
 *
 */
export async function setFarmMasterDataIndexedDB(farms : Array<IFarm>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(FARM_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(FARM_TABLE_NAME);

        farms.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves farms.
 */
export async function getFarmMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(FARM_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IFarm>(FARM_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates ftp detail object store. Adding new record or updating if the record already exists
 *
 */
export async function setFtpDetailMasterDataIndexedDB(ftpDetails : Array<IFtpDetail>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(FTP_DETAIL_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(FTP_DETAIL_TABLE_NAME);

        ftpDetails.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves ftp details.
 */
export async function getFtpDetailMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(FTP_DETAIL_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IFtpDetail>(FTP_DETAIL_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates inspection point object store. Adding new record or updating if the record already exists
 *
 */
export async function setInspectionPointMasterDataIndexedDB(inspectionPoints : Array<IInspectionPoint>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(INSPECTION_POINT_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(INSPECTION_POINT_TABLE_NAME);

        inspectionPoints.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves inspection points.
 */
export async function getInspectionPointMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(INSPECTION_POINT_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IInspectionPoint>(INSPECTION_POINT_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates inventory object store. Adding new record or updating if the record already exists
 *
 */
export async function setInventoryMasterDataIndexedDB(inventories : Array<IInventory>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(INVENTORY_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(INVENTORY_TABLE_NAME);

        inventories.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves inventories.
 */
export async function getInventoryMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(INVENTORY_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IInventory>(INVENTORY_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates lot type object store. Adding new record or updating if the record already exists
 *
 */
export async function setLotTypeMasterDataIndexedDB(lotTypes : Array<ILotType>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(LOT_TYPE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(LOT_TYPE_TABLE_NAME);

        lotTypes.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves lot types.
 */
export async function getLotTypeMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(LOT_TYPE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<ILotType>(LOT_TYPE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates mark object store. Adding new record or updating if the record already exists
 *
 */
export async function setMarksMasterDataIndexedDB(marks : Array<IMark>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(MARK_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(MARK_TABLE_NAME);

        marks.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves marks.
 */
export async function getMarksMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(MARK_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IMark>(MARK_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates market object store. Adding new record or updating if the record already exists
 *
 */
export async function setMarketsMasterDataIndexedDB(markets : Array<IMarket>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(MARKET_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(MARKET_TABLE_NAME);

        markets.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves markets.
 */
export async function getMarketsMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(MARKET_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IMarket>(MARKET_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates material object store. Adding new record or updating if the record already exists
 *
 */
export async function setMaterialsMasterDataIndexedDB(materials : Array<IMaterial>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(MATERIAL_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(MATERIAL_TABLE_NAME);

        materials.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves materials.
 */
export async function getMaterialsMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(MATERIAL_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IMaterial>(MATERIAL_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates material type object store. Adding new record or updating if the record already exists
 *
 */
export async function setMaterialTypesMasterDataIndexedDB(materialTypes : Array<IMaterialType>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(MATERIAL_TYPE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(MATERIAL_TYPE_TABLE_NAME);

        materialTypes.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves material types.
 */
export async function getMaterialTypesMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(MATERIAL_TYPE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IMaterialType>(MATERIAL_TYPE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates outlet object store. Adding new record or updating if the record already exists
 *
 */
export async function setOutletMasterDataIndexedDB(outlets : Array<IOutlet>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(OUTLET_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(OUTLET_TABLE_NAME);

        outlets.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves outlets.
 */
export async function getOutletMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(OUTLET_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IOutlet>(OUTLET_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates pack category object store. Adding new record or updating if the record already exists
 *
 */
export async function setPackCategoryMasterDataIndexedDB(packCategories : Array<IPackCategory>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PACK_CATEGORY_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(PACK_CATEGORY_TABLE_NAME);

        packCategories.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves pack categories.
 */
export async function getPackCategoryMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PACK_CATEGORY_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IPackCategory>(PACK_CATEGORY_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates pack line object store. Adding new record or updating if the record already exists
 *
 */
export async function setPackLineMasterDataIndexedDB(packLines : Array<IPackLine>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PACK_LINE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(PACK_LINE_TABLE_NAME);

        packLines.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves pack lines.
 */
export async function getPackLineMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PACK_LINE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IPackLine>(PACK_LINE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates pallet base type object store. Adding new record or updating if the record already exists
 *
 */
export async function setPalletBaseTypeMasterDataIndexedDB(palletBaseTypes : Array<IPalletBaseType>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PALLET_BASE_TYPE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(PALLET_BASE_TYPE_TABLE_NAME);

        palletBaseTypes.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves pallet base types.
 */
export async function getPalletBaseTypeMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PALLET_BASE_TYPE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IPalletBaseType>(PALLET_BASE_TYPE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates printer object store. Adding new record or updating if the record already exists
 *
 */
export async function setPrinterMasterDataIndexedDB(printers : Array<IPrinter>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PRINTER_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(PRINTER_TABLE_NAME);

        printers.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves printers.
 */
export async function getPrinterMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PRINTER_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IPrinter>(PRINTER_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates print server object store. Adding new record or updating if the record already exists
 *
 */
export async function setPrintServerMasterDataIndexedDB(printServers : Array<IPrintServer>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PRINT_SERVER_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(PRINT_SERVER_TABLE_NAME);

        printServers.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves print servers.
 */
export async function getPrintServerMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PRINT_SERVER_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IPrintServer>(PRINT_SERVER_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates projects object store. Adding new record or updating if the record already exists
 *
 */
export async function setProjectMasterDataIndexedDB(projects : Array<IProject>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PROJECT_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(PROJECT_TABLE_NAME);

        projects.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves projects.
 */
export async function getProjectMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PROJECT_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IProject>(PROJECT_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates region object store. Adding new record or updating if the record already exists
 *
 */
export async function setRegionMasterDataIndexedDB(regions : Array<IRegion>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(REGION_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(REGION_TABLE_NAME);

        regions.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves regions.
 */
export async function getRegionMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(REGION_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IRegion>(REGION_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates report object store. Adding new record or updating if the record already exists
 *
 */
export async function setReportMasterDataIndexedDB(reports : Array<IReport>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(REPORT_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(REPORT_TABLE_NAME);

        reports.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves reports.
 */
export async function getReportMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(REPORT_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IReport>(REPORT_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates report object store. Adding new record or updating if the record already exists
 *
 */
export async function setSiteSettingMasterDataIndexedDB(siteSettings : Array<ISiteSetting>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SITE_SETTING_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(SITE_SETTING_TABLE_NAME);

        siteSettings.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves site settings.
 */
export async function getSiteSettingMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SITE_SETTING_TABLE_NAME, 'readonly');

        const result = tx.objectStore<ISiteSetting>(SITE_SETTING_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates sales point object store. Adding new record or updating if the record already exists
 *
 */
export async function setSalesPointMasterDataIndexedDB(salesPoints : Array<ISalesPoint>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SALES_POINT_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(SALES_POINT_TABLE_NAME);

        salesPoints.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves sales points.
 */
export async function getSalesPointMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SALES_POINT_TABLE_NAME, 'readonly');

        const result = tx.objectStore<ISalesPoint>(SALES_POINT_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates storage unit object store. Adding new record or updating if the record already exists
 *
 */
export async function setStorageUnitMasterDataIndexedDB(storageUnits : Array<IStorageUnit>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(STORAGE_UNIT_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(STORAGE_UNIT_TABLE_NAME);

        storageUnits.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves storage units.
 */
export async function getStorageUnitMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(STORAGE_UNIT_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IStorageUnit>(STORAGE_UNIT_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates truck type object store. Adding new record or updating if the record already exists
 *
 */
export async function setTruckTypeMasterDataIndexedDB(truckTypes : Array<ITruckType>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(TRUCK_TYPE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(TRUCK_TYPE_TABLE_NAME);

        truckTypes.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves truck types.
 */
export async function getTruckTypeMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(TRUCK_TYPE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<ITruckType>(TRUCK_TYPE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates unit of measure object store. Adding new record or updating if the record already exists
 *
 */
export async function setUnitOfMeasureMasterDataIndexedDB(unitOfMeasures : Array<IUnitOfMeasure>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(UNIT_OF_MEASURE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(UNIT_OF_MEASURE_TABLE_NAME);

        unitOfMeasures.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves unit of measures.
 */
export async function getUnitOfMeasureMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(UNIT_OF_MEASURE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IUnitOfMeasure>(UNIT_OF_MEASURE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates unit of measure type object store. Adding new record or updating if the record already exists
 *
 */
export async function setUnitOfMeasureTypeMasterDataIndexedDB(unitOfMeasureTypes : Array<IUnitOfMeasureType>) {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(UNIT_OF_MEASURE_TYPE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(UNIT_OF_MEASURE_TYPE_TABLE_NAME);

        unitOfMeasureTypes.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves unit of measure types.
 */
export async function getUnitOfMeasureTypeMasterDataIndexedDB() {
    // checks if indexedDB is available.
    if (!!self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(UNIT_OF_MEASURE_TYPE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IUnitOfMeasureType>(UNIT_OF_MEASURE_TYPE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}


// function clearIndexedDBObjectStores() {
//     throw new Error('Function not implemented.');
// }
/* End of Master Data IndexedDB storage */
/* ****************************************************************************** */

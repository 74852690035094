import { openDb, UpgradeDB } from 'idb';
import { IUserToken } from '../@types/model/user/userToken';
import { IOrganization } from '../@types/model/masterData/organization/organization';
import { IInventory } from '../@types/model/masterData/inventory/inventory';
import { authSetSession } from '../store/auth/Actions';
import { dispatch } from '../store/Index';
import { ISite } from '../@types/model/masterData/site/site';
import {
    ACCREDITATION_TABLE_NAME,
    AGREEMENT_CODE_TABLE_NAME,
    BARCODE_RANGE_TABLE_NAME,
    CARRIER_TABLE_NAME,
    COLOUR_TABLE_NAME,
    COMMODITY_CONDITION_TABLE_NAME,
    COMMODITY_STATE_TABLE_NAME,
    COMMODITY_TABLE_NAME,
    CONTACT_INFO_TABLE_NAME,
    COUNTRY_TABLE_NAME,
    DEPARTMENT_TABLE_NAME,
    DEVICE_TABLE_NAME,
    DOMAIN_TABLE_NAME,
    FARM_TABLE_NAME,
    FTP_DETAIL_TABLE_NAME,
    GRADE_TABLE_NAME,
    INSPECTION_POINT_TABLE_NAME,
    INVENTORY_TABLE_NAME,
    LOT_TYPE_TABLE_NAME,
    MARK_TABLE_NAME,
    MARKET_TABLE_NAME,
    MATERIAL_TABLE_NAME,
    MATERIAL_TYPE_TABLE_NAME,
    ORCHARD_TABLE_NAME,
    ORGANIZATION_TABLE_NAME,
    OUTLET_TABLE_NAME,
    PACK_CATEGORY_TABLE_NAME,
    PACK_LINE_TABLE_NAME,
    PACK_TABLE_NAME,
    PALLET_BASE_TYPE_TABLE_NAME,
    PRINT_SERVER_TABLE_NAME,
    PRINTER_TABLE_NAME,
    PROJECT_TABLE_NAME,
    REGION_TABLE_NAME,
    REPORT_TABLE_NAME,
    SALES_POINT_TABLE_NAME,
    SEASON_TABLE_NAME,
    SITE_SETTING_TABLE_NAME,
    SITE_TABLE_NAME,
    SIZE_TABLE_NAME,
    STORAGE_UNIT_TABLE_NAME,
    TRUCK_TYPE_TABLE_NAME,
    UNIT_OF_MEASURE_TABLE_NAME,
    UNIT_OF_MEASURE_TYPE_TABLE_NAME,
    VARIETY_TABLE_NAME,
} from './masterDataSyncService';
import moment from 'moment';
import { IAccreditation } from '../@types/model/masterData/accreditation/accreditation';
import { IAgreementCode } from '../@types/model/masterData/agreementCode/agreementCode';
import { IBarcodeRange } from '../@types/model/masterData/barcodeRange/barcodeRange';
import { ICarrier } from '../@types/model/masterData/carrier/carrier';
import { IColour } from '../@types/model/masterData/colour/colour';
import { ICommodity } from '../@types/model/masterData/commodity/commodity';
import { ICommodityCondition } from '../@types/model/masterData/commodityCondition/commodityCondition';
import { ICommodityState } from '../@types/model/masterData/commodityState/commodityState';
import { IGrade } from '../@types/model/masterData/grade/grade';
import { IOrchard } from '../@types/model/masterData/orchard/orchard';
import { IPack } from '../@types/model/masterData/pack/pack';
import { ISeason } from '../@types/model/masterData/season/season';
import { ISize } from '../@types/model/masterData/size/size';
import { IVariety } from '../@types/model/masterData/variety/variety';
import { IContactInfo } from '../@types/model/masterData/contactInfo/contactInfo';
import { ICountry } from '../@types/model/masterData/country/country';
import { IDepartment } from '../@types/model/masterData/department/department';
import { IDevice } from '../@types/model/masterData/device/device';
import { IDomain } from '../@types/model/user/domain';
import { IFarm } from '../@types/model/masterData/farm/farm';
import { IInspectionPoint } from '../@types/model/masterData/inspectionPoint/inspectionPoint';
import { ILotType } from '../@types/model/masterData/lotType/lotType';
import { IMark } from '../@types/model/masterData/mark/mark';
import { IMarket } from '../@types/model/masterData/market/market';
import { IMaterial } from '../@types/model/masterData/material/material';
import { IMaterialType } from '../@types/model/masterData/materialType/materialType';
import { IOutlet } from '../@types/model/masterData/outlet/outlet';
import { IPackCategory } from '../@types/model/masterData/pack/packCategory';
import { IPackLine } from '../@types/model/masterData/packLine/packLine';
import { IPalletBaseType } from '../@types/model/masterData/palletBaseType/palletBaseType';
import { IPrinter } from '../@types/model/masterData/printer/printer';
import { IProject } from '../@types/model/masterData/project/project';
import { IPrintServer } from '../@types/model/masterData/printServer/printServer';
import { ISalesPoint } from '../@types/model/masterData/salesPoint/salesPoint';
import { IRegion } from '../@types/model/masterData/region/region';
import { IReport } from '../@types/model/masterData/report/report';
import { IStorageUnit } from '../@types/model/masterData/storageUnit/storageUnit';
import { ITruckType } from '../@types/model/masterData/truckType/truckType';
import { IUnitOfMeasure } from '../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import { IUnitOfMeasureType } from '../@types/model/masterData/unitOfMeasureType/unitOfMeasureType';
import { IFtpDetail } from '../@types/model/masterData/ftpDetail/ftpDetail';
import { ISiteSetting } from '../@types/model/masterData/siteSetting/siteSetting';

const SESSION_NAME = 'zz2-packman-session';
const SESSION_KEY = 'zz2-packman-token';

const MASTER_DATA_LAST_SYNC_DATE = 'master-data-last-sync-date';
const SELECTED_ORGANIZATIONS_KEY = 'selected-organizations';
const SELECTED_SITES_KEY = 'selected-sites';
const SELECTED_EXPORTER_KEY = 'selected-exporter';
const SELECTED_INVENTORY_KEY = 'selected-inventory';

const SELECTED_THEME = 'selected-theme';

const HOME_URL_KEY = 'home-url';

let sessionCallback : (userToken : IUserToken | null) => void;

export async function getLocalStorageSession() {
    let session : IUserToken | null = null;
    if (self.indexedDB) {
        session = await getSessionIndexedDB();
    } else if (self.localStorage) {
        session = getSessionLocalStorage();
    }

    if (session) {
        return session;
    } else {
        return null;
    }
}

export async function clearLocalStorage() {
    await setSessionLocalStorage(null);
    await setSessionIndexedDB(null);
}

export async function setLocalStorageSession(userToken : IUserToken | null) {
    if (userToken === null) {
        dispatch(authSetSession(userToken));
    }
    if (!!self.indexedDB) {
        await setSessionIndexedDB(userToken);
    } else if (!!self.localStorage) {
        setSessionLocalStorage(userToken);
    }

    if (!!sessionCallback) {
        sessionCallback(userToken);
    }
}

function setSessionLocalStorage(userToken : IUserToken | null) {
    if (!!userToken) {
        localStorage.setItem(SESSION_KEY, JSON.stringify(userToken));
    } else {
        localStorage.removeItem(SESSION_KEY);
    }
}

function getSessionLocalStorage() : IUserToken | null {
    const session = localStorage.getItem(SESSION_KEY);

    if (session) {
        return JSON.parse(session);
    } else {
        return null;
    }
}

/**
 * Creates all object stores up to the current DB version. i.e. for version 2, this function will execute for versions
 * 0, 1 and 2.
 *
 * @param db
 */
export function upgradeDb(db : UpgradeDB) {
    if (!db.objectStoreNames.contains(SESSION_NAME)) {
        db.createObjectStore<IUserToken, string>(SESSION_NAME);
    }
    if (!db.objectStoreNames.contains(ORGANIZATION_TABLE_NAME)) {
        db.createObjectStore<IOrganization, string>(ORGANIZATION_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(SITE_TABLE_NAME)) {
        db.createObjectStore<ISite, string>(SITE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(ACCREDITATION_TABLE_NAME)) {
        db.createObjectStore<IAccreditation, string>(ACCREDITATION_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(AGREEMENT_CODE_TABLE_NAME)) {
        db.createObjectStore<IAgreementCode, string>(AGREEMENT_CODE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(BARCODE_RANGE_TABLE_NAME)) {
        db.createObjectStore<IBarcodeRange, string>(BARCODE_RANGE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(CARRIER_TABLE_NAME)) {
        db.createObjectStore<ICarrier, string>(CARRIER_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(COLOUR_TABLE_NAME)) {
        db.createObjectStore<IColour, string>(COLOUR_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(COMMODITY_TABLE_NAME)) {
        db.createObjectStore<ICommodity, string>(COMMODITY_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(COMMODITY_CONDITION_TABLE_NAME)) {
        db.createObjectStore<ICommodityCondition, string>(COMMODITY_CONDITION_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(COMMODITY_STATE_TABLE_NAME)) {
        db.createObjectStore<ICommodityState, string>(COMMODITY_STATE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(GRADE_TABLE_NAME)) {
        db.createObjectStore<IGrade, string>(GRADE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(ORCHARD_TABLE_NAME)) {
        db.createObjectStore<IOrchard, string>(ORCHARD_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(PACK_TABLE_NAME)) {
        db.createObjectStore<IPack, string>(PACK_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(SEASON_TABLE_NAME)) {
        db.createObjectStore<ISeason, string>(SEASON_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(SIZE_TABLE_NAME)) {
        db.createObjectStore<ISize, string>(SIZE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(VARIETY_TABLE_NAME)) {
        db.createObjectStore<IVariety, string>(VARIETY_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(CONTACT_INFO_TABLE_NAME)) {
        db.createObjectStore<IContactInfo, string>(CONTACT_INFO_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(COUNTRY_TABLE_NAME)) {
        db.createObjectStore<ICountry, string>(COUNTRY_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(DEPARTMENT_TABLE_NAME)) {
        db.createObjectStore<IDepartment, string>(DEPARTMENT_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(DEVICE_TABLE_NAME)) {
        db.createObjectStore<IDevice, string>(DEVICE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(DOMAIN_TABLE_NAME)) {
        db.createObjectStore<IDomain, string>(DOMAIN_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(FARM_TABLE_NAME)) {
        db.createObjectStore<IFarm, string>(FARM_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(INSPECTION_POINT_TABLE_NAME)) {
        db.createObjectStore<IInspectionPoint, string>(INSPECTION_POINT_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(INVENTORY_TABLE_NAME)) {
        db.createObjectStore<IInventory, string>(INVENTORY_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(LOT_TYPE_TABLE_NAME)) {
        db.createObjectStore<ILotType, string>(LOT_TYPE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(MARK_TABLE_NAME)) {
        db.createObjectStore<IMark, string>(MARK_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(MARKET_TABLE_NAME)) {
        db.createObjectStore<IMarket, string>(MARKET_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(MATERIAL_TABLE_NAME)) {
        db.createObjectStore<IMaterial, string>(MATERIAL_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(MATERIAL_TYPE_TABLE_NAME)) {
        db.createObjectStore<IMaterialType, string>(MATERIAL_TYPE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(OUTLET_TABLE_NAME)) {
        db.createObjectStore<IOutlet, string>(OUTLET_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(PACK_CATEGORY_TABLE_NAME)) {
        db.createObjectStore<IPackCategory, string>(PACK_CATEGORY_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(PACK_LINE_TABLE_NAME)) {
        db.createObjectStore<IPackLine, string>(PACK_LINE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(PALLET_BASE_TYPE_TABLE_NAME)) {
        db.createObjectStore<IPalletBaseType, string>(PALLET_BASE_TYPE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(PRINTER_TABLE_NAME)) {
        db.createObjectStore<IPrinter, string>(PRINTER_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(PROJECT_TABLE_NAME)) {
        db.createObjectStore<IProject, string>(PROJECT_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(PRINT_SERVER_TABLE_NAME)) {
        db.createObjectStore<IPrintServer, string>(PRINT_SERVER_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(SALES_POINT_TABLE_NAME)) {
        db.createObjectStore<ISalesPoint, string>(SALES_POINT_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(REGION_TABLE_NAME)) {
        db.createObjectStore<IRegion, string>(REGION_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(REPORT_TABLE_NAME)) {
        db.createObjectStore<IReport, string>(REPORT_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(SITE_SETTING_TABLE_NAME)) {
        db.createObjectStore<ISiteSetting, string>(SITE_SETTING_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(STORAGE_UNIT_TABLE_NAME)) {
        db.createObjectStore<IStorageUnit, string>(STORAGE_UNIT_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(TRUCK_TYPE_TABLE_NAME)) {
        db.createObjectStore<ITruckType, string>(TRUCK_TYPE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(UNIT_OF_MEASURE_TABLE_NAME)) {
        db.createObjectStore<IUnitOfMeasure, string>(UNIT_OF_MEASURE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(UNIT_OF_MEASURE_TYPE_TABLE_NAME)) {
        db.createObjectStore<IUnitOfMeasureType, string>(UNIT_OF_MEASURE_TYPE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(FTP_DETAIL_TABLE_NAME)) {
        db.createObjectStore<IFtpDetail, string>(FTP_DETAIL_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(SITE_SETTING_TABLE_NAME)) {
        db.createObjectStore<ISiteSetting, string>(SITE_SETTING_TABLE_NAME);
    }
}

/**
 * Sets the auth session. If no session is specified, deletes the existing entry.
 *
 * @param userToken The session.
 */
async function setSessionIndexedDB(userToken : IUserToken | null) {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx = db.transaction(SESSION_NAME, 'readwrite');

    const store = tx.objectStore(SESSION_NAME);

    if (!userToken) {
        await store.delete(SESSION_KEY);
    } else {
        await store.put(userToken, SESSION_KEY);
    }
    await tx.complete;
}

/**
 * Opens the DB and retrieves the current auth session.
 */
async function getSessionIndexedDB() {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx = db.transaction(SESSION_NAME, 'readonly');

    const result = tx.objectStore<IUserToken>(SESSION_NAME).get(SESSION_KEY);

    await tx.complete;

    return result;
}

/**
 * Specifies the callback that will be fired whenever the auth session undergoes a change.
 *
 * @param callback
 */
export async function onSessionChanged(callback : (userToken : IUserToken | null) => void) {
    sessionCallback = callback;
    if (!!self.indexedDB) {
        indexedDBSessionChange();
    } else if (!!self.localStorage) {
        const session = getSessionLocalStorage();
        sessionCallback(session);
    }
}

/**
 * Retrieves auth session, and once done fires the session callback.
 */
function indexedDBSessionChange() {
    getSessionIndexedDB().then(
        (res) => {
            sessionCallback(res);
        },
        () => {
            sessionCallback(null);
        });
}

/// ////////////////

/**
 * Stores master data last sync date
 */
export function setMasterDataLastSyncDateLocalStorage(lastSyncDate : moment.Moment) {
    if (!!lastSyncDate) {
        localStorage.setItem(MASTER_DATA_LAST_SYNC_DATE, JSON.stringify(lastSyncDate));
    } else {
        localStorage.removeItem(MASTER_DATA_LAST_SYNC_DATE);
    }
}

/**
 * Retrieves Organization
 */
export function getMasterDataLastSyncDateLocalStorage() : moment.Moment {
    const lastSyncDate = localStorage.getItem(MASTER_DATA_LAST_SYNC_DATE);

    if (lastSyncDate) return JSON.parse(lastSyncDate);

    return moment().utc();
}

/**
 * Stores Organization
 */
export function setOrganizationLocalStorage(organizationData ?: Array<number>) {
    if (!!organizationData) {
        localStorage.setItem(SELECTED_ORGANIZATIONS_KEY, JSON.stringify(organizationData));
    } else {
        localStorage.removeItem(SELECTED_ORGANIZATIONS_KEY);
    }
}

/**
 * Retrieves Organization
 */
export function getOrganizationLocalStorage() : Array<number> {
    const organizationData = localStorage.getItem(SELECTED_ORGANIZATIONS_KEY);

    if (organizationData) return JSON.parse(organizationData);

    return [];
}

/**
 * Stores Site
 */
export function setSiteLocalStorage(siteData ?: Array<number>) {
    if (!!siteData) {
        localStorage.setItem(SELECTED_SITES_KEY, JSON.stringify(siteData));
    } else {
        localStorage.removeItem(SELECTED_SITES_KEY);
    }
}

/**
 * Retrieves Site
 */
export function getSiteLocalStorage() : Array<number> {
    const siteData = localStorage.getItem(SELECTED_SITES_KEY);

    if (siteData) return JSON.parse(siteData);

    return [];
}

/**
 * Stores Exporter Organization
 */
export function setExportOrganizationLocalStorage(exporterData ?: IOrganization) {
    if (!!exporterData) {
        localStorage.setItem(SELECTED_EXPORTER_KEY, JSON.stringify(exporterData));
    } else {
        localStorage.removeItem(SELECTED_EXPORTER_KEY);
    }
}

/**
 * Retrieves Exporter Organization
 */
export function getExportOrganizationLocalStorage() : IOrganization | undefined {
    const exporterData = localStorage.getItem(SELECTED_EXPORTER_KEY);

    if (exporterData) return JSON.parse(exporterData);

    return undefined;
}

/**
 * Stores Selected Inventory
 */
export function setSelectedInventoryLocalStorage(inventoryData ?: IInventory) {
    if (!!inventoryData) {
        localStorage.setItem(SELECTED_INVENTORY_KEY, JSON.stringify(inventoryData));
    } else {
        localStorage.removeItem(SELECTED_INVENTORY_KEY);
    }
}

/**
 * Retrieves Selected Inventory
 */
export function getSelectedInventoryLocalStorage() : IInventory | undefined {
    const inventoryData = localStorage.getItem(SELECTED_INVENTORY_KEY);

    if (inventoryData) return JSON.parse(inventoryData);

    return undefined;
}

/**
 * Stores Home url
 */
export function setHomeUrlStorage(url : string) {
    localStorage.setItem(HOME_URL_KEY, url);
}

/**
 * Retrieves Home url
 */
export function getHomeUrlStorage() : string {
    const url = localStorage.getItem(HOME_URL_KEY);

    if (url) return url;

    return '/';
}

/**
 * Stores Selected Theme
 */
export function setSelectedThemeLocalStorage(theme ?: string) {
    if (!!theme) {
        localStorage.setItem(SELECTED_THEME, theme);
    } else {
        localStorage.removeItem(SELECTED_THEME);
    }
}

/**
 * Retrieves Selected Theme
 */
export function getSelectedThemeLocalStorage() : string {
    const selectedTheme = localStorage.getItem(SELECTED_THEME);

    if (selectedTheme) return selectedTheme;

    return 'Default';
}

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IInspectionPoint } from '../../../@types/model/masterData/inspectionPoint/inspectionPoint';

export default class InspectionPointHttpService {
    public static getInspectionPointData() : AxiosPromise<Array<IInspectionPoint>> {
        return axios.get(`${PACKMAN_API_URL}InspectionPoint/GetAllWeb`);
    }

    public static addOrUpdateInspectionPoint(data : IInspectionPoint) : AxiosPromise<IInspectionPoint> {
        return axios.post(`${PACKMAN_API_URL}InspectionPoint/PostInspectionPoint`, data);
    }

    public static deleteInspectionPoint(inspectionPointId : number) : AxiosPromise<IInspectionPoint> {
        return axios.delete(`${PACKMAN_API_URL}InspectionPoint/Delete`, {
            params : { inspectionPointId },
        });
    }
}

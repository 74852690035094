import { IBaseModel } from '../../../model';
import { IContactInfoFormValues } from './contactInfoFormValues';

export interface IContactInfo extends IBaseModel {
    name : string;
    address ?: string;
    telNumber ?: string;
    faxNumber ?: string;
    email ?: string;
}

export class ContactInfo implements IContactInfo {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public name : string;
    public address ?: string;
    public telNumber ?: string;
    public faxNumber ?: string;
    public email ?: string;

    public constructor(formValues : IContactInfoFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.name = formValues.name;
        this.address = formValues.address;
        this.telNumber = formValues.telNumber;
        this.faxNumber = formValues.faxNumber;
        this.email = formValues.email;
    }
}

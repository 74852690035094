import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../general/Functions';
import { dispatch } from '../Index';
import { googleAuth, logout, logInManual } from '../../services/httpService';
import { setLocalStorageSession } from '../../services/localStorageService';
import { authSetSession } from './Actions';

/**
 * Performs log in request with API then sets the interceptor (using auth token) and auth state accordingly. Kicks off
 * the setup for local storage service to store the session and, in turn, firing its callback (that is set during app
 * init).
 *
 * @param idToken
 */
export async function authSignInGoogle(idToken : string) {
    try {
        const res = await googleAuth(idToken);
        await setLocalStorageSession(res.data);

        return res.data;
    } catch {
        generalShowErrorSnackbar('An error occurred while trying to sign you in');
        return null;
    }
}

/**
 * Performs log out request with API then sets the auth state accordingly.
 */
export async function authSignOutGoogle() {
    try {
        await logout();

        generalShowSuccessSnackbar('Logged Out!');
        dispatch(authSetSession(null));
        await setLocalStorageSession(null);
    } catch (e) {
        if (!e || !e.response) {
            dispatch(authSetSession(null));
            await setLocalStorageSession(null);
        } else {
            generalShowErrorSnackbar('An error occurred while trying to sign you out');
        }
    }
}

/**
 * Performs log in request with API then sets the interceptor (using auth token) and auth state accordingly. Kicks off
 * the setup for local storage service to store the session and, in turn, firing its callback (that is set during app
 * init).
 *
 * @param emailOrUsername
 * @param password
 */
export const authManualLogIn = async (emailOrUsername : string, password : string) => {
    try {
        const res = await logInManual(emailOrUsername, password);

        generalShowSuccessSnackbar('Logged In!');
        await setLocalStorageSession(res.data);

        return res.data;
    } catch (e) {
        generalShowErrorSnackbar(e?.response?.data?.Message ?? 'An error occurred while trying to sign you in');
        return null;
    }
};

import Icon from '@mui/material/Icon';
import * as React from 'react';


interface IBooleanFlagProps {
    value : boolean;
}

export const BooleanFlag = (props : IBooleanFlagProps) => {

    return props.value
        ?
        <Icon>check_circle</Icon>
        :
        <Icon id={'booleanFlagFalse'}>cancel</Icon>;
};

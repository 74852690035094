import * as logActions from './Actions';
import { getType } from 'typesafe-actions';
import { ILogState, LogAction } from '../../@types/redux';

const initialState = {
    logs: [],
};

export default function logReducer(state : ILogState = initialState, action : LogAction) : ILogState {
    switch (action.type) {
        case getType(logActions.dataSetLogs):
            return { ...state, logs: action.payload };
        default:
            return state;
    }
}

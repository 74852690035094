import * as React from 'react';
import CustomTable from '../../../components/datagrid/CustomTable';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetDomains } from '../../../store/masterData/Actions';
import { formatDateTime, upsertArrayElement } from '../../../services/appFunctionsService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikActions } from 'formik';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { Domain, IDomain } from '../../../@types/model/user/domain';
import DomainHttpService from '../../../services/right/domainHttpService';
import { DomainFormValues, IDomainFormValues } from '../../../@types/model/user/domainFormValues';
import DomainForm from './form/DomainForm';
import { setDomainMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IDomainScreenProps extends RouteComponentProps {
    dataSetDomains : DispatchCall<Array<IDomain>>;
    domainData : Array<IDomain>;
    auth : IAuthState;
}

interface IDomainScreenState {
    rows : Array<IDomain>;
    isLoading : boolean;
    selectedDomain ?: IDomain;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingDomain ?: IDomain;
}

class DomainScreen extends React.Component<IDomainScreenProps, IDomainScreenState> {
    constructor(props : IDomainScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedDomain: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await DomainHttpService.getDomainData();

                this.props.dataSetDomains(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading domain data.');
                this.setLoading(false);
            }
        }
    };

    public editDomain = (domain : IDomain) => {
        this.setState({
            isDialogOpen: true,
            selectedDomain: domain,
        });
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedDomain: undefined,
        });
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public getSelectedDomain = (props : IDomainScreenProps, state : IDomainScreenState) => state.selectedDomain;

    public getInitialFormValues = createSelector(
        [this.getSelectedDomain],
        (domain : IDomain) => {
            return new DomainFormValues(domain);
        },
    );

    public onReset = async (formValues : IDomainFormValues, formikActions : FormikActions<IDomainFormValues>) => {
        formikActions.resetForm();
        this.closeDialog();
    };

    public onSubmit = async (values : IDomainFormValues) => {
        this.setLoading(true);

        try {
            const res = await DomainHttpService.addOrUpdateDomain(new Domain(values));

            const newDomainList = upsertArrayElement(this.props.domainData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetDomains(newDomainList);
            await setDomainMasterDataIndexedDB(newDomainList);

            if (this.state.selectedDomain) {
                generalShowSuccessSnackbar('Domain updated successfully.');
            } else {
                generalShowSuccessSnackbar('Domain added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating domain data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (domain : IDomain) => {
        this.setState({ isDeletePopupOpen: true, deletingDomain: domain });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingDomain: undefined });
    };

    private removeDomain = async () => {
        const newDomain = this.state.deletingDomain;
        if (newDomain) {
            newDomain.isActive = false;
            this.setLoading(true);

            try {
                const res = await DomainHttpService.deleteDomain(newDomain.id);

                const newDomainList = upsertArrayElement(this.props.domainData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetDomains(newDomainList);
                await setDomainMasterDataIndexedDB(newDomainList);

                generalShowSuccessSnackbar('Domain updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting domain.');
                newDomain.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private getRights = (props : IDomainScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IDomainScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.domainData ? this.props.domainData : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Domain'
                    isEdit={!!this.state.selectedDomain}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={DomainFormValues.formSchema}
                        component={DomainForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IDomain>
                            enableAdding={this.hasEditingRight(this.props)}
                            enableDeleting={(domain : IDomain) => domain.isActive && this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editDomain}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Domain Name', field: 'domainName', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IDomain) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationPrompt
                    title={'Delete Domain'}
                    open={this.state.isDeletePopupOpen}
                    message={'Are you sure you want to delete this domain?'}
                    onOkClicked={this.removeDomain}
                    onCancelClicked={this.closeDeleteConfirmationPopup}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        domainData: state.masterData.domains,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetDomains }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DomainScreen);

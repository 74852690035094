import React, { ReactNode } from 'react';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import materialTheme from '../styles/materialTheme';

interface IScreenProps {
    isPadded ?: boolean;
    isScrollable ?: boolean;
    actions ?: ReactNode;
    isLoading ?: boolean;
}

export default class Screen extends React.Component<IScreenProps> {
    public render() {
        const {
            isPadded = true,
            isScrollable = true,
            actions,
        } = this.props;

        return (
            <div style={{ backgroundColor: materialTheme.custom.screen.background }} className={'hfill flx1 oxh fdc posr'}>
                {
                    this.props.isLoading &&
                    <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                        <div className={'posr aic jcc h50 w50'}>
                            <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                                <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                            </div>
                            <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                        </div>
                    </div>
                }
                <div className={`hfill flx1 fdc ${isPadded ? 'p20 pb10' : ''} ${isScrollable ? 'oys' : ''}`}>
                    {this.props.children}
                </div>
                <div>{actions}</div>
            </div>
        );
    }
}

import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IInventory } from '../../../@types/model/masterData/inventory/inventory';

export default class InventoryHttpService {
    public static getInventoryData() : AxiosPromise<Array<IInventory>> {
        return axios.get(`${PACKMAN_API_URL}Inventory/GetAllWeb`);
    }

    public static addOrUpdateInventory(data : IInventory) : AxiosPromise<IInventory> {
        return axios.post(`${PACKMAN_API_URL}Inventory/PostInventory`, data);
    }

    public static deleteInventory(inventoryId : number) : AxiosPromise<IInventory> {
        return axios.delete(`${PACKMAN_API_URL}Inventory/Delete`, {
            params : { inventoryId },
        });
    }
}

import { IBaseModel } from '../../../model';
import { ISalesPointFormValues } from './salesPointFormValues';

export interface ISalesPoint extends IBaseModel {
    name : string;
    farmIds ?: Array<number>;
}

export class SalesPoint implements ISalesPoint {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public name : string;
    public farmIds ?: Array<number> | undefined;

    public constructor(formValues : ISalesPointFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.name = formValues.name;

        this.farmIds = formValues.farms.map(x => Number(x.value)) ?? undefined;
    }
}

import { IBaseModel } from '../../../model';
import { IPackCategoryFormValues } from './packCategoryFormValues';

export interface IPackCategory extends IBaseModel {
    code : string;
    category : string;
}

export class PackCategory implements IPackCategory {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public category : string;

    public constructor(formValues : IPackCategoryFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.code = formValues.code;
        this.category = formValues.category;
    }
}

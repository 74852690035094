import * as React from 'react';
import { addArrayElement, removeArrayElement, compareDate, formatDateTime, upsertArrayElement, booleanToYesNo, setArrayElement } from '../../../services/appFunctionsService';
import moment from 'moment';
import { TextField, Card, Checkbox, Divider, Stepper, Step, StepLabel, Dialog, AppBar, Toolbar, Typography, Button, Icon } from '@mui/material';
import { Form } from 'informed';
import validationService from '../../../services/validationService';
import CustomAutoSuggest from '../../../components/input/CustomAutoSuggest';
import { IDropDownOptions } from '../../../@types/other';
import { IOrganization } from '../../../@types/model/masterData/organization/organization';
import { ISite } from '../../../@types/model/masterData/site/site';
import { IRootState, IAuthState } from '../../../@types/redux';
import { connect } from 'react-redux';
import PillButton from '../../../components/input/PillButton';
import CustomTable from '../../../components/table/CustomTable';
import { IStock } from '../../../@types/model/stock/stock';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import PopupActionButton from '../../../components/button/PopupActionButton';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { DATEPICKER_FORMAT_DEFAULT } from '../../../appConstants';
import FloatingActionButton from '../../../components/input/FloatingActionButton';
import { ITrip } from '../../../@types/model/dispatch/trip';
import uuidv1 from 'uuid';
import DateInput from '../../../components/input/DateInput';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { createSelector } from 'reselect';
import Screen from '../../../components/Screen';
import { IMaterialStock } from '../../../@types/model/materialStock/materialStock';
import { IMaterial } from '../../../@types/model/masterData/material/material';
import { IUnitOfMeasure } from '../../../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import { dataSetAllMaterialRelatedData } from '../../../store/data/Functions';
import MaterialDispatchHttpService from '../../../services/http/materialDispatch/materialDispatchHttpService';
import { ICarrier } from '../../../@types/model/masterData/carrier/carrier';
import { ICreateMaterialDispatch } from '../../../@types/model/materialDispatch/createMaterialDispatch';
import { ISelectedMaterialStock } from '../../../@types/model/materialStock/selectedMaterialStock';
import TextInput from '../../../components/input/TextInput';
import { syncMasterData } from '../../../services/masterDataSyncService';
import lodash from 'lodash';

interface IMaterialDispatchWizardProps {
    materialStocks : Array<IMaterialStock>;
    organizations : Array<IOrganization>;
    sites : Array<ISite>;
    auth : IAuthState;
    selectedOrganizationIds : Array<number>;
    selectedSiteIds : Array<number>;
    materials : Array<IMaterial>;
    unitOfMeasures : Array<IUnitOfMeasure>;
    trips : Array<ITrip>;
    carriers : Array<ICarrier>;
}

interface IMaterialDispatchWizardState {
    selectedSourceOrganization ?: number;
    selectedSourceSite ?: number;
    selectedTargetOrganization ?: number;
    selectedTargetSite ?: number;
    selectedLoadDate : moment.Moment;
    currentPage : number;
    activeDialog ?: number;
    rows : Array<IStock>;
    isLoading : boolean;
    createdMaterialDispatchCode ?: string;
    createdComplianceCode ?: string;
    createdTripId ?: number;
    trips : Array<ITrip>;
    newTrip ?: ITrip;
    selectedTripId ?: number;
    createCompliance ?: boolean;
    isEditingTrip : boolean;
    selectedMaterialStocks : Array<ISelectedMaterialStock>;
}

class MaterialDispatchWizard extends React.Component<IMaterialDispatchWizardProps, IMaterialDispatchWizardState> {

    constructor(props : IMaterialDispatchWizardProps) {
        super(props);
        this.state = {
            selectedLoadDate: moment(),
            currentPage: 1,
            rows: [],
            isLoading: false,
            activeDialog: undefined,
            trips: [],
            isEditingTrip: false,
            selectedMaterialStocks: [],
        };
    }

    public componentDidMount = async () => {
        const nextProps = this.props;
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }
        this.setLoading(false);

        if (nextProps.selectedSiteIds?.length === 1 && nextProps.selectedOrganizationIds?.length === 1) {
            const selectedOrganization = nextProps.selectedOrganizationIds[0];
            const selectedSite = nextProps.selectedSiteIds[0];
            this.setState({ selectedSourceOrganization: selectedOrganization },
                () => this.setState({ selectedSourceSite: selectedSite }));
        }
    };

    public componentDidUpdate = async (prevProps : IMaterialDispatchWizardProps, prevState : IMaterialDispatchWizardState) => {
        const nextProps = this.props;
        const nextState = this.state;
        if (nextProps) {
            const selectedOrganizationId = nextProps.selectedOrganizationIds?.length === 1 ? nextProps.selectedOrganizationIds[0] : undefined;
            const selectedSiteId = nextProps.selectedSiteIds?.length === 1 ? nextProps.selectedSiteIds[0] : undefined;
            if (selectedOrganizationId && selectedSiteId && (this.state.selectedSourceSite !== selectedSiteId || this.state.selectedSourceOrganization !== selectedOrganizationId)) {
                const selectedOrganization = selectedOrganizationId;
                const selectedSite = selectedSiteId;
                this.setState({ selectedSourceOrganization: selectedOrganization },
                    () => this.setState({ selectedSourceSite: selectedSite }));
            }
        }

        if (nextState) {
            if (nextState.currentPage === 2 && prevState.currentPage !== 2) {
                this.setLoading(true);
                // checks if indexedDB is available.
                const isIndexedDBAvailable = !!self.indexedDB ? true : false;

                try {
                    const res = await MaterialDispatchHttpService.getMaterialDispatchWizardData(undefined, undefined, this.getDate('trip'), this.state.selectedSourceSite, !isIndexedDBAvailable);

                    dataSetAllMaterialRelatedData(res.data);
                    this.setLoading(false);
                } catch (e) {
                    generalShowErrorSnackbar('An error occurred retrieving material dispatch wizard data.');
                    this.setLoading(false);
                }
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    private isTripChecked = (id : number) => id === this.state.selectedTripId;

    private handleTripCheckBoxChecked = (row : ITrip, checked : boolean) => {
        this.setLoading(true);
        if (checked) {
            if (!this.state.newTrip || row.id === 0) {
                this.setState({ selectedTripId: row.id }, this.setLoading);
            } else {
                this.setLoading();
            }
        } else {
            this.setState({ selectedTripId: undefined, newTrip: undefined }, this.removeNewTrip);
        }
    };

    private removeNewTrip = () => {
        this.setLoading(true);
        const index = this.state.trips.findIndex(x => x.id === 0);
        if (index !== -1) {
            this.setState(prevState => ({ trips: removeArrayElement(prevState.trips, index) }), this.setLoading);
        } else {
            this.setLoading();
        }
    };

    private setSelectedmaterialStock = (id : number, event : React.ChangeEvent<HTMLInputElement>) => {
        const selectedMaterialStocks = [...this.state.selectedMaterialStocks];
        const selectedMaterialStockIndex = selectedMaterialStocks.findIndex(x => x.materialStockId === id);
        const newMaterialStock : ISelectedMaterialStock = {
            materialStockId: id,
            amount: Number(event.target.value),
        };

        if (selectedMaterialStockIndex !== -1) {
            if (newMaterialStock.amount < 1) {
                this.setState({ selectedMaterialStocks: removeArrayElement([...selectedMaterialStocks], selectedMaterialStockIndex) });
            } else {
                this.setState({ selectedMaterialStocks: setArrayElement([...selectedMaterialStocks], selectedMaterialStockIndex, newMaterialStock) });
            }
        } else {
            this.setState({ selectedMaterialStocks: addArrayElement([...selectedMaterialStocks], newMaterialStock, 'end') });
        }
    };

    private isAmountValid = (amount : number, row : IMaterialStock) => {
        if (amount > row.amountUnAllocated) {
            return 'Specified Amount Is More Then Available Amount';
        } else if (amount >= 0 && amount <= row.amountUnAllocated) {
            return '';
        } else {
            return 'Value Must Be Non Negative';
        }
    };

    private columns = () => [
        { title: 'Specify Amount', field: 'id', width: 150,
            containerComponent: (row : IMaterialStock) => {
                return (
                    <TextInput
                        name={'materialStockAmount'}
                        label={''}
                        type={'number'}
                        value={this.state.selectedMaterialStocks?.find(x => x.materialStockId === row.id)?.amount.toString() ?? ''}
                        onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.setSelectedmaterialStock(row.id, e)}
                        error={this.isAmountValid(this.state.selectedMaterialStocks?.find(x => x.materialStockId === row.id)?.amount ?? 0, row)}
                    />
                );
            },
        },
        { title: 'Material Code', field: 'materialId', formatFunction: this.getMaterialCode, enableFiltering: true, enableSorting: true },
        { title: 'Description', field: 'materialId', formatFunction: this.getMaterialName, enableFiltering: true, enableSorting: true },
        { title: 'Site', field: 'siteId', formatFunction: this.getSiteDescription, enableFiltering: true, enableSorting: true },
        { title: 'Date Code', field: 'dateCode', enableFiltering: true, enableSorting: true },
        { title: 'Amount Allocated', field: 'amountAllocated', enableFiltering: true, enableSorting: true },
        { title: 'Amount Unallocated', field: 'amountUnAllocated', enableFiltering: true, enableSorting: true },
        { title: 'Amount Scrapped', field: 'amountScrapped', enableFiltering: true, enableSorting: true },
        { title: 'Amount On-Hand', field: 'amountOnHand', enableFiltering: true, enableSorting: true },
        { title: 'Amount Total', field: 'amountTotal', enableFiltering: true, enableSorting: true },
        { title: 'Unit of Measure', field: 'unitOfMeasureId', formatFunction: this.getUnitOfMeasureCode, enableFiltering: true, enableSorting: true },
        { title: 'Id', field: 'id', enableFiltering: true, enableSorting: true },
        { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
        { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
        { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
        { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
        { title: 'Active?', field: 'isActive', formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true },

    ];

    private getMaterialCode = (materialId : number) => {
        const material = this.props.materials.find(x => x.id === materialId);
        return material ? material.code : '';
    };

    private getMaterialName = (materialId : number) => {
        const material = this.props.materials.find(x => x.id === materialId);
        return material ? material.name : '';
    };

    private getUnitOfMeasureCode = (unitId : number) => {
        const unitOfMeasure = this.props.unitOfMeasures.find(x => x.id === unitId);
        return unitOfMeasure ? unitOfMeasure.code : '';
    };

    private tripColumns = () => [
        { title: 'Select', field: 'id', formatFunction: this.isTripChecked, width: 80,
            containerComponent: (row : ITrip, value : boolean) => {
                return <Checkbox
                    checked={!!value}
                    onChange={(event, checked) => this.handleTripCheckBoxChecked(row, checked)}
                />;
            },
        },
        { title: 'Id', field: 'id', enableFiltering: true, enableSorting: true },
        { title: 'Load Date', field: 'loadDate', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
        { title: 'Description', field: 'description', enableFiltering: true, enableSorting: true },
        { title: 'Driver', field: 'driver', enableFiltering: true, enableSorting: true },
        { title: 'Registration Number', field: 'registrationNumber', enableFiltering: true, enableSorting: true },
        { title: 'Container', field: 'container', enableFiltering: true, enableSorting: true },
        { title: 'ContainerTareWeight', field: 'containerTareWeight', enableFiltering: true, enableSorting: true },
        { title: 'Carrier', field: 'carrierId', formatFunction: this.getCarrierName, enableFiltering: true, enableSorting: true },
    ];

    private getCarrierName = (id : number) => this.props.carriers.find(x => x.id === id)?.name || '';


    private siteOptions = (type : 'source' | 'destination') : Array<IDropDownOptions> => {
        const selectedOrganization = type === 'source' ? this.state.selectedSourceOrganization : this.state.selectedTargetOrganization;
        if (!this.props.sites || !selectedOrganization || (type === 'destination' && (!this.state.selectedSourceSite || !this.state.selectedSourceOrganization))) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Site', disabled: true });
        this.props.sites.filter(x => x.organizationIds?.some(y => y === selectedOrganization)
            && ((type === 'destination') || this.props.selectedSiteIds?.some(y => y === x.id))
            && ((type === 'source') || (x.id !== this.state.selectedSourceSite))
            && x.isActive)
            .forEach(x => returnValue.push({ value: x.id, label: `(${x.code}) ${x.description}` }));
        return returnValue;
    };

    private organizationOptions = (type : 'source' | 'destination') => {
        if (!this.props.organizations) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Organization', disabled: true });
        this.props.organizations.filter(x => x.isActive
            && ((type === 'destination') || this.props.selectedOrganizationIds?.some(y => y === x.id)))
            ?.forEach(x => returnValue.push({ value: x.id, label: `(${x.code}) ${x.name}` }));
        return returnValue;

    };

    private getMaterialStock = (props : IMaterialDispatchWizardProps) => props.materialStocks ?? [];

    private getMaterialStockRows = createSelector([this.getMaterialStock], materialStockData =>
        materialStockData
            .filter(x => x.isActive)
            .filter(x => x.materialStockLines.some(y => y.isActive)));

    private filterTripRows = () => this.setState({ trips: this.props.trips.filter(x => x.isActive) });

    private renderPage = () => {
        switch (this.state.currentPage) {
            case 1:
                return this.renderStartPage();
            case 2:
                return this.renderMaterialStockSelectPage();
            case 3:
                return this.renderPreviewPage();
        }
    };

    private nextPage = () => this.setState(prevState => ({ currentPage: prevState.currentPage + 1 }), () => {
        if (this.state.currentPage === 3) {
            this.filterTripRows();
        }
    });

    private previousPage = () => this.setState(prevState => ({ currentPage: prevState.currentPage - 1 }), () => {
        switch (this.state.currentPage) {
            case 1:
                this.setState({ selectedMaterialStocks: [] });
                break;
        }
    });

    public onLoadDateChange = (selectedDate : moment.Moment) => {
        this.setState({ selectedLoadDate: selectedDate });
    };

    private renderStartPage = () =>  <Form>
        <Card className={'mb20'} style={{ height: `${this.state.currentPage === 1 ? 'calc(100% + 133px)' : ''}` }}>
            <AppBar position='static' elevation={0}>
                <Toolbar variant='dialog' className={'fdr'}>
                    <Typography color='inherit' variant='h6'>{'Select Dispatch Details'}</Typography>
                </Toolbar>
            </AppBar>
            <div className={'fdr wfill mb10 p20'}>
                <div className={'fdc flx1 mb10'}>
                    <CustomAutoSuggest
                        className={'wfill m5'}
                        options={this.organizationOptions('source')}
                        label={'Source Organization'}
                        initialValue={this.state.selectedSourceOrganization}
                        hasInitialValue={!!this.state.selectedSourceOrganization}
                        validate={validationService.required}
                        disabled={this.state.isLoading}
                        validateOnBlur
                        validateOnChange
                        field={'sourceOrganization'}
                        value={this.state.selectedSourceOrganization ?? 0}
                        onChange={(e : any) => this.setState({ selectedSourceOrganization: e })}
                        enableDropDownIcon
                    />
                    <div className={'h10'}/>
                    <CustomAutoSuggest
                        className={'wfill m5'}
                        options={this.siteOptions('source')}
                        label={'Source Site'}
                        initialValue={this.state.selectedSourceSite}
                        hasInitialValue={!!this.state.selectedSourceOrganization && !!this.state.selectedSourceSite}
                        validate={validationService.required}
                        disabled={this.state.isLoading}
                        validateOnBlur
                        validateOnChange
                        field={'sourceSite'}
                        value={this.state.selectedSourceSite ?? 0}
                        onChange={e => this.setState({ selectedSourceSite: e })}
                        enableDropDownIcon
                    />
                </div>
                <div className={'flx1 fdr aic jcc'}>
                    <Icon style={{ minHeight: 80, height: 80, maxHeight: 80 }} className={'w200'} fontSize={'large'}>trending_flat</Icon>
                </div>
                <div className={'fdc flx1 mb10'}>
                    <CustomAutoSuggest
                        className={'wfill m5'}
                        options={this.organizationOptions('destination')}
                        label={'Target Organization'}
                        initialValue={this.state.selectedTargetOrganization}
                        hasInitialValue={!!this.state.selectedTargetOrganization}
                        validate={validationService.required}
                        disabled={this.state.isLoading}
                        validateOnBlur
                        validateOnChange
                        field={'targetOrganization'}
                        value={this.state.selectedTargetOrganization ?? 0}
                        onChange={(e : any) => this.setState({ selectedTargetOrganization: e })}
                        enableDropDownIcon
                    />
                    <div className={'h10'}/>
                    <CustomAutoSuggest
                        className={'wfill m5'}
                        options={this.siteOptions('destination')}
                        label={'Target Site'}
                        initialValue={this.state.selectedTargetSite}
                        hasInitialValue={!!this.state.selectedTargetSite && !!this.state.selectedTargetSite}
                        validate={validationService.required}
                        disabled={this.state.isLoading}
                        validateOnBlur
                        validateOnChange
                        field={'targetSite'}
                        value={this.state.selectedTargetSite ?? 0}
                        onChange={(e : any) => this.setState({ selectedTargetSite: e })}
                        enableDropDownIcon
                    />
                    <div className={'h10'}/>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateInput
                            className={'wfill m5'}
                            label={'Load Date'}
                            name={'loadDate'}
                            variant={'standard'}
                            disabled={this.state.isLoading}
                            value={this.state.selectedLoadDate}
                            onDateChange={this.onLoadDateChange}
                        />
                    </LocalizationProvider>
                </div>
            </div>
        </Card>
        <div className={'fdr flx1'}>
            <div className={'flx1'}/>
            <PillButton color={'secondary'}
                className={'ml15 pl20 pr20 h35'}
                type={'submit'}
                text={'Next'}
                onClick={this.nextPage}
                disabled={this.state.isLoading || this.state.currentPage !== 1 || !this.state.selectedLoadDate
                    || !this.state.selectedSourceOrganization || !this.state.selectedSourceSite
                    || !this.state.selectedTargetOrganization || !this.state.selectedTargetSite} />
        </div>
    </Form>;

    private getDate = (type : 'from' | 'to' | 'trip') => {
        switch (type) {
        // case 'from':
        //     return this.state.selectedFromDate.utc().unix() * 1000;
        // case 'to':
        //     return this.state.selectedToDate.utc().unix() * 1000;
            case 'trip':
                return moment(this.state.selectedLoadDate, DATEPICKER_FORMAT_DEFAULT).utc().unix() * 1000;
        }
    };

    private refreshMaterialStockTableData = async () => {
        try {
            this.setLoading(true);

            // checks if indexedDB is available.
            const isIndexedDBAvailable = !!self.indexedDB ? true : false;

            const res = await MaterialDispatchHttpService.getMaterialDispatchWizardData(undefined, undefined, this.getDate('trip'), this.state.selectedSourceSite, !isIndexedDBAvailable);
            dataSetAllMaterialRelatedData(res.data);
            this.setLoading(false);
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving dispatch wizard data.');
            this.setLoading(false);
        }
    };

    private isMaterialstockAmountValid = () => {
        const selectedMaterialStocks = this.props.materialStocks.filter(x => this.state.selectedMaterialStocks.some(y => y.materialStockId === x.id));
        if (selectedMaterialStocks.some(x => this.state.selectedMaterialStocks.some(y => y.amount > x.amountUnAllocated))) {
            generalShowErrorSnackbar('Some of the material stock amounts specified is more then available amounts.');
        } else {
            this.nextPage();
        }
    };

    private renderMaterialStockSelectPage = () => <div className={'fdc'}>
        <Card className={'fdc mb20 selectTextOnly'}>
            <CustomTable<IMaterialStock>
                enableSorting
                enableFiltering
                enableClearFilterButton
                enablePagination
                enableRefresh
                refreshFunction={this.refreshMaterialStockTableData}
                columns={this.columns()}
                rows={this.getMaterialStockRows(this.props)}
                initialSortOrder={[{ columnName: 'dateCode_Date Code', direction : 'desc' }]}
                pageSizes={[50, 150, 250, 500, 1000]}
                isActive={(row : IMaterialStock) => row.isActive}
                pageHeight={350}
            />
        </Card>
        <div className={'fdr flx1 mt20'}>
            <div className={'fdr aic'}>
                {'Selected:'}
                <div className={'w10'}/>
                <Typography variant={'h6'} className={'aic'}>
                    {this.state.selectedMaterialStocks.length}
                </Typography>
            </div>
            <div className={'w100'}/>
            <div className={'fdr aic'}>
                {'Total Amount:'}
                <div className={'w10'}/>
                <Typography variant={'h6'} className={'aic fdr'}>
                    {lodash.sumBy(this.state.selectedMaterialStocks, x => x.amount)}
                </Typography>
            </div>
            <div className={'flx1'}/>
            <Button
                className={'fwb h35'}
                variant='text'
                color='primary'
                disabled={this.state.isLoading || (this.state.currentPage < 2 || this.state.currentPage > 3)}
                onClick={this.previousPage}>
                Back
            </Button>
            <PillButton color={'secondary'}
                className={'ml15 pl20 pr20 h35'}
                type={'submit'}
                text={'Next'}
                onClick={this.isMaterialstockAmountValid}
                disabled={this.state.isLoading || this.state.currentPage < 2 || this.state.currentPage > 3 || !this.state.selectedLoadDate
                    || !this.state.selectedSourceOrganization || !this.state.selectedSourceSite
                    || !this.state.selectedTargetOrganization || !this.state.selectedTargetSite
                    || this.state.selectedMaterialStocks.length === 0
                    || this.state.selectedMaterialStocks.some(x => x.amount < 1)} />
        </div>
    </div>;

    private getMaterialStocks = (props : IMaterialDispatchWizardProps) => props.materialStocks;
    private getSelectedMaterialStocks = (props : IMaterialDispatchWizardProps, state : IMaterialDispatchWizardState) => state.selectedMaterialStocks;

    private getSelectedMaterialStockRows = createSelector(
        [this.getMaterialStocks, this.getSelectedMaterialStocks],
        (materialStocks, selectedMaterialStocks : Array<ISelectedMaterialStock>) => {
            return materialStocks.filter(x => selectedMaterialStocks.some(y => y.materialStockId === x.id));
        },
    );

    private getSelectedAmount = (materialStockId : number) => {
        const selectedMaterialStock = this.state.selectedMaterialStocks.find(x => x.materialStockId === materialStockId);

        return selectedMaterialStock ? selectedMaterialStock.amount : '';
    };

    private getSiteDescription = (siteId ?: number) => {
        const sites = this.props.sites;
        const site = sites && sites.find(x => x.id === siteId);
        return site ? site.description : '';
    };

    private getOrganizationName = (orgId ?: number) => {
        const organizations = this.props.organizations;
        const organization = organizations && organizations.find(x => x.id === orgId);
        return organization ? organization.name : '';
    };

    private getMaterialStockDateCode = (materialStockId : number) => this.props.materialStocks.find(x => x.id === materialStockId)?.dateCode || '';

    private getTotalAmounts = () => {
        let total = 0;
        this.state.selectedMaterialStocks.forEach(x => total += x.amount);
        return total;
    };

    private showTripDialog = () => this.setState({ activeDialog: 0, newTrip: {
        loadDate: moment(this.state.selectedLoadDate).startOf('day').format(DATEPICKER_FORMAT_DEFAULT),
        id: 0,
        guid: uuidv1(),
        isActive: true,
        temperatureUnits: [],
    } });

    private addTrip = () => {
        if (this.state.newTrip) {
            const trip = { ...this.state.newTrip };
            this.setState(prevState => ({ activeDialog: undefined, selectedTripId: 0, trips: addArrayElement(prevState.trips, trip, 'end') }));
        }
    };

    private editTrip = () => {
        if (this.state.isEditingTrip) {
            if (this.state.newTrip) {
                const trip = { ...this.state.newTrip };
                this.setState(prevState => ({ activeDialog: undefined, selectedTripId: 0, trips: upsertArrayElement(prevState.trips, trip, x => x.id === trip.id) ?? [], isEditingTrip: false }));
            }
        }
    };

    private cancelTripDialog = () => this.setState({ activeDialog: undefined, newTrip: undefined, isEditingTrip: false });

    private setEditTrip = (row : ITrip) => {
        this.setState({
            activeDialog: 0,
            newTrip: { ...row },
            isEditingTrip: true,
        });
    };

    private getTrips = (state : IMaterialDispatchWizardState) => state.trips;
    private getSelectedLoadDate = (state : IMaterialDispatchWizardState) => state.selectedLoadDate;

    private getTripRows = createSelector([this.getTrips, this.getSelectedLoadDate], (trips, selectedLoadDate) =>
        trips.filter(x => moment(x.loadDate).local().date() === moment(selectedLoadDate).local().date()));

    private renderPreviewPage = () => <div className={'fdc'}>
        <Card className={'mb20'}>
            <AppBar position='static' elevation={0}>
                <Toolbar variant='dense' className={'fdr'}>
                    <Typography color='inherit' variant='h6'>{'Summary'}</Typography>
                    <div className={'flx1'}/>
                    <img src={'../../../assets/icons/carton.svg'} color={'white'} height={30} className={'mr20'}/>
                    {this.getTotalAmounts()}
                    <span className={'w30'}/>
                    <img src={'../../../assets/icons/pallet.svg'} color={'white'} height={30} className={'mr20'}/>
                    {this.state.selectedMaterialStocks.length}
                </Toolbar>
            </AppBar>
            <div className={'p20 fdc '}>
                <div className={'fdr p20'}>
                    <span className={'fw600 mr5'}>Source Organization:</span>{this.getOrganizationName(this.state.selectedSourceOrganization)}
                    <div className={'flx1'}/>
                    <span className={'fw600 mr5'}>Destination Organization: </span>{this.getOrganizationName(this.state.selectedTargetOrganization)}
                </div>
                <div className={'fdr pl20 pr20 pb20'}>
                    <span className={'fw600 mr5'}>Source Site: </span>{this.getSiteDescription(this.state.selectedSourceSite)}
                    <div className={'flx1'}/>
                    <span className={'fw600 mr5'}>Destination Site: </span>{this.getSiteDescription(this.state.selectedTargetSite)}
                </div>
                <Divider className={'mt5 mb5'}/>
                <div className={'fdc p20'}>
                    <div className={'fdr fw600 mb10'}>Selected Material Stock:</div>
                    <CustomTable<IMaterialStock>
                        enableSorting
                        enableFiltering
                        fitWidthToPage
                        enablePagination
                        columns={[
                            { title: 'Date Code', field: 'dateCode', enableFiltering: true, enableSorting: true },
                            { title: 'Material Code', field: 'materialId', formatFunction: this.getMaterialCode, enableFiltering: true, enableSorting: true },
                            { title: 'Material Description', field: 'materialId', formatFunction: this.getMaterialName, enableFiltering: true, enableSorting: true },
                            { title: 'Amount', field: 'id', formatFunction: this.getSelectedAmount, enableFiltering: true, enableSorting: true },
                        ]}
                        rows={this.getSelectedMaterialStockRows(this.props, this.state)}
                        initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                        pageSizes={[50, 150, 250, 500, 1000]}
                        pageHeight={800}
                    />
                </div>
            </div>

        </Card>
        <Card className={'mb20 mxh500 selectTextOnly'}>
            <div className={'pt10 pb10 pl20 bcp'}>
                <Typography className={'cw'} variant='h6'>{'Assign Trip'}</Typography>
            </div>
            <CustomTable<ITrip>
                editFunction={this.setEditTrip}
                enableEditing
                enableSorting
                enableFiltering
                enableClearFilterButton
                enablePagination
                fitWidthToPage
                columns={this.tripColumns()}
                rows={this.getTripRows(this.state)}
                initialSortOrder={[{ columnName: 'id_Id', direction : 'desc' }]}
                pageSizes={[50, 150, 250, 500, 1000]}
            />
        </Card>
        <div className={'fdr flx1 mb20'}>
            <div className={'flx1'}/>
            <FloatingActionButton
                color={'secondary'}
                disabled={!!this.state.newTrip}
                onClick={this.showTripDialog}
            />
        </div>
        <div className={'fdr flx1'}>
            <div className={'flx1'}/>
            <Button
                className={'fwb h35'}
                variant='text'
                disabled={this.state.isLoading || this.state.currentPage !== 3}
                onClick={this.previousPage}>
                Back
            </Button>
            <PillButton color={'secondary'}
                className={'ml15 pl20 pr20 h35'}
                type={'submit'}
                text={'Create Material Dispatch'}
                onClick={this.createDispatch}
                disabled={this.state.isLoading || this.state.currentPage !== 3 || !this.state.selectedLoadDate
                    || !this.state.selectedSourceOrganization || !this.state.selectedSourceSite
                    || !this.state.selectedTargetOrganization || !this.state.selectedTargetSite
                    || this.state.selectedMaterialStocks.length === 0} />
        </div>
    </div>;

    private createDispatch = async () => {
        this.setLoading(true);
        if (this.state.selectedSourceOrganization && this.state.selectedSourceSite
            && this.state.selectedLoadDate && this.state.selectedTargetSite
            && this.state.selectedMaterialStocks.length > 0) {
            const loadDate = moment(this.state.selectedLoadDate).startOf('day').format(DATEPICKER_FORMAT_DEFAULT);
            const newTrip = this.state.newTrip ? { ...this.state.newTrip } : undefined;
            const selectedExistingTrip = this.state.trips.find(x => x.id === this.state.selectedTripId);

            if (newTrip) {
                newTrip.loadDate = loadDate;
            }

            const data : ICreateMaterialDispatch = {
                loadDate,
                sourceSiteId: this.state.selectedSourceSite,
                destinationSiteId: this.state.selectedTargetSite,
                status: 'Instructed',
                isPrinted: false,
                existingTripId: this.state.selectedTripId ?? undefined,
                newTrip,
                materialStocks: this.state.selectedMaterialStocks,
                registrationNumber: newTrip ? newTrip.registrationNumber : selectedExistingTrip?.registrationNumber,
                driver: newTrip ? newTrip.driver : selectedExistingTrip?.driver,
            };

            try {
                const res = await MaterialDispatchHttpService.createInstructedMaterialDispatch(data);

                if (res && res.data) {
                    this.setState({ activeDialog: 1, createdMaterialDispatchCode: res.data?.materialDispatchCode, createdTripId: res.data?.tripId });
                    generalShowSuccessSnackbar('Material Dispatch successfully created.');
                    this.setLoading(false);
                } else {
                    generalShowErrorSnackbar('An error occurred creating the material dispatch.');
                    this.setLoading(false);
                }
            } catch (e) {
                if (e?.data?.message) {
                    generalShowErrorSnackbar(e?.data?.message);
                } else {
                    generalShowErrorSnackbar('An error occurred creating the material dispatch.');
                }
                this.setLoading(false);
            }
        }

    };

    private createAnother = () => {
        this.setState({
            activeDialog: undefined,
            createCompliance: undefined,
            createdTripId: undefined,
            trips: [],
            newTrip: undefined,
            selectedTripId: undefined,
            createdComplianceCode: undefined,
            createdMaterialDispatchCode: undefined,
            currentPage: 1,
            selectedSourceOrganization: undefined,
            selectedSourceSite: undefined,
            selectedTargetOrganization: undefined,
            selectedTargetSite: undefined,
            selectedLoadDate: moment(),
            selectedMaterialStocks: [],
        });
    };

    private dialogTitles = [
        'Add Trip',
        'Material Dispatch Created',
    ];

    private renderDialog = () => <Dialog
        fullWidth
        open={this.state.activeDialog !== undefined}>
        <AppBar position='static' elevation={0}>
            <Toolbar variant='dense' className={'fdr'}>
                <Typography color='inherit' variant='h6'>{this.state.isEditingTrip ? 'Edit Trip' : this.dialogTitles[this.state.activeDialog ?? -1]}</Typography>
            </Toolbar>
        </AppBar>
        {this.renderInnerDialog()}
    </Dialog>;

    private renderInnerDialog = () => {
        switch (this.state.activeDialog) {
            case 0:
                return this.renderTripDialog();
            case 1:
                return this.renderNextActionDialog();
        }
    };

    private carrierOptions = () : Array<IDropDownOptions> => {
        if (!this.props.carriers) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Carrier', disabled: true });
        this.props.carriers
            .forEach(x => returnValue.push({ value: x.id, label: `(${x.code}) ${x.name}` }));
        return returnValue;
    };

    private setTripDescription = (description : string) => {
        if (this.state.newTrip) {
            const newTrip = { ...this.state.newTrip, description };
            this.setState({ newTrip });
        }
    };

    private setTripDriver = (driver : string) => {
        if (this.state.newTrip) {
            const newTrip = { ...this.state.newTrip, driver };
            this.setState({ newTrip });
        }
    };

    private setTripRegistrationNumber = (registrationNumber : string) => {
        if (this.state.newTrip) {
            const newTrip = { ...this.state.newTrip, registrationNumber };
            this.setState({ newTrip });
        }
    };

    private setTripContainer = (container ?: string) => {
        if (this.state.newTrip) {
            const newTrip = { ...this.state.newTrip, container };
            this.setState({ newTrip });
        }
    };

    private setTripContainerTareWeight = (containerTareWeight ?: number) => {
        if (this.state.newTrip) {
            const newTrip = { ...this.state.newTrip, containerTareWeight };
            this.setState({ newTrip });
        }
    };

    private setTripCarrierId = (carrierId : number) => {
        if (this.state.newTrip) {
            const newTrip = { ...this.state.newTrip, carrierId };
            this.setState({ newTrip });
        }
    };

    private renderTripDialog = () => <div className={'p20 fdc'}>
        <Form>
            <div className={'fdr mb10'}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateInput
                        className={'flx1 pl5 pr5'}
                        label={'Load Date'}
                        name={'loadDate'}
                        variant={'standard'}
                        disabled={true}
                        value={this.state.selectedLoadDate}
                        onDateChange={this.onLoadDateChange}
                    />
                </LocalizationProvider>
                <TextField
                    className={'flx1 m5'}
                    label={'Description'}
                    placeholder={'Description'}
                    autoFocus
                    onChange={e => this.setTripDescription(e.target.value)}
                    value={this.state.newTrip?.description}
                />
            </div>
            <div className={'fdr mb10'}>
                <TextField
                    className={'flx1 m5'}
                    label={'Driver'}
                    placeholder={'Driver'}
                    onChange={e => this.setTripDriver(e.target.value)}
                    value={this.state.newTrip?.driver}
                />
                <TextField
                    className={'flx1 m5'}
                    label={'Registration Number'}
                    placeholder={'Registration Number'}
                    onChange={e => this.setTripRegistrationNumber(e.target.value)}
                    value={this.state.newTrip?.registrationNumber}
                />
            </div>
            <div className={'fdr mb10'}>
                <Typography className={'flx1 mb5 ml5 mr5 mt20'} color='inherit' variant='subtitle1'>
                    <CustomAutoSuggest
                        className={'flx1'}
                        placeholder={'Carrier'}
                        field={'carrierId'}
                        options={this.carrierOptions()}
                        onValueChange={newValue => this.setTripCarrierId(Number(newValue))}
                    />
                </Typography>
                <TextField
                    className={'flx1 m5'}
                    label={'Container'}
                    placeholder={'Container'}
                    onChange={e => this.setTripContainer(e.target.value)}
                    value={this.state.newTrip?.container}
                />
                <TextField
                    className={'flx1 m5'}
                    label={'Container Tare Weight'}
                    placeholder={'ContainerTareWeight'}
                    onChange={e => this.setTripContainerTareWeight(e.target.value ? Number(e.target.value) : undefined)}
                    value={this.state.newTrip?.containerTareWeight}
                />
            </div>
        </Form>
        <div className={'fdr flx1 mt20'}>
            <div className={'flx1'}/>
            <Button
                className={'fwb h35'}
                variant='text'
                color='primary'
                disabled={this.state.isLoading}
                onClick={this.cancelTripDialog}>
                Cancel
            </Button>
            <PillButton color={'secondary'}
                className={'ml15 pl20 pr20 h35'}
                type={'submit'}
                text={!this.state.isEditingTrip ? 'Add Trip' : 'Save Trip'}
                onClick={this.state.isEditingTrip ? this.editTrip : this.addTrip}
                disabled={this.state.isLoading} />
        </div>
    </div>;

    private renderNextActionDialog = () =>  <div className={'p20 fdc'}>
        <div className={'fdc mb20'}>
            <div className={'fs16 fw550 fdr'}>{'Material Dispatch Code: '} <div className={'pl5 fs18 fw700'}>{this.state.createdMaterialDispatchCode}</div></div>
            { this.state.createdTripId && <div className={'fs16 fw550'}>{`Trip Id: #${this.state.createdTripId}`}</div>}
        </div>
        <div className = {'fdr flx1 aic jcc'}>
            <Link to='/materialDispatch/dashboard' className={'pl15 pr15 mt10 mb10 w120 h150 mr15'} style={{ textDecoration: 'unset', color: 'unset' }}>
                <PopupActionButton
                    text={'GO TO MATERIAL DISPATCH DASHBOARD'}
                    icon={<DashboardIcon/>}
                    disabled={this.state.isLoading || this.state.activeDialog !== 1}
                    styleButton={'w120 h150'}/>
            </Link>
            <PopupActionButton
                text={'CREATE ANOTHER MATERIAL DISPATCH'}
                icon={<AddIcon/>}
                disabled={this.state.isLoading || this.state.activeDialog !== 1}
                onClick={this.createAnother}
                styleButton={'pl15 pr15 mt15 mb10 w120 mr20 ml20 h150'}/>
        </div>

    </div>;

    private steps = [
        'Select Material Dispatch Details',
        'Select Source Material Stock',
        'Create Material Dispatch',
    ];

    public render() {
        return (
            <Screen isPadded={false} isScrollable={false} isLoading={this.state.isLoading}>
                <div className={'fdc flx1 disableSelect posr p20'}>
                    <Stepper activeStep={this.state.currentPage - 1} className={'pb20'}>
                        {
                            this.steps.map(label => <Step key={label}>
                                <StepLabel style={{ transform: 'scale(1.2)' }} className={'pr15 pl15'}>{label}</StepLabel>
                            </Step>)
                        }
                    </Stepper>
                    {this.renderPage()}
                    {this.renderDialog()}
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
        materialStocks: state.data.materialStocks,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
        materials: state.masterData.materials,
        unitOfMeasures: state.masterData.unitOfMeasures,
        trips: state.data.trips,
        carriers: state.masterData.carriers,
    };
};

export default connect(
    mapStateToProps,
)(MaterialDispatchWizard);
